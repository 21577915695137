import { connectorTypesMap } from '../connectors/connectorTypes';

export function getConnectionKey({ fromNodeId, toNodeId, fromConnectorType, toConnectorType }) {
  return `${fromNodeId}::${toNodeId}::${fromConnectorType}::${toConnectorType}`;
}

function makeConnection({
  toNodeId,
  fromNodeId,
  fromConnectorType,
  toConnectorType,
  activeConnectors,
}) {
  const connectionKey = getConnectionKey({
    toNodeId,
    fromNodeId,
    fromConnectorType,
    toConnectorType,
  });

  activeConnectors.add(`${fromNodeId}::${fromConnectorType}`);
  activeConnectors.add(`${toNodeId}::${toConnectorType}`);

  return {
    key: connectionKey,
    fromNodeId: fromNodeId,
    fromConnectorType: fromConnectorType,
    toNodeId: toNodeId,
    toConnectorType: toConnectorType,
  };
}

export function deriveConnections({ advancedFlow }) {
  if (advancedFlow == null) return null;

  const connections = {};
  const activeConnectors = new Set();

  const nodeParents = {};
  const nodeChildren = {};

  for (const [id, card] of Object.entries(advancedFlow.cards ?? {})) {
    if (card[connectorTypesMap.Out] != null) {
      for (const toNodeId of card[connectorTypesMap.Out]) {
        const connection = makeConnection({
          toNodeId: toNodeId,
          fromNodeId: id,
          fromConnectorType: connectorTypesMap.Out,
          toConnectorType: connectorTypesMap.In,
          activeConnectors,
        });
        connections[connection.key] = connection;
        nodeParents[connection.toNodeId] = nodeParents[connection.toNodeId] ?? [];
        nodeParents[connection.toNodeId].push({ id, fromConnectorType: connectorTypesMap.Out });
        nodeChildren[id] = nodeChildren[id] ?? [];
        nodeChildren[id].push({
          id: connection.toNodeId,
          fromConnectorType: connectorTypesMap.Out,
        });
      }
    }

    if (card[connectorTypesMap.Error] != null) {
      for (const toNodeId of card[connectorTypesMap.Error]) {
        const connection = makeConnection({
          toNodeId: toNodeId,
          fromNodeId: id,
          fromConnectorType: connectorTypesMap.Error,
          toConnectorType: connectorTypesMap.In,
          activeConnectors,
        });
        connections[connection.key] = connection;
        nodeParents[connection.toNodeId] = nodeParents[connection.toNodeId] ?? [];
        nodeParents[connection.toNodeId].push({ id, fromConnectorType: connectorTypesMap.Error });
        nodeChildren[id] = nodeChildren[id] ?? [];
        nodeChildren[id].push({
          id: connection.toNodeId,
          fromConnectorType: connectorTypesMap.Error,
        });
      }
    }

    if (card[connectorTypesMap.True] != null) {
      for (const toNodeId of card[connectorTypesMap.True]) {
        const connection = makeConnection({
          toNodeId: toNodeId,
          fromNodeId: id,
          fromConnectorType: connectorTypesMap.True,
          toConnectorType: connectorTypesMap.In,
          activeConnectors,
        });
        connections[connection.key] = connection;
        nodeParents[connection.toNodeId] = nodeParents[connection.toNodeId] ?? [];
        nodeParents[connection.toNodeId].push({ id, fromConnectorType: connectorTypesMap.True });
        nodeChildren[id] = nodeChildren[id] ?? [];
        nodeChildren[id].push({
          id: connection.toNodeId,
          fromConnectorType: connectorTypesMap.True,
        });
      }
    }

    if (card[connectorTypesMap.False] != null) {
      for (const toNodeId of card[connectorTypesMap.False]) {
        const connection = makeConnection({
          toNodeId: toNodeId,
          fromNodeId: id,
          fromConnectorType: connectorTypesMap.False,
          toConnectorType: connectorTypesMap.In,
          activeConnectors,
        });
        connections[connection.key] = connection;
        nodeParents[connection.toNodeId] = nodeParents[connection.toNodeId] ?? [];
        nodeParents[connection.toNodeId].push({ id, fromConnectorType: connectorTypesMap.False });
        nodeChildren[id] = nodeChildren[id] ?? [];
        nodeChildren[id].push({
          id: connection.toNodeId,
          fromConnectorType: connectorTypesMap.False,
        });
      }
    }
  }

  /**
   * We need to map the ancestors in order to find out which tokens a specific card has access to.
   * Because we know the ancestors we can check if any of them have tokens and supply these to a
   * card.
   */
  const nodeAncestors = {};

  function getAncestors(parents) {
    let accumulator = [];
    const doneMap = {};

    for (const { id, fromConnectorType } of parents) {
      const card = advancedFlow.cards[id];

      accumulator.push({
        id,
        fromConnectorType,
        cardType: card.type,
        cardOwnerUri: card.ownerUri,
        cardId: card.id,
      });

      const currentParents = nodeParents[id];

      if (currentParents?.length > 0) {
        // The same id can occur multiple times for example when 2 outputs of a card go to the same
        // child card. So if this card was already handled continue to the next card.
        if (doneMap[id] === true) {
          continue;
        }

        // This path might already be done so reuse it.
        const parentAncestors = nodeAncestors[id] ?? getAncestors(currentParents);

        // Merge the results.
        accumulator = accumulator.concat(parentAncestors);

        // Mark this node as done in the current context.
        doneMap[id] = true;
      }
    }

    return accumulator;
  }

  let hasStartCard = false;

  for (const [key, card] of Object.entries(advancedFlow.cards ?? {})) {
    if (card.type === 'start') {
      hasStartCard = true;
    }

    const parents = nodeParents[key];

    // Sort the parents based on the card.input order. If the parent is not in inputs it should
    // be put at the end because we set index to parents.length. The all card uses this order
    // to determine connector order.
    if (parents?.length > 0) {
      const orderedParents = card[connectorTypesMap.In];

      orderedParents != null &&
        parents.sort((firstParent, secondParent) => {
          let firstIndex = orderedParents.indexOf(
            `${firstParent.id}::${firstParent.fromConnectorType}`
          );
          let secondIndex = orderedParents.indexOf(
            `${secondParent.id}::${secondParent.fromConnectorType}`
          );

          if (firstIndex === -1) firstIndex = parents.length;
          if (secondIndex === -1) secondIndex = parents.length;

          return firstIndex - secondIndex;
        });
    }

    switch (true) {
      case card.type === 'trigger':
      case card.type === 'condition':
      case card.type === 'action':
      case card.type === 'delay':
      case card.type === 'start':
      case card.type === 'all':
      case card.type === 'any':
        if (parents?.length > 0) {
          nodeAncestors[key] = getAncestors(parents);
        }
        break;
      default:
        break;
    }
  }

  return { connections, activeConnectors, nodeParents, nodeChildren, nodeAncestors, hasStartCard };
}
