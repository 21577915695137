import { useEffect } from 'react';

import { currentOS, OS } from '../../../lib/platform';
import { FlowEditor, useFlowEditor } from './useFlowEditor';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { showForLarge } from '../../../theme/classes/responsive';

import { HeaderActionsContainer } from '../../../components/header/HeaderActionsContainer';
import { MonoButton } from '../../../components/buttons/MonoButton';
import { VariablesButton } from '../../logic/variables/VariablesButton';

import { iconActionRedo } from '../../../theme/icons/interface/action-redo';
import { iconActionUndo } from '../../../theme/icons/interface/action-undo';

export function FlowActions(props) {
  const { i18n } = useI18n();
  const { history, historyIndex } = useFlowEditor();

  const canUndo = history[historyIndex - 1] != null;
  const canRedo = history[historyIndex + 1] != null;

  useEffect(() => {
    function handleKeyDown(event) {
      let isUndo;
      let isRedo;

      const key = event.key?.toLowerCase() ?? null;

      switch (currentOS) {
        case OS.MACOS:
          isUndo = event.metaKey && !event.shiftKey && key === 'z';
          isRedo = event.metaKey && event.shiftKey && key === 'z';
          break;
        default:
          isUndo = event.ctrlKey && key === 'z';
          isRedo =
            (event.ctrlKey && key === 'y') || (event.ctrlKey && event.shiftKey && key === 'z');
          break;
      }

      if (isRedo) {
        event.preventDefault();
        FlowEditor.redo();
      } else if (isUndo) {
        event.preventDefault();
        FlowEditor.undo();
      }
    }

    document.documentElement.addEventListener('keydown', handleKeyDown);

    return function () {
      document.documentElement.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <HeaderActionsContainer>
      <MonoButton
        title={i18n.messageFormatter('common.undo')}
        isDisabled={canUndo === false}
        onPress={() => {
          FlowEditor.undo();
        }}
      >
        <MonoButton.Icon url={iconActionUndo} />
        <MonoButton.Text className={showForLarge}>
          {i18n.messageFormatter('common.undo')}
        </MonoButton.Text>
      </MonoButton>

      <MonoButton
        title={i18n.messageFormatter('common.redo')}
        isDisabled={canRedo === false}
        onPress={() => {
          FlowEditor.redo();
        }}
      >
        <MonoButton.Icon url={iconActionRedo} />
        <MonoButton.Text className={showForLarge}>
          {i18n.messageFormatter('common.redo')}
        </MonoButton.Text>
      </MonoButton>

      <VariablesButton />
    </HeaderActionsContainer>
  );
}
