import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';
import { ResourceUtils } from '../ResourceUtils';

// import { I18NStore } from '../../providers/LocaleProvider';

export class FlowTokenStore extends BaseApiStore {
  static key = 'flowtokens';
  static store = this.createStore(this.key);
  static flowTokenCacheId = 'HomeyAPI.ManagerFlowToken.FlowToken';

  static createInitialState() {
    return {
      data: null,
      byKey: null,
      byOwnerUri: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static listeners = new Map();

  static addValueListener(key, listener) {
    const prev = this.listeners.get(key);

    if (prev != null) {
      prev.add(listener);
    } else {
      this.listeners.set(key, new Set([listener]));
    }
  }

  static removeValueListener(key, listener) {
    const prev = this.listeners.get(key);

    if (prev != null) {
      prev.delete(listener);
    }

    if (prev.size === 0) {
      this.listeners.delete(key);
    }
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info(`fetch:${this.key}`);
    this.destroy('before:fetch');
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerFlowToken = state.api.flowToken;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const tokensPromise = managerFlowToken.getFlowTokens({
        $skipCache: skipCache,
      });
      // const collatorPromise = I18NStore.getCollator();

      // collator
      const [, tokensData] = await Promise.all([
        waitPromise,
        tokensPromise,
        // collatorPromise,
      ]);

      const values = Object.values(tokensData);
      // values.sort(FlowTokenUtils.compareLog.bind(FlowTokenUtils, collator));

      const result = values.reduce(
        (accumulator, token, index) => {
          const tokenReference = { ...token };
          const key = ResourceUtils.getTokenKey(tokenReference);
          const ownerUri = ResourceUtils.getOwnerUri(tokenReference);
          const ownerId = ResourceUtils.getOwnerId(tokenReference);
          const ownerName = ResourceUtils.getOwnerName(tokenReference);
          const ownerColor = ResourceUtils.getOwnerColor(tokenReference);

          accumulator.data[key] = token;
          accumulator.byKey[key] = tokenReference;
          accumulator.byOwnerUri[ownerUri] = {
            ...accumulator.byOwnerUri[ownerUri],
            data: {
              ...(accumulator.byOwnerUri[ownerUri]?.data ?? null),
              [key]: tokenReference,
            },
          };

          accumulator.byOwnerUri[ownerUri].ownerId = ownerId;
          accumulator.byOwnerUri[ownerUri].ownerName = ownerName;
          accumulator.byOwnerUri[ownerUri].ownerColor = ownerColor;

          return accumulator;
        },
        {
          data: {},
          byKey: {},
          byOwnerUri: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byKey: result.byKey,
        byOwnerUri: result.byOwnerUri,
        loading: false,
        manager: managerFlowToken,
      });

      managerFlowToken.addListener('flowtoken.create', this.handleCreate);
      managerFlowToken.addListener('flowtoken.update', this.handleUpdate);
      managerFlowToken.addListener('flowtoken.delete', this.handleDelete);
    } catch (error) {
      this.destroy('error');
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy(reason) {
    __DEV__ && console.info(`destroy:${this.key}:${reason ?? ''}`);
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerFlowToken = state.api.flowToken;

      managerFlowToken.removeListener('flowtoken.create', this.handleCreate);
      managerFlowToken.removeListener('flowtoken.update', this.handleUpdate);
      managerFlowToken.removeListener('flowtoken.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(token) {
    const state = this.get();
    const cache = state.api?.flowToken._caches[this.flowTokenCacheId];

    return cache?.getOne({
      id: cache._guessCacheId(token),
    });
  }

  static handleCreate = (createdToken) => {
    const key = ResourceUtils.getTokenKey(createdToken);
    __DEV__ && console.info(`create:token:${key}`);
    const state = this.get();
    const tokenInstance = this.getInstanceFromCache(createdToken);

    if (tokenInstance == null) return;

    const tokenReference = { ...tokenInstance };
    const ownerUri = ResourceUtils.getOwnerUri(tokenReference);
    const ownerId = ResourceUtils.getOwnerId(tokenReference);
    const ownerName = ResourceUtils.getOwnerName(tokenReference);
    const ownerColor = ResourceUtils.getOwnerColor(tokenReference);

    const nextByOwnerUri = {
      ...state.byOwnerUri,
      [ownerUri]: {
        ...(state.byOwnerUri[ownerUri] ?? null),
        data: {
          ...(state.byOwnerUri[ownerUri]?.data ?? null),
          [key]: tokenReference,
        },
      },
    };

    nextByOwnerUri[ownerUri].ownerUri = ownerUri;
    nextByOwnerUri[ownerUri].ownerId = ownerId;
    nextByOwnerUri[ownerUri].ownerName = ownerName;
    nextByOwnerUri[ownerUri].ownerColor = ownerColor;

    this.set({
      data: {
        ...state.data,
        [key]: tokenInstance,
      },
      byKey: {
        ...state.byKey,
        [key]: tokenReference,
      },
      byOwnerUri: nextByOwnerUri,
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedToken) => {
    const key = ResourceUtils.getTokenKey(updatedToken);
    //__DEV__ && console.info(`update:token:${key}`);

    this.updateQueue[key] = { ...updatedToken };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedTokens = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextByKey = {
          ...state.byKey,
        };

        const nextByOwnerUri = {
          ...state.byOwnerUri,
        };

        let updateCount = 0;

        for (const [key, queuedToken] of Object.entries(queuedTokens)) {
          const tokenInstance = this.getInstanceFromCache(queuedToken);

          if (tokenInstance == null || state.data[key] == null) {
            __DEV__ && console.info(`update:token:unknown:${key}`);
            continue;
          }

          const prevTokenReference = state.byKey[key];

          if (prevTokenReference.value !== tokenInstance.value) {
            const descriptor = Object.getOwnPropertyDescriptor(prevTokenReference, 'value');

            // not sure why it happens but sometimes it's not writeable so this would crash
            // nowhere can I find where the writeable is being set to false
            // maybe zustand doing something weird?
            if (descriptor.writable === true) {
              prevTokenReference.value = tokenInstance.value;
            }

            const listeners = this.listeners.get(key);
            listeners?.forEach((listener) => {
              listener(tokenInstance.value);
            });
          }

          if (
            prevTokenReference.title === tokenInstance.title &&
            prevTokenReference.type === tokenInstance.type
          ) {
            continue;
          }

          const tokenReference = { ...tokenInstance };
          const ownerUri = ResourceUtils.getOwnerUri(tokenReference);
          const ownerName = ResourceUtils.getOwnerName(tokenReference);
          const ownerId = ResourceUtils.getOwnerId(tokenReference);
          const ownerColor = ResourceUtils.getOwnerColor(tokenReference);

          nextByKey[key] = tokenReference;

          nextByOwnerUri[ownerUri] = {
            ...(nextByOwnerUri[ownerUri] ?? null),
            data: {
              ...(nextByOwnerUri[ownerUri]?.data ?? null),
              [key]: tokenReference,
            },
          };

          nextByOwnerUri[ownerUri].ownerUri = ownerUri;
          nextByOwnerUri[ownerUri].ownerId = ownerId;
          nextByOwnerUri[ownerUri].ownerName = ownerName;
          nextByOwnerUri[ownerUri].ownerColor = ownerColor;

          updateCount++;
        }

        __DEV__ && console.info(`update:token:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byKey: nextByKey,
            byOwnerUri: nextByOwnerUri,
          });
      }, 1000);
    }
  };

  static handleDelete = (deletedToken) => {
    const key = ResourceUtils.getTokenKey(deletedToken);
    __DEV__ && console.info(`delete:token:${key}`);
    const state = this.get();
    const tokenInstance = state.data[key];

    if (tokenInstance == null) return;
    const ownerUri = ResourceUtils.getOwnerUri(tokenInstance);

    const nextData = { ...state.data };
    const nextByKey = { ...state.byKey };
    const nextByOwnerUri = { ...state.byOwnerUri };

    delete nextData[key];
    delete nextByKey[key];

    nextByOwnerUri[ownerUri] = {
      ...(nextByOwnerUri[ownerUri] ?? null),
    };

    delete nextByOwnerUri[ownerUri].data[key];

    if (Object.keys(nextByOwnerUri[ownerUri].data).length === 0) {
      delete nextByOwnerUri[ownerUri];
    }

    this.set({
      data: nextData,
      byKey: nextByKey,
      byOwnerUri: nextByOwnerUri,
    });
  };
}
