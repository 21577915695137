import React, { useState } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';

import { iconChevronDown } from '../../../theme/icons/interface/chevron-down';
import { Icon } from '../../common/Icon';
import { mergeProps, useButton, useFocus } from 'react-aria';

export function SelectPreviewFlat(props) {
  const button = useButton(props.select.triggerProps, props.previewRef);
  const [isFocused, setIsFocused] = useState(false);
  const focus = useFocus({
    onFocusChange: setIsFocused,
  });

  return (
    <S.Input
      {...mergeProps(button.buttonProps, focus.focusProps)}
      ref={props.previewRef}
      title={props.state.selectedItem?.textValue}
      data-is-open={props.state.isOpen}
      data-is-focused={isFocused}
    >
      <S.Span {...props.select.valueProps}>{props.state.selectedItem?.textValue}</S.Span>
      <S.ChevronDownIcon
        url={iconChevronDown}
        color={theme.color.icon_light}
        size={theme.icon.size_tiny}
      />
    </S.Input>
  );
}

function S() {}
SelectPreviewFlat.S = S;

S.Input = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  width: 100%;
  height: 37px;
  background-color: ${theme.color.component};
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;
  justify-content: flex-end;
  padding: 0;

  &[data-is-open='true'],
  &[data-is-focused='true'] {
    color: ${theme.color.blue};
  }
`;

S.Span = styled.span`
  display: inline-block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

S.SelectedIcon = styled(Icon)`
  flex: 0 0 auto;
`;

S.ChevronDownIcon = styled(Icon)`
  flex: 0 0 ${theme.icon.size_tiny};
  margin-left: 5px;
`;
