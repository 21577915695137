import React, { useState } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useButton, useFocus } from 'react-aria';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { iconChevronDown } from '../../../theme/icons/interface/chevron-down';
import { Icon } from '../../../components/common/Icon';

export function DeviceSettingsGeneralStatusIndicatorSelectPreview(props) {
  const { previewRef, select, state, onBlur } = props;

  const button = useButton(select.triggerProps, previewRef);

  const [isFocused, setIsFocused] = useState(false);
  const focus = useFocus({
    onFocusChange: setIsFocused,
    onBlur,
  });

  return (
    <S.Input
      {...mergeProps(button.buttonProps, focus.focusProps)}
      ref={previewRef}
      title={state.selectedItem?.textValue}
      data-is-open={state.isOpen}
      data-is-focused={isFocused}
      data-is-disabled={state.collection?.size <= 1}
    >
      <S.Span {...select.valueProps}>
        <S.Name>{state.collection?.size > 0 && state.selectedItem?.textValue}</S.Name>
        {state.selectedItem?.rendered?.indicator}
      </S.Span>
      <S.ChevronDownIcon
        url={iconChevronDown}
        color={theme.color.icon_light}
        size={theme.icon.size_tiny}
      />
    </S.Input>
  );
}

function S() {}
DeviceSettingsGeneralStatusIndicatorSelectPreview.S = S;

S.Input = styled.button`
  position: relative;
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: ${su(1)};
  min-width: 0;
  width: 100%;
  height: 37px;
  padding: 6px ${su(1)};
  background-color: ${theme.color.component};
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;
  border: 1px solid ${theme.input.border};
  border-radius: ${theme.input.border_radius};

  &[data-is-disabled='true'] {
    cursor: initial;
    background-color: ${theme.color.mono_050};
  }

  &:not([data-is-disabled='true']) {
    &[data-is-open='true'],
    &[data-is-focused='true'] {
      border: 1px solid ${theme.input.border_focus};
    }
  }
`;

S.Span = styled.span`
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 6px;
  width: 100%;
`;

S.Name = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: ${theme.color.text_light};
`;

S.ChevronDownIcon = styled(Icon)`
  flex: 0 0 ${theme.icon.size_tiny};
`;
