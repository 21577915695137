import { useEffect, useRef, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useTextField } from 'react-aria';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { ArgumentBase, Argument } from './Argument';
import { MultiplierPicker } from './MultiplierPicker';

import { argInput } from './argInput';

const MULTIPLIER_MAP = {
  1: 'sec',
  60: 'min',
  3600: 'hour',
};

export function ArgumentDuration(props) {
  const { i18n } = useI18n();
  const triggerState = useOverlayTriggerState({});

  const label = props.label ?? i18n.messageFormatter('flow.argument.duration.title');
  const placeholder =
    props.placeholder ?? i18n.messageFormatter('flow.argument.duration.placeholder');
  const storeValue = props.storeValue ?? props.onGetStoreValue?.() ?? props.data?.duration;

  const [localValue, setLocalValue] = useState(storeValue);

  useEffect(() => {
    setLocalValue(storeValue);
  }, [storeValue]);

  function updateCard(nextValue) {
    if (storeValue !== nextValue) {
      const value = {
        number: nextValue.number,
        multiplier: parseInt(nextValue.multiplier),
      };

      if (props.onUpdateStoreValue) {
        props.onUpdateStoreValue(value);
        return;
      }

      props.onUpdate?.({
        value: value,
      });
    }
  }

  function handleChange(value) {
    const regex = new RegExp(/^\d{0,3}(-\d{0,3})?$/);

    // I'm to lazy to make a nice regex.
    if (value.startsWith('-')) {
      return;
    }

    if (regex.test(value) === false) {
      return;
    }

    setLocalValue((prevValue) => {
      // Ensures a default multiplier
      return {
        multiplier: 1,
        ...prevValue,
        number: value,
      };
    });
  }

  function handleMultiplierChange(value) {
    setLocalValue((prevValue) => {
      // Ensures a default value
      return {
        number: '1',
        ...prevValue,
        multiplier: value,
      };
    });
  }

  function handleSaveRequest() {
    const regex = new RegExp(/^\d{1,3}(-\d{0,3})?$/);

    if (regex.test(localValue.number)) {
      // There might still be some values that are defined as number types. So
      // check for existance of endsWith.
      if (localValue.number.endsWith?.('-')) {
        const number = localValue.number.slice(0, -1);
        triggerState.close();
        updateCard({
          ...localValue,
          number,
        });
      } else {
        triggerState.close();
        updateCard(localValue);
      }
    } else {
      // If empty value save is requested remove the property.
      if (localValue.number === '') {
        if (props.onUpdateStoreValue) {
          props.onUpdateStoreValue(undefined);
          triggerState.close();
          return;
        }

        // Will auto close because the element will disappear.
        props.onUpdate?.({
          value: undefined,
        });
      } else {
        triggerState.close();
      }
    }
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    setLocalValue(storeValue);
    triggerState.close();
  }

  let prefix = null;

  if (props.prefix === undefined) {
    prefix = ` ${i18n.messageFormatter('flow.argument.duration.prefix')}`;
  } else if (props.prefix === null) {
    prefix = null;
  } else {
    prefix = ` ${props.prefix}`;
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        if (localValue?.number != null && localValue?.multiplier != null) {
          return (
            <>
              {prefix}
              <ArgumentBase {...triggerProps} ref={triggerRef}>
                {`${localValue.number} ${MULTIPLIER_MAP[localValue.multiplier]}`}
              </ArgumentBase>
            </>
          );
        }

        return (
          <>
            {prefix}
            <ArgumentBase {...triggerProps} ref={triggerRef} data-is-empty={true}>
              ...
            </ArgumentBase>
          </>
        );
      }}
      renderOverlay={() => {
        return (
          <NumberInput
            value={localValue?.number ?? ''}
            multiplier={localValue?.multiplier ?? ''}
            aria-label={label}
            placeholder={placeholder}
            onChange={handleChange}
            onMultiplierChange={handleMultiplierChange}
            onSaveRequest={handleSaveRequest}
          />
        );
      }}
    />
  );
}

export function NumberInput(props) {
  const inputRef = useRef();
  const input = useTextField(
    {
      'aria-label': props['aria-label'],
      type: 'text',
      value: props.value ?? '',
      onChange: props.onChange,
      placeholder: props.placeholder,
      onKeyDown(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
          props.onSaveRequest();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
      onKeyUp(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
    },
    inputRef
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }, []);

  return (
    <argInput.InputContainer style={{ '--width': '200px' }}>
      <argInput.InputRow>
        <argInput.InputWrapper>
          <argInput.Input
            {...input.inputProps}
            ref={inputRef}
            min={props.min}
            max={props.max}
            step={props.step}
          />

          <argInput.InputAbsoluteRight>
            <MultiplierPicker
              aria-label="Multiplier"
              value={props.multiplier}
              onChange={props.onMultiplierChange}
            />
          </argInput.InputAbsoluteRight>
        </argInput.InputWrapper>
      </argInput.InputRow>
    </argInput.InputContainer>
  );
}
