import { color as colorLight } from './vars/color.palette';
import { func as funcLight } from './vars/color.functional';

import { color as colorDark } from './vars/color.palette.dark';
import { func as funcDark } from './vars/color.functional.dark';

import { boxShadow } from './vars/box-shadow';
import { boxShadow as boxShadowDark } from './vars/box-shadow.dark';
import { borderRadius } from './vars/border-radius';
import { curve, duration, transition } from './vars/transition';
import { zIndex } from './vars/zIndex';
import { fontWeight } from './vars/fontWeight';
import { fontSize } from './vars/fontSize';

import { sud } from './vars/sud';

import { suNew, suOld } from './functions/su';

import { filter } from './vars/filter';
import { filter as filterDark } from './vars/filter.dark';

import { icon } from './vars/components/icon';
import { input } from './vars/components/input';
import { flowcard } from './vars/components/flowcard';
import { device } from './vars/components/device';
import { input as inputDark } from './vars/components/input.dark';
import { flowcard as flowcardDark } from './vars/components/flowcard.dark';
import { gradientButton } from './vars/components/gradient-button';
import { monoButton } from './vars/components/mono-button';
import { monoButton as monoButtonDark } from './vars/components/mono-button.dark';

import { getVarAccessor, getVarName, createKeyProxies } from './utils';

const themeObjBase = {
  color: { ...colorLight, ...funcLight },
  boxShadow: boxShadow,
  borderRadius: borderRadius,
  fontWeight: fontWeight,
  fontSize: fontSize,
  transition: transition,
  duration: duration,
  curve: curve,
  filter: filter,
  zIndex: zIndex,
  sud: sud,
  // components
  icon: icon,
  input: input,
  flowcard: flowcard,
  device: device,
  gradientButton: gradientButton,
  monoButton: monoButton,
} as const;

const themeObjDark = {
  color: { ...colorDark, ...funcDark },
  boxShadow: boxShadowDark,
  filter: filterDark,
  // components
  input: inputDark,
  flowcard: flowcardDark,
  monoButton: monoButtonDark,
};

const varDefinitionHandler = {
  // @ts-ignore
  get(target, key, receiver) {
    return `${getVarName(target, key)}: ${target[key]};`;
  },
};

const varHandler = {
  // @ts-ignore
  get(target, key, receiver) {
    if (import.meta.env.MODE !== 'production') {
      if (target[key] == null && key !== '$$typeof') {
        // $$typeof somehow caused by CRA fast refresh
        throw new Error(`Unknown theme key: ${key}`);
      }
    }

    if (typeof target[key] === 'function') {
      return target[key];
    } else if (typeof target[key] === 'object' && target[key] !== null) {
      // @ts-ignore
      return keyProxies[key];
    } else {
      return getVarAccessor(target, key);
    }
  },
};

const keyProxies = createKeyProxies(themeObjBase, varHandler);

export const definitionBase = createKeyProxies(themeObjBase, varDefinitionHandler);
export const definitionDark = createKeyProxies(themeObjDark, varDefinitionHandler);

export const theme: typeof themeObjBase & { suo: typeof suOld; su: typeof suNew } = new Proxy(
  themeObjBase,
  varHandler
);

theme.suo = suOld;
theme.su = suNew;
