import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

import { ResourceUtils } from '../ResourceUtils';

export class DriverStore extends BaseApiStore {
  static key = 'drivers';
  static store = this.createStore(this.key);
  static driverCacheId = 'HomeyAPI.ManagerDrivers.Driver';

  static createInitialState() {
    return {
      data: null,
      byKey: null,
      byUri: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info('fetch:drivers');
    this.destroy();
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerDrivers = state.api.drivers;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const driversPromise = await managerDrivers.getDrivers({
        $skipCache: skipCache,
      });

      const [, driversData] = await Promise.all([waitPromise, driversPromise]);

      const result = Object.values(driversData).reduce(
        (accumulator, driver) => {
          const driverReference = { ...driver };
          const key = ResourceUtils.getKey(driverReference);
          const ownerUri = ResourceUtils.getOwnerUri(driverReference);
          const ownerId = ResourceUtils.getOwnerId(driverReference);
          const ownerName = ResourceUtils.getOwnerName(driverReference);
          const ownerColor = ResourceUtils.getOwnerColor(driverReference);

          accumulator.data[key] = driver;
          accumulator.byKey[key] = driverReference;
          accumulator.byUri[ownerUri] = {
            ...accumulator.byUri[ownerUri],
            data: {
              ...(accumulator.byUri[ownerUri]?.data ?? null),
              [key]: driverReference,
            },
          };

          accumulator.byUri[ownerUri].ownerUri = ownerUri;
          accumulator.byUri[ownerUri].ownerId = ownerId;
          accumulator.byUri[ownerUri].ownerName = ownerName;
          accumulator.byUri[ownerUri].ownerColor = ownerColor;

          return accumulator;
        },
        {
          data: {},
          byKey: {},
          byUri: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byKey: result.byKey,
        byUri: result.byUri,
        loading: false,
        manager: managerDrivers,
      });

      managerDrivers.addListener('driver.create', this.handleCreate);
      managerDrivers.addListener('driver.update', this.handleUpdate);
      managerDrivers.addListener('driver.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:drivers');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerDrivers = state.api.drivers;

      managerDrivers.removeListener('driver.create', this.handleCreate);
      managerDrivers.removeListener('driver.update', this.handleUpdate);
      managerDrivers.removeListener('driver.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(driver, stateArg) {
    const state = stateArg ?? this.get();
    const cache = state.api.drivers._caches[this.driverCacheId];

    return cache.getOne({
      id: cache._guessCacheId(driver),
    });
  }

  static handleCreate = (createdDriver) => {
    const key = ResourceUtils.getKey(createdDriver);
    __DEV__ && console.info(`create:driver:${key}`);
    const state = this.get();
    const driverInstance = this.getInstanceFromCache(createdDriver, state);

    if (driverInstance == null) return;

    const driverReference = { ...driverInstance };
    const ownerUri = ResourceUtils.getOwnerUri(driverReference);
    const ownerId = ResourceUtils.getOwnerId(driverReference);
    const ownerName = ResourceUtils.getOwnerName(driverReference);
    const ownerColor = ResourceUtils.getOwnerColor(driverReference);

    const nextByUri = {
      ...state.byUri,
      [ownerUri]: {
        ...(state.byUri[ownerUri] ?? null),
        data: {
          ...(state.byUri[ownerUri]?.data ?? null),
          [key]: driverReference,
        },
      },
    };

    nextByUri[ownerUri].ownerUri = ownerUri;
    nextByUri[ownerUri].ownerId = ownerId;
    nextByUri[ownerUri].ownerName = ownerName;
    nextByUri[ownerUri].ownerColor = ownerColor;

    this.set({
      data: {
        ...state.data,
        [key]: driverInstance,
      },
      byKey: {
        ...state.byKey,
        [key]: driverReference,
      },
      byUri: nextByUri,
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedDriver) => {
    const key = ResourceUtils.getKey(updatedDriver);
    __DEV__ && console.info(`update:driver:${key}`);

    this.updateQueue[key] = { ...updatedDriver };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedDrivers = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextByKey = {
          ...state.byKey,
        };

        const nextByUri = {
          ...state.byUri,
        };

        let updateCount = 0;

        for (const [key, driver] of Object.entries(queuedDrivers)) {
          const driverInstance = this.getInstanceFromCache(driver, state);

          if (driverInstance == null || state.data[key] == null) {
            __DEV__ && console.info(`update:driver:unknown:${key}`);
            continue;
          }

          const driverReference = { ...driverInstance };
          const ownerUri = ResourceUtils.getOwnerUri(driverReference);
          const ownerName = ResourceUtils.getOwnerName(driverReference);
          const ownerId = ResourceUtils.getOwnerId(driverReference);
          const ownerColor = ResourceUtils.getOwnerColor(driverReference);

          nextByKey[key] = driverReference;

          nextByUri[ownerUri] = {
            ...(nextByUri[ownerUri] ?? null),
            data: {
              ...(nextByUri[ownerUri]?.data ?? null),
              [key]: driverReference,
            },
          };

          nextByUri[ownerUri].ownerUri = ownerUri;
          nextByUri[ownerUri].ownerId = ownerId;
          nextByUri[ownerUri].ownerName = ownerName;
          nextByUri[ownerUri].ownerColor = ownerColor;

          updateCount++;
        }

        __DEV__ && console.info(`update:driver:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byKey: nextByKey,
            byUri: nextByUri,
          });
      }, 200);
    }
  };

  static handleDelete = (deletedDriver) => {
    const key = ResourceUtils.getKey(deletedDriver);
    __DEV__ && console.info(`delete:driver:${key}`);
    const state = this.get();
    const driverInstance = state.data[key];

    if (driverInstance == null) return;
    const ownerUri = ResourceUtils.getOwnerUri(driverInstance);

    const nextData = { ...state.data };
    const nextByKey = { ...state.byKey };
    const nextByUri = { ...state.byUri };

    delete nextData[key];
    delete nextByKey[key];

    nextByUri[ownerUri] = {
      ...(nextByUri[ownerUri] ?? null),
    };

    delete nextByUri[ownerUri].data[key];

    if (Object.keys(nextByUri[ownerUri].data).length === 0) {
      delete nextByUri[ownerUri];
    }

    this.set({
      data: nextData,
      byKey: nextByKey,
      byUri: nextByUri,
    });
  };
}
