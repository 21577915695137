import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';

import { ToastManager } from '../../../ToastManager';

import { mergeProps, useFocusRing } from 'react-aria';
import { useOptionContext } from '../../list-box/ListBox';

import { theme } from '../../../theme/theme';
import { scrollbars } from '../../../theme/elements/scrollbars';

import * as Containers from '../Containers';
import { CapabilitySelect } from '../CapabilitySelect';
import { ListBox } from '../../list-box/ListBox';

export function Picker({ device, component, capabilities }) {
  const instance = useRef(`Picker:${uuid()}`);
  const [value, setValue] = useState(null);
  const [capability, setCapability] = useState(() => {
    return capabilities?.[component.capabilities[0]] ?? null;
  });

  useEffect(() => {
    setCapability(capabilities?.[component.capabilities[0]] ?? null);
  }, [capabilities, component.capabilities]);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;
        setValue(value);
      });

      setValue(capability.value);

      return unregister;
    }
  }, [capability]);

  if (capability == null) return null;

  function renderItem() {
    return <PickerItem />;
  }

  function handleSelectionChange([id]) {
    const nextValue = id;
    if (value === nextValue) return;
    setValue(nextValue);

    capability
      .setValue(nextValue, instance.current)
      .then(() => {})
      .catch((error) => {
        setValue(value);
        ToastManager.handleError(error);
      });
  }

  return (
    <Containers.Control>
      <Containers.Select>
        {component.capabilities.length > 1 && (
          <CapabilitySelect
            selectedCapability={capability}
            capabilities={capabilities}
            componentCapabilities={component.capabilities}
            onSelectionChange={setCapability}
          />
        )}
      </Containers.Select>
      <Containers.Action>
        <Picker.ListBox
          aria-label="Picker"
          selectionMode="single"
          disallowEmptySelection={true}
          selectedKeys={[value]}
          items={
            capability?.values?.map((item) => {
              return {
                key: item.id,
                textValue: item.title,
                context: {
                  item,
                },
              };
            }) ?? []
          }
          renderItem={renderItem}
          onSelectionChange={handleSelectionChange}
        />
      </Containers.Action>
    </Containers.Control>
  );
}

Picker.ListBox = styled(ListBox)`
  ${scrollbars.default}
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding-top: 48px;
`;

export function PickerItem(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  return (
    <PickerItem.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={props.style}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      {item.textValue}
    </PickerItem.Root>
  );
}

PickerItem.Root = styled.div`
  width: 100%;
  max-width: 312px;
  padding: 10px;
  margin: 10px auto;
  color: ${theme.color.white_o_50};
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  font-size: ${theme.fontSize.heading2};
  font-weight: ${theme.fontWeight.medium};
  text-align: center;
  cursor: pointer;

  &[data-is-selected='true'] {
    color: ${theme.color.white_o_100};
    border: 1px solid ${theme.color.white_o_100};
  }

  &[data-is-disabled='true'] {
  }

  &[data-is-focused='true'] {
  }

  &[data-is-pressed='true'] {
  }

  &[data-is-focus-visible='true'] {
    outline: 2px solid ${theme.color.highlight};
    outline-offset: 2px;
  }
`;
