import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useSelectState, Item } from 'react-stately';
import { useSelect, useButton, useFocus, mergeProps } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { Icon } from '../../../components/common/Icon';
import { Hint } from '../../../components/forms/Hint';
import { ListBoxOverlay } from '../../../components/forms/select/ListBoxOverlay';

import { iconChevronDown } from '../../../theme/icons/interface/chevron-down';

DeviceSettingsCheckboxSelect.Option = Item;

// This component is rendered as a dropdown with yes/no options
export function DeviceSettingsCheckboxSelect(props) {
  const { i18n } = useI18n();
  const options = [
    { id: true, title: i18n.messageFormatter('common.yes') },
    { id: false, title: i18n.messageFormatter('common.no') },
  ];

  const buttonRef = useRef();

  const { field } = useController({
    name: props.name,
    control: props.control,
    rules: { required: props.required },
    defaultValue: props.defaultValue,
  });

  const selectState = useSelectState({
    items: options,
    children: props.children,
    defaultSelectedKey: props.defaultValue,
    onSelectionChange(value) {
      field.onChange?.(value);
      props.onChange?.(value);
    },
  });

  const select = useSelect(
    {
      label: props.label,
      'aria-label': props['aria-label'],
    },
    selectState,
    buttonRef
  );

  const [isFocused, setIsFocused] = useState(false);
  const focus = useFocus({
    onFocusChange: setIsFocused,
    onBlur() {
      field.onBlur?.();
    },
  });
  const button = useButton(select.triggerProps, buttonRef);

  return (
    <S.Root className={props.className}>
      <S.Header>
        {props.label && (
          <S.Label {...select.labelProps} title={props.label}>
            {props.renderLabel?.(props.label) ?? props.label}
          </S.Label>
        )}

        <input type="hidden" name={props.name} value={selectState.selectedKey} />

        {props.hint && <Hint hint={props.hint} />}
      </S.Header>

      <S.Input
        {...mergeProps(button.buttonProps, focus.focusProps)}
        ref={mergeRefs([buttonRef, field.ref])}
        title={selectState.selectedItem?.textValue}
        data-is-open={selectState.isOpen}
        data-is-focused={isFocused}
        data-style-flat={props.styleFlat}
      >
        <span {...select.valueProps}>{selectState.selectedItem?.textValue}</span>
        <Icon url={iconChevronDown} color={theme.color.icon_light} size={theme.icon.size_tiny} />
      </S.Input>

      {selectState.isOpen && (
        <ListBoxOverlay
          menuProps={select.menuProps}
          selectState={selectState}
          targetRef={buttonRef}
          overlayStyle={props.overlayStyle}
          styleOption={props.styleOption}
        />
      )}
    </S.Root>
  );
}

function S() {}
DeviceSettingsCheckboxSelect.S = S;

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

S.Header = styled.div`
  display: block;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 24px;
  margin-bottom: 5px;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;

S.Label = styled.label`
  display: inline;
  min-width: 0;
  margin-bottom: 0;
`;

S.Input = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  height: 37px;
  padding: 6px 10px;
  background-color: ${theme.color.component};
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;

  ${Icon.S.Root} {
    flex: 0 0 ${theme.icon.size_tiny};
  }

  &:not([data-style-flat='true']) {
    border: 1px solid ${theme.input.border};
    border-radius: ${theme.input.border_radius};

    &[data-is-open='true'],
    &[data-is-focused='true'] {
      border: 1px solid ${theme.input.border_focus};
    }
  }

  span {
    display: inline-block;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-style-flat='true'] {
    &[data-is-open='true'],
    &[data-is-focused='true'] {
      color: ${theme.color.blue};
    }

    justify-content: flex-end;
    padding: 0;

    ${Icon.S.Root} {
      margin-left: 5px;
    }
  }
`;
