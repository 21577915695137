/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useContext, createContext } from 'react';
import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';

interface State {
  isRestarting: boolean;
}

type IsRestartingSlice = [boolean, (state: boolean) => void];

class Store {
  store = createStore<State>(() => {
    return {
      isRestarting: false,
    };
  });

  setIsRestarting = (value: boolean) => {
    this.store.setState({
      isRestarting: value,
    });
  };

  selectIsRestarting = (state: State) => {
    return [state.isRestarting, this.setIsRestarting] as IsRestartingSlice;
  };
}

export class AppSettingsStore {
  static context = createContext<Store | null>(null);

  static Provider(props: { children: React.ReactNode }) {
    const storeRef = useRef<Store | null>(null);

    if (storeRef.current == null) {
      storeRef.current = new Store();
    }

    return (
      <AppSettingsStore.context.Provider value={storeRef.current}>
        {props.children}
      </AppSettingsStore.context.Provider>
    );
  }

  static useStore() {
    const context = useContext(AppSettingsStore.context);

    if (context == null) {
      throw new Error('useState must be used within a AppSettingsStore.Provider');
    }

    return { store: context.store };
  }

  static useState<T>(selector: (state: State) => T, equalityFn?: (a: T, b: T) => boolean) {
    const context = useContext(AppSettingsStore.context);

    if (context == null) {
      throw new Error('useState must be used within a AppSettingsStore.Provider');
    }

    return useStore(context.store, selector, equalityFn);
  }

  static useIsRestarting() {
    const context = useContext(AppSettingsStore.context);

    if (context == null) {
      throw new Error('useIsRestarting must be used within a AppSettingsStore.Provider');
    }

    return useStore(context.store, context.selectIsRestarting, compareFirstIndex);
  }
}

function compareFirstIndex(a: [unknown, unknown], b: [unknown, unknown]) {
  return a[0] === b[0];
}
