import { overlayCloseReasons } from './overlayCloseReasons';

export function useOverlay(props, ref) {
  const { isKeyboardDismissDisabled = false } = props;

  // Handle the escape key
  function onKeyDown(event) {
    if (event.key === 'Escape' && !isKeyboardDismissDisabled) {
      event.preventDefault();
      // Stop the propagation so that if this overlay is nested it wont bubble to parent overlays.
      // E.g. a Select in a Dialog should not close the dialog on pressing escape in the Select
      // overlay.
      event.stopPropagation();

      // Only hide the overlay when it is the topmost visible overlay in the stack.
      if (
        props.context.stack[props.context.stack.length - 1].ref === ref &&
        props.onCloseRequest != null
      ) {
        props.onCloseRequest(overlayCloseReasons.ESCAPE);
      }
    }
  }

  function handlePointerDownUnderlay(event) {
    // Fixes a firefox issue that starts text selection.
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1675846
    if (event.target === event.currentTarget) {
      event.preventDefault();
    }
  }

  return {
    overlayProps: {
      onKeyDown,
    },
    underlayProps: {
      onPointerDown: handlePointerDownUnderlay,
    },
  };
}
