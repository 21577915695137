import { HomeyColors } from '../../theme/HomeyColors';

export class AppUtils {
  static defaultAppBackgroundColor = HomeyColors.getColorForUri('homey:manager:apps');

  static getBackgroundColor(app) {
    if (app == null) {
      return this.defaultAppBackgroundColor;
    }

    return app.brandColor ?? app.color;
  }
}
