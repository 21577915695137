export const executionStateType = {
  idle: 'idle',
  prestart: 'prestart',
  start: 'start',
  restart: 'restart',
  error: 'error',
  end: 'end',
  true: 'true',
  false: 'false',
  unreachable: 'unreachable',
};
