import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';
import { SCOPES } from '../../../lib/scopes';
import { defer } from '../../../lib/defer';

import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { HeaderIcons } from './HeaderIcons';
import { Image } from '../../../components/common/Image';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { GeneralSettingsSection } from '../system/GeneralSettingsSection';
import { Switch } from '../../../components/forms/Switch';

import { theme } from '../../../theme/theme';

import { iconSquareArrowUpRight } from '../../../theme/icons/interface/square-arrow-up-right/square-arrow-up-right';
import { iconGoogleAssistant as iconGoogleAssistantRound } from '../../../theme/icons/apps/google-assistant/google-assistant';
import { iconGoogle } from '../../../theme/icons-v2/system/google/google';

export function GoogleAssistantSettings() {
  const { i18n } = useI18n();
  const { api, scopes } = useApi();

  const instanceRef = useRef({ pendingGet: null, pendingSet: null });
  const [isSyncEnabled, setIsSyncEnabled] = useState(false);

  useEffect(() => {
    if (api != null && api.isCloud !== true) {
      if (instanceRef.current.pendingGet == null) {
        (async () => {
          try {
            instanceRef.current.pendingGet = api.googleAssistant.getOptionEnabled();
            const option = await instanceRef.current.pendingGet;
            setIsSyncEnabled(option.value);
          } catch (error) {
            ToastManager.handleError(error);
          } finally {
            instanceRef.current.pendingGet = null;
          }
        })();
      }
    }
  }, [api]);

  return (
    <S.Root>
      <S.Content>
        <HeaderIcons>
          <Image url={iconGoogleAssistantRound} size="48px" />
        </HeaderIcons>

        <h2>Google Assistant</h2>

        <p>{i18n.messageFormatter('settings.integrations.googleAssistantSubtitle')}</p>

        <GradientButton
          elementType="a"
          target="_blank"
          href="https://support.homey.app/hc/articles/360022832954"
        >
          <GradientButton.Icon
            url={iconSquareArrowUpRight}
            color={theme.color.white}
            size={theme.icon.size_small}
          />
          <GradientButton.Text>
            {i18n.messageFormatter('settings.integrations.getStartedButtonTitle')}
          </GradientButton.Text>
        </GradientButton>
      </S.Content>

      {api?.isCloud !== true && (
        <GeneralSettingsSection.S.Section>
          <GeneralSettingsSection.S.SectionView>
            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.integrations.googleAssistantSyncLabel')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                <Switch
                  aria-label="Sync"
                  name="sync"
                  isDisabled={scopes[SCOPES.SYSTEM] !== true}
                  value={isSyncEnabled}
                  onChange={(nextValue) => {
                    if (instanceRef.current.pendingSet != null) {
                      return;
                    }

                    const prevValue = isSyncEnabled;
                    setIsSyncEnabled(nextValue);

                    (async () => {
                      try {
                        const deferred = defer();
                        instanceRef.current.pendingSet = deferred.promise;

                        api.googleAssistant
                          .setOptionEnabled({
                            value: nextValue,
                          })
                          .then(deferred.resolve)
                          .catch(deferred.reject);

                        await instanceRef.current.pendingSet;
                      } catch (error) {
                        setIsSyncEnabled(prevValue);
                        ToastManager.handleError(error);
                      } finally {
                        instanceRef.current.pendingSet = null;
                      }
                    })();
                  }}
                />
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>
            <S.Hint>
              {i18n.messageFormatter('settings.integrations.googleAssistantSyncHint')}
            </S.Hint>
          </GeneralSettingsSection.S.SectionView>
        </GeneralSettingsSection.S.Section>
      )}
    </S.Root>
  );
}

GoogleAssistantSettings.pathKey = 'integrations/google-assistant';
GoogleAssistantSettings.icon = iconGoogle;
GoogleAssistantSettings.backgroundColor = theme.color.system_google;

GoogleAssistantSettings.Root = styled.div`
  margin-bottom: 20px;
  flex: 0 0 auto;
`;

function S() {}
GoogleAssistantSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 20px;
  flex: 1 0 auto;
`;

S.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(380px, 100%);

  p {
    color: ${theme.color.text_light};
    text-align: center;
    margin: 20px 0 30px;
    line-height: 1.5;
  }

  h2 {
    text-align: center;
  }

  &:not(:last-child) {
    margin-bottom: 50px;
  }
`;

S.Hint = styled.p`
  margin: 5px 0 15px;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;
