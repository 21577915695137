import { icon433mhz } from './icons-v2/system/433mhz/433mhz';
import { icon868mhz } from './icons-v2/system/868mhz/868mhz';
import { iconAlarms } from './icons-v2/system/alarms/alarms';
import { iconApps } from './icons-v2/system/apps/apps';
import { iconAudio } from './icons-v2/system/audio/audio';
import { iconBackups } from './icons-v2/system/backups/backups';
import { iconBluetooth } from './icons-v2/system/bluetooth/bluetooth';
import { iconDateTime } from './icons-v2/system/date-time/date-time';
import { iconDevices } from './icons-v2/system/devices/devices';
import { iconEnergy } from './icons-v2/system/energy/energy';
import { iconExperiments } from './icons-v2/system/experiments/experiments';
import { iconFamily } from './icons-v2/system/family/family';
import { iconFlow } from './icons-v2/system/flow/flow';
import { iconGeneral } from './icons-v2/system/general/general';
import { iconInfrared } from './icons-v2/system/infrared/infrared';
import { iconInsights } from './icons-v2/system/insights/insights';
import { iconLanguage } from './icons/system/language/language';
import { iconLedRing } from './icons-v2/system/led-ring/led-ring';
import { iconLocation } from './icons-v2/system/location/location';
import { iconLogic } from './icons-v2/system/logic/logic';
import { iconMobile } from './icons/interface/platform/mobile';
import { iconNfc } from './icons-v2/system/nfc/nfc';
import { iconPresence } from './icons-v2/system/presence/presence';
import { iconReminders } from './icons-v2/system/reminders/reminders';
import { iconSafety } from './icons-v2/system/safety/safety';
import { iconSecurity } from './icons-v2/system/security/security';
import { iconSpeech } from './icons-v2/system/speech/speech';
import { iconTag } from './icons/interface/tag';
import { iconTimelineNotifications } from './icons-v2/system/timeline-notifications/timeline-notifications';
import { iconUpdates } from './icons-v2/system/updates/updates';
import { iconWifi } from './icons-v2/system/wifi/wifi';
import { iconZigbee } from './icons-v2/system/zigbee/zigbee';
import { iconZWave } from './icons-v2/system/z-wave/z-wave';

export class HomeyImages {
  constructor() {
    throw new Error('dont make instances of this class');
  }

  static getDeviceImagePath(name: string) {
    return `/img/devices/${name}`;
  }

  static getZoneImagePath(name: string) {
    return `/img/zones/${name}`;
  }

  static getIconForDevice(device: string) {
    switch (device) {
      case '3d-printer':
        return this.getDeviceImagePath('3d-printer.svg');
      case '3d-printer2':
        return this.getDeviceImagePath('3d-printer2.svg');
      case 'air-purifier':
        return this.getDeviceImagePath('air-purifier.svg');
      case 'airfryer':
        return this.getDeviceImagePath('airfryer.svg');
      case 'aquarium':
        return this.getDeviceImagePath('aquarium.svg');
      case 'assistant-amazon-alexa':
        return this.getDeviceImagePath('assistant-amazon-alexa.svg');
      case 'assistant-google-home':
        return this.getDeviceImagePath('assistant-google-home.svg');
      case 'aux-cable':
        return this.getDeviceImagePath('aux-cable.svg');
      case 'avr':
        return this.getDeviceImagePath('avr.svg');
      case 'bike':
        return this.getDeviceImagePath('bike.svg');
      case 'blender':
        return this.getDeviceImagePath('blender.svg');
      case 'blinds':
        return this.getDeviceImagePath('blinds.svg');
      case 'camera':
        return this.getDeviceImagePath('camera.svg');
      case 'cd':
        return this.getDeviceImagePath('cd.svg');
      case 'christmas-lights':
        return this.getDeviceImagePath('christmas-lights.svg');
      case 'christmas-tree':
        return this.getDeviceImagePath('christmas-tree.svg');
      case 'climate':
        return this.getDeviceImagePath('climate.svg');
      case 'climate2':
        return this.getDeviceImagePath('climate2.svg');
      case 'climate3':
        return this.getDeviceImagePath('climate3.svg');
      case 'clock':
        return this.getDeviceImagePath('clock.svg');
      case 'clock2':
        return this.getDeviceImagePath('clock2.svg');
      case 'coffee-machine':
        return this.getDeviceImagePath('coffee-machine.svg');
      case 'coffee-machine2':
        return this.getDeviceImagePath('coffee-machine2.svg');
      case 'coffee-machine3':
        return this.getDeviceImagePath('coffee-machine3.svg');
      case 'cooking-plate':
        return this.getDeviceImagePath('cooking-plate.svg');
      case 'curtains':
        return this.getDeviceImagePath('curtains.svg');
      case 'cv':
        return this.getDeviceImagePath('cv.svg');
      case 'door':
        return this.getDeviceImagePath('door.svg');
      case 'door-window-sensor':
        return this.getDeviceImagePath('door-window-sensor.svg');
      case 'doorbell':
        return this.getDeviceImagePath('doorbell.svg');
      case 'doorbell2':
        return this.getDeviceImagePath('doorbell2.svg');
      case 'dryer':
        return this.getDeviceImagePath('dryer.svg');
      case 'dvd-bluray':
        return this.getDeviceImagePath('dvd-bluray.svg');
      case 'energy-storage':
        return this.getDeviceImagePath('energy-storage.svg');
      case 'extractor-hood':
        return this.getDeviceImagePath('extractor-hood.svg');
      case 'fan':
        return this.getDeviceImagePath('fan.svg');
      case 'fan2':
        return this.getDeviceImagePath('fan2.svg');
      case 'fish':
        return this.getDeviceImagePath('fish.svg'); // Unused
      case 'fridge':
        return this.getDeviceImagePath('fridge.svg');
      case 'game':
        return this.getDeviceImagePath('game.svg');
      case 'garage-door':
        return this.getDeviceImagePath('garage-door.svg');
      case 'generic-cube':
        return this.getDeviceImagePath('generic-cube.svg');
      case 'generic-rectangle':
        return this.getDeviceImagePath('generic-rectangle.svg');
      case 'heat-pump-boiler':
        return this.getDeviceImagePath('heat-pump-boiler.svg');
      case 'heating':
        return this.getDeviceImagePath('heating.svg');
      case 'intercom':
        return this.getDeviceImagePath('intercom.svg');
      case 'ipod':
        return this.getDeviceImagePath('ipod.svg');
      case 'kettle':
        return this.getDeviceImagePath('kettle.svg');
      case 'keys':
        return this.getDeviceImagePath('keys.svg');
      case 'laptop':
        return this.getDeviceImagePath('laptop.svg');
      case 'letterbox':
        return this.getDeviceImagePath('letterbox.svg');
      case 'letterbox2':
        return this.getDeviceImagePath('letterbox2.svg');
      case 'light-bulb':
        return this.getDeviceImagePath('light-bulb.svg');
      case 'light-bulb2':
        return this.getDeviceImagePath('light-bulb2.svg');
      case 'light-bulb3':
        return this.getDeviceImagePath('light-bulb3.svg');
      case 'light-children':
        return this.getDeviceImagePath('light-children.svg');
      case 'light-children2':
        return this.getDeviceImagePath('light-children2.svg');
      case 'light-children3':
        return this.getDeviceImagePath('light-children3.svg');
      case 'light-desk':
        return this.getDeviceImagePath('light-desk.svg');
      case 'light-hanging':
        return this.getDeviceImagePath('light-hanging.svg');
      case 'light-hanging2':
        return this.getDeviceImagePath('light-hanging2.svg');
      case 'light-hanging3':
        return this.getDeviceImagePath('light-hanging3.svg');
      case 'light-hanging4':
        return this.getDeviceImagePath('light-hanging4.svg');
      case 'light-hanging5':
        return this.getDeviceImagePath('light-hanging5.svg');
      case 'light-hanging6':
        return this.getDeviceImagePath('light-hanging6.svg');
      case 'light-hanging7':
        return this.getDeviceImagePath('light-hanging7.svg');
      case 'light-hanging8':
        return this.getDeviceImagePath('light-hanging8.svg');
      case 'light-hanging9':
        return this.getDeviceImagePath('light-hanging9.svg');
      case 'light-hanging10':
        return this.getDeviceImagePath('light-hanging10.svg');
      case 'light-hanging11':
        return this.getDeviceImagePath('light-hanging11.svg');
      case 'light-hanging12':
        return this.getDeviceImagePath('light-hanging12.svg');
      case 'light-hanging13':
        return this.getDeviceImagePath('light-hanging13.svg');
      case 'light-hanging14':
        return this.getDeviceImagePath('light-hanging14.svg');
      case 'light-hanging15':
        return this.getDeviceImagePath('light-hanging15.svg');
      case 'light-hanging16':
        return this.getDeviceImagePath('light-hanging16.svg');
      case 'light-hanging17':
        return this.getDeviceImagePath('light-hanging17.svg');
      case 'light-led-strip':
        return this.getDeviceImagePath('light-led-strip.svg');
      case 'light-outdoor':
        return this.getDeviceImagePath('light-outdoor.svg');
      case 'light-spot':
        return this.getDeviceImagePath('light-spot.svg');
      case 'light-spot2':
        return this.getDeviceImagePath('light-spot2.svg');
      case 'light-spot3':
        return this.getDeviceImagePath('light-spot3.svg');
      case 'light-standing':
        return this.getDeviceImagePath('light-standing.svg');
      case 'light-standing2':
        return this.getDeviceImagePath('light-standing2.svg');
      case 'light-standing3':
        return this.getDeviceImagePath('light-standing3.svg');
      case 'light-standing4':
        return this.getDeviceImagePath('light-standing4.svg');
      case 'light-standing5':
        return this.getDeviceImagePath('light-standing5.svg');
      case 'light-table':
        return this.getDeviceImagePath('light-table.svg');
      case 'light-table2':
        return this.getDeviceImagePath('light-table2.svg');
      case 'light-table3':
        return this.getDeviceImagePath('light-table3.svg');
      case 'light-table4':
        return this.getDeviceImagePath('light-table4.svg');
      case 'light-table5':
        return this.getDeviceImagePath('light-table5.svg');
      case 'light-table6':
        return this.getDeviceImagePath('light-table6.svg');
      case 'light-table7':
        return this.getDeviceImagePath('light-table7.svg');
      case 'light-table8':
        return this.getDeviceImagePath('light-table8.svg');
      case 'light-table9':
        return this.getDeviceImagePath('light-table9.svg');
      case 'light-table10':
        return this.getDeviceImagePath('light-table10.svg');
      case 'light-table11':
        return this.getDeviceImagePath('light-table11.svg');
      case 'light-table12':
        return this.getDeviceImagePath('light-table12.svg');
      case 'light-table13':
        return this.getDeviceImagePath('light-table13.svg');
      case 'light-table14':
        return this.getDeviceImagePath('light-table14.svg');
      case 'light-wall-mounted':
        return this.getDeviceImagePath('light-wall-mounted.svg');
      case 'light-wall-mounted2':
        return this.getDeviceImagePath('light-wall-mounted2.svg');
      case 'light-wall-mounted3':
        return this.getDeviceImagePath('light-wall-mounted3.svg');
      case 'light-wall-mounted4':
        return this.getDeviceImagePath('light-wall-mounted4.svg');
      case 'lock':
        return this.getDeviceImagePath('lock.svg');
      case 'lp':
        return this.getDeviceImagePath('lp.svg');
      case 'microwave':
        return this.getDeviceImagePath('microwave.svg');
      case 'motion-sensor':
        return this.getDeviceImagePath('motion-sensor.svg');
      case 'multimedia':
        return this.getDeviceImagePath('multimedia.svg');
      case 'network-switch':
        return this.getDeviceImagePath('network-switch.svg');
      case 'network-switch2':
        return this.getDeviceImagePath('network-switch2.svg');
      case 'oven':
        return this.getDeviceImagePath('oven.svg');
      case 'pc':
        return this.getDeviceImagePath('pc.svg');
      case 'plant1':
        return this.getDeviceImagePath('plant1.svg');
      case 'plant2':
        return this.getDeviceImagePath('plant2.svg');
      case 'plant3':
        return this.getDeviceImagePath('plant3.svg');
      case 'plant4':
        return this.getDeviceImagePath('plant4.svg');
      case 'plant5':
        return this.getDeviceImagePath('plant5.svg');
      case 'plant6':
        return this.getDeviceImagePath('plant6.svg');
      case 'plant7':
        return this.getDeviceImagePath('plant7.svg');
      case 'plant8':
        return this.getDeviceImagePath('plant8.svg');
      case 'plant9':
        return this.getDeviceImagePath('plant9.svg');
      case 'plug':
        return this.getDeviceImagePath('plug.svg');
      case 'plug2':
        return this.getDeviceImagePath('plug2.svg');
      case 'plug3':
        return this.getDeviceImagePath('plug3.svg');
      case 'plug4':
        return this.getDeviceImagePath('plug4.svg');
      case 'plug5':
        return this.getDeviceImagePath('plug5.svg');
      case 'plug6':
        return this.getDeviceImagePath('plug6.svg');
      case 'plug7':
        return this.getDeviceImagePath('plug7.svg');
      case 'plug8':
        return this.getDeviceImagePath('plug8.svg');
      case 'powerstrip':
        return this.getDeviceImagePath('powerstrip.svg');
      case 'powerstrip2':
        return this.getDeviceImagePath('powerstrip2.svg');
      case 'powerstrip3':
        return this.getDeviceImagePath('powerstrip3.svg');
      case 'powerstrip4':
        return this.getDeviceImagePath('powerstrip4.svg');
      case 'pre-amp':
        return this.getDeviceImagePath('pre-amp.svg');
      case 'printer':
        return this.getDeviceImagePath('printer.svg');
      case 'projector':
        return this.getDeviceImagePath('projector.svg');
      case 'remote-control':
        return this.getDeviceImagePath('remote-control.svg');
      case 'router':
        return this.getDeviceImagePath('router.svg');
      case 'scale':
        return this.getDeviceImagePath('scale.svg');
      case 'sedan':
        return this.getDeviceImagePath('sedan.svg');
      case 'sensor-air-quality':
        return this.getDeviceImagePath('sensor-air-quality.svg');
      case 'sensor-humidity':
        return this.getDeviceImagePath('sensor-humidity.svg');
      case 'sensor-light':
        return this.getDeviceImagePath('sensor-light.svg');
      case 'sensor-outdoor-motion':
        return this.getDeviceImagePath('sensor-outdoor-motion.svg');
      case 'sensor-temperature':
        return this.getDeviceImagePath('sensor-temperature.svg');
      case 'sensor-water-leak':
        return this.getDeviceImagePath('sensor-water-leak.svg');
      case 'set-top-box':
        return this.getDeviceImagePath('set-top-box.svg');
      case 'smart-meter':
        return this.getDeviceImagePath('smart-meter.svg');
      case 'smart-meter2':
        return this.getDeviceImagePath('smart-meter2.svg');
      case 'smart-meter3':
        return this.getDeviceImagePath('smart-meter3.svg');
      case 'smoke-detector':
        return this.getDeviceImagePath('smoke-detector.svg');
      case 'smoke-detector2':
        return this.getDeviceImagePath('smoke-detector2.svg');
      case 'socket':
        return this.getDeviceImagePath('socket.svg');
      case 'socket2':
        return this.getDeviceImagePath('socket2.svg');
      case 'socket3':
        return this.getDeviceImagePath('socket3.svg');
      case 'socket4':
        return this.getDeviceImagePath('socket4.svg');
      case 'solar-panels':
        return this.getDeviceImagePath('solar-panels.svg');
      case 'soundbar':
        return this.getDeviceImagePath('soundbar.svg');
      case 'speaker':
        return this.getDeviceImagePath('speaker.svg');
      case 'speaker-stand':
        return this.getDeviceImagePath('speaker-stand.svg');
      case 'speakerset':
        return this.getDeviceImagePath('speakerset.svg');
      case 'stairs':
        return this.getDeviceImagePath('stairs.svg');
      case 'subwoofer':
        return this.getDeviceImagePath('subwoofer.svg');
      case 'sunshade':
        return this.getDeviceImagePath('sunshade.svg');
      case 'sunshade2':
        return this.getDeviceImagePath('sunshade2.svg');
      case 'suv':
        return this.getDeviceImagePath('suv.svg');
      case 'switch':
        return this.getDeviceImagePath('switch.svg');
      case 'switch-double':
        return this.getDeviceImagePath('switch-double.svg');
      case 'switch-double2':
        return this.getDeviceImagePath('switch-double2.svg');
      case 'switch-double3':
        return this.getDeviceImagePath('switch-double3.svg');
      case 'switch-double4':
        return this.getDeviceImagePath('switch-double4.svg');
      case 'switch-double5':
        return this.getDeviceImagePath('switch-double5.svg');
      case 'switch-single':
        return this.getDeviceImagePath('switch-single.svg');
      case 'switch-single2':
        return this.getDeviceImagePath('switch-single2.svg');
      case 'switch-single3':
        return this.getDeviceImagePath('switch-single3.svg');
      case 'switch-single4':
        return this.getDeviceImagePath('switch-single4.svg');
      case 'switch-single5':
        return this.getDeviceImagePath('switch-single5.svg');
      case 'thermostat':
        return this.getDeviceImagePath('thermostat.svg');
      case 'toaster':
        return this.getDeviceImagePath('toaster.svg');
      case 'truck':
        return this.getDeviceImagePath('truck.svg');
      case 'tv':
        return this.getDeviceImagePath('tv.svg');
      case 'tv-av-combo':
        return this.getDeviceImagePath('tv-av-combo.svg');
      case 'vacuum-cleaner':
        return this.getDeviceImagePath('vacuum-cleaner.svg');
      case 'vcr':
        return this.getDeviceImagePath('vcr.svg');
      case 'ventilation':
        return this.getDeviceImagePath('ventilation.svg');
      case 'video-processor':
        return this.getDeviceImagePath('video-processor.svg');
      case 'video-processor2':
        return this.getDeviceImagePath('video-processor2.svg');
      case 'washing-machine':
        return this.getDeviceImagePath('washing-machine.svg');
      case 'washing-machine2':
        return this.getDeviceImagePath('washing-machine2.svg');
      case 'water-meter':
        return this.getDeviceImagePath('water-meter.svg');
      case 'window':
        return this.getDeviceImagePath('window.svg');
      case 'window2':
        return this.getDeviceImagePath('window2.svg');
      case 'window3':
        return this.getDeviceImagePath('window3.svg');
      default:
        return this.getDeviceImagePath('default.png');
    }
  }

  static getIconForUri(uri: string) {
    switch (uri) {
      case 'homey:manager:alarms':
        return iconAlarms;
      case 'homey:manager:apps':
        return iconApps;
      case 'homey:manager:audio':
      case 'homey:manager:media':
        return iconAudio;
      case 'homey:manager:vdevice':
        return '/img/logo192.png';
      case 'homey:manager:devices':
        return iconDevices;
      case 'homey:manager:experiments':
        return iconExperiments;
      case 'homey:manager:flow':
        return iconFlow;
      case 'homey:manager:flowtoken':
        return iconTag;
      case 'homey:manager:geolocation':
        return iconLocation;
      case 'homey:manager:insights':
        return iconInsights;
      case 'homey:manager:ledring':
        return iconLedRing;
      case 'homey:manager:logic':
        return iconLogic;
      case 'homey:manager:mobile':
        return iconMobile;
      case 'homey:manager:notifications':
        return iconTimelineNotifications;
      case 'homey:manager:presence':
        return iconPresence;
      case 'homey:manager:reminder':
        return iconReminders;
      case 'homey:manager:speech-input':
      case 'homey:manager:speech-output':
        return iconSpeech;
      case 'homey:manager:users':
        return iconFamily;
      case 'homey:manager:zigbee':
      case 'homey:wireless:zigbee':
        return iconZigbee;
      case 'homey:manager:zwave':
      case 'homey:wireless:zwave':
        return iconZWave;
      case 'homey:manager:i18n':
        return iconLanguage;
      case 'homey:manager:updates':
        return iconUpdates;
      case 'homey:manager:wifi':
        return iconWifi;
      case 'homey:wireless:433':
        return icon433mhz;
      case 'homey:wireless:868':
        return icon868mhz;
      case 'homey:manager:infrared':
      case 'homey:wireless:ir':
        return iconInfrared;
      case 'homey:manager:nfc':
      case 'homey:wireless:nfc':
        return iconNfc;
      case 'homey:wireless:ble':
        return iconBluetooth;
      case 'homey:manager:cron':
        return iconDateTime;
      case 'homey:manager:api':
        return iconGeneral;
      case 'homey:manager:safety':
        return iconSafety;
      case 'homey:manager:security':
        return iconSecurity;
      case 'homey:manager:energy':
        return iconEnergy;
      case 'homey:manager:backup':
        return iconBackups;
      case 'homey:manager:zones':
        return iconGeneral;
      case 'homey:manager:coprocessor':
      case 'homey:manager:database':
      case 'homey:manager:devkit':
      case 'homey:manager:drivers':
      case 'homey:manager:homekit':
      case 'homey:manager:images':
      case 'homey:manager:rf':
      case 'homey:manager:system':
      case 'homey:manager:weather':
      case 'homey:manager:webserver':
      default:
        return iconGeneral;
    }
  }

  static getIconForZone(name: string) {
    switch (name) {
      case 'firstFloor':
        return this.getZoneImagePath('first-floor.svg');
      case 'groundFloor':
        return this.getZoneImagePath('ground-floor.svg');
      case 'officeBuildings':
        return this.getZoneImagePath('office-buildings.svg');
      case 'singleFloor':
        return this.getZoneImagePath('single-floor.svg');
      case 'topFloor':
        return this.getZoneImagePath('top-floor.svg');

      case 'home':
        return this.getZoneImagePath('home.svg');
      // Legacy
      case 'stairs-down':
      case 'basement':
        return this.getZoneImagePath('basement.svg');
      // Legacy
      case 'stairs-up':
      case 'floor':
        return this.getZoneImagePath('floor.svg');
      // Legacy
      case 'roof':
      case 'attic':
        return this.getZoneImagePath('attic.svg');
      case 'roofSolar':
        return this.getZoneImagePath('roof_solar_panels.svg');
      case 'garage':
        return this.getZoneImagePath('garage.svg');
      case 'pool':
        return this.getZoneImagePath('pool.svg');
      case 'garden':
        return this.getZoneImagePath('garden.svg');
      case 'gardenShed':
        return this.getZoneImagePath('garden_shed.svg');
      case 'terrace':
        return this.getZoneImagePath('terrace.svg');
      case 'carport':
        return this.getZoneImagePath('carport.svg');
      case 'hallway':
        return this.getZoneImagePath('hallway.svg');
      // Legacy
      case 'living':
      case 'livingRoom':
        return this.getZoneImagePath('living_room.svg');
      case 'kitchen':
        return this.getZoneImagePath('kitchen.svg');
      case 'diningRoom':
        return this.getZoneImagePath('dining_room.svg');
      case 'bedroomSingle':
        return this.getZoneImagePath('bedroom.svg');
      // Legacy
      case 'bed':
      case 'bedroomDouble':
        return this.getZoneImagePath('master_bedroom.svg');
      case 'bedroomKids':
        return this.getZoneImagePath('kinder_room.svg');
      // Legacy
      case 'shower':
      case 'bathroom':
        return this.getZoneImagePath('bathroom.svg');
      case 'office':
        return this.getZoneImagePath('office.svg');
      case 'gym':
        return this.getZoneImagePath('gym.svg');
      case 'toilet':
        return this.getZoneImagePath('toilet.svg');
      case 'entrance':
        return this.getZoneImagePath('entrance_front_door.svg');
      // Legacy
      case 'books':
      case 'studyRoom':
        return this.getZoneImagePath('study_room.svg');
      case 'gameRoom':
        return this.getZoneImagePath('game_room.svg');
      case 'utilitiesRoom':
        return this.getZoneImagePath('utilities_room.svg');
      case 'recreationRoom':
        return this.getZoneImagePath('recreation_room.svg');
      case 'tipi':
        return this.getZoneImagePath('tipi_tent.svg');
      case 'wardrobe':
        return this.getZoneImagePath('wardrobe.svg');
      case 'doorClosed':
        return this.getZoneImagePath('door-closed.svg');
      case 'lounge':
        return this.getZoneImagePath('lounge-chair.svg');
      case 'fuseBox':
        return this.getZoneImagePath('lightning-bolt.svg');
      case 'laundryRoom':
        return this.getZoneImagePath('laundry-basket.svg');
      case 'phone':
        return this.getZoneImagePath('phone.svg');
      case 'coffeeMachine':
        return this.getZoneImagePath('coffee-machine.svg');
      case 'drinks':
        return this.getZoneImagePath('drinks.svg');
      case 'pantry':
        return this.getZoneImagePath('pantry.svg');
      case 'suitcase':
        return this.getZoneImagePath('suitcase.svg');
      case 'door':
        return this.getZoneImagePath('door.svg');
      case 'toothbrush':
        return this.getZoneImagePath('toothbrush.svg');
      case 'sink':
        return this.getZoneImagePath('sink.svg');
      case 'default':
      default:
        return this.getZoneImagePath('hallway_door.svg');
    }
  }
}

export const deviceIconsByCategory = [
  {
    id: 'lights',
    label: 'Lights',
    icons: [
      { id: 'light-bulb', url: HomeyImages.getIconForDevice('light-bulb') },
      { id: 'light-bulb2', url: HomeyImages.getIconForDevice('light-bulb2') },
      { id: 'light-bulb3', url: HomeyImages.getIconForDevice('light-bulb3') },
      { id: 'light-spot', url: HomeyImages.getIconForDevice('light-spot') },
      { id: 'light-led-strip', url: HomeyImages.getIconForDevice('light-led-strip') },

      { id: 'light-hanging', url: HomeyImages.getIconForDevice('light-hanging') },
      { id: 'light-hanging3', url: HomeyImages.getIconForDevice('light-hanging3') },
      { id: 'light-hanging11', url: HomeyImages.getIconForDevice('light-hanging11') },
      { id: 'light-hanging6', url: HomeyImages.getIconForDevice('light-hanging6') },
      { id: 'light-hanging7', url: HomeyImages.getIconForDevice('light-hanging7') },

      { id: 'light-hanging8', url: HomeyImages.getIconForDevice('light-hanging8') },
      { id: 'light-hanging9', url: HomeyImages.getIconForDevice('light-hanging9') },
      { id: 'light-hanging10', url: HomeyImages.getIconForDevice('light-hanging10') },
      { id: 'light-hanging5', url: HomeyImages.getIconForDevice('light-hanging5') },
      { id: 'light-hanging14', url: HomeyImages.getIconForDevice('light-hanging14') },

      { id: 'light-hanging16', url: HomeyImages.getIconForDevice('light-hanging16') },
      { id: 'light-hanging17', url: HomeyImages.getIconForDevice('light-hanging17') },
      { id: 'light-hanging2', url: HomeyImages.getIconForDevice('light-hanging2') },
      { id: 'light-hanging12', url: HomeyImages.getIconForDevice('light-hanging12') },
      { id: 'light-hanging4', url: HomeyImages.getIconForDevice('light-hanging4') },

      { id: 'light-hanging13', url: HomeyImages.getIconForDevice('light-hanging13') },
      { id: 'light-spot3', url: HomeyImages.getIconForDevice('light-spot3') },
      { id: 'light-spot2', url: HomeyImages.getIconForDevice('light-spot2') },
      { id: 'light-wall-mounted4', url: HomeyImages.getIconForDevice('light-wall-mounted4') },
      { id: 'light-hanging15', url: HomeyImages.getIconForDevice('light-hanging15') },

      { id: 'light-standing2', url: HomeyImages.getIconForDevice('light-standing2') },
      { id: 'light-standing4', url: HomeyImages.getIconForDevice('light-standing4') },
      { id: 'light-standing', url: HomeyImages.getIconForDevice('light-standing') },
      { id: 'light-standing3', url: HomeyImages.getIconForDevice('light-standing3') },
      { id: 'light-standing5', url: HomeyImages.getIconForDevice('light-standing5') },

      { id: 'light-table8', url: HomeyImages.getIconForDevice('light-table8') },
      { id: 'light-table10', url: HomeyImages.getIconForDevice('light-table10') },
      { id: 'light-table2', url: HomeyImages.getIconForDevice('light-table2') },
      { id: 'light-table3', url: HomeyImages.getIconForDevice('light-table3') },
      { id: 'light-table5', url: HomeyImages.getIconForDevice('light-table5') },

      { id: 'light-table', url: HomeyImages.getIconForDevice('light-table') },
      { id: 'light-table4', url: HomeyImages.getIconForDevice('light-table4') },
      { id: 'light-desk', url: HomeyImages.getIconForDevice('light-desk') },
      { id: 'light-table13', url: HomeyImages.getIconForDevice('light-table13') },
      { id: 'light-table14', url: HomeyImages.getIconForDevice('light-table14') },

      { id: 'light-table6', url: HomeyImages.getIconForDevice('light-table6') },
      { id: 'light-table7', url: HomeyImages.getIconForDevice('light-table7') },
      { id: 'light-table9', url: HomeyImages.getIconForDevice('light-table9') },
      { id: 'light-table11', url: HomeyImages.getIconForDevice('light-table11') },
      { id: 'light-table12', url: HomeyImages.getIconForDevice('light-table12') },

      { id: 'light-children2', url: HomeyImages.getIconForDevice('light-children2') },
      { id: 'light-children', url: HomeyImages.getIconForDevice('light-children') },
      { id: 'light-children3', url: HomeyImages.getIconForDevice('light-children3') },
      { id: 'light-wall-mounted2', url: HomeyImages.getIconForDevice('light-wall-mounted2') },
      { id: 'christmas-lights', url: HomeyImages.getIconForDevice('christmas-lights') },

      { id: 'light-wall-mounted', url: HomeyImages.getIconForDevice('light-wall-mounted') },
      { id: 'light-wall-mounted3', url: HomeyImages.getIconForDevice('light-wall-mounted3') },
      { id: 'light-outdoor', url: HomeyImages.getIconForDevice('light-outdoor') },
    ],
  },
  {
    id: 'coverings',
    label: 'Coverings',
    icons: [
      { id: 'window', url: HomeyImages.getIconForDevice('window') },
      { id: 'window2', url: HomeyImages.getIconForDevice('window2') },
      { id: 'window3', url: HomeyImages.getIconForDevice('window3') },
      { id: 'blinds', url: HomeyImages.getIconForDevice('blinds') },
      { id: 'sunshade', url: HomeyImages.getIconForDevice('sunshade') },

      { id: 'sunshade2', url: HomeyImages.getIconForDevice('sunshade2') },
      { id: 'curtains', url: HomeyImages.getIconForDevice('curtains') },
      { id: 'door', url: HomeyImages.getIconForDevice('door') },
      { id: 'garage-door', url: HomeyImages.getIconForDevice('garage-door') },
    ],
  },
  {
    id: 'security',
    label: 'Security',
    icons: [
      { id: 'camera', url: HomeyImages.getIconForDevice('camera') },
      { id: 'doorbell', url: HomeyImages.getIconForDevice('doorbell') },
      { id: 'doorbell2', url: HomeyImages.getIconForDevice('doorbell2') },
      { id: 'lock', url: HomeyImages.getIconForDevice('lock') },
      { id: 'keys', url: HomeyImages.getIconForDevice('keys') },

      { id: 'smoke-detector', url: HomeyImages.getIconForDevice('smoke-detector') },
      { id: 'motion-sensor', url: HomeyImages.getIconForDevice('motion-sensor') },
      { id: 'sensor-outdoor-motion', url: HomeyImages.getIconForDevice('sensor-outdoor-motion') },
      { id: 'door-window-sensor', url: HomeyImages.getIconForDevice('door-window-sensor') },
      { id: 'intercom', url: HomeyImages.getIconForDevice('intercom') },
    ],
  },
  {
    id: 'appliances',
    label: 'Appliances',
    icons: [
      { id: 'coffee-machine', url: HomeyImages.getIconForDevice('coffee-machine') },
      { id: 'coffee-machine2', url: HomeyImages.getIconForDevice('coffee-machine2') },
      { id: 'coffee-machine3', url: HomeyImages.getIconForDevice('coffee-machine3') },
      { id: 'kettle', url: HomeyImages.getIconForDevice('kettle') },
      { id: 'airfryer', url: HomeyImages.getIconForDevice('airfryer') },
      { id: 'microwave', url: HomeyImages.getIconForDevice('microwave') },

      { id: 'blender', url: HomeyImages.getIconForDevice('blender') },
      { id: 'toaster', url: HomeyImages.getIconForDevice('toaster') },
      { id: 'cooking-plate', url: HomeyImages.getIconForDevice('cooking-plate') },
      { id: 'extractor-hood', url: HomeyImages.getIconForDevice('extractor-hood') },
      { id: 'oven', url: HomeyImages.getIconForDevice('oven') },

      { id: 'fridge', url: HomeyImages.getIconForDevice('fridge') },
      { id: 'washing-machine', url: HomeyImages.getIconForDevice('washing-machine') },
      { id: 'washing-machine2', url: HomeyImages.getIconForDevice('washing-machine2') },
      { id: 'dryer', url: HomeyImages.getIconForDevice('dryer') },
      { id: 'scale', url: HomeyImages.getIconForDevice('scale') },

      { id: 'vacuum-cleaner', url: HomeyImages.getIconForDevice('vacuum-cleaner') },
    ],
  },
  {
    id: 'climate',
    label: 'Climate',
    icons: [
      { id: 'climate2', url: HomeyImages.getIconForDevice('climate2') },
      { id: 'climate', url: HomeyImages.getIconForDevice('climate') },
      { id: 'climate3', url: HomeyImages.getIconForDevice('climate3') },
      { id: 'fan', url: HomeyImages.getIconForDevice('fan') },
      { id: 'fan2', url: HomeyImages.getIconForDevice('fan2') },

      { id: 'thermostat', url: HomeyImages.getIconForDevice('thermostat') },
      { id: 'heating', url: HomeyImages.getIconForDevice('heating') },
      { id: 'cv', url: HomeyImages.getIconForDevice('cv') },
      { id: 'ventilation', url: HomeyImages.getIconForDevice('ventilation') },
      { id: 'energy-storage', url: HomeyImages.getIconForDevice('energy-storage') },

      { id: 'heat-pump-boiler', url: HomeyImages.getIconForDevice('heat-pump-boiler') },
      { id: 'air-purifier', url: HomeyImages.getIconForDevice('air-purifier') },
    ],
  },
  {
    id: 'switching',
    label: 'Switching',
    icons: [
      { id: 'switch-single', url: HomeyImages.getIconForDevice('switch-single') },
      { id: 'switch-double', url: HomeyImages.getIconForDevice('switch-double') },
      { id: 'plug', url: HomeyImages.getIconForDevice('plug') },
      { id: 'plug2', url: HomeyImages.getIconForDevice('plug2') },
      { id: 'socket', url: HomeyImages.getIconForDevice('socket') },

      { id: 'switch-single2', url: HomeyImages.getIconForDevice('switch-single2') },
      { id: 'switch-double3', url: HomeyImages.getIconForDevice('switch-double3') },
      { id: 'plug5', url: HomeyImages.getIconForDevice('plug5') },
      { id: 'plug6', url: HomeyImages.getIconForDevice('plug6') },
      { id: 'socket3', url: HomeyImages.getIconForDevice('socket3') },

      { id: 'switch-single4', url: HomeyImages.getIconForDevice('switch-single4') },
      { id: 'switch-double4', url: HomeyImages.getIconForDevice('switch-double4') },
      { id: 'plug7', url: HomeyImages.getIconForDevice('plug7') },
      { id: 'plug8', url: HomeyImages.getIconForDevice('plug8') },
      { id: 'socket4', url: HomeyImages.getIconForDevice('socket4') },

      { id: 'switch-single5', url: HomeyImages.getIconForDevice('switch-single5') },
      { id: 'switch-double5', url: HomeyImages.getIconForDevice('switch-double5') },
      { id: 'powerstrip', url: HomeyImages.getIconForDevice('powerstrip') },
      { id: 'powerstrip2', url: HomeyImages.getIconForDevice('powerstrip2') },
      { id: 'powerstrip3', url: HomeyImages.getIconForDevice('powerstrip3') },

      { id: 'powerstrip4', url: HomeyImages.getIconForDevice('powerstrip4') },
    ],
  },
  {
    id: 'audio',
    label: 'Audio',
    icons: [
      { id: 'multimedia', url: HomeyImages.getIconForDevice('multimedia') },
      { id: 'speakerset', url: HomeyImages.getIconForDevice('speakerset') },
      { id: 'soundbar', url: HomeyImages.getIconForDevice('soundbar') },
      { id: 'speaker', url: HomeyImages.getIconForDevice('speaker') },
      { id: 'subwoofer', url: HomeyImages.getIconForDevice('subwoofer') },

      { id: 'speaker-stand', url: HomeyImages.getIconForDevice('speaker-stand') },
      { id: 'pre-amp', url: HomeyImages.getIconForDevice('pre-amp') },
      { id: 'avr', url: HomeyImages.getIconForDevice('avr') },
      { id: 'video-processor2', url: HomeyImages.getIconForDevice('video-processor2') },
      { id: 'aux-cable', url: HomeyImages.getIconForDevice('aux-cable') },

      { id: 'cd', url: HomeyImages.getIconForDevice('cd') },
      { id: 'lp', url: HomeyImages.getIconForDevice('lp') },
    ],
  },
  {
    id: 'multimedia',
    label: 'Multimedia',
    icons: [
      { id: 'remote-control', url: HomeyImages.getIconForDevice('remote-control') },
      { id: 'tv', url: HomeyImages.getIconForDevice('tv') },
      { id: 'tv-av-combo', url: HomeyImages.getIconForDevice('tv-av-combo') },
      { id: 'pc', url: HomeyImages.getIconForDevice('pc') },
      { id: 'laptop', url: HomeyImages.getIconForDevice('laptop') },

      { id: 'projector', url: HomeyImages.getIconForDevice('projector') },
      { id: 'set-top-box', url: HomeyImages.getIconForDevice('set-top-box') },
      { id: 'vcr', url: HomeyImages.getIconForDevice('vcr') },
      { id: 'dvd-bluray', url: HomeyImages.getIconForDevice('dvd-bluray') },
      { id: 'video-processor', url: HomeyImages.getIconForDevice('video-processor') },

      { id: 'network-switch', url: HomeyImages.getIconForDevice('network-switch') },
      { id: 'network-switch2', url: HomeyImages.getIconForDevice('network-switch2') },
    ],
  },
  {
    id: 'household',
    label: 'Household',
    icons: [
      { id: 'solar-panels', url: HomeyImages.getIconForDevice('solar-panels') },
      { id: 'smart-meter', url: HomeyImages.getIconForDevice('smart-meter') },
      { id: 'smart-meter2', url: HomeyImages.getIconForDevice('smart-meter2') },
      { id: 'smart-meter3', url: HomeyImages.getIconForDevice('smart-meter3') },
      { id: 'water-meter', url: HomeyImages.getIconForDevice('water-meter') },

      { id: 'switch', url: HomeyImages.getIconForDevice('switch') },
      { id: 'router', url: HomeyImages.getIconForDevice('router') },
      { id: 'printer', url: HomeyImages.getIconForDevice('printer') },
      { id: 'assistant-amazon-alexa', url: HomeyImages.getIconForDevice('assistant-amazon-alexa') },
      { id: 'assistant-google-home', url: HomeyImages.getIconForDevice('assistant-google-home') },
    ],
  },
  {
    id: 'various',
    label: 'Various',
    icons: [
      { id: 'game', url: HomeyImages.getIconForDevice('game') },
      { id: 'ipod', url: HomeyImages.getIconForDevice('ipod') },
      { id: '3d-printer', url: HomeyImages.getIconForDevice('3d-printer') },
      { id: '3d-printer2', url: HomeyImages.getIconForDevice('3d-printer2') },
      { id: 'aquarium', url: HomeyImages.getIconForDevice('aquarium') },

      { id: 'plant1', url: HomeyImages.getIconForDevice('plant1') },
      { id: 'plant2', url: HomeyImages.getIconForDevice('plant2') },
      { id: 'plant3', url: HomeyImages.getIconForDevice('plant3') },
      { id: 'plant4', url: HomeyImages.getIconForDevice('plant4') },
      { id: 'plant5', url: HomeyImages.getIconForDevice('plant5') },

      { id: 'plant6', url: HomeyImages.getIconForDevice('plant6') },
      { id: 'plant7', url: HomeyImages.getIconForDevice('plant7') },
      { id: 'plant8', url: HomeyImages.getIconForDevice('plant8') },
      { id: 'plant9', url: HomeyImages.getIconForDevice('plant9') },
      { id: 'stairs', url: HomeyImages.getIconForDevice('stairs') },

      { id: 'generic-cube', url: HomeyImages.getIconForDevice('generic-cube') },
      { id: 'generic-rectangle', url: HomeyImages.getIconForDevice('generic-rectangle') },
      { id: 'letterbox', url: HomeyImages.getIconForDevice('letterbox') },
      { id: 'letterbox2', url: HomeyImages.getIconForDevice('letterbox2') },
      { id: 'christmas-tree', url: HomeyImages.getIconForDevice('christmas-tree') },

      { id: 'sensor-temperature', url: HomeyImages.getIconForDevice('sensor-temperature') },
      { id: 'sensor-humidity', url: HomeyImages.getIconForDevice('sensor-humidity') },
      { id: 'sensor-light', url: HomeyImages.getIconForDevice('sensor-light') },
      { id: 'sensor-air-quality', url: HomeyImages.getIconForDevice('sensor-air-quality') },
      { id: 'sensor-water-leak', url: HomeyImages.getIconForDevice('sensor-water-leak') },

      { id: 'clock', url: HomeyImages.getIconForDevice('clock') },
      { id: 'clock2', url: HomeyImages.getIconForDevice('clock2') },
      { id: 'sedan', url: HomeyImages.getIconForDevice('sedan') },
      { id: 'suv', url: HomeyImages.getIconForDevice('suv') },
      { id: 'truck', url: HomeyImages.getIconForDevice('truck') },

      { id: 'bike', url: HomeyImages.getIconForDevice('bike') },
    ],
  },
] as const;

export const zoneIcons = [
  { id: 'firstFloor', url: HomeyImages.getIconForZone('firstFloor') },
  { id: 'groundFloor', url: HomeyImages.getIconForZone('groundFloor') },
  { id: 'officeBuildings', url: HomeyImages.getIconForZone('officeBuildings') },
  { id: 'singleFloor', url: HomeyImages.getIconForZone('singleFloor') },
  { id: 'topFloor', url: HomeyImages.getIconForZone('topFloor') },

  { id: 'default', url: HomeyImages.getIconForZone('default') },
  { id: 'home', url: HomeyImages.getIconForZone('home') },
  { id: 'floor', url: HomeyImages.getIconForZone('floor') },
  { id: 'basement', url: HomeyImages.getIconForZone('basement') },
  { id: 'attic', url: HomeyImages.getIconForZone('attic') },
  { id: 'roofSolar', url: HomeyImages.getIconForZone('roofSolar') },
  { id: 'garage', url: HomeyImages.getIconForZone('garage') },
  { id: 'pool', url: HomeyImages.getIconForZone('pool') },
  { id: 'garden', url: HomeyImages.getIconForZone('garden') },
  { id: 'gardenShed', url: HomeyImages.getIconForZone('gardenShed') },

  { id: 'terrace', url: HomeyImages.getIconForZone('terrace') },
  { id: 'carport', url: HomeyImages.getIconForZone('carport') },
  { id: 'hallway', url: HomeyImages.getIconForZone('hallway') },
  { id: 'livingRoom', url: HomeyImages.getIconForZone('livingRoom') },
  { id: 'kitchen', url: HomeyImages.getIconForZone('kitchen') },
  { id: 'diningRoom', url: HomeyImages.getIconForZone('diningRoom') },
  { id: 'bedroomSingle', url: HomeyImages.getIconForZone('bedroomSingle') },
  { id: 'bedroomDouble', url: HomeyImages.getIconForZone('bedroomDouble') },
  { id: 'bedroomKids', url: HomeyImages.getIconForZone('bedroomKids') },
  { id: 'bathroom', url: HomeyImages.getIconForZone('bathroom') },

  { id: 'wardrobe', url: HomeyImages.getIconForZone('wardrobe') },
  { id: 'office', url: HomeyImages.getIconForZone('office') },
  { id: 'gym', url: HomeyImages.getIconForZone('gym') },
  { id: 'toilet', url: HomeyImages.getIconForZone('toilet') },
  { id: 'entrance', url: HomeyImages.getIconForZone('entrance') },
  { id: 'studyRoom', url: HomeyImages.getIconForZone('studyRoom') },
  { id: 'gameRoom', url: HomeyImages.getIconForZone('gameRoom') },
  { id: 'utilitiesRoom', url: HomeyImages.getIconForZone('utilitiesRoom') },
  { id: 'recreationRoom', url: HomeyImages.getIconForZone('recreationRoom') },
  { id: 'tipi', url: HomeyImages.getIconForZone('tipi') },

  { id: 'doorClosed', url: HomeyImages.getIconForZone('doorClosed') },
  { id: 'lounge', url: HomeyImages.getIconForZone('lounge') },
  { id: 'fuseBox', url: HomeyImages.getIconForZone('fuseBox') },
  { id: 'laundryRoom', url: HomeyImages.getIconForZone('laundryRoom') },
  { id: 'phone', url: HomeyImages.getIconForZone('phone') },
  { id: 'coffeeMachine', url: HomeyImages.getIconForZone('coffeeMachine') },
  { id: 'drinks', url: HomeyImages.getIconForZone('drinks') },
  { id: 'pantry', url: HomeyImages.getIconForZone('pantry') },
  { id: 'suitcase', url: HomeyImages.getIconForZone('suitcase') },
  { id: 'door', url: HomeyImages.getIconForZone('door') },

  { id: 'toothbrush', url: HomeyImages.getIconForZone('toothbrush') },
  { id: 'sink', url: HomeyImages.getIconForZone('sink') },
] as const;
