import React, { useRef, forwardRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useButton, useFocusRing, mergeProps, AriaButtonProps } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { getIconScaleHover } from '../../theme/functions/getIconScale';
import { theme } from '../../theme/theme';

import { ButtonIcon } from './ButtonIcon';

interface Props extends AriaButtonProps<'button'> {
  className?: string;
  title?: string;
  color?: string;
  hoverColor?: string;
  url?: string;
  size?: string;
  style?: any;
  keyedAutoFocus?: string;
}

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLButtonElement>> {
  S: typeof S;
  Icon: typeof ButtonIcon;
}

export const IconButton = forwardRef((props, forwardedRef) => {
  const ref = useRef<HTMLButtonElement>(null);
  const button = useButton(props, ref);
  const focusRing = useFocusRing({});

  useEffect(() => {
    if (props.keyedAutoFocus != null) {
      ref.current?.focus();
    }
  }, [props.keyedAutoFocus]);

  return (
    <IconButton.S.Root
      {...mergeProps(focusRing.focusProps, button.buttonProps)}
      ref={mergeRefs([ref, forwardedRef])}
      style={{
        ...props.style,
        '--icon-scale-pressed': button.isPressed ? 1 : 'unset',
        '--icon-scale-hover': getIconScaleHover(props.size ?? theme.icon.size_default),
        '--icon-color': props.color,
        '--icon-hover-color': props.hoverColor,
      }}
      className={props.className}
      title={props.title}
    >
      <IconButton.Icon color={props.color} url={props.url} size={props.size} />
    </IconButton.S.Root>
  );
}) as CompoundedComponent;

IconButton.Icon = ButtonIcon;

function S() {}
IconButton.S = S;

S.Root = styled.button`
  ${IconButton.Icon.S.Root} {
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: transform, background-color;
  }

  &:hover {
    ${IconButton.Icon.S.Root} {
      background-color: var(--icon-hover-color, var(--icon-color));
      transform: scale(var(--icon-scale-pressed, var(--icon-scale-hover)));
    }
  }
`;
