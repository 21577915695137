import { useMemo } from 'react';
import { useCollator, useLocale } from 'react-aria';
import HomeyLib from 'homey-lib/webpack/index.js';

const deviceClasses = HomeyLib.Device.getClasses();

export function useDriverClassList({ uriDrivers, searchValue }) {
  const collator = useCollator();
  const { locale } = useLocale();

  return useMemo(() => {
    if (uriDrivers != null) {
      const entries = Object.entries(uriDrivers.data ?? {});

      const byClass = entries.reduce((accumulator, [uri, driver]) => {
        if (
          driver.deprecated === true ||
          driver.pair === false ||
          driver.name.toLowerCase().includes(searchValue) === false
        )
          return accumulator;

        const driverClass = entries.length >= 10 ? driver.class : '__none__';

        accumulator[driverClass] = accumulator[driverClass] ?? [];
        accumulator[driverClass].push(driver);

        return accumulator;
      }, {});

      return Object.entries(byClass)
        .map(([classId, drivers]) => {
          return {
            classId: classId,
            classTitle: deviceClasses?.[classId]?.title?.[locale] ?? classId,
            drivers: drivers.sort((firstEl, secondEl) => {
              return collator.compare(firstEl.name, secondEl.name);
            }),
          };
        })
        .sort((firstEl, secondEl) => {
          return collator.compare(firstEl.classTitle, secondEl.classTitle);
        });
    }

    return [];
  }, [collator, locale, uriDrivers, searchValue]);
}
