import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { DialogShiftLock } from '../../../components/overlay/DialogShiftLock';
import { IconButton } from '../../../components/buttons/IconButton';
import { Clipboard } from '../../../components/common/Clipboard';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { Icon } from '../../../components/common/Icon';

import { ApiKeysSettingsForm } from './ApiKeysSettingsForm';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconApiKeys } from '../../../theme/icons/system/api-keys/api-keys';

export function ApiKeysSettingsDialogView(props) {
  const params = useParams();
  const pat = props.pats?.[params.patId] ?? null;

  function handleClosePress() {
    props.onCloseRequest();
  }

  return (
    <DialogShiftLock onClose={props.onCloseRequest}>
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color="#452FB2">
          <Icon size="32px" url={iconApiKeys} color={theme.color.white} />
        </RoundIconWrapper>

        <S.Title>{pat.name}</S.Title>

        <ApiKeysSettingsForm pat={pat} isReadOnly={true} isNameHidden={true} />
      </S.Root>
    </DialogShiftLock>
  );
}

function S() {}
ApiKeysSettingsDialogView.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 560px;
  height: min(100vh - 40px, 760px);
  padding: ${su(4)} 0 ${su(2)};

  &[data-is-small-view='true'] {
    width: 440px;
    height: auto;
  }

  ${Clipboard.S.Root} {
    width: 100%;
  }
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Title = styled.h3`
  padding: ${su(3)} 0 ${su(1)};
`;
