import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { mergeProps, useTextField } from 'react-aria';

import { stopPressPropagation } from '../../lib/dom/stopPressPropagation';
import { mergeRefs } from '../../lib/mergeRefs';

import { useI18n } from '../../hooks/useI18nFormatters';

import { theme } from '../../theme/theme';
import { input } from '../../theme/Global';

import { Hint } from './Hint';

export function TextField(props) {
  const { i18n } = useI18n();
  const step = props.type === 'number' ? props.step ?? 'any' : undefined;

  const { ref: registerRef, ...registerProps } =
    props.register?.(props.name, {
      required: props.required,
      minLength: props.minLength,
      pattern: props.pattern ? new RegExp(props.pattern) : undefined,
      min: props.min,
      max: props.max,
    }) ?? {};

  const { autoFocus, ...rest } = props;
  const textFieldRef = useRef();
  const textField = useTextField(
    {
      inputMode: 'text',
      // These handlers are just so handlers are applied and events never bubble. stopPropagation is the default
      // for react-aria but it only called if there is a handler. We need this because we might have global
      // shortcuts that lisener to a backspace key for example. These obviously need to be handled here if
      // the input is focused.
      onKeyDown(event) {
        props.onKeyDown?.(event);
      },
      onKeyUp(event) {
        props.onKeyUp?.(event);
      },
      ...rest,
    },
    textFieldRef
  );

  const Root = props.orientation === 'vertical' ? TextField.RootVertical : TextField.RootHorizontal;

  const hint = getHint({
    hint: props.hint,
    min: props.min,
    max: props.max,
    step: step,
    messageFormatter: i18n.messageFormatter,
  });

  useEffect(() => {
    if (autoFocus === true) {
      setTimeout(() => {
        textFieldRef.current.focus();
      }, 0);
    }
  }, [autoFocus]);

  return (
    // the Root needs to be rendered for emotion to tag the root from a parent
    // this is why the as prop is used instead of rendering RootVertical || RootHorizontal
    <TextField.Root as={Root} className={props.className}>
      <TextField.Header>
        {props.label != null && (
          <TextField.Label {...textField.labelProps} title={props.label}>
            {props.renderLabel?.(props.label) ?? props.label}
          </TextField.Label>
        )}

        {hint && <Hint hint={hint} />}
      </TextField.Header>

      <TextField.Input
        {...mergeProps(stopPressPropagation, textField.inputProps, registerProps)}
        ref={mergeRefs([textFieldRef, registerRef, props.inputRef])}
        min={props.min}
        max={props.max}
        step={step}
        data-has-error={props.error != null}
      />
    </TextField.Root>
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  minLength: PropTypes.number,
  pattern: PropTypes.string,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.any,
  error: PropTypes.object,
  register: PropTypes.func,
  orientation: PropTypes.string,
};

TextField.Root = styled.div`
  display: flex;
`;

TextField.Header = styled.div`
  display: block;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;

TextField.Label = styled.label`
  display: inline;
  min-width: 0;
  margin-bottom: 0;
`;

TextField.Input = styled.input`
  ${input};
  position: relative;
  display: inline-block;
  padding: 8px;
  outline: 0;
`;

TextField.RootHorizontal = styled(TextField.Root)`
  align-items: center;
  justify-content: space-between;

  ${TextField.Header} {
    flex: 0 1 50%;
    font-weight: ${theme.fontWeight.regular};
  }

  ${TextField.Input} {
    flex: 0 0 50%;
  }
`;

TextField.RootVertical = styled(TextField.Root)`
  flex-direction: column;
  align-items: flex-start;

  ${TextField.Header} {
    margin-bottom: 5px;
    color: ${theme.color.text_light};
    font-size: ${theme.fontSize.small};
    line-height: 24px;
  }

  ${TextField.Input} {
    width: 100%;
  }
`;

function getHint({ hint, min, max, step, messageFormatter }) {
  let extendedHint = hint;

  const messages = [];
  if (min != null) {
    messages.push(messageFormatter('device.settings.min', { value: min }));
  }

  if (max != null) {
    messages.push(messageFormatter('device.settings.max', { value: max }));
  }

  if (step != null && step !== 'any') {
    messages.push(messageFormatter('device.settings.step', { value: step }));
  }

  extendedHint =
    messages.length > 0 ? `${extendedHint ?? ''} \n${mapMessages(messages)}` : extendedHint;

  return extendedHint;
}

function mapMessages(messages) {
  return messages
    .map((message, index, arr) => {
      let result = message;

      if (index === 0) {
        result = result[0].toUpperCase() + result.slice(1);
      }

      if (index === arr.length - 1) {
        result += '.';
      }

      return result;
    })
    .join(', ');
}
