import React, { memo, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useButton, usePress } from 'react-aria';
import { useHover } from '../../../../hooks/useHover';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';
import { useConnectionLink } from './useConnectionLink';

import { theme } from '../../../../theme/theme';

import { connectorTypeColorMap } from '../connectors/connectorTypes';
import { executionStateType } from '../card/executionStateType';
import { keyframes } from '@emotion/react';

export const MemoConnection = memo(Connection);

export function Connection(props) {
  const basePathRef = useRef();
  const animationPathRef = useRef();
  const hitAreaButtonRef = useRef();

  const advancedFlowViewContext = useAdvancedFlowViewContext();

  const [isFocused, setIsFocused] = useState(false);

  const [executionState, setExecutionState] = useState({
    type: executionStateType.idle,
  });

  const isUnreachableNode = executionState.type === executionStateType.unreachable;
  const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  // const isTestingRoot = isTesting && props.testNodeId === props.nodeId;
  const isSaving = props.conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isDisabled = isTesting || isUnreachableNode || isSaving || props.isInteractionDisabled;
  // eslint-disable-next-line no-unused-vars
  const isDisabledStyle = isUnreachableNode ? 'unreachable' : 'readonly';

  const hitAreaHover = useHover({
    isDisabled: isDisabled,
    onHoverStart: () => {
      props.setHoveredConnectionKey(props.connection.key);
    },
    onHoverEnd: () => {
      props.setHoveredConnectionKey(null);
    },
  });

  // Because the order changes we can now get stuck in a tab loop.
  // https://react-spectrum.adobe.com/react-aria/FocusScope.html#usefocusmanager-example
  const hitAreaButton = useButton(
    {
      elementType: 'g',
      excludeFromTabOrder: true,
      isDisabled: isDisabled,
      onKeyDown: (event) => {
        switch (event.key) {
          case 'Backspace':
          case 'Delete':
          case 'Escape':
            break;
          default:
            event.continuePropagation();
            break;
        }
      },
      onKeyUp: (event) => {
        switch (event.key) {
          case 'Backspace':
          case 'Delete':
            AdvancedFlowViewStore.deleteConnection({ connection: props.connection });
            break;
          case 'Escape':
            hitAreaButtonRef.current.blur();
            break;
          default:
            event.continuePropagation();
            break;
        }
      },
      onFocus: () => {
        props.setFocusedConnectionKey(props.connection.key);
        setIsFocused(true);
      },
      onPress() {},
      onBlur: () => {
        props.setFocusedConnectionKey(null);
        setIsFocused(false);
      },
    },
    hitAreaButtonRef
  );

  const [connectionLink, recalculateLink] = useConnectionLink({
    containerRef: props.containerRef,
    connection: props.connection,
  });

  useEffect(() => {
    // On restart render once to apply the restart styles after that the start styles are applied.
    if (executionState.type === 'restart') {
      setExecutionState((prevState) => {
        return {
          type: executionStateType.start,
          duration: prevState.duration,
        };
      });
    }
  }, [executionState.type]);

  useEffect(() => {
    const connection = {
      key: props.connectionKey,
      getElement() {
        return basePathRef.current;
      },
      async onConnectionPreStart({ isReachable }) {
        if (isReachable) {
          setExecutionState({
            type: executionStateType.prestart,
          });
        } else {
          setExecutionState({
            type: executionStateType.unreachable,
          });
        }
      },
      async onConnectionStart() {
        const duration = 500;
        setExecutionState({
          type: executionStateType.restart,
          duration: duration,
        });
        // The time the animation takes. Waiting here prevents the Flow from executing further.
        await new Promise((resolve) => setTimeout(resolve, duration));
      },
      async onConnectionError() {
        const duration = 500;
        setExecutionState({
          type: executionStateType.error,
          duration: duration,
        });

        // Kind of creating this as a test. Since we know the animation name we can find it on
        // the element and await the finished state of the animation. I think a problem here is
        // that is the animation never starts this promise never resolves. But when would the
        // the animation not start?

        // This method does allow us to sync up animations with starting the next cards. Since a
        // setTimeout is not synced with the animation. It is highly unlikely though that users
        // would be able to see this minor offset. We could possibly do the same for transitions
        // with ontransitionstart, ontransitionend.
        return new Promise((resolve, reject) => {
          const animationPathElement = animationPathRef.current;

          function listener(event) {
            const animation = animationPathElement.getAnimations().find((animation) => {
              return animation.animationName === animationTestOvershoot.name;
            });

            // Rejects on animation removed so unmount is cleaned up.
            animation.finished.catch(console.log).finally(() => {
              animationPathElement.removeEventListener('animationstart', listener);
              resolve();
            });
          }

          animationPathElement.addEventListener('animationstart', listener);
        });
      },
      async onConnectionClear() {
        setExecutionState({
          type: executionStateType.idle,
        });
      },
      recalculate() {
        recalculateLink();
      },
    };

    advancedFlowViewContext.registerConnection(connection);

    return function () {
      advancedFlowViewContext.unregisterConnection(connection);
    };
  }, [advancedFlowViewContext, recalculateLink, props.connectionKey]);

  const press = usePress({
    onPress(event) {
      AdvancedFlowViewStore.deleteConnection({ connection: props.connection });
    },
  });

  const color = connectorTypeColorMap[props.connection.fromConnectorType];

  // Because react doesnt remount on order change but we need the transition to fire again. So it
  // renders once with the previous state and the effect causes the next render to start the
  // transition.
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  useEffect(() => {
    setIsHovered(hitAreaHover.isHovered);
    setIsPressed(hitAreaButton.isPressed);
  }, [hitAreaHover.isHovered, hitAreaButton.isPressed]);

  return (
    <Connection.Root
      {...mergeProps(hitAreaHover.hoverProps, hitAreaButton.buttonProps)}
      ref={hitAreaButtonRef}
      data-is-dragging={props.isDragging}
      data-is-focused={isFocused}
      data-is-hovered={isHovered}
      data-is-pressed={isPressed}
      data-is-testing={isTesting}
      data-execution-state-type={executionState.type}
      style={{
        '--connection-color-default': color?.default,
        '--connection-color-hover': color?.hover,
        '--connection-color-active': color?.active,
        '--connection-color-error': theme.flowcard.connector_error,
      }}
    >
      <Connection.LineGroup>
        {/** White stroke to create the effect of this line overlaying other lines. */}
        <Connection.FocusedLine d={connectionLink.path} pathLength={1} />

        {/** Grey line in the background. Default no progress/done testing */}
        <Connection.BaseLine ref={basePathRef} d={connectionLink.path} />

        {/** Stroke offset and color animation line. */}
        <Connection.ColorLine
          ref={animationPathRef}
          style={{
            '--connection-transition-duration': `${executionState.duration ?? 0}ms`,
          }}
          d={connectionLink.path}
          pathLength={1}
        />

        {/** To increase the hit area of the Root element. */}
        <Connection.HitAreaLine d={connectionLink.path} />
      </Connection.LineGroup>

      <Connection.CloseIconButton
        {...press.pressProps}
        style={{
          '--default-color': color?.default,
          '--active-color': color?.active,
          '--hover-color': color?.hover,
        }}
        data-is-pressed={press.isPressed}
        role="button"
        transform={`translate(${connectionLink.centerX ?? 0}, ${connectionLink.centerY ?? 0})`}
      >
        <Connection.CloseIcon>
          <Connection.CloseIconCircle r={10} />
          <svg viewBox="0 0 48 48" width={8} height={8} x={-4} y={-4}>
            <path
              d="M31.54 24 46.437 9.106a5.327 5.327 0 0 0 0-7.541 5.327 5.327 0 0 0-7.54 0L24 16.46 9.105 1.564a5.327 5.327 0 0 0-7.541 0 5.327 5.327 0 0 0 0 7.541l14.895 14.896L1.564 38.896a5.327 5.327 0 0 0 0 7.541A5.318 5.318 0 0 0 5.334 48c1.366 0 2.73-.523 3.77-1.563L24 31.542l14.895 14.895A5.318 5.318 0 0 0 42.665 48a5.319 5.319 0 0 0 3.771-1.563 5.327 5.327 0 0 0 0-7.54L31.541 24z"
              fill="#ffffff"
            />
          </svg>
        </Connection.CloseIcon>
      </Connection.CloseIconButton>
    </Connection.Root>
  );
}

Connection.LineGroup = styled.g``;

Connection.FocusedLine = styled.path`
  fill: transparent;
  stroke: ${theme.color.body};
  stroke-width: 0;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: stroke, stroke-width;
  pointer-events: none;
`;

Connection.BaseLine = styled.path`
  fill: transparent;
  stroke: ${theme.color.mono_200};
  stroke-width: 2;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: stroke, stroke-width;
  pointer-events: none;

  ${Connection.Root}[data-execution-state-type='${executionStateType.unreachable}'] & {
    stroke: ${theme.flowcard.disabled_background_color};
  }
`;

const animationTestOvershoot = keyframes`
  0% {
    stroke-dashoffset: 1;
    animation-timing-function: ease-in-out;
  }

  55% {
    stroke-dashoffset: 0.45;
    animation-timing-function: ease-in-out;
  }

  70% {
    stroke-dashoffset: 0.5125;
    animation-timing-function: ease-in-out;
  }

  85% {
    stroke-dashoffset: 0.4925;
    animation-timing-function: ease-in-out;
  }

  92.5% {
    stroke-dashoffset: 0.50375;
    animation-timing-function: ease-in-out;
  }

  96.25% {
    stroke-dashoffset: 0.498125;
    animation-timing-function: ease-in-out;
  }

  100% {
    stroke-dashoffset: 0.5;
  }
`;

Connection.ColorLine = styled.path`
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: stroke, stroke-width;

  ${Connection.Root}[data-execution-state-type='${executionStateType.idle}'] & {
    stroke: var(--connection-color-default);
    stroke-dashoffset: 0;
  }

  ${Connection.Root}[data-execution-state-type='${executionStateType.prestart}'] & {
    stroke-dashoffset: 1;
  }

  ${Connection.Root}[data-execution-state-type='${executionStateType.restart}'] & {
    stroke-dashoffset: 1;
  }

  ${Connection.Root}[data-execution-state-type='${executionStateType.start}'] & {
    stroke-dashoffset: 0;
    stroke: var(--connection-color-default);
    transition: var(--connection-transition-duration) ease-in-out;
    transition-property: stroke-dashoffset;
  }

  ${Connection.Root}[data-execution-state-type='${executionStateType.error}'] & {
    stroke-dashoffset: 0.5;
    stroke: var(--connection-color-default);
    transition: var(--connection-transition-duration) ease-in-out;
    transition-property: stroke-dashoffset;
  }

  ${Connection.Root}[data-execution-state-type='${executionStateType.error}'][data-is-testing='true'] & {
    animation: ${animationTestOvershoot} linear 1 forwards;
    animation-duration: calc(var(--connection-transition-duration) * 2);
  }

  ${Connection.Root}[data-execution-state-type='${executionStateType.end}'] & {
  }
`;

Connection.HitAreaLine = styled.path`
  fill: transparent;
  stroke: transparent;
  stroke-width: 20;
`;

Connection.CloseIconCircle = styled.circle`
  fill: var(--default-color);
  transition: fill ${theme.duration.fast} ${theme.curve.fastIn};
`;

Connection.CloseIcon = styled.g`
  opacity: 0;
  transform: scale(0);
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: opacity, transform;
  cursor: pointer;
`;

Connection.CloseIconButton = styled.g`
  pointer-events: all;

  &:hover {
    ${Connection.CloseIconCircle} {
      fill: var(--hover-color);
    }
  }

  &[data-is-pressed='true']:hover {
    ${Connection.CloseIconCircle} {
      fill: var(--active-color);
    }

    ${Connection.CloseIcon} {
      transform: scale(0.9);
    }
  }
`;

Connection.Root = styled.g`
  position: relative;
  z-index: 0;
  outline: 0;
  pointer-events: none;

  &[data-is-dragging='false'] {
    cursor: pointer;
    pointer-events: stroke;

    &[data-is-hovered='true'] {
      ${Connection.CloseIcon} {
        opacity: 1;
        transform: scale(1);
      }

      ${Connection.BaseLine} {
        stroke-width: 3;
      }

      ${Connection.ColorLine} {
        stroke: var(--connection-color-hover);
        stroke-width: 3;
      }

      ${Connection.FocusedLine} {
        stroke-width: 7;
      }
    }

    &[data-is-focused='true'] {
      ${Connection.CloseIcon} {
        opacity: 1;
        transform: scale(1);
      }

      ${Connection.BaseLine} {
        stroke-width: 3;
      }

      ${Connection.ColorLine} {
        stroke: var(--connection-color-default);
        stroke-width: 3;
      }

      ${Connection.FocusedLine} {
        stroke-width: 11;
        stroke: ${theme.color.mono_000};
      }
    }

    &[data-is-pressed='true'] {
      ${Connection.BaseLine} {
        stroke: ${theme.color.mono_300};
      }

      ${Connection.ColorLine} {
        stroke: var(--connection-color-active);
      }
    }
  }

  &[data-execution-state-type='${executionStateType.unreachable}'] {
    cursor: unset;
  }
`;
