import React, { useLayoutEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { su } from '../../../theme/functions/su';
import { theme } from '../../../theme/theme';

export function GroupDivider(props) {
  const showDivider = props.item.values.length > 0 || props.isDragging;

  return (
    <>
      <divider.Root isShow={showDivider} isHidden={!showDivider}>
        <divider.Title>{props.item.title}</divider.Title>
      </divider.Root>
    </>
  );
}

export function DragArea(props) {
  const ref = useRef();
  const [, dropRef] = useDrop({
    accept: [props.dragType],
    canDrop(dragItem, dropMonitor) {
      return false;
    },
    hover(item, dropMonitor) {
      const hoveredCard = props.stateRef.current.cards.find(
        (card) => card.cardIndex === item.cardIndex
      );

      if (props.item.group !== hoveredCard.cardData.group) {
        props.onMoveCardToGroup({
          prevIndex: hoveredCard.cardIndex,
          prevGroup: hoveredCard.cardData.group,
          nextGroup: props.item.group,
          position: props.item.position,
        });
      }
    },
    drop(item, monitor) {
      return;
    },
  });

  useLayoutEffect(() => {
    dropRef(ref);
    return () => {
      dropRef(null);
    };
  }, [dropRef, ref]);

  return (
    <>
      <HoverTarget ref={ref} isDragging={props.isDragging} />
    </>
  );
}

const HoverTarget = styled.div`
  height: ${(props) => (props.isDragging ? 20 : 0)}px;
  width: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  //background: #f09; // for testing purpose only
  transform: translateY(-100%);
`;

const divider = {
  Root: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    margin: 0;
    transition: all 350ms ease-in-out;

    ${(props) =>
      props.isShow &&
      css`
        opacity: 1;
        margin: ${su(2)} 0;
      `}

    ${(props) =>
      props.isHidden &&
      css`
        opacity: 0;
        margin: ${su(0)} 0;
      `}

    &::after,
    &::before {
      content: '';
      border-top: 1px solid ${theme.color.line};
      flex: 1 1 auto;
    }
  `,
  Title: styled.div`
    padding: 0 ${su(1)};
    color: ${theme.color.text_light};
    margin: -20px 0;
  `,
};
