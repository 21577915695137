import React, { useState, useEffect, useMemo } from 'react';

import { getTokenFlowKey } from './tokens';

import { useToken } from './tokens';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { theme } from '../../../theme/theme';

import { Argument, ArgumentBase } from './Argument';
import { Icon } from '../../../components/common/Icon';
import { FlowToken, tokenTypes } from './FlowToken';
import { TokenPicker } from '../token-picker/TokenPicker';

import { iconTag } from '../../../theme/icons/interface/tag';

export function ArgumentDroptoken(props) {
  const droptoken = props.card.droptoken;
  const types = useMemo(() => {
    const droptokens = Array.isArray(droptoken) ? [...droptoken] : [droptoken];

    // If it can accept strings it can also accept errors since they are also strings.
    if (droptokens.includes(tokenTypes.string)) {
      droptokens.push(tokenTypes.error);
    }

    return droptokens;
  }, [droptoken]);

  const storeValue = props.data.droptoken;
  const [localValue, setLocalValue] = useState(storeValue);

  useEffect(() => {
    setLocalValue(storeValue);
  }, [storeValue]);

  const { token, loading } = useToken({
    value: localValue,
    isDropToken: true,
  });

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: 'droptoken',
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  return (
    <TokenPicker
      triggerElementType="span"
      defaultOpen={props.isFirstArgument && storeValue === undefined}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      types={types}
      onSelect={(key, { kind, token }) => {
        const fokenFlowKey = getTokenFlowKey(key, { kind, token });

        setLocalValue(fokenFlowKey);
        checkInvalid(fokenFlowKey, { checkNode: true });

        props.onUpdate?.({
          value: fokenFlowKey,
        });
      }}
      onClearRequest={() => {
        checkInvalid(undefined, { checkNode: true });
        props.onUpdate?.({
          value: undefined,
        });
      }}
      renderTrigger={(triggerRef, triggerProps) => {
        switch (true) {
          case token != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-droptoken={true}>
                <FlowToken
                  token={token}
                  tokenKey={localValue}
                  tokenValueKey="droptoken"
                  label={token.title}
                />
              </ArgumentBase>
            );
          case localValue != null && loading === false:
            // probably missing token
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-droptoken={true}>
                <FlowToken title={localValue} />
              </ArgumentBase>
            );
          default:
            return (
              <ArgumentBase
                {...triggerProps}
                ref={triggerRef}
                data-is-invalid={isInvalid}
                data-is-empty={true}
              >
                <Argument.IconWrapper>
                  <Icon url={iconTag} size={theme.icon.size_tiny} />
                </Argument.IconWrapper>
                {props.argumentTypeText}
              </ArgumentBase>
            );
        }
      }}
    />
  );
}
