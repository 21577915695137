import { useEffect } from 'react';

import { ValidationError } from '../../../lib/InternalError';
import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

export function useAdvancedFlowValidation({ advancedFlowViewContext, messageFormatter }) {
  useEffect(() => {
    function onBeforeSaveCheckArguments() {
      let isAdvancedFlowInvalid = false;

      for (const [, nodeArgumentsHandler] of advancedFlowViewContext.nodeArgumentsHandlerByNodeId) {
        // eslint-disable-next-line no-unused-vars
        const { isNodeInvalid, result } = nodeArgumentsHandler.onInvalidRequest();
        if (isNodeInvalid === true) {
          isAdvancedFlowInvalid = true;
        }
      }

      if (isAdvancedFlowInvalid === true) {
        return new ValidationError.InvalidArguments(
          messageFormatter('flow.validation.invalid_arguments')
        );
      }
    }

    function onBeforeSaveCheckName(advancedFlow) {
      if (advancedFlow.name === '__create__') {
        return new ValidationError.MissingName(messageFormatter('flow.validation.missing_name'));
      }
    }

    function onBeforeSaveCheckCards(advancedFlow) {
      if (Object.keys(advancedFlow.cards ?? {}).length === 0) {
        return new ValidationError.MissingCards(messageFormatter('flow.validation.missing_cards'));
      }
    }

    function onAfterSaveResetArguments(advancedFlow) {
      for (const [, nodeArgumentsHandler] of advancedFlowViewContext.nodeArgumentsHandlerByNodeId) {
        nodeArgumentsHandler.onInvalidResetRequest();
      }
    }

    AdvancedFlowViewStore.registerOnBeforeSave(onBeforeSaveCheckArguments);
    AdvancedFlowViewStore.registerOnBeforeSave(onBeforeSaveCheckName);
    AdvancedFlowViewStore.registerOnBeforeSave(onBeforeSaveCheckCards);

    AdvancedFlowViewStore.registerOnAfterSave(onAfterSaveResetArguments);

    return function () {
      AdvancedFlowViewStore.unregisterOnBeforeSave(onBeforeSaveCheckArguments);
      AdvancedFlowViewStore.unregisterOnBeforeSave(onBeforeSaveCheckName);
      AdvancedFlowViewStore.unregisterOnBeforeSave(onBeforeSaveCheckCards);

      AdvancedFlowViewStore.unregisterOnAfterSave(onAfterSaveResetArguments);
    };
  }, [advancedFlowViewContext, messageFormatter]);
}
