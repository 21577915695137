export function round(value, decimals = 1) {
  return Math.round(value * 10 ** decimals + Number.EPSILON) / 10 ** decimals;
}

export function scaleValue(value, valueMax, valueMin, rangeMax, rangeMin) {
  return (rangeMin - rangeMax) * ((value - valueMin) / (valueMax - valueMin)) + rangeMax;
}

export function logScale(value, min, max) {
  const minp = 0;
  const maxp = 1;

  // The result should be between min and max
  const minv = Math.log(min);
  const maxv = Math.log(max);

  // calculate adjustment factor
  const scale = (maxv - minv) / (maxp - minp);

  return round(Math.exp(minv + scale * (value - minp)), 2);
}

export function clamp(value, min = -Infinity, max = Infinity) {
  return Math.min(Math.max(value, min), max);
}
