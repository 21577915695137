import { useRef } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing, useTableRow } from 'react-aria';

import { theme } from '../../../theme/theme';

import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';
import { Icon } from '../../../components/common/Icon';

export function SettingsTableRow(props) {
  const ref = useRef();
  // const isSelected = props.state.selectionManager.isSelected(props.item.key);

  const tableRow = useTableRow(
    {
      node: props.item,
    },
    props.state,
    ref
  );
  const focusRing = useFocusRing();

  return (
    <S.TableRow {...mergeProps(tableRow.rowProps, focusRing.focusProps)} ref={ref}>
      {props.children}
    </S.TableRow>
  );
}

function S() {}
SettingsTableRow.S = S;

S.TableRow = styled.tr`
  position: relative;
  outline: none;
  box-shadow: none;

  td:not(:first-child) {
    text-align: right;
  }

  td:first-child {
    padding-left: 20px;
  }

  td:last-child {
    padding-right: 20px;
  }

  &:first-child {
    td:first-child {
      border-top-left-radius: ${theme.borderRadius.default};
    }

    td:last-child {
      border-top-right-radius: ${theme.borderRadius.default};
    }
  }

  &:last-child {
    td:last-child {
      border-bottom-right-radius: ${theme.borderRadius.default};
    }

    td:first-child {
      border-bottom-left-radius: ${theme.borderRadius.default};
    }
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${theme.color.line};
  }

  ${ContextMenuButton.Root} {
    opacity: 1;

    ${Icon.S.Root} {
      transition: background-color ${theme.transition.micro_partial};
      background-color: ${theme.color.mono_400};
    }
  }

  ${ContextMenuButton.Root}[data-is-hovered='true']:not([data-is-disabled='true']) {
    ${Icon.S.Root} {
      background-color: ${theme.color.icon_dark};
    }
  }

  ${ContextMenuButton.Root}[data-is-disabled='true'] {
    cursor: not-allowed;

    ${Icon.S.Root} {
      background: ${theme.color.mono_050};
    }
  }
`;
