import React, { useRef } from 'react';

import { mergeProps, useHover } from 'react-aria';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { TokenContextProvider } from './TokenContextProvider';

import { mergeRefs } from '../../../../lib/mergeRefs';

import { useExecutionState } from './useExecutionState';
import { useRecalculateConnection } from './useRecalculateConnection';
import { useDraggableButton } from './useDraggableButton';

import { ContextMenu } from '../../../../components/common/context-menu/ContextMenu';

import { StartCardContextMenuContent } from './StartCardContextMenuContent';
import { ConnectorOut } from '../connectors/ConnectorOut';
import { AdvancedFlowCard } from './AdvancedFlowCard';

import { executionStateType } from './executionStateType';
import { AdvancedFlowCardTestButton } from './AdvancedFlowCardTestButton';

export function StartCard(props) {
  const rootRef = useRef();
  const buttonRef = useRef();

  const { executionState } = useExecutionState({
    rootRef,
    nodeId: props.nodeId,
    cardData: props.data,
  });

  // copy this section from AdvancedFlowCard for now
  const isUnreachableNode = executionState.type === executionStateType.unreachable;
  const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  const isTestingRoot = isTesting && props.testNodeId === props.nodeId;
  const isSaving = props.conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isDisabled = isTesting || isUnreachableNode || isSaving || props.isInteractionDisabled;
  const isDisabledStyle = isUnreachableNode ? 'unreachable' : 'readonly';
  // copy this section end

  useRecalculateConnection({ rootRef, nodeId: props.nodeId });

  const isSelected = AdvancedFlowViewStore.useIsSelected({ id: props.nodeId });

  const hover = useHover({
    isDisabled: isDisabled,
  });
  const button = useDraggableButton(
    {
      elementType: 'div',
      isDisabled: isDisabled,
      onPress: props.onPress,
    },
    buttonRef
  );

  return (
    <TokenContextProvider nodeId={props.nodeId} cardData={props.data}>
      <ContextMenu
        isDisabled={isDisabled}
        content={
          <StartCardContextMenuContent
            nodeId={props.nodeId}
            card={props.card}
            data={props.data}
            onStartRequest={props.onStartRequest}
            onCopyRequest={props.onCopyRequest}
            onDuplicateRequest={props.onDuplicateRequest}
            onDeleteRequest={props.onDeleteRequest}
          />
        }
      >
        {({ isOpen, onContextMenu }) => {
          return (
            <AdvancedFlowCard.Root
              {...mergeProps(button.buttonProps, hover.hoverProps)}
              ref={mergeRefs([props.forwardedRef, rootRef, buttonRef])}
              className={props.className}
              nodeId={props.nodeId}
              data-is-selected={isSelected}
              data-style-type={props.data.type}
              data-execution-state-type={executionState.type}
              onContextMenu={onContextMenu}
            >
              <AdvancedFlowCard.Inner>
                <AdvancedFlowCardTestButton
                  nodeId={props.nodeId}
                  isUnreachableNode={isUnreachableNode}
                  isTesting={isTesting}
                  isTestingRoot={isTestingRoot}
                  isSaving={isSaving}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                  onStartRequest={props.onStartRequest}
                />

                <ConnectorOut
                  cardRootRef={rootRef}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: 'calc(100% - 1px)',
                    transform: 'translate(0, -50%) rotate(-90deg)',
                  }}
                  nodeId={props.nodeId}
                  activeConnectors={props.activeConnectors}
                  executionState={executionState}
                  isVisibleOverride={true}
                  isSelected={isSelected}
                  isHovered={hover.isHovered}
                  isUnreachableNode={isUnreachableNode}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                />

                {/*<CardProgress executionState={executionState} isSelected={props.isSelected} />*/}
              </AdvancedFlowCard.Inner>
            </AdvancedFlowCard.Root>
          );
        }}
      </ContextMenu>
    </TokenContextProvider>
  );
}
