import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';

import { useVariablesByType } from '../../../store/logic/useLogic';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { LogicStore } from '../../../store/logic/LogicStore';
import { RouteManager } from '../../../RouteManager';

import { theme } from '../../../theme/theme';
import { scrollbarDark } from '../../../theme/elements/scrollbars';

import { SolidButton } from '../../../components/buttons/SolidButton';
import { SearchField } from '../../../components/common/search-field/SearchField';
import { Separator } from '../../../components/common/Separator';
import { VariablesListSection } from './VariablesListSection';

export function VariablesList(props) {
  const searchFieldRef = useRef();

  const { i18n } = useI18n();
  const variables = useVariablesByType();
  const [filterValue, setFilterValue] = useState('');

  const handleChange = useMemo(() => {
    return debounce((value) => {
      setFilterValue(value);
    }, 100);
  }, []);

  const { byType, length } = useMemo(() => {
    const byType = {
      string: [],
      number: [],
      boolean: [],
    };

    let length = 0;

    for (const [key, items] of Object.entries(variables.byType ?? {})) {
      for (const [, value] of Object.entries(items)) {
        if (value.name.toLowerCase().includes(filterValue.toLowerCase())) {
          length++;
          byType[key].push(value);
        }
      }

      byType[key].sort((a, b) => i18n.collator.compare(a.name, b.name));
    }

    return { byType, length };
  }, [variables.byType, filterValue, i18n]);

  useEffect(() => {
    setTimeout(() => {
      searchFieldRef.current?.focus();
    }, 0);
  }, []);

  return (
    <S.Container>
      <S.SearchField
        ref={searchFieldRef}
        variant="flat"
        aria-label={i18n.messageFormatter('logic.searchVariables')}
        placeholder={`${i18n.messageFormatter('logic.searchVariables')}...`}
        onChange={handleChange}
        onClear={() => {
          setFilterValue('');
        }}
        onKeyDown={(event) => {
          // If it was empty and escape it pressed propagate event so that
          // overlays can be closed with keyboard.
          if (event.key === 'Escape' && filterValue === '') {
            event.continuePropagation();
          }
        }}
      />

      <Separator mx={20} />

      {variables.loading === false && length > 0 && (
        <S.List>
          {Object.entries(byType ?? {}).map(([key, variables]) => {
            if (variables.length === 0) {
              return null;
            }

            return (
              <VariablesListSection
                key={key}
                type={key}
                title={i18n.messageFormatter(`logic.${key}`)}
                variables={variables}
                onAddVariablePress={(event, type) => {
                  LogicStore.createTempVariable({ type });
                }}
              />
            );
          })}
        </S.List>
      )}

      {length === 0 && filterValue.length > 0 && (
        <S.Message>{i18n.messageFormatter('common.emptyResults')}</S.Message>
      )}

      {length === 0 && filterValue.length === 0 && (
        <S.Message>{i18n.messageFormatter('logic.emptyVariables')}</S.Message>
      )}

      <S.Footer>
        <SolidButton
          styleFlat={true}
          styleColor="green"
          styleWidth="full"
          onPress={() => {
            props.onCloseRequest();
            RouteManager.pushState({ logicDialog: true });
          }}
        >
          <SolidButton.Text>{i18n.messageFormatter('logic.newVariable')}</SolidButton.Text>
        </SolidButton>
      </S.Footer>
    </S.Container>
  );
}

function S() {}
VariablesList.S = S;

S.Container = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 460px;
  overflow: hidden;
`;

S.List = styled.ul`
  ${scrollbarDark};
  padding: 10px 0;
  outline: 0;
  width: 100%;
  overflow: auto;
  flex: 1 1 auto;
`;

S.Footer = styled.div`
  display: flex;
  padding: 0 10px 10px;
`;

S.SearchField = styled(SearchField)`
  display: block;
  width: 100%;
  padding: 10px 10px 5px;
  flex: 0 0 auto;
`;

S.Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  color: ${theme.color.text_light};
`;
