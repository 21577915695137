import { useOverlayTriggerState } from 'react-stately';

import { useArgumentValues } from '../argumentHooks';
import { useNodeArgumentContext } from '../../view-advanced-flow/card/NodeArgumentsContext';

import { ArgumentBase, Argument } from '../Argument';
import { CodeInput } from './CodeInput';

// Applies to both conditions and actions.
export const migrateAbleMap = {
  runCode: true,
  runCodeReturnsString: true,
  runCodeReturnsNumber: true,
  runCodeReturnsBoolean: true,
  runCodeWithArg: true,
  runCodeWithArgReturnsString: true,
  runCodeWithArgReturnsNumber: true,
  runCodeWithArgReturnsBoolean: true,
};

export function ArgumentHomeyScript(props) {
  // TODO homey-api remove props.card.id since now ownerId is unified
  const isMigrateable =
    (migrateAbleMap[props.card.id] || migrateAbleMap[props.card.ownerId]) &&
    props.card.deprecated === true;

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? localValue : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    const nextValue = value.length === 0 ? undefined : value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  function renderTrigger(triggerRef, triggerProps) {
    switch (true) {
      case localValue != null:
        return (
          <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
            {localValue.split('\n')[0]}
          </ArgumentBase>
        );
      default:
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-invalid={isInvalid}
            data-is-empty={true}
          >
            {props.argumentTypeText}
          </ArgumentBase>
        );
    }
  }

  function renderOverlay() {
    return (
      <CodeInput
        //label={label}
        //placeholder={placeholder}
        argument={props.argument}
        cardInstance={props.card}
        cardData={props.data}
        value={localValue}
        isMigrateable={isMigrateable}
        onChange={handleChange}
        onSaveRequest={handleSaveRequest}
        onCancelRequest={handleCancelRequest}
      />
    );
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      animationRemainAnimation="fade"
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={renderTrigger}
      renderOverlay={renderOverlay}
    />
  );
}
