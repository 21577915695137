import { useEffect, useState, useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { ToastManager } from '../../ToastManager';
import { useI18n } from '../useI18nFormatters';

export const SYSTEM_CAPABILITIES = ['onoff', 'speaker_playing', 'button'];

export function useQuickAction({ device, capabilities }) {
  const quickActionId = device?.ui?.quickAction ?? null;
  const quickAction = capabilities?.[quickActionId] ?? null;
  const initialValue = quickAction?.value;

  const instance = useRef(`quickaction:${uuid()}`);
  const { i18n } = useI18n();

  const [value, setValue] = useState(initialValue);

  const id = quickAction?.id.split('.')[0];

  let quickActionTitle = i18n.messageFormatter(`device.quickAction.default`);

  if (id != null && SYSTEM_CAPABILITIES.includes(id)) {
    if (quickAction.getable) {
      quickActionTitle = i18n.messageFormatter(`device.quickAction.${id}.${value ?? false}`);
    } else {
      quickActionTitle = i18n.messageFormatter(`device.quickAction.${id}`);
    }
  }

  const onQuickAction = useCallback(() => {
    if (quickAction && quickAction.setable && quickAction.getable) {
      setValue((prevValue) => {
        const nextValue = !prevValue;
        quickAction
          .setValue(nextValue, instance.current)
          .then(({ value }) => {
            // if you spam the button this will cause snapping
            //setValue(nullAsTrue(value));
          })
          .catch((error) => {
            ToastManager.handleError(error);
            setValue(prevValue);
          });

        return nextValue;
      });
    } else if (quickAction && quickAction.setable) {
      quickAction
        .setValue(true, instance.current)
        .then(({ value }) => {
          //setValue(nullAsTrue(value));
        })
        .catch((error) => {
          ToastManager.handleError(error);
        });
    }
  }, [quickAction]);

  useEffect(() => {
    if (quickAction) {
      const unregister = quickAction.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        setValue(value);
      });

      setValue(quickAction.value);

      return function () {
        unregister();
      };
    }
  }, [quickAction]);

  return { value, onQuickAction, quickActionId, quickActionTitle };
}
