class InternalError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class SaveError extends InternalError {}

export class ValidationError extends InternalError {}

ValidationError.MissingName = class ValidationErrorMissingName extends ValidationError {};
ValidationError.MissingCards = class ValidationErrorMissingCards extends ValidationError {};
ValidationError.InvalidArguments = class ValidationErrorInvalidArguments extends ValidationError {};

export class VersionError extends InternalError {}

VersionError.Homey = class VersionErrorHomey extends VersionError {};
VersionError.App = class VersionErrorApp extends VersionError {};

export class HomeyError extends InternalError {}

HomeyError.Offline = class HomeyErrorOffline extends HomeyError {};
HomeyError.NotFound = class HomeyErrorNotFound extends HomeyError {};

export class PendingError extends InternalError {}
