import styled from '@emotion/styled';

import { useSeparator } from 'react-aria';

import { theme } from '../../../theme/theme';

import { VariablesListItem } from './VariablesListItem';

export function VariablesListSection(props) {
  const separator = useSeparator({
    elementType: 'li',
  });

  return (
    <>
      <sc.SectionSeparator {...separator.separatorProps} />
      <sc.Section>
        <sc.SectionHeading>{props.title}</sc.SectionHeading>
        <sc.SectionItems>
          {props.variables.map((variable) => {
            return <VariablesListItem key={variable.id} variableId={variable.id} />;
          })}
        </sc.SectionItems>
      </sc.Section>
    </>
  );
}

const sc = {};

sc.SectionSeparator = styled.li`
  // height: 1px;
  // background-color: ${theme.color.line};
`;

sc.Section = styled.li`
  padding: 0;
  margin-bottom: 10px;
`;

sc.SectionHeading = styled.div`
  margin: 10px 0 5px 0;
  padding: 0 20px;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;

sc.SectionItems = styled.ul``;

sc.SectionEmptyMessage = styled.li`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  line-height: 20px;
  color: ${theme.color.text_light};
`;
