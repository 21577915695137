import { ResourceUtils } from '../../../store/ResourceUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';
import { FlowCardMissingContextMenuContent } from './FlowCardMissingContextMenuContent';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';
import { iconReplace } from '../../../theme/icons/interface/replace';

export function FlowCardTriggerContextMenuContent(props) {
  const { i18n } = useI18n();

  async function onAction(key) {
    switch (key) {
      case 'replace':
        props.onReplaceRequest();
        break;
      case 'delete':
        props.onDeleteRequest();
        break;
      default:
        break;
    }
  }

  if (props.card == null) {
    return <FlowCardMissingContextMenuContent {...props} />;
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.replace')}
        icon={iconReplace}
        onPress={() => {
          onAction('replace');
        }}
      />

      <DeveloperMenu
        onIdRequest={() => props.card?.id}
        onUriRequest={() => ResourceUtils.getOwnerUri(props.card ?? {})}
        onResourceRequest={() => {
          return {
            card: props.card,
            data: props.data,
          };
        }}
      />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
