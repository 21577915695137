import { useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useDrop } from 'react-dnd';
import { differenceInHours } from 'date-fns';

import { useCollator } from 'react-aria';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useApi } from '../../store/HomeyStore';

import { HomeyImages } from '../../theme/HomeyImages';
import { dragTypes } from '../../components/dnd/dragTypes';

import { theme } from '../../theme/theme';
import { isReducedMotion } from '../../theme/classes/reducedMotion';
import { animationFade } from '../../theme/animations/animationFade';

import { Heading3Bold } from '../../components/common/text/Heading3Bold';
import { ContentLoader } from '../../components/content-loader/ContentLoader';
import { Time } from '../../components/common/Time';
import { Icon, RoundIconWrapper } from '../../components/common/Icon';
import { DeviceGrid } from '../../components/device/DeviceGrid';
import { Device } from '../../components/device/Device';
import { MoodGrid } from '../../components/mood/MoodGrid';
import { MemoizedMood } from '../../components/mood/Mood';

export function ZoneView(props) {
  const collator = useCollator();
  const { i18n } = useI18n();

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: [dragTypes.DEVICE],
    collect: (dropMonitor) => {
      return {
        isOver: dropMonitor.isOver(),
        canDrop: dropMonitor.canDrop(),
      };
    },
    canDrop(dragItem, dropMonitor) {
      return true;
    },
    hover(item, monitor) {},
    drop(dragItem, monitor) {
      props.onDrop({ type: dragItem.type, dropId: props.zoneId, dragId: dragItem.id });
    },
  });

  const sortedZoneDevices = useMemo(() => {
    return Object.entries(props.zoneDevices).sort(([, firstDeviceName], [, secondDeviceName]) => {
      return collator.compare(firstDeviceName, secondDeviceName);
    });
  }, [props.zoneDevices, collator]);

  const sortedZoneMoods = useMemo(() => {
    return Object.entries(props.zoneMoods).sort(([, firstMood], [, secondMood]) => {
      return collator.compare(firstMood, secondMood);
    });
  }, [props.zoneMoods, collator]);

  const iconUrl = HomeyImages.getIconForZone(props.zone?.icon ?? null);

  const activeLastUpdated = props.zone.activeLastUpdated
    ? new Date(props.zone.activeLastUpdated)
    : null;
  const hourDifference = activeLastUpdated
    ? differenceInHours(new Date(), activeLastUpdated)
    : null;

  const delayBetweenDevices = 10;
  const zoneActiveLabel = i18n.messageFormatter('zone.active');

  return (
    <S.Root
      ref={dropRef}
      key={`${props.zoneId}-${props.selectedZoneId}`}
      canDrop={canDrop}
      isOver={isOver}
      data-is-over={isOver}
      data-can-drop={canDrop}
    >
      <S.Header
        style={{
          '--animation-delay': `${delayBetweenDevices * props.devicesCounter}ms`,
        }}
      >
        <RoundIconWrapper
          borderRadius={theme.borderRadius.default}
          color={theme.color.mono_050}
          size="40px"
        >
          <Icon size={theme.icon.size_medium} url={iconUrl} color={theme.color.icon_dark} />
        </RoundIconWrapper>

        <Heading3Bold>{props.zone.name}</Heading3Bold>

        {props.zone.active === true && (
          <S.Activity>
            <S.ActivityPulse />
            {zoneActiveLabel}
          </S.Activity>
        )}

        {props.zone.active === false &&
          typeof hourDifference === 'number' &&
          hourDifference <= 1 && (
            <Time
              date={activeLastUpdated}
              render={(date) => {
                const time =
                  date &&
                  i18n.dateFormatter.formatDistanceToNowStrict(date, {
                    addSuffix: true,
                  });

                return (
                  <S.Activity data-is-active={false}>
                    {zoneActiveLabel} {time}
                  </S.Activity>
                );
              }}
            />
          )}
      </S.Header>

      <MoodGrid.Root>
        {sortedZoneMoods.map(([moodId, mood], index) => {
          return (
            <MoodGrid.Item
              key={`${moodId}-${props.selectedZoneId}`}
              style={{
                '--animation-delay': `${delayBetweenDevices * (index + props.moodsCounter)}ms`,
              }}
            >
              <MemoizedMood isDragDropDisabled={true} moodId={moodId} />
            </MoodGrid.Item>
          );
        })}
      </MoodGrid.Root>

      <DeviceGrid.Root>
        {sortedZoneDevices.map(([deviceId, deviceName], index) => {
          return (
            <DeviceGrid.Item
              key={`${deviceId}-${props.selectedZoneId}`}
              style={{
                '--animation-delay': `${delayBetweenDevices * (index + props.devicesCounter)}ms`,
              }}
            >
              <Device
                deviceId={deviceId}
                isSelected={deviceId === props.selectedDeviceId}
                onControlsClick={props.onControlsClick}
                tileSize={props.tileSize}
              />
            </DeviceGrid.Item>
          );
        })}
      </DeviceGrid.Root>
    </S.Root>
  );
}

export function ZoneViewContentLoader(props) {
  const { api } = useApi();

  return (
    <S.Root>
      <S.Header>
        <ContentLoader.Shape width={40} height={40} />

        <Heading3Bold>
          <ContentLoader.Heading3 />
        </Heading3Bold>
      </S.Header>

      {api?.features.moods.hasMoods && (
        <MoodGrid.Root>
          {Array.from({ length: props.moodCount }).map((_, index) => {
            return (
              <MoodGrid.Item key={index} data-animated={true}>
                <ContentLoader.Mood />
              </MoodGrid.Item>
            );
          })}
        </MoodGrid.Root>
      )}

      <DeviceGrid.Root>
        {Array.from({ length: props.deviceCount }).map((_, index) => {
          return (
            <DeviceGrid.Item key={index} data-animated={true}>
              <ContentLoader.Device tileSize={props.tileSize} />
            </DeviceGrid.Item>
          );
        })}
      </DeviceGrid.Root>
    </S.Root>
  );
}

const S = (ZoneView.S = class S {
  static Header = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 60px;

    opacity: 0;
    animation-name: ${animationFade.in};
    animation-timing-function: ease-in-out;
    animation-duration: ${theme.duration.fast};
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: var(--animation-delay);

    ${isReducedMotion} {
      opacity: 1;
      animation-name: none;
      animation-delay: initial;
    }

    ${Heading3Bold.S.Root} {
      padding: ${theme.su(1, 1, 0)};
    }
  `;

  static Root = styled.section`
    position: relative;
    margin: 0 -20px;
    padding: 0 20px;
    border-radius: ${theme.borderRadius.default};
    background-color: transparent;

    &:first-of-type {
      padding-top: 20px;
    }

    &:not(:first-of-type) {
      ${this.Header} {
        margin-top: 20px;
      }
    }

    &[data-is-over='true'][data-can-drop='true'] {
      background-color: ${theme.color.state_droparea_background};
    }

    ${MoodGrid.Root} {
      padding-bottom: ${theme.su(0.5)};
    }
  `;

  static Activity = styled.div`
    position: relative;
    padding-top: 13px;
    padding-right: ${theme.su(1)};
    color: ${theme.color.highlight};
    font-size: ${theme.fontSize.small};

    &[data-is-active='false'] {
      color: ${theme.color.text_light};
    }
  `;

  static animationPulse = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: .4;
  }

  50% {
    opacity: .4;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0;
  }
`;

  static ActivityPulse = styled.span`
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${theme.color.highlight};

    ::before {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      will-change: transform;
      background: ${theme.color.highlight};
      z-index: -1;
      animation: 2s ${this.animationPulse} infinite ease-out;
    }
  `;
});
