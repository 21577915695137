import { useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { animationRotate } from '../../theme/animations/animationRotate';

export function Spinner(props) {
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  useLayoutEffect(() => {
    if (props.isActive === true) {
      setIsAnimationActive(true);
    }
  }, [props.isActive]);

  return (
    <S.Root
      data-is-animation-active={isAnimationActive}
      onAnimationIteration={(event) => {
        if (props.isActive !== true) {
          setIsAnimationActive(false);
        }
      }}
    >
      {props.children}
    </S.Root>
  );
}

function S() {}
Spinner.S = S;

S.Root = styled.div`
  display: inline-flex;

  &[data-is-animation-active='true'] {
    animation-name: ${animationRotate.default};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
`;
