import { useState } from 'react';
import { useFocusRing, mergeProps } from 'react-aria';

import { promptMessage } from '../../components/overlay/DialogPrompt';
import { RouteManager } from '../../RouteManager';
import { ScriptStore } from './ScriptStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useOptionContext } from '../../components/list-box/ListBox';

import { theme } from '../../theme/theme';

import { ContextMenu } from '../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../components/common/context-menu/ContextMenuButton';
import { SubNavItem } from '../../components/sub-navigation/SubNavItem';

import { ScriptNavigationItemContextMenuContent } from './ScriptNavigationItemContextMenuContent';

import { iconPage } from '../../theme/icons/interface/page';

export function ScriptNavigationItem(props) {
  const { i18n } = useI18n();

  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();
  const focusRing = useFocusRing();

  const [isRenaming, setIsRenaming] = useState(false);

  function renderRenaming() {
    return (
      <SubNavItem.NameTextField
        aria-label={i18n.messageFormatter('script.nameLabel')}
        defaultValue={item.textValue}
        selectDefaultValue={true}
        onSaveRequest={(value) => {
          ScriptStore.updateScript({ id: item.key, script: { name: value } }).catch(console.error);
          setIsRenaming(false);
        }}
        onCancelRequest={() => {
          setIsRenaming(false);
        }}
      />
    );
  }

  const contextMenuContent = (
    <ScriptNavigationItemContextMenuContent
      scriptId={item.key}
      onRenameRequest={() => {
        // This is not implemented for now. Needs support in the HomeyScript app.
        setIsRenaming(true);
      }}
      onStartRequest={() => {
        Promise.resolve()
          .then(async () => {
            await ScriptStore.startScript({ id: props.scriptId });
          })
          .catch(console.error);
      }}
      onDeleteRequest={() => {
        Promise.resolve()
          .then(async () => {
            try {
              await promptMessage({
                message: i18n.messageFormatter('prompt.delete', {
                  name: item.textValue,
                }),
              });

              await ScriptStore.deleteScript({ id: item.key });
            } catch {
              // canceled
            }
          })
          .catch(console.error);
      }}
    />
  );

  return (
    <ContextMenu content={contextMenuContent}>
      {({ isOpen, onContextMenu }) => {
        return (
          <SubNavItem.Root
            {...mergeProps(option.optionProps, focusRing.focusProps)}
            ref={ref}
            data-is-selected={option.isSelected}
            data-is-renaming={isRenaming}
            data-is-focus-visible={focusRing.isFocusVisible}
            data-is-context-menu-open={isOpen}
            onContextMenu={onContextMenu}
          >
            <SubNavItem.Link isDisabled={isRenaming} href={RouteManager.getPathForScript(item.key)}>
              {(() => {
                if (item.value.context.script.version === 1) {
                  return (
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 1,
                          position: 'absolute',
                          right: -6,
                          bottom: -1,
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: theme.color.yellow,
                          border: `2px solid ${theme.color.component}`,
                        }}
                      />

                      <SubNavItem.Icon url={iconPage} size={theme.icon.size_small} />
                    </div>
                  );
                }

                return <SubNavItem.Icon url={iconPage} size={theme.icon.size_small} />;
              })()}

              {/* <SubNavItem.Icon url={iconPage} size={theme.icon.size_small} /> */}

              {isRenaming ? (
                renderRenaming()
              ) : (
                <SubNavItem.Name>
                  {item.textValue}
                  <SubNavItem.Extension>.js</SubNavItem.Extension>
                </SubNavItem.Name>
              )}
            </SubNavItem.Link>

            {isRenaming === false && <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />}
          </SubNavItem.Root>
        );
      }}
    </ContextMenu>
  );
}
