import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

export interface State {
  optionLocationData: { value: Location } | null;
  optionModeData: { value: Mode | null } | null;
  isLoading: boolean;
  error: unknown;
}

type Location = {
  latitude: number;
  longitude: number;
  accuracy: number;
};

type Mode = 'manual' | 'auto';

export type LocationSettingsStoreState = State & BaseApiStoreState;

class LocationSettingsStore extends BaseApiStore<State> {
  key = 'location-settings';

  override createInitialState() {
    return {
      optionLocationData: null,
      optionModeData: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:location');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerGeolocation = api.geolocation;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const optionLocationPromise = managerGeolocation.getOptionLocation();
      const optionModePromise = managerGeolocation.getOptionMode().catch((error) => {
        // Only exists on <=2019 models.
        if (error.code === 404) {
          return { value: null };
        }

        throw error;
      });

      const [, optionLocationData, optionModeData] = await Promise.all([
        waitPromise,
        optionLocationPromise,
        optionModePromise,
      ]);

      this.set({
        ...this.createInitialState(),
        optionLocationData: optionLocationData as unknown as { value: Location },
        optionModeData: optionModeData as unknown as { value: Mode | null },
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, this.key);

    return { locationSettings: this.store(selectAll, shallow) };
  }

  async setOptionLocation({ value }: { value: Location }) {
    const api = this.getApi();
    const promise = api.geolocation.setOptionLocation({ value }) as unknown as Promise<{
      value: Location;
    }>;
    const result = await promise;
    this.set({ optionLocationData: result });
  }

  async setOptionMode({ value }: { value: Mode }) {
    const api = this.getApi();
    const promise = api.geolocation.setOptionMode({ value }) as unknown as Promise<{
      value: Mode;
    }>;
    const result = await promise;
    this.set({ optionModeData: result });
  }
}

function selectAll(state: LocationSettingsStoreState) {
  return {
    ...selectBase(state),
    optionLocationData: state.optionLocationData,
    optionModeData: state.optionModeData,
  };
}

export const locationSettingsStore = new LocationSettingsStore();
