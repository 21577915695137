import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useDragLayer } from 'react-dnd';

import { dragTypes } from './dragTypes';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export function DragLayer(props) {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => {
    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    };
  });

  const rendered = useMemo(() => {
    if (item && item.render) {
      return item.render();
    }
  }, [item]);

  if (!isDragging) {
    return null;
  }

  const renderedItem = renderItem({ itemType, item, rendered });

  return (
    renderedItem != null && (
      <DragLayer.Root>
        <div style={getItemStyles(initialOffset, currentOffset)}>{renderedItem}</div>
      </DragLayer.Root>
    )
  );
}

function renderItem({ itemType, item, rendered }) {
  switch (itemType) {
    case dragTypes.ZONE:
      return <ZonePreview item={item} />;
    case dragTypes.FLOW:
    case dragTypes.FLOW_CARD_ACTION:
    case dragTypes.FLOW_CARD_CONDITION:
    case dragTypes.DEVICE:
    case dragTypes.MOOD:
    case dragTypes.LOG:
    case dragTypes.FOLDER:
    case dragTypes.ITEM:
      return rendered;
    default:
      return null;
  }
}

function ZonePreview({ item }) {
  return (
    <ZonePreviewContainer>
      <ZonePreviewContent>
        <Icon url={item.iconUrl} size={theme.icon.size_default} color={theme.color.icon_light} />
        <ZonePreviewName>{item.name}</ZonePreviewName>
      </ZonePreviewContent>
    </ZonePreviewContainer>
  );
}

DragLayer.Root = styled.div`
  position: fixed;
  z-index: ${theme.zIndex.drag_layer};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

const ZonePreviewContainer = styled.div`
  display: inline-block;
  min-width: 160px;
  max-width: 256px;
  height: 40px;
  border-radius: 10px;
  background-color: ${theme.color.white};
  box-shadow: ${theme.boxShadow.default};
`;

const ZonePreviewContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 20px;
`;

const ZonePreviewName = styled.div`
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
