export const boxShadow = {

  default: `
    0 2px  12px  0 rgba(0,0,0, 0.2),
    0 2px  4px   0 rgba(0,0,0, 0.08),
    0 0    1px   1px rgba(255,255,255, 0.2)
  `,

  default_hover: `
    0 2px 12px 0 rgba(0, 0, 0, 0.15),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    0 0    1px   1px rgba(255,255,255, 0.3)
  `,

  default_active: `
    0 2px 12px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 0    1px   1px rgba(255,255,255, 0.4)
  `
};
