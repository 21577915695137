import { theme } from '../theme';
import { css as cn } from '@emotion/css';

/**
 * @type {{ light: string }}
 */
export const themeClass = {
  color: {
    light: cn`
      color: ${theme.color.text_light};
    `,
  },
};
