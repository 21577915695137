import styled from '@emotion/styled';

import { Logo } from '../../../components/common/Logo';

import { theme } from '../../../theme/theme';

export function HeaderIcons(props) {
  return (
    <S.Root>
      {props.children}

      <svg width="120px" height="48px" viewBox="0 0 120 48">
        <line x1="0" y1="24" x2="114" y2="24" stroke={theme.color.line} strokeWidth="2" />
        <polygon points="114,20 120,24 114,28" fill={theme.color.line} />
      </svg>

      <Logo size="48px" />
    </S.Root>
  );
}

function S() {}
HeaderIcons.S = S;

S.Root = styled.div`
  display: flex;
  width: 256px;
  margin-bottom: 40px;
  justify-content: space-between;
`;
