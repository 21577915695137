import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

export function useConnectorDrag({ ref, type, nodeId, isDisabled }) {
  // eslint-disable-next-line no-unused-vars
  const [collectedProps, connectorDragSource, preview] = useDrag(() => {
    return {
      type: type,
      item: (monitor) => {
        return {
          nodeId: nodeId,
        };
      },
      end(item, dragMonitor) {},
      canDrag(dragMonitor) {
        if (isDisabled === true) {
          return false;
        }

        return true;
      },
      collect(dragMonitor) {
        return {
          isDragging: dragMonitor.isDragging(),
        };
      },
    };
  }, [type, nodeId, isDisabled]);
  connectorDragSource(ref);

  useEffect(() => {
    return function () {
      connectorDragSource(null);
    };
  }, [connectorDragSource]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return collectedProps;
}
