import React from 'react';
import styled from '@emotion/styled';

import { useSectionContext } from '../../../components/list-box/ListBox';

import { su } from '../../../theme/functions/su';
import { theme } from '../../../theme/theme';

export function DeviceSettingsGeneralIconSelectSection(props) {
  // eslint-disable-next-line no-unused-vars
  const { section, state, item, value, children } = useSectionContext();

  const HeadingComponent = props.headingComponent ?? S.Heading;

  return (
    <S.Root {...section.itemProps} className={props.className} style={props.style} as={props.as}>
      {item.rendered && (
        <HeadingComponent {...section.headingProps}>{item.rendered}</HeadingComponent>
      )}

      <S.Children {...section.groupProps}>{children}</S.Children>
    </S.Root>
  );
}

function S() {}
DeviceSettingsGeneralIconSelectSection.S = S;

S.Root = styled.li`
  &:not(:last-of-type) {
    padding-bottom: ${su(2)};
  }
`;

S.Heading = styled.div`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.text_light};
  padding-bottom: ${su(1)};
`;

S.Children = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 48px);
  grid-gap: ${su(1)};
  list-style: none;
`;
