import styled from '@emotion/styled';

import { theme } from '../../theme/theme';

import { Tooltip } from '../../components/common/tooltip/Tooltip';
import { Icon } from '../../components/common/Icon';
import { TextMedium } from '../../components/common/text/TextMedium';
import { TextTinyMedium } from '../../components/common/text/TextTinyMedium';
import { TextNormal } from '../../components/common/text/TextNormal';
import { ExternalLink } from '../../components/common/link/ExernalLink';

import { iconWarningCircle } from '../../theme/icons/interface/warning-circle/warning-circle';
import { iconExternalLink } from '../../theme/icons/interface/external-link';

export function DeprecatedLabel(props) {
  function renderTrigger(triggerRef, triggerProps) {
    return (
      <S.TriggerRoot ref={triggerRef} {...triggerProps}>
        <TextTinyMedium color={theme.color.text_white}>Classic</TextTinyMedium>
      </S.TriggerRoot>
    );
  }

  return (
    <Tooltip offset={10} instant={true} anchorPointer={true} renderTrigger={renderTrigger}>
      <S.ContentRoot>
        <S.ContentHeader>
          <Icon size={theme.icon.size_medium} url={iconWarningCircle} color={theme.color.yellow} />

          <TextMedium>Deprecated API version</TextMedium>
        </S.ContentHeader>

        <TextNormal>You're using an old version of the API for this script.</TextNormal>

        <S.ContentFooter>
          <S.Link url="https://athombv.github.io/com.athom.homeyscript/tutorial-migrate">
            Learn how to upgrade
            <Icon
              url={iconExternalLink}
              color={theme.color.highlight}
              size={theme.icon.size_tiny}
            />
          </S.Link>
        </S.ContentFooter>
      </S.ContentRoot>
    </Tooltip>
  );
}

const S = (DeprecatedLabel.S = class S {
  static TriggerRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 0 ${theme.su(1)};
    border-radius: 12px;
    background-color: ${theme.color.yellow};
    cursor: pointer;
  `;

  static ContentRoot = styled.div`
    padding: ${theme.su(3)};
  `;

  static ContentHeader = styled.header`
    display: flex;
    align-items: center;
    gap: ${theme.su(1)};
    padding-bottom: ${theme.su(1)};
  `;

  static ContentFooter = styled.footer`
    padding-top: ${theme.su(1)};
  `;

  // Extract to component and use in other places. with iconExternalLink
  static Link = styled(ExternalLink)`
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 5px;
    align-items: center;
    text-decoration: none;
    outline: 0;

    &:hover {
      text-decoration: underline;
    }
  `;
});
