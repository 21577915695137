import React, { forwardRef, useMemo } from 'react';
// import { shallow } from 'zustand/shallow';

import { ResourceUtils } from '../../../store/ResourceUtils';

import { useFlowCards } from '../../../store/flow-cards/useFlowCards';

import { DeviceFlowCard } from './DeviceFlowCard';
import { MoodFlowCard } from './MoodFlowCard';
import { ZoneFlowCard } from './ZoneFlowCard';
import { AppFlowCard } from './AppFlowCard';
import { ManagerFlowCard } from './ManagerFlowCard';

export function createCard(component) {
  return forwardRef(function (props, forwardedRef) {
    const Component = component;

    const { ownerType, ownerUri, ownerId } = useMemo(() => {
      return ResourceUtils.getOwnerProps(props.card);
    }, [props.card]);

    const otherProps = {
      ownerType,
      ownerUri,
      ownerId,
      forwardedRef,
      component: Component,
    };

    switch (ownerType) {
      case 'device':
        return <DeviceFlowCard {...props} {...otherProps} />;
      case 'mood':
        return <MoodFlowCard {...props} {...otherProps} />;
      case 'zone':
        return <ZoneFlowCard {...props} {...otherProps} />;
      case 'app':
        return <AppFlowCard {...props} {...otherProps} />;
      case 'manager':
        return <ManagerFlowCard {...props} {...otherProps} />;
      default:
        // missing card
        // console.error('unimplemented flowcard owner type');
        return <Component {...props} {...otherProps} />;
    }
  });
}

export function createCardWithStoreHook(component) {
  return React.memo(
    forwardRef(function (props, forwardedRef) {
      const Component = component;
      const flowCards = useFlowCards();

      const key = ResourceUtils.getKey(props.data);
      const typePlural = `${props.data.type}s`;
      const card = flowCards[typePlural]?.byKey?.[key] ?? null;

      const { ownerType, ownerUri, ownerId } = useMemo(() => {
        return ResourceUtils.getOwnerProps(card);
      }, [card]);

      const otherProps = {
        ownerType,
        ownerUri,
        ownerId,
        card,
        forwardedRef,
        component: Component,
        isLoading: flowCards.isLoading,
      };

      switch (ownerType) {
        case 'device':
          return <DeviceFlowCard {...props} {...otherProps} />;
        case 'mood':
          return <MoodFlowCard {...props} {...otherProps} />;
        case 'zone':
          return <ZoneFlowCard {...props} {...otherProps} />;
        case 'app':
          return <AppFlowCard {...props} {...otherProps} />;
        case 'manager':
          return <ManagerFlowCard {...props} {...otherProps} />;
        default:
          // missing card
          // console.error('unimplemented flowcard owner type');
          return <Component {...props} {...otherProps} />;
      }
    }),
    (prevProps, nextProps) => {
      // const result = shallow(prevProps, nextProps);
      // console.log(`comparator:${nextProps.nodeId.substring(0, 6)}`, result, prevProps, nextProps);

      // This wont work yet because the Component receives inline functions.
      return false;
    }
  );
}
