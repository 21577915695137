import React, { useRef } from 'react';

import { useConnectorDrag } from './useConnectorDrag';
import { useConnectorRegister } from './useConnectorRegister';

import { ConnectorBase } from './ConnectorBase';

import { connectorTypesMap } from './connectorTypes';

export function ConnectorOut(props) {
  const circleRef = useRef();
  const connectorOutRef = useRef();

  const collectedProps = useConnectorDrag({
    ref: connectorOutRef,
    type: connectorTypesMap.Out,
    nodeId: props.nodeId,
    isDisabled: props.isDisabled,
  });

  useConnectorRegister({
    cardRootRef: props.cardRootRef,
    centerRef: circleRef,
    type: connectorTypesMap.Out,
    nodeId: props.nodeId,
  });

  const isActive = props.activeConnectors.has(`${props.nodeId}::${connectorTypesMap.Out}`);

  return (
    <ConnectorBase
      circleRef={circleRef}
      dragHandleRef={connectorOutRef}
      style={props.style}
      connectorType={connectorTypesMap.Out}
      executionState={props.executionState}
      isActive={isActive}
      isHovered={props.isHovered}
      isSelected={props.isSelected}
      isDisabled={props.isDisabled}
      isDisabledStyle={props.isDisabledStyle}
      isDragging={collectedProps.isDragging}
      isVisibleOverride={props.isVisibleOverride}
      isDragOverAndCanConnect={props.isDragOverAndCanConnect}
    />
  );
}
