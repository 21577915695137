import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useScriptLogs } from './useScripts';

import { theme } from '../../theme/theme';
import { containers } from '../../theme/elements/containers';
import { scrollbarDark } from '../../theme/elements/scrollbars';

export function ScriptOutput() {
  const { i18n } = useI18n();

  const scrollRef = useRef();

  const { logs, result } = useScriptLogs();

  useEffect(() => {
    const scrollElement = scrollRef.current;

    if (scrollElement != null) {
      scrollElement.scrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;
    }
  }, [logs, result]);

  return (
    <ScriptOutput.Root>
      <ScriptOutput.Content ref={scrollRef}>
        {(logs == null || logs?.length === 0) && result == null && (
          <ScriptOutput.Empty>
            {i18n.messageFormatter('script.consolePlaceholder')}
          </ScriptOutput.Empty>
        )}

        {logs?.map((log, index) => {
          return <pre key={index}>{log}</pre>;
        })}
      </ScriptOutput.Content>
    </ScriptOutput.Root>
  );
}

ScriptOutput.Root = styled.div`
  ${containers.card};
  display: flex;
  flex-direction: column;

  flex: 1 1 0;
  padding: 20px 0;

  overflow: hidden;

  &,
  pre {
    font-family: Roboto Mono, monospace;
    font-size: ${theme.fontWeight.regular};
    font-size: ${theme.fontSize.small};
    font-feature-settings: 'liga' 0, 'calt' 0;
    white-space: pre-wrap;
  }
`;

ScriptOutput.Content = styled.div`
  ${scrollbarDark};

  flex: 1 1 0;
  padding: 0 20px;

  overflow-y: auto;
`;

ScriptOutput.Empty = styled.div`
  color: ${theme.color.text_light};
`;
