import { memo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';
import { ToastManager } from '../../ToastManager';

import { useMood } from '../../store/moods/useMoods';
import { useApi } from '../../store/HomeyStore';
import { useMoodDragDrop } from './useMoodDragDrop';
import { useMixedColors } from './CanvasLiquidLinearCircle';

import { theme } from '../../theme/theme';

import { ContextMenu } from '../common/context-menu/ContextMenu';
import { Icon } from '../common/Icon';
import { TextSmallMedium } from '../common/text/TextSmallMedium';
import { TextTiny } from '../common/text/TextTiny';
import { MoodContextMenuContent } from './MoodContextMenuContent';
import { CanvasLiquidLinearCircle } from './CanvasLiquidLinearCircle';

import { iconWarning } from '../../theme/icons/interface/warning/warning';

export function Mood(props) {
  const buttonRef = useRef();
  const index = useRef(0);

  const { api } = useApi();
  const { mood } = useMood({ moodId: props.moodId });

  const circleSize = 24;
  const mixedColors = useMixedColors({ devices: mood?.devices });

  const [elements, setElements] = useState([]);

  const button = useButton(
    {
      elementType: 'div',
      onPress(event) {
        const key = index.current++;

        setElements((prevElements) => [
          ...prevElements,
          <S.PulseAnimation
            key={key}
            style={{
              '--circle-size': `${circleSize}px`,
              background: generateCSSGradient(mixedColors),
            }}
            onAnimationEnd={() => {
              setElements((prevElements) =>
                prevElements.filter((element) => {
                  return parseInt(element.key, 10) !== key;
                })
              );
            }}
          />,
        ]);
        api.moods
          ._call('POST', `mood/${props.moodId}/set`, {
            opts: {},
            body: {},
            queryParameters: {},
          })
          .then(() => {})
          .catch(ToastManager.handleError);
      },
    },
    buttonRef
  );

  const { dragRef, dropRef, isDragging } = useMoodDragDrop({
    moodId: props.moodId,
    isDisabled: props.isDragDropDisabled,
    onDrop: props.onDrop,
    onRequestSwap: props.onRequestSwap,
    render() {
      return (
        <S.Root data-is-drag-preview={true}>
          <CanvasLiquidLinearCircle id={mood.id} size={circleSize} mixedColors={mixedColors} />

          <S.Section>
            <S.Title>
              <TextSmallMedium>{mood.name}</TextSmallMedium>
            </S.Title>
            <S.Subtitle>
              <TextTiny color={theme.color.text_light}>
                {Object.values(mood.devices).length} Lights
              </TextTiny>
            </S.Subtitle>
          </S.Section>
        </S.Root>
      );
    },
  });

  if (mood == null) {
    return (
      <S.Root title={props.moodId} data-is-interaction-disabled={true}>
        <Icon size={theme.icon.size_default} color={theme.icon.color_medium} url={iconWarning} />
      </S.Root>
    );
  }

  function generateCSSGradient(mixedColors) {
    // Start the gradient string with the direction
    let gradientStr = 'linear-gradient(to top right';

    // Add each color stop to the gradient string
    mixedColors.forEach((color, index) => {
      const offset = (index / mixedColors.length) * 100;
      const colorStr = `hsla(${color.hue}, ${color.saturation}%, ${color.lightness}%, ${color.alpha}) ${offset}%`;
      gradientStr += `, ${colorStr}`;
    });

    // Close the gradient string
    gradientStr += ')';

    return gradientStr;
  }

  return (
    <ContextMenu content={<MoodContextMenuContent moodId={props.moodId} />}>
      {({ isOpen, onContextMenu }) => {
        return (
          <S.Root
            {...button.buttonProps}
            ref={mergeRefs([dragRef, dropRef, buttonRef])}
            aria-haspopup="menu"
            aria-expanded={props.isOpen}
            data-is-dragging={isDragging}
            onContextMenu={onContextMenu}
          >
            <CanvasLiquidLinearCircle id={mood.id} size={circleSize} mixedColors={mixedColors} />

            {elements}

            {/* <S.PulseAnimation
              style={{
                '--circle-size': `${circleSize}px`,
                background: generateCSSGradient(mixedColors),
              }}
            /> */}

            <S.Section>
              <S.Title>
                <TextSmallMedium>{mood.name}</TextSmallMedium>
              </S.Title>
              <S.Subtitle>
                <TextTiny color={theme.color.text_light}>
                  {Object.values(mood.devices).length} Lights
                </TextTiny>
              </S.Subtitle>
            </S.Section>
          </S.Root>
        );
      }}
    </ContextMenu>
  );
}

const S = (Mood.S = class S {
  static Root = styled.div`
    --transform-transition: transform ease-in-out ${theme.duration.fast};

    position: relative;

    display: inline-flex;
    align-items: center;
    min-width: 180px;
    max-width: 180px;
    height: 48px;
    padding: 0 ${theme.su(1.5)};
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.color.component};
    box-shadow: ${theme.boxShadow.default};
    cursor: pointer;
    transition: var(--transform-transition);
    outline: 0;
    overflow: hidden;

    &:hover {
      transform: scale(1.05);
    }

    &[data-is-interaction-disabled='true'] {
      cursor: default;

      &:hover {
        transform: initial;

        &:active:not(:focus-within) {
          transform: initial;
        }
      }
    }

    &[data-is-drag-preview='true'] {
      transform: scale(0.95);
      animation: ${keyframes`
      from {
        transform: scale(1);
      }

      to {
        transform: scale(0.95);
      }
    `} ${theme.duration.slow} ease-out forwards 1;
    }

    &[data-is-dragging='true'] {
      opacity: 0;
    }
  `;

  static Section = styled.div`
    overflow: hidden;
  `;

  static Title = styled.div`
    padding-right: ${theme.su(1.5)};
    padding-left: ${theme.su(1.5)};

    ${TextSmallMedium.S.Root} {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;

  static Subtitle = styled.div`
    padding-right: ${theme.su(1.5)};
    padding-left: ${theme.su(1.5)};

    ${TextTiny.S.Root} {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;

  static PulseAnimation = styled.div`
    --mask-size: calc(172px * 2);

    position: absolute;
    left: calc(${theme.su(1.5)} + var(--circle-size) / 2);
    top: 50%;
    width: var(--mask-size);
    height: var(--mask-size);
    background: linear-gradient(45deg, #f00, #0f0 100%, #00f 200%);
    mask-image: radial-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 50%;
    mask-size: var(--mask-size) var(--mask-size);
    mask-repeat: no-repeat;
    transform: translate(-50%, -50%);

    animation-name: ${(() => {
      const animation = keyframes`

      0% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 1;
      }

      70% {
        opacity: 0.5;
      }

      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
      }

      `;

      return animation;
    })()};
    animation-duration: ${theme.duration.slow};
    animation-timing-function: ease-in-out;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-play-state: running;
  `;
});

export const MemoizedMood = memo(Mood);
