import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import offset from 'dom-helpers/offset';
import { OverlayContainer } from 'react-aria';

import { theme } from '../../../theme/theme';

import { AnchorPointer } from '../AnchorPointer';

const ContextMenuContext = createContext({});

/**
 * Provides the global context for context menu's
 */
export function ContextMenuContextProvider(props) {
  const overlayRef = useRef();
  const instanceRef = useRef({
    position: null,
  });

  const [position, setPosition] = useState(null);
  const [showArrow, setShowArrow] = useState(false);

  useLayoutEffect(() => {
    instanceRef.current.position = position == null ? instanceRef.current.position : position;
  });

  const context = useMemo(() => {
    const contextMenus = new Set();
    const subMenus = new Set();

    return {
      overlayRef,
      subMenus,
      register(instance) {
        contextMenus.add(instance);
      },
      unregister(instance) {
        contextMenus.delete(instance);
      },
      registerSubMenu(instance) {
        subMenus.add(instance);
      },
      unregisterSubMenu(instance) {
        subMenus.delete(instance);
      },
      onContextMenu(event, targetInstance) {
        // Close all known submenu's
        subMenus.forEach((instance) => {
          instance.onClose();
        });

        let action = null;

        // Close any open context menu that is not equal to this context menu.
        contextMenus.forEach((instance) => {
          if (instance !== targetInstance) {
            instance.onClose();
            return;
          }

          instance.target = event.target;
          instance.type = event.type;

          // Attached to button.
          if (instance.isOpen === true && event.type !== 'contextmenu') {
            // If it was already open on a press close it.
            action = () => {
              instance.onClose();
              setPosition(null);
              return { exit: true };
            };
          } else {
            action = () => {
              instance.onOpen();
              return { exit: false };
            };
          }
        });

        const { exit } = action();

        if (exit === true) return;

        // We need to reset here because opening the same menu on a different spot needs these
        // values to be reset. If we omit this the bounds checking goes wrong.
        overlayRef.current.style.setProperty('--x-overflow', `0px`);
        overlayRef.current.style.setProperty('--y-overflow', `0px`);

        // Setting this to currentTarget causes issues with navigation items context menu's but I
        // dont think it should be event.target.
        const target = event.target;

        // Returns the offset of the target, including top and left positions, width and height.
        const targetOffset = offset(target);

        // Move it slightly down.
        let offsetY = 20;

        // Relative to event target this can be used in advanced flow for example to place something
        // e.g. a flow card.
        const x = event.clientX != null ? event.clientX - targetOffset.left + target.scrollLeft : 0;
        const y = event.clientY != null ? event.clientY - targetOffset.top + target.scrollTop : 0;

        // Works for now but should be something like anchor top/bottom.
        if (event.type !== 'contextmenu') {
          offsetY = offsetY + targetOffset.height / 2;
        }

        // Default anchor when not attached to button is event.clientX / event.clientY.
        const anchorX = event.clientX ?? targetOffset.left + targetOffset.width / 2;
        const anchorY = (event.clientY ?? targetOffset.top) + offsetY;

        // This forces the layout effect.
        setPosition({
          x,
          y,
          clientX: event.clientX,
          clientY: event.clientY,
          anchorX,
          anchorY,
        });
      },
      onCloseRequest() {
        // Called on interacted outside or escape.
        contextMenus.forEach((instance) => {
          instance.onClose();
        });
        setPosition(null);
      },
      getPosition() {
        return instanceRef.current.position;
      },
      onHoverStart(event, parent) {
        const ancestors = [];

        function traverse(parent) {
          if (parent != null) {
            ancestors.push(parent);
            traverse(parent.parent);
          }
        }

        traverse(parent);

        subMenus.forEach((instance) => {
          if (ancestors.includes(instance) === false) {
            instance.onClose();
          }
        });
      },
    };
  }, []);

  // Bounds checking.
  useLayoutEffect(() => {
    if (position != null) {
      const overlayElement = overlayRef.current;
      const overlayRect = overlayElement.getBoundingClientRect();

      const overlayHorizontalOffset = overlayRect.width / 2;
      overlayElement.style.setProperty('--offset', `${overlayHorizontalOffset}px`);

      const overlayOffset = offset(overlayRef.current);

      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;

      // Show arrow to the target by default. If the context menu does not fit we hide the arrow
      // since we can't guarantee we are pointing at the correct thing.
      let shouldShowArrow = true;

      // Menu it outside of vertical bounds.
      if (overlayOffset.top + overlayOffset.height > clientHeight) {
        const yOverflow = overlayOffset.top + overlayOffset.height - clientHeight;
        overlayRef.current.style.setProperty('--y-overflow', `${yOverflow}px`);
        shouldShowArrow = false;
      } else if (overlayOffset.top < 0) {
        const yOverflow = overlayOffset.top;
        overlayRef.current.style.setProperty('--y-overflow', `${yOverflow}px`);
        shouldShowArrow = false;
      }

      // Menu it outside of horizontal bounds.
      if (overlayOffset.left + overlayOffset.width > clientWidth) {
        const xOverflow = overlayOffset.left + overlayOffset.width - clientWidth;
        overlayRef.current.style.setProperty('--x-overflow', `${xOverflow}px`);
        shouldShowArrow = false;
      } else if (overlayOffset.left < 0) {
        const xOverflow = overlayOffset.left;
        overlayRef.current.style.setProperty('--x-overflow', `${xOverflow}px`);
        shouldShowArrow = false;
      }

      setShowArrow(shouldShowArrow);
    }
  }, [position]);

  return (
    <ContextMenuContext.Provider value={context}>
      <OverlayContainer>
        <ContextMenuContextProvider.Overlay
          ref={overlayRef}
          style={{
            '--anchor-y': `${position?.anchorY ?? 0}px`,
            '--anchor-x': `${position?.anchorX ?? 0}px`,
            '--x-overflow': '0px',
            '--y-overflow': '0px',
            '--offset': '0px',

            '--display': position == null ? 'none' : 'block',
            '--top': `calc(var(--anchor-y) - var(--y-overflow))`,
            '--left': `calc(var(--anchor-x) - var(--x-overflow))`,
          }}
        >
          {position != null && showArrow === true && (
            <AnchorPointer style={{ left: '50%' }} placement="bottom" />
          )}
        </ContextMenuContextProvider.Overlay>
      </OverlayContainer>

      {props.children}
    </ContextMenuContext.Provider>
  );
}

export function useContextMenuContext() {
  return useContext(ContextMenuContext);
}

ContextMenuContextProvider.Overlay = styled.div`
  position: fixed;
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};

  display: var(--display);
  top: var(--top);
  left: var(--left);

  transform: translateX(-50%);

  z-index: 100000;
`;
