import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ImageStore } from './ImageStore';
import { selectData, selectByKey } from '../selectors';

import { useAttach } from '../useAttach';

export function useImagesData() {
  useAttach(ImageStore, 'imagesData');

  return ImageStore.store(selectData, shallow);
}

export function useImagesByKey() {
  useAttach(ImageStore, 'imagesByKey');

  return ImageStore.store(selectByKey, shallow);
}

export function useImage({ imageKey }) {
  useAttach(ImageStore, 'image');

  const select = useCallback(
    (state) => {
      return {
        image: state.byKey?.[imageKey] ?? null,
        manager: state.manager,
      };
    },
    [imageKey]
  );

  return ImageStore.store(select, shallow);
}

export function useHomeyImage() {
  useAttach(ImageStore, 'image');

  const select = useCallback((state) => {
    const homeyImage = Object.values(state.byKey ?? {}).find((image) => {
      return image.ownerUri === 'homey:manager:images';
    });

    return {
      image: homeyImage ?? null,
      manager: state.manager,
    };
  }, []);

  return ImageStore.store(select, shallow);
}

export function useImagesAttach() {
  useAttach(ImageStore, 'useImagesAttach');
}
