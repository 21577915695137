import { createContext, forwardRef, useRef, useState, useContext, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { mergeProps, useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { useHover } from '../../hooks/useHover';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { Button } from './Button';
import { ButtonIcon } from './ButtonIcon';

const DualButtonContext = createContext(null);

export function DualButton(props) {
  const [partHoveredState, setPartHoveredState] = useState();
  const [partPressedState, setPartPressedState] = useState();

  const [firstChild, secondChild] = props.children;

  // Provide context to DualButton.Parts so they can act as a pair.
  return (
    <DualButtonContext.Provider value={{ setPartPressedState, setPartHoveredState }}>
      <DualButton.Root data-part-hovered={partHoveredState} data-part-pressed={partPressedState}>
        {cloneElement(firstChild, { partPosition: 'left' })}
        <DualButton.Divider />
        {cloneElement(secondChild, { partPosition: 'right' })}
      </DualButton.Root>
    </DualButtonContext.Provider>
  );
}

DualButton.Part = forwardRef((props, forwardedRef) => {
  const ref = useRef();
  const state = useContext(DualButtonContext);

  // Props always prefers useButton.
  const button = useButton(
    {
      ...props,
      // Explicit set. Note useHover.
      isDisabled: props.isDisabled,
      onPressStart(event) {
        props.onPressStart?.(event);
        state?.setPartPressedState(props.partPosition);
      },
      onPressEnd(event) {
        props.onPressEnd?.(event);
        state?.setPartPressedState(null);
      },
    },
    ref
  );

  const hover = useHover({
    // Explicit set. Note useButton.
    isDisabled: props.isDisabled,
    onHoverStart() {
      state?.setPartHoveredState(props.partPosition);
    },
    onHoverEnd() {
      state?.setPartHoveredState(null);
    },
  });

  return (
    <DualButton.Button
      {...mergeProps(button.buttonProps, hover.hoverProps)}
      ref={mergeRefs([ref, forwardedRef])}
      title={props.title}
      className={props.className}
      style={props.style}
      data-is-disabled={props.isDisabled}
      data-is-pressed={button.isPressed}
    >
      {props.children}
    </DualButton.Button>
  );
});

DualButton.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

DualButton.Part.propTypes = {
  ...Button.propTypes,
  // override: Mark title as required since this button only has icons.
  title: PropTypes.string.isRequired,
};

DualButton.Text = styled.span`
  white-space: nowrap;
`;

DualButton.Icon = ButtonIcon;

DualButton.Divider = styled.div`
  position: relative;
  width: 1px;
  height: ${su(4)};
  border-top: ${su(1)} solid ${theme.color.component};
  border-bottom: ${su(1)} solid ${theme.color.component};
  background: ${theme.color.line};
  z-index: 15;
`;

DualButton.Button = styled.button`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  min-width: ${su(4)};
  padding: ${su(1)};
  line-height: ${su(2)};
  background: ${theme.color.component};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: background-color;
  z-index: 10;

  &:first-of-type {
    border-radius: ${theme.borderRadius.default} 0 0 ${theme.borderRadius.default};
  }

  &:last-of-type {
    border-radius: 0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0;
  }

  ${DualButton.Icon.S.Root} {
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: transform;
  }

  // States
  &:not([data-is-disabled='true']) {
    &:hover {
      background-color: ${theme.monoButton.background_hover};
    }

    &[data-is-pressed='true'] {
      ${DualButton.Icon.S.Root} {
        transform: scale(0.875);
      }
    }
  }

  &[data-is-disabled='true'] {
    cursor: not-allowed;

    ${DualButton.Icon.S.Root} {
      background-color: ${theme.icon.color_disabled};
    }
  }
`;

DualButton.Root = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  background: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  border-radius: ${theme.borderRadius.default};
  transform: perspective(300px) rotateY(0deg);
  transform-origin: center center;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${theme.borderRadius.default};
    box-shadow: ${theme.boxShadow.default_hover_combo};
    opacity: 0;
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: opacity;
    z-index: -1;
  }

  &::before {
    transform: perspective(300px) rotateY(45deg);
    transform-origin: left center;
  }

  &::after {
    transform: perspective(300px) rotateY(-45deg);
    transform-origin: right center;
  }

  &[data-part-hovered='left']:not([data-part-pressed='left']) {
    &::before {
      opacity: 1;
    }
  }

  &[data-part-hovered='right']:not([data-part-pressed='right']) {
    &::after {
      opacity: 1;
    }
  }

  &[data-part-pressed='left'] {
    //transform: perspective(300px) rotateY(-3deg);
    //transform-origin: right center;

    &::before {
      box-shadow: ${theme.boxShadow.default_active_combo};
      opacity: 1;
    }
  }

  &[data-part-pressed='right'] {
    //transform: perspective(300px) rotateY(3deg);
    //transform-origin: left center;

    &::after {
      box-shadow: ${theme.boxShadow.default_active_combo};
      opacity: 1;
    }
  }
`;
