import onoff from '../images/toggle/onoff.svg';
import windowcoverings_open from '../images/toggle/windowcoverings_open.png';
import windowcoverings_closed from '../images/toggle/windowcoverings_closed.png';
import garagedoor_open from '../images/toggle/garagedoor_open.svg';
import garagedoor_closed from '../images/toggle/garagedoor_closed.svg';

import { iconToggleLocked } from '../../../theme/icons/capabilities/toggle/locked';
import { iconToggleUnlocked } from '../../../theme/icons/capabilities/toggle/unlocked';

export function getToggleImages(capabilityId, capability) {
  const [actualId] = capabilityId.split('.');

  const result = {
    urlTrue: null,
    urlFalse: null,
    useIconObj: false,
    iconObj: null,
    iconUrlTrue: null,
    iconUrlFlase: null,
  };

  switch (actualId) {
    case 'onoff':
      result.urlTrue = onoff;
      result.urlFalse = onoff;
      break;
    case 'locked':
      result.urlTrue = iconToggleLocked;
      result.urlFalse = iconToggleUnlocked;
      break;
    case 'windowcoverings_closed':
      result.urlTrue = windowcoverings_closed;
      result.urlFalse = windowcoverings_open;
      break;
    case 'garagedoor_closed':
      result.urlTrue = garagedoor_closed;
      result.urlFalse = garagedoor_open;
      break;
    default:
      if (capability.iconObj) {
        result.urlTrue = `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`;
        result.urlFalse = `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`;
        result.iconObj = capability.iconObj;
        result.useIconObj = true;
        break;
      }

      result.urlTrue = onoff;
      result.urlFalse = onoff;
  }

  result.iconUrlTrue = result.urlTrue;
  result.iconUrlFalse = result.urlFalse;

  return result;
}
