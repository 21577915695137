import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useListState, Item } from 'react-stately';
import { useListBox, useOption, useFocusRing, mergeProps } from 'react-aria';

import { Icon } from '../../../components/common/Icon';

import { HomeyImages } from '../../../theme/HomeyImages';
import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

ZoneParentListBox.Item = Item;

export function ZoneParentListBox({
  children,
  selectionMode = 'single',
  selectedKeys,
  defaultSelectedKeys,
  onSelectionChange,
}) {
  const listRef = useRef();

  const listState = useListState({
    children,
    filter: null,
    selectionMode,
    selectedKeys,
    defaultSelectedKeys,
    onSelectionChange,
  });

  const listBox = useListBox(
    {
      children,
      selectionMode,
      selectedKeys,
      defaultSelectedKeys,
      onSelectionChange,
      'aria-label': 'Parent Options',
    },
    listState,
    listRef
  );

  return (
    <>
      <List {...listBox.listBoxProps} ref={listRef}>
        {[...listState.collection].map((item) => (
          <Option key={item.key} item={item} listState={listState} />
        ))}
      </List>
    </>
  );
}

function Option({ item, listState }) {
  const optionRef = React.useRef();
  const isDisabled = listState.disabledKeys.has(item.key);
  const isSelected = listState.selectionManager.isSelected(item.key);
  const option = useOption(
    {
      'aria-label': 'Icon Option',
      key: item.key,
      isDisabled,
      isSelected,
    },
    listState,
    optionRef
  );

  const focus = useFocusRing();

  const { zone, zoneData } = item.props;

  return (
    <ListItem
      {...mergeProps(option.optionProps, focus.focusProps)}
      ref={optionRef}
      isSelected={isSelected}
      isFocusVisible={focus.isFocusVisible}
      level={zone.level}
    >
      <ListIcon
        size={theme.icon.size_default}
        url={HomeyImages.getIconForZone(zoneData.icon)}
        color={theme.color.icon_light}
      />
      <ZoneName>{zoneData.name}</ZoneName>
    </ListItem>
  );
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: 340px;
  margin: 0;
  border: 0;
  outline: 0;
  list-style: none;
  padding: ${su(1, 0)};
`;

const ListIcon = styled(Icon)``;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 40px;
  padding: ${su(1)};
  padding-left: ${(props) => su(props.level * 2 + 2)};
  outline: 0;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.background_hover};

    ${ListIcon} {
      background-color: ${theme.color.icon_dark};
    }
  }

  ${(props) =>
    props.isSelected &&
    css`
      cursor: auto;
      color: ${theme.color.text_inactive};

      ${ListIcon} {
        background-color: ${theme.color.icon_light_inactive};
      }
      &:hover {
        background-color: transparent;

        ${ListIcon} {
          background-color: ${theme.color.icon_light_inactive};
        }
      }
    `}
`;

const ZoneName = styled.div`
  margin-left: 10px;
`;
