import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import { useButton, useFocusRing, mergeProps } from 'react-aria';

import { ImageBase } from './ImageBase';

export const ImageButton = forwardRef(
  ({ url, mask, size = 48, round, children, className, motion, ...rest }, forwardedRef) => {
    const buttonRef = useRef();
    const button = useButton({ ...rest }, forwardedRef ?? buttonRef);
    const focus = useFocusRing();

    return (
      <ButtonBase
        {...mergeProps(focus.focusProps, button.buttonProps)}
        {...motion}
        ref={forwardedRef ?? buttonRef}
        width={size}
        height={size}
        className={className}
      >
        {children}
        <ImageBase url={url ?? mask} width={size} height={size} round={round} />
      </ButtonBase>
    );
  }
);

const ButtonBase = styled(motion.button)`
  appearance: none;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
`;
