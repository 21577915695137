import { currentOS, OS } from '../lib/platform';
import { useEffect } from 'react';
import { useCurrentProps } from './useCurrentProps';

const modKey = currentOS === OS.MACOS ? 'metaKey' : 'ctrlKey';
export const ignoredTags = new Set(['INPUT', 'TEXTAREA', 'SELECT']);

export function useEditorKeyboardShortcuts({
  ref,
  onCopy,
  onPaste,
  onCut,
  onDelete,
  onUndo,
  onRedo,
  onSave,
  onDuplicate,
  onSelectAll,
  onCancel,
  isDisabled,
}) {
  const currentProps = useCurrentProps({
    onCopy,
    onPaste,
    onCut,
    onDelete,
    onUndo,
    onRedo,
    onSave,
    onDuplicate,
    onSelectAll,
    onCancel,
    isDisabled,
  });

  useEffect(() => {
    function handleKeyDown(event) {
      if (
        event.defaultPrevented === true ||
        event.repeat === true ||
        currentProps.isDisabled === true
      ) {
        return;
      }

      const tagName = event.target.tagName;
      const isContentEditable = event.target.isContentEditable;

      if (ignoredTags.has(tagName) === true || isContentEditable === true) {
        return;
      }

      let parent = false;
      let parentNode = ref.current?.parentNode;

      while (parentNode != null) {
        if (parentNode === event.target) {
          parent = true;
          break;
        }
        parentNode = parentNode?.parentNode ?? null;
      }

      const contains = ref.current.contains(event.target);

      if (parent !== true && contains !== true) {
        return;
      }

      const key = event.key?.toLowerCase();

      switch (true) {
        case key === 'escape':
          currentProps.onCancel?.(event);
          break;
        case key === 'delete':
        case key === 'backspace':
          currentProps.onDelete?.(event);
          break;
        case event[modKey] && key === 'c':
          if (window.getSelection().toString().length > 0) {
            break;
          }
          currentProps.onCopy?.(event);
          break;
        case event[modKey] && key === 'v':
          currentProps.onPaste?.(event);
          break;
        case event[modKey] && key === 'x':
          currentProps.onCut?.(event);
          break;
        case event[modKey] && key === 's':
          currentProps.onSave?.(event);
          break;
        case event[modKey] && key === 'd':
          currentProps.onDuplicate?.(event);
          break;
        case event[modKey] && key === 'a':
          currentProps.onSelectAll?.(event);
          break;
        case event[modKey] && event.shiftKey !== true && key === 'z':
          currentProps.onUndo?.(event);
          break;
        case event[modKey] && event.shiftKey === true && key === 'z':
          currentProps.onRedo?.(event);
          break;
        case currentOS === OS.WINDOWS && event[modKey] && key === 'y':
          currentProps.onRedo?.(event);
          break;
        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return function () {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentProps, ref]);
}
