import { useEffect } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom';

import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';
import { generalSettingsStore } from './GeneralSettingsStore';

import { useApi } from '../../../store/HomeyStore';
// import { useAuth } from '../../../store/AuthStore';

import { theme } from '../../../theme/theme';

import { Throbber } from '../../../components/common/Throbber';

import { GeneralSettingsInfoHomey } from './GeneralSettingsInfoHomey';
import { GeneralSettingsInfoStorage } from './GeneralSettingsInfoStorage';
import { GeneralSettingsInfoMemory } from './GeneralSettingsInfoMemory';
import { GeneralSettingsInfoCloud } from './GeneralSettingsInfoCloud';
import { GeneralSettingsInfoWebApp } from './GeneralSettingsInfoWebApp';
import { GeneralSettingsInfoSystem } from './GeneralSettingsInfoSystem';
import { GeneralSettingsActions } from './GeneralSettingsActions';
import { GeneralSettingsDialogDiagnosticsReport } from './GeneralSettingsDialogDiagnosticsReport';
// import { GeneralSettingsAATP } from './GeneralSettingsAATP';

import { iconSettings } from '../../../theme/icons-v2/system/settings/settings';

export function GeneralSettings() {
  // Matches the parent route.
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  // const { experimentalFlag } = useAuth();

  const { api } = useApi();
  const { generalSettings } = generalSettingsStore.use();

  useEffect(() => {
    if (generalSettings.isLoading === true) return;
    if (generalSettings.error != null) return;

    let timeout;

    function refresh() {
      timeout = setTimeout(async () => {
        await generalSettingsStore.refresh().catch(ToastManager.handleError);
        refresh();
      }, 30 * 1000);
    }

    refresh();

    return function () {
      clearTimeout(timeout);
    };
  }, [generalSettings.isLoading, generalSettings.error]);

  if (generalSettings.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  return (
    <S.Root>
      <GeneralSettingsInfoHomey />

      {api?.isCloud === false && <GeneralSettingsInfoStorage />}

      {api?.isCloud === false && <GeneralSettingsInfoMemory />}

      <GeneralSettingsInfoCloud />

      <GeneralSettingsInfoWebApp />

      <GeneralSettingsInfoSystem />

      <GeneralSettingsActions
        onDiagnosticsReportPress={() => {
          history.push(`${location.pathname}/report`);
        }}
      />

      {/* {experimentalFlag && <GeneralSettingsAATP />} */}

      <AnimatePresence mode="wait">
        <Switch location={location} key={location.pathname}>
          <Route
            path={`${routeMatch.path}/report`}
            render={() => {
              function handleCloseRequest() {
                RouteManager.toPath(`${routeMatch.url}`);
              }

              return <GeneralSettingsDialogDiagnosticsReport onCloseRequest={handleCloseRequest} />;
            }}
          />
        </Switch>
      </AnimatePresence>
    </S.Root>
  );
}

GeneralSettings.pathKey = 'system/general';
GeneralSettings.icon = iconSettings;
GeneralSettings.backgroundColor = theme.color.system_general;

function S() {}
GeneralSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
