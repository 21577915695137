import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import cloneDeep from 'lodash.clonedeep';

import { BaseApiStore } from '../../../store/BaseApiStore';

import { useAttach } from '../../../store/useAttach';

import { __DEV__ } from '../../../lib/__dev__';
import { RouteManager } from '../../../RouteManager';
import { ResourceUtils } from '../../../store/ResourceUtils';
import { FlowCardStore } from '../../../store/flow-cards/FlowCardStore';
import { FlowCardUtils } from '../../../store/flow-cards/FlowCardUtils';

/**
 * For now it wont update the store on all flow property updates.
 * todo: convert to subscriber pattern
 * todo: keep track of edits and merge updates
 * todo: prevent updates from rerendering useFlowEditor
 */

export class FlowEditor extends BaseApiStore {
  static key = 'flowEditor';
  static store = this.createStore(this.key);
  static createInitialState() {
    return {
      data: null,
      flow: null,
      initialFlow: null,
      history: [],
      historyIndex: 0,
      loading: true,
      saving: false,
      error: null,
      manager: null,
    };
  }

  static async fetchFlow({ flowId }) {
    __DEV__ && console.info('fetch:flow');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerFlow = state.api.flow;
      const data = await managerFlow.getFlow({ id: flowId });

      const flow = cloneDeep(data);

      const conditionGroups = {
        group1: [],
        group2: [],
        group3: [],
      };

      flow.conditions?.forEach((condition) => {
        conditionGroups[condition.group].push(condition);
      });

      flow.conditions = [
        ...conditionGroups.group1,
        ...conditionGroups.group2,
        ...conditionGroups.group3,
      ];

      const actionGroups = {
        then: [],
        else: [],
      };

      flow.actions?.forEach((action) => {
        actionGroups[action.group].push(action);
      });

      flow.actions = [...actionGroups.then, ...actionGroups.else];

      const initialFlow = cloneDeep(flow);

      this.set({
        ...this.createInitialState(),
        loading: false,
        data: data,
        flow: flow,
        initialFlow: initialFlow,
        history: [initialFlow],
        historyIndex: 0,
        manager: managerFlow,
      });

      data.addListener('$update', this.handleUpdate);
      data.addListener('$delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      if (error.code === 404) {
        RouteManager.toFlows();
      }

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static handleDelete = () => {
    const state = this.get();

    state.data?.removeListener('$update', this.handleUpdate);
    state.data?.removeListener('$delete', this.handleDelete);

    this.set({
      ...this.createInitialState(),
    });
    RouteManager.toFlows();
  };

  static handleUpdate = (updatedFlow) => {
    const state = this.get();

    // if the name changed and the current edit flow name was not changed
    if (state.flow.name !== updatedFlow.name && state.flow.name === state.initialFlow.name) {
      state.initialFlow.name = updatedFlow.name;

      this.set({
        flow: {
          ...state.flow,
          name: updatedFlow.name,
        },
      });
    }
  };

  static setCreateFlow({ folderId }) {
    this.destroy();
    const state = this.get();
    const managerFlow = state.api.flow;

    const data = {
      id: 'create',
      name: '',
      folder: folderId ?? null,
      trigger: null,
      conditions: [],
      actions: [],
    };

    const initialFlow = cloneDeep(data);

    this.set({
      ...this.createInitialState(),
      loading: false,
      data: data,
      flow: initialFlow,
      initialFlow: initialFlow,
      history: [initialFlow],
      historyIndex: 0,
      manager: managerFlow,
    });
  }

  static onDetached() {
    this.destroy();
    this.set(this.createInitialState());
  }

  static destroy() {
    __DEV__ && console.info('destroy:flow');
    const state = this.get();

    if (state.api) {
      state.data?.removeListener?.('$delete', this.handleDelete);
      state.data?.removeListener?.('$update', this.handleUpdate);
    }
  }

  static saveFlow() {
    const state = this.get();
    const managerFlow = state.api.flow;

    this.set({
      saving: true,
    });

    const waitPromise = new Promise((resolve) => setTimeout(resolve, 300));
    return new Promise(async (resolve, reject) => {
      try {
        const result = await managerFlow.updateFlow({
          id: state.flow.id,
          flow: {
            trigger: state.flow.trigger,
            conditions: state.flow.conditions,
            actions: state.flow.actions,
            name: state.flow.name,
          },
        });
        await waitPromise;

        this.set({
          initialFlow: state.flow,
          saving: false,
        });

        resolve(result);
      } catch (error) {
        this.set({
          saving: false,
        });

        reject(error);
      }
    });
  }

  static createFlow() {
    const state = this.get();
    const managerFlow = state.api.flow;

    this.set({
      saving: true,
    });

    const waitPromise = new Promise((resolve) => setTimeout(resolve, 300));
    return new Promise(async (resolve, reject) => {
      try {
        const result = await managerFlow.createFlow({
          flow: {
            trigger: state.flow.trigger,
            conditions: state.flow.conditions,
            actions: state.flow.actions,
            name: state.flow.name,
            folder: state.flow.folder,
          },
        });
        await waitPromise;

        this.set({
          initialFlow: state.flow,
          saving: false,
        });

        resolve(result);
        RouteManager.toFlow(result.id);
      } catch (error) {
        this.set({
          saving: false,
        });

        reject(error);
      }
    });
  }

  static validate({ type } = {}) {
    const { flow } = this.get();

    const validation = {};
    const { triggers, conditions, actions } = FlowCardStore.get();

    if ((flow.name == null || flow.name.length === 0) && type !== 'test') {
      validation.name = {
        type: 'flow.validation.missing_name',
      };
    }

    if (flow.trigger == null) {
      validation.trigger = {
        type: 'flow.validation.missing_trigger',
      };
    } else {
      const triggerCard = triggers?.byKey?.[ResourceUtils.getKey(flow.trigger)];
      const cardTitle = FlowCardUtils.getTitle(triggerCard);

      if (triggerCard?.droptoken != null && flow.trigger.droptoken === null) {
        validation[`trigger::0::droptoken`] = {
          type: 'flow.validation.missing_droptoken',
          cardTitle: cardTitle,
        };
      }

      Object.entries(flow.trigger.args ?? {}).forEach(([key, value]) => {
        const cardArg = triggerCard?.args?.find((arg) => arg.name === key);
        const isRequired = cardArg?.required ?? true;

        if (value == null && isRequired)
          validation[`trigger::0::${key}`] = {
            type: 'flow.validation.missing_argument',
            cardTitle: cardTitle,
            argTitle: cardArg.title ?? cardArg.type,
          };
      });
    }

    if (flow.conditions.length > 0) {
      flow.conditions.forEach((condition, index) => {
        const conditionCard = conditions?.byKey?.[ResourceUtils.getKey(condition)];
        const cardTitle = FlowCardUtils.getTitle(conditionCard);

        if (conditionCard?.droptoken != null && condition.droptoken === null) {
          validation[`condition::${index}::droptoken`] = {
            type: 'flow.validation.missing_droptoken',
            cardTitle: cardTitle,
          };
        }

        Object.entries(condition.args ?? {}).forEach(([key, value]) => {
          const cardArg = conditionCard?.args?.find((arg) => arg.name === key);
          const isRequired = cardArg?.required ?? true;

          if (value == null && isRequired) {
            validation[`action::${index}::${key}`] = {
              type: 'flow.validation.missing_argument',
              cardTitle: cardTitle,
              argTitle: cardArg.title ?? cardArg.type,
            };
          }
        });
      });
    }

    if (flow.actions.length === 0) {
      validation.actions = {
        type: 'flow.validation.missing_action',
      };
    } else {
      flow.actions.forEach((action, index) => {
        const actionCard = actions?.byKey?.[ResourceUtils.getKey(action)];
        const cardTitle = FlowCardUtils.getTitle(actionCard);

        if (actionCard?.droptoken != null && action.droptoken === null) {
          validation[`action::${index}::droptoken`] = {
            type: 'flow.validation.missing_droptoken',
            cardTitle: cardTitle,
          };
        }

        Object.entries(action.args ?? {}).forEach(([key, value]) => {
          const cardArg = actionCard?.args?.find((arg) => arg.name === key);
          const isRequired = cardArg?.required ?? true;

          if (value == null && isRequired) {
            validation[`action::${index}::${key}`] = {
              type: 'flow.validation.missing_argument',
              cardTitle: cardTitle,
              argTitle: cardArg.title ?? cardArg.type,
            };
          }
        });
      });
    }

    return validation;
  }

  static setNextFlow({ flow, state }) {
    const nextHistory = [...state.history.slice(0, state.historyIndex + 1), cloneDeep(flow)];

    this.set({
      flow: flow,
      history: nextHistory,
      historyIndex: nextHistory.length - 1,
    });
  }

  static undo() {
    const state = this.get();

    const flow = state.history[state.historyIndex - 1];
    if (!flow) return;

    const nextHistoryIndex = state.historyIndex - 1;

    this.set({
      flow: cloneDeep(flow),
      historyIndex: nextHistoryIndex,
    });
  }

  static redo() {
    const state = this.get();

    const flow = state.history[state.historyIndex + 1];
    if (!flow) return;

    const nextHistoryIndex = state.historyIndex + 1;

    this.set({
      flow: cloneDeep(flow),
      historyIndex: nextHistoryIndex,
    });
  }

  static setProperty({ property, value }) {
    const state = this.get();
    const flow = state.flow;

    const nextFlow = {
      ...flow,
      [property]: value,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static setTrigger({ cardKey, cardIndex, card }) {
    const state = this.get();
    const cardArgs = card.args ?? [];

    const nextFlow = {
      ...state.flow,
      trigger: {
        ...FlowCardUtils.generateFlowCardDataIdProperties(card),
        droptoken: card.droptoken ? null : undefined,
        args: cardArgs.reduce((accumulator, arg) => {
          accumulator[arg.name] = arg.value ?? undefined;
          return accumulator;
        }, {}),
      },
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static setCondition({ cardKey, cardIndex, card }) {
    const state = this.get();
    const cardArgs = card.args ?? [];

    const nextConditions = [...state.flow.conditions];

    let group;

    if (cardIndex != null) {
      const prevCondition = nextConditions[cardIndex];
      group = prevCondition.group;
    } else {
      const groupNum = nextConditions.reduce((accumulator, condition) => {
        const group = condition.group;
        const groupNum = parseInt(group.replace('group', ''), 10);

        if (groupNum > accumulator) return groupNum;

        return accumulator;
      }, 1);
      group = `group${groupNum}`;
    }

    const nextCondition = {
      ...FlowCardUtils.generateFlowCardDataIdProperties(card),
      group: group,
      droptoken: card.droptoken ? null : undefined,
      inverted: false,
      args: cardArgs.reduce((accumulator, arg) => {
        accumulator[arg.name] = arg.value ?? undefined;
        return accumulator;
      }, {}),
    };

    if (cardIndex != null) {
      nextConditions[cardIndex] = nextCondition;
    } else {
      // todo: find last index based on group
      nextConditions.push(nextCondition);
    }

    const nextFlow = {
      ...state.flow,
      conditions: nextConditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static setAction({ cardKey, cardIndex, card }) {
    const state = this.get();
    const cardArgs = card.args ?? [];

    const nextActions = [...state.flow.actions];

    let group;

    if (cardIndex != null) {
      const prevAction = nextActions[cardIndex];
      group = prevAction.group;
    } else {
      group = nextActions.reduce((accumulator, action) => {
        const group = action.group;

        if (group === 'else') {
          return 'else';
        }

        return accumulator;
      }, 'then');
    }

    const nextAction = {
      ...FlowCardUtils.generateFlowCardDataIdProperties(card),
      group: group,
      delay: null,
      duration: null,
      droptoken: card.droptoken ? null : undefined,
      args: cardArgs.reduce((accumulator, arg) => {
        accumulator[arg.name] = arg.value ?? undefined;
        return accumulator;
      }, {}),
    };

    if (cardIndex != null) {
      nextActions[cardIndex] = nextAction;
    } else {
      // todo: find last index based on group
      nextActions.push(nextAction);
    }

    const nextFlow = {
      ...state.flow,
      actions: nextActions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static setActions({ actions }) {
    const state = this.get();

    const nextFlow = {
      ...state.flow,
      actions: actions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static setConditions({ conditions }) {
    const state = this.get();

    const nextFlow = {
      ...state.flow,
      conditions: conditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static updateCard({ cardType, cardIndex, argumentKey, value }) {
    switch (cardType) {
      case 'trigger':
        this.updateTriggerCard({ cardIndex, argumentKey, value });
        break;
      case 'condition':
        this.updateConditionCard({ cardIndex, argumentKey, value });
        break;
      case 'action':
        this.updateActionCard({ cardIndex, argumentKey, value });
        break;
      default:
        break;
    }
  }

  static updateTriggerCard({ cardIndex, argumentKey, value }) {
    const state = this.get();
    const trigger = state.flow.trigger;
    const nextTrigger = {
      ...trigger,
      args: {
        ...trigger.args,
        [argumentKey]: value,
      },
    };

    const nextFlow = {
      ...state.flow,
      trigger: nextTrigger,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static updateConditionCard({ cardIndex, argumentKey, value }) {
    const state = this.get();
    const condition = state.flow.conditions[cardIndex];
    const nextCondition = {
      ...condition,
      args: {
        ...condition.args,
        [argumentKey]: value,
      },
    };

    const nextConditions = [...state.flow.conditions];
    nextConditions[cardIndex] = nextCondition;

    const nextFlow = {
      ...state.flow,
      conditions: nextConditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static updateActionCard({ cardIndex, argumentKey, value }) {
    const state = this.get();
    const action = state.flow.actions[cardIndex];
    const nextAction = {
      ...action,
      args: {
        ...action.args,
        [argumentKey]: value,
      },
    };

    const nextActions = [...state.flow.actions];
    nextActions[cardIndex] = nextAction;

    const nextFlow = {
      ...state.flow,
      actions: nextActions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static updateCardProperty({ cardType, ...rest }) {
    switch (cardType) {
      case 'trigger':
        this.updateTriggerCardProperty(rest);
        break;
      case 'condition':
        this.updateConditionCardProperty(rest);
        break;
      case 'action':
        this.updateActionCardProperty(rest);
        break;
      default:
        break;
    }
  }

  static updateTriggerCardProperty({ cardIndex, property, value }) {
    const state = this.get();
    const trigger = state.flow.trigger;
    const nextTrigger = {
      ...trigger,
      [property]: value,
    };

    const nextFlow = {
      ...state.flow,
      trigger: nextTrigger,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static updateConditionCardProperty({ cardIndex, property, value }) {
    const state = this.get();
    const condition = state.flow.conditions[cardIndex];
    const nextCondition = {
      ...condition,
      [property]: value,
    };

    const nextConditions = [...state.flow.conditions];
    nextConditions[cardIndex] = nextCondition;

    const nextFlow = {
      ...state.flow,
      conditions: nextConditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static updateActionCardProperty({ cardIndex, property, value }) {
    const state = this.get();
    const action = state.flow.actions[cardIndex];
    const nextAction = {
      ...action,
      [property]: value,
    };

    const nextActions = [...state.flow.actions];
    nextActions[cardIndex] = nextAction;

    const nextFlow = {
      ...state.flow,
      actions: nextActions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static deleteTriggerCard({ cardIndex }) {
    const state = this.get();

    const nextFlow = {
      ...state.flow,
      trigger: null,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static deleteConditionCard({ cardIndex }) {
    const state = this.get();
    const nextConditions = [...state.flow.conditions];
    nextConditions.splice(cardIndex, 1);

    const nextFlow = {
      ...state.flow,
      conditions: nextConditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static deleteActionCard({ cardIndex }) {
    const state = this.get();
    const nextActions = [...state.flow.actions];
    nextActions.splice(cardIndex, 1);

    const nextFlow = {
      ...state.flow,
      actions: nextActions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static duplicateConditionCard({ cardIndex }) {
    const state = this.get();
    const nextConditions = [...state.flow.conditions];
    const condition = state.flow.conditions[cardIndex];

    nextConditions.splice(cardIndex, 0, cloneDeep(condition));

    const nextFlow = {
      ...state.flow,
      conditions: nextConditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static duplicateActionCard({ cardIndex }) {
    const state = this.get();
    const nextActions = [...state.flow.actions];
    const action = state.flow.actions[cardIndex];

    nextActions.splice(cardIndex, 0, cloneDeep(action));

    const nextFlow = {
      ...state.flow,
      actions: nextActions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }

  static invertConditionCard({ cardIndex }) {
    const state = this.get();
    const condition = state.flow.conditions[cardIndex];
    const nextCondition = {
      ...condition,
      inverted: !condition.inverted,
    };

    const nextConditions = [...state.flow.conditions];
    nextConditions[cardIndex] = nextCondition;

    const nextFlow = {
      ...state.flow,
      conditions: nextConditions,
    };

    this.setNextFlow({
      flow: nextFlow,
      state: state,
    });
  }
}

export function useSetFlowEditor({ flowId, folderId, resetId }) {
  useAttach(FlowEditor, 'flowEditor');

  // only start fetching when the api is present
  // in the FlowEditor store provided by useAttach
  const api = FlowEditor.store(selectApi);

  useEffect(() => {
    if (api != null && flowId != null) {
      if (flowId === 'create') {
        FlowEditor.setCreateFlow({ folderId });
      } else {
        FlowEditor.fetchFlow({ flowId });
      }
    }
  }, [api, flowId, folderId, resetId]);

  useEffect(() => {
    return function () {
      FlowEditor.onDetached();
    };
  }, []);
}

export function useFlowEditor() {
  useAttach(FlowEditor, 'flowEditor');
  return FlowEditor.store(select, shallow);
}

function selectApi(state) {
  return state.api;
}

function select(state) {
  return {
    data: state.data,
    flow: state.flow,
    initialFlow: state.initialFlow,
    history: state.history,
    historyIndex: state.historyIndex,
    loading: state.loading,
    saving: state.saving,
    error: state.error,
    manager: state.manager,
    retry: state.retry,
  };
}
