import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import { BaseStore, BaseStoreNonStatic } from './BaseStore';
import { HomeyStore } from './HomeyStore';

/**
 * @typedef {Object} BaseApiStoreState
 * @property {any} mounts
 * @property {any} timeout
 * @property {import('athom-api').HomeyAPI} api
 * @property {any} fetched
 * @property {any} retry
 * @property {any} refresh
 */

/**
 * @extends {BaseStore}
 */
export class BaseApiStore extends BaseStore {
  /**
   * @returns {BaseApiStoreState} initialState
   */
  static createInitialBaseState() {
    return {
      mounts: new Set(),
      timeout: null,
      api: null,
      fetched: false,
    };
  }

  /**
   * @abstract
   * @returns {Object<string, any>}
   */
  static createInitialState() {
    throw new Error('must be implemented by subclass!');
  }

  /**
   * @abstract
   */
  static onDetached() {
    throw new Error('must be implemented by subclass!');
  }

  static selectApi(state) {
    return {
      api: state.api,
    };
  }

  static getApi() {
    const { api } = this.get();
    if (api == null) throw new Error('Missing Api');

    return api;
  }

  /**
   * @param {{ api: import('athom-api').HomeyAPI | null }} param
   */
  static onApi({ api }) {
    // console.log(`${this.key}:onApi`);
    const state = this.get();

    if (state.api !== api) {
      this.destroy?.('onApi');
      state.api = api;
      state.fetched = false;
    }

    if (api != null) {
      if (state.mounts.size > 0) {
        state.fetched = true;
        this.fetchData?.().catch(console.error);
        this.onSetApi?.();
      } else {
        this.set(this.createInitialState());
      }
    } else {
      this.set(this.createInitialState());
    }
  }

  /**
   * @param {string} key
   * @param {Object<string, any>} [staticPartialState]
   */
  static createStore(key, staticPartialState) {
    const initialState = this.createInitialState();
    const initialBaseState = this.createInitialBaseState();

    const store = create(
      devtools(
        subscribeWithSelector((set, get, api) => {
          return {
            ...initialState,
            ...initialBaseState,
            ...staticPartialState,
            retry: (args) => {
              const { api } = get();
              api != null && this.fetchData(args).catch(console.error);
            },
            refresh: () => {
              const { retry } = get();
              retry({ silent: true, skipCache: true });
            },
          };
        }),
        { name: key }
      )
    );

    this.apiUnsubscribe = HomeyStore.store.subscribe(this.selectApi, this.onApi.bind(this), {
      equalityFn: shallow,
    });

    return store;
  }

  /**
   * @param {import('react').RefObject} ref
   */
  static detach(ref) {
    const state = this.get();
    state.mounts.delete(ref);

    if (state.mounts.size === 0) {
      // Check if onDetached was overidden to decide if its a normal store api store.
      // It doesnt have cached resources/listeners from HomeyAPI. So we dont have the normal
      // destroy behavior.
      if (this.onDetached !== BaseApiStore.onDetached) {
        state.timeout = setTimeout(() => {
          this.onDetached();
        }, 0);
      } else {
        // Destroy after 1 mins.
        state.timeout = setTimeout(() => {
          this.destroy?.('detached');
          this.set({
            ...this.createInitialState(),
            fetched: false,
          });
        }, 60 * 1000);
      }
    }
  }

  /**
   * @param {import('react').RefObject} ref
   */
  static attach(ref) {
    const state = this.get();
    clearTimeout(state.timeout);
    state.mounts.add(ref);

    if (state.fetched === false && state.api != null) {
      state.fetched = true;
      this.fetchData?.().catch(console.error);
      this.onSetApi?.();
    }
  }
}

export class BaseApiStoreNonStatic extends BaseStoreNonStatic {
  /**
   * @returns {BaseApiStoreState} initialState
   */
  createInitialBaseState() {
    return {
      mounts: new Set(),
      timeout: null,
      api: null,
      fetched: false,
    };
  }

  /**
   * @abstract
   * @returns {Object<string, any>}
   */
  createInitialState() {
    throw new Error('must be implemented by subclass!');
  }

  /**
   * @abstract
   */
  onDetached() {
    throw new Error('must be implemented by subclass!');
  }

  selectApi(state) {
    return {
      api: state.api,
    };
  }

  /**
   * @param {{ api: import('athom-api').HomeyAPI | null }} param
   */
  onApi({ api }) {
    // console.log(`${this.key}:onApi`);
    const state = this.get();

    if (state.api !== api) {
      this.destroy?.('onApi');
      state.api = api;
      state.fetched = false;
    }

    if (api != null) {
      if (state.mounts.size > 0) {
        state.fetched = true;
        this.fetchData?.().catch(console.error);
        this.onSetApi?.();
      } else {
        this.set(this.createInitialState());
      }
    } else {
      this.set(this.createInitialState());
    }
  }

  /**
   * @param {string} key
   * @param {Object<string, any>} [staticPartialState]
   */
  createStore(key, staticPartialState) {
    const initialState = this.createInitialState();
    const initialBaseState = this.createInitialBaseState();

    const store = create(
      devtools(
        subscribeWithSelector((set, get, api) => {
          return {
            ...initialState,
            ...initialBaseState,
            ...staticPartialState,
            retry: (args) => {
              const { api } = get();
              api != null && this.fetchData(args).catch(console.error);
            },
            refresh: () => {
              const { retry } = get();
              retry({ silent: true, skipCache: true });
            },
          };
        }),
        { name: key }
      )
    );

    this.apiUnsubscribe = HomeyStore.store.subscribe(this.selectApi, this.onApi.bind(this), {
      equalityFn: shallow,
    });

    return store;
  }

  /**
   * @param {import('react').RefObject} ref
   */
  detach(ref) {
    const state = this.get();
    state.mounts.delete(ref);

    if (state.mounts.size === 0) {
      // Check if onDetached was overidden to decide if its a normal store api store.
      // It doesnt have cached resources/listeners from HomeyAPI. So we dont have the normal
      // destroy behavior.
      if (this.onDetached !== this.constructor.prototype.onDetached) {
        state.timeout = setTimeout(() => {
          this.onDetached();
        }, 0);
      } else {
        // Destroy after 1 mins.
        state.timeout = setTimeout(() => {
          this.destroy?.('detached');
          this.set({
            ...this.createInitialState(),
            fetched: false,
          });
        }, 60 * 1000);
      }
    }
  }

  /**
   * @param {import('react').RefObject} ref
   */
  attach(ref) {
    const state = this.get();
    clearTimeout(state.timeout);
    state.mounts.add(ref);

    if (state.fetched === false && state.api != null) {
      state.fetched = true;
      this.fetchData?.().catch(console.error);
      this.onSetApi?.();
    }
  }
}
