import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useGlobalListeners } from '@react-aria/utils';

import { theme } from '../../../theme/theme';

export function TemperatureRadial(props) {
  const { addGlobalListener, removeAllGlobalListeners } = useGlobalListeners();

  const colorRadialRef = useRef();
  const colorRadialSelectorRef = useRef();
  const stateRef = useRef({
    color: {
      temperature: props.temperature,
      x: props.x,
    },
    baseBoundingClientRect: null,
    isMoving: false,
  });

  function handleOnPointerMove(event) {
    // When mouseup outside viewport window, then cleanup and stop moving.
    if (event.buttons === 0) {
      return cleanup();
    }

    stateRef.current.isMoving = true;

    const baseBoundingClientRect = stateRef.current.baseBoundingClientRect;
    const radius = baseBoundingClientRect.width / 2;

    // Calculate relative mouse position to ColorRadial
    const pickerRelativePage = {
      x: event.pageX - baseBoundingClientRect.x,
      y: event.pageY - baseBoundingClientRect.y,
    };

    // Calculate relative mouse position to the center of ColorRadial
    const pickerCenterRelativePage = {
      x: pickerRelativePage.x - radius,
      y: pickerRelativePage.y - radius,
    };

    let selectorPosition = {
      x: pickerCenterRelativePage.x,
      y: pickerCenterRelativePage.y,
    };

    const distance = Math.sqrt(pickerCenterRelativePage.x ** 2 + pickerCenterRelativePage.y ** 2);
    const radians = Math.atan2(pickerCenterRelativePage.y, pickerCenterRelativePage.x);

    if (distance > radius) {
      selectorPosition.x = radius * Math.cos(radians);
      selectorPosition.y = radius * Math.sin(radians);
    }

    const temperature = (selectorPosition.y + radius) / baseBoundingClientRect.height;
    const x = (selectorPosition.x + radius) / baseBoundingClientRect.width;

    stateRef.current.color = { temperature, x: x };
    updateSelector({ temperature, x: x });
  }

  useEffect(() => {
    stateRef.current.baseBoundingClientRect = colorRadialRef.current.getBoundingClientRect();
  }, []);

  useEffect(() => {
    const color = stateRef.current.color;
    color.temperature = props.temperature;
    color.x = props.x;

    if (stateRef.current.isMoving === false) {
      updateSelector({ temperature: color.temperature, x: color.x });
    }
  }, [props.temperature, props.x]);

  function cleanup() {
    stateRef.current.isMoving = false;
    removeAllGlobalListeners();
  }

  function updateSelector({ temperature, x }) {
    const baseBoundingClientRect = stateRef.current.baseBoundingClientRect;
    const y = temperature * baseBoundingClientRect.height - baseBoundingClientRect.height / 2;
    if (x && x > 0) {
      x = x * baseBoundingClientRect.width - baseBoundingClientRect.width / 2;
    }

    // Set default color
    let temperatureColor = `hsl(181, 100%, 100%)`;

    // When cold temperature
    if (temperature < 0.5) {
      const lightness = 90 + temperature * 2 * 10;
      temperatureColor = `hsl(181, 100%, ${lightness}%)`;
    }
    // When warm temperature
    else if (temperature > 0.5) {
      const lightness = 75 + (1 - temperature) * 2 * 25;
      temperatureColor = `hsl(42, 100%, ${lightness}%)`;
    }

    // Temperature can be 0 so check if temperature is a number
    if (typeof temperature === 'number') {
      colorRadialSelectorRef.current.style.setProperty('--temperature', temperatureColor);
    }

    colorRadialSelectorRef.current.style.setProperty('--y', `${y}px`);

    if (x) {
      colorRadialSelectorRef.current.style.setProperty('--x', `${x}px`);
    }
  }

  function setLightTemperature() {
    const temperature = stateRef.current.color;
    if (temperature) {
      props.onChange(temperature);
    }
  }

  function handleSelectorOnPointerDown(event) {
    stateRef.current.baseBoundingClientRect = colorRadialRef.current.getBoundingClientRect();

    handleOnPointerMove(event);

    addGlobalListener(window, 'pointermove', handleOnPointerMove);
    addGlobalListener(window, 'pointerup', handleSelectorOnPointerUp);
  }

  function handleSelectorOnPointerUp(event) {
    cleanup();
    stateRef.current.isMoving = false;
    setLightTemperature();
  }

  return (
    <TemperatureRadial.Root
      ref={colorRadialRef}
      onPointerDown={(event) => handleSelectorOnPointerDown(event)}
    >
      <TemperatureRadial.Center>
        <TemperatureRadial.Selector
          ref={colorRadialSelectorRef}
          onPointerDown={(event) => handleSelectorOnPointerDown(event)}
          onPointerUp={(event) => handleSelectorOnPointerUp(event)}
        />
      </TemperatureRadial.Center>
    </TemperatureRadial.Root>
  );
}

TemperatureRadial.Root = styled.div`
  --temperature-radial-size: 250px;
  position: relative;
  width: var(--temperature-radial-size);
  height: var(--temperature-radial-size);
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &::before {
    z-index: 0;
    background: linear-gradient(
      hsl(181, 100%, 90%) 0%,
      hsl(181, 100%, 100%) 50%,
      hsl(42, 100%, 100%) 50%,
      hsl(42, 100%, 75%) 100%
    );
  }
`;

TemperatureRadial.Center = styled.div`
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
`;

TemperatureRadial.Selector = styled.button`
  position: absolute;
  z-index: 30;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--temperature);
  top: var(--y, 0);
  left: var(--x, 0);
  box-shadow: 0 0 3px ${theme.color.black_o_50}, inset 0 0 3px ${theme.color.black_o_50};
  cursor: grab;
  border: 2px solid ${theme.color.white};
  transition: transform ${theme.duration.fast} ${theme.curve.fastIn};
  transform: translate(-50%, -50%);

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &:active:hover {
    transform: translate(-50%, -50%) scale(0.9);
    cursor: grabbing;
  }
`;
