import React from 'react';
import styled from '@emotion/styled';

import { mergeProps, useFocusRing } from 'react-aria';
import { useOptionContext } from '../../../list-box/ListBox';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';

import { Icon } from '../../../common/Icon';
import { Image } from '../../../common/Image';

export function AppItem(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  const owner = value.context.owner;

  return (
    <AppItem.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      {item.key === 'homey:manager:vdevice' ? (
        <Image url={'/img/logo192.png'} size="50px" />
      ) : (
        <AppItem.IconWrapper style={{ '--icon-wrapper-background-color': owner.color }}>
          <Icon url={owner.iconUrl} color={theme.color.white} size={theme.icon.size_large} />
        </AppItem.IconWrapper>
      )}

      <AppItem.Name>{item.textValue}</AppItem.Name>
    </AppItem.Root>
  );
}

AppItem.Root = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  cursor: pointer;
  transition: ${theme.duration.micro} ${theme.curve.easeInOut};
  transition-property: background-color;

  &[data-is-focus-visible='true'],
  &:hover {
    background-color: ${theme.color.background_hover};
  }
`;

AppItem.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--icon-wrapper-background-color, ${theme.color.black});
`;

AppItem.Name = styled.div`
  flex: 1 1 0;
  margin-left: ${su(1)};
`;
