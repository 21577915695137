import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useOverlayTriggerState } from 'react-stately';
import { useKeyboard, useLabel } from 'react-aria';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { theme } from '../../../theme/theme';

import { argInput } from './argInput';
import { ArgumentBase, Argument } from './Argument';

export function ArgumentColor(props) {
  const { i18n } = useI18n();

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.color.title');
  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  let renderValue = null;

  switch (true) {
    case localValue != null:
      renderValue = (
        <ArgumentColor.Value style={{ '--argument-color-current-color': localValue }} />
      );
      break;
    default:
      break;
  }

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? localValue : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    setLocalValue(value);
    checkInvalid(value, { checkNode: false });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-invalid={isInvalid}
            data-is-empty={renderValue == null}
          >
            {renderValue ?? props.argumentTypeText}
          </ArgumentBase>
        );
      }}
      renderOverlay={() => {
        return (
          <ColorInput
            label={label}
            localValue={localValue}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
          />
        );
      }}
    />
  );
}

function ColorInput(props) {
  const containerRef = useRef();
  const label = useLabel({
    label: props.label,
  });

  const value = props.localValue ?? '#00ffcc';

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker
    const walker = getFocusableTreeWalker(containerRef.current, { tabbable: true });

    const nextNode = walker.nextNode();
    if (nextNode != null) {
      // Prevent instant close.
      requestAnimationFrame(() => {
        nextNode.focus();
      });
    }
  }, []);

  const keyboard = useKeyboard({
    onKeyDown(event) {
      // Consume enter and delete key and propagate rest.
      if (event.key === 'Enter') {
        event.preventDefault();
        props.onSaveRequest();
      } else if (event.key === 'Delete') {
        event.preventDefault();
        props.onChange(undefined);
      } else {
        event.continuePropagation();
      }
    },
    onKeyUp(event) {
      // Consume enter and delete key and propagate rest.
      if (event.key === 'Enter') {
        event.preventDefault();
      } else if (event.key === 'Delete') {
        event.preventDefault();
      } else {
        event.continuePropagation();
      }
    },
  });

  return (
    <ColorInput.InputContainer {...keyboard.keyboardProps} ref={containerRef}>
      <argInput.InputLabel {...label.labelProps}>{props.label}</argInput.InputLabel>

      <ColorInput.HexColorPicker color={value} onChange={props.onChange} />
      <ColorInput.HexColorInput {...label.fieldProps} color={value} onChange={props.onChange} />
    </ColorInput.InputContainer>
  );
}

ArgumentColor.Value = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: -4px 0;
  border-radius: 50%;
  background-color: var(--argument-color-current-color);

  [aria-disabled='true'][data-is-disabled-style='unreachable'] & {
    background-color: ${theme.flowcard.disabled_background_color};
  }
`;

ColorInput.InputContainer = styled.div`
  padding: 20px;
  width: 240px;
`;

ColorInput.HexColorPicker = styled(HexColorPicker)`
  margin-top: 10px;
  outline: 0;

  .react-colorful {
    &__hue {
      height: 8px;
      margin-top: 10px;
      border-radius: 4px;
    }

    &__pointer {
      width: 20px;
      height: 20px;
      border-width: 1px;
      cursor: grab;
    }

    &__hue-pointer {
    }
  }
`;

ColorInput.HexColorInput = styled(HexColorInput)`
  width: 100%;
  min-width: 0;
  padding: 5px;
  margin-top: 10px;
  border: 1px solid ${theme.color.line};
  border-radius: ${theme.borderRadius.default};
  background-color: ${theme.color.component};
  color: ${theme.color.text};
  outline: 0;
  line-height: 1.25;
`;
