import { shallow } from 'zustand/shallow';

import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

import { HeaderActionsContainer } from '../../../components/header/HeaderActionsContainer';
import { AddCardButton } from './AddCardButton';
import { VariablesButton } from '../../logic/variables/VariablesButton';
import { DualButton } from '../../../components/buttons/DualButton';
import { SaveButton } from './SaveButton';

import { iconActionRedo } from '../../../theme/icons/interface/action-redo';
import { iconActionUndo } from '../../../theme/icons/interface/action-undo';

function selectActionsDependencies(state) {
  return {
    history: state.history,
    historyIndex: state.historyIndex,
    hasStartCard: state.hasStartCard,
    conditionSave: state.conditionSave,
    advancedFlow: state.advancedFlow,
    initialAdvancedFlow: state.initialAdvancedFlow,
  };
}

function useActionsDependencies() {
  return AdvancedFlowViewStore.store(selectActionsDependencies, shallow);
}

export function AdvancedFlowActions(props) {
  const { i18n } = useI18n();
  const { history, historyIndex, conditionSave, hasStartCard, advancedFlow, initialAdvancedFlow } =
    useActionsDependencies();

  const canUndo = history[historyIndex - 1] != null;
  const canRedo = history[historyIndex + 1] != null;

  const hasUnsavedChanges = advancedFlow !== initialAdvancedFlow;
  const isSaving = conditionSave === AdvancedFlowViewStore.conditionSave.saving;

  function handleSavePress() {
    AdvancedFlowViewStore.saveAdvancedFlow({
      route: true,
    }).catch(console.error);
  }

  function handleUndo() {
    AdvancedFlowViewStore.undo();
  }

  function handleRedo() {
    AdvancedFlowViewStore.redo();
  }

  return (
    <HeaderActionsContainer>
      <AddCardButton hasStartCard={hasStartCard} />
      <DualButton>
        <DualButton.Part
          title={i18n.messageFormatter('common.undo')}
          isDisabled={canUndo === false || isSaving}
          onPress={handleUndo}
        >
          <DualButton.Icon
            url={iconActionUndo}
            color={theme.color.icon_dark}
            size={theme.icon.size_small}
          />
        </DualButton.Part>
        <DualButton.Part
          title={i18n.messageFormatter('common.redo')}
          isDisabled={canRedo === false || isSaving}
          onPress={handleRedo}
        >
          <DualButton.Icon
            url={iconActionRedo}
            color={theme.color.icon_dark}
            size={theme.icon.size_small}
          />
        </DualButton.Part>
      </DualButton>
      <VariablesButton />
      <SaveButton
        hasUnsavedChanges={hasUnsavedChanges}
        isSaving={isSaving}
        onPress={handleSavePress}
      />
    </HeaderActionsContainer>
  );
}
