import { FocusScope } from 'react-aria';

import { RouteManager } from '../../RouteManager';
import { ToastManager } from '../../ToastManager';
import { UserMeStore } from '../../store/user-me/UserMeStore';
import { ResourceUtils } from '../../store/ResourceUtils';

import { platform } from '../../lib/platform';
import { promptMessage } from '../overlay/DialogPrompt';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useContextMenuContext } from '../common/context-menu/ContextMenuContextProvider';
import { useUserMe } from '../../store/user-me/useUserMe';
import { useDevice } from '../../store/devices/useDevices';
import { useFlowsByCardOwnerUri } from '../../store/flow/useFlows';
import { useAdvancedFlowsByCardOwnerUri } from '../../store/advanced-flow/useAdvancedFlows';
import { useLogsByOwnerUri } from '../../store/insights/useInsights';

import { theme } from '../../theme/theme';

import { ContextMenuContent } from '../common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../common/context-menu/DeveloperMenu';
import { ZoneListBox } from '../zone/ZoneListBox';

import { iconFavorite } from '../../theme/icons/interface/heart-fill';
import { iconNotFavorite } from '../../theme/icons/interface/heart-outline';
import { iconMouseLeftClick } from '../../theme/icons/interface/mouse-left-click';
import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';

export function DeviceContextMenuContent(props) {
  const { i18n } = useI18n();
  const ctx = useContextMenuContext();

  const { device, api } = useDevice({ deviceId: props.deviceId });
  const flows = useFlowsByCardOwnerUri();
  const advancedFlows = useAdvancedFlowsByCardOwnerUri();
  const logs = useLogsByOwnerUri();

  const userMe = useUserMe();

  if (device == null) return null;

  const isFavoriteDevice = userMe.favoriteDevices?.includes(device.id) ?? false;
  const deviceFlows = flows.byCardOwnerUri?.[`homey:device:${props.deviceId}`] ?? null;
  const deviceAdvancedFlows =
    advancedFlows.byCardOwnerUri?.[`homey:device:${props.deviceId}`] ?? null;
  const deviceLogs = logs.byOwnerUri?.[`homey:device:${props.deviceId}`] ?? null;

  const numberLogs = Object.values(deviceLogs?.data ?? {}).filter((log) => {
    return log.type === 'number';
  });
  const deviceFlowsAndAdvancedFlows = Object.values({ ...deviceFlows, ...deviceAdvancedFlows });

  async function onAction(key) {
    switch (key) {
      case 'quickaction':
        props.onQuickActionRequest?.();
        break;
      case 'rename':
        props.onRenameRequest();
        break;
      case 'favorite':
        if (isFavoriteDevice) {
          unfavorite(device, i18n.messageFormatter);
          return;
        }

        favorite(device, i18n.messageFormatter);
        break;
      case 'settings':
        RouteManager.toDeviceSettings(device.id);
        break;
      case 'repair':
        const driverKey = ResourceUtils.getKey({
          uri: device.driverUri,
          id: device.driverId,
        });

        RouteManager.pushState({
          pairDialog: {
            type: 'repair',
            driverKey: driverKey,
            deviceId: device.id,
          },
        });

        break;
      case 'insights':
        const keys = numberLogs.map((log) => {
          return ResourceUtils.temp__getId(log);
        });

        RouteManager.toInsights({
          defaultSelectedKeys: keys,
          deviceId: device.id,
        });
        break;
      case 'flows':
        RouteManager.toFlows({
          deviceId: device.id,
        });
        break;
      case 'delete':
        if (device.unpair) {
          const driverKey = ResourceUtils.getKey({
            uri: device.driverUri,
            id: device.driverId,
          });

          RouteManager.pushState({
            pairDialog: {
              type: 'unpair',
              driverKey: driverKey,
              deviceId: device.id,
            },
          });
          return;
        }

        try {
          await promptMessage({
            message: i18n.messageFormatter('prompt.delete', {
              name: device.name,
            }),
          });

          try {
            await api.devices.deleteDevice({
              id: device.id,
            });
          } catch (error) {
            ToastManager.handleError(error);
          }
        } catch {}
        break;
      default:
        break;
    }
  }

  async function handleSelectionChange(keys, listState) {
    const [key] = keys;
    const item = listState.collection.getItem(key);
    ctx.onCloseRequest();

    if (item != null) {
      try {
        await api.devices.updateDevice({
          id: device.id,
          device: { zone: key },
        });
      } catch (error) {
        ToastManager.handleError(error);
      }
    }
  }

  if (props.isLocked === true) {
    return (
      <ContextMenuContent.List>
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter(`device.favorite`)}
          icon={isFavoriteDevice ? iconFavorite : iconNotFavorite}
          onPress={() => {
            onAction('favorite');
          }}
        />

        <ContextMenuContent.Divider />

        <ContextMenuContent.SubMenu title={i18n.messageFormatter(`device.moveTo`)}>
          <ZoneListBox selectedKeys={[device.zone]} onSelectionChange={handleSelectionChange} />
        </ContextMenuContent.SubMenu>

        <DeveloperMenu onIdRequest={() => device?.id} onResourceRequest={() => device} />

        <ContextMenuContent.Divider />
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter('common.delete')}
          variant="danger"
          icon={iconTrashFill}
          onPress={() => {
            onAction('delete');
          }}
        />
      </ContextMenuContent.List>
    );
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={props.quickActionTitle}
        hint={`${platform.optionSymbol} +`}
        icon={iconMouseLeftClick}
        isDisabled={device.ui?.quickAction == null}
        onPress={() => {
          onAction('quickaction');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`device.favorite`)}
        icon={isFavoriteDevice ? iconFavorite : iconNotFavorite}
        onPress={() => {
          onAction('favorite');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        hint={numberLogs.length}
        label={i18n.messageFormatter(`navigation.insights`)}
        onPress={() => {
          onAction('insights');
        }}
      />

      <ContextMenuContent.ListItem
        hint={deviceFlowsAndAdvancedFlows.length}
        label="Flows"
        onPress={() => {
          onAction('flows');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.SubMenu title={i18n.messageFormatter(`device.moveTo`)}>
        <FocusScope autoFocus={true} contain restoreFocus={false}>
          <ZoneListBox selectedKeys={[device.zone]} onSelectionChange={handleSelectionChange} />
        </FocusScope>
      </ContextMenuContent.SubMenu>

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.rename')}
        onPress={() => {
          onAction('rename');
        }}
      />

      {device.repair && (
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter(`device.repair`)}
          onPress={() => {
            onAction('repair');
          }}
        />
      )}

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`device.settings.title`)}
        onPress={() => {
          onAction('settings');
        }}
      />

      <DeveloperMenu onIdRequest={() => device?.id} onResourceRequest={() => device} />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}

async function favorite(device, messageFormatter) {
  const favoriteName = `**${device.name}**`;
  try {
    await UserMeStore.addFavoriteDevice({ id: device.id });
    ToastManager.add({
      icon: iconFavorite,
      iconColor: theme.color.special_favorite,
      message: messageFormatter(`device.addFavorite`, {
        name: favoriteName,
      }),
    });
  } catch (error) {
    ToastManager.handleError(error);
  }
}

async function unfavorite(device, messageFormatter) {
  const favoriteName = `**${device.name}**`;
  try {
    await UserMeStore.removeFavoriteDevice({ id: device.id });
    ToastManager.add({
      icon: iconNotFavorite,
      iconColor: theme.icon.color_light,
      message: messageFormatter(`device.removeFavorite`, {
        name: favoriteName,
      }),
    });
  } catch (error) {
    ToastManager.handleError(error);
  }
}
