import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';

import { StandardFlowGraphic } from './StandardFlowGraphic';
import { AdvancedFlowGraphic } from './AdvancedFlowGraphic';
import { IconButton } from '../../../components/buttons/IconButton';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

export function FlowTypeSelectView(props) {
  const { i18n } = useI18n();

  return (
    <FlowTypeSelectView.Root>
      <FlowTypeSelectView.CloseButton
        url={iconCloseThin}
        color={theme.icon.color_light}
        hoverColor={theme.icon.color_light_hover}
        size={theme.icon.size_small}
        onPress={props.onCloseRequest}
      />

      <FlowTypeSelectView.Content>
        <h1>{i18n.messageFormatter('flow.kindOfFlowTitle')}</h1>
        <FlowTypeSelectView.CaptionLarge>
          {i18n.messageFormatter('flow.kindOfFlowQuestion')}
        </FlowTypeSelectView.CaptionLarge>
        <FlowTypeSelectView.Buttons>
          {/* Standard Flow */}
          <FlowTypeSelectView.Button
            className="standard-flow-graphic-wrapper"
            onClick={props.onNavigateToFlowRequest}
          >
            <FlowTypeSelectView.ButtonInner>
              <h2>{i18n.messageFormatter('flow.standardFlowTitle')}</h2>
              <FlowTypeSelectView.Caption>
                {i18n.messageFormatter('flow.standardFlowSubtitle')}
              </FlowTypeSelectView.Caption>
              <FlowTypeSelectView.Graphic>
                <StandardFlowGraphic />
              </FlowTypeSelectView.Graphic>
            </FlowTypeSelectView.ButtonInner>
          </FlowTypeSelectView.Button>

          {/* Advanced Flow */}
          <FlowTypeSelectView.Button
            className="standard-flow-graphic-wrapper"
            onClick={props.onNavigateToAdvancedFlowRequest}
          >
            <FlowTypeSelectView.ButtonInner>
              <h2>Advanced Flow</h2>
              <FlowTypeSelectView.Caption>
                {i18n.messageFormatter('flow.advancedFlowSubtitle')}
              </FlowTypeSelectView.Caption>
              <FlowTypeSelectView.Graphic>
                <AdvancedFlowGraphic />
              </FlowTypeSelectView.Graphic>
            </FlowTypeSelectView.ButtonInner>
          </FlowTypeSelectView.Button>
        </FlowTypeSelectView.Buttons>
      </FlowTypeSelectView.Content>
    </FlowTypeSelectView.Root>
  );
}

FlowTypeSelectView.Root = styled.div`
  position: relative;
  text-align: center;
  animation: ${animationFade.in} ${theme.duration.normal} ease-out forwards;
`;

FlowTypeSelectView.CloseButton = styled(IconButton)`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

FlowTypeSelectView.Content = styled.div`
  padding: ${su(4, 4, 0, 4)};
`;

FlowTypeSelectView.CaptionLarge = styled.div`
  color: ${theme.color.text_light};
  margin-top: 5px;
  font-size: ${theme.fontSize.medium};
`;

FlowTypeSelectView.Caption = styled.div`
  color: ${theme.color.text_light};
  margin-top: 3px;
  padding: 0 5px;
`;

FlowTypeSelectView.Buttons = styled.div`
  display: grid;
  grid-gap: ${su(2)};
  grid-template-columns: 1fr 1fr;
  margin: ${su(4)} 0;
`;

FlowTypeSelectView.Button = styled.button`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  font-weight: ${theme.fontWeight.regular};
`;

FlowTypeSelectView.ButtonInner = styled.div`
  display: block;
  width: 360px;
  height: 280px;
  border: 2px solid ${theme.color.line};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, background-color, border;
  border-radius: ${theme.borderRadius.default};
  padding: ${su(3)} 0;
  background: ${theme.color.mono_010};
  cursor: pointer;
  overflow: hidden; // cut off graphic

  ${FlowTypeSelectView.Button}:hover & {
    background-color: ${theme.color.blue_050};
    border-color: ${theme.color.highlight_hover};
  }

  ${FlowTypeSelectView.Button}:hover:active & {
    border-color: ${theme.color.highlight_active};
    transform: scale(0.98);
  }
`;

FlowTypeSelectView.Graphic = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: ${su(-2)};
  margin-right: ${su(-2)};
  transform: translate3d(0, 0, 0);
`;
