import styled from '@emotion/styled';

import { Tooltip } from '../components/common/tooltip/Tooltip';
import { mergeProps } from 'react-aria';

export function TestPage() {
  return (
    <Container>
      <Spacer>
        <Tooltip
          placement="top"
          offset={20}
          anchorPointer={true}
          isOpen={true}
          renderTrigger={(triggerRef, triggerProps) => {
            return (
              <button ref={triggerRef} {...mergeProps(triggerProps, {})}>
                Hi
              </button>
            );
          }}
        >
          <Wrapper>Hello World</Wrapper>
        </Tooltip>
      </Spacer>
      <Spacer>
        <Tooltip
          placement="right"
          offset={20}
          anchorPointer={true}
          isOpen={true}
          renderTrigger={(triggerRef, triggerProps) => {
            return (
              <button ref={triggerRef} {...mergeProps(triggerProps, {})}>
                Hi
              </button>
            );
          }}
        >
          <Wrapper>Hello World</Wrapper>
        </Tooltip>
      </Spacer>
      <Spacer>
        <Tooltip
          placement="bottom"
          offset={20}
          anchorPointer={true}
          isOpen={true}
          renderTrigger={(triggerRef, triggerProps) => {
            return (
              <button ref={triggerRef} {...mergeProps(triggerProps, {})}>
                Hi
              </button>
            );
          }}
        >
          <Wrapper>Hello World</Wrapper>
        </Tooltip>
      </Spacer>
      <Spacer>
        <Tooltip
          placement="left"
          offset={20}
          anchorPointer={true}
          isOpen={true}
          renderTrigger={(triggerRef, triggerProps) => {
            return (
              <button ref={triggerRef} {...mergeProps(triggerProps, {})}>
                Hi
              </button>
            );
          }}
        >
          <Wrapper>Hello World</Wrapper>
        </Tooltip>
      </Spacer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: 120px 240px;
`;

const Spacer = styled.div`
  padding-bottom: 120px;
`;

const Wrapper = styled.div`
  padding: 20px;
`;
