import { useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';
import { RouteManager } from '../../../RouteManager';
import { StateRoute } from '../../../lib/StateRoute';
import { AppUtils } from '../../../store/apps/AppUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';
import { useApp } from '../../../store/apps/useApps';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Dialog } from '../../../components/overlay/Dialog';
import { HomeyIcon } from '../../../components/common/HomeyIcon';
import { SolidButton } from '../../../components/buttons/SolidButton';
import { IconButton } from '../../../components/buttons/IconButton';
import { RoundIconWrapper } from '../../../components/common/Icon';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

export function AppSettingsDialogUninstall(props) {
  const { i18n } = useI18n();
  const { context } = AppSettingsDialogUninstall.route.useContext();

  const { api } = useApi();
  const { app } = useApp({ appId: context?.appId });

  const [isUninstalling, setIsUninstalling] = useState(false);

  const appBackgroundColor = AppUtils.getBackgroundColor(app);

  function handleClosePress() {
    AppSettingsDialogUninstall.route.close();
  }

  function handleUninstallPress() {
    setIsUninstalling(true);
    api.apps
      .uninstallApp({ id: context?.appId })
      .then(() => {
        RouteManager.toSettings();
      })
      .catch(ToastManager.handleError)
      .finally(() => {
        setIsUninstalling(false);
      });
  }

  return (
    <Dialog
      containFocus={true}
      autoFocus={true}
      onClose={() => {
        AppSettingsDialogUninstall.route.close();
      }}
    >
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color={appBackgroundColor}>
          <HomeyIcon size="32px" iconObj={app?.iconObj} color={theme.color.white} />
        </RoundIconWrapper>

        <S.Title>
          {i18n.messageFormatter('common.uninstall')} {app?.name ?? 'App'}
        </S.Title>

        <S.Info>{i18n.messageFormatter('settings.app.uninstallHint')}</S.Info>

        <S.Actions>
          <SolidButton
            isDisabled={isUninstalling === true}
            styleFlat={true}
            styleColor="red"
            onPress={handleUninstallPress}
          >
            {/* <FlatButton.Icon url={iconArrowDoubleCircularRotated} size={theme.icon.size_small} /> */}
            <SolidButton.Text>{i18n.messageFormatter('common.uninstall')}</SolidButton.Text>
          </SolidButton>
        </S.Actions>
      </S.Root>
    </Dialog>
  );
}

AppSettingsDialogUninstall.route = new StateRoute({ key: AppSettingsDialogUninstall.name });

function S() {}
AppSettingsDialogUninstall.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: ${su(4)} ${su(2)} ${su(2)};
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Title = styled.h3`
  padding-top: ${su(2)};
`;

S.Info = styled.p`
  color: ${theme.color.text_light};
  text-align: center;
`;

S.Actions = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
`;
