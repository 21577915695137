import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { useOverlay, FocusScope } from 'react-aria';

import { theme } from '../../../theme/theme';

import { GlobalSearchResults } from './GlobalSearchResults';

export function GlobalSearchOverlay(props) {
  const overlayRef = useRef();

  const overlay = useOverlay(
    {
      isOpen: props.overlayTriggerState.isOpen,
      isDismissable: true,
      isKeyboardDismissDisabled: false,
      shouldCloseOnBlur: false,
      shouldCloseOnInteractOutside(element) {
        return props.rootRef.current.contains(element) === false;
      },
      onClose: props.overlayTriggerState.close,
    },
    overlayRef
  );

  return (
    props.overlayTriggerState.isOpen && (
      <GlobalSearchOverlay.Wrapper {...overlay.overlayProps} ref={overlayRef} tabIndex={-1}>
        <FocusScope contain autoFocus={false}>
          <GlobalSearchOverlay.Root>
            <GlobalSearchResults
              overlayTriggerState={props.overlayTriggerState}
              searchFieldState={props.searchFieldState}
              setHasResults={props.setHasResults}
            />
          </GlobalSearchOverlay.Root>
        </FocusScope>
      </GlobalSearchOverlay.Wrapper>
    )
  );
}

// small experiment
// factory createRoot
function Root({ as: As, ...props }) {
  return <As {...props} />;
}

GlobalSearchOverlay.Root = Root.bind({});
GlobalSearchOverlay.Root.cn = 'GlobalSearchOverlay-Root-cn';
GlobalSearchOverlay.Root.defaultProps = {
  className: GlobalSearchOverlay.Root.cn,
  as: 'div',
};

GlobalSearchOverlay.Wrapper = styled.div`
  outline: 0;

  .${GlobalSearchOverlay.Root.cn} {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    max-height: 500px;

    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 1px solid ${theme.color.line};
      z-index: 10;
    }
  }
`;
