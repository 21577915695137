import { useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';
import { wait } from '../../../lib/wait';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { DialogShiftLock } from '../../../components/overlay/DialogShiftLock';
import { IconButton } from '../../../components/buttons/IconButton';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { Clipboard } from '../../../components/common/Clipboard';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { Icon } from '../../../components/common/Icon';

import { ApiKeysSettingsForm } from './ApiKeysSettingsForm';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconApiKeys } from '../../../theme/icons/system/api-keys/api-keys';

export function ApiKeysSettingsDialogCreate(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  const formId = 'api-key-form';

  const [pat, setPat] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  function handleClosePress() {
    props.onCloseRequest();
  }

  function handleSubmit({ name, scopes }) {
    setIsCreating(true);
    const minDuration = wait(500);

    api.users
      ._call('POST', 'pat', {
        opts: {},
        body: {
          name: name,
          scopes: scopes,
        },
        queryParameters: {},
      })
      .then(async (result) => {
        await minDuration;
        setPat(result);
        props.refresh();
      })
      .catch((error) => {
        console.error(error);
        ToastManager.handleError(error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  }

  return (
    <DialogShiftLock layoutId={pat != null ? '1' : '2'} onClose={props.onCloseRequest}>
      <S.Root data-is-small-view={pat != null}>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color={theme.color.system_api_keys}>
          <Icon size="32px" url={iconApiKeys} color={theme.color.white} />
        </RoundIconWrapper>

        <S.Title>{i18n.messageFormatter('settings.system.apiKeysNew')}</S.Title>

        {pat == null && <ApiKeysSettingsForm id={formId} onSubmit={handleSubmit} />}

        {pat != null && (
          <S.Result>
            <S.Message>{i18n.messageFormatter('settings.system.apiKeysCopyToken')}</S.Message>

            <Clipboard
              value={pat.token}
              autoFocus={true}
              message={i18n.messageFormatter('settings.system.apiKeysCopyTokenCopied')}
            />
          </S.Result>
        )}

        <S.Actions>
          <GradientButton
            type="submit"
            form={formId}
            isDisabled={isCreating === true}
            styleFlat={true}
            styleColor="blue"
            onPress={() => {
              if (pat != null) {
                props.onCloseRequest();
              }
            }}
          >
            <GradientButton.Text>
              {isCreating
                ? `${i18n.messageFormatter('common.creating')}...`
                : pat == null
                ? i18n.messageFormatter('common.create')
                : i18n.messageFormatter('common.done')}
            </GradientButton.Text>
          </GradientButton>
        </S.Actions>
      </S.Root>
    </DialogShiftLock>
  );
}

function S() {}
ApiKeysSettingsDialogCreate.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 560px;
  height: min(100vh - 40px, 760px);
  padding: ${su(4)} 0 ${su(1)};

  &[data-is-small-view='true'] {
    width: 440px;
    height: auto;
  }

  ${Clipboard.S.Root} {
    width: 100%;
  }
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Result = styled.div`
  width: 100%;
  padding: 0 ${su(2)} ${su(2)};
`;

S.Actions = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
  padding: ${su(1)} ${su(1)} 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: ${theme.color.line};
  }
`;

S.Title = styled.h3`
  padding: ${su(3)} 0 ${su(1)};
`;

S.Message = styled.p`
  color: ${theme.color.text_light};
  text-align: center;
`;
