import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { animationRotate } from '../../theme/animations/animationRotate';

interface Props {
  size?: string;
}

export function Throbber(props: Props) {
  return (
    <S.Root
      style={{
        // @ts-ignore not sure why it is complaining here but '--size' is really a valid prop.
        '--size': props.size ?? theme.icon.size_default,
      }}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={theme.color.mono_900} fillRule="evenodd">
        <path
          d="M6.364 10c0 .753-.61 1.364-1.364 1.364H1.364a1.364 1.364 0 0 1 0-2.728H5c.753 0 1.364.61 1.364 1.364Z"
          opacity=".4"
        />
        <path
          d="M2.929 2.929a1.364 1.364 0 0 1 1.928 0L7.43 5.5A1.364 1.364 0 1 1 5.5 7.43L2.93 4.857a1.364 1.364 0 0 1 0-1.928Z"
          opacity=".5"
        />
        <path
          d="M10 13.636c.753 0 1.364.61 1.364 1.364v3.636a1.364 1.364 0 1 1-2.728 0V15c0-.753.61-1.364 1.364-1.364Z"
          opacity=".2"
        />
        <path
          d="M7.429 12.571a1.364 1.364 0 0 1 0 1.929l-2.572 2.57a1.364 1.364 0 0 1-1.928-1.928L5.5 12.57a1.364 1.364 0 0 1 1.929 0Z"
          opacity=".3"
        />
        <path
          d="M12.571 12.571a1.364 1.364 0 0 1 1.929 0l2.571 2.572a1.364 1.364 0 0 1-1.928 1.928L12.57 14.5a1.364 1.364 0 0 1 0-1.929Z"
          opacity=".15"
        />
        <path
          d="M20 10c0 .753-.61 1.364-1.364 1.364H15a1.364 1.364 0 1 1 0-2.728h3.636C19.39 8.636 20 9.246 20 10Z"
          opacity=".1"
        />
        <path
          d="M17.071 2.929a1.364 1.364 0 0 1 0 1.928L14.5 7.43a1.364 1.364 0 1 1-1.93-1.93l2.572-2.571a1.364 1.364 0 0 1 1.928 0Z"
          opacity=".05"
        />
        <path
          d="M10 0c.753 0 1.364.61 1.364 1.364V5a1.364 1.364 0 0 1-2.728 0V1.364C8.636.61 9.246 0 10 0Z"
          opacity=".6"
        />
      </g>
    </S.Root>
  );
}

function S() {}
Throbber.S = S;

S.Root = styled.svg`
  animation-name: ${animationRotate.default};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(8);

  width: var(--size);
  height: var(--size);
`;
