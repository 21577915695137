import React, { useEffect, useReducer, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';

import { ToastManager } from '../../../ToastManager';

import { useConstant } from '../../../hooks/useConstant';

import { ThermostatRadial } from './ThermostatRadial';
import { CapabilitySelect } from '../CapabilitySelect';

import * as Containers from '../Containers';

const initialState = {
  measure_temperature: null,
  target_temperature: null,
  thermostat_mode: null,
  measureValue: null,
  targetValue: null,
  modeValue: null,
  max: 40,
  min: 0,
  step: 0.5,
  units: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'init':
      return action.payload;
    default:
      throw new Error();
  }
}

export function Thermostat({ device, component, capabilities }) {
  const instance = useRef(`Thermostat:${uuid()}`);
  const handlers = useConstant();
  const [state, dispatch] = useReducer(reducer, initialState);

  const componentCapabilities = useMemo(() => {
    const mapping = {};

    for (const capabilityId of component.capabilities) {
      const isTargetTemperature = capabilityId.startsWith('target_temperature');
      const [, subcapabilityId] = capabilityId.split('.');

      if (mapping[subcapabilityId] == null) {
        mapping[subcapabilityId] = capabilityId;
      }

      // prefer target temperature
      if (isTargetTemperature && mapping[subcapabilityId] != null) {
        mapping[subcapabilityId] = capabilityId;
      }
    }

    return Object.values(mapping);
  }, [component.capabilities]);

  const [capability, setCapability] = useState(() => {
    return capabilities?.[componentCapabilities[0]] ?? null;
  });

  useEffect(() => {
    setCapability(capabilities?.[componentCapabilities[0]] ?? null);
  }, [capabilities, componentCapabilities]);

  useEffect(() => {
    const listeners = [];
    let nextState = {
      ...initialState,
    };

    let keys = [];

    if (capability != null) {
      const [, subcapabilityId] = capability.id.split('.');

      if (subcapabilityId === undefined) {
        keys = ['target_temperature', 'measure_temperature', 'thermostat_mode'];
      } else {
        keys = [
          `target_temperature.${subcapabilityId}`,
          `measure_temperature.${subcapabilityId}`,
          `thermostat_mode.${subcapabilityId}`,
        ];
      }
    }

    const target_temperature = capabilities?.[keys[0]] ?? null;
    const measure_temperature = capabilities?.[keys[1]] ?? null;
    const thermostat_mode = capabilities?.[keys[2]] ?? null;

    if (measure_temperature) {
      const unregister = measure_temperature.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        dispatch({
          type: 'set',
          payload: {
            measureValue: value,
          },
        });
      });
      listeners.push(unregister);
      nextState = {
        ...nextState,
        measure_temperature,
        measureValue: measure_temperature.value,
      };
    }

    if (target_temperature) {
      const unregister = target_temperature.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        dispatch({
          type: 'set',
          payload: {
            targetValue: value,
          },
        });
      });

      listeners.push(unregister);
      nextState = {
        ...nextState,
        target_temperature,
        targetValue: target_temperature.value,
        max: target_temperature.max ?? 40,
        min: target_temperature.min ?? 0,
        step: target_temperature.step ?? 0.5,
        units: target_temperature.units ?? '°C',
      };
    }

    if (thermostat_mode) {
      const unregister = thermostat_mode.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        dispatch({
          type: 'set',
          payload: {
            modeValue: value,
          },
        });
      });
      listeners.push(unregister);
      nextState = {
        ...nextState,
        thermostat_mode,
        modeValue: thermostat_mode.value,
      };
    }

    dispatch({
      type: 'init',
      payload: nextState,
    });

    return function () {
      listeners.forEach((unregister) => {
        unregister();
      });
    };
  }, [capabilities, device, component.capabilities, capability]);

  handlers.onTargetValueChange = useMemo(() => {
    return debounce(function (value) {
      state.target_temperature
        ?.setValue(value, instance.current)
        .then(() => {})
        .catch((error) => {
          ToastManager.handleError(error);
        });
    }, 1000);
  }, [state.target_temperature]);

  return (
    <Containers.Control>
      <Containers.Select>
        {componentCapabilities.length > 1 && (
          <CapabilitySelect
            selectedCapability={capability}
            capabilities={capabilities}
            componentCapabilities={componentCapabilities}
            onSelectionChange={setCapability}
          />
        )}
      </Containers.Select>
      <Containers.Action>
        <ThermostatRadial
          min={state.min}
          max={state.max}
          step={state.step}
          units={state.units}
          targetValue={state.targetValue}
          targetValueDecimals={state.target_temperature?.decimals}
          measureValue={state.measureValue}
          measureValueDecimals={state.measure_temperature?.decimals}
          hideMeasureTemperature={state.measure_temperature == null}
          modeValue={state.modeValue}
          onTargetValueChange={handlers.onTargetValueChange}
        />
      </Containers.Action>
    </Containers.Control>
  );
}
