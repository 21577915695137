import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';

import { theme } from '../../theme/theme';

export function LogDropZone(props) {
  const margin = {
    top: 40,
    right: 30,
    bottom: 40,
    left: 70,
  };

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const { timeScale } = useMemo(() => {
    return {
      timeScale: scaleLinear({
        range: [0, xMax],
        domain: [0, 10],
        nice: true,
      }),
    };
  }, [xMax]);

  const { valueScale } = useMemo(() => {
    return {
      valueScale: scaleLinear({
        range: [yMax, 0],
        domain: [0, 10],
        nice: true,
      }),
    };
  }, [yMax]);

  return (
    <LogDropZone.Root
      ref={props.dropRef}
      style={{
        '--drop-zone-height': `${props.height + 80}px`,
      }}
      data-is-over={props.isOver}
    >
      <LogDropZone.SVG width={props.width} height={props.height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            pointerEvents="none"
            top={0}
            left={0}
            scale={valueScale}
            width={xMax}
            height={yMax}
            stroke={theme.color.line}
            strokeDasharray="4 4"
            strokeOpacity={0.5}
            numTicks={4}
          />
          <AxisLeft
            scale={valueScale}
            numTicks={4}
            hideZero
            stroke={theme.color.line}
            tickLength={3}
            tickStroke={theme.color.line}
            tickFormat={() => null}
          />
          <AxisBottom
            top={yMax}
            scale={timeScale}
            numTicks={props.width > 520 ? 8 : 4}
            hideZero
            stroke={theme.color.line}
            tickLength={3}
            tickStroke={theme.color.line}
            tickFormat={() => null}
          />
        </Group>
      </LogDropZone.SVG>
    </LogDropZone.Root>
  );
}

LogDropZone.Root = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${theme.borderRadius.default};
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed transparent;
  animation-name: ${keyframes`
    from {
      height: 0
    }

    to {
      height: var(--drop-zone-height)
    }
  `};
  animation-duration: ${theme.duration.normal};
  animation-timing-function: ${theme.curve.easeInOut};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  &[data-is-over='true'] {
    background-color: ${theme.color.state_drop_background};
    border: 1px solid ${theme.color.state_drop_line};
  }
`;

LogDropZone.SVG = styled.svg`
  max-width: 100%;
`;
