import { useContext, createContext, useMemo } from 'react';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useDevice } from '../../../store/devices/useDevices';
import { useToken } from './tokens';

import { FlowToken } from './FlowToken';

const hintSet = new Set([
  'condition:homey:manager:logic:equal',
  'condition:homey:manager:logic:gt',
  'condition:homey:manager:logic:lt',
  'condition:homey:manager:logic:equation',
]);

const TokenHintContext = createContext(null);

export function useTokenHint() {
  return useContext(TokenHintContext);
}

// let str1 = '[[device:abc123|cmd]]';
// let str2 = '[[device:xyz789|cmd]][[device:xyz789|cmd]]';

// Regex pattern
const singleTokenRegex = /^(?!.*\[\[(.*?)\]\].*\[\[\1\]\])\[\[(.*?)\]\]$/;

// console.log(regex.test(str1)); // Expected output: true
// console.log(regex.test(str2)); // Expected output: false

export function TokenHintContextDecider(props) {
  // TODO homey-api remove props.card.id+uri
  const isCorrectCard =
    hintSet.has(`${props.cardType}:${props.card.id}`) ||
    hintSet.has(`${props.cardType}:${props.card.uri}:${props.card.id}`);

  if (isCorrectCard) {
    if (props.data.droptoken?.startsWith('homey:device:')) {
      return (
        <TokenHintContextProvider token={props.data.droptoken}>
          {props.children}
        </TokenHintContextProvider>
      );
    }

    if (props.argument.name === 'value1' && props.data.args.value2 != null) {
      const isSingleToken = singleTokenRegex.test(props.data.args.value2);

      if (isSingleToken) {
        return (
          <TokenHintContextProvider
            token={props.data.args.value2.slice(2, props.data.args.value2.length - 2)}
          >
            {props.children}
          </TokenHintContextProvider>
        );
      }
    }

    if (props.argument.name === 'value2' && props.data.args.value1 != null) {
      const isSingleToken = singleTokenRegex.test(props.data.args.value1);

      if (isSingleToken) {
        return (
          <TokenHintContextProvider
            token={props.data.args.value1.slice(2, props.data.args.value1.length - 2)}
          >
            {props.children}
          </TokenHintContextProvider>
        );
      }
    }
  }

  return props.children;
}

function TokenHintContextProvider(props) {
  const { i18n } = useI18n();
  const [ownerUri, tokenId] = props.token.split('|');
  const deviceId = ownerUri.split(':')[2];
  const capabilityId = tokenId;

  const { device } = useDevice({ deviceId });
  const { token } = useToken({ value: props.token, isDropToken: true });

  const hint = useMemo(() => {
    if (device == null || capabilityId == null || device.capabilitiesObj == null || token == null) {
      return null;
    }

    const capability = device.capabilitiesObj[capabilityId];

    if (capability == null || capability.type !== 'number') {
      return null;
    }

    if (capability.min != null && capability.max != null) {
      const i18nArguments = {
        deviceName: device.name,
        min: capability.min,
        max: capability.max,
        step: capability.step,
      };

      if (capability.step == null) {
        const startHint = i18n.messageFormatter('flow.tokenMinMaxHintStart', i18nArguments);
        const endHint = i18n.messageFormatter('flow.tokenMinMaxHintEnd', i18nArguments);

        return (
          <>
            {startHint + ' '}
            <FlowToken label={token.title} tokenKey={props.token} token={token} />
            {' ' + endHint}
          </>
        );
      }

      const startHint = i18n.messageFormatter('flow.tokenMinMaxStepHintStart', i18nArguments);
      const endHint = i18n.messageFormatter('flow.tokenMinMaxStepHintEnd', i18nArguments);

      return (
        <>
          {startHint + ' '}
          <FlowToken label={token.title} tokenKey={props.token} token={token} />
          {' ' + endHint}
        </>
      );
    }
  }, [device, capabilityId, props.token, token, i18n]);

  return <TokenHintContext.Provider value={hint}>{props.children}</TokenHintContext.Provider>;
}
