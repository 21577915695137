import React, { useMemo } from 'react';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useFlowCardExplorerContext } from './FlowCardExplorerContext';
import { useZonesByIdChildren } from '../../../store/zones/useZones';

export function ColumnTypeZone(props) {
  const { i18n } = useI18n();

  const { sections } = useZoneSections({
    i18n,
    entry: props.entry,
    columnProps: props.columnProps,
  });

  return (
    <props.listBoxComponent {...props.listBoxProps} aria-label="Zone column" items={sections} />
  );
}

function useZoneSections({ i18n, entry, columnProps }) {
  const { cardType, cardFilter, onCardSelect } = columnProps;
  const cardTypePlural = `${cardType}s`;
  const flowCardExplorerContext = useFlowCardExplorerContext();
  const cards = flowCardExplorerContext[cardTypePlural];

  const zones = useZonesByIdChildren();

  const zone = useMemo(() => {
    return cards.byZoneId[entry.key] ?? {};
  }, [cards.byZoneId, entry.key]);

  const zoneChildren = useMemo(() => {
    return zones.byIdChildren?.[entry.key] ?? new Set();
  }, [zones.byIdChildren, entry.key]);

  const sections = useMemo(() => {
    const sections = [];

    const zoneItems = [];

    for (const zoneId of [...zoneChildren]) {
      const zone = zones.data[zoneId];

      zoneItems.push({
        key: zoneId,
        type: 'zone',
        textValue: zone?.name,
        context: {
          zoneId,
        },
      });
    }

    zoneItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (zoneItems.length > 0) {
      sections.push({
        key: 'zones',
        title: i18n.messageFormatter('flow.cardDialog.zones'),
        children: zoneItems,
      });
    }

    const zoneDeviceItems = [];
    const zoneDeviceEntries = Object.entries(zone.devices ?? {});

    for (const [deviceId, device] of zoneDeviceEntries) {
      zoneDeviceItems.push({
        key: deviceId,
        type: 'device',
        textValue: device.name,
        context: {},
      });
    }
    zoneDeviceItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (zoneDeviceItems.length > 0) {
      sections.push({
        key: 'devices',
        title: i18n.messageFormatter('flow.cardDialog.devices'),
        children: zoneDeviceItems,
      });
    }

    const zoneMoodItems = [];
    const zoneMoodEntries = Object.entries(zone.moods ?? {});

    for (const [moodId, mood] of zoneMoodEntries) {
      zoneMoodItems.push({
        key: moodId,
        type: 'mood',
        textValue: mood.name,
        action() {
          // TODO make this work for 2019 where it should be uri+id.
          onCardSelect(mood.card.id, mood.card);
        },
        context: {
          card: mood.card,
        },
      });
    }
    zoneMoodItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (zoneMoodItems.length > 0) {
      sections.push({
        key: 'moods',
        title: 'Moods',
        children: zoneMoodItems,
      });
    }

    const cardItems = [];
    const cardsByKeyEntries = Object.entries(zone.cardsByKey ?? {});

    for (const [cardKey, card] of cardsByKeyEntries) {
      if (cardFilter(card) === false) continue;

      const cardItem = {
        key: cardKey,
        type: 'card',
        textValue: card.title ?? card.titleFormatted,
        action() {
          onCardSelect(cardKey, card);
        },
        context: {
          card,
        },
      };

      // If the card is popular, add it to the start of the array.
      if (card.highlight === true) {
        cardItems.unshift(cardItem);
        continue;
      }

      cardItems.push(cardItem);
    }

    if (cardItems.length > 0) {
      sections.push({
        key: 'cards',
        title: i18n.messageFormatter('flow.cardDialog.cards'),
        children: cardItems,
      });
    }

    return sections;
  }, [i18n, cardFilter, onCardSelect, zones.data, zone, zoneChildren]);

  return { sections };
}
