import { Fragment } from 'react';
import styled from '@emotion/styled';
import { differenceInSeconds } from 'date-fns';
import { useHover } from 'react-aria';

import { useI18n } from '../../hooks/useI18nFormatters';

import { HomeyImages } from '../../theme/HomeyImages';
import { Icon } from '../common/Icon';
import { ImageBase } from '../common/ImageBase';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';
import { getIconScaleHover } from '../../theme/functions/getIconScale';

import { iconCloseCircle } from '../../theme/icons/interface/close-circle';

export function NotificationsListItem(props) {
  const { i18n } = useI18n();
  const hover = useHover({});

  const avatarSrc = props.notification.meta?.userAthomId
    ? `${import.meta.env.VITE_ATHOM_CLOUD_API_BASEURL || 'https://api.athom.com'}/user/${
        props.notification.meta.userAthomId
      }/avatar?size=medium`
    : null;

  const date = new Date(props.notification.dateCreated);

  function formatDate(date) {
    if (hover.isHovered) {
      return i18n.dateFormatter.format(date, 'MMM dd yyyy HH:mm:ss');
    }

    if (differenceInSeconds(new Date(), date) <= 60) {
      return i18n.dateFormatter.formatDistanceToNow(date, {
        addSuffix: true,
      });
    }

    return i18n.dateFormatter.formatDistanceToNowStrict(date, {
      addSuffix: true,
    });
  }

  return (
    <TimelineItemContainer {...hover.hoverProps} data-is-hovered={hover.isHovered}>
      {avatarSrc ? (
        <IconMaskContainer>
          <Image round url={avatarSrc} />
        </IconMaskContainer>
      ) : (
        <IconMaskContainer>
          <Icon
            style={{
              zIndex: 2,
            }}
            size={theme.icon.size_medium}
            color={theme.color.icon_dark}
            url={HomeyImages.getIconForUri(props.notification.ownerUri)}
          />
        </IconMaskContainer>
      )}

      <Content>
        <Excerpt>{mapExcerpt(props.notification?.excerpt ?? '')}</Excerpt>
        <TimeAgo>{formatDate(date)}</TimeAgo>
      </Content>
      <DeleteAction
        onClick={() => {
          props.onRequestDelete({ id: props.notification.id });
        }}
      >
        <Icon size={theme.icon.size_default} url={iconCloseCircle} />
      </DeleteAction>
    </TimelineItemContainer>
  );
}

function mapExcerpt(excerpt) {
  return excerpt?.split?.('**').map((part, index) => {
    if (index % 2 === 1) return <strong key={index}>{part}</strong>;
    return <Fragment key={index}>{part}</Fragment>;
  });
}

const DeleteAction = styled.div`
  opacity: 0;
  padding: ${su(1)};
  cursor: pointer;

  transition: all ${theme.duration.fast} ${theme.curve.fastIn};

  ${Icon.S.Root} {
    transition: all ${theme.duration.fast} ${theme.curve.fastIn};
    background-color: ${theme.color.icon_light};
  }

  &:hover {
    ${Icon.S.Root} {
      background-color: ${theme.color.danger};
      transform: scale(${getIconScaleHover(theme.icon.scale_hover_default)});
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  opacity: ${(props) => (props.isRead ? 0.5 : 1)};
`;

const IconMaskContainer = styled.div`
  display: flex;
  z-index: 2;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  background-color: ${theme.color.component};
  margin: 0 20px 0 10px;
  border: 2px solid ${theme.color.line};
  border-radius: 50%;
`;

const Image = styled(ImageBase)`
  z-index: 2;
  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;
`;

const Excerpt = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
`;

const TimeAgo = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.tiny};
  line-height: 1.167;
`;

const TimelineItemContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  min-height: 60px;
  padding: ${su(1)};
  max-width: 380px;
  transition: ${theme.transition.micro};

  &[data-is-hovered='true'] {
    ${DeleteAction} {
      opacity: 1;
    }
  }

  :not(:last-child):after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 58px;
    left: 44px;
    height: 100%;
    overflow: visible;
    width: 2px;
    background-color: ${theme.color.line};
    // box-shadow: 0 0 1px 1px black;
  }
`;
