import { isWebKit as getIsWebKit, isChrome as getIsChrome } from '@react-aria/utils';

export const OS = {
  MACOS: 'Mac os',
  IOS: 'iOs',
  WINDOWS: 'Windows',
  ANDROID: 'Android',
  LINUX: 'Linux',
};

/**
 * Source from https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
 * @returns {null|string}
 */
function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.MACOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = OS.ANDROID;
  } else if (/Linux/.test(platform)) {
    os = OS.LINUX;
  }

  return os;
}

export const currentOS = getOS();
export const isMACOS = currentOS === OS.MACOS;
export const isWINDOWS = currentOS === OS.WINDOWS;

export const isChrome = getIsChrome();
export const isWebKit = getIsWebKit();

// https://github.com/adobe/react-spectrum/issues/2322
// on restoring this also check for anything checking if PointerEvent is undefined
if (isWebKit) {
  window.PointerEvent = undefined;
}

const keys = {
  alt: 'ALT',
  option: '⌥',
  meta: '⌘',
  ctrl: '✲',
};

export function getSymbolForKey(value) {
  if (currentOS === OS.MACOS && value === 'alt') {
    return keys.option;
  }

  return keys[value.toLowerCase()];
}

export function getOSSymbolForMetaOrCtrlKey() {
  if (isMACOS) {
    return keys.meta;
  }

  return keys.ctrl;
}

export const platform = {
  OS,
  currentOS,
  isMACOS,
  isWINDOWS,
  metaOrCtrlSymbol: getOSSymbolForMetaOrCtrlKey(),
  optionSymbol: getSymbolForKey('alt'),
  getSymbolForKey,
  getOS,
  isWebKit: isWebKit,
  isChrome: isChrome,
};
