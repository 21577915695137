import React, { createContext, useState } from 'react';
import { useMotionValue } from 'framer-motion';

const LayoutStateContext = createContext();

export function LayoutContextProvider(props) {
  const scrollbarThumbBackgroundMV = useMotionValue(null);

  const [layoutState] = useState({
    scrollbarThumbBackgroundMV: scrollbarThumbBackgroundMV,
  });

  return (
    <LayoutStateContext.Provider value={layoutState}>{props.children}</LayoutStateContext.Provider>
  );
}

export function useLayoutContextState() {
  return { layoutContextState: React.useContext(LayoutStateContext) };
}
