import styled from '@emotion/styled';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useApi } from '../../store/HomeyStore';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { scrollbars } from '../../theme/elements/scrollbars';

import { Icon } from '../../components/common/Icon';
import { Menu, MenuItem } from '../../components/common/Menu';

import { iconDevices } from '../../theme/icons/system/devices/devices';
import { iconFlow } from '../../theme/icons/system/flow/flow';
import { iconLogic } from '../../theme/icons/system/logic';
import { iconHomeyScript } from '../../theme/icons/system/homeyscript';
import { iconAdvancedFlow } from '../../theme/icons/interface/advanced-flow/advanced-flow';

export function ActionMenu(props) {
  const { i18n } = useI18n();

  const { api } = useApi();

  function onAction(...args) {
    props.onAction(...args);
  }

  return (
    <ActionMenu.Root>
      <Menu onAction={onAction} aria-label="Action Menu">
        <MenuItem
          key={ActionMenu.KEYS.DEVICE}
          textValue={i18n.messageFormatter('header.action.device')}
        >
          <ActionContainer>
            <Icon url={iconDevices} color={theme.icon.color_dark} size={theme.icon.size_default} />
            <ActionName>{i18n.messageFormatter('header.action.device')}</ActionName>
          </ActionContainer>
        </MenuItem>

        <MenuItem
          key={ActionMenu.KEYS.FLOW}
          textValue={i18n.messageFormatter('header.action.flow')}
        >
          <ActionContainer>
            <Icon url={iconFlow} color={theme.icon.color_dark} size={theme.icon.size_default} />
            <ActionName>{i18n.messageFormatter('header.action.flow')}</ActionName>
          </ActionContainer>
        </MenuItem>

        <MenuItem
          key={ActionMenu.KEYS.ADVANCED_FLOW}
          textValue={i18n.messageFormatter('header.action.advancedFlow')}
        >
          <ActionContainer>
            <Icon
              url={iconAdvancedFlow}
              color={theme.icon.color_dark}
              size={theme.icon.size_default}
            />
            <ActionName>{i18n.messageFormatter('header.action.advancedFlow')}</ActionName>
          </ActionContainer>
        </MenuItem>

        <MenuItem
          key={ActionMenu.KEYS.LOGIC}
          textValue={i18n.messageFormatter('header.action.logic')}
        >
          <ActionContainer>
            <Icon url={iconLogic} color={theme.icon.color_dark} size={theme.icon.size_default} />
            <ActionName>{i18n.messageFormatter('header.action.logic')}</ActionName>
          </ActionContainer>
        </MenuItem>

        {api?.homey.platform === 'local' && (
          <MenuItem
            key={ActionMenu.KEYS.SCRIPT}
            textValue={i18n.messageFormatter('header.action.script')}
          >
            <ActionContainer>
              <Icon
                url={iconHomeyScript}
                color={theme.icon.color_dark}
                size={theme.icon.size_default}
              />
              <ActionName>{i18n.messageFormatter('header.action.script')}</ActionName>
            </ActionContainer>
          </MenuItem>
        )}
      </Menu>
    </ActionMenu.Root>
  );
}

ActionMenu.KEYS = {
  DEVICE: 'DEVICE',
  FLOW: 'FLOW',
  ADVANCED_FLOW: 'ADVANCED_FLOW',
  LOGIC: 'LOGIC',
  SCRIPT: 'SCRIPT',
};

ActionMenu.Root = styled.div`
  ${scrollbars.dark}
  max-height: 460px;
  padding: ${su(1)} 0;
  overflow-y: auto;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: ${su(1)} ${su(2)};
  color: ${theme.color.text};
  text-decoration: none;
`;

const ActionName = styled.span`
  display: inline-block;
  margin-left: 10px;
`;
