import { useMemo } from 'react';
import { extent } from 'd3-array';
import { scaleLinear, scaleTime } from '@visx/scale';

import { getDate } from './chartUtils';

export function useScales({ xMax, yMax, minValue, maxValue, values }) {
  const { timeScale } = useMemo(() => {
    const [minTime, maxTime] = extent(values, getDate);

    return {
      timeScale: scaleTime({
        range: [0, xMax],
        domain: [minTime, maxTime],
      }),
    };
  }, [xMax, values]);

  const { valueScale } = useMemo(() => {
    return {
      valueScale: scaleLinear({
        range: [yMax, 0],
        domain: [minValue, maxValue],
        nice: true,
        //clamp: true,
      }),
    };
  }, [yMax, minValue, maxValue]);

  return { timeScale, valueScale };
}
