import styled from '@emotion/styled';

export const HeaderActionsContainer = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: center;

  > :not(:last-child) {
    margin-right: 20px;
  }
`;
