import { useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { MonoButton } from '../../../components/buttons/MonoButton';
import { TextSmall } from '../../../components/common/text/TextSmall';

import { SpinningIcon } from '../../../components/common/SpinningIcon';
import { iconSpinner } from '../../../theme/icons/interface/spinner/spinner';

export function DeviceSettingsMaintenanceAction(props) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleMaintenanceAction() {
    if (isLoading === true) return;

    setIsLoading(true);
    const wait = new Promise((resolve) => setTimeout(resolve, 300));

    try {
      await props.capability.setValue(true);
      await wait;
    } catch (error) {
      ToastManager.handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <S.Root>
      <MonoButton
        key={props.capability.id}
        styleWidth="full"
        styleFlat={true}
        styleColor="blue"
        onPress={() => handleMaintenanceAction()}
      >
        {isLoading ? (
          <SpinningIcon url={iconSpinner} color={theme.color.blue} />
        ) : (
          props.capability.title
        )}
      </MonoButton>
      <TextSmall>{props.capability.desc}</TextSmall>
    </S.Root>
  );
}

function S() {}
DeviceSettingsMaintenanceAction.S = S;

S.Root = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  grid-gap: ${su(1)};
  margin-bottom: ${su(2)};

  ${TextSmall.S.Root} {
    margin: 0;
    color: ${theme.color.text_body_light};
  }
`;
