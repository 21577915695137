import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

export interface State {
  systemInfoData: object | null;
  systemNameData: string | null;
  cloudStateData: object | null;
  isLoading: boolean;
  error: unknown;
}

export type GeneralSettingsStoreState = State & BaseApiStoreState;

class GeneralSettingsStore extends BaseApiStore<State> {
  key = 'general-settings';

  override createInitialState() {
    return {
      systemInfoData: null,
      systemNameData: null,
      cloudStateData: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:system');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerSystem = api.system;
      const managerCloud = api.cloud;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const systemInfoPromise = managerSystem.getInfo();
      const cloudGetStatePromise = managerCloud.getState();
      const systemNamePromise = managerSystem.getSystemName();

      const [, systemInfoData, systemNameData, cloudStateData] = await Promise.all([
        waitPromise,
        systemInfoPromise,
        systemNamePromise,
        cloudGetStatePromise,
      ]);

      this.set({
        ...this.createInitialState(),
        systemInfoData: systemInfoData as unknown as object,
        systemNameData: systemNameData as unknown as string,
        cloudStateData: cloudStateData,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  async setSystemName(name: string) {
    const api = this.getApi();
    await api.system.setSystemName({ name });
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, 'general-settings');

    return { generalSettings: this.store(selectAll, shallow) };
  }
}

function selectAll(state: GeneralSettingsStoreState) {
  return {
    ...selectBase(state),
    systemInfoData: state.systemInfoData,
    systemNameData: state.systemNameData,
    cloudStateData: state.cloudStateData,
  };
}

export const generalSettingsStore = new GeneralSettingsStore();
