import React, { useMemo } from 'react';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useFlowCardExplorerContext } from './FlowCardExplorerContext';
import { useZonesByIdChildren } from '../../../store/zones/useZones';
import { useAppsData } from '../../../store/apps/useApps';
import { HomeyColors } from '../../../theme/HomeyColors';
import { HomeyImages } from '../../../theme/HomeyImages';

export function ColumnTypeRoot(props) {
  const { i18n } = useI18n();

  const { sections } = useRootSections({
    i18n,
    entry: props.entry,
    columnProps: props.columnProps,
  });

  return (
    <props.listBoxComponent {...props.listBoxProps} aria-label="Root column" items={sections} />
  );
}

function useRootSections({ i18n, entry, columnProps }) {
  // eslint-disable-next-line no-unused-vars
  const { cardType, cardFilter, onCardSelect } = columnProps;
  const cardTypePlural = `${cardType}s`;
  const flowCardExplorerContext = useFlowCardExplorerContext();
  const cards = flowCardExplorerContext[cardTypePlural];

  const zones = useZonesByIdChildren();

  const zoneChildren = useMemo(() => {
    return zones.byIdChildren?.[null] ?? new Set();
  }, [zones.byIdChildren]);

  // Only updates on initial/add/removal
  const apps = useAppsData();

  const sections = useMemo(() => {
    const sections = [];

    const zoneItems = [];

    for (const zoneId of [...zoneChildren]) {
      const zone = zones.data[zoneId];

      zoneItems.push({
        key: zoneId,
        type: 'zone',
        textValue: zone?.name,
        context: {
          zoneId,
        },
      });
    }

    zoneItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (zoneItems.length > 0) {
      sections.push({
        key: 'zones',
        title: i18n.messageFormatter('flow.cardDialog.zones'),
        children: zoneItems,
      });
    }

    const managerItems = [];
    const cardsByManagerUriEntries = Object.entries(cards.byManagerUri ?? {});

    for (const [managerUri, manager] of cardsByManagerUriEntries) {
      let name = manager.name;

      // Maybe we should translate all managers if ownerName disappears.
      if (managerUri === 'homey:manager:vdevice') {
        // name = i18n.messageFormatter('manager.vdevice.name');
        // Disabled for now devices should be reachable via their zones.
        continue;
      }

      if (
        Object.values(manager.cardsByKey ?? {}).filter(cardFilter).length === 0 &&
        manager.devices == null
      ) {
        continue;
      }

      const iconBackgroundColor = HomeyColors.getColorForUri(managerUri);
      const iconUrl = HomeyImages.getIconForUri(managerUri);

      managerItems.push({
        key: managerUri,
        type: 'manager',
        textValue: name,
        context: {
          iconBackgroundColor: iconBackgroundColor,
          iconUrl: iconUrl,
        },
      });
    }
    managerItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (managerItems.length > 0) {
      sections.push({
        key: 'system',
        title: i18n.messageFormatter('flow.cardDialog.system'),
        children: managerItems,
      });
    }

    const appItems = [];
    const cardsByAppUriEntries = Object.entries(cards.byAppUri ?? {});

    // eslint-disable-next-line no-unused-vars
    for (const [appUri, app] of cardsByAppUriEntries) {
      // homey:manager:vdevice handled above
      if (appUri.startsWith('homey:app:') === false) continue;

      const appId = appUri.replace('homey:app:', '');
      const appData = apps.data?.[appId];

      if (
        Object.values(app.cardsByKey ?? {}).filter(cardFilter).length === 0 &&
        app.devices == null
      ) {
        continue;
      }

      appItems.push({
        key: appUri,
        type: 'app',
        textValue: appData?.name,
        context: {
          appId,
        },
      });
    }
    appItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (appItems.length > 0) {
      sections.push({
        key: 'apps',
        title: i18n.messageFormatter('flow.cardDialog.apps'),
        children: appItems,
      });
    }

    return sections;
  }, [i18n, cardFilter, zoneChildren, apps.data, zones.data, cards.byManagerUri, cards.byAppUri]);

  return { sections };
}
