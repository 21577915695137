import styled from '@emotion/styled';

import { DevicesTable } from './DevicesTable';
// import { MoodsTable } from './MoodsTable';

import { theme } from '../../../theme/theme';
import { scrollbars } from '../../../theme/elements/scrollbars';

export function TableView(props) {
  return (
    <Root>
      {/* <Container>
        <MoodsTable selectedZoneId={props.selectedZoneId} />
      </Container> */}

      <Container>
        <DevicesTable
          selectedZoneId={props.selectedZoneId}
          selectedDeviceId={props.selectedDeviceId}
          onControlsClick={props.onControlsClick}
        />
      </Container>
    </Root>
  );
}

const Root = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

const Container = styled.div`
  ${scrollbars.dark};
  background: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  margin: ${theme.su(2, 3)};
  overflow-x: auto;
  display: flex;
  box-shadow: ${theme.boxShadow.default};

  // trick to get border-radius work in safari browsers combined with sticky elements
  transform: translateZ(0);
`;
