export const dragTypes = {
  ZONE: 'zone',
  DEVICE: 'device',
  MOOD: 'mood',
  LOG: 'log',
  FLOW: 'flow',
  FLOW_CARD: 'flow_card',
  FLOW_CARD_CONDITION: 'flow_card_condition',
  FLOW_CARD_ACTION: 'flow_card_action',
  FOLDER: 'folder',
  ITEM: 'item',
};
