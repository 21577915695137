import { ResourceUtils } from '../../store/ResourceUtils';

import { RootItem, AppItem, DeviceItem, ManagerItem } from './GroupOwnerItem';

export function LogOwnerItem(props) {
  if (props.log == null) {
    return null;
  }

  const ownerType = ResourceUtils.getOwnerType(props.log);

  switch (ownerType) {
    case 'device': {
      const deviceId = ResourceUtils.getOwnerId(props.log);
      return <DeviceItem rootProps={props} deviceId={deviceId} />;
    }
    case 'app': {
      const appId = ResourceUtils.getOwnerId(props.log);
      return <AppItem rootProps={props} appId={appId} />;
    }
    case 'manager': {
      const managerId = ResourceUtils.getOwnerId(props.log);
      return <ManagerItem rootProps={props} managerId={managerId} />;
    }
    default: {
      return <RootItem rootProps={props} />;
    }
  }
}
