import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useButton } from 'react-aria';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Icon } from './Icon';

export function Modus(props) {
  return <Modus.Root>{props.children}</Modus.Root>;
}

export function ModusButton(props) {
  const ref = useRef();
  const button = useButton(props);

  return (
    <ModusButton.Root {...button.buttonProps} ref={ref} isActive={props.isActive}>
      <ModusButton.Icon
        url={props.url}
        size={theme.icon.size_small}
        color={theme.color.icon_light}
      />
    </ModusButton.Root>
  );
}

Modus.Root = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  margin-left: ${su(3)};
  background: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  border-radius: ${theme.borderRadius.large};
  padding: 0 10px;
`;

ModusButton.Icon = styled(Icon)`
  transition: ${theme.transition.micro};
  display: block;
`;

ModusButton.Root = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ${theme.duration.fast} ${theme.curve.fastIn};

  &:hover {
    ${ModusButton.Icon} {
      background-color: var(--icon-color, ${theme.color.icon_light_hover});
      transform: scale(${theme.icon.scale_default});
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      ${ModusButton.Icon} {
        --icon-color: ${theme.color.highlight};
        background-color: var(--icon-color);
      }
    `};
`;
