import { useEffect, useRef } from 'react';
import { ReactEditor } from 'slate-react';
import { Editor, Transforms } from 'slate';

export function useEditorRef({ editor }) {
  const ref = useRef();

  useEffect(() => {
    ref.current = ReactEditor.toDOMNode(editor, editor);
  }, [editor]);

  return ref;
}

export function useEditorAutoFocus({ editor, autoFocus }) {
  useEffect(() => {
    if (autoFocus === true) {
      setTimeout(() => {
        ReactEditor.focus(editor);
        Transforms.select(editor, Editor.end(editor, []));
      }, 0);
    }
  }, [editor, autoFocus]);
}
