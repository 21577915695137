import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { round } from '../../lib/math';
import { getThermostatColor } from '../../lib/capabilities/thermostat';
import { theme } from '../../theme/theme';

export function IndicatorThermostat({ device, capabilities }) {
  const measure_temperature = capabilities?.measure_temperature;
  const target_temperature = capabilities?.target_temperature;
  const thermostat_mode = capabilities?.thermostat_mode;

  const [state, setState] = useState({
    measure_temperature: measure_temperature?.value,
    target_temperature: target_temperature?.value,
    thermostat_mode: thermostat_mode?.value,
  });

  useEffect(() => {
    if (measure_temperature) {
      return measure_temperature.onChange(({ value }) => {
        setState((prevState) => {
          return {
            ...prevState,
            measure_temperature: value,
          };
        });
      });
    }
  }, [measure_temperature]);

  useEffect(() => {
    if (target_temperature) {
      return target_temperature.onChange(({ value }) => {
        setState((prevState) => {
          return {
            ...prevState,
            target_temperature: value,
          };
        });
      });
    }
  }, [target_temperature]);

  useEffect(() => {
    if (thermostat_mode) {
      return thermostat_mode.onChange(({ value }) => {
        setState((prevState) => {
          return {
            ...prevState,
            thermostat_mode: value,
          };
        });
      });
    }
  }, [thermostat_mode]);

  const color = getThermostatColor({
    mode: state.thermostat_mode,
    measure: state.measure_temperature,
    target: state.target_temperature,
  });

  return (
    <sc.container color={color}>
      <sc.text>
        {state.target_temperature != null
          ? round(state.target_temperature, 1)
              .toString()
              .split('.')
              .map((part, index) => {
                if (index > 0) return <sc.sup key={index}>{part}</sc.sup>;

                return part;
              })
          : '-'}
      </sc.text>
    </sc.container>
  );
}

const sc = {
  container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    box-shadow: 0 0 1px 1px ${(props) => props.color};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.bold};
  `,
  text: styled.div`
    color: ${theme.color.text_white};
  `,
  sup: styled.sup`
    font-size: 8px;
  `,
};
