import React from 'react';

import { Option, Select } from './Select';

export function CapabilitySelect({
  selectedCapability,
  componentCapabilities,
  capabilities,
  onSelectionChange,
}) {
  return (
    <Select
      aria-label="Capability Select"
      selectedKey={selectedCapability?.id ?? null}
      onSelectionChange={(key) => {
        onSelectionChange(capabilities?.[key] ?? null);
      }}
    >
      {componentCapabilities.map((capabilityId) => {
        return (
          <Option key={capabilityId}>
            {getOptionLabel({
              option: capabilityId,
              capabilities,
            })}
          </Option>
        );
      })}
    </Select>
  );
}

function getOptionLabel({ option, capabilities }) {
  const capability = capabilities?.[option];
  return capability ? capability.title : '';
}
