import { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { FlowTokenStore } from './FlowTokenStore';
import { selectData, selectByKey, selectByOwnerUri } from '../selectors';

import { useAttach } from '../useAttach';
import { ResourceUtils } from '../ResourceUtils';

export function useTokensData() {
  useAttach(FlowTokenStore, 'tokensData');

  return FlowTokenStore.store(selectData, shallow);
}

export function useTokensByKey() {
  useAttach(FlowTokenStore, 'tokensByKey');

  return FlowTokenStore.store(selectByKey, shallow);
}

export function useTokensByOwnerUri() {
  useAttach(FlowTokenStore, 'tokensByOwnerUri');

  return FlowTokenStore.store(selectByOwnerUri, shallow);
}

export function useToken({ tokenKey }) {
  useAttach(FlowTokenStore, 'token');

  const select = useCallback(
    (state) => {
      return {
        token: state.byKey?.[tokenKey] ?? null,
        manager: state.manager,
      };
    },
    [tokenKey]
  );

  return FlowTokenStore.store(select, shallow);
}

export function useTokenValue({ tokenKey }) {
  useAttach(FlowTokenStore, 'tokenValue');

  const { token } = useToken({ tokenKey });
  const [value, setValue] = useState(token?.value);

  useEffect(() => {
    if (token != null) {
      function listener(value) {
        setValue(value);
      }

      const key = ResourceUtils.getKey(token);

      FlowTokenStore.addValueListener(key, listener);

      setValue(token.value);

      return function () {
        FlowTokenStore.removeValueListener(key, listener);
      };
    }
  }, [token]);

  return { token, value };
}

export function useTokensAttach() {
  useAttach(FlowTokenStore, 'useTokensAttach');
}
