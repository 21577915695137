import React from 'react';

import * as Containers from '../Containers';

import { MediaArt } from './MediaArt';
import { MediaMeta } from './MediaMeta';
import { MediaControls } from './MediaControls';

export function Media({ device, capabilities, component }) {
  return (
    <Containers.Control>
      <Containers.Select />
      <Containers.Action>
        <MediaArt device={device} />
        <MediaMeta capabilities={capabilities} componentCapabilities={component.capabilities} />
        <MediaControls capabilities={capabilities} componentCapabilities={component.capabilities} />
      </Containers.Action>
    </Containers.Control>
  );
}
