import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../BaseApiStore2';
import type { HomeyAPI } from 'athom-api';

type UsersData = { [key: string]: HomeyAPI.ManagerUsers.User };
type UsersById = { [key: string]: HomeyAPI.ManagerUsers.User };

interface State {
  data: null | UsersData;
  byId: null | UsersById;
  isLoading: boolean;
  error: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UserStoreState = State & BaseApiStoreState;

export class UserStore extends BaseApiStore<State> {
  key = 'users';
  // static store = this.createStore(this.key);
  userCacheId = 'HomeyAPI.ManagerUsers.User';

  override createInitialState() {
    return {
      data: null,
      byId: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    __DEV__ && console.info(`fetch:${this.key}`);
    this.destroy('before:fetch');

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerUsers = api.users;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const usersPromise = managerUsers.getUsers({
        // @ts-ignore
        $skipCache: skipCache,
      });

      const [, usersData] = await Promise.all([waitPromise, usersPromise]);

      const users = Object.values(usersData);

      const data: UsersData = {};
      const byId: UsersById = {};

      for (const userInstance of users) {
        const userReference = { ...userInstance };

        data[userReference.id] = userInstance;
        byId[userReference.id] = userReference;
      }

      this.set({
        ...this.createInitialState(),
        data: data,
        byId: byId,
        isLoading: false,
      });
    } catch (error) {
      this.destroy('error');
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy(reason: string) {
    __DEV__ && console.info(`destroy:${this.key}:${reason ?? ''}`);
    clearTimeout(this.processBatchTimeout ?? undefined);
    this.processBatchTimeout = null;
    this.updateQueue = {};
  }

  onAttached({ api }: { api: HomeyAPI }): void {
    console.log('onAttached');

    // @ts-ignore
    api.users.addListener('user.create', this.handleCreate);
    // @ts-ignore
    api.users.addListener('user.update', this.handleUpdate);
    // @ts-ignore
    api.users.addListener('user.delete', this.handleDelete);
  }

  onDetached({ api }: { api: HomeyAPI }): void {
    console.log('onDetached');

    // @ts-ignore
    api.users.removeListener('user.create', this.handleCreate);
    // @ts-ignore
    api.users.removeListener('user.update', this.handleUpdate);
    // @ts-ignore
    api.users.removeListener('user.delete', this.handleDelete);
  }

  getInstanceFromCache(user: HomeyAPI.ManagerUsers.User) {
    const api = this.getApi();
    // @ts-ignore
    const cache = api.users._caches[this.userCacheId];

    return cache.getOne({
      id: cache._guessCacheId(user),
    });
  }

  getCache(): UsersData {
    const api = this.getApi();
    // @ts-ignore
    return api.users._caches[this.userCacheId]._cache;
  }

  handleCreate = (createdUser: HomeyAPI.ManagerUsers.User) => {
    __DEV__ && console.info(`create:user:${createdUser.id}`);
    const state = this.get();
    const userInstance = this.getInstanceFromCache(createdUser);

    if (userInstance == null) return;

    const userReference = { ...userInstance };

    this.set({
      data: {
        ...state.data,
        [createdUser.id]: userInstance,
      },
      byId: {
        ...state.byId,
        [createdUser.id]: userReference,
      },
    });
  };

  processBatchTimeout: null | ReturnType<typeof setTimeout> = null;
  updateQueue: { [key: string]: HomeyAPI.ManagerUsers.User } = {};
  handleUpdate = (updatedUser: HomeyAPI.ManagerUsers.User) => {
    __DEV__ && console.info(`update:user:${updatedUser.id}`);

    this.updateQueue[updatedUser.id] = updatedUser;

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedUsers = this.updateQueue;

        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextById = {
          ...state.byId,
        };

        let updateCount = 0;

        for (const [id, queuedUser] of Object.entries(queuedUsers)) {
          const userInstance = this.getInstanceFromCache(queuedUser);

          if (state.data == null || state.byId == null) {
            __DEV__ && console.info(`update:user:missing-data`);
            continue;
          }

          if (userInstance == null || state.data[id] == null || state.byId[id] == null) {
            __DEV__ && console.info(`update:user:unknown:${id}`);
            continue;
          }

          const userReference = { ...userInstance };

          nextById[id] = userReference;

          updateCount++;
        }

        __DEV__ && console.info(`update:user:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byId: nextById,
          });
      }, 200);
    }
  };

  handleDelete = (deletedUser: HomeyAPI.ManagerUsers.User) => {
    __DEV__ && console.info(`delete:user:${deletedUser.id}`);
    const state = this.get();

    if (state.data == null) {
      __DEV__ && console.log(`delete:user:missing-data`);
      return;
    }

    const userInstance = state.data[deletedUser.id];

    if (userInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };

    delete nextData[deletedUser.id];
    delete nextById[deletedUser.id];

    this.set({
      data: nextData,
      byId: nextById,
    });
  };

  restore() {
    const cache = this.getCache();
    const users = Object.values(cache ?? {});
    const data: UsersData = {};
    const byId: UsersById = {};

    for (const userInstance of users) {
      const userReference = { ...userInstance };
      data[userReference.id] = userInstance;
      byId[userReference.id] = userReference;
    }

    this.set({
      byId: byId,
      data: data,
    });
  }

  async deleteUser({ id }: { id: string }) {
    try {
      const api = this.getApi();
      const state = this.get();

      if (state.data == null) {
        __DEV__ && console.log(`delete:user:missing-data`);
        return;
      }

      const userInstance = state.data[id];
      if (userInstance == null) return;

      const nextById = { ...state.byId };
      delete nextById[id];

      this.set({
        byId: nextById,
      });

      await api.users.deleteUser({
        id,
      });
    } catch (error) {
      this.restore();
      throw error;
    }
  }

  async deleteUserMe({ id }: { id: string }) {
    try {
      const api = this.getApi();
      const state = this.get();

      if (state.data == null) {
        __DEV__ && console.log(`delete:user:missing-data`);
        return;
      }

      const userInstance = state.data[id];
      if (userInstance == null) return;

      const nextById = { ...state.byId };
      delete nextById[id];

      this.set({
        byId: nextById,
      });

      await api.users.deleteUserMe();
    } catch (error) {
      this.restore();
      throw error;
    }
  }

  async swapOwner({ id }: { id: string }) {
    try {
      const api = this.getApi();

      // TODO
      // We can set this in our local cache for better responsiveness.

      await api.users.swapOwner({
        newOwnerUserId: id,
      });
    } catch (error) {
      this.restore();
      throw error;
    }
  }
}

export const userStore = new UserStore();
