import styled from '@emotion/styled';
import { mergeProps, useFocusRing } from 'react-aria';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useOptionContext } from '../list-box/ListBox';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

export function PremiumRequiredDialogItem(props) {
  const { i18n } = useI18n();
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing({});

  return (
    <S.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      data-is-selected={option.isSelected}
      data-is-focused={focusRing.isFocusVisible}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
    >
      <S.IconWrapper>
        <S.Ring>
          <S.RingEmpty cx="50%" cy="50%" r="1.25rem" />
          <S.RingFill
            style={
              option.isSelected
                ? {
                    strokeDasharray: `${props.progress + props.iteration} 1`,
                    strokeDashoffset: `${props.iteration}`,
                  }
                : {}
            }
            cx="50%"
            cy="50%"
            r="1.25rem"
            pathLength={1}
          />
        </S.Ring>
        <Icon url={value.url} color={theme.color.icon_dark} size="2rem" />
      </S.IconWrapper>
      <S.TextWrapper>
        <S.Title>{i18n.messageFormatter(value.title)}</S.Title>
        <S.Subtitle>{i18n.messageFormatter(value.subtitle)}</S.Subtitle>
      </S.TextWrapper>
    </S.Root>
  );
}

function S() {}
PremiumRequiredDialogItem.S = S;

S.IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

S.Ring = styled.svg`
  --stroke-width: 3px;

  position: absolute;
  top: calc(-0.5 * var(--stroke-width));
  left: calc(-0.5 * var(--stroke-width));
  width: calc(100% + var(--stroke-width));
  height: calc(100% + var(--stroke-width));
  opacity: 0;
`;

S.RingEmpty = styled.circle`
  fill: transparent;
  stroke: ${theme.color.mono_050};
  stroke-width: var(--stroke-width);
`;

S.RingFill = styled.circle`
  fill: transparent;
  stroke: ${theme.color.system_premium};
  stroke-width: var(--stroke-width);
  stroke-dasharray: 1 1;
  stroke-dashoffset: 1;
  stroke-linecap: round;
  transform: rotate(270deg);
  transform-origin: center center;
  transition: opacity ${theme.duration.normal} ${theme.curve.fastIn},
    stroke-dasharray ${theme.duration.normal} ${theme.curve.easeOut},
    stroke-dashoffset 0s linear ${theme.duration.normal};
`;

S.TextWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

S.Title = styled.div`
  font-weight: ${theme.fontWeight.medium};
`;

S.Subtitle = styled.div`
  color: ${theme.color.text_light};
`;

S.Root = styled.li`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  grid-gap: 0.75rem;
  align-items: center;
  padding: 0.5rem;
  margin: 0 -0.5rem;
  outline: 0;
  border-radius: ${theme.borderRadius.default};
  cursor: pointer;

  &[data-is-focused='true'],
  &:hover {
    background: ${theme.color.mono_050};

    ${S.RingEmpty} {
      stroke: ${theme.color.mono_100};
    }
  }

  &[data-is-selected='true'] {
    ${S.Ring} {
      opacity: 1;
    }

    ${Icon.S.Root} {
      transform: scale(0.75);
      background-color: ${theme.color.system_premium};
    }
  }
`;
