import semver from 'semver';
// import { AuthStore } from './AuthStore';

export class HomeyUtils {
  // Advanced Flow is available as of Homey v8.0.0
  // but due to issues with semver checking versions
  // with suffixes (e.g. 8.0.1-rc.2) we must check for
  // greater than the version without Advanced Flow (v7.4.3).
  static ADVANCED_FLOW_VERSION = '7.4.3';
  static MOODS_VERSION_LOCAL_PLATFORM_V2 = '10.3.3';
  static MOODS_VERSION_LOCAL_PLATFORM_V1 = '11.0.0';
  static SEAMLESS_DEVICE_PAIRING_SCREENS_VERSION = '8.1.0';

  static getHasAdvancedFlowVersion(homey) {
    const version = semver.coerce(homey.softwareVersion);

    if (version == null) {
      return false;
    }

    return semver.gt(homey.softwareVersion, `${HomeyUtils.ADVANCED_FLOW_VERSION}`);
  }

  static getHasMoods(homey, user) {
    if (this.getIsCloud(homey)) {
      return true;
    }

    const version = semver.coerce(homey.softwareVersion);

    if (version == null) {
      return false;
    }

    if (
      homey.apiVersion === 2 &&
      semver.gte(version, `${HomeyUtils.MOODS_VERSION_LOCAL_PLATFORM_V1}`)
    ) {
      return true;
    }

    return (
      homey.apiVersion >= 3 && semver.gte(version, `${HomeyUtils.MOODS_VERSION_LOCAL_PLATFORM_V2}`)
    );
  }

  static getHasAdvancedFlowLicense(homey) {
    if (homey?.licenses.includes('advanced-flow')) return true;
    if (homey?.platform === 'local' && homey?.platformVersion === 2) return true;
    return false;
  }

  static getIsCloud(homey) {
    return homey?.platform === 'cloud' ?? false;
  }

  static getIsSeamlessDevicePairingScreens(homey) {
    if (this.getIsCloud(homey)) {
      return true;
    }

    const version = semver.coerce(homey.softwareVersion);

    if (version == null) {
      return false;
    }

    return semver.gte(version, HomeyUtils.SEAMLESS_DEVICE_PAIRING_SCREENS_VERSION);
  }
}
