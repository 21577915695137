import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { useButton, useFocusRing, useFocusManager, mergeProps } from 'react-aria';
import { getIconUrl } from './getIconUrl';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../../components/common/Icon';

import { iconFlow } from '../../../theme/icons/system/flow/flow';
import { iconInsights } from '../../../theme/icons/system/insights';

export function ResultEntry(props) {
  const ref = useRef();
  const focusManager = useFocusManager();
  const focusRing = useFocusRing({});

  function onKeyDown(event) {
    switch (event.key) {
      case 'ArrowDown':
        focusManager.focusNext({ wrap: true });
        break;
      case 'ArrowUp':
        focusManager.focusPrevious({ wrap: true });
        break;
      default:
        event.continuePropagation();
        break;
    }
  }

  const button = useButton({
    onPress: props.onPress,
    onKeyDown,
    elementType: 'a',
    //excludeFromTabOrder: true
  });
  const url = getIconUrl({ iconId: props.iconId });

  const icon =
    url ?? (props.type === 'flow' ? iconFlow : props.type === 'log' ? iconInsights : null);

  // useEffect(() => {
  //   const unregister = props.registerFocusableItem(key, () => {
  //
  //   })
  //
  //   return function() {
  //     unregister();
  //   }
  //
  // }, [props.registerFocusableItem])

  return (
    <ResultEntry.Root
      {...mergeProps(button.buttonProps, focusRing.focusProps)}
      ref={ref}
      style={{
        '--background-color': focusRing.isFocusVisible && theme.color.background_hover,
      }}
    >
      <ResultEntry.IconWrapper
        style={{
          '--background-color': props.color ?? theme.color.system_flow,
        }}
      >
        <Icon url={icon} color={theme.color.white} size={theme.icon.size_default} />
      </ResultEntry.IconWrapper>

      <ResultEntry.InfoContainer>
        <ResultEntry.Title>{props.title}</ResultEntry.Title>
        <ResultEntry.Subtitle>{props.subtitle}</ResultEntry.Subtitle>
      </ResultEntry.InfoContainer>
    </ResultEntry.Root>
  );
}

ResultEntry.IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--background-color);
  width: 30px;
  height: 30px;
`;

ResultEntry.InfoContainer = styled.div`
  padding-left: ${su(1)};
`;

ResultEntry.Title = styled.div`
  line-height: 18px;
  color: ${theme.color.text};
`;

ResultEntry.Subtitle = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 18px;
  transition: color ${theme.duration.fast} ${theme.curve.fastIn};
`;

ResultEntry.Root = styled.a`
  min-width: 500px; //force width to avoid animation shift on open
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: 0;
  text-decoration: none;
  padding: ${su(0.5, 2)};
  cursor: pointer;
  background-color: var(--background-color, initial);
  transition: background-color ${theme.duration.fast} ${theme.curve.fastIn};

  &:hover {
    background-color: ${theme.color.background_hover};

    ${ResultEntry.Title} {
      font-weight: ${theme.fontWeight.medium};
    }

    ${ResultEntry.Subtitle} {
      color: ${theme.color.text_light_hover};
    }
  }
`;
