import styled from '@emotion/styled';

import { mergeProps, useFocusRing } from 'react-aria';
import { useOptionContext } from '../../list-box/ListBox';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../common/Icon';

import { iconCheckmark } from '../../../theme/icons/interface/checkmark';

export function OptionDescriptive(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  return (
    <OptionDescriptive.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={props.style}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <OptionDescriptive.Title>{item.textValue}</OptionDescriptive.Title>
      <OptionDescriptive.Description>{value.description}</OptionDescriptive.Description>

      <Icon url={iconCheckmark} color={theme.color.blue} size={theme.icon.size_small} />
    </OptionDescriptive.Root>
  );
}

OptionDescriptive.Title = styled.div`
  font-weight: ${theme.fontWeight.medium};
  padding-bottom: 5px;
`;

OptionDescriptive.Description = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;

OptionDescriptive.Root = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 40px;
  padding: ${su(1, 2)};
  outline: 0;
  cursor: pointer;

  ${Icon.S.Root} {
    position: absolute;
    right: 20px;
    top: 10px;
    opacity: 0;
  }

  &[data-is-selected='true'] {
    ${Icon.S.Root} {
      opacity: 1;
    }
  }

  &[data-is-disabled='true'] {
  }

  &[data-is-focused='true'] {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-pressed='true'] {
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }

  &:not(:last-of-type):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${theme.color.line};
  }
`;
