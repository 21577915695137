import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { useButton } from 'react-aria';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

import { iconPlusBoldSmall } from '../../theme/icons/interface/plus-bold';

export function SubNavNewButton(props) {
  const buttonRef = useRef();
  const button = useButton(props, buttonRef);

  return (
    <SubNavNewButton.Root {...button.buttonProps} ref={buttonRef}>
      <Icon url={iconPlusBoldSmall} size={theme.icon.size_small} color={theme.icon.color_dark} />
      {props.children}
    </SubNavNewButton.Root>
  );
}

SubNavNewButton.Root = styled.button`
  display: inline-flex;
  width: auto;
  justify-content: flex-start;
  cursor: pointer;
  transition: all ${theme.duration.fast} ease-in-out;
  white-space: nowrap;

  &:hover {
    transform: scale(${theme.icon.scale_default});
  }

  ${Icon.S.Root} {
    display: inline-block;
    margin-right: 10px;
  }
`;
