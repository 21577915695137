export const SCOPES = {
  ALL_RESOURCES: 'resource',
  EVERYTHING: 'homey',
  ALARM: 'homey.alarm',
  ALARM_READONLY: 'homey.alarm.readonly',
  APP: 'homey.app',
  APP_READONLY: 'homey.app.readonly',
  APP_CONTROL: 'homey.app.control',
  DASHBOARD: 'homey.dashboard',
  DASHBOARD_READONLY: 'homey.dashboard.readonly',
  SYSTEM: 'homey.system',
  SYSTEM_READONLY: 'homey.system.readonly',
  USER: 'homey.user',
  USER_READONLY: 'homey.user.readonly',
  USER_SELF: 'homey.user.self',
  UPDATES: 'homey.updates',
  UPDATES_READONLY: 'homey.updates.readonly',
  GEOLOCATION: 'homey.geolocation',
  GEOLOCATION_READONLY: 'homey.geolocation.readonly',
  DEVICE: 'homey.device',
  DEVICE_READONLY: 'homey.device.readonly',
  DEVICE_CONTROL: 'homey.device.control',
  FLOW: 'homey.flow',
  FLOW_READONLY: 'homey.flow.readonly',
  FLOW_START: 'homey.flow.start',
  INSIGHTS: 'homey.insights',
  INSIGHTS_READONLY: 'homey.insights.readonly',
  LOGIC: 'homey.logic',
  LOGIC_READONLY: 'homey.logic.readonly',
  MOOD: 'homey.mood',
  MOOD_READONLY: 'homey.mood.readonly',
  MOOD_SET: 'homey.mood.set',
  NOTIFICATIONS: 'homey.notifications',
  NOTIFICATIONS_READONLY: 'homey.notifications.readonly',
  REMINDER: 'homey.reminder',
  REMINDER_READONLY: 'homey.reminder.readonly',
  PRESENCE: 'homey.presence',
  PRESENCE_READONLY: 'homey.presence.readonly',
  PRESENCE_SELF: 'homey.presence.self',
  SPEECH: 'homey.speech',
  ZONE: 'homey.zone',
  ZONE_READONLY: 'homey.zone.readonly',
} as const;
