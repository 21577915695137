import React, { useEffect } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import styled from '@emotion/styled';
import { motion, useAnimation } from 'framer-motion';
import { theme } from '../../theme/theme';

const TYPES = {
  COLOR: 'color',
  IMAGE: 'image',
};

const initialState = {
  type: null,
  value: null,
  previous: null,
};

const controlsBackgroundStore = create(
  (set, get, api) => ({
    ...initialState,
    reset: () => {
      set({ type: null, value: null });
    },
  }),
  { name: 'controlsBackground' }
);

function selector(state) {
  return {
    type: state.type,
    value: state.value,
    reset: state.reset,
  };
}

function useControlsBackground() {
  return controlsBackgroundStore(selector, shallow);
}

export function setBackgroundAnimationUrl(url) {
  controlsBackgroundStore.setState({
    type: TYPES.IMAGE,
    value: url,
  });
}

export function setBackgroundAnimationColor(color) {
  controlsBackgroundStore.setState({
    type: TYPES.COLOR,
    value: color,
  });
}

export function ControlsBackground({ resetId }) {
  const animation = useAnimation();
  const { type, value, reset } = useControlsBackground();

  useEffect(() => {
    animate({ animation, type, value });
  }, [animation, type, value]);

  useEffect(() => {
    return function () {
      reset();
    };
  }, [resetId, reset]);

  return <ControlsBackgroundContainer animate={animation} />;
}

async function animate({ animation, type, value }) {
  const { previous } = controlsBackgroundStore.getState();
  animation.stop();

  switch (type) {
    case 'color':
      controlsBackgroundStore.setState({ previous: TYPES.COLOR });
      animateColor({ animation, previous, value });
      break;
    case 'image':
      controlsBackgroundStore.setState({ previous: TYPES.IMAGE });
      animateImage({ animation, previous, value });
      break;
    default:
      animation.set({
        opacity: 0,
        backgroundImage: `none`,
      });
      break;
  }
}

async function animateImage({ animation, previous, value }) {
  const isFireFox = navigator.userAgent.search('Firefox') > -1;
  if (isFireFox) return;

  animation.set({
    filter: 'blur(160px)',
  });

  if (previous !== 'image') {
    animation.set({
      opacity: 0,
      backgroundImage: `none`,
    });
    await animation.start({
      opacity: 1,
      backgroundImage: `url(${value})`,
      transition: { duration: 1 },
    });
    return;
  }

  await animation.start({
    opacity: 0,
  });

  await animation.start({
    opacity: 1,
    backgroundImage: `url(${value})`,
    transition: { duration: 1 },
  });
}

async function animateColor({ animation, previous, value }) {
  const start = value.start ?? value;
  const stop = value.stop ?? value;
  const opacity = value.opacity ?? 1;
  const angle = value.angle ?? 225;

  if (previous !== 'color') {
    animation.set({
      filter: 'none',
      opacity: 0,
      backgroundImage: `linear-gradient(${angle}deg, #131313, #262626)`,
    });
    await animation.start({
      opacity: opacity,
      backgroundImage: `linear-gradient(${angle}deg, ${start}, ${stop})`,
      transition: { duration: 1 },
    });
    return;
  }

  await animation.start({
    opacity: opacity,
    backgroundImage: `linear-gradient(${angle}deg, ${start}, ${stop})`,
    transition: { duration: 1 },
  });
}

const ControlsBackgroundContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  will-change: filter;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: ${theme.borderRadius
    .default}; /* Border radius is extra required to avoid overflow on safari browser*/
`;
