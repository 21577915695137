import React from 'react';
import styled from '@emotion/styled';

import { useSectionContext } from './ListBox';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

export function ListBoxSection(props) {
  // eslint-disable-next-line no-unused-vars
  const { section, state, item, value, children } = useSectionContext();

  const HeadingComponent = props.headingComponent ?? S.Heading;

  return (
    <S.Root {...section.itemProps} className={props.className} style={props.style} as={props.as}>
      {item.rendered && (
        <HeadingComponent {...section.headingProps}>{item.rendered}</HeadingComponent>
      )}

      <S.List {...section.groupProps}>{children}</S.List>
    </S.Root>
  );
}

function S() {}
ListBoxSection.S = S;

S.Root = styled.li`
  &:not(:last-of-type) {
    padding-bottom: ${su(2)};
  }
`;

S.Heading = styled.div`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.text_light};
  padding-left: ${su(1)};
  padding-bottom: ${su(1)};
`;

S.List = styled.ul`
  list-style: none;
`;
