import { css } from '@emotion/react';
import { theme } from '../theme';

export const scrollbar = css`
  //scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const scrollbarDark = css`
  /* Mozilla browsers */
  scrollbar-color: ${theme.color.mono_o_20} transparent;
  scrollbar-width: thin;

  /* Webkit browsers */
  &::-webkit-scrollbar {
    width: 10px; // vertical scrollbar
    height: 10px; // horizontal scrollbar
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    height: 10px;
    border: solid 2px transparent;
    border-radius: 4px;
    background: var(--scrollbar-thumb-background, ${theme.color.mono_o_10});
    background-clip: padding-box;
    transition: ${theme.transition.micro};

    &:hover {
      background-color: var(--scrollbar-thumb-background, ${theme.color.mono_o_20});
    }

    &:active {
      background-color: var(--scrollbar-thumb-background, ${theme.color.mono_o_40});
    }
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
`;

export const scrollbarDarkTiny = css`
  /* Mozilla browsers */
  scrollbar-color: ${theme.color.mono_o_20} transparent;
  scrollbar-width: thin;

  /* Webkit browsers */

  &::-webkit-scrollbar {
    width: 8px; // vertical scrollbar
    height: 8px; // horizontal scrollbar
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: ${theme.transition.slow};
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border: solid 2px transparent;
    border-radius: 4px;
    background: var(--scrollbar-thumb-background, ${theme.color.mono_o_10});
    background-clip: padding-box;
    transition: ${theme.transition.micro};

    &:hover {
      background-color: var(--scrollbar-thumb-background, ${theme.color.mono_o_20});
    }

    &:active {
      background-color: var(--scrollbar-thumb-background, ${theme.color.mono_o_40});
    }
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
`;

export const scrollbars = {
  default: scrollbar,
  dark: scrollbarDark,
  darkTiny: scrollbarDarkTiny,
};
