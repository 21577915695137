import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { ToastManager } from '../../../ToastManager';
import { FlowTest } from './useFlowTest';

import { su } from '../../../theme/functions/su';

import { defaultTokenExamples } from '../flow-arguments/tokens';

import { Overlay } from '../../../components/overlay/Overlay';
import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { SolidButton } from '../../../components/buttons/SolidButton';
import { SelectControllable } from '../../../components/forms/select/SelectControllable';
import { TextField } from '../../../components/forms/TextField';

import { iconFlow } from '../../../theme/icons/system/flow/flow';

export function FlowTestOverlayTrigger(props) {
  const triggerRef = useRef();
  const triggerState = useOverlayTriggerState({});
  const overlayTrigger = useOverlayTrigger({ type: 'menu' }, triggerState, triggerRef);

  return (
    <>
      {props.renderTrigger(
        triggerRef,
        {
          ...overlayTrigger.triggerProps,
        },
        triggerState
      )}
      <AnimationRemain condition={triggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          // TODO
          // Use FlowTestForm.js here.

          return (
            <FlowTestOverlay
              targetRef={triggerRef}
              overlayTrigger={overlayTrigger}
              triggerState={triggerState}
              triggerTokensById={props.triggerTokensById}
              animationRemainProps={animationRemainProps}
            />
          );
        }}
      </AnimationRemain>
    </>
  );
}

function FlowTestOverlay(props) {
  const buttonRef = useRef();

  const { i18n } = useI18n();
  const { register, handleSubmit, control } = useForm();

  function onSubmit(data) {
    const tokens = Object.entries(data).reduce((accumulator, [tokenKey, tokenValue]) => {
      const token = props.triggerTokensById[tokenKey];

      switch (token.type) {
        case 'number':
          accumulator[tokenKey] = Number(tokenValue);
          break;
        case 'string':
          accumulator[tokenKey] = tokenValue;
          break;
        case 'boolean':
          accumulator[tokenKey] = tokenValue === 'true';
          break;
        default:
          break;
      }

      return accumulator;
    }, {});

    FlowTest.testFlow({ tokens }).then(() => {
      ToastManager.add({
        icon: iconFlow,
        message: i18n.messageFormatter('flow.startTest'),
      });
    });

    props.triggerState.close();
  }

  function handleStartPress() {
    handleSubmit(onSubmit)();
  }

  function triggerTokensByIdMapper([tokenKey, token]) {
    if (token.type === 'string' || token.type === 'number') {
      const type = token.type === 'string' ? 'text' : 'number';
      const defaultValue = token.example ?? defaultTokenExamples[token.type];

      return (
        <TextField
          key={tokenKey}
          orientation="vertical"
          name={tokenKey}
          type={type}
          label={token.title}
          defaultValue={defaultValue}
          register={register}
        />
      );
    }

    if (token.type === 'boolean') {
      const options = [
        {
          id: 'true',
          textValue: i18n.messageFormatter('common.true'),
        },
        {
          id: 'false',
          textValue: i18n.messageFormatter('common.false'),
        },
      ];

      return (
        <SelectControllable
          key={tokenKey}
          orientation="vertical"
          name={tokenKey}
          label={token.title}
          defaultValue="true"
          options={options}
          control={control}
        />
      );
    }

    return null;
  }

  useEffect(() => {
    setTimeout(() => {
      buttonRef.current?.focus();
    }, 0);
  }, []);

  return (
    <Overlay
      targetRef={props.targetRef}
      overlayProps={{}}
      overlayTriggerState={props.triggerState}
      offset={10}
      animationRemainProps={props.animationRemainProps}
      autoFocus={true}
      restoreFocus={true}
    >
      <S.Form
        onSubmit={(event) => {
          // single element form causes submit on enter key in a field
          event.preventDefault();
        }}
      >
        {Object.entries(props.triggerTokensById).map(triggerTokensByIdMapper)}

        <S.ButtonWrapper>
          <SolidButton
            ref={buttonRef}
            styleColor="green"
            styleFlat={true}
            styleWidth="full"
            onPress={handleStartPress}
          >
            <SolidButton.Text>Start</SolidButton.Text>
          </SolidButton>
        </S.ButtonWrapper>
      </S.Form>
    </Overlay>
  );
}

function S() {}
FlowTestOverlay.S = S;

S.Form = styled.form`
  position: relative;
  padding: 20px;
  outline: 0;
  width: 320px;

  ${TextField.Root} {
    margin: 0 0 ${su(1)};
  }

  ${SelectControllable.S.Root} {
    margin: 0 0 ${su(1)};
  }
`;

S.ButtonWrapper = styled.div`
  display: flex;
  padding-top: ${su(1)};
`;
