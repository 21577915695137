import { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing, useTableCell } from 'react-aria';

import { SCOPES } from '../../../lib/scopes';
import { ToastManager } from '../../../ToastManager';
import { notificationSettingsStore } from './NotificationSettingsStore';

import { useApi } from '../../../store/HomeyStore';
import { useCellContext } from './SettingsTable';

import { HomeyColors } from '../../../theme/HomeyColors';
import { HomeyImages } from '../../../theme/HomeyImages';
import { theme } from '../../../theme/theme';

import { Switch } from '../../../components/forms/Switch';
import { Icon } from '../../../components/common/Icon';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';
import { NotificationSettingsTableOwnersContextMenuContent } from './NotificationSettingsTableOwnersContextMenuContent';

export function NotificationSettingsTableOwnersCell(props) {
  const { cell, row, state } = useCellContext();
  const [isPending, setIsPending] = useState(false);
  const [value, setValue] = useState(cell.props.value ?? false);

  const ref = useRef();
  const tableCell = useTableCell({ node: cell }, state, ref);
  const focusRing = useFocusRing();

  const { scopes } = useApi();

  useEffect(() => {
    setValue(cell.props.value ?? false);
  }, [cell.props.value]);

  function getContent() {
    switch (cell.column.key) {
      case 'category':
        return (
          <S.NameCell>
            <RoundIconWrapper size="24px" color={HomeyColors.getColorForUri(row.key)}>
              <Icon
                size="18px"
                url={HomeyImages.getIconForUri(row.key)}
                color={theme.color.white}
              />
            </RoundIconWrapper>

            <S.Name>{cell.textValue}</S.Name>
            <S.Size>({row.value.size})</S.Size>
          </S.NameCell>
        );
      case 'timeline':
        return (
          <Switch
            name="timeline"
            aria-label="Timeline"
            value={value}
            isDisabled={isPending !== true && scopes[SCOPES.NOTIFICATIONS] !== true}
            onChange={(nextValue) => {
              const prevValue = value;
              setValue(nextValue);
              setIsPending(true);
              notificationSettingsStore
                .setOwnerEnabled({
                  uri: row.key,
                  enabled: nextValue,
                })
                .catch((error) => {
                  ToastManager.handleError(error);
                  setValue(prevValue);
                })
                .finally(() => {
                  setIsPending(false);
                });
            }}
          />
        );
      case 'push':
        return (
          <Switch
            name="push"
            aria-label="Push"
            value={row.value.timeline === false ? false : value}
            isDisabled={
              row.value.timeline === false ||
              (isPending !== true && scopes[SCOPES.NOTIFICATIONS] !== true)
            }
            onChange={(nextValue) => {
              const prevValue = value;
              setValue(nextValue);
              setIsPending(true);
              notificationSettingsStore
                .setOwnerPush({
                  uri: row.key,
                  push: nextValue,
                })
                .catch((error) => {
                  ToastManager.handleError(error);
                  setValue(prevValue);
                })
                .finally(() => {
                  setIsPending(false);
                });
            }}
          />
        );
      case 'actions':
        return (
          <S.ActionsCell>
            <ContextMenu
              content={
                <NotificationSettingsTableOwnersContextMenuContent
                  uri={row.key}
                  name={row.value.category}
                />
              }
            >
              {({ isOpen, onContextMenu }) => {
                return (
                  <ContextMenuButton
                    isDisabled={scopes[SCOPES.NOTIFICATIONS] !== true}
                    isOpen={isOpen}
                    onPress={onContextMenu}
                  />
                );
              }}
            </ContextMenu>
          </S.ActionsCell>
        );
      default:
        break;
    }
  }

  return (
    <S.TableCell {...mergeProps(tableCell.gridCellProps, focusRing.focusProps)} ref={ref}>
      {getContent()}
    </S.TableCell>
  );
}

function S() {}
NotificationSettingsTableOwnersCell.S = S;

S.TableCell = styled.td`
  height: 50px;
  outline: none;
  cursor: default;
  background-color: ${theme.color.component};
`;

S.ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

S.NameCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

S.Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
`;

S.Size = styled.div`
  color: ${theme.color.text_light};
  padding-left: 5px;
`;
