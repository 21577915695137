import React, { useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import { useSelectState, Item } from 'react-stately';
import { useSelect, useButton, useListBox, useOption, mergeProps, HiddenSelect } from 'react-aria';

import { Overlay } from '../overlay/Overlay';

export function Select(props) {
  const selectRef = useRef();

  function children(item) {
    return (
      <Item key={item[props.keyProp]} textValue={item[props.textValueProp]}>
        {props.renderItem}
      </Item>
    );
  }

  const sharedProps = {
    label: props.label,
    items: props.items,
    children: children,
    defaultSelectedKey: props.defaultValue,
    selectedKey: props.value,
    onSelectionChange: props.onChange,
    onOpenChange: props.onOpenChange,
    defaultOpen: props.defaultOpen,
    isOpen: props.isOpen,
    autoFocus: props.autoFocus ?? true,
  };

  const selectState = useSelectState({ ...sharedProps });
  const select = useSelect({ ...sharedProps }, selectState, selectRef);
  const button = useButton(select.triggerProps, selectRef);

  return (
    <>
      <HiddenSelect
        state={selectState}
        triggerRef={selectRef}
        label={props.label}
        name={props.name}
      />

      {props.renderInput({
        ref: selectRef,
        buttonProps: button.buttonProps,
        valueProps: select.valueProps,
        selectedItem: selectState.selectedItem,
        selectState: selectState,
      })}

      <AnimatePresence>
        {selectState.isOpen && (
          <Overlay targetRef={selectRef} overlayProps={{}} overlayTriggerState={selectState}>
            <ListBox
              menuProps={select.menuProps}
              state={selectState}
              renderList={props.renderList}
            />
          </Overlay>
        )}
      </AnimatePresence>
    </>
  );
}

function ListBox(props) {
  const listBoxRef = useRef();
  const listBox = useListBox(
    {
      disallowEmptySelection: false,
      autoFocus: false,
      ...props.menuProps,
    },
    props.state,
    listBoxRef
  );

  const children = (
    <>
      {[...props.state.collection].map((item) => (
        <Option key={item.key} item={item} state={props.state} />
      ))}
    </>
  );

  return props.renderList({
    ref: listBoxRef,
    props: mergeProps(listBox.listBoxProps, props.menuProps),
    children,
  });
}

function Option({ item, state }) {
  const optionRef = useRef();
  const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = state.selectionManager.isSelected(item.key);
  const option = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
    },
    state,
    optionRef
  );

  return item.rendered({
    ref: optionRef,
    item: item,
    props: mergeProps(option.optionProps),
    state: state,
  });
}
