import { useRef } from 'react';
import styled from '@emotion/styled';

import { useSelectState, Item, Section } from 'react-stately';
import { useSelect } from 'react-aria';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { resolutions } from './resolutions';

import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { ResolutionSelectListBox } from './ResolutionSelectListBox';

import { iconClockFill } from '../../../theme/icons/interface/clock-fill/clock-fill';

export function ResolutionSelect(props) {
  const { i18n } = useI18n();

  return (
    <ListBox
      label="Resolution Select"
      onSelectionChange={props.onSelectionChange}
      selectedKey={props.selectedKey}
      items={resolutions}
    >
      {(item) => {
        const { id, children } = item;

        return (
          <Section key={id} title={id} aria-label={id}>
            {children.map((resolution) => {
              return (
                <Item key={resolution.id} resolution={resolution}>
                  {i18n.messageFormatter(`insights.resolutions.${resolution.id}`)}
                </Item>
              );
            })}
          </Section>
        );
      }}
    </ListBox>
  );
}

export function ListBox(props) {
  const buttonRef = useRef();

  const sharedProps = {
    children: props.children,
    onSelectionChange: props.onSelectionChange,
    selectedKey: props.selectedKey,
    items: props.items,
    label: props.label,
    'aria-label': props.label,
  };

  const selectState = useSelectState({
    ...sharedProps,
  });

  const select = useSelect(
    {
      ...sharedProps,
    },
    selectState,
    buttonRef
  );

  return (
    <ResolutionSelect.Root>
      <input type="hidden" name={props.name} value={selectState.selectedKey} />
      <GradientButton
        {...select.triggerProps}
        ref={buttonRef}
        title={selectState.selectedItem?.rendered}
      >
        <GradientButton.Icon url={iconClockFill} color={theme.color.white} />
        <GradientButton.Text {...select.valueProps}>
          {selectState.selectedItem?.rendered}
        </GradientButton.Text>
      </GradientButton>

      <AnimationRemain condition={selectState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <ResolutionSelectListBox
              menuProps={select.menuProps}
              selectState={selectState}
              targetRef={buttonRef}
              animationRemainProps={animationRemainProps}
            />
          );
        }}
      </AnimationRemain>
    </ResolutionSelect.Root>
  );
}

ResolutionSelect.Root = styled.div`
  display: inline-block;
`;
