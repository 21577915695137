import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import CrossFrame from '../../../lib/crossframe';
import { useLocale } from 'react-aria';

import { StateRoute } from '../../../lib/StateRoute';
import { HomeyStore, useBaseUrl } from '../../../store/HomeyStore';

import { useApp } from '../../../store/apps/useApps';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Dialog } from '../../../components/overlay/Dialog';
import { SpinningIcon } from '../../../components/common/SpinningIcon';
import { IconButton } from '../../../components/buttons/IconButton';

import { iconSpinner } from '../../../theme/icons/interface/spinner/spinner';
import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

// TODO
// Extended context menu's

export function AppSettingsDialogConfigureApp(props) {
  const { locale } = useLocale();
  const { context } = AppSettingsDialogConfigureApp.route.useContext();

  const { app, appInstance, loading } = useApp({ appId: context?.appId });
  const [isReady, setIsReady] = useState(false);

  const iFrameRef = useRef();

  const { baseUrl } = useBaseUrl();

  useEffect(() => {
    if (context?.appId == null) return;
    if (appInstance == null) return;
    if (app?.state !== 'running') return;
    if (app?.settings !== true) return;

    // Debug
    // window.addEventListener('message', (event) => {
    //   console.log(event);
    // });

    // Uses https://developer.mozilla.org/docs/Web/API/HTMLIFrameElement/contentWindow to send
    // messages to the window of the iframe. The iframe sends messages back to window.parent which
    // will be the window of the web app.
    const crossFrame = new CrossFrame(iFrameRef.current);

    crossFrame.on('alert', (data, callback) => {
      window.alert(data.text);
      callback();
    });

    crossFrame.on('confirm', (data, callback) => {
      const result = window.confirm(data.text);
      callback(null, result);
    });

    crossFrame.on('popup', (data, callback) => {
      popup(data.url, data.opts);
      callback(null);
    });

    crossFrame.on('getLanguage', (data, callback) => {
      // TODO return correct locale
      callback(null, locale);
    });

    crossFrame.on('getDevmode', (data, callback) => {
      // TODO when should this be true?
      callback(null, false);
    });

    crossFrame.on('ready', (data, callback) => {
      setIsReady(true);
      callback();
    });

    crossFrame.on('api', (data, callback) => {
      while (data.path.indexOf('..') > -1) data.path = data.path.replace('..', '');
      while (data.path.indexOf('//') > -1) data.path = data.path.replace('/', '');
      data.method = data.method.toLowerCase();

      (async () => {
        try {
          let result;
          let query = null;

          switch (data.method) {
            case 'get':
              result = await appInstance.apiGet(data.path, query);
              break;
            case 'post':
              result = await appInstance.apiPost(data.path, data.body, query);
              break;
            case 'put':
              result = await appInstance.apiPut(data.path, data.body, query);
              break;
            case 'delete':
              result = await appInstance.apiDelete(data.path, query);
              break;
            default:
              throw new Error(`Invalid method: ${data.method}`);
          }

          callback(null, result);
        } catch (error) {
          // console.error('API Error:', data.method, data.path, err);
          // error.message = err.message;
          // error.code = err.code;
          // error.description = err.error_description;
          callback(error);
        }
      })();
    });

    crossFrame.on('openURL', (data, callback) => {
      window.open(data);
      callback();
    });

    crossFrame.on('registerRealtimeListener', async (event, callback) => {
      try {
        appInstance.addListener(event, callback);
      } catch (error) {
        callback(error);
      }
    });

    crossFrame.on('getSettings', async (data, callback) => {
      try {
        const api = HomeyStore.get().api;
        const settings = await api.apps.getAppSettings({
          id: context.appId,
        });
        callback(null, settings);
      } catch (error) {
        callback(error);
      }
    });

    crossFrame.on('getSetting', async (data, callback) => {
      try {
        const api = HomeyStore.get().api;
        const settings = await api.apps.getAppSetting({
          id: context.appId,
          name: data.key,
        });
        callback(null, settings);
      } catch (error) {
        callback(error);
      }
    });

    crossFrame.on('setSetting', async (data, callback) => {
      try {
        const api = HomeyStore.get().api;
        const settings = await api.apps.setAppSetting({
          id: context.appId,
          name: data.key,
          value: data.value,
        });
        callback(null, settings);
      } catch (error) {
        callback(error);
      }
    });

    crossFrame.on('unsetSetting', async (data, callback) => {
      try {
        const api = HomeyStore.get().api;
        const settings = await api.apps.unsetAppSetting({
          id: context.appId,
          name: data.key,
        });
        callback(null, settings);
      } catch (error) {
        callback(error);
      }
    });

    crossFrame.on('getAppLocales', async (data, callback) => {
      try {
        const api = HomeyStore.get().api;
        const strings = await api.apps.getAppLocales({
          id: context.appId,
        });
        callback(null, strings);
      } catch (error) {
        callback(error);
      }
    });

    // Apply the src after attaching all listeners.
    const src = baseUrl + '/app/' + context?.appId + '/settings/';
    iFrameRef.current.src = src;

    return function () {
      crossFrame.destroy();
      setIsReady(false);
    };
  }, [locale, context?.appId, baseUrl, appInstance, app?.state, app?.settings]);

  return (
    <Dialog
      containFocus={true}
      autoFocus={true}
      onClose={() => {
        AppSettingsDialogConfigureApp.route.close();
      }}
    >
      <S.Root>
        {(() => {
          if (loading === false && app == null) {
            return <S.Message>App Not Available</S.Message>;
          }

          if (loading === false && app?.state !== 'running') {
            return <S.Message>App Not Running</S.Message>;
          }

          if (loading === false && app?.settings !== true) {
            return <S.Message>App Has No Settings</S.Message>;
          }

          if (loading === true) {
            return (
              <S.Message>
                <SpinningIcon url={iconSpinner} size={theme.icon.size_large} />
              </S.Message>
            );
          }

          if (isReady === false) {
            return (
              <S.Message>
                <SpinningIcon url={iconSpinner} size={theme.icon.size_large} />
              </S.Message>
            );
          }

          return null;
        })()}

        <S.Header>
          <h3>{app?.name}</h3>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={() => {
              AppSettingsDialogConfigureApp.route.close();
            }}
          />
        </S.Header>

        <S.IFrameWrapper data-is-ready={isReady}>
          <S.IFrame
            ref={iFrameRef}
            title="settings"
            sandbox="allow-scripts allow-forms"
            src="about:blank"
          />
        </S.IFrameWrapper>
      </S.Root>
    </Dialog>
  );
}

AppSettingsDialogConfigureApp.route = new StateRoute({ key: AppSettingsDialogConfigureApp.name });

function S() {}
AppSettingsDialogConfigureApp.S = S;

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 480px;
  height: 800px;
  background-color: ${theme.color.mono_000};
  border-radius: ${theme.borderRadius.default};
  overflow: hidden;
`;

S.Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: ${su(2)} 16px 0;
`;

S.IFrameWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
  opacity: 0;
  transition: opacity ${theme.duration.slow} ${theme.curve.easeInOut};
  overflow: hidden;

  &[data-is-ready='true'] {
    opacity: 1;
  }
`;

S.Message = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
`;

S.IFrame = styled.iframe`
  flex: 1 1 auto;
  border: 0;
  filter: ${theme.filter.darkMode};
`;

function popup(url, opts) {
  opts = opts || {};
  opts.width = opts.width || 500;
  opts.height = opts.height || 800;

  const left = window.screen.width / 2 - opts.width / 2;
  const top = window.screen.height / 2 - opts.height / 2;

  window.open(
    url,
    'homey_dialog',
    'width=' +
      opts.width +
      ', height=' +
      opts.height +
      ', left=' +
      left +
      ', top=' +
      top +
      ', menubar=no, status=no, toolbar=no'
  );
}
