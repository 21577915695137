import { theme } from '../theme';

/**
 * Get Icon Scale Hover
 * @param size
 * @returns {number}
 */
export function getIconScaleHover(size) {
  switch (size) {
    case theme.icon.size_large:
      return theme.icon.scale_hover_large;
    case theme.icon.size_small:
      return theme.icon.scale_hover_small;
    case theme.icon.size_tiny:
      return theme.icon.scale_hover_tiny;
    case theme.icon.size_default:
    default:
      return theme.icon.scale_hover_default;
  }
}

/**
 * Get Icon Scale Pressed
 * @param size
 * @returns {number}
 */
export function getIconScalePressed(size) {
  switch (size) {
    case theme.icon.size_large:
      return theme.icon.scale_press_large;
    case theme.icon.size_small:
      return theme.icon.scale_press_small;
    case theme.icon.size_tiny:
      return theme.icon.scale_press_tiny;
    case theme.icon.size_default:
    default:
      return theme.icon.scale_press_default;
  }
}
