import { memo, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';
import { isMACOS } from '../../lib/platform';

import { ToastManager } from '../../ToastManager';
import { RouteManager } from '../../RouteManager';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useFlow } from '../../store/flow/useFlows';
import { useFlowDragDrop } from './useFlowDragDrop';

import { theme } from '../../theme/theme';

import { ContextMenu } from '../common/context-menu/ContextMenu';
import { Icon } from '../common/Icon';
import { FlowContextMenuContent } from './FlowContextMenuContent';

import { iconFlow } from '../../theme/icons/system/flow/flow';
import { iconPlay } from '../../theme/icons/interface/play';
import { iconWarning } from '../../theme/icons/interface/warning/warning';

export const Flow = memo(function (props) {
  const triggerButtonRef = useRef();
  const buttonRef = useRef();
  const instanceRef = useRef({
    time: null,
  });

  const { i18n } = useI18n();
  const { flow, api, loading, error } = useFlow({ flowId: props.flowId });

  function triggerFlow() {
    (async () => {
      if (flow.triggerable === true) {
        try {
          await api.flow.triggerFlow({ id: flow.id });
          ToastManager.add({
            icon: iconFlow,
            message: i18n.messageFormatter(`flow.started`, {
              name: flow.name,
            }),
          });
        } catch (error) {
          ToastManager.handleError(error);
        }
      }
    })().catch(console.error);
  }

  function handlePress(event) {
    if (event.altKey) {
      triggerFlow();
      return;
    }

    RouteManager.toFlow(flow.id);
  }

  const button = useButton(
    {
      elementType: 'div',
      onPressStart(event) {
        instanceRef.current.time = Date.now();
      },
      onPress(event) {
        if (event.ctrlKey && isMACOS === true) return;

        const ms = Date.now() - (instanceRef.current.time ?? 0);

        if (ms > 300 && props.onLongPress != null) {
          props.onLongPress(event);
        } else {
          handlePress(event);
        }
      },
    },
    buttonRef
  );

  const triggerButton = useButton(
    {
      'aria-label': i18n.messageFormatter('common.start'),
      isDisabled: flow?.triggerable !== true,
      onPress() {
        triggerFlow();
      },
    },
    triggerButtonRef
  );

  const { dragRef, dropRef, isDragging } = useFlowDragDrop({
    flowId: flow?.id,
    isDisabled: props.isDragDropDisabled,
    onDrop: props.onDrop,
    onRequestSwap: props.onRequestSwap,
    render() {
      return (
        <Flow.Root data-is-drag-preview={true}>
          <Flow.TriggerButton data-is-disabled={flow?.triggerable !== true}>
            <Icon url={iconPlay} color={theme.color.white} size={theme.icon.size_small} />
          </Flow.TriggerButton>
          <Flow.Name title={flow?.name}>{flow?.name}</Flow.Name>
        </Flow.Root>
      );
    },
  });

  if (flow == null && loading === false && error == null) {
    return (
      <Flow.Root title={props.flowId} data-is-unknown={true} data-is-interaction-disabled={true}>
        <Icon size={theme.icon.size_default} color={theme.icon.color_medium} url={iconWarning} />
      </Flow.Root>
    );
  }

  if (loading === true) {
    return (
      <Flow.Root>
        <Flow.TriggerButton data-is-disabled={flow?.triggerable !== true}>
          <Icon url={iconPlay} color={theme.color.white} size={theme.icon.size_small} />
        </Flow.TriggerButton>
        <Flow.Name />
      </Flow.Root>
    );
  }

  if (flow == null) return null;

  return (
    <ContextMenu
      content={
        <FlowContextMenuContent
          flowId={flow.id}
          onTriggerRequest={() => {
            triggerFlow();
          }}
        />
      }
    >
      {({ isOpen, onContextMenu }) => {
        return (
          <Flow.Root
            {...button.buttonProps}
            ref={mergeRefs([dragRef, dropRef, buttonRef])}
            aria-haspopup="menu"
            aria-expanded={props.isOpen}
            data-is-dragging={isDragging}
            onContextMenu={onContextMenu}
          >
            <Flow.TriggerButton {...triggerButton.buttonProps} ref={triggerButtonRef}>
              <Icon url={iconPlay} color={theme.color.white} size={theme.icon.size_small} />
            </Flow.TriggerButton>

            <Flow.Name
              href={RouteManager.getPathForFlow(flow.id)}
              title={flow.name}
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              {flow.name}
            </Flow.Name>
          </Flow.Root>
        );
      }}
    </ContextMenu>
  );
});

Flow.Root = styled.div`
  --transform-transition: transform ease-in-out ${theme.duration.fast};

  display: inline-flex;
  align-items: center;
  min-width: 120px;
  max-width: var(--tmp-node-width, var(--node-width, 300px));
  width: var(--tmp-node-width, var(--node-width));
  height: var(--tmp-node-height, var(--node-height, 40px));
  padding-right: 10px;
  border-radius: 20px;
  background-color: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  cursor: pointer;
  transition: var(--transform-transition);

  &:hover {
    transform: scale(1.05);

    &:active:not(:focus-within) {
      transform: scale(1);
    }
  }

  &[data-is-interaction-disabled='true'] {
    cursor: default;

    &:hover {
      transform: initial;

      &:active:not(:focus-within) {
        transform: initial;
      }
    }
  }

  &[data-is-unknown='true'] {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(221, 222, 226, 0.5);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='rgb(151, 151, 151)' stroke-width='4' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &[data-is-drag-preview='true'] {
    transform: scale(0.95);
    animation: ${keyframes`
      from {
        transform: scale(1);
      }

      to {
        transform: scale(0.95);
      }
    `} ${theme.duration.slow} ease-out forwards 1;
  }

  &[data-is-dragging='true'] {
    opacity: 0;
  }
`;

Flow.TriggerButton = styled.button`
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  margin-left: -2px;
  border-radius: 50%;
  background-color: ${theme.color.success};
  transition: var(--transform-transition);

  &[data-is-disabled='true'] {
    background-color: ${theme.color.text_disabled};
  }

  &:hover:not([data-is-disabled='true']) {
    transform: scale(1.05);

    &:active:not([data-is-disabled='true']) {
      transform: scale(1);
    }
  }

  ${Icon.S.Root} {
    transform: translate(1px, 0);
  }
`;

Flow.Name = styled.a`
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  font-size: ${theme.fontSize.small};
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
`;
