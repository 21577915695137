import React, { useContext, useState, createContext } from 'react';

const Setter = createContext(null);
const Getter = createContext(null);

export function ChartTooltipProvider(props) {
  const [state, setState] = useState(null);

  return (
    <Setter.Provider value={setState}>
      <Getter.Provider value={state}>{props.children}</Getter.Provider>
    </Setter.Provider>
  );
}

export function useChartTooltipGetData() {
  return useContext(Getter);
}

export function useChartTooltipSetData() {
  return useContext(Setter);
}
