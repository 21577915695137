import { useEffect } from 'react';
import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';

/**
 * Observes the ref for resizes and recalculates the connection. Needed because cards can grow.
 *
 * @param rootRef
 * @param nodeId
 */
export function useRecalculateConnection({ rootRef, nodeId }) {
  const advancedFlowViewContext = useAdvancedFlowViewContext();

  // if the container changes update position of connections
  useEffect(() => {
    const containerElement = rootRef?.current;

    if (containerElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        const connections = advancedFlowViewContext.connectionsByNodeId.get(nodeId) ?? new Map();

        // called on initial render
        // called on element removed which we dont really need here and might cause
        // unexpected results
        if (entries[0].contentRect.width !== 0 && entries[0].contentRect.height !== 0) {
          for (const [, connection] of connections) {
            connection.recalculate();
          }
        }
      });

      resizeObserver.observe(containerElement);

      return function () {
        resizeObserver.unobserve(containerElement);
      };
    }
  }, [advancedFlowViewContext, nodeId, rootRef]);
}
