import { useMemo, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useOverlayTrigger } from 'react-aria';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { InsightUtils } from '../../../store/insights/InsightUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useLogsByKey, useLogsColorMap } from '../../../store/insights/useInsights';

import { theme } from '../../../theme/theme';

import { OutlineButton } from '../../../components/buttons/OutlineButton';
import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { InsightAddListBox } from './InsightAddListBox';

import { iconAddThin } from '../../../theme/icons/interface/plus-thin';

export function InsightAddButton(props) {
  const triggerRef = useRef();
  const overlayTriggerState = useOverlayTriggerState({});
  const { i18n } = useI18n();

  const logs = useLogsByKey();
  const { colorMap } = useLogsColorMap();
  const overlayTrigger = useOverlayTrigger({ type: 'listbox' }, overlayTriggerState, triggerRef);

  const filteredLogs = useMemo(() => {
    const selectedKeys = props.selectedKeys ?? {};

    return Object.values(logs.byKey ?? {}).filter((log) => {
      const key = ResourceUtils.temp__getId(log);
      const units = InsightUtils.getUnits(log);

      if (log.type !== 'number') return false;

      return selectedKeys[key] == null && units === props.units;
    });
  }, [logs.byKey, props.selectedKeys, props.units]);

  return (
    <>
      <OutlineButton
        {...overlayTrigger.triggerProps}
        ref={triggerRef}
        onPress={() => overlayTriggerState.toggle()}
      >
        <OutlineButton.Icon
          url={iconAddThin}
          color={theme.color.icon_dark}
          size={theme.icon.size_small}
        />
        <OutlineButton.Text>{i18n.messageFormatter('insights.addChart')}</OutlineButton.Text>
      </OutlineButton>

      <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <InsightAddListBox
              targetRef={triggerRef}
              overlayProps={overlayTrigger.overlayProps}
              overlayTriggerState={overlayTriggerState}
              items={filteredLogs}
              colorMap={colorMap}
              animationRemainProps={animationRemainProps}
              onSave={props.onRequestSelectKeys}
            />
          );
        }}
      </AnimationRemain>
    </>
  );
}

function S() {}
InsightAddButton.S = S;

S.Root = OutlineButton.Root;
