import React, { Fragment, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { mergeProps } from 'react-aria';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { TokenContextProvider } from './TokenContextProvider';
import { TokenValueContextProvider } from '../../TokenValueContext';

import { mergeRefs } from '../../../../lib/mergeRefs';
import { createCardWithStoreHook } from '../../flow-card/createCard';

import { useHover } from '../../../../hooks/useHover';
import { useDraggableButton } from './useDraggableButton';
import { useI18n } from '../../../../hooks/useI18nFormatters';
import { useTitleChunks } from '../../flow-card/useTitleChunks';
import { useExecutionState } from './useExecutionState';
import { useRecalculateConnection } from './useRecalculateConnection';
import { useTokenChildHover } from '../../TokenHoverContext';
import { useNodeArgumentsContext } from './NodeArgumentsContext';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { animationShake } from '../../../../theme/animations/animationShake';

import { Icon } from '../../../../components/common/Icon';
import { HomeyIcon } from '../../../../components/common/HomeyIcon';
import { ContextMenu } from '../../../../components/common/context-menu/ContextMenu';

import { FlowCardBase } from '../../flow-card/FlowCardBase';
import { FlowCardHint } from '../../flow-card/FlowCardHint';
import { FlowCardTokensHint } from '../../flow-card/FlowCardTokensHint';
import { AdvancedFlowCardContextMenuContent } from './AdvancedFlowCardContextMenuContent';
import { AdvancedFlowCardTestButton } from './AdvancedFlowCardTestButton';
import { CardProgress } from './CardProgress';
import { DraggableNode } from './DraggableNode';

import { ConnectorIn } from '../connectors/ConnectorIn';
import { ConnectorOut } from '../connectors/ConnectorOut';
import { ConnectorError } from '../connectors/ConnectorError';
import { ConnectorTrue } from '../connectors/ConnectorTrue';
import { ConnectorFalse } from '../connectors/ConnectorFalse';

import { executionStateType } from './executionStateType';
import { argumentMapper } from './argumentMapper';

import { iconQuestionMark } from '../../../../theme/icons/interface/questionmark';

// Injects the card with the correct title, icon... (e.g. device card);
export const AdvancedFlowCard = createCardWithStoreHook(AdvancedFlowCardInner);

function AdvancedFlowCardInner(props) {
  const { i18n } = useI18n();

  const rootRef = useRef();
  const buttonRef = useRef();

  const { executionState } = useExecutionState({
    rootRef,
    nodeId: props.nodeId,
    cardData: props.data,
  });

  const nodeArgumentsContext = useNodeArgumentsContext();

  const isUnreachableNode = executionState.type === executionStateType.unreachable;
  const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  const isTestingRoot = isTesting && props.testNodeId === props.nodeId;
  const isSaving = props.conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isDisabled = isTesting || isUnreachableNode || isSaving || props.isInteractionDisabled;
  const isDisabledStyle = isUnreachableNode ? 'unreachable' : 'readonly';

  useRecalculateConnection({ rootRef, nodeId: props.nodeId });

  const isSelected = AdvancedFlowViewStore.useIsSelected({ id: props.nodeId });

  const hover = useHover({
    isDisabled: isDisabled,
  });
  const tokenChildHover = useTokenChildHover({ nodeId: props.nodeId });

  const [isDraggingAndCanConnect, setIsDraggingAndCanConnect] = useState(null);
  const [isDragOverAndCanConnect, setIsDragOverAndCanConnect] = useState(null);
  const button = useDraggableButton(
    {
      elementType: 'div',
      isDisabled: isDisabled,
      onPress: props.onPress,
    },
    buttonRef
  );

  const { titleChunks } = useTitleChunks({
    card: props.card,
    data: props.data,
  });

  function handleIsDraggingAndCanConnectChange(isDraggingAndCanConnect) {
    // Dont update the state if it's null and it's called with false. This ensures the initial render
    // does not cause an animation effect.
    setIsDraggingAndCanConnect((prevState) => {
      if (prevState == null && isDraggingAndCanConnect === false) {
        return prevState;
      }

      return isDraggingAndCanConnect;
    });
  }

  function handleIsDragOverAndCanConnectChange(isDragOverAndCanConnect) {
    // Dont update the state if it's null and it's called with false. This ensures the initial render
    // does not cause an animation effect.
    setIsDragOverAndCanConnect((prevState) => {
      if (prevState == null && isDragOverAndCanConnect === false) {
        return prevState;
      }

      return isDragOverAndCanConnect;
    });
  }

  function renderContent() {
    if (props.isLoading === true) {
      return (
        <Fragment>
          <FlowCardBase.IconWrapper
            style={{
              '--flow-card-icon-wrapper-background': theme.color.mono_020,
            }}
          >
            {/*<Icon url={iconLoadingThrobber} color={theme.icon.color_dark} />*/}
          </FlowCardBase.IconWrapper>

          <FlowCardBase.Details>
            <FlowCardBase.NameLoading />
            <FlowCardBase.ContentLoading />
          </FlowCardBase.Details>
        </Fragment>
      );
    }

    // Card unavailble (deleted from system)
    if (props.card == null) {
      return (
        <Fragment>
          <FlowCardBase.IconWrapper
            style={{
              '--flow-card-icon-wrapper-background': theme.icon.color_light,
            }}
          >
            <Icon url={iconQuestionMark} color={theme.icon.color_white} />
          </FlowCardBase.IconWrapper>

          <FlowCardBase.Details>
            <div
              style={{
                display: 'flex',
                flex: '1 1 auto',
                alignItems: 'center',
                gap: 5,
                color: theme.color.text_light,
              }}
            >
              {i18n.messageFormatter('flow.unavailableCard')}
              <FlowCardHint
                hint={
                  <div>
                    <div style={{ paddingBottom: 10 }}>
                      {i18n.messageFormatter('flow.unavailableCardMessage')}
                    </div>
                    <div>{props.data?.ownerUri ?? props.data?.uri}</div>
                    <div>{props.data?.id}</div>
                  </div>
                }
              />
            </div>
          </FlowCardBase.Details>
        </Fragment>
      );
    }

    function renderIcon() {
      if (props.iconComponent) {
        return props.iconComponent;
      }

      if (props.iconUrl != null) {
        return (
          <Icon
            url={props.iconUrl}
            color={theme.color.white}
            size={props.iconSize ?? theme.icon.size_medium}
          />
        );
      }

      return (
        <HomeyIcon
          iconObj={props.iconObj}
          color={theme.color.white}
          size={props.iconSize ?? theme.icon.size_default}
        />
      );
    }

    const details = (
      <FlowCardBase.Details>
        <FlowCardBase.Header>
          <FlowCardBase.Name>{props.name}</FlowCardBase.Name>
          {props.card.hint != null && (
            <FlowCardHint isDisabled={isDisabled} hint={props.card.hint} />
          )}
          {props.card.tokens != null && props.card.tokens?.length > 0 && (
            <AdvancedFlowCard.Tag>
              <FlowCardTokensHint
                isDisabled={isDisabled}
                isHighlightIcon={tokenChildHover.isHovered}
                tokens={props.card.tokens}
                cardDataKey={props.nodeId}
                cardType={props.data.type}
              />
            </AdvancedFlowCard.Tag>
          )}
        </FlowCardBase.Header>
        <FlowCardBase.Content>
          {titleChunks.map((chunk, index) => {
            return argumentMapper({
              chunk,
              index,
              cardContainerRef: rootRef,
              card: props.card,
              data: props.data,
              isDisabled: isDisabled,
              isDisabledStyle: isDisabledStyle,
              onArgumentUpdate(args) {
                AdvancedFlowViewStore.updateCardArg({
                  nodeId: props.nodeId,
                  argumentKey: args.argumentKey,
                  value: args.value,
                });
              },
              onPropertyUpdate(args) {
                AdvancedFlowViewStore.updateCardProperty({
                  nodeId: props.nodeId,
                  propertyKey: args.propertyKey,
                  value: args.value,
                });
              },
            });
          })}
        </FlowCardBase.Content>
      </FlowCardBase.Details>
    );

    if (props.data.type === 'trigger') {
      return (
        <Fragment>
          <AdvancedFlowCard.ButtonWrapper>
            <AdvancedFlowCard.TestButton>
              <AdvancedFlowCardTestButton
                nodeId={props.nodeId}
                isUnreachableNode={isUnreachableNode}
                isTesting={isTesting}
                isTestingRoot={isTestingRoot}
                isSaving={isSaving}
                isDisabled={isDisabled}
                isDisabledStyle={isDisabledStyle}
                onStartRequest={props.onStartRequest}
              />
            </AdvancedFlowCard.TestButton>

            <FlowCardBase.IconWrapper
              style={{
                '--flow-card-icon-wrapper-background': props.iconBackgroundColor,
              }}
            >
              {renderIcon()}
            </FlowCardBase.IconWrapper>
          </AdvancedFlowCard.ButtonWrapper>

          {details}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <FlowCardBase.IconWrapper
          style={{
            '--flow-card-icon-wrapper-background': props.iconBackgroundColor,
          }}
        >
          {renderIcon()}
        </FlowCardBase.IconWrapper>

        {details}
      </Fragment>
    );
  }

  function renderConnectors() {
    const sharedProps = {
      cardRootRef: rootRef,
      nodeId: props.nodeId,
      activeConnectors: props.activeConnectors,
      nodeChildren: props.nodeChildren,
      nodeParents: props.nodeParents,
      executionState: executionState,
      isSelected: isSelected,
      isHovered: hover.isHovered,
      isDragOverAndCanConnect: isDragOverAndCanConnect,
      isUnreachableNode: isUnreachableNode,
      isDisabled: isDisabled,
      isDisabledStyle: isDisabledStyle,
    };

    switch (props.data.type) {
      case 'trigger':
        return (
          <React.Fragment>
            <ConnectorOut
              {...sharedProps}
              style={{
                position: 'absolute',
                left: 'calc(100% + 1px)',
                top: '50%',
                transform: 'translate(0, -50%) rotate(-90deg)',
              }}
            />
          </React.Fragment>
        );
      case 'condition':
        return (
          <React.Fragment>
            <ConnectorTrue
              {...sharedProps}
              style={{
                position: 'absolute',
                top: '50%',
                left: 'calc(100% + 1px)',
                transform: 'translate(0, calc(-50% - 11px)) rotate(-90deg)',
              }}
            />
            <ConnectorFalse
              {...sharedProps}
              style={{
                position: 'absolute',
                top: '50%',
                left: 'calc(100% + 1px)',
                transform: 'translate(0, calc(-50% + 11px)) rotate(-90deg)',
              }}
            />
            <ConnectorError
              {...sharedProps}
              style={{
                position: 'absolute',
                top: 'calc(100%)',
                right: '50%',
                transform: 'translate(50%, 0) rotate(0deg)',
              }}
            />
            <ConnectorIn
              {...sharedProps}
              style={{
                position: 'absolute',
                top: '50%',
                right: 'calc(100% + 1px)',
                transform: 'translate(0, -50%) rotate(90deg)',
              }}
              onIsDraggingAndCanConnectChange={handleIsDraggingAndCanConnectChange}
              onIsDragOverAndCanConnectChange={handleIsDragOverAndCanConnectChange}
            />
          </React.Fragment>
        );
      case 'action':
        return (
          <React.Fragment>
            <ConnectorOut
              {...sharedProps}
              style={{
                position: 'absolute',
                top: '50%',
                left: 'calc(100% + 1px)',
                transform: 'translate(0, -50%) rotate(-90deg)',
              }}
            />
            <ConnectorError
              {...sharedProps}
              style={{
                position: 'absolute',
                top: 'calc(100%)',
                right: '50%',
                transform: 'translate(50%, 0) rotate(0deg)',
              }}
            />
            <ConnectorIn
              {...sharedProps}
              style={{
                position: 'absolute',
                top: '50%',
                right: 'calc(100% + 1px)',
                transform: 'translate(0, -50%) rotate(90deg)',
              }}
              onIsDraggingAndCanConnectChange={handleIsDraggingAndCanConnectChange}
              onIsDragOverAndCanConnectChange={handleIsDragOverAndCanConnectChange}
            />
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  return (
    <TokenContextProvider nodeId={props.nodeId} cardData={props.data} cardInstance={props.card}>
      <TokenValueContextProvider tokens={executionState.usedTokens}>
        <ContextMenu
          isDisabled={isDisabled}
          content={
            <AdvancedFlowCardContextMenuContent
              nodeId={props.nodeId}
              card={props.card}
              data={props.data}
              onStartRequest={props.onStartRequest}
              onReplaceRequest={props.onReplaceRequest}
              onCopyRequest={props.onCopyRequest}
              onDuplicateRequest={props.onDuplicateRequest}
              onDeleteRequest={props.onDeleteRequest}
              onInvertRequest={props.onInvertRequest}
            />
          }
        >
          {({ isOpen, onContextMenu }) => {
            return (
              <AdvancedFlowCard.Root
                {...mergeProps(button.buttonProps, hover.hoverProps)}
                ref={mergeRefs([props.forwardedRef, rootRef, buttonRef])}
                className={props.className}
                data-is-selected={isSelected}
                data-is-test-node={props.testNodeId === props.nodeId}
                data-is-dragging-and-can-connect={isDraggingAndCanConnect}
                data-is-dragover-and-can-connect={isDragOverAndCanConnect}
                data-is-owned-token-highlight={tokenChildHover.isHovered}
                data-is-invalid={nodeArgumentsContext.isNodeInvalid}
                data-style-type={props.data.type}
                data-execution-state-type={executionState.type}
                onContextMenu={onContextMenu}
              >
                <AdvancedFlowCard.Inner>
                  {renderContent()}

                  {props.data.type !== 'trigger' && (
                    <CardProgress
                      nodeId={props.nodeId}
                      card={props.card}
                      data={props.data}
                      executionState={executionState}
                      isDisabled={isDisabled}
                      isCardHovered={hover.isHovered}
                      isSelected={isSelected}
                    />
                  )}
                </AdvancedFlowCard.Inner>
                {renderConnectors()}
              </AdvancedFlowCard.Root>
            );
          }}
        </ContextMenu>
      </TokenValueContextProvider>
    </TokenContextProvider>
  );
}

/**
 * Consists of a root and inner element to allow multiple shadows and animations.
 */
AdvancedFlowCard.Root = styled.div`
  --flow-token-max-width: 270px; // Used in src/containers/flows/flow-arguments/FlowToken.js
  --flowcard-background: ${theme.flowcard.background};
  --flowcard-border-radius: ${theme.borderRadius.default};
  --flowcard-highlight-border-radius: calc(${theme.borderRadius.default} + 2px);

  position: relative;
  background-color: var(--flowcard-background);
  border-radius: var(--flowcard-border-radius);
  outline: 0;

  transition-property: background-color;
  transition-duration: ${theme.duration.fast};
  transition-timing-function: ${theme.curve.fastIn};

  // Creates a additional shadow on hover to emphasize the card
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // Needed because else the arguments are not pressable.
    // Not adding pointer-events: none because that causes shift drag to
    // become broken.
    z-index: -1;
    border-radius: var(--flowcard-highlight-border-radius);
    box-shadow: ${theme.boxShadow.default};
    opacity: 0;
    transition-property: opacity;
    transition-duration: ${theme.duration.fast};
    transition-timing-function: ${theme.curve.fastIn};
  }

  // Selection border
  &::after {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    // Needed because else the arguments are not pressable.
    // Not adding pointer-events: none because that causes shift drag to
    // become broken.
    z-index: -1;
    border: 2px solid transparent;
    border-radius: var(--flowcard-highlight-border-radius);
    opacity: 0;
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: opacity, background-color, border-color;
  }

  &[data-style-type='trigger'] {
    --flowcard-border-radius: 30px 10px 10px 30px;
    --flowcard-highlight-border-radius: 32px 12px 12px 32px;
  }

  &[data-style-type='all'] {
    --flowcard-width: auto;
    --flowcard-border-radius: 10px 20px 20px 10px;
    --flowcard-highlight-border-radius: 12px 22px 22px 12px;
  }

  &[data-style-type='start'] {
    --flowcard-width: auto;
    --flowcard-padding: ${su(0.5)};
    --flowcard-border-radius: 50%;
    --flowcard-highlight-border-radius: 50%;
  }

  &[data-style-type='any'],
  &[data-style-type='delay'] {
    --flowcard-width: auto;
    --flowcard-border-radius: 20px;
    --flowcard-highlight-border-radius: 22px;
  }

  &[data-style-type='delay'] {
    --flowcard-padding: ${su(1, 1.5, 1, 1)};
  }

  &[data-style-type='note'] {
  }

  &[data-is-invalid='true'],
  &[data-execution-state-type='${executionStateType.error}'] {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    animation-name: ${animationShake.default};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-duration: ${theme.duration.slow};
    animation-fill-mode: both;
    animation-iteration-count: 1;
  }

  // Do net set pointer-events: none on card for disabled. This
  // creates odd click though elements that we dont want.
  &[data-execution-state-type='${executionStateType.unreachable}'] {
    color: ${theme.flowcard.disabled_color_text};

    ${FlowCardBase.Name} {
      color: ${theme.flowcard.disabled_color_text};
    }

    ${FlowCardBase.Content} {
      color: ${theme.flowcard.disabled_color_text};
    }

    ${FlowCardBase.IconWrapper} {
      background-color: ${theme.flowcard.disabled_background_color};

      ${Icon.S.Root} {
        background-color: ${theme.flowcard.disabled_icon_color};
      }
    }
  }

  &[data-is-selected='true'] {
    --flowcard-background: ${theme.color.selected_background};

    &::after {
      border-color: ${theme.color.selected_border};
      opacity: 1;
    }
  }

  &[data-is-dragover-and-can-connect='true'] {
    --flowcard-background: ${theme.flowcard.dragover_background};

    &::after {
      border-color: ${theme.flowcard.dragover_border};
      opacity: 1;
    }
  }

  &:hover {
    &::before {
      opacity: 0.5;
    }
  }
`;

AdvancedFlowCard.Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--flowcard-padding, ${su(1)});
  width: var(--flowcard-width, 340px);
  border-radius: var(--flowcard-border-radius);
  outline: 0;

  /**
   * The main highlight shadow that will pulse when a card can be connected during
   * a drag operation.
   */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // Needed because else the arguments are not pressable.
    // Not adding pointer-events: none because that causes shift drag to
    // become broken.
    z-index: -1;
    border-radius: var(--flowcard-highlight-border-radius);
    box-shadow: ${theme.flowcard.dragging_and_can_connect_shadow};
    opacity: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // Needed because else the arguments are not pressable.
    // Not adding pointer-events: none because that causes shift drag to
    // become broken.
    z-index: -1;
    border-radius: var(--flowcard-highlight-border-radius);
    box-shadow: ${theme.boxShadow.default};
    opacity: 1;
  }

  // Reapply the default box shadow smoothly
  ${AdvancedFlowCard.Root}[data-is-owned-token-highlight='false'] &::after,
  ${AdvancedFlowCard.Root}[data-is-dragging-and-can-connect='false'] &::after {
    animation-name: ${keyframes`
      from {
        opacity: 0.5;
      }

      to {
        opacity: 1;
      }
    `};
    animation-duration: 350ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  // place unreachable box-shadow opacity here

  ${AdvancedFlowCard.Root}[data-is-dragging-and-can-connect='true'] & {
    // Blue Pulse
    &::before {
      animation-name: ${keyframes`
        from {
          opacity: 0.5;
        }

        to {
          opacity: 1;
        }
      `};
      animation-duration: 1000ms;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }

    // Fade out the default box shadow smoothly
    &::after {
      animation-name: ${keyframes`
        from {
          opacity: 0.5;
        }

        to {
          opacity: 0;
        }
      `};
      animation-duration: 500ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
  }

  ${AdvancedFlowCard.Root}[data-is-owned-token-highlight='true'] & {
    // Blue Pulse
    &::before {
      animation: 1s cubic-bezier(1, 0, 0.75, 0.25) forwards infinite alternate;
      animation-name: ${keyframes`
        from {
          opacity: 0.5;
        }

        to {
          opacity: 1;
        }
      `};
    }

    // Fade out the default box shadow smoothly
    &::after {
      animation: 1000ms ease-in-out forwards;
      animation-name: ${keyframes`
        from {
          opacity: 0.5;
        }

        to {
          opacity: 0;
        }
      `};
    }
  }
`;

AdvancedFlowCard.TestButton = styled.div``;

AdvancedFlowCard.ButtonWrapper = styled.div`
  position: relative;
  flex: 0 0 40px;
  height: 40px;

  ${AdvancedFlowCard.TestButton} {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 350ms ${theme.curve.easeInOut};
    z-index: 10;

    // If not dragging and selected.
    ${DraggableNode.Root}:not([data-is-dragging='true']) ${AdvancedFlowCard.Root}[data-is-selected='true'] &,
      // If not dragging and root test node.
    ${DraggableNode.Root}:not([data-is-dragging='true']) ${AdvancedFlowCard.Root}[data-is-test-node='true'] &,
      // If not dragging, not unreachable and hovered.
    ${DraggableNode.Root}:not([data-is-dragging='true']) ${AdvancedFlowCard.Root}:not([data-execution-state-type='${executionStateType.unreachable}']) ${AdvancedFlowCard.Inner}:hover & {
      opacity: 1;
    }
  }

  ${FlowCardBase.IconWrapper} {
    opacity: 1;
    transition: opacity 350ms ${theme.curve.easeInOut};

    // If not dragging and selected.
    ${DraggableNode.Root}:not([data-is-dragging='true']) ${AdvancedFlowCard.Root}[data-is-selected='true'] &,
      // If not dragging and root test node.
    ${DraggableNode.Root}:not([data-is-dragging='true']) ${AdvancedFlowCard.Root}[data-is-test-node='true'] &,
      // If not dragging, not unreachable and hovered.
    ${DraggableNode.Root}:not([data-is-dragging='true']) ${AdvancedFlowCard.Root}:not([data-execution-state-type='${executionStateType.unreachable}']) ${AdvancedFlowCard.Inner}:hover & {
      opacity: 0;
    }
  }
`;

AdvancedFlowCard.Tag = styled.div`
  ${AdvancedFlowCard.Root}[data-is-owned-token-highlight='true'] & {
    animation: 1s forward;
    transform-origin: 12.5px 1.5px;
    animation-name: ${keyframes`
      0% {
        transform: rotate(0deg);
        animation-timing-function: ease-in-out;
      }

      5% {
        transform: rotate(4deg);
        animation-timing-function: ease-in-out;
      }

      20% {
        transform: rotate(-10deg);
        animation-timing-function: ease-in-out;
      }

      35% {
        transform: rotate(3deg);
        animation-timing-function: ease-in-out;
      }

      50% {
        transform: rotate(-7.5deg);
        animation-timing-function: ease-in-out;
      }

      65% {
        transform: rotate(2deg);
        animation-timing-function: ease-in-out;
      }

      80% {
        transform: rotate(-5deg);
        animation-timing-function: ease-in-out;
      }

      95% {
        transform: rotate(1deg);
        animation-timing-function: ease-in-out;
      }

      100% {
        transform: rotate(0deg);
        animation-timing-function: ease-in-out;
      }
    `};
  }
`;
