import type { BaseApiStoreState } from './BaseApiStore2';

type State = BaseApiStoreState & { isLoading: boolean | null; error: unknown };

export function selectBase<TState extends State>(state: TState) {
  return {
    isLoading: state.isLoading,
    error: state.error,
    api: state.api,
    retry: state.retry,
    refresh: state.refresh,
  };
}
