import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { SCOPES } from '../../../lib/scopes';
import { replaceDot } from '../../../lib/replaceDot';
import { enqueueTask } from '../../../lib/enqueueTask';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { scrollbars } from '../../../theme/elements/scrollbars';

import { TextField } from '../../../components/forms/TextField';
import { CheckboxControllable } from '../../../components/forms/CheckboxControllable';

export function ApiKeysSettingsForm(props) {
  const { i18n } = useI18n();

  const ref = useRef(null);
  const [key, setKey] = useState(0);

  if (ref.current == null) {
    const defaultValues = {
      name: '',
    };

    for (const scope of Object.values(SCOPES)) {
      const replaced = replaceDot.replace(scope);
      defaultValues[replaced] = false;
    }

    ref.current = defaultValues;
  }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: ref.current,
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (props.pat != null) {
      const defaultValues = {
        name: props.pat.name,
      };

      const currentScopes = new Set([...(props.pat?.scopes ?? [])]);

      for (const scope of Object.values(SCOPES)) {
        const replaced = replaceDot.replace(scope);
        defaultValues[replaced] = currentScopes.has(scope);
      }

      ref.current = defaultValues;
      reset(defaultValues);

      // Let reset render first.
      enqueueTask(() => {
        setKey((state) => state + 1);
      });
    }
  }, [reset, props.pat]);

  const watchAllFields = watch();

  return (
    <S.Root
      // Remount the whole thing on key change because at this time it seems impossible to fix
      // the checkbox component to reflect the current checked state. useForm reset does not work
      // properly.
      key={key}
      id={props.id}
      onSubmit={handleSubmit((data) => {
        const { name, ...rest } = data;
        const scopes = [];

        for (const [key, value] of Object.entries(rest)) {
          if (value === true) {
            scopes.push(replaceDot.reverseReplace(key));
          }
        }

        props.onSubmit({ name, scopes });
      })}
    >
      {props.isNameHidden !== true && (
        <TextField
          name="name"
          label={i18n.messageFormatter('common.name')}
          placeholder="..."
          orientation="vertical"
          register={register}
          required={true}
          error={errors.name}
          isReadOnly={props.isReadOnly}
          isDisabled={props.isReadOnly}
        />
      )}

      {Object.entries({
        HOMEY: {
          ROOT: {
            value: SCOPES.EVERYTHING,
            label: i18n.messageFormatter('scopes.homey'),
          },
        },
        DEVICE: {
          ROOT: {
            value: SCOPES.DEVICE,
            label: i18n.messageFormatter('scopes.device'),
          },
          DEVICE_READONLY: {
            value: SCOPES.DEVICE_READONLY,
            label: i18n.messageFormatter('scopes.device.readonly'),
          },
          DEVICE_CONTROL: {
            value: SCOPES.DEVICE_CONTROL,
            label: i18n.messageFormatter('scopes.device.control'),
          },
        },
        FLOW: {
          ROOT: {
            value: SCOPES.FLOW,
            label: i18n.messageFormatter('scopes.flow'),
          },
          FLOW_READONLY: {
            value: SCOPES.FLOW_READONLY,
            label: i18n.messageFormatter('scopes.flow.readonly'),
          },
          FLOW_START: {
            value: SCOPES.FLOW_START,
            label: i18n.messageFormatter('scopes.flow.start'),
          },
        },
        MOOD: {
          ROOT: {
            value: SCOPES.MOOD,
            label: i18n.messageFormatter('scopes.mood'),
          },
          FLOW_READONLY: {
            value: SCOPES.MOOD_READONLY,
            label: i18n.messageFormatter('scopes.mood.readonly'),
          },
          FLOW_START: {
            value: SCOPES.MOOD_SET,
            label: i18n.messageFormatter('scopes.mood.set'),
          },
        },
        LOGIC: {
          ROOT: {
            value: SCOPES.LOGIC,
            label: i18n.messageFormatter('scopes.logic'),
          },
          LOGIC_READONLY: {
            value: SCOPES.LOGIC_READONLY,
            label: i18n.messageFormatter('scopes.logic.readonly'),
          },
        },
        ZONE: {
          ROOT: {
            value: SCOPES.ZONE,
            label: i18n.messageFormatter('scopes.zone'),
          },
          ZONE_READONLY: {
            value: SCOPES.ZONE_READONLY,
            label: i18n.messageFormatter('scopes.zone.readonly'),
          },
        },
        NOTIFICATIONS: {
          ROOT: {
            value: SCOPES.NOTIFICATIONS,
            label: i18n.messageFormatter('scopes.notifications'),
          },
          NOTIFICATIONS_READONLY: {
            value: SCOPES.NOTIFICATIONS_READONLY,
            label: i18n.messageFormatter('scopes.notifications.readonly'),
          },
        },
        APP: {
          ROOT: {
            value: SCOPES.APP,
            label: i18n.messageFormatter('scopes.app'),
          },
          APP_READONLY: {
            value: SCOPES.APP_READONLY,
            label: i18n.messageFormatter('scopes.app.readonly'),
          },
          APP_CONTROL: {
            value: SCOPES.APP_CONTROL,
            label: i18n.messageFormatter('scopes.app.control'),
          },
        },
        USER: {
          ROOT: {
            value: SCOPES.USER,
            label: i18n.messageFormatter('scopes.user'),
          },
          USER_READONLY: {
            value: SCOPES.USER_READONLY,
            label: i18n.messageFormatter('scopes.user.readonly'),
          },
          USER_SELF: {
            value: SCOPES.USER_SELF,
            label: i18n.messageFormatter('scopes.user.self'),
          },
        },
        INSIGHTS: {
          ROOT: {
            value: SCOPES.INSIGHTS,
            label: i18n.messageFormatter('scopes.insights'),
          },
          INSIGHTS_READONLY: {
            value: SCOPES.INSIGHTS_READONLY,
            label: i18n.messageFormatter('scopes.insights.readonly'),
          },
        },
        ALARM: {
          ROOT: {
            value: SCOPES.ALARM,
            label: i18n.messageFormatter('scopes.alarms'),
          },
          ALARM_READONLY: {
            value: SCOPES.ALARM_READONLY,
            label: i18n.messageFormatter('scopes.alarms.readonly'),
          },
        },
        PRESENCE: {
          ROOT: {
            value: SCOPES.PRESENCE,
            label: i18n.messageFormatter('scopes.presence'),
          },
          PRESENCE_READONLY: {
            value: SCOPES.PRESENCE_READONLY,
            label: i18n.messageFormatter('scopes.presence.readonly'),
          },
          PRESENCE_SELF: {
            value: SCOPES.PRESENCE_SELF,
            label: i18n.messageFormatter('scopes.presence.self'),
          },
        },
        SYSTEM: {
          ROOT: {
            value: SCOPES.SYSTEM,
            label: i18n.messageFormatter('scopes.system'),
          },
          SYSTEM_READONLY: {
            value: SCOPES.SYSTEM_READONLY,
            label: i18n.messageFormatter('scopes.system.readonly'),
          },
        },
        UPDATES: {
          ROOT: {
            value: SCOPES.UPDATES,
            label: i18n.messageFormatter('scopes.updates'),
          },
          UPDATES_READONLY: {
            value: SCOPES.UPDATES_READONLY,
            label: i18n.messageFormatter('scopes.updates.readonly'),
          },
        },
        GEOLOCATION: {
          ROOT: {
            value: SCOPES.GEOLOCATION,
            label: i18n.messageFormatter('scopes.geolocation'),
          },
          GEOLOCATION_READONLY: {
            value: SCOPES.GEOLOCATION_READONLY,
            label: i18n.messageFormatter('scopes.geolocation.readonly'),
          },
        },
      }).map(([scopeGroupKey, scopeGroup]) => {
        return (
          <S.Group key={scopeGroupKey}>
            {Object.values(scopeGroup).map((scope) => {
              const replaced = replaceDot.replace(scope.value);
              const [rootScope, parentScope, selfScope] = scope.value.split('.');
              let forceSelectedStyle = null;

              if (rootScope && parentScope && selfScope) {
                if (
                  watchAllFields[rootScope] ||
                  watchAllFields[replaceDot.replace(`${rootScope}.${parentScope}`)]
                ) {
                  forceSelectedStyle = true;
                }
              } else if (rootScope && parentScope) {
                if (watchAllFields[rootScope]) {
                  forceSelectedStyle = true;
                }
              }

              return (
                <S.CheckboxContainer key={replaced} data-is-child-scope={selfScope != null}>
                  <CheckboxControllable
                    name={replaced}
                    label={scope.label}
                    control={control}
                    validate={(value, values) => {
                      let valid = false;

                      // Require at least one scope to be selected. The only way to do this
                      // in react hook form seems to add a validate to every field.
                      for (const [key, value] of Object.entries(values)) {
                        if (key.startsWith('homey') && value === true) {
                          valid = true;
                        }
                      }

                      return valid;
                    }}
                    defaultChecked={ref.current[replaced]}
                    error={errors[replaced]}
                    isReversed={true}
                    isDisabled={props.isReadOnly ?? forceSelectedStyle}
                    isReadOnly={props.isReadOnly}
                    forceSelectedStyle={forceSelectedStyle}
                    styleSize="small"
                    styleColor="blue"
                  />

                  <S.ScopeKey>{scope.value}</S.ScopeKey>
                </S.CheckboxContainer>
              );
            })}
          </S.Group>
        );
      })}
    </S.Root>
  );
}

function S() {}
ApiKeysSettingsForm.S = S;

S.Root = styled.form`
  ${scrollbars.dark};
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 ${su(4)};

  ${TextField.Root} {
    padding-bottom: 20px;
  }
`;

S.Group = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  &:not(:last-child)::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: ${theme.color.line};
  }
`;

S.ScopeKey = styled.div`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.text_light};
`;

S.CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  &[data-is-child-scope='true'] {
    ${CheckboxControllable.S.Root} {
      padding-left: 20px;
    }
  }

  ${CheckboxControllable.S.Root} {
    flex: 0 0 50%;
  }

  ${S.ScopeKey} {
    flex: 0 0 50%;
  }

  ${CheckboxControllable.S.Label} {
    font-size: 16px;
    padding-left: 10px;
  }
`;
