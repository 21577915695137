import { useRef } from 'react';
import styled from '@emotion/styled';

import { useButton } from 'react-aria';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

import { Icon } from '../../../components/common/Icon';

import { iconPlusBoldSmall } from '../../../theme/icons/interface/plus-bold';

export function NewZoneButton(props) {
  const { i18n } = useI18n();
  const buttonRef = useRef();
  const button = useButton(props, buttonRef);

  return (
    <ButtonBase {...button.buttonProps} ref={buttonRef}>
      <Icon url={iconPlusBoldSmall} size={theme.icon.size_default} color={theme.color.icon_dark} />
      <Text>{i18n.messageFormatter('zone.newZone')}</Text>
    </ButtonBase>
  );
}

const ButtonBase = styled.button`
  appearance: none;
  display: inline-flex;
  position: relative;
  z-index: calc(${theme.zIndex.nav} + 1);
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 10px;
  background-color: transparent;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius.default};
  transition: all ${theme.duration.fast};
  white-space: nowrap;

  &:hover {
    transform: scale(${theme.icon.scale_default});
  }
`;

const Text = styled.span`
  margin-left: 10px;
  display: inline-block;
`;
