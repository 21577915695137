import React, { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';

import { useButton, useFocusRing, mergeProps } from 'react-aria';
import { useOverlayTrigger } from '../../../components/overlay/useOverlayTrigger';

import { theme } from '../../../theme/theme';

import { Icon } from '../../../components/common/Icon';
import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { Overlay } from '../../../components/overlay/Overlay';
import { FlowToken } from './FlowToken';

export function Argument(props) {
  const defaultRef = useRef();
  const overlayTriggerRef = props.triggerRef ?? defaultRef;
  const overlayTriggerState = props.triggerState;
  const overlayTrigger = useOverlayTrigger(
    { type: 'dialog' },
    overlayTriggerState,
    overlayTriggerRef
  );

  const focusRing = useFocusRing();
  const button = useButton(
    {
      ...overlayTrigger.triggerProps,
      isDisabled: props.isTriggerDisabled,
      elementType: 'span',
      onPress: () => {
        overlayTriggerState.toggle();
      },
    },
    overlayTriggerRef
  );

  return (
    <>
      {props.renderTrigger(overlayTriggerRef, {
        ...mergeProps(button.buttonProps, focusRing.focusProps),
        'data-is-disabled': props.isTriggerDisabled,
        'data-is-disabled-style': props.isTriggerDisabledStyle,
        // 'data-is-focus-visible': focusRing.isFocusVisible, // disabled for now
      })}
      <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={overlayTriggerRef}
              animationRemainAnimation={props.animationRemainAnimation}
              overlayProps={overlayTrigger.overlayProps}
              overlayTriggerState={overlayTriggerState}
              offset={10}
              animationRemainProps={animationRemainProps}
              onClose={(reason) => {
                switch (reason) {
                  case Overlay.REASON.INTERACT_OUTSIDE:
                  case Overlay.REASON.SCROLL:
                    props.onCloseRequest?.();
                    break;
                  case Overlay.REASON.ESCAPE:
                    if (props.onCancelRequest != null) {
                      props.onCancelRequest();
                    } else {
                      props.onCloseRequest?.();
                    }
                    break;
                  default:
                    break;
                }
              }}
            >
              {props.renderOverlay()}
            </Overlay>
          );
        }}
      </AnimationRemain>
    </>
  );
}

export const ArgumentBase = forwardRef((props, ref) => {
  return (
    <>
      <Argument.Space
        data-is-empty={props['data-is-empty']}
        data-is-example={props['data-is-example']}
      >
        {' '}
      </Argument.Space>
      <Argument.Root {...props} ref={ref} />
      <Argument.Space
        data-is-empty={props['data-is-empty']}
        data-is-example={props['data-is-example']}
      >
        {' '}
      </Argument.Space>
    </>
  );
});

Argument.IconWrapper = styled.div`
  display: inline-flex;
  padding-right: 2px;
  transform: translate(-2px, 2px);
`;

// Controlling the font-size to increase or decrease space.
Argument.Space = styled.span`
  font-size: 2px;

  &[data-is-empty='true'],
  &[data-is-example='true'] {
    font-size: 8px;
  }
`;

Argument.Root = styled.span`
  position: relative;
  display: inline;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0 1px;
  outline: 0;
  box-decoration-break: slice;
  word-break: break-word;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.default};
  transition: ${theme.duration.micro} ${theme.curve.easeInOut};
  transition-property: color, background-color, border-color, border-radius;

  &[data-is-droptoken='true'] {
    padding: 0;
  }

  &[data-is-empty='true'],
  &[data-is-example='true'] {
    padding: 2px 6px;
    border-color: ${theme.color.line};
    border-radius: 10px;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSize.tiny};

    &:not([data-is-disabled='true'][data-is-disabled-style='unreachable']) {
      color: ${theme.color.text_light};
    }
  }

  &[data-is-disabled='true'] {
    ${FlowToken.Root} {
      cursor: unset;
    }

    &[data-is-disabled-style='unreachable'] {
      ${FlowToken.Root} {
        pointer-events: none;
        --flow-token-background: ${theme.flowcard.disabled_background_color};
      }
    }
  }

  ${Argument.IconWrapper} ${Icon.S.Root} {
    transition: ${theme.duration.micro} ${theme.curve.easeInOut};
    transition-property: background;
  }

  &:not([data-is-example='true']):not([data-is-disabled='true']) {
    &[aria-expanded='true'] {
      background: ${theme.color.blue_o_08};
      color: ${theme.color.highlight};

      ${Argument.IconWrapper} ${Icon.S.Root} {
        background: ${theme.color.highlight};
      }

      &[data-is-empty='true'] {
        border-color: ${theme.color.highlight};
      }

      &[data-is-invalid='true'] {
        background: ${theme.color.red_o_08};
        color: ${theme.color.error};

        ${Argument.IconWrapper} ${Icon.S.Root} {
          background: ${theme.color.error};
        }

        &[data-is-empty='true'] {
          border-color: ${theme.color.error};
        }
      }
    }

    &[aria-expanded='false'] {
      &[data-is-empty='true'] {
        border-color: ${theme.color.line};
      }

      &[data-is-invalid='true'] {
        color: ${theme.color.error};

        ${Argument.IconWrapper} ${Icon.S.Root} {
          background: ${theme.color.error};
        }

        &[data-is-empty='true'] {
          border-color: ${theme.color.red};
        }
      }
    }

    &:hover {
      cursor: pointer;

      color: ${theme.color.highlight_hover};
      background: ${theme.color.blue_o_08};

      ${Argument.IconWrapper} ${Icon.S.Root} {
        background: ${theme.color.highlight_hover};
      }

      &[data-is-empty='true'] {
        border-color: ${theme.color.highlight_hover};
      }

      &[data-is-invalid='true'] {
        color: ${theme.color.red_hover};
        background: ${theme.color.red_o_08};

        ${Argument.IconWrapper} ${Icon.S.Root} {
          background: ${theme.color.red_hover};
        }

        &[data-is-empty] {
          border-color: ${theme.color.red_hover};
        }
      }
    }

    &[data-is-focus-visible='true'] {
      color: ${theme.color.highlight_hover};
      background: ${theme.color.blue_o_08};
      //border-color: ${theme.color.highlight_hover};
      border-radius: 4px;

      ${Argument.IconWrapper} ${Icon.S.Root} {
        background: ${theme.color.highlight_hover};
      }

      &[data-is-empty='true'] {
        border-color: ${theme.color.highlight_hover};
      }

      &[data-is-invalid='true'] {
        color: ${theme.color.red_hover};
        background: ${theme.color.red_o_08};
        border-color: ${theme.color.red_hover};
      }
    }
  }
`;
