import React, { useMemo } from 'react';

import { useUsersById } from '../../store/users/useUsers';

import { typography } from '../../components/primitives/typography';

import { User } from '../../components/user/User';
import { UserGrid } from '../../components/user/UserGrid';
import { ContentLoader } from '../../components/content-loader/ContentLoader';

export function Users({ isSiblingLoading, onUserPress }) {
  const users = useUsersById();

  const enabledUsersList = useMemo(() => {
    return Object.values(users.byId ?? {}).filter((user) => {
      return user.enabled && user.verified;
    });
  }, [users.byId]);

  if (users.isLoading || isSiblingLoading) {
    return (
      <UserGrid.Root>
        {Array.from({ length: 2 }).map((_, index) => {
          return (
            <UserGrid.Item key={index} data-animated={true}>
              <ContentLoader.User />
            </UserGrid.Item>
          );
        })}
      </UserGrid.Root>
    );
  }

  if (users.error) {
    const message = users.error.message;
    return <typography.body1>{message}</typography.body1>;
  }

  return (
    <UserGrid.Root>
      {enabledUsersList.map((user, index) => {
        return (
          <UserGrid.Item
            key={user.id}
            style={{
              '--animation-delay': `${10 * index}ms`,
            }}
          >
            <User userId={user.id} onPress={onUserPress} />
          </UserGrid.Item>
        );
      })}
    </UserGrid.Root>
  );
}
