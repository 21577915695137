import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { isMACOS } from '../../../lib/platform';

import { useContextMenuContext } from './ContextMenuContextProvider';

import { OverlayStackContextConsumer } from '../../overlay/OverlayStackContextConsumer';

export function ContextMenu(props) {
  const context = useContextMenuContext();
  const [isOpen, setIsOpen] = useState(false);

  const instanceRef = useRef({
    target: null,
    type: null,
    onClose() {
      instanceRef.current.target = null;
      instanceRef.current.type = null;
      instanceRef.current.isOpen = false;
      setIsOpen(false);
    },
    onOpen() {
      instanceRef.current.isOpen = true;
      setIsOpen(true);
    },
  });

  useEffect(() => {
    const instance = instanceRef.current;

    context.register(instance);

    return function () {
      context.unregister(instance);
    };
  }, [context]);

  function onContextMenu(event) {
    if ((event.ctrlKey && isMACOS === false) || event.shiftKey || event.altKey) {
      return;
    }

    // Do not trigger on portaled children. For example when right clicking in a tooltip.
    // This is still broken on non portaled overlays but it's at least a start.
    if (event.currentTarget?.contains(event.target) === false) {
      return;
    }

    // Make sure we catch the event itself. So no underlaying context menu opens.
    event.preventDefault?.();
    event.stopPropagation?.();

    if (props.isDisabled === true) {
      return;
    }

    props.onContextMenu?.(event);
    context.onContextMenu(event, instanceRef.current);
  }

  // Portal the content into the global context menu element.
  const children =
    isOpen &&
    ReactDOM.createPortal(
      <OverlayStackContextConsumer
        overlayRef={context.overlayRef}
        onCloseRequest={() => {
          context.onCloseRequest();
        }}
      >
        {props.content}
      </OverlayStackContextConsumer>,
      context.overlayRef.current
    );

  return (
    <React.Fragment>
      {props.children({
        isOpen,
        onContextMenu,
      })}

      {children}
    </React.Fragment>
  );
}
