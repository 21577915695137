import { useRef, forwardRef } from 'react';
import styled from '@emotion/styled';

import { useSearchFieldState } from 'react-stately';
import { useButton, useSearchField } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { Icon } from '../Icon';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconMagnifyingGlass } from '../../../theme/icons/interface/magnifying-glass/magnifying-glass';

export const SearchField = forwardRef(function (props, forwardedRef) {
  const inputRef = useRef();
  const buttonRef = useRef();
  const searchFieldState = useSearchFieldState(props);
  const searchField = useSearchField(props, searchFieldState, inputRef);
  const button = useButton({ ...searchField.clearButtonProps, elementType: 'span' }, buttonRef);
  const variant = props.variant;

  return (
    <SearchField.Root className={props.className}>
      <SearchField.Relative>
        {props.label && <label {...searchField.labelProps}>{props.label}</label>}

        <SearchField.Input
          {...searchField.inputProps}
          ref={mergeRefs([inputRef, forwardedRef])}
          variant={variant}
          backgroundColor={props.backgroundColor}
          borderRadius={props.borderRadius}
        />

        <SearchField.IconFilter url={iconMagnifyingGlass} color={theme.icon.color_medium} />

        {searchFieldState.value !== '' && (
          <SearchField.IconClear {...button.buttonProps} ref={buttonRef}>
            <Icon
              url={iconCloseThin}
              color={theme.color.icon_body_light}
              size={theme.icon.size_small}
              display="block"
            />
          </SearchField.IconClear>
        )}
      </SearchField.Relative>

      {props.children}
    </SearchField.Root>
  );
});

SearchField.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  //width: 240px;
`;

SearchField.Relative = styled.div`
  width: 100%;
  position: relative;
`;

SearchField.IconFilter = styled(Icon)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);
  transition: ${theme.transition.micro};
  pointer-events: none;
`;

SearchField.Input = styled.input`
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 40px;
  width: 100%;
  outline: 0;
  border: 1px solid ${theme.input.search_border};
  line-height: 20px;
  padding: 10px 30px 10px 50px;
  border-radius: ${(props) => props.borderRadius ?? '20px'};
  transition: ${theme.transition.micro};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.variant === 'flat'
      ? 'transparent'
      : theme.input.search_background};
  color: ${theme.color.text};
  caret-color: ${theme.color.mono_1000};

  &::placeholder {
    color: ${theme.color.text_light};
  }

  &[value='']:hover:not(:focus) {
    background: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.variant === 'flat'
        ? 'transparent'
        : theme.color.component};
    border-color: ${theme.color.line};
    box-shadow: ${theme.input.boxShadow_hover};

    & + ${SearchField.IconFilter} {
      background-color: ${theme.color.icon_light_hover};
    }
  }

  &:focus {
    background: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.variant === 'flat'
        ? 'transparent'
        : theme.color.component};
    border-color: ${theme.input.border_focus};
    box-shadow: ${theme.input.boxShadow_focus};

    &::placeholder {
      //color: ${theme.color.mono_200};
    }

    & + ${SearchField.IconFilter} {
      background-color: ${theme.color.icon_light_hover};
    }
  }
`;

SearchField.IconClear = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  padding: 2px;

  ${Icon.S.Root} {
    transition: ${theme.transition.micro};
  }

  &:hover {
    ${Icon.S.Root} {
      background-color: ${theme.color.icon_light_hover};
      transform: scale(1.125);
    }
  }
`;
