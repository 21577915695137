import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import lockedSrc from './images/indicator/door-locked.svg';
import unlockedSrc from './images/indicator/door-unlocked.svg';

import { ImageBase } from '../common/ImageBase';

export function IndicatorLock({ device, capabilities }) {
  const capability = capabilities?.locked;

  const [isLocked, setIsLocked] = useState(capability?.value ?? false);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value }) => {
        setIsLocked(value);
      });

      return function () {
        unregister();
      };
    }
  }, [capability]);

  return capability != null && <Container url={isLocked ? lockedSrc : unlockedSrc} />;
}

const Container = styled(ImageBase)`
  width: auto;
  height: 24px;
`;
