import { useRef } from 'react';

import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { RouteManager } from '../../../RouteManager';
import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { showForLarge } from '../../../theme/classes/responsive';

import { Overlay } from '../../../components/overlay/Overlay';
import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { GradientButton } from '../../../components/buttons/GradientButton';

import { iconAddMedium } from '../../../theme/icons/interface/plus-medium';
import { iconChevronSmallDown } from '../../../theme/icons/interface/chevron-small-down/chevron-small-down';

export function AddCardButton(props) {
  const { i18n } = useI18n();

  const triggerRef = useRef();
  const overlayTriggerState = useOverlayTriggerState({ defaultOpen: props.defaultOpen });
  const overlayTrigger = useOverlayTrigger({ type: 'menu' }, overlayTriggerState, triggerRef);

  function handlePress() {
    overlayTriggerState.toggle();
  }

  return (
    <>
      <GradientButton
        {...overlayTrigger.triggerProps}
        ref={triggerRef}
        onPress={handlePress}
        styleCompact
        title={i18n.messageFormatter('flow.addCard')}
      >
        <GradientButton.Icon
          url={iconAddMedium}
          color={theme.icon.color_white}
          size={theme.icon.size_small}
        />
        <GradientButton.Text className={showForLarge}>
          {i18n.messageFormatter('common.add')}
        </GradientButton.Text>
        <GradientButton.Icon
          url={iconChevronSmallDown}
          color={theme.icon.color_white}
          size={theme.icon.size_small}
        />
      </GradientButton>

      <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={triggerRef}
              overlayProps={overlayTrigger.overlayProps}
              overlayTriggerState={overlayTriggerState}
              offset={10}
              animationRemainProps={animationRemainProps}
              autoFocus={true}
              restoreFocus={true}
            >
              <ContextMenuContent.ListBase
                onCloseRequest={() => {
                  overlayTriggerState.close();
                }}
              >
                <ContextMenuContent.ListItemBase
                  label={`${i18n.messageFormatter('flow.triggerCardTitle')}...`}
                  onPress={() => {
                    overlayTriggerState.close();
                    RouteManager.pushState({
                      cardDialog: {
                        type: 'trigger',
                      },
                    });
                  }}
                />
                <ContextMenuContent.ListItemBase
                  label={`${i18n.messageFormatter('flow.conditionCardTitle')}...`}
                  onPress={() => {
                    overlayTriggerState.close();
                    RouteManager.pushState({
                      cardDialog: {
                        type: 'condition',
                      },
                    });
                  }}
                />
                <ContextMenuContent.ListItemBase
                  label={`${i18n.messageFormatter('flow.actionCardTitle')}...`}
                  onPress={() => {
                    overlayTriggerState.close();
                    RouteManager.pushState({
                      cardDialog: {
                        type: 'action',
                      },
                    });
                  }}
                />

                <ContextMenuContent.Divider />

                <ContextMenuContent.ListItemBase
                  label={i18n.messageFormatter('flow.startCardTitle')}
                  hint={props.hasStartCard === true ? '1/1' : '0/1'}
                  isDisabled={props.hasStartCard === true}
                  onPress={() => {
                    overlayTriggerState.close();
                    AdvancedFlowViewStore.setCardCursorData({
                      cardData: {
                        type: 'start',
                        x: 0,
                        y: 0,
                      },
                    });
                  }}
                />

                <ContextMenuContent.ListItemBase
                  label={i18n.messageFormatter('flow.delayCardTitle')}
                  onPress={() => {
                    overlayTriggerState.close();
                    AdvancedFlowViewStore.setCardCursorData({
                      cardData: {
                        type: 'delay',
                        args: {
                          delay: {
                            number: '1',
                            multiplier: 1,
                          },
                        },
                        x: 0,
                        y: 0,
                      },
                    });
                  }}
                />

                <ContextMenuContent.ListItemBase
                  label={i18n.messageFormatter('flow.allCardTitle')}
                  onPress={() => {
                    overlayTriggerState.close();
                    AdvancedFlowViewStore.setCardCursorData({
                      cardData: {
                        type: 'all',
                        x: 0,
                        y: 0,
                      },
                    });
                  }}
                />

                <ContextMenuContent.ListItemBase
                  label={i18n.messageFormatter('flow.anyCardTitle')}
                  onPress={() => {
                    overlayTriggerState.close();
                    AdvancedFlowViewStore.setCardCursorData({
                      cardData: {
                        type: 'any',
                        x: 0,
                        y: 0,
                      },
                    });
                  }}
                />

                <ContextMenuContent.Divider />

                <ContextMenuContent.ListItemBase
                  label={i18n.messageFormatter('flow.noteCardTitle')}
                  onPress={() => {
                    overlayTriggerState.close();
                    AdvancedFlowViewStore.setCardCursorData({
                      cardData: {
                        type: 'note',
                        value: '',
                        color: 'yellow',
                        x: 0,
                        y: 0,
                        width: null,
                        height: null,
                      },
                    });
                  }}
                />
              </ContextMenuContent.ListBase>
            </Overlay>
          );
        }}
      </AnimationRemain>
    </>
  );
}
