import React, { useMemo } from 'react';

import { RouteManager } from '../../../RouteManager';
import { HomeyColors } from '../../../theme/HomeyColors';
import { getOverrideURL } from '../../../components/device/DeviceIcon';

import { useDevice } from '../../../store/devices/useDevices';
import { useZone } from '../../../store/zones/useZones';

export function DeviceFlowCard(props) {
  const { component: Component, ...rest } = props;
  const { device, api } = useDevice({ deviceId: props.ownerId });
  const { zone } = useZone({ zoneId: device?.zone });

  const { iconUrl, iconObj, iconBackgroundColor, resourceUrl, name, isLocked } = useMemo(() => {
    const result = {
      iconUrl: null,
      iconObj: null,
      iconBackgroundColor: HomeyColors.getColorForUri('homey:manager:devices'),
      resourceUrl: null,
      name: props.card.ownerName ?? props.card.uriObj?.name,
      isLocked:
        (props.card.requiresPremium === true || device?.requiresPremium === true) &&
        api.tier !== 'premium',
    };

    if (zone != null) {
      result.name = `${result.name} - ${zone.name}`;
    }

    if (device != null) {
      result.iconObj = device.iconObj;
      result.resourceUrl = RouteManager.getPathForDevice(device.id);

      if (device.color != null) {
        result.iconBackgroundColor = device.color;
      }

      if (device.iconOverride != null) {
        result.iconUrl = getOverrideURL({ iconOverride: device.iconOverride });
      }
    }

    return result;
  }, [device, zone, props.card, api]);

  return (
    <Component
      {...rest}
      iconUrl={iconUrl}
      iconObj={iconObj}
      iconBackgroundColor={iconBackgroundColor}
      resourceUrl={resourceUrl}
      name={name}
      isLocked={isLocked}
    />
  );
}
