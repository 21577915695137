import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { client } from '../../../lib/api';
import { AuthStore } from '../../../store/AuthStore';
import { HomeyStore } from '../../../store/HomeyStore';

import { useHomey } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useCurrentProps } from '../../../hooks/useCurrentProps';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';

import { GradientButton } from '../../../components/buttons/GradientButton';
import { IconButton } from '../../../components/buttons/IconButton';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

export function PurchaseView(props) {
  const instanceRef = useRef({
    timeoutId: null,
  });

  const { i18n } = useI18n();

  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const { current } = useHomey();

  const currentProps = useCurrentProps({
    onContinueRequest: props.onContinueRequest,
  });

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return function () {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // Always enabled polling after 2 minutes. This will most likely be when the user is done
    // watching the video.
    const timeout = setTimeout(() => {
      setIsPollingEnabled(true);
    }, 1000 * 60 * 2);

    return function () {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const instance = instanceRef.current;

    function makeTimeout(timeout) {
      return setTimeout(() => {
        client
          .getHomey({ id: current.id })
          .then((result) => {
            const hasAdvancedFlowLicense = result?.licenses.includes('advanced-flow') ?? false;

            if (hasAdvancedFlowLicense === true) {
              AuthStore.assignLicences({ licenses: result.licenses });
              HomeyStore.assignAdvancedFlowLicense();
              currentProps.onContinueRequest();
            }
          })
          .catch(console.error)
          .finally(() => {
            instance.timeoutId = makeTimeout(5000);
          });
      }, timeout);
    }

    if (current != null && isPollingEnabled === true && isVisible === true) {
      instance.timeoutId = makeTimeout(1000);

      return function () {
        clearTimeout(instance.timeoutId);
      };
    }
  }, [currentProps, current, isPollingEnabled, isVisible]);

  return (
    <PurchaseView.Root>
      <PurchaseView.Header>
        {/*<BackButton onPress={props.onBackRequest} />*/}

        <IconButton
          url={iconCloseThin}
          color={theme.icon.color_light}
          hoverColor={theme.icon.color_light_hover}
          size={theme.icon.size_small}
          onPress={props.onCloseRequest}
        />
      </PurchaseView.Header>

      <PurchaseView.Video controls>
        <source
          src="https://etc.athom.com/advanced-flow/advanced-flow-1920x1080.mp4"
          type="video/mp4"
        />
      </PurchaseView.Video>

      <PurchaseView.Content>
        <h1>Advanced Flow</h1>
        <p>{i18n.messageFormatter('flow.purchaseParagraph')}</p>
        <GradientButton
          elementType="a"
          href="https://homey.app/advanced-flow/"
          target="_blank"
          onPress={() => {
            setIsPollingEnabled(true);
          }}
        >
          {i18n.messageFormatter('flow.purchaseButtonTitle')}
        </GradientButton>
      </PurchaseView.Content>
    </PurchaseView.Root>
  );
}

PurchaseView.Root = styled.div`
  position: relative;
  width: 540px;
  animation: ${animationFade.in} ${theme.duration.normal} ease-out forwards;
`;

PurchaseView.Header = styled.header`
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding: 20px;
`;

PurchaseView.Video = styled.video`
  width: 100%;
`;

PurchaseView.Content = styled.div`
  padding: ${su(4, 2, 2)};

  ${GradientButton.S.Root} {
    width: 100%;
  }
`;
