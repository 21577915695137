import { theme } from '../../theme/theme';

import { SVGRoot } from './ContentLoader';

export function User(props) {
  return (
    <SVGRoot
      className={props.className}
      style={props.style}
      role="img"
      width="60"
      height="86"
      aria-labelledby="loading-aria"
      viewBox="0 0 60 86"
      preserveAspectRatio="none"
    >
      <title id="loading-aria">Loading...</title>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath="url(#clip-path-user)"
        style={{
          fill: 'url(#fill-user)',
        }}
      />
      <defs>
        <clipPath id="clip-path-user">
          <circle cx="30" cy="30" r="30" />
          <rect x="0" y="67" rx="5" ry="5" width="60" height="17" />
        </clipPath>
        <linearGradient id="fill-user">
          <stop offset="0.6" stopColor={theme.color.mono_100} stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="1.6" stopColor={theme.color.mono_050} stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="2.6" stopColor={theme.color.mono_100} stopOpacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </SVGRoot>
  );
}
