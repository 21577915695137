import { useState, useEffect } from 'react';

/**
 * Use Local State
 * @description Use this hook when you want to have your react state saved into local storage
 * @param defaultValue
 * @param key
 * @returns {[any, Function]}
 */
export function useLocalStorageState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const localValue = localStorage.getItem(key);
    return localValue !== null ? JSON.parse(localValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
