import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../BaseApiStore2';
import type { HomeyAPI } from 'athom-api';

type MoodsData = { [key: string]: HomeyAPI.ManagerMoods.Mood };
type MoodsById = { [key: string]: HomeyAPI.ManagerMoods.Mood };

interface State {
  data: null | MoodsData;
  byId: null | MoodsById;
  isLoading: boolean;
  error: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MoodsStoreState = State & BaseApiStoreState;

export class MoodsStore extends BaseApiStore<State> {
  key = 'moods';
  // static store = this.createStore(this.key);
  moodCacheId = 'HomeyAPI.ManagerMoods.Mood';

  override createInitialState() {
    return {
      data: null,
      byId: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    __DEV__ && console.info(`fetch:${this.key}`);
    this.destroy('before:fetch');

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    // @ts-ignore
    if (api.features.moods.hasMoods === false) {
      this.set({
        ...this.createInitialState(),
        data: {},
        byId: {},
        isLoading: false,
      });
      return;
    }

    try {
      const managerMoods = api.moods;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const moodsPromise = managerMoods.getMoods({
        // @ts-ignore
        $skipCache: skipCache,
      });

      const [, moodsData] = await Promise.all([waitPromise, moodsPromise]);

      const moods = Object.values(moodsData);

      const data: MoodsData = {};
      const byId: MoodsById = {};

      for (const moodInstance of moods) {
        const moodReference = { ...moodInstance };

        data[moodReference.id] = moodInstance;
        byId[moodReference.id] = moodReference;
      }

      this.set({
        ...this.createInitialState(),
        data: data,
        byId: byId,
        isLoading: false,
      });
    } catch (error) {
      this.destroy('error');
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy(reason: string) {
    __DEV__ && console.info(`destroy:${this.key}:${reason ?? ''}`);
    clearTimeout(this.processBatchTimeout ?? undefined);
    this.processBatchTimeout = null;
    this.updateQueue = {};
  }

  onAttached({ api }: { api: HomeyAPI }): void {
    console.log('onAttached');

    // @ts-ignore
    if (api.features.moods.hasMoods === false) {
      return;
    }

    // @ts-ignore
    api.moods.addListener('mood.create', this.handleCreate);
    // @ts-ignore
    api.moods.addListener('mood.update', this.handleUpdate);
    // @ts-ignore
    api.moods.addListener('mood.delete', this.handleDelete);
  }

  onDetached({ api }: { api: HomeyAPI }): void {
    console.log('onDetached');

    // @ts-ignore
    if (api.features.moods.hasMoods === false) {
      return;
    }

    // @ts-ignore
    api.moods.removeListener('mood.create', this.handleCreate);
    // @ts-ignore
    api.moods.removeListener('mood.update', this.handleUpdate);
    // @ts-ignore
    api.moods.removeListener('mood.delete', this.handleDelete);
  }

  getInstanceFromCache(mood: HomeyAPI.ManagerMoods.Mood) {
    const api = this.getApi();
    // @ts-ignore
    const cache = api.moods._caches[this.moodCacheId];

    return cache.getOne({
      id: cache._guessCacheId(mood),
    });
  }

  getCache(): MoodsData {
    const api = this.getApi();
    // @ts-ignore
    return api.moods._caches[this.moodCacheId]._cache;
  }

  handleCreate = (createdMood: HomeyAPI.ManagerMoods.Mood) => {
    __DEV__ && console.info(`create:mood:${createdMood.id}`);
    const state = this.get();
    const moodInstance = this.getInstanceFromCache(createdMood);

    if (moodInstance == null) return;

    const moodReference = { ...moodInstance };

    this.set({
      data: {
        ...state.data,
        [createdMood.id]: moodInstance,
      },
      byId: {
        ...state.byId,
        [createdMood.id]: moodReference,
      },
    });
  };

  processBatchTimeout: null | ReturnType<typeof setTimeout> = null;
  updateQueue: { [key: string]: HomeyAPI.ManagerMoods.Mood } = {};
  handleUpdate = (updatedMood: HomeyAPI.ManagerMoods.Mood) => {
    __DEV__ && console.info(`update:mood:${updatedMood.id}`);

    this.updateQueue[updatedMood.id] = updatedMood;

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedMoods = this.updateQueue;

        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextById = {
          ...state.byId,
        };

        let updateCount = 0;

        for (const [id, queuedMood] of Object.entries(queuedMoods)) {
          const moodInstance = this.getInstanceFromCache(queuedMood);

          if (state.data == null || state.byId == null) {
            __DEV__ && console.info(`update:mood:missing-data`);
            continue;
          }

          if (moodInstance == null || state.data[id] == null || state.byId[id] == null) {
            __DEV__ && console.info(`update:mood:unknown:${id}`);
            continue;
          }

          const moodReference = { ...moodInstance };

          nextById[id] = moodReference;

          updateCount++;
        }

        __DEV__ && console.info(`update:mood:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byId: nextById,
          });
      }, 200);
    }
  };

  handleDelete = (deletedMood: HomeyAPI.ManagerMoods.Mood) => {
    __DEV__ && console.info(`delete:mood:${deletedMood.id}`);
    const state = this.get();

    if (state.data == null) {
      __DEV__ && console.log(`delete:mood:missing-data`);
      return;
    }

    const moodInstance = state.data[deletedMood.id];

    if (moodInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };

    delete nextData[deletedMood.id];
    delete nextById[deletedMood.id];

    this.set({
      data: nextData,
      byId: nextById,
    });
  };

  restore() {
    const cache = this.getCache();
    const moods = Object.values(cache ?? {});
    const data: MoodsData = {};
    const byId: MoodsById = {};

    for (const moodInstance of moods) {
      const moodReference = { ...moodInstance };
      data[moodReference.id] = moodInstance;
      byId[moodReference.id] = moodReference;
    }

    this.set({
      byId: byId,
      data: data,
    });
  }
}

export const moodsStore = new MoodsStore();
