import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Icon } from '../common/Icon';

export function ListItem() {}

ListItem.Name = styled.div`
  flex: 1 1 auto;
  padding-left: ${su(1)};
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  // overflow: hidden;
`;

ListItem.Icon = styled(Icon)`
  flex: 0 0 auto;
`;

ListItem.Inner = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: inherit;
  padding: ${su(1)};
`;

ListItem.Root = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  max-width: 100%;
  outline: 0;
  padding-left: calc(var(--indent-level, 0) * ${su(2)});
  cursor: pointer;

  &[data-is-selected='true'] {
    ${ListItem.Name} {
      color: ${theme.color.highlight};
      font-weight: ${theme.fontWeight.medium};
    }

    ${ListItem.Icon} {
      background-color: ${theme.color.highlight};
    }
  }

  &[data-is-disabled='true'] {
  }

  &[data-is-focused='true'] {
  }

  &[data-is-pressed='true'] {
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-drag-over='true'][data-can-drop='true'] {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: ${theme.color.state_drop_background};
      border: 1px solid ${theme.color.state_drop_line};
    }
  }

  &:hover {
    background-color: ${theme.color.background_hover};

    &:not([data-is-selected='true']) {
      ${ListItem.Name} {
        font-weight: ${theme.fontWeight.medium};
      }

      ${ListItem.Icon} {
        background-color: ${theme.color.icon_dark};
      }
    }
  }
`;
