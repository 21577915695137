export const resolutions = [
  {
    id: 'ranges1',
    children: [
      {
        id: 'yesterday',
        format: 'time',
      },
      {
        id: 'lastWeek',
        format: 'date',
      },
      {
        id: 'lastMonth',
        format: 'date',
      },
      {
        id: 'lastYear',
        format: 'month',
      },
      {
        id: 'last2Years',
        format: 'month',
      },
    ],
  },
  {
    id: 'ranges2',
    children: [
      {
        id: 'today',
        format: 'time',
      },
      {
        id: 'thisWeek',
        format: 'date',
      },
      {
        id: 'thisMonth',
        format: 'date',
      },
      {
        id: 'thisYear',
        format: 'month',
      },
    ],
  },
  {
    id: 'ranges3',
    children: [
      {
        id: 'lastHour',
        format: 'time',
      },
      {
        id: 'last6Hours',
        format: 'time',
      },
      {
        id: 'last24Hours',
        format: 'time',
      },
      {
        id: 'last7Days',
        format: 'date',
      },
      {
        id: 'last14Days',
        format: 'date',
      },
      {
        id: 'last31Days',
        format: 'date',
      },
    ],
  },
];

export const resolutionFormats = {
  yesterday: 'time',
  lastWeek: 'date',
  lastMonth: 'date',
  lastYear: 'month',
  last2Years: 'month',

  today: 'time',
  thisWeek: 'date',
  thisMonth: 'date',
  thisYear: 'month',

  lastHour: 'time',
  last6Hours: 'time',
  last24Hours: 'time',
  last7Days: 'date',
  last14Days: 'date',
  last31Days: 'date',
};
