import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { InsightStore } from './InsightStore';
import { selectData, selectByKey, selectByOwnerUri } from '../selectors';

import { useAttach } from '../useAttach';

export function useLogsData() {
  useAttach(InsightStore, 'logsData');

  return InsightStore.store(selectData, shallow);
}

export function useLogsByKey() {
  useAttach(InsightStore, 'logsByKey');

  return InsightStore.store(selectByKey, shallow);
}

export function useLogsByOwnerUri() {
  useAttach(InsightStore, 'logsByOwnerUri');

  return InsightStore.store(selectByOwnerUri, shallow);
}

export function useLog({ logKey }) {
  useAttach(InsightStore, 'log');

  const select = useCallback(
    (state) => {
      return {
        log: state.byKey?.[logKey] ?? null,
        manager: state.manager,
      };
    },
    [logKey]
  );

  return InsightStore.store(select, shallow);
}

function selectColorMap(state) {
  return {
    colorMap: state.colorMap,
  };
}

export function useLogsColorMap() {
  return InsightStore.store(selectColorMap, shallow);
}

export function useLogsAttach() {
  useAttach(InsightStore, 'useLogsAttach');
}
