import { path as makePath } from 'd3-path';

// const width = 340;
// const cardHeight = 65;
const lineCurveScale = 0.75;

export function generateLinkPath(origX, origY, destX, destY, sc, { height, width }) {
  var dy = destY - origY;
  var dx = destX - origX;
  var delta = Math.sqrt(dy * dy + dx * dx);
  var scale = lineCurveScale;
  var scaleY = 0;

  if (dx * sc > 0) {
    if (delta < width) {
      scale = 0.75 - 0.75 * ((width - delta) / width);
      // scale += 2*(Math.min(5*node_width,Math.abs(dx))/(5*node_width));
      // if (Math.abs(dy) < 3*node_height) {
      //     scaleY = ((dy>0)?0.5:-0.5)*(((3*node_height)-Math.abs(dy))/(3*node_height))*(Math.min(node_width,Math.abs(dx))/(node_width)) ;
      // }
    }
  } else {
    scale = 0.4 - 0.2 * Math.max(0, (width - Math.min(Math.abs(dx), Math.abs(dy))) / width);
  }

  const curveX1 = origX + sc * (width * scale);
  const curveY1 = origY + scaleY * height;
  const curveX2 = destX - sc * scale * width;
  const curveY2 = destY - scaleY * height;

  if (dx * sc > 0) {
    return `M ${origX} ${origY} C ${curveX1} ${curveY1}, ${curveX2} ${curveY2}, ${destX} ${destY}`;
  } else {
    var midX = Math.floor(destX - dx / 2);
    var midY = Math.floor(destY - dy / 2);
    //
    if (dy === 0) {
      midY = destY + height;
    }
    var cp_height = height / 2;
    var y1 = (destY + midY) / 2;
    var topX = origX + sc * width * scale;
    var topY =
      dy > 0 ? Math.min(y1 - dy / 2, origY + cp_height) : Math.max(y1 - dy / 2, origY - cp_height);
    var bottomX = destX - sc * width * scale;
    var bottomY = dy > 0 ? Math.max(y1, destY - cp_height) : Math.min(y1, destY + cp_height);
    var x1 = (origX + topX) / 2;
    var scy = dy > 0 ? 1 : -1;
    var cp = [
      // Orig -> Top
      [x1, origY],
      [topX, dy > 0 ? Math.max(origY, topY - cp_height) : Math.min(origY, topY + cp_height)],
      // Top -> Mid
      // [Mirror previous cp]
      [x1, dy > 0 ? Math.min(midY, topY + cp_height) : Math.max(midY, topY - cp_height)],
      // Mid -> Bottom
      // [Mirror previous cp]
      [bottomX, dy > 0 ? Math.max(midY, bottomY - cp_height) : Math.min(midY, bottomY + cp_height)],
      // Bottom -> Dest
      // [Mirror previous cp]
      [(destX + bottomX) / 2, destY],
    ];
    if (cp[2][1] === topY + scy * cp_height) {
      if (Math.abs(dy) < cp_height * 10) {
        cp[1][1] = topY - (scy * cp_height) / 2;
        cp[3][1] = bottomY - (scy * cp_height) / 2;
      }
      cp[2][0] = topX;
    }
    return `M ${origX} ${origY} C ${cp[0][0]} ${cp[0][1]}, ${cp[1][0]} ${cp[1][1]}, ${topX} ${topY} S ${cp[2][0]} ${cp[2][1]}, ${midX} ${midY} S ${cp[3][0]} ${cp[3][1]}, ${bottomX} ${bottomY} S ${cp[4][0]} ${cp[4][1]}, ${destX} ${destY}`;
  }
}

export function generateErrorLinkPath(fromX, fromY, toX, toY) {
  const base = makePath();

  base.moveTo(fromX, fromY);

  const minX = Math.min(toX, fromX);
  // const minY = Math.min(toY, fromY);
  // const maxX = Math.max(toX, fromX);
  const maxY = Math.max(toY, fromY);

  const deltaX = Math.abs(toX - fromX);
  const deltaY = Math.abs(toY - fromY);

  if (fromX < toX && fromY < toY) {
    //console.log('quadraticCurveTo');
    //console.log('bottomLeft');

    const cpx1 = minX;
    const cpy1 = fromY + deltaY;

    base.quadraticCurveTo(cpx1, cpy1, toX, toY);
  } else if (fromX < toX && fromY > toY) {
    //console.log('bezierCurveTo');
    //console.log('topRight');

    const cpx1 = fromX;
    const cpy1 = fromY + deltaY;

    const cpx2 = toX - 100;
    // node height + spacing
    const cpy2 = toY - 64 + 20;

    base.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, toX, toY);
  } else {
    //console.log('bezierCurveTo');

    const cpx1 = fromX;
    const cpy1 = fromY + deltaY;

    const cpx2 = toX - deltaX;
    // node height + spacing
    const cpy2 = maxY - 64 - 20;

    base.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, toX, toY);
  }

  return base.toString();
}
