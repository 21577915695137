import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { su } from '../../../theme/functions/su';
import { theme } from '../../../theme/theme';

import { Heading3Bold } from '../../../components/common/text/Heading3Bold';
import { TextSmallMedium } from '../../../components/common/text/TextSmallMedium';

import { DeviceSettingsAdvancedSetting } from './DeviceSettingsAdvancedSetting';

export function DeviceSettingsAdvanced(props) {
  function getLabel(setting) {
    const label = setting.label ?? setting.title;
    return label != null && setting.units != null ? `${label} (${setting.units})` : label;
  }

  const advancedSettings = useMemo(() => {
    return props.advancedSettings.sort((a, b) => {
      if (a.type !== 'group' && b.type === 'group') {
        return -1;
      }
      if (a.type === 'group' && b.type !== 'group') {
        return 1;
      }
      return 0;
    });
  }, [props.advancedSettings]);

  return (
    <S.Root>
      <Heading3Bold>Advanced Settings</Heading3Bold>

      {advancedSettings.map((object, index) => {
        if (object.type === 'group') {
          return (
            <React.Fragment key={index}>
              <TextSmallMedium>{getLabel(object)}</TextSmallMedium>
              {object.children.map((setting) => {
                return (
                  <DeviceSettingsAdvancedSetting
                    key={setting.id}
                    device={props.device}
                    setting={setting}
                    register={props.register}
                    control={props.control}
                    errors={props.errors}
                  />
                );
              })}
            </React.Fragment>
          );
        }
        return (
          <DeviceSettingsAdvancedSetting
            key={object.id}
            device={props.device}
            setting={object}
            register={props.register}
            control={props.control}
            errors={props.errors}
          />
        );
      })}
    </S.Root>
  );
}

function S() {}
DeviceSettingsAdvanced.S = S;

S.Root = styled.div`
  ${Heading3Bold.S.Root} {
    margin: ${su(4, 0, 2)};
  }

  ${TextSmallMedium.S.Root} {
    margin: ${su(3, 0, 1)};
    padding-bottom: ${su(1)};
    border-bottom: 1px solid ${theme.color.line};
  }
`;
