import React from 'react';
import { Global, css } from '@emotion/react';

import { theme } from '../../../theme/theme';

export function AdvancedFlowGraphic() {
  return (
    <svg width="400px" height="212px" viewBox="0 0 400 212">
      <Global
        styles={css`
          .advanced-flow-graphic__line-1-b,
          .advanced-flow-graphic__line-2-b,
          .advanced-flow-graphic__line-3-b,
          .advanced-flow-graphic__line-4-b {
            stroke-dashoffset: 70px;
            stroke-dasharray: 70px;
            transition: stroke-dashoffset 250ms ease-in-out;
          }

          .is-active-option .advanced-flow-graphic__line-1-b,
          .is-active-option .advanced-flow-graphic__line-2-b,
          .is-active-option .advanced-flow-graphic__line-3-b,
          .is-active-option .advanced-flow-graphic__line-4-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-1-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-2-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-3-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-4-b {
            stroke-dashoffset: 0px;
          }

          .advanced-flow-graphic__line-1-b {
            transition-delay: 100ms;
          }

          .is-active-option .advanced-flow-graphic__line-1-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-1-b {
            transition-delay: 0ms;
          }

          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-2-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-3-b,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__line-4-b {
            transition-delay: 100ms;
          }

          circle {
            transition: 100ms fill ease-in-out;
          }

          .is-active-option .advanced-flow-graphic__circle-1,
          .is-active-option .advanced-flow-graphic__circle-3,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-1,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-3 {
            fill: #0082fa;
          }

          .is-active-option .advanced-flow-graphic__circle-2,
          .is-active-option .advanced-flow-graphic__circle-5,
          .is-active-option .advanced-flow-graphic__circle-6,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-2,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-5,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-6 {
            fill: #00c4ff;
          }

          .is-active-option .advanced-flow-graphic__circle-4,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-4 {
            fill: #ff0b0b;
          }

          .is-active-option .advanced-flow-graphic__circle-7,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-7 {
            fill: #ff3d3d;
          }

          .advanced-flow-graphic__circle-1 {
            transition-delay: 200ms;
          }

          .is-active-option .advanced-flow-graphic__circle-1,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-1 {
            transition-delay: 0ms;
          }

          .advanced-flow-graphic__circle-2,
          .advanced-flow-graphic__circle-3,
          .advanced-flow-graphic__circle-4 {
            transition-delay: 100ms;
          }

          .advanced-flow-graphic__circle-5,
          .advanced-flow-graphic__circle-6,
          .advanced-flow-graphic__circle-7 {
            transition-delay: 0ms;
          }

          .is-active-option .advanced-flow-graphic__circle-5,
          .is-active-option .advanced-flow-graphic__circle-6,
          .is-active-option .advanced-flow-graphic__circle-7,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-5,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-6,
          .standard-flow-graphic-wrapper:hover .advanced-flow-graphic__circle-7 {
            transition-delay: 200ms;
          }
        `}
      />
      <defs>
        <linearGradient id="advancedFlowGradient-1">
          <stop stopColor="#0082FA" offset="0%" />
          <stop stopColor="#00C4FF" offset="100%" />
        </linearGradient>
        <linearGradient id="advancedFlowGradient-2">
          <stop stopColor="#FF0B0B" offset="0%" />
          <stop stopColor="#FF3D3D" offset="100%" />
        </linearGradient>
        <path
          d="M76,48 C78.209139,48 80,49.790861 80,52 L80,54.023 L80.0070595,54.1854204 C80.0727604,55.0473014 80.5944178,55.857245 81.4614354,56.4415976 L81.6199075,56.5435117 L86.3799767,59.4637236 C86.5891225,59.5920306 86.7820134,59.7339749 86.9562871,59.8878182 C88.5109665,61.2602399 88.3009372,63.2813475 86.5121935,64.4664542 L86.3714872,64.5559423 L81.628397,67.4512931 C80.6483004,68.0495788 80.0607591,68.9223907 80.0044552,69.8526682 L80,69.97 L80,72 C80,74.209139 78.209139,76 76,76 L4,76 C1.790861,76 2.705415e-16,74.209139 0,72 L0,52 C-2.705415e-16,49.790861 1.790861,48 4,48 L76,48 Z"
          id="path-2"
        />
        <path
          d="M7.99554485,21.8526682 C7.93924095,20.9223907 7.35169956,20.0495788 6.37160295,19.4512931 L6.37160295,19.4512931 L1.62851282,16.5559423 L1.48780646,16.4664542 C-0.300937172,15.2813475 -0.510966489,13.2602399 1.04371287,11.8878182 C1.21798659,11.7339749 1.41087749,11.5920306 1.62002331,11.4637236 L1.62002331,11.4637236 L6.38009246,8.54351174 L6.53856462,8.44159755 C7.46751202,7.8155055 8,6.93044418 8,6 L8,6 L8,4 C8,1.790861 9.790861,4.05812251e-16 12,0 L84,0 C86.209139,-4.05812251e-16 88,1.790861 88,4 L88,24 C88,26.209139 86.209139,28 84,28 L12,28 C9.790861,28 8,26.209139 8,24 L8,22 Z"
          id="path-4"
        />
        <filter
          x="-80.7%"
          y="-217.9%"
          width="261.4%"
          height="607.1%"
          filterUnits="objectBoundingBox"
          id="filter-5"
        >
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.04 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter3" />
          <feGaussianBlur stdDeviation="22" in="shadowOffsetOuter3" result="shadowBlurOuter3" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.07 0"
            type="matrix"
            in="shadowBlurOuter3"
            result="shadowMatrixOuter3"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
            <feMergeNode in="shadowMatrixOuter3" />
          </feMerge>
        </filter>
        <path
          d="M7.99554485,21.8526682 C7.93924095,20.9223907 7.35169956,20.0495788 6.37160295,19.4512931 L6.37160295,19.4512931 L1.62851282,16.5559423 L1.48780646,16.4664542 C-0.300937172,15.2813475 -0.510966489,13.2602399 1.04371287,11.8878182 C1.21798659,11.7339749 1.41087749,11.5920306 1.62002331,11.4637236 L1.62002331,11.4637236 L6.38009246,8.54351174 L6.53856462,8.44159755 C7.46751202,7.8155055 8,6.93044418 8,6 L8,6 L8,4 C8,1.790861 9.790861,4.05812251e-16 12,0 L84,0 C86.209139,-4.05812251e-16 88,1.790861 88,4 L88,24 C88,26.209139 86.209139,28 84,28 L12,28 C9.790861,28 8,26.209139 8,24 L8,22 Z"
          id="path-6"
        />

        <path
          d="M7.99554485,21.8526682 C7.93924095,20.9223907 7.35169956,20.0495788 6.37160295,19.4512931 L6.37160295,19.4512931 L1.62851282,16.5559423 L1.48780646,16.4664542 C-0.300937172,15.2813475 -0.510966489,13.2602399 1.04371287,11.8878182 C1.21798659,11.7339749 1.41087749,11.5920306 1.62002331,11.4637236 L1.62002331,11.4637236 L6.38009246,8.54351174 L6.53856462,8.44159755 C7.46751202,7.8155055 8,6.93044418 8,6 L8,6 L8,4 C8,1.790861 9.790861,4.05812251e-16 12,0 L84,0 C86.209139,-4.05812251e-16 88,1.790861 88,4 L88,24 C88,26.209139 86.209139,28 84,28 L12,28 C9.790861,28 8,26.209139 8,24 L8,22 Z"
          id="path-8"
        />

        <path
          d="M120,76 C117.790861,76 116,74.209139 116,72 L116,70 L115.995545,69.8526682 C115.939241,68.9223907 115.3517,68.0495788 114.371603,67.4512931 L114.371603,67.4512931 L109.628513,64.5559423 L109.487806,64.4664542 C107.699063,63.2813475 107.489034,61.2602399 109.043713,59.8878182 C109.217987,59.7339749 109.410877,59.5920306 109.620023,59.4637236 L109.620023,59.4637236 L114.380092,56.5435117 L114.538565,56.4415976 C115.467512,55.8155055 116,54.9304442 116,54 L116,54 L116,52 C116,49.790861 117.790861,48 120,48 L192,48 C194.209139,48 196,49.790861 196,52 L196,54 C196,54.9304442 196.532488,55.8155055 197.461435,56.4415976 L197.619908,56.5435117 L202.379977,59.4637236 C202.589123,59.5920306 202.782013,59.7339749 202.956287,59.8878182 C204.510966,61.2602399 204.300937,63.2813475 202.512194,64.4664542 L202.371487,64.5559423 L197.628397,67.4512931 C196.6483,68.0495788 196.060759,68.9223907 196.004455,69.8526682 L196,70 L196,72 C196,74.209139 194.209139,76 192,76 L164,76 C163.069556,76 162.184494,76.532488 161.558402,77.4614354 L161.456488,77.6199075 L158.536276,82.3799767 C158.407969,82.5891225 158.266025,82.7820134 158.112182,82.9562871 C156.73976,84.5109665 154.718653,84.3009372 153.533546,82.5121935 L153.444058,82.3714872 L150.548707,77.628397 C149.950421,76.6483004 149.077609,76.0607591 148.147332,76.0044552 L148,76 L120,76 Z"
          id="path-10"
        />
      </defs>
      <g stroke="none" fill="none">
        <g transform="translate(-956.000000, -492.000000)">
          <g transform="translate(544.000000, 285.000000)">
            <g transform="translate(432.000000, 137.000000)">
              <g transform="translate(24.000000, 104.000000)">
                <path
                  className="advanced-flow-graphic__line-1-a"
                  d="M83,62 L113,62"
                  stroke={theme.color.line}
                  strokeWidth="2"
                />
                <path
                  className="advanced-flow-graphic__line-1-b"
                  d="M83,62 L113,62.0001"
                  stroke="url(#advancedFlowGradient-1)"
                  strokeWidth="2"
                  fill="none"
                />

                <path
                  className="advanced-flow-graphic__line-2-a"
                  d="M199,62 C236,62 201,14 229,14"
                  stroke={theme.color.line}
                  strokeWidth="2"
                />
                <path
                  className="advanced-flow-graphic__line-2-b"
                  d="M199,62 C236,62 201,14 229,14"
                  stroke="url(#advancedFlowGradient-1)"
                  strokeWidth="2"
                />

                <line
                  className="advanced-flow-graphic__line-3-a"
                  x1="199"
                  y1="62"
                  x2="229"
                  y2="62"
                  stroke={theme.color.line}
                  strokeWidth="2"
                />
                <line
                  className="advanced-flow-graphic__line-3-b"
                  x1="199"
                  y1="62.0001"
                  x2="229"
                  y2="62"
                  stroke="url(#advancedFlowGradient-1)"
                  strokeWidth="2"
                />

                <path
                  className="advanced-flow-graphic__line-4-a"
                  d="M156,80 C156,112 179.027397,110 197,110"
                  stroke={theme.color.line}
                  strokeWidth="2"
                />
                <path
                  className="advanced-flow-graphic__line-4-b"
                  d="M156,80 C156,112 179.027397,110 197,110"
                  stroke="url(#advancedFlowGradient-2)"
                  strokeWidth="2"
                />

                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-2" />
                  <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-2" />
                </g>
                <g transform="translate(224.000000, 48.000000)">
                  <g id="Combined-Shape-Copy">
                    <use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-4" />
                    <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-4" />
                  </g>
                  <circle
                    className="advanced-flow-graphic__circle-5"
                    fill={theme.color.line}
                    cx="5"
                    cy="14"
                    r="2"
                  />
                </g>
                <g transform="translate(224.000000, 0.000000)">
                  <g id="Combined-Shape-Copy-4">
                    <use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-6" />
                    <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-6" />
                  </g>
                  <circle
                    className="advanced-flow-graphic__circle-6"
                    fill={theme.color.line}
                    cx="5"
                    cy="14"
                    r="2"
                  />
                </g>
                <g transform="translate(192.000000, 96.000000)">
                  <g id="Combined-Shape-Copy-3">
                    <use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-8" />
                    <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-8" />
                  </g>
                  <circle
                    className="advanced-flow-graphic__circle-7"
                    fill={theme.color.line}
                    cx="4"
                    cy="14"
                    r="2"
                  />
                </g>
                <g id="Combined-Shape-Copy-2">
                  <use fill="black" fillOpacity="1" filter="url(#filter-5)" xlinkHref="#path-10" />
                  <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-10" />
                </g>

                <circle
                  className="advanced-flow-graphic__circle-1"
                  fill={theme.color.line}
                  cx="83"
                  cy="62"
                  r="2"
                />
                <circle
                  className="advanced-flow-graphic__circle-2"
                  fill={theme.color.line}
                  cx="113"
                  cy="62"
                  r="2"
                />
                <circle
                  className="advanced-flow-graphic__circle-3"
                  fill={theme.color.line}
                  cx="199"
                  cy="62"
                  r="2"
                />
                <circle
                  className="advanced-flow-graphic__circle-4"
                  fill={theme.color.line}
                  cx="156"
                  cy="80"
                  r="2"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
