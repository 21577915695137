import React from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { isDarkMode } from '../../theme/classes/darkMode';

import { Dialog } from '../overlay/Dialog';
import { IconButton } from '../buttons/IconButton';

import { PairingFrame } from './PairingFrame';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';

export function PairingDialog(props) {
  return (
    <Dialog onClose={props.onClose} isOpen>
      <PairingDialog.Root>
        <PairingDialog.Header>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={props.onClose}
          />
        </PairingDialog.Header>

        <PairingDialog.Content>
          <PairingFrame
            type={props.type}
            driverKey={props.driverKey}
            deviceId={props.deviceId}
            onDone={props.onClose}
          />
        </PairingDialog.Content>
      </PairingDialog.Root>
    </Dialog>
  );
}

PairingDialog.Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 420px;
  height: 800px;
  padding: ${su(2, 2, 0, 2)};

  ${isDarkMode} {
    background: ${theme.color.black};
    border-radius: ${theme.borderRadius.default};

    ${PairingFrame.AbsoluteSpinner} {
      background: ${theme.color.black};
    }

    ${PairingFrame.IFrameWrapper} {
      background: ${theme.color.mono_000};
    }

    ${PairingFrame.IFrame} {
      filter: ${theme.filter.darkMode};
    }
  }
`;

PairingDialog.Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

PairingDialog.Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: ${su(2, 0)};
`;
