import styled from '@emotion/styled';

import { HomeyStore, useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { FlatButton } from '../../../components/buttons/FlatButton';

import { GeneralSettingsSection } from './GeneralSettingsSection';

import { iconPage } from '../../../theme/icons/interface/page';
import { iconArrowDoubleCircularRotated } from '../../../theme/icons/interface/arrow-double-circular-rotated/arrow-double-circular-rotated';

export function GeneralSettingsActions(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  return (
    <GeneralSettingsSection.S.Section>
      <GeneralSettingsSection.S.SectionHeading>
        {i18n.messageFormatter('common.actions')}
      </GeneralSettingsSection.S.SectionHeading>

      <S.Root>
        <FlatButton styleWidth="full" styleColor="blue" onPress={props.onDiagnosticsReportPress}>
          {/* // TODO update icon from sketch */}
          <FlatButton.Icon url={iconPage} size={theme.icon.size_small} />
          <FlatButton.Text>
            {i18n.messageFormatter('settings.system.diagnosticsReportCreateTitle')}
          </FlatButton.Text>
        </FlatButton>

        {api?.isCloud === false && (
          <FlatButton
            styleWidth="full"
            styleColor="red"
            onPress={() => {
              HomeyStore.reboot().catch((error) => {
                console.error(error);
              });
            }}
          >
            <FlatButton.Icon url={iconArrowDoubleCircularRotated} size={theme.icon.size_small} />
            <FlatButton.Text>{i18n.messageFormatter('common.restart')}</FlatButton.Text>
          </FlatButton>
        )}
      </S.Root>
    </GeneralSettingsSection.S.Section>
  );
}

function S() {}
GeneralSettingsActions.S = S;

S.Root = styled.section`
  display: grid;
  width: 440px;
  padding: ${su(2)};
  grid-gap: ${su(2)};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
`;
