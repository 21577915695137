import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

import { Select } from '../../../components/select/Select';

export function BooleanSelect(props) {
  const { i18n } = useI18n();

  return (
    <Select
      label={props['aria-label']}
      keyProp="id"
      textValueProp="textValue"
      defaultValue={props.defaultValue?.toString()}
      onChange={(key) => props.onChange(key === 'true')}
      items={[
        { id: 'true', textValue: i18n.messageFormatter('common.true') },
        { id: 'false', textValue: i18n.messageFormatter('common.false') },
      ]}
      renderList={({ ref, props, children }) => {
        return (
          <List {...props} ref={ref}>
            {children}
          </List>
        );
      }}
      renderItem={({ ref, props, item }) => {
        return (
          <ListItem {...props} ref={ref}>
            {item.textValue}
          </ListItem>
        );
      }}
      renderInput={({ ref, buttonProps, valueProps, selectedItem, selectState }) => {
        return (
          <Button {...buttonProps} ref={ref} isOpen={selectState.isOpen} align={props.align}>
            <span {...valueProps}>{selectedItem ? selectedItem.textValue : ' '}</span>
          </Button>
        );
      }}
    />
  );
}

const rowHighlight = css`
  background-color: ${theme.color.background_hover};
`;

const active = css`
  background-color: ${theme.input.background};
  border-color: ${theme.input.border};
`;

const Button = styled.button`
  justify-content: flex-end;
  min-width: 0;

  border: 1px solid;
  outline: 0;
  border-radius: ${theme.borderRadius.default};
  line-height: 30px;
  text-align: ${(props) => props.align};
  padding: 0 5px;
  margin-${(props) => props.align}: -6px;

  background-color: transparent;
  border-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    border-color: ${theme.input.border_hover};
    box-shadow: ${theme.input.boxShadow_hover};
    background: ${theme.color.component};
  }

  &[aria-expanded='true'] {
    border-color: ${theme.input.border_focus};
    box-shadow: ${theme.input.boxShadow_focus};
    background: ${theme.color.component};
  }

  ${(props) => props.isOpen && active};
`;

const List = styled.ul`
  padding: 5px 0;
  outline: 0;
  max-height: 300px;
`;

const ListItem = styled.li`
  min-width: 120px;
  width: 100%;
  padding: 5px 10px;
  outline: 0;
  cursor: pointer;

  &:hover {
    ${rowHighlight};
  }
`;
