import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useCollator } from 'react-aria';

import { ResourceUtils } from '../../../store/ResourceUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useTokensByOwnerUri } from '../../../store/flow-tokens/useFlowTokens';
import { useTokenContext } from '../TokenContext';
import { useDevicesById } from '../../../store/devices/useDevices';
// import { useZonesById } from '../../../store/zones/useZones';

import { su } from '../../../theme/functions/su';
import { scrollbars } from '../../../theme/elements/scrollbars';

import { ListBox } from '../../../components/list-box/ListBox';
import { ListBoxSection } from '../../../components/list-box/ListBoxSection';
import { SearchField } from '../../../components/common/search-field/SearchField';
import { TokenItem, LocalTokenItem } from './TokenItem';

export function TokenListBox(props) {
  const collator = useCollator();
  const { i18n } = useI18n();

  const tokens = useTokensByOwnerUri();
  const devices = useDevicesById();
  //  const zones = useZonesById();
  const { tokensByKey } = useTokenContext();

  const [filterValue, setFilterValue] = useState('');

  const items = useMemo(() => {
    const root = [];

    const hasFilter = filterValue.length > 0;
    const searchValue = filterValue.toLowerCase();

    const localTokenSectionKey = '__local_tokens__';
    const localTokenChildren = [];

    function makeGroupSort() {
      return function (firstElement, secondElement) {
        return (
          collator.compare(firstElement.type, secondElement.type) ||
          collator.compare(firstElement.textValue, secondElement.textValue)
        );
      };
    }

    function matchDevice(device, value) {
      return device.name.toLowerCase().includes(value) || device.id.includes(value);
    }

    for (const [id, localToken] of Object.entries(tokensByKey ?? {})) {
      const value = {
        key: id,
        type: 'local-token',
        kind: 'local',
        textValue: localToken.title,
        context: {
          token: localToken,
        },
      };

      if (props.types.includes(localToken.type)) {
        if (hasFilter) {
          if (localToken.title.toLowerCase().includes(searchValue)) {
            localTokenChildren.push(value);
          }
        } else {
          localTokenChildren.push(value);
        }
      }
    }

    if (localTokenChildren.length > 0) {
      localTokenChildren.sort(makeGroupSort());

      root.push({
        key: localTokenSectionKey,
        title: i18n.messageFormatter('flow.thisFlow'),
        children: localTokenChildren,
      });
    }

    for (const [ownerUri, group] of Object.entries(tokens.byOwnerUri ?? {})) {
      const children = [];
      let device = null;

      const owner = ResourceUtils.getOwnerTypeAndIdFromOwnerUri(ownerUri);

      if (owner.type === 'device') {
        device = devices.byId?.[owner.id];
      }

      for (const [tokenKey, token] of Object.entries(group.data)) {
        let tokenValue;

        if (device?.capabilitiesObj?.[token.ownerId] != null) {
          tokenValue = device.capabilitiesObj[token.ownerId].value;
        }

        const value = {
          key: tokenKey,
          type: 'token',
          kind: 'global',
          textValue: token.title,
          context: {
            token,
            tokenValue,
          },
        };

        if (props.types.includes(token.type)) {
          if (hasFilter) {
            if (
              group.ownerName?.toLowerCase().includes(searchValue) ||
              token.title.toLowerCase().includes(searchValue) ||
              (device != null && matchDevice(device, searchValue))
            ) {
              children.push(value);
            }
          } else {
            children.push(value);
          }
        }
      }

      if (children.length === 0) {
        continue;
      }

      children.sort(makeGroupSort());

      root.push({
        key: ownerUri,
        title: group.ownerName,
        children: children,
      });
    }

    return root.sort((firstElement, secondElement) => {
      if (firstElement.key === localTokenSectionKey) return -1;
      if (secondElement.key === localTokenSectionKey) return 1;
      if (firstElement.key === localTokenSectionKey && secondElement.key === localTokenSectionKey)
        return 0;

      return collator.compare(firstElement.title, secondElement.title);
    });
  }, [collator, i18n, filterValue, tokens.byOwnerUri, tokensByKey, devices.byId, props.types]);

  function renderSection() {
    return <ListBoxSection />;
  }

  function renderItem({ value }) {
    if (value.type === 'local-token') {
      return <LocalTokenItem />;
    }

    return <TokenItem />;
  }

  function handleSelectionChange(keys, listState) {
    const [key] = keys;
    const item = listState.collection.getItem(key);

    if (item != null) {
      props.onSelect(key, { kind: item.value.kind, token: item.value.context.token });
    }
  }

  return (
    <TokenListBox.Root>
      <TokenListBox.SearchField
        aria-label="Filter"
        placeholder="Filter..."
        onChange={(value) => {
          setFilterValue(value);
        }}
        onClear={() => {
          setFilterValue('');
        }}
        onKeyDown={(event) => {
          // If it was empty and escape it pressed propagate event so that
          // overlays can be closed with keyboard.
          if (event.key === 'Escape' && filterValue === '') {
            event.continuePropagation();
          }
        }}
      />

      <TokenListBox.ListBox
        aria-label="Token picker"
        items={items}
        renderSection={renderSection}
        renderItem={renderItem}
        onSelectionChange={handleSelectionChange}
      />
    </TokenListBox.Root>
  );
}

TokenListBox.Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

TokenListBox.SearchField = styled(SearchField)`
  display: block;
  flex: 0 0 auto;
  width: 100%;
  padding: ${su(1)};
`;

TokenListBox.ListBox = styled(ListBox)`
  ${scrollbars.dark}
  flex: 1 1 auto;
  width: 400px;
  height: 420px;
  padding: ${su(1)};
  overflow: auto;
`;
