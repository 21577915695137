/** @jsxImportSource @emotion/react */
import React, { useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { OverlayContainer } from 'react-aria';

import { localPoint } from '@visx/event';
import { Bar } from '@visx/shape';
import { bisector } from 'd3-array';

import { ResourceUtils } from '../../store/ResourceUtils';

import { round } from '../../lib/math';
import { getDate, getPathYFromX } from './chartUtils';

import { useChartTooltipSetData, useChartTooltipGetData } from './ChartTooltipProvider';

import { containers } from '../../theme/elements/containers';
import { typography } from '../primitives/typography';
import { theme } from '../../theme/theme';

import { Circle } from '../common/Circle';

const bisectDate = bisector((d) => new Date(d.t)).left;

export function ChartTooltip(props) {
  const containerRef = useRef();
  const lineRef = useRef();
  const tooltipRef = useRef();
  const circleGroupRefs = useRef({});

  const [isOpen, setIsOpen] = useState(false);
  const setTooltipData = useChartTooltipSetData();

  const { timeScale, values, refs, parentOffsetX } = props;
  const handleTooltip = useMemo(() => {
    return (event) => {
      const pageX = event.pageX;

      let { x } = localPoint(event) || { x: 0 };
      x = x - parentOffsetX;

      if (lineRef.current) {
        lineRef.current.setAttribute('x1', x);
        lineRef.current.setAttribute('x2', x);
      }

      const yMin = Object.entries(refs.current).reduce((accumulator, [key, value]) => {
        if (!circleGroupRefs.current[key]) {
          return accumulator;
        }

        const y = getPathYFromX(x, value);

        circleGroupRefs.current[key].setAttribute('transform', `translate(${x}, ${y})`);

        if (y < accumulator) {
          accumulator = y;
        }

        return accumulator;
      }, Infinity);

      if (tooltipRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        const tooltipX = getTooltipX(tooltipRect, pageX);
        const tooltipY = getTooltipY(tooltipRect, containerRect, yMin);

        tooltipRef.current.style.transform = `translate(calc(${tooltipX}px - 50%), ${tooltipY}px)`;
      }

      const x0 = timeScale.invert(x);
      const index = bisectDate(values, x0, 1);
      const d0 = values[index - 1];
      const d1 = values[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
      }

      setTooltipData({
        d,
      });
    };
  }, [timeScale, values, refs, setTooltipData, parentOffsetX]);

  return (
    <>
      <Bar
        innerRef={containerRef}
        x={0}
        y={0}
        width={props.xMax}
        height={props.yMax}
        fill="transparent"
        //onTouchStart={handleTooltip}
        //onTouchMove={handleTooltip}
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseMove={isOpen ? handleTooltip : undefined}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      />
      {isOpen && (
        <g>
          <line
            ref={lineRef}
            x1={0}
            x2={0}
            y1={0}
            y2={props.yMax}
            stroke="rgb(170, 170, 170)"
            strokeWidth={1}
            pointerEvents="none"
            strokeDasharray="2,5"
          />
          {Object.entries(props.logsByKey).map(([key, log]) => {
            const color = props.colorMap[key] ?? {};

            return (
              <g
                key={key}
                ref={(ref) => {
                  circleGroupRefs.current[key] = ref;
                }}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={14}
                  fill={color.hsl}
                  stroke="transparent"
                  pointerEvents="none"
                  opacity={0.1}
                />
                <circle
                  cx={0}
                  cy={0}
                  r={7}
                  fill={color.hsl}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            );
          })}

          <OverlayContainer>
            <div
              ref={tooltipRef}
              style={{
                pointerEvents: 'none',
                left: 0,
                top: 0,
                zIndex: 100000,
                position: 'absolute',
              }}
            >
              <Tooltip
                targetRef={tooltipRef}
                logsByKey={props.logsByKey}
                dateFormatter={props.dateFormatter}
                colorMap={props.colorMap}
              />
            </div>
          </OverlayContainer>
        </g>
      )}
    </>
  );
}

function Tooltip(props) {
  const tooltipData = useChartTooltipGetData();
  if (tooltipData == null) return null;

  return (
    <div css={sc_css.container_tooltip}>
      {Object.entries(tooltipData.d ?? {}).map(([key, value]) => {
        if (key === 't') {
          return (
            <typography.body2 css={sc_css.date} color={theme.color.text_light} key={key}>
              {props.dateFormatter.format(getDate({ t: value }), 'MMM dd yyyy HH:mm')}
            </typography.body2>
          );
        }

        const log = props.logsByKey[key];
        const color = props.colorMap[key];

        if (log == null) return null;

        return (
          <div key={key} css={sc_css.container_value}>
            <Circle hue={color.hue} />

            <typography.body2 fontWeight={theme.fontWeight.bold} key={key}>
              {value != null ? round(value, log.decimals).toFixed(log.decimals ?? 1) : '-'}{' '}
              {log?.units}
            </typography.body2>

            <typography.body2 fontWeight={theme.fontWeight.bold}>
              {ResourceUtils.getOwnerName(log)}
            </typography.body2>
            <typography.body2 color={theme.color.text_light}>{log.title}</typography.body2>
          </div>
        );
      })}
    </div>
  );
}

const sc_css = {
  container_tooltip: css`
    ${containers.card};
    pointer-events: none;
    padding: 10px 20px;
    white-space: nowrap;
  `,
  container_value: css`
    display: flex;
    align-items: center;

    > :not(:last-of-type) {
      margin-right: 4px;
    }

    > :first-of-type {
      margin-right: 10px;
    }
  `,
  date: css`
    text-align: center;
    margin-bottom: 10px;
  `,
};

function getTooltipX(tooltipRect, pageX) {
  let localPageX = pageX;

  if (pageX + (tooltipRect.width / 2 + 30) > document.documentElement.clientWidth) {
    localPageX =
      pageX - ((pageX + (tooltipRect.width / 2 + 30)) % document.documentElement.clientWidth);
  }

  return localPageX;
}

function getTooltipY(tooltipRect, containerRect, yMin) {
  let top = containerRect.top + yMin - tooltipRect.height - 20;

  if (top < 20) {
    top = 20;
  }

  return top;
}
