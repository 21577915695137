import React, { forwardRef, useEffect, useState } from 'react';

import { getImageFromCacheAsync, getImageFromCacheSync } from '../../lib/imageCache';

import { useMount } from '../../hooks/useMount';
import { useBaseUrl } from '../../store/HomeyStore';

import { Icon } from './Icon';

import generic_device from '../../components/controls/images/sensor/generic-device.svg';

export const HomeyIcon = forwardRef(function (props, forwardedRef) {
  const { baseUrl } = useBaseUrl();
  const [state, setState] = useState(() => {
    // Attempt to read sync. If it fails the useEffect behavior always runs.
    const image = getImageFromCacheSync({ id: props.iconObj?.id });

    if (image != null) {
      return image.src;
    }

    return null;
  });
  const mount = useMount();

  useEffect(() => {
    function setStateIfNotEqual(nextState) {
      setState((prevState) => {
        if (nextState === prevState) {
          return prevState;
        }

        return nextState;
      });
    }

    // Not sure why they can be empty strings but it happens.
    if (props.iconObj?.id != null && baseUrl != null) {
      const iconId = props.iconObj.id;
      const baseSrc = `https://icons-cdn.athom.com/${props.iconObj.id}.svg?ver=1`;
      const nextSrc = `${baseUrl}${props.iconObj.url}`;

      (async () => {
        // Await should wrap any non-promise value.
        const image = await getImageFromCacheAsync({
          id: iconId,
          baseSrc: baseSrc,
          nextSrc: nextSrc,
        });

        if (mount.isMounted) {
          setStateIfNotEqual(image.src);
        }
      })().catch((error) => {
        console.log(error);
        setStateIfNotEqual(generic_device);
      });
    }

    if (props.iconObj?.id == null) {
      setStateIfNotEqual(generic_device);
    }
  }, [baseUrl, props.iconObj, mount]);

  return <Icon {...props} url={state ?? ''} ref={forwardedRef} />;
});

HomeyIcon.Root = Icon.S.Root;

// TODO upload to CDN
// _getImageFromHomey = async () => {
//   const homeyRes = await RNFetchBlob.config({
//     fileCache: true,
//   }).fetch('GET', this._getHomeyUri());
//
//   const info = homeyRes.info();
//
//   if (info.status === 200) {
//     return homeyRes;
//   } else {
//     await homeyRes.flush();
//   }
// };
//
// /**
//  * Posts a local image to the cloud for converting
//  *
//  * @param blobRes
//  * @return {Promise<void>}
//  * @private
//  */
// _postImageToCloud = async (blobRes) => {
//   try {
//     const cloudRes = await RNFetchBlob.fetch(
//       'POST',
//       constants.iconCloudURL,
//       {
//         'Content-Type': 'application/octet-stream',
//       },
//       RNFetchBlob.wrap(blobRes.path())
//     );
//
//     this._cloudUri = cloudRes.json().url;
//   } catch (error) {
//     Log(this, 'CACHE', '_postImageToCloud error', error);
//   }
//
//   await blobRes.flush();
// };
//
// _getHomeyUri = () => {
//   return `${this.homeyUrl}${this.iconObj.url}`;
// };
