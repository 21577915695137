import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useMotionValue } from 'framer-motion';

const NavStateContext = createContext();
const NavSetContext = createContext();

export function NavContextProvider(props) {
  const prevWidthValue = useMotionValue(1);
  const widthValue = useMotionValue(1);

  const [navState, setState] = useState({
    prevWidth: 1,
    width: 1,
    prevWidthValue,
    widthValue,
  });

  useEffect(() => {
    document.documentElement.style.setProperty('--width-left', `${widthValue.get()}px`);

    const unsubscribe = widthValue.onChange(() => {
      document.documentElement.style.setProperty('--width-left', `${widthValue.get()}px`);
    });

    return function () {
      unsubscribe();
    };
  }, [widthValue]);

  const setNavState = useCallback(
    (key, width) => {
      setState((prevState) => {
        // console.log(width);
        // console.log(localStorage.getItem(`layout.width.${key}`));

        const nextWidth = parseInt(localStorage.getItem(`layout.width.${key}`) ?? width ?? 1, 10);

        prevWidthValue.set(widthValue.get());
        widthValue.set(nextWidth);

        document.documentElement.style.setProperty('--width-left', `${widthValue.get()}px`);

        return {
          ...prevState,
          prevWidth: prevWidthValue.get(),
          width: widthValue.get(),
          key: key,
        };
      });
    },
    [prevWidthValue, widthValue]
  );

  return (
    <NavSetContext.Provider value={setNavState}>
      <NavStateContext.Provider value={navState}>{props.children}</NavStateContext.Provider>
    </NavSetContext.Provider>
  );
}

export function useSetNavContextState() {
  const setter = React.useContext(NavSetContext);

  return {
    setNavContextState: setter,
  };
}

export function useNavContextState() {
  return React.useContext(NavStateContext);
}
