import cloneDeep from 'lodash.clonedeep';
import { v4 as uuid } from 'uuid';

import { string } from '../../../../lib/string';

import { DraggableNode } from '../card/DraggableNode';

import { connectorTypesList } from '../connectors/connectorTypes';

export class AdvancedFlowViewUtils {
  static copyFromAdvancedFlow({ advancedFlow, map }) {
    const nodes = {};

    let anchor = null;
    let minX = Infinity;
    let minY = Infinity;

    map.forEach((node, nodeId) => {
      const card = advancedFlow.cards[nodeId];

      if (node.type === DraggableNode.type && card != null) {
        const clonedCard = cloneDeep(card);

        if (clonedCard.x <= minX && clonedCard.y <= minY) {
          anchor = {
            x: clonedCard.x,
            y: clonedCard.y,
          };
          minX = clonedCard.x;
          minY = clonedCard.y;
        }

        nodes[nodeId] = {
          type: node.type,
          value: clonedCard,
        };
      }
    });

    return {
      anchor,
      nodes,
    };
  }

  static cutFromAdvancedFlow({ advancedFlow, map }) {
    const nodes = {};

    let anchor = null;
    let minX = Infinity;
    let minY = Infinity;

    map.forEach((node, nodeId) => {
      const card = advancedFlow.cards[nodeId];

      // Since only one start card is allowed they cant be copied.
      if (node.type === DraggableNode.type && card != null) {
        const clonedCard = cloneDeep(card);

        if (clonedCard.x <= minX && clonedCard.y <= minY) {
          anchor = {
            x: clonedCard.x,
            y: clonedCard.y,
          };
          minX = clonedCard.x;
          minY = clonedCard.y;
        }

        nodes[nodeId] = {
          type: node.type,
          value: clonedCard,
        };
      }
    });

    return {
      nextAdvancedFlow: this.deleteFromAdvancedFlow({ advancedFlow, map }),
      nextNodes: new Map(),
      clipboard: {
        anchor,
        nodes,
      },
    };
  }

  static pasteIntoAdvancedFlow({ advancedFlow, clipboard, pointer }) {
    const nodes = {};
    const idMapping = {};
    const nextNodes = new Map();
    let nextAdvancedFlow = advancedFlow;

    let diffX = 0;
    let diffY = 0;

    if (pointer != null) {
      const anchor = clipboard.anchor ?? { x: 0, y: 0 };
      diffX = pointer.x - anchor.x;
      diffY = pointer.y - anchor.y;
      diffX = diffX - (diffX % 20);
      diffY = diffY - (diffY % 20);
    }

    const hasStartCard = Object.values(advancedFlow.cards ?? {}).some(
      (card) => card.type === 'start'
    );

    if (clipboard.nodes != null) {
      // Create a new id for each node and adjust coordinates for new posiiton.
      for (const [nodeId, node] of Object.entries(clipboard.nodes)) {
        // Only one start card allowed.
        if (node.value.type === 'start' && hasStartCard) continue;

        const newId = uuid();

        // Map the old id to the new id.
        idMapping[nodeId] = newId;

        nodes[newId] = cloneDeep(node.value);
        nodes[newId].x = nodes[newId].x + diffX;
        nodes[newId].y = nodes[newId].y + diffY;
        nextNodes.set(newId, { id: newId, type: node.type });
      }

      this.mutateConnectionsAndTokens({
        nodes,
        idMapping,
      });

      nextAdvancedFlow = { ...advancedFlow };
      nextAdvancedFlow.cards = {
        ...nextAdvancedFlow.cards,
        ...nodes,
      };
    }

    return { nextAdvancedFlow, nextNodes };
  }

  static mutateConnectionsAndTokens({ nodes, idMapping }) {
    const prevIds = Object.keys(idMapping);

    // Preserve connections that occur within the selection and remove external connections.
    Object.entries(nodes).forEach(([nodeId, node]) => {
      for (const connectorType of connectorTypesList) {
        if (node[connectorType] != null) {
          // Filter out external node connections.
          node[connectorType] = node[connectorType].filter((possibleComposedKey) => {
            // eslint-disable-next-line no-unused-vars
            const [cardDataId, cardConnectorType] = possibleComposedKey.split('::');

            return prevIds.includes(cardDataId);
          });

          // Replace the previous key with the new one.
          node[connectorType] = node[connectorType].map((possibleComposedKey) => {
            const [cardDataId, cardConnectorType] = possibleComposedKey.split('::');

            // If it was a composed key only replace the id part.
            if (cardConnectorType) {
              return `${idMapping[cardDataId]}::${cardConnectorType}`;
            }

            return idMapping[cardDataId];
          });
        }
      }

      // Replace local tokens with the new ids.
      Object.keys(node.args ?? {}).forEach((argumentKey) => {
        if (typeof node.args[argumentKey] === 'string') {
          const matches = string.getUUIDMatches(node.args[argumentKey]);

          for (const match of matches) {
            const idInToken = match[0];

            if (prevIds.includes(idInToken)) {
              node.args[argumentKey] = string.replaceAll(
                node.args[argumentKey],
                idInToken,
                idMapping[idInToken]
              );
            }
          }
        }
      });

      // Replace droptoken tokens with the new id.
      if (node.droptoken != null && typeof node.droptoken === 'string') {
        const matches = string.getUUIDMatches(node.droptoken);

        for (const match of matches) {
          const idInToken = match[0];

          if (prevIds.includes(idInToken)) {
            node.droptoken = string.replaceAll(node.droptoken, idInToken, idMapping[idInToken]);
          }
        }
      }
    });
  }

  static deleteFromAdvancedFlow({ advancedFlow, map }) {
    let nextAdvancedFlow = advancedFlow;

    if (map.size > 0) {
      const removedIds = [];

      nextAdvancedFlow = { ...advancedFlow };
      nextAdvancedFlow.cards = {
        ...nextAdvancedFlow.cards,
      };

      // Delete all selected cards from the advanced Flow.
      map.forEach((value, nodeId) => {
        if (value.type === DraggableNode.type) {
          delete nextAdvancedFlow.cards[nodeId];
          // collect the removed keys
          removedIds.push(nodeId);
        }
      });

      // For every card check if any connector holds removed keys
      // if so filter the connector for all removed keys.
      for (const [cardDataId, card] of Object.entries(nextAdvancedFlow.cards)) {
        function removeConnections(type) {
          if (
            card[type]?.some((possibleComposedKey) => {
              // eslint-disable-next-line no-unused-vars
              const [cardDataId, cardConnectorType] = possibleComposedKey.split('::');
              return removedIds.includes(cardDataId);
            })
          ) {
            nextAdvancedFlow.cards[cardDataId] = {
              ...nextAdvancedFlow.cards[cardDataId],
              [type]: card[type].filter((possibleComposedKey) => {
                // eslint-disable-next-line no-unused-vars
                const [cardDataId, cardConnectorType] = possibleComposedKey.split('::');
                return !removedIds.includes(cardDataId);
              }),
            };

            if (nextAdvancedFlow.cards[cardDataId][type]?.length === 0) {
              delete nextAdvancedFlow.cards[cardDataId][type];
            }
          }
        }

        for (const connectorType of connectorTypesList) {
          removeConnections(connectorType);
        }
      }
    }

    return nextAdvancedFlow;
  }

  static invertInAdvancedFlow({ advancedFlow, map }) {
    let nextAdvancedFlow = advancedFlow;

    // I dont think we currently use invert many anywhere but it's just consistent with other methods.
    if (map.size > 0) {
      nextAdvancedFlow = {
        ...advancedFlow,
        cards: {
          ...advancedFlow.cards,
        },
      };

      for (const [nodeId, node] of map) {
        if (node.type === DraggableNode.type) {
          const card = nextAdvancedFlow.cards[nodeId];

          if (card.type === 'condition') {
            const currentInverted = card.inverted ?? false;

            nextAdvancedFlow.cards[nodeId] = {
              ...card,
              inverted: !currentInverted,
            };
          }
        }
      }
    }

    return nextAdvancedFlow;
  }

  static duplicateInAdvancedFlow({ advancedFlow, map }) {
    let nextAdvancedFlow = advancedFlow;

    const nodes = {};
    const idMapping = {};
    const nextNodes = new Map();

    if (map.size > 0) {
      for (const [nodeId, node] of map) {
        if (node.type === DraggableNode.type) {
          const card = nextAdvancedFlow.cards[nodeId];

          // Only one start card allowed.
          if (card.type === 'start') continue;

          const clonedCard = cloneDeep(card);
          const newId = uuid();

          // Map the old key to the new key.
          idMapping[nodeId] = newId;

          // So they wont stack.
          clonedCard.x += 10;
          clonedCard.y += 10;

          nodes[newId] = clonedCard;

          nextNodes.set(newId, { id: newId, type: node.type });
        }
      }

      this.mutateConnectionsAndTokens({
        nodes,
        idMapping,
      });

      nextAdvancedFlow = {
        ...advancedFlow,
      };
      nextAdvancedFlow.cards = {
        ...nextAdvancedFlow.cards,
        ...nodes,
      };
    }

    return { nextAdvancedFlow, nextNodes };
  }

  // Should make this a method of selectBox.
  static selectAllInAdvancedFlow({ advancedFlow }) {
    const nextNodes = new Map([]);

    for (const [nodeId] of Object.entries(advancedFlow.cards)) {
      nextNodes.set(nodeId, { id: nodeId, type: DraggableNode.type });
    }

    return {
      nextNodes,
    };
  }
}
