import { ResourceUtils } from '../ResourceUtils';

export class FlowCardUtils {
  static isZoneCard(card) {
    if (card?.ownerUri) {
      return card.ownerUri.startsWith('homey:zone:');
    }

    return card?.uri.startsWith('homey:zone:') ?? false;
  }

  static isMoodCard(card) {
    if (card?.ownerUri) {
      return card.ownerUri.startsWith('homey:mood:');
    }

    return card?.uri.startsWith('homey:mood:') ?? false;
  }

  static isDeviceCard(card) {
    if (card?.ownerUri) {
      return card.ownerUri.startsWith('homey:device:');
    }

    return card?.uri.startsWith('homey:device:') ?? false;
  }

  static isAppCard(card) {
    if (card?.ownerUri) {
      return card.ownerUri.startsWith('homey:app:');
    }

    return card?.uri.startsWith('homey:app:') ?? false;
  }

  static isManagerCard(card) {
    if (card?.ownerUri) {
      return card.ownerUri.startsWith('homey:manager:');
    }

    return card?.uri.startsWith('homey:manager:') ?? false;
  }

  static getOwnerType(card) {
    switch (true) {
      case FlowCardUtils.isZoneCard(card):
        return 'zone';
      case FlowCardUtils.isDeviceCard(card):
        return 'device';
      case FlowCardUtils.isAppCard(card):
        return 'app';
      case FlowCardUtils.isManagerCard(card):
        return 'manager';
      default:
        return null;
    }
  }

  static getTitle(card) {
    if (card == null) return '';

    // todo:
    // homey-core support
    const zoneName = card.uriObj?.meta?.zoneName;
    return `${ResourceUtils.getOwnerName(card) ?? ''}${zoneName ? ` - ${zoneName}` : ''}`;
  }

  static generateAdvancedFlowCardData(card) {
    const ownerUri = ResourceUtils.getOwnerUri(card);
    const id = ResourceUtils.getId(card);

    return {
      ownerUri: ownerUri,
      id: id,
      droptoken: card.droptoken ? null : undefined,
      args:
        card.args?.reduce((accumulator, arg) => {
          accumulator[arg.name] = arg.value ?? undefined;
          return accumulator;
        }, {}) ?? undefined,
    };
  }

  static generateFlowCardDataIdProperties(card) {
    // TODO
    // At some point we want to only provide id for apiVersion > 3 but we need to make sure all
    // front ends can deal with this first.
    // const apiVersion = HomeyStore.get().api?.apiVersion;
    return {
      id: card.id,
      uri: ResourceUtils.getOwnerUri(card),
    };
  }
}
