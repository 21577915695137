import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import { Redirect, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { RouteManager } from '../../RouteManager';

import { FlowCardExplorerContextProvider } from './flow-card-explorer-dialog/FlowCardExplorerContext';
import { AdvancedFlowViewContext } from './view-advanced-flow/AdvancedFlowViewContext';

import { useFlowTestListener } from './view-flow/useFlowTest';
import { useFlowCardsAttach } from '../../store/flow-cards/useFlowCards';
import { useTokensAttach } from '../../store/flow-tokens/useFlowTokens';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useZonesAttach } from '../../store/zones/useZones';
import { useAppsAttach } from '../../store/apps/useApps';
import { useDevicesAttach } from '../../store/devices/useDevices';
import { useWebAppSettings } from '../../store/web-app-settings/useWebAppSettings';
import { useApi } from '../../store/HomeyStore';

import { publicUrl } from '../../theme/functions/publicUrl';
import { su } from '../../theme/functions/su';

import { MatchPath } from '../../components/routing/MatchPath';
import { Layout } from '../../Layout';
import { Scroll } from '../../components/common/Scroll';
import { GradientButton } from '../../components/buttons/GradientButton';
import { HeaderActionsContainer } from '../../components/header/HeaderActionsContainer';
import { PremiumRequiredDialog } from '../../components/premium/PremiumRequiredDialog';
import { VariablesButton } from '../logic/variables/VariablesButton';
import { LogicDialog } from '../logic/LogicDialog';

import { NewFlowDialog } from './new-flow-dialog/NewFlowDialog';
import { FlowNavigation } from './FlowNavigation';
import { FlowQuotes } from './FlowQuotes';
import { SaveAdvancedFlowDialog } from './save-advanced-flow-dialog/SaveAdvancedFlowDialog';
import { FlowActions } from './view-flow/FlowActions';
import { FlowEditorView } from './view-flow/FlowEditorView';
import { AdvancedFlowActions } from './view-advanced-flow/AdvancedFlowActions';
import { AdvancedFlowView } from './view-advanced-flow/AdvancedFlowView';

export function FlowsPage() {
  const { i18n } = useI18n();

  const location = useLocation();

  useZonesAttach();
  useAppsAttach();
  useDevicesAttach();
  useTokensAttach();
  useFlowCardsAttach();
  useFlowTestListener();

  const { settings } = useWebAppSettings();
  const { api } = useApi();

  const hasAdvancedFlowLicence = api?.features.advancedFlow.hasLicense;
  const hasAdvancedFlowVersion = api?.features.advancedFlow.hasVersion;
  const isAdvancedFlowEnabled = hasAdvancedFlowLicence === true && hasAdvancedFlowVersion === true;

  function getActions() {
    return (
      <MatchPath
        path={RouteManager.matchPath.flow}
        children={({ match }) => {
          const defaultView = (
            <HeaderActionsContainer>
              <VariablesButton />
            </HeaderActionsContainer>
          );

          if (match == null) {
            return defaultView;
          }

          if (match?.params.flowId === 'advanced') {
            if (isAdvancedFlowEnabled === true) {
              return <AdvancedFlowActions />;
            }

            return defaultView;
          }

          return <FlowActions />;
        }}
      />
    );
  }

  function getContent() {
    return (
      <MatchPath
        path={RouteManager.matchPath.flow}
        children={({ match }) => {
          const defaultView = (
            <Scroll flex>
              <FlowsPage.Root>
                <FlowsPage.Content>
                  {settings.theme.darkMode === true ? (
                    <FlowsPage.Image
                      height="332"
                      srcSet={`${publicUrl('/img/flows/flow-darkmode.png')} 1x, ${publicUrl(
                        '/img/flows/flow-darkmode@2x.png'
                      )} 2x`}
                      src={publicUrl('/img/flows/flow-darkmode.png')}
                    />
                  ) : (
                    <FlowsPage.Image
                      height="333"
                      srcSet={`${publicUrl('/img/flows/flow.png')} 1x, ${publicUrl(
                        '/img/flows/flow@2x.png'
                      )} 2x`}
                      src={publicUrl('/img/flows/flow.png')}
                    />
                  )}
                  <FlowQuotes />
                  <GradientButton
                    onPress={() => {
                      if (api?.isCloud !== true) {
                        RouteManager.toNewFlow();
                      } else if (api?.isCloud === true && hasAdvancedFlowVersion === true) {
                        RouteManager.toNewFlow();
                      } else {
                        RouteManager.toFlow(`create?id=${uuid()}`);
                      }
                    }}
                  >
                    {i18n.messageFormatter('flow.createFlow')}
                  </GradientButton>
                </FlowsPage.Content>
              </FlowsPage.Root>
            </Scroll>
          );

          if (match == null) {
            return defaultView;
          }

          if (match.params.flowId === 'advanced') {
            if (isAdvancedFlowEnabled === true) {
              return <AdvancedFlowView />;
            }

            if (api?.isCloud === true) {
              return (
                <Redirect
                  to={{
                    pathname: RouteManager.getPathForFlows(),
                    state: RouteManager.getStateForPremiumRequiredDialog(),
                  }}
                />
              );
            }

            return (
              <Redirect
                to={{
                  pathname: RouteManager.getPathForFlows(),
                  state: RouteManager.createStateForNewFlow({
                    viewId: NewFlowDialog.views.purchase.id,
                  }),
                }}
              />
            );
          }

          if (match.params.flowId === 'new') {
            return (
              <Redirect
                to={{
                  pathname: RouteManager.getPathForFlows(),
                  state: RouteManager.createStateForNewFlow({
                    viewId: NewFlowDialog.views.default.id,
                  }),
                }}
              />
            );
          }

          return <FlowEditorView flowId={match.params.flowId} />;
        }}
      />
    );
  }

  return (
    <AdvancedFlowViewContext>
      <FlowCardExplorerContextProvider>
        <Layout
          subNavigationProps={{
            key: 'flows',
            width: 280,
            title: 'Flow',
          }}
          subNavigationChildren={<FlowNavigation />}
          actions={getActions()}
          content={getContent()}
        >
          <AnimatePresence>
            {location.state?.logicDialog === true && <LogicDialog />}
          </AnimatePresence>
          <AnimatePresence>
            {location.state?.[RouteManager.dialogState.newFlowDialog] === true && <NewFlowDialog />}
          </AnimatePresence>
          <AnimatePresence>
            {location.state?.premiumRequiredDialog === true && <PremiumRequiredDialog />}
          </AnimatePresence>
          <AnimatePresence>
            {location.state?.[RouteManager.dialogState.saveAdvancedFlowDialog] === true && (
              <SaveAdvancedFlowDialog />
            )}
          </AnimatePresence>
        </Layout>
      </FlowCardExplorerContextProvider>
    </AdvancedFlowViewContext>
  );
}

FlowsPage.Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

FlowsPage.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

FlowsPage.Image = styled.img`
  display: block;
  max-height: 40vh;
  margin-bottom: ${su(4)};
`;
