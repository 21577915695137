import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';
import { formatBytes } from '../../../lib/formatBytes';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

type StorageInfoItem = { name: string; size: number };
type StorageInfo = { free: number; total: number; types: { [key: string]: StorageInfoItem } };

interface State {
  storageInfoData: object | null;
  free: number;
  freeFormatted: string;
  total: number;
  totalFormatted: string;
  items: (StorageInfoItem & { key: string; sizeFormatted: string; color: string })[];
  isLoading: boolean;
  error: unknown;
}

type GeneralSettingsInfoStorageStoreState = State & BaseApiStoreState;

function getColorForIndex(index: number) {
  let hue = index * 50;
  hue += 150;
  hue %= 360;

  return `hsl(${hue}, 80%, 70%)`;
}

class GeneralSettingsInfoStorageStore extends BaseApiStore<State> {
  key = 'general-settings-info-storage';

  override createInitialState() {
    return {
      storageInfoData: null,
      free: 0,
      freeFormatted: '',
      total: 0,
      totalFormatted: '',
      items: [],
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:storageInfo');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerSystem = api.system;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const storageInfoPromise = managerSystem.getStorageInfo() as unknown as Promise<StorageInfo>;

      const [, storageInfoData] = await Promise.all([waitPromise, storageInfoPromise]);

      const free = storageInfoData.free ?? 0;
      const total = storageInfoData.total ?? 0;

      const items = Object.entries(storageInfoData.types ?? {})
        .sort(([, a], [, b]) => b.size - a.size)
        .map(([key, value], index) => {
          return {
            ...value,
            key,
            sizeFormatted: formatBytes(value.size),
            color: getColorForIndex(index),
          };
        });

      this.set({
        ...this.createInitialState(),
        storageInfoData: storageInfoData,
        free,
        freeFormatted: formatBytes(free),
        total,
        totalFormatted: formatBytes(total),
        items,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, 'general-settings-info-storage');

    return { generalSettingsInfoStorage: this.store(selectAll, shallow) };
  }
}

function selectAll(state: GeneralSettingsInfoStorageStoreState) {
  return {
    ...selectBase(state),
    storageInfoData: state.storageInfoData,
    free: state.free,
    freeFormatted: state.freeFormatted,
    total: state.total,
    totalFormatted: state.totalFormatted,
    items: state.items,
  };
}

export const generalSettingsInfoStorageStore = new GeneralSettingsInfoStorageStore();
