import { HomeyColors } from '../../theme/HomeyColors';
import { HomeyImages } from '../../theme/HomeyImages';

import { theme } from '../../theme/theme';

import { useZone } from '../../store/zones/useZones';

import { ColumnItemDefault } from './ColumnItemDefault';

export function ColumnItemZone(props) {
  const { zone } = useZone({ zoneId: props.zoneId });

  const backgroundColor = HomeyColors.getColorForUri('homey:manager:zones');
  const url = HomeyImages.getIconForZone(zone?.icon);

  return (
    <ColumnItemDefault iconSize={theme.icon.size_18} url={url} backgroundColor={backgroundColor} />
  );
}
