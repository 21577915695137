import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';

import { Item, useListState } from 'react-stately';
import { useListBox } from 'react-aria';

import { ResourceUtils } from '../../../store/ResourceUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { su } from '../../../theme/functions/su';

import { GradientButton } from '../../../components/buttons/GradientButton';
import { SearchField } from '../../../components/common/search-field/SearchField';
import { Scroll } from '../../../components/common/Scroll';
import { InsightAddListBoxOption } from './InsightAddListBoxOption';
import { LogOwnerItem } from '../LogOwnerItem';
import { Overlay } from '../../../components/overlay/Overlay';

export function InsightAddListBox(props) {
  const listBoxRef = useRef();
  const searchFieldRef = useRef();

  const { i18n } = useI18n();

  const [filterValue, setFilterValue] = useState('');

  const items = props.items;
  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      if (filterValue == null || filterValue.length === 0) return true;

      const formattedFilterValue = filterValue.toLowerCase().replace(/\s+/g, '');

      const formattedItemTitle = item.title.toLowerCase().replace(/\s+/g, '');
      const formattedItemOwnerName = ResourceUtils.getOwnerName(item)
        ?.toLowerCase()
        .replace(/\s+/g, '');
      //const formattedItemZoneName = item.uriZoneName.toLowerCase().replace(/\s+/g, '');

      return (
        formattedItemTitle.includes(formattedFilterValue) ||
        formattedItemOwnerName?.includes(formattedFilterValue)
      );
      //formattedItemZoneName.includes(formattedFilterValue)
    });
  }, [items, filterValue]);

  const handleChange = useMemo(() => {
    return debounce((value) => {
      setFilterValue(value);
    }, 200);
  }, []);

  const children = (item) => {
    const key = ResourceUtils.temp__getId(item);
    return <Item key={key} textValue={item.title} />;
  };

  const sharedProps = {
    selectionMode: 'multiple',
    items: filteredItems,
    children: children,
    selectedKeys: props.selectedKeys,
    'aria-label': 'add chart',
    shouldFocusWrap: true,
  };

  const listState = useListState({ ...sharedProps });
  // labelProps are ignored since we use an aria-label
  const listBox = useListBox({ ...sharedProps }, listState, listBoxRef);

  return (
    <Overlay
      targetRef={props.targetRef}
      animationRemainProps={props.animationRemainProps}
      overlayProps={props.overlayProps}
      overlayTriggerState={props.overlayTriggerState}
      offset={10}
    >
      <S.Root>
        <S.SearchField
          ref={searchFieldRef}
          autoFocus={true}
          aria-label="Filter logs"
          placeholder="Filter..."
          onChange={handleChange}
          onClear={() => {
            setFilterValue('');
          }}
        />

        {listState.collection.size === 0 && (
          <S.EmptyMessage>{i18n.messageFormatter('common.emptyResults')}</S.EmptyMessage>
        )}

        <S.ScrollList>
          <S.List {...listBox.listBoxProps} ref={listBoxRef}>
            {[...listState.collection].map((item) => {
              return (
                <LogOwnerItem
                  key={item.key}
                  log={item.value}
                  colorMap={props.colorMap}
                  component={InsightAddListBoxOption}
                  item={item}
                  listState={listState}
                />
              );
            })}
          </S.List>
        </S.ScrollList>
        <S.Footer>
          <GradientButton
            styleFlat={true}
            styleWidth="full"
            onPress={() => {
              props.overlayTriggerState.close();
              props.onSave(listState.selectionManager.selectedKeys);
            }}
          >
            {i18n.messageFormatter('common.add')}
          </GradientButton>
        </S.Footer>
      </S.Root>
    </Overlay>
  );
}

function S() {}
InsightAddListBox.S = S;

S.Root = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

S.ScrollList = styled(Scroll)`
  height: 260px;
  flex: 1 1 auto;
`;

S.Footer = styled.footer`
  margin-top: 10px;
  padding: 0 10px;
  flex: 0 0 auto;
`;

S.SearchField = styled(SearchField)`
  width: 100%;
  padding: 0 ${su(1)} ${su(1)};
  flex: 0 0 auto;
`;

S.EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 10px;
  flex: 0 0 auto;
`;

S.List = styled.ul`
  outline: 0;
  width: 100%;
`;
