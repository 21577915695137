import { useMemo } from 'react';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';

import { useUsersById } from '../../../store/users/useUsers';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { SettingsTable } from './SettingsTable';
import { FamilySettingsTableUsersCell } from './FamilySettingsTableUsersCell';
import { FamilySettingsTableUsersColumnHeader } from './FamilySettingsTableUsersColumnHeader';

export function FamilySettingsTableUsers(props) {
  const { i18n } = useI18n();
  const users = useUsersById();

  const columns = [
    { key: 'name', name: i18n.messageFormatter('settings.system.usersColumnLabelName') },
    { key: 'role', name: i18n.messageFormatter('settings.system.usersColumnLabelRole') },
    { key: 'enabled', name: i18n.messageFormatter('settings.system.usersColumnLabelEnabled') },
    { key: 'actions', name: 'Actions' },
  ];

  const rows = useMemo(() => {
    const rows = [];
    const rolesOrder = ['owner', 'manager', 'user', 'guest'];

    for (const [, user] of Object.entries(users.byId ?? {})) {
      if (user.athomId != null) {
        rows.push(user);
      }
    }

    rows.sort((a, b) => {
      const roleDiff = rolesOrder.indexOf(a.role) - rolesOrder.indexOf(b.role);

      // If the roles are the same sort by name.
      return roleDiff !== 0 ? roleDiff : i18n.collator.compare(a.name, b.name);
    });

    return rows;
  }, [users.byId, i18n]);

  function renderCell() {
    return <FamilySettingsTableUsersCell />;
  }

  function renderColumnHeader() {
    return <FamilySettingsTableUsersColumnHeader />;
  }

  return (
    <SettingsTable
      aria-label="Table"
      selectionMode="none"
      renderCell={renderCell}
      renderColumnHeader={renderColumnHeader}
    >
      <TableHeader columns={columns}>
        {(column) => {
          return <Column>{column.name}</Column>;
        }}
      </TableHeader>

      <TableBody items={rows}>
        {(item) => {
          return (
            <Row>
              {(columnKey) => {
                switch (columnKey) {
                  default:
                    return (
                      <Cell
                        value={item[columnKey]}
                        item={item}
                        textValue={String(item[columnKey])}
                      />
                    );
                }
              }}
            </Row>
          );
        }}
      </TableBody>
    </SettingsTable>
  );
}
