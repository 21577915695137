import { proxy as palette } from './color.palette';
import { proxy as functional } from './color.functional';

const color = {...palette, ...functional};

/**
 * Box shadows
 */
export const boxShadow = {
  default: `
    0 10px 44px 0 rgba(0, 0, 0, 0.07),
    0 2px 12px 0 rgba(0, 0, 0, 0.1),
    0 2px 4px 0 rgba(0, 0, 0, 0.04)
  `,

  default_hover: `
    0 10px 44px 0 rgba(0, 0, 0, 0.07),
    0 2px 12px 0 rgba(0, 0, 0, 0.15),
    0 2px 4px 0 rgba(0, 0, 0, 0.1)
  `,

  default_active: `
    0 10px 44px 0 rgba(0, 0, 0, 0.07),
    0 2px 12px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2)
  `,

  //used on top of default
  default_hover_combo: `
    0 2px 12px 0 rgba(0, 0, 0, 0.1),
    0 2px 4px 0 rgba(0, 0, 0, 0.1)
  `,

  //used on top of default
  default_active_combo: `
    0 2px 12px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.2)
  `,

  scroll: `
    0 0 20px 0 rgba(0, 0, 0, 0.1)
  `,
  drag: `
    0 10px 44px 0 rgba(0, 0, 0, 0.14),
    0 2px 12px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.08)
  `,
  hover: `
    0 10px 44px 0 rgba(0, 0, 0, 0.0525),
    0 2px 12px 0 rgba(0, 0, 0, 0.075),
    0 2px 4px 0 rgba(0, 0, 0, 0.03)
  `,
  drop: `
    0 10px 44px 0 rgba(0, 130, 250, 0.07),
    0 2px 12px 0 rgba(0, 130, 250, 0.1),
    0 2px 4px 0 rgba(0, 130, 250, 0.08)
  `,
  focus: `
    0 0 3px 0px rgba(0, 130, 250, .5)
  `,
  error: `
    0 0 3px 0px rgba(216, 28, 29, .5)
  `,
  // unused?
  highlight: `
    0 10px 44px 0 ${color.blue_o_14},
    0 2px 12px 0 ${color.blue_o_20},
    0 2px 4px 0 ${color.blue_o_08}
  `
} as const;


