import React from 'react';
import styled from '@emotion/styled';
import { useListBoxSection, useSeparator } from 'react-aria';

import { InsightsListBoxOption } from './InsightListBoxOption';

export function InsightListBoxSection(props) {
  const listBoxSection = useListBoxSection({
    heading: props.section.rendered,
    'aria-label': props.section['aria-label'],
  });

  const seperator = useSeparator({
    elementType: 'li',
  });

  return (
    <>
      {props.section.key !== props.listState.collection.getFirstKey() && (
        <li {...seperator.separatorProps} />
      )}
      <InsightListBoxSection.Root {...listBoxSection.itemProps}>
        {props.section.rendered && props.section.rendered(listBoxSection.headingProps, props)}
        <InsightListBoxSection.Options {...listBoxSection.groupProps}>
          {[...props.section.childNodes].map((node) => (
            <InsightsListBoxOption key={node.key} item={node} listState={props.listState} />
          ))}
        </InsightListBoxSection.Options>
      </InsightListBoxSection.Root>
    </>
  );
}

InsightListBoxSection.Root = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
  --unit-opacity-parent-hover: 0;

  &:hover {
    --unit-opacity-parent-hover: 1;
  }
`;

InsightListBoxSection.Options = styled.ul``;
