import semver from 'semver';

import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';
import { ToastManager } from '../../ToastManager';
import { FlowUtils } from '../flow/FlowUtils';
import { AdvancedFlowUtils } from './AdvancedFlowUtils';

export class AdvancedFlowStore extends BaseApiStore {
  static key = 'advanced-flow';
  static store = this.createStore(this.key);
  static advancedFlowCacheId = 'HomeyAPI.ManagerFlow.AdvancedFlow';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      byFolderId: null,
      byCardOwnerUri: null,
      loading: true,
      error: null,
    };
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info('fetch:advancedFlow');
    this.destroy();
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerFlow = state.api.flow;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));

      let advancedFlowsPromise = Promise.resolve({});

      const isCloud = state.api.homey.platform === 'cloud';
      const satisfiesProVersion = semver.satisfies(state.api.homeyVersion, '>=7.4.1');
      const supportsAdvancedFlow = (isCloud === false && satisfiesProVersion) || isCloud === true;

      if (supportsAdvancedFlow) {
        advancedFlowsPromise = managerFlow
          .getAdvancedFlows({
            $skipCache: skipCache,
          })
          .catch((error) => {
            if (error.code === 404) {
              return {};
            }

            throw error;
          });
      }

      const [, advancedFlowsData] = await Promise.all([waitPromise, advancedFlowsPromise]);

      const result = Object.values(advancedFlowsData).reduce(
        (accumulator, advancedFlowInstance) => {
          const advancedFlowReference = { ...advancedFlowInstance };
          const id = advancedFlowReference.id;
          const folder = FlowUtils.getFolderId(advancedFlowReference);

          accumulator.data[id] = advancedFlowInstance;
          accumulator.byId[id] = advancedFlowReference;
          accumulator.byFolderId[folder] = {
            ...accumulator.byFolderId[folder],
            [id]: advancedFlowReference,
          };

          const cardOwnerUris = AdvancedFlowUtils.getOwnerUrisSetFromCards(advancedFlowInstance);
          advancedFlowInstance.cardOwnerUris = cardOwnerUris;
          advancedFlowReference.cardOwnerUris = cardOwnerUris;

          for (const cardOwnerUri of cardOwnerUris.values()) {
            accumulator.byCardOwnerUri[cardOwnerUri] =
              accumulator.byCardOwnerUri[cardOwnerUri] ?? {};
            accumulator.byCardOwnerUri[cardOwnerUri][advancedFlowInstance.id] =
              advancedFlowInstance;
          }

          return accumulator;
        },
        {
          data: {},
          byId: {},
          byFolderId: {},
          byCardOwnerUri: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byId: result.byId,
        byFolderId: result.byFolderId,
        byCardOwnerUri: result.byCardOwnerUri,
        loading: false,
      });

      managerFlow.addListener('advancedflow.create', this.handleCreate);
      managerFlow.addListener('advancedflow.update', this.handleUpdate);
      managerFlow.addListener('advancedflow.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:advancedFlows');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerFlow = state.api.flow;

      managerFlow.removeListener('advancedflow.create', this.handleCreate);
      managerFlow.removeListener('advancedflow.update', this.handleUpdate);
      managerFlow.removeListener('advancedflow.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(advancedFlow, stateArg) {
    const state = stateArg ?? this.get();

    const cache = state.api.flow._caches[this.advancedFlowCacheId];

    return cache.getOne({
      id: cache._guessCacheId(advancedFlow),
    });
  }

  static handleCreate = (createdAdvancedFlow) => {
    const id = createdAdvancedFlow.id;
    __DEV__ && console.info(`create:advancedFlow:${id}`);
    const state = this.get();
    const advancedFlowInstance = this.getInstanceFromCache(createdAdvancedFlow, state);

    if (advancedFlowInstance == null) return;

    const advancedFlowReference = { ...advancedFlowInstance };
    const folder = FlowUtils.getFolderId(advancedFlowReference);

    const cardOwnerUris = AdvancedFlowUtils.getOwnerUrisSetFromCards(advancedFlowInstance);
    advancedFlowInstance.cardOwnerUris = cardOwnerUris;
    advancedFlowReference.cardOwnerUris = cardOwnerUris;

    const byCardOwnerUri = {
      ...state.byCardOwnerUri,
    };

    for (const cardOwnerUri of cardOwnerUris.values()) {
      byCardOwnerUri[cardOwnerUri] = byCardOwnerUri[cardOwnerUri] ?? {};
      byCardOwnerUri[cardOwnerUri][advancedFlowInstance.id] = advancedFlowReference;
    }

    this.set({
      data: {
        ...state.data,
        [id]: advancedFlowInstance,
      },
      byId: {
        ...state.byId,
        [id]: advancedFlowReference,
      },
      byFolderId: {
        ...state.byFolderId,
        [folder]: {
          ...state.byFolderId[folder],
          [id]: advancedFlowReference,
        },
      },
      byCardOwnerUri: byCardOwnerUri,
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedAdvancedFlow) => {
    const id = updatedAdvancedFlow.id;
    __DEV__ && console.info(`update:advancedFlow:${id}`);

    this.updateQueue[id] = { ...updatedAdvancedFlow };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedAdvancedFlows = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextById = {
          ...state.byId,
        };

        const nextByFolderId = {
          ...state.byFolderId,
        };

        const nextByCardOwnerUri = {
          ...state.byCardOwnerUri,
        };

        let updateCount = 0;

        for (const [id, queuedAdvancedFlow] of Object.entries(queuedAdvancedFlows)) {
          const advancedFlowInstance = this.getInstanceFromCache(queuedAdvancedFlow, state);

          if (advancedFlowInstance == null || state.data[id] == null) {
            __DEV__ && console.info(`update:advancedFlow:unknown:${id}`);
            continue;
          }

          const advancedFlowReference = { ...advancedFlowInstance };

          const prevAdvancedFlow = state.byId[id];
          const prevFolder = FlowUtils.getFolderId(prevAdvancedFlow);
          const nextFolder = FlowUtils.getFolderId(advancedFlowReference);

          nextById[id] = advancedFlowReference;

          if (prevFolder !== advancedFlowReference.folder) {
            delete nextByFolderId[prevFolder][id];

            if (Object.keys(nextByFolderId[prevFolder]).length === 0) {
              delete nextByFolderId[prevFolder];
            }
          }

          nextByFolderId[nextFolder] = {
            ...nextByFolderId[nextFolder],
            [id]: advancedFlowReference,
          };

          for (const cardOwnerUri of state.byId[queuedAdvancedFlow.id].cardOwnerUris?.values()) {
            if (nextByCardOwnerUri[cardOwnerUri]?.[advancedFlowInstance.id] != null) {
              nextByCardOwnerUri[cardOwnerUri] = {
                ...nextByCardOwnerUri[cardOwnerUri],
              };
              delete nextByCardOwnerUri[cardOwnerUri][advancedFlowInstance.id];

              if (Object.values(nextByCardOwnerUri[cardOwnerUri]).length === 0) {
                delete nextByCardOwnerUri[cardOwnerUri];
              }
            }
          }

          const cardOwnerUris = AdvancedFlowUtils.getOwnerUrisSetFromCards(advancedFlowInstance);
          advancedFlowInstance.cardOwnerUris = cardOwnerUris;
          advancedFlowReference.cardOwnerUris = cardOwnerUris;

          for (const cardOwnerUri of cardOwnerUris.values()) {
            nextByCardOwnerUri[cardOwnerUri] = nextByCardOwnerUri[cardOwnerUri] ?? {};
            nextByCardOwnerUri[cardOwnerUri][advancedFlowInstance.id] = advancedFlowReference;
          }

          updateCount++;
        }

        __DEV__ && console.info(`update:advancedFlow:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byId: nextById,
            byFolderId: nextByFolderId,
            byCardOwnerUri: nextByCardOwnerUri,
          });
      }, 200);
    }
  };

  static handleDelete = (deletedAdvancedFlow) => {
    const id = deletedAdvancedFlow.id;
    __DEV__ && console.info(`delete:advancedFlow:${id}`);
    const state = this.get();
    const advancedFlowInstance = state.data[id];

    if (advancedFlowInstance == null) return;

    const folder = FlowUtils.getFolderId(advancedFlowInstance);

    const nextData = { ...state.data };
    const nextById = { ...state.byId };
    const nextByFolderId = { ...state.byFolderId };

    delete nextData[id];
    delete nextById[id];

    if (nextByFolderId[folder] != null) {
      delete nextByFolderId[folder][id];

      if (Object.keys(nextByFolderId[folder]).length === 0) {
        delete nextByFolderId[folder];
      }
    }

    const nextByCardOwnerUri = {
      ...state.byCardOwnerUri,
    };

    for (const cardOwnerUri of advancedFlowInstance.cardOwnerUris.values()) {
      if (nextByCardOwnerUri[cardOwnerUri]?.[advancedFlowInstance.id] != null) {
        nextByCardOwnerUri[cardOwnerUri] = {
          ...nextByCardOwnerUri[cardOwnerUri],
        };
        delete nextByCardOwnerUri[cardOwnerUri][advancedFlowInstance.id];

        if (Object.values(nextByCardOwnerUri[cardOwnerUri]).length === 0) {
          delete nextByCardOwnerUri[cardOwnerUri];
        }
      }
    }

    this.set({
      data: nextData,
      byId: nextById,
      byFolderId: nextByFolderId,
      byCardOwnerUri: nextByCardOwnerUri,
    });
  };

  static async createAdvancedFlow({ advancedFlow }, options) {
    const nextOptions = {
      handleError: true,
      ...options,
    };

    const state = this.get();
    const managerFlow = state.api.flow;
    const nextAdvancedFlow = { ...advancedFlow };

    try {
      return await managerFlow.createAdvancedFlow({
        advancedflow: {
          name: nextAdvancedFlow.name,
          cards: nextAdvancedFlow.cards,
          folder: nextAdvancedFlow.folder,
          enabled: nextAdvancedFlow.enabled,
        },
      });
    } catch (error) {
      if (nextOptions.handleError === true) {
        ToastManager.handleError(error);
      } else {
        throw error;
      }
    }
  }

  static async updateAdvancedFlow({ id, advancedFlow }, options) {
    const nextOptions = {
      handleError: true,
      ...options,
    };

    const state = this.get();
    const managerFlow = state.api.flow;
    const nextAdvancedFlow = { ...advancedFlow };

    try {
      return await managerFlow.updateAdvancedFlow({
        id,
        advancedflow: {
          name: nextAdvancedFlow.name,
          cards: nextAdvancedFlow.cards,
          folder: nextAdvancedFlow.folder,
          enabled: nextAdvancedFlow.enabled,
        },
      });
    } catch (error) {
      if (nextOptions.handleError === true) {
        ToastManager.handleError(error);
      } else {
        throw error;
      }
    }
  }

  static async deleteAdvancedFlow({ id }, options) {
    const nextOptions = {
      handleError: true,
      ...options,
    };

    const state = this.get();
    const managerFlow = state.api.flow;

    const prevAdvancedFlow = state.byId[id];
    const folderId = FlowUtils.getFolderId(prevAdvancedFlow);
    const nextById = { ...state.byId };
    const nextByFolderId = { ...state.byFolderId };

    delete nextById[id];
    delete nextByFolderId[prevAdvancedFlow.folder][id];

    if (Object.keys(nextByFolderId[prevAdvancedFlow.folder]).length === 0) {
      delete nextByFolderId[prevAdvancedFlow.folder];
    }

    this.set({ byId: nextById, byFolderId: nextByFolderId });

    try {
      await managerFlow.deleteAdvancedFlow({
        id,
      });
    } catch (error) {
      const state = this.get();
      const nextById = { ...state.byId, [id]: prevAdvancedFlow };
      const nextByFolderId = {
        ...state.byFolderId,
        [folderId]: {
          ...state.byFolderId[folderId],
          [id]: prevAdvancedFlow,
        },
      };

      this.set({ byId: nextById, byFolderId: nextByFolderId });

      if (nextOptions.handleError === true) {
        ToastManager.handleError(error);
      } else {
        throw error;
      }
    }
  }

  static async updateAdvancedFlowName({ id, name }, options) {
    const nextOptions = {
      handleError: true,
      ...options,
    };

    const state = this.get();
    const managerFlow = state.api.flow;

    const prevAdvancedFlow = state.byId[id];
    const nextAdvancedFlow = { ...prevAdvancedFlow, name: name };

    const currentFolder = FlowUtils.getFolderId(nextAdvancedFlow);

    const nextById = { ...state.byId, [id]: nextAdvancedFlow };
    const nextByFolderId = {
      ...state.byFolderId,
      [currentFolder]: {
        ...state.byFolderId[currentFolder],
        [id]: nextAdvancedFlow,
      },
    };

    const revert = () => {
      const state = this.get();

      if (state.byId[id] != null && state.data[id] != null) {
        const currentFolder = FlowUtils.getFolderId(state.byId[id]);

        const revertedAdvancedFlow = { ...state.byId[id], name: state.data[id].name };
        const nextByFolderId = {
          ...state.byFolderId,
          [currentFolder]: {
            ...state.byFolderId[currentFolder],
            [id]: revertedAdvancedFlow,
          },
        };
        const nextById = { ...state.byId, [id]: revertedAdvancedFlow };

        this.set({
          byId: nextById,
          byFolderId: nextByFolderId,
        });
      }
    };

    this.set({ byId: nextById, byFolderId: nextByFolderId });

    try {
      // await new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     reject(new Error('rename'));
      //   }, 10000);
      // });

      await managerFlow.updateAdvancedFlow({
        id: id,
        advancedflow: {
          name: nextAdvancedFlow.name,
        },
      });
    } catch (error) {
      revert();

      if (nextOptions.handleError === true) {
        ToastManager.handleError(error);
      } else {
        throw error;
      }
    }
  }

  static async updateAdvancedFlowFolder({ id, folderId, handleError }, options) {
    const nextOptions = {
      handleError: true,
      ...options,
    };

    const state = this.get();
    const managerFlow = state.api.flow;

    const prevAdvancedFlow = state.byId[id];

    const currentFolder = FlowUtils.getFolderId(state.byId[id]);
    const nextFolder = FlowUtils.getFolderId({ folder: folderId });

    const nextAdvancedFlow = { ...prevAdvancedFlow, folder: nextFolder };

    const nextById = { ...state.byId, [id]: nextAdvancedFlow };
    const nextByFolderId = { ...state.byFolderId };
    nextByFolderId[currentFolder] = {
      ...nextByFolderId[currentFolder],
    };
    delete nextByFolderId[currentFolder][id];

    if (Object.keys(nextByFolderId[currentFolder]).length === 0) {
      delete nextByFolderId[currentFolder];
    }

    nextByFolderId[nextFolder] = {
      ...nextByFolderId[nextFolder],
      [id]: nextAdvancedFlow,
    };

    const revert = () => {
      const state = this.get();

      if (state.byId[id] != null && state.data[id] != null) {
        const currentFolder = FlowUtils.getFolderId(state.byId[id]);
        const prevFolder = FlowUtils.getFolderId(state.data[id]);

        const nextAdvancedFlow = { ...state.byId[id], folder: prevFolder };

        const nextById = { ...state.byId, [id]: nextAdvancedFlow };
        const nextByFolderId = { ...state.byFolderId };

        nextByFolderId[currentFolder] = {
          ...nextByFolderId[currentFolder],
        };
        delete nextByFolderId[currentFolder][id];

        if (Object.keys(nextByFolderId[currentFolder]).length === 0) {
          delete nextByFolderId[currentFolder];
        }

        nextByFolderId[prevFolder] = {
          ...nextByFolderId[prevFolder],
          [id]: nextAdvancedFlow,
        };

        this.set({ byId: nextById, byFolderId: nextByFolderId });
      }
    };

    this.set({ byId: nextById, byFolderId: nextByFolderId });

    try {
      // await new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     reject(new Error('folder'));
      //   }, 10000);
      // });

      await managerFlow.updateAdvancedFlow({
        id: id,
        advancedflow: {
          folder: nextAdvancedFlow.folder,
        },
      });
    } catch (error) {
      revert();

      if (nextOptions.handleError === true) {
        ToastManager.handleError(error);
      } else {
        throw error;
      }
    }
  }
}
