import { useEffect, useState } from 'react';
import { useLocale } from 'react-aria';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { ToastManager } from '../../../ToastManager';

import { appsAPI } from '../../../lib/appsAPI';

import { useDriversByUri } from '../../../store/drivers/useDrivers';
import { useApi } from '../../../store/HomeyStore';
import { useApp } from '../../../store/apps/useApps';

export function useUriDrivers({ uri }) {
  const { locale } = useLocale();

  const { api } = useApi();

  const appId = uri?.substring('homey:app:'.length);
  const { app } = useApp({ appId });
  const drivers = useDriversByUri();

  const [state, setState] = useState({
    uri: null,
    uriDrivers: null,
    //app: null,
    appInfo: null,
    isInstalled: null,
  });

  useEffect(() => {
    if (uri != null && drivers.byUri && drivers.loading === false && drivers.error == null) {
      const uriDrivers = drivers.byUri?.[uri];

      if (app != null) {
        setState({
          uri,
          uriDrivers,
          isInstalled: true,
          appInfo: {
            appId: app.id,
            appName: app.name,
            appIcon: `https://icons-cdn.athom.com/${app.iconObj?.id}.svg`,
            appColor: app.brandColor ?? app.color,
            appPermissions: [],
          },
        });
      } else if (uri === 'homey:manager:vdevice') {
        setState({
          uri,
          uriDrivers,
          isInstalled: true,
          appInfo: {
            appId: 'vdevice',
            appName: 'Homey',
            appIcon: null,
            appColor: null,
            appPermissions: [],
          },
        });
      } else {
        (async () => {
          try {
            const appId = uri.replace(/^(homey:app:)/, '');
            const app = await appsAPI.getApp({
              appId: appId,
            });

            const result = {
              data: {},
              ownerName: app.liveBuild.name[locale] ?? app.liveBuild.name?.en ?? app.liveBuild.name,
              ownerId: app.id,
              ownerUri: `homey:app:${app.id}`,
              ownerIcon: app.liveBuild.icon,
              ownerColor: app.liveBuild.brandColor,
            };

            for (const driver of app.liveBuild.drivers) {
              if (driver.platforms == null) driver.platforms = ['local'];

              if (driver.platforms.includes(api.homey.platform) === false) {
                continue;
              }

              let id = driver.id;

              if (api.apiVersion === 3) {
                id = `${uri}:${driver.id}`;
              }

              const key = ResourceUtils.getKey({
                id: id,
                uri: uri,
              });

              result.data[key] = {
                id: id,
                uri: uri,
                name: driver.name[locale] ?? driver.name.en ?? driver.name,
                icon: driver.icon,
                class: driver.class,
                __source: driver,
              };
            }

            setState({
              uri,
              uriDrivers: result,
              //app,
              isInstalled: false,
              appInfo: {
                appId: result.ownerId,
                appName: result.ownerName,
                appIcon: result.ownerIcon,
                appColor: result.ownerColor,
                appPermissions: app.liveBuild.permissions ?? [],
              },
            });
          } catch (error) {
            ToastManager.handleError(error);
            setState({
              uri: null,
              uriDrivers: null,
              //app: null,
              appInfo: null,
              isInstalled: null,
            });
          }
        })();
      }
    }
  }, [drivers.byUri, drivers.loading, drivers.error, app, uri, locale, api]);

  return {
    isLoading: state.uri !== uri,
    uriDrivers: state.uriDrivers,
    //app: state.app,
    appInfo: state.appInfo,
    isInstalled: state.isInstalled,
  };
}
