import { useMemo, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

export function useDebouncedMemo(factory, deps, duration) {
  const ref = useRef({});

  ref.current.factory = factory;

  const [state, setState] = useState(ref.current.factory());
  const debouncedSetState = useMemo(() => {
    return debounce((value) => setState(value), duration);
  }, [duration]);

  useEffect(() => {
    debouncedSetState(ref.current.factory());

    return function () {
      debouncedSetState.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, debouncedSetState]);

  return state;
}
