import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Item, Section, useListState } from 'react-stately';
import { useListBox } from 'react-aria';

import { GroupOwner } from '../GroupOwnerItem';

import { useDevice } from '../../../store/devices/useDevices';
import { useDevicesData } from '../../../store/devices/useDevices';
import { useZonesData } from '../../../store/zones/useZones';
import { useAppsData } from '../../../store/apps/useApps';
import { useLogsByOwnerUri, useLogsColorMap } from '../../../store/insights/useInsights';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Scroll } from '../../../components/common/Scroll';
import { InsightListBoxSection } from './InsightListBoxSection';
import { ChippedSearchField } from '../../../components/common/search-field/ChippedSearchField';
import { GroupOwnerItem } from '../GroupOwnerItem';
import { ResourceUtils } from '../../../store/ResourceUtils';
import { useI18n } from '../../../hooks/useI18nFormatters';

export function InsightListBox(props) {
  const { i18n } = useI18n();
  const logs = useLogsByOwnerUri();

  const { colorMap } = useLogsColorMap();
  const chipDeviceId = props.chipDeviceId ?? null;
  const { device } = useDevice({ deviceId: chipDeviceId });

  const devices = useDevicesData();
  const zones = useZonesData();
  const apps = useAppsData();

  const numberLogsByOwnerUri = useMemo(() => {
    return Object.entries(logs.byOwnerUri ?? {}).reduce(
      (accumulator, [ownerUri, ownerUriGroup]) => {
        const logs = {};

        for (const [logKey, log] of Object.entries(ownerUriGroup.data)) {
          if (log.type === 'number') {
            logs[logKey] = log;
          }
        }

        if (Object.keys(logs).length > 0) {
          accumulator[ownerUri] = {
            ...ownerUriGroup,
            data: logs,
          };
        }

        return accumulator;
      },
      {}
    );
  }, [logs.byOwnerUri]);

  const [filterValue, setFilterValue] = useState('');
  const filteredLogGroupsEntries = useMemo(() => {
    const filteredLogGroupsEntries = [];

    if (devices.data == null || zones.data == null || apps.data == null) {
      return filteredLogGroupsEntries;
    }

    const formattedFilterValue = filterValue.toLowerCase().replace(/\s+/g, '');

    // Chipped filter.
    if (chipDeviceId) {
      // Created a loop for this in case we want to allow more chips in the future.
      for (const [ownerUri, ownerUriGroup] of Object.entries(numberLogsByOwnerUri)) {
        // If this is not a device.
        if (ownerUriGroup.ownerType !== 'device') continue;

        // If this device is not equal to the filter device.
        const currentDeviceId = ownerUri.substring('homey:device:'.length);
        if (currentDeviceId !== chipDeviceId) continue;

        const nextOwnerUriGroup = {
          ...ownerUriGroup,
          data: {
            ...ownerUriGroup.data,
          },
        };

        // If a filter value is present filter the logs.
        if (formattedFilterValue.length > 0) {
          for (const [logKey, log] of Object.entries(nextOwnerUriGroup.data)) {
            const formattedLogTitle = log.title?.toLowerCase().replace(/\s+/g, '');

            if (formattedLogTitle.includes(formattedFilterValue) === false) {
              delete nextOwnerUriGroup.data[logKey];
            }
          }
        }

        filteredLogGroupsEntries.push([ownerUri, nextOwnerUriGroup]);
      }

      return filteredLogGroupsEntries;
    }

    // No filter.
    if (formattedFilterValue.length === 0) {
      return Object.entries(numberLogsByOwnerUri);
    }

    // Default filter.
    for (const [ownerUri, ownerUriGroup] of Object.entries(numberLogsByOwnerUri)) {
      const owner = ResourceUtils.getOwnerTypeAndIdFromOwnerUri(ownerUri);
      let ownerProps = null;

      switch (owner.type) {
        case 'device': {
          const device = devices.data?.[owner.id];
          const zone = zones.data?.[device?.zone];
          ownerProps = GroupOwner.getDeviceProps({ device, zone });
          break;
        }
        case 'manager': {
          ownerProps = GroupOwner.getManagerProps({ managerId: owner.id, i18n });
          break;
        }
        case 'app': {
          const app = apps.data?.[owner.id];
          ownerProps = GroupOwner.getAppProps({ appId: owner.id, app });
          break;
        }
        default:
          ownerProps = GroupOwner.getDefaultProps();
          break;
      }

      const formattedOwnerName = ownerProps.ownerName?.toLowerCase().replace(/\s+/g, '') ?? null;
      const formattedZoneName = ownerProps.ownerZoneName?.toLowerCase().replace(/\s+/g, '') ?? null;

      let hasOwnerNameOrZoneNameMatch = false;

      if (
        formattedOwnerName?.includes(formattedFilterValue) ||
        formattedZoneName?.includes(formattedFilterValue)
      ) {
        hasOwnerNameOrZoneNameMatch = true;
      }

      const nextOwnerUriGroup = {
        ...ownerUriGroup,
        data: {
          ...ownerUriGroup.data,
        },
      };

      let hasLogMatch = false;

      for (const [logKey, log] of Object.entries(nextOwnerUriGroup.data)) {
        const formattedLogTitle = log.title?.toLowerCase().replace(/\s+/g, '') ?? '';

        if (formattedLogTitle.includes(formattedFilterValue) !== false) {
          hasLogMatch = true;
          continue;
        }

        delete nextOwnerUriGroup.data[logKey];
      }

      if (hasOwnerNameOrZoneNameMatch && hasLogMatch) {
        filteredLogGroupsEntries.push([ownerUri, nextOwnerUriGroup]);
        continue;
      }

      if (hasLogMatch) {
        filteredLogGroupsEntries.push([ownerUri, nextOwnerUriGroup]);
        continue;
      }

      if (hasOwnerNameOrZoneNameMatch) {
        filteredLogGroupsEntries.push([ownerUri, ownerUriGroup]);
        continue;
      }
    }

    return filteredLogGroupsEntries;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    numberLogsByOwnerUri,
    filterValue,
    props.selectedResolutionKey,
    chipDeviceId,
    devices.data,
    zones.data,
    apps.data,
  ]);
  // todo move to context
  // props.selectedResolutionKey for now because it needs to invalidate for the download from context menu

  return (
    <InsightListBox.Root>
      <InsightListBox.Filter>
        <ChippedSearchField
          chips={chipDeviceId != null ? [{ key: chipDeviceId, title: device?.name ?? '?' }] : null}
          aria-label="Filter logs"
          placeholder="Filter..."
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && event.target.value === '') {
              props.setChipDeviceId(null);
            }
          }}
          onChange={(value) => {
            setFilterValue(value);
          }}
          onRemoveChips={(keys) => {
            props.setChipDeviceId(null);
          }}
        />
      </InsightListBox.Filter>

      <ListBox
        aria-label="Insight Select"
        selectionMode="multiple"
        onSelectionChange={props.onSelectionChange}
        selectedKeys={props.selectedKeys}
        items={filteredLogGroupsEntries}
      >
        {(item) => {
          const [ownerUri, ownerUriGroup] = item;

          return (
            <Section
              key={ownerUri}
              title={(headingProps, props) => {
                return (
                  <InsightListBox.SectionTitle {...headingProps}>
                    {props.ownerName}{' '}
                    <InsightListBox.SectionSubTitle>
                      {props.ownerZoneName}
                    </InsightListBox.SectionSubTitle>
                  </InsightListBox.SectionTitle>
                );
              }}
              props={ownerUriGroup}
              aria-label={ownerUriGroup.ownerName}
            >
              {Object.entries(ownerUriGroup.data).map(([key, log]) => {
                return (
                  <Item
                    key={key}
                    textValue={log.title}
                    log={log}
                    colorMap={colorMap}
                    selectedResolutionKey={props.selectedResolutionKey}
                  />
                );
              })}
            </Section>
          );
        }}
      </ListBox>
    </InsightListBox.Root>
  );
}

function ListBox(props) {
  const listBoxRef = useRef();

  const sharedProps = {
    children: props.children,
    onSelectionChange: props.onSelectionChange,
    selectedKeys: props.selectedKeys,
    selectionMode: props.selectionMode,
    disallowEmptySelection: false,
    defaultSelectedKeys: null,
    disabledKeys: null,
    items: props.items,
    shouldFocusWrap: true,
    'aria-label': props['aria-label'],
    shouldSelectOnPressUp: true,
  };

  const listState = useListState({
    ...sharedProps,
  });

  const listBox = useListBox({ ...sharedProps }, listState, listBoxRef);

  return (
    <Scroll ref={listBoxRef}>
      <InsightListBox.ListBox {...listBox.listBoxProps}>
        {[...listState.collection].map((section) => {
          const [, ownerUriGroup] = section.value;

          return (
            <GroupOwnerItem
              component={InsightListBoxSection}
              ownerType={ownerUriGroup.ownerType}
              ownerId={ownerUriGroup.ownerId}
              ownerName={ownerUriGroup.ownerName}
              key={section.key}
              section={section}
              listState={listState}
            />
          );
        })}
      </InsightListBox.ListBox>
    </Scroll>
  );
}

InsightListBox.Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ChippedSearchField.S.Root} {
    margin: 0 auto 10px;
    flex: 0 0 auto;
  }
`;

InsightListBox.SectionTitle = styled.div`
  padding: 0 ${su(1)};
  margin-bottom: 10px;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.text};
`;

InsightListBox.SectionSubTitle = styled.span`
  display: inline-block;
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.color.text_light};
`;

InsightListBox.ListBox = styled.ul`
  padding: ${su(1)};
  outline: 0;
`;

InsightListBox.Filter = styled.div`
  padding: 0 ${su(2)};
`;
