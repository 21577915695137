import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useCurrentProps } from './useCurrentProps';

/**
 * @param {Object} args
 * @param {string} args.id
 * @param {string} args.type
 * @param {boolean} args.isDisabled
 * @param {() => {}} args.onStart
 * @returns {[CollectedProps, import('react').RefCallback]}
 */
export function useNodeDrag({ id, type, isDisabled, onStart, onEnd }) {
  const currentProps = useCurrentProps({ onStart, onEnd });
  const fakeRef = useRef();

  const [collectedProps, dragRef, dragPreview] = useDrag(() => {
    return {
      type: type,
      options: {
        dropEffect: 'move',
      },
      item: () => {
        currentProps.onStart?.();
        return {
          id: id,
        };
      },
      isDragging(monitor) {
        return id === monitor.getItem().id;
      },
      canDrag(monitor) {
        return isDisabled !== true;
      },
      end(item, monitor) {
        currentProps.onEnd?.();
      },
      /**
       * @typedef {Object} CollectedProps
       * @property {boolean} isDragging
       */
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    };
  }, [id, type, isDisabled, currentProps]);

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview, isDisabled]);
  /**
   * Added isEditMode as a dependency because else after a temporary disabled the
   * preview was visible again after a drag. I'm not sure why it's needed but it's needed
   * so if you remove this make sure the drag preview is not visible after isEditMode
   * had been toggled.
   */

  return [collectedProps, isDisabled ? fakeRef : dragRef];
}
