import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useUsersById } from '../../../store/users/useUsers';

import { SettingsTable } from './SettingsTable';
import { FamilySettingsTableUsersCell } from './FamilySettingsTableUsersCell';
import { FamilySettingsTableUsersColumnHeader } from './FamilySettingsTableUsersColumnHeader';

export function FamilySettingsTableInvites(props) {
  const { i18n } = useI18n();
  const users = useUsersById();

  const rows = [];

  const columns = [
    { key: 'name', name: i18n.messageFormatter('settings.system.usersColumnLabelName') },
    { key: 'role', name: i18n.messageFormatter('settings.system.usersColumnLabelRole') },
    { key: 'enabled', name: i18n.messageFormatter('settings.system.usersColumnLabelEnabled') },
    { key: 'actions', name: 'Actions' },
  ];

  for (const [, user] of Object.entries(users.byId ?? {})) {
    if (user.athomId == null) {
      rows.push({
        ...user,
        name: i18n.messageFormatter('settings.system.usersCellLabelPendingInvite'),
      });
    }
  }

  if (rows.length === 0) return null;

  function renderCell() {
    return <FamilySettingsTableUsersCell type="invite" />;
  }

  function renderColumnHeader() {
    return <FamilySettingsTableUsersColumnHeader />;
  }

  return (
    <SettingsTable
      aria-label="Table"
      selectionMode="none"
      isHeaderHidden={true}
      renderCell={renderCell}
      renderColumnHeader={renderColumnHeader}
    >
      <TableHeader columns={columns}>
        {(column) => {
          return <Column>{column.name}</Column>;
        }}
      </TableHeader>

      <TableBody items={rows}>
        {(item) => {
          return (
            <Row>
              {(columnKey) => {
                switch (columnKey) {
                  default:
                    return (
                      <Cell
                        value={item[columnKey]}
                        item={item}
                        textValue={String(item[columnKey])}
                      />
                    );
                }
              }}
            </Row>
          );
        }}
      </TableBody>
    </SettingsTable>
  );
}
