import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { FlowCardUtils } from '../../../store/flow-cards/FlowCardUtils';
import { DeviceStore } from '../../../store/devices/DeviceStore';
import { ZoneStore } from '../../../store/zones/ZoneStore';
import { ZoneUtils } from '../../../store/zones/ZoneUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useRootZoneId } from '../../../store/zones/useZones';

import { theme } from '../../../theme/theme';

import { Dialog } from '../../../components/overlay/Dialog';
import { IconButton } from '../../../components/buttons/IconButton';
import { ColumnView } from '../../../components/explorer/ColumnView';

import { ListBoxSection } from '../../../components/list-box/ListBoxSection';

import { FlowCardExplorerDialogSearch } from './FlowCardExplorerDialogSearch';
import { ColumnTypeApp } from './ColumnTypeApp';
import { ColumnTypeDevice } from './ColumnTypeDevice';
import { ColumnTypeZone } from './ColumnTypeZone';
import { ColumnTypeManager } from './ColumnTypeManager';
import { ColumnTypeRoot } from './ColumnTypeRoot';
import { ColumnItemCard } from './ColumnItemCard';

import { ColumnItemGeneric } from '../../../components/explorer/ColumnItemGeneric';
import { ColumnItemDevice } from '../../../components/explorer/ColumnItemDevice';
import { ColumnItemZone } from '../../../components/explorer/ColumnItemZone';
import { ColumnItemApp } from '../../../components/explorer/ColumnItemApp';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

FlowCardExplorerDialog.propTypes = {
  cardType: PropTypes.string.isRequired,
  advanced: PropTypes.bool,
  currentCard: PropTypes.object,
  onCardSelect: PropTypes.func,
  onCloseRequest: PropTypes.func,
};

FlowCardExplorerDialog.defaultProps = {
  advanced: true,
};

export function FlowCardExplorerDialog(props) {
  const { i18n } = useI18n();
  const { rootZoneId, loading } = useRootZoneId();

  function getDefaultColumns() {
    if (props.currentCard) {
      if (FlowCardUtils.isManagerCard(props.currentCard)) {
        return [
          {
            type: 'root',
            key: 'root',
          },
          {
            type: 'manager',
            key: props.currentCard.ownerUri ?? props.currentCard.uri,
          },
        ];
      }

      if (FlowCardUtils.isAppCard(props.currentCard)) {
        return [
          {
            type: 'root',
            key: 'root',
          },
          {
            type: 'app',
            key: props.currentCard.ownerUri ?? props.currentCard.uri,
          },
        ];
      }

      if (FlowCardUtils.isDeviceCard(props.currentCard)) {
        const uri = props.currentCard.ownerUri ?? props.currentCard.uri;
        const deviceId = uri.substring('homey:device:'.length);
        const state = DeviceStore.get();

        // if the card was rendered we can assume
        // the device will be in the store
        const device = state.byId?.[deviceId];
        const driverUri = device?.driverUri;

        return [
          {
            type: 'root',
            key: 'root',
          },
          ...(driverUri != null
            ? [
                {
                  type: 'app',
                  key: driverUri,
                },
              ]
            : []),
          {
            type: 'device',
            key: deviceId,
          },
        ];
      }

      if (FlowCardUtils.isZoneCard(props.currentCard)) {
        // this whole approach assumes the data is there
        // so if it's not there just return the default view
        const uri = props.currentCard.ownerUri ?? props.currentCard.uri;
        const zoneId = uri.substring('homey:zone:'.length);
        const state = ZoneStore.get();

        if (state.byId?.[zoneId] != null) {
          const path = ZoneUtils.getPathForZone(state.byId, state.byId[zoneId]);

          return [
            {
              type: 'root',
              key: 'root',
            },
            ...(path?.map((zoneId) => {
              return {
                type: 'zone',
                key: zoneId,
              };
            }) ?? []),
            {
              type: 'zone',
              key: zoneId,
            },
          ];
        }
      }
    }

    return [
      {
        type: 'root',
        key: 'root',
      },
      {
        type: 'zone',
        key: rootZoneId,
      },
    ];
  }

  function renderSection() {
    return <ListBoxSection />;
  }

  function renderItem({ item, value, state }) {
    switch (value.type) {
      case 'zone':
        return <ColumnItemZone zoneId={item.key} />;
      case 'app':
        return <ColumnItemApp appId={value.context.appId} />;
      case 'device':
        return <ColumnItemDevice deviceId={item.key} />;
      case 'mood':
        return <ColumnItemCard />;
      case 'card':
        return <ColumnItemCard />;
      default:
        return <ColumnItemGeneric />;
    }
  }

  function getColumnComponent({ type }) {
    let Component = React.Fragment;

    switch (type) {
      case 'root':
        Component = ColumnTypeRoot;
        break;
      case 'manager':
        Component = ColumnTypeManager;
        break;
      case 'app':
        Component = ColumnTypeApp;
        break;
      case 'zone':
        Component = ColumnTypeZone;
        break;
      case 'device':
        Component = ColumnTypeDevice;
        break;
      default:
        break;
    }

    return Component;
  }

  const ref = useRef({});
  ref.current.onCardSelect = props.onCardSelect;
  const onCardSelect = useCallback((cardKey, card) => {
    return ref.current.onCardSelect(cardKey, card);
  }, []);

  const cardFilter = useCallback(
    (card) => {
      if (card.deprecated === true) {
        return false;
      }

      if (props.advanced === false && card.advanced === true) {
        return false;
      }

      // Hide AdvancedFlow cards from normal Flows.
      if (props.advanced === false && props.cardType === 'action' && card.tokens != null) {
        return false;
      }

      const ownerUri = ResourceUtils.getOwnerUri(card);

      // Hide programmatic_trigger from AdvancedFlow since we have the start card.
      if (
        props.advanced === true &&
        props.cardType === 'trigger' &&
        ownerUri === 'homey:manager:flow' &&
        (card.id === 'programmatic_trigger' ||
          card.id === 'homey:manager:flow:programmatic_trigger')
      ) {
        return false;
      }

      return true;
    },
    [props.cardType, props.advanced]
  );

  return (
    <Dialog containFocus={true} autoFocus={true} onClose={props.onCloseRequest}>
      <FlowCardExplorerDialog.Root>
        <FlowCardExplorerDialog.Header>
          <h3>{`${i18n.messageFormatter(`flow.${props.cardType}`)}...`}</h3>

          <FlowCardExplorerDialogSearch
            advanced={props.advanced}
            cardType={props.cardType}
            cardFilter={cardFilter}
            onCardSelect={onCardSelect}
          />

          <IconButton
            excludeFromTabOrder={true}
            url={iconCloseThin}
            color={theme.color.icon_light}
            hoverColor={theme.color.icon_light_hover}
            size={theme.icon.size_small}
            onPress={props.onCloseRequest}
          />
        </FlowCardExplorerDialog.Header>

        {loading === false && rootZoneId != null && (
          <ColumnView
            columnProps={{
              cardType: props.cardType,
              cardFilter,
              onCardSelect,
            }}
            getDefaultColumns={getDefaultColumns}
            getColumnComponent={getColumnComponent}
            renderItem={renderItem}
            renderSection={renderSection}
          />
        )}
      </FlowCardExplorerDialog.Root>
    </Dialog>
  );
}

FlowCardExplorerDialog.Root = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 1200px;
  height: min(1000px, 90vh);
`;

FlowCardExplorerDialog.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  height: 60px;
  padding: 10px 20px;
  background-color: ${theme.color.body};
  border-radius: ${theme.borderRadius.default} ${theme.borderRadius.default} 0 0;
  border-bottom: 1px solid ${theme.color.line};
`;
