import { useMemo } from 'react';

import { HomeyColors } from '../../../theme/HomeyColors';
import { HomeyImages } from '../../../theme/HomeyImages';

import { useZone } from '../../../store/zones/useZones';

import { theme } from '../../../theme/theme';

export function ZoneFlowCard(props) {
  const { component: Component, ...rest } = props;
  const { zone, api } = useZone({ zoneId: props.ownerId });

  const { iconUrl, iconObj, iconBackgroundColor, resourceUrl, name, isLocked } = useMemo(() => {
    const result = {
      iconUrl: null,
      iconObj: null,
      iconBackgroundColor: null,
      resourceUrl: null,
      name: props.card.ownerName ?? props.card.uriObj?.name,
      isLocked: props.card.requiresPremium === true && api.tier !== 'premium',
    };

    if (zone != null) {
      result.iconUrl = HomeyImages.getIconForZone(zone.icon);
      result.iconBackgroundColor = HomeyColors.getColorForUri('homey:manager:zones');
      result.name = zone.name;
    }

    return result;
  }, [zone, props.card, api]);

  return (
    <Component
      {...rest}
      iconUrl={iconUrl}
      iconObj={iconObj}
      iconBackgroundColor={iconBackgroundColor}
      iconSize={theme.icon.size_medium}
      resourceUrl={resourceUrl}
      name={name}
      isLocked={isLocked}
    />
  );
}
