import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import semver from 'semver';

import { SCOPES } from '../../../lib/scopes';
import { ToastManager } from '../../../ToastManager';
import { HomeyStore } from '../../../store/HomeyStore';
import { languageSettingsStore } from './LanguageSettingsStore';

import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { GeneralSettingsSection } from './GeneralSettingsSection';
import { Throbber } from '../../../components/common/Throbber';
import { RadioGroup } from '../../../components/forms/radio/RadioGroup';
import { Radio } from '../../../components/forms/radio/Radio';

import { FlatButton } from '../../../components/buttons/FlatButton';

import { iconLanguage } from '../../../theme/icons-v2/system/language/language';

export function LanguageSettings() {
  const { i18n } = useI18n();
  const { api, scopes } = useApi();
  const { languageSettings } = languageSettingsStore.use();

  const [isSavingLocked, setIsSavingLocked] = useState(false);
  const [optionLanguage, setOptionLanguage] = useState('en');

  const supportsKorean = (() => {
    if (api?.homey.platform === 'cloud') {
      return true;
    }

    if (api?.homey.platform === 'local' && api?.homey.platformVersion >= 2) {
      const version = semver.coerce(api.homey.softwareVersion);
      return semver.gt(version, `12.0.0`);
    }

    return false;
  })();

  useEffect(() => {
    setOptionLanguage(languageSettings.optionLanguageData?.value ?? 'en');
  }, [languageSettings.optionLanguageData]);

  if (languageSettings.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  return (
    <S.Root>
      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>
          {i18n.messageFormatter('settings.system.language')}
        </GeneralSettingsSection.S.SectionHeading>
        <GeneralSettingsSection.S.SectionView>
          <RadioGroup
            name="language"
            aria-label="Language"
            value={optionLanguage}
            isDisabled={scopes[SCOPES.SYSTEM] !== true}
            onChange={(value) => {
              setOptionLanguage(value);
            }}
          >
            <Radio title="Dansk" aria-label="Dansk" value="da" />
            <Radio title="Deutsch" aria-label="Deutsch" value="de" />
            <Radio title="English" aria-label="English" value="en" />
            <Radio title="Español" aria-label="Español" value="es" />
            <Radio title="Français" aria-label="Français" value="fr" />
            <Radio title="Italiano" aria-label="Italiano" value="it" />
            <Radio title="Nederlands" aria-label="Nederlands" value="nl" />
            <Radio title="Norsk" aria-label="Norsk" value="no" />
            <Radio title="Polskie" aria-label="Polskie" value="pl" />
            <Radio title="Pусский" aria-label="Pусский" value="ru" />
            <Radio title="Svenska" aria-label="Svenska" value="sv" />
            {supportsKorean && <Radio title="한국인" aria-label="한국인" value="ko" />}
          </RadioGroup>

          <S.ButtonWrapper>
            <FlatButton
              styleWidth="full"
              styleColor="blue"
              isDisabled={
                isSavingLocked === true ||
                scopes[SCOPES.SYSTEM] !== true ||
                (languageSettings.optionLanguageData?.value ?? 'en') === optionLanguage
              }
              onPress={() => {
                setIsSavingLocked(true);

                languageSettingsStore
                  .setOptionLanguage({
                    value: optionLanguage,
                  })
                  .then(async (result) => {
                    HomeyStore.reboot().catch((error) => {
                      ToastManager.handleError(error);
                      setIsSavingLocked(false);
                    });
                  })
                  .catch((error) => {
                    ToastManager.handleError(error);
                    setIsSavingLocked(false);
                  })
                  .finally(() => {});
              }}
            >
              <FlatButton.Text>
                {i18n.messageFormatter('settings.system.languageSaveAndRestartButtonLabel')}
              </FlatButton.Text>
            </FlatButton>
          </S.ButtonWrapper>
        </GeneralSettingsSection.S.SectionView>
      </GeneralSettingsSection.S.Section>
    </S.Root>
  );
}

LanguageSettings.pathKey = 'system/language';
LanguageSettings.icon = iconLanguage;
LanguageSettings.backgroundColor = theme.color.system_language;

function S() {}
LanguageSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

S.ButtonWrapper = styled.div`
  padding: ${su(2)} 0 ${su(1)};
`;
