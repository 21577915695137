const insightListItem = (
  <svg
    role="img"
    width="240"
    height="20"
    aria-labelledby="loading-aria"
    viewBox="0 0 240 20"
    preserveAspectRatio="none"
  >
    <title id="loading-aria">Loading...</title>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      clipPath="url(#clip-path)"
      style={{
        fill: 'url(#fill)',
      }}
    />
    <defs>
      <clipPath id="clip-path">
        <circle cx="10" cy="10" r="10" />
        <rect x="30" y="0" rx="10" ry="10" width="210" height="20" />
      </clipPath>
      <linearGradient id="fill">
        <stop offset="0.6" stopColor="rgba(255,255,255,0.1)" stopOpacity="1">
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="1.6" stopColor="rgba(255,255,255,0.2)" stopOpacity="1">
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="2.6" stopColor="rgba(255,255,255,0.1)" stopOpacity="1">
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export function InsightListItem() {
  return insightListItem;
}
