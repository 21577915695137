import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';
import { matchPath } from 'react-router-dom';

import { history } from './history';
import { isUUID } from './lib/string';

export const routingStore = create((set, get, api) => ({
  homeyPath: '',
}));

export class RouteManager {
  // If it should be routeable e.g. you are able to pass a link we add the dialog in the url. Else
  // we add it in the location state which is refreshable but not linkable.
  static dialogRoute = {
    deviceSettings: 'device-settings',
    userPresence: 'user-presence',
  };

  static dialogState = {
    saveAdvancedFlowDialog: 'saveAdvancedFlowDialog',
    newScriptDialog: 'newScriptDialog',
    newFlowDialog: 'newFlowDialog',
  };

  static matchPath = {
    script: '/homeys/:homeyId/scripts/:scriptId',
    flow: '/homeys/:homeyId/flows/:flowId',
    advancedFlow: '/homeys/:homeyId/flows/advanced/:advancedFlowId',
    setting: '/homeys/:homeyId/settings/:settingSectionId/:settingId',
  };

  static toHome(state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}`, state);
  }

  static toFlow(flowId, state) {
    history.push(RouteManager.getPathForFlow(flowId), state);
  }

  static toAdvancedFlow(advancedFlowId, state) {
    history.push(RouteManager.getPathForAdvancedFlow(advancedFlowId), state);
  }

  static toNewFlow(state) {
    const { homeyPath } = routingStore.getState();
    const nextState = this.createStateForNewFlow();

    if (history.location.pathname.startsWith(`${homeyPath}/flows`)) {
      this.pushState(nextState);
      return;
    }

    this.toFlows(nextState);
  }

  static createStateForNewFlow(state) {
    return { [RouteManager.dialogState.newFlowDialog]: true, ...state };
  }

  static isActiveFlow(flowId) {
    return history.location.pathname.includes(flowId);
  }

  static toFlows(state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}/flows`, state);
  }

  static toNewLogicVariable() {
    const { homeyPath } = routingStore.getState();

    if (history.location.pathname.startsWith(`${homeyPath}/flows`)) {
      this.pushState({ logicDialog: true });
      return;
    }

    this.toFlows({ logicDialog: true });
  }

  static toScript(scriptId, state) {
    history.push(RouteManager.getPathForScript(scriptId), state);
  }

  static toScripts(state) {
    history.push(RouteManager.getPathForScripts(), state);
  }

  static toNewScript() {
    const { homeyPath } = routingStore.getState();

    if (history.location.pathname.startsWith(`${homeyPath}/scripts`)) {
      this.pushState({ [RouteManager.dialogState.newScriptDialog]: true });
      return;
    }

    this.toScripts({ [RouteManager.dialogState.newScriptDialog]: true });
  }

  static toDevice(deviceId, state) {
    history.push(RouteManager.getPathForDevice(deviceId), state);
  }

  static toDevices(state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}/devices`, state);
  }

  static toNewDevice() {
    const { homeyPath } = routingStore.getState();

    if (
      history.location.pathname.startsWith(`${homeyPath}/devices`) ||
      history.location.pathname === homeyPath
    ) {
      this.pushState({
        pairDialog: {
          type: 'pair',
        },
      });
      return;
    }

    this.toDevices({
      pairDialog: {
        type: 'pair',
      },
    });
  }

  static toDeviceSettings(deviceId, state) {
    history.push(
      `${history.location.pathname}?dialog=${RouteManager.dialogRoute.deviceSettings}&key=${deviceId}`,
      state
    );
  }

  static toUserPresence(userId, state) {
    history.push(
      `${history.location.pathname}?dialog=${RouteManager.dialogRoute.userPresence}&key=${userId}`,
      state
    );
  }

  static toFavoriteDevice(deviceId, state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}/${deviceId}`, state);
  }

  static toInsights(state, search, { replace = false } = {}) {
    const { homeyPath } = routingStore.getState();

    if (replace === true) {
      history.replace(`${homeyPath}/insights${search != null ? `?${search}` : ''}`, state);
    } else {
      history.push(`${homeyPath}/insights${search != null ? `?${search}` : ''}`, state);
    }
  }

  static toCreatePat(state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}/settings/system/api-keys/create`, state);
  }

  static toPat(patId, state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}/settings/system/api-keys/${patId}`, state);
  }

  static toDeletePat(patId, state) {
    const { homeyPath } = routingStore.getState();
    history.push(`${homeyPath}/settings/system/api-keys/${patId}/delete`, state);
  }

  static toPremiumRequiredDialog() {
    this.pushState(this.getStateForPremiumRequiredDialog());
  }

  static toSetting(settingId, state) {
    history.push(RouteManager.getPathForSetting(settingId), state);
  }

  static toSettings(state) {
    history.push(RouteManager.getPathForSettings(), state);
  }

  static toPath(path, state) {
    history.push(path, state);
  }

  static getStateForPremiumRequiredDialog() {
    return { premiumRequiredDialog: true };
  }

  static getPathForDevice(deviceId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/devices/${deviceId}`;
  }

  static getPathForFlows() {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/flows`;
  }

  static getPathForFlow(flowId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/flows/${flowId}`;
  }

  static getPathForAdvancedFlow(advancedFlowId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/flows/advanced/${advancedFlowId}`;
  }

  static getPathForScript(scriptId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/scripts/${scriptId}`;
  }

  static getPathForScripts() {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/scripts/`;
  }

  static getPathForSetting(settingId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/settings/${settingId}`;
  }

  static getPathForSettings() {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/settings/`;
  }

  static getPathForDeleteUser(userId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/settings/system/family/${userId}/delete`;
  }

  static getPathForSwapOwner(userId) {
    const { homeyPath } = routingStore.getState();
    return `${homeyPath}/settings/system/family/${userId}/swap-owner`;
  }

  static pushState(state) {
    history.push(`${history.location.pathname}${history.location.search}`, state);
  }

  static extendState(state) {
    // TODO
    // implement
    // history.push(`${location.pathname}${location.search}`, state);
  }

  static replaceState(state) {
    history.replace(`${history.location.pathname}${history.location.search}`, state);
  }

  static toOmitQuery() {
    history.push(`${history.location.pathname}`);
  }

  static getPathMatch(path) {
    return matchPath(history.location.pathname, path);
  }
}

export function useHomeyPath() {
  return routingStore((state) => state.homeyPath);
}

function listener(location, action) {
  const match = matchPath(location.pathname, '/homeys/:homeyId');
  let homeyPath = match?.url ?? '';

  if (homeyPath.endsWith('/')) {
    homeyPath = homeyPath.substring(0, homeyPath.length - 1);
  }

  unstable_batchedUpdates(() => {
    routingStore.setState({ homeyPath });
  });
}

history.listen(listener);
listener(history.location);

/**
 *
 * @param match
 * @param location
 * @returns {boolean|*}
 */
export function getIsHomeActive(match, location) {
  if (match == null && location.pathname != null) {
    // For when the device controls are open on the homepage.
    const [, , , deviceId] = location.pathname.split('/');
    return isUUID(deviceId);
  }

  return true;
}
