import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { publicUrl } from '../../../theme/functions/publicUrl';
import { animationFade } from '../../../theme/animations/animationFade';

export function MatterView(props) {
  const { i18n } = useI18n();

  return (
    <MatterView.Root>
      <MatterView.Heading>Matter</MatterView.Heading>

      <MatterView.Paragraph>
        {i18n.messageFormatter('pair.matterOnlyMobileMessage1')}
      </MatterView.Paragraph>
      <MatterView.Paragraph>
        {i18n.messageFormatter('pair.matterOnlyMobileMessage2')}
      </MatterView.Paragraph>

      <img src={publicUrl('/img/matter-screen.png')} alt="matter" />
    </MatterView.Root>
  );
}

MatterView.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: ${su(2, 4, 0)};

  width: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;

  img {
    width: auto;
    min-height: 0;
    flex: 1 1 0;
    margin-top: ${su(2)};
  }
`;

MatterView.Heading = styled.h2`
  padding: ${su(2, 0, 1)};
  text-align: center;
`;

MatterView.Paragraph = styled.p`
  text-align: center;
`;
