import { shallow } from 'zustand/shallow';

import { FlowCardStore } from './FlowCardStore';

import { useAttach } from '../useAttach';

import { selectBase } from '../selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    triggers: state.triggers,
    conditions: state.conditions,
    actions: state.actions,
  };
}

function selectData(state) {
  return {
    ...selectBase(state),
    triggerData: state.triggerData,
    conditionData: state.conditionData,
    actionData: state.actionData,
  };
}

export function useFlowCards() {
  useAttach(FlowCardStore, 'flowCards');

  return FlowCardStore.store(selectAll, shallow);
}

export function useFlowCardsData() {
  useAttach(FlowCardStore, 'flowCardsData');

  return FlowCardStore.store(selectData, shallow);
}

export function useFlowCardsAttach() {
  useAttach(FlowCardStore, 'useFlowCardsAttach');
}
