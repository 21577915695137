import { useContext, createContext } from 'react';

export const OverlayContext = createContext();

/**
 * @return {{ setPreventCloseOverride: () => {} }}
 */
export function useOverlayContext() {
  return useContext(OverlayContext);
}
