import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

interface Heading2MediumProps {
  lineHeight?: React.CSSProperties['lineHeight'];
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  children: React.ReactNode;
}

export function Heading2Medium(props: Heading2MediumProps) {
  return (
    <S.Root
      style={{
        '--heading-2-medium-line-height': props.lineHeight,
        '--heading-2-medium-text-align': props.textAlign,
        '--heading-2-medium-color': props.color,
      }}
    >
      {props.children}
    </S.Root>
  );
}

interface CSSProperties {
  '--heading-2-medium-line-height'?: Heading2MediumProps['lineHeight'];
  '--heading-2-medium-text-align'?: Heading2MediumProps['textAlign'];
  '--heading-2-medium-color'?: Heading2MediumProps['color'];
}

const S = (Heading2Medium.S = class S {
  static Root = styled.h2<{ style: CSSProperties }>`
    font-size: ${theme.fontSize.heading2};
    font-weight: ${theme.fontWeight.medium};
    line-height: var(--heading-2-medium-line-height, 28px);
    text-align: var(--heading-2-medium-text-align, left);
    color: var(--heading-2-medium-color, ${theme.color.text});
  `;
});
