import getPosition from 'dom-helpers/position';
import { useEffect } from 'react';

import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';

export function useConnectorRegister({ type, nodeId, centerRef, centerRefs, cardRootRef }) {
  const advancedFlowViewContext = useAdvancedFlowViewContext();

  useEffect(() => {
    if (nodeId == null) return;

    const connector = {
      key: `${nodeId}::${type}`,
      nodeId: nodeId,
      type: type,
      getPosition({ offsetParentElement, fromConnectorKey, toConnectorKey }) {
        let circleElement = centerRef?.current;

        if (centerRefs != null && fromConnectorKey) {
          circleElement = centerRefs.current[fromConnectorKey];
        }

        if (circleElement == null) {
          return {};
        }

        const position = getPosition(circleElement, offsetParentElement);

        return {
          ...position,
          x: position.left,
          y: position.top,
        };
      },
      getCardHeight() {
        return 60;
        // return cardRootRef.current.offsetHeight;
      },
      getCardWidth() {
        return 340;
        // return cardRootRef.current.offsetWidth;
      },
    };

    advancedFlowViewContext.registerConnector(connector);

    return function () {
      advancedFlowViewContext.unregisterConnector(connector);
    };
  }, [advancedFlowViewContext, centerRef, centerRefs, cardRootRef, type, nodeId]);
}
