import { ToastManager } from '../../../ToastManager';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';
import { FlowCardMissingContextMenuContent } from './FlowCardMissingContextMenuContent';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';
import { iconReplace } from '../../../theme/icons/interface/replace';
import { iconCopyDuplicate } from '../../../theme/icons/interface/copy-duplicate';
import { iconPlay } from '../../../theme/icons/interface/play';
import { iconCheckmark } from '../../../theme/icons/interface/checkmark';

export function FlowCardConditionContextMenuContent(props) {
  const { i18n } = useI18n();

  const { api } = useApi();

  async function onAction(key) {
    switch (key) {
      case 'replace':
        props.onReplaceRequest();
        break;
      case 'duplicate':
        props.onDuplicateRequest();
        break;
      case 'run':
        async function run() {
          try {
            const response = await api.flow.runFlowCardCondition({
              uri: ResourceUtils.getOwnerUriV3(props.data),
              id: props.data.id,
              args: props.data.args,
              droptoken: props.data.droptoken,
            });

            if (props.data.inverted) {
              response.result = !response.result;
            }

            ToastManager.add({
              icon: iconCheckmark,
              message: i18n.messageFormatter('flow.cardConditionResult', {
                condition: i18n.messageFormatter(`common.${String(response.result ?? 'unknown')}`),
              }),
            });
          } catch (error) {
            ToastManager.handleError(error);
          }
        }

        run();
        break;

      case 'invert':
        props.onInvertRequest();
        break;
      case 'delete':
        props.onDeleteRequest();
        break;
      default:
        break;
    }
  }

  if (props.card == null) {
    return <FlowCardMissingContextMenuContent {...props} />;
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.replace')}
        icon={iconReplace}
        onPress={() => {
          onAction('replace');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.test')}
        icon={iconPlay}
        onPress={() => {
          onAction('run');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.invert')}
        onPress={() => {
          onAction('invert');
        }}
      />

      <DeveloperMenu
        onIdRequest={() => props.card?.id}
        onUriRequest={() => ResourceUtils.getOwnerUri(props.card ?? {})}
        onResourceRequest={() => {
          return {
            card: props.card,
            data: props.data,
          };
        }}
      />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
