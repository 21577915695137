import React, { useRef, useCallback, useEffect, useMemo, useContext, createContext } from 'react';
import { createStore, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { subscribeWithSelector } from 'zustand/middleware';

import { useCurrentProps } from '../../../../hooks/useCurrentProps';
import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';
// import { enqueueTask } from '../../../../../lib/enqueueTask';

const StoreContext = createContext(null);

function getInitialStore(set, get, api) {
  return {
    isNodeInvalid: null,
    arguments: {},
    instances: {},
    api: {
      ...api,
      register(key, instance) {
        if (get().instances[key] != null) {
          throw new Error(`Already registered: ${key}`);
        }

        get().instances[key] = instance;
        get().arguments[key] = null;
      },
      unregister(key) {
        if (get().instances[key] == null) {
          throw new Error(`Not registered: ${key}`);
        }

        delete get().instances[key];
        delete get().arguments[key];
      },
      onCheckNodeRequest() {
        let isNodeInvalid = false;

        const state = api.getState();

        for (const [, argument] of Object.entries(state.arguments)) {
          if (argument === true) {
            isNodeInvalid = true;
          }
        }

        api.setState({
          isNodeInvalid: isNodeInvalid,
        });
      },
      onInvalidRequest() {
        let isNodeInvalid = false;

        const state = api.getState();

        for (const [key, instance] of Object.entries(state.instances)) {
          const isInvalid = instance.onInvalidRequest();
          if (isInvalid === true) isNodeInvalid = true;

          state.arguments[key] = isInvalid;
        }

        api.setState({
          arguments: {
            ...state.arguments,
          },
          isNodeInvalid: isNodeInvalid,
        });

        return { isNodeInvalid: isNodeInvalid };
      },
      onInvalidResetRequest() {
        const state = api.getState();

        // eslint-disable-next-line no-unused-vars
        for (const [key, instance] of Object.entries(state.instances)) {
          state.arguments[key] = null;
        }

        api.setState({
          arguments: {
            ...state.arguments,
          },
          isNodeInvalid: null,
        });
      },
    },
  };
}

export function NodeArgumentsContext(props) {
  const storeRef = useRef(null);

  if (storeRef.current == null) {
    storeRef.current = createStore(subscribeWithSelector(getInitialStore));
  }

  // Fallback for standard Flow and cursor card.
  // When we add validation there we need to remove this.
  if (props.nodeId == null) {
    return <StoreContext.Provider value={storeRef.current}>{props.children}</StoreContext.Provider>;
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      <InnerContextConsumer nodeId={props.nodeId}>{props.children}</InnerContextConsumer>
    </StoreContext.Provider>
  );
}

function selectStoreApi(state) {
  return state.api;
}

function InnerContextConsumer(props) {
  const advancedFlowViewContext = useAdvancedFlowViewContext();
  const store = useContext(StoreContext);
  const api = useStore(store, selectStoreApi);

  useEffect(() => {
    advancedFlowViewContext.registerNodeArgumentsHandler(props.nodeId, api);

    return function () {
      advancedFlowViewContext.unregisterNodeArgumentsHandler(props.nodeId);
    };
    // api, advancedFlowViewContext are both stable references
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId]);

  return props.children;
}

export function useNodeArgumentsContext() {
  const store = useContext(StoreContext);
  const { selector, equalityFn } = useMemo(() => {
    return {
      selector(state) {
        return {
          isNodeInvalid: state.isNodeInvalid,
          api: state.api,
        };
      },
      equalityFn(currentStateSlice, nextStateSlice) {
        return shallow(currentStateSlice.isNodeInvalid, nextStateSlice.isNodeInvalid);
      },
    };
  }, []);

  return useStore(store, selector, equalityFn);
}

export function useNodeArgumentContext({ key, onInvalidRequest, isDisabled }) {
  const currentProps = useCurrentProps({ key, onInvalidRequest });
  const store = useContext(StoreContext);

  // todo
  // onOpenRequest()

  const { selector, equalityFn } = useMemo(() => {
    return {
      selector(state) {
        return {
          [key]: state.arguments[key],
          api: state.api,
        };
      },
      equalityFn(currentStateSlice, nextStateSlice) {
        return shallow(currentStateSlice[key], nextStateSlice[key]);
      },
    };
  }, [key]);

  const state = useStore(store, selector, equalityFn);

  const setInvalid = useCallback(
    (value, { checkNode } = {}) => {
      const api = state.api;

      api.setState((prevState) => {
        if (prevState.arguments[key] === value) return prevState;

        return {
          arguments: {
            ...prevState.arguments,
            [key]: value,
          },
        };
      });

      if (checkNode === true) {
        api.onCheckNodeRequest();
      }
    },
    // api is a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key]
  );

  useEffect(() => {
    const api = state.api;

    if (isDisabled !== true) {
      api.register(key, currentProps);

      return function () {
        api.unregister(key);
      };
    }
    // api and currentProps are stable references
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, isDisabled]);

  return { isInvalid: state[key], setInvalid };
}
