import { ToastManager } from '../../ToastManager';
import { UserMeStore } from '../../store/user-me/UserMeStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useUserMe } from '../../store/user-me/useUserMe';
import { useMood } from '../../store/moods/useMoods';
import { useApi } from '../../store/HomeyStore';

import { theme } from '../../theme/theme';

import { ContextMenuContent } from '../common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../common/context-menu/DeveloperMenu';

import { iconMouseLeftClick } from '../../theme/icons/interface/mouse-left-click';
import { iconNotFavorite } from '../../theme/icons/interface/heart-outline';
import { iconFavorite } from '../../theme/icons/interface/heart-fill';
import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';

export function MoodContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { api } = useApi();
  const { mood } = useMood({ moodId: props.moodId });
  const userMe = useUserMe();

  const isFavoriteMood = userMe.favoriteMoods?.includes(props.moodId) ?? false;

  async function onAction(key) {
    switch (key) {
      case 'set':
        api.moods
          ._call('POST', `mood/${props.moodId}/set`, {
            opts: {},
            body: {},
            queryParameters: {},
          })
          .then(() => {})
          .catch(ToastManager.handleError);
        break;
      case 'favorite':
        const favoriteName = `**${mood.name}**`;

        try {
          if (isFavoriteMood) {
            await UserMeStore.removeFavoriteMood({ id: props.moodId });
            ToastManager.add({
              icon: iconNotFavorite,
              iconColor: theme.icon.color_light,
              message: i18n.messageFormatter(`mood.removeFavorite`, {
                name: favoriteName,
              }),
            });
            return;
          }

          await UserMeStore.addFavoriteMood({ id: props.moodId });
          ToastManager.add({
            icon: iconFavorite,
            iconColor: theme.color.special_favorite,
            message: i18n.messageFormatter(`mood.addFavorite`, {
              name: favoriteName,
            }),
          });
        } catch (error) {
          ToastManager.handleError(error);
        }
        break;
      case 'delete':
        api.moods
          ._call('DELETE', `mood/${props.moodId}`, {
            opts: {},
            body: {},
            queryParameters: {},
          })
          .then(() => {})
          .catch(ToastManager.handleError);
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`mood.set`)}
        icon={iconMouseLeftClick}
        onPress={() => {
          onAction('set');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`mood.favorite`)}
        icon={isFavoriteMood ? iconFavorite : iconNotFavorite}
        onPress={() => {
          onAction('favorite');
        }}
      />

      <DeveloperMenu onIdRequest={() => mood?.id} onResourceRequest={() => mood} />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
