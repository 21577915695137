import { Fragment, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useHover } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';
import { createCard } from '../flow-card/createCard';
import { FlowEditor } from './useFlowEditor';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useTitleChunks } from '../flow-card/useTitleChunks';
import { useFlowTest } from './useFlowTest';

import { theme } from '../../../theme/theme';
import { containers } from '../../../theme/elements/containers';
import { su } from '../../../theme/functions/su';

import { RouterLink } from '../../../components/common/link/RouterLink';
import { Icon } from '../../../components/common/Icon';
import { HomeyIcon } from '../../../components/common/HomeyIcon';
import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';
import { PremiumLocked } from '../../../components/premium/PremiumLocked';

import { FlowCardBase } from '../flow-card/FlowCardBase';
import { FlowArgument } from '../flow-arguments/FlowArgument';
import { ArgumentDroptoken } from '../flow-arguments/ArgumentDroptoken';
import { FlowCardHint } from '../flow-card/FlowCardHint';
import { FlowCardTokensHint } from '../flow-card/FlowCardTokensHint';
import { ArgumentDuration } from '../flow-arguments/ArgumentDuration';
import { ArgumentDelay } from '../flow-arguments/ArgumentDelay';

import { iconWarning } from '../../../theme/icons/interface/warning/warning';
import { iconWarningCircle } from '../../../theme/icons/interface/warning-circle/warning-circle';
import { iconCheckCircle } from '../../../theme/icons/interface/check-circle';
import { iconSpinner } from '../../../theme/icons/interface/spinner/spinner';
import { iconQuestionMark } from '../../../theme/icons/interface/questionmark';

export const FlowCard = createCard(FlowCardInner);

function FlowCardInner(props) {
  const { i18n } = useI18n();
  const itemHover = useHover({});
  const cardContainerRef = useRef();

  const { titleChunks, firstArgumentIndex } = useTitleChunks({
    card: props.card,
    data: props.data,
  });

  const testResult = useFlowTest({
    cardType: props.cardType,
    cardIndex: props.cardIndex,
  });

  function mapper(chunk, index) {
    if (chunk.type === 'argument') {
      return (
        <FlowArgument
          key={index}
          cardContainerRef={cardContainerRef}
          argumentKey={chunk.value}
          argument={chunk.argument}
          argumentTypeText={chunk.argumentTypeText}
          otherArguments={chunk.otherArguments}
          cardType={props.cardType}
          cardIndex={props.cardIndex}
          card={props.card}
          data={props.data}
          isFirstArgument={firstArgumentIndex === index}
          onUpdate={(args) => {
            FlowEditor.updateCard({
              cardType: props.cardType,
              cardIndex: props.cardIndex,
              argumentKey: args.argumentKey,
              value: args.value,
            });
          }}
        />
      );
    }

    if (chunk.type === 'droptoken') {
      return (
        <ArgumentDroptoken
          key={index}
          cardContainerRef={cardContainerRef}
          argumentTypeText={chunk.argumentTypeText}
          otherArguments={chunk.otherArguments}
          cardType={props.cardType}
          cardIndex={props.cardIndex}
          card={props.card}
          data={props.data}
          isFirstArgument={firstArgumentIndex === index}
          onUpdate={(args) => {
            FlowEditor.updateCardProperty({
              cardType: props.cardType,
              cardIndex: props.cardIndex,
              property: 'droptoken',
              value: args.value,
            });
          }}
        />
      );
    }

    if (chunk.type === 'duration') {
      return (
        <ArgumentDuration
          key={index}
          cardContainerRef={cardContainerRef}
          cardType={props.cardType}
          cardIndex={props.cardIndex}
          card={props.card}
          data={props.data}
          onUpdate={(args) => {
            FlowEditor.updateCardProperty({
              cardType: props.cardType,
              cardIndex: props.cardIndex,
              property: 'duration',
              value: args.value,
            });
          }}
        />
      );
    }

    if (chunk.type === 'delay') {
      return (
        <ArgumentDelay
          Fragment
          key={index}
          cardContainerRef={cardContainerRef}
          cardType={props.cardType}
          cardIndex={props.cardIndex}
          card={props.card}
          data={props.data}
          onUpdate={(args) => {
            FlowEditor.updateCardProperty({
              cardType: props.cardType,
              cardIndex: props.cardIndex,
              property: 'delay',
              value: args.value,
            });
          }}
        />
      );
    }

    return <FlowCardBase.Text key={index}>{chunk.value}</FlowCardBase.Text>;
  }

  function renderTestResult(isContextMenuOpen) {
    if (itemHover.isHovered === false && isContextMenuOpen === false) {
      if (testResult?.state === 'start') {
        return (
          <FlowCard.StatusIconWrapper>
            <Icon url={iconSpinner} color={theme.color.icon_dark} />
          </FlowCard.StatusIconWrapper>
        );
      }

      if (testResult?.state === 'end' && testResult?.result === true) {
        return (
          <FlowCard.StatusIconWrapper>
            <Icon url={iconCheckCircle} color={theme.color.success} />
          </FlowCard.StatusIconWrapper>
        );
      }

      if (testResult?.state === 'end' && testResult?.result === false) {
        return (
          <FlowCard.StatusIconWrapper>
            <Icon url={iconWarningCircle} color={theme.color.warning} />
          </FlowCard.StatusIconWrapper>
        );
      }

      if (testResult?.state === 'error') {
        return (
          <FlowCard.StatusIconWrapper>
            <Icon url={iconWarning} color={theme.color.error} />
          </FlowCard.StatusIconWrapper>
        );
      }
    }
  }

  function renderContent() {
    if (props.card == null) {
      return (
        <Fragment>
          <FlowCardBase.IconWrapper
            style={{
              '--flow-card-icon-wrapper-background': theme.icon.color_light,
            }}
          >
            <Icon url={iconQuestionMark} color={theme.icon.color_white} />
          </FlowCardBase.IconWrapper>

          <FlowCardBase.Details>
            <div
              style={{
                display: 'flex',
                flex: '1 1 auto',
                alignItems: 'center',
                gap: 5,
                color: theme.color.text_light,
              }}
            >
              {i18n.messageFormatter('flow.unavailableCard')}
              <FlowCardHint
                hint={
                  <div>
                    <div style={{ paddingBottom: 10 }}>
                      {i18n.messageFormatter('flow.unavailableCardMessage')}
                    </div>
                    <div>{props.data?.ownerUri ?? props.data?.uri}</div>
                    <div>{props.data?.id}</div>
                  </div>
                }
              />
            </div>
          </FlowCardBase.Details>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <FlowCardBase.IconWrapper
          style={{
            '--flow-card-icon-wrapper-background': props.iconBackgroundColor,
          }}
        >
          {(() => {
            if (props.iconComponent != null) {
              return props.iconComponent;
            }

            if (props.iconUrl != null) {
              return (
                <Icon
                  url={props.iconUrl}
                  color={theme.color.white}
                  size={props.iconSize ?? theme.icon.size_medium}
                />
              );
            }

            return (
              <HomeyIcon
                iconObj={props.iconObj}
                color={theme.color.white}
                size={props.iconSize ?? theme.icon.size_default}
              />
            );
          })()}
        </FlowCardBase.IconWrapper>

        <FlowCardBase.Details>
          <FlowCardBase.Header>
            <FlowCardBase.Name>
              {props.resourceUrl ? (
                <FlowCard.Link to={props.resourceUrl}>{props.name}</FlowCard.Link>
              ) : (
                props.name
              )}
            </FlowCardBase.Name>
            {props.card.hint && <FlowCardHint hint={props.card.hint} />}
            {props.card.tokens != null && props.card.tokens?.length > 0 && (
              <FlowCardTokensHint tokens={props.card.tokens} />
            )}
          </FlowCardBase.Header>
          <FlowCardBase.Content>{titleChunks.map(mapper)}</FlowCardBase.Content>
        </FlowCardBase.Details>
      </Fragment>
    );
  }

  return (
    <ContextMenu content={props.contextMenuContent}>
      {({ isOpen, onContextMenu }) => {
        return (
          <FlowCard.Root
            {...itemHover.hoverProps}
            ref={mergeRefs([props.forwardedRef, cardContainerRef])}
            className={`flow-card ${props.className ?? ''}`}
            data-style-type={props.cardType}
            isDraggingClone={props.isDraggingClone}
            onContextMenu={onContextMenu}
          >
            {props.isLocked === true && <PremiumLocked.Pressable zIndex={2} />}

            {renderContent()}

            {renderTestResult(isOpen)}

            <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
          </FlowCard.Root>
        );
      }}
    </ContextMenu>
  );
}

FlowCard.Link = styled(RouterLink)`
  color: ${theme.color.text_light};

  &:hover {
    color: ${theme.color.text_light_hover};
  }
`;

FlowCard.Root = styled.div`
  ${containers.card};
  --flow-token-max-width: 240px; // Used in src/containers/flows/flow-arguments/FlowToken.js

  position: relative;
  display: flex;
  align-items: center;
  padding: ${su(1)};
  margin: ${su(2)} 0;
  transition: box-shadow ${theme.duration.micro} ease-in-out;
  width: 340px;
  outline: 0;
  background: ${theme.flowcard.background};

  ${(props) =>
    props.isDraggingClone &&
    css`
      box-shadow: ${theme.boxShadow.drag};
    `}
  ${ContextMenuButton.Root} {
    z-index: 3;
  }

  &:hover {
    ${ContextMenuButton.Root} {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:not([data-style-type='trigger']) ${FlowCardBase.IconWrapper} {
    cursor: grab;
  }
`;

FlowCard.StatusIconWrapper = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 10px;
`;

FlowCard.Text = styled.span`
  border: 1px solid transparent;
`;
