import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useButton } from '@react-aria/button';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { iconChevronRight } from '../../../theme/icons/interface/chevron-right/chevron-right';
import { iconChevronLeft } from '../../../theme/icons/interface/chevron-left';

import { Icon } from '../../../components/common/Icon';

export function ViewButton(props) {
  const ref = useRef();
  const button = useButton(props, ref);

  return (
    <ViewButton.Root {...button.buttonProps} as={props.as} ref={ref}>
      {props.children}
    </ViewButton.Root>
  );
}

ViewButton.Forward = function Forward(props) {
  return (
    <ViewButton {...props} as={ViewButton.Forward.Root}>
      {props.children}

      <Icon color={theme.color.text_link} size={theme.icon.size_tiny} url={iconChevronRight} />
    </ViewButton>
  );
};

ViewButton.Backward = function Backward(props) {
  return (
    <ViewButton {...props} as={ViewButton.Backward.Root}>
      <Icon color={theme.color.text_link} size={theme.icon.size_tiny} url={iconChevronLeft} />

      {props.children}
    </ViewButton>
  );
};

ViewButton.Root = styled.button`
  padding: ${su(1, 0)};
  color: ${theme.color.text_link};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${theme.color.text_link_hover};
    text-decoration: underline;

    ${Icon.S.Root} {
      background-color: ${theme.color.text_link_hover};
    }
  }
`;

ViewButton.Forward.Root = styled(ViewButton.Root)`
  ${Icon.S.Root} {
    margin-left: ${su(1)};
  }
`;

ViewButton.Backward.Root = styled(ViewButton.Root)`
  ${Icon.S.Root} {
    margin-right: ${su(1)};
  }
`;
