import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { theme } from '../../theme/theme';

function base(props) {
  if (props.color != null && props.fontWeight != null) {
    return css`
      color: ${props.color};
      font-weight: ${props.fontWeight};
    `;
  }

  if (props.color != null) {
    return css`
      color: ${props.color};
    `;
  }

  if (props.fontWeight != null) {
    return css`
      font-weight: ${props.fontWeight};
    `;
  }

  return css``;
}

export const typography_css = {
  h1: css`
    color: ${theme.color.text};
  `,
  h2: css`
    color: ${theme.color.text};
  `,
  h3: css`
    color: ${theme.color.text};
  `,
  body1: css`
    color: ${theme.color.text};
  `,
  body2: css`
    font-size: ${theme.fontSize.small};
    color: ${theme.color.text};
  `,
};

const h1 = styled.h1`
  ${typography_css.h1};
  ${base};
`;

const h2 = styled.h2`
  ${typography_css.h2};
  ${base};
`;

const h3 = styled.h3`
  ${typography_css.h3};
  ${base};
`;

const body1 = styled.div`
  ${typography_css.body1};
  ${base};
`;

const body2 = styled.div`
  ${typography_css.body2};
  ${base};
`;

export const typography = {
  h1: h1,
  h2: h2,
  h3: h3,
  body1: body1,
  body2: body2,
};
