import { useReducer, useRef, useMemo, useEffect, useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { FlowEditor } from './useFlowEditor';

import { NodeArgumentsContext } from '../view-advanced-flow/card/NodeArgumentsContext';
import { TokenContext } from '../TokenContext';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { dragTypes } from '../../../components/dnd/dragTypes';

import { AddCardButton, flowCardTypes } from './flowCardTypes';

import { FlowCard } from './FlowCard';
import { DragArea, GroupDivider } from './flowCardDragging';
import { FlowCardActionContextMenuContent } from './FlowCardActionContextMenuContent';

export function FlowCardActions(props) {
  const { i18n } = useI18n();

  const [state, dispatch] = useReducer(reducer, props.actions, initReducer);
  const [isDragging, setIsDragging] = useState(false);
  const stateRef = useRef({});
  stateRef.current = state;

  const contentList = useMemo(() => {
    return [
      {
        type: 'dragarea',
        position: 'top',
        group: 'then',
        values: state.groups.then,
      },
      ...state.groups.then,
      {
        type: 'dragarea',
        position: 'bottom',
        group: 'then',
        values: state.groups.then,
      },
      {
        type: 'divider',
        title: i18n.messageFormatter('flow.else'),
        group: 'else',
        values: state.groups.else,
      },
      {
        type: 'dragarea',
        position: 'top',
        group: 'else',
        values: state.groups.else,
      },
      ...state.groups.else,
      {
        type: 'dragarea',
        group: 'else',
        position: 'bottom',
        values: state.groups.else,
      },
    ];
  }, [state, i18n]);

  const actions = props.actions;
  useEffect(() => {
    dispatch({ type: 'reset', payload: actions });
  }, [actions]);

  function handleMoveCard({ prevIndex, prevGroup, nextIndex, nextGroup }) {
    dispatch({
      type: 'move_card',
      payload: {
        prevIndex,
        prevGroup,
        nextIndex,
        nextGroup,
      },
    });
  }

  function handleMoveCardToGroup({ prevIndex, prevGroup, nextGroup, position }) {
    dispatch({
      type: 'move_card_to_group',
      payload: {
        prevIndex,
        prevGroup,
        nextGroup,
        position,
      },
    });
  }

  function handleMoveCardBegin() {
    setIsDragging(true);
  }

  function handleMoveCardEnd() {
    setIsDragging(false);
    FlowEditor.setActions({
      actions: state.cards.map((card) => card.cardData),
    });
  }

  return (
    <flowCardTypes.Section>
      <flowCardTypes.Title isNonActive={props.actions?.length === 0}>
        {`${i18n.messageFormatter('flow.then')}...`}
      </flowCardTypes.Title>

      <flowCardTypes.Content>
        {contentList.map((item) => {
          if (item.type === 'divider') {
            return (
              <GroupDivider key={`${item.group}-divider`} item={item} isDragging={isDragging} />
            );
          }

          if (item.type === 'dragarea') {
            return (
              <DragArea
                key={`${item.group}-${item.position}`}
                item={item}
                isDragging={isDragging}
                stateRef={stateRef}
                onMoveCardToGroup={handleMoveCardToGroup}
                dragType={dragTypes.FLOW_CARD_ACTION}
              />
            );
          }

          const key = ResourceUtils.getKey(item.cardData);
          const card = props.flowCards.actions.byKey?.[key];

          return (
            <FlowCardAction
              key={`${key}-${item.cardIndex}`}
              cardKey={key}
              cardIndex={item.cardIndex}
              card={card}
              data={item.cardData}
              setFlowCardPickerState={props.setFlowCardPickerState}
              stateRef={stateRef}
              onMoveCard={handleMoveCard}
              onMoveCardBegin={handleMoveCardBegin}
              onMoveCardEnd={handleMoveCardEnd}
            />
          );
        })}
      </flowCardTypes.Content>

      <AddCardButton
        onClick={() => {
          props.setFlowCardPickerState({
            type: 'action',
          });
        }}
      >
        {i18n.messageFormatter('flow.addCard')}
      </AddCardButton>
    </flowCardTypes.Section>
  );
}

// This is a temp solution. Because dragItemRender renders outside of the current tree it does not
// have access to the correct context. This solution makes local tokens unavailable while dragging
// but at least prevents the app from crashing until we have a permanent solution.
const stubContext = { tokensByKey: {} };

function FlowCardAction(props) {
  const ref = useRef();

  function dragItemRender() {
    return (
      <TokenContext.Provider value={stubContext}>
        <NodeArgumentsContext>
          <FlowCard
            cardType="action"
            cardIndex={props.cardIndex}
            card={props.card}
            data={props.data}
            isDraggingClone={true}
          />
        </NodeArgumentsContext>
      </TokenContext.Provider>
    );
  }

  const dragItem = {
    type: dragTypes.FLOW_CARD_ACTION,
    id: props.cardIndex,
    cardIndex: props.cardIndex,
    render: dragItemRender,
  };

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: dragTypes.FLOW_CARD_ACTION,
    item: () => {
      props.onMoveCardBegin();
      return dragItem;
    },
    collect(dragMonitor) {
      return {
        isDragging: dragMonitor.isDragging(),
      };
    },
    end: (dropResult, monitor) => {
      props.onMoveCardEnd();
    },
  });
  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const [, dropRef] = useDrop({
    accept: dragTypes.FLOW_CARD_ACTION,
    canDrop(dragItem, dropMonitor) {
      return false;
    },
    hover(item, dropMonitor) {
      const hoveredCard = props.stateRef.current.cards.find(
        (card) => card.cardIndex === item.cardIndex
      );

      const draggingCard = props.stateRef.current.cards.find(
        (card) => card.cardIndex === dragItem.cardIndex
      );

      if (hoveredCard.cardIndex !== draggingCard.cardIndex) {
        props.onMoveCard({
          prevIndex: hoveredCard.cardIndex,
          prevGroup: hoveredCard.cardData.group,
          nextIndex: draggingCard.cardIndex,
          nextGroup: draggingCard.cardData.group,
        });
      }
    },
  });

  useLayoutEffect(() => {
    dragRef(dropRef(ref));
    return () => {
      dragRef(null);
      dropRef(null);
    };
  }, [dragRef, dropRef, ref]);

  return (
    <NodeArgumentsContext>
      <DraggableFlowCard
        ref={ref}
        cardType="action"
        cardIndex={props.cardIndex}
        card={props.card}
        data={props.data}
        isDragging={isDragging}
        contextMenuContent={
          <FlowCardActionContextMenuContent
            cardIndex={props.cardIndex}
            card={props.card}
            data={props.data}
            onReplaceRequest={() => {
              props.setFlowCardPickerState({
                type: 'action',
                cardIndex: props.cardIndex,
                currentCard: props.card,
              });
            }}
            onDuplicateRequest={() => {
              FlowEditor.duplicateActionCard({
                cardIndex: props.cardIndex,
              });
            }}
            onDurationRequest={() => {
              let value = null;

              if (props.data.duration == null) {
                value = {
                  number: '1',
                  multiplier: 1,
                };
              }

              FlowEditor.updateCardProperty({
                cardType: 'action',
                cardIndex: props.cardIndex,
                property: 'duration',
                value: value,
              });
            }}
            onDelayRequest={() => {
              let value = null;

              if (props.data.delay == null) {
                value = {
                  number: '1',
                  multiplier: 1,
                };
              }

              FlowEditor.updateCardProperty({
                cardType: 'action',
                cardIndex: props.cardIndex,
                property: 'delay',
                value: value,
              });
            }}
            onDeleteRequest={() => {
              FlowEditor.deleteActionCard({
                cardIndex: props.cardIndex,
              });
            }}
          />
        }
      />
    </NodeArgumentsContext>
  );
}

const DraggableFlowCard = styled(FlowCard)`
  opacity: ${(props) => (props.isDragging ? 0.2 : 1)};
`;

function initReducer(actions) {
  const groups = {
    then: [],
    else: [],
  };

  actions.forEach((action, index) => {
    const group = action.group;
    groups[group].push({
      cardIndex: index,
      cardData: action,
    });
  });

  return { groups, cards: [...groups.then, ...groups.else] };
}

function reducer(state, action) {
  switch (action.type) {
    case 'move_card': {
      const { prevIndex, prevGroup, nextIndex, nextGroup } = action.payload;

      if (prevGroup === nextGroup) {
        const prevGroupItems = state.groups[prevGroup];

        const prevGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === prevIndex);
        const nextGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === nextIndex);

        const nextGroupItems = [...prevGroupItems];

        const tmp = {
          ...nextGroupItems[prevGroupIndex],
        };

        nextGroupItems[prevGroupIndex] = {
          ...nextGroupItems[nextGroupIndex],
        };

        nextGroupItems[nextGroupIndex] = tmp;

        const nextGroups = {
          ...state.groups,
          [prevGroup]: nextGroupItems,
        };

        return {
          ...state,
          groups: nextGroups,
          cards: [...nextGroups.then, ...nextGroups.else],
        };
      }

      if (prevGroup !== nextGroup) {
        const prevGroupItems = [...state.groups[prevGroup]];
        const nextGroupItems = [...state.groups[nextGroup]];

        const prevGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === prevIndex);

        const [item] = prevGroupItems.splice(prevGroupIndex, 1);

        nextGroupItems.push({
          ...item,
          cardData: {
            ...item.cardData,
            group: nextGroup,
          },
        });

        const nextGroups = {
          ...state.groups,
          [prevGroup]: prevGroupItems,
          [nextGroup]: nextGroupItems,
        };

        return {
          ...state,
          groups: nextGroups,
          cards: [...nextGroups.then, ...nextGroups.else],
        };
      }

      return state;
    }

    case 'move_card_to_group': {
      const { prevIndex, prevGroup, nextGroup, position } = action.payload;
      const prevGroupItems = [...state.groups[prevGroup]];
      const nextGroupItems = [...state.groups[nextGroup]];

      const prevGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === prevIndex);

      const [item] = prevGroupItems.splice(prevGroupIndex, 1);

      if (position === 'top') {
        nextGroupItems.unshift({
          ...item,
          cardData: {
            ...item.cardData,
            group: nextGroup,
          },
        });
      } else if (position === 'bottom') {
        nextGroupItems.push({
          ...item,
          cardData: {
            ...item.cardData,
            group: nextGroup,
          },
        });
      }

      const nextGroups = {
        ...state.groups,
        [prevGroup]: prevGroupItems,
        [nextGroup]: nextGroupItems,
      };

      return {
        ...state,
        groups: nextGroups,
        cards: [...nextGroups.then, ...nextGroups.else],
      };
    }

    case 'reset':
      return initReducer(action.payload);

    default:
      throw new Error('unknown_action');
  }
}
