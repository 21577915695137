import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { ToastManager } from '../../../ToastManager';
import { AppUtils } from '../../../store/apps/AppUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';
import { useApp } from '../../../store/apps/useApps';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';

import { DialogShiftLock } from '../../../components/overlay/DialogShiftLock';
import { HomeyIcon } from '../../../components/common/HomeyIcon';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { TextareaField } from '../../../components/forms/TextareaField';
import { IconButton } from '../../../components/buttons/IconButton';
import { SpinnerFade } from '../../../components/common/SpinnerFade';
import { Clipboard } from '../../../components/common/Clipboard';
import { RoundIconWrapper } from '../../../components/common/Icon';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconPaperAirplane } from '../../../theme/icons/interface/paper-airplane/paper-airplane';

export function AppSettingsDialogDiagnosticsReport(props) {
  const { i18n } = useI18n();
  const { api } = useApi();
  const { app } = useApp({ appId: props.appId });

  const appBackgroundColor = AppUtils.getBackgroundColor(app);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
    mode: 'onSubmit',
  });

  const [isSending, setIsSending] = useState(false);
  const [result, setResult] = useState(null);

  function handleClosePress() {
    props.onCloseRequest();
  }

  function handleSendPress() {
    if (result != null) {
      props.onCloseRequest();
      return;
    }

    handleSubmit((data) => {
      setIsSending(true);
      const minDuration = new Promise((resolve) => setTimeout(resolve, 2000));

      api.apps
        .getAppStd({
          id: props.appId,
          message: data.message,
        })
        .then(async (result) => {
          await minDuration;
          setResult(result);
        })
        .catch(ToastManager.handleError)
        .finally(() => {
          setIsSending(false);
        });
    })();
  }

  return (
    <DialogShiftLock layoutId={result != null ? '2' : '1'} onClose={props.onCloseRequest}>
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color={appBackgroundColor}>
          <HomeyIcon size="32px" iconObj={app?.iconObj} color={theme.color.white} />
        </RoundIconWrapper>

        <S.Title>{i18n.messageFormatter('settings.app.diagnosticsReport')}</S.Title>

        <S.ViewWrapper>
          {(() => {
            if (result != null) return;

            const minLength = 10;
            return (
              <S.MessageView>
                <S.Info>{i18n.messageFormatter('settings.app.diagnosticsReportHint')}</S.Info>

                <TextareaField
                  register={register}
                  aria-label="Message"
                  name="message"
                  rows={4}
                  orientation="vertical"
                  isDisabled={isSending}
                  minLength={minLength}
                  required={true}
                  placeholder={i18n.messageFormatter('settings.app.diagnosticsReportPlaceholder')}
                  error={errors.message}
                >
                  {errors.message && (
                    <S.FormError>
                      {
                        {
                          minLength: i18n.messageFormatter('common.minLength', { minLength }),
                          required: i18n.messageFormatter('common.required'),
                        }[errors.message.type]
                      }
                    </S.FormError>
                  )}
                </TextareaField>
              </S.MessageView>
            );
          })()}

          {result != null && (
            <S.CodeView>
              <S.Info>{i18n.messageFormatter('settings.app.diagnosticsReportCodeHint')}</S.Info>

              <Clipboard value={result} />
            </S.CodeView>
          )}
        </S.ViewWrapper>

        <S.Actions>
          <GradientButton
            isDisabled={isSending === true}
            styleFlat={true}
            styleColor="blue"
            onPress={handleSendPress}
          >
            <SpinnerFade
              isActive={isSending}
              size={theme.icon.size_small}
              color={theme.color.white}
            >
              {result == null && (
                <GradientButton.Icon
                  url={iconPaperAirplane}
                  size={theme.icon.size_small}
                  color={theme.color.white}
                />
              )}
            </SpinnerFade>
            <GradientButton.Text>
              {isSending
                ? `${i18n.messageFormatter('common.sending')}...`
                : result == null
                ? i18n.messageFormatter('common.send')
                : i18n.messageFormatter('common.done')}
            </GradientButton.Text>
          </GradientButton>
        </S.Actions>
      </S.Root>
    </DialogShiftLock>
  );
}

function S() {}
AppSettingsDialogDiagnosticsReport.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: ${su(4)} ${su(2)} ${su(2)};

  ${TextareaField.Root} {
    width: 100%;
  }
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.ViewWrapper = styled.form`
  width: 100%;
`;

S.MessageView = styled.div``;

S.CodeView = styled.div`
  opacity: 0;
  animation: ${animationFade.in} ${theme.duration.normal} ease-out forwards;
`;

S.Title = styled.h3`
  padding-top: ${su(2)};
`;

S.Info = styled.p`
  text-align: center;
  color: ${theme.color.text_light};
`;

S.Actions = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
  padding-top: ${su(2)};
`;

S.FormError = styled.div`
  position: absolute;
  right: 4px;
  font-size: 12px;
  font-weight: 500;
  bottom: -16px;
  color: ${theme.color.error};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
