import * as Sentry from '@sentry/react';

import { ResourceUtils } from '../ResourceUtils';

export class InsightUtils {
  static getUnits(log) {
    if (log && log.units && typeof log.units.toLowerCase !== 'function') {
      const scope = new Sentry.Scope();
      scope.setContext('log', {
        ...log,
      });
      Sentry.captureMessage('getUnits', scope);
    }

    return log?.units?.toLowerCase?.() ?? null;
  }

  static getOwnerColor(log) {
    return log.color ?? log.uriObj?.color ?? null;
  }

  static compareOwner(collator, firstElement, secondElement) {
    const firstOwner = ResourceUtils.getOwnerName(firstElement);
    const secondOwner = ResourceUtils.getOwnerName(secondElement);
    return collator.compare(firstOwner, secondOwner);
  }

  static compareTitle(collator, firstElement, secondElement) {
    return collator.compare(firstElement.title, secondElement.title);
  }

  static _list = ['homey:manager:apps', 'homey:manager:system'];
  static compareLog(collator, firstElement, secondElement) {
    const firstOwnerUri = ResourceUtils.getOwnerUri(firstElement);
    const secondOwnerUri = ResourceUtils.getOwnerUri(secondElement);

    const isSameOwnerUri = firstOwnerUri === secondOwnerUri;
    const isFirstInList = InsightUtils._list.includes(firstOwnerUri);
    const isSecondInList = InsightUtils._list.includes(secondOwnerUri);

    if (isFirstInList && isSecondInList) {
      return InsightUtils.compareTitle(collator, firstElement, secondElement);
    }

    if (isSameOwnerUri === false && isFirstInList) {
      return 1;
    }

    if (isSameOwnerUri === false && isSecondInList) {
      return -1;
    }

    return (
      InsightUtils.compareOwner(collator, firstElement, secondElement) ||
      InsightUtils.compareTitle(collator, firstElement, secondElement)
    );
  }
}
