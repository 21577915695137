import styled from '@emotion/styled';
import { useTableRowGroup } from 'react-aria';

export function SettingsTableRowGroup(props) {
  const tableRowGroup = useTableRowGroup();

  let Element = props.type;

  if (Element === 'tbody') {
    Element = S.TableBody;
  }

  return <Element {...tableRowGroup.rowGroupProps}>{props.children}</Element>;
}

function S() {}
SettingsTableRowGroup.S = S;

S.TableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`;
