import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useListState, Item } from 'react-stately';
import { useListBox, useOption, useFocusRing, mergeProps } from 'react-aria';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { Icon } from '../../../components/common/Icon';

ZoneIconListBox.Item = Item;

export function ZoneIconListBox({
  children,
  selectionMode = 'single',
  selectedKeys,
  defaultSelectedKeys,
  onSelectionChange,
}) {
  const listRef = useRef();

  const listState = useListState({
    children,
    filter: null,
    selectionMode,
    selectedKeys,
    onSelectionChange,
    defaultSelectedKeys,
  });

  const listBox = useListBox(
    {
      children,
      selectionMode,
      selectedKeys,
      defaultSelectedKeys,
      onSelectionChange,
      'aria-label': 'Icon Options',
    },
    listState,
    listRef
  );

  return (
    <List {...listBox.listBoxProps} ref={listRef}>
      {[...listState.collection].map((item) => (
        <Option key={item.key} item={item} listState={listState} />
      ))}
    </List>
  );
}

function Option({ item, listState }) {
  const optionRef = React.useRef();
  const isDisabled = listState.disabledKeys.has(item.key);
  const isSelected = listState.selectionManager.isSelected(item.key);
  const option = useOption(
    {
      'aria-label': 'Icon Option',
      key: item.key,
      isDisabled,
      isSelected,
    },
    listState,
    optionRef
  );

  const focus = useFocusRing();

  const { icon } = item.props;

  return (
    <ListItem
      {...mergeProps(option.optionProps, focus.focusProps)}
      ref={optionRef}
      isSelected={isSelected}
      isFocusVisible={focus.isFocusVisible}
    >
      <Icon
        url={icon.url}
        color={
          focus.isFocusVisible
            ? 'rgba(255,151,19, 1)'
            : isSelected
            ? theme.color.highlight
            : theme.color.icon_dark
        }
        size={theme.icon.size_medium}
      />
    </ListItem>
  );
}

const List = styled.ul`
  display: grid;
  grid-gap: ${su(1)};
  grid-template-columns: repeat(4, 1fr);
  margin: 0;
  padding: ${su(1)};
  border: 0;
  outline: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${su(1)};
  outline: 0;
  cursor: pointer;
  transition: ${theme.transition.micro};
  border-radius: ${theme.borderRadius.default};
  border: 1px solid transparent;

  &:hover {
    background-color: ${theme.color.background_hover};
    border-color: ${theme.color.line_hover};
  }

  ${(props) =>
    props.isSelected &&
    css`
      &,
      &:hover {
        border-color: ${theme.color.highlight};
        background-color: ${theme.color.highlight_background};
      }
    `}

  ${(props) =>
    props.isFocusVisible &&
    css`
      border-color: rgba(255, 151, 19, 1);
      background-color: rgba(255, 151, 19, 0.05);
    `}
`;
