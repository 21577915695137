import { useId } from 'react-aria';

export function useOverlayTrigger(props, state, ref) {
  // Aria 1.1 supports multiple values for aria-haspopup other than just menus.
  // https://www.w3.org/TR/wai-aria-1.1/#aria-haspopup
  // However, we only add it for menus for now because screen readers often
  // announce it as a menu even for other values.
  let ariaHasPopup = undefined;

  if (props.type === 'menu') {
    ariaHasPopup = true;
  } else if (props.type === 'listbox') {
    ariaHasPopup = 'listbox';
  }

  const overlayId = useId();

  return {
    triggerProps: {
      'aria-haspopup': ariaHasPopup,
      'aria-expanded': state.isOpen,
      'aria-controls': state.isOpen ? overlayId : null,
    },
    overlayProps: {
      id: overlayId,
    },
  };
}
