import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import tinycolor from 'tinycolor2';
import { motion, AnimatePresence } from 'framer-motion';

import { Icon } from '../../common/Icon';

import back_arrow_colorpicker from '../images/color/back-arrow-colorpicker.png';

export function Picker({ color, mode, onSelect }) {
  const [state, setState] = useState({
    depth: 0,
    colors: presetColors[mode],
    selectedColor: color,
  });

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedColor: color,
      };
    });
  }, [color]);

  function handleClick(event, color) {
    if (state.depth === 1) {
      setState((prevState) => {
        return {
          ...prevState,
          selectedColor: color,
        };
      });
      onSelect(color);
      return;
    }

    if (mode === 'temperature') {
      const index = presetColors.temperature.findIndex((item) => {
        return item === color;
      });
      onSelect(1 - index / 8);
      return;
    }

    const colorObj = tinycolor(color);

    const nextColors = [];

    for (let index = 0; index < 7; index++) {
      nextColors.push(colorObj.lighten(5).toHexString());
    }

    setState((prevState) => {
      return {
        ...prevState,
        depth: 1,
        colors: nextColors,
        selectedColor: color,
      };
    });
    onSelect(color);
  }

  return (
    <PickerContainer>
      <AnimatePresence>
        {state.colors.map((color, index) => {
          const rotationIndex = state.depth === 1 ? index - 1 : index - 2;

          const radians = (360 / 8) * rotationIndex * (Math.PI / 180);
          const x = Math.round(100 * Math.cos(radians));
          const y = Math.round(100 * Math.sin(radians));

          return (
            <Button
              onClick={(event) => handleClick(event, color)}
              key={color}
              color={color}
              custom={{ x, y }}
              variants={variants}
              animate="animate"
              exit="exit"
              transition="transition"
            />
          );
        })}
        {state.depth === 1 && (
          <Button
            key="previous-button"
            color="#ffffff"
            custom={{ x: 0, y: -100 }}
            variants={variants}
            animate="animate"
            exit="exit"
            transition="transition"
            onClick={() => {
              setState((prevState) => {
                return {
                  ...prevState,
                  depth: 0,
                  colors: presetColors[mode],
                };
              });
            }}
          >
            <Icon color="#000000" url={back_arrow_colorpicker} />
          </Button>
        )}
        <CenterButton key="center" color={state.selectedColor} />
      </AnimatePresence>
    </PickerContainer>
  );
}

const variants = {
  animate: ({ x, y }) => {
    return {
      x,
      y,
      opacity: 1,
    };
  },
  exit: { x: 0, y: 0, opacity: 0 },
  transition: {
    default: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.2,
    },
  },
};

const PickerContainer = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
`;

const CenterButton = styled.button`
  position: absolute;
  appearance: none;
  top: calc(50% - 34px);
  left: calc(50% - 34px);
  width: 68px;
  height: 68px;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: ${(props) => props.color};
  cursor: pointer;
`;

const Button = styled(motion.button)`
  position: absolute;
  appearance: none;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background-color: ${(props) => props.color};
  cursor: pointer;
`;

const presetColors = {
  color: ['#FF0000', '#FE7023', '#FFC836', '#D4FF42', '#1EFE43', '#12D3FC', '#004EF9', '#E80FF9'],
  temperature: [
    '#FFDB74',
    '#FEDD7F',
    '#FFEAB3',
    '#FFF4D5',
    '#FFFFFF',
    '#F7FFFF',
    '#E4FFFF',
    '#CFFBFE',
  ],
};
