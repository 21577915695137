import { useMemo } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing } from 'react-aria';

import { HomeyImages } from '../../theme/HomeyImages';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useZone, useZonesTree } from '../../store/zones/useZones';

import { theme } from '../../theme/theme';
import { scrollbars } from '../../theme/elements/scrollbars';

import { ListBox, useOptionContext } from '../list-box/ListBox';
import { ListItem } from '../list-box/ListItem';

export function ZoneListBox(props) {
  const { i18n } = useI18n();

  const zones = useZonesTree();

  const items = useZoneItems({ zones });

  function renderItem() {
    return <ZoneItem />;
  }

  return (
    <ZoneListBox.ListBox
      aria-label={i18n.messageFormatter('zone.title')}
      items={items}
      selectedKeys={props.selectedKeys}
      renderItem={renderItem}
      onSelectionChange={props.onSelectionChange}
    />
  );
}

ZoneListBox.ListBox = styled(ListBox)`
  ${scrollbars.dark};
  flex: 1 1 auto;
  width: 300px;
  padding: 10px 0;
  overflow: auto;
`;

function useZoneItems({ zones }) {
  return useMemo(() => {
    return (
      zones.tree?.list.map((zone) => {
        const zoneData = zones.byId[zone.id];

        return {
          key: zone.id,
          type: 'zone',
          textValue: zoneData.name,
          context: {
            zone,
          },
        };
      }) ?? []
    );
  }, [zones.tree, zones.byId]);
}

export function ZoneItem(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  const level = value.context.zone.level ?? 0;
  const { zone = {} } = useZone({ zoneId: item.key });

  return (
    <ListItem.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={{ '--indent-level': level }}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <ListItem.Inner>
        <ListItem.Icon url={HomeyImages.getIconForZone(zone.icon)} size={theme.icon.size_small} />

        <ListItem.Name>{zone.name}</ListItem.Name>
      </ListItem.Inner>
    </ListItem.Root>
  );
}
