import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useApi } from '../../store/HomeyStore';

import { RouteManager } from '../../RouteManager';
import { ToastManager } from '../../ToastManager';
import { LogicUtils } from '../../store/logic/LogicUtils';
import { wait } from '../../lib/wait';

import { typography } from '../../components/primitives/typography';
import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Dialog } from '../../components/overlay/Dialog';
import { IconButton } from '../../components/buttons/IconButton';
import { SolidButton } from '../../components/buttons/SolidButton';
import { TextField } from '../../components/forms/TextField';
import { SelectControllable } from '../../components/forms/select/SelectControllable';
import { RadioGroup } from '../../components/forms/radio/RadioGroup';
import { TypeRadio } from '../../components/forms/radio/TypeRadio';
import { SpinnerFade } from '../../components/common/SpinnerFade';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';
import { iconLogic } from '../../theme/icons/system/logic';
import { iconCheckmark } from '../../theme/icons/interface/checkmark';

export function LogicDialog() {
  const { i18n } = useI18n();
  const [isSaving, setIsSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm({
    defaultValues: {
      type: 'string',
      name: '',
      value: '',
    },
  });

  const { api } = useApi();

  function onSubmit(data, options = {}) {
    setIsSaving(true);
    const values = { ...data };
    values.value = LogicUtils.parseValue(values.type, values.value);

    const waitPromise = wait(500);
    const setPromise = api.logic.createVariable({ variable: values });

    // todo fix catch and then it makes no sense
    Promise.all([waitPromise, setPromise])
      .then(([, result]) => {
        ToastManager.add({
          icon: iconLogic,
          message: i18n.messageFormatter(`logic.createdVariable`, {
            name: result.name,
          }),
        });
        setIsSaving(false);
        if (!options.preventClose) handleClose();
      })
      .catch((error) => {
        ToastManager.handleError(error);
        setIsSaving(false);
      });
  }

  function handleClose() {
    RouteManager.pushState({});
  }

  const watchType = watch('type');

  useEffect(() => {
    if (watchType === 'boolean') {
      reset({
        type: 'boolean',
        name: '',
        value: 'true',
      });
    } else {
      reset({
        type: watchType,
        name: '',
        value: '',
      });
    }
  }, [watchType, reset]);

  return (
    <Dialog onClose={handleClose} isOpen>
      <S.Root>
        <S.Header>
          <typography.h3>{i18n.messageFormatter(`logic.newVariable`)}</typography.h3>

          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClose}
          />
        </S.Header>

        <S.Form>
          <RadioGroup
            name="type"
            aria-label={i18n.messageFormatter(`logic.type`)}
            orientation="horizontal"
            defaultValue="string"
            register={register}
          >
            <TypeRadio type="string" value="string">
              {i18n.messageFormatter(`logic.string`)}
            </TypeRadio>
            <TypeRadio type="number" value="number">
              {i18n.messageFormatter(`logic.number`)}
            </TypeRadio>
            <TypeRadio type="boolean" value="boolean">
              {i18n.messageFormatter(`logic.boolean`)}
            </TypeRadio>
          </RadioGroup>

          <TextField
            autoComplete="off"
            orientation="vertical"
            type="text"
            name="name"
            label={i18n.messageFormatter('common.name')}
            placeholder={i18n.messageFormatter('logic.namePlaceholder')}
            register={register}
            required
            minLength={1}
            error={errors.name}
          />

          {watchType === 'boolean' ? (
            <SelectControllable
              orientation="vertical"
              name="value"
              label={i18n.messageFormatter('common.value')}
              placeholder={i18n.messageFormatter('common.value')}
              defaultValue="true"
              options={[
                { key: 'true', textValue: i18n.messageFormatter('common.true') },
                { key: 'false', textValue: i18n.messageFormatter('common.false') },
              ]}
              required
              error={errors.value}
              control={control}
            />
          ) : (
            <TextField
              autoComplete="off"
              orientation="vertical"
              type={watchType === 'number' ? 'number' : 'text'}
              step={watchType === 'number' ? 'any' : undefined}
              name="value"
              label={i18n.messageFormatter('common.value')}
              placeholder={i18n.messageFormatter(`logic.${watchType}Placeholder`)}
              register={register}
              required
              minLength={1}
              error={errors.value}
            />
          )}
        </S.Form>

        <S.Footer>
          <SolidButton
            styleFlat={true}
            styleWidth="full"
            styleColor="blue"
            isDisabled={isSaving}
            onPress={() => {
              handleSubmit(onSubmit)({ preventClose: false });
            }}
          >
            <SpinnerFade
              isActive={isSaving === true}
              color={theme.color.white}
              size={theme.icon.size_small}
            >
              <SolidButton.Icon
                url={iconCheckmark}
                color={theme.color.white}
                size={theme.icon.size_small}
              />
            </SpinnerFade>
            <SolidButton.Text>
              {/* {isSaving === true
                ? `${i18n.messageFormatter('common.saving')}...`
                : i18n.messageFormatter('common.save')} */}
              {i18n.messageFormatter('common.save')}
            </SolidButton.Text>
          </SolidButton>
        </S.Footer>
      </S.Root>
    </Dialog>
  );
}

function S() {}
LogicDialog.S = S;

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;
  max-width: 320px;
`;

S.Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${su(2, 2, 0, 2)};
`;

S.Form = styled.form`
  padding: ${su(2, 2, 1, 2)};

  ${TextField.Root}, ${SelectControllable.S.Root} {
    margin: ${su(2)} 0;
  }
`;

S.Footer = styled.footer`
  padding: ${su(0, 1, 1, 1)};
`;
