import { keyframes } from '@emotion/react';
import { css } from '@emotion/react';

/**
 * Setup
 * @param direction
 * @param coordinateX
 * @param coordinateY
 * @returns {any}
 */
export function setupAnimationVariables(direction, coordinateX, coordinateY) {
  coordinateX = coordinateX != null ? `${Math.round(coordinateX)}px` : `center`;
  coordinateY = coordinateY != null ? `${Math.round(coordinateY)}px` : `center`;

  // console.log({ direction, coordinateX, coordinateY });

  const directions = {
    top: css`
      --transform-origin: ${coordinateX} bottom;
      --anim-rotateX: 3deg;
    `,
    right: css`
      --transform-origin: left ${coordinateY};
      --anim-rotateY: 3deg;
    `,
    bottom: css`
      --transform-origin: ${coordinateX} top;
      --anim-rotateX: -3deg;
    `,
    left: css`
      --transform-origin: right ${coordinateY};
      --anim-rotateY: -3deg;
    `,
  };

  return directions[direction] != null ? directions[direction] : directions.bottom;
}

export const animationScalePerspFade = {
  setupVars: setupAnimationVariables,
  in: keyframes`
    from {
      opacity: 0;
      transform: scale(0.95) perspective(var(--anim-perspective, 250px)) rotateX(var(--anim-rotateX, 0deg)) rotateY(var(--anim-rotateY, 0deg));
      transform-origin: var(--transform-origin);
    }

    to {
      opacity: 1;
      transform: scale(1) perspective(var(--anim-perspective, 250px)) rotateX(0deg) rotateY(0deg);
      transform-origin: var(--transform-origin);
    }
  `,
  out: keyframes`
    from {
      opacity: 1;
      transform: scale(1) perspective(var(--anim-perspective, 250px)) rotateX(0deg) rotateY(0deg);
      transform-origin: var(--transform-origin);
    }

    to {
      opacity: 0;
      transform: scale(0.95) perspective(var(--anim-perspective, 250px)) rotateX(var(--anim-rotateX, 0deg)) rotateY(var(--anim-rotateY, 0deg));
      transform-origin: var(--transform-origin);
    }
  `,
};
