import { useRef } from 'react';
import styled from '@emotion/styled';

import { mergeProps, useButton, useFocusRing, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { theme } from '../../../theme/theme';

import { Overlay } from '../../../components/overlay/Overlay';
import { Icon } from '../../../components/common/Icon';
import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { TokenListBox } from './TokenListBox';

import { iconTag } from '../../../theme/icons/interface/tag';

export function TokenPicker(props) {
  const triggerRef = useRef();
  const overlayTriggerState = useOverlayTriggerState({
    defaultOpen: props.defaultOpen,
    onOpenChange(isOpen) {
      props.onOpenChange?.(isOpen);
    },
  });
  const overlayTrigger = useOverlayTrigger({ type: 'listbox' }, overlayTriggerState, triggerRef);

  const focusRing = useFocusRing();
  const button = useButton(
    {
      ...overlayTrigger.triggerProps,
      elementType: props.triggerElementType,
      isDisabled: props.isTriggerDisabled,
      onPress: () => {
        overlayTriggerState.toggle();
      },
      onKeyDown(event) {
        if (event.key === 'Delete') {
          event.preventDefault();
          props.onClearRequest?.();
        } else {
          event.continuePropagation();
        }
      },
    },
    triggerRef
  );

  return (
    <>
      {props.renderTrigger ? (
        props.renderTrigger(triggerRef, {
          ...mergeProps(button.buttonProps, focusRing.focusProps),
          'data-is-disabled': props.isTriggerDisabled,
          'data-is-disabled-style': props.isTriggerDisabledStyle,
          // 'data-is-focus-visible': focusRing.isFocusVisible,
        })
      ) : (
        <TokenPicker.Root
          {...button.buttonProps}
          // Not implemented for default button yet
          // data-is-disabled: props.isTriggerDisabled
          // data-is-disabled-style: props.isTriggerDisabledStyle
          ref={triggerRef}
        >
          <Icon
            url={iconTag}
            size={props.triggerIconSize}
            color={
              overlayTriggerState.isOpen
                ? theme.color.highlight
                : props.buttonColor ?? theme.color.icon_light
            }
          />
        </TokenPicker.Root>
      )}

      <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={triggerRef}
              overlayProps={overlayTrigger.overlayProps}
              overlayTriggerState={overlayTriggerState}
              offset={10}
              animationRemainProps={animationRemainProps}
              autoFocus={props.autoFocus ?? true}
              restoreFocus={props.restoreFocus ?? true}
              containFocus={props.containFocus}
            >
              <TokenListBox
                types={props.types}
                onSelect={(...args) => {
                  overlayTriggerState.close();
                  props.onSelect(...args);
                }}
              />
            </Overlay>
          );
        }}
      </AnimationRemain>
    </>
  );
}

TokenPicker.Root = styled.button`
  position: relative;
  align-items: center;
  flex: 0 0 auto;
  padding: 10px;

  ${Icon.S.Root} {
    transition: background-color ${theme.transition.micro_partial};

    &:hover {
      background: ${theme.color.icon_light_hover};
    }
  }
`;
