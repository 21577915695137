export function makeConnectorPath(dimensions) {
  dimensions.height = dimensions.height + dimensions.offset;

  const M = `M0, ${dimensions.offset}`;
  // C x1 y1, x2 y2, x y
  const C1 = `C ${dimensions.width * 0.25},${dimensions.offset} ${dimensions.width * 0.25},${
    dimensions.height
  } ${dimensions.width * 0.5},${dimensions.height}`;
  const C2 = `C ${dimensions.width * 0.75}, ${dimensions.height} ${dimensions.width * 0.75},${
    dimensions.offset
  } ${dimensions.width * 1},${dimensions.offset}`;

  return `${M} ${C1} ${C2}`;
}
