import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useButton, useHover } from 'react-aria';

import { theme } from '../../theme/theme';

import { Icon } from '../../components/common/Icon';
import { Circle } from '../../components/common/Circle';

import { iconCloseCircle } from '../../theme/icons/interface/close-circle';

export function LegendItem(props) {
  const buttonRef = useRef();
  const button = useButton(
    {
      onPress: () => {
        props.onRemoveKeyRequest({
          logKey: props.logKey,
        });
      },
    },
    buttonRef
  );

  const hover = useHover({
    onHoverStart: props.onHoverStart,
    onHoverEnd: props.onHoverEnd,
  });

  return (
    <LegendItem.Root {...hover.hoverProps} style={{ '--hue': props.color.hue }}>
      <LegendItem.Button {...button.buttonProps} ref={buttonRef}>
        <Icon
          url={iconCloseCircle}
          size={theme.icon.size_default}
          color={theme.icon.color_body_light}
        />
      </LegendItem.Button>

      <LegendItem.Owner.Root>
        <LegendItem.Owner.Name>{props.ownerName}</LegendItem.Owner.Name>
        <LegendItem.Owner.ZoneName>{props.ownerZoneName}</LegendItem.Owner.ZoneName>
      </LegendItem.Owner.Root>

      <LegendItem.Log.Root>
        <LegendItem.Log.Circle hue={props.color.hue} />
        <LegendItem.Log.Name>{props.log.title}</LegendItem.Log.Name>
      </LegendItem.Log.Root>
    </LegendItem.Root>
  );
}

LegendItem.Button = styled.button`
  position: absolute;
  right: -8px;
  top: -8px;
  opacity: 0;
  transition: ${theme.transition.micro};

  ${Icon.S.Root} {
    transition: ${theme.transition.micro};

    &:hover {
      background-color: ${theme.color.danger};
      transform: scale(${theme.icon.scale_default});
    }
  }
`;

LegendItem.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: default;
  transition: ${theme.transition.micro};

  &:hover {
    background-color: ${theme.color.mono_010};
    border-color: ${theme.color.line};

    ${LegendItem.Button} {
      opacity: 1;
    }
  }
`;

LegendItem.Owner = {};
LegendItem.Owner.Name = styled.div``;
LegendItem.Owner.ZoneName = styled.div``;
LegendItem.Owner.Root = styled.div`
  display: flex;
  align-items: center;

  ${LegendItem.Owner.Name} {
    font-weight: ${theme.fontWeight.medium};
    margin-right: 5px;
  }

  ${LegendItem.Owner.ZoneName} {
    color: ${theme.color.text_light};
  }
`;

LegendItem.Log = {};
LegendItem.Log.Circle = styled(Circle)``;
LegendItem.Log.Name = styled.div``;
LegendItem.Log.Root = styled.div`
  display: flex;
  align-items: center;

  ${LegendItem.Log.Circle} {
    margin-right: 5px;
  }

  ${LegendItem.Log.Name} {
    margin-left: 5px;
  }
`;
