import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Dialog } from '../../../components/overlay/Dialog';
import { IconButton } from '../../../components/buttons/IconButton';
import { Icon } from '../../../components/common/Icon';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { GradientButton } from '../../../components/buttons/GradientButton';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconExperiments } from '../../../theme/icons/system/experiments/experiments';

export function ExperimentsSettingsDialogEnabled(props) {
  const { i18n } = useI18n();

  function handleClosePress() {
    props.onCloseRequest();
  }

  return (
    <Dialog onClose={props.onCloseRequest}>
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color={theme.color.blue_050}>
          <Icon size="32px" url={iconExperiments} color={theme.color.blue} />
        </RoundIconWrapper>

        <S.Title>
          {i18n.messageFormatter('settings.system.experimentsActivatedExperimentDialogTitle')}
        </S.Title>

        <p>{String(props.result)}</p>

        <S.Actions>
          <GradientButton styleFlat={true} styleColor="blue" onPress={props.onCloseRequest}>
            <GradientButton.Text>
              {i18n.messageFormatter(
                'settings.system.experimentsActivatedExperimentDialogButtonLabel'
              )}
            </GradientButton.Text>
          </GradientButton>
        </S.Actions>
      </S.Root>
    </Dialog>
  );
}

function S() {}
ExperimentsSettingsDialogEnabled.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 440px;
  padding: ${su(4)} ${su(2)} ${su(2)};

  p {
    text-align: center;
    color: ${theme.color.text_light};
  }
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Title = styled.h3`
  padding: ${su(3)} 0 ${su(1)};
`;

S.Actions = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
`;
