import { proxy as color } from "./color.palette";
import { accessorHandler, createNamedProxy } from "../utils";

/**
 * Functional colors of the Homey Design System
 *
 * @description
 * Rules:
 * - Don't define colors directly in here. You can do that in color.palette.js
 * - Don't define component colors in here. This is for global use only
 */
export const func = {
  /* Global */
  highlight:                color.blue,
  highlight_hover:          color.blue_hover,
  highlight_active:         color.blue_active,
  highlight_background:     color.blue_o_10_mono_000_b,
  highlight_component_on:   color.blue_o_10_mono_000_b,
  highlight_border:         color.blue_o_10,

  /* Texts */
  text:                     color.mono_900,
  text_light:               color.mono_400,
  text_light_hover:         color.mono_500,
  text_body_light:          color.mono_500,
  text_highlight:           color.blue,
  text_link:                color.blue,
  text_link_hover:          color.blue_hover,
  text_invert:              color.mono_000,
  text_invert_o_50:         color.white_o_50, // todo replace colors with correct version like: text_white_50
  text_disabled:            color.mono_300,
  text_inactive:            color.mono_200,
  text_white:               color.white,         // `text_white` stays white in DarkMode -> use `text_invert` if you want the opposite
  text_white_o_50:          color.white_o_50,
  text_black:               color.mono_900,       // `text_black` stays black in DarkMode -> use `text` if you want the opposite
  text_black_o_30:          color.black_o_30,
  text_black_o_60:          color.black_o_60,
  text_black_o_70:          color.black_o_70,
  text_black_o_80:          color.black_o_80,

  /* Lines */
  line:                     color.mono_100,
  line_hover:               color.mono_300,
  line_light:               color.mono_020,

  /* Grids */
  grid:                     color.mono_050,

  /* Icons */
  icon_white:               color.white,
  icon_light:               color.mono_200,
  icon_light_hover:         color.mono_300,
  icon_light_active:        color.mono_500,
  icon_light_inactive:      color.mono_050,
  icon_medium:              color.mono_300,
  icon_medium_hover:        color.mono_400,
  icon_dark:                color.mono_800,
  icon_dark_hover:          color.mono_900,
  icon_blue:                color.blue,
  icon_disabled:            color.mono_100,
  /* Icons on body color */
  icon_body_light:          color.mono_200,

  /* Main */
  body:                     color.mono_025, // the background color used in the largest area
  component:                color.mono_000,
  component_on:             color.mono_000,
  component_off:            color.mono_ex030,
  component_line:           color.mono_100,
  overlay:                  color.black_o_50,

  /* Backgrounds */
  background_hover:         color.mono_025,

  /* Scroll */
  scroll_shadow:            color.black_o_10,

  /* Types */
  danger:                   color.red,
  danger_icon_hover:        color.red_o_80,
  danger_text:              color.red,
  danger_background:        color.red_o_05,
  success:                  color.green,
  error:                    color.red,
  warning:                  color.orange,

  /* States */
  state_drop_line:          color.blue,
  state_drop_background:    color.blue_o_05,
  state_droparea_background:color.mono_050,

  state_hover_background:   color.mono_020,

  /* Selection */
  selection_background:     color.blue_o_05,
  selection_border:         color.blue,

  // Selected
  selected_background: color.blue_o_05_mono_000_b, // used for advanced flow cards
  selected_border: color.blue, // used for advanced flow cards
} as const;

export const proxy: typeof func  = createNamedProxy('color', func, accessorHandler);
