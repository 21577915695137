import { useContext } from 'react';

import { I18nFormattersContext } from '../providers/LocaleProvider';

export function useI18n() {
  const context = useContext(I18nFormattersContext);

  if (context === null) {
    throw new Error('useI18n must be used within a LocaleProvider');
  }

  return {
    i18n: context,
  };
}
