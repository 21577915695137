import { b64EncodeUnicode } from './b64EncodeUnicode';

const cache = {};

export function getImageFromCacheAsync({ id, baseSrc, nextSrc }) {
  // console.log({ id, baseSrc, nextSrc });

  if (id.startsWith('data:image')) {
    cache[id] = { src: id };
  }

  // baseSrc = '/img/devices/i-do-not-exist.svg';
  // id = '/img/devices/i-do-not-exist.svg';

  if (cache[id] == null) {
    cache[id] = new Promise(async (resolve, reject) => {
      let response = null;
      let finalError = null;

      try {
        response = await fetch(baseSrc, { headers: { Accept: 'image/*' } });
        if (response.ok === false) {
          finalError = new Error(`${response.status} (${response.statusText}) ${baseSrc}`);
          response = null;
        }
      } catch (error) {
        response = null;
        finalError = error;
      }

      if (response == null && nextSrc != null) {
        try {
          response = await fetch(nextSrc, { headers: { Accept: 'image/*' } });
          if (response.ok === false) {
            finalError = new Error(`${response.status} (${response.statusText}) ${nextSrc}`);
            response = null;
          }
        } catch (error) {
          response = null;
          finalError = error;
        }
      }

      if (response != null) {
        try {
          const text = await response.text();
          const b64 = 'data:image/svg+xml;base64,' + b64EncodeUnicode(text);

          const image = {
            src: b64,
          };

          resolve(image);
          cache[id] = image;

          return;
        } catch (error) {
          console.error(error);
          finalError = error;
        }
      }

      reject(finalError);
    });
  }

  return cache[id];
}

export function getImageFromCacheSync({ id }) {
  return cache[id];
}
