import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { EventEmitter } from 'events';

import type { HomeyAPI } from 'athom-api';

import { HomeyStore } from './HomeyStore';
import { Deferred } from '../lib/defer';

export interface BaseApiStoreState {
  mounts: Set<unknown>;
  timeout: ReturnType<typeof setTimeout> | null;
  api: HomeyAPI | null;
  fetched: boolean;
  retry: (args?: { silent: boolean; skipCache: boolean }) => Promise<void>;
  refresh: (args?: { skipCache: boolean }) => Promise<void>;
}

export type FetchArgs = { api: HomeyAPI; silent: boolean; skipCache: boolean };

export abstract class BaseApiStore<TState extends object> {
  store: ReturnType<BaseApiStore<TState>['createStore']>;
  emitter: EventEmitter;
  apiUnsubscribe!: () => void;
  pending: { [key: string]: Deferred<void> } = {};

  key!: string;
  // TODO disable when devtools is false.
  devtools?: boolean;

  constructor() {
    this.store = this.createStore();
    this.emitter = new EventEmitter();
  }

  private createInitialBaseState() {
    return {
      mounts: new Set(),
      timeout: null,
      api: null,
      fetched: false,
    };
  }

  abstract createInitialState(): TState;

  protected onAttached?({ api }: { api: HomeyAPI }): void;

  abstract fetch(args: FetchArgs): Promise<void>;

  protected abstract destroy(arg: string): void;

  protected onDetached?({ api }: { api: HomeyAPI }): void;

  private selectApi(state: TState & BaseApiStoreState) {
    return {
      api: state.api,
    };
  }

  private onApi({ api }: { api: HomeyAPI | null }) {
    // console.log(`${this.key}:onApi`);
    const state = this.store.getState();

    if (state.api !== api) {
      if (state.api !== null) {
        this.onDetached?.({ api: state.api });
      }
      this.destroy?.('onApi');
      state.api = api;
      state.fetched = false;
    }

    if (api != null) {
      if (state.mounts.size > 0) {
        state.fetched = true;
        this.onAttached?.({ api });
        this.fetch?.({ api, silent: false, skipCache: false }).catch(console.error);
      } else {
        this.store.setState(this.createInitialState());
      }
    } else {
      this.store.setState(this.createInitialState());
    }
  }

  private createStore() {
    const initialState = this.createInitialState();
    const initialBaseState = this.createInitialBaseState();

    const store = create<TState & BaseApiStoreState>()(
      devtools(
        subscribeWithSelector((set, get, api) => {
          return {
            ...initialState,
            ...initialBaseState,
            retry: async (args?: { silent: boolean; skipCache: boolean }) => {
              const { api } = get();

              if (api != null) {
                return this.fetch({
                  silent: args?.silent ?? false,
                  skipCache: args?.skipCache ?? false,
                  api,
                }).catch(console.error);
              }
            },
            refresh: async (args?: { skipCache: boolean }) => {
              const { retry } = get();
              return retry({ silent: true, skipCache: args?.skipCache ?? false });
            },
          };
        }),
        {
          name: this.key,
        }
      )
    );

    this.store = store;

    this.apiUnsubscribe = HomeyStore.store.subscribe(
      this.selectApi.bind(this),
      this.onApi.bind(this),
      {
        equalityFn: shallow,
        fireImmediately: true,
      }
    );

    return store;
  }

  attach(ref: unknown) {
    const state = this.store.getState();
    clearTimeout(state.timeout ?? undefined);
    state.mounts.add(ref);

    if (state.fetched === false && state.api != null) {
      state.fetched = true;
      this.onAttached?.({ api: state.api });
      this.fetch?.({ api: state.api, silent: false, skipCache: false }).catch(console.error);
    }
  }

  detach(ref: unknown) {
    const state = this.store.getState();
    state.mounts.delete(ref);

    if (state.mounts.size === 0) {
      // Destroy after 1 mins.
      state.timeout = setTimeout(() => {
        if (state.api != null) {
          this.onDetached?.({ api: state.api });
        }
        this.destroy?.('detached');
        this.store.setState({
          ...this.createInitialState(),
          fetched: false,
        });
      }, 60 * 1000);
    }
  }

  get() {
    return this.store.getState();
  }

  set(...args: Parameters<typeof this.store.setState>) {
    this.store.setState(...args);
  }

  getApi() {
    const { api } = this.store.getState();
    if (api == null) throw new Error('Missing Api');

    return api;
  }

  async retry(args?: { silent: boolean; skipCache?: boolean }) {
    const state = this.get();
    return state.retry({ silent: args?.silent ?? false, skipCache: args?.skipCache ?? false });
  }

  async refresh(args?: { skipCache?: boolean }) {
    const state = this.get();
    return state.refresh({ skipCache: args?.skipCache ?? false });
  }
}
