import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApp } from '../../../store/apps/useApps';

import { theme } from '../../../theme/theme';

import { Icon } from '../../../components/common/Icon';
import { ExternalLink } from '../../../components/common/link/ExernalLink';

import { AppSettingsStore } from './AppSettingsStore';
import { AppSettingsInfo } from './AppSettingsInfo';
import { AppSettingsActions } from './AppSettingsActions';

import { iconExternalLink } from '../../../theme/icons/interface/external-link';

export function AppSettings(props) {
  const { i18n } = useI18n();
  const { app } = useApp({ appId: props.appId });

  if (app == null) {
    return <div>Missing App</div>;
  }

  let url = `https://homey.app/a/${app.id}`;

  if (app.channel === 'test') {
    url = `${url}/test`;
  }

  return (
    <AppSettingsStore.Provider>
      <S.Root>
        <AppSettingsInfo app={app} />

        <AppSettingsActions app={app} />

        {app.origin === 'appstore' && (
          <S.Link url={url}>
            <Icon
              url={iconExternalLink}
              color={theme.color.text_light}
              size={theme.icon.size_tiny}
            />
            {i18n.messageFormatter('settings.app.viewAppStore')}
          </S.Link>
        )}
      </S.Root>
    </AppSettingsStore.Provider>
  );
}

AppSettings.pathKey = 'apps';

function S() {}
AppSettings.S = S;

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 30px;

  ${AppSettingsInfo.S.Root} {
    margin-bottom: 40px;
  }
`;

S.Link = styled(ExternalLink)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  text-decoration: none;
  outline: 0;
  padding: 30px 0 0;
  color: ${theme.color.text_light};

  &:hover {
    text-decoration: underline;
  }
`;
