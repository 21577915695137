import { useMemo, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';

import { RouteManager } from '../../RouteManager';

import { DeviceUtils } from '../../store/devices/DeviceUtils';

import { ToastManager } from '../../ToastManager';
import { UserMeStore } from '../../store/user-me/UserMeStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useCapabilities, useDevice } from '../../store/devices/useDevices';
import { useUserMe } from '../../store/user-me/useUserMe';

import { getControlComponent } from './getControlComponent';

import { theme } from '../../theme/theme';

import { ControlsBackground } from './ControlsBackground';
import { ControlsMessage } from './ControlsMessage';
import { IconButton } from '../buttons/IconButton';
import { Tabs } from '../tabs/Tabs';
import { ControlsComponentTab } from './ControlsComponentTab';
import { ControlsComponentTabPanel } from './ControlsComponentTabPanel';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';
import { iconSettings } from '../../theme/icons/system/settings/settings';
import { iconNotFavorite } from '../../theme/icons/interface/heart-outline';
import { iconFavorite } from '../../theme/icons/interface/heart-fill';

export function Controls(props) {
  const { i18n } = useI18n();

  const userMe = useUserMe();
  const { device, loading, error } = useDevice({ deviceId: props.deviceId });
  const { capabilities } = useCapabilities({ deviceId: props.deviceId });

  const { components, componentsStartAt } = useComponents({
    device,
  });

  const initialTabKey = components?.[componentsStartAt]?.id ?? null;
  const [selectedTabKey, setSelectedTabKey] = useState(initialTabKey);

  const isFavoriteDevice = DeviceUtils.isFavoriteDevice(device, userMe);

  const instanceRef = useRef({
    prevWarningMessage: null,
    prevDeviceId: null,
  });
  Object.assign(instanceRef.current, { onClosePress: props.onClosePress });

  useEffect(() => {
    setSelectedTabKey(initialTabKey);
  }, [props.deviceId, initialTabKey]);

  // When the device disappears.
  useEffect(() => {
    if (device == null && loading === false && error == null && props.deviceId != null) {
      instanceRef.current.onClosePress();
    }
  }, [device, loading, error, props.deviceId]);

  // When the warning message changes.
  useEffect(() => {
    if (device?.warningMessage == null) {
      instanceRef.current.prevWarningMessage = null;
      return;
    }

    if (instanceRef.current.prevWarningMessage === device.warningMessage) {
      return;
    }

    instanceRef.current.prevWarningMessage = device.warningMessage;

    ToastManager.add({
      type: ToastManager.TYPES.WARNING,
      message: device.warningMessage,
    });
  }, [device?.warningMessage]);

  // When the device changes.
  useEffect(() => {
    if (props.deviceId !== instanceRef.current.prevDeviceId) {
      instanceRef.current.prevDeviceId = props.deviceId;
      instanceRef.current.prevWarningMessage = null;
    }
  }, [props.deviceId]);

  function handleFavoritePress() {
    const onError = (error) => ToastManager.handleError(error);
    const favoriteName = `**${device.name}**`;

    if (isFavoriteDevice) {
      UserMeStore.removeFavoriteDevice({ id: device.id })
        .then(() => {
          ToastManager.add({
            icon: iconNotFavorite,
            iconColor: theme.color.icon_light,
            message: i18n.messageFormatter(`device.removeFavorite`, {
              name: favoriteName,
            }),
          });
        })
        .catch(onError);
      return;
    }

    UserMeStore.addFavoriteDevice({ id: device.id })
      .then(() => {
        ToastManager.add({
          icon: iconFavorite,
          iconColor: theme.color.special_favorite,
          message: i18n.messageFormatter(`device.addFavorite`, {
            name: favoriteName,
          }),
        });
      })
      .catch(onError);
  }

  function handleSettingsPress() {
    RouteManager.toDeviceSettings(props.deviceId);
  }

  function handleTabSelectionChange(key) {
    setSelectedTabKey(key);
  }

  return (
    <>
      <ControlsBackground resetId={device?.id} />

      <AnimatePresence mode="wait">
        <Controls.Root
          aria-hidden={props.deviceId == null}
          key={props.deviceId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Controls.Header>
            <Controls.IconButtonWrapper>
              <IconButton
                url={iconCloseThin}
                color={theme.color.white_o_50}
                isDisabled={props.deviceId == null}
                onPress={props.onClosePress}
              />
            </Controls.IconButtonWrapper>

            <Controls.DeviceName title={device?.name ?? '-'}>
              {device?.name ?? '-'}
            </Controls.DeviceName>

            <Controls.Actions>
              <Controls.IconButtonWrapper>
                <IconButton
                  url={isFavoriteDevice ? iconFavorite : iconNotFavorite}
                  color={theme.color.white_o_50}
                  isDisabled={props.deviceId == null}
                  onPress={handleFavoritePress}
                />
              </Controls.IconButtonWrapper>

              <Controls.IconButtonWrapper>
                <IconButton
                  url={iconSettings}
                  color={theme.color.white_o_50}
                  isDisabled={props.deviceId == null}
                  onPress={handleSettingsPress}
                />
              </Controls.IconButtonWrapper>
            </Controls.Actions>
          </Controls.Header>

          {(() => {
            if (
              components != null &&
              device?.available !== false &&
              device?.ready !== false &&
              device?.ready != null
            ) {
              // https://github.com/adobe/react-spectrum/issues/3104
              if (selectedTabKey == null) return;

              return (
                <Controls.Tabs
                  aria-label="Components"
                  selectedKey={selectedTabKey}
                  onSelectionChange={handleTabSelectionChange}
                  items={components.map((component) => {
                    return {
                      key: component.id,
                      textValue: '-',
                      context: {
                        component,
                      },
                    };
                  })}
                  renderTab={() => {
                    return <ControlsComponentTab />;
                  }}
                  renderTabPanel={({ value }) => {
                    const component = value.context.component;
                    const Component = getControlComponent(component.id);

                    return (
                      <ControlsComponentTabPanel>
                        <Component
                          key={component.id}
                          device={device}
                          capabilities={capabilities}
                          component={component}
                        />
                      </ControlsComponentTabPanel>
                    );
                  }}
                />
              );
            }

            if (
              device != null &&
              (device.available === false || device.ready === false || device.ready == null)
            ) {
              return (
                <ControlsMessage
                  type="warning"
                  title={i18n.messageFormatter('device.unavailable')}
                  message={device.unavailableMessage}
                />
              );
            }

            return (
              device && (
                <ControlsMessage
                  title={i18n.messageFormatter('device.noControls')}
                  message={i18n.messageFormatter('device.noControlsDesc')}
                />
              )
            );
          })()}
        </Controls.Root>
      </AnimatePresence>
    </>
  );
}

function useComponents({ device }) {
  const { components, componentsStartAt } = useMemo(() => {
    if (device == null) {
      return {
        components: null,
        componentsStartAt: null,
      };
    }

    const components = [...(device.ui?.components ?? [])];

    const hasCamera =
      device.images?.some((image) => {
        return image.type === 'camera';
      }) ?? false;

    const hasInsights =
      device.insights?.some((log) => {
        return log.type === 'boolean';
      }) ?? false;

    if (hasCamera) {
      components.unshift({ id: 'camera', capabilities: [] });
    }

    if (hasInsights) {
      components.push({ id: 'timeline', capabilities: [] });
    }

    return {
      // If the devices has a camera that ui component should be the first one.
      componentsStartAt: hasCamera === true ? 0 : device.ui?.componentsStartAt ?? 0,
      components: components.length > 0 ? components : null,
    };
  }, [device]);

  return { components, componentsStartAt };
}

Controls.Root = styled(motion.div)`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  background-color: transparent;
`;

Controls.Tabs = styled(Tabs)`
  flex: 1 1 auto;

  ${Tabs.TabList} {
    justify-content: center;
    padding: 30px 0;
  }
`;

Controls.DeviceName = styled.div`
  margin-left: 30px;
  color: ${theme.color.white};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

Controls.Header = styled.div`
  display: flex;
  flex: 0 0 76px;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
`;

Controls.Actions = styled.div`
  display: flex;
`;

Controls.IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;
