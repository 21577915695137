import styled from '@emotion/styled';

import { WebAppSettingsStore } from '../../../store/web-app-settings/WebAppSettingsStore';

import { useWebAppSettings } from '../../../store/web-app-settings/useWebAppSettings';

import { theme } from '../../../theme/theme';

import { Switch } from '../../../components/forms/Switch';
import { GeneralSettingsSection } from './GeneralSettingsSection';

import { iconWebApp } from '../../../theme/icons-v2/system/web-app/web-app';

export function WebAppSettings() {
  const { settings } = useWebAppSettings();

  return (
    <S.Root>
      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>Developer</GeneralSettingsSection.S.SectionHeading>

        <GeneralSettingsSection.S.SectionView>
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>Inspect Mode</GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              <Switch
                aria-label="Inspect Mode"
                name="inspectMode"
                value={settings.developer.inspect}
                onChange={(value) => {
                  WebAppSettingsStore.set((state) => {
                    return {
                      developer: {
                        ...state.developer,
                        inspect: value,
                      },
                    };
                  });
                }}
              />
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
          <S.Hint>Adds the option to inspect and copy items from the context menu.</S.Hint>
        </GeneralSettingsSection.S.SectionView>
      </GeneralSettingsSection.S.Section>
    </S.Root>
  );
}

WebAppSettings.pathKey = 'system/webapp';
WebAppSettings.icon = iconWebApp;
WebAppSettings.backgroundColor = theme.color.system_appearance;

function S() {}
WebAppSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  flex: 1 0 auto;
`;

S.Hint = styled.p`
  margin: 5px 0 15px;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;
