import { RouteManager } from '../../../RouteManager';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';

export function ApiKeysSettingsContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  async function onAction(key) {
    switch (key) {
      case 'view':
        RouteManager.toPat(props.pat.id);
        break;
      case 'delete':
        RouteManager.toDeletePat(props.pat.id);
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`common.view`)}
        onPress={() => {
          onAction('view');
        }}
      />

      <DeveloperMenu onIdRequest={() => props.pat?.id} onResourceRequest={() => props.pat} />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
