export function getThermostatColorForMode(mode) {
  switch (mode) {
    case 'heat':
      return '#ff7003';
    case 'cool':
      return '#03d3ff';
    default:
      return '#262626';
  }
}

export function getThermostatGradientForMode(mode) {
  switch (mode) {
    case 'heat':
      return {
        start: '#ff7003',
        stop: '#ea4c07',
      };
    case 'cool':
      return {
        start: '#03d3ff',
        stop: '#0791ea',
      };
    default:
      return {
        start: '#131313',
        stop: '#262626',
      };
  }
}

export function getThermostatMode({ mode, measure, target }) {
  if (measure == null || target == null) {
    return 'off';
  }

  if (mode == null && measure < target) {
    return 'heat';
  }

  if (mode === 'heat' && measure < target) {
    return 'heat';
  }

  if (mode === 'cool' && measure > target) {
    return 'cool';
  }

  if (mode === 'auto') {
    if (measure < target) return 'heat';
    if (measure > target) return 'cool';
  }

  return 'off';
}

export function getThermostatColor({ mode, measure, target }) {
  return getThermostatColorForMode(getThermostatMode({ mode, measure, target }));
}
