import React, { useState, useContext, createContext, useEffect, useRef } from 'react';

export const TokenHoverContext = createContext(null);

export function TokenHoverContextProvider(props) {
  const instanceRef = useRef({
    nodes: new Map(),

    register({ nodeId, onHoverStart, onHoverEnd }) {
      if (instanceRef.current.nodes.get(nodeId) != null) {
        throw new Error(`Already registered ${nodeId}`);
      }

      instanceRef.current.nodes.set(nodeId, {
        onHoverEnd,
        onHoverStart,
      });

      return function unregister() {
        instanceRef.current.nodes.delete(nodeId);
      };
    },

    onHoverStart(event, { nodeId, tokeyKey }) {
      const handlers = instanceRef.current.nodes.get(nodeId);
      handlers?.onHoverStart(event);
    },

    onHoverEnd(event, { nodeId, tokeyKey }) {
      const handlers = instanceRef.current.nodes.get(nodeId);
      handlers?.onHoverEnd(event);
    },
  });

  return (
    <TokenHoverContext.Provider value={instanceRef.current}>
      {props.children}
    </TokenHoverContext.Provider>
  );
}

export function useTokenHoverContext() {
  return useContext(TokenHoverContext);
}

export function useTokenChildHover({ nodeId }) {
  const tokenHoverContext = useTokenHoverContext();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const unregister = tokenHoverContext.register({
      nodeId,
      onHoverStart(event) {
        setIsHovered(true);
      },
      onHoverEnd(event) {
        setIsHovered(false);
      },
    });

    return function () {
      unregister();
    };
  }, [tokenHoverContext, nodeId]);

  return { isHovered: isHovered };
}
