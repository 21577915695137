import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { DriverStore } from './DriverStore';
import { useAttach } from '../useAttach';

import { selectBase } from '../selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    data: state.data,
    byKey: state.byKey,
    byUri: state.byUri,
  };
}

function selectByKey(state) {
  return {
    ...selectBase(state),
    byKey: state.byKey,
  };
}

function selectByUri(state) {
  return {
    ...selectBase(state),
    byUri: state.byUri,
  };
}

export function useDrivers() {
  useAttach(DriverStore, 'drivers');

  return DriverStore.store(selectAll, shallow);
}

export function useDriversByKey() {
  useAttach(DriverStore, 'driversByKey');

  return DriverStore.store(selectByKey, shallow);
}

export function useDriversByUri() {
  useAttach(DriverStore, 'driversByUri');

  return DriverStore.store(selectByUri, shallow);
}

export function useDriver({ driverKey }) {
  useAttach(DriverStore, 'driver');

  const select = useCallback(
    (state) => {
      return {
        driver: state.byKey?.[driverKey] ?? null,
        manager: state.manager,
      };
    },
    [driverKey]
  );

  return DriverStore.store(select, shallow);
}

export function useDriversAttach() {
  useAttach(DriverStore, 'useDriversAttach');
}
