import { Global, css } from '@emotion/react';

import { theme } from '../../../theme/theme';

export function StandardFlowGraphic() {
  return (
    <svg width="180px" height="220px" viewBox="0 0 180 220">
      <Global
        styles={css`
          .standard-flow-graphic__arrow-1-b {
            clip-path: inset(0px 0px 20px 0px);
            transition: clip-path 250ms ease-in-out;
            transition-delay: 150ms;
          }

          .is-active-option .standard-flow-graphic__arrow-1-b,
          .standard-flow-graphic-wrapper:hover .standard-flow-graphic__arrow-1-b {
            clip-path: inset(0px 0px 0px 0px);
            transition-delay: 0ms;
          }

          .standard-flow-graphic__arrow-2-b {
            clip-path: inset(0px 0px 20px 0px);
            transition: clip-path 250ms ease-in-out;
            transition-delay: 0ms;
          }

          .is-active-option .standard-flow-graphic__arrow-2-b,
          .standard-flow-graphic-wrapper:hover .standard-flow-graphic__arrow-2-b {
            clip-path: inset(0px 0px 0px 0px);
            transition-delay: 150ms;
          }
        `}
      />
      <defs>
        <rect id="path-1" x="2" y="0" width="88" height="28" rx="4" />
        <filter
          x="-80.7%"
          y="-217.9%"
          width="261.4%"
          height="607.1%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.04 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter3" />
          <feGaussianBlur stdDeviation="22" in="shadowOffsetOuter3" result="shadowBlurOuter3" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.07 0"
            type="matrix"
            in="shadowBlurOuter3"
            result="shadowMatrixOuter3"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
            <feMergeNode in="shadowMatrixOuter3" />
          </feMerge>
        </filter>
        <linearGradient
          x1="102.232566%"
          y1="23.3721299%"
          x2="-2.09600432%"
          y2="133.194829%"
          id="linearGradient-3"
        >
          <stop stopColor="#00BFFF" offset="0%" />
          <stop stopColor="#006AFF" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="102.232566%"
          y1="23.3721299%"
          x2="-2.09600432%"
          y2="133.194829%"
          id="linearGradient-4"
        >
          <stop stopColor={theme.color.line} offset="0%" />
          <stop stopColor={theme.color.line} offset="100%" />
        </linearGradient>
        <rect id="path-5" x="0" y="51" width="92" height="29" rx="4.2" />
        <rect id="path-7" x="2" y="104" width="88" height="28" rx="4" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-674.000000, -488.000000)">
          <g transform="translate(544.000000, 285.000000)">
            <g transform="translate(40.000000, 137.000000)">
              <g transform="translate(134.000000, 100.000000)">
                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
                  <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-1" />
                </g>
                <g id="glyphs-/-interface-/-arrow-down" transform="translate(38.000000, 32.000000)">
                  <path
                    className="standard-flow-graphic__arrow-1-a"
                    fill="url(#linearGradient-4)"
                    d="M14.0370471,9.03863193 C14.4172243,9.41368754 14.4314886,10.0152955 14.0801115,10.4073565 L14.0361911,10.45337 L8.70753016,15.7101992 C8.51936737,15.8959901 8.2649044,16 7.99998224,16 C7.75914391,16 7.52694955,15.9140414 7.34542262,15.7588935 L7.29252659,15.7102902 L1.96380887,10.45337 C1.56795571,10.0637866 1.56756863,9.42868976 1.96295292,9.03863193 C2.33867206,8.66797432 2.93616613,8.65280334 3.33026239,8.99358649 L3.3784343,9.03803834 L7.00246332,12.6129136 L7.00224499,0.996206892 C7.00224499,0.483135082 7.39209606,0.0629580677 7.89114484,0.00646088047 L7.94919603,0.00157134451 L8.00600221,0 C8.53903517,0 8.97741657,0.413198677 9.00804964,0.937508361 L9.00975943,0.996206892 L9.00993436,12.601037 L12.6218192,9.03778852 C12.9828883,8.68243828 13.5478952,8.65485174 13.9406296,8.95481093 L13.990848,8.99579356 L14.0370471,9.03863193 Z"
                  />
                  <path
                    className="standard-flow-graphic__arrow-1-b"
                    fill="url(#linearGradient-3)"
                    d="M14.0370471,9.03863193 C14.4172243,9.41368754 14.4314886,10.0152955 14.0801115,10.4073565 L14.0361911,10.45337 L8.70753016,15.7101992 C8.51936737,15.8959901 8.2649044,16 7.99998224,16 C7.75914391,16 7.52694955,15.9140414 7.34542262,15.7588935 L7.29252659,15.7102902 L1.96380887,10.45337 C1.56795571,10.0637866 1.56756863,9.42868976 1.96295292,9.03863193 C2.33867206,8.66797432 2.93616613,8.65280334 3.33026239,8.99358649 L3.3784343,9.03803834 L7.00246332,12.6129136 L7.00224499,0.996206892 C7.00224499,0.483135082 7.39209606,0.0629580677 7.89114484,0.00646088047 L7.94919603,0.00157134451 L8.00600221,0 C8.53903517,0 8.97741657,0.413198677 9.00804964,0.937508361 L9.00975943,0.996206892 L9.00993436,12.601037 L12.6218192,9.03778852 C12.9828883,8.68243828 13.5478952,8.65485174 13.9406296,8.95481093 L13.990848,8.99579356 L14.0370471,9.03863193 Z"
                  />
                </g>
                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-5" />
                  <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-5" />
                </g>
                <g
                  id="glyphs-/-interface-/-arrow-down-copy"
                  transform="translate(38.000000, 84.000000)"
                >
                  <path
                    className="standard-flow-graphic__arrow-2-a"
                    fill="url(#linearGradient-4)"
                    d="M14.0370471,9.03863193 C14.4172243,9.41368754 14.4314886,10.0152955 14.0801115,10.4073565 L14.0361911,10.45337 L8.70753016,15.7101992 C8.51936737,15.8959901 8.2649044,16 7.99998224,16 C7.75914391,16 7.52694955,15.9140414 7.34542262,15.7588935 L7.29252659,15.7102902 L1.96380887,10.45337 C1.56795571,10.0637866 1.56756863,9.42868976 1.96295292,9.03863193 C2.33867206,8.66797432 2.93616613,8.65280334 3.33026239,8.99358649 L3.3784343,9.03803834 L7.00246332,12.6129136 L7.00224499,0.996206892 C7.00224499,0.483135082 7.39209606,0.0629580677 7.89114484,0.00646088047 L7.94919603,0.00157134451 L8.00600221,0 C8.53903517,0 8.97741657,0.413198677 9.00804964,0.937508361 L9.00975943,0.996206892 L9.00993436,12.601037 L12.6218192,9.03778852 C12.9828883,8.68243828 13.5478952,8.65485174 13.9406296,8.95481093 L13.990848,8.99579356 L14.0370471,9.03863193 Z"
                    id="Path"
                  />
                  <path
                    className="standard-flow-graphic__arrow-2-b"
                    fill="url(#linearGradient-3)"
                    d="M14.0370471,9.03863193 C14.4172243,9.41368754 14.4314886,10.0152955 14.0801115,10.4073565 L14.0361911,10.45337 L8.70753016,15.7101992 C8.51936737,15.8959901 8.2649044,16 7.99998224,16 C7.75914391,16 7.52694955,15.9140414 7.34542262,15.7588935 L7.29252659,15.7102902 L1.96380887,10.45337 C1.56795571,10.0637866 1.56756863,9.42868976 1.96295292,9.03863193 C2.33867206,8.66797432 2.93616613,8.65280334 3.33026239,8.99358649 L3.3784343,9.03803834 L7.00246332,12.6129136 L7.00224499,0.996206892 C7.00224499,0.483135082 7.39209606,0.0629580677 7.89114484,0.00646088047 L7.94919603,0.00157134451 L8.00600221,0 C8.53903517,0 8.97741657,0.413198677 9.00804964,0.937508361 L9.00975943,0.996206892 L9.00993436,12.601037 L12.6218192,9.03778852 C12.9828883,8.68243828 13.5478952,8.65485174 13.9406296,8.95481093 L13.990848,8.99579356 L14.0370471,9.03863193 Z"
                    id="Path"
                  />
                </g>
                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-7" />
                  <use fill={theme.color.component} fillRule="evenodd" xlinkHref="#path-7" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      )
    </svg>
  );
}
