import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useGlobalListeners } from '@react-aria/utils';

import { theme } from '../../../theme/theme';

export function ColorRadial(props) {
  const { addGlobalListener, removeAllGlobalListeners } = useGlobalListeners();

  const colorRadialRef = useRef();
  const colorRadialSelectorRef = useRef();
  const colorRadialCenterRef = useRef();
  const stateRef = useRef({
    hsv: {
      h: props.hue,
      s: props.saturation,
      v: 1,
    },
    baseBoundingClientRect: null,
    isMoving: false,
  });

  function handleOnPointerMove(event) {
    const isUpdateHueOnly = event.shiftKey;
    const isUpdateSaturationOnly = event.metaKey;

    // When mouseup outside viewport window, then cleanup and stop moving.
    if (event.buttons === 0) {
      return cleanup();
    }

    stateRef.current.isMoving = true;

    const baseBoundingClientRect = stateRef.current.baseBoundingClientRect;
    const radius = baseBoundingClientRect.width / 2;

    // Calculate relative mouse position to ColorRadial
    const pickerRelativePage = {
      x: event.pageX - baseBoundingClientRect.x,
      y: event.pageY - baseBoundingClientRect.y,
    };

    // Calculate relative mouse position to the center of ColorRadial
    const pickerCenterRelativePage = {
      x: pickerRelativePage.x - radius,
      y: pickerRelativePage.y - radius,
    };

    const pointerDirection = getPointerDirection(
      0,
      0,
      pickerCenterRelativePage.x,
      pickerCenterRelativePage.y
    );

    let pointerDistance = Math.min(
      Math.round(getPointerDistance(0, 0, pickerCenterRelativePage.x, pickerCenterRelativePage.y)),
      radius
    );

    const hue = Math.abs(pointerDirection - 180);

    // Add limit for pointerDistance when Meta key is used and pointer is in the angle opposite of the current hue.
    const maxAngleRange = 135;
    const minAngle = stateRef.current.hsv && stateRef.current.hsv.h - maxAngleRange;
    const maxAngle = stateRef.current.hsv && stateRef.current.hsv.h + maxAngleRange;
    const hueCorrection =
      minAngle < 0 && hue > 360 + minAngle
        ? -360 + hue
        : maxAngle > 360 && hue < maxAngle - 360
        ? 360 + hue
        : hue;
    const isMaxRange = hueCorrection < minAngle || hueCorrection > maxAngle;

    if (isUpdateSaturationOnly && isMaxRange) {
      pointerDistance = 0;
    }

    const lightness = 100 - (pointerDistance / radius) * 50;
    const distance = Math.max(0, Math.abs(pointerDistance)) * -1;
    const hsvSaturation = Math.min((1 / radius) * pointerDistance, 1);

    if (isUpdateHueOnly) {
      updateSelector({ hue });
      stateRef.current.hsv.h = hue;
    } else if (isUpdateSaturationOnly) {
      updateSelector({ lightness, distance });
      stateRef.current.hsv.s = hsvSaturation;
    } else {
      updateSelector({ hue, lightness, distance });
      // Set HSV color
      stateRef.current.hsv = {
        h: hue,
        s: hsvSaturation,
        v: 1,
      };
    }
  }

  useEffect(() => {
    const hsv = stateRef.current.hsv;
    hsv.h = props.hue * 360;
    hsv.s = props.saturation;

    const hue = hsv.h;
    const lightness = 100 - hsv.s * 50;
    const distance = hsv.s * -125;

    if (stateRef.current.isMoving === false) {
      updateSelector({ hue, lightness, distance });
    }
  }, [props.hue, props.saturation]);

  function cleanup() {
    stateRef.current.isMoving = false;
    removeAllGlobalListeners();
  }

  function updateSelector({ hue, lightness, distance }) {
    // Set Selector Color and Rotation
    if (typeof hue === 'number') {
      colorRadialSelectorRef.current.style.setProperty('--hue', hue);
      colorRadialCenterRef.current.style.setProperty('--rotation', `${hue}deg`);
    }
    // Set Selector Brightness and Position
    if (typeof lightness === 'number' && typeof distance === 'number') {
      colorRadialSelectorRef.current.style.setProperty('--lightness', `${lightness}%`);
      colorRadialSelectorRef.current.style.setProperty('--distance', `${distance}px`);
    }
  }

  function setLightHSV() {
    const hsv = stateRef.current.hsv;
    if (hsv) {
      props.onChange({ hue: hsv.h, saturation: hsv.s });
    }
  }

  function handleSelectorOnPointerDown(event) {
    stateRef.current.baseBoundingClientRect = colorRadialRef.current.getBoundingClientRect();

    handleOnPointerMove(event);

    addGlobalListener(window, 'pointermove', handleOnPointerMove);
    addGlobalListener(window, 'pointerup', handleSelectorOnPointerUp);
  }

  function handleSelectorOnPointerUp(event) {
    cleanup();
    stateRef.current.isMoving = false;
    setLightHSV();
  }

  return (
    <ColorRadial.Root
      ref={colorRadialRef}
      onPointerDown={(event) => handleSelectorOnPointerDown(event)}
    >
      <ColorRadial.Center ref={colorRadialCenterRef}>
        <ColorRadial.Selector
          ref={colorRadialSelectorRef}
          onPointerDown={(event) => handleSelectorOnPointerDown(event)}
          onPointerUp={(event) => handleSelectorOnPointerUp(event)}
        />
      </ColorRadial.Center>
    </ColorRadial.Root>
  );
}

function getPointerDirection(x1, y1, x2, y2) {
  return Math.round((Math.atan2(x2 - y1, y2 - x1) * 180) / Math.PI);
}

function getPointerDistance(x1, y1, x2, y2) {
  let y = x2 - x1;
  let x = y2 - y1;

  return Math.sqrt(x * x + y * y);
}

ColorRadial.Root = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &::before {
    z-index: 0;
    background: conic-gradient(
      hsl(0, 100%, 50%),
      hsl(15, 100%, 50%),
      hsl(30, 100%, 50%),
      hsl(45, 100%, 50%),
      hsl(60, 100%, 50%),
      hsl(75, 100%, 50%),
      hsl(90, 100%, 50%),
      hsl(105, 100%, 50%),
      hsl(120, 100%, 50%),
      hsl(135, 100%, 50%),
      hsl(150, 100%, 50%),
      hsl(165, 100%, 50%),
      hsl(180, 100%, 50%),
      hsl(195, 100%, 50%),
      hsl(210, 100%, 50%),
      hsl(225, 100%, 50%),
      hsl(240, 100%, 50%),
      hsl(255, 100%, 50%),
      hsl(270, 100%, 50%),
      hsl(285, 100%, 50%),
      hsl(300, 100%, 50%),
      hsl(315, 100%, 50%),
      hsl(330, 100%, 50%),
      hsl(345, 100%, 50%),
      hsl(360, 100%, 50%)
    );
  }

  &::after {
    z-index: 10;
    background: radial-gradient(closest-side, ${theme.color.white}, ${theme.color.white_o_0});
  }
`;

ColorRadial.Center = styled.div`
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: rotate(var(--rotation, 0deg));
`;

ColorRadial.Selector = styled.button`
  position: absolute;
  z-index: 30;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: hsl(var(--hue, 0), var(--saturation, 100%), var(--lightness, 0%));
  left: 0;
  top: var(--distance, 0);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 3px ${theme.color.black_o_50}, inset 0 0 3px ${theme.color.black_o_50};
  cursor: grab;
  border: 2px solid ${theme.color.white};
  transition: transform ${theme.duration.fast} ${theme.curve.fastIn};

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &:active:hover {
    transform: translate(-50%, -50%) scale(0.9);
    cursor: grabbing;
  }
`;
