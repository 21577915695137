import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import tinycolor from 'tinycolor2';
import { v4 as uuid } from 'uuid';
import { motion, LayoutGroup } from 'framer-motion';

import { logScale, round } from '../../../lib/math';
import { getColorForTemperature, getColorForHueSaturation } from '../../../lib/capabilities/light';
import { setBackgroundAnimationColor } from '../ControlsBackground';

import { ToastManager } from '../../../ToastManager';
import { useLightState } from '../../../hooks/capabilities/useLightState';
import { useDim } from '../../../hooks/capabilities/useDim';

import { Picker } from './Picker';
import { ModeButton } from './ModeButton';
import { ColorRadial } from './ColorRadial';
import * as Containers from '../Containers';
import { useOnOff } from '../../../hooks/capabilities/useOnOff';
import { su } from '../../../theme/functions/su';
import { TemperatureRadial } from './TemperatureRadial';

export function Color({ device, capabilities, component }) {
  const instance = useRef(`Color:${uuid()}`);

  const { state, setValues } = useLightState({
    ids: component.capabilities,
    capabilities: capabilities,
    initialState: {
      radial_mode: false,
    },
    instanceId: instance.current,
  });

  const dim = useDim({ capabilities });
  const onoff = useOnOff({ capabilities });

  function handleColorChange({ hue, saturation }) {
    const { light_hue, light_saturation } = capabilities;
    Promise.all([
      light_hue?.setValue(round(hue / 360, light_hue.decimals ?? 2), instance.current),
      light_saturation?.setValue(
        round(saturation, light_saturation.decimals ?? 2),
        instance.current
      ),
    ])
      .then(() => {
        setValues({
          light_hue: hue / 360,
          light_saturation: saturation,
        });
      })
      .catch((error) => {
        ToastManager.handleError(error);
      });
  }

  function handleTemperatureChange({ temperature, x }) {
    const { light_temperature } = capabilities;
    Promise.all([
      light_temperature?.setValue(
        round(temperature, light_temperature.decimals ?? 2),
        instance.current
      ),
    ])
      .then(() => {
        setValues({
          light_temperature: temperature,
          light_temperature_x: x,
        });
      })
      .catch((error) => {
        ToastManager.handleError(error);
      });
  }

  function handleModeClick(mode) {
    const prevMode = state.light_mode;
    const prevRadialMode = state.radial_mode;

    const radial_mode = mode === state.light_mode ? !state.radial_mode : state.radial_mode;

    setValues({
      light_mode: mode,
      radial_mode: radial_mode,
    });

    capabilities.light_mode
      ?.setValue(mode, instance.current)
      .then(() => {})
      .catch((error) => {
        setValues({
          light_mode: prevMode,
          radial_mode: prevRadialMode,
        });
        ToastManager.handleError(error);
      });
  }

  const color = getColor({ state });

  useEffect(() => {
    if (color) {
      const start = tinycolor.mix(color, '#262626', 30).toHexString();
      const stop = tinycolor.mix(color, '#131313', 60).toHexString();
      const angle = 180;
      let isOn = onoff.value ?? true;

      if (isOn && dim.value === 0) {
        isOn = false;
      }

      setBackgroundAnimationColor({
        start,
        stop,
        angle,
        opacity: isOn ? logScale(dim.value ?? 1, 0.4, 0.8) : 0,
      });
    }
  }, [color, dim.value, onoff.value]);

  return (
    <Containers.Control>
      <Containers.Select />
      <Containers.Action>
        <LayoutGroup>
          <Color.Root>
            <Color.Picker>
              {state.radial_mode && state.light_mode === 'color' && (
                <ColorRadial
                  hue={state.light_hue}
                  saturation={state.light_saturation}
                  onChange={handleColorChange}
                />
              )}
              {state.radial_mode && state.light_mode === 'temperature' && (
                <TemperatureRadial
                  temperature={state.light_temperature}
                  x={state.light_temperature_x}
                  onChange={handleTemperatureChange}
                />
              )}
              {!state.radial_mode && state.light_mode === 'color' && (
                <Picker
                  mode={state.light_mode}
                  color={color}
                  onSelect={(color) => {
                    const hsv = tinycolor(color).toHsv();
                    handleColorChange({ hue: hsv.h, saturation: hsv.s });
                  }}
                />
              )}
              {!state.radial_mode && state.light_mode === 'temperature' && (
                <Picker
                  mode={state.light_mode}
                  color={color}
                  onSelect={(temperature) => {
                    handleTemperatureChange({ temperature: temperature, x: 0 });
                  }}
                />
              )}
            </Color.Picker>
            <Color.ButtonContainer>
              {state.light_modes?.map((mode) => {
                return (
                  <ModeButton
                    key={mode}
                    mode={mode}
                    size={46}
                    onPress={() => handleModeClick(mode)}
                  >
                    {mode === state.light_mode && (
                      <Color.ModeButtonOutline
                        layoutId="outline"
                        initial={false}
                        //animate={{ borderColor: 'white' }}
                        transition={spring}
                      />
                    )}
                  </ModeButton>
                );
              })}
            </Color.ButtonContainer>
          </Color.Root>
        </LayoutGroup>
      </Containers.Action>
    </Containers.Control>
  );
}

function getColor({ state }) {
  if (state.light_mode === 'color') {
    return getColorForHueSaturation(state).toHexString();
  }

  if (state.light_mode === 'temperature') {
    return getColorForTemperature(state).toHexString();
  }
}

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
};

Color.Root = styled.div``;

Color.Picker = styled.div`
  margin-bottom: ${su(5)};
`;

Color.ModeButtonOutline = styled(motion.div)`
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 2px solid white;
  border-radius: 50%;
`;

Color.ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${su(5)};
`;
