import volume_mute from '../images/button/volume_mute.svg';
import volume_up from '../images/button/volume_up.svg';
import volume_down from '../images/button/volume_down.svg';
import middle from '../images/button/middle.svg';
import windowcoverings_tilt_set_upper from '../images/slider/windowcoverings_tilt_set_upper.png';
import windowcoverings_tilt_set_lower from '../images/slider/windowcoverings_tilt_set_lower.png';
import arrow_up from '../images/button/arrow_up.svg';
import arrow_down from '../images/button/arrow_down.svg';

export function getButtonImage(capabilityId, capability) {
  const [actualId] = capabilityId.split('.');

  const result = {
    url: null,
    useIconObj: false,
    iconObj: null,
    iconUrl: null,
  };

  switch (actualId) {
    case 'volume_mute':
      result.url = volume_mute;
      break;
    case 'volume_up':
      result.url = volume_up;
      break;
    case 'volume_down':
      result.url = volume_down;
      break;
    case 'windowcoverings_tilt_up':
      result.url = windowcoverings_tilt_set_upper;
      break;
    case 'windowcoverings_tilt_down':
      result.url = windowcoverings_tilt_set_lower;
      break;
    case 'channel_up':
      result.url = arrow_up;
      break;
    case 'channel_down':
      result.url = arrow_down;
      break;
    default:
      if (capability?.iconObj) {
        result.url = `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`;
        result.useIconObj = true;
        result.iconObj = capability.iconObj;
        break;
      }

      result.url = middle;
  }

  result.iconUrl = result.url;

  return result;
}
