import React from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';

export function IndicatorNumberStatic({ device, capabilities, capabilityId }) {
  const capability = capabilities[capabilityId];
  const isMeasurePower = capabilityId.startsWith('measure_power');
  const unitsPostFix = capability?.units ? ` ${capability.units}` : '';

  return (
    <IndicatorNumberStatic.Root data-style-color={isMeasurePower === true ? 'green' : undefined}>
      {unitsPostFix}
    </IndicatorNumberStatic.Root>
  );
}

IndicatorNumberStatic.Root = styled.div`
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.bold};
  text-align: right;

  &[data-style-color='green'] {
    color: #0ba95d;
  }
`;
