import { useReducer, useMemo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';

import { CapUtils } from '../../../lib/capabilities/capabilities';
import { getMediaImage } from './getMediaImage';

import { ToastManager } from '../../../ToastManager';

import { IconButton } from '../../buttons/IconButton';
import { theme } from '../../../theme/theme';

const initialState = {
  speaker_shuffle: false,
  speaker_playing: false,
  speaker_repeat: 'none',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

function set(id, value) {
  return {
    type: 'set',
    payload: {
      [id]: value,
    },
  };
}

export function MediaControls({ capabilities, componentCapabilities }) {
  const instance = useRef(`MediaControls:${uuid()}`);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { controlCapabilities, images } = useMemo(() => {
    return [
      'speaker_shuffle',
      'speaker_prev',
      'speaker_playing',
      'speaker_next',
      'speaker_repeat',
    ].reduce(
      (accumulator, capabilityId) => {
        accumulator.controlCapabilities[capabilityId] = capabilities?.[capabilityId] ?? null;
        accumulator.images[capabilityId] = getMediaImage(capabilityId);
        return accumulator;
      },
      { controlCapabilities: {}, images: {} }
    );
  }, [capabilities]);

  useEffect(() => {
    const listeners = [];
    const nextValues = {};

    if (controlCapabilities.speaker_playing) {
      listeners.push(
        controlCapabilities.speaker_playing.onChange(({ value, callerId }) => {
          if (callerId === instance.current) return;
          dispatch(set('speaker_playing', CapUtils.nullAsFalse(value)));
        })
      );

      nextValues.speaker_playing = CapUtils.nullAsFalse(controlCapabilities.speaker_playing.value);
    }

    if (controlCapabilities.speaker_shuffle) {
      listeners.push(
        controlCapabilities.speaker_shuffle.onChange(({ value, callerId }) => {
          if (callerId === instance.current) return;
          dispatch(set('speaker_shuffle', CapUtils.nullAsFalse(value)));
        })
      );

      nextValues.speaker_shuffle = CapUtils.nullAsFalse(controlCapabilities.speaker_shuffle.value);
    }

    if (controlCapabilities.speaker_repeat) {
      listeners.push(
        controlCapabilities.speaker_repeat.onChange(({ value, callerId }) => {
          if (callerId === instance.current) return;
          dispatch(set('speaker_repeat', value ?? 'none'));
        })
      );

      nextValues.speaker_repeat = controlCapabilities.speaker_repeat.value ?? 'none';
    }

    dispatch({ type: 'set', payload: nextValues });

    return function () {
      listeners.forEach((unregister) => unregister());
    };
  }, [controlCapabilities]);

  return (
    <MediaControlsContainer>
      {controlCapabilities.speaker_shuffle && (
        <IconButton
          size="24px"
          color={state.speaker_shuffle ? 'rgba(18, 193, 255, 1)' : theme.color.white}
          url={images.speaker_shuffle}
          onPress={() =>
            handleToggleClick({
              prevValue: state.speaker_shuffle,
              capability: controlCapabilities.speaker_shuffle,
              dispatch,
              instance,
            })
          }
        />
      )}

      {controlCapabilities.speaker_prev && (
        <IconButton
          size="24px"
          color={theme.color.white}
          url={images.speaker_prev}
          onPress={() =>
            handleButtonClick({
              capability: controlCapabilities.speaker_prev,
              instance,
            })
          }
        />
      )}
      {controlCapabilities.speaker_playing && (
        <IconButton
          size="56px"
          color={theme.color.white}
          url={state.speaker_playing ? images.speaker_playing.pause : images.speaker_playing.play}
          onPress={() =>
            handleToggleClick({
              prevValue: state.speaker_playing,
              capability: controlCapabilities.speaker_playing,
              dispatch,
              instance,
            })
          }
        />
      )}
      {controlCapabilities.speaker_next && (
        <IconButton
          size="24px"
          color={theme.color.white}
          url={images.speaker_next}
          onPress={() =>
            handleButtonClick({
              capability: controlCapabilities.speaker_next,
              instance,
            })
          }
        />
      )}
      {controlCapabilities.speaker_repeat && (
        <IconButton
          size="24px"
          url={images.speaker_repeat[state.speaker_repeat]}
          color={state.speaker_repeat !== 'none' ? 'rgba(18, 193, 255, 1)' : theme.color.white}
          onPress={() =>
            handleMultiToggleClick({
              prevValue: state.speaker_repeat,
              capability: controlCapabilities.speaker_repeat,
              dispatch,
              instance,
            })
          }
        />
      )}
    </MediaControlsContainer>
  );
}

function handleToggleClick({ prevValue, capability, dispatch, instance }) {
  const nextValue = !prevValue;
  dispatch(set(capability.id, nextValue));

  capability
    .setValue(nextValue, instance.current)
    .then(({ transactionId, transactionTime, value }) => {
      // dispatch(set(capability.id, value));
    })
    .catch((error) => {
      dispatch(set(capability.id, prevValue));
      ToastManager.handleError(error);
    });
}

function handleMultiToggleClick({ prevValue, capability, dispatch, instance }) {
  const values = capability.values;
  const currentIndex = values.findIndex((value) => value.id === prevValue);
  const nextValue = values[(currentIndex + 1) % values.length].id;
  dispatch(set(capability.id, nextValue));

  capability
    .setValue(nextValue, instance.current)
    .then(({ transactionId, transactionTime, value }) => {
      // dispatch(set(capability.id, value));
    })
    .catch((error) => {
      dispatch(set(capability.id, prevValue));
      ToastManager.handleError(error);
    });
}

function handleButtonClick({ capability, instance }) {
  capability
    .setValue(true, instance.current)
    .then(({ transactionId, transactionTime, value }) => {})
    .catch((error) => {
      ToastManager.handleError(error);
    });
}

const MediaControlsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin-top: 42px;
`;
