import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { NotificationStore } from './NotificationStore';
import { useAttach } from '../useAttach';

import { selectBase, selectLoading } from '../selectors';

function selectById(state) {
  return {
    ...selectBase(state),
    byId: state.byId,
  };
}

export function useNotificationsById() {
  useAttach(NotificationStore, 'notificationsById');

  return NotificationStore.store(selectById, shallow);
}

export function useNotification({ notificationId }) {
  useAttach(NotificationStore, 'notification');

  const select = useCallback(
    (state) => {
      return {
        notification: state.byId?.[notificationId] ?? null,
        manager: state.manager,
        api: state.api,
        loading: state.loading,
        error: state.error,
      };
    },
    [notificationId]
  );

  return NotificationStore.store(select, shallow);
}

export function useNotificationsLoading() {
  useAttach(NotificationStore, 'useNotificationsLoading');

  return NotificationStore.store(selectLoading, shallow);
}

export function useNotificationsAttach() {
  useAttach(NotificationStore, 'useNotificationsAttach');
}
