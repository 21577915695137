export function flattenObject(object: Record<string, unknown>): Record<string, unknown> {
  const flattened: Record<string, unknown> = {};

  for (let prop in object) {
    if (!object.hasOwnProperty(prop)) continue;

    if (typeof object[prop] === 'object') {
      const flatObject = flattenObject(object[prop] as Record<string, unknown>);
      for (let childProp in flatObject) {
        if (!flatObject.hasOwnProperty(childProp)) continue;
        flattened[prop + '.' + childProp] = flatObject[childProp];
      }
    } else {
      flattened[prop] = object[prop];
    }
  }

  return flattened;
}
