import styled from '@emotion/styled';

import { platform } from '../../../../lib/platform';
import { noteColors } from './noteColors';

import { ResourceUtils } from '../../../../store/ResourceUtils';

import { useI18n } from '../../../../hooks/useI18nFormatters';

import { DraggableNode } from './DraggableNode';
import { ContextMenuContent } from '../../../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../../../components/common/context-menu/DeveloperMenu';

import { theme } from '../../../../theme/theme';

import { iconTrashFill } from '../../../../theme/icons/interface/trash-fill/trash-fill';
import { iconCopyDuplicate } from '../../../../theme/icons/interface/copy-duplicate';
import { iconEdit } from '../../../../theme/icons/interface/edit';

export function NoteCardContextMenuContent(props) {
  const { i18n } = useI18n();

  function onAction(key, args) {
    switch (key) {
      case 'edit':
        props.onEditRequest(true);
        break;
      case 'color':
        props.onColorChangeRequest(args.color);
        break;
      case 'copy':
        props.onCopyRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'start':
        props.onStartRequest({ nodeId: props.nodeId });
        break;
      case 'duplicate':
        props.onDuplicateRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'delete':
        props.onDeleteRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.edit')}
        icon={iconEdit}
        onPress={() => onAction('edit')}
      />
      <ContextMenuContent.SubMenu title={i18n.messageFormatter('common.color')}>
        <ContextMenuContent.List>
          <ContextMenuContent.ListItem
            onPress={() => onAction('color', { color: noteColors.yellow })}
          >
            {i18n.messageFormatter('flow.noteCardYellow')}{' '}
            <ColorAttribute data-style-color={noteColors.yellow} />
          </ContextMenuContent.ListItem>
          <ContextMenuContent.ListItem onPress={() => onAction('color', { color: noteColors.red })}>
            {i18n.messageFormatter('flow.noteCardRed')}{' '}
            <ColorAttribute data-style-color={noteColors.red} />
          </ContextMenuContent.ListItem>
          <ContextMenuContent.ListItem
            onPress={() => onAction('color', { color: noteColors.green })}
          >
            {i18n.messageFormatter('flow.noteCardGreen')}{' '}
            <ColorAttribute data-style-color={noteColors.green} />
          </ContextMenuContent.ListItem>
          <ContextMenuContent.ListItem
            onPress={() => onAction('color', { color: noteColors.blue })}
          >
            {i18n.messageFormatter('flow.noteCardBlue')}{' '}
            <ColorAttribute data-style-color={noteColors.blue} />
          </ContextMenuContent.ListItem>
        </ContextMenuContent.List>
      </ContextMenuContent.SubMenu>
      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.copy')}
        hint={`${platform.metaOrCtrlSymbol} + C`}
        onPress={() => {
          onAction('copy');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <DeveloperMenu
        onIdRequest={() => props.card?.id}
        onUriRequest={() => ResourceUtils.getOwnerUri(props.card ?? {})}
        onResourceRequest={() => {
          return {
            card: props.card,
            data: props.data,
            nodeId: props.nodeId,
          };
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}

const ColorAttribute = styled.div`
  width: 20px;
  height: 20px;
  border-radius: ${theme.borderRadius.small};
  background-color: var(--note-context-color);
  border: 1px solid var(--note-context-border-color);

  &[data-style-color='${noteColors.yellow}'] {
    --note-context-color: ${theme.color.note_yellow};
    --note-context-border-color: ${theme.color.note_yellow_selected};
  }

  &[data-style-color='${noteColors.red}'] {
    --note-context-color: ${theme.color.note_red};
    --note-context-border-color: ${theme.color.note_red_selected};
  }

  &[data-style-color='${noteColors.green}'] {
    --note-context-color: ${theme.color.note_green};
    --note-context-border-color: ${theme.color.note_green_selected};
  }

  &[data-style-color='${noteColors.blue}'] {
    --note-context-color: ${theme.color.note_blue};
    --note-context-border-color: ${theme.color.note_blue_selected};
  }
`;
