import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware';

import { BaseStore } from '../BaseStore';

/**
 * @typedef {Object} WebAppSettingsStoreState - creates a new type named 'WebAppSettingsStoreState'
 * @property {Object} developer - todo
 * @property {boolean} developer.inspect - todo
 * @property {Object} theme - todo
 */

// issues because of localStorage
// if you add something in a nested object its undefined because it gets overwritten by localStorage
export class WebAppSettingsStore extends BaseStore {
  static createInitialState() {
    return {
      developer: {
        inspect: false,
      },
      theme: {
        darkMode: false,
        prevDarkMode: null,
        reducedMotion: false,
      },
    };
  }

  static store = create(
    persist(
      devtools(
        (set, get, api) => ({
          ...this.createInitialState(),
        }),
        {
          name: 'app-settings',
        }
      ),
      {
        name: 'app-settings',
        storage: createJSONStorage(() => localStorage),
      }
    )
  );

  static toggleDarkMode() {
    const state = this.get();

    this.set({
      theme: {
        ...state.theme,
        darkMode: !state.theme.darkMode,
      },
    });
  }

  static disableDarkMode() {
    const state = this.get();

    this.set({
      theme: {
        ...state.theme,
        darkMode: false,
        prevDarkMode: state.theme.darkMode,
      },
    });
  }

  static restoreDarkMode() {
    const state = this.get();

    this.set({
      theme: {
        ...state.theme,
        darkMode: state.theme.prevDarkMode,
        prevDarkMode: null,
      },
    });
  }
}

// Was this not to pollute localStorage or something?
export class WebAppSettingsStoreTemp extends BaseStore {
  static createInitialState() {
    return {
      isFullscreen: false,
    };
  }

  static store = create(
    devtools(
      (set, get, api) => ({
        ...this.createInitialState(),
      }),
      { name: 'app-settings-temp' }
    )
  );

  static enableFullscreen() {
    this.set({
      isFullscreen: true,
    });
  }

  static disableFullscreen() {
    this.set({
      isFullscreen: false,
    });
  }
}
