import React from 'react';
import styled from '@emotion/styled';

import { scrollbarDark } from '../../../theme/elements/scrollbars';
import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Overlay } from '../../../components/overlay/Overlay';
import { ListBox } from '../../../components/list-box/ListBox';
import { DeviceSettingsGeneralStatusIndicatorSelectOption } from './DeviceSettingsGeneralStatusIndicatorSelectOption';

export function DeviceSettingsGeneralStatusIndicatorListBoxOverlay(props) {
  const width = props.targetRef.current?.getBoundingClientRect().width ?? 'auto';

  function renderItem() {
    return <DeviceSettingsGeneralStatusIndicatorSelectOption />;
  }

  // Note that if you are adding an exit animation here with animationRemainProps all restore focus
  // behavior will become broken for Select's that are rendered inside another overlay.
  return (
    <Overlay
      targetRef={props.targetRef}
      overlayProps={{}}
      overlayTriggerState={props.selectState}
      offset={5}
      autoFocus={true}
      restoreFocus={true}
      anchorPointer={false}
    >
      <S.ListBox
        {...props.menuProps}
        style={{ width, ...props.overlayStyle }}
        autoFocus={props.selectState.focusStrategy || true}
        disallowEmptySelection={true}
        state={props.selectState}
        renderItem={renderItem}
      />
    </Overlay>
  );
}

function S() {}
DeviceSettingsGeneralStatusIndicatorListBoxOverlay.S = S;

S.ListBox = styled(ListBox)`
  ${scrollbarDark};
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 420px;
  padding: ${su(1, 0)};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  overflow-y: auto;
`;
