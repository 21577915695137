import { RouteManager } from '../../RouteManager';
import { ToastManager } from '../../ToastManager';
import { UserMeStore } from '../../store/user-me/UserMeStore';
import { FlowStore } from '../../store/flow/FlowStore';

import { promptMessage } from '../../components/overlay/DialogPrompt';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useUserMe } from '../../store/user-me/useUserMe';
import { useApi } from '../../store/HomeyStore';
import { useFlow } from '../../store/flow/useFlows';

import { theme } from '../../theme/theme';

import { ContextMenuContent } from '../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../components/common/context-menu/DeveloperMenu';

import { iconHeartFill } from '../../theme/icons/interface/heart-fill';
import { iconHeartOutline } from '../../theme/icons/interface/heart-outline';
import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';
import { iconPlay } from '../../theme/icons/interface/play';
import { iconShare } from '../../theme/icons/interface/share';
import { iconFlow } from '../../theme/icons/system/flow/flow';
import { iconCopyDuplicate } from '../../theme/icons/interface/copy-duplicate';

export function FlowNavigationItemFlowContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { api } = useApi();
  const { flow } = useFlow({ flowId: props.nodeId });

  const userMe = useUserMe();
  const isFavoriteFlow = userMe.favoriteFlows?.includes(flow.id) ?? false;

  const toggleText = flow.enabled
    ? i18n.messageFormatter(`flow.disable`)
    : i18n.messageFormatter(`flow.enable`);

  async function onAction(key) {
    switch (key) {
      case 'trigger':
        try {
          await api.flow.triggerFlow({ id: flow.id });
          ToastManager.add({
            icon: iconFlow,
            message: i18n.messageFormatter(`flow.started`, {
              name: flow.name,
            }),
          });
        } catch (error) {
          ToastManager.handleError(error);
        }

        break;
      case 'toggle':
        FlowStore.toggleEnabled({ flowId: flow.id }).catch(ToastManager.handleError);
        break;

      case 'favorite':
        const favoriteName = `**${flow.name}**`;

        if (isFavoriteFlow) {
          try {
            await UserMeStore.removeFavoriteFlow({ id: flow.id });
            ToastManager.add({
              icon: iconHeartOutline,
              iconColor: theme.icon.color_light,
              message: i18n.messageFormatter(`flow.removeFavorite`, {
                name: favoriteName,
              }),
            });
          } catch (error) {
            ToastManager.handleError(error);
          }
          return;
        }

        try {
          await UserMeStore.addFavoriteFlow({ id: flow.id });
          ToastManager.add({
            icon: iconHeartFill,
            iconColor: theme.color.special_favorite,
            message: i18n.messageFormatter(`flow.addFavorite`, {
              name: favoriteName,
            }),
          });
        } catch (error) {
          ToastManager.handleError(error);
        }

        break;
      case 'share':
        try {
          const shareWindow = window.open();

          const result = await api.flow.shareFlow({ id: flow.id });

          // If it somehow fails.
          if (shareWindow == null) {
            window.location.href = result.url;
            return;
          }

          shareWindow.location.replace(result.url);
        } catch (error) {
          ToastManager.handleError(error);
        }

        break;
      case 'duplicate':
        try {
          const result = await api.flow.createFlow({
            flow: {
              name: `${flow.name} (${i18n.messageFormatter('common.copyNoun')})`,
              folder: flow.folder,
              enabled: flow.enabled,
              trigger: flow.trigger,
              conditions: flow.conditions,
              actions: flow.actions,
            },
          });

          RouteManager.toFlow(result.id, {
            focus: 'name',
          });
        } catch (error) {
          ToastManager.handleError(error);
        }

        break;
      case 'rename':
        props.onRenameRequest();
        break;
      case 'delete':
        try {
          await promptMessage({
            message: i18n.messageFormatter('prompt.delete', { name: flow.name }),
          });

          try {
            await api.flow.deleteFlow({ id: flow.id });
          } catch (error) {
            ToastManager.handleError(error);
          }
        } catch {}

        // props.onDeleteRequest();
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.start')}
        icon={iconPlay}
        isDisabled={!flow.triggerable}
        onPress={() => {
          onAction('trigger');
        }}
      />
      <ContextMenuContent.ListItem
        label={toggleText}
        onPress={() => {
          onAction('toggle');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.rename')}
        onPress={() => {
          onAction('rename');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.favorite')}
        icon={isFavoriteFlow ? iconHeartFill : iconHeartOutline}
        onPress={() => {
          onAction('favorite');
        }}
      />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.share')}
        icon={iconShare}
        onPress={() => {
          onAction('share');
        }}
      />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <DeveloperMenu onIdRequest={() => flow?.id} onResourceRequest={() => flow} />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
