import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import { useHover } from 'react-aria';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { getSensorImages } from './getSensorImages';
import { CapUtils } from '../../../lib/capabilities/capabilities';

import { theme } from '../../../theme/theme';

import { Icon } from '../../common/Icon';
import { HomeyIcon } from '../../common/HomeyIcon';
import { Time } from '../../common/Time';

export function Sensor({ device, capability }) {
  const { i18n } = useI18n();
  const [stateValue, setStateValue] = useState(capability?.value);
  const hover = useHover({});

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value, callerId }) => {
        setStateValue(value);
      });

      setStateValue(capability.value);

      return unregister;
    }
  }, [capability]);

  if (capability == null) return null;

  const image = getSensorImages(capability.id.split('.')[0], capability);

  const formattedValue = CapUtils.getFormattedValue({
    messageFormatter: i18n.messageFormatter,
    capability,
    value: stateValue,
  });

  const alarmActive = capability.id.startsWith('alarm_') && stateValue === true;

  const colorVariants = {
    animating: { color: theme.color.red },
    still: { color: '#ffffff' },
  };

  const colorValueVariants = {
    animating: { color: theme.color.red },
    still: { color: 'rgba(255,255,255,0.5)' },
  };

  const backgroundColorVariants = {
    animating: { backgroundColor: theme.color.red },
    still: { backgroundColor: '#ffffff' },
  };

  const animate = alarmActive ? 'animating' : 'still';

  // Use a transition to make the animation loop infinitely often.
  const transition = alarmActive
    ? {
        repeat: Infinity,
        ease: 'linear',
        repeatType: 'reverse',
        duration: 0.75,
      }
    : {};

  return (
    <SensorContainer {...hover.hoverProps}>
      {image.useIconObj ? (
        <HomeyIcon
          iconObj={capability.iconObj}
          as={motion.span}
          size="52px"
          animate={animate}
          variants={backgroundColorVariants}
          transition={transition}
        />
      ) : (
        <Icon
          as={motion.span}
          size="52px"
          url={stateValue === true ? image.active : image.default}
          animate={animate}
          variants={backgroundColorVariants}
          transition={transition}
        />
      )}

      <Value animate={animate} variants={colorVariants} transition={transition}>
        {formattedValue}
      </Value>

      <motion.div variants={colorValueVariants} animate={animate} transition={transition}>
        {hover.isHovered ? (
          <Time render={(date) => renderTime({ date, i18n })} date={capability.lastUpdated} />
        ) : (
          <Name>{capability.title}</Name>
        )}
      </motion.div>
    </SensorContainer>
  );
}

function renderTime({ date, i18n }) {
  // some wacky stuff going on
  const time = date
    ? i18n.dateFormatter.formatDistanceToNowStrict(date.setMilliseconds(0), {
        addSuffix: true,
      })
    : i18n.messageFormatter('device.sensor.noLastUpdate');

  return <LastChanged>{time}</LastChanged>;
}

const SensorContainer = styled.div`
  display: flex;
  flex: 0 0 45%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const Value = styled(motion.div)`
  margin-top: 14px;
  font-size: ${theme.fontSize.heading2};
  font-weight: ${theme.fontWeight.medium};
  text-align: center;
  line-height: 26px;
`;

const Name = styled.div`
  margin-top: 4px;
  text-align: center;
  height: 32px;
  line-height: 18px;
`;

const LastChanged = styled.div`
  margin-top: 4px;
  text-align: center;
  height: 32px;
  line-height: 18px;
`;
