import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { FlowStore } from './FlowStore';
import { useAttach } from '../useAttach';

import { selectById, selectData, selectBase, selectLoading } from '../selectors';

function selectByFolderId(state) {
  return {
    ...selectBase(state),
    byFolderId: state.byFolderId,
  };
}

function selectByCardOwnerUri(state) {
  return {
    ...selectBase(state),
    byCardOwnerUri: state.byCardOwnerUri,
  };
}

export function useFlowsData() {
  useAttach(FlowStore, 'flowsData');

  return FlowStore.store(selectData, shallow);
}

export function useFlowsById() {
  useAttach(FlowStore, 'flowsById');

  return FlowStore.store(selectById, shallow);
}

export function useFlowsByCardOwnerUri() {
  useAttach(FlowStore, 'flowsByCardOwnerUri');

  return FlowStore.store(selectByCardOwnerUri, shallow);
}

export function useFlowsByFolderId() {
  useAttach(FlowStore, 'flowsByFolderId');

  return FlowStore.store(selectByFolderId, shallow);
}

export function useFlow({ flowId }) {
  useAttach(FlowStore, 'flow');

  const select = useCallback(
    (state) => {
      return {
        flow: state.byId?.[flowId] ?? null,
        manager: state.manager,
        api: state.api,
        loading: state.loading,
        error: state.error,
      };
    },
    [flowId]
  );

  return FlowStore.store(select, shallow);
}

export function useFlowsLoading() {
  useAttach(FlowStore, 'useFlowsLoading');

  return FlowStore.store(selectLoading, shallow);
}

export function useFlowsAttach() {
  useAttach(FlowStore, 'useFlowsAttach');
}
