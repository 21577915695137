import styled from '@emotion/styled';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { createCard } from './createCard';

import { useTitleChunks } from './useTitleChunks';

import { theme } from '../../../theme/theme';

import { Icon } from '../../../components/common/Icon';
import { HomeyIcon } from '../../../components/common/HomeyIcon';

import { FlowCardBase } from './FlowCardBase';
import { Argument, ArgumentBase } from '../flow-arguments/Argument';
import { FlowCardHint } from './FlowCardHint';
import { FlowCardTokensHint } from './FlowCardTokensHint';
import { PremiumLocked } from '../../../components/premium/PremiumLocked';
import { PopularBadge } from '../../../components/badges/PopularBadge';

import { iconTag } from '../../../theme/icons/interface/tag';

export const StaticFlowCard = createCard(StaticFlowCardInner);

export function StaticFlowCardInner(props) {
  const { titleChunks } = useTitleChunks({
    card: props.card,
    data: null,
  });

  const key = ResourceUtils.getKey(props.card);

  function mapper(chunk, index) {
    if (chunk.type === 'text') {
      return <FlowCardBase.Text key={index}>{chunk.value}</FlowCardBase.Text>;
    }

    if (chunk.type === 'argument' || chunk.type === 'droptoken') {
      return (
        <ArgumentBase key={index} data-is-example={true}>
          {chunk.type === 'droptoken' && (
            <Argument.IconWrapper>
              <Icon url={iconTag} size={theme.icon.size_tiny} />
            </Argument.IconWrapper>
          )}
          {chunk.argumentTypeText}
        </ArgumentBase>
      );
    }

    return (
      <ArgumentBase key={index} data-is-example={true}>
        ...
      </ArgumentBase>
    );
  }

  return (
    <StaticFlowCard.Root
      style={{ '--card-width': props.cardWidth }}
      data-key={key}
      data-is-focused={props.isFocused}
      data-is-focus-visible={props.isFocusVisible}
    >
      <FlowCardBase.IconWrapper
        style={{
          '--flow-card-icon-wrapper-background': props.iconBackgroundColor,
        }}
      >
        {(() => {
          if (props.iconComponent != null) {
            return props.iconComponent;
          }

          if (props.iconUrl != null) {
            return (
              <Icon
                url={props.iconUrl}
                color={theme.color.white}
                size={props.iconSize ?? theme.icon.size_medium}
              />
            );
          }

          return (
            <HomeyIcon
              iconObj={props.iconObj}
              color={theme.color.white}
              size={props.iconSize ?? theme.icon.size_default}
            />
          );
        })()}
      </FlowCardBase.IconWrapper>

      <FlowCardBase.Details>
        <FlowCardBase.Header>
          <FlowCardBase.Name>{props.name}</FlowCardBase.Name>
          {props.card.hint && <FlowCardHint hint={props.card.hint} />}
          {props.card.tokens != null && props.card.tokens?.length > 0 && (
            <FlowCardTokensHint tokens={props.card.tokens} />
          )}
        </FlowCardBase.Header>
        <FlowCardBase.Content>{titleChunks.map(mapper)}</FlowCardBase.Content>
      </FlowCardBase.Details>
      {props.card.highlight && <PopularBadge />}

      {props.isLocked && <PremiumLocked zIndex={2} />}
    </StaticFlowCard.Root>
  );
}

StaticFlowCard.Root = styled.div`
  --flow-token-max-width: 240px; // Used in src/containers/flows/flow-arguments/FlowToken.js
  position: relative;
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  display: flex;
  flex: 0 0 auto;
  width: var(--card-width, 340px);
  padding: 10px;
  cursor: pointer;
  outline: 0;
  background: ${theme.flowcard.background};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: ${theme.borderRadius.default};
    box-shadow: ${theme.boxShadow.default};
    opacity: 0;
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: opacity;
    pointer-events: none;
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-focused='true'],
  &:hover {
    &::before {
      opacity: 0.5;
    }
  }
`;
