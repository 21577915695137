import React, { Fragment } from 'react';
import { create } from 'zustand';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuid } from 'uuid';

import { useI18n } from './hooks/useI18nFormatters';

import { theme } from './theme/theme';
import { su } from './theme/functions/su';

import { Icon } from './components/common/Icon';
import { IconButton } from './components/buttons/IconButton';

import { iconWarning } from './theme/icons/interface/warning/warning';
import { iconCloseThin } from './theme/icons/interface/close-thin';

export class ToastManager {
  static MAX = 3;
  static TYPES = {
    DEFAULT: 'default',
    WARNING: 'warning',
    ERROR: 'error',
  };
  static store = create((set, get, api) => ({
    list: [],
  }));

  static get state() {
    return ToastManager.store.getState();
  }

  static set(state) {
    ToastManager.store.setState(state);
  }

  static add({
    type = ToastManager.TYPES.DEFAULT,
    icon,
    iconColor,
    message,
    i18n,
    timeout = 5000,
  }) {
    const { list: prevList } = ToastManager.state;

    const id = uuid();
    const nextItem = {
      id: id,
      type,
      icon,
      iconColor,
      message,
      i18n,
      timeout: setTimeout(() => {
        const { list } = ToastManager.state;
        const index = list.findIndex((toast) => toast.id === id);

        if (index >= 0) {
          const nextList = [...list];
          nextList.splice(index, 1);
          ToastManager.set({ list: nextList });
        }
      }, timeout),
    };

    if (prevList.length >= ToastManager.MAX) {
      const deleteCount = (prevList.length % ToastManager.MAX) + 1;
      const nextList = [...prevList];
      const removedItems = nextList.splice(0, deleteCount);
      removedItems.forEach((item) => {
        clearTimeout(item.timeout);
      });

      nextList.push(nextItem);

      ToastManager.set({ list: nextList });
      return;
    }

    const nextList = [...prevList, nextItem];
    ToastManager.set({ list: nextList });
  }

  static remove(index) {
    const { list } = ToastManager.state;
    const nextList = [...list];
    const [removed] = nextList.splice(index, 1);
    if (removed != null) clearTimeout(removed.timeout);
    ToastManager.set({ list: nextList });
  }

  static addError(options) {
    let message = options.message ?? '';

    // console.log(options.error.name)
    // console.log(options.error.cause)
    // console.log(options.error.code)
    // console.log(options.error.message)
    //
    // console.log(options.error.cause.error) // === name
    // console.log(options.error.cause.error_description) // === message
    // console.log(options.error.cause.code) // === code
    // console.log(options.error.cause.$stack)

    // TODO: parse more error possibilities
    if (options.error) {
      if (options.error.cause?.error) {
        message = options.error.cause?.error;
      } else if (options.error.message?.length > 0) {
        message = options.error.message;
      } else if (options.error.name) {
        message = options.error.name;
      } else if (options.error.error_description) {
        message = options.error.error_description;
      }
    }

    ToastManager.add({
      icon: iconWarning,
      iconColor: theme.color.white,
      ...options,
      message,
      type: ToastManager.TYPES.ERROR,
    });
  }

  static handleError(error) {
    console.error(error);
    return ToastManager.addError({ error });
  }
}

const selectList = (state) => state.list;

export function Toasts() {
  const list = ToastManager.store(selectList);
  const { i18n } = useI18n();

  return (
    <Toasts.Root>
      <Toasts.List>
        <AnimatePresence initial={false}>
          {list.map((item, index) => {
            let message = item.message;

            if (item.i18n != null) {
              message = i18n.messageFormatter(item.i18n.key, item.i18n.props);
            }

            return (
              <Toast.Root
                key={item.id}
                type={item.type}
                layout
                data-style-type={item.type}
                initial={{ opacity: 0, y: 50, scale: 0.9 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.2 }}
              >
                {item.icon && (
                  <Toast.Icon>
                    <Icon url={item.icon} color={item.iconColor ?? theme.color.icon_dark} />
                  </Toast.Icon>
                )}

                <Toast.Message>{mapMessage(message)}</Toast.Message>
                <Toast.CloseWrapper>
                  <IconButton
                    url={iconCloseThin}
                    size={theme.icon.size_tiny}
                    onPress={() => ToastManager.remove(index)}
                  />
                </Toast.CloseWrapper>
              </Toast.Root>
            );
          })}
        </AnimatePresence>
      </Toasts.List>
    </Toasts.Root>
  );
}

export function mapMessage(message) {
  return (
    message?.split('**').map((part, index) => {
      if (index % 2 === 1) return <strong key={index}>{part}</strong>;
      return <Fragment key={index}>{part}</Fragment>;
    }) ?? ''
  );
}

Toasts.Root = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${theme.zIndex.toasts};
`;

Toasts.List = styled.ul`
  li {
    margin-bottom: 10px;
  }
`;

const Toast = {};

Toast.Icon = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

Toast.Message = styled.div`
  flex: 1 1 auto;
  white-space: pre-wrap;
`;

Toast.CloseWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${su(1)};
  transform: translateY(-50%);

  ${IconButton.S.Root} {
    padding: 10px;

    &:hover {
      ${IconButton.Icon.S.Root} {
        background-color: ${theme.icon.color_light_hover};
      }
    }
  }
`;

Toast.Root = styled(motion.li)`
  display: flex;
  position: relative;
  align-items: center;
  min-width: 400px;
  max-width: 400px;
  padding: ${su(2, 4, 2, 2)};
  box-shadow: ${theme.boxShadow.default};
  border-radius: 10px;
  background-color: ${theme.color.component};
  color: ${theme.color.text};

  &[data-style-type='${ToastManager.TYPES.ERROR}'] {
    background-color: ${theme.color.danger};
    color: ${theme.color.white};

    ${Toast.CloseWrapper} {
      ${IconButton.S.Root} {
        ${IconButton.Icon.S.Root} {
          background-color: ${theme.icon.color_white};
        }

        &:hover ${IconButton.Icon.S.Root} {
          background-color: ${theme.icon.color_white};
        }
      }
    }
  }

  &[data-style-type='${ToastManager.TYPES.WARNING}'] {
    background-color: ${theme.color.warning};
    color: ${theme.color.white};

    ${Toast.CloseWrapper} {
      ${IconButton.S.Root} {
        ${IconButton.Icon.S.Root} {
          background-color: ${theme.icon.color_white};
        }

        &:hover ${IconButton.Icon.S.Root} {
          background-color: ${theme.icon.color_white};
        }
      }
    }
  }
`;
