import { useRef } from 'react';
import { useDrop } from 'react-dnd';

import { dragTypes } from '../dnd/dragTypes';

export function useTreeItemDrop({ type, nodeId, nodeType, treeContext }) {
  const ref = useRef({});
  ref.current.onNodeDrop = treeContext.onNodeDrop;

  const [{ isDragOver, canDrop }, dropRef] = useDrop(() => {
    const accept = type === dragTypes.FOLDER ? [dragTypes.FOLDER, dragTypes.ITEM] : [];
    return {
      accept: accept,
      collect: (dropMonitor) => {
        return {
          isDragOver: dropMonitor.isOver({ shallow: true }),
          canDrop: dropMonitor.canDrop(),
        };
      },
      canDrop(dragItem, dropMonitor) {
        const isSame = dragItem.id === nodeId;

        if (isSame) {
          return false;
        }

        const parents = [];
        let parent = treeContext.getParent(nodeId);
        while (parent != null) {
          parents.push(parent);
          parent = treeContext.getParent(parent);
        }

        const type = dropMonitor.getItemType();

        if (type === dragTypes.FOLDER && parents.includes(dragItem.id)) {
          return false;
        }

        return true;
      },
      hover(item, monitor) {},
      drop(dragItem, monitor) {
        if (monitor.didDrop()) return;

        ref.current.onNodeDrop({
          dropItem: {
            id: dragItem.id,
            nodeType: dragItem.nodeType,
            type: monitor.getItemType(),
          },
          dropTarget: {
            id: nodeId,
            nodeType: nodeType,
            type: type,
          },
        });
      },
    };
  }, [treeContext.getParent, nodeId, nodeType, type]);

  return { isDragOver, canDrop, dropRef };
}
