import { useRef } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing, useTableCell } from 'react-aria';

import { SCOPES } from '../../../lib/scopes';
import { ToastManager } from '../../../ToastManager';

import { useApi } from '../../../store/HomeyStore';
import { useUserMe } from '../../../store/user-me/useUserMe';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useCellContext } from './SettingsTable';

import { theme } from '../../../theme/theme';

import { Select } from '../../../components/forms/select/Select';
import { Switch } from '../../../components/forms/Switch';
import { Icon } from '../../../components/common/Icon';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';

import { FamilySettingsTableUsersContextMenuContent } from './FamilySettingsTableUsersContextMenuContent';
import { FamilySettingsTableInvitesContextMenuContent } from './FamilySettingsTableInvitesContextMenuContent';

import { iconAccount } from '../../../theme/icons/interface/account';

export function FamilySettingsTableUsersCell(props) {
  const { api, scopes } = useApi();
  const { i18n } = useI18n();
  const { cell, row, state } = useCellContext();
  const ref = useRef();
  const tableCell = useTableCell({ node: cell }, state, ref);
  const focusRing = useFocusRing();

  const { me } = useUserMe();

  const options = [
    {
      id: 'manager',
      textValue: i18n.messageFormatter('common.manager'),
      description: i18n.messageFormatter('settings.system.usersRoleOptionHintManager'),
    },
    {
      id: 'user',
      textValue: i18n.messageFormatter('common.user'),
      description: i18n.messageFormatter('settings.system.usersRoleOptionHintUser'),
    },
    {
      id: 'guest',
      textValue: i18n.messageFormatter('common.guest'),
      description: i18n.messageFormatter('settings.system.usersRoleOptionHintGuest'),
    },
  ];

  function getContent() {
    switch (cell.column.key) {
      case 'name':
        if (props.type === 'invite') {
          return (
            <S.NameCell data-type="invite">
              <RoundIconWrapper size="24px" color={theme.color.orange_0_20}>
                <Icon size="12px" url={iconAccount} color={theme.color.orange} />
              </RoundIconWrapper>

              <S.Name>{cell.textValue}</S.Name>
            </S.NameCell>
          );
        }

        return (
          <S.NameCell>
            <S.Avatar src={row.value.avatar} />

            <S.Name title={cell.textValue}>{cell.textValue}</S.Name>
          </S.NameCell>
        );

      case 'role':
        if (cell.props.value === 'owner') {
          return i18n.messageFormatter('common.owner');
        }

        // Only owner can change roles.
        if (scopes[SCOPES.USER] !== true) {
          return (() => {
            switch (cell.props.value) {
              case 'manager':
                return i18n.messageFormatter('common.manager');
              case 'user':
                return i18n.messageFormatter('common.user');
              case 'guest':
                return i18n.messageFormatter('common.guest');
              default:
                return null;
            }
          })();
        }

        return (
          <Select
            styleFlat={true}
            styleOption="descriptive"
            overlayStyle={{ width: '320px' }}
            name="role"
            aria-label="Role"
            options={options}
            defaultValue={cell.props.value}
            onChange={(value) => {
              api.users
                .updateUser({
                  id: row.value.id,
                  user: {
                    role: value,
                  },
                })
                .then(() => {})
                .catch(ToastManager.handleError);
            }}
          />
        );

      case 'enabled':
        return (
          row.value.role !== 'owner' && (
            <Switch
              name="enabled"
              aria-label="Enabled"
              defaultValue={cell.props.value}
              isDisabled={scopes[SCOPES.USER] !== true}
              onChange={(value) => {
                api.users
                  .updateUser({
                    id: row.value.id,
                    user: {
                      enabled: value,
                    },
                  })
                  .then(() => {})
                  .catch(ToastManager.handleError);
              }}
            />
          )
        );

      case 'actions':
        const isDisabled = scopes[SCOPES.USER] !== true && row.value.id !== me?.id;

        return (
          <S.ActionsCell>
            <ContextMenu
              content={
                props.type === 'invite' ? (
                  <FamilySettingsTableInvitesContextMenuContent userId={row.value.id} />
                ) : (
                  <FamilySettingsTableUsersContextMenuContent userId={row.value.id} />
                )
              }
            >
              {({ isOpen, onContextMenu }) => {
                return (
                  <ContextMenuButton
                    isOpen={isOpen}
                    onPress={onContextMenu}
                    isDisabled={isDisabled}
                  />
                );
              }}
            </ContextMenu>
          </S.ActionsCell>
        );

      default:
        return cell.textValue;
    }
  }

  return (
    <S.TableCell {...mergeProps(tableCell.gridCellProps, focusRing.focusProps)} ref={ref}>
      {getContent()}
    </S.TableCell>
  );
}

function S() {}
FamilySettingsTableUsersCell.S = S;

S.TableCell = styled.td`
  height: 50px;
  outline: none;
  cursor: default;
  background-color: ${theme.color.component};
`;

S.NameCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  &[data-type='invite'] {
    color: ${theme.color.orange};
  }
`;

S.ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

S.Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

S.Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
