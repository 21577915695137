import { keyframes } from '@emotion/react';

export const animationFade = {
  in: keyframes`
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  `,
  out: keyframes`
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  `,
};
