import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { showForLarge } from '../../../theme/classes/responsive';

import { SpinnerLoading } from '../../../components/common/SpinnerLoading';
import { MonoButton } from '../../../components/buttons/MonoButton';

import { iconCheckmark } from '../../../theme/icons/interface/checkmark';

export function SaveButton(props) {
  const { i18n } = useI18n();

  return (
    <MonoButton
      styleColor="blue"
      isDisabled={props.hasUnsavedChanges === false || props.isSaving}
      onPress={props.onPress}
    >
      <SaveButton.AnimationContainer data-is-saving={props.isSaving}>
        <SaveButton.IconWrapper>
          <MonoButton.Icon url={iconCheckmark} size={theme.icon.size_small} />
        </SaveButton.IconWrapper>
        <SaveButton.SpinnerWrapper>
          <SpinnerLoading color={theme.icon.color_light} size="16px" />
        </SaveButton.SpinnerWrapper>
      </SaveButton.AnimationContainer>
      <MonoButton.Text className={showForLarge}>
        {i18n.messageFormatter('common.save')}
      </MonoButton.Text>
    </MonoButton>
  );
}

SaveButton.SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: opacity;
`;

SaveButton.IconWrapper = styled.div`
  opacity: 1;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: opacity;
`;

SaveButton.AnimationContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  &[data-is-saving='true'] {
    ${SaveButton.SpinnerWrapper} {
      opacity: 1;
    }

    ${SaveButton.IconWrapper} {
      opacity: 0;
    }
  }
`;
