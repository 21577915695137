export class ZoneUtils {
  static getPathForZone(zones, zone) {
    const path = [];
    let currentZone = zone;

    while (currentZone.parent != null) {
      path.unshift(currentZone.parent);
      currentZone = zones[currentZone.parent];
    }

    return path;
  }
}
