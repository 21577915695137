import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';
import { formatBytes } from '../../../lib/formatBytes';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

type MemoryInfoItem = { name: string; size: number };
type MemoryInfo = {
  free: number;
  total: number;
  swap?: number;
  types: { [key: string]: MemoryInfoItem };
};

interface State {
  memoryInfoData: object | null;
  free: number;
  freeFormatted: string;
  total: number;
  totalFormatted: string;
  swap: number | null;
  swapFormatted: string | null;
  items: (MemoryInfoItem & { key: string; sizeFormatted: string; color: string })[];
  isLoading: boolean;
  error: unknown;
}

type GeneralSettingsInfoMemoryStoreState = State & BaseApiStoreState;

function getColorForIndex(index: number) {
  let hue = index * 50;
  hue += 150;
  hue %= 360;

  return `hsl(${hue}, 80%, 70%)`;
}

class GeneralSettingsInfoMemoryStore extends BaseApiStore<State> {
  key = 'general-settings-memory-info';

  override createInitialState() {
    return {
      memoryInfoData: null,
      free: 0,
      freeFormatted: '',
      total: 0,
      totalFormatted: '',
      swap: null,
      swapFormatted: null,
      items: [],
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:memoryInfo');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerSystem = api.system;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const memoryInfoPromise = managerSystem.getMemoryInfo() as unknown as Promise<MemoryInfo>;

      const [, memoryInfoData] = await Promise.all([waitPromise, memoryInfoPromise]);

      const free = memoryInfoData.free ?? 0;
      const total = memoryInfoData.total ?? 0;
      let swap = memoryInfoData.swap ?? null;

      if (swap === 0) {
        swap = null;
      }

      const items = Object.entries(memoryInfoData.types ?? {})
        .sort(([, a], [, b]) => b.size - a.size)
        .map(([key, value], index) => {
          return {
            ...value,
            key,
            sizeFormatted: formatBytes(value.size),
            color: getColorForIndex(index),
          };
        });

      this.set({
        ...this.createInitialState(),
        memoryInfoData: memoryInfoData,
        free,
        freeFormatted: formatBytes(free),
        total,
        totalFormatted: formatBytes(total),
        swap,
        swapFormatted: swap !== null ? formatBytes(swap) : null,
        items,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, 'general-settings-info-memory');

    return { generalSettingsInfoMemory: this.store(selectAll, shallow) };
  }
}

function selectAll(state: GeneralSettingsInfoMemoryStoreState) {
  return {
    ...selectBase(state),
    memoryInfoData: state.memoryInfoData,
    free: state.free,
    freeFormatted: state.freeFormatted,
    total: state.total,
    totalFormatted: state.totalFormatted,
    swap: state.swap,
    swapFormatted: state.swapFormatted,
    items: state.items,
  };
}

export const generalSettingsInfoMemoryStore = new GeneralSettingsInfoMemoryStore();
