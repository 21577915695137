import React from 'react';
import styled from '@emotion/styled';

import { useTooltipTriggerStateAlwaysWarmUp } from '../../../../components/common/tooltip/useTooltipTriggerStateAlwaysWarmUp';
import { useTooltipTriggerCustom } from '../../../../components/common/tooltip/useTooltipTriggerCustom';

import { theme } from '../../../../theme/theme';

import { executionStateType } from './executionStateType';

import { CardResult } from './CardResult';
import { Tooltip } from '../../../../components/common/tooltip/Tooltip';

const gradients = {
  red: 'linear-gradient(45deg, #D81C1D, #FF4444)',
  blue: 'linear-gradient(45deg, rgb(54, 124, 255), rgb(82, 222, 255))',
  yellow: 'linear-gradient(45deg, rgb(247, 181, 0), rgb(252, 219, 0))',
};

export function CardProgress(props) {
  return (
    <Tooltip
      triggerStateHook={useTooltipTriggerStateAlwaysWarmUp}
      triggerHook={useTooltipTriggerCustom}
      offset={20}
      anchorPointer={true}
      placement="bottom"
      delay={100}
      isDisabled={props.executionState.result == null}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <CardProgress.Root
            {...triggerProps}
            ref={triggerRef}
            style={{
              '--transition-duration': `${props.executionState.duration ?? 0}ms`,
            }}
            data-state-type={props.executionState.type}
            data-is-selected={props.isSelected}
            data-is-card-hovered={props.isCardHovered}
          >
            <CardProgress.Positioner>
              <CardProgress.Bar />
            </CardProgress.Positioner>
          </CardProgress.Root>
        );
      }}
    >
      <CardResult
        nodeId={props.nodeId}
        executionState={props.executionState}
        card={props.card}
        data={props.data}
      />
    </Tooltip>
  );
}

CardProgress.Bar = styled.div`
  width: 100%;
  height: 10px;
  background: ${gradients.blue};

  transform: translateX(var(--translate-x, 0));
  transition-property: var(--transition-properties, none);
  transition-duration: var(--transition-duration);
  transition-timing-function: ${theme.curve.fastIn};
`;

CardProgress.Positioner = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  bottom: 0;
  pointer-events: none;

  transition: ${theme.duration.micro} ${theme.curve.easeInOut};
  transition-property: transform;
`;

CardProgress.Root = styled.div`
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  overflow: hidden;
  border-radius: 0 0 10px 10px;

  ${CardProgress.Positioner} {
    transform: translateY(8px);
  }

  &[data-is-card-hovered='true'] {
    ${CardProgress.Positioner} {
      transform: translateY(6px);
    }
  }

  &[data-state-type='${executionStateType.idle}'],
  &[data-state-type='${executionStateType.prestart}'],
  &[data-state-type='${executionStateType.restart}'],
  &[data-state-type='${executionStateType.unreachable}'] {
    ${CardProgress.Bar} {
      --translate-x: -100%;
    }
  }

  &[data-state-type='${executionStateType.start}'] {
    ${CardProgress.Bar} {
      background: ${gradients.blue};
      --translate-x: -50%;
      --transition-properties: transform, background;
    }
  }

  &[data-state-type='${executionStateType.error}'] {
    ${CardProgress.Bar} {
      background: ${gradients.red};
      --translate-x: 0;
      --transition-properties: transform, background;
    }
  }

  &[data-state-type='${executionStateType.true}'] {
    ${CardProgress.Bar} {
      background: ${gradients.blue};
      --translate-x: 0;
      --transition-properties: transform, background;
    }
  }

  &[data-state-type='${executionStateType.false}'] {
    ${CardProgress.Bar} {
      background: ${gradients.yellow};
      --translate-x: 0;
      --transition-properties: transform, background;
    }
  }

  &[data-state-type='${executionStateType.end}'] {
    ${CardProgress.Bar} {
      background: ${gradients.blue};
      --translate-x: 0;
      --transition-properties: transform, background;
    }
  }
`;
