import { __DEV__ } from './__dev__';

export function buildTree(map) {
  if (map == null) return { root: null, children: null, list: null };

  const { mapping, list } = Object.values(map).reduce(
    (accumulator, item, index) => {
      accumulator.mapping[item.id] = index;

      accumulator.list.push({
        id: item.id,
        level: getLevel(item, map),
        parent: item.parent,
      });

      return accumulator;
    },
    {
      mapping: {},
      list: [],
    }
  );

  const ghosts = [];

  const root = list.reduce((accumulator, item) => {
    if (item.parent === null) {
      accumulator = item;
      return accumulator;
    }

    const parent = list[mapping[item.parent]];

    if (parent) {
      parent.children = [...(parent.children || []), item];
    } else {
      ghosts.push(item);
    }

    return accumulator;
  }, null);

  root.children = root.children?.concat(ghosts) ?? [...ghosts];

  const children = list.reduce((accumulator, item) => {
    accumulator[item.id] = item.children
      ? new Set(flatten(item.children))
      : new Set();
    return accumulator;
  }, {});

  const sortedList = getSortedList(root, [], map);

  return {
    root,
    children,
    list: sortedList,
  };
}

function getSortedList(root, array, map) {
  if (root == null) return array;

  array.push(root);

  if (root.children) {
    const entries = Object.entries(root.children).sort(
      ([, firstChild], [, secondChild]) => {
        const firstChildName = map[firstChild.id].name.toLowerCase();
        const secondChildName = map[secondChild.id].name.toLowerCase();

        if (firstChildName === secondChildName) return 0;

        return firstChildName < secondChildName ? -1 : 1;
      }
    );

    entries.forEach(([childId, child]) => {
      getSortedList(child, array, map);
    });
  }

  return array;
}

function flatten(children) {
  let result = [];
  children.forEach((child) => {
    result.push(child.id);
    if (Array.isArray(child.children)) {
      result = result.concat(flatten(child.children));
    }
  });
  return result;
}

function getLevel(item, map, initialCount = 0) {
  let count = initialCount;

  if (item.parent === null) {
    return count;
  }

  count = count + 1;
  const parent = map[item.parent];

  if (parent) {
    return getLevel(map[item.parent], map, count);
  } else {
    __DEV__ && console.log(map[item.id].name);
  }

  return count;
}
