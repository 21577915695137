import { useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { useAdvancedFlowViewContext } from './AdvancedFlowViewContext';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

import { SpinningIcon } from '../../../components/common/SpinningIcon';
import { Icon } from '../../../components/common/Icon';
import { TextButton } from '../../../components/buttons/TextButton';

import { iconSpinner } from '../../../theme/icons/interface/spinner/spinner';
import { iconStop } from '../../../theme/icons/interface/stop';
import { iconExit } from '../../../theme/icons/interface/rectangle-portrait-arrow-right';
import { iconCheckCircle } from '../../../theme/icons/interface/check-circle';

export function AdvancedFlowTestBar(props) {
  const { i18n } = useI18n();

  const animationRef = useRef();
  const advancedFlowViewContext = useAdvancedFlowViewContext();

  function handleClearPress() {
    // Reset all connection states and card results.
    advancedFlowViewContext.resetAll().catch(console.error);
    // Set the exiting attribute to start the animation.
    animationRef.current.setAttribute('data-is-exiting', true);
  }

  function handleStopPress() {
    AdvancedFlowViewStore.set({
      conditionTest: AdvancedFlowViewStore.conditionTest.stopped,
    });
  }

  const isTestingDone =
    props.conditionTest === AdvancedFlowViewStore.conditionTest.stopped ||
    props.conditionTest === AdvancedFlowViewStore.conditionTest.tested;
  const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;

  return (
    (isTestingDone || isTesting) && (
      <AdvancedFlowTestBar.Root>
        <AdvancedFlowTestBar.Inner
          ref={animationRef}
          onAnimationEnd={(event) => {
            // Make sure the animation is on this element and it's the exact animation.
            if (
              event.target === event.currentTarget &&
              event.animationName === animationSlideBottom.out.name
            ) {
              // This will cause unmount because children will become null.
              AdvancedFlowViewStore.set({
                conditionTest: AdvancedFlowViewStore.conditionTest.initial,
                testNodeId: null,
              });
            }
          }}
        >
          <AdvancedFlowTestBar.StatusWrapper data-is-testing-done={isTestingDone}>
            <AdvancedFlowTestBar.Status data-is-current={isTesting}>
              <SpinningIcon
                size={theme.icon.size_small}
                url={iconSpinner}
                color={theme.color.text_light}
              />{' '}
              {i18n.messageFormatter('flow.testing')}...
            </AdvancedFlowTestBar.Status>

            <AdvancedFlowTestBar.Status data-is-current={isTestingDone}>
              <Icon url={iconCheckCircle} size={theme.icon.size_small} />{' '}
              {i18n.messageFormatter('flow.testingDone')}
            </AdvancedFlowTestBar.Status>
          </AdvancedFlowTestBar.StatusWrapper>

          <AdvancedFlowTestBar.Divider />

          <AdvancedFlowTestBar.ActionWrapper>
            <AdvancedFlowTestBar.ActionInner data-is-testing-done={isTestingDone}>
              <AdvancedFlowTestBar.Action data-is-current={isTesting}>
                <TextButton
                  styleColor="red"
                  styleWidth="full"
                  excludeFromTabOrder
                  isDisabled={isTesting === false}
                  onPress={handleStopPress}
                >
                  <TextButton.Icon url={iconStop} size={theme.icon.size_small} />
                  <TextButton.Text>{i18n.messageFormatter('common.stop')}</TextButton.Text>
                </TextButton>
              </AdvancedFlowTestBar.Action>

              <AdvancedFlowTestBar.Action data-is-current={isTestingDone}>
                <TextButton
                  styleColor="blue"
                  styleWidth="full"
                  excludeFromTabOrder
                  isDisabled={isTestingDone === false}
                  onPress={handleClearPress}
                >
                  <TextButton.Icon
                    color={theme.icon.color_blue}
                    url={iconExit}
                    size={theme.icon.size_small}
                  />
                  <TextButton.Text>{i18n.messageFormatter('common.exit')}</TextButton.Text>
                </TextButton>
              </AdvancedFlowTestBar.Action>
            </AdvancedFlowTestBar.ActionInner>
          </AdvancedFlowTestBar.ActionWrapper>
        </AdvancedFlowTestBar.Inner>
      </AdvancedFlowTestBar.Root>
    )
  );
}

export const animationSlideBottom = {
  in: keyframes`
    0% {
      transform: translateY(200%);
      animation-timing-function: ${theme.curve.easeOut};
    }

    60% {
      transform: translateY(-20%);
      animation-timing-function: ${theme.curve.easeInOut};
    }

    100% {
      transform: translateY(0);
    }
  `,
  out: keyframes`
    0% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-20%);
    }

    100% {
      transform: translateY(200%);
    }
  `,
};

AdvancedFlowTestBar.Root = styled.div`
  position: fixed;
  bottom: 20px;
  left: calc(var(--space-reserve-width) / 2 + 50%);
  transform: translateX(-50%);
  z-index: calc(${theme.zIndex.advanced_flow_view} + 10);
`;

AdvancedFlowTestBar.Inner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 280px;
  height: 40px;
  overflow: hidden;

  background-color: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  border-radius: ${theme.borderRadius.large};

  animation-name: ${animationSlideBottom.in};
  animation-timing-function: ${theme.curve.easeOut};
  animation-duration: ${theme.duration.slow};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  &[data-is-exiting='true'] {
    animation-name: ${animationSlideBottom.out};
  }
`;

AdvancedFlowTestBar.StatusWrapper = styled.div`
  flex: 1 1 0;
  position: relative;

  &[data-is-testing-done='true'] {
  }
`;

AdvancedFlowTestBar.Status = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.color.text_light};
  opacity: 0;
  transition: opacity ${theme.duration.normal} ${theme.curve.easeInOut};

  ${Icon.S.Root},
  ${SpinningIcon} {
    margin-right: 5px;
  }

  &[data-is-current='true'] {
    opacity: 1;
  }
`;

AdvancedFlowTestBar.Divider = styled.div`
  flex: 0 0 1px;
  background-color: ${theme.color.line};
  height: 24px;
`;

AdvancedFlowTestBar.ActionWrapper = styled.div`
  position: relative;
  flex: 1 1 0;
  height: 100%;
  overflow: hidden;
`;

AdvancedFlowTestBar.ActionInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  transform: translateY(0%);
  transition: transform ${theme.duration.normal} ${theme.curve.easeInOut};

  &[data-is-testing-done='true'] {
    transform: translateY(-50%);
  }
`;

AdvancedFlowTestBar.Action = styled.div`
  opacity: 0;
  transition: opacity ${theme.duration.normal} ${theme.curve.easeInOut};

  &[data-is-current='true'] {
    opacity: 1;
  }
`;
