import { RouteManager } from '../../../RouteManager';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useUser } from '../../../store/users/useUsers';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
// import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';

import { iconArrowDoubleSquare } from '../../../theme/icons/interface/arrow-double-square/arrow-double-square';
import { useUserMe } from '../../../store/user-me/useUserMe';
import { useApi } from '../../../store/HomeyStore';

export function FamilySettingsTableUsersContextMenuContent(props) {
  const { i18n } = useI18n();

  const { api } = useApi();
  const { me } = useUserMe();
  const { user } = useUser({ userId: props.userId });

  function onAction(key) {
    switch (key) {
      case 'transfer':
        // is a link
        break;
      case 'delete':
        // is a link
        break;
      default:
        break;
    }
  }

  const isDeleteEnabled = (() => {
    // Prevent deleting the owner. Not possible with the API anyway.
    if (user?.role === 'owner') {
      return false;
    }

    // Allow deleting yourself.
    if (me?.id != null && user?.id != null && me?.id === user?.id) {
      return true;
    }

    // Owner can delete others.
    if (me?.role === 'owner') {
      return true;
    }

    return false;
  })();

  return (
    <ContextMenuContent.List>
      {api?.isCloud !== true && me?.role === 'owner' && user?.role !== 'owner' && (
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter('settings.system.usersTransferOwnerButtonLabel')}
          icon={iconArrowDoubleSquare}
          to={RouteManager.getPathForSwapOwner(props.userId)}
          onPress={() => {
            onAction('transfer');
          }}
        />
      )}

      {/* <DeveloperMenu onIdRequest={() => user?.id} onResourceRequest={() => user} /> */}

      {api?.isCloud !== true && me?.role === 'owner' && user?.role !== 'owner' && (
        <ContextMenuContent.Divider />
      )}

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        to={RouteManager.getPathForDeleteUser(props.userId)}
        isDisabled={isDeleteEnabled === false}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
