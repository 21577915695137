import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

interface TextTinyMediumProps {
  lineHeight?: React.CSSProperties['lineHeight'];
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  children: React.ReactNode;
}

export function TextTinyMedium(props: TextTinyMediumProps) {
  return (
    <S.Root
      style={{
        '--text-tiny-medium-line-height': props.lineHeight,
        '--text-tiny-medium-align': props.textAlign,
        '--text-tiny-medium-color': props.color,
      }}
    >
      {props.children}
    </S.Root>
  );
}

interface CSSProperties {
  '--text-tiny-medium-line-height'?: TextTinyMediumProps['lineHeight'];
  '--text-tiny-medium-align'?: TextTinyMediumProps['textAlign'];
  '--text-tiny-medium-color'?: TextTinyMediumProps['color'];
}

const S = (TextTinyMedium.S = class S {
  static Root = styled.div<{ style: CSSProperties }>`
    font-size: ${theme.fontSize.tiny};
    font-weight: ${theme.fontWeight.medium};
    line-height: var(--text-tiny-medium-line-height, 14px);
    text-align: var(--text-tiny-medium-align, left);
    color: var(--text-tiny-medium-color, ${theme.color.text});
  `;
});
