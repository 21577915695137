import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { Button } from './Button';
import { ButtonIcon } from './ButtonIcon';

export const FlatButton = forwardRef((props, forwardedRef) => {
  const ref = useRef();
  const button = useButton(props, ref);

  return (
    <FlatButton.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      title={props.title}
      className={props.className}
      style={props.style}
      data-style-color={props.styleColor}
      data-style-width={props.styleWidth}
      data-style-compact={props.styleCompact}
      data-is-pressed={button.isPressed}
      data-is-disabled={props.isDisabled}
    >
      {props.children}
    </FlatButton.Root>
  );
});

FlatButton.defaultProps = {
  styleColor: 'default',
};

FlatButton.propTypes = {
  ...Button.propTypes,
  styleColor: PropTypes.oneOf(['blue', 'red']),
  styleCompact: PropTypes.bool,
  styleWidth: PropTypes.oneOf(['full']),
};

FlatButton.Text = styled.span`
  white-space: nowrap;
`;

FlatButton.Icon = ButtonIcon;

FlatButton.Root = styled.button`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding: ${su(1)} ${su(2)};
  background: var(--flat-button-background);
  color: var(--flat-button-color);
  border-radius: ${theme.borderRadius.default};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, color, background-color;

  ${FlatButton.Icon.S.Root} {
    background: var(--flat-button-color);
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: background-color;
  }

  // States
  &:not([data-is-disabled='true']) {
    &:hover {
      background: var(--flat-button-background-hover);
      color: var(--flat-button-color-hover);

      ${FlatButton.Icon.S.Root} {
        background: var(--flat-button-color-hover);
      }
    }

    &[data-is-pressed='true'] {
      transform: scale(0.96875);
      background: var(--flat-button-background-active);
      color: var(--flat-button-color-active);

      ${FlatButton.Icon.S.Root} {
        background: var(--flat-button-color-active);
      }
    }

    &[data-style-color='blue'] {
      --flat-button-background: ${theme.color.blue_050};
      --flat-button-background-active: ${theme.color.blue_050};
      --flat-button-background-hover: ${theme.color.blue_050};
      --flat-button-color: ${theme.color.blue};
      --flat-button-color-hover: ${theme.color.blue_hover};
      --flat-button-color-active: ${theme.color.blue_active};
    }

    &[data-style-color='red'] {
      --flat-button-background: ${theme.color.red_050};
      --flat-button-background-active: ${theme.color.red_050};
      --flat-button-background-hover: ${theme.color.red_050};
      --flat-button-color: ${theme.color.red};
      --flat-button-color-hover: ${theme.color.red_hover};
      --flat-button-color-active: ${theme.color.red_active};
    }

    &[data-style-color='green'] {
      --flat-button-background: ${theme.color.green_050};
      --flat-button-background-active: ${theme.color.green_050};
      --flat-button-background-hover: ${theme.color.green_050};
      --flat-button-color: ${theme.color.green};
      --flat-button-color-hover: ${theme.color.green_hover};
      --flat-button-color-active: ${theme.color.green_active};
    }
  }

  &[data-is-disabled='true'] {
    --flat-button-background: ${theme.color.mono_020};
    --flat-button-color: ${theme.color.text_disabled};
    cursor: not-allowed;
  }

  // Size variants
  &[data-style-compact='true'] {
    grid-gap: 6px;
    padding-left: ${su(1.5)};
    padding-right: ${su(1.5)};
  }

  // Width variants
  &[data-style-width='full'] {
    width: 100%;
  }
`;
