import { useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';
import { AdvancedFlowViewStore } from '../view-advanced-flow/store/AdvancedFlowViewStore';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Dialog } from '../../../components/overlay/Dialog';
import { IconButton } from '../../../components/buttons/IconButton';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { Icon } from '../../../components/common/Icon';
import { TextField } from '../../../components/forms/TextField';
import { SpinnerLoading } from '../../../components/common/SpinnerLoading';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconCheckmark } from '../../../theme/icons/interface/checkmark';
import { iconAdvancedFlow } from '../../../theme/icons/interface/advanced-flow/advanced-flow';

export function SaveAdvancedFlowDialog() {
  const { i18n } = useI18n();
  const [isSaving, setIsSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  function onSubmit(data, options = {}) {
    setIsSaving(true);
    const values = { ...data };
    const waitPromise = new Promise((resolve) => setTimeout(resolve, 500));

    AdvancedFlowViewStore.updateAdvancedFlow((prevAdvancedFlow) => {
      return {
        ...prevAdvancedFlow,
        name: values.name,
      };
    });

    const savePromise = AdvancedFlowViewStore.saveAdvancedFlow({
      route: true,
    });

    Promise.all([waitPromise, savePromise])
      .then(([, result]) => {
        ToastManager.add({
          icon: iconCheckmark,
          message: i18n.messageFormatter(`flow.createdAdvancedFlow`, {
            name: result.name,
          }),
        });
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  }

  function handleClose() {
    RouteManager.pushState({});
  }

  return (
    <Dialog onClose={handleClose} isOpen>
      <SaveAdvancedFlowDialog.Root>
        <SaveAdvancedFlowDialog.Header>
          <IconButton
            onPress={handleClose}
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
          />

          <SaveAdvancedFlowDialog.HeaderIconWrapper>
            <Icon
              url={iconAdvancedFlow}
              size={theme.icon.size_medium}
              color={theme.color.icon_dark}
            />
          </SaveAdvancedFlowDialog.HeaderIconWrapper>

          <SaveAdvancedFlowDialog.HeaderTitle>
            {i18n.messageFormatter('flow.newFlowNamePlaceholder')}
          </SaveAdvancedFlowDialog.HeaderTitle>
        </SaveAdvancedFlowDialog.Header>

        <SaveAdvancedFlowDialog.Form
          onSubmit={(event) => {
            // single element form causes submit on enter key in a field
            event.preventDefault();
          }}
        >
          <TextField
            autoFocus
            autoComplete="off"
            orientation="vertical"
            type="text"
            name="name"
            label={i18n.messageFormatter('common.name')}
            placeholder={i18n.messageFormatter('flow.newFlowNamePlaceholder')}
            register={register}
            required
            minLength={1}
            error={errors.name}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(onSubmit)({});
              }
            }}
          />
        </SaveAdvancedFlowDialog.Form>

        <SaveAdvancedFlowDialog.Divider />

        <SaveAdvancedFlowDialog.Footer>
          <GradientButton
            flat
            onPress={() => {
              handleSubmit(onSubmit)({});
            }}
          >
            {/* Note the sizes are linked 16 -> theme.icon.size_small */}
            {isSaving ? (
              <SpinnerLoading size={16} color={theme.color.mono_000} />
            ) : (
              <GradientButton.Icon
                url={iconCheckmark}
                color={theme.color.white}
                size={theme.icon.size_small}
              />
            )}
            <GradientButton.Text>{i18n.messageFormatter('common.save')}</GradientButton.Text>
          </GradientButton>
        </SaveAdvancedFlowDialog.Footer>
      </SaveAdvancedFlowDialog.Root>
    </Dialog>
  );
}

SaveAdvancedFlowDialog.Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
`;

SaveAdvancedFlowDialog.HeaderIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: ${theme.color.mono_025};
  border-radius: 50%;
`;

SaveAdvancedFlowDialog.HeaderTitle = styled.div`
  font-size: ${theme.fontSize.heading2};
  font-weight: ${theme.fontWeight.medium};
`;

SaveAdvancedFlowDialog.Header = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${su(2, 2, 0, 2)};

  ${IconButton.S.Root} {
    align-self: flex-end;
  }

  ${SaveAdvancedFlowDialog.HeaderIconWrapper} {
    align-self: center;
  }

  ${SaveAdvancedFlowDialog.HeaderTitle} {
    align-self: center;
    padding-top: ${su(2)};
  }
`;

SaveAdvancedFlowDialog.Form = styled.form`
  padding: ${su(0, 2, 1, 2)};

  ${TextField.Root} {
    margin: ${su(2)} 0;
  }
`;

SaveAdvancedFlowDialog.Divider = styled.div`
  background-color: ${theme.color.line};
  height: 1px;
  margin: 0 ${su(2)};
`;

SaveAdvancedFlowDialog.Footer = styled.footer`
  padding: ${su(2)};

  ${GradientButton.S.Root} {
    width: 100%;
  }
`;
