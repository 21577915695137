import { AthomCloudAPI, AthomStorageAdapter } from 'athom-api';
import config from '../config.json';

export const store = new AthomStorageAdapter.LocalStorage('_athom_api_client_store');

const client = new AthomCloudAPI({
  clientId: config.clientId,
  clientSecret: config.clientSecret,
  baseUrl: import.meta.env.VITE_ATHOM_CLOUD_API_BASEURL || 'https://api.athom.com',
  redirectUrl: window.location.origin,
  store: store,
});
client.authorizationUrl = `${
  import.meta.env.VITE_ATHOM_CLOUD_API_BASEURL || 'https://api.athom.com'
}/oauth2/authorise`;

export { client };
