import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

interface TextProps {
  lineHeight?: React.CSSProperties['lineHeight'];
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  children: React.ReactNode;
}

export function TextNormal(props: TextProps) {
  return (
    <S.Root
      style={{
        '--text-normal-line-height': props.lineHeight,
        '--text-normal-text-align': props.textAlign,
        '--text-normal-color': props.color,
      }}
    >
      {props.children}
    </S.Root>
  );
}

interface CSSProperties {
  '--text-normal-line-height'?: TextProps['lineHeight'];
  '--text-normal-text-align'?: TextProps['textAlign'];
  '--text-normal-color'?: TextProps['color'];
}

const S = (TextNormal.S = class S {
  static Root = styled.div<{ style: CSSProperties }>`
    font-size: ${theme.fontSize.default};
    line-height: var(--text-normal-line-height, 24px);
    text-align: var(--text-normal-text-align, left);
    color: var(--text-normal-color, ${theme.color.text});
  `;
});
