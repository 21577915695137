import { Fragment } from 'react';
import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { defaultTokenExamples } from '../flow-arguments/tokens';

import { su } from '../../../theme/functions/su';

import { FlowCardHint } from './FlowCardHint';
import { FlowToken } from '../flow-arguments/FlowToken';

import { iconTag } from '../../../theme/icons/interface/tag';

export function FlowCardTokensHint(props) {
  const { i18n } = useI18n();

  return (
    <FlowCardHint
      isDisabled={props.isDisabled}
      isHighlightIcon={props.isHighlightIcon}
      iconUrl={iconTag}
      hint={
        <Fragment>
          <FlowCardTokensHint.Explainer>
            {i18n.messageFormatter('flow.tokensExplainer')}
          </FlowCardTokensHint.Explainer>
          <div>
            {props.tokens.map((token) => {
              return (
                <FlowCardTokensHint.TokenGroup key={token.id}>
                  <FlowCardTokensHint.TokenGroupInner>
                    <div>
                      <FlowToken
                        tokenKey={
                          props.cardDataKey
                            ? `${props.cardType}::${props.cardDataKey}::${token.id}`
                            : null
                        }
                        token={token}
                        isHoverable={false}
                        label={token.title}
                      />
                    </div>
                    <div>{String(token.example ?? defaultTokenExamples[token.type])}</div>
                  </FlowCardTokensHint.TokenGroupInner>

                  {/*{token.hint != null && (*/}
                  {/*  <FlowCardTokensHint.TokenGroupHint>*/}
                  {/*    {token.hint}*/}
                  {/*  </FlowCardTokensHint.TokenGroupHint>*/}
                  {/*)}*/}
                </FlowCardTokensHint.TokenGroup>
              );
            })}
          </div>
        </Fragment>
      }
    />
  );
}

FlowCardTokensHint.Explainer = styled.div`
  padding-bottom: ${su(1)};
`;

FlowCardTokensHint.TokenGroup = styled.div`
  &:not(:first-of-type) {
    padding-top: ${su(1)};
  }
`;

FlowCardTokensHint.TokenGroupInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

// FlowCardTokensHint.TokenGroupHint = styled.div`
//   padding-top: ${su(1)};
// `;
