import React, { forwardRef, useRef } from 'react';
import { useButton } from 'react-aria';
import styled from '@emotion/styled';

import { mergeRefs } from '../../lib/mergeRefs';

import { theme } from '../../theme/theme';

export const HeaderButton = forwardRef((props, forwardedRef) => {
  const ref = useRef();
  const button = useButton(props, ref);

  return (
    <HeaderButton.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      date-is-pressed={button.isPressed}
      title={props.title}
      style={{
        '--background': props.background,
      }}
    >
      {props.children}
    </HeaderButton.Root>
  );
});

HeaderButton.Root = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: ${theme.boxShadow.default};
  background: var(--background);
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, box-shadow;

  &[date-is-pressed='true'] {
    --scale: 1;
  }

  &:hover {
    transform: scale(var(--scale, 1.1));
    box-shadow: ${theme.boxShadow.hover};
  }
`;
