import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { CapabilitySelect } from '../CapabilitySelect';
import * as Containers from '../Containers';
import { motion } from 'framer-motion';

import { theme } from '../../../theme/theme';

const BORDER_WIDTH = 2;
const MAX_BATTERY_WIDTH = 240;

export function Battery({ device, capabilities, component }) {
  const [value, setValue] = useState(0);
  const [capability, setCapability] = useState(() => {
    return capabilities?.[component.capabilities[0]] ?? null;
  });

  useEffect(() => {
    setCapability(capabilities?.[component.capabilities[0]] ?? null);
  }, [capabilities, component.capabilities]);

  // If the value of the battery changes while it is in view, then update it.
  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value }) => {
        setValue(value);
      });

      setValue(capability.value);

      return unregister;
    }
  }, [capability]);

  if (capability == null) return null;

  const state = getState(capability, value);
  const batteryShape = getBatteryShape(state, MAX_BATTERY_WIDTH);

  // Two variants: either the animation runs or it does not.
  const variants = {
    animating: { opacity: 0 },
    still: { opacity: 1 },
  };

  // The animation must only run when the battery is (almost) empty.
  const runAnimation =
    (state.type === 'alarm' && state.alarm === true) ||
    (state.type === 'measure' && state.value !== null && state.value <= 0);

  // Use a transition to make the animation loop infinitely often.
  const transition = runAnimation
    ? {
        repeat: Infinity,
        ease: 'linear',
        repeatType: 'reverse',
        duration: 0.75,
      }
    : {};

  return (
    <Containers.Control>
      <Containers.Select>
        {component.capabilities.length > 1 && (
          <CapabilitySelect
            selectedCapability={capability}
            componentCapabilities={component.capabilities}
            capabilities={capabilities}
            onSelectionChange={setCapability}
          />
        )}
      </Containers.Select>
      <Containers.Action>
        <BatteryContainer>
          <BatteryView>
            <FillContainer>
              <FillArea
                style={{
                  width: batteryShape.width,
                  height: batteryShape.height,
                }}
              >
                <BackgroundRectangle
                  style={{
                    width: batteryShape.rect.width,
                    height: batteryShape.height,
                    backgroundColor: getBackgroundColor(state),
                  }}
                />
                <BackgroundTriangle
                  style={{
                    width: batteryShape.fill.width,
                    height: batteryShape.fill.height,
                    left: batteryShape.rect.width,
                    marginLeft: -(batteryShape.fill.width / 2),
                    backgroundColor: getBackgroundColor(state),
                    transform: `rotate(${batteryShape.fill.rotation}rad) scaleY(2)`,
                  }}
                  animate={runAnimation ? 'animating' : 'still'}
                  variants={variants}
                  transition={transition}
                />
                <BatteryText>{getDisplayValue(state)}</BatteryText>
              </FillArea>
            </FillContainer>
            <Pole
              style={{
                height: batteryShape.pole.height,
                width: batteryShape.pole.width,
                right: -(batteryShape.pole.width - BORDER_WIDTH),
                top: (batteryShape.height - batteryShape.pole.height) / 2,
              }}
            />
          </BatteryView>
        </BatteryContainer>
      </Containers.Action>
    </Containers.Control>
  );
}

const BatteryContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const BatteryView = styled.div`
  position: relative;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const FillContainer = styled.div`
  position: relative;
  border: ${BORDER_WIDTH}px solid #ffffff;
  border-radius: 12px;
  overflow: hidden;
`;

const FillArea = styled.div`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
`;

const BackgroundRectangle = styled.div`
  position: absolute;
  left: 0;
`;

const BackgroundTriangle = styled(motion.div)`
  position: absolute;
`;

const BatteryText = styled.span`
  color: white;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.heading1};
  z-index: 1;
`;

const Pole = styled.div`
  position: absolute;
  border-radius: 0px 12px 12px 0px;
  border: ${BORDER_WIDTH}px solid #ffffff;
  z-index: 1;
`;

// Set the background color for the battery for a given state.
const getBackgroundColor = (state) => {
  // For the measure type we define a range between red and green.
  if (state.type === 'measure') {
    if (state.value === null) return '#aaa'; // Gray for undefined values.
    if (state.value <= 0) return '#ca0000';
    if (state.value <= 20) return '#c95100';
    if (state.value <= 40) return '#c9a100';
    if (state.value <= 60) return '#a1c900';
    if (state.value <= 80) return '#51c900';
    if (state.value <= 100) return '#00c900';
  }

  // For the 'alarm' type the color is either red or green.
  if (state.type === 'alarm') {
    if (state.alarm === null) return '#aaa'; // Gray for undefined values.
    return state.alarm ? '#ca0000' : '#00c900';
  }
};

// Get the text to display on top of the battery.
// For 'measurement' types this is the percentage.
// For 'alarm' types this is either 'LOW' or 'OK'.
const getDisplayValue = (state) => {
  if (state.type === 'measure') {
    if (state.value !== null) {
      return Math.round(state.value) + '%';
    } else {
      return '?';
    }
  }

  if (state.type === 'alarm') {
    if (state.alarm === null) return '?';
    if (state.alarm === true) {
      return 'LOW';
    } else {
      return 'OK';
    }
  }
};

// Get the width of the rectangle that indicates the energy the battery holds.
// For 'measurement' types this is a percentage of the batterywidth,
// for 'alarm' types it is either completely full or completely empty.
const getRectWidth = (state, batteryWidth) => {
  if (state.type === 'measure') {
    return (state.value / 100) * batteryWidth;
  }

  if (state.type === 'alarm') {
    if (state.alarm === true || state.alarm == null) {
      return 0;
    } else {
      return batteryWidth;
    }
  }
};

// Convert the capablity to a state which will be used for rendering.
const getState = (capability, value) => {
  if (capability.id.indexOf('measure_battery') !== -1) {
    return {
      type: 'measure',
      value: value,
      alarm: null,
    };
  }
  if (capability.id.indexOf('alarm_battery') !== -1) {
    return {
      type: 'alarm',
      value: null,
      alarm: value,
    };
  }
};

// Creates an object that defines the shape of the battery shown on screen.
const getBatteryShape = (state, maxBatteryWidth) => {
  const originalWidth = maxBatteryWidth;
  const batteryWidth = originalWidth > maxBatteryWidth ? maxBatteryWidth : originalWidth;
  const batteryHeight = 120;
  const poleWidth = 20;
  const poleHeight = 60;
  const rectWidth = getRectWidth(state, maxBatteryWidth);
  const triangleWidth = 0.15 * batteryWidth;
  const trianglePlaneHeight = Math.sqrt(
    triangleWidth * triangleWidth + batteryHeight * batteryHeight
  );
  const trianglePlaneRotation = Math.atan(triangleWidth / batteryHeight);

  return {
    width: batteryWidth,
    height: batteryHeight,
    pole: {
      width: poleWidth,
      height: poleHeight,
    },
    fill: {
      width: triangleWidth,
      height: trianglePlaneHeight,
      rotation: trianglePlaneRotation,
    },
    rect: {
      width: rectWidth,
    },
  };
};
