import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

import { InsightUtils } from './InsightUtils';
import { I18NStore } from '../../providers/LocaleProvider';

import { getHSL } from '../../lib/string';
import { ResourceUtils } from '../ResourceUtils';

export class InsightStore extends BaseApiStore {
  static key = 'insights';
  static store = this.createStore(this.key);
  static logCacheId = 'HomeyAPI.ManagerInsights.Log';

  static createInitialState() {
    return {
      data: null,
      byKey: null,
      byOwnerUri: null,
      colorMap: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info(`fetch:${this.key}`);
    this.destroy('before:fetch');
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerInsights = state.api.insights;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const insightsPromise = managerInsights.getLogs({
        $skipCache: skipCache,
      });
      const collatorPromise = I18NStore.getCollator();

      const [, insightsData, collator] = await Promise.all([
        waitPromise,
        insightsPromise,
        collatorPromise,
      ]);

      const values = Object.values(insightsData);
      values.sort(InsightUtils.compareLog.bind(InsightUtils, collator));

      const result = values.reduce(
        (accumulator, log, index) => {
          // TODO remove after homey-core 1.22.12
          if (log.units !== null && typeof log.units === 'object') {
            log.units = log.units.en ?? null;
          }

          const logReference = { ...log };
          const key = ResourceUtils.temp__getId(logReference);
          const { ownerUri, ownerId, ownerType } = ResourceUtils.getOwnerProps(logReference);
          const ownerName = ResourceUtils.getOwnerName(logReference);
          const ownerColor = InsightUtils.getOwnerColor(logReference);

          accumulator.data[key] = log;
          accumulator.byKey[key] = logReference;
          accumulator.byOwnerUri[ownerUri] = {
            ...accumulator.byOwnerUri[ownerUri],
            data: {
              ...(accumulator.byOwnerUri[ownerUri]?.data ?? null),
              [key]: logReference,
            },
          };

          const hue = Math.round(5 * index) % 360;
          accumulator.colorMap[key] = {
            hue: hue,
            hsl: getHSL(hue),
          };

          accumulator.byOwnerUri[ownerUri].ownerId = ownerId;
          accumulator.byOwnerUri[ownerUri].ownerType = ownerType;
          accumulator.byOwnerUri[ownerUri].ownerName = ownerName;
          accumulator.byOwnerUri[ownerUri].ownerColor = ownerColor;

          return accumulator;
        },
        {
          data: {},
          byKey: {},
          byOwnerUri: {},
          colorMap: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byKey: result.byKey,
        byOwnerUri: result.byOwnerUri,
        colorMap: result.colorMap,
        loading: false,
        manager: managerInsights,
      });

      managerInsights.addListener('log.create', this.handleCreate);
      managerInsights.addListener('log.update', this.handleUpdate);
      managerInsights.addListener('log.delete', this.handleDelete);
    } catch (error) {
      this.destroy('error');
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy(reason) {
    __DEV__ && console.info(`destroy:${this.key}:${reason ?? ''}`);
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerInsights = state.api.insights;

      managerInsights.removeListener('log.create', this.handleCreate);
      managerInsights.removeListener('log.update', this.handleUpdate);
      managerInsights.removeListener('log.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(log, stateArg) {
    const state = stateArg ?? this.get();
    const cache = state.api.insights._caches[this.logCacheId];

    return cache.getOne({
      id: cache._guessCacheId(log),
    });
  }

  static handleCreate = (createdLog) => {
    const key = ResourceUtils.temp__getId(createdLog);
    __DEV__ && console.info(`create:log:${key}`);
    const state = this.get();
    const logInstance = this.getInstanceFromCache(createdLog, state);

    if (logInstance == null) return;

    // TODO remove after homey-core 1.22.12
    if (logInstance.units !== null && typeof logInstance.units === 'object') {
      logInstance.units = logInstance.units.en ?? null;
    }

    const logReference = { ...logInstance };
    const { ownerUri, ownerId, ownerType } = ResourceUtils.getOwnerProps(logReference);
    const ownerName = ResourceUtils.getOwnerName(logReference);
    const ownerColor = InsightUtils.getOwnerColor(logReference);

    const nextByOwnerUri = {
      ...state.byOwnerUri,
      [ownerUri]: {
        ...(state.byOwnerUri[ownerUri] ?? null),
        data: {
          ...(state.byOwnerUri[ownerUri]?.data ?? null),
          [key]: logReference,
        },
      },
    };

    nextByOwnerUri[ownerUri].ownerUri = ownerUri;
    nextByOwnerUri[ownerUri].ownerId = ownerId;
    nextByOwnerUri[ownerUri].ownerType = ownerType;
    nextByOwnerUri[ownerUri].ownerName = ownerName;
    nextByOwnerUri[ownerUri].ownerColor = ownerColor;

    const nextColorMap = {
      ...state.colorMap,
      [key]: {
        hue: 1,
        hsl: getHSL(1),
      },
    };

    this.set({
      data: {
        ...state.data,
        [key]: logInstance,
      },
      byKey: {
        ...state.byKey,
        [key]: logReference,
      },
      byOwnerUri: nextByOwnerUri,
      colorMap: nextColorMap,
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedLog) => {
    const key = ResourceUtils.temp__getId(updatedLog);
    __DEV__ && console.info(`update:log:${key}`);

    // TODO remove after homey-core 1.22.12
    if (updatedLog.units !== null && typeof updatedLog.units === 'object') {
      updatedLog.units = updatedLog.units.en ?? null;
    }

    this.updateQueue[key] = { ...updatedLog };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedLogs = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextByKey = {
          ...state.byKey,
        };

        const nextByOwnerUri = {
          ...state.byOwnerUri,
        };

        let updateCount = 0;

        for (const [key, log] of Object.entries(queuedLogs)) {
          const logInstance = this.getInstanceFromCache(log, state);

          if (logInstance == null || state.data[key] == null) {
            __DEV__ && console.info(`update:log:unknown:${key}`);
            continue;
          }

          const logReference = { ...logInstance };
          const { ownerUri, ownerId, ownerType } = ResourceUtils.getOwnerProps(logReference);
          const ownerName = ResourceUtils.getOwnerName(logReference);
          const ownerColor = InsightUtils.getOwnerColor(logReference);

          nextByKey[key] = logReference;

          nextByOwnerUri[ownerUri] = {
            ...(nextByOwnerUri[ownerUri] ?? null),
            data: {
              ...(nextByOwnerUri[ownerUri]?.data ?? null),
              [key]: logReference,
            },
          };

          nextByOwnerUri[ownerUri].ownerUri = ownerUri;
          nextByOwnerUri[ownerUri].ownerId = ownerId;
          nextByOwnerUri[ownerUri].ownerType = ownerType;
          nextByOwnerUri[ownerUri].ownerName = ownerName;
          nextByOwnerUri[ownerUri].ownerColor = ownerColor;

          updateCount++;
        }

        __DEV__ && console.info(`update:log:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byKey: nextByKey,
            byOwnerUri: nextByOwnerUri,
          });
      }, 200);
    }
  };

  static handleDelete = (deletedLog) => {
    const key = ResourceUtils.temp__getId(deletedLog);
    __DEV__ && console.info(`delete:log:${key}`);
    const state = this.get();
    const logInstance = state.data[key];

    if (logInstance == null) return;
    const ownerUri = ResourceUtils.getOwnerUri(logInstance);

    const nextData = { ...state.data };
    const nextByKey = { ...state.byKey };
    const nextByOwnerUri = { ...state.byOwnerUri };

    delete nextData[key];
    delete nextByKey[key];

    nextByOwnerUri[ownerUri] = {
      ...(nextByOwnerUri[ownerUri] ?? null),
    };

    delete nextByOwnerUri[ownerUri].data[key];

    if (Object.keys(nextByOwnerUri[ownerUri].data).length === 0) {
      delete nextByOwnerUri[ownerUri];
    }

    this.set({
      data: nextData,
      byKey: nextByKey,
      byOwnerUri: nextByOwnerUri,
    });
  };
}
