import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';

import { useApi } from '../../../store/HomeyStore';
import { useZonesTree } from '../../../store/zones/useZones';
import { ZoneStore } from '../../../store/zones/ZoneStore';

import { HomeyImages } from '../../../theme/HomeyImages';
import { dragTypes } from '../../../components/dnd/dragTypes';

import { ContentLoader } from '../../../components/content-loader/ContentLoader';
import { Scroll } from '../../../components/common/Scroll';
import { ZoneNavigationList } from './ZoneNavigationList';
import { NewZoneButton } from './NewZoneButton';

export function ZoneNavigation(props) {
  const { api } = useApi();
  const zones = useZonesTree();
  const [draftZone, setDraftZone] = useState(null);
  const [editZoneId, setEditZoneId] = useState(null);

  const listToRender = useMemo(() => {
    if (zones.tree?.list == null) {
      return null;
    }

    // If the draft zone is in the list, return the list as is, otherwise add the draft zone to the list.
    if (draftZone == null || zones.tree.list.some((item) => item.id === draftZone.id)) {
      return zones.tree.list;
    }

    const newList = [...zones.tree.list];

    // Recursive function to find the index of the last child of the parent or the parent itself.
    function getLastChildIndex(newList, parentId) {
      let parentIndex = newList.findIndex((item) => item.id === parentId);
      let lastChildIndex = parentIndex;

      newList.forEach((item, index) => {
        if (item.parent === parentId) {
          lastChildIndex = index;
        }
      });

      if (lastChildIndex === parentIndex) {
        return lastChildIndex;
      } else {
        return getLastChildIndex(newList, newList[lastChildIndex].id);
      }
    }

    const parent = newList.find((item) => item.id === draftZone.parentId);
    const lastChildIndex = getLastChildIndex(newList, draftZone.parentId);

    // Add the draft zone after the last child of the parent or the parent itself.
    newList.splice(lastChildIndex + 1, 0, {
      id: draftZone.id,
      level: parent.level + 1,
      parent: draftZone.parentId,
    });

    return newList;
  }, [zones.tree, draftZone]);

  // Remove draft zone if it was created successfully.
  useLayoutEffect(() => {
    if (draftZone && zones.tree?.list?.some((item) => item.id === draftZone.id)) {
      setDraftZone(null);
    }
  }, [zones.tree?.list, draftZone]);

  const onDrop = useCallback(
    ({ type, dropId, dragId }) => {
      if (type === dragTypes.ZONE) {
        api.zones
          .updateZone({
            id: dragId,
            zone: {
              name: zones.byId[dragId].name,
              parent: dropId,
            },
          })
          .then(() => {})
          .catch(console.error);
      }

      if (type === dragTypes.DEVICE) {
        api.devices
          .updateDevice({
            id: dragId,
            device: {
              zone: dropId,
            },
          })
          .then(() => {})
          .catch(console.error);
      }
    },
    [api, zones.byId]
  );

  function handleEditSave({ id, value, resolve, reject }) {
    zones.manager
      .updateZone({ id, zone: { name: value } })
      .then(resolve)
      .catch((error) => {
        reject(error);
        ToastManager.handleError(error);
      });
  }

  // Create a new zone and update the draft zone with the new id as a placeholder.
  function handleCreateZone({ id, parentId, value }) {
    zones.manager
      .createZone({
        id,
        zone: { name: value, parent: parentId },
      })
      .then((result) => {
        const state = ZoneStore.store.getState();
        // If the store is updated before this resolves, clear the draft zone.
        if (state.tree.list.some((item) => item.id === result.id)) {
          setDraftZone(null);
        } else {
          setDraftZone((prevDraftZone) => {
            return {
              ...prevDraftZone,
              id: result.id,
              name: value,
            };
          });
        }
      })
      .catch((error) => {
        ToastManager.handleError(error);
      });
  }

  return (
    <ZonesDrawer>
      {zones.loading && <ContentLoader.ZoneList />}

      {!zones.loading && !zones.error && (
        <>
          <Scroll>
            <ZoneNavigationList
              tree={zones.tree}
              listToRender={listToRender}
              byId={zones.byId}
              selectedId={props.selectedZoneId}
              draftZone={draftZone}
              editId={editZoneId}
              onSelect={({ id }) => props.setSelectedZoneId(id)}
              setDraftZone={setDraftZone}
              setEditZoneId={setEditZoneId}
              onDraftZoneBlur={() => {
                setDraftZone(null);
              }}
              onEditBlur={() => {
                setEditZoneId(null);
              }}
              onDraftZoneSave={handleCreateZone}
              onEditSave={handleEditSave}
              onDrop={onDrop}
              icon={getIcon}
            />
          </Scroll>
          <ZoneActions>
            <NewZoneButton
              onPress={() => {
                setDraftZone({ id: 'create', parentId: zones.tree.root.id });
              }}
            />
          </ZoneActions>
        </>
      )}
    </ZonesDrawer>
  );
}

function getIcon(item) {
  return HomeyImages.getIconForZone(item?.icon ?? null);
}

const ZonesDrawer = styled.aside`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 100%;
`;

const ZoneActions = styled.div`
  display: flex;
  padding: 10px 10px 0 10px;
  overflow: hidden;
`;
