import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useOverlayTriggerState } from 'react-stately';
import { useButton, useKeyboard, useLabel } from 'react-aria';

import { enqueueTask } from '../../../lib/enqueueTask';
import { stopPressPropagation } from '../../../lib/dom/stopPressPropagation';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { getTokenFlowKey, useToken } from './tokens';
import { su } from '../../../theme/functions/su';
import { argInput } from './argInput';

import { TokenPicker } from '../token-picker/TokenPicker';
import { ArgumentBase, Argument } from './Argument';
import { FlowToken } from './FlowToken';

export function ArgumentRange(props) {
  const { i18n } = useI18n();

  const inputLabel = props.argument.title ?? i18n.messageFormatter('flow.argument.range.title');
  const placeholder =
    props.argument.placeholder ?? i18n.messageFormatter('flow.argument.range.placeholder');

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  const { token, tokenKey, isToken } = useToken({
    value: localValue,
  });

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue =
        token != null ? localValue : localValue != null ? Number(localValue) : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleTokenSelect(key, { kind, token }) {
    const tokenFlowKey = getTokenFlowKey(key, { kind, token });
    const nextValue = `[[${tokenFlowKey}]]`;

    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: true });

    props.onUpdate?.({
      argumentKey: props.argumentKey,
      value: nextValue,
    });

    // Give the TokenPicker a chance to gracefully start closing.
    enqueueTask(() => {
      triggerState.close();
    });
  }

  function handleClearTokenRequest() {
    const nextValue = undefined;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleChange(value) {
    const nextValue = value?.length === 0 || value == null ? undefined : value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  const { labelDecimals = 1, labelMultiplier = 1, label = '' } = props.argument;

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        switch (true) {
          case token != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken tokenKey={tokenKey} token={token} label={token.title} />
              </ArgumentBase>
            );
          case token == null && isToken:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken title={localValue} />
              </ArgumentBase>
            );
          case localValue != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                {(localValue * labelMultiplier).toFixed(labelDecimals)}
                {label}
              </ArgumentBase>
            );
          default:
            return (
              <ArgumentBase
                {...triggerProps}
                ref={triggerRef}
                data-is-invalid={isInvalid}
                data-is-empty={true}
              >
                {props.argumentTypeText}
              </ArgumentBase>
            );
        }
      }}
      renderOverlay={() => {
        return (
          <RangeInput
            value={localValue ?? ''}
            label={inputLabel}
            valueLabel={label}
            placeholder={placeholder}
            min={props.argument.min}
            max={props.argument.max}
            step={props.argument.step}
            token={token}
            tokenKey={tokenKey}
            onChange={handleChange}
            onTokenSelect={handleTokenSelect}
            onClearTokenRequest={handleClearTokenRequest}
            onSaveRequest={handleSaveRequest}
          />
        );
      }}
    />
  );
}

const TOKEN_TYPES = ['number'];

function RangeInput(props) {
  const inputLabel = props.label;
  const valueLabel = props.valueLabel?.length > 0 ? `(${props.valueLabel})` : null;
  const fullLabel = `${inputLabel}${valueLabel ? ` ${valueLabel}` : ''}`;

  const buttonRef = useRef();
  const inputRef = useRef();
  const label = useLabel({
    label: fullLabel,
    'aria-label': fullLabel,
  });

  function onKeyDown(event) {
    // Consume every key and save on enter.
    if (event.key === 'Enter') {
      event.preventDefault();
      props.onSaveRequest();
    } else if (event.key === 'Escape') {
      // Propagate on escape.
      event.continuePropagation();
    } else if (event.key === 'Delete') {
      event.preventDefault();
      props.onChange(undefined);
      enqueueTask(() => {
        inputRef.current?.focus();
      });
    }
  }

  function onKeyUp(event) {
    // Consume every key and save on enter.
    if (event.key === 'Enter') {
      event.preventDefault();
    } else if (event.key === 'Escape') {
      // Propagate on escape.
      event.continuePropagation();
    } else if (event.key === 'Delete') {
      event.preventDefault();
    }
  }

  const keyboard = useKeyboard({
    onKeyDown: onKeyDown,
    onKeyUp: onKeyUp,
  });

  const containerRef = useRef();

  useEffect(() => {
    requestAnimationFrame(() => {
      // It either renders as a button or as an input.
      inputRef.current?.focus();
      buttonRef.current?.focus();
    });
  }, []);

  function wrap(children) {
    return (
      <argInput.InputContainer ref={containerRef}>
        <argInput.InputLabel {...label.labelProps}>{fullLabel}</argInput.InputLabel>
        <argInput.InputRow>
          {children}
          <TokenPicker types={TOKEN_TYPES} onSelect={props.onTokenSelect} />
        </argInput.InputRow>
      </argInput.InputContainer>
    );
  }

  const button = useButton(
    {
      elementType: FlowToken.Root.__emotion_base,
      onKeyDown: onKeyDown,
      onKeyUp: onKeyUp,
      onPress() {
        props.onClearTokenRequest();
        enqueueTask(() => {
          inputRef.current?.focus();
        });
      },
    },
    buttonRef
  );

  if (props.token != null) {
    return wrap(
      <FlowToken
        {...button.buttonProps}
        ref={buttonRef}
        tokenKey={props.tokenKey}
        token={props.token}
        showRemoveIcon={true}
        isValueTooltipDisabled={true}
        label={props.token.title}
      />
    );
  }

  return wrap(
    <ArgumentRangeInputWrapper>
      <argInput.Input
        {...keyboard.keyboardProps}
        {...stopPressPropagation}
        ref={inputRef}
        type="range"
        onChange={(event) => {
          event.stopPropagation();
          props.onChange(event.target.value);
        }}
        placeholder={props.placeholder}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        style={{
          '--webkit-range-progress':
            ((Number(props.value) + props.min * -1) / (props.max + props.min * -1)) * 100,
        }}
      />
    </ArgumentRangeInputWrapper>
  );
}

const ArgumentRangeInputWrapper = styled.div`
  margin-right: ${su(1)};
  flex: 1 1 auto;
  height: 22px;
`;
