import { useCallback, useEffect, useRef, useState } from 'react';

import { ToastManager } from '../../ToastManager';
import { ResourceUtils } from '../../store/ResourceUtils';
import { parseLogsEntries } from './logUtils';

import { useMount } from '../../hooks/useMount';

export function useFetchEntries({ byKey, manager, resolutionId }) {
  const mount = useMount();
  const timeoutRef = useRef();

  const [data, setData] = useState(null);
  const [currentLoadedResolutionId, setCurrentLoadedResolutionId] = useState(null);

  const fetchEntries = useCallback(async () => {
    if (manager == null) return Promise.all([]);

    const promises = Object.values(byKey)
      .map((log) => {
        // deleted for example
        if (log == null) return null;

        return manager.getLogEntries({
          uri: ResourceUtils.getOwnerUri(log), // redundant for cloud
          id: ResourceUtils.getId(log),
          resolution: resolutionId,
        });
      })
      .filter((promise) => promise != null);

    return Promise.all(promises);
  }, [byKey, manager, resolutionId]);

  useEffect(() => {
    const localTimeoutRef = timeoutRef;

    (async () => {
      clearTimeout(localTimeoutRef.current);

      async function load() {
        try {
          const resolved = await fetchEntries();

          if (resolved.length === 0) {
            setCurrentLoadedResolutionId(resolutionId);
            return;
          }

          const valuesKeys = resolved.reduce((accumulator, log) => {
            accumulator[ResourceUtils.temp__getId(log)] = true;
            return accumulator;
          }, {});

          const { groupValues, lastUpdatesIn, minValue, maxValue } = parseLogsEntries(resolved);

          if (mount.isMounted) {
            setCurrentLoadedResolutionId(resolutionId);

            setData({
              values: groupValues,
              valuesKeys,
              minValue,
              maxValue,
            });

            if (lastUpdatesIn > 0) {
              localTimeoutRef.current = setTimeout(() => {
                load();
              }, lastUpdatesIn);
            }
          }
        } catch (error) {
          console.error(error);
          ToastManager.handleError(error);
        }
      }

      load();
    })();

    return function () {
      clearTimeout(localTimeoutRef.current);
    };
  }, [mount, fetchEntries, resolutionId]);

  return { data, currentLoadedResolutionId };
}
