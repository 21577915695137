import React from 'react';

import { PairingDialog } from './PairingDialog';
import { AddDeviceDialog } from './add-device-dialog/AddDeviceDialog';
import { RouteManager } from '../../RouteManager';

export function PairingDelegator(props) {
  function handleClose() {
    RouteManager.pushState({});
  }

  switch (props.state.type) {
    case 'pair':
      return <AddDeviceDialog onClose={handleClose} zoneId={props.zoneId} />;
    case 'repair':
    case 'unpair':
      return (
        <PairingDialog
          type={props.state.type}
          driverKey={props.state.driverKey}
          deviceId={props.state.deviceId}
          onClose={handleClose}
        />
      );
    default:
      return null;
  }
}
