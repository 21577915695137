import { useMemo } from 'react';

export function useTreeUsedTokenKeys({ fromNodeId, advancedFlow, nodeChildren, nodeAncestors }) {
  return useMemo(() => {
    const treeTokenKeys = getTreeUsedTokenKeys({
      fromNodeId,
      advancedFlow,
      nodeChildren,
      nodeAncestors,
    });

    const canTestWithoutTokens = Object.keys(treeTokenKeys).length === 0;

    return { treeTokenKeys, canTestWithoutTokens };
  }, [fromNodeId, advancedFlow, nodeChildren, nodeAncestors]);
}

function getTreeUsedTokenKeys({ fromNodeId, advancedFlow, nodeChildren, nodeAncestors }) {
  const tokens = {};

  const fromNodeAncestors = nodeAncestors[fromNodeId] ?? [];

  if (fromNodeId == null) {
    return Object.keys(tokens);
  }

  function checkNode({ nodeId }) {
    const cardData = advancedFlow.cards[nodeId];

    if (cardData == null) {
      console.error(`Missing card data: ${nodeId}`);
      return;
    }

    const argsTokenKeys = getUsedTokensKeys(cardData);

    for (const tokenKey of argsTokenKeys) {
      // eslint-disable-next-line no-unused-vars
      const [sourceType, sourceId, tokenId] = tokenKey.split('::');

      if (
        // If the token is generated before this fromNodeId.
        fromNodeAncestors.some((ancestor) => ancestor.id === sourceId) === true ||
        // If the token is generated in this fromNodeId. Action sourceType and card sourceType are
        // omitted because these are always generated by the cards.
        (sourceId === fromNodeId && sourceType === 'trigger')
      ) {
        tokens[tokenKey] = true;
      }
    }
  }

  function traverseChildren({ nodeId }) {
    const children = nodeChildren[nodeId] ?? [];

    for (const node of children) {
      checkNode({ nodeId: node.id });
      traverseChildren({ nodeId: node.id });
    }
  }

  checkNode({ nodeId: fromNodeId });
  traverseChildren({ nodeId: fromNodeId });

  return Object.keys(tokens);
}

function getBracketMatches(string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /\[\[([^\[\]]*?)\]\]/g;
  return string.match(regex);
}

export function getUsedTokensKeys({ args, droptoken }) {
  const tokens = {};

  if (droptoken != null) {
    if (
      droptoken.startsWith('trigger::') ||
      droptoken.startsWith('action::') ||
      droptoken.startsWith('card::')
    ) {
      tokens[droptoken] = true;
    }
  }

  // We should probably check for the kind of argument here.

  // eslint-disable-next-line no-unused-vars
  for (const [argumentKey, argument] of Object.entries(args ?? {})) {
    if (typeof argument === 'string') {
      const matches = getBracketMatches(argument);

      if (matches != null) {
        // eslint-disable-next-line no-unused-vars
        for (const [index, match] of matches.entries()) {
          const value = match.substring(2).slice(0, -2);

          if (
            value.startsWith('trigger::') ||
            value.startsWith('action::') ||
            value.startsWith('card::')
          ) {
            tokens[value] = true;
          }
        }
      }
    }
  }

  return Object.keys(tokens);
}
