import { useRef } from 'react';
import styled from '@emotion/styled';
import { Item, useListState } from 'react-stately';
import { useTagGroup } from 'react-aria';

import { Tag } from './Tag';

export function TagGroup(props) {
  const ref = useRef(null);
  const state = useListState(props);
  const tagGroup = useTagGroup(props, state, ref);

  return (
    <S.Root>
      {/* <div {...tagGroup.labelProps}>{props.label}</div> */}

      <S.TagsContainer {...tagGroup.gridProps} ref={ref}>
        {[...state.collection].map((item) => (
          <Tag key={item.key} item={item} state={state} />
        ))}
      </S.TagsContainer>

      {props.description && <div {...tagGroup.descriptionProps}>{props.description}</div>}

      {props.errorMessage && <div {...tagGroup.errorMessageProps}>{props.errorMessage}</div>}
    </S.Root>
  );
}

function S() {}
TagGroup.S = S;
TagGroup.Item = Item;

S.Root = styled.div`
  overflow: hidden;
  min-width: 60px;
`;

S.TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: 4px;
`;
