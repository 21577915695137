import { useRef, useState, createContext } from 'react';

export const GlobalOverlayContext = createContext(null);
export const GlobalOverlayActiveContext = createContext(null);

class GlobalOverlay {
  constructor({ setActiveContextState }) {
    this.setActiveContextState = setActiveContextState;
  }

  setActiveContext(context) {
    this.setActiveContextState(context);
  }
}

export function GlobalOverlayContextProvider(props) {
  const context = useRef(null);
  const [activeContextState, setActiveContextState] = useState(null);

  if (context.current == null) {
    context.current = new GlobalOverlay({ setActiveContextState });
  }

  return (
    <GlobalOverlayContext.Provider value={context.current}>
      <GlobalOverlayActiveContext.Provider value={activeContextState}>
        {props.children}
      </GlobalOverlayActiveContext.Provider>
    </GlobalOverlayContext.Provider>
  );
}
