import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

import { ResourceUtils } from '../ResourceUtils';
import { ImageUtils } from './ImageUtils';
import { I18NStore } from '../../providers/LocaleProvider';

export class ImageStore extends BaseApiStore {
  static key = 'images';
  static store = this.createStore(this.key);
  static imageCacheId = 'HomeyAPI.ManagerImages.Image';

  static createInitialState() {
    return {
      data: null,
      byKey: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info(`fetch:${this.key}`);
    this.destroy('before:fetch');
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerImages = state.api.images;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const imagesPromise = managerImages.getImages({
        $skipCache: skipCache,
      });
      const collatorPromise = I18NStore.getCollator();

      const [, imagesData, collator] = await Promise.all([
        waitPromise,
        imagesPromise,
        collatorPromise,
      ]);

      const values = Object.values(imagesData);
      values.sort(ImageUtils.compareImage.bind(ImageUtils, collator));

      const result = values.reduce(
        (accumulator, image, index) => {
          const imageReference = { ...image };
          const key = ResourceUtils.getKey(imageReference);

          accumulator.data[key] = image;
          accumulator.byKey[key] = imageReference;

          return accumulator;
        },
        {
          data: {},
          byKey: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byKey: result.byKey,
        loading: false,
        manager: managerImages,
      });

      managerImages.addListener('image.create', this.handleCreate);
      managerImages.addListener('image.update', this.handleUpdate);
      managerImages.addListener('image.delete', this.handleDelete);
    } catch (error) {
      this.destroy('error');
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy(reason) {
    __DEV__ && console.info(`destroy:${this.key}:${reason ?? ''}`);
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerImages = state.api.images;

      managerImages.removeListener('image.create', this.handleCreate);
      managerImages.removeListener('image.update', this.handleUpdate);
      managerImages.removeListener('image.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(image, stateArg) {
    const state = stateArg ?? this.get();
    const cache = state.api.images._caches[this.imageCacheId];

    return cache.getOne({
      id: cache._guessCacheId(image),
    });
  }

  static handleCreate = (createdImage) => {
    const key = ResourceUtils.getKey(createdImage);
    __DEV__ && console.info(`create:image:${key}`);
    const state = this.get();
    const imageInstance = this.getInstanceFromCache(createdImage, state);

    if (imageInstance == null) return;

    const imageReference = { ...imageInstance };

    this.set({
      data: {
        ...state.data,
        [key]: imageInstance,
      },
      byKey: {
        ...state.byKey,
        [key]: imageReference,
      },
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedImage) => {
    const key = ResourceUtils.getKey(updatedImage);
    __DEV__ && console.info(`update:image:${key}`);

    this.updateQueue[key] = { ...updatedImage };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedImages = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextByKey = {
          ...state.byKey,
        };

        let updateCount = 0;

        for (const [key, image] of Object.entries(queuedImages)) {
          const imageInstance = this.getInstanceFromCache(image, state);

          if (imageInstance == null || state.data[key] == null) {
            __DEV__ && console.info(`update:image:unknown:${key}`);
            continue;
          }

          const imageReference = { ...imageInstance };

          nextByKey[key] = imageReference;

          updateCount++;
        }

        __DEV__ && console.info(`update:image:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byKey: nextByKey,
          });
      }, 200);
    }
  };

  static handleDelete = (deletedImage) => {
    const key = ResourceUtils.getKey(deletedImage);
    __DEV__ && console.info(`delete:image:${key}`);
    const state = this.get();
    const imageInstance = state.data[key];

    if (imageInstance == null) return;

    const nextData = { ...state.data };
    const nextByKey = { ...state.byKey };

    delete nextData[key];
    delete nextByKey[key];

    this.set({
      data: nextData,
      byKey: nextByKey,
    });
  };
}
