import React from 'react';

import { Layout } from '../../Layout';

// import { useVariable, useVariablesByType } from '../../store/logic/useLogic';

export function LogicPage() {
  // const variables = useVariablesByType();

  return <Layout />;
}

// function Variable(props) {
//   const { variable } = useVariable({ variableId: props.variableId });
//
//   return (
//     <div>
//       {variable?.name} {variable?.value}
//     </div>
//   );
// }

export default LogicPage;
