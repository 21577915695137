import { proxy as color } from '../color.palette';

/**
 *
 */
export const gradientButton = {
  background_position_default: '33% top',
  background_position_hover: '66% top',
  background_position_active: '15% top',
  background_size: '200% 100%',
  background_color_blue: `linear-gradient(30deg, ${color.gradient_blue_active}, ${color.gradient_blue_start} 33%, ${color.gradient_blue_end} 66%, ${color.gradient_blue_hover})`,
  background_color_pink: `linear-gradient(30deg, ${color.gradient_pink_active}, ${color.gradient_pink_start} 33%, ${color.gradient_pink_end} 66%, ${color.gradient_pink_hover})`,
  background_color_purple: `linear-gradient(30deg, ${color.gradient_purple_active}, ${color.gradient_purple_start} 33%, ${color.gradient_purple_end} 66%, ${color.gradient_purple_hover})`,
}
