import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../../components/common/Icon';

import { iconPlusBold } from '../../../theme/icons/interface/plus-bold';

const Section = styled.section`
  margin: 30px 0;
`;

const Title = styled.h3`
  margin: ${su(2)} 0;

  ${(props) =>
    props.isNonActive &&
    css`
      color: ${theme.color.text_light};
    `}
`;

const Content = styled.div`
  //margin-top: ${su(2)}; // we can't have modular margins because of animation
  //margin-bottom: ${su(2)};
  position: relative;
`;

const ButtonCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.color.mono_300};
  width: 20px;
  height: 20px;
  margin-right: ${su(1)};

  ${Icon.S.Root} {
    background-color: ${theme.color.mono_020};
  }
`;

const Button = styled.button`
  //margin: ${su(2)} 0; // we can't have modular margins because of animation
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 20px;
  color: ${theme.color.text_light};
  background: ${theme.color.mono_020};
  border-radius: ${theme.borderRadius.default};
  transition: transform ${theme.transition.micro_partial};

  &:hover {
    color: ${theme.color.text_light_hover};
    background-color: ${theme.color.mono_050};

    ${ButtonCircle} {
      background-color: ${theme.color.mono_300};
    }

    transform: scale(1.04);
  }

  &:hover:active {
    transform: scale(1);
  }
`;

export function AddCardButton(props) {
  return (
    <Button onClick={props.onClick}>
      <ButtonCircle>
        <Icon size={theme.icon.size_tiny} url={iconPlusBold} color={theme.color.text_light} />
      </ButtonCircle>
      {props.children}
    </Button>
  );
}

export const flowCardTypes = {
  Section,
  Title,
  Content,
};
