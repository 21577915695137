import { theme } from '../theme';

/**
 * Default SpaceUnit
 * @param value
 * @returns {string}
 */
export function su(...values) {
  const rootOld = theme.sud.root_old;
  let result = '';

  for (let i = 0; i < values.length; i++) {
    result += `calc(${rootOld} * ${values[i]})`;
    if (i < values.length - 1) {
      result += ' ';
    }
  }

  return result;
}

export const suOld = su;

export function suNew(...values) {
  const root = theme.sud.root;
  let result = '';

  for (let i = 0; i < values.length; i++) {
    result += `calc(${root} * ${values[i]})`;
    if (i < values.length - 1) {
      result += ' ';
    }
  }

  return result;
}
