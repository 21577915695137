import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

export function FlowQuotes() {
  const quotes = [
    {
      content: 'Creativity is just connecting things',
      author: 'Steve Jobs',
    },
    {
      content: "You can't use up creativity. The more you use, the more you have.",
      author: 'Maya Angelou',
    },
    {
      content: 'Design is thinking made visual.',
      author: 'Saul Bass',
    },
    {
      content: 'If I had asked people what they wanted, they would have said faster horses.',
      author: 'Henry Ford',
    },
    {
      content: 'The best way to predict the future is to create it',
      author: 'Abraham Lincoln',
    },
    {
      content:
        'Imagination is the beginning of creation. You imagine what you desire, you will what you imagine, and at last, you create what you will.',
      author: 'George Bernard Shaw',
    },
    {
      content:
        'Everyone who’s ever taken a shower has had an idea. It’s the person who gets out of the shower, dries off, and does something about it who makes a difference.',
      author: 'Nolan Bushnell',
    },
    {
      content: 'Look at usual things with unusual eyes.',
      author: 'Vico Magistretti',
    },
    {
      content:
        'There is no doubt that creativity is the most important human resource of all. Without creativity, there would be no progress, and we would be forever repeating the same patterns.',
      author: 'Edward de Bono',
    },
    {
      content: 'Creativity is nothing but the way to solve new problems.',
      author: 'Diana Santos',
    },
    {
      content: 'Simplicity is the ultimate sophistication.',
      author: 'Leonardo da Vinci',
    },
    {
      content:
        'The most profound technologies are those that disappear. They weave themselves into the fabric of everyday life until they are indistinguishable from it.',
      author: 'Mark Weiser',
    },
    {
      content: "I have not failed. I've just found 10,000 ways that won't work",
      author: 'Thomas Edison',
    },
  ];

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const ref = useRef({
    random: getRandomInt(0, quotes.length - 1),
  });

  return (
    <FlowQuotes.Root>
      <FlowQuotes.Content>{quotes[ref.current.random].content}</FlowQuotes.Content>
      <FlowQuotes.Author>{quotes[ref.current.random].author}</FlowQuotes.Author>
    </FlowQuotes.Root>
  );
}

// todo make common component
FlowQuotes.Root = styled.figure`
  margin-bottom: ${su(4)};
  text-align: center;
`;

FlowQuotes.Content = styled.blockquote`
  font-size: ${theme.fontSize.heading2};
  font-weight: ${theme.fontWeight.medium};
  font-style: italic;
  max-width: 35ch;
  line-height: 1.66;
  margin-bottom: ${su(1)};
  color: ${theme.color.text_body_light};

  &::before {
    content: '“';
  }

  &::after {
    content: '”';
  }
`;

FlowQuotes.Author = styled.figcaption`
  color: ${theme.color.text_body_light};

  &::before {
    content: '— ';
  }
`;
