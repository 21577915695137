import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { theme } from '../../theme/theme';

export function Battery({ percentage }) {
  return (
    <Battery.Root>
      <Battery.Status percentage={percentage} />
    </Battery.Root>
  );
}

Battery.Root = styled.div`
  border: 1px solid ${theme.color.mono_300};
  width: 24px;
  height: 12px;
  border-radius: 4px;
  position: relative;
  display: inline-flex;

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    width: 2px;
    background: ${theme.color.mono_300};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left: 100%;
    top: 50%;
    transform: translate(2px, -50%);
  }
`;

Battery.Status = styled.div`
  width: 100%;
  margin: 1px;
  border-radius: 2px;
  background: ${theme.color.green};

  // if percentage
  ${(props) =>
    props.percentage &&
    css`
      width: ${props.percentage}%;
    `};

  // if below 25%
  ${(props) =>
    props.percentage < 25 &&
    css`
      background: ${theme.color.red};
    `};

  // if no percentage
  ${(props) =>
    props.percentage == null &&
    css`
      background: repeating-linear-gradient(
        45deg,
        ${theme.color.mono_200},
        ${theme.color.mono_200} 5px,
        ${theme.color.mono_100} 5px,
        ${theme.color.mono_100} 10px
      );
    `};
`;
