import React, { useRef } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { useTreeUsedTokenKeys } from '../useTreeTokenKeys';

import { Overlay } from '../../../../components/overlay/Overlay';
import { AnimationRemain } from '../../../../components/animation/AnimationRemain';
import { PlayButton } from '../../../../components/buttons/PlayButton';

import { AdvancedFlowTestForm } from '../AdvancedFlowTestForm';

export function AdvancedFlowCardTestButton(props) {
  const ref = useRef();
  const overlayTriggerState = useOverlayTriggerState({ defaultOpen: props.defaultOpen });
  const overlayTrigger = useOverlayTrigger({ type: 'menu' }, overlayTriggerState, ref);
  // const advancedFlowViewContext = useAdvancedFlowViewContext();

  const { advancedFlow, nodeChildren, nodeAncestors } = AdvancedFlowViewStore.useAdvancedFlowView();
  const { treeTokenKeys, canTestWithoutTokens } = useTreeUsedTokenKeys({
    fromNodeId: props.nodeId,
    advancedFlow,
    nodeChildren,
    nodeAncestors,
  });

  function handlePress(event) {
    if (props.isTestingRoot) {
      AdvancedFlowViewStore.set({
        conditionTest: AdvancedFlowViewStore.conditionTest.stopped,
      });
      return;
    }

    if (canTestWithoutTokens === true) {
      props.onStartRequest({ nodeId: props.nodeId });
      return;
    }

    overlayTriggerState.open();
  }

  return (
    <>
      <PlayButton
        {...overlayTrigger.triggerProps}
        ref={ref}
        isTestingRoot={props.isTestingRoot}
        isDisabled={props.isTestingRoot === false ? props.isDisabled : false}
        isDisabledStyle={props.isDisabledStyle}
        onPress={handlePress}
      />

      <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={ref}
              overlayProps={overlayTrigger.overlayProps}
              overlayTriggerState={overlayTriggerState}
              offset={30}
              animationRemainProps={animationRemainProps}
              autoFocus={true}
              restoreFocus={true}
            >
              <AdvancedFlowTestForm
                currentNodeId={props.nodeId}
                treeTokenKeys={treeTokenKeys}
                advancedFlow={advancedFlow}
                onStartRequest={({ tokens }) => {
                  overlayTriggerState.close();
                  props.onStartRequest({ nodeId: props.nodeId, tokens });
                }}
              />
            </Overlay>
          );
        }}
      </AnimationRemain>
    </>
  );
}
