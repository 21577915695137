import tinycolor from 'tinycolor2';

export function getColorForTemperature({ light_temperature }) {
  let red = 0;
  let green = 0;
  let blue = 0;

  if (light_temperature < 0.125) {
    red = 207;
    green = 255;
    blue = 255;
  } else {
    // for red
    if (light_temperature >= 0.5) {
      red = 255;
    } else {
      red = 285 * Math.pow(light_temperature, 0.154);
    }

    // for green
    if (light_temperature <= 0.5) {
      green = 255;
    } else {
      green = 217 * Math.pow(light_temperature, -0.234);
    }

    // for blue
    if (light_temperature <= 0.5) {
      blue = 255;
    } else {
      blue = 116 * Math.pow(light_temperature, -1.2);
    }
  }

  return tinycolor.fromRatio({
    r: Math.round(red),
    g: Math.round(green),
    b: Math.round(blue),
  });
}

export function getColorForHueSaturation({ light_hue, light_saturation }) {
  return tinycolor.fromRatio({
    h: light_hue * 360,
    s: light_saturation * 100,
    v: 1,
  });
}

export function getDefaultColor() {
  return tinycolor('#ffda74');
}

export function hasColorMode({ light_hue, light_saturation }) {
  return light_hue !== undefined && light_saturation !== undefined;
}

export function hasTemperatureMode({ light_temperature }) {
  return light_temperature !== undefined;
}

export function getMode({
  light_mode,
  light_temperature,
  light_hue,
  light_saturation,
}) {
  switch (true) {
    case light_mode != null:
      return light_mode;
    case light_temperature === null &&
      light_hue === null &&
      light_saturation === null:
      return 'temperature';
    case light_temperature === undefined &&
      light_saturation !== undefined &&
      light_hue !== undefined:
      return 'color';
    default:
      return 'temperature';
  }
}
