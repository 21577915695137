import React, { useState, useCallback, createContext, useRef, useContext } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { scrollbarDark } from '../../theme/elements/scrollbars';

import { Column } from './Column';

const ColumnViewContext = createContext();

export function useColumnViewContext() {
  return useContext(ColumnViewContext);
}

export function ColumnView(props) {
  const context = useRef({
    columns: new Map(),
    register(index, ref) {
      this.columns.set(index, ref);
    },
    unregister(index) {
      this.columns.delete(index);
    },
  });

  const [state, setState] = useState(props.getDefaultColumns);

  const push = useCallback(function push(entry, index) {
    setState((prevState) => {
      const prev = prevState.slice(0, index + 1);
      return [...prev, entry];
    });
  }, []);

  const slice = useCallback(function slice(index) {
    setState((prevState) => {
      const prev = prevState.slice(0, index + 1);
      return [...prev];
    });
  }, []);

  return (
    <ColumnViewContext.Provider value={context.current}>
      <ColumnView.Root>
        <ColumnView.Container style={{ '--number-of-columns': state.length }}>
          {state.map((entry, index, array) => {
            const next = array[index + 1];
            const prev = array[index - 1];

            return (
              <Column
                columnProps={props.columnProps}
                key={entry.key}
                index={index}
                entry={entry}
                next={next}
                prev={prev}
                push={push}
                slice={slice}
                renderItem={props.renderItem}
                renderSection={props.renderSection}
                getColumnComponent={props.getColumnComponent}
              />
            );
          })}
        </ColumnView.Container>
      </ColumnView.Root>
    </ColumnViewContext.Provider>
  );
}

ColumnView.Root = styled.div`
  ${scrollbarDark};
  display: flex;
  flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: ${theme.borderRadius.default};
`;

ColumnView.Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  min-width: calc(var(--number-of-columns) * 380px);
  transition: min-width ${theme.duration.slow} ${theme.curve.easeInOut};
`;
