import React, { createContext, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { scrollbarDark } from '../../theme/elements/scrollbars';
import { theme } from '../../theme/theme';
import { mergeRefs } from '../../lib/mergeRefs';

export const ScrollContext = createContext(null);

export function useScrollRef() {
  return React.useContext(ScrollContext);
}

export const Scroll = forwardRef(function (
  {
    className,
    containerElementType,
    detectElementType,
    children,
    style,
    shadowTop = true,
    shadowBottom = true,
    scrollX = false,
    ...rest
  },
  forwardedRef
) {
  const scrollRef = useRef();
  const THRESHOLD = [0, 0.05, 0.1, 0.2, 0.4, 0.6, 0.8, 0.9, 0.95];

  const [refTop, inViewTop, entryTop] = useInView({
    threshold: THRESHOLD,
    trackVisibility: true,
    delay: 100,
    root: forwardedRef?.current,
  });

  const [refBottom, inViewBottom, entryBottom] = useInView({
    threshold: THRESHOLD,
    trackVisibility: true,
    delay: 100,
    root: forwardedRef?.current,
  });

  const toOpacity = (value) => {
    return 1 - Math.ceil(value * 10) / 10;
  };

  return (
    <ScrollContext.Provider value={scrollRef}>
      <Container
        {...rest}
        tabindex="0"
        ref={mergeRefs([forwardedRef, scrollRef])}
        as={containerElementType}
        style={style}
        className={`${className ?? ''}
        ${inViewTop ? 'is-scroll-top' : ''}
        ${inViewBottom ? 'is-scroll-bottom' : ''}`}
        inViewTop={inViewTop}
        inViewBottom={inViewBottom}
        shadowTop={shadowTop}
        shadowBottom={shadowBottom}
        opacityTop={entryTop ? toOpacity(entryTop.intersectionRatio) : 0}
        opacityBottom={entryBottom ? toOpacity(entryBottom.intersectionRatio) : 0}
        scrollX={scrollX}
      >
        <Detect as={detectElementType} ref={refTop} />
        <Content>{children}</Content>
        <Detect as={detectElementType} ref={refBottom} />
      </Container>
    </ScrollContext.Provider>
  );
});

Scroll.propTypes = {
  flex: PropTypes.bool,
  className: PropTypes.string,
  containerElementType: PropTypes.string,
  detectElementType: PropTypes.string,
  children: PropTypes.any,
};

const Container = styled(motion.div)`
  ${scrollbarDark};
  --display: ${(props) => (props.flex ? 'flex' : 'block')};

  overflow-y: auto;
  overflow-x: ${(props) => (props.scrollX ? 'auto' : 'hidden')};

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    content: '';
    pointer-events: none;
    min-height: 20px;
    height: 20px;
    width: 100%;
    z-index: 1;
    display: block;
    position: sticky;
    opacity: 0;
    transition: ${theme.transition.micro};
  }

  &::before {
    bottom: calc(
      100% - 20px
    ); /* fix for safari to avoid the element from stop being sticky after visible height is passed */
    margin-top: -20px;
    order: 1; /* fix for safari to avoid the element from stop being sticky after visible height is passed*/
    background: radial-gradient(ellipse at 50% 0%, ${theme.color.scroll_shadow}, transparent 66%);
  }

  &::after {
    bottom: 0;
    margin-top: -20px;
    order: 2; /* fix for safari to avoid the element from stop being sticky after visible height is passed*/
    background: radial-gradient(ellipse at 50% 100%, ${theme.color.scroll_shadow}, transparent 66%);
  }

  ${(props) =>
    props.shadowTop === true &&
    css`
      &::before {
        opacity: ${props.opacityTop};
      }
    `};

  ${(props) =>
    props.shadowBottom === true &&
    css`
      &::after {
        opacity: ${props.opacityBottom};
      }
    `};
`;

const Detect = styled.div`
  min-height: 50px;
  height: 50px;
  width: 1px; // width of 1px so scroll shadow won't appear on bottom/top when it doesn't fit horizontal

  &:first-of-type {
    margin-bottom: -50px;
  }

  &:last-of-type {
    margin-top: -50px;
  }
`;

/**
 * Content
 * @description content element is required to force a minimal height so the 'shadows' won't be applied when there is no content yet.
 */
const Content = styled.div`
  flex: 1 1 auto;
  position: relative;
  z-index: 0;
  display: var(--display);
`;

export function scrollIntoView(scrollView, element) {
  let offsetX = relativeOffset(scrollView, element, 'left');
  let offsetY = relativeOffset(scrollView, element, 'top');
  let width = element.offsetWidth;
  let height = element.offsetHeight;
  let x = scrollView.scrollLeft;
  let y = scrollView.scrollTop;
  let maxX = x + scrollView.offsetWidth;
  let maxY = y + scrollView.offsetHeight;

  if (offsetX <= x) {
    x = offsetX;
  } else if (offsetX + width > maxX) {
    x += offsetX + width - maxX;
  }
  if (offsetY <= y) {
    y = offsetY;
  } else if (offsetY + height > maxY) {
    y += offsetY + height - maxY;
  }

  scrollView.scrollLeft = x;
  scrollView.scrollTop = y;
}

/**
 * Computes the offset left or top from child to ancestor by accumulating
 * offsetLeft or offsetTop through intervening offsetParents.
 */
function relativeOffset(ancestor, child, axis) {
  const prop = axis === 'left' ? 'offsetLeft' : 'offsetTop';
  let sum = 0;
  while (child.offsetParent) {
    sum += child[prop];
    if (child.offsetParent === ancestor) {
      // Stop once we have found the ancestor we are interested in.
      break;
    } else if (child.offsetParent.contains(ancestor)) {
      // If the ancestor is not `position:relative`, then we stop at
      // _its_ offset parent, and we subtract off _its_ offset, so that
      // we end up with the proper offset from child to ancestor.
      sum -= ancestor[prop];
      break;
    }
    child = child.offsetParent;
  }
  return sum;
}
