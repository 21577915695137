import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import { SCOPES } from '../../../lib/scopes';
import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';

import { useApi } from '../../../store/HomeyStore';
import { useEffectEvent } from '../../../hooks/useEffectEvent';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { scrollbars } from '../../../theme/elements/scrollbars';

import { RoundIconWrapper } from '../../../components/common/Icon';
import { OutlineButton } from '../../../components/buttons/OutlineButton';
import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';
import { Icon } from '../../../components/common/Icon';
import { CenteredMessage } from '../../../components/common/CenteredMessage';
import { SpinnerLoading } from '../../../components/common/SpinnerLoading';
import { ExternalLink } from '../../../components/common/link/ExernalLink';

import { ApiKeysSettingsDialogCreate } from './ApiKeysSettingsDialogCreate';
import { ApiKeysSettingsDialogView } from './ApiKeysSettingsDialogView';
import { ApiKeySettingsDialogDelete } from './ApiKeysSettingsDialogDelete';
import { ApiKeysSettingsContextMenuContent } from './ApiKeysSettingsContextMenuContent';

import { iconApiKeys } from '../../../theme/icons-v2/system/api-keys/api-keys';
import { iconPlusMedium } from '../../../theme/icons/interface/plus-medium';

export function ApiKeysSettings() {
  const { i18n } = useI18n();
  const { api, scopes } = useApi();

  // Matches the parent route.
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const [pats, setPats] = useState(null);

  const refresh = useEffectEvent(() => {
    if (api && scopes[SCOPES.EVERYTHING] === true) {
      api.users
        ._call('GET', 'pat', {
          opts: { $timeout: 40000 },
          body: undefined,
          queryParameters: {},
        })
        .then((result) => {
          setPats(result);
        })
        .catch(ToastManager.handleError);
    }
  });

  useEffect(() => {
    refresh();
  }, [refresh]);

  const patsList = Object.values(pats ?? {});

  function getRoot() {
    if (scopes[SCOPES.EVERYTHING] !== true) {
      return (
        <S.Root>
          <CenteredMessage
            title={i18n.messageFormatter('common.insufficientPermissions')}
            subtitleColor={theme.color.text_light}
            subtitle={i18n.messageFormatter('common.insufficientPermissionsViewSubtitle', {
              name: i18n.messageFormatter('settings.system.apiKeys'),
            })}
          />
        </S.Root>
      );
    }

    if (pats == null) {
      return (
        <S.Root>
          <CenteredMessage
            icon={<SpinnerLoading color={theme.color.text_light} size={40} />}
            subtitleColor={theme.color.text_light}
            subtitle={i18n.messageFormatter('status.loading')}
          />
        </S.Root>
      );
    }

    if (patsList.length === 0) {
      return (
        <S.Root data-center="true">
          <S.WrapperEmpty>
            <RoundIconWrapper size="80px" color={theme.color.system_api_keys}>
              <Icon size="32px" url={iconApiKeys} color={theme.color.white} />
            </RoundIconWrapper>

            <div>
              <h3>{i18n.messageFormatter('settings.system.apiKeys')}</h3>
              <p>{i18n.messageFormatter('settings.system.apiKeysNoKeys')}</p>
            </div>

            <S.ButtonWrapper>
              <OutlineButton
                styleWidth="full"
                styleIconColorDisabled={true}
                onPress={() => {
                  RouteManager.toCreatePat();
                }}
              >
                <RoundIconWrapper size={theme.icon.size_default} color={theme.color.blue}>
                  <OutlineButton.Icon
                    url={iconPlusMedium}
                    size={theme.icon.size_tiny}
                    color={theme.color.white}
                  />
                </RoundIconWrapper>

                <OutlineButton.Text>
                  {i18n.messageFormatter('settings.system.apiKeysNew')}
                </OutlineButton.Text>
              </OutlineButton>
            </S.ButtonWrapper>

            <ExternalLink url="https://support.homey.app/hc/articles/8178797067292-Getting-started-with-API-Keys">
              {i18n.messageFormatter('settings.system.apiKeysHelp')}
            </ExternalLink>
          </S.WrapperEmpty>
        </S.Root>
      );
    }

    return (
      <S.Root>
        <S.Wrapper>
          <S.Title>
            {i18n.messageFormatter('settings.system.apiKeys')}{' '}
            <ExternalLink url="https://support.homey.app/hc/articles/8178797067292-Getting-started-with-API-Keys">
              {i18n.messageFormatter('common.help')}
            </ExternalLink>
          </S.Title>

          <S.Container>
            {patsList.length > 0 &&
              patsList.map((pat) => {
                return (
                  <ContextMenu
                    key={pat.id}
                    content={<ApiKeysSettingsContextMenuContent pat={pat} />}
                  >
                    {({ isOpen, onContextMenu }) => {
                      return (
                        <S.Row onContextMenu={onContextMenu}>
                          <Icon.RoundWrapper size="24px" color={ApiKeysSettings.backgroundColor}>
                            <Icon
                              url={iconApiKeys}
                              size={theme.icon.size_small}
                              color={theme.color.white}
                            />
                          </Icon.RoundWrapper>

                          <S.Name>{pat.name}</S.Name>
                          <S.Id>{`...${pat.id}...`}</S.Id>
                          <S.Actions>
                            <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
                          </S.Actions>
                        </S.Row>
                      );
                    }}
                  </ContextMenu>
                );
              })}
          </S.Container>

          <S.ButtonWrapper>
            <OutlineButton
              styleWidth="full"
              styleIconColorDisabled={true}
              onPress={() => {
                RouteManager.toCreatePat();
              }}
            >
              <RoundIconWrapper size={theme.icon.size_default} color={theme.color.blue}>
                <OutlineButton.Icon
                  url={iconPlusMedium}
                  size={theme.icon.size_tiny}
                  color={theme.color.white}
                />
              </RoundIconWrapper>

              <OutlineButton.Text>
                {i18n.messageFormatter('settings.system.apiKeysNew')}
              </OutlineButton.Text>
            </OutlineButton>
          </S.ButtonWrapper>
        </S.Wrapper>
      </S.Root>
    );
  }

  const routes = (
    <AnimatePresence mode="wait">
      <Switch location={location} key={location.pathname}>
        <Route
          path={`${routeMatch.path}/create`}
          render={() => {
            function handleCloseRequest() {
              RouteManager.toPath(`${routeMatch.url}`);
            }

            return (
              <ApiKeysSettingsDialogCreate refresh={refresh} onCloseRequest={handleCloseRequest} />
            );
          }}
        />

        <Route
          path={`${routeMatch.path}/:patId/delete`}
          render={() => {
            function handleCloseRequest() {
              RouteManager.toPath(`${routeMatch.url}`);
            }

            return (
              <ApiKeySettingsDialogDelete
                refresh={refresh}
                pats={pats}
                onCloseRequest={handleCloseRequest}
              />
            );
          }}
        />

        <Route
          path={`${routeMatch.path}/:patId`}
          render={() => {
            function handleCloseRequest() {
              RouteManager.toPath(`${routeMatch.url}`);
            }

            return <ApiKeysSettingsDialogView pats={pats} onCloseRequest={handleCloseRequest} />;
          }}
        />
      </Switch>
    </AnimatePresence>
  );

  return (
    <>
      {getRoot()}
      {routes}
    </>
  );
}

ApiKeysSettings.pathKey = 'system/api-keys';
ApiKeysSettings.icon = iconApiKeys;
ApiKeysSettings.backgroundColor = theme.color.system_api_keys;

function S() {}
ApiKeysSettings.S = S;

S.Root = styled.div`
  ${scrollbars.dark}
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 20px;
  overflow-y: auto;
  padding-top: 30px;

  &[data-center='true'] {
    justify-content: center;
  }

  ${ContextMenuButton.Root} {
    opacity: 1;

    ${Icon.S.Root} {
      transition: background-color ${theme.transition.micro_partial};
      background-color: ${theme.color.mono_400};
    }
  }

  ${ContextMenuButton.Root}[data-is-hovered='true'] {
    ${Icon.S.Root} {
      background-color: ${theme.color.icon_dark};
    }
  }
`;

S.Wrapper = styled.div`
  width: min(calc(100% - 40px), 720px);
`;

S.WrapperEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(calc(100% - 40px), 720px);

  h3 {
    text-align: center;
    margin-top: 20px;
  }

  p {
    color: ${theme.color.text_light};
    margin: 10px 0 20px;
  }

  a {
    margin-top: 20px;
  }
`;

S.Title = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  padding: 0 20px 16px;
`;

S.Message = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.default};
  padding: 20px;
`;

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  border-radius: ${theme.borderRadius.default};
`;

S.Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding-left: 20px;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${theme.color.line};
  }
`;

S.Name = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

S.Id = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.default};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

S.Actions = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

S.ButtonWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;
