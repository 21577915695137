import { useRef } from 'react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';

import { ToastManager } from '../../ToastManager';
import { dragTypes } from '../../components/dnd/dragTypes';

import { useEffectEvent } from '../../hooks/useEffectEvent';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useDevicesByZoneId } from '../../store/devices/useDevices';
import { useApi } from '../../store/HomeyStore';
import { useZonesTree } from '../../store/zones/useZones';
import { useMoodsByZoneId } from '../../store/moods/useMoods';

import { ZoneView, ZoneViewContentLoader } from './ZoneView';
import { CenteredMessage } from '../../components/common/CenteredMessage';
import { SolidButton } from '../../components/buttons/SolidButton';

export function ZonesView(props) {
  const { api } = useApi();

  const zones = useZonesTree();
  const devices = useDevicesByZoneId();
  const moods = useMoodsByZoneId();

  const { i18n } = useI18n();
  const ref = useRef();
  // TODO: better drag scrolling
  // to allow scrolling, this is a hack
  const [, dropRef] = useDrop({
    accept: [dragTypes.DEVICE],
  });
  dropRef(ref);

  const onDrop = useEffectEvent(({ type, dropId, dragId }) => {
    api.devices
      .updateDevice({
        id: dragId,
        device: {
          zone: dropId,
        },
      })
      .then(() => {})
      .catch(ToastManager.handleError);
  });

  if (devices.loading === true || zones.isLoading === true || moods.isLoading === true) {
    return (
      <S.Root>
        <ZoneViewContentLoader deviceCount={4} tileSize={props.tileSize} moodCount={3} />
        <ZoneViewContentLoader deviceCount={3} tileSize={props.tileSize} moodCount={2} />
        <ZoneViewContentLoader deviceCount={2} tileSize={props.tileSize} moodCount={1} />
      </S.Root>
    );
  }

  if (devices.error != null || zones.error != null || moods.error != null) {
    return (
      <CenteredMessage
        title={i18n.messageFormatter(`errors.generic`)}
        action={
          <SolidButton
            styleColor="green"
            onPress={() => {
              if (zones.error) zones.retry();
              if (devices.error) devices.retry();
              if (moods.error) moods.retry();
            }}
          >
            {i18n.messageFormatter(`common.retry`)}
          </SolidButton>
        }
      />
    );
  }

  let zoneDevicesCurrent = 0;
  let zoneMoodsCurrent = 0;

  if (devices.byZoneId == null || zones.byId == null || moods.byZoneId == null) {
    return null;
  }

  return (
    <S.Root ref={ref}>
      {zones.tree.list.map((zoneMeta) => {
        const zoneId = zoneMeta.id;
        const zoneDevices = devices.byZoneId[zoneId] ?? {};
        const zoneMoods = moods.byZoneId[zoneId] ?? {};
        const zoneDevicesCount = Object.keys(zoneDevices).length;
        const zoneMoodsCount = Object.keys(zoneMoods).length;

        const zone = zones.byId[zoneId];

        if (zone == null) return null;

        const selectedZoneChildren = zones.tree.children[props.selectedZoneId] ?? new Set();

        if (
          props.selectedZoneId != null &&
          props.selectedZoneId !== zoneId &&
          !selectedZoneChildren.has(zoneId)
        ) {
          return null;
        }

        if (
          zoneDevicesCount === 0 &&
          zoneMoodsCount === 0 &&
          props.selectedZoneId === zoneId &&
          selectedZoneChildren.size > 0
        ) {
          return null;
        }

        if (zoneDevicesCount === 0 && zoneMoodsCount === 0 && props.selectedZoneId !== zoneId) {
          return null;
        }

        let devicesCounter = zoneDevicesCurrent;
        zoneDevicesCurrent += zoneDevicesCount;

        let moodsCounter = zoneMoodsCurrent;
        zoneMoodsCurrent += zoneMoodsCount;

        return (
          <ZoneView
            key={`${zoneId}-${props.selectedZoneId}`}
            zoneId={zoneId}
            zone={zone}
            zoneDevices={zoneDevices}
            zoneMoods={zoneMoods}
            devicesCounter={devicesCounter}
            moodsCounter={moodsCounter}
            tileSize={props.tileSize}
            selectedZoneId={props.selectedZoneId}
            selectedDeviceId={props.selectedDeviceId}
            onDrop={onDrop}
            onControlsClick={props.onControlsClick}
          />
        );
      })}
    </S.Root>
  );
}

const S = (ZonesView.S = class S {
  static Root = styled.div`
    width: 100%;
  `;
});
