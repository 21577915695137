/**
 * Interface to define what properties/methods are required for an Overlay source.
 */
export class OverlaySource {
  constructor(props) {
    this.ref = props.ref;
    this.onCloseRequest = props.onCloseRequest;
    this.getIsPreventClose = props.getIsPreventClose;
  }
}
