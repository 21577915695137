import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMove } from 'react-aria';
import { motion, useTransform } from 'framer-motion';

import { getIsHomeActive } from '../RouteManager';
import { HomeyStore } from '../store/HomeyStore';

import { useI18n } from '../hooks/useI18nFormatters';
import { useApi } from '../store/HomeyStore';
import { useHomeyPath } from '../RouteManager';
import { useNavContextState } from './NavContext';

import { theme } from '../theme/theme';
import { su } from '../theme/functions/su';
import { scrollbarDark } from '../theme/elements/scrollbars';
import { animationFade } from '../theme/animations/animationFade';

import { IconNavLink } from '../components/common/IconNavLink';
import { Logo as LogoBase } from '../components/common/Logo';

// dynamic page loading (broken)
import { DevicesPage, FlowsPage, HomePage, InsightsPage } from './dynamic';

import { iconHome } from '../theme/icons/system/home';
import { iconDevices } from '../theme/icons/system/devices/devices';
import { iconFlowBold } from '../theme/icons/system/flow-bold';
import { iconInsights } from '../theme/icons/system/insights';
import { iconHomeyScript } from '../theme/icons/system/homeyscript';
import { iconSettings } from '../theme/icons/system/settings/settings';

Nav.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string,
  beta: PropTypes.bool,
};

Nav.defaultProps = {
  width: 0,
  beta: false,
};

/**
 * all dynamic loading is broken for now because of layout changes, this can be
 * re-enabled when the nav is no longer part of the dynamically loaded page.
 */
export function Nav(props) {
  const { i18n } = useI18n();

  const navContextState = useNavContextState();

  const { api } = useApi();
  const homeyPath = useHomeyPath();
  const isLoading = HomeyStore.store((state) => state.loading);

  const prev = useTransform(navContextState.prevWidthValue, (value) => `${value}px`);
  const next = useTransform(navContextState.widthValue, (value) => `${value}px`);

  const move = useMove({
    onMoveStart(e) {},
    onMove(event) {
      if (navContextState.widthValue.get() + event.deltaX < 5) return;
      navContextState.widthValue.set(navContextState.widthValue.get() + event.deltaX);
    },
    onMoveEnd(e) {
      localStorage.setItem(`layout.width.${navContextState.key}`, navContextState.widthValue.get());
    },
  });

  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      '--space-reserve-width',
      `calc(var(--width-left) + 70px + 20px * 2)`
    );
  });

  return (
    // reserve space so no layout shifts are causing performance issues
    <Nav.SpaceReserve
      style={{
        '--nav-items-width': `70px`,
        '--nav-margin': `20px`,
        '--prev-width': prev,
        '--next-width': next,
      }}
    >
      <Nav.Container>
        <Nav.Header>
          <Nav.Logo alt="app-logo" isLoading={isLoading} />
          {props.title && (
            <Nav.HeaderTitleWrapper>
              <Nav.HeaderTitle>
                {props.title}
                {/* Beta isnt in use right now but leaving it for new features. */}
                {props.beta && <Nav.Beta>Beta</Nav.Beta>}
              </Nav.HeaderTitle>
            </Nav.HeaderTitleWrapper>
          )}
        </Nav.Header>
        <Nav.Content>
          <Nav.NavLinkItems>
            <IconNavLink
              exact
              to={homeyPath}
              url={iconHome}
              alt="navigation-home"
              tooltip={i18n.messageFormatter('navigation.home')}
              dynamic={HomePage}
              isActive={getIsHomeActive}
            />
            <IconNavLink
              to={`${homeyPath}/devices`}
              url={iconDevices}
              alt="navigation-devices"
              tooltip={i18n.messageFormatter('navigation.devices')}
              dynamic={DevicesPage}
            />
            <IconNavLink
              to={`${homeyPath}/flows`}
              url={iconFlowBold}
              alt="navigation-flows"
              tooltip={i18n.messageFormatter('navigation.flows')}
              dynamic={FlowsPage}
            />
            <IconNavLink
              to={`${homeyPath}/insights`}
              url={iconInsights}
              alt="navigation-insights"
              tooltip={i18n.messageFormatter('navigation.insights')}
              dynamic={InsightsPage}
            />

            {api?.homey.platform === 'local' && (
              <IconNavLink
                to={`${homeyPath}/scripts`}
                url={iconHomeyScript}
                alt="navigation-script"
                tooltip={i18n.messageFormatter('navigation.script')}
              />
            )}

            <IconNavLink
              to={`${homeyPath}/settings`}
              url={iconSettings}
              alt="navigation-settings"
              tooltip={i18n.messageFormatter('navigation.settings')}
            />
          </Nav.NavLinkItems>

          <Nav.SubNavWrapper
            prevWidth={navContextState.prevWidth}
            nextWidth={navContextState.width}
            onAnimationEnd={() => {
              //subNavigationPrevWidth = props.width;
            }}
          >
            {props.children && (
              <Nav.SubNav
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0, ease: 'easeInOut' }}
              >
                {props.children}
                <Handle {...move.moveProps} />
              </Nav.SubNav>
            )}
          </Nav.SubNavWrapper>
        </Nav.Content>
      </Nav.Container>
    </Nav.SpaceReserve>
  );
}

const Handle = styled.div`
  background-color: transparent;
  position: absolute;
  right: -5px;
  width: 10px;
  top: 0;
  bottom: 0;
  z-index: 10000;

  &:hover,
  &:active {
    cursor: ew-resize;
  }
`;

const kf1 = keyframes`
  0% {
    width: var(--prev-width);
  }

  70% {
    width: calc(var(--next-width) + 20px);
  }

  100% {
    width: var(--next-width);
  }
`;

const kf2 = keyframes`
  0% {
    width: var(--prev-width);
  }

  70% {
    width: var(--next-width);
  }

  85% {
    width: var(--next-width);
  }

  100% {
    width: var(--next-width);
  }
`;

/**
 * animate when the component is locked,
 * onAnimationEnd will release the component
 */
function getSubNavAnimation(props) {
  if (props.nextWidth === props.prevWidth) {
    return css`
      width: var(--next-width);
      --slide-in-duration: 100ms;
      --slide-start-opacity: 1;
      --slide-start-animation: none;
    `;
  }

  // When new width is larger then previous width use elastic animation
  if (props.nextWidth > props.prevWidth) {
    return css`
      animation-name: ${kf1};
      width: var(--next-width);
    `;
  }

  // When new width is smaller then previous width use bounce animation
  if (props.nextWidth < props.prevWidth) {
    return css`
      --slide-start-animation-direction: reverse;
      animation-name: ${kf2};
      width: var(--next-width);
    `;
  }
}

/* reserve space for the absolute positioned child */
Nav.SpaceReserve = styled(motion.div)`
  position: relative;
  display: flex;
  min-width: calc(var(--next-width) + var(--nav-items-width) + var(--nav-margin) * 2);
`;

Nav.Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 auto;
  z-index: ${theme.zIndex.nav};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  margin: var(--nav-margin);
`;

Nav.Header = styled.header`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
  padding: ${su(2)};
  margin-bottom: ${su(3)};
  overflow: hidden;
`;

Nav.Logo = styled(LogoBase)`
  display: block;
  width: 40px;
  height: 40px;
  margin: -5px;
`;

Nav.HeaderTitleWrapper = styled.div`
  flex: 1 1 auto;
  width: 0; // width 0 required for subnavigation animation
  overflow: hidden; // required for subnavigation animation
`;

Nav.HeaderTitle = styled.div`
  padding-left: ${su(2)};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.heading2};

  opacity: 0;
  animation: ${animationFade.in};
  animation-timing-function: ease-in-out;
  animation-duration: 500ms;
  //animation-delay: 400ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`;

Nav.Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  // max-height: calc(100% - 100px); // 100px = total header height
  overflow: hidden; //experimental: todo remove this is if no issues with animations and scrolling
  padding-top: ${su(1)}; // Makes sure shadows from children are still rendered
  margin-top: ${su(-1)}; // Makes sure shadows from children are still rendered
`;

Nav.NavLinkItems = styled.nav`
  ${scrollbarDark};
  display: flex;
  flex: 0 0 auto;
  width: var(--nav-items-width);
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px;
  overflow-y: auto;
`;

/**
 * Animation fill mode is none because Safari sticks to the last
 * animated value with forwards even if you change the variable later.
 */
Nav.SubNavWrapper = styled.div`
  --slide-in-duration: 500ms;
  --slide-start-opacity: 0;
  --slide-start-animation: ${animationFade.in};
  --slide-start-animation-direction: normal;

  overflow-x: hidden; // required for input and buttons
  padding-top: 10px; // required for shadows effects in search inputs
  margin-top: -10px; // required for shadows effects in search inputs
  will-change: width;

  flex: 0 0 auto;
  width: var(--next-width); // https://github.com/philipwalton/flexbugs#flexbug-11

  display: flex;
  flex-direction: column;

  ${getSubNavAnimation};

  animation-timing-function: ease-in-out;
  animation-duration: var(--slide-in-duration);
  animation-fill-mode: none;
  animation-iteration-count: 1;

  margin-bottom: 20px;
`;

Nav.SubNav = styled(motion.nav)`
  display: flex;
  flex: 1 1 auto;
  border-left: 1px solid ${theme.color.line};
  max-height: 100%;
  //overflow: hidden; //experimental: todo remove this is if no issues with animations and scrolling

  // opacity: var(--slide-start-opacity);
  // animation: var(--slide-start-animation);
  // animation-timing-function: ease-in-out;
  // animation-duration: var(--slide-in-duration);
  // animation-direction: var(--slide-start-animation-direction);
  // animation-fill-mode: forwards;
  // animation-iteration-count: 1;
  //
  // > * {
  //   opacity: 0;
  //   animation: ${animationFade.in};
  //   animation-timing-function: ease-in-out;
  //   animation-duration: 300ms;
  //   animation-delay: var(--slide-in-duration);
  //   animation-fill-mode: forwards;
  //   animation-iteration-count: 1;
  // }
`;

Nav.Beta = styled.span`
  vertical-align: super;
  color: ${theme.color.special_beta};
  font-size: ${theme.fontSize.tiny};
  font-weight: ${theme.fontWeight.bold};
  margin-left: 5px;
`;
