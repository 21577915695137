import { useRef, useLayoutEffect } from 'react';

/**
 * Use Current Props
 *
 * Assigns the passed props into the current contant object on useLayoutEffect. This way stale
 * references to callbacks can be prevented without adding them as a dependency to a dependency
 * array.
 *
 * @param {Object<any>} props - Props to assign into the contant object reference.
 * @returns {Object<any>} currentProps - A constant reference.
 */
export function useCurrentProps(props) {
  const currentValue = useRef({});

  // TODO this can be a memory leak
  useLayoutEffect(() => {
    Object.assign(currentValue.current, props);
  });

  return currentValue.current;
}
