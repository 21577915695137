import { useEffect, useState } from 'react';

export function useArgumentValues({ props }) {
  const storeValue = props.data.args?.[props.argumentKey];
  const [localValue, setLocalValue] = useState(storeValue);

  useEffect(() => {
    setLocalValue(storeValue);
  }, [storeValue]);

  return {
    storeValue,
    localValue,
    setLocalValue,
  };
}
