import styled from '@emotion/styled';

export const Control = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

export const Action = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Select = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
