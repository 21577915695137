import React, { useEffect, useRef, useState, useContext, createContext } from 'react';
import styled from '@emotion/styled';

import { mergeRefs } from '../../../../lib/mergeRefs';

import { useNodeDrag } from '../../../../hooks/useNodeDrag';

const DragglabeNodeContext = createContext(null);

export function useDraggableNodeContext() {
  return useContext(DragglabeNodeContext);
}

export function DraggableNode(props) {
  const nodeRef = useRef();
  const stateRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  stateRef.current == null &&
    (function init() {
      stateRef.current = {
        id: props.nodeId,
        x: props.data.x,
        y: props.data.y,
        update({ x, y }) {
          stateRef.current.x = x;
          stateRef.current.y = y;

          // nodeRef.current.style.setProperty('--x', `${stateRef.current.x}px`);
          // nodeRef.current.style.setProperty('--y', `${stateRef.current.y}px`);
          // This is so much more performant instead of updating the variables.
          nodeRef.current.style.transform = `translate(${x}px, ${y}px`;
        },
        setState({ x, y }) {
          stateRef.current.x = x;
          stateRef.current.y = y;
        },
        setStyleFromState() {
          stateRef.current.setStyle({
            x: stateRef.current.x,
            y: stateRef.current.y,
          });
        },
        setStyle({ x, y, layer = stateRef.current.layer }) {
          nodeRef.current.style.transform = `translate(${x}px, ${y}px`;
        },
      };
    })();

  useEffect(() => {
    const unregister = props.manager.register(stateRef.current);

    return function () {
      unregister();
    };
  }, [props.manager]);

  useEffect(() => {
    stateRef.current.setState({
      x: props.data.x,
      y: props.data.y,
    });
    stateRef.current.setStyleFromState();
  }, [props.data]);

  const [collectedProps, dragRef] = useNodeDrag({
    id: props.nodeId,
    type: DraggableNode.type,
    isDisabled: isDisabled || props.isDisabled,
    onStart() {
      props.onDragStart?.();
    },
    onEnd() {
      props.onDragEnd?.();
    },
  });

  const style = {
    transform: `translate(${stateRef.current.x}px, ${stateRef.current.y}px)`,
  };

  const context = useRef({ setIsDisabled });

  return (
    <DragglabeNodeContext.Provider value={context.current}>
      <DraggableNode.Root
        ref={mergeRefs([nodeRef, dragRef])}
        style={style}
        data-node={props.isDisabled ? 'false' : 'true'}
        data-id={props.nodeId}
        data-type={DraggableNode.type}
        data-is-dragging={collectedProps.isDragging}
      >
        {props.children}
      </DraggableNode.Root>
    </DragglabeNodeContext.Provider>
  );
}

DraggableNode.type = 'node';

DraggableNode.Root = styled.div`
  --node-z-index: var(--node-layer-override, var(--node-layer, 0));

  position: absolute;
  pointer-events: auto;
  top: 0;
  left: 0;
  outline: 0;
  z-index: var(--node-z-index);

  &[data-is-dragging='true'] {
    --node-z-index: 1;

    & * {
      pointer-events: none;
    }
  }
`;
