import { proxy as color } from '../color.functional';

/**
 * Icon variants
 */
export const icon = {
  //sizes
  size_large: `28px`,
  size_medium: `24px`,
  size_default: `20px`,
  size_18: `18px`,
  size_small: `16px`,
  size_tiny: `12px`,
  //scale effects
  scale_large: 1.0714285714,
  scale_default: 1.1,
  scale_small: 1.125,
  scale_tiny: 1.1666666667,
  // scale hover
  scale_hover_large:         1.0714285714,  // 28px => 30px
  scale_hover_default:       1.1,           // 20px => 22px
  scale_hover_small:         1.125,         // 16px => 18px
  scale_hover_tiny:          1.1666666667,  // 12px => 14px
  // scale press
  scale_press_large:         0.9285,        // 28px => 26px
  scale_press_default:       0.9,           // 20px => 18px
  scale_press_small:         0.875,         // 16px => 14px
  scale_press_tiny:          0.8333,        // 12px => 10px
  //colors
  color_light:               color.icon_light,
  color_light_hover:         color.icon_light_hover,
  color_light_inactive:      color.icon_light_inactive,
  color_medium:              color.icon_medium,
  color_medium_hover:        color.icon_medium_hover,
  color_dark:                color.icon_dark,
  color_body_light:          color.icon_body_light,
  color_blue:                color.icon_blue,
  color_white:               color.icon_white,
  color_disabled:            color.icon_disabled,
} as const;
