import { __DEV__ } from '../../lib/__dev__';
import { isUUID } from '../../lib/string';

import { BaseApiStore } from '../BaseApiStore';
import { DeviceStore } from '../devices/DeviceStore';
import { moodsStore } from '../moods/MoodsStore';
import { FlowStore } from '../flow/FlowStore';
import { AdvancedFlowStore } from '../advanced-flow/AdvancedFlowStore';

export class UserMeStore extends BaseApiStore {
  static key = 'userMe';
  static store = this.createStore(this.key);
  static createInitialState() {
    return {
      data: null,
      me: null,
      favoriteDevices: null,
      favoriteFlows: null,
      favoriteMoods: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:userMe');
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      this.destroy();
      const managerUsers = state.api.users;
      const userMePromise = managerUsers.getUserMe();
      const [data] = await Promise.all([userMePromise]);

      this.set({
        ...this.createInitialState(),
        loading: false,
        data: data,
        me: {
          ...data,
        },
        favoriteDevices: data.properties?.favoriteDevices ?? [],
        favoriteFlows: data.properties?.favoriteFlows ?? [],
        favoriteMoods: data.properties?.favoriteMoods ?? [],
        manager: managerUsers,
      });

      data.addListener('$update', this.handleUpdate);
      data.addListener('$delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:userMe');
    const { data } = this.get();

    if (data) {
      data.removeListener('$update', this.handleUpdate);
      data.removeListener('$delete', this.handleDelete);
    }
  }

  static handleUpdate = (updatedUserMe) => {
    __DEV__ && console.info(`update:userMe:${updatedUserMe.id}`);

    this.set({
      me: {
        ...updatedUserMe,
      },
      favoriteDevices: updatedUserMe.properties?.favoriteDevices ?? [],
      favoriteFlows: updatedUserMe.properties?.favoriteFlows ?? [],
      favoriteMoods: updatedUserMe.properties?.favoriteMoods ?? [],
    });
  };

  static handleDelete = (deletedUserMe) => {
    __DEV__ && console.info(`delete:userMe:${deletedUserMe.id}`);

    this.set({
      data: null,
      me: null,
      favoriteDevices: null,
      favoriteFlows: null,
      favoriteMoods: null,
    });
  };

  static addFavoriteDevice({ id }) {
    const { manager, favoriteDevices } = this.get();

    if (!isUUID(id)) return Promise.reject(new Error(`Invalid device id: ${id}`));

    const nextFavoriteDevices = [...new Set([...favoriteDevices, id])];

    this.set({ favoriteDevices: nextFavoriteDevices });

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteDevices',
          value: nextFavoriteDevices,
        });
        resolve();
      } catch (error) {
        this.set({ favoriteDevices: favoriteDevices });
        reject(error);
      }
    });
  }

  static removeFavoriteDevice({ id }) {
    const { manager, favoriteDevices } = this.get();

    if (!isUUID(id)) return Promise.reject(new Error(`Invalid device id: ${id}`));

    const nextFavoriteDevices = favoriteDevices.filter((deviceId) => deviceId !== id);

    this.set({ favoriteDevices: nextFavoriteDevices });

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteDevices',
          value: nextFavoriteDevices,
        });
        resolve();
      } catch (error) {
        this.set({ favoriteDevices: favoriteDevices });
        reject(error);
      }
    });
  }

  static swapFavoriteDevices({ firstId, secondId }) {
    const { favoriteDevices } = this.get();

    const firstIndex = favoriteDevices.findIndex((deviceId) => deviceId === firstId);
    const secondIndex = favoriteDevices.findIndex((deviceId) => deviceId === secondId);

    const nextFavoriteDevices = [...favoriteDevices];

    const prevItem = nextFavoriteDevices[firstIndex];
    nextFavoriteDevices[firstIndex] = nextFavoriteDevices[secondIndex];
    nextFavoriteDevices[secondIndex] = prevItem;

    this.set({ favoriteDevices: nextFavoriteDevices });
  }

  static saveFavoriteDevices() {
    const { manager, favoriteDevices, data } = this.get();
    let nextFavoriteDevices = [];

    const devices = DeviceStore.get();

    if (devices.byId != null) {
      for (const deviceId of favoriteDevices) {
        if (devices.byId[deviceId] != null) {
          nextFavoriteDevices.push(deviceId);
        }
      }
    }

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteDevices',
          value: nextFavoriteDevices,
        });
        resolve();
      } catch (error) {
        this.set({
          favoriteDevices: data?.me?.properties?.favoriteDevices ?? [],
        });
        reject(error);
      }
    });
  }

  static addFavoriteFlow({ id }) {
    const { manager, favoriteFlows } = this.get();

    if (!isUUID(id)) return Promise.reject(new Error(`Invalid flow id: ${id}`));

    const nextFavoriteFlows = [...new Set([...favoriteFlows, id])];

    this.set({ favoriteFlows: nextFavoriteFlows });

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteFlows',
          value: nextFavoriteFlows,
        });
        resolve();
      } catch (error) {
        this.set({ favoriteFlows: favoriteFlows });
        reject(error);
      }
    });
  }

  static removeFavoriteFlow({ id }) {
    const { manager, favoriteFlows } = this.get();

    if (!isUUID(id)) return Promise.reject(new Error(`Invalid flow id: ${id}`));

    const nextFavoriteFlows = favoriteFlows.filter((flowId) => flowId !== id);

    this.set({ favoriteFlows: nextFavoriteFlows });

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteFlows',
          value: nextFavoriteFlows,
        });
        resolve();
      } catch (error) {
        this.set({ favoriteFlows: favoriteFlows });
        reject(error);
      }
    });
  }

  static swapFavoriteFlows({ firstId, secondId }) {
    const { favoriteFlows } = this.get();

    const firstIndex = favoriteFlows.findIndex((flowId) => flowId === firstId);
    const secondIndex = favoriteFlows.findIndex((flowId) => flowId === secondId);

    const nextFavoriteFlows = [...favoriteFlows];

    const prevItem = nextFavoriteFlows[firstIndex];
    nextFavoriteFlows[firstIndex] = nextFavoriteFlows[secondIndex];
    nextFavoriteFlows[secondIndex] = prevItem;

    this.set({ favoriteFlows: nextFavoriteFlows });
  }

  static saveFavoriteFlows() {
    const { manager, favoriteFlows, data } = this.get();
    let nextFavoriteFlows = [];

    const flows = FlowStore.get();
    const advancedFlows = AdvancedFlowStore.get();

    if (flows.byId != null && advancedFlows.byId != null) {
      for (const flowId of favoriteFlows) {
        if (flows.byId[flowId] != null || advancedFlows.byId[flowId] != null) {
          nextFavoriteFlows.push(flowId);
        }
      }
    }

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteFlows',
          value: nextFavoriteFlows,
        });
        resolve();
      } catch (error) {
        this.set({
          favoriteFlows: data?.me?.properties?.favoriteFlows ?? [],
        });
        reject(error);
      }
    });
  }

  static addFavoriteMood({ id }) {
    const { manager, favoriteMoods } = this.get();

    if (!isUUID(id)) return Promise.reject(new Error(`Invalid mood id: ${id}`));

    const nextFavoriteMoods = [...new Set([...favoriteMoods, id])];

    this.set({ favoriteMoods: nextFavoriteMoods });

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteMoods',
          value: nextFavoriteMoods,
        });
        resolve();
      } catch (error) {
        this.set({ favoriteMoods: favoriteMoods });
        reject(error);
      }
    });
  }

  static removeFavoriteMood({ id }) {
    const { manager, favoriteMoods } = this.get();

    if (!isUUID(id)) return Promise.reject(new Error(`Invalid mood id: ${id}`));

    const nextFavoriteMoods = favoriteMoods.filter((moodId) => moodId !== id);

    this.set({ favoriteMoods: nextFavoriteMoods });

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteMoods',
          value: nextFavoriteMoods,
        });
        resolve();
      } catch (error) {
        this.set({ favoriteMoods: favoriteMoods });
        reject(error);
      }
    });
  }

  static swapFavoriteMoods({ firstId, secondId }) {
    const { favoriteMoods } = this.get();

    const firstIndex = favoriteMoods.findIndex((moodId) => moodId === firstId);
    const secondIndex = favoriteMoods.findIndex((moodId) => moodId === secondId);

    const nextFavoriteMoods = [...favoriteMoods];

    const prevItem = nextFavoriteMoods[firstIndex];
    nextFavoriteMoods[firstIndex] = nextFavoriteMoods[secondIndex];
    nextFavoriteMoods[secondIndex] = prevItem;

    this.set({ favoriteMoods: nextFavoriteMoods });
  }

  static saveFavoriteMoods() {
    const { manager, favoriteMoods, data } = this.get();
    let nextFavoriteMoods = [];

    const moods = moodsStore.get();

    if (moods.byId != null) {
      for (const moodId of favoriteMoods) {
        if (moods.byId[moodId] != null) {
          nextFavoriteMoods.push(moodId);
        }
      }
    }

    return new Promise(async (resolve, reject) => {
      try {
        await manager.updateUserMeProperties({
          id: 'favoriteMoods',
          value: nextFavoriteMoods,
        });
        resolve();
      } catch (error) {
        this.set({
          favoriteMoods: data?.me?.properties?.favoriteMoods ?? [],
        });
        reject(error);
      }
    });
  }
}
