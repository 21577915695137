import React, { createContext, useContext, useRef } from 'react';
import styled from '@emotion/styled';

import { useTabPanel } from 'react-aria';

const TabPanelContext = createContext();

export function useTabPanelContext() {
  return useContext(TabPanelContext);
}

export function TabPanelItem(props) {
  const tabPanelRef = useRef();
  const { state, ...rest } = props;
  const tabPanel = useTabPanel(rest, state, tabPanelRef);

  const isSelected = state.selectedKey === props.item.key;
  const isDisabled = state.disabledKeys.has(props.item.key);

  // Nothing is focusable when this is not the active tab
  if (isSelected === false || isDisabled === true) {
    delete tabPanel.tabPanelProps.tabIndex;
  }

  return (
    <TabPanelContext.Provider
      value={{
        ref: tabPanelRef,
        tabPanel: tabPanel,
        item: props.item,
        value: props.item.value,
        state: props.state,
      }}
    >
      {props.renderTabPanel({
        item: props.item,
        value: props.item.value,
        state: props.state,
      })}
    </TabPanelContext.Provider>
  );
}

export function TabPanel(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, tabPanel, item, value, state } = useTabPanelContext();

  const isSelected = state.selectedKey === item.key;
  const isDisabled = state.disabledKeys.has(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  return (
    <TabPanel.Root
      {...tabPanel.tabPanelProps}
      ref={ref}
      data-is-selected={isSelected}
      data-is-disabled={isDisabled}
      data-is-focused={isFocused}
    >
      <input
        name={item.key}
        defaultValue={item.key}
        disabled={isSelected === false || isDisabled === true}
      />
    </TabPanel.Root>
  );
}

export function renderTabPanel() {
  return <TabPanel />;
}

TabPanel.Root = styled.div`
  flex: 0 0 100%;
`;
