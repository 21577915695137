import { useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { SCOPES } from '../../../lib/scopes';
import { ToastManager } from '../../../ToastManager';
import { experimentsSettingsStore } from './ExperimentsSettingsStore';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { GeneralSettingsSection } from './GeneralSettingsSection';
import { Switch } from '../../../components/forms/Switch';
import { Throbber } from '../../../components/common/Throbber';
import { Icon } from '../../../components/common/Icon';
import { CenteredMessage } from '../../../components/common/CenteredMessage';

import { ExperimentsSettingsDialogEnabled } from './ExperimentsSettingsDialogEnabled';

import { iconExperiments } from '../../../theme/icons-v2/system/experiments/experiments';

export function ExperimentsSettings() {
  const { i18n } = useI18n();
  const { scopes } = useApi();
  const { experimentsSettings } = experimentsSettingsStore.use();
  const [state, setState] = useState({});
  const [result, setResult] = useState(null);

  if (scopes[SCOPES.SYSTEM] !== true) {
    return (
      <S.Root>
        <CenteredMessage
          title={i18n.messageFormatter('common.insufficientPermissions')}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('common.insufficientPermissionsViewSubtitle', {
            name: i18n.messageFormatter('settings.system.experiments'),
          })}
        />
      </S.Root>
    );
  }

  if (experimentsSettings.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  return (
    <S.Root>
      <S.Content>
        <S.Header>
          <h3>
            <Icon
              color={theme.color.black_o_60}
              size={theme.icon.size_default}
              url={iconExperiments}
            />
            {i18n.messageFormatter('settings.system.experimentsDisclaimerTitle')}
          </h3>
          <p>{i18n.messageFormatter('settings.system.experimentsDisclaimerContent')}</p>
        </S.Header>

        <S.Heading>{i18n.messageFormatter('settings.system.experiments')}</S.Heading>

        {Object.entries(experimentsSettings.experimentsData ?? {}).map(
          ([key, experiment], index) => {
            return (
              <GeneralSettingsSection.S.Section key={key}>
                <GeneralSettingsSection.S.SectionView>
                  <GeneralSettingsSection.S.Row>
                    <GeneralSettingsSection.S.Label>
                      {experiment.title}
                    </GeneralSettingsSection.S.Label>
                    <GeneralSettingsSection.S.Value>
                      <Switch
                        aria-label={experiment.title}
                        name={key}
                        isDisabled={state[key] != null}
                        value={state[key] ?? experiment.enabled}
                        onChange={(value) => {
                          if (state[key] != null) return;
                          setState({ ...state, [key]: value });

                          (async () => {
                            try {
                              let result;

                              if (value === true) {
                                result = await experimentsSettingsStore.enableExperiment({
                                  $timeout: 60 * 1000,
                                  id: key,
                                });
                                setResult(result);
                              } else if (value === false) {
                                await experimentsSettingsStore.disableExperiment({
                                  $timeout: 60 * 1000,
                                  id: key,
                                });
                                result = 'Disabled';
                              }

                              // ToastManager.add({ message: result });
                            } catch (error) {
                              ToastManager.handleError(error);
                            } finally {
                              setState({ ...state, [key]: undefined });
                            }
                          })();
                        }}
                      />
                    </GeneralSettingsSection.S.Value>
                  </GeneralSettingsSection.S.Row>
                  <S.Hint>{experiment.description}</S.Hint>
                </GeneralSettingsSection.S.SectionView>
              </GeneralSettingsSection.S.Section>
            );
          }
        )}
      </S.Content>

      <AnimatePresence mode="wait">
        {result != null && (
          <ExperimentsSettingsDialogEnabled
            result={result}
            onCloseRequest={() => {
              setResult(null);
            }}
          />
        )}
      </AnimatePresence>
    </S.Root>
  );
}

ExperimentsSettings.pathKey = 'system/experiments';
ExperimentsSettings.icon = iconExperiments;
ExperimentsSettings.backgroundColor = theme.color.system_experiments;

function S() {}
ExperimentsSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

S.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
`;

S.Header = styled.header`
  text-align: center;
  padding: 20px 20px 0;
  border: 1px solid ${theme.color.note_yellow_border};
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.color.note_yellow};
  margin: 20px 0;

  h3 {
    color: ${theme.color.black_o_60};
    display: flex;
    align-items: center;
    justify-content: center;

    ${Icon.S.Root} {
      margin-right: 10px;
    }
  }

  p {
    color: ${theme.color.black_o_60};
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.small};
  }
`;

S.Heading = styled.h3`
  padding: ${su(2)} 0 ${su(1)};
  width: 100%;
`;

S.Hint = styled.p`
  margin: 5px 0 15px;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;
