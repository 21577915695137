import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { AdvancedFlowStore } from './AdvancedFlowStore';
import { useAttach } from '../useAttach';

import { selectBase, selectLoading } from '../selectors';

function selectById(state) {
  return {
    ...selectBase(state),
    byId: state.byId,
  };
}

function selectByCardOwnerUri(state) {
  return {
    ...selectBase(state),
    byCardOwnerUri: state.byCardOwnerUri,
  };
}

function selectByFolderId(state) {
  return {
    ...selectBase(state),
    byFolderId: state.byFolderId,
  };
}

export function useAdvancedFlowsById() {
  useAttach(AdvancedFlowStore, 'advancedFlowsById');

  return AdvancedFlowStore.store(selectById, shallow);
}

export function useAdvancedFlowsByCardOwnerUri() {
  useAttach(AdvancedFlowStore, 'advancedFlowsByCardOwnerUri');

  return AdvancedFlowStore.store(selectByCardOwnerUri, shallow);
}

export function useAdvancedFlowsByFolderId() {
  useAttach(AdvancedFlowStore, 'advancedFlowsByFolderId');

  return AdvancedFlowStore.store(selectByFolderId, shallow);
}

export function useAdvancedFlow({ advancedFlowId }) {
  useAttach(AdvancedFlowStore, 'advancedFlow');

  const select = useCallback(
    (state) => {
      return {
        advancedFlow: state.byId?.[advancedFlowId] ?? null,
        manager: state.manager,
        api: state.api,
        loading: state.loading,
        error: state.error,
      };
    },
    [advancedFlowId]
  );

  return AdvancedFlowStore.store(select, shallow);
}

export function useAdvancedFlowsLoading() {
  useAttach(AdvancedFlowStore, 'useAdvancedFlowsLoading');

  return AdvancedFlowStore.store(selectLoading, shallow);
}

export function useAdvancedFlowsAttach() {
  useAttach(AdvancedFlowStore, 'useAdvancedFlowsAttach');
}
