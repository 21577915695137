import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';
import { PendingError } from '../../../lib/InternalError';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';
import { defer } from '../../../lib/defer';

export interface State {
  ownersData: null | { [key: string]: Owner };
  isLoading: boolean;
  error: unknown;
}

export type NotificationSettingsStoreState = State & BaseApiStoreState;

type OwnerData = { [key: string]: Owner };

type Owner = {
  enabled: boolean;
  push: boolean;
  size: number;
  uriObj: {
    name: string;
  };
};

class NotificationSettingsStore extends BaseApiStore<State> {
  key = 'notification-owners-settings';

  override createInitialState() {
    return {
      ownersData: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:notification-owners');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerNotifications = api.notifications;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const ownersPromise = managerNotifications.getOwners();

      const [, ownersData] = await Promise.all([waitPromise, ownersPromise]);

      this.set({
        ...this.createInitialState(),
        ownersData: ownersData as unknown as OwnerData,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, 'notification-owners-settings');

    return { notificationSettings: this.store(selectAll, shallow) };
  }

  async setOwnerEnabled({ uri, enabled }: { uri: string; enabled: boolean }) {
    const key = `${this.setOwnerEnabled.name}:${uri}`;

    if (this.pending[key] != null) {
      throw new PendingError();
    }

    this.pending[key] = defer();

    try {
      const api = this.getApi();
      const state = this.get();
      const prevData = state.ownersData?.[uri];

      if (prevData == null) {
        throw new Error('Trying to set owner enabled a non existing owner.');
      }

      this.set({
        ownersData: {
          ...state.ownersData,
          [uri]: {
            ...prevData,
            enabled: enabled,
          },
        },
      });

      try {
        (await api.notifications.setOwnerEnabled({ uri, enabled })) as unknown as void;
      } catch (error) {
        this.set({
          ownersData: {
            ...state.ownersData,
            [uri]: {
              ...prevData,
              enabled: prevData.enabled,
            },
          },
        });
        throw error;
      }
    } finally {
      this.pending[key].resolve();
      delete this.pending[key];
    }
  }

  async setOwnerPush({ uri, push }: { uri: string; push: boolean }) {
    const key = `${this.setOwnerPush.name}:${uri}`;

    if (this.pending[key] != null) {
      throw new PendingError();
    }

    this.pending[key] = defer();

    try {
      const api = this.getApi();
      const state = this.get();
      const prevData = state.ownersData?.[uri];

      if (prevData == null) {
        throw new Error('Trying to set owner push a non existing owner.');
      }

      this.set({
        ownersData: {
          ...state.ownersData,
          [uri]: {
            ...prevData,
            push: push,
          },
        },
      });

      try {
        (await api.notifications.setOwnerPush({ uri, push })) as unknown as void;
      } catch (error) {
        this.set({
          ownersData: {
            ...state.ownersData,
            [uri]: {
              ...prevData,
              push: prevData.push,
            },
          },
        });
        throw error;
      }
    } finally {
      this.pending[key].resolve();
      delete this.pending[key];
    }
  }

  async deleteNotifications({ uri }: { uri: string; push: boolean }) {
    const api = this.getApi();
    (await api.notifications.deleteNotifications({ ownerUri: uri })) as unknown as void;
  }
}

function selectAll(state: NotificationSettingsStoreState) {
  return {
    ...selectBase(state),
    ownersData: state.ownersData,
  };
}

export const notificationSettingsStore = new NotificationSettingsStore();
