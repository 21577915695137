import React from 'react';

import { ArgumentText } from './argument-text/ArgumentText';
import { ArgumentHomeyScript } from './argument-homeyscript/ArgumentHomeyScript';
import { ArgumentHomeyScriptWithArgument } from './argument-homeyscript/ArgumentHomeyScriptWithArgument';
import { ArgumentNumber } from './ArgumentNumber';
import { ArgumentTime } from './ArgumentTime';
import { ArgumentDate } from './ArgumentDate';
import { ArgumentDropdown } from './ArgumentDropdown';
import { ArgumentAutocomplete } from './ArgumentAutocomplete';
import { ArgumentRange } from './ArgumentRange';
import { ArgumentColor } from './ArgumentColor';
import { ArgumentCheckbox } from './ArgumentCheckbox';
import { ArgumentDevice } from './ArgumentDevice';

export function FlowArgument(props) {
  if (props.argument == null) {
    console.error('Expected card argument');
    return null;
  }

  const Component = getComponent(props);

  return (
    <Component
      cardContainerRef={props.cardContainerRef}
      argumentKey={props.argumentKey}
      argumentTypeText={props.argumentTypeText}
      cardType={props.cardType}
      cardIndex={props.cardIndex}
      argument={props.argument}
      otherArguments={props.otherArguments}
      card={props.card}
      data={props.data}
      isFirstArgument={props.isFirstArgument}
      isDisabled={props.isDisabled}
      isDisabledStyle={props.isDisabledStyle}
      onUpdate={props.onUpdate}
      onPropertyUpdate={props.onPropertyUpdate}
    />
  );
}

function getComponent(props) {
  switch (props.argument.type) {
    case 'code':
      if (props.argument.language === 'homeyscript') {
        if (props.otherArguments.argument != null) {
          return ArgumentHomeyScriptWithArgument;
        }

        return ArgumentHomeyScript;
      }

      return ArgumentText;
    case 'text':
      return ArgumentText;
    case 'autocomplete':
      return ArgumentAutocomplete;
    case 'dropdown':
      return ArgumentDropdown;
    case 'range':
      return ArgumentRange;
    case 'number':
      return ArgumentNumber;
    case 'color':
      return ArgumentColor;
    case 'date':
      return ArgumentDate;
    case 'time':
      return ArgumentTime;
    case 'checkbox':
      return ArgumentCheckbox;
    case 'device':
      return ArgumentDevice;
    default:
      console.error(`unknown argument type ${props.argument.type}`);
      return ArgumentText;
  }
}
