import React from 'react';
import styled from '@emotion/styled';
import { useRadioGroupState } from 'react-stately';
import { useRadioGroup } from 'react-aria';

export const RadioContext = React.createContext();

export function RadioGroup(props) {
  const state = useRadioGroupState(props);
  const radio = useRadioGroup(props, state);

  return (
    <S.Root {...radio.radioGroupProps}>
      {props.label && <span {...radio.labelProps}>{props.label}</span>}

      <RadioContext.Provider value={{ state, register: props.register, name: props.name }}>
        <S.Children>{props.children}</S.Children>
      </RadioContext.Provider>
    </S.Root>
  );
}

function S() {}
RadioGroup.S = S;

S.Children = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

S.Root = styled.div`
  display: flex;
  flex-direction: column;

  &[aria-orientation='horizontal'] ${S.Children} {
    flex-direction: row;
  }
`;
