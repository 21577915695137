import { useMemo } from 'react';
import { useI18n } from '../../../hooks/useI18nFormatters';

const REGEX_ARGUMENT = /(\[\[.*?\]\])/g;
const REGEX_CONDITIONAL = /!{{(.*)\|(.*)}}/gi;
const TYPES = ['text', 'number', 'range', 'checkbox'];

export function useTitleChunks({ card, data }) {
  const { i18n } = useI18n();

  return useMemo(() => {
    return getTitleChunks({ card, data, messageFormatter: i18n.messageFormatter });
  }, [card, data, i18n]);
}

export function getTitleChunks({ card, data, messageFormatter }) {
  if (card == null) return [];

  const title = card.titleFormatted ?? card.title ?? '';

  const otherArguments = {};
  const args = [];
  const result = [];

  let firstArgumentPush = false;
  let firstArgumentIndex = null;

  function getTypeText(argument) {
    let text = '...';

    if (argument != null) {
      switch (true) {
        case argument.title != null:
          text = argument.title;
          break;
        case argument.placeholder != null:
          text = argument.placeholder;
          break;
        case argument.type != null && TYPES.includes(argument.type):
          text = messageFormatter(`flow.argument.${argument.type}.typeText`);
          break;
        default:
          text = '...';
      }
    }

    return text;
  }

  function pushArgument({ type, value, card }) {
    let length;

    if (type === 'droptoken') {
      const droptokens = Array.isArray(card.droptoken) ? [...card.droptoken] : [card.droptoken];
      const types = [];

      for (const dropTokenType of droptokens) {
        switch (dropTokenType) {
          case 'string':
            types.push(messageFormatter(`flow.argument.droptoken.typeTextString`));
            break;
          case 'number':
            types.push(messageFormatter(`flow.argument.droptoken.typeTextNumber`));
            break;
          case 'boolean':
            types.push(messageFormatter(`flow.argument.droptoken.typeTextBoolean`));
            break;
          case 'image':
            types.push(messageFormatter(`flow.argument.droptoken.typeTextImage`));
            break;
          default:
            types.push(messageFormatter(`flow.argument.droptoken.typeText`));
            break;
        }
      }

      otherArguments[value] = {};

      length = result.push({
        type,
        value,
        otherArguments,
        argument: null,
        argumentTypeText: types.join(', '),
      });
    } else if (type === 'argument') {
      const argument = card.args?.find((arg) => {
        return arg.name === value;
      });

      otherArguments[value] = argument;

      length = result.push({
        type,
        value,
        otherArguments,
        argument,
        argumentTypeText: getTypeText(argument),
      });
    }

    if (firstArgumentPush === false) {
      firstArgumentPush = true;
      firstArgumentIndex = length - 1;
    }
  }

  const conditionMatches = title.matchAll(REGEX_CONDITIONAL);

  let subject = title;

  // Replace the condition text.
  for (const match of conditionMatches) {
    const inverted = data?.inverted ?? false;
    const replacement = match[inverted ? 2 : 1];
    subject = subject.replace(match[0], replacement);
  }

  const matches = subject.matchAll(REGEX_ARGUMENT);

  let index = 0;

  // Process the matches in titleFormatted.
  for (const match of matches) {
    // Add text between arguments.
    if (match.index !== index) {
      result.push({
        type: 'text',
        value: subject.substring(index, match.index).trim(),
      });
    }

    const key = subject.substring(match.index + 2, match.index + match[0].length - 2);

    args.push(key);

    if (key === 'droptoken') {
      pushArgument({
        type: 'droptoken',
        value: key,
        card,
      });
    } else {
      pushArgument({
        type: 'argument',
        value: key,
        card,
      });
    }

    index = match.index + match[0].length;
  }

  // Add remaining text.
  if (index <= subject.length - 1) {
    result.push({
      type: 'text',
      value: subject.substring(index, subject.length).trim(),
    });
  }

  // Anything not in titleFormatted.
  card.args?.forEach((arg) => {
    if (args.includes(arg.name) === false) {
      pushArgument({
        type: 'argument',
        value: arg.name,
        card,
      });
    }
  });

  // If the droptoken was not in titleFormatted.
  if (card.droptoken && args.includes('droptoken') === false) {
    pushArgument({
      type: 'droptoken',
      value: 'droptoken',
      card,
    });
  }

  if (data?.duration != null) {
    result.push({
      type: 'duration',
    });
  }

  if (data?.delay != null) {
    result.push({
      type: 'delay',
    });
  }

  return { titleChunks: result, firstArgumentIndex: firstArgumentIndex };
}
