import { useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useProgressBar } from 'react-aria';

import { ToastManager } from '../ToastManager';
import { HomeyStore, useApi } from '../store/HomeyStore';
import { updatesSettingsStore } from './settings/system/UpdatesSettingsStore';

import { useI18n } from '../hooks/useI18nFormatters';
import { useHomey } from '../store/HomeyStore';

import { theme } from '../theme/theme';
import { su } from '../theme/functions/su';
import { scrollbars } from '../theme/elements/scrollbars';

import { Layout } from '../Layout';
import { GradientButton } from '../components/buttons/GradientButton';
import { Logo } from '../components/common/Logo';

export function UpdateRebootPage(props) {
  const { isRebooting, isUpdating } = useHomey();

  return (
    <Layout
      minimalHeader={true}
      hideSubNavigation={true}
      content={(() => {
        if (isUpdating === true) {
          return <UpdateContent />;
        }

        if (isRebooting === true) {
          return <RebootContent />;
        }

        return null;
      })()}
    >
      {null}
    </Layout>
  );
}

function RebootContent(props) {
  const { api } = useApi();

  useEffect(() => {
    const { api, bootId } = HomeyStore.get();

    let timeout;
    let canceled = false;
    let attempts = 1;

    function getInfo() {
      timeout = setTimeout(async () => {
        try {
          console.log('Fetching system info...');
          const info = await api.system.getInfo({ $timeout: 5000 });
          if (canceled === true) return;

          console.log({
            prevBootId: bootId,
            nextBootId: info.bootId,
          });

          if (bootId !== info.bootId) {
            console.log('Boot ID has changed.');
            console.log('Reloading page in 5 seconds...');
            setTimeout(() => {
              window.location.reload();
            }, 5000);
            return;
          }
        } catch (error) {
          console.log('Unable to get system info while rebooting retrying...');
          console.log('Attempt: ', attempts);
          console.log(error);
        }

        attempts++;

        getInfo();
      }, 5000);
    }

    getInfo();

    return function () {
      canceled = true;
      clearTimeout(timeout);
    };
  }, []);

  const name = api?.isCloud === true ? 'Homey' : 'Homey Pro';

  return (
    <S.Content>
      <Logo size="80px" />

      <S.Header>Restarting...</S.Header>

      <S.Paragraph>{`Please wait while ${name} is restarting.`}</S.Paragraph>

      <ProgressBar aria-label="Restarting" isIndeterminate={true} color={gradients.purle} />
    </S.Content>
  );
}

function UpdateContent(props) {
  const { i18n } = useI18n();

  const { updatesSettings } = updatesSettingsStore.use();

  useEffect(() => {
    function onDownloadDone() {}
    function onInstallDone({ bootId }) {
      HomeyStore.setIsUpdating(false);
      HomeyStore.awaitReboot({ bootId });
    }

    updatesSettingsStore.emitter.on('download_done', onDownloadDone);
    updatesSettingsStore.emitter.on('install_done', onInstallDone);

    return function () {
      updatesSettingsStore.emitter.off('download_done', onDownloadDone);
      updatesSettingsStore.emitter.off('install_done', onInstallDone);
    };
  }, []);

  if (updatesSettings.updateStateData?.error != null) {
    return (
      <S.Content>
        <Logo size="80px" />

        <S.Header>Error</S.Header>

        <S.Paragraph>{String(updatesSettings.updateStateData?.error)}</S.Paragraph>

        <GradientButton
          styleColor="blue"
          onPress={() => {
            updatesSettingsStore.installUpdate().catch(ToastManager.handleError);
          }}
        >
          <GradientButton.Text>{i18n.messageFormatter('common.retry')}</GradientButton.Text>
        </GradientButton>
      </S.Content>
    );
  }

  return (
    <S.Content>
      <Logo size="80px" />

      <S.Header>Downloading...</S.Header>

      <S.Paragraph>Homey Pro will restart once the update has been downloaded.</S.Paragraph>

      <ProgressBar
        aria-label="Downloading…"
        minValue={0}
        maxValue={1}
        value={updatesSettings.updateStateData?.downloads_progress ?? 0}
      />
    </S.Content>
  );
}

function S() {}
UpdateRebootPage.S = S;

S.Content = styled.div`
  ${scrollbars.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
`;

S.Header = styled.h3`
  padding-top: ${su(3)};
`;

S.Paragraph = styled.p`
  width: 320px;
  color: ${theme.color.text_light};
  text-align: center;
`;

// TODO move to own file
function ProgressBar(props) {
  const { showValueLabel = !!props.label } = props;
  const progressBar = useProgressBar(props);

  // Calculate the width of the progress bar as a percentage
  const percentage = (props.value - props.minValue) / (props.maxValue - props.minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <div {...progressBar.progressBarProps} style={{ width: 320 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {props.label && <span {...progressBar.labelProps}>{props.label}</span>}
        {showValueLabel && <span>{progressBar.progressBarProps['aria-valuetext']}</span>}
      </div>
      <div
        style={{ background: theme.color.mono_100, height: 6, borderRadius: 3, overflow: 'hidden' }}
      >
        <Bar
          data-is-indeterminate={props.isIndeterminate}
          style={{
            width: props.isIndeterminate ? '25%' : barWidth,
            background: props.color ?? gradients.blue,
          }}
        />
      </div>
    </div>
  );
}

const gradients = {
  blue: 'linear-gradient(45deg, rgb(54, 124, 255), rgb(82, 222, 255))',
  purle: 'linear-gradient(45deg, rgb(237, 15, 255), rgb(196, 18, 202))',
};

export const indeterminate = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(400%);
  }
`;

const Bar = styled.div`
  height: 6px;
  border-radius: 3px;
  transition: width 0.8s linear;

  &[data-is-indeterminate='true'] {
    animation-name: ${indeterminate};
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
