import { useEffect, useState, useMemo } from 'react';

import { RouteManager } from '../../RouteManager';
import { ScriptStore } from './ScriptStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useScriptRouteId } from './useScriptRouteId';
import { useScriptsById } from './useScripts';

import { theme } from '../../theme/theme';

import { SearchField } from '../../components/common/search-field/SearchField';
import { SubNav } from '../../components/sub-navigation/SubNavRoot';
import { SubNavItem } from '../../components/sub-navigation/SubNavItem';

import { ScriptNavigationItem } from './ScriptNavigationItem';

import { iconPage } from '../../theme/icons/interface/page';

export function ScriptNavigation(props) {
  const { i18n } = useI18n();
  const { scriptRouteId } = useScriptRouteId();

  const scripts = useScriptsById();

  const [filterValue, setFilterValue] = useState('');
  const [isNewScriptMode, setIsNewScriptMode] = useState(false);

  const items = useScriptItems({
    scripts,
    filterValue,
    isNewScriptMode,
  });

  useEffect(() => {
    if (
      scriptRouteId != null &&
      scripts.byId?.[scriptRouteId] == null &&
      scripts.loading === false &&
      scripts.error == null
    ) {
      RouteManager.toScripts();
    }
  }, [scripts.byId, scripts.loading, scripts.error, scriptRouteId]);

  function onSelectionChange(keys, listState) {
    const item = listState.collection.getItem(keys[0]);

    if (item != null) {
      RouteManager.toScript(encodeURIComponent(item.key));
    } else {
      RouteManager.toScripts();
    }
  }

  function renderItem({ value }) {
    if (value.type === 'edit') {
      return (
        <SubNavItem.Root
          aria-label={i18n.messageFormatter('script.addLabel')}
          data-is-renaming={true}
        >
          <SubNavItem.Inner>
            <SubNavItem.Icon url={iconPage} size={theme.icon.size_small} />

            <SubNavItem.NameTextField
              aria-label={i18n.messageFormatter('script.nameLabel')}
              defaultValue={i18n.messageFormatter('script.newScriptName')}
              selectDefaultValue={true}
              onSaveRequest={(value) => {
                Promise.resolve()
                  .then(async () => {
                    const result = await ScriptStore.createScript({
                      script: {
                        id: value,
                        name: value,
                        code: '',
                      },
                    });

                    if (result != null) {
                      setIsNewScriptMode(false);
                      RouteManager.toScript(encodeURIComponent(result.id));
                    }
                  })
                  .catch(console.error);
              }}
              onCancelRequest={() => {
                setIsNewScriptMode(false);
              }}
            />
          </SubNavItem.Inner>
        </SubNavItem.Root>
      );
    }

    return <ScriptNavigationItem />;
  }

  return (
    <SubNav.Root>
      <SubNav.SearchFieldWrapper>
        <SearchField
          aria-label={i18n.messageFormatter('script.filter')}
          placeholder={i18n.messageFormatter('common.filterPlaceholder')}
          onChange={(value) => {
            setFilterValue(value);
          }}
          onClear={() => {
            setFilterValue('');
          }}
        />
      </SubNav.SearchFieldWrapper>

      <SubNav.ListBox
        aria-label={i18n.messageFormatter('script.title')}
        items={items}
        selectedKeys={scriptRouteId != null ? [scriptRouteId] : []}
        renderItem={renderItem}
        onSelectionChange={onSelectionChange}
      />

      <SubNav.Footer>
        <SubNav.NewButton
          onPress={() => {
            setIsNewScriptMode(true);
          }}
        >
          {i18n.messageFormatter('script.newScript')}
        </SubNav.NewButton>
      </SubNav.Footer>
    </SubNav.Root>
  );
}

function useScriptItems({ scripts, filterValue, isNewScriptMode }) {
  return useMemo(() => {
    const children = Object.values(scripts.byId ?? {})
      .map((script) => {
        return {
          key: script.id,
          type: 'script',
          textValue: script.name,
          context: {
            script: script,
          },
        };
      })
      .filter((item) => {
        return item.textValue?.toLowerCase().includes(filterValue.toLowerCase());
      });

    if (isNewScriptMode) {
      children.push({
        key: 'edit',
        type: 'edit',
        textValue: 'edit',
      });
    }

    return children;
  }, [scripts.byId, filterValue, isNewScriptMode]);
}
