import React, { useContext, useMemo } from 'react';

import { FlowCardUtils } from '../../../store/flow-cards/FlowCardUtils';

import { useFlowCards } from '../../../store/flow-cards/useFlowCards';
import { useDevicesById } from '../../../store/devices/useDevices';
import { useMoodsById } from '../../../store/moods/useMoods';

const FlowCardExplorerContext = React.createContext({});

export function FlowCardExplorerContextProvider(props) {
  const flowCards = useFlowCards();

  const devices = useDevicesById();
  const moods = useMoodsById();

  const context = useMemo(() => {
    if (
      devices.byId != null &&
      flowCards.triggers.byKey != null &&
      flowCards.conditions.byKey != null &&
      flowCards.actions.byKey != null
    ) {
      const triggers = processGroup({
        data: Object.entries(flowCards.triggers.byKey),
        devicesById: devices.byId,
        moodsById: moods.byId,
      });

      const conditions = processGroup({
        data: Object.entries(flowCards.conditions.byKey),
        devicesById: devices.byId,
        moodsById: moods.byId,
      });

      const actions = processGroup({
        data: Object.entries(flowCards.actions.byKey),
        devicesById: devices.byId,
        moodsById: moods.byId,
      });

      return {
        triggers,
        conditions,
        actions,
      };
    }

    return {
      triggers: {
        byZoneId: {},
        byDeviceId: {},
        byAppUri: {},
        byManagerUri: {},
      },
      conditions: {
        byZoneId: {},
        byDeviceId: {},
        byAppUri: {},
        byManagerUri: {},
      },
      actions: {
        byZoneId: {},
        byDeviceId: {},
        byAppUri: {},
        byManagerUri: {},
      },
    };
  }, [devices.byId, moods.byId, flowCards.triggers, flowCards.conditions, flowCards.actions]);

  return (
    <FlowCardExplorerContext.Provider value={context}>
      {props.children}
    </FlowCardExplorerContext.Provider>
  );
}

export function useFlowCardExplorerContext() {
  return useContext(FlowCardExplorerContext);
}

function processGroup({ data, devicesById, moodsById }) {
  return data.reduce(
    (accumulator, [key, flowCard]) => {
      const card = flowCard;
      let isGrouped = false;

      if (FlowCardUtils.isZoneCard(flowCard)) {
        isGrouped = true;
        const uri = flowCard.ownerUri ?? flowCard.uri;
        const zoneId = uri.substring('homey:zone:'.length);

        accumulator.byZoneId[zoneId] = {
          ...accumulator.byZoneId[zoneId],
          cardsByKey: {
            ...(accumulator.byZoneId[zoneId]?.cardsByKey ?? null),
            [key]: card,
          },
        };
      }

      if (FlowCardUtils.isMoodCard(flowCard)) {
        isGrouped = true;
        const uri = flowCard.ownerUri ?? flowCard.uri;
        const moodId = uri.substring('homey:mood:'.length);
        const mood = moodsById[moodId];

        if (mood != null) {
          accumulator.byZoneId[mood.zone] = {
            ...accumulator.byZoneId[mood.zone],
            moods: {
              ...(accumulator.byZoneId[mood.zone]?.moods ?? null),
              [mood.id]: { name: mood.name, card },
            },
          };
        }
      }

      if (FlowCardUtils.isDeviceCard(flowCard)) {
        isGrouped = true;
        const uri = flowCard.ownerUri ?? flowCard.uri;
        const deviceId = uri.substring('homey:device:'.length);

        const device = devicesById[deviceId];

        if (device != null && device.flags?.includes('homey') === false) {
          const zoneId = device.zone;
          const driverUri = device.driverUri;
          const deviceName = device.name;

          accumulator.byZoneId[zoneId] = {
            ...accumulator.byZoneId[zoneId],
            devices: {
              ...(accumulator.byZoneId[zoneId]?.devices ?? null),
              [deviceId]: { name: deviceName },
            },
          };

          if (driverUri.startsWith('homey:app')) {
            accumulator.byAppUri[driverUri] = {
              ...accumulator.byAppUri[driverUri],
              devices: {
                ...(accumulator.byAppUri[driverUri]?.devices ?? null),
                [deviceId]: { name: deviceName },
              },
            };
          } else if (driverUri.startsWith('homey:manager')) {
            accumulator.byManagerUri[driverUri] = {
              ...accumulator.byManagerUri[driverUri],
              devices: {
                ...(accumulator.byManagerUri[driverUri]?.devices ?? null),
                [deviceId]: { name: deviceName },
              },
            };
          } else {
            console.error('Failed to group by driverUri', flowCard);
          }

          accumulator.byDeviceId[deviceId] = {
            ...accumulator.byDeviceId[deviceId],
            cardsByKey: {
              ...(accumulator.byDeviceId[deviceId]?.cardsByKey ?? null),
              [key]: card,
            },
          };
        }
      }

      if (FlowCardUtils.isAppCard(flowCard)) {
        isGrouped = true;
        const uri = flowCard.ownerUri ?? flowCard.uri;
        // const appUri = uri.substring('homey:app:'.length);
        const appUri = uri;

        accumulator.byAppUri[appUri] = {
          ...accumulator.byAppUri[appUri],
          cardsByKey: {
            ...(accumulator.byAppUri[appUri]?.cardsByKey ?? null),
            [key]: card,
          },
        };
      }

      if (FlowCardUtils.isManagerCard(flowCard)) {
        isGrouped = true;
        const uri = flowCard.ownerUri ?? flowCard.uri;
        // const appUri = uri.substring('homey:manager:'.length);
        const managerUri = uri;

        accumulator.byManagerUri[managerUri] = {
          ...accumulator.byManagerUri[managerUri],
          cardsByKey: {
            ...(accumulator.byManagerUri[managerUri]?.cardsByKey ?? null),
            [key]: card,
          },
        };

        if (accumulator.byManagerUri[managerUri].name == null) {
          accumulator.byManagerUri[managerUri].name = flowCard.ownerName ?? flowCard.uriObj?.name;
        }
      }

      if (isGrouped === false) {
        console.error('Failed to group card', flowCard);
      }

      return accumulator;
    },
    {
      byZoneId: {},
      byDeviceId: {},
      byAppUri: {},
      byManagerUri: {},
    }
  );
}
