import React from 'react';

import { useOptionContext } from '../list-box/ListBox';

import { ColumnItemDefault } from './ColumnItemDefault';

export function ColumnItemGeneric(props) {
  const { value } = useOptionContext();

  const url = value.context.iconUrl;
  const backgroundColor = value.context.iconBackgroundColor;

  return <ColumnItemDefault url={url} backgroundColor={backgroundColor} />;
}
