import { generalSettingsStore } from './GeneralSettingsStore';
import { formatBytes } from '../../../lib/formatBytes';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

import { GeneralSettingsSection } from './GeneralSettingsSection';
import { ExternalLink } from '../../../components/common/link/ExernalLink';
import { Clipboard } from '../../../components/common/Clipboard';
import { Icon } from '../../../components/common/Icon';

import { iconExternalLink } from '../../../theme/icons/interface/external-link';
import { useApi } from '../../../store/HomeyStore';

export function GeneralSettingsInfoSystem(props) {
  const { i18n } = useI18n();

  const { api } = useApi();
  const { generalSettings } = generalSettingsStore.use();

  return (
    <GeneralSettingsSection.S.Section>
      <GeneralSettingsSection.S.SectionHeading>
        {i18n.messageFormatter('settings.system.generalInfoSystemTitle')}
      </GeneralSettingsSection.S.SectionHeading>
      <GeneralSettingsSection.S.SectionView>
        {api?.isCloud === false && (
          <GeneralSettingsSection.S.SubSection>
            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemHostname')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                <Clipboard
                  styleTextAlign="right"
                  styleColor="light"
                  message={i18n.messageFormatter(
                    'settings.system.generalInfoSystemHostnameCopied',
                    { value: generalSettings.systemInfoData?.hostname }
                  )}
                  value={generalSettings.systemInfoData?.hostname}
                />
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('common.memory')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {formatBytes(
                  generalSettings.systemInfoData?.totalmem -
                    (generalSettings.systemInfoData?.freemem ??
                      generalSettings.systemInfoData?.freememMachine)
                )}{' '}
                / {formatBytes(generalSettings.systemInfoData?.totalmem)}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>Devmode</GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {(() => {
                  return generalSettings.systemInfoData?.devmode === true
                    ? i18n.messageFormatter('common.true')
                    : i18n.messageFormatter('common.false');
                })()}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>Node.js</GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {generalSettings.systemInfoData?.nodeVersion}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemBootId')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                <Clipboard
                  styleTextAlign="right"
                  styleColor="light"
                  message={i18n.messageFormatter('settings.system.generalInfoSystemBootIdCopied', {
                    value: generalSettings.systemInfoData?.bootId,
                  })}
                  value={generalSettings.systemInfoData?.bootId}
                />
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemUptime')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {i18n.dateFormatter.formatDistanceToNowStrict(
                  Date.now() - (generalSettings.systemInfoData?.uptime ?? 0) * 1000
                )}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>
          </GeneralSettingsSection.S.SubSection>
        )}

        <GeneralSettingsSection.S.SubSection>
          <GeneralSettingsSection.S.SubSectionHeading>
            {i18n.messageFormatter('settings.system.generalInfoSystemDateAndTime')}
          </GeneralSettingsSection.S.SubSectionHeading>
          <GeneralSettingsSection.S.Row title={generalSettings.systemInfoData?.date}>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('settings.system.generalInfoSystemDate')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {generalSettings.systemInfoData?.date != null
                ? i18n.dateFormatter.format(
                    new Date(generalSettings.systemInfoData.date),
                    'MMM dd yyyy HH:mm'
                  )
                : '-'}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('settings.system.generalInfoSystemTimezone')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {generalSettings.systemInfoData?.timezone}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
        </GeneralSettingsSection.S.SubSection>

        {api?.isCloud === false && (
          <GeneralSettingsSection.S.SubSection>
            <GeneralSettingsSection.S.SubSectionHeading>
              {i18n.messageFormatter('settings.system.generalInfoSystemWifi')}
            </GeneralSettingsSection.S.SubSectionHeading>

            {api?.homey.platformVersion >= 2 && (
              <GeneralSettingsSection.S.Row>
                <GeneralSettingsSection.S.Label>
                  {i18n.messageFormatter('common.connected')}
                </GeneralSettingsSection.S.Label>
                <GeneralSettingsSection.S.Value>
                  {(() => {
                    return generalSettings.systemInfoData?.wifiConnected === true
                      ? i18n.messageFormatter('common.true')
                      : i18n.messageFormatter('common.false');
                  })()}
                </GeneralSettingsSection.S.Value>
              </GeneralSettingsSection.S.Row>
            )}

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>MAC</GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {generalSettings.systemInfoData?.wifiMac ?? '-'}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemNetwork')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {generalSettings.systemInfoData?.wifiSsid ?? '-'}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemAddress')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {generalSettings.systemInfoData?.wifiAddress ?? '-'}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>
          </GeneralSettingsSection.S.SubSection>
        )}

        {api?.isCloud === false && api?.homey.platformVersion >= 2 && (
          <GeneralSettingsSection.S.SubSection>
            <GeneralSettingsSection.S.SubSectionHeading>
              {i18n.messageFormatter('settings.system.generalInfoSystemEthernet')}
            </GeneralSettingsSection.S.SubSectionHeading>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('common.connected')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {(() => {
                  return generalSettings.systemInfoData?.ethernetConnected === true
                    ? i18n.messageFormatter('common.true')
                    : i18n.messageFormatter('common.false');
                })()}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>MAC</GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {generalSettings.systemInfoData?.ethernetMac ?? '-'}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemAddress')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {generalSettings.systemInfoData?.ethernetAddress ?? '-'}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>
          </GeneralSettingsSection.S.SubSection>
        )}

        {api?.isCloud === false && (
          <GeneralSettingsSection.S.SubSection>
            <GeneralSettingsSection.S.SubSectionHeading>
              {i18n.messageFormatter('settings.system.generalInfoSystemCpuUsage')}
            </GeneralSettingsSection.S.SubSectionHeading>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemCpuUsageLastMinute')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {(() => {
                  const load = generalSettings.systemInfoData?.loadavg?.[0];
                  const cpus = generalSettings.systemInfoData?.cpus?.length;

                  if (load != null && cpus != null) {
                    return `${((load / cpus) * 100).toFixed(2)}%`;
                  }

                  return '-';
                })()}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemCpuUsageLast5Minutes')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {(() => {
                  const load = generalSettings.systemInfoData?.loadavg?.[1];
                  const cpus = generalSettings.systemInfoData?.cpus?.length;

                  if (load != null && cpus != null) {
                    return `${((load / cpus) * 100).toFixed(2)}%`;
                  }

                  return '-';
                })()}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>

            <GeneralSettingsSection.S.Row>
              <GeneralSettingsSection.S.Label>
                {i18n.messageFormatter('settings.system.generalInfoSystemCpuUsageLast15Minutes')}
              </GeneralSettingsSection.S.Label>
              <GeneralSettingsSection.S.Value>
                {(() => {
                  const load = generalSettings.systemInfoData?.loadavg?.[2];
                  const cpus = generalSettings.systemInfoData?.cpus?.length;

                  if (load != null && cpus != null) {
                    return `${((load / cpus) * 100).toFixed(2)}%`;
                  }

                  return '-';
                })()}
              </GeneralSettingsSection.S.Value>
            </GeneralSettingsSection.S.Row>
          </GeneralSettingsSection.S.SubSection>
        )}

        <GeneralSettingsSection.S.Row>
          <GeneralSettingsSection.S.Label>
            {i18n.messageFormatter('settings.system.generalInfoSystemCredits')}
          </GeneralSettingsSection.S.Label>
          <GeneralSettingsSection.S.Value>
            <ExternalLink url="https://credits.athom.com">
              <Icon size={theme.icon.size_small} url={iconExternalLink} />
            </ExternalLink>
          </GeneralSettingsSection.S.Value>
        </GeneralSettingsSection.S.Row>

        <GeneralSettingsSection.S.Row>
          <GeneralSettingsSection.S.Label>
            {i18n.messageFormatter('settings.system.generalInfoSystemPrivacyPolicy')}
          </GeneralSettingsSection.S.Label>
          <GeneralSettingsSection.S.Value>
            <ExternalLink url="https://legal.athom.com/">
              <Icon size={theme.icon.size_small} url={iconExternalLink} />
            </ExternalLink>
          </GeneralSettingsSection.S.Value>
        </GeneralSettingsSection.S.Row>
      </GeneralSettingsSection.S.SectionView>
    </GeneralSettingsSection.S.Section>
  );
}
