import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useToggleState } from 'react-stately';
import { usePress, useLabel, useSwitch, VisuallyHidden, mergeProps } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';
import { theme } from '../../theme/theme';

import { Hint } from './Hint';

export function Switch(props) {
  const switchRef = useRef();

  const { ref: registerRef, ...registerProps } =
    props.register?.(props.name, {
      required: props.required,
    }) ?? {};

  const label = useLabel({
    label: props.label,
    'aria-label': props['aria-label'],
  });

  const { autoFocus, ...rest } = props;
  const toggleState = useToggleState({
    ...rest,
    defaultSelected: props.defaultValue,
    isSelected: props.value,
  });

  const [isFocused, setIsFocused] = useState(false);

  const switch_ = useSwitch(
    {
      ...rest,
      ...label.fieldProps,
      defaultSelected: props.defaultValue,
      isSelected: props.value,
      onFocusChange(isFocused) {
        setIsFocused(isFocused);
      },
    },
    toggleState,
    switchRef
  );

  useEffect(() => {
    if (autoFocus === true) {
      setTimeout(() => {
        switchRef.current.focus();
      }, 0);
    }
  }, [autoFocus]);

  const press = usePress({
    isDisabled: props.isDisabled,
    onPress() {
      // controlled
      if (props.onChange != null && props.value != null) {
        props.onChange(!props.value);
      } else {
        toggleState.toggle();
        switchRef.current.focus();
      }
    },
  });

  const Root = props.orientation === 'vertical' ? Switch.RootVertical : Switch.RootHorizontal;

  return (
    <Switch.Root as={Root} className={props.className}>
      <VisuallyHidden>
        <input
          {...mergeProps(switch_.inputProps, registerProps)}
          ref={mergeRefs([switchRef, registerRef])}
        />
      </VisuallyHidden>

      {props.label && (
        <Switch.Label {...label.labelProps} title={props.label}>
          {props.label}
        </Switch.Label>
      )}

      {props.hint && <Hint hint={props.hint} />}

      <Switch.InputContainer>
        <Switch.Input
          {...press.pressProps}
          aria-hidden="true"
          data-is-selected={toggleState.isSelected}
          data-is-focused={isFocused}
          data-is-disabled={props.isDisabled}
        />
      </Switch.InputContainer>
      {props.children}
    </Switch.Root>
  );
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.any,
  register: PropTypes.func,
};

Switch.Root = styled.div`
  display: flex;
`;

Switch.Label = styled.label`
  display: inline-block;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Switch.Input = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 24px;
  background-color: ${theme.color.mono_100};
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
  transition: ${theme.duration.fast} ${theme.curve.easeInOut};
  transition-property: background-color;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: ${theme.color.white};
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    transition: ${theme.transition.micro};
  }

  &[data-is-selected='true'] {
    background-color: ${theme.color.green};

    &:hover:not([data-is-disabled='true']) {
      background: ${theme.color.green_hover};
    }

    &::before {
      left: calc(100% - 22px);
    }
  }

  &[data-is-disabled='true'] {
    background-color: ${theme.color.mono_050};
    cursor: not-allowed;

    &::before {
      box-shadow: none;
    }
  }
`;

Switch.InputContainer = styled.div`
  display: inline-flex;
  flex: 1 0 50%;
  justify-content: flex-end;
`;

Switch.RootHorizontal = styled(Switch.Root)`
  align-items: center;
  justify-content: space-between;

  ${Switch.Label} {
    flex: 0 1 50%;
    margin-bottom: 0;
    font-weight: ${theme.fontWeight.regular};
  }

  ${Switch.Input} {
  }
`;

Switch.RootVertical = styled(Switch.Root)`
  flex-direction: column;
  align-items: flex-start;

  ${Switch.Label} {
    color: ${theme.color.text_light};
    margin-bottom: 5px;
    font-size: ${theme.fontSize.small};
    line-height: 24px;
  }

  ${Switch.Input} {
  }
`;
