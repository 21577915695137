import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';

import {
  DismissButton,
  FocusScope,
  HiddenSelect,
  mergeProps,
  useListBox,
  useOption,
  useOverlay,
  useOverlayPosition,
  useSelect,
  useTextField,
} from 'react-aria';
import { Item, useOverlayTriggerState, useSelectState } from 'react-stately';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { getIconUrl } from '../../header/global-search/getIconUrl';
import { getDeviceIconURL } from '../../../components/device/DeviceIcon';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useDevicesById } from '../../../store/devices/useDevices';
import { useZonesById } from '../../../store/zones/useZones';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { theme } from '../../../theme/theme';
import { containers } from '../../../theme/elements/containers';
import { argInput } from './argInput';

import { Icon } from '../../../components/common/Icon';
import { Image } from '../../../components/common/Image';
import { Argument, ArgumentBase } from './Argument';
import { Scroll } from '../../../components/common/Scroll';

import { iconMagnifyingGlass } from '../../../theme/icons/interface/magnifying-glass/magnifying-glass';

export function ArgumentDevice(props) {
  const { i18n } = useI18n();

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.device.title');
  const placeholder =
    props.argument.placeholder ?? i18n.messageFormatter('flow.argument.device.placeholder');

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  let renderValue = null;

  switch (true) {
    case localValue != null:
      renderValue = localValue.name;
      break;
    default:
      renderValue = props.argumentTypeText;
      break;
  }

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard(value) {
    if (storeValue !== value) {
      const nextValue = value != null ? value : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    triggerState.close();

    const nextValue = value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });

    updateCard(nextValue);
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard(localValue);
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-invalid={isInvalid}
            data-is-empty={localValue == null}
          >
            {/*{localValue?.image && <sc.ArgumentImage url={localValue.image} />}*/}
            {/*{localValue?.icon && <Icon url={localValue.icon} />}*/}
            {renderValue}
          </ArgumentBase>
        );
      }}
      renderOverlay={() => {
        return (
          <AutocompleteInput
            card={props.card}
            cardType={props.cardType}
            argumentKey={props.argumentKey}
            argument={props.argument}
            value={localValue}
            label={label}
            placeholder={placeholder}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
            onInteractOutside={(element) => {
              triggerState.close();
            }}
          />
        );
      }}
    />
  );
}

function AutocompleteInput(props) {
  const selectRef = useRef();
  const { i18n } = useI18n();

  const devices = useDevicesById();
  const zones = useZonesById();

  const filteredDevicesList = useMemo(() => {
    const ownerProps = ResourceUtils.getOwnerProps(props.card);

    const searchParams = new URLSearchParams(props.argument.filter ?? '');
    const preparedOptions = {};

    // Prepare the options for all search params.
    // https://apps.developer.homey.app/the-basics/flow#flow-card-device-filters
    for (let [key, value] of searchParams.entries()) {
      // Only driver_id does not match the actual device property key.
      if (key === 'driver_id') {
        preparedOptions.driverId = value.split('|');
        continue;
      }

      preparedOptions[key] = value.split('|');
    }
    const preparedOptionsEntries = Object.entries(preparedOptions);

    let driverUri = null;

    switch (ownerProps.ownerType) {
      case 'device':
        const deviceId = ownerProps.ownerId;
        const device = devices.byId?.[deviceId];
        // The driverUri is the app or manager this device belongs to.
        driverUri = device?.driverUri;
        break;
      case 'app':
      case 'manager':
        // Manager case is unlikely to ever happen but might aswell support it.
        driverUri = ownerProps.ownerUri;
        break;
      default:
        break;
    }

    if (driverUri != null) {
      // Return all devices that belong to this driverUri(app or manager) and match the search params.
      return Object.values(devices.byId ?? {}).filter((device) => {
        const isAppOrManagerDevice = device.driverUri === driverUri;

        // The device needs to belong to the current app or manager.
        if (isAppOrManagerDevice === true) {
          let matches = 0;

          for (let [deviceOptionKey, options] of preparedOptionsEntries) {
            // e.g. device.capabilities
            if (Array.isArray(device[deviceOptionKey])) {
              // e.g. options = [onoff,dim]
              const isSomeMatch = options.some((value) => {
                return device[deviceOptionKey].includes(value);
              });

              if (isSomeMatch) {
                matches = matches + 1;
              }
            } else if (
              device[deviceOptionKey] != null &&
              options.includes(device[deviceOptionKey])
            ) {
              // e.g. device.class, options = ['socket']
              matches = matches + 1;
            }
          }

          // Every deviceOptionKey value or values should match at least one options value.
          if (matches === preparedOptionsEntries.length) {
            return true;
          }
        }

        return false;
      });
    }

    return [];
  }, [devices.byId, props.card, props.argument]);

  const items = useMemo(() => {
    return filteredDevicesList.map((device) => {
      const icon = getDeviceIconURL({
        iconUrl: device.iconObj?.id != null ? getIconUrl({ iconId: device.iconObj.id }) : null,
        iconOverride: device.iconOverride,
      });
      const zone = zones.byId?.[device.zone];

      return {
        id: device.id,
        name: device.name,
        icon: icon,
        description: zone?.name,
      };
    });
  }, [filteredDevicesList, zones.byId]);

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState(props.value?.name ?? '');

  const handleQueryChange = useMemo(() => {
    return debounce((value) => {
      setQuery(value);
    }, 200);
  }, []);

  const children = (item) => {
    return <Item key={item.id ?? item.name} value={item} textValue={item.name} children={null} />;
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      selectRef.current?.focus();
    });
  }, []);

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      return (
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.description.toLowerCase().includes(query.toLowerCase())
      );
    });
  }, [items, query]);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = props.value?.name ?? '';
    }
  }, [props.value]);

  const sharedProps = {
    children,
    items: filteredItems,
    label: props.label,
    placeholder: props.placeholder,
    selectedKey: props.value?.id ?? props.value?.name ?? '',
    onSelectionChange: (key) => {
      const item = items.find((item) => {
        return item.id === key || item.name === key;
      });

      props.onChange(item);
    },
  };

  const selectState = useSelectState(sharedProps);
  const select = useSelect({ ...sharedProps, autoFocus: false }, selectState, selectRef);

  const { onBlur, onFocus, onKeyDown, onKeyDownCapture, onPress, onPressStart, ...rest } =
    select.triggerProps;

  const textField = useTextField(
    {
      ...rest,
      placeholder: props.placeholder,
      type: 'text',
      onChange(...args) {
        selectState.open();
        handleQueryChange(...args);
      },
      onFocus() {
        selectState.open();
      },
      onKeyDown(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
          props.onSaveRequest();
        } else if (event.key === 'Delete') {
          event.preventDefault();
          props.onChange(undefined);
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
      onKeyUp(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
        } else if (event.key === 'Delete') {
          event.preventDefault();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
    },
    selectRef
  );

  const containerRef = useRef();

  return (
    <argInput.InputContainer ref={containerRef}>
      <HiddenSelect
        state={selectState}
        triggerRef={selectRef}
        label={props.label}
        name={props.name}
      />
      <argInput.InputLabel {...select.labelProps}>{props.label}</argInput.InputLabel>
      <argInput.InputRow>
        <argInput.InputWrapper>
          {props.value?.image && <Image url={props.value?.image} />}
          {props.value?.icon && <Icon url={props.value?.icon} />}
          <argInput.Input {...textField.inputProps} ref={selectRef} />
          <argInput.InputIcon url={iconMagnifyingGlass} />
        </argInput.InputWrapper>
      </argInput.InputRow>
      {selectState.isOpen && (
        <ListBoxPopup
          messageFormatter={i18n.messageFormatter}
          menuProps={select.menuProps}
          targetRef={selectRef}
          selectState={selectState}
          onInteractOutside={(element) => {
            if (
              containerRef.current.contains(element) ||
              element.isSameNode(containerRef.current)
            ) {
              return;
            }

            props.onInteractOutside(element);
          }}
        />
      )}
    </argInput.InputContainer>
  );
}

function ListBoxPopup(props) {
  delete props.menuProps.autoFocus;

  const listBoxRef = useRef();
  const listBox = useListBox(
    {
      autoFocus: false,
      disallowEmptySelection: false,
      shouldFocusOnHover: false,
      ...props.menuProps,
    },
    props.selectState,
    listBoxRef
  );

  const overlayRef = useRef();
  const overlay = useOverlay(
    {
      onClose: () => props.selectState.close(),
      shouldCloseOnBlur: false,
      isOpen: props.selectState.isOpen,
      isDismissable: true,
      shouldCloseOnInteractOutside(element) {
        props.onInteractOutside(element);
        return true;
      },
    },
    overlayRef
  );

  const overlayPosition = useOverlayPosition({
    targetRef: props.targetRef,
    overlayRef: overlayRef,
    placement: 'bottom',
    offset: 5,
    crossOffset: 0,
    isOpen: props.selectState.isOpen,
    shouldUpdatePosition: true,
    shouldFlip: true,
  });

  const items = [...props.selectState.collection];

  return (
    // <OverlayContainer>
    <FocusScope restoreFocus contain>
      <sc.Overlay
        {...mergeProps(overlay.overlayProps, overlayPosition.overlayProps)}
        ref={overlayRef}
        style={{
          ...overlayPosition.overlayProps.style,
          maxHeight: Math.min(overlayPosition.overlayProps.style.maxHeight ?? 300, 300),
        }}
      >
        <DismissButton onDismiss={() => props.selectState.close()} />
        <Scroll
          style={{
            maxHeight: Math.min(overlayPosition.overlayProps.style.maxHeight ?? 300, 300),
          }}
        >
          <sc.List {...mergeProps(listBox.listBoxProps, props.menuProps)} ref={listBoxRef}>
            {items.map((item) => (
              <Option key={item.key} item={item} state={props.selectState} />
            ))}

            {items.length === 0 && <li>{props.messageFormatter('common.emptyResults')}</li>}
          </sc.List>
        </Scroll>
        <DismissButton onDismiss={() => props.selectState.close()} />
      </sc.Overlay>
    </FocusScope>
    //</OverlayContainer>
  );
}

function Option({ item, state }) {
  const optionRef = useRef();
  const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = state.selectionManager.isSelected(item.key);
  const option = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: false,
    },
    state,
    optionRef
  );

  const value = item.props.value;
  const hasIcon = value?.image || value?.icon;

  return (
    <sc.ListItem {...mergeProps(option.optionProps)} ref={optionRef}>
      {hasIcon && (
        <ListItemIconWrapper>
          {value?.image && <sc.ListImage url={value.image} />}
          {value?.icon && <Icon url={value.icon} />}
        </ListItemIconWrapper>
      )}

      <sc.ListItemDetails>
        <sc.ListItemName>{value.name}</sc.ListItemName>
        <sc.ListItemDescription>{value.description}</sc.ListItemDescription>
      </sc.ListItemDetails>
    </sc.ListItem>
  );
}

const ArgumentImage = styled(Image)`
  vertical-align: middle;
  margin-right: 4px;
  transform: translateY(-1px);
`;

const ListImage = styled(Image)`
  width: 30px;
  height: 30px;
  margin: -5px 0;
  vertical-align: middle;
  display: block;
`;

const Overlay = styled.div`
  ${containers.card};
  position: relative;
`;

const List = styled.ul`
  width: 300px;
  outline: 0;
  padding: 10px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  outline: 0;
  padding: 10px;
  cursor: pointer;
  border-radius: ${theme.borderRadius.default};

  &:hover {
    background-color: ${theme.color.background_hover};
  }
`;

const ListItemIconWrapper = styled.div`
  margin-right: 10px;
`;

const ListItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItemName = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemDescription = styled.div`
  color: ${theme.color.text_light};
`;

export const sc = {
  Overlay,
  List,
  ListItem,
  ListItemIconWrapper,
  ListItemDetails,
  ListItemName,
  ListItemDescription,
  ArgumentImage,
  ListImage,
};
