import React, { useRef, useMemo, useReducer, useEffect } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';

import { Option, Select } from '../Select';
import { IconButton } from '../../buttons/IconButton';
import * as Containers from '../Containers';

import default_camera_image from '../images/camera/default_camera_image.png';
import refresh from '../images/button/refresh.svg';
import { ToastManager } from '../../../ToastManager';

function init(initialState) {
  return { ...initialState, imageUrl: null, loading: false };
}

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

function set(dispatch, payload) {
  dispatch({
    type: 'set',
    payload: payload,
  });
}

export function Camera({ device, component, capabilities }) {
  const images = useImages({ device });

  const imageRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [{ image, imageUrl, loading }, dispatch] = useReducer(
    reducer,
    { image: images?.[0] ?? null },
    init
  );

  useImageListener({ image, dispatch, imageRef });

  return (
    <Containers.Control>
      <Containers.Select>
        {images.length > 1 && (
          <Select
            aria-label="Camera Select"
            selectedKey={image?.id ?? null}
            onSelectionChange={(key) => {
              set(dispatch, {
                image: images.find((image) => image.id === key),
                loading: true,
              });
            }}
          >
            {images?.map((image) => {
              return <Option key={image.id}>{image.title}</Option>;
            })}
          </Select>
        )}
      </Containers.Select>
      <Containers.Action>
        <sc.Image
          ref={imageRef}
          src={default_camera_image}
          onLoad={() => set(dispatch, { loading: false })}
          onError={() => {
            imageRef.current.src = default_camera_image;
            ToastManager.handleError({ message: 'An error occurred while loading the image.' });
          }}
        />

        <IconButton
          url={refresh}
          color={theme.color.white}
          onPress={() => {
            fetchImage({ image, dispatch, imageRef });
          }}
        />
      </Containers.Action>
    </Containers.Control>
  );
}

function fetchImage({ image, dispatch, imageRef }) {
  if (!image || !image.imageObj) return;

  set(dispatch, { loading: true });

  const imageObj = image.imageObj;
  const imageUrl = `${imageObj.fullUrl}?lastUpdated=${getLastUpdated(image)}`;

  fetch(imageUrl)
    .then(async (res) => {
      if (res.ok) {
        imageRef.current.src = imageUrl;

        return;
      }

      await res.json().then((errorData) => {
        imageRef.current.src = default_camera_image;
        ToastManager.handleError(errorData);
      });
    })
    .catch((err) => {
      imageRef.current.src = default_camera_image;
      console.log('Failed to load the image', err);
      ToastManager.handleError({ message: 'An error occurred while loading the image.' });
    })
    .finally(() => {
      set(dispatch, { loading: false });
    });
}

function useImages({ device }) {
  return useMemo(() => {
    if (device == null) return null;

    return device.images.filter((image) => {
      return image.type === 'camera';
    });
  }, [device]);
}

function useImageListener({ image, dispatch, imageRef }) {
  useEffect(() => {
    if (image) {
      function handleUpdate() {
        fetchImage({ image, dispatch, imageRef });
      }

      image.imageObj.addListener('$update', handleUpdate);

      fetchImage({ image, dispatch, imageRef });

      return function () {
        image.imageObj.removeListener('$update', handleUpdate);
      };
    }
  }, [image, dispatch, imageRef]);
}

function getLastUpdated(image) {
  if (image.imageObj.lastUpdated) {
    return new Date(image.imageObj.lastUpdated).getTime();
  }
  return new Date().getTime();
}

const sc = {};

sc.Image = styled.img`
  width: 256px;
  height: 256px;
  border-radius: ${theme.borderRadius.default};
  margin-bottom: 20px;
  object-fit: contain;
  background-color: ${theme.color.black};
`;
