import { memo } from 'react';

import { getIndicator } from './getIndicator';

import { useDevice, useCapabilities } from '../../store/devices/useDevices';

import { DeviceTileStatic } from './DeviceTileStatic';

export const DeviceStatic = memo(function Device({ deviceId, tileSize }) {
  const { device } = useDevice({ deviceId });
  const { capabilities } = useCapabilities({ deviceId });

  const deviceName = device?.name.replace(/\s/g, ' ');

  const indicator = getIndicator({
    device,
    capabilities,
    tileSize,
  });

  return (
    <DeviceTileStatic
      name={deviceName}
      deviceId={deviceId}
      device={device}
      iconObj={device?.iconObj}
      iconOverride={device?.iconOverride}
      indicator={indicator}
      tileSize={tileSize}
    />
  );
});
