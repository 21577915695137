import { shallow } from 'zustand/shallow';

import type { HomeyAPI } from 'athom-api';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

type UpdateItem = { changelog: string; date: string; size: number; version: string };
type UpdatesInfo = UpdateItem[];

type UpdateStateData = {
  downloading: boolean;
  downloads_progress: number | null;
  installing: boolean;
  error: string | null;
};

interface State {
  updateData: UpdateItem | null;
  updateStateData: UpdateStateData | null;
  optionAutoupdateData: boolean;
  isLoading: boolean;
  isRefetchingUpdates: boolean;
  error: unknown;
}

type UpdatesSettingsStoreState = State & BaseApiStoreState;

class UpdatesSettingsStore extends BaseApiStore<State> {
  key = 'updates-settings';

  override createInitialState() {
    return {
      updateData: null,
      updateStateData: null,
      optionAutoupdateData: false,
      stateData: null,
      isLoading: true,
      isRefetchingUpdates: false,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:updates');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerUpdates = api.updates;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const updatePromise = managerUpdates.getUpdates({
        cache: false,
      }) as unknown as Promise<UpdatesInfo>;
      const optionAutoupdatePromise = managerUpdates.getOptionAutoupdate() as unknown as Promise<{
        value: boolean;
      }>;
      const updateStatePromise = managerUpdates.getState() as unknown as Promise<UpdateStateData>;

      const [, updateData, optionAutoupdateData, updateStateData] = await Promise.all([
        waitPromise,
        updatePromise,
        optionAutoupdatePromise,
        updateStatePromise,
      ]);

      this.set({
        ...this.createInitialState(),
        updateData: updateData[updateData.length - 1] ?? null,
        updateStateData: updateStateData,
        optionAutoupdateData: optionAutoupdateData.value,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, this.key);

    return { updatesSettings: this.store(selectAll, shallow) };
  }

  onAttached({ api }: { api: HomeyAPI }): void {
    console.log('onAttached');

    // @ts-ignore
    api.updates.addListener('state', this.onState);
    // @ts-ignore
    api.updates.addListener('download_start', this.onDownloadStart);
    // @ts-ignore
    api.updates.addListener('download_progress', this.onDownloadProgress);
    // @ts-ignore
    api.updates.addListener('download_done', this.onDownloadDone);
    // @ts-ignore
    api.updates.addListener('download_error', this.onDownloadError);
    // @ts-ignore
    api.updates.addListener('install_start', this.onInstallStart);
    // @ts-ignore
    api.updates.addListener('install_done', this.onInstallDone);
    // @ts-ignore
    api.updates.addListener('install_error', this.onInstallError);
  }

  onDetached({ api }: { api: HomeyAPI }): void {
    console.log('onDetached');

    // @ts-ignore
    api.updates.removeListener('state', this.onState);
    // @ts-ignore
    api.updates.removeListener('download_start', this.onDownloadStart);
    // @ts-ignore
    api.updates.removeListener('download_progress', this.onDownloadProgress);
    // @ts-ignore
    api.updates.removeListener('download_done', this.onDownloadDone);
    // @ts-ignore
    api.updates.removeListener('download_error', this.onDownloadError);
    // @ts-ignore
    api.updates.removeListener('install_start', this.onInstallStart);
    // @ts-ignore
    api.updates.removeListener('install_done', this.onInstallDone);
    // @ts-ignore
    api.updates.removeListener('install_error', this.onInstallError);
  }

  private onState = (state: { downloading: boolean; installing: boolean; error: null }) => {
    console.log('onState');
    console.log(state);

    this.set((prevState) => {
      if (prevState.updateStateData == null) return {};

      return {
        updateStateData: { ...prevState.updateStateData, ...state },
      };
    });
  };

  private onDownloadStart = () => {
    console.log('onDownloadStart');
  };

  private onDownloadProgress = ({ percent }: { percent: number }) => {
    console.log('onDownloadProgress');
    this.set((prevState) => {
      if (prevState.updateStateData == null) return {};

      return {
        updateStateData: { ...prevState.updateStateData, downloads_progress: percent / 100 },
      };
    });
  };

  private onDownloadDone = () => {
    console.log('onDownloadDone');
    this.emitter.emit('download_done');
  };

  private onDownloadError = ({ message }: { message: string }) => {
    console.log('onDownloadError', message);
    this.set((prevState) => {
      if (prevState.updateStateData == null) return {};

      return {
        updateStateData: { ...prevState.updateStateData, error: message },
      };
    });
  };

  private onInstallStart = () => {
    console.log('onInstallStart');
  };

  private onInstallDone = ({ bootId }: { bootId: string }) => {
    console.log('onInstallDone', { bootId });
    this.emitter.emit('install_done', { bootId });
  };

  // Only on platform version 1.
  private onInstallError = ({ message }: { message: string }) => {
    console.log('onInstallError');
    this.set((prevState) => {
      if (prevState.updateStateData == null) return {};

      return {
        updateStateData: { ...prevState.updateStateData, error: message },
      };
    });
  };

  public async installUpdate() {
    const api = this.getApi();
    await api.updates.installUpdate({});
  }

  public async refetchUpdates() {
    const api = this.getApi();
    const state = this.get();

    if (state.isRefetchingUpdates === true) return;

    this.set({
      isRefetchingUpdates: true,
    });

    let updateData;

    try {
      updateData = (await api.updates.getUpdates({
        cache: false,
      })) as unknown as UpdatesInfo;
    } catch (error) {
      this.set({
        isRefetchingUpdates: false,
      });
      throw error;
    }

    this.set({
      updateData: updateData[0],
      isRefetchingUpdates: false,
    });

    return updateData;
  }
}

function selectAll(state: UpdatesSettingsStoreState) {
  return {
    ...selectBase(state),
    updateData: state.updateData,
    optionAutoupdateData: state.optionAutoupdateData,
    updateStateData: state.updateStateData,
    isRefetchingUpdates: state.isRefetchingUpdates,
  };
}

export const updatesSettingsStore = new UpdatesSettingsStore();
