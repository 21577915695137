import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css as cssClassName } from '@emotion/css';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useMove } from 'react-aria';

import Editor from '@monaco-editor/react';

import { ScriptStore } from './ScriptStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useScript } from './useScripts';
import { useWebAppSettings } from '../../store/web-app-settings/useWebAppSettings';
import { useScriptRouteId } from './useScriptRouteId';
import { useScriptUnsavedChangesPrompt } from './useScriptUnsavedChangesPrompt';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { DeprecatedLabel } from './DeprecatedLabel';
import { ScriptOutput } from './ScriptOutput';

export function ScriptEditor(props) {
  const { i18n } = useI18n();
  const { settings } = useWebAppSettings();

  const { scriptRouteId } = useScriptRouteId();
  const { script } = useScript({ scriptId: scriptRouteId });

  const editorInstanceRef = props.editorInstanceRef;
  const monacoRef = useRef();

  const [isEditorReady, setIsEditorReady] = useState(false);

  const { prompt } = useScriptUnsavedChangesPrompt({ editorInstanceRef });

  useEffect(() => {
    if (script != null && isEditorReady === true && script.code != null) {
      if (
        editorInstanceRef.current != null &&
        script.code !== editorInstanceRef.current.getValue()
      ) {
        editorInstanceRef.current.setValue(script.code);
        editorInstanceRef.current.revealLine(0);
      }
    }
  }, [script, isEditorReady, editorInstanceRef]);

  function handleRequestSaveScript() {
    ScriptStore.updateScript({
      id: script.id,
      script: {
        code: editorInstanceRef.current?.getValue(),
      },
    });
  }

  function handleRequestTestScript() {
    ScriptStore.testScript({
      id: script.id,
      script: {
        code: editorInstanceRef.current?.getValue(),
      },
    });
  }

  // function handleEditorValidation(markers) {
  //   // model markers
  //   markers.forEach(marker => console.log("onValidate:", marker.message));
  // }

  const ref = useRef({});
  Object.assign(ref.current, {
    onSaveScriptRequest: handleRequestSaveScript,
    onTestScriptRequest: handleRequestTestScript,
  });

  function handleEditorDidMount(editorInstance, monaco) {
    // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneCodeEditor.html

    // https://microsoft.github.io/monaco-editor/api/modules/monaco.editor.html
    // console.log(editorInstance);
    // console.log(monaco);

    editorInstanceRef.current = editorInstance;
    monacoRef.current = monaco;

    applyMonacoSettings(monaco);
    fetchTypes(monaco);

    editorInstance.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      ref.current.onSaveScriptRequest();
    });

    editorInstance.addCommand(
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyR,
      () => {
        ref.current.onTestScriptRequest();
      }
    );

    editorInstance.updateOptions({
      tabSize: 2,
      wordWrap: 'wordWrapColumn',
      wordWrapColumn: 120,
      codeLens: true,
      padding: {
        bottom: 0,
        top: 0,
      },
      scrollbar: {
        verticalSliderSize: 10,
        verticalScrollbarSize: 10,
      },
      minimap: { enabled: false },
      fontSize: 14,
      fontFamily: 'Roboto Mono, monospace',
      fontWeight: 400,
      // needed for overflowing popover
      fixedOverflowWidgets: true,
    });

    // editorInstance.focus();

    setIsEditorReady(true);
  }

  const heightValue = useMotionValue(
    parseInt(localStorage.getItem(`homeyscript.output.height`) ?? 360, 10)
  );
  const flexBasis = useTransform(heightValue, (value) => `${value}px`);

  const move = useMove({
    onMoveStart(event) {},
    onMove(event) {
      if (heightValue.get() - event.deltaY < 20) return;
      heightValue.set(heightValue.get() - event.deltaY);
    },
    onMoveEnd(event) {
      localStorage.setItem(`homeyscript.output.height`, heightValue.get());
    },
  });

  return (
    <ScriptEditor.Root>
      <ScriptEditor.Header>
        <ScriptEditor.Name>
          {script?.name}
          <ScriptEditor.Extension>.js</ScriptEditor.Extension>
        </ScriptEditor.Name>

        {script?.version === 1 && <DeprecatedLabel />}
      </ScriptEditor.Header>

      <ScriptEditor.Layout>
        <ScriptEditor.Editor
          wrapperProps={{
            className: wrapperClassName,
          }}
          defaultLanguage="javascript"
          defaultValue=""
          theme={settings.theme.darkMode ? 'vs-dark' : 'light'}
          loading={<div />}
          onMount={handleEditorDidMount}
          // onValidate={handleEditorValidation}
        />

        <ScriptEditor.OutputContainer style={{ '--flex-basis': flexBasis }}>
          <ScriptEditor.Divider {...move.moveProps}>
            {i18n.messageFormatter('script.console')}
          </ScriptEditor.Divider>

          <ScriptOutput />
        </ScriptEditor.OutputContainer>
      </ScriptEditor.Layout>

      {prompt}
    </ScriptEditor.Root>
  );
}

ScriptEditor.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: ${su(1, 3, 2, 2)};
`;

ScriptEditor.OutputContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 0 0 var(--flex-basis);
`;

ScriptEditor.Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.su(1)};
`;

ScriptEditor.Divider = styled.div`
  display: flex;
  align-items: center;

  flex: 0 0 auto;
  padding: 20px 0;

  color: ${theme.color.text_light};

  &:hover,
  &:active {
    cursor: ns-resize;
  }

  &::after {
    margin-left: 20px;
    content: '';
    height: 1px;
    background-color: ${theme.color.line};
    flex-grow: 1;
  }
`;

ScriptEditor.Name = styled.h3`
  padding: 10px 0;
`;

ScriptEditor.Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

ScriptEditor.Extension = styled.span`
  color: ${theme.color.text_light};
`;

const wrapperClassName = cssClassName`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  align-items: stretch;
  border-radius: ${theme.borderRadius.default};
  background-color: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  overflow: hidden;

  .margin {
    background: transparent !important;
  }

  .monaco-editor {
    background: transparent !important;
  }

  .monaco-editor-background {
    background: transparent !important;
  }

  .overflow-guard {

  }

  .slider {
    border-radius: ${theme.borderRadius.default};
    width: 6px !important;
  }
`;

ScriptEditor.Editor = styled(Editor, {
  shouldForwardProp: (prop) => {
    // theme prop is prevented by emotion
    // on custom components
    return true;
  },
})`
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export function applyMonacoSettings(monaco) {
  monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    lib: ['es2019'], // https://www.typescriptlang.org/docs/handbook/compiler-options.html
    target: monaco.languages.typescript.ScriptTarget.ES2019,
    allowNonTsExtensions: true,
  });
}

export function fetchTypes(monaco) {
  fetch('/types/types.d.ts')
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      const libSource = [
        `
            declare var Homey: HomeyAPI
          `,
      ].join('\n');

      const result = data + '\n' + libSource;

      const libUri = 'ts:filename/homey.d.ts';
      monaco.languages.typescript.javascriptDefaults.addExtraLib(result, libUri);

      const globals = [
        `
            declare function log(...args: any[]): void;

            declare namespace console {
              function log(...args: any[]): void;
              function error(...args: any[]): void;
              function info(...args: any[]): void;
            }

            declare function say(text: string): Promise<any>;
            declare function tag(id: string, value: string|boolean|number): Promise<any>;
            declare function wait(delay: number): Promise<any>;

            declare namespace global {
              function get(key: string): any;
              function set(key: string, value: any): any;
              function keys(): any;
            }
          `,
      ].join('\n');

      const libUriGlobals = 'ts:filename/globals.d.ts';
      monaco.languages.typescript.javascriptDefaults.addExtraLib(globals, libUriGlobals);
    })
    .catch(console.error);
}
