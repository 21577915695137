import styled from '@emotion/styled';

import { su } from '../../../theme/functions/su';
import { theme } from '../../../theme/theme';

export function FlowCardBase() {
  throw new Error('Dont render this');
}

FlowCardBase.Details = styled.div`
  flex: 1 1 auto;
  padding-left: ${su(1)};
`;

FlowCardBase.Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${su(0.5)}; // only modern browser support (safari 14.1 +)
`;

FlowCardBase.Name = styled.div`
  color: ${theme.flowcard.name_color};
  font-size: ${theme.flowcard.name_font_size};
  line-height: ${theme.flowcard.name_line_height};
`;

FlowCardBase.Content = styled.div`
  font-size: ${theme.flowcard.content_font_size};
  line-height: ${theme.flowcard.content_line_height};
  padding-bottom: ${su(0.5)};
  white-space: pre-line;
`;

FlowCardBase.Text = styled.span`
  border: 1px solid transparent;
`;

FlowCardBase.NameLoading = styled.div`
  width: 70px;
  height: 12px;
  background-color: ${theme.color.mono_020};
  border-radius: 6px;
  margin-bottom: 4px;
`;

FlowCardBase.ContentLoading = styled.div`
  width: 140px;
  height: 20px;
  background-color: ${theme.color.mono_020};
  border-radius: ${theme.borderRadius.default};
`;

FlowCardBase.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--flow-card-icon-wrapper-background);
`;
