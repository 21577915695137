import styled from '@emotion/styled';

import { Device } from './Device';
import { Mood } from './Mood';
import { Flow } from './Flow';
import { User } from './User';
import { ZoneList } from './ZoneList';
import { ZoneListItem } from './ZoneListItem';
import { InsightList } from './InsightList';
import { InsightListItem } from './InsightListItem';
import { Heading3 } from './Heading3';
import { Shape } from './Shape';

export const ContentLoader = {
  Shape,
  Device,
  Mood,
  Flow,
  User,
  ZoneList,
  ZoneListItem,
  InsightList,
  InsightListItem,
  Heading3,
};

export const SVGRoot = styled.svg`
  display: block;
`;
