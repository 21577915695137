import * as Sentry from '@sentry/react';

import { CenteredMessage } from './common/CenteredMessage';

export function ErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary
      showDialog={false}
      onError={(error, componentStack, eventId) => {
        // Attempt to reload once after ChunkLoadError. These usually happen during deploys if
        // a cached resource references something that no longer exists. Since we preload all chunks
        // for every page we can assume a ChunkLoadError will only occur on switching pages. And
        // since switching pages does not have any state we should be able to hard reload without
        // breaking user state.
        if (
          error.name === 'ChunkLoadError' &&
          (window.sessionStorage.getItem('refreshed') ?? 'false') === 'false'
        ) {
          window.sessionStorage.setItem('refreshed', 'true');
          window.location.reload();
          return;
        }

        Sentry.showReportDialog({ eventId: eventId });
      }}
      fallback={({ error, componentStack, resetError, eventId }) => {
        return (
          <CenteredMessage title="An error occured">
            {error.name}: {error.message}
          </CenteredMessage>
        );
      }}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
