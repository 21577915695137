import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useTextField } from 'react-aria';

import { ToastManager } from '../../ToastManager';

import { stopPressPropagation } from '../../lib/dom/stopPressPropagation';

import { useScrollRef, scrollIntoView } from '../common/Scroll';
import { input } from '../../theme/Global';

export function NameTextField(props) {
  const inputRef = useRef();
  const scrollRef = useScrollRef();

  useEffect(() => {
    if (scrollRef?.current && inputRef.current) {
      scrollIntoView(scrollRef.current, inputRef.current);
    } else if (inputRef.current) {
      inputRef.current.scrollIntoView?.();
    }
  }, [scrollRef]);

  const input = useTextField(
    {
      ...props,
      onFocus(event) {
        // when used in a listBox prevent instant blur
        event.stopPropagation();
      },
      onBlur: (event) => {
        event.stopPropagation();
        const value = event.target.value;

        if (value.length > 0 && value !== props.defaultValue) {
          props.onSaveRequest?.(value);
        } else {
          props.onCancelRequest?.();
        }
      },
      onKeyUp: (event) => {
        switch (event.key) {
          case 'Enter':
            const value = event.target.value;

            if (value.length > 0) {
              props.onSaveRequest?.(value);
              props.onRequestSave?.(value);
              break;
            }

            ToastManager.add({ message: 'Minimum length is 1' });

            break;
          case 'Escape':
            props.onCancelRequest?.();
            props.onRequestCancel?.();
            break;
          default:
            break;
        }
      },
      onKeyDown(event) {
        // to prevent bubbling
        // console.log(event)
      },
    },
    inputRef
  );

  useEffect(() => {
    setTimeout(() => {
      if (props.selectDefaultValue) {
        inputRef.current?.select();
      } else {
        inputRef.current?.focus();
      }
    }, 0);
  }, [props.selectDefaultValue]);

  return (
    <NameTextField.Root
      {...mergeProps(stopPressPropagation, input.inputProps)}
      ref={inputRef}
      className={props.className}
    />
  );
}

NameTextField.Root = styled.input`
  ${input};
  display: block;
  appearance: none;
  padding: 0 5px;
  font-weight: normal;
  outline: 0;
  width: 100%;
`;
