import React, { useEffect, useRef } from 'react';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { useDrop } from 'react-dnd';
import { useConnectorRegister } from './useConnectorRegister';
import { useCurrentProps } from '../../../../hooks/useCurrentProps';

import { ConnectorBase } from './ConnectorBase';

import { connectorTypesMap } from './connectorTypes';

export function ConnectorIn(props) {
  const currentProps = useCurrentProps({
    onIsDraggingAndCanConnectChange: props.onIsDraggingAndCanConnectChange,
    onIsDragOverAndCanConnectChange: props.onIsDragOverAndCanConnectChange,
  });

  // Used in dropTargetElement
  // const connectorInRef = useRef();
  // ref={connectorInRef}

  // Same name as other connectors
  const circleRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [collectedProps, connectorInDropTarget] = useDrop(() => {
    const accept = [
      connectorTypesMap.Out,
      connectorTypesMap.Error,
      connectorTypesMap.True,
      connectorTypesMap.False,
    ];

    return {
      accept: accept,
      collect: (dropMonitor) => {
        return {
          isDragOver: dropMonitor.isOver({ shallow: true }),
          canDrop: dropMonitor.canDrop(),
          // This method seems to be called on any drag operation so it can receive
          // flowcard items even when they are not in accept. So check the accept array for
          // the current item type and dont return the item if we do not care about it.
          //
          // This seems like a bug because this component renders/collects for no reason now.
          item: accept.includes(dropMonitor.getItemType()) ? dropMonitor.getItem() : null,
        };
      },
      canDrop(dragItem, dropMonitor) {
        // not path.includes(me or my parents)

        if (dragItem.nodeId === props.nodeId || props.isDisabled) {
          return false;
        }

        function isDragItemChild(children) {
          if (children == null) {
            return false;
          }

          if (children.some((child) => child.id === dragItem.nodeId)) {
            return true;
          }

          for (const child of children) {
            const childChildren = props.nodeChildren[child.id];
            const result = isDragItemChild(childChildren);

            if (result === true) {
              return result;
            }
          }

          return false;
        }

        const children = props.nodeChildren[props.nodeId];
        const result = isDragItemChild(children);

        return result !== true;
      },
      hover(item, monitor) {},
      drop(dragItem, monitor) {
        if (monitor.didDrop()) return;

        const fromNodeId = dragItem.nodeId;

        AdvancedFlowViewStore.createConnection({
          fromNodeId: fromNodeId,
          fromConnectorType: monitor.getItemType(),
          toNodeId: props.nodeId,
          toConnectorType: connectorTypesMap.In,
        });
      },
    };
  }, [props.nodeId, props.nodeChildren, props.isDisabled]);

  // We attach the behavior to the whole card.
  connectorInDropTarget(props.cardRootRef);

  useEffect(() => {
    return function () {
      connectorInDropTarget(null);
    };
  }, [connectorInDropTarget]);

  useConnectorRegister({
    cardRootRef: props.cardRootRef,
    centerRef: circleRef,
    type: connectorTypesMap.In,
    nodeId: props.nodeId,
  });

  const isActive = props.activeConnectors.has(`${props.nodeId}::${connectorTypesMap.In}`);
  const isDraggingAndCanConnect = collectedProps.item != null && collectedProps.canDrop === true;
  const isDragOverAndCanConnect =
    collectedProps.item != null &&
    collectedProps.canDrop === true &&
    collectedProps.isDragOver === true;

  useEffect(() => {
    currentProps.onIsDraggingAndCanConnectChange(isDraggingAndCanConnect);
  }, [currentProps, isDraggingAndCanConnect]);

  useEffect(() => {
    currentProps.onIsDragOverAndCanConnectChange(isDragOverAndCanConnect);
  }, [currentProps, isDragOverAndCanConnect]);

  let connectorTypeColorOverride = null;

  const thisNodeParents = props.nodeParents[props.nodeId];

  // Get the first connected parent node so we can show a connected color for the circle.
  if (thisNodeParents != null) {
    const thisNodeFirstParent = thisNodeParents[0];
    connectorTypeColorOverride = thisNodeFirstParent.fromConnectorType;
  }

  return (
    <ConnectorBase
      circleRef={circleRef}
      style={props.style}
      connectorType={connectorTypesMap.In}
      connectorTypeColorOverride={connectorTypeColorOverride}
      executionState={props.executionState}
      isActive={isActive}
      isDraggingAndCanConnect={isDraggingAndCanConnect}
      isDragOverAndCanConnect={isDragOverAndCanConnect}
      isHovered={props.isHovered}
      isSelected={props.isSelected}
      isDisabled={props.isDisabled}
      isDisabledStyle={props.isDisabledStyle}
      isVisibleOverride={props.isVisibleOverride}
    />
  );
}
