import { theme } from '../../../../theme/theme';

export const connectorTypesMap = {
  In: 'input',
  Out: 'outputSuccess',
  True: 'outputTrue',
  False: 'outputFalse',
  Error: 'outputError',
};

export const connectorTypesList = [
  connectorTypesMap.In,
  connectorTypesMap.Out,
  connectorTypesMap.Error,
  connectorTypesMap.True,
  connectorTypesMap.False,
];

export const connectorOutputTypesList = [
  connectorTypesMap.Out,
  connectorTypesMap.Error,
  connectorTypesMap.True,
  connectorTypesMap.False,
];

export const connectorTypeColorMap = {
  [connectorTypesMap.Out]: {
    default: theme.flowcard.connector_out,
    hover: theme.flowcard.connector_out_hover,
    active: theme.flowcard.connector_out_active,
  },
  [connectorTypesMap.Error]: {
    default: theme.flowcard.connector_error,
    hover: theme.flowcard.connector_error_hover,
    active: theme.flowcard.connector_error_active,
  },
  [connectorTypesMap.True]: {
    default: theme.flowcard.connector_true,
    hover: theme.flowcard.connector_true_hover,
    active: theme.flowcard.connector_true_active,
  },
  [connectorTypesMap.False]: {
    default: theme.flowcard.connector_false,
    hover: theme.flowcard.connector_false_hover,
    active: theme.flowcard.connector_false_active,
  },
};
