import { useEffect } from 'react';
import { isWebKit } from '../lib/platform';
import { useCurrentProps } from './useCurrentProps';

export function usePointerPosition({ onChange }, ref) {
  const currentProps = useCurrentProps({ onChange, ref });

  useEffect(() => {
    const containerElement = currentProps.ref.current;

    function handlePointerMove(event) {
      const containerRect = containerElement.getBoundingClientRect();

      const x = event.clientX - containerRect.left;
      const y = event.clientY - containerRect.top;

      const clientX = event.clientX;
      const clientY = event.clientY;

      currentProps.onChange({ x, y, clientX, clientY });
    }

    if (isWebKit) {
      window.addEventListener('mousemove', handlePointerMove);
    } else {
      window.addEventListener('pointermove', handlePointerMove);
    }

    return function () {
      if (isWebKit) {
        window.removeEventListener('mousemove', handlePointerMove);
      } else {
        window.removeEventListener('pointermove', handlePointerMove);
      }
    };
  }, [currentProps]);
}
