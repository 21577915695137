import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';

import { Hint } from '../../../components/forms/Hint';

export function DeviceSettingsLabel(props) {
  return (
    <S.Root className={props.className}>
      <S.Header>
        <S.Label name={props.name} label={props.label} title={props.label}>
          {props.label}
        </S.Label>
        {props.hint && <Hint hint={props.hint} />}
      </S.Header>
      <S.Value tabIndex={0}>{props.defaultValue}</S.Value>
    </S.Root>
  );
}

function S() {}
DeviceSettingsLabel.S = S;

S.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
};

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

S.Header = styled.div`
  display: block;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 24px;
  margin-bottom: 5px;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;

S.Label = styled.label`
  display: inline;
  min-width: 0;
  margin-bottom: 0;
`;

S.Value = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 37px;
  padding: 8px;
  background: ${theme.color.mono_050};
  color: ${theme.color.text_light};
  border: 1px solid ${theme.input.border};
  border-radius: ${theme.input.border_radius};
  word-break: break-word;
`;
