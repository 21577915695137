/**
 *
 */
export class BaseStore {
  static get() {
    return this.store.getState();
  }

  static set(state) {
    this.store.setState(state);
  }
}

export class BaseStoreNonStatic {
  get() {
    return this.store.getState();
  }

  set(state) {
    this.store.setState(state);
  }
}
