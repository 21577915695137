import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { HomeyUtils } from '../../../store/HomeyUtils';
import { RouteManager } from '../../../RouteManager';
import { mapMessage } from '../../../ToastManager';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';
import { useCurrentProps } from '../../../hooks/useCurrentProps';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';
import { publicUrl } from '../../../theme/functions/publicUrl';

import { GradientButton } from '../../../components/buttons/GradientButton';
import { IconButton } from '../../../components/buttons/IconButton';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

export function UpdateView(props) {
  // eslint-disable-next-line no-unused-vars
  const { i18n } = useI18n();

  const instanceRef = useRef({
    timeoutId: null,
  });

  const currentProps = useCurrentProps({
    onContinueRequest: props.onContinueRequest,
  });

  const [isVisible, setIsVisible] = useState(true);

  const { api } = useApi();

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return function () {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const instance = instanceRef.current;

    function makeTimeout(timeout) {
      return setTimeout(() => {
        api.system
          .ping({ $timeout: 5000 })
          .then(async () => {
            const info = await api.system.getInfo();

            const hasAdvancedFlowVersion = HomeyUtils.getHasAdvancedFlowVersion(info.homeyVersion);

            if (hasAdvancedFlowVersion) {
              window.location.href = RouteManager.getPathForAdvancedFlow(`create?id=template`);
            }
          })
          .catch(console.error)
          .finally(() => {
            instance.timeoutId = makeTimeout(5000);
          });
      }, timeout);
    }

    if (api != null && isVisible === true) {
      instance.timeoutId = makeTimeout(1000);

      return function () {
        clearTimeout(instance.timeoutId);
      };
    }
  }, [currentProps, api, isVisible]);

  return (
    <UpdateView.Root>
      <UpdateView.Header>
        {/*<BackButton onPress={props.onBackRequest} />*/}

        <IconButton
          url={iconCloseThin}
          color={theme.icon.color_light}
          hoverColor={theme.icon.color_light_hover}
          size={theme.icon.size_small}
          onPress={props.onCloseRequest}
        />
      </UpdateView.Header>

      <UpdateView.Content>
        <UpdateView.Image src={publicUrl('/img/flows/mobile.png')} alt="Mobile update screen" />

        <UpdateView.Column>
          <UpdateView.RequiredBadge>
            Homey v8.0.0 {i18n.messageFormatter('common.required')}
          </UpdateView.RequiredBadge>
          <h1>{i18n.messageFormatter('common.update')} Homey Pro</h1>
          <p>{mapMessage(i18n.messageFormatter('flow.updateHomeyProParagraph'))}</p>
        </UpdateView.Column>
      </UpdateView.Content>
    </UpdateView.Root>
  );
}

UpdateView.Root = styled.div`
  position: relative;
  width: 740px;
  animation: ${animationFade.in} ${theme.duration.normal} ease-out forwards;
`;

UpdateView.Header = styled.header`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 20px;
`;

UpdateView.Content = styled.div`
  display: flex;
  padding: ${su(0, 4, 2)};

  ${GradientButton.S.Root} {
    width: 100%;
  }
`;

UpdateView.Column = styled.aside`
  flex: 1 1 auto;
  align-self: center;
  padding-left: 40px;
`;

UpdateView.Image = styled.img`
  flex: 0 0 auto;
  width: 220px;
`;

UpdateView.RequiredBadge = styled.div`
  display: inline-block;
  padding: 2px 8px;
  margin-bottom: 40px;
  background-color: ${theme.color.orange};
  border-radius: 12px;
  color: ${theme.color.text_white};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
`;
