import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { EventEmitter } from 'events';

import { client } from '../lib/api';

import { HomeyError } from '../lib/InternalError';
import { BaseStore } from './BaseStore';

export class AuthStore extends BaseStore {
  static emitter = new EventEmitter();

  static createInitialState() {
    return {
      user: null,
      homeys: null,
      current: null,
      experimentalFlag: false,
      employeeFlag: false,
      loading: true,
      error: null,
      resolved: false,
    };
  }

  static store = create(
    devtools(
      subscribeWithSelector((set, get, api) => ({
        ...this.createInitialState(),
      })),
      { name: 'auth' }
    )
  );

  static async getAuthenticatedUserState() {
    const user = await client.getAuthenticatedUser();
    const homeys = user.homeys;

    const experimentalFlag = this.getExperimentalFlag(user);
    const employeeFlag = this.getEmployeeFlag(user);

    return {
      user,
      homeys,
      experimentalFlag,
      employeeFlag,
    };
  }

  static async resolve() {
    const isLoggedIn = await client.isLoggedIn();

    // If not logged in resolve so that redirect logic to login page can run.
    if (isLoggedIn !== true) {
      this.set({
        resolved: true,
        loading: false,
      });
      return;
    }

    // unstable_batchedUpdates(() => {
    //   this.set({
    //     loading: true,
    //   });
    // });

    const init = async () => {
      const authenticatedUserState = await this.getAuthenticatedUserState();

      // Flush the set user immediately to the AuthStateManager.
      this.emitter.emit('user', { user: authenticatedUserState.user });

      unstable_batchedUpdates(() => {
        this.set({
          ...authenticatedUserState,
          resolved: true,
          loading: false,
        });
      });
    };

    try {
      await init();
    } catch (error) {
      switch (error.name) {
        case 'invalid_token':
          await client.refreshTokens();
          break;
        default:
      }

      await init();
    }
  }

  static async authenticateWithAuthorizationCode(code) {
    try {
      await client.authenticateWithAuthorizationCode(code);
      const authenticatedUserState = await this.getAuthenticatedUserState();

      // Flush the set user immediately to the AuthStateManager.
      this.emitter.emit('user', { user: authenticatedUserState.user });

      unstable_batchedUpdates(() => {
        this.set({ ...authenticatedUserState, loading: false });
      });

      return authenticatedUserState.user;
    } catch (error) {
      this.set({
        user: null,
        homeys: null,
        experimentalFlag: false,
        employeeFlag: false,
        error,
      });
    }
  }

  static async logout() {
    this.emitter.emit('user', { user: null });

    await client.logout();

    localStorage.removeItem('current_homey_id');

    // https://github.com/pmndrs/zustand/issues/302
    unstable_batchedUpdates(() => {
      this.set({ ...this.createInitialState(), resolved: true });
    });
  }

  static getCurrentHomey(user) {
    const id = localStorage.getItem('current_homey_id');
    let homey = null;

    if (id != null) {
      homey = user.homeys?.find((homey) => {
        return homey.id === id;
      });
    } else {
      homey = user.homeys?.[0];
    }

    if (homey == null) {
      localStorage.removeItem('current_homey_id');
      throw new HomeyError.NotFound();
    }

    return homey;
  }

  static getCurrentHomeyById(user, id) {
    localStorage.setItem('current_homey_id', id);

    const homey = user.homeys?.find((homey) => {
      return homey.id === id;
    });

    if (homey == null) {
      localStorage.removeItem('current_homey_id');
      throw new HomeyError.NotFound();
    }

    return homey;
  }

  static getLoginUrl(location) {
    let state = location.pathname;
    if (location.search) {
      state += '?' + location.search;
    }
    if (location.hash) {
      state += '#' + location.hash;
    }
    return client.getLoginUrl({
      state,
      scopes: ['homey', 'account', 'resource'],
    });
  }

  static getExperimentalFlag(user) {
    const isTesting = window.location.hostname === 'my.homey.wtf';
    const isPlayground = window.location.hostname === 'my.homey.fun';

    if (import.meta.env.MODE === 'development' || isTesting || isPlayground) {
      return true;
    }

    return user != null && (user.roleIds?.includes('aatp') || user.roleIds?.includes('employee'));
  }

  static getEmployeeFlag(user) {
    const isTesting = window.location.hostname === 'my.homey.wtf';
    const isPlayground = window.location.hostname === 'my.homey.fun';

    if (import.meta.env.MODE === 'development' || isTesting || isPlayground) {
      return true;
    }

    return user != null && user.roleIds?.includes('employee');
  }

  static assignLicences({ licenses }) {
    this.get().current.licenses = licenses;
  }
}

AuthStore.resolve().catch(console.error);

export function useAuth() {
  return AuthStore.store((state) => state);
}
