import { useRouteMatch } from 'react-router-dom';

import { RouteManager } from '../../RouteManager';

export function useScriptRouteId() {
  const scriptRoute = RouteManager.matchPath.script;
  const scriptRouteMatch = useRouteMatch(scriptRoute);
  const scriptRouteId = scriptRouteMatch?.params?.scriptId
    ? decodeURIComponent(scriptRouteMatch.params.scriptId)
    : null;

  return { scriptRouteId };
}
