import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { ToastManager } from '../../../ToastManager';
import { AppUtils } from '../../../store/apps/AppUtils';

import { useApi, useBaseUrl } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { publicUrl } from '../../../theme/functions/publicUrl';
import { su } from '../../../theme/functions/su';

import { Tooltip } from '../../../components/common/tooltip/Tooltip';
import { MatchPath } from '../../../components/routing/MatchPath';
import { HomeyIcon } from '../../../components/common/HomeyIcon';
import { Icon } from '../../../components/common/Icon';
import { Switch } from '../../../components/forms/Switch';
import { FlatButton } from '../../../components/buttons/FlatButton';

import { AppSettingsDialogConfigureApp } from './AppSettingsDialogConfigureApp';
import { AppSettingsStore } from './AppSettingsStore';

import { iconSliderHorizontal } from '../../../theme/icons/interface/slider-horizontal/slider-horizontal';
import { iconWarning } from '../../../theme/icons/interface/warning/warning';

export function AppSettingsInfo(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  // Matches the parent route.
  const routeMatch = useRouteMatch();

  const appBackgroundColor = AppUtils.getBackgroundColor(props.app);

  const [isEnabled, setIsEnabled] = useState(props.app.enabled);
  const [isEnabledLocked, setIsEnabledLocked] = useState(false);
  const [isAutoupdate, setIsAutoupdate] = useState(props.app.autoupdate);
  const [isAutoupdateLocked, setIsAutoupdateLocked] = useState(false);

  const [isRestarting] = AppSettingsStore.useIsRestarting();

  useEffect(() => {
    setIsEnabled(props.app.enabled);
  }, [props.app.enabled]);

  useEffect(() => {
    setIsAutoupdate(props.app.autoupdate);
  }, [props.app.autoupdate]);

  const { baseUrl } = useBaseUrl();

  function toAbsolutePathWithBaseUrl(path = '') {
    // Get the current URL and split it into parts.
    if (path.startsWith('/')) return new URL(baseUrl + '/app/' + props.app.id + path).toString();

    return new URL(path, baseUrl + '/app/' + props.app.id + '/').toString();
  }

  let src =
    props.app.origin !== 'appstore'
      ? // Dev
        toAbsolutePathWithBaseUrl(props.app.images?.large)
      : // Core 2023
      props.app.images?.large?.startsWith('https')
      ? props.app.images.large
      : // Pro <= 2019
        toAbsolutePathWithBaseUrl(props.app.images?.large);

  const isConfigureDisabled = (() => {
    switch (true) {
      case isRestarting === true:
      case props.app.enabled !== true:
      case props.app.state === 'stopping':
      case props.app.state === 'starting':
      case props.app.state === 'crashed':
      case props.app.state === 'stopped':
        return true;
      case props.app.state === 'running':
        return false;
      default:
        return true;
    }
  })();

  return (
    <S.Root>
      {/* TODO Placeholder/loading */}
      <S.Image
        src={src}
        onError={(event) => {
          const placeholder = publicUrl('/img/apps/placeholder.png');
          if (event.currentTarget.src !== placeholder) {
            event.currentTarget.src = placeholder;
          }
        }}
        data-is-app-enabled={isEnabled}
      />

      <S.IconWrapperClip>
        <S.IconWrapper data-is-app-enabled={isEnabled} backgroundColor={appBackgroundColor}>
          <HomeyIcon size="32px" iconObj={props.app.iconObj} color={theme.color.white} />
        </S.IconWrapper>
      </S.IconWrapperClip>
      <S.AppName>{props.app.name}</S.AppName>

      <S.LabelContainer>
        <S.Label tabIndex={-1}>v{props.app.version}</S.Label>

        {props.app.state === 'crashed' && (
          <Tooltip
            placement="bottom"
            offset={10}
            delay={0}
            anchorPointer={true}
            renderTrigger={(triggerRef, triggerProps) => {
              return (
                <S.Label {...triggerProps} ref={triggerRef} data-style-color="red">
                  <Icon url={iconWarning} color={theme.color.white} size={theme.icon.size_tiny} />
                  {props.app.state.charAt(0).toUpperCase() + props.app.state.slice(1)}
                </S.Label>
              );
            }}
          >
            <S.TooltipContent>
              {props.app.crashedMessage ?? 'No crashed message available.'}
            </S.TooltipContent>
          </Tooltip>
        )}

        {(props.app.channel === 'test' || props.app.channel === 'beta') && (
          <S.Label data-style-color="orange">Test</S.Label>
        )}

        {props.app.origin !== 'appstore' && (
          <S.Label tabIndex={-1} data-style-color="purple">
            Development
          </S.Label>
        )}
      </S.LabelContainer>

      <S.SwitchesContainer>
        {api?.isCloud !== true && (
          <Switch
            label={i18n.messageFormatter('common.enabled')}
            name="enabled"
            value={isEnabled}
            onChange={(value) => {
              if (isEnabledLocked) {
                ToastManager.add({ message: 'Pending Request' });
                return;
              }

              setIsEnabled(value);
              setIsEnabledLocked(true);

              switch (value) {
                case true:
                  api.apps
                    .enableApp({ id: props.app.id })
                    .then(() => {})
                    .catch((error) => {
                      setIsEnabled(props.app.enabled);
                      ToastManager.handleError(error);
                    })
                    .finally(() => {
                      setIsEnabledLocked(false);
                    });
                  break;
                case false:
                  api.apps
                    .disableApp({ id: props.app.id })
                    .then(() => {})
                    .catch((error) => {
                      setIsEnabled(props.app.enabled);
                      ToastManager.handleError(error);
                    })
                    .finally(() => {
                      setIsEnabledLocked(false);
                    });
                  break;
                default:
                  break;
              }
            }}
          />
        )}

        {api?.isCloud !== true && props.app.origin === 'appstore' && (
          <Switch
            label={i18n.messageFormatter('settings.app.autoupdate')}
            name="autoupdate"
            value={isAutoupdate}
            onChange={(value) => {
              if (isAutoupdateLocked) {
                ToastManager.add({ message: 'Pending Request' });
                return;
              }

              setIsAutoupdate(value);
              setIsAutoupdateLocked(true);

              api.apps
                .updateApp({
                  id: props.app.id,
                  app: {
                    autoupdate: value,
                  },
                })
                .then(() => {})
                .catch((error) => {
                  setIsAutoupdate(props.app.autoupdate);
                  ToastManager.handleError(error);
                })
                .finally(() => {
                  setIsAutoupdateLocked(false);
                });
            }}
          />
        )}
      </S.SwitchesContainer>

      {props.app.settings === true && (
        <S.ConfigureContainer>
          <FlatButton
            isDisabled={isConfigureDisabled}
            styleWidth="full"
            styleColor="blue"
            onPress={() => {
              AppSettingsDialogConfigureApp.route.open({ context: { appId: props.app.id } });
            }}
          >
            <FlatButton.Icon url={iconSliderHorizontal} size={theme.icon.size_small} />
            <FlatButton.Text>{i18n.messageFormatter('settings.app.configure')}</FlatButton.Text>
          </FlatButton>
        </S.ConfigureContainer>
      )}

      <MatchPath
        path={`${routeMatch.path}/settings`}
        children={({ match }) => {
          if (match) {
            return (
              <Redirect
                to={{
                  pathname: `${routeMatch.url}/settings`,
                  state: AppSettingsDialogConfigureApp.route.createState({
                    context: {
                      appId: props.app.id,
                    },
                  }),
                }}
              />
            );
          }

          return null;
        }}
      />
    </S.Root>
  );
}

function S() {}
AppSettingsInfo.S = S;

S.Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
`;

S.SwitchesContainer = styled.div`
  padding: 20px;
  width: 100%;

  ${Switch.Root} {
    height: 40px;
  }

  ${Switch.Label} {
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.regular};
  }
`;

S.ConfigureContainer = styled.div`
  width: 100%;
  padding: 0 20px 20px;
`;

S.AppName = styled.div`
  padding-top: 15px;
  font-size: ${theme.fontSize.heading3};
  font-weight: ${theme.fontWeight.medium};
  padding-bottom: 8px;
`;

S.IconWrapperClip = styled.div`
  background-color: ${theme.color.component};
  padding: 5px;
  border-radius: 50%;
  margin-top: -40px;
  z-index: 1;
`;

S.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  transition: ${theme.duration.slow} ${theme.curve.easeInOut};
  transition-property: filter;

  &:not([data-is-app-enabled='true']) {
    filter: grayscale(1);
  }
`;

S.Image = styled.img`
  width: 100%;
  height: 308px;
  border-radius: ${theme.borderRadius.default} ${theme.borderRadius.default} 0 0;
  transition: ${theme.duration.slow} ${theme.curve.easeInOut};
  transition-property: filter;

  &:not([data-is-app-enabled='true']) {
    filter: grayscale(1);
  }
`;

S.LabelContainer = styled.div`
  display: flex;
  gap: 4px;
`;

S.Label = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background: linear-gradient(45deg, rgb(63, 63, 63), rgb(80, 80, 80));
  color: ${theme.color.white};
  border-radius: ${theme.borderRadius.small};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  cursor: default;

  ${Icon.S.Root} {
    margin-right: 4px;
  }

  &[data-style-color='purple'] {
    background: linear-gradient(45deg, rgb(98, 54, 255), rgb(127, 61, 255));
  }

  &[data-style-color='orange'] {
    background: linear-gradient(45deg, rgb(251, 105, 0), rgb(253, 159, 16));
  }

  &[data-style-color='red'] {
    background: linear-gradient(45deg, rgb(199, 29, 34), rgb(255, 41, 41));
  }
`;

S.TooltipContent = styled.div`
  width: 300px;
  padding: ${su(2)};
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
  word-break: break-word;
  white-space: pre-wrap;
`;
