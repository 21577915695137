import React from 'react';
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';

import { theme } from '../../../theme/theme';

export function RouterLink(props) {
  return (
    <RouterLink.Root className={props.className} style={props.style} to={props.to}>
      {props.children}
    </RouterLink.Root>
  );
}

RouterLink.Root = styled(Link)`
  color: ${theme.color.text_link};
  text-decoration: none;
`;
