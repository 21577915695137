import React from 'react';

import { getSymbolForKey } from '../../lib/platform';

import { ToastManager } from '../../ToastManager';
import { UserMeStore } from '../../store/user-me/UserMeStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useFlow } from '../../store/flow/useFlows';
import { useUserMe } from '../../store/user-me/useUserMe';

import { theme } from '../../theme/theme';

import { ContextMenuContent } from '../common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../common/context-menu/DeveloperMenu';

import { iconMouseLeftClick } from '../../theme/icons/interface/mouse-left-click';
import { iconNotFavorite } from '../../theme/icons/interface/heart-outline';
import { iconFavorite } from '../../theme/icons/interface/heart-fill';

export function FlowContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { flow } = useFlow({ flowId: props.flowId });
  const userMe = useUserMe();

  const isFavoriteFlow = userMe.favoriteFlows?.includes(props.flowId) ?? false;

  async function onAction(key) {
    switch (key) {
      case 'trigger':
        props.onTriggerRequest?.();
        break;
      case 'favorite':
        const favoriteName = `**${flow.name}**`;

        try {
          if (isFavoriteFlow) {
            await UserMeStore.removeFavoriteFlow({ id: props.flowId });
            ToastManager.add({
              icon: iconNotFavorite,
              iconColor: theme.icon.color_light,
              message: i18n.messageFormatter(`flow.removeFavorite`, {
                name: favoriteName,
              }),
            });
            return;
          }

          await UserMeStore.addFavoriteFlow({ id: props.flowId });
          ToastManager.add({
            icon: iconFavorite,
            iconColor: theme.color.special_favorite,
            message: i18n.messageFormatter(`flow.addFavorite`, {
              name: favoriteName,
            }),
          });
        } catch (error) {
          ToastManager.handleError(error);
        }
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`flow.start`)}
        isDisabled={flow?.triggerable !== true}
        hint={`${getSymbolForKey('alt')} +`}
        icon={iconMouseLeftClick}
        onPress={() => {
          onAction('trigger');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter(`flow.favorite`)}
        icon={isFavoriteFlow ? iconFavorite : iconNotFavorite}
        onPress={() => {
          onAction('favorite');
        }}
      />

      <DeveloperMenu onIdRequest={() => flow?.id} onResourceRequest={() => flow} />
    </ContextMenuContent.List>
  );
}
