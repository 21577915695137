import React from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing } from 'react-aria';

import { useOptionContext } from '../../../components/list-box/ListBox';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { StaticFlowCard } from '../flow-card/StaticFlowCard';

export function ColumnItemCard(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();
  const focusRing = useFocusRing();

  return (
    <ColumnItemCard.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={props.style}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-focus-visible={focusRing.isFocusVisible}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
    >
      <StaticFlowCard
        card={value.context.card}
        cardWidth="100%"
        isFocused={option.isFocused}
        isFocusVisible={focusRing.isFocusVisible}
      />
    </ColumnItemCard.Root>
  );
}

ColumnItemCard.Root = styled.li`
  outline: 0;
  transition: transform ${theme.duration.fast} ${theme.curve.fastIn};

  &:not(:last-of-type) {
    padding-bottom: ${su(1)};
  }

  &[data-is-pressed='true'] {
    transform: scale(0.97);
  }
`;
