import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';

export const Image = forwardRef(function ({ url, size, ...rest }, forwardedRef) {
  return (
    <Image.Root
      {...rest}
      ref={forwardedRef}
      style={{
        '--url': `url(${url})`,
        '--size': size ?? theme.icon.size_default,
      }}
    />
  );
});

Image.propTypes = {
  url: PropTypes.string,
  size: PropTypes.string,
};

Image.Root = styled.div`
  display: inline-block;
  flex: 0 0 var(--size);
  width: var(--size);
  height: var(--size);
  border: 0;
  outline: 0;
  background-image: var(--url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
`;
