import { useRef, useImperativeHandle } from 'react';
import styled from '@emotion/styled';
import { useComboBox, useFilter } from 'react-aria';
import { useComboBoxState, Item } from 'react-stately';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { Icon } from '../../../components/common/Icon';
import { ListBox } from '../../../components/list-box/ListBox';
import { LocationSettingsComboBoxListItem } from './LocationSettingsComboBoxListItem';

import { iconLocation } from '../../../theme/icons/system/location/location';

export function LocationSettingsComboBox(props) {
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({ ...props, defaultFilter: contains });

  useImperativeHandle(props.stateRef, () => state, [state]);

  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const overlayRef = useRef(null);

  const { inputProps, listBoxProps } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef: null,
      listBoxRef,
      popoverRef: overlayRef,
    },
    state
  );

  return (
    <S.Root>
      {state.isOpen === true && (
        <ListBox
          {...listBoxProps}
          listBoxRef={mergeRefs([listBoxRef, overlayRef])}
          state={state}
          renderItem={() => {
            return <LocationSettingsComboBoxListItem />;
          }}
        />
      )}

      <S.InputContainer>
        <Icon url={iconLocation} size={theme.icon.size_small} color={theme.color.icon_light} />
        <S.Input {...inputProps} ref={inputRef} placeholder={props.placeholder} />
      </S.InputContainer>
    </S.Root>
  );
}

LocationSettingsComboBox.Item = Item;

function S() {}
LocationSettingsComboBox.S = S;

S.Root = styled.div``;

S.InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

S.Input = styled.input`
  padding-left: 10px;
  height: 40px;
  outline: 0;
  width: 100%;
  outline: none;
  border: none;
  border-radius: ${theme.borderRadius.default};
  background: none;
  color: ${theme.color.text};

  &::placeholder {
    color: ${theme.color.text_light};
  }
`;
