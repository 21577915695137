
export const zIndex = {
  // Global Middle Layers: increment with 100
  content: 100,
  nav: 200,
  advanced_flow_view: 300,
  header: 400,
  beta: 500,
  overlay: 600,
  drag_layer: 700,
  dialog: 800,
  toasts: 1000,
  tooltip: 1100,

  // Global Higher Layers: increment with 10.000
  disabledLayer: 10000,
  cursor: 20000,
} as const;


