import styled from '@emotion/styled';
import { mergeProps, useFocusRing } from 'react-aria';

import { useOptionContext } from '../../../components/list-box/ListBox';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../../components/common/Icon';

import { iconLocation } from '../../../theme/icons/system/location/location';

export function LocationSettingsComboBoxListItem(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  return (
    <S.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={props.style}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <S.Icon url={iconLocation} size={theme.icon.size_small} />
      <S.Name>{item.textValue}</S.Name>
    </S.Root>
  );
}

function S() {}
LocationSettingsComboBoxListItem.S = S;

S.Name = styled.div`
  flex: 1 1 auto;
  padding-left: ${su(1)};
  text-overflow: ellipsis;
  word-break: break-all;
`;

S.Icon = styled(Icon)`
  flex: 0 0 auto;
`;

S.Root = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  outline: 0;
  cursor: pointer;
  padding: 10px;

  &[data-is-selected='true'] {
    ${S.Name} {
      color: ${theme.color.highlight};
      font-weight: ${theme.fontWeight.medium};
    }

    ${S.Icon} {
      background-color: ${theme.color.highlight};
    }
  }

  &[data-is-disabled='true'] {
  }

  &[data-is-focused='true'] {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-pressed='true'] {
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }

  &:hover {
    background-color: ${theme.color.background_hover};

    &:not([data-is-selected='true']) {
      ${S.Name} {
        font-weight: ${theme.fontWeight.medium};
      }

      ${S.Icon} {
        background-color: ${theme.color.icon_dark};
      }
    }
  }
`;
