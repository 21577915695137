import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { mergeProps, useHover } from 'react-aria';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { TokenContextProvider } from './TokenContextProvider';

import { mergeRefs } from '../../../../lib/mergeRefs';

import { useExecutionState } from './useExecutionState';
import { useRecalculateConnection } from './useRecalculateConnection';
import { useDraggableButton } from './useDraggableButton';

import { theme } from '../../../../theme/theme';

import { ContextMenu } from '../../../../components/common/context-menu/ContextMenu';

import { ArgumentDuration } from '../../flow-arguments/ArgumentDuration';
import { DelayCardContextMenuContent } from './DelayCardContextMenuContent';
import { ConnectorIn } from '../connectors/ConnectorIn';
import { ConnectorOut } from '../connectors/ConnectorOut';
import { AdvancedFlowCard } from './AdvancedFlowCard';

import { executionStateType } from './executionStateType';

export function DelayCard(props) {
  const rootRef = useRef();
  const buttonRef = useRef();

  const { executionState } = useExecutionState({
    rootRef,
    nodeId: props.nodeId,
    cardData: props.data,
  });
  useRecalculateConnection({ rootRef, nodeId: props.nodeId });

  // copy this section from AdvancedFlowCard for now
  const isUnreachableNode = executionState.type === executionStateType.unreachable;
  const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  // eslint-disable-next-line no-unused-vars
  const isTestingRoot = isTesting && props.testNodeId === props.nodeId;
  const isSaving = props.conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isDisabled = isTesting || isUnreachableNode || isSaving || props.isInteractionDisabled;
  const isDisabledStyle = isUnreachableNode ? 'unreachable' : 'readonly';
  // copy this section end

  const isSelected = AdvancedFlowViewStore.useIsSelected({ id: props.nodeId });

  const hover = useHover({});
  const button = useDraggableButton(
    {
      elementType: 'div',
      isDisabled: isDisabled,
      onPress: props.onPress,
    },
    buttonRef
  );

  const [isDraggingAndCanConnect, setIsDraggingAndCanConnect] = useState(null);
  const [isDragOverAndCanConnect, setIsDragOverAndCanConnect] = useState(null);

  function handleIsDraggingAndCanConnectChange(isDraggingAndCanConnect) {
    // Dont update the state if it's null and it's called with false. This ensures the initial render
    // does not cause an animation effect.
    setIsDraggingAndCanConnect((prevState) => {
      if (prevState == null && isDraggingAndCanConnect === false) {
        return prevState;
      }

      return isDraggingAndCanConnect;
    });
  }

  function handleIsDragOverAndCanConnectChange(isDragOverAndCanConnect) {
    // Dont update the state if it's null and it's called with false. This ensures the initial render
    // does not cause an animation effect.
    setIsDragOverAndCanConnect((prevState) => {
      if (prevState == null && isDragOverAndCanConnect === false) {
        return prevState;
      }

      return isDragOverAndCanConnect;
    });
  }

  return (
    <TokenContextProvider nodeId={props.nodeId} cardData={props.data}>
      <ContextMenu
        content={
          <DelayCardContextMenuContent
            nodeId={props.nodeId}
            card={props.card}
            data={props.data}
            onStartRequest={props.onStartRequest}
            onCopyRequest={props.onCopyRequest}
            onDuplicateRequest={props.onDuplicateRequest}
            onDeleteRequest={props.onDeleteRequest}
          />
        }
      >
        {({ isOpen, onContextMenu }) => {
          return (
            <DelayCard.Root
              {...mergeProps(button.buttonProps, hover.hoverProps)}
              ref={mergeRefs([props.forwardedRef, rootRef, buttonRef])}
              className={props.className}
              nodeId={props.nodeId}
              data-is-selected={isSelected}
              data-is-dragging-and-can-connect={isDraggingAndCanConnect}
              data-is-dragover-and-can-connect={isDragOverAndCanConnect}
              data-is-unreachable-node={isUnreachableNode}
              data-is-testing={isTesting}
              data-style-type={props.data.type}
              data-execution-state-type={executionState.type}
              onContextMenu={onContextMenu}
            >
              <DelayCard.Inner>
                <ConnectorIn
                  cardRootRef={rootRef}
                  style={{
                    position: 'absolute',
                    right: 'calc(100% - 1px)',
                    top: '50%',
                    transform: 'translate(0, -50%) rotate(90deg)',
                  }}
                  nodeId={props.nodeId}
                  nodeChildren={props.nodeChildren}
                  nodeParents={props.nodeParents}
                  activeConnectors={props.activeConnectors}
                  executionState={executionState}
                  isSelected={isSelected}
                  isHovered={hover.isHovered}
                  isVisibleOverride={true}
                  isUnreachableNode={isUnreachableNode}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                  onIsDraggingAndCanConnectChange={handleIsDraggingAndCanConnectChange}
                  onIsDragOverAndCanConnectChange={handleIsDragOverAndCanConnectChange}
                />

                <ConnectorOut
                  cardRootRef={rootRef}
                  style={{
                    position: 'absolute',
                    left: 'calc(100% - 1px)',
                    top: '50%',
                    transform: 'translate(0, -50%) rotate(-90deg)',
                  }}
                  nodeId={props.nodeId}
                  activeConnectors={props.activeConnectors}
                  executionState={executionState}
                  isSelected={isSelected}
                  isHovered={hover.isHovered}
                  isVisibleOverride={true}
                  isDragOverAndCanConnect={isDragOverAndCanConnect}
                  isUnreachableNode={isUnreachableNode}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                />

                <DelayCard.ClockRoot
                  width="24px"
                  height="24px"
                  style={{
                    marginRight: 10,
                    marginTop: -2,
                    marginBottom: -2,
                    '--delay-card-duration':
                      executionState.delay != null ? `${executionState.delay}ms` : null,
                  }}
                  viewBox="0 0 24 24"
                >
                  <DelayCard.ClockCircleBase pathLength={1} r={11} cx={12} cy={12} />
                  <DelayCard.ClockCircleColor pathLength={1} r={11} cx={12} cy={12} />
                  <DelayCard.ClockHands transform="translate(12,12)">
                    <DelayCard.ClockBigHand x1={0} y1={0} x2={0} y2={-7} />
                    <DelayCard.ClockSmallHand x1={0} y1={0} x2={0} y2={-5} />
                  </DelayCard.ClockHands>
                  <DelayCard.ClockCheckCircle pathLength={1} r={11} cx={12} cy={12} />
                  <DelayCard.ClockCheck d="M8,13 L11,16 L16,8" pathLength={1} />
                </DelayCard.ClockRoot>

                <ArgumentDuration
                  cardContainerRef={null}
                  card={null}
                  data={null}
                  prefix={null}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                  onGetStoreValue={() => {
                    return props.data.args?.delay;
                  }}
                  onUpdateStoreValue={(value) => {
                    // Set default one second for delay card on empty
                    if (value == null) {
                      value = {
                        number: '1',
                        multiplier: 1,
                      };
                    }

                    AdvancedFlowViewStore.updateCardArg({
                      nodeId: props.nodeId,
                      argumentKey: 'delay',
                      value: value,
                    });
                  }}
                />

                {/*<ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />*/}

                {/*<CardProgress executionState={executionState} isSelected={props.isSelected} />*/}
              </DelayCard.Inner>
            </DelayCard.Root>
          );
        }}
      </ContextMenu>
    </TokenContextProvider>
  );
}

DelayCard.Root = AdvancedFlowCard.Root;

DelayCard.Inner = AdvancedFlowCard.Inner;

DelayCard.ClockCircleBase = styled.circle`
  fill: transparent;
  stroke-width: 2;
  stroke: ${theme.color.line};
`;

DelayCard.ClockCircleColor = styled.circle`
  fill: transparent;
  stroke: ${theme.color.blue};
  stroke-width: 2;
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: center;
`;

DelayCard.ClockHands = styled.g`
  stroke: ${theme.color.blue};
  stroke-width: 2;
  stroke-linecap: round;
  transition: ${theme.duration.fast} ${theme.curve.easeInOut};
  transition-property: stroke, opacity;
`;

DelayCard.ClockBigHand = styled.line`
  transform: rotate(0deg);
`;

DelayCard.ClockSmallHand = styled.line`
  transform: rotate(120deg);
`;

DelayCard.ClockCheckCircle = styled.circle`
  fill: ${theme.color.blue};
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: ${theme.duration.normal} ${theme.curve.easeInOut};
  transition-property: opacity, transform;
`;

DelayCard.ClockCheck = styled.path`
  fill: transparent;
  stroke: ${theme.color.white};
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 0;
  transition: ${theme.duration.normal} ${theme.curve.easeInOut} ${theme.duration.fast},
    ${theme.duration.fast} ${theme.curve.easeInOut} ${theme.duration.fast};
  transition-property: stroke-dashoffset, opacity;
`;

DelayCard.ClockRoot = styled.svg`
  ${DelayCard.Root}[data-is-unreachable-node='true'] & {
    ${DelayCard.ClockCircleColor} {
      stroke: ${theme.flowcard.disabled_background_color};
    }

    ${DelayCard.ClockHands} {
      stroke: ${theme.flowcard.disabled_background_color};
    }
  }

  ${DelayCard.Root}:not([data-execution-state-type='${executionStateType.idle}'],[data-is-unreachable-node='true']) & {
    ${DelayCard.ClockBigHand} {
      animation: infinite 5s linear forwards;
      animation-name: ${keyframes`
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      `};
      animation-play-state: paused;
    }

    ${DelayCard.ClockSmallHand} {
      animation: infinite 60s linear forwards;
      animation-name: ${keyframes`
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      `};
      animation-play-state: paused;
    }
  }

  ${DelayCard.Root}[data-execution-state-type='${executionStateType.prestart}'] & {
    ${DelayCard.ClockCircleColor} {
      stroke-dashoffset: 1;
    }

    ${DelayCard.ClockHands} {
      stroke: ${theme.color.line};
    }

    ${DelayCard.ClockSmallHand} {
      transform: rotate(0deg);
    }
  }

  ${DelayCard.Root}[data-execution-state-type='${executionStateType.start}'] & {
    ${DelayCard.ClockCircleColor} {
      animation: var(--delay-card-duration, 0) ${theme.curve.linear} forwards 1;
      animation-name: ${keyframes`
        from {
          stroke-dashoffset: 1;
        }

        to {
          stroke-dashoffset: 0;
        }
      `};
    }

    ${DelayCard.ClockHands} {
      stroke: ${theme.color.blue};
    }

    ${DelayCard.ClockBigHand} {
      animation-play-state: running;
    }

    ${DelayCard.ClockSmallHand} {
      animation-play-state: running;
    }
  }

  ${DelayCard.Root}[data-execution-state-type='${executionStateType.start}'][data-is-testing='false'] & {
    ${DelayCard.ClockCircleColor} {
      animation-play-state: paused;
    }

    ${DelayCard.ClockBigHand} {
      animation-play-state: paused;
    }

    ${DelayCard.ClockSmallHand} {
      animation-play-state: paused;
    }
  }

  ${DelayCard.Root}[data-execution-state-type='${executionStateType.end}'] & {
    ${DelayCard.ClockCircleColor} {
      stroke-dashoffset: 0;
    }

    ${DelayCard.ClockHands} {
      opacity: 0;
    }

    ${DelayCard.ClockCheckCircle} {
      transform: scale(1);
      opacity: 1;
    }

    ${DelayCard.ClockCheck} {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`;
