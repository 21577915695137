import { theme } from '../../theme/theme';

import { SVGRoot } from './ContentLoader';

export function Shape(props) {
  const width = props.width;
  const height = props.height;
  const id = `shape-${width}-${height}`;

  return (
    <SVGRoot
      className={props.className}
      style={props.style}
      role="img"
      width={width}
      height={height}
      aria-labelledby="loading-aria"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      <title id="loading-aria">Loading...</title>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath={`url(#clip-path-${id})`}
        style={{
          fill: `url(#fill-${id})`,
        }}
      />
      <defs>
        <clipPath id={`clip-path-${id}`}>
          <rect x="0" y="0" rx="10" ry="10" width={width} height={height} />
        </clipPath>
        <linearGradient id={`fill-${id}`}>
          <stop offset="0.6" stopColor={theme.color.mono_100} stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="1.6" stopColor={theme.color.mono_050} stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="2.6" stopColor={theme.color.mono_100} stopOpacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </SVGRoot>
  );
}
