import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';

import { Clipboard } from '../../../components/common/Clipboard';

export function GeneralSettingsSection() {}
function S() {}
GeneralSettingsSection.S = S;

S.Section = styled.section`
  padding-bottom: 20px;
`;

S.SectionView = styled.div`
  position: relative;
  width: 440px;
  padding: ${su(1)} ${su(2)};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.color.component};
`;

S.SectionHeading = styled.h3`
  padding: ${su(2)} 0 ${su(1)};
`;

S.SubSection = styled.section`
  padding-bottom: 20px;
`;

S.SubSectionHeading = styled.div`
  color: #93939a;
  font-size: 14px;
  padding-bottom: 5px;
`;

S.Row = styled.div`
  display: grid;
  align-items: center;
  min-height: 40px;
  gap: 20px;
  grid-template-columns: auto 1fr;
  min-width: 0;
`;

S.Label = styled.div``;

S.Value = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #93939a;
  word-break: break-word;
  text-align: right;
  min-width: 0;

  > *:not(:first-child) {
    margin-left: 4px;
  }

  ${Clipboard.S.Root} {
    flex: 1;
  }
`;

S.FadeInWrapper = styled.div`
  opacity: 0;
  animation-name: ${animationFade.in};
  animation-duration: ${theme.duration.normal};
  animation-timing-function: ${theme.curve.easeOut};
  animation-fill-mode: forwards;
`;

S.Center = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
