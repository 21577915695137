import { useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes as cnKeyframes } from '@emotion/css';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { motion, useMotionValue } from 'framer-motion';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { RouteManager, useHomeyPath } from '../../RouteManager';

import { WebAppSettingsStore } from '../../store/web-app-settings/WebAppSettingsStore';
import { useUserMe } from '../../store/user-me/useUserMe';

import { useAuth } from '../../store/AuthStore';
import { useApi, useCurrent } from '../../store/HomeyStore';
import { useNotificationsById } from '../../store/notifications/useNotifications';
import { useI18n } from '../../hooks/useI18nFormatters';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { GlobalSearch } from './global-search/GlobalSearch';
import { Notifications } from '../../components/notifications/Notifications';
import { Icon } from '../../components/common/Icon';
import { Overlay } from '../../components/overlay/Overlay';
import { AnimationRemain } from '../../components/animation/AnimationRemain';

import { ActionMenu } from './ActionMenu';
import { UserMenu } from './UserMenu';
import { HeaderButton } from './HeaderButton';

import { iconMoonRound } from '../../theme/icons/interface/moon-round';
import { iconPlusBold18 } from '../../theme/icons/interface/plus-bold';
import { iconBell22 } from '../../theme/icons/interface/bell';

export function Header(props) {
  const { i18n } = useI18n();

  const homeyPath = useHomeyPath();
  const history = useHistory();
  const location = useLocation();

  const { user, homeys } = useAuth();
  const { api } = useApi();
  const current = useCurrent();
  const userMe = useUserMe();
  const notifications = useNotificationsById();

  function handleHomeySelect({ homeyId }) {
    let nextUrl = location.pathname.replace(homeyPath, `/homeys/${homeyId}`);
    // Only allow base pages.
    nextUrl = nextUrl.split('/', 4).join('/');

    history.push(nextUrl);
  }

  const animationMV = useMotionValue(null);

  useLayoutEffect(() => {
    if (props.isRightOpen) {
      animationMV.set(`${panelSlideIn} ease-in-out 750ms`);

      return function () {
        animationMV.set(`${panelSlideOut} ease-in-out 500ms`);
      };
    }
  }, [props.isRightOpen, animationMV]);

  const actionMenuTriggerRef = useRef();
  const actionMenuTriggerState = useOverlayTriggerState({ defaultOpen: false });
  const actionMenuOverlayTrigger = useOverlayTrigger(
    { type: 'menu' },
    actionMenuTriggerState,
    actionMenuTriggerRef
  );

  const timelineTriggerRef = useRef();
  const timelineTriggerState = useOverlayTriggerState({ defaultOpen: false });
  const timelineOverlayTrigger = useOverlayTrigger(
    { type: 'listbox' },
    timelineTriggerState,
    timelineTriggerRef
  );

  return (
    <Header.Root>
      <Header.Children>{props.children}</Header.Children>
      <Header.Content
        isRightOverlay={props.isRightOverlay}
        isRightOpen={props.isRightOpen}
        style={{
          '--animation': animationMV,
        }}
      >
        {user && (
          <>
            {props.isMinimal !== true && <GlobalSearch />}

            {current && props.isMinimal !== true && (
              <Header.Item>
                <HeaderButton
                  {...actionMenuOverlayTrigger.triggerProps}
                  ref={actionMenuTriggerRef}
                  background="linear-gradient(#00B7FF,#0080FF)"
                  title={i18n.messageFormatter('header.add')}
                  onPress={actionMenuTriggerState.toggle}
                >
                  <Icon url={iconPlusBold18} size="18px" color={theme.color.white} />
                </HeaderButton>

                <AnimationRemain condition={actionMenuTriggerState.isOpen} delay={200}>
                  {(animationRemainProps) => {
                    return (
                      <Overlay
                        targetRef={actionMenuTriggerRef}
                        overlayProps={actionMenuOverlayTrigger.overlayProps}
                        overlayTriggerState={actionMenuTriggerState}
                        offset={10}
                        animationRemainProps={animationRemainProps}
                        autoFocus={true}
                        restoreFocus={true}
                      >
                        <ActionMenu
                          onAction={(key) => {
                            actionMenuTriggerState.close();
                            switch (key) {
                              case ActionMenu.KEYS.FLOW:
                                RouteManager.toFlow(`create?id=${uuid()}`);
                                break;
                              case ActionMenu.KEYS.ADVANCED_FLOW:
                                if (api.isCloudAndPremium === true) {
                                  RouteManager.toAdvancedFlow(`create?id=${uuid()}`);
                                  return;
                                }

                                if (api.isCloud === true) {
                                  RouteManager.toFlows(
                                    RouteManager.getStateForPremiumRequiredDialog()
                                  );
                                  return;
                                }

                                const hasAdvancedFlowLicense = api.features.advancedFlow.hasLicense;

                                if (hasAdvancedFlowLicense === false) {
                                  RouteManager.toNewFlow({ viewId: 'purchase' });
                                  return;
                                }

                                const hasAdvancedFlowVersion = api.features.advancedFlow.hasVersion;

                                if (hasAdvancedFlowVersion === false) {
                                  RouteManager.toNewFlow({ viewId: 'update' });
                                  return;
                                }

                                RouteManager.toAdvancedFlow(`create?id=${uuid()}`);
                                break;
                              case ActionMenu.KEYS.LOGIC:
                                const isPremiumLocked =
                                  api?.tier !== 'premium' &&
                                  api?.homey.platform !== undefined &&
                                  api?.homey.platform !== 'local';

                                if (isPremiumLocked === true) {
                                  RouteManager.toFlows(
                                    RouteManager.getStateForPremiumRequiredDialog()
                                  );
                                  return;
                                }

                                RouteManager.toNewLogicVariable();
                                break;
                              case ActionMenu.KEYS.SCRIPT:
                                RouteManager.toNewScript();
                                break;
                              case ActionMenu.KEYS.DEVICE:
                                RouteManager.toNewDevice();
                                break;
                              default:
                                break;
                            }
                          }}
                        />
                      </Overlay>
                    );
                  }}
                </AnimationRemain>
              </Header.Item>
            )}

            {current && props.isMinimal !== true && (
              <Header.Item>
                <HeaderButton
                  {...timelineOverlayTrigger.triggerProps}
                  ref={timelineTriggerRef}
                  title={i18n.messageFormatter('header.notifications')}
                  background={theme.color.component}
                  iconHoverColor
                  onPress={timelineTriggerState.toggle}
                >
                  <Icon url={iconBell22} size="22px" color={theme.color.icon_medium} />
                </HeaderButton>

                <AnimationRemain condition={timelineTriggerState.isOpen} delay={200}>
                  {(animationRemainProps) => {
                    return (
                      <Overlay
                        targetRef={timelineTriggerRef}
                        overlayProps={timelineOverlayTrigger.overlayProps}
                        overlayTriggerState={timelineTriggerState}
                        offset={10}
                        animationRemainProps={animationRemainProps}
                        autoFocus={true}
                        restoreFocus={true}
                      >
                        <Notifications
                          me={userMe.me}
                          notificationsById={notifications.byId}
                          onRequestDelete={({ id }) => {
                            notifications.api.notifications
                              .deleteNotification({ id })
                              .catch(() => {});
                          }}
                        />
                      </Overlay>
                    );
                  }}
                </AnimationRemain>
              </Header.Item>
            )}

            <Header.Item>
              <HeaderButton
                title="Toggle DarkMode"
                background={theme.color.component}
                onPress={() => {
                  WebAppSettingsStore.toggleDarkMode();
                }}
              >
                <Icon url={iconMoonRound} size="22px" color={theme.color.icon_medium} />
              </HeaderButton>
            </Header.Item>

            <Header.Item>
              <UserMenu
                user={user}
                homeys={homeys}
                current={current}
                onHomeySelect={handleHomeySelect}
              />
            </Header.Item>
          </>
        )}
      </Header.Content>
    </Header.Root>
  );
}

const panelSlideIn = cnKeyframes`
  0% {
    transform: translateX(420px);
  }

  60% {
    transform: translateX(-21px);
  }

  100% {
    transform: translateX(0);
  }
`;

const panelSlideOut = cnKeyframes`
  0% {
    transform: translateX(-420px);
  }

  100% {
    transform: translateX(0);
  }
`;

Header.Root = styled.header`
  pointer-events: auto;
  display: flex;
  flex: 0 0 80px;
  align-items: center;
  justify-content: space-between;
  min-height: 0;
  padding-top: 20px;
  padding-bottom: 20px;
`;

Header.Content = styled(motion.nav)`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin: 0 ${su(2)};
  animation: var(--animation, none);
  animation-fill-mode: forwards;
  z-index: ${theme.zIndex.header};

  ${(props) => props.isRightOverlay && props.isRightOpen && { paddingRight: 420 }}
`;

Header.Children = styled.div`
  z-index: ${theme.zIndex.header};
`;

Header.Item = styled.div`
  margin-left: 20px;

  &:first-of-type {
    margin-left: 0;
  }
`;
