import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';

import { executionStateType } from './executionStateType';

import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';

export function useExecutionState({ rootRef, nodeId, cardData }) {
  const [executionState, setExecutionState] = useState({
    type: executionStateType.idle,
  });

  const advancedFlowViewContext = useAdvancedFlowViewContext();

  useEffect(() => {
    // on restart
    // render once to apply the restart styles
    // after that the start styles are applied
    if (executionState.type === 'restart') {
      setExecutionState((prevState) => {
        return {
          type: executionStateType.start,
          duration: prevState.duration,
          delay: prevState.delay,
        };
      });
    }
  }, [executionState.type]);

  useEffect(() => {
    if (nodeId == null) return;

    const node = {
      id: nodeId,
      getElement() {
        return rootRef.current;
      },
      async onCardPreStart({ isReachable = true }) {
        if (isReachable) {
          setExecutionState({
            type: executionStateType.prestart,
          });
        } else {
          setExecutionState({
            type: executionStateType.unreachable,
          });
        }
      },
      async onCardStart({ delay, data }) {
        const duration = 500;
        setExecutionState({
          type: executionStateType.restart,
          duration: duration,
          delay: delay,
        });
        await new Promise((resolve) => setTimeout(resolve, duration));
      },
      async onCardUpdate({ data }) {
        // console.log('onCardUpdate', data);
        ReactDOM.flushSync(() => {
          setExecutionState((prevState) => {
            return {
              ...prevState,
              data,
            };
          });
        });
      },
      async onCardError({ error, tokens, usedTokens, elapsedTime, data }) {
        const duration = 500;
        setExecutionState((prevState) => {
          return {
            type: executionStateType.error,
            duration: duration,
            result: error,
            tokens: tokens,
            usedTokens: usedTokens,
            elapsedTime: elapsedTime,
            data: prevState.data,
          };
        });
        await new Promise((resolve) => setTimeout(resolve, duration));
      },
      async onCardEnd({ result, tokens, usedTokens, elapsedTime, data }) {
        const duration = 500;

        if (cardData.type === 'condition' && result != null) {
          if (result === true) {
            setExecutionState((prevState) => {
              return {
                type: executionStateType.true,
                duration: duration,
                result: result,
                tokens: tokens,
                usedTokens: usedTokens,
                elapsedTime: elapsedTime,
                data: data,
              };
            });
          } else {
            setExecutionState((prevState) => {
              return {
                type: executionStateType.false,
                duration: duration,
                result: result,
                tokens: tokens,
                usedTokens: usedTokens,
                elapsedTime: elapsedTime,
                data: data,
              };
            });
          }
        } else {
          setExecutionState((prevState) => {
            return {
              type: executionStateType.end,
              duration: duration,
              delay: prevState.delay,
              result: true,
              tokens: tokens,
              usedTokens: usedTokens,
              elapsedTime: elapsedTime,
              data: data,
            };
          });
        }
        await new Promise((resolve) => setTimeout(resolve, duration));
      },
      async onCardClear() {
        setExecutionState({
          type: executionStateType.idle,
        });
      },
    };

    advancedFlowViewContext.registerNode(node);

    return function () {
      advancedFlowViewContext.unregisterNode(node);
    };
  }, [advancedFlowViewContext, rootRef, nodeId, cardData?.type]);

  return { executionState };
}
