import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../../../theme/theme';
import { scrollbarDark } from '../../../../theme/elements/scrollbars';

export function ArgumentHomeyScriptOutput(props) {
  const scrollRef = useRef();

  useEffect(() => {
    const scrollElement = scrollRef.current;

    if (scrollElement != null) {
      scrollElement.scrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;
    }
  }, [props.logs]);

  return (
    <ArgumentHomeyScriptOutput.Root ref={scrollRef}>
      {props.logs.map((log, index) => {
        return <pre key={index}>{log}</pre>;
      })}
    </ArgumentHomeyScriptOutput.Root>
  );
}

ArgumentHomeyScriptOutput.Root = styled.div`
  ${scrollbarDark};
  flex: 1 1 0;
  padding: 10px;
  overflow-y: auto;

  &,
  pre {
    font-family: Roboto Mono, monospace;
    font-size: ${theme.fontWeight.regular};
    font-size: ${theme.fontSize.small};
    font-feature-settings: 'liga' 0, 'calt' 0;
  }
`;
