import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Battery } from '../../../components/common/Battery';

const table = styled.table`
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  font-size: ${theme.fontSize.small};
  margin-right: -9999px; // faulty trick to manipulate browser calculations for building up flexbox elements. If not applied, flex items are the minimum width of the table, which is unwanted.
  border-radius: ${theme.borderRadius.default};
`;

const tbody = styled.tbody`
  transition: ${theme.transition.micro};
  border-top: 2px solid ${theme.color.line_light};

  td:nth-of-type(1):not(:first-of-type),
  td:nth-of-type(2):not(:first-of-type) {
    cursor: pointer;
  }
`;
const thead = styled.thead`
  background: ${theme.color.component};
  box-shadow: ${theme.boxShadow.scroll};
  transition: ${theme.transition.micro};
  font-weight: ${theme.fontWeight.medium};
`;

const col = styled.col`
  transition: ${theme.transition.micro};
  ${(props) =>
    props.isHover &&
    css`
      background-color: ${theme.color.background_hover};
    `};
`;

const tr = styled.tr`
  cursor: default;
  color: var(--color, ${theme.color.mono_700});
`;

const th = styled.th`
  padding: ${su(1.5, 1)};
  transition: ${theme.transition.micro};
  width: 1%;
  white-space: nowrap;
  cursor: not-allowed;

  &:nth-last-of-type(2),
  &:last-child {
    width: auto;
    padding: 0;
  }

  ${(props) =>
    props.sortable &&
    css`
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: ${theme.color.background_hover};
      }
    `};
`;

const td = styled.td`
  border-top: 1px solid ${theme.color.line_light};
  padding: ${su(1)};

  min-width: 100px;
  width: 1%;
  white-space: nowrap;

  &:first-of-type {
    min-width: 20px;
  }

  &:nth-last-of-type(2) {
    min-width: auto;
    width: auto;
  }

  &:last-of-type:not(:first-of-type) {
    position: sticky;
    right: 0;
    min-width: auto;
    padding-top: 0;
    padding-bottom: 0;
    text-align: right;
    background-clip: padding-box;
  }

  ${Battery.Root} {
    margin-right: 10px;
  }
`;

export const Table = { table, thead, col, tbody, tr, th, td };
