import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
// import { getEmptyImage } from 'react-dnd-html5-backend';

export function useTreeItemDrag({ nodeId, nodeType, type }) {
  const [, dragRef, preview] = useDrag(() => {
    return {
      // { isDragging }
      type: type,
      item: {
        id: nodeId,
        nodeType: nodeType,
        type: type,
      },
      collect(dragMonitor) {
        return {
          isDragging: dragMonitor.isDragging(),
        };
      },
    };
  }, [type, nodeId, nodeType]);

  useEffect(() => {
    //preview(getEmptyImage());
  }, [preview]);

  return { dragRef };
}
