import styled from '@emotion/styled';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';

import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { MatchPath } from '../../../components/routing/MatchPath';
import { FlatButton } from '../../../components/buttons/FlatButton';
import { Spinner } from '../../../components/common/Spinner';

import { AppSettingsStore } from './AppSettingsStore';
import { AppSettingsDialogUninstall } from './AppSettingsDialogUninstall';
import { AppSettingsDialogDiagnosticsReport } from './AppSettingsDialogDiagnosticsReport';

import { iconPage } from '../../../theme/icons/interface/page';
import { iconArrowDoubleCircularRotated } from '../../../theme/icons/interface/arrow-double-circular-rotated/arrow-double-circular-rotated';
import { iconTrashRegular } from '../../../theme/icons/interface/trash-regular/trash-regular';

export function AppSettingsActions(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  // Matches the parent route.
  const routeMatch = useRouteMatch();

  const [isRestarting, setIsRestarting] = AppSettingsStore.useIsRestarting();

  const isRestartDisabled = (() => {
    switch (true) {
      case isRestarting === true:
      case props.app.enabled !== true:
      case props.app.state === 'stopping':
      case props.app.state === 'starting':
        return true;
      case props.app.state === 'running':
      case props.app.state === 'crashed':
      case props.app.state === 'stopped':
        return false;
      default:
        return true;
    }
  })();

  const isCreateDiagnosticsReportDisabled = (() => {
    return isRestartDisabled;
  })();

  const isUninstallDisabled = (() => {
    return false;
  })();

  function handleRestartPress() {
    setIsRestarting(true);
    api.apps
      .restartApp({ id: props.app.id })
      .then(() => {})
      .catch(ToastManager.handleError)
      .finally(() => {
        setIsRestarting(false);
      });
  }

  function handleUninstallPress() {
    AppSettingsDialogUninstall.route.open({ context: { appId: props.app.id } });
  }

  function handleCreateDiagnosticsReportPress() {
    RouteManager.toPath(`${routeMatch.url}/report`);
  }

  return (
    <S.Root>
      {api?.isCloud !== true && (
        <FlatButton
          styleWidth="full"
          styleColor="blue"
          isDisabled={isRestartDisabled}
          onPress={handleRestartPress}
        >
          <Spinner isActive={isRestarting}>
            <FlatButton.Icon url={iconArrowDoubleCircularRotated} size={theme.icon.size_small} />
          </Spinner>
          <FlatButton.Text>
            {(() => {
              switch (true) {
                case props.app.state === 'stopping':
                  return `${i18n.messageFormatter('common.stopping')}...`;
                case props.app.state === 'starting':
                  return `${i18n.messageFormatter('common.starting')}...`;
                default:
                  return i18n.messageFormatter('common.restart');
              }
            })()}
          </FlatButton.Text>
        </FlatButton>
      )}

      {props.app.origin === 'appstore' && (
        <FlatButton
          styleWidth="full"
          styleColor="blue"
          isDisabled={isCreateDiagnosticsReportDisabled}
          onPress={handleCreateDiagnosticsReportPress}
        >
          {/* TODO update icon from sketch */}
          <FlatButton.Icon url={iconPage} size={theme.icon.size_small} />
          <FlatButton.Text>
            {i18n.messageFormatter('settings.app.diagnosticsReportCreateTitle')}
          </FlatButton.Text>
        </FlatButton>
      )}

      <FlatButton
        styleWidth="full"
        styleColor="red"
        isDisabled={isUninstallDisabled}
        onPress={handleUninstallPress}
      >
        {/* TODO update icon from sketch */}
        <FlatButton.Icon url={iconTrashRegular} size={theme.icon.size_small} />
        <FlatButton.Text>{i18n.messageFormatter('common.uninstall')}</FlatButton.Text>
      </FlatButton>

      <MatchPath
        path={`${routeMatch.path}/uninstall`}
        children={({ match }) => {
          if (match) {
            return (
              <Redirect
                to={{
                  pathname: routeMatch.url,
                  state: AppSettingsDialogUninstall.route.createState({
                    context: {
                      appId: props.app.id,
                    },
                  }),
                }}
              />
            );
          }

          return null;
        }}
      />

      <MatchPath
        path={`${routeMatch.path}/report`}
        children={({ match }) => {
          function handleCloseRequest() {
            RouteManager.toPath(`${routeMatch.url}`);
          }

          return (
            <AnimatePresence>
              {match != null && (
                <AppSettingsDialogDiagnosticsReport
                  appId={props.app.id}
                  onCloseRequest={handleCloseRequest}
                />
              )}
            </AnimatePresence>
          );
        }}
      />
    </S.Root>
  );
}

function S() {}
AppSettingsActions.S = S;

S.Root = styled.section`
  display: grid;
  width: 440px;
  padding: ${su(2)};
  grid-gap: ${su(2)};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
`;
