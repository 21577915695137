import { useEffect, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useButton, useTextField } from 'react-aria';

import { enqueueTask } from '../../../lib/enqueueTask';
import { getTokenFlowKey } from './tokens';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';
import { useTokenHint } from './TokenHintContext';
import { useToken } from './tokens';

import { argInput } from './argInput';

import { TokenPicker } from '../token-picker/TokenPicker';
import { ArgumentBase, Argument } from './Argument';
import { FlowToken } from './FlowToken';
import { TokenHintContextDecider } from './TokenHintContext';

export function ArgumentNumber(props) {
  const { i18n } = useI18n();

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.number.title');
  const placeholder =
    props.argument.placeholder ?? i18n.messageFormatter('flow.argument.number.placeholder');

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  const { token, tokenKey, isToken } = useToken({
    value: localValue,
  });

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (
      (value == null && props.argument.required !== false) ||
      (props.argument.min !== undefined && Number(value) < props.argument.min) ||
      (props.argument.max !== undefined && Number(value) > props.argument.max)
    ) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue =
        token != null ? localValue : localValue != null ? Number(localValue) : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleTokenSelect(key, { kind, token }) {
    const tokenFlowKey = getTokenFlowKey(key, { kind, token });
    const nextValue = `[[${tokenFlowKey}]]`;

    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: true });

    props.onUpdate?.({
      argumentKey: props.argumentKey,
      value: nextValue,
    });

    // Give the TokenPicker a chance to gracefully start closing.
    enqueueTask(() => {
      triggerState.close();
    });
  }

  function handleClearTokenRequest() {
    const nextValue = undefined;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleChange(value) {
    const nextValue = value?.length === 0 || value == null ? undefined : value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        switch (true) {
          case token != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken tokenKey={tokenKey} token={token} label={token.title} />
              </ArgumentBase>
            );
          case token == null && isToken:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken title={localValue} />;
              </ArgumentBase>
            );
          case localValue != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                {localValue}
              </ArgumentBase>
            );
          default:
            return (
              <ArgumentBase
                {...triggerProps}
                ref={triggerRef}
                data-is-invalid={isInvalid}
                data-is-empty={true}
              >
                {props.argumentTypeText}
              </ArgumentBase>
            );
        }
      }}
      renderOverlay={() => {
        return (
          <TokenHintContextDecider
            cardType={props.cardType}
            card={props.card}
            data={props.data}
            argument={props.argument}
          >
            <NumberInput
              label={label}
              placeholder={placeholder}
              min={props.argument.min}
              max={props.argument.max}
              step={props.argument.step}
              token={token}
              tokenKey={tokenKey}
              value={localValue}
              onChange={handleChange}
              onTokenSelect={handleTokenSelect}
              onClearTokenRequest={handleClearTokenRequest}
              onSaveRequest={handleSaveRequest}
            />
          </TokenHintContextDecider>
        );
      }}
    />
  );
}

const TOKEN_TYPES = ['number'];

function NumberInput(props) {
  const buttonRef = useRef();
  const inputRef = useRef();
  const containerRef = useRef();

  const tokenHint = useTokenHint();

  function onKeyDown(event) {
    // Consume every key and save on enter.
    if (event.key === 'Enter') {
      event.preventDefault();
      props.onSaveRequest();
    } else if (event.key === 'Escape') {
      // Propagate on escape.
      event.continuePropagation();
    } else if (event.key === 'Delete') {
      event.preventDefault();
      props.onChange(undefined);
      enqueueTask(() => {
        inputRef.current?.focus();
      });
    }
  }

  function onKeyUp(event) {
    // Consume every key and save on enter.
    if (event.key === 'Enter') {
      event.preventDefault();
    } else if (event.key === 'Escape') {
      // Propagate on escape.
      event.continuePropagation();
    } else if (event.key === 'Delete') {
      event.preventDefault();
    }
  }

  const input = useTextField(
    {
      label: props.label,
      type: 'number',
      value: props.value ?? '',
      onChange: props.onChange,
      placeholder: props.placeholder,
      onKeyDown: onKeyDown,
      onKeyUp: onKeyUp,
    },
    inputRef
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      // It either renders as a button or as an input.
      inputRef.current?.focus();
      buttonRef.current?.focus();
    });
  }, []);

  function wrap(children) {
    return (
      <argInput.InputContainer ref={containerRef}>
        <argInput.InputLabel {...input.labelProps}>{props.label}</argInput.InputLabel>
        <argInput.InputRow>
          {children}
          <TokenPicker types={TOKEN_TYPES} onSelect={props.onTokenSelect} />
        </argInput.InputRow>

        {tokenHint != null && <argInput.InputHint>{tokenHint}</argInput.InputHint>}
      </argInput.InputContainer>
    );
  }

  const button = useButton(
    {
      elementType: FlowToken.Root.__emotion_base,
      onKeyDown: onKeyDown,
      onKeyUp: onKeyUp,
      onPress() {
        props.onClearTokenRequest();
        enqueueTask(() => {
          inputRef.current?.focus();
        });
      },
    },
    buttonRef
  );

  if (props.token != null) {
    return wrap(
      <FlowToken
        {...button.buttonProps}
        ref={buttonRef}
        tokenKey={props.tokenKey}
        token={props.token}
        showRemoveIcon={true}
        isValueTooltipDisabled={true}
        label={props.token.title}
      />
    );
  }

  return wrap(
    <argInput.Input
      {...input.inputProps}
      ref={inputRef}
      min={props.min}
      max={props.max}
      step={props.step}
    />
  );
}
