import { ResourceUtils } from '../../store/ResourceUtils';

export function parseLogsEntries(resolved) {
  const lastUpdatesIn = resolved.reduce((accumulator, logData) => {
    if (logData.updatesIn > accumulator) {
      return logData.updatesIn;
    }

    return accumulator;
  }, 0);

  const [firstLogData, ...otherLogsData] = resolved;

  let minValue = Infinity;
  let maxValue = -Infinity;

  const groupValues = firstLogData.values.reduce((accumulator, value, index, array) => {
    // fill in empty values

    const v =
      value.v != null
        ? value.v
        : index + 1 === array.length
        ? firstLogData.lastValue
        : index + 2 === array.length
        ? firstLogData.lastValue
        : null;

    if (v != null && v < minValue) {
      minValue = v;
    }

    if (v != null && v > maxValue) {
      maxValue = v;
    }

    const baseValue = {
      t: value.t,
      [ResourceUtils.temp__getId(firstLogData)]: v,
    };

    const mergedValue = otherLogsData.reduce((accumulator, logData) => {
      const value = logData.values[index];

      // fill in empty values
      const v =
        value.v != null
          ? value.v
          : index + 1 === array.length
          ? logData.lastValue
          : index + 2 === array.length
          ? logData.lastValue
          : null;

      if (v != null && v < minValue) {
        minValue = v;
      }

      if (v != null && v > maxValue) {
        maxValue = v;
      }

      accumulator[ResourceUtils.temp__getId(logData)] = v;

      return accumulator;
    }, baseValue);

    accumulator.push(mergedValue);

    return accumulator;
  }, []);

  return { groupValues, lastUpdatesIn, minValue, maxValue };
}
