import React, { useRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useToggleState } from 'react-stately';
import { useToggleButton } from 'react-aria';

import { theme } from '../../theme/theme';
import { mergeRefs } from '../../lib/mergeRefs';

export const SwitchButton = forwardRef(function (props, forwardedRef) {
  const ref = useRef();
  const state = useToggleState(props);
  const toggleButton = useToggleButton(props, state, ref);

  return (
    <SwitchButton.Root
      {...toggleButton.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      status={props.readonly ? props.status : state.isSelected}
      small={props.small}
    />
  );
});

SwitchButton.Root = styled.button`
  --circle-size-h: 14px;
  --circle-size-w: 14px;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 16px;
  border: 1px solid ${theme.color.line};
  width: 30px;
  border-radius: 8px;
  background: ${theme.color.mono_300};
  transition: ${theme.transition.micro};
  cursor: pointer;
  outline: none;

  &::before {
    content: '';
    display: block;
    border-radius: var(--circle-size-h);
    background: #fff;
    width: var(--circle-size-w);
    height: var(--circle-size-h);
    position: absolute;
    transition: ${theme.transition.micro};
    left: 0;
  }

  ${(props) =>
    props.readonly == null &&
    css`
      &:hover {
        background: ${theme.color.mono_400};
        border-color: ${theme.color.mono_400};
        --circle-size-w: 16px;
      }
    `}

  ${(props) =>
    props.status === true &&
    css`
      background: ${theme.color.highlight};
      border-color: ${theme.color.highlight};
      justify-content: flex-end;

      &:hover {
        background: ${theme.color.highlight_hover};
        border-color: ${theme.color.highlight_hover};
      }

      &::before {
        left: calc(100% - var(--circle-size-w));
      }
    `};

  ${(props) =>
    props.small === true &&
    css`
      --circle-size-h: 8px;
      --circle-size-w: 8px;
      height: 10px;
      width: 18px;

      ${props.readonly == null &&
      css`
        &:hover {
          --circle-size-w: 10px;
        }
      `}
    `};
`;
