import { useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { useFocusRing, useRadio, VisuallyHidden, mergeProps, useId } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { RadioContext } from './RadioGroup';

export function Radio(props) {
  const radioRef = useRef();
  const context = useContext(RadioContext);
  const radio = useRadio({ ...props, children: props.description }, context.state, radioRef);
  const focusRing = useFocusRing();

  const { ref: registerRef, ...registerProps } =
    context.register?.(context.name, {
      required: props.required,
    }) ?? {};

  const titleId = useId();
  // const descriptionId = useId();

  return (
    <S.Label>
      <VisuallyHidden>
        <input
          {...mergeProps(radio.inputProps, focusRing.focusProps, registerProps)}
          ref={mergeRefs([registerRef, radioRef])}
          aria-labelledby={titleId}
          // aria-describedby={descriptionId}
        />
      </VisuallyHidden>

      <S.Title id={titleId}>{props.title}</S.Title>

      <S.Cirle data-is-selected={radio.isSelected} data-is-disabled={radio.isDisabled} />
      {/* <SRadio.Description id={descriptionId}>{props.description}</SRadio.Description> */}
    </S.Label>
  );
}

function S() {}
Radio.S = S;

S.Label = styled.label`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  height: 40px;
  font-size: ${theme.fontSize.default};
  cursor: pointer;
`;

S.Title = styled.div``;

S.Cirle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid ${theme.color.mono_100};
  border-radius: 50%;

  &[data-is-selected='true'] {
    border-color: ${theme.color.blue};

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      background-color: ${theme.color.blue};
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &[data-is-disabled='true'] {
    border-color: ${theme.color.mono_050};
    cursor: not-allowed;

    ::after {
      background-color: ${theme.color.mono_050};
    }
  }
`;
