import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { isReducedMotion } from '../../theme/classes/reducedMotion';
import { animationFade } from '../../theme/animations/animationFade';
import { animationScaleFade2 } from '../../theme/animations/animationScaleFade';

export function MoodGrid() {}

MoodGrid.Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

MoodGrid.Item = styled.div`
  flex: 0 0 auto;
  padding-right: ${su(1.5)};
  padding-bottom: ${su(1.5)};

  &:not([data-animated='true']) {
    opacity: 0;
    animation-name: ${animationScaleFade2.in};
    animation-timing-function: ease-in-out;
    animation-duration: ${theme.duration.slow};
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: var(--animation-delay);

    ${isReducedMotion} {
      opacity: 1;
      animation-name: ${animationFade.in};
      animation-delay: initial;
    }
  }
`;

MoodGrid.Item.defaultProps = {
  onAnimationEnd(event) {
    event.target.setAttribute('data-animated', 'true');
  },
};
