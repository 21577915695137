import { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

export function useAttach(Store, name = 'x', { isDisabled } = {}) {
  const instanceRef = useRef(null);

  if (instanceRef.current === null) {
    instanceRef.current = `${Store.key}:${name}:${uuid()}`;
  }

  useEffect(() => {
    const ref = instanceRef;

    if (Store && isDisabled !== true) {
      Store.attach(ref.current);
      return function () {
        Store.detach(ref.current);
      };
    }
  }, [Store, isDisabled]);
}
