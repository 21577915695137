import { v4 as uuid } from 'uuid';

import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';
import { ToastManager } from '../../ToastManager';
import { LogicUtils } from './LogicUtils';

export class LogicStore extends BaseApiStore {
  static key = 'logic';
  static store = this.createStore(this.key);
  static variableCacheId = 'HomeyAPI.ManagerLogic.Variable';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      byType: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:logic');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerLogic = state.api.logic;

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 0));
      const variablesPromise = await managerLogic.getVariables();

      const [, variablesData] = await Promise.all([waitPromise, variablesPromise]);

      const result = Object.values(variablesData).reduce(
        (accumulator, variable) => {
          const variableReference = { ...variable };

          accumulator.data[variable.id] = variable;
          accumulator.byId[variable.id] = variableReference;
          accumulator.byType[variable.type] = {
            ...accumulator.byType[variable.type],
            [variable.id]: { id: variable.id, name: variable.name },
          };
          return accumulator;
        },
        {
          data: {},
          byId: {},
          byType: {
            string: {},
            number: {},
            boolean: {},
          },
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byId: result.byId,
        byType: result.byType,
        loading: false,
        manager: managerLogic,
      });

      managerLogic.addListener('variable.create', this.handleCreate);
      managerLogic.addListener('variable.update', this.handleUpdate);
      managerLogic.addListener('variable.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:logic');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerLogic = state.api.logic;

      managerLogic.removeListener('variable.create', this.handleCreate);
      managerLogic.removeListener('variable.update', this.handleUpdate);
      managerLogic.removeListener('variable.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(variable) {
    const state = this.get();
    const cache = state.api.logic._caches[this.variableCacheId];

    return cache.getOne({
      id: cache._guessCacheId(variable),
    });
  }

  static handleCreate = (createdVariable) => {
    const id = createdVariable.id;
    __DEV__ && console.info(`create:variable:${id}`);
    const state = this.get();
    const variableInstance = this.getInstanceFromCache(createdVariable);

    if (variableInstance == null) return;

    this.set({
      data: {
        ...state.data,
        [createdVariable.id]: variableInstance,
      },
      byId: {
        ...state.byId,
        [createdVariable.id]: { ...variableInstance },
      },
      byType: {
        ...state.byType,
        [variableInstance.type]: {
          ...state.byType[variableInstance.type],
          [variableInstance.id]: { id: variableInstance.id, name: variableInstance.name },
        },
      },
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedVariable) => {
    __DEV__ && console.info(`update:variable:${updatedVariable.id}`);
    this.updateQueue[updatedVariable.id] = {
      ...updatedVariable,
    };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedVariables = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const updates = Object.entries(queuedVariables).reduce(
          (accumulator, [id, queuedVariable]) => {
            const variableInstance = this.getInstanceFromCache(queuedVariable);

            if (variableInstance == null || state.data[id] == null) {
              __DEV__ && console.info(`update:variable:unknown:${id}`);
              return accumulator;
            }

            if (
              state.byId[queuedVariable.id].name !== queuedVariable.name ||
              state.byId[queuedVariable.id].type !== queuedVariable.type
            ) {
              accumulator.byTypeUpdates[queuedVariable.id] = queuedVariable;
            }

            return accumulator;
          },
          {
            byTypeUpdates: {},
          }
        );

        const nextById = {
          ...state.byId,
          ...queuedVariables,
        };

        let nextByType = state.byType;

        if (Object.keys(updates.byTypeUpdates).length > 0) {
          nextByType = { ...state.byType };

          for (const nextVariable of Object.values(updates.byTypeUpdates)) {
            const prevVariable = state.byId[nextVariable.id];

            const prevType = prevVariable.type;
            const nextType = nextVariable.type;

            const { [prevVariable.id]: value, ...rest } = nextByType[prevType];

            nextByType[prevType] = {
              ...rest,
            };

            // if (Object.keys(nextByType[prevType]).length === 0) {
            //   delete nextByType[prevType];
            // }

            nextByType[nextType] = {
              ...nextByType[nextType],
              [nextVariable.id]: {
                id: nextVariable.id,
                name: nextVariable.name,
              },
            };
          }
        }

        this.set({
          byId: nextById,
          byType: nextByType,
        });
      }, 1000);
    }
  };

  static handleDelete = (deletedVariable) => {
    __DEV__ && console.info(`delete:variable:${deletedVariable.id}`);
    const state = this.get();
    const variableInstance = state.data[deletedVariable.id];

    if (variableInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };
    const nextByType = { ...state.byType };

    delete nextData[variableInstance.id];
    delete nextById[variableInstance.id];
    delete nextByType[variableInstance.type][variableInstance.id];

    this.set({
      data: nextData,
      byId: nextById,
      byType: nextByType,
    });
  };

  static saveVariableName({ id, type, name }) {
    const state = this.get();

    this.set({
      byId: {
        ...state.byId,
        [id]: {
          ...state.byId[id],
          name: name,
        },
      },
      byType: {
        ...state.byType,
        [type]: {
          ...state.byType[type],
          [id]: {
            id: id,
            name: name,
          },
        },
      },
    });

    if (id.startsWith('create') === false) {
      state.manager
        .updateVariable({
          id: id,
          variable: {
            name: name,
          },
        })
        .then(() => {})
        .catch(ToastManager.handleError);
    }

    if (id.startsWith('create') === true) {
      this.createVariable({
        id,
        type,
        name,
        value: state.byId[id].value,
      });
    }
  }

  static saveVariableValue({ id, type, value }) {
    const state = this.get();

    this.set({
      byId: {
        ...state.byId,
        [id]: {
          ...state.byId[id],
          value: LogicUtils.parseValue(type, value),
        },
      },
    });

    if (id.startsWith('create') === false) {
      state.manager
        .updateVariable({
          id: id,
          variable: {
            value: LogicUtils.parseValue(type, value),
          },
        })
        .then(() => {})
        .catch(ToastManager.handleError);
    }

    if (id.startsWith('create') === true) {
      this.createVariable({
        id,
        type,
        name: state.byId[id].name,
        value,
      });
    }
  }

  static async createVariable({ id, type, name, value }) {
    const state = this.get();

    state.manager.removeListener('variable.create', this.handleCreate);
    state.manager
      .createVariable({
        variable: {
          type: type,
          name: name,
          value: LogicUtils.parseValue(type, value),
        },
      })
      .then(async (createdVariable) => {
        const variableInstance = await state.manager
          .getVariable({
            id: createdVariable.id,
          })
          .catch(console.error);

        if (variableInstance == null) return;

        const nextState = {
          data: {
            ...state.data,
            [createdVariable.id]: variableInstance,
          },
          byId: {
            ...state.byId,
            [createdVariable.id]: { ...variableInstance },
          },
          byType: {
            ...state.byType,
            [variableInstance.type]: {
              ...state.byType[variableInstance.type],
              [variableInstance.id]: { id: variableInstance.id, name: variableInstance.name },
            },
          },
        };

        delete nextState.byId[id];
        delete nextState.byType[type][id];

        this.set(nextState);
      })
      .catch(ToastManager.handleError)
      .finally(() => {
        state.manager.addListener('variable.create', this.handleCreate);
      });
  }

  static deleteVariable({ id }) {
    const state = this.get();
    const variable = state.byId[id];
    const variableInstance = state.data[id];

    const nextState = {
      data: {
        ...state.data,
      },
      byId: {
        ...state.byId,
      },
      byType: {
        ...state.byType,
        [variable.type]: {
          ...state.byType[variable.type],
        },
      },
    };

    delete nextState.data[variable.id];
    delete nextState.byId[variable.id];
    delete nextState.byType[variable.type][variable.id];

    this.set(nextState);

    variableInstance != null &&
      state.manager
        .deleteVariable({ id })
        .then(() => {})
        .catch(ToastManager.handleError);
  }

  static createTempVariable({ type }) {
    const state = this.get();
    const id = `create-${uuid()}`;

    this.set({
      byId: {
        ...state.byId,
        [id]: {
          id: id,
          type: type,
          name: '',
          value: LogicUtils.getDefaultTypeValue(type),
        },
      },
      byType: {
        ...state.byType,
        [type]: {
          ...state.byType[type],
          [id]: {
            id,
            name: '',
          },
        },
      },
    });
  }
}
