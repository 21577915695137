import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { useDrop } from 'react-dnd';
import { useConnectorRegister } from './useConnectorRegister';
import { useCurrentProps } from '../../../../hooks/useCurrentProps';

import { ConnectorBase } from './ConnectorBase';

import { connectorTypesMap } from './connectorTypes';
import { DropZone } from './DropZone';

export function ConnectorInMany(props) {
  const currentProps = useCurrentProps({
    onIsDraggingAndCanConnectChange: props.onIsDraggingAndCanConnectChange,
    onIsDragOverAndCanConnectChange: props.onIsDragOverAndCanConnectChange,
  });

  const circleRefs = useRef({});

  // eslint-disable-next-line no-unused-vars
  const [collectedProps, connectorInDropTarget] = useDrop(() => {
    const accept = [
      connectorTypesMap.Out,
      connectorTypesMap.Error,
      connectorTypesMap.True,
      connectorTypesMap.False,
    ];

    return {
      accept: accept,
      collect: (dropMonitor) => {
        return {
          isDragOver: dropMonitor.isOver({ shallow: false }),
          canDrop: dropMonitor.canDrop(),
          // This method seems to be called on any drag operation so it can receive
          // flowcard items even when they are not in accept. So check the accept array for
          // the current item type and dont return the item if we do not care about it.
          //
          // This seems like a bug because this component renders/collects for no reason now.
          item: accept.includes(dropMonitor.getItemType()) ? dropMonitor.getItem() : null,
        };
      },
      canDrop(dragItem, dropMonitor) {
        // not path.includes(me or my parents)

        if (dragItem.nodeId === props.nodeId || props.isDisabled) {
          return false;
        }

        function isDragItemChild(children) {
          if (children == null) {
            return false;
          }

          if (children.some((child) => child.id === dragItem.nodeId)) {
            return true;
          }

          for (const child of children) {
            const childChildren = props.nodeChildren[child.id];
            const result = isDragItemChild(childChildren);

            if (result === true) {
              return result;
            }
          }

          return false;
        }

        const children = props.nodeChildren[props.nodeId];
        const result = isDragItemChild(children);

        return result !== true;
      },
      hover(item, monitor) {},
      drop(dragItem, monitor) {
        // Allows nested targets to handle the drop.
        if (monitor.didDrop()) return;

        const fromNodeId = dragItem.nodeId;

        AdvancedFlowViewStore.createOrderedConnection({
          fromNodeId: fromNodeId,
          fromConnectorType: monitor.getItemType(),
          toNodeId: props.nodeId,
          toConnectorType: connectorTypesMap.In,
          index: 1000,
        });
      },
    };
  }, [props.nodeId, props.nodeChildren, props.isDisabled]);

  // We attach the behavior to the whole card.
  connectorInDropTarget(props.cardRootRef);

  useEffect(() => {
    return function () {
      connectorInDropTarget(null);
    };
  }, [connectorInDropTarget]);

  useConnectorRegister({
    cardRootRef: props.cardRootRef,
    centerRef: null,
    centerRefs: circleRefs,
    type: connectorTypesMap.In,
    nodeId: props.nodeId,
  });

  const isActive = props.activeConnectors.has(`${props.nodeId}::${connectorTypesMap.In}`);
  const isDraggingAndCanConnect = collectedProps.item != null && collectedProps.canDrop === true;
  const isDragOverAndCanConnect =
    collectedProps.item != null &&
    collectedProps.canDrop === true &&
    collectedProps.isDragOver === true;

  useEffect(() => {
    currentProps.onIsDraggingAndCanConnectChange(isDraggingAndCanConnect);
  }, [currentProps, isDraggingAndCanConnect]);

  useEffect(() => {
    currentProps.onIsDragOverAndCanConnectChange(isDragOverAndCanConnect);
  }, [currentProps, isDragOverAndCanConnect]);

  const thisNodeParents = props.nodeParents[props.nodeId];

  const containerRef = useRef();
  const sizerRef = useRef();

  useLayoutEffect(() => {
    sizerRef.current.style.height = `${containerRef.current.getBoundingClientRect().height}px`;
  });

  const parents = [...(thisNodeParents ?? [])];

  if (parents.length === 0) {
    parents.push({
      id: 'firstEmpty',
      fromConnectorType: 'none',
      isPlaceholder: true,
    });
    parents.push({
      id: 'secondEmpty',
      fromConnectorType: 'none',
      isPlaceholder: true,
    });
  }

  if (parents.length === 1) {
    parents.push({
      id: 'secondEmpty',
      fromConnectorType: 'none',
      isPlaceholder: true,
    });
  }

  return (
    <>
      <ConnectorInMany.SizerRef ref={sizerRef} />
      <ConnectorInMany.Container ref={containerRef}>
        {parents?.map((parent, index, array) => {
          const key = `${parent.id}::${parent.fromConnectorType}`;
          const isConnectorDone = props.executionState.data?.doneConnectorKeysSet.has(key) ?? false;

          return (
            <ConnectorInMany.Item key={key}>
              <DropZone
                index={index}
                nodeId={props.nodeId}
                nodeChildren={props.nodeChildren}
                isFirstChild={index === 0}
                isLastChild={false}
                isDisabled={props.isDisabled}
              />

              <ConnectorBase
                circleRef={(ref) => {
                  circleRefs.current[key] = ref;
                }}
                style={{
                  transform: 'rotate(90deg)',
                }}
                connectorType={connectorTypesMap.In}
                connectorTypeColorOverride={parent.fromConnectorType}
                executionState={props.executionState}
                isActive={parent.isPlaceholder ? false : isActive}
                isDraggingAndCanConnect={isDraggingAndCanConnect}
                isDragOverAndCanConnect={isDragOverAndCanConnect}
                isHovered={props.isHovered}
                isSelected={props.isSelected}
                isDisabled={props.isDisabled}
                isDisabledStyle={props.isDisabledStyle}
                isVisibleOverride={props.isVisibleOverride}
                isConnectorDone={isConnectorDone}
              />

              {index === array.length - 1 && (
                <DropZone
                  index={index + 1}
                  nodeId={props.nodeId}
                  nodeChildren={props.nodeChildren}
                  isFirstChild={false}
                  isLastChild={true}
                  isDisabled={props.isDisabled}
                />
              )}
            </ConnectorInMany.Item>
          );
        })}
      </ConnectorInMany.Container>
    </>
  );
}

ConnectorInMany.Container = styled.div`
  position: absolute;
  right: calc(100% + 1px);
  // Increasing height ensures better lines for right connector out.
  padding: 2px 0;

  // background: rgba(255, 125, 0, 0.5);
`;

ConnectorInMany.SizerRef = styled.div`
  width: 0;
  margin: -3px 0;
`;

ConnectorInMany.Item = styled.div`
  position: relative;
`;
