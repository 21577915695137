import { useEffect, useState } from 'react';

export function useOnOff({ capabilities }) {
  const [value, setValue] = useState(capabilities?.onoff?.value ?? true);

  useEffect(() => {
    if (capabilities.onoff != null) {
      const unregister = capabilities.onoff.onChange(({ value }) => {
        setValue(value);
      });

      const currentValue = capabilities.onoff.value ?? true;

      setValue((prevValue) => {
        if (prevValue !== currentValue) {
          return currentValue;
        }
        return prevValue;
      });

      return function () {
        unregister();
      };
    }
  }, [capabilities]);

  return { value, capability: capabilities?.onoff };
}
