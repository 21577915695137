import { useRef, useEffect, useReducer } from 'react';

import {
  getMode,
  hasColorMode,
  hasTemperatureMode,
} from '../../lib/capabilities/light';

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'set_mode':
      const { light_mode } = action.payload;
      if (light_mode == null) {
        return {
          ...state,
          light_mode: getMode(state),
        };
      }
      return { ...state, ...action.payload };
    case 'init':
      return { ...action.payload };
    default:
      throw new Error();
  }
}

export function useLightState({
  ids,
  capabilities,
  initialState = {},
  instanceId,
}) {
  const initialStateConstant = useRef(initialState).current;
  const [state, dispatch] = useReducer(reducer, {}, undefined);

  function setValues(values) {
    dispatch({
      type: 'set',
      payload: values,
    });
  }

  useEffect(() => {
    if (capabilities != null && ids != null) {
      const unregisters = [];

      function handleChange({ capabilityId, value, callerId }) {
        if (callerId != null && instanceId != null && callerId === instanceId)
          return;

        switch (capabilityId) {
          case 'light_temperature':
            dispatch({
              type: 'set',
              payload: {
                [capabilityId]: value,
                light_temperature_x: 0,
              },
            });
            break;
          case 'light_mode':
            dispatch({
              type: 'set_mode',
              payload: {
                [capabilityId]: value,
              },
            });
            break;
          default:
            dispatch({
              type: 'set',
              payload: {
                [capabilityId]: value,
              },
            });
            break;
        }
      }

      const initialValues = Object.values(capabilities).reduce(
        (accumulator, capability) => {
          unregisters.push(capability.onChange(handleChange));
          accumulator[capability.id] = capability.value;
          return accumulator;
        },
        {}
      );

      initialValues.light_modes = [];

      if (hasColorMode(initialValues)) {
        initialValues.light_modes.push('color');
      }

      if (hasTemperatureMode(initialValues)) {
        initialValues.light_modes.push('temperature');
      }

      initialValues.light_mode = getMode(initialValues);

      dispatch({
        type: 'init',
        payload: {
          ...initialValues,
          ...initialStateConstant,
        },
      });

      return function () {
        unregisters.forEach((unregister) => unregister());
      };
    }
  }, [ids, capabilities, initialStateConstant, instanceId]);

  return { state, setValues };
}
