import React from 'react';

import { FlowCardBase } from '../../flow-card/FlowCardBase';
import { FlowArgument } from '../../flow-arguments/FlowArgument';
import { ArgumentDroptoken } from '../../flow-arguments/ArgumentDroptoken';
import { ArgumentDuration } from '../../flow-arguments/ArgumentDuration';
import { ArgumentDelay } from '../../flow-arguments/ArgumentDelay';

export function argumentMapper({
  chunk,
  index,
  data,
  cardContainerRef,
  card,
  isDisabled,
  isDisabledStyle,
  onArgumentUpdate,
  onPropertyUpdate,
}) {
  switch (chunk.type) {
    case 'argument': {
      return (
        <FlowArgument
          key={index}
          cardContainerRef={cardContainerRef}
          argumentKey={chunk.value}
          argument={chunk.argument}
          argumentTypeText={chunk.argumentTypeText}
          otherArguments={chunk.otherArguments}
          cardType={data.type}
          card={card}
          data={data}
          isFirstArgument={false}
          isDisabled={isDisabled}
          isDisabledStyle={isDisabledStyle}
          onUpdate={onArgumentUpdate}
          onPropertyUpdate={onPropertyUpdate}
        />
      );
    }
    case 'droptoken': {
      return (
        <ArgumentDroptoken
          key={index}
          cardContainerRef={cardContainerRef}
          argumentTypeText={chunk.argumentTypeText}
          otherArguments={chunk.otherArguments}
          cardType={data.type}
          card={card}
          data={data}
          isFirstArgument={false}
          isDisabled={isDisabled}
          isDisabledStyle={isDisabledStyle}
          onUpdate={(args) => {
            onPropertyUpdate({
              propertyKey: 'droptoken',
              value: args.value,
            });
          }}
        />
      );
    }
    case 'duration': {
      return (
        <ArgumentDuration
          key={index}
          cardContainerRef={cardContainerRef}
          cardType={data.type}
          card={card}
          data={data}
          isDisabled={isDisabled}
          isDisabledStyle={isDisabledStyle}
          onUpdate={(args) => {
            onPropertyUpdate({
              propertyKey: 'duration',
              value: args.value,
            });
          }}
        />
      );
    }
    case 'delay': {
      return (
        <ArgumentDelay
          key={index}
          cardContainerRef={cardContainerRef}
          cardType={data.type}
          card={card}
          data={data}
          isDisabled={isDisabled}
          isDisabledStyle={isDisabledStyle}
          onUpdate={(args) => {
            onPropertyUpdate({
              propertyKey: 'delay',
              value: args.value,
            });
          }}
        />
      );
    }
    default: {
      return <FlowCardBase.Text key={index}>{chunk.value}</FlowCardBase.Text>;
    }
  }
}
