import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useHomeyImage } from '../../store/images/useImages';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { GradientButton } from '../../components/buttons/GradientButton';
import { TextField } from '../../components/forms/TextField';
import { SelectControllable } from '../../components/forms/select/SelectControllable';
import { FlowToken, tokenTypes } from './flow-arguments/FlowToken';

import { iconPlay } from '../../theme/icons/interface/play';

// TODO
// add some sort of session storage for multiple tests

export function FlowTestForm(props) {
  const { i18n } = useI18n();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      // type: 'string',
      // name: '',
      // value: '',
    },
  });

  const { image } = useHomeyImage();

  function onSubmit(data, options = {}) {
    const formatted = {};

    for (const [tokenKey, value] of Object.entries(data)) {
      const token = props.tokens[tokenKey];
      if (token.type === 'number') {
        formatted[tokenKey] = parseFloat(value);
        continue;
      } else if (token.type === 'boolean') {
        formatted[tokenKey] = value === 'true';
        continue;
      }

      formatted[tokenKey] = value;
    }

    // Fill in the image tokens with the default homey image.
    for (const [tokenKey, token] of Object.entries(props.tokens)) {
      if (token.type === 'image') {
        formatted[tokenKey] = {
          type: 'image',
          id: image?.id,
        };
      }
    }

    props.onStartRequest({ tokens: formatted });
  }

  function tokenMapper([key, value], index, source) {
    switch (value.type) {
      case tokenTypes.boolean: {
        const options = [
          {
            id: 'true',
            textValue: i18n.messageFormatter('common.true'),
          },
          {
            id: 'false',
            textValue: i18n.messageFormatter('common.false'),
          },
        ];

        return (
          <SelectControllable
            key={key}
            //orientation="vertical"
            name={key}
            label={value.title ?? value.label}
            renderLabel={(label) => {
              return <FlowToken tokenType={value.type} isHoverable={false} label={label} />;
            }}
            defaultValue="true"
            options={options}
            control={control}
            required
          />
        );
      }
      case tokenTypes.image: {
        // TODO
        // either support getDummyImage for runFlowCard[Type]
        // or render and pass the default dummy image here
        break;
      }
      default: {
        return (
          <TextField
            key={key}
            autoComplete="off"
            //orientation="vertical"
            type={value.type === 'string' ? 'text' : value.type}
            name={key}
            label={value.title ?? value.label}
            defaultValue={value.example ?? ''}
            register={register}
            required
            renderLabel={(label) => {
              return <FlowToken tokenType={value.type} isHoverable={false} label={label} />;
            }}
            onKeyDown={(event) => {
              // If we hit enter in any TextField start the test.
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(onSubmit)({ preventClose: false });
              }
            }}
          />
        );
      }
    }
  }

  return (
    <FlowTestForm.Root
      onSubmit={(event) => {
        // single element form causes submit on enter key in a field
        event.preventDefault();
      }}
    >
      <FlowTestForm.Paragraph>{i18n.messageFormatter('flow.testFormHint')}</FlowTestForm.Paragraph>

      {Object.entries(props.tokens).map(tokenMapper)}

      <GradientButton
        styleWidth="full"
        styleFlat={true}
        onPress={() => {
          handleSubmit(onSubmit)({ preventClose: false });
        }}
      >
        <GradientButton.Icon
          url={iconPlay}
          color={theme.color.white}
          size={theme.icon.size_small}
        />
        <GradientButton.Text>{i18n.messageFormatter('common.test')}</GradientButton.Text>
      </GradientButton>
    </FlowTestForm.Root>
  );
}

FlowTestForm.Root = styled.form`
  padding: ${su(2)};
  width: 320px;

  ${TextField.Root}:not(:last-of-type), ${SelectControllable.Root}:not(:last-of-type) {
    padding-bottom: ${su(1)};
  }

  ${TextField.Label}, ${SelectControllable.S.Label} {
    flex: 0 0 50%;
    margin-right: ${su(1)};
  }

  ${TextField.Input}, ${SelectControllable.S.Input} {
    flex: 0 0 50%;
    min-width: 0;
  }

  ${GradientButton.S.Root} {
    margin-top: ${su(2)};
  }
`;

FlowTestForm.Paragraph = styled.p`
  color: ${theme.color.text_light};
  padding: 0;
  margin: 0;
  padding-bottom: ${su(2)};
  font-size: ${theme.fontSize.small};
`;
