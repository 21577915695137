import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { mergeProps } from 'react-aria';

import { mergeRefs } from '../../../../lib/mergeRefs';

import { useHover } from '../../../../hooks/useHover';
import { useDraggableButton } from '../card/useDraggableButton';

import { theme } from '../../../../theme/theme';
import { isDarkMode } from '../../../../theme/classes/darkMode';
import { publicUrl } from '../../../../theme/functions/publicUrl';

import { makeConnectorPath } from './makeConnectorPath';
import { connectorTypesMap, connectorTypeColorMap } from './connectorTypes';
import { executionStateType } from '../card/executionStateType';

const width = 22;
const maxHeight = 20;
const height = 8;

const expandedPath = makeConnectorPath({
  width: width,
  height: height,
  maxHeight: maxHeight,
  offset: 0,
});

const collapsedPath = makeConnectorPath({
  width: width,
  height: 0,
  maxHeight: maxHeight,
  offset: 0,
});

const expandedStrokePath = makeConnectorPath({
  width: width,
  height: height,
  maxHeight: maxHeight,
  offset: 1, // this is the -2px on the selected border in AdvancedFlowCard
});

const collapsedStrokePath = makeConnectorPath({
  width: width,
  height: 0,
  maxHeight: maxHeight,
  offset: 1,
});

ConnectorBase.propTypes = {
  dragHandleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  circleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  style: PropTypes.object,
  connectorType: PropTypes.string.isRequired,
  connectorTypeColorOverride: PropTypes.string,
  executionState: PropTypes.object,
  /**
   * Option to override the connector done state. Normally it's considered done on card start for
   * inputs and card end for outputs. Since 'all' type cards have multiple inputs which run at
   * different times they need to be able to be set as done individually. Only added support for
   * in connectors for now since we don't have multiple out connectors that run at different times.
   */
  isConnectorDone: PropTypes.bool,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  isSiblingActive: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDisabledStyle: PropTypes.string,
  isVisibleOverride: PropTypes.bool,
  isDraggingAndCanConnect: PropTypes.bool,
  isDragOverAndCanConnect: PropTypes.bool,
};

export function ConnectorBase(props) {
  const isVisible =
    (props.isHovered === true && props.isDisabled !== true) ||
    props.isSelected ||
    props.isDragging ||
    props.isVisibleOverride ||
    props.isSiblingActive ||
    props.isDraggingAndCanConnect;

  const showExpandedPath = props.isActive || isVisible;
  const baseD = showExpandedPath ? expandedPath : collapsedPath;
  const strokeD = showExpandedPath ? expandedStrokePath : collapsedStrokePath;

  const pathTransition = { duration: 0.2, ease: 'easeInOut' };
  const pathAnimate = { d: baseD };
  const strokeAnimate = { d: strokeD };
  const connectorColorType = props.connectorTypeColorOverride ?? props.connectorType;

  const buttonRef = useRef();
  const hover = useHover({});
  const button = useDraggableButton(
    {
      elementType: 'circle',
      excludeFromTabOrder: true,
    },
    buttonRef
  );

  return (
    <ConnectorBase.Root style={props.style}>
      <ConnectorBase.ShadowCircle data-is-visible={isVisible} data-is-active={props.isActive} />
      <ConnectorBase.SVGBase
        data-is-selected={props.isSelected}
        data-is-visible={isVisible}
        data-is-active={props.isActive}
        data-is-dragover-and-can-connect={props.isDragOverAndCanConnect}
      >
        <ConnectorBase.Path transition={pathTransition} animate={pathAnimate} />
        <ConnectorBase.StrokePath transition={pathTransition} animate={strokeAnimate} />

        <ConnectorBase.Circle
          style={{
            '--active-circle-color': connectorTypeColorMap[connectorColorType]?.default,
          }}
          cx={width / 2}
          cy={2}
          r={3}
          data-is-visible={isVisible}
          data-is-active={props.isActive}
          data-is-handle-hovered={hover.isHovered}
          data-is-handle-pressed={button.isPressed}
          data-is-disabled={props.isDisabled}
          data-is-disabled-style={props.isDisabledStyle}
          data-connector-type={props.connectorType}
          data-is-connector-done={props.isConnectorDone ?? true}
          data-execution-state-type={props.executionState.type}
        />

        <circle ref={props.circleRef} cx={width / 2} cy={2} r={3} fill="transparent" />
      </ConnectorBase.SVGBase>

      {props.dragHandleRef && (
        <ConnectorBase.Handle
          {...mergeProps(button.buttonProps, hover.hoverProps)}
          ref={mergeRefs([props.dragHandleRef, buttonRef])}
          data-connector-type={props.connectorType}
          data-is-disabled={props.isDisabled}
        />
      )}

      {props.children}
    </ConnectorBase.Root>
  );
}

ConnectorBase.Root = styled.div`
  position: relative;
  // background: red;
`;

ConnectorBase.ShadowCircle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translateX(-50%) translateY(-75%) scale(0);
  z-index: -1;
  opacity: 0;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: opacity, transform;
  border-radius: 50%;
  box-shadow: ${theme.boxShadow.default};
  clip-path: inset(2px -100% -100% -100%);

  &[data-is-active='true'],
  &[data-is-visible='true'] {
    opacity: 1;
    transform: translateX(-50%) translateY(-25%) scale(1);
  }
`;

ConnectorBase.Path = styled(motion.path)`
  transition: stroke ${theme.duration.fast} ${theme.curve.fastIn};
  fill: var(--flowcard-background);
  stroke-width: 2;
`;

ConnectorBase.StrokePath = styled(motion.path)`
  fill: none;
  stroke-width: 2;
  stroke: ${theme.color.selection_border};
  stroke-opacity: 0;
  transition-property: stroke, stroke-opacity;
  // transition-duration is set lower because the border from AdvancedFlowCard is visibile under
  // this stroke. This causes two opacity colors to overlap causing a darker color. So we decrease
  // theme.duration.fast by 50ms to reduce this darker effect.
  transition-duration: calc(${theme.duration.fast} - 50ms);
  transition-timing-function: ${theme.curve.fastIn};
  transition-delay: 0ms;
`;

ConnectorBase.Circle = styled.circle`
  fill: ${theme.color.mono_200};
  opacity: 0;

  transform: scale(0) translateY(-200%);
  transform-origin: 50% 50%;
  transform-box: fill-box;

  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: opacity, transform, fill;

  &[data-is-visible='true'] {
    opacity: 1;
    transform: scale(1);
  }

  &[data-is-active='true'] {
    opacity: 1;
    transform: scale(1);

    &[data-execution-state-type=${executionStateType.idle}] {
      fill: var(--active-circle-color);
    }
  }

  &[data-is-handle-hovered='true'] {
    fill: ${theme.color.mono_400};
  }

  &[data-is-handle-pressed='true'] {
    fill: var(--active-circle-color);
  }

  &[data-is-disabled='true'][data-is-disabled-style='unreachable'] {
    fill: ${theme.flowcard.disabled_background_color};
  }

  &:not([data-is-disabled='true'][data-is-disabled-style='unreachable']) {
    &[data-connector-type=${connectorTypesMap.In}] {
      &[data-execution-state-type=${executionStateType.prestart}] {
        fill: ${theme.flowcard.disabled_background_color};
      }

      &[data-execution-state-type=${executionStateType.start}],
      &[data-execution-state-type=${executionStateType.true}],
      &[data-execution-state-type=${executionStateType.false}],
      &[data-execution-state-type=${executionStateType.error}],
      &[data-execution-state-type=${executionStateType.end}] {
        &[data-is-active='true'][data-is-connector-done='true'] {
          fill: var(--active-circle-color);
        }
      }
    }

    &[data-connector-type=${connectorTypesMap.Out}] {
      &[data-execution-state-type=${executionStateType.start}],
      &[data-execution-state-type=${executionStateType.prestart}] {
        fill: ${theme.flowcard.disabled_background_color};
      }

      &[data-execution-state-type=${executionStateType.end}] {
        fill: var(--active-circle-color);
      }
    }

    &[data-connector-type=${connectorTypesMap.True}] {
      &[data-execution-state-type=${executionStateType.start}],
      &[data-execution-state-type=${executionStateType.prestart}] {
        fill: ${theme.flowcard.disabled_background_color};
      }

      &[data-execution-state-type=${executionStateType.true}] {
        fill: var(--active-circle-color);
      }
    }

    &[data-connector-type=${connectorTypesMap.False}] {
      &[data-execution-state-type=${executionStateType.start}],
      &[data-execution-state-type=${executionStateType.prestart}] {
        fill: ${theme.flowcard.disabled_background_color};
      }

      &[data-execution-state-type=${executionStateType.false}] {
        fill: var(--active-circle-color);
      }
    }

    &[data-connector-type=${connectorTypesMap.Error}] {
      &[data-execution-state-type=${executionStateType.start}],
      &[data-execution-state-type=${executionStateType.prestart}] {
        fill: ${theme.flowcard.disabled_background_color};
      }

      &[data-execution-state-type=${executionStateType.error}] {
        fill: var(--active-circle-color);
      }
    }
  }
`;

ConnectorBase.SVGBase = styled.svg`
  // SVG needs a width and height because else it will default to 300px, 150px. This also sizes
  // the parent.
  width: ${width}px;
  height: ${maxHeight}px;

  z-index: 1;
  overflow: visible;

  &[data-is-selected='true'] {
    ${ConnectorBase.StrokePath} {
      transition-duration: ${theme.duration.fast};
      transition-delay: 0ms;
      stroke-opacity: 1;
    }
  }

  &[data-is-dragover-and-can-connect='true'][data-is-visible='true'],
  &[data-is-dragover-and-can-connect='true'][data-is-active='true'] {
    ${ConnectorBase.StrokePath} {
      stroke: ${theme.flowcard.dragover_border};
      stroke-opacity: 1;
    }
  }
`;

ConnectorBase.Handle = styled.div`
  position: absolute;

  top: -6px;
  left: 50%;
  transform: translateX(-50%);

  width: 24px;
  height: 24px;
  border-radius: 50%;
  outline: 0;
  z-index: 10;

  // background-color: rgba(255, 128, 0, 0.5);

  &[data-connector-type=${connectorTypesMap.In}] {
    cursor: image-set(
          url(${publicUrl('/img/cursor/crosshair-disabled.png')}) 1x,
          url(${publicUrl('/img/cursor/crosshair-disabled@2x.png')}) 2x
        )
        8 8,
      auto;

    ${isDarkMode} {
      cursor: image-set(
            url(${publicUrl('/img/cursor/darkmode/crosshair-disabled.png')}) 1x,
            url(${publicUrl('/img/cursor/darkmode/crosshair-disabled@2x.png')}) 2x
          )
          8 8,
        auto;
    }
  }

  &[data-connector-type=${connectorTypesMap.Out}] {
    cursor: image-set(
          url(${publicUrl('/img/cursor/crosshair-true.png')}) 1x,
          url(${publicUrl('/img/cursor/crosshair-true@2x.png')}) 2x
        )
        8 8,
      auto;

    ${isDarkMode} {
      cursor: image-set(
            url(${publicUrl('/img/cursor/darkmode/crosshair-true.png')}) 1x,
            url(${publicUrl('/img/cursor/darkmode/crosshair-true@2x.png')}) 2x
          )
          8 8,
        auto;
    }
  }

  &[data-connector-type=${connectorTypesMap.True}] {
    cursor: image-set(
          url(${publicUrl('/img/cursor/crosshair-true.png')}) 1x,
          url(${publicUrl('/img/cursor/crosshair-true@2x.png')}) 2x
        )
        8 8,
      auto;

    ${isDarkMode} {
      cursor: image-set(
            url(${publicUrl('/img/cursor/darkmode/crosshair-true.png')}) 1x,
            url(${publicUrl('/img/cursor/darkmode/crosshair-true@2x.png')}) 2x
          )
          8 8,
        auto;
    }
  }

  &[data-connector-type=${connectorTypesMap.False}] {
    cursor: image-set(
          url(${publicUrl('/img/cursor/crosshair-false.png')}) 1x,
          url(${publicUrl('/img/cursor/crosshair-false@2x.png')}) 2x
        )
        8 8,
      auto;

    ${isDarkMode} {
      cursor: image-set(
            url(${publicUrl('/img/cursor/darkmode/crosshair-false.png')}) 1x,
            url(${publicUrl('/img/cursor/darkmode/crosshair-false@2x.png')}) 2x
          )
          8 8,
        auto;
    }
  }

  &[data-connector-type=${connectorTypesMap.Error}] {
    cursor: image-set(
          url(${publicUrl('/img/cursor/crosshair-error.png')}) 1x,
          url(${publicUrl('/img/cursor/crosshair-error@2x.png')}) 2x
        )
        8 8,
      auto;

    ${isDarkMode} {
      cursor: image-set(
            url(${publicUrl('/img/cursor/darkmode/crosshair-error.png')}) 1x,
            url(${publicUrl('/img/cursor/darkmode/crosshair-error@2x.png')}) 2x
          )
          8 8,
        auto;
    }
  }

  &[data-is-disabled='true'] {
    cursor: unset;
  }
`;
