import { proxy as palette } from "../color.palette";
import { proxy as functional } from "../color.functional";

const color = {...palette, ...functional};

/**
 * Input
 */
export const input = {
  search_background: color.mono_020,
};
