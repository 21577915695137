import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, usePresence } from 'framer-motion';

import { useEffectEvent } from '../../hooks/useEffectEvent';

AnimationRemain.propTypes = {
  /** Mounted condition */
  condition: PropTypes.bool.isRequired,
  /** Delay value in ms **/
  delay: PropTypes.number,
  /** Render func **/
  children: PropTypes.func.isRequired,
};

export function AnimationRemain(props) {
  return (
    <AnimatePresence>
      {props.condition && <Remain delay={props.delay}>{props.children}</Remain>}
    </AnimatePresence>
  );
}

function Remain(props) {
  const [isPresent, safeToRemove] = usePresence();

  const remove = useEffectEvent(() => {
    safeToRemove?.();
  });

  useEffect(() => {
    if (isPresent === false) {
      setTimeout(remove, props.delay);
    }
  }, [isPresent, remove, props.delay]);

  return props.children({
    isExiting: !isPresent,
    duration: `${props.delay}ms`,
  });
}
