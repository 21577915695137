import { forwardRef } from 'react';
import styled from '@emotion/styled';

import { mergeProps } from 'react-aria';
import { mergeRefs } from '../../../lib/mergeRefs';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { useApi } from '../../../store/HomeyStore';
import { useHover } from '../../../hooks/useHover';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useTokenValueContext } from '../TokenValueContext';
import { useTooltipTriggerCustom } from '../../../components/common/tooltip/useTooltipTriggerCustom';
import { useTokenHoverContext } from '../TokenHoverContext';
import { useTooltipTriggerStateAlwaysWarmUp } from '../../../components/common/tooltip/useTooltipTriggerStateAlwaysWarmUp';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Tooltip } from '../../../components/common/tooltip/Tooltip';
import { Icon } from '../../../components/common/Icon';

import { iconBoolean } from '../../../theme/icons/logic/boolean';
import { iconNumber } from '../../../theme/icons/logic/number';
import { iconText } from '../../../theme/icons/logic/text';
import { iconImage } from '../../../theme/icons/logic/image';
import { iconWarning } from '../../../theme/icons/interface/warning/warning';
import { iconCloseCircle } from '../../../theme/icons/interface/close-circle';
import { iconQuestionMarkSmall } from '../../../theme/icons/interface/questionmark-small';

export const tokenTypes = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  image: 'image',
  error: 'error',
  unavailable: 'unavailable',
};

export const iconMap = {
  [tokenTypes.string]: iconText,
  [tokenTypes.number]: iconNumber,
  [tokenTypes.boolean]: iconBoolean,
  [tokenTypes.image]: iconImage,
  [tokenTypes.error]: iconWarning,
  [tokenTypes.unavailable]: iconQuestionMarkSmall,
};

export const FlowToken = forwardRef(({ label, ...rest }, forwardedRef) => {
  const { i18n } = useI18n();

  const { api } = useApi();

  // Only works when there is a parent provider and props.tokenKey is defined.
  const tokenHoverContext = useTokenHoverContext();
  const tokenType = rest.token?.type ?? rest.tokenType ?? tokenTypes.unavailable;

  if (label == null) {
    label = i18n.messageFormatter('common.unavailable');
  }

  // Add to some hoverContext.
  const hover = useHover({
    onHoverStart(event) {
      const split = rest.tokenKey?.split('::');

      // Is AdvancedFlow token key.
      if (split?.length === 3) {
        const nodeId = split[1];
        tokenHoverContext?.onHoverStart(event, {
          nodeId,
          tokeyKey: rest.tokenKey,
        });
      }
    },
    onHoverEnd(event) {
      const split = rest.tokenKey?.split('::');

      // Is AdvancedFlow token key.
      if (split?.length === 3) {
        const nodeId = split[1];
        tokenHoverContext?.onHoverEnd(event, {
          nodeId,
          tokeyKey: rest.tokenKey,
        });
      }
    },
  });

  let displayValue = null;

  const { tokens } = useTokenValueContext();
  const value = tokens?.[rest.tokenKey] ?? tokens?.[rest.tokenValueKey];
  // const { tokenNameOverrides, renamedTokens, setRenamedTokens } =
  //   useAdvancedFlowTokenNameOverridesContext();

  // if (tokenNameOverrides[rest.tokenKey] != null) {
  //   label = tokenNameOverrides[rest.tokenKey].title;
  // }

  // if (renamedTokens[rest.tokenKey] != null) {
  //   label = renamedTokens[rest.tokenKey];
  // }

  switch (tokenType) {
    case tokenTypes.boolean:
      displayValue = value != null ? i18n.messageFormatter(`common.${value}`) : null;
      break;
    case tokenTypes.string:
    case tokenTypes.number:
    case tokenTypes.error:
      displayValue = value != null ? String(value) : null;
      break;
    case tokenTypes.image: {
      const src = `${api?.images._baseUrl}${value?.url}?${String(value?.lastUpdated)}`;
      displayValue = <img alt="token" src={src} />;
      break;
    }

    default:
      break;
  }

  return (
    <Tooltip
      triggerHook={useTooltipTriggerCustom}
      triggerStateHook={useTooltipTriggerStateAlwaysWarmUp}
      offset={20}
      delay={300}
      anchorPointer={true}
      placement="top"
      isDisabled={value == null || rest.isValueTooltipDisabled}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <FlowToken.Root
            {...mergeProps(triggerProps, hover.hoverProps, rest)}
            ref={mergeRefs([triggerRef, forwardedRef])}
            className={rest.className}
            style={rest.style}
            // Dont show title when tooltip is enabled this is too much popovers
            title={value == null ? FlowToken.getTitle(rest.token) : undefined}
            data-token-type={tokenType}
            data-is-hoverable={rest.isHoverable ?? true}
            data-has-remove-icon={rest.showRemoveIcon}
          >
            <FlowToken.IconWrapper>
              <Icon display="inline-block" url={iconMap[tokenType]} size={theme.icon.size_small} />
            </FlowToken.IconWrapper>

            <FlowToken.NameWrapper>
              <FlowToken.Name style={{ '--flow-token-name-length': label.length }}>
                {label}
                {rest.children}
              </FlowToken.Name>
            </FlowToken.NameWrapper>

            {rest.showRemoveIcon && (
              <FlowToken.RemoveIcon
                color={theme.color.white}
                size={theme.icon.size_small}
                url={iconCloseCircle}
              />
            )}
          </FlowToken.Root>
        );
      }}
    >
      <FlowToken.Tooltip>
        <FlowToken.Value>
          {displayValue != null ? displayValue : JSON.stringify(value)}
        </FlowToken.Value>
      </FlowToken.Tooltip>
    </Tooltip>
  );
});

FlowToken.getTitle = (token) => {
  if (token == null) return null;

  const ownerName = ResourceUtils.getOwnerName(token);

  if (ownerName != null) {
    return `${ownerName} - ${token.title ?? '?'}`;
  }

  return `${token.title ?? '?'}`;
};

FlowToken.Root = styled.span`
  --flow-token-background: var(--flow-token-background-default);
  --flow-token-max-width-default: 140px;
  --flow-token-max-width-animation: var(
    --flow-token-max-width,
    var(--flow-token-max-width-default)
  );

  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  max-width: var(--flow-token-max-width, 140px);
  height: 20px;
  padding: 2px;
  overflow: hidden;
  vertical-align: -3px;
  background: var(--flow-token-background);
  color: ${theme.color.white};
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  font-weight: ${theme.fontWeight.medium};
  cursor: pointer;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: background-color;

  &[data-has-remove-icon='true'] {
    --flow-token-max-width-animation: calc(
      var(--flow-token-max-width, var(--flow-token-max-width-default)) - ${theme.icon.size_small}
    );
  }

  &[data-is-hoverable='true']:hover {
    --flow-token-background: var(--flow-token-background-hover);
  }

  &[data-is-hoverable='true']:hover:active {
    --flow-token-background: var(--flow-token-background-active);
  }

  &[data-is-hoverable='false'] {
    cursor: unset;
  }

  --flow-token-background-default: ${theme.color.warning};
  --flow-token-background-hover: ${theme.color.warning};
  --flow-token-background-active: ${theme.color.warning};

  &[data-token-type=${tokenTypes.string}] {
    --flow-token-background-default: ${theme.color.logic_string};
    --flow-token-background-hover: ${theme.color.logic_string_hover};
    --flow-token-background-active: ${theme.color.logic_string_active};
  }

  &[data-token-type=${tokenTypes.number}] {
    --flow-token-background-default: ${theme.color.logic_number};
    --flow-token-background-hover: ${theme.color.logic_number_hover};
    --flow-token-background-active: ${theme.color.logic_number_active};
  }

  &[data-token-type=${tokenTypes.boolean}] {
    --flow-token-background-default: ${theme.color.logic_boolean};
    --flow-token-background-hover: ${theme.color.logic_boolean_hover};
    --flow-token-background-active: ${theme.color.logic_boolean_active};
  }

  &[data-token-type=${tokenTypes.image}] {
    --flow-token-background-default: ${theme.color.logic_image};
    --flow-token-background-hover: ${theme.color.logic_image_hover};
    --flow-token-background-active: ${theme.color.logic_image_active};
  }

  &[data-token-type=${tokenTypes.error}] {
    --flow-token-background-default: ${theme.color.logic_error};
    --flow-token-background-hover: ${theme.color.logic_error_hover};
    --flow-token-background-active: ${theme.color.logic_error_active};
  }

  &[data-token-type=${tokenTypes.unavailable}] {
    background: var(--flow-token-background)
      linear-gradient(
        45deg,
        ${theme.color.mono_o_10} 25%,
        transparent 25%,
        transparent 50%,
        ${theme.color.mono_o_10} 50%,
        ${theme.color.mono_o_10} 75%,
        transparent 75%,
        transparent 100%
      );
    background-size: 20px 20px;
    --flow-token-background-default: ${theme.color.mono_300};
    --flow-token-background-hover: ${theme.color.mono_200};
    --flow-token-background-active: ${theme.color.mono_400};
  }
`;

FlowToken.IconWrapper = styled.span`
  display: inline-flex;
  position: relative;
  // Disabled for now causes icons to be on top of the sticky footer for normal Flows when
  // scrollable. Not sure why @Harwin added this.
  // z-index: 20;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.color.white};

  ${Icon.S.Root} {
    background: var(--flow-token-background);
  }

  ${FlowToken.Root}[data-token-type='error'] & {
    ${Icon.S.Root} {
      transform: scale(0.7);
    }
  }
`;

FlowToken.NameWrapper = styled.div`
  position: relative;
  margin: -2px -2px -2px -4px;
  padding: 2px 8px 2px 4px;
  overflow: hidden;
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    black 6px,
    black calc(100% - 8px),
    transparent 100%
  );
`;

FlowToken.Name = styled.div`
  --flow-token-name-speed-in: calc(var(--flow-token-name-length, 30) * 60ms);
  --flow-token-name-speed-out: calc(var(--flow-token-name-length, 30) * 30ms);
  font-size: ${theme.fontSize.small};
  line-height: 16px;
  white-space: nowrap;
  width: min-content;
  transition: transform var(--flow-token-name-speed-out) ${theme.curve.fastInOut};

  ${FlowToken.Root}:hover & {
    transform: translateX(min(0px, calc(-100% + (var(--flow-token-max-width-animation) - 30px))));
    transition: transform var(--flow-token-name-speed-in) ${theme.curve.fastInOut} 0.5s;
  }
`;

FlowToken.RemoveIcon = styled(Icon)``;

FlowToken.Tooltip = styled.div`
  padding: ${su(1)};
  max-width: 340px;
  min-width: 120px;
`;

FlowToken.Value = styled.div`
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.color.body};
  padding: ${su(1)};
  white-space: pre-wrap;
  word-break: break-all;

  img {
    width: 200px;
    height: auto;
  }
`;
