import React from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Tooltip } from '../common/tooltip/Tooltip';
import { Icon } from '../common/Icon';

import { iconInfo } from '../../theme/icons/interface/info';

export function Hint(props) {
  return (
    <Tooltip
      offset={10}
      instant={true}
      anchorPointer={true}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <Hint.IconWrapper>
            <Icon
              {...triggerProps}
              ref={triggerRef}
              size={theme.icon.size_small}
              url={iconInfo}
              color={theme.color.icon_light}
            />
          </Hint.IconWrapper>
        );
      }}
    >
      <Hint.Root>{props.hint}</Hint.Root>
    </Tooltip>
  );
}

Hint.Root = styled.div`
  max-width: 320px;
  padding: 10px;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
`;

Hint.IconWrapper = styled.div`
  vertical-align: text-top;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: ${su(1)};

  ${Icon.S.Root} {
    cursor: pointer;
  }
`;
