import { useEffect } from 'react';
import { useCurrentProps } from './useCurrentProps';

export function useResizeObserver({ ref, onResize }) {
  const currentProps = useCurrentProps({ onResize });

  useEffect(() => {
    const containerElement = ref?.current;

    if (containerElement != null) {
      const resizeObserver = new ResizeObserver((entries) => {
        // called on initial render
        // called on element removed which we dont really need here and might cause
        // unexpected results
        if (entries[0].contentRect.width !== 0 && entries[0].contentRect.height !== 0) {
          currentProps.onResize();
        }
      });

      resizeObserver.observe(containerElement);

      return function () {
        resizeObserver.unobserve(containerElement);
      };
    }
  }, [ref, currentProps]);
}
