import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { dragTypes } from '../dnd/dragTypes';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useEffectEvent } from '../../hooks/useEffectEvent';

export function useMoodDragDrop({ moodId, isDisabled, onDrop, onRequestSwap, render }) {
  const renderFunction = useEffectEvent((...args) => {
    return render(...args);
  });

  const [{ isDragging }, dragRef, preview] = useDrag(() => {
    return {
      type: dragTypes.MOOD,
      item: () => {
        return {
          type: dragTypes.MOOD,
          id: moodId,
          render: renderFunction,
        };
      },
      canDrag(monitor) {
        return isDisabled !== true;
      },
      end(dragItem, dragMonitor) {},
      collect(dragMonitor) {
        return {
          isDragging: dragMonitor.isDragging(),
        };
      },
    };
  }, [moodId, isDisabled]);

  const [, dropRef] = useDrop(() => {
    return {
      accept: [dragTypes.MOOD],
      canDrop(item, monitor) {
        return isDisabled !== true;
      },
      hover(item) {
        if (item.id !== moodId) {
          onRequestSwap?.({ firstId: moodId, secondId: item.id });
        }
      },
      drop(item, monitor) {
        onDrop?.(item);
      },
    };
  }, [moodId, isDisabled, onRequestSwap, onDrop]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return {
    dragRef,
    dropRef,
    isDragging,
  };
}
