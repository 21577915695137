import { useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { ToastManager } from '../../../ToastManager';
import { wait } from '../../../lib/wait';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { DialogShiftLock } from '../../../components/overlay/DialogShiftLock';
import { IconButton } from '../../../components/buttons/IconButton';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { SpinnerFade } from '../../../components/common/SpinnerFade';
import { Clipboard } from '../../../components/common/Clipboard';
import { RadioGroup } from '../../../components/forms/radio/RadioGroup';
import { DescriptionRadio } from '../../../components/forms/radio/DescriptionRadio';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { Icon } from '../../../components/common/Icon';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconAccount } from '../../../theme/icons/interface/account';

export function FamilySettingsDialogCreateInvite(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  const formId = 'invite-form';

  const [result, setResult] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {},
    mode: 'onSubmit',
  });

  function handleClosePress() {
    props.onCloseRequest();
  }

  function onSubmit(data) {
    setIsCreating(true);
    const minDuration = wait(500);
    console.log(data);

    api.users
      .createUser({
        user: {
          role: data.role,
        },
      })
      .then(async (result) => {
        await minDuration;
        setResult(result);
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
        ToastManager.handleError(error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  }

  return (
    <DialogShiftLock layoutId={result == null ? '1' : '2'} onClose={props.onCloseRequest}>
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color={theme.color.orange_0_20}>
          <Icon size="32px" url={iconAccount} color={theme.color.orange} />
        </RoundIconWrapper>

        <S.Content>
          <S.Title>{i18n.messageFormatter('settings.system.usersCreateInviteDialogTitle')}</S.Title>

          {result == null && (
            <S.Subtitle>
              {i18n.messageFormatter('settings.system.usersCreateInviteDialogSubtitle')}
            </S.Subtitle>
          )}

          {result != null && (
            <S.Subtitle>
              {i18n.messageFormatter('settings.system.usersCreateInviteDialogShareSubtitle')}
            </S.Subtitle>
          )}

          {result == null && (
            <S.Form id={formId} onSubmit={handleSubmit(onSubmit)}>
              <RadioGroup name="role" aria-label="Role" defaultValue="user" register={register}>
                <DescriptionRadio
                  title={i18n.messageFormatter('common.manager')}
                  description={i18n.messageFormatter('settings.system.usersRoleOptionHintManager')}
                  value="manager"
                />
                <DescriptionRadio
                  title={i18n.messageFormatter('common.user')}
                  description={i18n.messageFormatter('settings.system.usersRoleOptionHintUser')}
                  value="user"
                />
                <DescriptionRadio
                  title={i18n.messageFormatter('common.guest')}
                  description={i18n.messageFormatter('settings.system.usersRoleOptionHintGuest')}
                  value="guest"
                />
              </RadioGroup>
            </S.Form>
          )}

          {result != null && (
            <Clipboard value={result.inviteUrl} autoFocus={true} message="Copied" />
          )}
        </S.Content>

        <S.Actions>
          <GradientButton
            type="submit"
            form={formId}
            isDisabled={isCreating === true}
            styleFlat={true}
            styleColor="blue"
            onPress={() => {
              if (result != null) {
                props.onCloseRequest();
              }
            }}
          >
            <SpinnerFade
              isActive={isCreating}
              size={theme.icon.size_small}
              color={theme.color.white}
            />

            <GradientButton.Text>
              {isCreating
                ? `${i18n.messageFormatter('common.creating')}...`
                : result == null
                ? i18n.messageFormatter('common.create')
                : i18n.messageFormatter('common.done')}
            </GradientButton.Text>
          </GradientButton>
        </S.Actions>
      </S.Root>
    </DialogShiftLock>
  );
}

function S() {}
FamilySettingsDialogCreateInvite.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: auto;
  padding: ${su(4)} 0 ${su(1)};
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Content = styled.div`
  padding: 0 20px 0;

  ${Clipboard.S.Root} {
    margin: 20px 0;
    width: 100%;
  }
`;

S.Actions = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
  padding: 0 ${su(1)} 0;
`;

S.Title = styled.h3`
  text-align: center;
  padding: ${su(3)} 0 ${su(1)};
`;

S.Subtitle = styled.div`
  text-align: center;
  color: ${theme.color.text_light};
`;

S.Form = styled.form`
  padding-top: 20px;
`;
