import { __DEV__ } from '../../lib/__dev__';

import { BaseApiStore } from '../BaseApiStore';
import { FlowFolderStore } from '../flow-folders/FlowFolderStore';

import { FlowUtils } from './FlowUtils';

export class FlowStore extends BaseApiStore {
  static key = 'flows';
  static store = this.createStore(this.key);
  static flowCacheId = 'HomeyAPI.ManagerFlow.Flow';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      byFolderId: null,
      byCardOwnerUri: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:flows');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerFlow = state.api.flow;

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 600));
      const flowsPromise = await managerFlow.getFlows();

      const [, data] = await Promise.all([waitPromise, flowsPromise]);

      const result = Object.values(data).reduce(
        (accumulator, flowInstance) => {
          const folderId = FlowUtils.getFolderId(flowInstance);

          accumulator.data[flowInstance.id] = flowInstance;
          const flowReference = { ...flowInstance };

          accumulator.byId[flowInstance.id] = flowReference;
          accumulator.byFolderId[folderId] = this.assignToPrevFolder(
            accumulator.byFolderId[folderId],
            flowReference
          );

          const cardOwnerUris = FlowUtils.getOwnerUrisSetFromCards(flowInstance);
          flowInstance.cardOwnerUris = cardOwnerUris;
          flowReference.cardOwnerUris = cardOwnerUris;

          for (const cardOwnerUri of cardOwnerUris.values()) {
            accumulator.byCardOwnerUri[cardOwnerUri] =
              accumulator.byCardOwnerUri[cardOwnerUri] ?? {};
            accumulator.byCardOwnerUri[cardOwnerUri][flowInstance.id] = flowReference;
          }

          return accumulator;
        },
        {
          data: {},
          byId: {},
          byFolderId: {},
          byCardOwnerUri: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        loading: false,
        data: result.data,
        byId: result.byId,
        byFolderId: result.byFolderId,
        byCardOwnerUri: result.byCardOwnerUri,
        manager: managerFlow,
      });

      managerFlow.addListener('flow.create', this.handleCreate);
      managerFlow.addListener('flow.update', this.handleUpdate);
      managerFlow.addListener('flow.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:flows');
    const { api } = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (api) {
      const { flow } = api;
      flow.removeListener('flow.create', this.handleCreate);
      flow.removeListener('flow.update', this.handleUpdate);
      flow.removeListener('flow.delete', this.handleDelete);
    }
  }

  static handleCreate = (createdFlow) => {
    __DEV__ && console.info(`create:flow:${createdFlow.id}`);
    const state = this.get();
    // read sync from the cache
    const flowInstance = state.api.flow._caches[this.flowCacheId].getOne({
      id: createdFlow.id,
    });

    if (flowInstance == null) return;

    const folderId = FlowUtils.getFolderId(flowInstance);
    const flowReference = { ...flowInstance };

    const cardOwnerUris = FlowUtils.getOwnerUrisSetFromCards(flowInstance);
    flowInstance.cardOwnerUris = cardOwnerUris;
    flowReference.cardOwnerUris = cardOwnerUris;

    const byCardOwnerUri = {
      ...state.byCardOwnerUri,
    };

    for (const cardOwnerUri of cardOwnerUris.values()) {
      byCardOwnerUri[cardOwnerUri] = byCardOwnerUri[cardOwnerUri] ?? {};
      byCardOwnerUri[cardOwnerUri][flowInstance.id] = flowReference;
    }

    this.set({
      data: {
        ...state.data,
        [flowInstance.id]: flowInstance,
      },
      byId: {
        ...state.byId,
        [flowInstance.id]: flowReference,
      },
      byFolderId: {
        ...state.byFolderId,
        [folderId]: {
          ...state.byFolderId[folderId],
          [flowInstance.id]: flowReference,
        },
      },
      byCardOwnerUri,
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedFlow) => {
    __DEV__ && console.info(`update:flow:${updatedFlow.id}`);
    this.updateQueue[updatedFlow.id] = updatedFlow;

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedFlows = this.updateQueue;

        this.updateQueue = {};
        this.processBatchTimeout = null;

        const folderUpdates = {};

        const nextById = {
          ...state.byId,
        };
        const nextByCardOwnerUri = {
          ...state.byCardOwnerUri,
        };

        let updateCount = 0;

        for (const [id, queuedFlow] of Object.entries(queuedFlows)) {
          const flowInstance = this.getInstanceFromCache(queuedFlow);

          if (state.data == null || state.byId == null) {
            __DEV__ && console.info(`update:flow:missing-data`);
            continue;
          }

          if (flowInstance == null || state.data[id] == null || state.byId[id] == null) {
            __DEV__ && console.info(`update:flow:unknown:${id}`);
            continue;
          }

          const flowReference = { ...flowInstance };

          if (
            state.byId[queuedFlow.id].folder !== flowReference.folder ||
            state.byId[queuedFlow.id].name !== flowReference.name ||
            state.byId[queuedFlow.id].enabled !== flowReference.enabled ||
            state.byId[queuedFlow.id].broken !== flowReference.broken
          ) {
            folderUpdates[queuedFlow.id] = flowReference;
          }

          nextById[id] = flowReference;

          for (const cardOwnerUri of state.byId[queuedFlow.id].cardOwnerUris?.values()) {
            if (nextByCardOwnerUri[cardOwnerUri]?.[flowInstance.id] != null) {
              nextByCardOwnerUri[cardOwnerUri] = {
                ...nextByCardOwnerUri[cardOwnerUri],
              };
              delete nextByCardOwnerUri[cardOwnerUri][flowInstance.id];

              if (Object.values(nextByCardOwnerUri[cardOwnerUri]).length === 0) {
                delete nextByCardOwnerUri[cardOwnerUri];
              }
            }
          }

          const cardOwnerUris = FlowUtils.getOwnerUrisSetFromCards(flowInstance);
          flowInstance.cardOwnerUris = cardOwnerUris;
          flowReference.cardOwnerUris = cardOwnerUris;

          for (const cardOwnerUri of cardOwnerUris.values()) {
            nextByCardOwnerUri[cardOwnerUri] = nextByCardOwnerUri[cardOwnerUri] ?? {};
            nextByCardOwnerUri[cardOwnerUri][flowInstance.id] = flowReference;
          }

          updateCount++;
        }

        __DEV__ && console.info(`update:flow:batch:count:${updateCount}`);

        let nextByFolderId = state.byFolderId;

        if (Object.keys(folderUpdates).length > 0) {
          nextByFolderId = Object.values(folderUpdates).reduce(
            (accumulator, updatedFlow) => {
              const prevFlow = state.byId[updatedFlow.id];
              const prevFolder = FlowUtils.getFolderId(prevFlow);
              const nextFolder = FlowUtils.getFolderId(updatedFlow);

              const { [prevFlow.id]: value, ...rest } = accumulator[prevFolder];

              accumulator[prevFolder] = {
                ...rest,
              };

              if (Object.keys(accumulator[prevFolder]).length === 0) {
                delete accumulator[prevFolder];
              }

              accumulator[nextFolder] = this.assignToPrevFolder(
                accumulator[nextFolder],
                updatedFlow
              );

              return accumulator;
            },
            {
              ...state.byFolderId,
            }
          );
        }

        updateCount > 0 &&
          this.set({
            byId: nextById,
            byFolderId: nextByFolderId,
            byCardOwnerUri: nextByCardOwnerUri,
          });
      }, 200);
    }
  };

  static handleDelete = (deletedFlow) => {
    __DEV__ && console.info(`delete:flow:${deletedFlow.id}`);
    const state = this.get();
    const flowInstance = state.data[deletedFlow.id];
    const flow = state.byId[deletedFlow.id];

    if (flowInstance == null || flow == null) return;

    const folderId = FlowUtils.getFolderId(flow);

    const nextData = { ...state.data };
    const nextById = { ...state.byId };
    const nextByFolderId = { ...state.byFolderId };

    delete nextData[flow.id];
    delete nextById[flow.id];

    const { [flow.id]: value, ...rest } = nextByFolderId[folderId];

    nextByFolderId[folderId] = {
      ...rest,
    };

    if (Object.keys(nextByFolderId[folderId]).length === 0) {
      delete nextByFolderId[folderId];
    }

    const nextByCardOwnerUri = {
      ...state.byCardOwnerUri,
    };

    for (const cardOwnerUri of flow.cardOwnerUris.values()) {
      if (nextByCardOwnerUri[cardOwnerUri]?.[flowInstance.id] != null) {
        nextByCardOwnerUri[cardOwnerUri] = {
          ...nextByCardOwnerUri[cardOwnerUri],
        };
        delete nextByCardOwnerUri[cardOwnerUri][flowInstance.id];

        if (Object.values(nextByCardOwnerUri[cardOwnerUri]).length === 0) {
          delete nextByCardOwnerUri[cardOwnerUri];
        }
      }
    }

    this.set({
      data: nextData,
      byId: nextById,
      byFolderId: nextByFolderId,
      byCardOwnerUri: nextByCardOwnerUri,
    });
  };

  static getInstanceFromCache(flow) {
    const api = this.getApi();
    const cache = api.flow._caches[this.flowCacheId];

    return cache.getOne({
      id: cache._guessCacheId(flow),
    });
  }

  static assignToPrevFolder(prev, flow) {
    return {
      ...prev,
      [flow.id]: flow,
    };
  }

  static produceState({ flowId, prop, value }) {
    const state = this.get();
    const prevFlow = state.byId[flowId];
    const folderId = FlowUtils.getFolderId(prevFlow);

    const nextById = {
      ...state.byId,
      [flowId]: {
        ...prevFlow,
        [prop]: value,
      },
    };

    const nextByFolderId = {
      ...state.byFolderId,
      [folderId]: {
        ...state.byFolderId[folderId],
        [flowId]: {
          ...prevFlow,
          [prop]: value,
        },
      },
    };

    return {
      byId: nextById,
      byFolderId: nextByFolderId,
    };
  }

  static setFlowFolder({ flowId, folderId }) {
    const state = this.get();
    const prevById = state.byId;
    const prevByFolderId = state.byFolderId;
    const prevFlow = prevById[flowId];
    const nextFolderId = folderId === FlowFolderStore.rootFolderId ? null : folderId;

    if (nextFolderId === FlowUtils.getFolderId(prevFlow)) return Promise.resolve();

    const nextFlow = {
      ...prevFlow,
      folder: nextFolderId,
    };

    const nextById = {
      ...state.byId,
      [nextFlow.id]: nextFlow,
    };

    const nextByFolderId = {
      ...state.byFolderId,
      [FlowUtils.getFolderId(nextFlow)]: {
        ...state.byFolderId[FlowUtils.getFolderId(nextFlow)],
        [nextFlow.id]: nextFlow,
      },
    };

    delete nextByFolderId[FlowUtils.getFolderId(prevFlow)][prevFlow.id];

    if (Object.keys(nextByFolderId[FlowUtils.getFolderId(prevFlow)]).length === 0) {
      delete nextByFolderId[FlowUtils.getFolderId(prevFlow)];
    }

    this.set({
      byId: nextById,
      byFolderId: nextByFolderId,
    });

    return state.manager
      .updateFlow({
        id: nextFlow.id,
        flow: {
          folder: nextFlow.folder,
        },
      })
      .catch((error) => {
        this.set({
          byId: {
            ...prevById,
          },
          byFolderId: {
            ...prevByFolderId,
          },
        });

        throw error;
      });
  }

  static saveFlowName({ flowId, name }) {
    const state = this.get();
    const prevFlow = state.byId[flowId];
    const prevName = prevFlow.name;

    const nextState = this.produceState({
      flowId,
      prop: 'name',
      value: name,
    });

    this.set({
      ...nextState,
    });

    return state.manager
      .updateFlow({
        id: flowId,
        flow: {
          name,
        },
      })
      .catch((error) => {
        const nextState = this.produceState({
          flowId,
          prop: 'name',
          value: prevName,
        });

        this.set(nextState);

        throw error;
      });
  }

  static toggleEnabled({ flowId }) {
    const state = this.get();
    const prevFlow = state.byId[flowId];

    const prevEnabled = prevFlow.enabled;
    const nextEnabled = !prevEnabled;

    const nextState = this.produceState({
      flowId: flowId,
      prop: 'enabled',
      value: nextEnabled,
    });

    this.set(nextState);

    return state.manager
      .updateFlow({
        id: flowId,
        flow: {
          enabled: nextEnabled,
        },
      })
      .catch((error) => {
        const nextState = this.produceState({
          flowId: flowId,
          prop: 'enabled',
          value: prevEnabled,
        });

        this.set(nextState);

        throw error;
      });
  }
}
