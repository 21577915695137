import React, { useMemo } from 'react';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useFlowCardExplorerContext } from './FlowCardExplorerContext';

export function ColumnTypeManager(props) {
  const { i18n } = useI18n();

  const { sections } = useManagerSections({
    i18n,
    entry: props.entry,
    columnProps: props.columnProps,
  });

  return (
    <props.listBoxComponent {...props.listBoxProps} aria-label="Manager column" items={sections} />
  );
}

function useManagerSections({ i18n, entry, columnProps }) {
  const { cardType, cardFilter, onCardSelect } = columnProps;
  const cardTypePlural = `${cardType}s`;
  const flowCardExplorerContext = useFlowCardExplorerContext();
  const cards = flowCardExplorerContext[cardTypePlural];

  const manager = useMemo(() => {
    return cards.byManagerUri[entry.key] ?? {};
  }, [cards.byManagerUri, entry.key]);

  const sections = useMemo(() => {
    const sections = [];

    const managerDeviceItems = [];
    const managerDeviceEntries = Object.entries(manager.devices ?? {});

    for (const [deviceId, device] of managerDeviceEntries) {
      managerDeviceItems.push({
        key: deviceId,
        type: 'device',
        textValue: device.name,
        context: {},
      });
    }
    managerDeviceItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    if (managerDeviceItems.length > 0) {
      sections.push({
        key: 'devices',
        title: i18n.messageFormatter('flow.cardDialog.devices'),
        children: managerDeviceItems,
      });
    }

    const cardItems = [];
    const cardsByKeyEntries = Object.entries(manager.cardsByKey ?? {});

    for (const [cardKey, card] of cardsByKeyEntries) {
      if (cardFilter(card) === false) continue;

      const cardItem = {
        key: cardKey,
        type: 'card',
        textValue: card.title ?? card.titleFormatted,
        action() {
          onCardSelect(cardKey, card);
        },
        context: {
          card,
        },
      };

      // If the card is popular, add it to the start of the array.
      if (card.highlight === true) {
        cardItems.unshift(cardItem);
        continue;
      }

      cardItems.push(cardItem);
    }

    if (cardItems.length > 0) {
      sections.push({
        key: 'cards',
        title: i18n.messageFormatter('flow.cardDialog.cards'),
        children: cardItems,
      });
    }

    return sections;
  }, [i18n, cardFilter, onCardSelect, manager]);

  return { sections };
}
