import { useRef } from 'react';

import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { RouteManager } from '../../../RouteManager';

import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useVariablesAttach } from '../../../store/logic/useLogic';

import { showForLarge } from '../../../theme/classes/responsive';
import { Overlay } from '../../../components/overlay/Overlay';
import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { VariablesList } from './VariablesList';
import { PremiumLocked } from '../../../components/premium/PremiumLocked';
import { MonoButton } from '../../../components/buttons/MonoButton';

import { iconLogic } from '../../../theme/icons/system/logic';

export function VariablesButton(props) {
  useVariablesAttach();

  const { api } = useApi();
  const { i18n } = useI18n();
  const triggerRef = useRef();
  const overlayTriggerState = useOverlayTriggerState({
    defaultOpen: props.defaultOpen,
  });

  const overlayTrigger = useOverlayTrigger({ type: 'listbox' }, overlayTriggerState, triggerRef);
  const isPremiumLocked =
    api?.tier !== 'premium' && api?.homey.platform !== undefined && api?.homey.platform !== 'local';

  return (
    <>
      <MonoButton
        {...overlayTrigger.triggerProps}
        ref={triggerRef}
        styleCompact
        styleColor={isPremiumLocked ? 'disabled' : 'default'}
        styleIconColor={isPremiumLocked ? 'disabled' : 'default'}
        title={i18n.messageFormatter('logic.variablesTitle')}
        onPress={() => {
          if (isPremiumLocked) {
            RouteManager.toPremiumRequiredDialog();
            return;
          }

          overlayTriggerState.toggle();
        }}
      >
        <MonoButton.Icon url={iconLogic} />
        <MonoButton.Text className={showForLarge}>
          {i18n.messageFormatter('logic.variablesTitle')}
        </MonoButton.Text>

        {isPremiumLocked && <PremiumLocked size={PremiumLocked.size.small} zIndex={2} />}
      </MonoButton>

      <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={triggerRef}
              overlayProps={overlayTrigger.overlayProps}
              overlayTriggerState={overlayTriggerState}
              offset={10}
              animationRemainProps={animationRemainProps}
            >
              <VariablesList
                onCloseRequest={() => {
                  overlayTriggerState.close();
                }}
              />
            </Overlay>
          );
        }}
      </AnimationRemain>
    </>
  );
}
