import React from 'react';
import styled from '@emotion/styled';

import { mergeProps, useFocusRing } from 'react-aria';
import { useOptionContext } from '../../list-box/ListBox';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

export function Option(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  return (
    <Option.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={props.style}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      {item.textValue}
    </Option.Root>
  );
}

Option.Root = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 40px;
  padding: ${su(1, 2)};
  outline: 0;
  cursor: pointer;

  &[data-is-selected='true'] {
    font-weight: ${theme.fontWeight.medium};
  }

  &[data-is-disabled='true'] {
  }

  &[data-is-focused='true'] {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-pressed='true'] {
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }
`;
