import { useRef } from 'react';
import styled from '@emotion/styled';
import { useFocusRing, useTag, useButton } from 'react-aria';

import { theme } from '../../../theme/theme';

import { Icon } from '../Icon';

import { iconCloseCircle } from '../../../theme/icons/interface/close-circle';

export function Tag(props) {
  const ref = useRef(null);
  const tag = useTag(props, props.state, ref);
  const focusRing = useFocusRing({ within: true });

  return (
    <S.Tag
      ref={ref}
      {...tag.rowProps}
      {...focusRing.focusProps}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <S.Text {...tag.gridCellProps}>{props.item.rendered}</S.Text>

      {tag.allowsRemoving && <RemoveButton {...tag.removeButtonProps} />}
    </S.Tag>
  );
}

function S() {}
Tag.S = S;

S.Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: max-content;
  height: 24px;
  border-radius: 12px;
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
  font-size: ${theme.fontSize.small};
  overflow: hidden;
  padding: 0 4px 0 8px;
  outline: none;
`;

S.Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
`;

function RemoveButton(props) {
  const ref = useRef(null);
  const button = useButton(props, ref);

  return (
    <button {...button.buttonProps} ref={ref}>
      <Icon url={iconCloseCircle} color={theme.color.white} size="16px" />
    </button>
  );
}
