import React, { useState, useRef, useEffect, useCallback, forwardRef } from 'react';

import TreeViewContext from './TreeViewContext';

function arrayDiff(arr1, arr2) {
  if (arr1.length !== arr2.length) return true;

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return true;
  }

  return false;
}

export const TreeView = forwardRef(function (props, ref) {
  const [tabbable, setTabbable] = useState(null);

  const nodeMap = useRef({});
  const visibleNodes = useRef([]);
  const lastSelectedNode = useRef(null);

  const isExpanded = useCallback(
    (id) => (props.expanded ? props.expanded.has(id) : false),
    [props.expanded]
  );

  const isSelected = useCallback(
    (id) =>
      Array.isArray(props.selected) ? props.selected.indexOf(id) !== -1 : props.selected === id,
    [props.selected]
  );

  const isTabbable = (id) => tabbable === id;
  const getParent = useCallback((id) => nodeMap.current[id]?.parent ?? null, []);

  function toggleExpansion(event, value) {
    let newExpanded;
    if (props.expanded.has(value)) {
      newExpanded = new Set([...props.expanded].filter((id) => id !== value));
      setTabbable((oldTabbable) => {
        const map = nodeMap.current[oldTabbable];
        if (oldTabbable && (map && map.parent ? map.parent.id : null) === value) {
          return value;
        }
        return oldTabbable;
      });
    } else {
      newExpanded = new Set([value, ...props.expanded]);
    }

    if (props.onNodeToggle) {
      props.onNodeToggle(event, newExpanded);
    }
  }

  function expandAllSiblings(event, id) {
    const map = nodeMap.current[id];
    const parent = nodeMap.current[map.parent];

    let diff;
    if (parent) {
      diff = parent.children.filter((child) => !isExpanded(child));
    } else {
      const topLevelNodes = nodeMap.current[-1].children;
      diff = topLevelNodes.filter((node) => !isExpanded(node));
    }
    const newExpanded = new Set([...props.expanded, ...diff]);

    if (diff.length > 0) {
      if (props.onNodeToggle) {
        props.onNodeToggle(event, newExpanded);
      }
    }
  }

  function handleSingleSelect(event, value, nodeData) {
    if (props.onNodeSelect) {
      props.onNodeSelect(event, value, nodeData);
    }
  }

  function selectNode(event, id, nodeData) {
    if (id) {
      handleSingleSelect(event, id, nodeData);
      lastSelectedNode.current = id;

      return true;
    }
    return false;
  }

  const addNodeToNodeMap = useCallback((id, childrenIds) => {
    const currentMap = nodeMap.current[id];
    nodeMap.current[id] = { ...currentMap, children: childrenIds, id };

    childrenIds.forEach((childId) => {
      const currentChildMap = nodeMap.current[childId];
      nodeMap.current[childId] = {
        ...currentChildMap,
        parent: id,
        id: childId,
      };
    });
  }, []);

  const getNodesToRemove = useCallback((id) => {
    const map = nodeMap.current[id];
    const nodes = [];
    if (map) {
      nodes.push(id);
      if (map.children) {
        nodes.concat(map.children);
        map.children.forEach((node) => {
          nodes.concat(getNodesToRemove(node));
        });
      }
    }
    return nodes;
  }, []);

  const removeNodeFromNodeMap = useCallback(
    (id) => {
      const nodes = getNodesToRemove(id);
      const newMap = { ...nodeMap.current };

      nodes.forEach((node) => {
        const map = newMap[node];
        if (map) {
          if (map.parent) {
            const parentMap = newMap[map.parent];
            if (parentMap && parentMap.children) {
              const parentChildren = parentMap.children.filter((c) => c !== node);
              newMap[map.parent] = { ...parentMap, children: parentChildren };
            }
          }

          delete newMap[node];
        }
      });
      nodeMap.current = newMap;
    },
    [getNodesToRemove]
  );

  const prevChildIds = useRef([]);
  const [childrenCalculated, setChildrenCalculated] = useState(false);
  useEffect(() => {
    const childIds = [];

    React.Children.forEach(props.children, (child) => {
      if (React.isValidElement(child) && child.props.nodeId) {
        childIds.push(child.props.nodeId);
      }
    });
    if (arrayDiff(prevChildIds.current, childIds)) {
      nodeMap.current[-1] = { parent: null, children: childIds };

      childIds.forEach((id, index) => {
        if (index === 0) {
          setTabbable(id);
        }
      });
      visibleNodes.current = nodeMap.current[-1].children;
      prevChildIds.current = childIds;
      setChildrenCalculated(true);
    }
  }, [props.children]);

  useEffect(() => {
    const buildVisible = (nodes) => {
      let list = [];
      for (let i = 0; i < nodes.length; i += 1) {
        const item = nodes[i];
        list.push(item);
        const childs = nodeMap.current[item].children;
        if (isExpanded(item) && childs) {
          list = list.concat(buildVisible(childs));
        }
      }
      return list;
    };

    if (childrenCalculated) {
      visibleNodes.current = buildVisible(nodeMap.current[-1].children);
    }
  }, [props.expanded, childrenCalculated, isExpanded, props.children]);

  return (
    <TreeViewContext.Provider
      value={{
        expandAllSiblings,
        toggleExpansion,
        isExpanded,
        isSelected,
        onNodeDrop: props.onNodeDrop,
        selectNode,
        isTabbable,
        getParent,
        addNodeToNodeMap,
        removeNodeFromNodeMap,
      }}
    >
      <ul role="tree" className={props.className} style={props.style} ref={ref}>
        {props.children}
      </ul>
    </TreeViewContext.Provider>
  );
});
