import { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import { __DEV__ } from '../../../../lib/__dev__';
import { history } from '../../../../history';

import { SaveError, ValidationError } from '../../../../lib/InternalError';
import { ToastManager } from '../../../../ToastManager';
import { RouteManager } from '../../../../RouteManager';
import { BaseApiStore } from '../../../../store/BaseApiStore';
import { AdvancedFlowStore } from '../../../../store/advanced-flow/AdvancedFlowStore';

import { useLocale } from 'react-aria';
import { useI18n } from '../../../../hooks/useI18nFormatters';
import { useCurrentProps } from '../../../../hooks/useCurrentProps';
import { useAttach } from '../../../../store/useAttach';

import { DraggableNode } from '../card/DraggableNode';

import { testAdvancedFlow } from './testAdvancedFlow';
import { deriveConnections } from './deriveConnections';

import { templateFlow } from './templateFlow';
import { HomeyStore } from '../../../../store/HomeyStore';

/** @typedef {import('../../../../store/BaseApiStore').BaseApiStoreState} BaseApiStoreState */

/**
 * @typedef {'trigger' | 'condition' | 'action' | 'delay' | 'start'} AdvancedFlowCardType
 */

/**
 * @typedef {Object} AdvancedFlowCard
 * @property {AdvancedFlowCardType} type
 * @property {string} ownerUri
 * @property {string} id
 * @property {number} x
 * @property {number} y
 *
 * @property {?Object<string, any>} args
 * @property {?string} droptoken
 * @property {any} duration
 * @property {?boolean} inverted
 *
 * @property {?Array<string>} outputSuccess
 * @property {?Array<string>} outputTrue
 * @property {?Array<string>} outputFalse
 * @property {?Array<string>} outputError
 */

/**
 * @typedef {Object} AdvancedFlow
 * @property {string} id
 * @property {string} name
 * @property {Object<string, AdvancedFlowCard>} cards
 * @property {?string} folder
 * @property {?number} enabled
 * @property {?number} broken
 * @property {?number} triggerable
 */

/**
 * @typedef {Object} AdvancedFlowViewStoreState
 * @property {?AdvancedFlow} advancedFlow
 * @property {?AdvancedFlow} initialAdvancedFlow
 * @property {any} connections
 * @property {any} activeConnectors
 * @property {any} nodeParents
 * @property {any} nodeChildren
 * @property {any} nodeAncestors Used to determine the available tokens for a card.
 * @property {any} selected
 * @property {any} history
 * @property {any} historyIndex
 * @property {any} conditionTest
 * @property {any} conditionSave
 * @property {?string} testNodeId
 * @property {any} cardCursorData
 */

/**
 * @extends {BaseApiStore}
 */
export class AdvancedFlowViewStore extends BaseApiStore {
  static key = 'advancedFlowView';

  static conditionTest = {
    initial: 'initial',
    testing: 'testing',
    tested: 'tested',
    stopped: 'stopped',
  };

  static conditionSave = {
    initial: 'initial',
    saving: 'saving',
    saved: 'saved',
  };

  /**
   * @override
   * @returns {AdvancedFlowViewStoreState} initialState
   */
  static createInitialState() {
    return {
      advancedFlow: null,
      initialAdvancedFlow: null,
      connections: null,
      activeConnectors: new Set(),
      nodeParents: null,
      nodeChildren: null,
      nodeAncestors: null,
      selected: new Map(),
      history: [],
      historyIndex: 0,
      conditionTest: this.conditionTest.initial,
      conditionSave: this.conditionSave.initial,
      testNodeId: null,
      hasStartCard: false,
      cardCursorData: null,
    };
  }

  // Order matters here

  /** @type {import('zustand').StoreApi<AdvancedFlowViewStoreState & BaseApiStoreState>} */
  static store = this.createStore(this.key, {
    onBeforeSaveListeners: new Set(),
    onAfterSaveListeners: new Set(),
    onErrorSaveListeners: new Set(),
  });

  /**
   * @override
   */
  static onDetached() {
    this.set(this.createInitialState());
  }

  // Seems middleware is a better name here.
  static registerOnBeforeSave(listenerFn) {
    this.get().onBeforeSaveListeners.add(listenerFn);
  }

  static unregisterOnBeforeSave(listenerFn) {
    this.get().onBeforeSaveListeners.delete(listenerFn);
  }

  static registerOnAfterSave(listenerFn) {
    this.get().onAfterSaveListeners.add(listenerFn);
  }

  static unregisterOnAfterSave(listenerFn) {
    this.get().onAfterSaveListeners.delete(listenerFn);
  }

  static registerOnErrorSave(listenerFn) {
    this.get().onErrorSaveListeners.add(listenerFn);
  }

  static unregisterOnErrorSave(listenerFn) {
    this.get().onErrorSaveListeners.delete(listenerFn);
  }

  /**
   * @template {(current: AdvancedFlow) => AdvancedFlow} FunctionArgument
   * @template {{ advancedFlow: AdvancedFlow }} ObjectArgument
   * @param {FunctionArgument | ObjectArgument} args
   * @param {Object} options
   * @param {boolean} options.save
   */
  static updateAdvancedFlow(args, options) {
    // const nextOptions = {
    //   ...options,
    // };

    this.set((state) => {
      const nextAdvancedFlow =
        typeof args === 'function' ? args(state.advancedFlow) : args.advancedFlow;

      if (nextAdvancedFlow === state.advancedFlow) {
        return;
      }

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.deriveConnections({ advancedFlow: nextAdvancedFlow }),
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  /**
   * @param {Object} options
   * @param {boolean} options.route
   * @param {boolean} options.handleError
   */
  static async saveAdvancedFlow(options) {
    const nextOptions = {
      route: false,
      handleError: true,
      ...options,
    };

    const state = this.get();

    if (state.conditionSave === this.conditionSave.saving) {
      throw new SaveError(`Already saving`);
    }

    const nextAdvancedFlow = state.advancedFlow;

    const errorTypes = new Map();

    // Each listener returns one error or undefined or it throws an error.
    for (const listener of this.get().onBeforeSaveListeners) {
      try {
        const error = listener(nextAdvancedFlow);
        if (error instanceof Error) {
          errorTypes.set(error.constructor, error);
        }
      } catch (error) {
        errorTypes.set(error.constructor, error);
      }
    }

    // todo
    // invalid arguments but what if they are offscreen
    // eslint-disable-next-line no-unused-vars
    const notReportable = [];

    const firstFirstErrorTypes = [ValidationError.MissingCards, ValidationError.InvalidArguments];
    const hasFixFirstErrorTypes = firstFirstErrorTypes.some((value) => errorTypes.has(value));

    // Fix before we try to open save dialog for name.
    if (hasFixFirstErrorTypes === true) {
      errorTypes.forEach((error, errorType) => {
        if (firstFirstErrorTypes.includes(errorType)) {
          ToastManager.handleError(error);
        }
      });
      throw new SaveError(`Failed to save, ${[...errorTypes.values()].join(', ')}`);
    }

    // Ask for name if missing.
    if (errorTypes.has(ValidationError.MissingName)) {
      RouteManager.pushState({
        [RouteManager.dialogState.saveAdvancedFlowDialog]: true,
      });
      throw new SaveError(`Failed to save, ${[...errorTypes.values()].join(', ')}`);
    }

    // Other unknown errors.
    if (errorTypes.size > 0) {
      errorTypes.forEach((error, errorType) => {
        ToastManager.handleError(error);
      });
      throw new SaveError(`Failed to save, ${[...errorTypes.values()].join(', ')}`);
    }

    this.set({
      conditionSave: this.conditionSave.saving,
    });

    // Not sure if we want this here but should at least have states active for a min duration.
    // await new Promise((resolve) => setTimeout(resolve, 5000));

    let returnValue = null;

    try {
      if (nextAdvancedFlow.id === 'create') {
        const createdAdvancedFlow = await AdvancedFlowStore.createAdvancedFlow(
          {
            advancedFlow: {
              name: nextAdvancedFlow.name,
              cards: nextAdvancedFlow.cards,
              folder: nextAdvancedFlow.folder,
            },
          },
          {
            handleError: false,
          }
        );

        if (nextOptions.route === true) {
          RouteManager.toAdvancedFlow(createdAdvancedFlow.id, {
            skipUnsavedChangesCheck: true,
          });
        }

        returnValue = cloneDeep(createdAdvancedFlow);
      } else {
        // eslint-disable-next-line no-unused-vars
        const updatedAdvancedFlow = await AdvancedFlowStore.updateAdvancedFlow(
          {
            id: nextAdvancedFlow.id,
            advancedFlow: {
              cards: nextAdvancedFlow.cards,
            },
          },
          {
            handleError: false,
          }
        );

        returnValue = nextAdvancedFlow;
      }

      // Replace the initialAdvancedFlow so we can check unsaved changes again.
      this.set({
        conditionSave: this.conditionSave.saved,
        advancedFlow: returnValue,
        initialAdvancedFlow: returnValue,
      });

      for (const listener of this.get().onAfterSaveListeners) {
        listener(nextAdvancedFlow);
      }
    } catch (error) {
      this.set({
        conditionSave: this.conditionSave.initial,
      });

      for (const listener of this.get().onErrorSaveListeners) {
        listener(nextAdvancedFlow);
      }

      if (nextOptions.handleError === true) {
        ToastManager.handleError(error);
      } else {
        throw error;
      }
    }

    return returnValue;
  }

  static addCard({ cardData, shouldSelect = true, removeCardCursorData = true }) {
    const key = uuid();

    this.set((state) => {
      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
          [key]: {
            ...cardData,
          },
        },
      };

      return {
        cardCursorData: removeCardCursorData ? null : state.cardCursorData,
        advancedFlow: nextAdvancedFlow,
        ...this.deriveConnections({ advancedFlow: nextAdvancedFlow }),
        ...(shouldSelect
          ? {
              selected: new Map([[key, { id: key, type: DraggableNode.type }]]),
            }
          : null),
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static replaceCard({ nodeId, cardData, shouldSelect = true }) {
    this.set((state) => {
      // Only the connections stay the same so it's only possible to replace the same types.

      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
        },
      };

      delete nextAdvancedFlow.cards[nodeId].args;
      delete nextAdvancedFlow.cards[nodeId].droptoken;
      delete nextAdvancedFlow.cards[nodeId].duration;
      delete nextAdvancedFlow.cards[nodeId].inverted;

      nextAdvancedFlow.cards[nodeId] = {
        ...state.advancedFlow.cards[nodeId],
        ...cardData,
      };

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.deriveConnections({ advancedFlow: nextAdvancedFlow }),
        ...(shouldSelect
          ? {
              selected: new Map([
                [
                  nodeId,
                  {
                    id: nodeId,
                    type: DraggableNode.type,
                  },
                ],
              ]),
            }
          : null),
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static setCardCursorData({ cardInstance, cardData }) {
    this.set((state) => {
      return {
        cardCursorData: {
          cardInstance,
          cardData,
        },
      };
    });
  }

  static updateCardArg({ nodeId, argumentKey, value }) {
    this.set((state) => {
      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
          [nodeId]: {
            ...state.advancedFlow.cards[nodeId],
            args: {
              ...state.advancedFlow.cards[nodeId].args,
              [argumentKey]: value,
            },
          },
        },
      };

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static updateCardProperty({ nodeId, propertyKey, value }) {
    this.set((state) => {
      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
          [nodeId]: {
            ...state.advancedFlow.cards[nodeId],
            [propertyKey]: value,
          },
        },
      };

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static deriveConnections({ advancedFlow }) {
    return deriveConnections({ advancedFlow });
  }

  static createConnection({ fromNodeId, fromConnectorType, toNodeId, toConnectorType }) {
    const state = this.get();

    const fromCard = state.advancedFlow.cards[fromNodeId];
    // const toCard = state.advancedFlow.cards[toNodeId];

    const prevFromConnectors = fromCard[fromConnectorType] ?? [];
    // const prevToConnectors = toCard[toConnectorType] ?? [];

    const nextFromCard = {
      ...fromCard,
      [fromConnectorType]: Array.from(new Set([...prevFromConnectors, toNodeId])),
    };

    // const nextToCard = {
    //   ...toCard,
    //   [toConnectorType]: Array.from(new Set([...prevToConnectors, fromNodeId])),
    // };

    this.set((state) => {
      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
          [fromNodeId]: nextFromCard,
          // [toNodeId]: nextToCard,
        },
      };

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.deriveConnections({ advancedFlow: nextAdvancedFlow }),
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static createOrderedConnection({
    fromNodeId,
    fromConnectorType,
    toNodeId,
    toConnectorType,
    index,
  }) {
    const state = this.get();

    const fromCard = state.advancedFlow.cards[fromNodeId];
    const toCard = state.advancedFlow.cards[toNodeId];

    const prevFromConnectors = fromCard[fromConnectorType] ?? [];
    const prevToConnectors = toCard[toConnectorType] ?? [];

    const nextFromCard = {
      ...fromCard,
      [fromConnectorType]: Array.from(new Set([...prevFromConnectors, toNodeId])),
    };

    const nextToCard = {
      ...toCard,
      [toConnectorType]: Array.from(new Set([...prevToConnectors])),
    };

    const key = `${fromNodeId}::${fromConnectorType}`;
    const existingConnectorIndex = nextToCard[toConnectorType].indexOf(key);

    if (index !== existingConnectorIndex) {
      if (existingConnectorIndex !== -1) {
        // reordering an already present item
        nextToCard[toConnectorType][existingConnectorIndex] = 'removeme';
      }

      // on index is before
      // index + 1 is after
      nextToCard[toConnectorType].splice(index, 0, key);

      const removeIndex = nextToCard[toConnectorType].indexOf('removeme');

      if (removeIndex !== -1) {
        const removed = nextToCard[toConnectorType].splice(removeIndex, 1);
        console.log(removed);
      }
    }

    this.set((state) => {
      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
          [fromNodeId]: nextFromCard,
          [toNodeId]: nextToCard,
        },
      };

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.deriveConnections({ advancedFlow: nextAdvancedFlow }),
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static deleteConnection({ connection }) {
    const state = this.get();

    const fromNodeId = connection.fromNodeId;
    const fromConnectorType = connection.fromConnectorType;
    const fromCard = state.advancedFlow.cards[fromNodeId];

    const toNodeId = connection.toNodeId;
    const toConnectorType = connection.toConnectorType;
    const toCard = state.advancedFlow.cards[toNodeId];
    let nextToCard = toCard;

    const currentFromConnections = new Set([...(fromCard[fromConnectorType] ?? [])]);
    currentFromConnections.delete(connection.toNodeId);

    const nextFromConnections = [...currentFromConnections];

    const nextFromCard = {
      ...fromCard,
      [fromConnectorType]: nextFromConnections,
    };

    if (toCard[toConnectorType] != null) {
      const currentToConnections = new Set([...(toCard[toConnectorType] ?? [])]);

      currentToConnections.delete(`${connection.fromNodeId}::${connection.fromConnectorType}`);

      const nextToConnections = [...currentToConnections];

      nextToCard = {
        ...toCard,
        [toConnectorType]: nextToConnections,
      };

      if (nextToConnections.length === 0) {
        delete nextToCard[toConnectorType];
      }
    }

    this.set((state) => {
      const nextAdvancedFlow = {
        ...state.advancedFlow,
        cards: {
          ...state.advancedFlow.cards,
          [fromNodeId]: nextFromCard,
          [toNodeId]: nextToCard,
        },
      };

      return {
        advancedFlow: nextAdvancedFlow,
        ...this.deriveConnections({ advancedFlow: nextAdvancedFlow }),
        ...this.getNextHistory({ advancedFlow: nextAdvancedFlow }),
      };
    });
  }

  static testAdvancedFlow = testAdvancedFlow.bind(this);

  static getNextHistory({ advancedFlow }) {
    const state = this.get();
    const nextHistory = [...state.history.slice(0, state.historyIndex + 1), advancedFlow];

    return {
      history: nextHistory,
      historyIndex: nextHistory.length - 1,
    };
  }

  static undo() {
    const state = this.get();

    const advancedFlow = state.history[state.historyIndex - 1];
    if (!advancedFlow) return;

    const nextHistoryIndex = state.historyIndex - 1;

    this.set({
      advancedFlow: advancedFlow,
      ...AdvancedFlowViewStore.deriveConnections({ advancedFlow: advancedFlow }),
      historyIndex: nextHistoryIndex,
    });
  }

  static redo() {
    const state = this.get();

    const advancedFlow = state.history[state.historyIndex + 1];
    if (!advancedFlow) return;

    const nextHistoryIndex = state.historyIndex + 1;

    this.set({
      advancedFlow: advancedFlow,
      ...AdvancedFlowViewStore.deriveConnections({ advancedFlow: advancedFlow }),
      historyIndex: nextHistoryIndex,
    });
  }

  static getSelected = () => {
    return this.get().selected;
  };

  static setSelected = (selected) => {
    if (typeof selected === 'function') {
      this.set((prevState) => {
        return {
          selected: selected(prevState.selected),
        };
      });

      return;
    }

    this.set({
      selected,
    });
  };
}

function select(state) {
  return {
    advancedFlow: state.advancedFlow,
    connections: state.connections,
    activeConnectors: state.activeConnectors,
    nodeParents: state.nodeParents,
    nodeChildren: state.nodeChildren,
    nodeAncestors: state.nodeAncestors,
    conditionTest: state.conditionTest,
    conditionSave: state.conditionSave,
    testNodeId: state.testNodeId,
    cardCursorData: state.cardCursorData,
  };
}

AdvancedFlowViewStore.useSetAdvancedFlowView = function useSetAdvancedFlowdView({
  advancedFlowId,
  folderId,
  resetId,
}) {
  useAttach(AdvancedFlowViewStore, 'useSetAdvancedFlowView');
  useAttach(AdvancedFlowStore, 'useSetAdvancedFlowView');

  const { locale } = useLocale();
  const { i18n } = useI18n();

  const currentProps = useCurrentProps({
    messageFormatter: i18n.messageFormatter,
    locale,
  });

  useEffect(() => {
    function makeInitialStateFromAdvancedFlow(advancedFlow) {
      const initialAdvancedFlowClone = cloneDeep(advancedFlow);

      return {
        advancedFlow: initialAdvancedFlowClone,
        initialAdvancedFlow: initialAdvancedFlowClone,
        ...AdvancedFlowViewStore.deriveConnections({ advancedFlow: initialAdvancedFlowClone }),
        history: initialAdvancedFlowClone ? [initialAdvancedFlowClone] : [],
        historyIndex: 0,
        selected: new Map(),
        conditionTest: AdvancedFlowViewStore.conditionTest.initial,
        conditionSave: AdvancedFlowViewStore.conditionSave.initial,
        testNodeId: null,
        cardCursorData: null,
      };
    }

    if (advancedFlowId === 'create') {
      const params = new URLSearchParams(history.location.search);
      const id = params.get('id');
      let cards = {};

      if (id === 'template') {
        const apiVersion = HomeyStore.get().api?.apiVersion;

        const translated = templateFlow({
          messageFormatter: currentProps.messageFormatter,
          locale: currentProps.locale,
          apiVersion,
        });
        cards = translated.cards;
      }

      AdvancedFlowViewStore.set((state) => {
        const newAdvancedFlow = {
          id: 'create',
          name: '__create__',
          folder: folderId ?? null,
          cards: cards,
        };

        return makeInitialStateFromAdvancedFlow(newAdvancedFlow);
      });

      return;
    }

    function advancedFlowStoreListener({ advancedFlow }) {
      // console.log('advancedFlowStoreListener', advancedFlow);

      if (advancedFlow == null) {
        // If it disappears should we route away?
      }

      AdvancedFlowViewStore.set((state) => {
        // todo
        // might remove this to enable 2 way updates

        // now it only fills when its null for initialization
        // since const advancedFlow = state.byId?.[advancedFlowId] ?? null;
        // might be null

        if (state.advancedFlow != null) {
          const updates = {};

          if (advancedFlow != null) {
            // Disabled this for now because it causes the Flow to be marked as having changes
            // lighting up the save button. Since we only update cards in the editor we might
            // aswell ignore everything else.

            // This will become an issue in the future if we decide to add other editable properties
            // in the editor. If the name would be changed in the sidebar what would happen if the
            // user hit undo?

            // if (state.advancedFlow.name !== advancedFlow.name) {
            //   updates.name = advancedFlow.name;
            // }
            //
            // if (state.advancedFlow.enabled !== advancedFlow.enabled) {
            //   updates.enabled = advancedFlow.enabled;
            // }
            //
            // if (state.advancedFlow.folder !== advancedFlow.folder) {
            //   updates.folder = advancedFlow.folder;
            // }

            if (Object.keys(updates).length > 0) {
              return {
                advancedFlow: {
                  ...state.advancedFlow,
                  ...updates,
                },
              };
            }
          }

          // the AdvancedFlow is not present anymore
          // we handle this on delete actions now
          // if (advancedFlow == null) {
          //   RouteManager.toFlows();
          // }

          return state;
        }

        return makeInitialStateFromAdvancedFlow(advancedFlow);
      });
    }

    function advancedFlowStoreSelector(state) {
      return {
        advancedFlow: state.byId?.[advancedFlowId] ?? null,
      };
    }

    const state = AdvancedFlowStore.get();
    const advancedFlow = state.byId?.[advancedFlowId] ?? null;
    AdvancedFlowViewStore.set(makeInitialStateFromAdvancedFlow(advancedFlow));

    const advancedFlowStoreUnsubscribe = AdvancedFlowStore.store.subscribe(
      advancedFlowStoreSelector,
      advancedFlowStoreListener,
      { equalityFn: shallow }
    );

    const advancedFlowViewStoreUnsubscribe = AdvancedFlowViewStore.store.subscribe(
      (state) => ({ advancedFlow: state.advancedFlow }),
      ({ advancedFlow }) => {
        __DEV__ && console.log(`update:AdvancedFlowViewStore:${advancedFlow?.id}`, advancedFlow);
      },
      { equalityFn: shallow }
    );

    return function () {
      advancedFlowStoreUnsubscribe();
      advancedFlowViewStoreUnsubscribe();
    };
  }, [currentProps, advancedFlowId, folderId, resetId]);
};

AdvancedFlowViewStore.useAdvancedFlowView = function useAdvancedFlowView() {
  useAttach(AdvancedFlowViewStore, 'useAdvancedFlowView');
  useAttach(AdvancedFlowStore, 'useAdvancedFlowView');

  return AdvancedFlowViewStore.store(select, shallow);
};

AdvancedFlowViewStore.useIsSelected = function useIsSelected({ id }) {
  const selector = useCallback(
    (state) => {
      return state.selected?.has(id) ?? false;
    },
    [id]
  );

  return AdvancedFlowViewStore.store(selector);
};
