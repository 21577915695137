import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { enqueueTask } from '../../lib/enqueueTask';

import { useKeyboard } from 'react-aria';
import { useColumnViewContext } from './ColumnView';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { scrollbarDark } from '../../theme/elements/scrollbars';

import { ListBox } from '../list-box/ListBox';

export function Column(props) {
  const rightScrollToRef = useRef();
  const leftScrollToRef = useRef();

  const context = useColumnViewContext();

  const stateRef = useRef({});
  useEffect(() => {
    const index = props.index;
    context.register(index, {
      entry: props.entry,
      get state() {
        // Lazy getter because the stateRef.current property gets updated on render.
        return stateRef.current;
      },
      scrollLeftIntoView() {
        enqueueTask(() => {
          leftScrollToRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        });
      },
      scrollRightIntoView() {
        enqueueTask(() => {
          rightScrollToRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        });
      },
    });

    return function () {
      context.unregister(index);
    };
  }, [context, props.index, props.entry]);

  useEffect(() => {
    // Put in the next tick because scrollIntoView not working on Chromium.
    enqueueTask(() => {
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1307562&q=scrollIntoView&can=2
      // After disabling the autoFocus for the ListBox it works again. So it has something to do
      // with an item being focused and react-aria trying to scroll it into view. We let that
      // behavior occur since its only on the vertical axis and in the next tick we scroll the ref
      // into view so we can have our smooth scroll behavior.

      // I've linked the issue to because I'm not 100% sure it's the scroll into view from
      // react-aria or the actual focus event. It just made sense to me it was a race between two
      // scroll into views.
      rightScrollToRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    });
  }, []);

  function onSelectionChange(keys, listState) {
    const item = listState.collection.getItem(keys[0]);

    if (item != null) {
      if (item.value.action != null) {
        item.value.action({ item });
        return;
      }

      props.push(
        {
          type: item.value.type,
          key: item.key,
        },
        props.index
      );
    } else {
      props.slice(props.index);
    }
  }

  const Component = props.getColumnComponent({ type: props.entry.type });

  const keyboard = useKeyboard({
    onKeyDown(event) {
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          const prevColumn = context.columns.get(props.index - 1);

          if (prevColumn != null) {
            const { selectionManager } = prevColumn.state;

            // Focus the collection
            selectionManager.setFocused(true);
            // Focus the last focused item
            // Which makes no sense but should make clear what we want to happen.
            selectionManager.setFocusedKey(selectionManager.focusedKey);
            prevColumn.scrollLeftIntoView();
          }

          break;
        case 'ArrowRight':
          event.preventDefault();
          const nextColumn = context.columns.get(props.index + 1);
          const thisColumn = context.columns.get(props.index);

          // A next column is present so we either focus that column if the current
          // focused item is the parent of the next column or we open the current focused item
          // and replace the existing next column.
          if (nextColumn != null) {
            const { selectionManager } = nextColumn.state;

            // If the next column key is the current focused key.
            if (nextColumn.entry.key === thisColumn.state.selectionManager.focusedKey) {
              // Focus the collection
              selectionManager.setFocused(true);
              // Focus the last focused item
              // Which makes no sense but should make clear what we want to happen.
              selectionManager.setFocusedKey(selectionManager.focusedKey);

              nextColumn.scrollRightIntoView();
              break;
            }
          }

          // On a different focused item than next column or no next column.
          const focusedKey = thisColumn.state.selectionManager.focusedKey;
          const focusedItem = thisColumn.state.collection.getItem(focusedKey);

          if (focusedItem != null) {
            thisColumn.state.selectionManager.setSelectedKeys([focusedKey]);
          }

          break;
        default:
          event.continuePropagation();
          break;
      }
    },
  });

  return (
    <Column.Root>
      <Column.ScrollTo ref={leftScrollToRef} />
      <Component
        listBoxComponent={Column.ListBox}
        listBoxProps={{
          ...keyboard.keyboardProps,
          autoFocus: 'first',
          defaultSelectedKeys: props.next?.key != null ? [props.next?.key] : [],
          stateRef: stateRef,
          renderItem: props.renderItem,
          renderSection: props.renderSection,
          onSelectionChange: onSelectionChange,
        }}
        columnProps={props.columnProps}
        entry={props.entry}
      />
      <Column.ScrollTo ref={rightScrollToRef} />
    </Column.Root>
  );
}

Column.Root = styled.div`
  flex: 0 0 380px;
  display: flex;
`;

Column.ListBox = styled(ListBox)`
  ${scrollbarDark};
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: ${su(2)};
  overflow-y: auto;
`;

Column.ScrollTo = styled.div`
  border-right: 1px solid ${theme.color.line};
`;
