import arrow_up from '../images/button/arrow_up.svg';
import middle from '../images/button/middle.svg';
import arrow_down from '../images/button/arrow_down.svg';

export function getTernaryImage(capabilityId) {
  switch (capabilityId) {
    case 'up':
      return arrow_up;
    case 'idle':
      return middle;
    case 'down':
      return arrow_down;
    default:
      return middle;
  }
}
