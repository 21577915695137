import styled from '@emotion/styled';

import { theme } from '../../theme/theme';

import { DeviceIcon } from './DeviceIcon';

export function DeviceTileStatic(props) {
  return (
    <DeviceTileStatic.Root>
      <DeviceTileStatic.TopContainer>
        <DeviceIcon
          iconObj={props.iconObj}
          iconUrl={props.iconUrl}
          iconOverride={props.iconOverride}
          size={'40px'}
        />
        {props.indicator && (
          <DeviceTileStatic.Indicator>{props.indicator}</DeviceTileStatic.Indicator>
        )}
      </DeviceTileStatic.TopContainer>

      <DeviceTileStatic.BottomContainer>
        <DeviceTileStatic.Name>{props.name}</DeviceTileStatic.Name>
      </DeviceTileStatic.BottomContainer>
    </DeviceTileStatic.Root>
  );
}

DeviceTileStatic.Indicator = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

DeviceTileStatic.TopContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: space-between;
`;

DeviceTileStatic.BottomContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: flex-end;
  justify-content: flex-start;
  min-height: 48px;
  padding: 10px;
  border-radius: 0 0 12px 12px;
`;

DeviceTileStatic.Name = styled.div`
  max-height: 36px;
  overflow: hidden;
  font-size: ${theme.fontSize.small};
  line-height: 1.286;
  text-align: left;
  text-overflow: ellipsis;
  word-break: normal;
  white-space: pre-line;
  hyphens: auto;
`;

DeviceTileStatic.Root = styled.div`
  --tile-size: ${theme.device.size_medium};

  display: flex;
  position: relative;
  appearance: none;
  flex-direction: column;
  align-items: normal;
  min-width: var(--tile-size);
  min-height: var(--tile-size);
  padding: 10px;
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  background-color: ${theme.color.component_on};
  box-shadow: ${theme.boxShadow.default};
`;
