//import { lazy } from 'react';

import { HomePage as HomePageSync } from './home/HomePage';
import { DevicesPage as DevicesPageSync } from './devices/DevicesPage';
import { FlowsPage as FlowsPageSync } from './flows/FlowsPage';
import { InsightsPage as InsightsPageSync } from './insights/InsightsPage';
import { ScriptPage as ScriptPageSync } from './script/ScriptPage';
import { LogicPage as LogicPageSync } from './logic/LogicPage';
import { SettingsPage as SettingsPageSync } from './settings/SettingsPage';

const HomePagePreload = () => {}; //import('./home/HomePage');
export const HomePage = HomePageSync; //lazy(HomePagePreload);
HomePage.preload = HomePagePreload;

const DevicesPagePreload = () => {}; //import('./devices/DevicesPage');
export const DevicesPage = DevicesPageSync; //lazy(DevicesPagePreload);
DevicesPage.preload = DevicesPagePreload;

const FlowsPagePreload = () => {}; //import('./flows/FlowsPage');
export const FlowsPage = FlowsPageSync; //lazy(FlowsPagePreload);
FlowsPage.preload = FlowsPagePreload;

const InsightsPagePreload = () => {}; //import('./insights/InsightsPage');
export const InsightsPage = InsightsPageSync; //lazy(InsightsPagePreload);
InsightsPage.preload = InsightsPagePreload;

const ScriptPagePreload = () => {}; //import('./script/ScriptPage');
export const ScriptPage = ScriptPageSync; //lazy(ScriptPagePreload);
ScriptPage.preload = ScriptPagePreload;

const LogicPagePreload = () => {}; //import('./logic/LogicPage');
export const LogicPage = LogicPageSync; //lazy(LogicPagePreload);
LogicPage.preload = LogicPagePreload;

const SettingsPagePreload = () => {}; //import('./settings/SettingsPage');
export const SettingsPage = SettingsPageSync; //lazy(SettingsPagePreload);
SettingsPage.preload = SettingsPagePreload;
