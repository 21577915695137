import { useCallback } from 'react';

import { RouteManager } from '../../RouteManager';

import { UserMeStore } from '../../store/user-me/UserMeStore';

import { useEffectEvent } from '../../hooks/useEffectEvent';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useDevicesById } from '../../store/devices/useDevices';
import { useUserMe } from '../../store/user-me/useUserMe';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

import { typography } from '../../components/primitives/typography';

import { Device } from '../../components/device/Device';
import { ContentLoader } from '../../components/content-loader/ContentLoader';
import { DeviceGrid } from '../../components/device/DeviceGrid';

export function FavoriteDevices({ selectedDeviceId, isSiblingLoading }) {
  const { i18n } = useI18n();
  const devices = useDevicesById();
  const userMe = useUserMe();

  const [tileSize] = useLocalStorageState(1, 'deviceTileSize');

  const handleControlsClick = useCallback(
    (event, device) => {
      if (selectedDeviceId === device.id) {
        RouteManager.toHome();
        return;
      }

      RouteManager.toFavoriteDevice(device.id);
    },
    [selectedDeviceId]
  );

  const handleRequestSwap = useEffectEvent(({ firstId, secondId }) => {
    UserMeStore.swapFavoriteDevices({
      firstId,
      secondId,
    });
  });

  const handleDrop = useEffectEvent(() => {
    UserMeStore.saveFavoriteDevices();
  });

  if (devices.loading || userMe.loading || isSiblingLoading) {
    return (
      <DeviceGrid.Root>
        {Array.from({ length: 3 }).map((_, index) => {
          return (
            <DeviceGrid.Item key={index} data-animated={true}>
              <ContentLoader.Device tileSize={tileSize} />
            </DeviceGrid.Item>
          );
        })}
      </DeviceGrid.Root>
    );
  }

  if (devices.error || userMe.error) {
    const message = devices.error?.message ?? userMe.error?.message ?? '';
    return <typography.body1>{message}</typography.body1>;
  }

  let result = null;

  if (devices.byId && userMe.favoriteDevices) {
    result = userMe.favoriteDevices.reduce((accumulator, favoriteDeviceId, index) => {
      const device = devices.byId[favoriteDeviceId];

      device != null &&
        accumulator.push(
          <DeviceGrid.Item
            key={favoriteDeviceId}
            style={{
              '--animation-delay': `${10 * index}ms`,
            }}
          >
            <Device
              deviceId={favoriteDeviceId}
              isSelected={favoriteDeviceId === selectedDeviceId}
              onControlsClick={handleControlsClick}
              onRequestSwap={handleRequestSwap}
              onDrop={handleDrop}
              tileSize={tileSize}
            />
          </DeviceGrid.Item>
        );

      return accumulator;
    }, []);
  }

  if (result?.length > 0) {
    return <DeviceGrid.Root>{result}</DeviceGrid.Root>;
  }

  return <p>{i18n.messageFormatter(`home.noFavoriteDevices`)}</p>;
}
