import { useRef } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing, useTableColumnHeader } from 'react-aria';

import { useHeaderColumnContext } from './SettingsTable';

export function SettingsTableColumnHeader(props) {
  const { column, state } = useHeaderColumnContext();
  const ref = useRef();
  const tableColumnHeader = useTableColumnHeader({ node: column }, state, ref);
  const focusRing = useFocusRing();

  return (
    <S.TableColumnHeader
      {...mergeProps(tableColumnHeader.columnHeaderProps, focusRing.focusProps)}
      colSpan={column.colspan}
      ref={ref}
      data-type={column.key}
    >
      {column.rendered}
    </S.TableColumnHeader>
  );
}

function S() {}
SettingsTableColumnHeader.S = S;

S.TableColumnHeader = styled.th`
  outline: none;
  text-align: left;
  cursor: default;

  &[data-type='name'] {
    width: 44%;
  }
`;
