import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Loader } from '@googlemaps/js-api-loader';
import debounce from 'lodash.debounce';

import { SCOPES } from '../../../lib/scopes';
import { ToastManager } from '../../../ToastManager';
import { locationSettingsStore } from './LocationSettingsStore';

import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

import { LayoutPortal } from '../../../Layout';
import { Switch } from '../../../components/forms/Switch';

import { LocationSettingsComboBox } from './LocationSettingsComboBox';

import { iconLocation } from '../../../theme/icons-v2/system/location/location';

export function LocationSettings() {
  const { i18n } = useI18n();

  const ref = useRef(null);
  const stateRef = useRef(null);

  const [marker, setMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [service, setService] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(null);

  const { api, scopes } = useApi();
  const { locationSettings } = locationSettingsStore.use();

  const hasGeolocationScope = scopes[SCOPES.GEOLOCATION];

  useEffect(() => {
    if (locationSettings.optionModeData?.value === 'auto' || hasGeolocationScope !== true) {
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }

    if (locationSettings.optionModeData?.value != null) {
      setMode(locationSettings.optionModeData.value);
    }
  }, [locationSettings.optionModeData, hasGeolocationScope]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyCtwaTZRNYZB7j6jdBFGmblQfGOmTULiXA',
      version: 'weekly',
    });

    loader.load().then(async (google) => {
      const { Map } = await google.maps.importLibrary('maps');
      const { Marker } = await google.maps.importLibrary('marker');
      const { AutocompleteService } = await google.maps.importLibrary('places');
      const { Geocoder } = await google.maps.importLibrary('geocoding');

      const defaultPosition = {
        lat: 52.22115954677011,
        lng: 6.894984191411735,
      };

      const map = new Map(ref.current, {
        center: defaultPosition,
        zoom: 20,
        mapId: '4504f8b37365c3d0',
        disableDefaultUI: true,
      });

      const geocoder = new Geocoder();
      const service = new AutocompleteService();

      const marker = new Marker({
        map,
        position: defaultPosition,
        draggable: false,
      });

      google.maps.event.addListener(map, 'click', (event) => {
        if (locationSettingsStore.get().optionModeData?.value === 'auto') return;

        marker.setPosition(event.latLng);

        locationSettingsStore
          .setOptionLocation({
            value: {
              latitude: event.latLng.lat(),
              longitude: event.latLng.lng(),
              accuracy: 0,
            },
          })
          .catch(ToastManager.handleError);
      });

      marker.addListener('dragend', (event) => {
        const position = marker.position;
        locationSettingsStore
          .setOptionLocation({
            value: {
              latitude: position.lat(),
              longitude: position.lng(),
              accuracy: 0,
            },
          })
          .catch(ToastManager.handleError);
      });

      setMarker(marker);
      setMap(map);
      setService(service);
      setGeocoder(geocoder);
    });
  }, []);

  useEffect(() => {
    if (map != null && marker != null) {
      if (isEnabled === false) {
        marker.setDraggable(false);
        map.setOptions({
          draggable: false, // this prevents dragging the map
          scrollwheel: false, // this disables zooming with the scroll wheel
          disableDoubleClickZoom: true, // this disables zooming by double clicking
          keyboardShortcuts: false, // this disables zooming with keyboard shortcuts
          gestureHandling: 'none', // this disables pinch-to-zoom and other touch gestures
        });
      } else {
        marker.setDraggable(true);
        map.setOptions({
          draggable: true, // this prevents dragging the map
          scrollwheel: true, // this disables zooming with the scroll wheel
          disableDoubleClickZoom: false, // this disables zooming by double clicking
          keyboardShortcuts: true, // this disables zooming with keyboard shortcuts
          gestureHandling: 'auto', // this disables pinch-to-zoom and other touch gestures
        });
      }
    }
  }, [map, marker, isEnabled]);

  useEffect(() => {
    if (locationSettings.optionLocationData?.value != null && marker != null && map != null) {
      marker.setPosition({
        lat: locationSettings.optionLocationData.value.latitude,
        lng: locationSettings.optionLocationData.value.longitude,
      });
      map.setZoom(20);
      map.setCenter({
        lat: locationSettings.optionLocationData.value.latitude,
        lng: locationSettings.optionLocationData.value.longitude,
      });
      // map.panBy(-372, 0);
      setIsLoaded(true);
    }
  }, [locationSettings.optionLocationData, locationSettings.optionModeData, marker, map]);

  const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const debouncedGetPlacePredictions = useMemo(() => {
    return debounce(
      async (value) => {
        if (value === '') {
          setItems([]);
          return;
        }

        try {
          const { predictions } = await service.getPlacePredictions({ input: value });

          const items = predictions.map((prediction) => {
            return {
              key: prediction.place_id,
              textValue: prediction.description,
              ...prediction,
            };
          });

          setItems(items);
          stateRef.current.open();
        } catch (error) {
          console.log(error);
          throw error;
        }
      },
      500,
      { leading: true }
    );
  }, [service]);

  return (
    <LayoutPortal>
      <S.Root data-is-visible={isLoaded}>
        <S.Map ref={ref} data-is-disabled={isEnabled !== true} />
        {/* <S.Input ref={inputRef} disabled={isEnabled !== true} placeholder="" /> */}

        <S.ControlsContainer>
          {(() => {
            if (api?.homey.apiVersion < 3 && isOpen !== true) {
              return (
                <div style={{ padding: '8px 10px' }}>
                  <Switch
                    label={i18n.messageFormatter('settings.system.locationAutoDetectLabel')}
                    name="autoupdate"
                    value={mode === 'auto' ? true : false}
                    isDisabled={hasGeolocationScope !== true}
                    onChange={(value) => {
                      if (value === true) {
                        setMode('auto');
                        locationSettingsStore
                          .setOptionMode({
                            value: 'auto',
                          })
                          .catch((error) => {
                            setMode(locationSettingsStore.optionModeData?.value);
                            ToastManager.handleError(error);
                          });
                      } else {
                        setMode('manual');
                        locationSettingsStore
                          .setOptionMode({
                            value: 'manual',
                          })
                          .catch((error) => {
                            setMode(locationSettingsStore.optionModeData?.value);
                            ToastManager.handleError(error);
                          });
                      }
                    }}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (
              api?.homey.apiVersion < 3 &&
              isOpen !== true &&
              locationSettings.optionModeData?.value !== 'auto'
            ) {
              return <S.Line />;
            }
          })()}

          {locationSettings.optionModeData?.value !== 'auto' && (
            <LocationSettingsComboBox
              stateRef={stateRef}
              aria-label="Select location"
              placeholder={i18n.messageFormatter('settings.system.locationSearchPlaceholder')}
              items={items}
              inputValue={inputValue}
              shouldFocusWrap={true}
              isDisabled={isEnabled !== true || hasGeolocationScope !== true}
              onInputChange={(value) => {
                setInputValue(value);
              }}
              onInput={(event) => {
                debouncedGetPlacePredictions(event.target.value);
              }}
              onSelectionChange={(key) => {
                const item = items.find((item) => item.key === key);

                if (item == null) {
                  return;
                }

                geocoder
                  .geocode({ placeId: item.place_id })
                  .then(({ results }) => {
                    const position = results[0].geometry.location;
                    locationSettingsStore
                      .setOptionLocation({
                        value: {
                          latitude: position.lat(),
                          longitude: position.lng(),
                          accuracy: 0,
                        },
                      })
                      .catch(ToastManager.handleError);

                    map.setZoom(20);
                    map.setCenter(position);
                    marker.setPosition(position);
                    marker.setVisible(true);
                  })
                  .catch(ToastManager.handleError);
              }}
              onOpenChange={(isOpen) => {
                setIsOpen(isOpen);
              }}
            >
              {(item) => (
                <LocationSettingsComboBox.Item key={item.key}>
                  {item.textValue}
                </LocationSettingsComboBox.Item>
              )}
            </LocationSettingsComboBox>
          )}
        </S.ControlsContainer>
      </S.Root>
    </LayoutPortal>
  );
}

LocationSettings.pathKey = 'system/location';
LocationSettings.icon = iconLocation;
LocationSettings.backgroundColor = theme.color.system_location;

function S() {}
LocationSettings.S = S;

S.Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transition: ${theme.duration.normal} ${theme.curve.easeInOut};
  transition-property: opacity;

  &[data-is-visible='true'] {
    opacity: 1;
  }
`;

S.Map = styled.div`
  width: 100%;
  height: 100%;

  &[data-is-disabled='true'] {
    filter: grayscale(1);
  }
`;

S.ControlsContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: calc(var(--space-reserve-width) / 2 + 50%);
  transform: translateX(-50%);
  outline: 0;
  width: 440px;
  outline: none;
  border: none;
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
`;

S.Line = styled.div`
  background-color: ${theme.color.line};
  height: 1px;
  width: 420px;
  margin: 0 auto;
`;
