import React, { useMemo, useReducer, useEffect } from 'react';
import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';

const initialState = {
  speaker_track: '',
  speaker_artist: '',
  speaker_album: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

function set(id, value) {
  return {
    type: 'set',
    payload: {
      [id]: value,
    },
  };
}

export function MediaMeta({ capabilities, componentCapabilities }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const metaCapabilities = useMemo(() => {
    return ['speaker_track', 'speaker_artist', 'speaker_album'].reduce(
      (accumulator, capabilityId) => {
        accumulator[capabilityId] = capabilities?.[capabilityId] ?? null;
        return accumulator;
      },
      {}
    );
  }, [capabilities]);

  useEffect(() => {
    const listeners = [];
    const nextValues = {};

    if (metaCapabilities.speaker_track) {
      listeners.push(
        metaCapabilities.speaker_track.onChange(({ value }) => {
          dispatch(set('speaker_track', value));
        })
      );

      nextValues.speaker_track = metaCapabilities.speaker_track.value;
    }

    if (metaCapabilities.speaker_artist) {
      listeners.push(
        metaCapabilities.speaker_artist.onChange(({ value }) => {
          dispatch(set('speaker_artist', value));
        })
      );

      nextValues.speaker_artist = metaCapabilities.speaker_artist.value;
    }

    if (metaCapabilities.speaker_album) {
      listeners.push(
        metaCapabilities.speaker_album.onChange(({ value }) => {
          dispatch(set('speaker_album', value));
        })
      );

      nextValues.speaker_album = metaCapabilities.speaker_album.value;
    }

    dispatch({ type: 'set', payload: nextValues });

    return function () {
      listeners.forEach((unregister) => unregister());
    };
  }, [metaCapabilities]);

  return (
    <MediaMeta.Root>
      <MediaMeta.Text title={state.speaker_track} data-style="track">
        {state.speaker_track}
      </MediaMeta.Text>
      <MediaMeta.Text title={state.speaker_artist} data-style="artist">
        {state.speaker_artist}
      </MediaMeta.Text>
    </MediaMeta.Root>
  );
}

MediaMeta.Root = styled.div`
  margin-top: 20px;
  max-width: 312px;
`;

MediaMeta.Text = styled.div`
  text-align: center;

  &[data-style='track'] {
    color: ${theme.color.white};
    font-weight: ${theme.fontWeight.medium};
  }

  &[data-style='artist'] {
    color: ${theme.color.white_o_50};
  }
`;
