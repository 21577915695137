import PropTypes from 'prop-types';

export function Button() {
  throw new Error('Dont render this');
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  onPress: PropTypes.func,
};
