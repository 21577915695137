import { HomeyStore } from '../../../store/HomeyStore';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { useTokensByKey } from '../../../store/flow-tokens/useFlowTokens';
import { useTokenContext } from '../TokenContext';

const REGEX = /\[\[(.*?)\]\]/g;

export function isTokenValue(value) {
  // seems odd but it's actually needed
  return Boolean(String(value).match(REGEX));
}

export function trimTokenBrackets(tokenString) {
  return tokenString.substring(2, tokenString.length - 2);
}

export function getTokenFlowKey(key, { kind, token }) {
  if (kind === 'local') {
    return key;
  }

  const apiVersion = HomeyStore.get().api.apiVersion;

  if (apiVersion >= 3) {
    return `${token.ownerUri}|${token.ownerId}`;
  }

  return `${token.uri}|${token.id}`;
}

export function getStoreKey(key) {
  const [ownerUri, id] = key.split('|');
  return ResourceUtils.getTokenKey({
    ownerUri: ownerUri,
    id: id,
  });
}

export function useToken({ value, isDropToken }) {
  const tokens = useTokensByKey();
  const { tokensByKey } = useTokenContext();

  let token = null;
  let tokenKey = null;
  let isToken = false;

  if (value != null) {
    isToken = isTokenValue(value);

    if (isToken) {
      tokenKey = trimTokenBrackets(value);

      token = tokenKey.includes('|')
        ? tokens.byKey?.[getStoreKey(tokenKey)] ?? null
        : tokensByKey[tokenKey] ?? null;
    } else if (isDropToken) {
      tokenKey = value;
      token = tokenKey.includes('|')
        ? tokens.byKey?.[getStoreKey(tokenKey)] ?? null
        : tokensByKey[tokenKey] ?? null;
    }
  }

  return { token, tokenKey, isToken, loading: tokens.loading };
}

export const defaultTokenExamples = {
  string: '-',
  number: '-',
  error: 'Error',
  boolean: true,
  image: null,
};
