import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';

import { getSliderImages } from './getSliderImages';

import { theme } from '../../../theme/theme';

import * as Containers from '../Containers';
import { CapabilitySelect } from '../CapabilitySelect';
import { Slider } from './Slider';
import { IconButton } from '../../buttons/IconButton';

export function SliderAdapter({ device, component, capabilities }) {
  const instance = useRef(`SliderComponentAdapter:${uuid()}`);
  const [capability, setCapability] = useState(() => {
    return capabilities?.[component.capabilities[0]] ?? null;
  });
  const [value, setValue] = useState(capability?.value ?? capability?.min ?? 0);

  useEffect(() => {
    setCapability(capabilities?.[component.capabilities[0]] ?? null);
  }, [capabilities, component.capabilities]);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        setValue(value);
      });

      setValue(capability.value ?? capability.min ?? 0);

      return unregister;
    }
  }, [capability]);

  const debouncedCall = useMemo(() => {
    return debounce((newValue) => {
      capability
        .setValue(newValue, instance.current)
        .then(({ transactionId, transactionTime, value }) => {})
        .catch((error) => {
          ToastManager.handleError(error);
        });
    }, 500);
  }, [capability]);

  if (capability == null) return null;

  const images = getSliderImages(capability.id, device);

  function handleChange([newValue]) {
    setValue(newValue);
    debouncedCall(newValue);
  }

  return (
    <Containers.Control>
      <Containers.Select>
        {component.capabilities.length > 1 && (
          <CapabilitySelect
            selectedCapability={capability}
            capabilities={capabilities}
            componentCapabilities={component.capabilities}
            onSelectionChange={setCapability}
          />
        )}
      </Containers.Select>
      <Containers.Action>
        <SliderAdapter.IconButtonWrapper>
          <IconButton
            size={theme.icon.size_large}
            url={images.upper}
            color={theme.color.white}
            onPress={() => {
              handleChange([capability.max ?? 1]);
            }}
          />
        </SliderAdapter.IconButtonWrapper>
        <Slider
          style={{
            flex: '1 1 auto', // Containers.Action is a flex container with column direction
          }}
          minValue={capability.min ?? 0}
          maxValue={capability.max ?? 1}
          step={capability.step ?? 0.01}
          aria-label={capability.title ?? 'Slider'}
          value={[value ?? '']}
          formatOptions={{ style: capability.units === '%' ? 'percent' : 'decimal' }}
          onChange={handleChange}
        />
        <SliderAdapter.IconButtonWrapper>
          <IconButton
            size={theme.icon.size_large}
            url={images.lower}
            color={theme.color.white}
            onPress={() => handleChange([capability.min ?? 0])}
          />
        </SliderAdapter.IconButtonWrapper>
      </Containers.Action>
    </Containers.Control>
  );
}

SliderAdapter.IconButtonWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
`;
