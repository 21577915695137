import { useState, useLayoutEffect } from 'react';

import { OverlaySource } from './OverlaySource';

import { useCurrentProps } from '../../hooks/useCurrentProps';
import { useOverlayStackContext } from './OverlayStackContext';

import { OverlayStackContext } from './OverlayStackContext';
import { OverlayContext } from './OverlayContext';

export function OverlayStackContextConsumer(props) {
  const [isPreventCloseOverride, setPreventCloseOverride] = useState(null);
  const [stackIndex, setStackIndex] = useState(-1);

  const overlayContext = useCurrentProps({ setPreventCloseOverride, stackIndex });
  const currentProps = useCurrentProps({
    overlayRef: props.overlayRef,
    onCloseRequest: props.onCloseRequest,
    isPreventCloseOverride: isPreventCloseOverride,
  });

  const { context, isRoot } = useOverlayStackContext();

  useLayoutEffect(() => {
    const source = new OverlaySource({
      ref: currentProps.overlayRef,
      onCloseRequest(reason) {
        currentProps.onCloseRequest(reason);
      },
      getIsPreventClose() {
        return currentProps.isPreventCloseOverride ?? false;
      },
    });

    const length = context.stack.push(source);
    setStackIndex(length - 1);

    return function () {
      // It might have been removed already onCloseRequest
      // console.log('current:stack', [...context.stack])
      const index = context.stack.findIndex((element) => element === source);

      if (index > -1) {
        context.stack.splice(index, 1);
        // console.log('next:stack', [...context.stack])
      }
    };
  }, [context, currentProps]);

  if (isRoot === true) {
    return (
      <OverlayStackContext.Provider value={context}>
        <OverlayContext.Provider value={overlayContext}>{props.children}</OverlayContext.Provider>
      </OverlayStackContext.Provider>
    );
  }

  return <OverlayContext.Provider value={overlayContext}>{props.children}</OverlayContext.Provider>;
}
