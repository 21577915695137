import React, { useMemo } from 'react';

import { useTokenContext } from '../TokenContext';

import { FlowTestForm } from '../FlowTestForm';

import { errorToken } from './card/TokenContextProvider';

export function AdvancedFlowTestForm(props) {
  const { tokensByKey, ownTokensByKey } = useTokenContext();

  // const currentCardData = props.advancedFlow?.cards[props.currentNodeId];

  const tokens = useMemo(() => {
    const result = {};

    if (props.advancedFlow != null) {
      for (const tokenKey of props.treeTokenKeys) {
        // eslint-disable-next-line no-unused-vars
        const [cardType, nodeId, tokenId] = tokenKey.split('::');

        const token = tokensByKey[tokenKey] ?? ownTokensByKey[tokenKey];
        const isCurrentCardToken = tokenKey.includes(props.currentNodeId);

        switch (true) {
          // So we don't ask for tokens from 2 different triggers.

          // TODO
          // use reachable like behavior for tokens so we indicate something is always undefined

          case isCurrentCardToken === true || cardType === 'trigger':
          case isCurrentCardToken === false && cardType === 'action':
          case isCurrentCardToken === false && cardType === 'card' && tokenId === errorToken.id:
            if (token != null) {
              result[tokenKey] = token;
            }
            break;
          default:
            break;
        }
      }
    }

    return result;
  }, [
    props.currentNodeId,
    props.advancedFlow,
    props.treeTokenKeys,
    tokensByKey,
    ownTokensByKey,
    // currentCardData,
  ]);

  return <FlowTestForm tokens={tokens} onStartRequest={props.onStartRequest} />;
}
