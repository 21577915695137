import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';

import { ToastManager } from '../../../ToastManager';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { useMount } from '../../../hooks/useMount';
import { useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';
import { scrollbar } from '../../../theme/elements/scrollbars';

import * as Containers from '../Containers';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { TimelineItem } from './TimelineListItem';

export function Timeline(props) {
  const { i18n } = useI18n();

  const entries = useEntries({
    device: props.device,
    capabilities: props.capabilities,
  });

  return (
    <Containers.Control>
      <Containers.Select />
      <Containers.Action>
        <Timeline.Root>
          {entries?.map((entry, index) => {
            return (
              <TimelineItem
                key={index}
                entry={entry}
                messageFormatter={i18n.messageFormatter}
                dateFormatter={i18n.dateFormatter}
              />
            );
          })}

          {Boolean(entries?.length) === false && (
            <Timeline.EmptyMessage>
              {i18n.messageFormatter('device.noHistory')}
            </Timeline.EmptyMessage>
          )}
        </Timeline.Root>
      </Containers.Action>
    </Containers.Control>
  );
}

Timeline.Root = styled.ul`
  ${scrollbar};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
`;

Timeline.EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  color: ${theme.color.text_light};
`;

function useEntries(props) {
  const mount = useMount();

  const instanceRef = useRef({
    pendingId: null,
  });

  const { api } = useApi();

  const [state, setState] = useState(null);

  useEffect(() => {
    async function fetchEntries() {
      const id = (instanceRef.current.pendingId = uuid());

      try {
        const logsByKey = {};
        const promises = [];

        props.device.insights?.forEach((log) => {
          if (log.type !== 'boolean') return;

          const opts = {
            uri: ResourceUtils.getOwnerUri(log),
            id: ResourceUtils.getId(log),
          };

          const key = ResourceUtils.temp__getId(log);
          logsByKey[key] = log;

          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const result = await api.insights.getLogEntries(opts);
                resolve([key, result]);
              } catch (error) {
                reject(error);
              }
            })
          );
        });

        const results = await Promise.all(promises);

        if (instanceRef.current.pendingId !== id) return;

        const values = [];

        results?.forEach(([key, result]) => {
          const log = logsByKey[key] ?? {};

          function getTitle(value) {
            return value ? log.titleTrue : log.titleFalse;
          }

          result.values?.forEach((entry, entryIndex) => {
            const date = new Date(entry.t);
            const value = entry.v === true;

            values.push({
              ...entry,
              date: date,
              title: getTitle(value),
              value: value,
              // key: `${date.getTime()}-${value.toString()}`,
              // expected date to be unique but it isnt somehow
              // key: entryIndex,
            });
          });
        });

        function dateSort(firstValue, secondValue) {
          return secondValue.date - firstValue.date;
        }

        if (mount.isMounted) {
          setState(values.sort(dateSort));
        }
      } catch (error) {
        if (instanceRef.current.pendingId !== id) return;
        ToastManager.handleError(error);
      }
    }

    const unregister = props.capabilities?.onChange((info) => {
      // this is an unoffical way to update the timeline
      // since we know it mostly consists of boolean capability updates
      // we need to give it 1000ms time because its a race condition
      // capability update might be emitted before log update is done

      if (props.capabilities[info.capabilityId]?.type === 'boolean') {
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          fetchEntries();
        });
      }
    });

    fetchEntries();

    return function () {
      unregister?.();
    };
  }, [mount, api, props.device?.insights, props.capabilities]);

  return state;
}
