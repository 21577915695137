import { useRef, forwardRef } from 'react';
import styled from '@emotion/styled';

import { mergeRefs } from '../../lib/mergeRefs';

import { useDraggableButton } from '../../containers/flows/view-advanced-flow/card/useDraggableButton';

import { theme } from '../../theme/theme';

import { ButtonIcon } from './ButtonIcon';

import { iconPlayOpticalAlignment } from '../../theme/icons/interface/play-optical-alignment';
import { iconStopOpticalAlignment } from '../../theme/icons/interface/stop-optical-alignment';

export const PlayButton = forwardRef((props, forwardedRef) => {
  const ref = useRef();

  // TODO
  // We should make that this draggable hook behavior comes from above and is not implemented here.
  const button = useDraggableButton(props, ref);

  return (
    <PlayButton.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      data-is-pressed={button.isPressed}
      data-is-testing-root={props.isTestingRoot}
      data-is-disabled={props.isDisabled}
      data-is-disabled-style={props.isDisabledStyle}
      data-is-transform-disabled={props.isTransformDisabled}
    >
      <PlayButton.RingWrapper>
        <PlayButton.Ring cx="50%" cy="50%" r="40%" pathLength={1} />
      </PlayButton.RingWrapper>
      <PlayButton.IconWrapper>
        <PlayButton.Play>
          <PlayButton.Icon
            url={iconPlayOpticalAlignment}
            color={theme.color.white}
            size={theme.icon.size_small}
            display="block"
          />
        </PlayButton.Play>
        <PlayButton.Stop>
          <PlayButton.Icon
            url={iconStopOpticalAlignment}
            color={theme.color.white}
            size={theme.icon.size_small}
            display="block"
          />
        </PlayButton.Stop>
      </PlayButton.IconWrapper>
    </PlayButton.Root>
  );
});

PlayButton.RingWrapper = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

PlayButton.Ring = styled.circle`
  fill: transparent;
  stroke: white;
  stroke-width: 2;
  stroke-dasharray: 0 1;
  stroke-dashoffset: 0.25;
  stroke-linecap: round;
  opacity: 0;
  transform: rotate(360deg);
  transform-origin: center center;
  transition: ${theme.duration.normal} ${theme.curve.fastIn},
    0s ${theme.curve.linear} ${theme.duration.slow}, ${theme.duration.slow} ${theme.curve.easeOut},
    ${theme.duration.slow} ${theme.curve.easeOut};
  transition-property: opacity, stroke-dashoffset, stroke-dasharray, transform;
`;

PlayButton.IconWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(360deg);
  transition: ${theme.duration.normal} ${theme.curve.fastIn};
  transition-property: transform;
`;

PlayButton.Icon = ButtonIcon;

PlayButton.Play = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  transition: ${theme.duration.normal} ${theme.curve.fastIn};
  transition-property: opacity, transform;
`;

PlayButton.Stop = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  opacity: 1;
  transform: scale(0.25);
  transition: ${theme.duration.normal} ${theme.curve.easeIn};
  transition-property: opacity, transform;
`;

PlayButton.Root = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${theme.gradientButton.background_color_blue};
  background-size: ${theme.gradientButton.background_size};
  background-position: ${theme.gradientButton.background_position_default};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: background-position, transform;

  &:hover {
    background-position: ${theme.gradientButton.background_position_hover};
  }

  &[data-is-pressed='true'] {
    background-position: ${theme.gradientButton.background_position_active};
    transform: scale(0.9);
  }

  &[data-is-testing-root='true'] {
    ${PlayButton.IconWrapper} {
      transform: rotate(0deg);
      transition: 0s transform;
    }

    &:not([data-is-transform-disabled='true']) {
      ${PlayButton.Play} {
        transform: scale(0.25) rotate(180deg);
        transition: ${theme.duration.normal} ${theme.curve.easeIn};
      }

      ${PlayButton.Stop} {
        transform: scale(1) rotate(180deg);
        transition: ${theme.duration.normal} ${theme.curve.fastIn};
      }
    }

    ${PlayButton.Ring} {
      opacity: 1;
      stroke-dasharray: 0.5 0.5;
      stroke-dashoffset: -500;
      transform: rotate(0deg);
      transition: ${theme.duration.normal} ${theme.curve.fastIn}, 600s ${theme.curve.linear},
        ${theme.duration.slow} ${theme.curve.easeOut}, 0s;
      transition-property: opacity, stroke-dashoffset, stroke-dasharray, transform;
    }
  }

  &[data-is-disabled='true'] {
    &:not([data-is-testing-root='true']) {
      cursor: unset;
    }

    &[data-is-disabled-style='unreachable'] {
      background: ${theme.flowcard.disabled_background_color};
    }
  }
`;
