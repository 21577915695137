import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

export interface State {
  experimentsData: ExperimentsData | null;
  isLoading: boolean;
  error: unknown;
}

type ExperimentsData = { [key: string]: Experiment };

type Experiment = {
  title: string;
  enabled: boolean;
  description: string;
};

export type ExperimentsSettingsStoreState = State & BaseApiStoreState;

class ExperimentsSettingsStore extends BaseApiStore<State> {
  key = 'experiments-settings';

  override createInitialState() {
    return {
      experimentsData: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:experiments');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerExperiments = api.experiments;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const experimentsPromise = managerExperiments.getExperiments();

      const [, experimentsData] = await Promise.all([waitPromise, experimentsPromise]);

      this.set({
        ...this.createInitialState(),
        experimentsData: experimentsData as unknown as ExperimentsData,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, 'experiments-settings');

    return { experimentsSettings: this.store(selectAll, shallow) };
  }

  async enableExperiment({ id }: { id: string }) {
    const api = this.getApi();
    const result = (await api.experiments.enableExperiment({ id })) as unknown as Promise<string>;

    this.set((state) => {
      const currentData = state.experimentsData?.[id];
      // Somehow we have no data for this experiment.
      if (currentData == null) return {};

      return {
        experimentsData: {
          ...state.experimentsData,
          [id]: {
            ...currentData,
            enabled: true,
          },
        },
      };
    });

    return result;
  }

  async disableExperiment({ id }: { id: string }) {
    const api = this.getApi();
    const result = (await api.experiments.disableExperiment({ id })) as unknown as Promise<void>;

    this.set((state) => {
      const currentData = state.experimentsData?.[id];
      // Somehow we have no data for this experiment.
      if (currentData == null) return {};

      return {
        experimentsData: {
          ...state.experimentsData,
          [id]: {
            ...currentData,
            enabled: false,
          },
        },
      };
    });

    return result;
  }
}

function selectAll(state: ExperimentsSettingsStoreState) {
  return {
    ...selectBase(state),
    experimentsData: state.experimentsData,
  };
}

export const experimentsSettingsStore = new ExperimentsSettingsStore();
