import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';

import { Icon } from './Icon';

/**
 * Render a message in the center of the parent container with an optional icon and action.
 */
export function CenteredMessage(props) {
  return (
    <CenteredMessage.Root>
      {props.icon}

      {props.iconUrl && (
        <CenteredMessage.Icon
          size="40px"
          color={props.iconColor ?? theme.color.text}
          url={props.iconUrl}
        />
      )}

      {props.title && (
        <CenteredMessage.Title style={{ '--title-color': props.titleColor }}>
          {props.title}
        </CenteredMessage.Title>
      )}

      {props.subtitle && (
        <CenteredMessage.Subtitle style={{ '--subtitle-color': props.subtitleColor }}>
          {props.subtitle}
        </CenteredMessage.Subtitle>
      )}

      <div>
        <p>{props.action}</p>
      </div>

      {props.children}
    </CenteredMessage.Root>
  );
}

CenteredMessage.propTypes = {
  iconUrl: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.any,
  titleColor: PropTypes.string,
  subtitle: PropTypes.any,
  subtitleColor: PropTypes.string,
  action: PropTypes.any,
  children: PropTypes.any,
  icon: PropTypes.any,
};

CenteredMessage.Root = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

CenteredMessage.Icon = styled(Icon)`
  margin-top: 10px;
`;

CenteredMessage.Title = styled.h2`
  margin-top: 10px;
  color: var(--title-color, ${theme.color.text});
`;

CenteredMessage.Subtitle = styled.div`
  max-width: 600px;
  color: var(--subtitle-color, ${theme.color.text});
  margin: 10px 0;
  text-align: center;
`;
