import speaker_next from '../images/media/speaker_next.svg';
import speaker_prev from '../images/media/speaker_prev.svg';
import speaker_playing from '../images/media/speaker_play.svg';
import speaker_pause from '../images/media/speaker_pause.svg';
import speaker_repeat from '../images/media/speaker_repeat.svg';
import speaker_repeat_list from '../images/media/speaker_repeat_list.svg';
import speaker_shuffle from '../images/media/speaker_shuffle.svg';

export function getMediaImage(capabilityId) {
  switch (capabilityId) {
    case 'speaker_next':
      return speaker_next;
    case 'speaker_prev':
      return speaker_prev;
    case 'speaker_playing':
      return {
        play: speaker_playing,
        pause: speaker_pause,
      };
    case 'speaker_repeat':
      return {
        none: speaker_repeat,
        track: speaker_repeat,
        playlist: speaker_repeat_list,
      };
    case 'speaker_shuffle':
      return speaker_shuffle;
    default:
      return null;
  }
}
