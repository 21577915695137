export function blendHexColorWithBackground({ hexColor, hexBackgroundColor, alpha }) {
  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function rgbToHex(r, g, b) {
    return (
      "#" +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("")
    );
  }

  const rgbaColor = hexToRgb(hexColor);
  const backgroundColor = hexToRgb(hexBackgroundColor);

  const newRed = Math.round((1 - alpha) * backgroundColor.r + alpha * rgbaColor.r);
  const newGreen = Math.round((1 - alpha) * backgroundColor.g + alpha * rgbaColor.g);
  const newBlue = Math.round((1 - alpha) * backgroundColor.b + alpha * rgbaColor.b);

  return rgbToHex(newRed, newGreen, newBlue);
}