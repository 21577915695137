import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

export class NotificationStore extends BaseApiStore {
  static key = 'notifications';
  static store = this.createStore(this.key);
  static notificationCacheId = 'HomeyAPI.ManagerNotifications.Notification';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      loading: true,
      error: null,
    };
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info('fetch:notifications');
    this.destroy();
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerNotifications = state.api.notifications;

      const time = silent === true ? 0 : 0;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));

      const notificationsPromise = managerNotifications.getNotifications({
        $skipCache: skipCache,
      });

      const [, notificationsData] = await Promise.all([waitPromise, notificationsPromise]);

      const result = Object.values(notificationsData).reduce(
        (accumulator, notificationInstance) => {
          const notificationReference = { ...notificationInstance };
          const id = notificationReference.id;

          accumulator.data[id] = notificationInstance;
          accumulator.byId[id] = notificationReference;

          return accumulator;
        },
        {
          data: {},
          byId: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byId: result.byId,
        loading: false,
      });

      managerNotifications.addListener('notification.create', this.handleCreate);
      managerNotifications.addListener('notification.update', this.handleUpdate);
      managerNotifications.addListener('notification.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:notifications');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerNotifications = state.api.notifications;

      managerNotifications.removeListener('notification.create', this.handleCreate);
      managerNotifications.removeListener('notification.update', this.handleUpdate);
      managerNotifications.removeListener('notification.delete', this.handleDelete);
    }
  }

  static getInstanceFromCache(notification, stateArg) {
    const state = stateArg ?? this.get();

    const cache = state.api.notifications._caches[this.notificationCacheId];

    return cache.getOne({
      id: cache._guessCacheId(notification),
    });
  }

  static handleCreate = (createdNotification) => {
    const id = createdNotification.id;
    __DEV__ && console.info(`create:notification:${id}`);
    const state = this.get();
    const notificationInstance = this.getInstanceFromCache(createdNotification, state);

    if (notificationInstance == null) return;

    const notificationReference = { ...notificationInstance };

    this.set({
      data: {
        ...state.data,
        [id]: notificationInstance,
      },
      byId: {
        ...state.byId,
        [id]: notificationReference,
      },
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedNotification) => {
    const id = updatedNotification.id;
    __DEV__ && console.info(`update:notification:${id}`);

    this.updateQueue[id] = { ...updatedNotification };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedNotifications = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextById = {
          ...state.byId,
        };

        let updateCount = 0;

        for (const [id, queuedNotification] of Object.entries(queuedNotifications)) {
          const notificationInstance = this.getInstanceFromCache(queuedNotification, state);

          if (notificationInstance == null || state.data[id] == null) {
            __DEV__ && console.info(`update:notification:unknown:${id}`);
            continue;
          }

          const notificationReference = { ...notificationInstance };
          nextById[id] = notificationReference;

          updateCount++;
        }

        __DEV__ && console.info(`update:notification:batch:count:${updateCount}`);

        updateCount > 0 &&
          this.set({
            byId: nextById,
          });
      }, 200);
    }
  };

  static handleDelete = (deletedNotification) => {
    const id = deletedNotification.id;
    __DEV__ && console.info(`delete:notification:${id}`);
    const state = this.get();
    const notificationInstance = state.data[id];

    if (notificationInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };

    delete nextData[id];
    delete nextById[id];

    this.set({
      data: nextData,
      byId: nextById,
    });
  };
}
