//import { v4 as uuid } from 'uuid';

import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';
import { buildTree } from '../../lib/buildTree';
//import { ToastManager } from '../../ToastManager';
//import { ZoneUtils } from './ZoneUtils';

export class ZoneStore extends BaseApiStore {
  static key = 'zones';
  static store = this.createStore(this.key);
  static zoneCacheId = 'HomeyAPI.ManagerZones.Zone';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      byIdChildren: null,
      rootZoneId: null,
      tree: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:zones');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerZones = state.api.zones;

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 0));
      const zonesPromise = await managerZones.getZones();

      const [, zonesData] = await Promise.all([waitPromise, zonesPromise]);

      const tree = buildTree(zonesData);
      const result = Object.values(zonesData).reduce(
        (accumulator, zone) => {
          const zoneReference = { ...zone };

          accumulator.data[zone.id] = zone;
          accumulator.byId[zone.id] = zoneReference;

          if (accumulator.byIdChildren[zone.parent] != null) {
            accumulator.byIdChildren[zone.parent].add(zone.id);
          } else {
            accumulator.byIdChildren[zone.parent] = new Set([zone.id]);
          }

          if (zone.parent == null) {
            accumulator.rootZoneId = zone.id;
          }

          return accumulator;
        },
        {
          data: {},
          byId: {},
          byIdChildren: {},
          rootZoneId: null,
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byId: result.byId,
        byIdChildren: result.byIdChildren,
        rootZoneId: result.rootZoneId,
        tree: tree,
        loading: false,
        manager: managerZones,
      });

      managerZones.addListener('zone.create', this.handleCreate);
      managerZones.addListener('zone.update', this.handleUpdate);
      managerZones.addListener('zone.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:zones');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerZones = state.api.zones;

      managerZones.removeListener('zone.create', this.handleCreate);
      managerZones.removeListener('zone.update', this.handleUpdate);
      managerZones.removeListener('zone.delete', this.handleDelete);
    }
  }

  static handleCreate = (createdZone) => {
    __DEV__ && console.info(`create:zone:${createdZone.id}`);
    const state = this.get();

    const zoneInstance = state.api.zones._caches[this.zoneCacheId].getOne({
      id: createdZone.id,
    });

    if (zoneInstance == null) return;

    const nextData = {
      ...state.data,
      [createdZone.id]: zoneInstance,
    };

    this.set({
      data: nextData,
      byId: {
        ...state.byId,
        [createdZone.id]: { ...zoneInstance },
      },
      byIdChildren: {
        ...state.byIdChildren,
        [createdZone.parent]: new Set([
          ...(state.byIdChildren[createdZone.parent] ?? []),
          createdZone.id,
        ]),
      },
      tree: buildTree(nextData),
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedZone) => {
    __DEV__ && console.info(`update:zone:${updatedZone.id}`);
    this.updateQueue[updatedZone.id] = {
      ...updatedZone,
    };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedZones = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextById = {
          ...state.byId,
          ...queuedZones,
        };

        this.set({
          byId: nextById,
          tree: buildTree(state.data),
        });
      }, 200);
    }
  };

  static handleDelete = (deletedZone) => {
    __DEV__ && console.info(`delete:zone:${deletedZone.id}`);
    const state = this.get();
    const zoneInstance = state.data[deletedZone.id];

    if (zoneInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };

    delete nextData[zoneInstance.id];
    delete nextById[zoneInstance.id];

    this.set({
      data: nextData,
      byId: nextById,
      tree: buildTree(nextData),
    });
  };
}
