import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { userStore } from '../../../store/users/UserStore';
import { ToastManager } from '../../../ToastManager';
import { wait } from '../../../lib/wait';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useUser } from '../../../store/users/useUsers';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { SpinnerFade } from '../../../components/common/SpinnerFade';
import { Dialog } from '../../../components/overlay/Dialog';
import { SolidButton } from '../../../components/buttons/SolidButton';
import { IconButton } from '../../../components/buttons/IconButton';
import { Icon } from '../../../components/common/Icon';
import { RoundIconWrapper } from '../../../components/common/Icon';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconArrowDoubleSquare } from '../../../theme/icons/interface/arrow-double-square/arrow-double-square';

export function FamilySettingsDialogSwapOwner(props) {
  const { i18n } = useI18n();
  const params = useParams();

  const { user } = useUser({ userId: params.userId });
  const [name, setName] = useState(user?.name);

  useEffect(() => {
    if (user?.name != null) {
      setName(user?.name);
    }
  }, [user?.name]);

  const [isSwapping, setIsSwapping] = useState(false);

  function handleClosePress() {
    props.onCloseRequest();
  }

  function handleSwapPress() {
    setIsSwapping(true);
    const minDuration = wait(500);

    userStore
      .swapOwner({ id: params.userId })
      .then(async (result) => {
        await minDuration;
        props.onCloseRequest();
      })
      .catch((error) => {
        setIsSwapping(false);
        ToastManager.handleError(error);
      })
      .finally(() => {});
  }

  return (
    <Dialog
      containFocus={true}
      autoFocus={true}
      onClose={() => {
        props.onCloseRequest();
      }}
    >
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        <RoundIconWrapper size="80px" color={theme.color.red_050}>
          <Icon size="32px" url={iconArrowDoubleSquare} color={theme.color.red} />
        </RoundIconWrapper>

        <S.Title>{i18n.messageFormatter('settings.system.usersSwapOwnerDialogTitle')}</S.Title>

        <S.Info>
          {i18n.messageFormatter('settings.system.usersSwapOwnerDialogSubtitle', { name })}
        </S.Info>

        <S.Actions>
          <SolidButton
            isDisabled={isSwapping === true}
            styleFlat={true}
            styleColor="red"
            onPress={handleSwapPress}
          >
            <SpinnerFade
              isActive={isSwapping === true}
              color={theme.color.white}
              size={theme.icon.size_small}
            />
            <SolidButton.Text>{i18n.messageFormatter('common.transfer')}</SolidButton.Text>
          </SolidButton>
        </S.Actions>
      </S.Root>
    </Dialog>
  );
}

function S() {}
FamilySettingsDialogSwapOwner.S = S;

S.Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: ${su(4)} ${su(2)} ${su(2)};
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Title = styled.h3`
  padding-top: ${su(2)};
`;

S.Info = styled.p`
  color: ${theme.color.text_light};
  text-align: center;
`;

S.Actions = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
`;
