import React, { createContext, useContext, useRef } from 'react';
import styled from '@emotion/styled';

import { useTab } from 'react-aria';

const TabContext = createContext();

export function useTabContext() {
  return useContext(TabContext);
}

export function TabItem(props) {
  const tabRef = useRef();
  const tab = useTab({ key: props.item.key }, props.state, tabRef);

  return (
    <TabContext.Provider
      value={{
        ref: tabRef,
        tab: tab,
        item: props.item,
        value: props.item.value,
        state: props.state,
      }}
    >
      {props.renderTab({
        item: props.item,
        value: props.item.value,
        state: props.state,
      })}
    </TabContext.Provider>
  );
}

export function Tab(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, tab, item, value, state } = useTabContext();

  const isSelected = state.selectedKey === item.key;
  const isDisabled = state.disabledKeys.has(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  return (
    <Tab.Root
      {...tab.tabProps}
      ref={ref}
      data-is-selected={isSelected}
      data-is-disabled={isDisabled}
      data-is-focused={isFocused}
    >
      {item.textValue}
    </Tab.Root>
  );
}

export function renderTab() {
  return <Tab />;
}

Tab.Root = styled.div`
  padding: 10px;

  &[data-is-selected='true'] {
  }

  &[data-is-disabled='true'] {
    opacity: 0.5;
  }
`;
