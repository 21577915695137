import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { ScriptStore } from './ScriptStore';
import { RouteManager } from '../../RouteManager';
import { VersionError } from '../../lib/InternalError';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useScripts } from './useScripts';
import { useScriptRouteId } from './useScriptRouteId';

import { theme } from '../../theme/theme';

import { Layout } from '../../Layout';
import { GradientButton } from '../../components/buttons/GradientButton';
import { SpinnerFade } from '../../components/common/SpinnerFade';
import { CenteredMessage } from '../../components/common/CenteredMessage';
import { SpinnerLoading } from '../../components/common/SpinnerLoading';

import { ScriptNavigation } from './ScriptNavigation';
import { ScriptEditor } from './ScriptEditor';
import { ScriptActions } from './ScriptActions';
import { ScriptDialog } from './ScriptDialog';

import { iconHomeyScript } from '../../theme/icons/system/homeyscript';
import { iconDownload } from '../../theme/icons/interface/download';

export function ScriptPage() {
  const editorInstanceRef = useRef();
  const location = useLocation();

  const { i18n } = useI18n();
  const { error, loading, installing } = useScripts();

  const { scriptRouteId } = useScriptRouteId();

  useEffect(() => {
    if (loading || installing || error || scriptRouteId == null) {
      return;
    }

    ScriptStore.getScript({ id: scriptRouteId }).catch(console.error);
  }, [loading, installing, error, scriptRouteId]);

  function handleInstallPress() {
    if (installing === false) {
      ScriptStore.install();
    }
  }

  let content = null;

  switch (true) {
    case error?.code === 404:
      content = (
        <CenteredMessage
          maskColor={theme.color.text_light}
          mask={iconHomeyScript}
          titleColor={theme.color.text_light}
          title={i18n.messageFormatter('script.installTitle')}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('script.installSubtitle')}
          action={
            <GradientButton onPress={handleInstallPress}>
              <SpinnerFade
                isActive={installing === true}
                size={theme.icon.size_small}
                color={theme.color.white}
              >
                <GradientButton.Icon
                  url={iconDownload}
                  size={theme.icon.size_small}
                  color={theme.color.white}
                />
              </SpinnerFade>
              <GradientButton.Text>
                {installing === true
                  ? i18n.messageFormatter('script.installing')
                  : i18n.messageFormatter('script.install')}
              </GradientButton.Text>
            </GradientButton>
          }
        />
      );
      break;

    case error instanceof VersionError.Homey:
      content = (
        <CenteredMessage
          titleColor={theme.color.text_light}
          title={i18n.messageFormatter('script.invalidHomeyVersionTitle')}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('script.invalidHomeyVersionSubtitle')}
        />
      );
      break;

    case error instanceof VersionError.App:
      content = (
        <CenteredMessage
          titleColor={theme.color.text_light}
          title={i18n.messageFormatter('script.invalidScriptVersionTitle')}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('script.invalidScriptVersionSubtitle')}
          action={
            <GradientButton onPress={handleInstallPress}>
              <SpinnerFade
                isActive={installing === true}
                size={theme.icon.size_small}
                color={theme.color.white}
              >
                <GradientButton.Icon
                  url={iconDownload}
                  size={theme.icon.size_small}
                  color={theme.color.white}
                />
              </SpinnerFade>
              <GradientButton.Text>
                {installing === true
                  ? i18n.messageFormatter('script.installing')
                  : i18n.messageFormatter('script.install')}
              </GradientButton.Text>
            </GradientButton>
          }
        />
      );
      break;

    case loading === true:
      content = (
        <CenteredMessage
          icon={<SpinnerLoading color={theme.color.text_light} size={40} />}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('status.loading')}
        />
      );
      break;

    case scriptRouteId == null:
      content = (
        <CenteredMessage
          maskColor={theme.color.text_light}
          mask={iconHomeyScript}
          titleColor={theme.color.text_light}
          title={i18n.messageFormatter('script.selectTitle')}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('script.selectSubtitle')}
        />
      );
      break;

    default:
      content = <ScriptEditor editorInstanceRef={editorInstanceRef} />;
      break;
  }

  const isReady = loading === false && installing === false && error == null;
  const showActions = isReady && scriptRouteId != null;
  const subNavigation = isReady
    ? {
        settings: {
          key: 'scripts',
          width: 280,
          title: 'HomeyScript',
        },
        component: <ScriptNavigation />,
      }
    : null;

  return (
    <Layout
      subNavigationProps={subNavigation?.settings}
      subNavigationChildren={subNavigation?.component}
      actions={showActions && <ScriptActions editorInstanceRef={editorInstanceRef} />}
      content={content}
    >
      <AnimatePresence>
        {isReady && location.state?.[RouteManager.dialogState.newScriptDialog] === true && (
          <ScriptDialog />
        )}
      </AnimatePresence>
    </Layout>
  );
}

export default ScriptPage;
