import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { useDragLayer } from 'react-dnd';
import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';

import { MemoConnection } from './Connection';

import { connectorTypeColorMap, connectorOutputTypesList } from '../connectors/connectorTypes';

export function Connections(props) {
  const advancedFlowViewContext = useAdvancedFlowViewContext();

  const [hoveredConnectionKey, setHoveredConnectionKey] = useState(null);
  const [focusedConnectionkey, setFocusedConnectionKey] = useState(null);

  // Calculate current drag connection.
  const collectedProps = useDragLayer((monitor) => {
    if (monitor.isDragging() !== true) {
      return {};
    }

    // Also prevents pointer-events from connections.
    if (connectorOutputTypesList.includes(monitor.getItemType()) === false) {
      return {
        isDragging: monitor.isDragging(),
      };
    }

    const initialSourceOffset = monitor.getInitialSourceClientOffset(); // element
    const currentClientOffset = monitor.getClientOffset(); // mouse
    const item = monitor.getItem();
    const itemType = monitor.getItemType();

    if (initialSourceOffset == null || currentClientOffset == null) {
      return {
        isDragging: monitor.isDragging(),
      };
    }

    const connector = advancedFlowViewContext.getConnector({ type: itemType, nodeId: item.nodeId });

    if (connector == null) {
      throw new Error('Missing connector for drag event');
    }

    // Get the from connector position relative to the offset parent.
    const offsetParentElement = props.containerRef.current;
    const connectorPosition = connector.getPosition({ offsetParentElement });

    // Attach on the center.
    const startX = connectorPosition.x + connectorPosition.width / 2;
    const startY = connectorPosition.y + connectorPosition.height / 2;

    // Get the current cursor position relative to the offset parent. Size its a set size we dont
    // have to adjust for scroll.
    const rootRect = offsetParentElement.getBoundingClientRect();
    const currentX = currentClientOffset.x - rootRect.left;
    const currentY = currentClientOffset.y - rootRect.top;

    const path = `M ${startX} ${startY} L ${currentX} ${currentY}`;

    const selected = AdvancedFlowViewStore.getSelected();

    // If something was selected remove the selection.
    if (selected?.size > 0) {
      AdvancedFlowViewStore.setSelected(new Map([]));
    }

    return {
      path,
      color: connectorTypeColorMap[monitor.getItemType()].default ?? 'black',
      fromX: startX,
      fromY: startY,
      toX: currentX,
      toY: currentY,
      isDragging: monitor.isDragging(),
    };
  });

  /**
   * Later in object means higher priority.
   */
  const connectionsSortedByDrawPriority = useMemo(() => {
    if (props.connections == null) {
      return {};
    }

    const connections = {};
    let hoveredConnection = null;
    let focusedConnection = null;

    for (const connection of Object.values(props.connections)) {
      if (connection.key === focusedConnectionkey) {
        focusedConnection = connection;
        continue;
      }

      if (connection.key === hoveredConnectionKey) {
        hoveredConnection = connection;
        continue;
      }

      connections[connection.key] = connection;
    }

    return {
      ...connections,
      ...(focusedConnection && { [focusedConnection.key]: focusedConnection }),
      ...(hoveredConnection && { [hoveredConnection.key]: hoveredConnection }),
    };
  }, [props.connections, hoveredConnectionKey, focusedConnectionkey]);

  return (
    <>
      {collectedProps?.path != null &&
        ReactDOM.createPortal(
          <g>
            <circle
              cx={collectedProps.fromX}
              cy={collectedProps.fromY}
              r="3"
              fill={collectedProps.color}
            />
            <circle
              cx={collectedProps.toX}
              cy={collectedProps.toY}
              r="3"
              fill={collectedProps.color}
            />
            <path
              d={collectedProps.path}
              fill="transparent"
              stroke={collectedProps.color}
              strokeWidth={2}
            />
          </g>,
          props.SVGPortalLayerRef.current
        )}

      {Object.values(connectionsSortedByDrawPriority ?? {}).map((connection) => {
        return (
          <MemoConnection
            key={connection.key}
            containerRef={props.containerRef}
            connectionKey={connection.key}
            connection={connection}
            conditionTest={props.conditionTest}
            conditionSave={props.conditionSave}
            isDragging={collectedProps.isDragging ?? false}
            isInteractionDisabled={props.isInteractionDisabled}
            setHoveredConnectionKey={setHoveredConnectionKey}
            setFocusedConnectionKey={setFocusedConnectionKey}
          />
        );
      })}
    </>
  );
}
