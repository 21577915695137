import styled from '@emotion/styled';
import { useListBoxSection, useSeparator } from 'react-aria';

import { ResolutionSelectOption } from './ResolutionSelectOption';
import { theme } from '../../../theme/theme';

export function ResolutionSelectSection({ section, selectState }) {
  const listBoxSection = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const seperator = useSeparator({
    elementType: 'li',
  });

  return (
    <>
      {section.key !== selectState.collection.getFirstKey() && (
        <ResolutionSelectSection.Seperator {...seperator.separatorProps} />
      )}
      <ResolutionSelectSection.Root {...listBoxSection.itemProps}>
        <ResolutionSelectSection.Options {...listBoxSection.groupProps}>
          {[...section.childNodes].map((node) => (
            <ResolutionSelectOption key={node.key} item={node} selectState={selectState} />
          ))}
        </ResolutionSelectSection.Options>
      </ResolutionSelectSection.Root>
    </>
  );
}

ResolutionSelectSection.Root = styled.li``;
ResolutionSelectSection.Seperator = styled.li`
  width: 1px;
  margin: 0 30px;
  background-color: ${theme.color.line};
`;
ResolutionSelectSection.Options = styled.ul``;
