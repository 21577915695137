import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ScriptStore } from './ScriptStore';
import { useAttach } from '../../store/useAttach';

import { selectBase, selectById } from '../../store/selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    appInstance: state.appInstance,
    app: state.app,
    byId: state.byId,
    installing: state.installing,
  };
}

function selectLogs(state) {
  return {
    logs: state.logs,
    result: state.result,
  };
}

export function useScriptsById() {
  useAttach(ScriptStore, 'scriptssById');

  return ScriptStore.store(selectById, shallow);
}

export function useScripts() {
  useAttach(ScriptStore, 'scripts');

  return ScriptStore.store(selectAll, shallow);
}

export function useScript({ scriptId }) {
  useAttach(ScriptStore, 'script');

  const select = useCallback(
    (state) => {
      return {
        script: state.byId?.[scriptId] ?? null,
        appApiVersion: state.appApiVersion,
        manager: state.manager,
      };
    },
    [scriptId]
  );

  return ScriptStore.store(select, shallow);
}

export function useScriptLogs() {
  useAttach(ScriptStore, 'scriptLogs');

  return ScriptStore.store(selectLogs, shallow);
}

export function useScriptsAttach() {
  useAttach(ScriptStore, 'useScriptsAttach');
}
