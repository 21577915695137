export function templateFlow({ messageFormatter, locale, apiVersion }) {
  function makeOwnerUriAndId({ ownerUri, id }) {
    if (apiVersion >= 3) {
      // New Homey and Cloud do not have speakers.
      if (ownerUri === 'homey:manager:speech-output' && id === 'say') {
        ownerUri = 'homey:manager:notifications';
        id = 'create_notification';
      }

      return {
        ownerUri,
        id: `${ownerUri}:${id}`,
      };
    }

    return {
      ownerUri,
      id,
    };
  }

  const result = {
    name: 'My Advanced Flow',
    folder: null,
    cards: {
      '11f9b96a-e879-4f80-b620-935388264d72': {
        type: 'start',
        x: 20,
        y: 420,
        outputSuccess: ['36bd062a-650c-4358-a7e9-ed41cbe662b3'],
      },
      '36bd062a-650c-4358-a7e9-ed41cbe662b3': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:logic',
          id: 'random',
        }),
        args: {
          chance: 50,
        },
        type: 'condition',
        x: 146,
        y: 423,
        outputFalse: ['9bd85c85-543d-417d-912f-2d2164b57ef8'],
        outputTrue: ['1c2948e1-21ec-4510-a3f5-1d001fd2e675'],
      },
      '1c2948e1-21ec-4510-a3f5-1d001fd2e675': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:speech-output',
          id: 'say',
        }),
        args: {
          text: messageFormatter('templateAdvancedFlow.coinHeads'),
        },
        type: 'action',
        x: 600,
        y: 340,
      },
      '9bd85c85-543d-417d-912f-2d2164b57ef8': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:speech-output',
          id: 'say',
        }),
        args: {
          text: messageFormatter('templateAdvancedFlow.coinTails'),
        },
        type: 'action',
        x: 600,
        y: 500,
      },
      '7cc8d9a0-412f-4dcb-983d-a836bb3b0e2e': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.coinNote'),
        color: 'green',
        x: 0,
        y: 220,
        width: null,
        height: null,
      },
      '27c6abc9-46c2-456b-8cd1-253abf1aefa6': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.welcomeNote'),
        color: 'yellow',
        x: 0,
        y: 0,
        width: null,
        height: null,
      },
      '0fa9b651-1ed5-4c73-8361-9c32aa36e69b': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:logic',
          id: 'http_advanced',
        }),
        type: 'action',
        x: 0,
        y: 1700,
        outputSuccess: ['a36287cd-207c-4d1a-981a-b866caaa7d2c'],
        args: {
          method: 'get',
          url: 'https://api.chucknorris.io/jokes/random',
          headers: '',
          body: '',
        },
        outputError: ['c1d6bc30-b6ec-49e3-9ef6-598a339c2ebb'],
      },
      'a36287cd-207c-4d1a-981a-b866caaa7d2c': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:logic',
          id: 'get_string_from_json',
        }),
        droptoken: 'action::0fa9b651-1ed5-4c73-8361-9c32aa36e69b::response',
        args: {
          path: 'value',
        },
        type: 'action',
        x: 420,
        y: 1740,
        outputSuccess: ['3fd0595f-f3c2-4f7d-b718-da20e2507341'],
      },
      '3fd0595f-f3c2-4f7d-b718-da20e2507341': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:speech-output',
          id: 'say',
        }),
        args: {
          text: messageFormatter('templateAdvancedFlow.speechOne'),
        },
        type: 'action',
        x: 820,
        y: 1740,
        outputSuccess: ['c6a78ef8-c170-456c-b29c-79ced6aa4e4a'],
      },
      'c1d6bc30-b6ec-49e3-9ef6-598a339c2ebb': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:speech-output',
          id: 'say',
        }),
        args: {
          text:
            messageFormatter('templateAdvancedFlow.speechError') +
            ' [[card::0fa9b651-1ed5-4c73-8361-9c32aa36e69b::error]]',
        },
        type: 'action',
        x: 260,
        y: 1880,
      },
      'c6a78ef8-c170-456c-b29c-79ced6aa4e4a': {
        type: 'delay',
        args: {
          delay: {
            number: '3',
            multiplier: 1,
          },
        },
        x: 800,
        y: 1900,
        outputSuccess: ['2ffb0a35-ef7d-437d-b8b5-9a5a7498888e'],
      },
      '2ffb0a35-ef7d-437d-b8b5-9a5a7498888e': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:speech-output',
          id: 'say',
        }),
        args: {
          text: messageFormatter('templateAdvancedFlow.speechTwo'),
        },
        type: 'action',
        x: 960,
        y: 1900,
        outputSuccess: ['d195530e-828f-4a23-8847-f6765f2a0bb2'],
      },
      'd195530e-828f-4a23-8847-f6765f2a0bb2': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:speech-output',
          id: 'say',
        }),
        args: {
          text: '[[action::a36287cd-207c-4d1a-981a-b866caaa7d2c::created_tag]]',
        },
        type: 'action',
        x: 1340,
        y: 1900,
      },
      '6ae7fa9c-f5b6-4ee7-80c6-ae65d318a34d': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.speechNoteRequest'),
        color: 'yellow',
        x: 0,
        y: 1420,
        width: null,
        height: null,
      },
      '4f5321f3-3054-4867-8d0c-8a597515c091': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.speechNoteReadJson'),
        color: 'yellow',
        x: 420,
        y: 1600,
        width: null,
        height: null,
      },
      '5cdc89c4-eb4d-4570-8dbb-4a4c4c87bb5d': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.speechNoteParseJson'),
        color: 'yellow',
        x: 820,
        y: 1640,
        width: null,
        height: null,
      },
      'ea7074ff-882d-4f2c-a561-2e1d0642d716': {
        type: 'all',
        x: 1040,
        y: 1118,
        input: [
          '7b3d1fa5-5af0-43eb-9211-5423ca8777ae::outputSuccess',
          'b6f2d717-46f4-48e3-be01-461710e8ce0f::outputSuccess',
          '1c00cc43-230c-4923-8165-c54b9d8a347c::outputSuccess',
        ],
        outputSuccess: ['0f7b5e89-6a53-440c-85f0-ac108e2cd1ad'],
      },
      '5afc3470-2f02-432b-ab21-4cbceb380976': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.blocksNoteDelay'),
        color: 'blue',
        x: 420,
        y: 1000,
        width: null,
        height: null,
      },
      '5e92a0e8-e758-4b18-b606-e8b1933878dd': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.speechNoteError'),
        color: 'yellow',
        x: 260,
        y: 1980,
        width: null,
        height: null,
      },
      'b6f2d717-46f4-48e3-be01-461710e8ce0f': {
        type: 'delay',
        args: {
          delay: {
            number: '2',
            multiplier: 1,
          },
        },
        x: 620,
        y: 1138,
        outputSuccess: [
          'ea7074ff-882d-4f2c-a561-2e1d0642d716',
          '26b275c1-fe4f-465d-a2e8-78b88a558e8c',
        ],
      },
      '7b3d1fa5-5af0-43eb-9211-5423ca8777ae': {
        type: 'delay',
        args: {
          delay: {
            number: '1',
            multiplier: 1,
          },
        },
        x: 620,
        y: 1078,
        outputSuccess: [
          'ea7074ff-882d-4f2c-a561-2e1d0642d716',
          '26b275c1-fe4f-465d-a2e8-78b88a558e8c',
        ],
      },
      '1c00cc43-230c-4923-8165-c54b9d8a347c': {
        type: 'delay',
        args: {
          delay: {
            number: '3',
            multiplier: 1,
          },
        },
        x: 620,
        y: 1198,
        outputSuccess: [
          'ea7074ff-882d-4f2c-a561-2e1d0642d716',
          '26b275c1-fe4f-465d-a2e8-78b88a558e8c',
        ],
      },
      '177bca0e-4af1-4842-afec-46182200f467': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:cron',
          id: 'time_exactly',
        }),
        args: {
          time: '13:37',
        },
        type: 'trigger',
        x: 0,
        y: 1118,
        outputSuccess: [
          '7b3d1fa5-5af0-43eb-9211-5423ca8777ae',
          'b6f2d717-46f4-48e3-be01-461710e8ce0f',
          '1c00cc43-230c-4923-8165-c54b9d8a347c',
        ],
      },
      '26b275c1-fe4f-465d-a2e8-78b88a558e8c': {
        type: 'any',
        x: 1040,
        y: 1278,
        outputSuccess: ['92a28568-8614-4143-aa30-e153a9b5ebe5'],
      },
      '0f7b5e89-6a53-440c-85f0-ac108e2cd1ad': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:notifications',
          id: 'create_notification',
        }),
        args: {
          text: messageFormatter('templateAdvancedFlow.blocksAllDone'),
        },
        type: 'action',
        x: 1200,
        y: 1138,
      },
      '92a28568-8614-4143-aa30-e153a9b5ebe5': {
        ...makeOwnerUriAndId({
          ownerUri: 'homey:manager:notifications',
          id: 'create_notification',
        }),
        args: {
          text: messageFormatter('templateAdvancedFlow.blocksAnyDone'),
        },
        type: 'action',
        x: 1200,
        y: 1238,
      },
      'a81aa2ef-b72e-49b1-bf99-619097e7c210': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.blocksNoteIntro'),
        color: 'blue',
        x: 0,
        y: 840,
        width: null,
        height: null,
      },
      '3a2bf667-4257-409a-8704-69f4a0b210ac': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.blocksNoteAll'),
        color: 'blue',
        x: 880,
        y: 1020,
        width: null,
        height: null,
      },
      '6c35b458-180a-4c5a-96ee-a4dc119e5dda': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.blocksNoteAny'),
        color: 'blue',
        x: 1060,
        y: 1358,
        width: null,
        height: null,
      },
      'e232d25f-bfdb-42b2-86ad-13a520792b35': {
        type: 'note',
        value: messageFormatter('templateAdvancedFlow.coinStartNote'),
        color: 'green',
        x: 20,
        y: 520,
        width: null,
        height: null,
      },
    },
  };

  if (positionOverrides[locale] != null) {
    Object.entries(positionOverrides[locale]).forEach(([key, override]) => {
      result.cards[key] = {
        ...result.cards[key],
        ...override,
      };
    });
  }

  return result;
}

export const positionOverrides = {
  da: {
    '5afc3470-2f02-432b-ab21-4cbceb380976': {
      x: 420,
      y: 960,
    },
  },
  de: {},
  en: {},
  es: {
    '5afc3470-2f02-432b-ab21-4cbceb380976': {
      x: 420,
      y: 960,
    },
    '3a2bf667-4257-409a-8704-69f4a0b210ac': {
      x: 880,
      y: 980,
    },
    'e232d25f-bfdb-42b2-86ad-13a520792b35': {
      x: 20,
      y: 540,
    },
  },
  fr: {
    '5afc3470-2f02-432b-ab21-4cbceb380976': {
      x: 420,
      y: 960,
    },
  },
  it: {
    '5afc3470-2f02-432b-ab21-4cbceb380976': {
      x: 420,
      y: 960,
    },
    '3a2bf667-4257-409a-8704-69f4a0b210ac': {
      x: 880,
      y: 1000,
    },
    '5e92a0e8-e758-4b18-b606-e8b1933878dd': {
      x: 260,
      y: 2000,
    },
  },
  nl: {
    '5afc3470-2f02-432b-ab21-4cbceb380976': {
      x: 420,
      y: 980,
    },
  },
  no: {},
  pl: {
    '3a2bf667-4257-409a-8704-69f4a0b210ac': {
      x: 880,
      y: 980,
    },
    '5e92a0e8-e758-4b18-b606-e8b1933878dd': {
      x: 260,
      y: 2000,
    },
  },
  ru: {
    '7cc8d9a0-412f-4dcb-983d-a836bb3b0e2e': {
      x: 0,
      y: 210,
    },
    '3a2bf667-4257-409a-8704-69f4a0b210ac': {
      x: 880,
      y: 1000,
    },
    '4f5321f3-3054-4867-8d0c-8a597515c091': {
      x: 420,
      y: 1580,
    },
    '5cdc89c4-eb4d-4570-8dbb-4a4c4c87bb5d': {
      x: 820,
      y: 1625,
    },
  },
  sv: {},
};
