import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { GeneralSettingsSection } from './GeneralSettingsSection';

const STRATEGIES = {
  unknown: 'Unknown',
  localSecure: 'Local Secure',
  local: 'Local',
  remoteForwarded: 'Remote Forwarded',
  cloud: 'Cloud',
  mdns: 'MDNS',
};

export function GeneralSettingsInfoWebApp(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  return (
    <GeneralSettingsSection.S.Section>
      <GeneralSettingsSection.S.SectionHeading>Web App</GeneralSettingsSection.S.SectionHeading>
      <GeneralSettingsSection.S.SectionView>
        <GeneralSettingsSection.S.Row>
          <GeneralSettingsSection.S.Label>
            {i18n.messageFormatter('common.version')}
          </GeneralSettingsSection.S.Label>
          <GeneralSettingsSection.S.Value>
            {import.meta.env.VITE_VERSION}
          </GeneralSettingsSection.S.Value>
        </GeneralSettingsSection.S.Row>

        {api?.isCloud === false && (
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('common.connection')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {STRATEGIES[api?.strategy ?? 'unknown']}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
        )}
      </GeneralSettingsSection.S.SectionView>
    </GeneralSettingsSection.S.Section>
  );
}
