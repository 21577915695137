import { useMemo } from 'react';

import { UserMeStore } from '../../store/user-me/UserMeStore';

import { useEffectEvent } from '../../hooks/useEffectEvent';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useFlowsById } from '../../store/flow/useFlows';
import { useAdvancedFlowsById } from '../../store/advanced-flow/useAdvancedFlows';
import { useUserMe } from '../../store/user-me/useUserMe';

import { typography } from '../../components/primitives/typography';

import { Flow } from '../../components/flow/Flow';
import { AdvancedFlow } from '../../components/flow/AdvancedFlow';
import { FlowFlex } from '../../components/flow/FlowGrid';
import { ContentLoader } from '../../components/content-loader/ContentLoader';

export function FavoriteFlows({ isSiblingLoading }) {
  const { i18n } = useI18n();
  const userMe = useUserMe();
  const flows = useFlowsById();
  const advancedFlows = useAdvancedFlowsById();

  const favoriteFlowsWithType = useMemo(() => {
    if (flows.byId == null || advancedFlows.byId == null || userMe.favoriteFlows == null) return [];

    const favoriteFlowsWithType = [];

    for (const flowId of userMe.favoriteFlows) {
      // Maybe favoriteFlows has been polluted.
      const id = String(flowId);

      if (flows.byId[id] != null) {
        favoriteFlowsWithType.push({
          type: 'default',
          id: id,
        });
        continue;
      }

      if (advancedFlows.byId[id] != null) {
        favoriteFlowsWithType.push({
          type: 'advanced',
          id: id,
        });
      }
    }

    return favoriteFlowsWithType;
  }, [flows.byId, advancedFlows.byId, userMe.favoriteFlows]);

  const handleRequestSwap = useEffectEvent(({ firstId, secondId }) => {
    UserMeStore.swapFavoriteFlows({
      firstId,
      secondId,
    });
  });

  const handleDrop = useEffectEvent(() => {
    UserMeStore.saveFavoriteFlows();
  });

  if (flows.loading || advancedFlows.loading || userMe.loading || isSiblingLoading) {
    return (
      <FlowFlex.Root>
        {Array.from({ length: 2 }).map((_, index) => {
          return (
            <FlowFlex.Item key={index} data-animated={true}>
              <ContentLoader.Flow />
            </FlowFlex.Item>
          );
        })}
      </FlowFlex.Root>
    );
  }

  if (flows.error || advancedFlows.error || userMe.error) {
    const message =
      flows.error?.message ?? advancedFlows.error?.message ?? userMe.error?.message ?? '';
    return <typography.body1>{message}</typography.body1>;
  }

  let result = null;

  if (flows.byId != null && advancedFlows.byId != null) {
    result = favoriteFlowsWithType.reduce((accumulator, favoriteFlow, index) => {
      let flow = null;

      if (favoriteFlow.type === 'advanced') {
        flow = advancedFlows.byId[favoriteFlow.id];
      } else {
        flow = flows.byId[favoriteFlow.id];
      }

      if (flow == null) return accumulator;

      let component = null;

      if (favoriteFlow.type === 'advanced') {
        component = (
          <AdvancedFlow
            advancedFlowId={favoriteFlow.id}
            onRequestSwap={handleRequestSwap}
            onDrop={handleDrop}
          />
        );
      } else {
        component = (
          <Flow flowId={favoriteFlow.id} onRequestSwap={handleRequestSwap} onDrop={handleDrop} />
        );
      }

      accumulator.push(
        <FlowFlex.Item
          key={favoriteFlow.id}
          style={{
            '--animation-delay': `${10 * index}ms`,
          }}
        >
          {component}
        </FlowFlex.Item>
      );

      return accumulator;
    }, []);
  }

  if (result?.length > 0) {
    return <FlowFlex.Root>{result}</FlowFlex.Root>;
  }

  return <p>{i18n.messageFormatter(`home.noFavoriteFlows`)}</p>;
}
