import React from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { RouteManager } from '../../../RouteManager';
import { FlowCardUtils } from '../../../store/flow-cards/FlowCardUtils';
import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { FlowCardExplorerDialog } from '../flow-card-explorer-dialog/FlowCardExplorerDialog';

export function AdvancedFlowViewCardDialog() {
  const location = useLocation();

  function handleCloseRequest() {
    RouteManager.replaceState({});
  }

  function handleCardSelect(cardKey, cardInstance) {
    RouteManager.replaceState({});
    const cardData = FlowCardUtils.generateAdvancedFlowCardData(cardInstance);
    cardData.type = location.state.cardDialog.type;

    if (location.state.cardDialog.currentCard != null) {
      AdvancedFlowViewStore.replaceCard({
        nodeId: location.state.cardDialog.nodeId,
        cardData: cardData,
      });
      return;
    }

    // TODO
    // check if we actually have a pointer
    // e.g. what happens on touch only
    if (location.state.cardDialog.pointer == null) {
      AdvancedFlowViewStore.setCardCursorData({
        cardInstance: cardInstance,
        cardData: cardData,
      });
      return;
    }

    // This is no longer used.
    AdvancedFlowViewStore.addCard({
      cardData: {
        ...cardData,
        x: location.state.cardDialog.pointer?.x ?? 0,
        y: location.state.cardDialog.pointer?.y ?? 0,
      },
    });
  }

  return (
    <AnimatePresence>
      {location.state?.cardDialog != null && (
        <FlowCardExplorerDialog
          cardType={location.state.cardDialog.type}
          currentCard={location.state.cardDialog.currentCard}
          onCloseRequest={handleCloseRequest}
          onCardSelect={handleCardSelect}
        />
      )}
    </AnimatePresence>
  );
}
