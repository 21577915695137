import React, { useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  useSlider,
  useSliderThumb,
  useFocusRing,
  VisuallyHidden,
  mergeProps,
  useNumberFormatter,
} from 'react-aria';
import { useSliderState } from 'react-stately';

import { theme } from '../../../theme/theme';

Slider.propTypes = {
  /** The element's unique identifier. */
  id: PropTypes.string,
  /** Defines a string value that labels the current element. */
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  'aria-describedby': PropTypes.string,
  'aria-details': PropTypes.string,
  formatOptions: PropTypes.object,
  /** The slider's maximum value. */
  maxValue: PropTypes.number,
  /** The slider's minimum value. */
  minValue: PropTypes.number,
  /** The slider's step value.. */
  step: PropTypes.number,
  /** The current value (controlled). */
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  /** The default value (uncontrolled). */
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  /** Handler that is called when the value changes. */
  onChange: PropTypes.func,
  /** Fired when the slider stops moving, due to being let go. */
  onChangeEnd: PropTypes.func,
  /** Whether the whole Slider is disabled. */
  isDisabled: PropTypes.bool,
};

Slider.defaultProps = {};

/**
 * A vertical Slider component that is ment to be used in device controls.
 *
 * https://react-spectrum.adobe.com/react-aria/useSlider.html
 */
export function Slider(props) {
  const trackRef = useRef();
  const numberFormatter = useNumberFormatter(props.formatOptions);
  const state = useSliderState({ ...props, numberFormatter });
  const slider = useSlider({ ...props, orientation: 'vertical' }, state, trackRef);

  if (PointerEvent === undefined) {
    delete slider.trackProps.onPointerDown;
  }

  return (
    <Slider.Root {...slider.groupProps} style={props.style} className={props.className}>
      <Slider.Track
        {...slider.trackProps}
        ref={trackRef}
        data-is-disabled={props.isDisabled}
        style={{
          '--slider-top': `${(1 - state.getThumbPercent(0)) * 100}%`,
        }}
      >
        {/*<Slider.Output {...slider.outputProps}>{state.getThumbValueLabel(0)}</Slider.Output>*/}
        <Slider.Inner>
          <Slider.Fill />
          <Thumb
            index={0}
            state={state}
            trackRef={trackRef}
            autoFocus={props.autoFocus}
            isDisabled={props.isDisabled}
          />
        </Slider.Inner>
      </Slider.Track>
    </Slider.Root>
  );
}

function Thumb(props) {
  const { state, trackRef, index } = props;
  const inputRef = useRef();
  const sliderThumb = useSliderThumb(
    {
      orientation: 'vertical',
      autoFocus: props.autoFocus,
      index,
      trackRef,
      inputRef,
    },
    state
  );
  const focusRing = useFocusRing();
  const isDragging = state.isThumbDragging(index);

  return (
    <Thumb.Root>
      <Thumb.Inner
        {...sliderThumb.thumbProps}
        data-is-disabled={props.isDisabled}
        data-is-dragging={isDragging}
        data-is-focus-visible={focusRing.isFocusVisible}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(sliderThumb.inputProps, focusRing.focusProps)} />
        </VisuallyHidden>
      </Thumb.Inner>
    </Thumb.Root>
  );
}

Slider.Root = styled.div`
  touch-action: none;
  max-height: 320px;
  min-width: 100px;
  height: 100%;
`;

Slider.Fill = styled.div`
  position: absolute;
  top: var(--slider-top);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.color.white_o_100};
  // @jeroen this animation is causing glitch issues while dragging the slider
  // transition: top ${theme.duration.fast} ${theme.curve.easeInOut};
`;

Slider.Track = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.white_o_50};
  border-radius: 10px;

  &[data-is-disabled='true'] {
    cursor: not-allowed;
  }
`;

// Slider.Output = styled.output`
//   position: absolute;
//   left: -50%;
//   top: var(--slider-top);
//   transform: translateY(-50%);
// `;

Slider.Inner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${theme.color.white_o_50};
  border-radius: 10px;
  overflow: hidden;
`;

Thumb.Root = styled.div`
  position: absolute;
  left: 50%;
  top: var(--slider-top);
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
`;

/**
 * We hide this because it's not offically in the design but this is
 * the focusable handle. States can be applied but it has 0 opacity right now.
 */
Thumb.Inner = styled.div`
  height: 10px;
  background-color: ${theme.color.white_o_100};
  cursor: pointer;
  opacity: 0;

  &[data-is-disabled='true'] {
    cursor: not-allowed;
  }

  &[data-is-dragging='true'] {
    background-color: ${theme.color.white_o_100};
  }

  &[data-is-focus-visible='true'] {
    background-color: orange;
  }
`;
