import { proxy as palette } from "../color.palette";
import { proxy as functional } from "../color.functional";

const color = {...palette, ...functional};

export const flowcard = {
  background: color.mono_100,

  /* States */
  disabled_background_color: color.mono_200,
  disabled_icon_color: color.mono_300,

  dropzone_target_outline: color.blue_o_30,

  /* Elements */
  // Any / All
  title_color: color.mono_400
};
