import styled from '@emotion/styled';
import React from 'react';
import { useTabPanelContext } from '../tabs/TabPanel';

export function ControlsComponentTabPanel(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, tabPanel, item, value, state } = useTabPanelContext();

  const isSelected = state.selectedKey === item.key;
  const isDisabled = state.disabledKeys.has(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  return (
    <ControlsComponentTabPanel.Root
      {...tabPanel.tabPanelProps}
      ref={ref}
      data-is-selected={isSelected}
      data-is-disabled={isDisabled}
      data-is-focused={isFocused}
    >
      {props.children}
    </ControlsComponentTabPanel.Root>
  );
}

ControlsComponentTabPanel.Root = styled.div`
  flex: 0 0 100%;
`;
