import { useButton } from 'react-aria';

/**
 * If we dont add draggable react-aria prevents default so the element cant be dragged.
 */
export function useDraggableButton(props, ref) {
  const button = useButton(props, ref);
  // https://github.com/adobe/react-spectrum/blob/6434e08b4ab3585a993aee598d1ab5237b261576/packages/%40react-aria/interactions/src/usePress.ts#L767
  button.buttonProps.draggable = true;

  return button;
}
