import { keyframes } from '@emotion/react';

export const animationScaleFade = {
  in: keyframes`
    from {
      opacity: 0;
      transform: scale(0.8);
      transform-origin: var(--transform-origin);
    }

    to {
      opacity: 1;
      transform:  scale(1);
      transform-origin: var(--transform-origin);
    }
  `,
  out: keyframes`
    from {
      opacity: 1;
      transform:  scale(1);
      transform-origin: var(--transform-origin);
    }

    to {
      opacity: 0;
      transform:  scale(0.8);
      transform-origin: var(--transform-origin);
    }
  `,
};

export const animationScaleFade2 = {
  in: keyframes`
    0% {
      opacity: 0;
      transform: scale(.9);
    }

    80% {
      transform: scale(1.02);
    }

    100% {
      opacity: 1;
      transform: scale(1);
      position: initial;
    }
  `,
};
