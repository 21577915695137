import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import {
  useButton,
  useKeyboard,
  useHover,
  FocusScope,
  OverlayContainer,
  mergeProps,
} from 'react-aria';
import offset from 'dom-helpers/offset';
import { Link } from 'react-router-dom';

import { mergeRefs } from '../../../lib/mergeRefs';

import { useContextMenuContext } from './ContextMenuContextProvider';
import { useOverlayContext } from '../../overlay/OverlayContext';

import { theme } from '../../../theme/theme';
import { scrollbars } from '../../../theme/elements/scrollbars';
import { su } from '../../../theme/functions/su';

import { OverlayStackContextConsumer } from '../../overlay/OverlayStackContextConsumer';
import { AnchorPointer } from '../AnchorPointer';
import { Tooltip } from '../tooltip/Tooltip';
import { Icon } from '../Icon';

import { iconChevronSmallRight } from '../../../theme/icons/interface/chevron-small-right/chevron-small-right';

const ParentContext = createContext(null);

export function ContextMenuContent() {}

// todo
// max height support
function ContextMenuContentListBase(props) {
  const keyboard = useKeyboard({
    onKeyDown(event) {
      if (event.key === 'Escape') {
        props.onCloseRequest?.();
      }
    },
  });

  // Make the container focusable so it's always possible to focus something. Else the focus remains in inputs and
  // it might not be possible to select any text or other elements.
  return (
    <ContextMenuContent.List.Root
      {...keyboard.keyboardProps}
      tabIndex={-1}
      as={props.as}
      style={props.style}
    >
      {props.children}
    </ContextMenuContent.List.Root>
  );
}

function ContextMenuContentList(props) {
  const context = useContextMenuContext();

  function handleCloseRequest() {
    context.onCloseRequest();
  }

  // If contain is set to true inputs inside a submenu cant be focused.
  return (
    <FocusScope autoFocus={true} contain={false} restoreFocus={false}>
      <ContextMenuContentListBase {...props} onCloseRequest={handleCloseRequest} />
    </FocusScope>
  );
}

const CustomLink = forwardRef(({ navigate, target, isDisabled, ...rest }, ref) => {
  return (
    <a
      {...rest}
      ref={ref}
      target={target}
      onClick={(event) => {
        if (isDisabled === true) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        // Copied from default react router link implementation.
        if (
          !event.defaultPrevented &&
          event.button === 0 &&
          (!target || target === '_self') &&
          !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
        ) {
          event.preventDefault();
          navigate();
        }

        rest.onClick(event);
      }}
    >
      {rest.children}
    </a>
  );
});

function ContextMenuContentListItemBase(props) {
  const buttonRef = useRef();
  const button = useButton(
    {
      ...props,
      onPress(...args) {
        props.onPress?.(...args);
      },
      elementType: props.to ? CustomLink : 'button',
    },
    buttonRef
  );

  const parent = useContext(ParentContext);

  const hover = useHover({
    onHoverStart(event) {
      props.onHoverStart?.(event, parent);
    },
  });

  if (props.isHidden === true) return null;

  function render(triggerRef, triggerProps) {
    if (props.to) {
      return (
        <ContextMenuContent.ListItem.Root
          data-is-disabled={props.isDisabled}
          _variant={props.variant}
        >
          <Link
            {...mergeProps(hover.hoverProps, triggerProps ?? {})}
            ref={mergeRefs([triggerRef])}
            to={props.to}
            component={CustomLink}
            isDisabled={props.isDisabled}
            onClick={() => {
              props.onPress?.();
            }}
          >
            {props.label != null && (
              <ContextMenuContent.ListItem.Label title={props.label}>
                {props.label}
              </ContextMenuContent.ListItem.Label>
            )}

            {props.children != null && (
              <ContextMenuContent.ListItem.Children>
                {props.children}
              </ContextMenuContent.ListItem.Children>
            )}

            {props.hint != null && (
              <ContextMenuContent.ListItem.Hint>{props.hint}</ContextMenuContent.ListItem.Hint>
            )}

            {props.icon != null && <Icon url={props.icon} size={theme.icon.size_small} />}
          </Link>
        </ContextMenuContent.ListItem.Root>
      );
    }

    return (
      <ContextMenuContent.ListItem.Root
        data-is-disabled={props.isDisabled}
        _variant={props.variant}
      >
        <button
          {...mergeProps(button.buttonProps, hover.hoverProps, triggerProps ?? {})}
          ref={mergeRefs([buttonRef, triggerRef])}
        >
          {props.label != null && (
            <ContextMenuContent.ListItem.Label title={props.label}>
              {props.label}
            </ContextMenuContent.ListItem.Label>
          )}

          {props.children != null && (
            <ContextMenuContent.ListItem.Children>
              {props.children}
            </ContextMenuContent.ListItem.Children>
          )}

          {props.hint != null && (
            <ContextMenuContent.ListItem.Hint>{props.hint}</ContextMenuContent.ListItem.Hint>
          )}

          {props.icon != null && <Icon url={props.icon} size={theme.icon.size_small} />}
        </button>
      </ContextMenuContent.ListItem.Root>
    );
  }

  if (props.tooltip) {
    return (
      <Tooltip
        delay={0}
        offset={10}
        placement="right"
        renderTrigger={(triggerRef, triggerProps) => {
          return render(triggerRef, triggerProps);
        }}
      >
        <ContextMenuContent.ListItem.TooltipContent>
          {props.tooltip}
        </ContextMenuContent.ListItem.TooltipContent>
      </Tooltip>
    );
  }

  return render();
}

function ContextMenuContentListItem(props) {
  const ctx = useContextMenuContext();

  function handlePress(...args) {
    ctx.onCloseRequest();
    props.onPress(...args);
  }

  function handleHoverStart(event, parent) {
    ctx.onHoverStart(event, parent);
  }

  return (
    <ContextMenuContentListItemBase
      {...props}
      onPress={handlePress}
      onHoverStart={handleHoverStart}
    />
  );
}

function ContextMenuContentSubMenu(props) {
  const context = useContextMenuContext();

  const [isOpen, setIsOpen] = useState(false);

  const buttonRef = useRef();
  const outerRef = useRef();
  const button = useButton(
    {
      ...props,
      onPress(event) {
        setIsOpen(true);
      },
      elementType: 'li',
    },
    buttonRef
  );

  const parent = useContext(ParentContext);

  const hover = useHover({
    onHoverStart(event) {
      if (props.isDisabled !== true) {
        context.onHoverStart(event, parent);
        setIsOpen(true);
      }
    },
  });

  const instance = useRef({
    onClose() {
      setIsOpen(false);
    },
    outerRef: outerRef,
    parent,
  });

  useLayoutEffect(() => {
    instance.current.parent = parent;
  });

  useEffect(() => {
    const current = instance.current;

    context.registerSubMenu(current);

    return function () {
      context.unregisterSubMenu(current);
    };
  }, [context]);

  useLayoutEffect(() => {
    if (isOpen === true) {
      const anchorOffset = offset(buttonRef.current);
      const menuOffset = offset(outerRef.current);

      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;

      const anchorRight = anchorOffset.width + anchorOffset.left + 10;
      const anchorLeft = anchorOffset.left - 10;
      const anchorTop = anchorOffset.top - 10;

      const nextX = anchorRight;
      const nextY = anchorTop;

      // TODO
      // These should probably reset the other so only 2 points are defined

      // For now we hide the AnchorPointer if it's placement is variable.
      if (nextX + menuOffset.width > clientWidth) {
        // grow left
        const xRight = clientWidth - anchorLeft;
        outerRef.current.style.setProperty('--x-right', `${xRight}px`);
        outerRef.current.style.setProperty('--anchor-pointer-display', 'none');
      } else {
        // grow right
        const xLeft = anchorRight;
        outerRef.current.style.setProperty('--x-left', `${xLeft}px`);
      }

      if (nextY + menuOffset.height > clientHeight) {
        // grow up
        const yBottom = clientHeight - anchorTop - anchorOffset.height - 10;
        outerRef.current.style.setProperty('--y-bottom', `${yBottom}px`);
        outerRef.current.style.setProperty('--anchor-pointer-display', 'none');
      } else {
        // grow down
        const yTop = anchorTop;
        outerRef.current.style.setProperty('--y-top', `${yTop}px`);
      }
    }
  }, [isOpen]);

  return (
    <ParentContext.Provider value={instance.current}>
      <ContextMenuContent.SubMenu.Root
        {...mergeProps(button.buttonProps, hover.hoverProps)}
        ref={buttonRef}
        data-is-open={isOpen}
      >
        <ContextMenuContent.ListItem.Label>{props.title}</ContextMenuContent.ListItem.Label>
        <Icon url={iconChevronSmallRight} size={theme.icon.size_default} />

        {isOpen && (
          <OverlayContainer>
            <OverlayStackContextConsumer
              overlayRef={outerRef}
              onCloseRequest={() => {
                setIsOpen(false);
              }}
            >
              <ContextMenuContentSubMenuOverlay overlayRef={outerRef}>
                {props.children}
              </ContextMenuContentSubMenuOverlay>
            </OverlayStackContextConsumer>
          </OverlayContainer>
        )}
      </ContextMenuContent.SubMenu.Root>
    </ParentContext.Provider>
  );
}

function ContextMenuContentSubMenuOverlay(props) {
  const overlayContext = useOverlayContext();

  return (
    <ContextMenuContent.SubMenu.Outer
      ref={props.overlayRef}
      data-stack-index={overlayContext.stackIndex}
      style={{ '--stack-index': overlayContext.stackIndex }}
    >
      <AnchorPointer placement="right" style={{ top: 30, left: 0 }} />
      <ContextMenuContent.SubMenu.Inner>{props.children}</ContextMenuContent.SubMenu.Inner>
    </ContextMenuContent.SubMenu.Outer>
  );
}

ContextMenuContent.List = ContextMenuContentList;
ContextMenuContent.ListBase = ContextMenuContentListBase;
ContextMenuContent.List.Root = styled.ul`
  ${scrollbars.dark};
  overflow-y: auto;
  outline: 0;
  padding: 10px 0;
  width: 200px;
`;

ContextMenuContent.ListItem = ContextMenuContentListItem;
ContextMenuContent.ListItemBase = ContextMenuContentListItemBase;

ContextMenuContent.ListItem.Children = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
`;

ContextMenuContent.ListItem.Label = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${su(2)};
  max-width: 200px;
  flex: 1 1 auto;
  text-align: left;
`;

ContextMenuContent.ListItem.Hint = styled.div`
  color: ${theme.icon.color_light};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: color;

  & + ${Icon.S.Root} {
    margin-left: 3px;
  }
`;

ContextMenuContent.ListItem.TooltipContent = styled.div`
  padding: 10px;
  color: ${theme.color.text_light};
`;

ContextMenuContent.ListItem.Root = styled.li`
  --text-color: inherit;
  --text-font-weight: inherit;
  --hover-icon-color: ${theme.icon.color_light_hover};
  --hover-background-color: ${theme.color.background_hover};

  display: flex;
  align-items: center;
  height: ${su(4)};
  outline: 0;
  color: var(--text-color);
  font-weight: var(--text-font-weight);
  cursor: pointer;
  user-select: none;
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: font-weight, background-color, color;

  ${Icon.S.Root} {
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: background-color;
    flex: 0 0 auto;
  }

  &:hover:not([data-is-disabled='true']),
  &[data-is-open='true'] {
    background-color: var(--hover-background-color);
    font-weight: ${theme.fontWeight.medium};

    ${Icon.S.Root} {
      background-color: var(--hover-icon-color);
    }

    ${ContextMenuContent.ListItem.Hint} {
      color: var(--hover-icon-color);
    }
  }

  button,
  a {
    padding: 0 ${su(2)};
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &[data-is-disabled='true'] a,
  &[data-is-disabled='true'] button {
    cursor: not-allowed;
    color: ${theme.color.text_disabled};
  }

  ${(props) => {
    if (props._variant === 'danger') {
      return {
        '--text-color': theme.color.danger_text,
        '--hover-background-color': theme.color.danger_background,
        '--hover-icon-color': theme.color.danger_icon_hover,
      };
    }

    if (props._variant === 'highlight') {
      return {
        '--text-color': theme.color.text_highlight,
        '--text-font-weight': theme.fontWeight.bold,
      };
    }
  }}
`;

ContextMenuContent.Divider = styled.div`
  height: 1px;
  background-color: ${theme.color.line};
  margin: 5px ${su(2)};
`;

ContextMenuContent.SubMenu = ContextMenuContentSubMenu;
ContextMenuContent.SubMenu.Root = styled(ContextMenuContent.ListItem.Root)`
  padding: 0 ${su(2)};
`;

ContextMenuContent.SubMenu.Outer = styled.div`
  position: absolute;
  left: var(--x-left);
  right: var(--x-right);
  top: var(--y-top);
  bottom: var(--y-bottom);
  z-index: calc(${theme.zIndex.overlay} + var(--stack-index, 0));
`;

ContextMenuContent.SubMenu.Inner = styled.div`
  ${scrollbars.dark};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};

  max-height: min(100vh, 400px);
  overflow-y: auto;
`;
