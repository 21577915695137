import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { theme } from '../../../../theme/theme';

import { connectorTypesMap } from './connectorTypes';

export function DropZone(props) {
  // eslint-disable-next-line no-unused-vars
  const [collectedProps, connectorInDropTarget] = useDrop(() => {
    const accept = [
      connectorTypesMap.Out,
      connectorTypesMap.Error,
      connectorTypesMap.True,
      connectorTypesMap.False,
    ];

    return {
      accept: accept,
      collect: (dropMonitor) => {
        return {
          isDragOver: dropMonitor.isOver({ shallow: true }),
          canDrop: dropMonitor.canDrop(),
          // This method seems to be called on any drag operation so it can receive
          // flowcard items even when they are not in accept. So check the accept array for
          // the current item type and dont return the item if we do not care about it.
          //
          // This seems like a bug because this component renders/collects for no reason now.
          item: accept.includes(dropMonitor.getItemType()) ? dropMonitor.getItem() : null,
        };
      },
      canDrop(dragItem, dropMonitor) {
        // not path.includes(me or my parents)

        if (dragItem.nodeId === props.nodeId || props.isDisabled) {
          return false;
        }

        function isDragItemChild(children) {
          if (children == null) {
            return false;
          }

          if (children.some((child) => child.id === dragItem.nodeId)) {
            return true;
          }

          for (const child of children) {
            const childChildren = props.nodeChildren[child.id];
            const result = isDragItemChild(childChildren);

            if (result === true) {
              return result;
            }
          }

          return false;
        }

        const children = props.nodeChildren[props.nodeId];
        const result = isDragItemChild(children);

        return result !== true;
      },
      hover(item, monitor) {
        // const currentClientOffset = monitor.getClientOffset();
        // const rect = dropZoneRef.current.getBoundingClientRect();
        // console.log(currentClientOffset.y - rect.y);
      },
      drop(dragItem, monitor) {
        // console.log('child', monitor.didDrop());

        if (monitor.didDrop()) return;

        const fromNodeId = dragItem.nodeId;

        AdvancedFlowViewStore.createOrderedConnection({
          fromNodeId: fromNodeId,
          fromConnectorType: monitor.getItemType(),
          toNodeId: props.nodeId,
          toConnectorType: connectorTypesMap.In,
          index: props.index,
        });
      },
    };
  }, [props.nodeId, props.nodeChildren, props.index, props.isDisabled]);

  const dropZoneRef = useRef();
  connectorInDropTarget(dropZoneRef);

  useEffect(() => {
    return function () {
      connectorInDropTarget(null);
    };
  }, [connectorInDropTarget]);

  return (
    <DropZone.Root
      data-is-first-child={props.isFirstChild}
      data-is-last-child={props.isLastChild}
      data-is-drag-over={collectedProps.isDragOver}
      data-can-drop={collectedProps.canDrop}
    >
      <DropZone.DropArea ref={dropZoneRef} />
      <DropZone.Target />
    </DropZone.Root>
  );
}

DropZone.Root = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  z-index: 10;

  &[data-is-last-child='true'] {
    top: 100%;
  }
`;

DropZone.DropArea = styled.div`
  position: absolute;
  left: calc(-200%);
  top: 0;
  width: calc(300% + 2px);
  height: 100%;
  //border: 1px solid white;
  //background-color: rgba(255, 0, 255, 0.5);

  ${DropZone.Root}[data-is-first-child='true'] & {
    //background-color: rgba(255, 128, 0, 0.2);
  }

  ${DropZone.Root}[data-is-last-child='true'] & {
    //background-color: rgba(0, 128, 255, 0.2);
  }
`;

DropZone.Target = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: ${theme.flowcard.dropzone_target_background};
  background-clip: padding-box;
  border: 3px solid ${theme.flowcard.dropzone_target_outline};
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  mask-image: radial-gradient(ellipse at -8px 50%, transparent 0, transparent 16px, black 16px);
  mask-size: 16px 32px;
  mask-position: center center;
  mask-repeat: no-repeat;
  opacity: 0;
  transition: 250ms ${theme.curve.fastInOut};
  transition-property: opacity, transform;

  ${DropZone.Root}[data-is-first-child='true'] & {
    clip-path: polygon(75% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  ${DropZone.Root}[data-is-last-child='true'] & {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 75% 100%);
  }

  ${DropZone.Root}[data-is-drag-over='true'][data-can-drop='true'] & {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
`;
