import { useRef, forwardRef } from 'react';
import styled from '@emotion/styled';

import { useSearchFieldState } from 'react-stately';
import { useSearchField } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { TagGroup } from './TagGroup';
import { Icon } from '../Icon';

import { iconMagnifyingGlass } from '../../../theme/icons/interface/magnifying-glass/magnifying-glass';

export const ChippedSearchField = forwardRef((props, forwardedRef) => {
  const inputRef = useRef();
  const searchFieldState = useSearchFieldState(props);
  const searchField = useSearchField(props, searchFieldState, inputRef);
  const variant = props.variant;

  return (
    <S.Root className={props.className}>
      {(props.chips == null || props.chips.length === 0) && (
        <Icon url={iconMagnifyingGlass} color={theme.icon.color_medium} />
      )}

      {props.chips?.length > 0 && (
        <TagGroup aria-label="Pre filter" onRemove={props.onRemoveChips}>
          {props.chips.map((chip) => {
            return <TagGroup.Item key={chip.key}>{chip.title}</TagGroup.Item>;
          })}
        </TagGroup>
      )}

      <S.Input
        {...searchField.inputProps}
        ref={mergeRefs([inputRef, forwardedRef])}
        variant={variant}
        backgroundColor={props.backgroundColor}
        borderRadius={props.borderRadius}
      />
    </S.Root>
  );
});

function S() {}
ChippedSearchField.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
  border: 1px solid ${theme.input.search_border};
  border-radius: 20px;
  background-color: ${theme.input.search_background};
  overflow: auto;
  padding: 8px;
  border: 1px solid transparent;
  transition: border-color ${theme.transition.fast} ${theme.curve.fastIn};

  ${Icon.S.Root} {
    margin-right: 4px;
  }

  &:hover {
    border-color: ${theme.input.border_hover};
    box-shadow: ${theme.input.boxShadow_hover};
    background-color: ${theme.color.component};
  }

  &:focus-within {
    border-color: ${theme.input.border_focus};
    box-shadow: ${theme.input.boxShadow_focus};
    background-color: ${theme.color.component};
  }
`;

S.Input = styled.input`
  background-color: transparent;
  color: ${theme.color.text};
  caret-color: ${theme.color.mono_1000};
  border: none;
  outline: none;
  flex: 1 0 auto;
  width: 0;
  min-width: 60px;
  margin-left: 4px;

  &::placeholder {
    color: ${theme.color.text_light};
  }
`;
