import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Icon } from '../common/Icon';
import { HomeyIcon } from '../common/HomeyIcon';
import { NameTextField } from './NameTextField';
import { ContextMenuButton } from '../common/context-menu/ContextMenuButton';

export function SubNavItem() {}

SubNavItem.NameTextField = NameTextField;

SubNavItem.Name = styled.div`
  flex: 1 1 auto;
  padding-left: ${su(1)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  margin-right: ${su(-1)};
`;

SubNavItem.Extension = styled.span`
  color: ${theme.color.text_light};
`;

SubNavItem.Icon = styled(Icon)`
  flex: 0 0 auto;
`;

SubNavItem.HomeyIcon = styled(HomeyIcon)`
  flex: 0 0 auto;
`;

SubNavItem.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
`;

// the only purpose of the link is so that open in new tab behavior works properly
// it does not actually navigate
function Link(props) {
  // only onClick atm
  // not sure about other default behavior that would cause unwanted routing
  if (props.isDisabled) {
    return <SubNavItem.Inner>{props.children}</SubNavItem.Inner>;
  }

  function onCapture(event) {
    if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
      // Prevent selection.
      event.stopPropagation();
    } else {
      // Dont follow the link by default.
      event.preventDefault();
    }
  }

  return (
    <SubNavItem.Inner
      tabIndex={-1}
      as="a"
      onClickCapture={onCapture}
      // onClick={(event) => {
      //   event.preventDefault();
      // }}
      // {...(window.PointerEvent === undefined
      //   ? {
      //       onMouseDownCapture: onCapture,
      //     }
      //   : {
      //       onPointerDownCapture: onCapture,
      //     })}
      href={props.href}
    >
      {props.children}
    </SubNavItem.Inner>
  );
}

SubNavItem.Inner = styled.div`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: inherit;
  padding: ${su(1)};
  overflow: hidden;
  outline: 0;
  cursor: unset;
`;

SubNavItem.Link = Link;

SubNavItem.Root = styled.li`
  display: flex;
  position: relative;
  align-items: stretch;
  max-width: 100%;
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  margin: 0 ${su(1)};
  padding-left: calc(var(--level, 0) * ${su(2)});
  padding-right: ${su(1)};
  cursor: pointer;

  ${ContextMenuButton.Root} {
    width: 0;
  }

  // Should also have focus.
  &[data-is-context-menu-open='true'],
  &:hover {
    ${ContextMenuButton.Root} {
      width: ${su(4)};
      opacity: 0.5;
      margin-right: ${su(-1)};

      &:hover {
        opacity: 1;
      }
    }
  }

  &[data-is-renaming='true'] {
    ${SubNavItem.Inner} {
      padding: ${su(0.5, 0, 0.5, 1)};
    }

    ${SubNavItem.NameTextField.Root} {
      margin-left: 4px;
    }
  }

  &[data-is-selected='true'] {
    ${SubNavItem.Name}, ${SubNavItem.Extension} {
      color: ${theme.color.highlight};
      font-weight: ${theme.fontWeight.medium};
    }

    ${SubNavItem.Icon} {
      background-color: ${theme.color.highlight};
    }

    ${SubNavItem.IconWrapper} {
      background-color: ${theme.color.highlight};

      ${SubNavItem.Icon} {
        background-color: ${theme.color.white};
      }
    }
  }

  &[data-is-disabled='true'] {
    cursor: not-allowed;

    ${SubNavItem.Name}, ${SubNavItem.Extension} {
      color: ${theme.color.text_disabled};
    }

    ${SubNavItem.IconWrapper} {
      background-color: ${theme.color.icon_disabled};
    }
  }

  &[data-show-disabled-style='true']:not([data-is-selected='true']) {
    ${SubNavItem.Name}, ${SubNavItem.Extension} {
      color: ${theme.color.text_disabled};
    }

    ${SubNavItem.IconWrapper} {
      background-color: ${theme.color.icon_disabled};
    }
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-drag-over='true'][data-can-drop='true'] {
    ::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: ${theme.color.state_drop_background};
      border: 1px solid ${theme.color.state_drop_line};
      border-radius: ${theme.borderRadius.default};
    }
  }

  &:hover:not([data-is-disabled='true']) {
    background-color: ${theme.color.background_hover};

    &:not([data-is-selected='true']) {
      ${SubNavItem.Name}, ${SubNavItem.Extension} {
        font-weight: ${theme.fontWeight.medium};
        color: unset;
      }

      ${SubNavItem.Icon} {
        background-color: ${theme.color.icon_dark};
      }

      ${SubNavItem.IconWrapper} {
        background-color: ${theme.color.icon_dark};

        ${SubNavItem.Icon} {
          background-color: ${theme.color.white};
        }
      }
    }
  }
`;

SubNavItem.Root.propTypes = {
  'data-is-selected': PropTypes.bool,
  'data-is-disabled': PropTypes.bool,
  'data-show-disabled-style': PropTypes.bool,
  'data-is-renaming': PropTypes.bool,
  'data-is-drag-over': PropTypes.bool,
  'data-can-drop': PropTypes.bool,
  'data-is-focus-visible': PropTypes.bool,
  'data-is-context-menu-open': PropTypes.bool,
};
