import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { Image } from '../../../components/common/Image';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { HeaderIcons } from './HeaderIcons';

import { theme } from '../../../theme/theme';

import { iconSquareArrowUpRight } from '../../../theme/icons/interface/square-arrow-up-right/square-arrow-up-right';
import { iconSiriShortcuts } from '../../../theme/icons-v2/system/siri-shortcuts/siri-shortcuts';
import { iconSiriShortcuts as iconSiriShortcutsRound } from '../../../theme/icons/apps/siri-shortcuts/siri-shortcuts';

export function SiriShortcutsSettings() {
  const { i18n } = useI18n();

  return (
    <S.Root>
      <S.Content>
        <HeaderIcons>
          <Image size="48px" url={iconSiriShortcutsRound} />
        </HeaderIcons>

        <h2>Siri Shortcuts</h2>

        <p>{i18n.messageFormatter('settings.integrations.siriShortcutsSubtitle')}</p>

        <GradientButton
          elementType="a"
          target="_blank"
          href="https://support.homey.app/hc/articles/4409843046290-Starting-Flows-with-Siri"
        >
          <GradientButton.Icon
            url={iconSquareArrowUpRight}
            color={theme.color.white}
            size={theme.icon.size_small}
          />
          <GradientButton.Text>
            {i18n.messageFormatter('settings.integrations.getStartedButtonTitle')}
          </GradientButton.Text>
        </GradientButton>
      </S.Content>
    </S.Root>
  );
}

SiriShortcutsSettings.pathKey = 'integrations/siri-shortcuts';
SiriShortcutsSettings.icon = iconSiriShortcuts;
SiriShortcutsSettings.backgroundColor = theme.color.system_siri_shortcuts;

function S() {}
SiriShortcutsSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 20px;
  flex: 1 0 auto;
`;

S.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(380px, 100%);

  p {
    color: ${theme.color.text_light};
    text-align: center;
    margin: 20px 0 30px;
    line-height: 1.5;
  }

  h2 {
    text-align: center;
  }
`;
