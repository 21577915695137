import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useFocusRing, useHover, mergeProps } from 'react-aria';
import { useOption } from 'react-aria';

import { InsightUtils } from '../../../store/insights/InsightUtils';

import { mergeRefs } from '../../../lib/mergeRefs';
import { dragTypes } from '../../../components/dnd/dragTypes';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { containers } from '../../../theme/elements/containers';
import { when } from '../../../theme/functions/when';

import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';

import { Circle } from '../../../components/common/Circle';
import { InsightContextMenuContent } from './InsightContextMenuContent';

export function InsightsListBoxOption({ item, listState }) {
  const optionRef = useRef();

  const option = useOption(
    {
      key: item.key,
    },
    listState,
    optionRef
  );

  const focus = useFocusRing();
  const optionHover = useHover({});

  function dragItemRender() {
    return (
      <InsightsListBoxOption.Root
        style={{ '--hue': item.props.colorMap[item.key]?.hue }}
        isDragging
      >
        <Circle />
        <InsightsListBoxOption.Text>{item.props.log.title}</InsightsListBoxOption.Text>
        <InsightsListBoxOption.Unit>{item.props.log.units}</InsightsListBoxOption.Unit>
      </InsightsListBoxOption.Root>
    );
  }

  // Store previous state for animation purpose
  // const [wasSelected, setWasSelected] = useState(false);

  // TODO
  // reinstate whatever this was suposed to do
  // function onClick() {
  //   listState.selectionManager.select(item.key);
  //   // Track previous state for animation purpose
  //   setWasSelected(true);
  // }

  // TODO this makes no sense since the dependency array is always has new item
  const dragItem = {
    type: dragTypes.LOG,
    id: item.key,
    units: InsightUtils.getUnits(item.props.log),
    render: dragItemRender,
  };

  const [, dragRef, preview] = useDrag(() => {
    return {
      // { isDragging }
      type: dragTypes.LOG,
      item: dragItem,
      collect(dragMonitor) {
        return {
          isDragging: dragMonitor.isDragging(),
        };
      },
    };
  }, [dragItem]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return (
    <ContextMenu
      content={
        <InsightContextMenuContent
          log={item.props.log}
          selectedResolutionKey={item.props.selectedResolutionKey}
        />
      }
    >
      {({ isOpen, onContextMenu }) => {
        return (
          <InsightsListBoxOption.Root
            {...optionHover.hoverProps}
            style={{ '--hue': item.props.colorMap[item.key]?.hue }}
            title={item.props.log.title}
            isSelected={option.isSelected}
            //wasSelected={wasSelected && !option.isSelected}
            isFocusVisible={focus.isFocusVisible}
            data-is-context-menu-open={isOpen}
            onContextMenu={onContextMenu}
          >
            <InsightsListBoxOption.InnerPressable
              {...mergeProps(option.optionProps, optionHover.hoverProps, focus.focusProps)}
              ref={mergeRefs([dragRef, optionRef])}
              draggable={true}
            >
              <Circle />
              <InsightsListBoxOption.Text>
                {item.props.log.title} &nbsp;
                <InsightsListBoxOption.Unit>{item.props.log.units}</InsightsListBoxOption.Unit>
              </InsightsListBoxOption.Text>
            </InsightsListBoxOption.InnerPressable>

            <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
          </InsightsListBoxOption.Root>
        );
      }}
    </ContextMenu>
  );
}

const animationCircle = {};
animationCircle.in = keyframes`
  0% {
    --outer-circle-size: 0px;
    top: var(--outer-circle-size);
    right: var(--outer-circle-size);
    bottom: var(--outer-circle-size);
    left: var(--outer-circle-size);
    animation-timing-function: ease-in-out;
  }

  80% {
    --outer-circle-size: -5px;
    top: var(--outer-circle-size);
    right: var(--outer-circle-size);
    bottom: var(--outer-circle-size);
    left: var(--outer-circle-size);
    animation-timing-function: ease-in-out;
  }

  100% {
    --outer-circle-size: -4px;
    top: var(--outer-circle-size);
    right: var(--outer-circle-size);
    bottom: var(--outer-circle-size);
    left: var(--outer-circle-size);
  }
`;
animationCircle.out = keyframes`
  0% {
    --outer-circle-size: -4px;
    top: var(--outer-circle-size);
    right: var(--outer-circle-size);
    bottom: var(--outer-circle-size);
    left: var(--outer-circle-size);
    animation-timing-function: ease-in-out;
  }

  20% {
    --outer-circle-size: -5px;
    top: var(--outer-circle-size);
    right: var(--outer-circle-size);
    bottom: var(--outer-circle-size);
    left: var(--outer-circle-size);
    animation-timing-function: ease-in-out;
  }

  100% {
    --outer-circle-size: 0px;
    top: var(--outer-circle-size);
    right: var(--outer-circle-size);
    bottom: var(--outer-circle-size);
    left: var(--outer-circle-size);
  }
`;

InsightsListBoxOption.Unit = styled.span`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};

  opacity: var(--unit-opacity-parent-hover, 1);
  transition: ${theme.transition.micro};
`;

InsightsListBoxOption.Text = styled.div`
  flex: 1 1 auto;
  padding: ${su(1)};
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

InsightsListBoxOption.InnerPressable = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  outline: 0;
  justify-content: flex-start;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 10px;
`;

InsightsListBoxOption.Root = styled.li`
  --hue: 0;

  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  padding: 0 10px 0 0;
  outline: 0;
  z-index: calc(${theme.zIndex.nav} + 1);
  border-radius: ${theme.borderRadius.default};
  cursor: pointer;

  font-weight: ${(props) => {
    return props.isSelected ? theme.fontWeight.medium : theme.fontWeight.regular;
  }};

  ${Circle} {
    background: ${theme.color.mono_200};
    border: 1px solid ${theme.color.mono_200};
    position: relative;
    transition: ${theme.transition.micro};

    &::after {
      --outer-circle-size: 0px;

      content: '';
      background: ${theme.color.mono_100};
      position: absolute;
      top: var(--outer-circle-size);
      right: var(--outer-circle-size);
      bottom: var(--outer-circle-size);
      left: var(--outer-circle-size);
      border: 1px solid ${theme.color.mono_200};
      border-radius: 50%;
      z-index: -1;
      transition: ${theme.transition.micro};
    }
  }

  ${InsightsListBoxOption.Unit} {
    margin-right: ${su(1)};
  }

  &:hover {
    background-color: hsla(var(--hue), 75%, 50%, 0.1);

    ${Circle} {
      background-color: hsl(var(--hue), 75%, 50%);
      border-color: hsl(var(--hue), 75%, 45%);

      &::after {
        transition: ${theme.transition.micro};
        background-color: hsla(var(--hue), 75%, 50%, 0.2);
        border-color: hsl(var(--hue), 75%, 45%);
      }
    }

    ${ContextMenuButton.Root} {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &[data-is-context-menu-open='true'] {
    ${ContextMenuButton.Root} {
      opacity: 1;
    }
  }

  ${when((props) => props.isFocusVisible === true)} {
    background-color: hsla(var(--hue), 75%, 50%, 0.1);

    ${Circle} {
      background-color: hsl(var(--hue), 75%, 50%);
      border-color: hsl(var(--hue), 75%, 45%);

      &::after {
        transition: ${theme.transition.micro};
      }
    }
  }

  ${when((props) => props.isSelected === true)} {
    ${Circle} {
      background-color: hsl(var(--hue), 75%, 50%);
      border-color: hsl(var(--hue), 75%, 45%);

      &::after {
        --outer-circle-size: -4px;
        animation: 400ms ${animationCircle.in} 1 forwards;
        background-color: hsla(var(--hue), 75%, 50%, 0.2);
        border-color: hsl(var(--hue), 75%, 45%);
      }
    }
  }

  ${when((props) => props.wasSelected === true)} {
    ${Circle} {
      &::after {
        --outer-circle-size: -4px;
        animation: 400ms ${animationCircle.out} 1 forwards;
      }
    }
  }

  ${when((props) => props.isDragging === true)} {
    ${containers.card};
    display: inline-flex;
    min-width: 260px;
    cursor: grabbing;

    ${Circle} {
      background: hsl(var(--hue), 75%, 50%);
      border-color: hsl(var(--hue), 75%, 40%);
    }
  }
`;
