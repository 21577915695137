import { Fragment, useRef } from 'react';
import styled from '@emotion/styled';
import { create } from 'zustand';

import { useI18n } from '../../hooks/useI18nFormatters';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Dialog } from './Dialog';
import { TextButton } from '../buttons/TextButton';
import { SolidButton } from '../buttons/SolidButton';
import { IconButton } from '../buttons/IconButton';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';

function getInitialState() {
  return {
    resolve: null,
    reject: null,
    message: null,
    type: null,
    isOpen: false,
    onActionPress: null,
  };
}

const store = create(() => {
  return getInitialState();
});

export async function promptMessage({ type = 'delete', message, onActionPress }) {
  return new Promise((resolve, reject) => {
    store.setState({
      resolve,
      reject,
      type,
      message,
      onActionPress,
      isOpen: true,
    });
  });
}

export function DialogPrompt() {
  const { i18n } = useI18n();
  const storeState = store((state) => state);
  const textFieldRef = useRef();

  return (
    storeState.isOpen && (
      <Dialog
        onClose={(reason) => {
          if (reason === Dialog.REASON.ESCAPE) {
            store.setState(getInitialState());
            storeState.reject?.();
          }
        }}
      >
        <DialogPrompt.S.Root>
          <DialogPrompt.S.Header>
            <IconButton
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                store.setState(getInitialState());
                storeState.reject?.();
              }}
            />
          </DialogPrompt.S.Header>

          {storeState.message && (
            <DialogPrompt.S.MessageWrapper>
              <DialogPrompt.S.Message>{mapMessage(storeState.message)}</DialogPrompt.S.Message>
            </DialogPrompt.S.MessageWrapper>
          )}

          <DialogPrompt.S.ActionsContainer>
            <TextButton
              styleColor="light"
              onPress={() => {
                store.setState(getInitialState());
                storeState.reject?.();
              }}
            >
              {i18n.messageFormatter('common.cancel')}
            </TextButton>

            <SolidButton
              styleFlat={true}
              styleColor={
                storeState.type === 'confirm' || storeState.type === 'save' ? 'blue' : 'red'
              }
              autoFocus={storeState.name == null}
              onPress={(event) => {
                const value = textFieldRef.current?.value;

                store.setState(getInitialState());
                storeState.onActionPress?.(event);
                storeState.resolve?.(value);
              }}
            >
              {storeState.type === 'confirm'
                ? i18n.messageFormatter('common.confirm')
                : storeState.type === 'clear'
                ? i18n.messageFormatter('common.clear')
                : storeState.type === 'save'
                ? i18n.messageFormatter('common.save')
                : i18n.messageFormatter('common.delete')}
            </SolidButton>
          </DialogPrompt.S.ActionsContainer>
        </DialogPrompt.S.Root>
      </Dialog>
    )
  );
}

function mapMessage(message) {
  return message.split('**').map((part, index) => {
    if (index % 2 === 1) return <strong key={index}>{part}</strong>;
    return <Fragment key={index}>{part}</Fragment>;
  });
}

function S() {}
DialogPrompt.S = S;

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 270px;
  max-width: 270px;
  outline: 0;
`;

S.Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: ${su(2, 2, 0, 2)};
`;

S.MessageWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: center;
  padding: ${su(1, 2, 2, 2)};
`;

S.Message = styled.div`
  color: ${theme.color.text};
  font-size: ${theme.fontSize.heading3};
  text-align: center;
`;

S.ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-top: 1px solid ${theme.color.line};

  > * {
    flex: 1 1 auto;
  }

  > *:not(:last-child) {
    margin-right: 10px;
  }
`;
