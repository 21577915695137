export function selectBase(state) {
  return {
    // TODO convert everything to isLoading.
    loading: state.loading ?? state.isLoading,
    isLoading: state.isLoading ?? state.loading,
    error: state.error,
    api: state.api,
    manager: state.manager,
    retry: state.retry,
    refresh: state.refresh,
  };
}

export function selectLoading(state) {
  return state.loading;
}

export function selectIsLoading(state) {
  return state.isLoading;
}

export function selectData(state) {
  return {
    ...selectBase(state),
    data: state.data,
  };
}

export function selectByKey(state) {
  return {
    ...selectBase(state),
    byKey: state.byKey,
  };
}

export function selectById(state) {
  return {
    ...selectBase(state),
    byId: state.byId,
  };
}

export function selectByUri(state) {
  return {
    ...selectBase(state),
    byUri: state.byUri,
  };
}

export function selectByOwnerUri(state) {
  return {
    ...selectBase(state),
    byOwnerUri: state.byOwnerUri,
  };
}
