import { ResourceUtils } from '../ResourceUtils';

export class AdvancedFlowUtils {
  static typeName = 'advanced-flow';

  static isAdvancedFlow(advancedFlow) {
    return advancedFlow != null && advancedFlow.cards !== undefined;
  }

  static getOwnerUrisSetFromCards(advancedFlow) {
    const cardOwnerUris = new Set();

    for (const card of Object.values(advancedFlow.cards)) {
      if (card.type !== 'trigger' && card.type !== 'condition' && card.type !== 'action') {
        continue;
      }

      const ownerUri = ResourceUtils.getOwnerUriV3(card);
      cardOwnerUris.add(ownerUri);
    }

    return cardOwnerUris;
  }
}
