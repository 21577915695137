import styled from '@emotion/styled';

import { useI18n } from '../../hooks/useI18nFormatters';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';
import { iconStarFill } from '../../theme/icons/interface/star-fill';

import { Icon } from '../common/Icon';

export function PopularBadge() {
  const { i18n } = useI18n();

  return (
    <S.Root>
      <S.Svg width="100%" height="100%">
        <defs>
          <linearGradient id="gradient" x1="0" x2="100%" y1="100%" y2="0">
            <stop offset="0%" stopColor="#ff4200" />
            <stop offset="100%" stopColor="#fc8f2b" />
          </linearGradient>
        </defs>
        <rect
          x="1"
          y="1"
          rx="5px"
          width="calc(100% - 2px)"
          height="calc(100% - 2px)"
          fill="transparent"
          strokeWidth={1}
          stroke="url(#gradient)"
        />
      </S.Svg>
      <Icon url={iconStarFill} size={theme.icon.size_tiny} color="#ff4200" />
      <S.Text>{i18n.messageFormatter('common.popular')}</S.Text>
    </S.Root>
  );
}

function S() {}
PopularBadge.S = S;

S.Root = styled.div`
  align-self: center;
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  margin: 0 6px;
  padding: ${su(0.5, 1)};
`;

S.Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

S.Text = styled.span`
  font-size: ${theme.fontSize.tiny};
  color: #ff4200;
`;
