import React, { useContext, createContext } from 'react';

export const TokenValueContext = createContext({});

export function useTokenValueContext() {
  return {
    tokens: useContext(TokenValueContext),
  };
}

export function TokenValueContextProvider(props) {
  return (
    <TokenValueContext.Provider value={props.tokens}>{props.children}</TokenValueContext.Provider>
  );
}
