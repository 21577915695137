import { useRef } from 'react';
import styled from '@emotion/styled';
import { useTextField } from 'react-aria';

import { input } from '../../../theme/Global';

export function EditNameTextField(props) {
  const ref = useRef();
  const textField = useTextField(
    { ...props, 'aria-label': 'text-field', autoFocus: true, placeholder: '' }, // TODO: translate placeholder
    ref
  );

  return <Input {...textField.inputProps} ref={ref} />;
}

const Input = styled.input`
  ${input};
  display: flex;
  flex: 1 1 auto;
  width: 0;
  padding: 0 4px;
  margin: 5px -5px 5px -5px;
  outline: 0;
`;
