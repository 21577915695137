import { theme } from '../../theme/theme';

const heading3 = (
  <svg
    role="img"
    width="160"
    height="25"
    aria-labelledby="loading-aria"
    viewBox="0 0 160 25"
    preserveAspectRatio="none"
  >
    <title id="loading-aria">Loading...</title>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      clipPath="url(#clip-path-h3)"
      style={{
        fill: 'url(#fill-h3)',
      }}
    />
    <defs>
      <clipPath id="clip-path-h3">
        <rect x="0" y="0" rx="5" ry="5" width="160" height="25" />
      </clipPath>
      <linearGradient id="fill-h3">
        <stop offset="0.6" stopColor={theme.color.mono_100} stopOpacity="1">
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="1.6" stopColor={theme.color.mono_050} stopOpacity="1">
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="2.6" stopColor={theme.color.mono_100} stopOpacity="1">
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export function Heading3() {
  return heading3;
}
