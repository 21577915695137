import React from 'react';

import { Battery } from './battery/Battery';
import { ButtonGroup } from './button/ButtonGroup';
import { Color } from './color/Color';
import { Media } from './media/Media';
import { Camera } from './camera/Camera';
import { Picker } from './picker/Picker';
import { SensorGroup } from './sensor/SensorGroup';
import { SliderAdapter } from './slider/SliderAdapter';
import { Ternary } from './ternary/Ternary';
import { Thermostat } from './thermostat/Thermostat';
import { Timeline } from './timeline/Timeline';
import { Toggle } from './toggle/Toggle';

export function getControlComponent(componentId) {
  switch (componentId) {
    case 'battery':
      return Battery;
    case 'button':
      return ButtonGroup;
    case 'color':
      return Color;
    case 'media':
      return Media;
    case 'camera':
      return Camera;
    case 'picker':
      return Picker;
    case 'sensor':
      return SensorGroup;
    case 'slider':
      return SliderAdapter;
    case 'ternary':
      return Ternary;
    case 'thermostat':
      return Thermostat;
    case 'timeline':
      return Timeline;
    case 'toggle':
      return Toggle;
    default:
      return React.Fragment;
  }
}
