import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';
import { ToastManager } from '../../ToastManager';
import { ResourceUtils } from '../ResourceUtils';

export class FlowCardStore extends BaseApiStore {
  static key = 'flowCards';
  static store = this.createStore(this.key);

  static createInitialState() {
    return {
      triggerData: null,
      triggers: {
        byKey: null,
      },
      conditionData: null,
      conditions: {
        byKey: null,
      },
      actionData: null,
      actions: {
        byKey: null,
      },
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:flowCards');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerFlow = state.api.flow;

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 0));
      const flowCardTriggersPromise = await managerFlow.getFlowCardTriggers();
      const flowCardConditionsPromise = await managerFlow.getFlowCardConditions();
      const flowCardActionsPromise = await managerFlow.getFlowCardActions();

      const [, triggerData, conditionData, actionData] = await Promise.all([
        waitPromise,
        flowCardTriggersPromise,
        flowCardConditionsPromise,
        flowCardActionsPromise,
      ]);

      const { triggersResult, conditionsResult, actionsResult } = this.processCards({
        triggerData,
        conditionData,
        actionData,
      });

      this.set({
        ...this.createInitialState(),
        loading: false,
        triggerData: triggersResult.data,
        triggers: {
          byKey: triggersResult.byKey,
        },
        conditionData: conditionsResult.data,
        conditions: {
          byKey: conditionsResult.byKey,
        },
        actionData: actionsResult.data,
        actions: {
          byKey: actionsResult.byKey,
        },
        manager: managerFlow,
      });

      this.addListeners(managerFlow);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static processCards({ triggerData, conditionData, actionData }) {
    const triggersResult = this.processGroup({ data: triggerData });
    const conditionsResult = this.processGroup({ data: conditionData });
    const actionsResult = this.processGroup({ data: actionData });

    return {
      triggersResult,
      conditionsResult,
      actionsResult,
    };
  }

  static processGroup({ data }) {
    return data.reduce(
      (accumulator, flowCard) => {
        const key = ResourceUtils.getKey(flowCard);
        accumulator.data[key] = flowCard;

        const card = { ...flowCard };

        accumulator.byKey[key] = card;

        return accumulator;
      },
      {
        data: {},
        byKey: {},
      }
    );
  }

  static destroy() {
    __DEV__ && console.info('destroy:flowCards');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);

    if (state.api) {
      this.removeListeners(state.api.flow);
    }
  }

  static async getCards() {
    try {
      const state = this.get();
      const managerFlow = state.api.flow;

      const flowCardTriggersPromise = managerFlow.getFlowCardTriggers();
      const flowCardConditionsPromise = managerFlow.getFlowCardConditions();
      const flowCardActionsPromise = managerFlow.getFlowCardActions();

      const [triggerData, conditionData, actionData] = await Promise.all([
        flowCardTriggersPromise,
        flowCardConditionsPromise,
        flowCardActionsPromise,
      ]);

      const { triggersResult, conditionsResult, actionsResult } = this.processCards({
        triggerData,
        conditionData,
        actionData,
      });

      this.set({
        triggerData: triggersResult.data,
        triggers: {
          byKey: triggersResult.byKey,
        },
        conditionData: conditionsResult.data,
        conditions: {
          byKey: conditionsResult.byKey,
        },
        actionData: actionsResult.data,
        actions: {
          byKey: actionsResult.byKey,
        },
      });
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static addListeners(managerFlow) {
    managerFlow.addListener('flowcardtrigger.create', this.handleCreate);
    managerFlow.addListener('flowcardtrigger.update', this.handleUpdate);
    managerFlow.addListener('flowcardtrigger.delete', this.handleDelete);
    managerFlow.addListener('flowcardcondition.create', this.handleCreate);
    managerFlow.addListener('flowcardcondition.update', this.handleUpdate);
    managerFlow.addListener('flowcardcondition.delete', this.handleDelete);
    managerFlow.addListener('flowcardaction.create', this.handleCreate);
    managerFlow.addListener('flowcardaction.update', this.handleUpdate);
    managerFlow.addListener('flowcardaction.delete', this.handleDelete);
  }

  static removeListeners(managerFlow) {
    managerFlow.removeListener('flowcardtrigger.create', this.handleCreate);
    managerFlow.removeListener('flowcardtrigger.update', this.handleUpdate);
    managerFlow.removeListener('flowcardtrigger.delete', this.handleDelete);
    managerFlow.removeListener('flowcardcondition.create', this.handleCreate);
    managerFlow.removeListener('flowcardcondition.update', this.handleUpdate);
    managerFlow.removeListener('flowcardcondition.delete', this.handleDelete);
    managerFlow.removeListener('flowcardaction.create', this.handleCreate);
    managerFlow.removeListener('flowcardaction.update', this.handleUpdate);
    managerFlow.removeListener('flowcardaction.delete', this.handleDelete);
  }

  static processBatchTimeout = null;

  static handleCreate = (createdFlowCard) => {
    __DEV__ && console.info(`create:flowCard:${createdFlowCard.id}`);

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = setTimeout(() => {
      this.getCards();
    }, 200);
  };

  static handleUpdate = (updatedFlowCard) => {
    __DEV__ && console.info(`update:flowCard:${updatedFlowCard.id}`);

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = setTimeout(() => {
      this.getCards();
    }, 200);
  };

  static handleDelete = (deletedFlowCard) => {
    __DEV__ && console.info(`delete:flowCard:${deletedFlowCard.id}`);

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = setTimeout(() => {
      this.getCards();
    }, 200);
  };
}
