import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

export interface State {
  screensaversData: OptionScreensaver[] | null;
  optionScreensaverData: object | null;
  optionBrightnessData: object | null;
  isLoading: boolean;
  error: unknown;
}

type OptionScreensaver = {
  uri: string;
  id: string;
  title: string;
};

export type LedringSettingsStoreState = State & BaseApiStoreState;

class LedringSettingsStore extends BaseApiStore<State> {
  key = 'ledring-settings';

  override createInitialState() {
    return {
      screensaversData: null,
      optionScreensaverData: null,
      optionBrightnessData: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:ledring');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerLedring = api.ledring;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const screensaversPromise = managerLedring.getScreensavers();
      const optionScreensaverPromise = managerLedring.getOptionScreensaver();
      const optionBrightnessPromise = managerLedring.getOptionBrightness().catch((error) => {
        // Only exists on 201x models.
        if (error.code === 404) {
          return null;
        }

        throw error;
      });

      const [, screensaversData, optionScreensaverData, optionBrightnessData] = await Promise.all([
        waitPromise,
        screensaversPromise,
        optionScreensaverPromise,
        optionBrightnessPromise,
      ]);

      const sortedScreensaversData = (screensaversData as unknown as OptionScreensaver[]).sort(
        (a, b) => {
          if (a.id === 'off') return 1;
          if (b.id === 'off') return -1;
          return 0;
        }
      );

      this.set({
        ...this.createInitialState(),
        screensaversData: sortedScreensaversData,
        optionScreensaverData: optionScreensaverData as unknown as object,
        optionBrightnessData: optionBrightnessData as unknown as object,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, 'ledring-settings');

    return { ledringSettings: this.store(selectAll, shallow) };
  }

  async setOptionScreensaver({ value }: { value: OptionScreensaver }) {
    const api = this.getApi();
    const result = (await api.ledring.setOptionScreensaver({ value })) as unknown as {
      value: OptionScreensaver;
    };
    this.set({ optionScreensaverData: result });
  }

  async setOptionBrightness({ value }: { value: number }) {
    const api = this.getApi();
    const result = (await api.ledring.setOptionBrightness({ value })) as unknown as {
      value: number;
    };
    this.set({ optionBrightnessData: result });
  }
}

function selectAll(state: LedringSettingsStoreState) {
  return {
    ...selectBase(state),
    screensaversData: state.screensaversData,
    optionScreensaverData: state.optionScreensaverData,
    optionBrightnessData: state.optionBrightnessData,
  };
}

export const ledringSettingsStore = new LedringSettingsStore();
