import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useToggleState } from 'react-stately';
import { usePress, useSwitch, useFocusRing, VisuallyHidden, mergeProps } from 'react-aria';

import { ToastManager } from '../../ToastManager';
import { RouteManager } from '../../RouteManager';

import { useUser } from '../../store/users/useUsers';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useApi } from '../../store/HomeyStore';
import { useUserMe } from '../../store/user-me/useUserMe';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Dialog } from '../../components/overlay/Dialog';
import { SolidButton } from '../../components/buttons/SolidButton';
import { Separator } from '../../components/common/Separator';
import { typography } from '../../components/primitives/typography';
import { IconButton } from '../../components/buttons/IconButton';
import { Icon as IconBase } from '../../components/common/Icon';
import { SpinnerFade } from '../../components/common/SpinnerFade';

import { iconHome } from '../../theme/icons/system/home';
import { iconLogOut } from '../../theme/icons/interface/log-out';
import { iconSun } from '../../theme/icons/interface/sun';
import { iconMoon } from '../../theme/icons/interface/moon';
import { iconCloseThin } from '../../theme/icons/interface/close-thin';
import { iconCheckmark } from '../../theme/icons/interface/checkmark';

export function PresenceDialog(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  const { me } = useUserMe();
  const { user } = useUser({ userId: props.userId });

  const userId = user?.id ?? null;
  const userPresent = user?.present ?? true;
  const userAsleep = user?.asleep ?? true;

  const [state, setState] = useState({
    userPresent,
    userAsleep,
    userId,
  });

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setState({
      userPresent,
      userAsleep,
      userId,
    });
  }, [userId, userPresent, userAsleep]);

  let handler = {
    isOpen: true,
    close() {
      RouteManager.toOmitQuery();
    },
  };

  return (
    handler.isOpen && (
      <Dialog onClose={() => handler.close()} isOpen={handler.isOpen}>
        <S.Content>
          <S.TopCircle />
          <S.Avatar src={user?.avatar || `/img/ph-64.png`} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 20,
            }}
          >
            <IconButton
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => handler.close()}
            />
          </div>

          <S.Spacer
            style={{
              paddingTop: 16,
            }}
          >
            <typography.h3>{user?.name.split(' ')[0]}</typography.h3>
          </S.Spacer>

          <S.SpacerColumn>
            <Switch
              activeText={i18n.messageFormatter('presence.present.true')}
              activeIcon={iconHome}
              inActiveText={i18n.messageFormatter('presence.present.false')}
              inActiveIcon={iconLogOut}
              isActive={state.userPresent}
              onChange={(isActive) => {
                setState((prevState) => {
                  return {
                    ...prevState,
                    userPresent: isActive,
                  };
                });
              }}
            />
            <Switch
              activeText={i18n.messageFormatter('presence.asleep.false')}
              activeIcon={iconSun}
              inActiveText={i18n.messageFormatter('presence.asleep.true')}
              inActiveIcon={iconMoon}
              isActive={state.userAsleep === false}
              onChange={(isActive) => {
                setState((prevState) => {
                  return {
                    ...prevState,
                    userAsleep: !isActive,
                  };
                });
              }}
            />
          </S.SpacerColumn>

          <Separator />

          <S.Spacer>
            <SolidButton
              styleFlat={true}
              styleColor="green"
              styleWidth="full"
              isDisabled={isSaving === true}
              onPress={() => {
                (async () => {
                  setIsSaving(true);

                  try {
                    if (user.id === me.id) {
                      await api.presence.setPresentMe({
                        value: state.userPresent,
                      });
                      await api.presence.setAsleepMe({
                        value: state.userAsleep,
                      });
                    } else {
                      await api.presence.setPresent({
                        id: user.id,
                        value: state.userPresent,
                      });
                      await api.presence.setAsleep({
                        id: user.id,
                        value: state.userAsleep,
                      });
                    }
                  } catch (error) {
                    ToastManager.handleError(error);
                  } finally {
                    setTimeout(() => {
                      setIsSaving(false);
                      handler.close();
                    }, 0);
                  }
                })();
              }}
            >
              <SpinnerFade
                isActive={isSaving === true}
                size={theme.icon.size_small}
                color={theme.color.white}
              >
                <SolidButton.Icon
                  url={iconCheckmark}
                  color={theme.color.white}
                  size={theme.icon.size_small}
                />
              </SpinnerFade>
              <SolidButton.Text>
                {/* {isSaving === true
                  ? `${i18n.messageFormatter('common.saving')}...`
                  : i18n.messageFormatter('common.save')} */}
                {i18n.messageFormatter('common.save')}
              </SolidButton.Text>
            </SolidButton>
          </S.Spacer>
        </S.Content>
      </Dialog>
    )
  );
}

function S() {}
PresenceDialog.S = S;

S.Content = styled.div`
  position: relative;
  min-width: 314px;

  ${SolidButton.S.Root} {
    flex: 1 1 auto;
  }
`;

S.Spacer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${su(1)};
`;

S.SpacerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 30px;

  & > :first-of-type {
    margin-bottom: 20px;
  }
`;

S.TopCircle = styled.div`
  position: absolute;
  top: -54px;
  right: 50%;
  transform: translateX(50%);
  border-radius: 50%;
  background-color: ${theme.color.component};
  width: 108px;
  height: 108px;
  box-shadow: ${theme.boxShadow.default};
  clip-path: inset(0 0 49% 0);
`;

S.Avatar = styled.img`
  position: absolute;
  top: -50px;
  right: 50%;
  transform: translateX(50%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  z-index: 1;
`;

const variants = {
  activeText: {
    color: theme.color.text,
  },
  activeIcon: {
    backgroundColor: theme.icon.color_dark,
  },
  inActiveText: {
    color: theme.color.text_light,
  },
  inActiveIcon: {
    backgroundColor: theme.icon.color_body_light,
  },
};

function Switch({ isActive, onChange, activeText, inActiveText, activeIcon, inActiveIcon }) {
  const toggleState = useToggleState({
    isSelected: isActive,
  });
  const press = usePress({
    onPress() {
      onChange(!isActive);
    },
  });
  const inputRef = useRef();
  const aSwitch = useSwitch({ 'aria-label': 'Switch' }, toggleState, inputRef);
  const focus = useFocusRing();

  return (
    <SwitchContainer {...press.pressProps} isFocusVisible={focus.isFocusVisible}>
      <VisuallyHidden>
        <input {...mergeProps(aSwitch.inputProps, focus.focusProps)} ref={inputRef} />
      </VisuallyHidden>
      <Handle
        initial={false}
        animate={{
          x: toggleState.isSelected ? '0%' : '100%',
        }}
      />
      <TextContainer>
        <Text
          initial={false}
          animate={toggleState.isSelected === true ? 'activeText' : 'inActiveText'}
          variants={variants}
        >
          <Icon
            as={motion.span}
            url={activeIcon}
            size="14px"
            initial={false}
            animate={toggleState.isSelected === true ? 'activeIcon' : 'inActiveIcon'}
            variants={variants}
          />
          {activeText}
        </Text>
        <Text
          initial={false}
          animate={toggleState.isSelected === false ? 'activeText' : 'inActiveText'}
          variants={variants}
        >
          <Icon
            as={motion.span}
            url={inActiveIcon}
            size="14px"
            initial={false}
            animate={toggleState.isSelected === false ? 'activeIcon' : 'inActiveIcon'}
            variants={variants}
          />
          {inActiveText}
        </Text>
      </TextContainer>
    </SwitchContainer>
  );
}

const SwitchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 220px;
  height: 34px;
  border-radius: 17px;
  background-color: ${theme.color.body};
  cursor: pointer;
`;

const Icon = styled(IconBase)`
  margin-left: 14px;
  margin-right: 6px;
`;

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  background-color: transparent;
  width: 220px;
  height: 34px;
  border-radius: 17px;
`;

const Text = styled(motion.div)`
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  height: 34px;
  line-height: 34px;
  vertical-align: center;
  font-size: ${theme.fontSize.small};
`;

const Handle = styled(motion.div)`
  display: flex;
  width: 110px;
  height: 34px;
  border-radius: 17px;
  background-color: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
`;
