import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { DeviceStore } from './DeviceStore';
import { useAttach } from '../useAttach';

import { selectBase, selectData, selectById, selectLoading } from '../selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    data: state.data,
    byId: state.byId,
    byZoneId: state.byZoneId,
    capabilities: state.capabilities,
  };
}

function selectByZoneId(state) {
  return {
    ...selectBase(state),
    byZoneId: state.byZoneId,
  };
}

export function useDevices() {
  useAttach(DeviceStore, 'devices');

  return DeviceStore.store(selectAll, shallow);
}

export function useDevicesData() {
  useAttach(DeviceStore, 'devicesData');

  return DeviceStore.store(selectData, shallow);
}

export function useDevicesByZoneId() {
  useAttach(DeviceStore, 'devicesByZoneId');

  return DeviceStore.store(selectByZoneId, shallow);
}

export function useDevicesById() {
  useAttach(DeviceStore, 'devicesById');

  return DeviceStore.store(selectById, shallow);
}

export function useDevice({ deviceId }) {
  useAttach(DeviceStore, 'device', { isDisabled: deviceId == null });

  const select = useCallback(
    (state) => {
      return {
        device: state.byId?.[deviceId] ?? null,
        loading: state.loading,
        error: state.error,
        manager: state.manager,
        api: state.api,
      };
    },
    [deviceId]
  );

  return DeviceStore.store(select, shallow);
}

export function useCapabilities({ deviceId }) {
  useAttach(DeviceStore, 'capabilities', { isDisabled: deviceId == null });

  const select = useCallback((state) => state.capabilities?.[deviceId] ?? null, [deviceId]);

  return { capabilities: DeviceStore.store(select) };
}

export function useDevicesLoading() {
  useAttach(DeviceStore, 'useDevicesLoading');

  return DeviceStore.store(selectLoading);
}

export function useDevicesAttach() {
  useAttach(DeviceStore, 'useDevicesAttach');
}
