import { useRef, useContext, createContext } from 'react';
import { createStore, useStore } from 'zustand';
// import createContext from 'zustand/context';

import { subscribeWithSelector } from 'zustand/middleware';

import { connectorTypesMap } from './connectors/connectorTypes';

const StoreContext = createContext();

function getInitialStore() {
  const nodes = new Map();
  const connections = new Map();
  const connectionsByNodeId = new Map();
  /** @type {Map<string, Map<string, Argument>>} */
  const nodeArgumentsHandlerByNodeId = new Map();
  const connectors = {
    [connectorTypesMap.In]: {
      nodes: new Map(),
      listenerMap: new Map(),
    },
    [connectorTypesMap.Out]: {
      nodes: new Map(),
      listenerMap: new Map(),
    },
    [connectorTypesMap.Error]: {
      nodes: new Map(),
      listenerMap: new Map(),
    },
    [connectorTypesMap.True]: {
      nodes: new Map(),
      listenerMap: new Map(),
    },
    [connectorTypesMap.False]: {
      nodes: new Map(),
      listenerMap: new Map(),
    },
  };

  return {
    nodes: nodes,
    connections: connections,
    connectionsByNodeId: connectionsByNodeId,
    connectors: connectors,
    nodeArgumentsHandlerByNodeId: nodeArgumentsHandlerByNodeId,

    registerNode(node) {
      if (node?.id == null) {
        throw new Error(`invalid node: ${JSON.stringify(node)}`);
      }

      if (nodes.get(node.id) != null) {
        throw new Error(`already registered node: ${JSON.stringify(node)}`);
      }

      nodes.set(node.id, node);
    },

    unregisterNode(node) {
      if (node?.id == null) {
        throw new Error(`invalid node: ${JSON.stringify(node)}`);
      }

      nodes.delete(node.id);
    },

    registerConnection(connection) {
      if (connection?.key == null) {
        throw new Error(`invalid connection: ${JSON.stringify(connection)}`);
      }

      if (connections.get(connection.key) != null) {
        throw new Error(`already registered connection: ${JSON.stringify(connection)}`);
      }

      // eslint-disable-next-line no-unused-vars
      const [fromNodeId, toNodeId, fromConnectorType, toConnectorType] = connection.key.split('::');

      const fromNodeIdConnections = connectionsByNodeId.get(fromNodeId) ?? new Map();
      fromNodeIdConnections.set(connection.key, connection);
      const toNodeIdConnections = connectionsByNodeId.get(toNodeId) ?? new Map();
      toNodeIdConnections.set(connection.key, connection);

      connectionsByNodeId.set(fromNodeId, fromNodeIdConnections);
      connectionsByNodeId.set(toNodeId, toNodeIdConnections);

      connections.set(connection.key, connection);
    },

    unregisterConnection(connection) {
      if (connection?.key == null) {
        throw new Error(`invalid connection: ${JSON.stringify(connection)}`);
      }

      // eslint-disable-next-line no-unused-vars
      const [fromNodeId, toNodeId, fromConnectorType, toConnectorType] = connection.key.split('::');

      const fromNodeIdConnections = connectionsByNodeId.get(fromNodeId);
      fromNodeIdConnections.delete(connection.key);

      if (fromNodeIdConnections.size === 0) {
        connectionsByNodeId.delete(fromNodeId);
      }

      const toNodeIdConnections = connectionsByNodeId.get(toNodeId);
      toNodeIdConnections.delete(connection.key);

      if (toNodeIdConnections.size === 0) {
        connectionsByNodeId.delete(toNodeId);
      }

      connections.delete(connection.key);
    },

    registerConnector(connector) {
      if (connector?.type == null || connector?.nodeId == null) {
        throw new Error(`invalid connector: ${JSON.stringify(connector)}`);
      }

      if (connectors[connector.type].nodes.get(connector.nodeId) != null) {
        throw new Error(`already registered connector: ${JSON.stringify(connector)}`);
      }

      connectors[connector.type].nodes.set(connector.nodeId, connector);

      const listenerMap = connectors[connector.type].listenerMap;
      const listeners = listenerMap.get(connector.nodeId) ?? new Set();

      listeners?.forEach((listener) => {
        listener(connector);
      });
    },

    unregisterConnector(connector) {
      if (connector?.type == null || connector?.nodeId == null) {
        throw new Error(`invalid connector: ${JSON.stringify(connector)}`);
      }

      connectors[connector.type].nodes.delete(connector.nodeId);
    },

    getConnector({ type, nodeId }) {
      return connectors[type].nodes.get(nodeId);
    },

    subscribe(connectorType, nodeId, listener) {
      const listenerMap = connectors[connectorType].listenerMap;
      const listeners = listenerMap.get(nodeId) ?? new Set();
      listeners.add(listener);
      listenerMap.set(nodeId, listeners);

      // const connector = connectors[connectorType].nodes.get(nodeId);

      // if (connector != null) {
      //   listener(connector);
      // }
    },

    unsubscribe(connectorType, nodeId, listener) {
      const listenerMap = connectors[connectorType].listenerMap;
      const listeners = listenerMap.get(nodeId);

      if (listeners != null) {
        listeners.delete(listener);
        if (listeners.size === 0) {
          listenerMap.delete(nodeId);
        }
      }
    },

    /**
     * @param {string} nodeId
     * @param {any} handler
     */
    registerNodeArgumentsHandler(nodeId, handler) {
      nodeArgumentsHandlerByNodeId.set(nodeId, handler);
    },

    /**
     * @param {string} nodeId
     */
    unregisterNodeArgumentsHandler(nodeId) {
      nodeArgumentsHandlerByNodeId.delete(nodeId);
    },

    async resetAll() {
      const promises = [];

      for (const [, node] of nodes) {
        promises.push(node.onCardClear());
      }

      for (const [, connection] of connections) {
        promises.push(connection.onConnectionClear());
      }

      await Promise.all(promises);
    },
  };
}

export function AdvancedFlowViewContext(props) {
  const storeRef = useRef(null);

  if (storeRef.current == null) {
    storeRef.current = createStore(subscribeWithSelector(getInitialStore));
  }

  return <StoreContext.Provider value={storeRef.current}>{props.children}</StoreContext.Provider>;
}

function selectAdvancedFlowViewContext(state) {
  return state;
}

export function useAdvancedFlowViewContext() {
  const store = useContext(StoreContext);
  return useStore(store, selectAdvancedFlowViewContext);
}
