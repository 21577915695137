import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

import { ToastManager } from '../../../ToastManager';
import { generalSettingsStore } from './GeneralSettingsStore';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';

import { TextField } from '../../../components/forms/TextField';
import { IconButton } from '../../../components/buttons/IconButton';

import { GeneralSettingsSection } from './GeneralSettingsSection';

import { iconRectanglePen } from '../../../theme/icons/interface/rectangle-pen/rectangle-pen';

export function GeneralSettingsInfoHomey(props) {
  const { i18n } = useI18n();
  const { api } = useApi();
  const { generalSettings } = generalSettingsStore.use();

  const inputRef = useRef(null);

  const instanceRef = useRef({
    promise: null,
  });

  const [name, setName] = useState(generalSettings.systemNameData ?? '');

  useEffect(() => {
    setName(generalSettings.systemNameData);
  }, [generalSettings.systemNameData]);

  function handleChange(value) {
    setName(value);
  }

  async function handleKeyDown(event) {
    try {
      if (event.key === 'Enter') {
        if (generalSettings.systemNameData === name) {
          event.currentTarget.blur();
          return;
        }

        if (instanceRef.current.promise != null) return;

        instanceRef.current.promise = generalSettingsStore.setSystemName(name);
        instanceRef.current.promise.catch(ToastManager.handleError).finally(() => {
          instanceRef.current.promise = null;
        });

        event.currentTarget.blur();
        return;
      }

      if (event.key === 'Escape') {
        setName(generalSettings.systemNameData);
        event.currentTarget.blur();
      }
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  function handlePress() {
    inputRef.current.focus();
  }

  return (
    <GeneralSettingsSection.S.Section>
      <GeneralSettingsSection.S.SectionHeading>Homey</GeneralSettingsSection.S.SectionHeading>

      <GeneralSettingsSection.S.SectionView>
        <GeneralSettingsSection.S.Row>
          <GeneralSettingsSection.S.Label>
            {i18n.messageFormatter('common.name')}
          </GeneralSettingsSection.S.Label>
          <GeneralSettingsSection.S.Value>
            <S.TextField
              inputRef={inputRef}
              name="homey-name"
              aria-label={i18n.messageFormatter('common.name')}
              value={name}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />

            <IconButton
              title={i18n.messageFormatter('common.edit')}
              url={iconRectanglePen}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={handlePress}
            />
          </GeneralSettingsSection.S.Value>
        </GeneralSettingsSection.S.Row>

        {api?.isCloud === false && (
          <GeneralSettingsSection.S.Row title={generalSettings.systemInfoData?.homeyModelId}>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('common.model')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {generalSettings.systemInfoData?.homeyModelName}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
        )}

        {api?.isCloud === false && (
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('common.version')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {generalSettings.systemInfoData?.homeyVersion}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
        )}
      </GeneralSettingsSection.S.SectionView>
    </GeneralSettingsSection.S.Section>
  );
}

function S() {}
GeneralSettingsInfoHomey.S = S;

S.TextField = styled(TextField)`
  ${TextField.Input} {
    text-align: right;
    padding: 0;
    border: 0;
    color: ${theme.color.text_light};
  }

  ${TextField.Input}:hover {
    border: 0;
    box-shadow: none;
  }

  ${TextField.Input}:focus {
    border: 0;
    box-shadow: none;
  }
`;
