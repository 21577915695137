// @ts-check
import React, { useMemo, useRef } from 'react';

import { getColorForTemperature } from '../../lib/capabilities/light';

const MIXED_COLOR_LIGHTNESS = 2.5;
const MIXED_COLOR_LIGHTNESS_CENTER = 3.5;
const MINIMUM_SATURATION = 100;

const FALLBACK_HEADER_COLORS = [
  parseHsv({ hue: 232, saturation: 0.2, value: 0.05 }),
  parseHsv({ hue: 240, saturation: 0.25, value: 0.21 }),
];
const FALLBACK_HSLA_COLORS = convertHsvColorsToHslaColors(FALLBACK_HEADER_COLORS);
FALLBACK_HSLA_COLORS.sort((a, b) => a.hue - b.hue);
const FALLBACK_MIXED_COLORS = generateMixColorsBetweenLinear(FALLBACK_HSLA_COLORS);

/**
 * @typedef {object} Mood
 * @property {{ [key: string]: { state: { [key: string]: any }} }} devices
 *
 * @typedef {object} Hsv
 * @property {number} hue
 * @property {number} saturation
 * @property {number} value
 *
 * @typedef {object} Hsla
 * @property {number} hue
 * @property {number} saturation
 * @property {number} lightness
 * @property {number} alpha
 *
 * @typedef {object} HslaAsCartesian
 * @property {number} x
 * @property {number} y
 * @property {number} lightness
 * @property {number} alpha
 */

/**
 * @param {Mood['devices']} moodDevices
 * @returns {Hsv[] | null}
 */
function getLiquidHsvColors(moodDevices) {
  const colors = [];

  for (const entry of Object.values(moodDevices ?? {})) {
    // Off lights are not included.
    if (entry.state.onoff === false) {
      continue;
    }

    switch (entry.state.light_mode) {
      case 'color': {
        const color = {
          hue: (entry.state.light_hue ?? 0) * 360,
          saturation: entry.state.light_saturation ?? 1,
        };

        colors.push(parseHsv({ hue: color.hue, saturation: color.saturation, value: 1 }));

        break;
      }
      case 'temperature': {
        const hsvColor = getColorForTemperature({
          light_temperature: entry.state.light_temperature ?? 1,
        }).toHsv();

        colors.push(parseHsv({ hue: hsvColor.h, saturation: hsvColor.s, value: 1 }));

        break;
      }
      default: {
        const hsvColor = getColorForTemperature({
          light_temperature: 1,
        }).toHsv();

        colors.push(parseHsv({ hue: hsvColor.h, saturation: hsvColor.s, value: 1 }));
        break;
      }
    }
  }

  if (colors.length === 0) {
    return null;
  }

  return colors;
}

/**
 * @param {Hsv} args
 * @returns {Hsv}
 */
function parseHsv({ hue, saturation, value }) {
  if (saturation < 0 || saturation > 1) {
    throw new Error(`Saturation must be between 0 and 1, got ${saturation}`);
  }

  if (hue < 0 || hue > 360) {
    throw new Error(`Hue must be between 0 and 360, got ${hue}`);
  }

  if (value < 0 || value > 1) {
    throw new Error(`Value must be between 0 and 1, got ${value}`);
  }

  return {
    hue: hue,
    saturation: saturation,
    value: value,
  };
}

/**
 * @param {Hsla[]} hslaColors
 * @returns {Hsla[]}
 */
function generateMixColorsBetweenLinear(hslaColors) {
  if (hslaColors.length === 1) {
    const colorBefore = { ...hslaColors[0] };
    const colorAfter = { ...hslaColors[0] };
    colorBefore.hue -= 10;
    colorBefore.lightness -= 5;

    if (colorBefore.lightness < 0) {
      colorBefore.lightness = 0;
    }

    colorAfter.hue += 10;
    colorAfter.lightness += 10;

    if (colorAfter.lightness > 100) {
      colorBefore.lightness = 100;
    }

    return [colorBefore, hslaColors[0], colorAfter];
  }

  /** @type {Hsla[]} */
  const initialValue = [];

  /** @type {Hsla[]} */
  const extraColors = hslaColors.reduce((extraColorsAccumulator, color, index) => {
    // If first index or last index.
    if (index === 0 || index === hslaColors.length - 1) {
      extraColorsAccumulator.push(color);
      extraColorsAccumulator.push(color);
    }

    extraColorsAccumulator.push(color);

    // If not last index.
    if (index < hslaColors.length - 1) {
      const averageColor = mixHslaColors([hslaColors[index], hslaColors[index + 1]]);
      extraColorsAccumulator.push(averageColor);
    }

    return extraColorsAccumulator;
  }, initialValue);

  return extraColors;
}

/**
 * @param {Hsla[]} colors
 * @param {boolean} center
 * @returns {Hsla}
 */
function mixHslaColors(colors, center = false) {
  // Convert HSL to Cartesian coordinates
  const cartesianColors = colors.map(hslaToCartesian);

  // Calculate the average Cartesian coordinates
  const avgCartesian = averageCartesian(cartesianColors);

  // Convert the average Cartesian coordinates back to HSL
  const mixedColor = cartesianToHsla(avgCartesian, center);

  return mixedColor;
}

/**
 * @param {Hsla} args
 * @returns {HslaAsCartesian}
 */
function hslaToCartesian({ hue, saturation, lightness, alpha }) {
  const radianHue = (hue * Math.PI) / 180;
  const x = Math.cos(radianHue) * saturation;
  const y = Math.sin(radianHue) * saturation;
  return { x, y, lightness, alpha };
}

/**
 * @param {HslaAsCartesian} args
 * @param {boolean} center
 * @returns {Hsla}
 */
function cartesianToHsla({ x, y, lightness, alpha }, center) {
  const hue = (Math.atan2(y, x) * 180) / Math.PI;
  const saturation = Math.sqrt(x * x + y * y);
  let nextLightness;

  if (center) {
    nextLightness = lightness + (100 - saturation) / MIXED_COLOR_LIGHTNESS_CENTER;
  } else {
    nextLightness = lightness + (100 - saturation) / MIXED_COLOR_LIGHTNESS;
  }

  return {
    hue: (hue + 360) % 360,
    saturation: Math.max(MINIMUM_SATURATION, saturation),
    lightness: nextLightness,
    alpha: alpha,
  };
}

/**
 * @param {HslaAsCartesian[]} cartesianColors
 * @returns {HslaAsCartesian}
 */
function averageCartesian(cartesianColors) {
  const sum = { x: 0, y: 0, lightness: 0, alpha: 0 };

  for (const color of cartesianColors) {
    sum.x += color.x;
    sum.y += color.y;
    sum.lightness += color.lightness;
    sum.alpha += color.alpha;
  }

  const average = {
    x: sum.x / cartesianColors.length,
    y: sum.y / cartesianColors.length,
    lightness: sum.lightness / cartesianColors.length,
    alpha: sum.alpha / cartesianColors.length,
  };

  return average;
}

/**
 * @param {Hsv[]} hsvColors
 * @returns {Hsla[]}
 */
function convertHsvColorsToHslaColors(hsvColors) {
  return hsvColors.map((color) => {
    // convert HSV saturation to HSLA lightness
    const lightness = 100 - 50 * color.saturation;

    return {
      hue: color.hue,
      saturation: 100,
      lightness: lightness,
      alpha: color.value,
    };
  });
}

/**
 * @param {object} args
 * @param {Mood['devices']} args.devices
 * @returns {Hsla[]}
 */
export function useMixedColors({ devices }) {
  const fallback = useRef([]);

  const mixedColors = useMemo(() => {
    if (devices == null) {
      return fallback.current;
    }

    const colors = getLiquidHsvColors(devices);

    if (colors == null) {
      return FALLBACK_MIXED_COLORS;
    }

    const hslaColors = convertHsvColorsToHslaColors(colors).sort((a, b) => a.hue - b.hue);
    const mixedColors = generateMixColorsBetweenLinear(hslaColors);

    return mixedColors;
  }, [devices]);

  return mixedColors;
}

/**
 * @param {object} props
 * @param {string} props.id
 * @param {number} props.size
 * @param {Hsla[]} props.mixedColors
 * @returns {React.ReactNode}
 */
export function CanvasLiquidLinearCircle(props) {
  const gradientId = `liquidGradient-${props.id}`;
  const blurId = `blurFilter-${props.id}`;

  return (
    <div
      style={{
        width: props.size,
        height: props.size,
        backgroundColor: 'transparent',
      }}
    >
      <svg
        id={props.id}
        viewBox={`0 0 ${props.size} ${props.size}`}
        width={props.size}
        height={props.size}
      >
        <defs>
          <filter id={blurId}>
            <feGaussianBlur stdDeviation={`${props.size / 6}`} />
            <feComposite operator={'over'} in2={'SourceGraphic'} />
          </filter>

          <linearGradient id={gradientId} x1="0%" y1="100%" x2="100%" y2="0%">
            {props.mixedColors.map((color, index) => (
              <stop
                key={index}
                offset={`${(index / props.mixedColors.length) * 100}%`}
                style={{
                  stopColor: `hsla(${color.hue}, ${color.saturation}%, ${color.lightness}%, ${color.alpha})`,
                }}
              />
            ))}
          </linearGradient>

          <clipPath id="clip">
            <circle cx={props.size / 2} cy={props.size / 2} r={props.size / 2} />
          </clipPath>
        </defs>

        {props.mixedColors === FALLBACK_MIXED_COLORS && (
          <circle cx={props.size / 2} cy={props.size / 2} r={props.size / 2} fill="black" />
        )}

        <rect
          width={props.size}
          height={props.size}
          fill={`url(#${gradientId})`}
          clipPath="url(#clip)"
          filter={`url(#${blurId})`}
        />
      </svg>
    </div>
  );
}
