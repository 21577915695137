import React, { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { useButton } from 'react-aria';

import { RouteManager } from '../../RouteManager';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

import { iconLockDetail } from '../../theme/icons/interface/locked-detail';

export const PremiumLockedDeviceRow = forwardRef(function PremiumLockedDeviceRowForwardRef(
  props,
  forwardedRef
) {
  return (
    <S.Root
      ref={forwardedRef}
      style={{
        '--z-index': props.zIndex,
      }}
    >
      <S.Background size={props.size}>
        <Icon url={iconLockDetail} color={theme.color.icon_white} />
      </S.Background>
    </S.Root>
  );
});

PremiumLockedDeviceRow.Pressable = function PremiumLockedDeviceRowPressable(props) {
  const buttonRef = useRef();
  const button = useButton(
    {
      elementType: 'div',
      onPress() {
        RouteManager.toPremiumRequiredDialog();
      },
    },
    buttonRef
  );

  return <PremiumLockedDeviceRow {...props} {...button.buttonProps} ref={buttonRef} />;
};

function S() {}
PremiumLockedDeviceRow.S = S;

S.Root = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: var(--z-index, 1);
  overflow: hidden;
`;

S.Background = styled.div`
  --icon-size: ${theme.icon.size_tiny};
  --default-transition: width ease-in-out ${theme.duration.slow},
    height ease-in-out ${theme.duration.slow};

  width: 100%;
  height: 100%;
  background: ${theme.color.system_premium};
  clip-path: polygon(0 0, 100% 0, 0 100%);
  transition: var(--default-transition);

  ${Icon.S.Root} {
    position: absolute;
    top: 5px;
    left: 5px;
    width: var(--icon-size);
    height: var(--icon-size);
    transition: var(--default-transition);
  }
`;
