import { useEffect, useRef } from 'react';
import { useCurrentProps } from '../../hooks/useCurrentProps';

export function useInteract(props) {
  const {
    stack,
    isDisabled,
    onInteractOutside,
    onInteractOutsideStart,
    onInteractInside,
    onInteractInsideStart,
  } = props;

  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
    interactionType: null,
  });

  const currentProps = useCurrentProps({
    onInteractOutside,
    onInteractOutsideStart,
    onInteractInside,
    onInteractInsideStart,
    stack,
  });

  useEffect(() => {
    if (isDisabled === true) {
      return;
    }

    function handleDown(event) {
      const { type, index } = isValidEvent(event, currentProps.stack);
      stateRef.current.interactionType = type;

      if (type != null) {
        if (type === 'outside' && currentProps.onInteractOutsideStart != null) {
          currentProps.onInteractOutsideStart(event, index);
        } else if (type === 'inside' && currentProps.onInteractInsideStart != null) {
          currentProps.onInteractInsideStart(event, index);
        }
        stateRef.current.isPointerDown = true;
      }
    }

    function handleUp(event) {
      if (stateRef.current.isPointerDown === true) {
        stateRef.current.isPointerDown = false;
        const { type, index } = isValidEvent(event, currentProps.stack);

        // When the type has changed we ignore the event because starting a pointer down inside
        // something and ending it outside should not count as a close.
        if (type !== stateRef.current.interactionType) {
          return;
        }

        if (type != null) {
          if (type === 'outside' && currentProps.onInteractOutside != null) {
            currentProps.onInteractOutside(event, index);
          } else if (type === 'inside' && currentProps.onInteractInside != null) {
            currentProps.onInteractInside(event, index);
          }
        }
      }
    }

    function onPointerDown(event) {
      handleDown(event);
    }

    // Use pointer events if available. Otherwise, fall back to mouse and touch events.
    if (typeof PointerEvent !== 'undefined') {
      function onPointerUp(event) {
        handleUp(event);
      }

      // Changing these to capture phase fixed combobox.
      document.addEventListener('pointerdown', onPointerDown, true);
      document.addEventListener('pointerup', onPointerUp, true);

      return function () {
        document.removeEventListener('pointerdown', onPointerDown, true);
        document.removeEventListener('pointerup', onPointerUp, true);
      };
    } else {
      function onMouseUp(event) {
        if (stateRef.current.ignoreEmulatedMouseEvents) {
          stateRef.current.ignoreEmulatedMouseEvents = false;
        } else {
          handleUp(event);
        }
      }

      function onTouchEnd(event) {
        stateRef.current.ignoreEmulatedMouseEvents = true;
        handleUp(event);
      }

      document.addEventListener('mousedown', onPointerDown, true);
      document.addEventListener('mouseup', onMouseUp, true);
      document.addEventListener('touchstart', onPointerDown, true);
      document.addEventListener('touchend', onTouchEnd, true);

      return () => {
        document.removeEventListener('mousedown', onPointerDown, true);
        document.removeEventListener('mouseup', onMouseUp, true);
        document.removeEventListener('touchstart', onPointerDown, true);
        document.removeEventListener('touchend', onTouchEnd, true);
      };
    }
  }, [currentProps, isDisabled]);
}

function isValidEvent(event, stack) {
  if (event.button > 0) {
    return {};
  }

  // If the event target is no longer in the document.
  if (event.target != null) {
    const ownerDocument = event.target.ownerDocument;
    if (ownerDocument == null || ownerDocument.documentElement.contains(event.target) === false) {
      return {};
    }
  }

  if (Array.isArray(stack)) {
    let foundIndex = null;

    const contains = stack.some(({ ref }, index) => {
      if (ref.current?.contains(event.target)) {
        foundIndex = index;
        return true;
      }

      foundIndex = -1;
      return false;
    });

    let result = null;

    if (contains === true) {
      result = {
        type: 'inside',
        index: foundIndex,
      };
    } else {
      result = {
        type: 'outside',
        index: foundIndex,
      };
    }

    return result;
  }

  return {};
}
