import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { input } from '../../../theme/Global';

import { Icon } from '../../../components/common/Icon';

const InputContainer = styled.div`
  // Used in src/containers/flows/flow-arguments/FlowToken.js
  --flow-token-max-width: 230px;
  display: flex;
  flex-direction: column;
  padding: var(--input-container-padding, ${su(1)});
  width: var(--width, 320px);
  overflow: hidden;
`;

const InputRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`;

/* @emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */
const Input = styled.input`
  ${input};
  --height: ${theme.input.height_medium};
  width: 100%;
  border-radius: ${theme.borderRadius.default};
  padding: 0 10px;
  outline: 0;
  min-width: 0;
  //background-color: ${theme.color.body};

  // range slider
  // todo currently it is not possible to style the 'blue' progress in -webkit- browsers
  &[type='range'] {
    -webkit-appearance: none;
    background-color: transparent;
    padding: 0;
    box-sizing: content-box;

    --height: 22px;

    border: none;

    &:hover {
      border: none;
      box-shadow: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &::-moz-range-thumb {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      // border: 1px solid ${theme.color.line};
      border: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: ${theme.color.white};
      cursor: pointer;
      margin: -10px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      // border: 1px solid ${theme.color.line};
      border: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: ${theme.color.white};
      cursor: pointer;
      position: relative;
      margin-top: -7px;
      margin-bottom: -7px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 6px;
      cursor: pointer;
      background: ${theme.color.mono_100};
      border-radius: 3px;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      border-radius: 3px;
      background: ${theme.color.mono_100};
      background-image: linear-gradient(
        90deg,
        ${theme.color.highlight} calc(var(--webkit-range-progress, 0) * 1%),
        ${theme.color.mono_100} calc(var(--webkit-range-progress, 0) * 1%)
      );
    }

    &::-moz-range-progress {
      background: ${theme.color.highlight};
      height: 6px;
      border-radius: 3px;
    }
  }
`;

const InputIcon = styled(Icon)``;

const InputAbsoluteRight = styled.div``;

const InputWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${theme.color.line};
  border-radius: ${theme.borderRadius.default};

  /* @emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */
  > *:first-child:not(${Input}) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  ${Input} {
    flex: 1 1 auto;
    border: none;

    /* @emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */
    &:not(:first-child) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  ${InputIcon} {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  ${InputAbsoluteRight} {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const InputButton = styled.button`
  justify-content: flex-start;
  width: 100%;
  line-height: 20px;
  padding: 10px;
  border-radius: 10px;
  outline: 0;
  border: 0;
  min-width: 0;
  background-color: ${theme.color.body};
`;

const InputDropdownIcon = styled(Icon)``;

const InputDropdown = styled.button`
  justify-content: space-between;
  width: 100%;
  line-height: 20px;
  padding: 10px;
  border-radius: 10px;
  outline: 0;
  min-width: 0;
  border: 1px solid ${theme.color.line};
  display: flex;
  transition: all ${theme.duration.fast} ${theme.curve.fastIn};

  &:hover {
    border: 1px solid ${theme.input.border_hover};
    box-shadow: ${theme.input.boxShadow_hover};
  }

  &:focus,
  &[aria-expanded='true'] {
    border: 1px solid ${theme.input.border_focus};
    box-shadow: ${theme.input.boxShadow_focus};
  }
`;

const InputLabel = styled.label`
  color: ${theme.color.text_light};
  font-weight: ${theme.fontWeight.regular};
`;

const InputHint = styled.div`
  color: ${theme.color.text_light};
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.fontSize.small};
  padding-top: ${theme.su(1)};
`;

export const argInput = {
  InputContainer,
  InputRow,
  InputLabel,
  InputHint,
  InputIcon,
  InputWrapper,
  Input,
  InputButton,
  InputDropdown,
  InputDropdownIcon,
  InputAbsoluteRight,
};
