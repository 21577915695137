import styled from '@emotion/styled';

import { notificationSettingsStore } from './NotificationSettingsStore';

import { theme } from '../../../theme/theme';

import { Throbber } from '../../../components/common/Throbber';
import { NotificationSettingsTableOwners } from './NotificationSettingsTableOwners';

import { iconTimelineNotifications } from '../../../theme/icons-v2/system/timeline-notifications/timeline-notifications';

export function NotificationSettings() {
  const { notificationSettings } = notificationSettingsStore.use();

  if (notificationSettings.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  return (
    <S.Root>
      <NotificationSettingsTableOwners />
    </S.Root>
  );
}

NotificationSettings.pathKey = 'system/timeline';
NotificationSettings.icon = iconTimelineNotifications;
NotificationSettings.backgroundColor = theme.color.system_timeline_notifications;

function S() {}
NotificationSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
