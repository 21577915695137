import { ResourceUtils } from '../../../store/ResourceUtils';
import { FlowEditor } from './useFlowEditor';
import { NodeArgumentsContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { AddCardButton, flowCardTypes } from './flowCardTypes';
import { FlowCard } from './FlowCard';
import { FlowCardTriggerContextMenuContent } from './FlowCardTriggerContextMenuContent';

export function FlowCardTrigger(props) {
  const { i18n } = useI18n();
  const triggerData = props.trigger;
  const key = ResourceUtils.getKey(triggerData);
  const triggerCard = props.flowCards.triggers.byKey?.[key];

  return (
    <NodeArgumentsContext>
      <flowCardTypes.Section>
        <flowCardTypes.Title isNonActive={!triggerData}>
          {`${i18n.messageFormatter('flow.when')}...`}
        </flowCardTypes.Title>

        {triggerData && (
          <flowCardTypes.Content>
            <FlowCard
              key={key}
              cardType="trigger"
              cardIndex={0}
              card={triggerCard}
              data={triggerData}
              contextMenuContent={
                <FlowCardTriggerContextMenuContent
                  card={triggerCard}
                  data={triggerData}
                  onReplaceRequest={() => {
                    props.setFlowCardPickerState({
                      type: 'trigger',
                      cardIndex: 0,
                      currentCard: triggerCard,
                    });
                  }}
                  onDeleteRequest={() => {
                    FlowEditor.deleteTriggerCard({
                      cardIndex: 0,
                    });
                  }}
                />
              }
            />
          </flowCardTypes.Content>
        )}

        {triggerData == null && (
          <AddCardButton
            onClick={() => {
              props.setFlowCardPickerState({
                type: 'trigger',
              });
            }}
          >
            {i18n.messageFormatter('flow.addCard')}
          </AddCardButton>
        )}
      </flowCardTypes.Section>
    </NodeArgumentsContext>
  );
}
