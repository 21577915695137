import { accessorHandler, createNamedProxy } from '../utils';

export const fontSize = {
  tiny: '12px',
  small: '14px',
  default: '16px',
  medium: '18px',
  heading3: '20px',
  heading2: '24px',
  heading1: '36px',
  hero1: '48px',
} as const;

export const proxy: typeof fontSize = createNamedProxy('fontSize', fontSize, accessorHandler);
