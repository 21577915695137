export function ExternalLink(props) {
  // Show the URL if there are no children.
  const children = props.children ? props.children : props.url;

  return (
    <a className={props.className} href={props.url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}
