import { useRef, useEffect } from 'react';

export function useMount() {
  const ref = useRef({ isMounted: true });

  useEffect(() => {
    const current = ref.current;
    current.isMounted = true;
    return function () {
      current.isMounted = false;
    };
  }, []);

  return ref.current;
}
