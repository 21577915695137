import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import { css } from '@emotion/react';
import { keyframes } from '@emotion/react';

export function Pulse(props) {
  return <Pulse.Root active={props.active} color={props.color} />;
}

const animationPulse = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: .4;
  }

  50% {
    opacity: .4;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0;
  }
`;

Pulse.Root = styled.span`
  --color: ${(props) =>
    props.active ? (props.color ? props.color : theme.color.highlight) : theme.color.mono_300};
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 1;
  background-color: var(--color);
  vertical-align: baseline;

  ::before {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color);
    z-index: -1;
    will-change: transform;
    opacity: 0;

    ${(props) =>
      props.active &&
      css`
        animation: 2s ${animationPulse} infinite ease-out;
      `}
  }
`;
