/**
 * Duration in MS for CSS animations / transitions
 */
export const duration = {
  slow:         '500ms',
  normal:       '350ms',
  fast:         '200ms',
  micro:        '100ms'
} as const;

/**
 * Duration in MS for JS animations
 */
export const durationMS = {
  slow:         500,
  normal:       350,
  fast:         200,
  micro:        100
} as const;

/**
 * Duration in S for JS (framer motion) animations
 */
export const durationS = {
  slow:       0.5,
  normal:       0.35,
  fast:         0.2,
  micro:        0.1
} as const;


/**
 * Transition
 */
export const transition = {
  slow:         `all ${duration.slow} ease-in-out`,
  normal:         `all ${duration.normal} ease-in-out`,
  fast:           `all ${duration.fast} ease-in-out`,
  micro:          `all ${duration.micro} ease-in-out`,
  slow_partial: `${duration.slow} ease-in-out`,
  micro_partial:  `${duration.micro} ease-in-out`
} as const;

/**
 * Curves
 */
export const curve = {
  fastInOut: `cubic-bezier(.25,0,.25,1)`,
  fastIn: `cubic-bezier(.25,0,.5,1)`,
  easeInOut: 'ease-in-out',
  easeIn: 'ease-in',
  easeOut: 'ease-out',
  linear: 'linear',
  ease: 'ease'
} as const
