import { useEffect, useRef } from 'react';

import { useButton, useSelect, HiddenSelect } from 'react-aria';
import { useOverlayTriggerState, useSelectState, Item } from 'react-stately';

import { enqueueTask } from '../../../lib/enqueueTask';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { theme } from '../../../theme/theme';
import { argInput } from './argInput';
import { getTokenFlowKey, useToken } from './tokens';

import { ArgumentBase, Argument } from './Argument';
import { ListBoxOverlay } from '../../../components/forms/select/ListBoxOverlay';

import { TokenPicker } from '../token-picker/TokenPicker';
import { FlowToken } from './FlowToken';

import { iconChevronDown } from '../../../theme/icons/interface/chevron-down';

export function ArgumentDropdown(props) {
  const { i18n } = useI18n();

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  const { token, tokenKey, isToken } = useToken({
    value: localValue,
  });

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.dropdown.title');
  const placeholder =
    props.argument.placeholder ?? i18n.messageFormatter('flow.argument.dropdown.placeholder');

  const selectedOption = props.argument.values.find((option) => option.id === localValue);

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? localValue : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: typeof nextValue === 'string' ? nextValue : undefined,
      });
    }
  }

  function handleTokenSelect(key, { kind, token }) {
    const tokenFlowKey = getTokenFlowKey(key, { kind, token });
    const nextValue = `[[${tokenFlowKey}]]`;

    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: true });

    props.onUpdate?.({
      argumentKey: props.argumentKey,
      value: nextValue,
    });

    // Give the TokenPicker a chance to gracefully start closing.
    enqueueTask(() => {
      triggerState.close();
    });
  }

  function handleClearTokenRequest() {
    const nextValue = undefined;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleChange(value, { preventClose = false } = {}) {
    if (preventClose === false) {
      triggerState.close();
    }

    setLocalValue(value);
    checkInvalid(value, { checkNode: false });

    props.onUpdate?.({
      argumentKey: props.argumentKey,
      value: typeof value === 'string' ? value : undefined,
    });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        switch (true) {
          case token != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken tokenKey={tokenKey} token={token} label={token.title} />
              </ArgumentBase>
            );
          case token == null && isToken:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken title={localValue} />
              </ArgumentBase>
            );
          case localValue != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                {selectedOption?.title ?? 'Missing Title'}
              </ArgumentBase>
            );
          default:
            return (
              <ArgumentBase
                {...triggerProps}
                ref={triggerRef}
                data-is-invalid={isInvalid}
                data-is-empty={true}
              >
                {props.argumentTypeText}
              </ArgumentBase>
            );
        }
      }}
      renderOverlay={() => {
        return (
          <DropdownInput
            label={label}
            placeholder={placeholder}
            value={localValue ?? ''}
            values={props.argument.values}
            token={token}
            tokenKey={tokenKey}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
            onTokenSelect={handleTokenSelect}
            onClearTokenRequest={handleClearTokenRequest}
          />
        );
      }}
    />
  );
}

export function DropdownInput(props) {
  const containerRef = useRef();
  const selectRef = useRef();
  const tokenButtonRef = useRef();

  function renderCollectionItem(sourceItem) {
    return (
      <Item
        key={sourceItem.id}
        value={sourceItem}
        aria-label={sourceItem['aria-label']}
        textValue={sourceItem.title ?? '-'}
        children={sourceItem}
      />
    );
  }

  const sharedProps = {
    children: renderCollectionItem,
    items: props.values,
    label: props.label,
    placeholder: props.placeholder,
    selectedKey: props.value,
    onSelectionChange: props.onChange,
    // Is the focus for the overlay.
    autoFocus: true,
  };

  const selectState = useSelectState({ ...sharedProps });
  const select = useSelect(
    {
      ...sharedProps,
      onKeyDown(event) {
        if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        } else if (event.key === 'Delete') {
          event.preventDefault();
          props.onChange(undefined, { preventClose: true });
          // Let it rerender in the cleared state. After that we can focus the Select.
          enqueueTask(() => {
            selectRef.current?.focus();
          });
        }
      },
      onKeyUp(event) {
        if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        } else if (event.key === 'Delete') {
          event.preventDefault();
        }
      },
    },
    selectState,
    selectRef
  );
  const button = useButton({ ...select.triggerProps }, selectRef);

  function getRenderedValue() {
    return selectState.selectedItem
      ? selectState.selectedItem.textValue
      : props.placeholder?.length
      ? props.placeholder
      : '';
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      selectRef.current?.focus();
      tokenButtonRef.current?.focus();
    });
  }, []);

  const tokenButton = useButton(
    {
      elementType: FlowToken.Root.__emotion_base,
      onKeyDown(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
          props.onSaveRequest();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        } else if (event.key === 'Delete') {
          event.preventDefault();
          props.onChange(undefined, { preventClose: true });
          enqueueTask(() => {
            selectRef.current?.focus();
          });
        }
      },
      onKeyUp(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        } else if (event.key === 'Delete') {
          event.preventDefault();
        }
      },
      onPress() {
        props.onClearTokenRequest();
        // Let it rerender in the cleared state. After that we can focus the Select.
        enqueueTask(() => {
          selectRef.current?.focus();
        });
      },
    },
    tokenButtonRef
  );

  return (
    <argInput.InputContainer ref={containerRef}>
      {props.token == null && (
        <HiddenSelect
          state={selectState}
          triggerRef={selectRef}
          label={props.label}
          name={props.name}
        />
      )}

      <argInput.InputLabel {...select.labelProps}>{props.label}</argInput.InputLabel>

      <argInput.InputRow>
        {props.token == null && (
          <argInput.InputDropdown {...button.buttonProps} ref={selectRef}>
            <span {...select.valueProps}>{getRenderedValue()}</span>
            <argInput.InputDropdownIcon size={theme.icon.size_small} url={iconChevronDown} />
          </argInput.InputDropdown>
        )}

        {props.token != null && (
          <FlowToken
            {...tokenButton.buttonProps}
            ref={tokenButtonRef}
            tokenKey={props.tokenKey}
            token={props.token}
            showRemoveIcon={true}
            isValueTooltipDisabled={true}
            label={props.token.title}
          />
        )}

        {props.tokenTypes != null && (
          <TokenPicker types={props.tokenTypes} onSelect={props.onTokenSelect} />
        )}
      </argInput.InputRow>

      {selectState.isOpen && (
        <ListBoxOverlay
          menuProps={select.menuProps}
          selectState={selectState}
          targetRef={selectRef}
        />
      )}
    </argInput.InputContainer>
  );
}
