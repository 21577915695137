import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { useTabList } from 'react-aria';
import { Item, useTabListState } from 'react-stately';

import { theme } from '../../theme/theme';

import { TabItem, renderTab } from './Tab';
import { TabPanelItem, renderTabPanel } from './TabPanel';

export function renderItem(sourceItem) {
  return (
    <Item
      key={sourceItem.key}
      value={sourceItem}
      aria-label={sourceItem['aria-label']}
      textValue={sourceItem.textValue ?? '-'}
      children={sourceItem}
    />
  );
}

export function Tabs(props) {
  const ref = useRef();

  const sharedProps = {
    id: props.id,
    'aria-label': props['aria-label'],
    'aria-labelledby': props['aria-labelledby'],
    'aria-describedby': props['aria-describedby'],
    'aria-details': props['aria-details'],
    children: renderItem,
    items: props.items,
    disabledKeys: props.disabledKeys,
    selectedKey: props.selectedKey,
    defaultSelectedKey: props.defaultSelectedKey,
    onSelectionChange: props.onSelectionChange,
    keyboardActivation: props.keyboardActivation,
    orientation: props.orientation,
    isDisabled: props.isDisabled,
  };

  const state = useTabListState(sharedProps);
  const tabList = useTabList(sharedProps, state, ref);

  const item = state.collection.getItem(state.selectedKey);
  // console.log(item?.index);

  const activeIndex = item?.index ?? 0;

  function tabMapper(item) {
    return (
      <TabItem key={item.key} item={item} state={state} renderTab={props.renderTab ?? renderTab} />
    );
  }

  function tabPanelMapper(item) {
    return (
      <TabPanelItem
        key={item.key}
        item={item}
        state={state}
        renderTabPanel={props.renderTabPanel ?? renderTabPanel}
      />
    );
  }

  return (
    <Tabs.Root className={props.className} style={props.style} as={props.as}>
      <Tabs.PanelsWrapper>
        <Tabs.Panels
          data-active-index={activeIndex}
          style={{
            '--panels-translate-x': `-${activeIndex * 100}%`,
          }}
        >
          {[...state.collection].map(tabPanelMapper)}
        </Tabs.Panels>
      </Tabs.PanelsWrapper>

      <Tabs.TabList {...tabList.tabListProps} ref={ref}>
        {[...state.collection].map(tabMapper)}
      </Tabs.TabList>
    </Tabs.Root>
  );
}

Tabs.Root = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * To hide the overflow but still be able to animation between views.
 */
Tabs.PanelsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex: 1 1 0;
  width: 100%;
  overflow: hidden;
`;

Tabs.Panels = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  transform: translateX(var(--panels-translate-x));
  transition: transform ${theme.duration.normal} ${theme.curve.easeInOut};
`;

Tabs.TabList = styled.div`
  display: flex;
  flex: 0 0 auto;
`;
