function replace(value: string) {
  return value.replace(/\./g, '__dot__');
}

function reverseReplace(value: string) {
  return value.replace(/__dot__/g, '.');
}

export const replaceDot = {
  replace,
  reverseReplace,
};
