import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';

import { useTextField } from 'react-aria';

import { theme } from '../../../theme/theme';

export function FlowName(props) {
  const inputRef = useRef();

  const [value, setValue] = useState(props.defaultValue);
  const defaultValue = props.defaultValue;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (props.autoFocus === true) {
      inputRef.current.select();
    }
  }, [props.autoFocus]);

  const input = useTextField(
    {
      'aria-label': props['aria-label'],
      value: value,
      placeholder: props.placeholder,
      type: 'text',
      onChange(value) {
        setValue(value);
      },
      onBlur: (event) => {
        const value = event.target.value;

        if (value.length > 0 && value !== props.defaultValue) {
          props.onRequestSave(value);
        } else {
          setValue(props.defaultValue);
          props.onRequestCancel();
        }
      },
      onKeyDown(event) {
        switch (true) {
          case event.key === 'Enter' && event.shiftKey === false:
            event.preventDefault();
            inputRef.current.blur();
            break;
          case event.key === 'Escape':
            setValue(props.defaultValue);
            props.onRequestCancel();
            break;
          default:
            break;
        }
      },
      onKeyUp: (event) => {},
    },
    inputRef
  );

  return (
    <>
      <FlowName.Textarea {...input.inputProps} ref={inputRef} />
      <FlowName.Line />
    </>
  );
}

FlowName.Line = styled.div`
  --shadow-color: ${theme.color.body};
  position: relative;
  height: 1px;
  transition: ${theme.transition.micro};

  background: linear-gradient(
    -90deg,
    ${theme.color.body},
    var(--shadow-color),
    ${theme.color.body}
  );

  &::before {
    --elipse-color: transparent;
    content: '';
    pointer-events: none;
    min-height: 4px;
    height: 4px;
    width: 100%;
    z-index: 1;
    display: block;
    transition: ${theme.transition.micro};
    top: 100%;
    transition: ${theme.transition.micro};
    background: radial-gradient(ellipse at 50% 0%, var(--elipse-color), transparent 66%);
  }
`;

FlowName.Textarea = styled(TextareaAutosize)`
  resize: none;
  text-align: center;
  display: block;
  appearance: none;
  font-size: ${theme.fontSize.heading1};
  line-height: 46px;
  padding: 0 0 5px 0;
  outline: 0;
  width: 100%;
  max-width: 100%;
  font-weight: ${theme.fontWeight.bold};
  border-radius: 0;
  border: none;
  background: ${theme.color.body};
  color: ${theme.color.text};
  caret-color: ${theme.color.mono_1000};
  overflow: hidden;
  transition: none; // no default transitions because of height updates

  &:hover {
    & + ${FlowName.Line} {
      --shadow-color: ${theme.color.mono_100};

      &::before {
        --elipse-color: ${theme.color.mono_o_10};
      }
    }
  }

  &:focus {
    box-shadow: none;

    & + ${FlowName.Line} {
      --shadow-color: ${theme.color.blue_o_50};

      &::before {
        --elipse-color: ${theme.color.blue_o_50};
        opacity: 0.5;
      }
    }
  }

  ::placeholder {
    color: ${theme.color.mono_200};
  }
`;
