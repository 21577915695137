import { useFocusRing, mergeProps } from 'react-aria';

import { RouteManager } from '../../RouteManager';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useOptionContext } from '../../components/list-box/ListBox';
// import { useAuth } from '../../store/AuthStore';

import { theme } from '../../theme/theme';

import { SubNavItem } from '../../components/sub-navigation/SubNavItem';

import { iconPage } from '../../theme/icons/interface/page';

const whiteList = new Set([
  'system/api-keys',
  'system/general',
  'system/updates',
  'system/family',
  'system/led-ring',
  'system/experiments',
  'system/timeline',
  'system/location',
  'system/language',
  'integrations/alexa',
  'integrations/google-assistant',
  'integrations/siri-shortcuts',
]);

export function SettingsNavigationItem(props) {
  const { i18n } = useI18n();
  // const { experimentalFlag } = useAuth();

  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();
  const focusRing = useFocusRing();

  return (
    <SubNavItem.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      data-is-selected={option.isSelected}
      data-is-focus-visible={focusRing.isFocusVisible}
      data-is-disabled={option.isDisabled}
    >
      <SubNavItem.Link href={RouteManager.getPathForSetting(item.key)}>
        <SubNavItem.IconWrapper backgroundColor={item.value.context.backgroundColor}>
          <SubNavItem.Icon
            url={item.value.context.icon ?? iconPage}
            size="18px"
            color={theme.color.white}
          />
        </SubNavItem.IconWrapper>

        <SubNavItem.Name>{item.textValue}</SubNavItem.Name>
      </SubNavItem.Link>

      {whiteList.has(item.key) === false && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            // marginRight: -10,
            flex: '0 1000000 auto',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              display: 'inline-block',
              fontSize: 12,
              fontWeight: 500,
              color: theme.color.mono_400,
              backgroundColor: theme.color.mono_020,
              padding: '5px 8px',
              borderRadius: '12px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flex: '0 0 auto',
            }}
          >
            {value.context.experimentalFlag === true
              ? 'AATP'
              : i18n.messageFormatter('common.comingSoon')}
          </div>
        </div>
      )}
    </SubNavItem.Root>
  );
}
