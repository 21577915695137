import { shallow } from 'zustand/shallow';

import { UserMeStore } from './UserMeStore';
import { useAttach } from '../useAttach';

import { selectBase } from '../selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    data: state.data,
    favoriteDevices: state.favoriteDevices,
    favoriteFlows: state.favoriteFlows,
    favoriteMoods: state.favoriteMoods,
    me: state.me,
  };
}

export function useUserMe() {
  useAttach(UserMeStore, 'userMe');

  return UserMeStore.store(selectAll, shallow);
}
