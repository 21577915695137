import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { scaleLinear } from '@visx/scale';

import { ToastManager } from '../../../ToastManager';
import { generalSettingsInfoMemoryStore } from './GeneralSettingsInfoMemoryStore';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { TextButton } from '../../../components/buttons/TextButton';
import { CenteredMessage } from '../../../components/common/CenteredMessage';
import { FlatButton } from '../../../components/buttons/FlatButton';
import { Throbber } from '../../../components/common/Throbber';
import { Icon } from '../../../components/common/Icon';

import { GeneralSettingsSection } from './GeneralSettingsSection';

import { iconChevronDown } from '../../../theme/icons/interface/chevron-down';

export function GeneralSettingsInfoMemory(props) {
  const { i18n } = useI18n();
  const [expanded, setExpanded] = useState(false);
  const showCount = 5;

  const { generalSettingsInfoMemory } = generalSettingsInfoMemoryStore.use();

  const xScale = scaleLinear({
    domain: [0, generalSettingsInfoMemory.total],
    range: [0, 100],
  });

  const bars = generalSettingsInfoMemory.items.reduce(
    (accumulator, item) => {
      const barPercentage = xScale(item.size);

      const bar = (
        <Bar
          key={item.key}
          x={`${accumulator.offset}%`}
          y={0}
          width={`${barPercentage}%`}
          height={32}
          fill={item.color}
        />
      );

      accumulator.bars.push(bar);
      accumulator.offset = accumulator.offset + barPercentage;

      return accumulator;
    },
    { bars: [], offset: 0 }
  );

  useEffect(() => {
    if (generalSettingsInfoMemory.isLoading === true) return;
    if (generalSettingsInfoMemory.error != null) return;

    let timeout;

    function refresh() {
      timeout = setTimeout(async () => {
        await generalSettingsInfoMemoryStore.refresh().catch(ToastManager.handleError);
        refresh();
      }, 30 * 1000);
    }

    refresh();

    return function () {
      clearTimeout(timeout);
    };
  }, [generalSettingsInfoMemory.isLoading, generalSettingsInfoMemory.error]);

  if (generalSettingsInfoMemory.isLoading === true) {
    return (
      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>
          {i18n.messageFormatter('common.memory')}
        </GeneralSettingsSection.S.SectionHeading>

        <S.Root>
          <GeneralSettingsSection.S.Center>
            <Throbber size={theme.icon.size_default} />
          </GeneralSettingsSection.S.Center>
        </S.Root>
      </GeneralSettingsSection.S.Section>
    );
  }

  if (generalSettingsInfoMemory.error != null) {
    return (
      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>
          {i18n.messageFormatter('common.memory')}
        </GeneralSettingsSection.S.SectionHeading>
        <S.Root>
          <GeneralSettingsSection.S.Center>
            <CenteredMessage
              title="Error"
              subtitle={generalSettingsInfoMemory.error?.message}
              action={
                <FlatButton
                  styleColor="blue"
                  onPress={() => {
                    generalSettingsInfoMemoryStore.retry();
                  }}
                >
                  {i18n.messageFormatter('common.retry')}
                </FlatButton>
              }
            />
          </GeneralSettingsSection.S.Center>
        </S.Root>
      </GeneralSettingsSection.S.Section>
    );
  }

  return (
    <GeneralSettingsSection.S.Section>
      <GeneralSettingsSection.S.SectionHeading>
        {i18n.messageFormatter('common.memory')}
      </GeneralSettingsSection.S.SectionHeading>

      <S.Root data-use-auto-height={true}>
        <GeneralSettingsSection.S.FadeInWrapper>
          <svg width="100%" height={32}>
            <defs>
              <rect id="rect" rx={4} width="100%" height={32} fill={theme.color.mono_050} />
            </defs>

            <clipPath id="clip-path">
              <use xlinkHref="#rect" />
            </clipPath>

            <use xlinkHref="#rect" />

            <Group clipPath="url(#clip-path)">{bars.bars}</Group>
          </svg>

          <S.Total>
            {i18n.messageFormatter('common.freeOf', {
              value: generalSettingsInfoMemory.freeFormatted,
              maxValue: generalSettingsInfoMemory.totalFormatted,
            })}

            {generalSettingsInfoMemory.swapFormatted != null &&
              ` + ${generalSettingsInfoMemory.swapFormatted} Swap`}
          </S.Total>

          <S.Items>
            {generalSettingsInfoMemory.items.map((item, index) => {
              if (expanded === false && index >= showCount) {
                return null;
              }

              return (
                <S.Row key={item.key}>
                  <S.Color style={{ '--color': item.color }} />
                  <S.Name>{item.name}</S.Name>
                  <S.Value>{item.sizeFormatted}</S.Value>
                </S.Row>
              );
            })}
          </S.Items>

          {generalSettingsInfoMemory.items.length > showCount && (
            <S.Footer data-is-expanded={expanded}>
              <TextButton
                styleFontWeight="regular"
                styleColor="light"
                onPress={() => {
                  setExpanded(!expanded);
                }}
              >
                <TextButton.Text>
                  {(() => {
                    if (expanded !== true) {
                      return i18n.messageFormatter('common.showMore', {
                        value: generalSettingsInfoMemory.items.length - showCount,
                      });
                    }

                    return i18n.messageFormatter('common.collapse');
                  })()}
                </TextButton.Text>
                <TextButton.Icon url={iconChevronDown} size="10px" />
              </TextButton>
            </S.Footer>
          )}
        </GeneralSettingsSection.S.FadeInWrapper>
      </S.Root>
    </GeneralSettingsSection.S.Section>
  );
}

function S() {}
GeneralSettingsInfoMemory.S = S;

S.Root = styled.div`
  position: relative;
  width: 440px;
  min-height: 352px;
  padding: ${su(2)} ${su(2)} ${su(0)};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.color.component};

  &[data-use-auto-height='true'] {
    min-height: auto;
  }
`;

S.Total = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  text-align: center;
  padding: 10px;
`;

S.Row = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: [first] min-content;
  grid-auto-flow: column;
  padding: 8px 0;
  align-items: center;
`;

S.Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--color);
`;

S.Name = styled.div`
  line-height: 1.5;
`;

S.Value = styled.div`
  justify-self: end;
  color: ${theme.color.text_light};
  line-height: 1.5;
`;

S.Items = styled.div`
  padding-bottom: 10px;
`;

S.Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 5px 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: ${theme.color.line};
  }

  ${Icon.S.Root} {
    transition: ${theme.duration.fast} ${theme.curve.easeInOut};
    transition-property: transform;
  }

  &[data-is-expanded='true'] {
    ${Icon.S.Root} {
      transform: rotate(180deg);
    }
  }
`;
