import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { noop } from '../../../lib/noop';
import { mergeRefs } from '../../../lib/mergeRefs';
import { dragTypes } from '../../../components/dnd/dragTypes';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../../components/common/Icon';

import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';

import { EditNameTextField } from './EditNameTextField';
import { ZoneContextMenuContent } from '../zone-context-menu/ZoneContextMenuContent';

export function ZoneNavigationList({
  tree,
  listToRender,
  byId,
  selectedId,
  draftZone,
  editId,
  icon,
  setDraftZone,
  setEditZoneId,
  onDrop,
  onSelect,
  onDraftZoneBlur,
  onEditBlur,
  onDraftZoneSave,
  onEditSave,
}) {
  const [dragId, setDragId] = useState(null);
  const { root, children } = tree ?? {};

  function handleItemPress(event) {
    onSelect?.({
      id: event.currentTarget.id,
    });
  }

  function handleDragBegin(dragItem) {
    setDragId(dragItem.id);
  }

  function handleDragEnd() {
    setDragId(null);
  }

  if (listToRender == null) return null;

  return (
    <>
      {listToRender.map((item) => {
        const isValidDropTarget = dragId
          ? !children[dragId].has(item.id) // && data[dragId].parent !== item.id
          : true;
        const isSelected = item.id === selectedId || (selectedId == null && root.id === item.id);
        const isEdit = item.id === editId;

        if (draftZone && item.id === draftZone.id) {
          return (
            <DraftZoneItem
              key={item.id}
              item={item}
              icon={icon}
              draftZone={draftZone}
              onDraftZoneBlur={onDraftZoneBlur}
              onDraftZoneSave={onDraftZoneSave}
            />
          );
        } else {
          return (
            <Item
              key={item.id}
              item={item}
              byId={byId}
              icon={icon}
              isSelected={isSelected}
              isEdit={isEdit}
              isValidDropTarget={isValidDropTarget}
              setDraftZone={setDraftZone}
              setEditZoneId={setEditZoneId}
              onDragBegin={handleDragBegin}
              onDragEnd={handleDragEnd}
              onDrop={onDrop ?? noop}
              onClick={handleItemPress}
              onEditBlur={onEditBlur}
              onEditSave={onEditSave}
            />
          );
        }
      })}
    </>
  );
}

function Item({
  item,
  byId,
  icon,
  isSelected,
  isEdit,
  isValidDropTarget,
  setDraftZone,
  setEditZoneId,
  onClick,
  onDragBegin,
  onDragEnd,
  onDrop,
  onEditBlur,
  onEditSave,
}) {
  const dragItem = {
    type: dragTypes.ZONE,
    id: item.id,
    iconUrl: icon(byId[item.id]),
    name: byId[item.id].name,
  };

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: dragTypes.ZONE,
    item: () => {
      onDragBegin(dragItem);
      return dragItem;
    },
    end(dragItem, dragMonitor) {
      onDragEnd(dragItem);
    },
    collect(dragMonitor) {
      return {
        isDragging: !!dragMonitor.isDragging(),
      };
    },
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: [dragTypes.ZONE, dragTypes.DEVICE],
    collect: (dropMonitor) => {
      return {
        isOver: dropMonitor.isOver() && isValidDropTarget && !isDragging,
        canDrop: dropMonitor.canDrop(),
      };
    },
    canDrop(dragItem, dropMonitor) {
      return dragItem.id !== item.id && isValidDropTarget;
    },
    drop(dragItem, monitor) {
      onDrop({ type: dragItem.type, dropId: item.id, dragId: dragItem.id });
    },
  });

  return (
    <ContextMenu
      content={
        <ZoneContextMenuContent
          zone={byId[item.id]}
          onRenameRequest={() => {
            setEditZoneId(item.id);
          }}
          onCreateRequest={() => {
            setDraftZone({ id: 'create', parentId: item.id });
          }}
        />
      }
    >
      {({ isOpen, onContextMenu }) => {
        return (
          <S.Zone
            ref={!isEdit ? mergeRefs([dragRef, dropRef]) : null}
            id={item.id}
            level={item.level}
            data-can-drop={canDrop}
            data-is-selected={isSelected}
            data-is-over={isOver}
            data-is-context-menu-open={isOpen}
            onClick={onClick}
            onContextMenu={onContextMenu}
          >
            {icon && (
              <Icon
                url={icon(byId[item.id])}
                color={theme.color.icon_dark}
                size={theme.icon.size_default}
              />
            )}

            {isEdit ? (
              <NameField item={item} data={byId} onEditBlur={onEditBlur} onEditSave={onEditSave} />
            ) : (
              <>
                <S.ZoneLabel isSelected={isSelected}>{byId[item.id].name}</S.ZoneLabel>

                <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
              </>
            )}
          </S.Zone>
        );
      }}
    </ContextMenu>
  );
}

function NameField({ item, data, onEditBlur, onEditSave }) {
  const [name, setName] = useState(data[item.id].name);
  const itemName = data[item.id].name;

  useEffect(() => {
    setName(itemName);
  }, [itemName]);

  return (
    <EditNameTextField
      defaultValue={name}
      onBlur={(event) => {
        if (event.currentTarget.value.length === 0) return onEditBlur();

        const prevName = name;
        setName(event.currentTarget.value);

        function reject() {
          setName(prevName);
        }

        function resolve() {}

        onEditBlur();
        onEditSave({
          id: item.id,
          value: event.currentTarget.value,
          resolve,
          reject,
        });
      }}
      onKeyUp={(event) => {
        switch (event.key) {
          case 'Enter':
            if (event.currentTarget.value.length === 0) return;
            const prevName = name;
            setName(event.currentTarget.value);

            function reject() {
              setName(prevName);
            }

            function resolve() {}

            onEditBlur();
            onEditSave({
              id: item.id,
              value: event.currentTarget.value,
              resolve,
              reject,
            });
            break;
          // Escape
          case 'Escape':
            onEditBlur();
            break;
          default:
            break;
        }
      }}
    />
  );
}

function DraftZoneItem({ item, icon, draftZone, onDraftZoneBlur, onDraftZoneSave }) {
  return (
    <S.Zone key={item.id} level={item.level}>
      <Icon url={icon(null)} size={theme.icon.size_default} color={theme.color.icon_dark} />

      {draftZone.id === 'create' ? (
        <EditNameTextField
          defaultValue=""
          onBlur={(event) => {
            if (event.currentTarget.value.length === 0) return onDraftZoneBlur();

            onDraftZoneSave({
              id: item.id,
              parentId: draftZone.parentId,
              value: event.currentTarget.value,
            });
          }}
          onKeyUp={(event) => {
            switch (event.key) {
              case 'Enter':
                if (event.currentTarget.value.length === 0) return;

                onDraftZoneSave({
                  id: item.id,
                  parentId: draftZone.parentId,
                  value: event.currentTarget.value,
                });
                break;
              case 'Escape':
                onDraftZoneBlur();
                break;
              default:
                break;
            }
          }}
        />
      ) : (
        <S.ZoneLabel isSelected={false}>{draftZone.name}</S.ZoneLabel>
      )}
    </S.Zone>
  );
}

function S() {}
ZoneNavigationList.S = S;

S.ZoneLabel = styled.span``;

S.Zone = styled.div`
  display: flex;
  position: relative;
  z-index: calc(${theme.zIndex.nav} + 1);
  align-items: center;
  margin: 1px ${su(1)};
  border-radius: ${theme.borderRadius.default};
  padding-left: ${getLevelPadding};
  padding-right: ${su(1)};
  outline: 0;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  transition: all ${theme.duration.fast} ${theme.curve.fastIn};

  ${Icon.S.Root} {
    flex: 0 0 auto;
    margin-right: 10px;
    background-color: ${theme.color.icon_dark};
    transition: background-color ${theme.duration.fast} ${theme.curve.fastIn};
  }

  ${S.ZoneLabel} {
    display: inline-block;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: ${su(2)};
    padding: ${su(1)} 0;
  }

  &:hover {
    background-color: ${theme.color.background_hover};
    font-weight: ${theme.fontWeight.medium};

    ${Icon.S.Root} {
      background-color: ${theme.color.icon_dark_hover};
    }

    ${ContextMenuButton.Root} {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &[data-is-context-menu-open='true'] {
    ${ContextMenuButton.Root} {
      opacity: 1;
    }
  }

  &[data-is-over='true'] {
    border-color: ${theme.color.state_drop_line};
    background-color: ${theme.color.state_drop_background};
  }

  &[data-can-drop='true'] {
  }

  &[data-is-selected='true'] {
    &,
    &:hover {
      color: ${theme.color.highlight};

      ${S.ZoneIconWrapper} ${Icon.S.Root} {
        background-color: ${theme.color.highlight};
      }

      ${S.ZoneLabel} {
        font-weight: ${theme.fontWeight.bold};
      }
    }
  }
`;

function getLevelPadding({ level }) {
  return `${level * 20 + 10}px`;
}
