import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { InsightStore } from '../../store/insights/InsightStore';

import { useLogsAttach } from '../../store/insights/useInsights';

export function useLogGroupData({ logs }) {
  useLogsAttach();

  const selector = useCallback(
    (state) => {
      const result = {
        byKey: {},
        defaultLog: null,
        colorMap: state.colorMap,
        manager: state.manager,
      };

      if (state.loading === true || state.byKey == null) {
        return result;
      }

      for (const [index, logKey] of (logs ?? []).entries()) {
        if (index === 0) {
          result.defaultLog = state.byKey[logKey];
        }

        result.byKey[logKey] = state.byKey[logKey];
      }

      return result;
    },
    [logs]
  );

  return InsightStore.store(selector, (prev, next) => {
    return (
      shallow(prev.byKey, next.byKey) &&
      shallow(prev.defaultLog, next.defaultLog) &&
      Object.is(prev.colorMap, next.colorMap) &&
      Object.is(prev.manager, next.manager)
    );
  });
}
