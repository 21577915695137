import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { AthomConnectAPI } from 'athom-api';

import { wait } from '../lib/wait';
import { ToastManager } from '../ToastManager';

import { useAuth } from '../store/AuthStore';
import { useQuery } from '../hooks/useQuery';

import { theme } from '../theme/theme';
import { su } from '../theme/functions/su';

import { ImageBase } from '../components/common/ImageBase';
import { GradientButton } from '../components/buttons/GradientButton';
import { SpinnerFade } from '../components/common/SpinnerFade';

import { UserMenu } from './header/UserMenu';

export function InvitePage(props) {
  const { user } = useAuth();
  const history = useHistory();

  const [isAccepting, setIsAccepting] = useState(false);

  const params = useQuery();
  const inviteToken = params.get('inviteToken');
  const homeyId = params.get('homeyId');
  const ownerId = params.get('ownerId');
  const ownerName = params.get('ownerName');

  function handleHomeySelect({ homeyId }) {
    history.push(`/homeys/${homeyId}`);
  }

  return (
    <InvitePage.Root>
      <InvitePage.Header>
        <UserMenu.Injected onHomeySelect={handleHomeySelect} />
      </InvitePage.Header>

      <InvitePage.Content>
        <InvitePage.Centered>
          <ImageBase
            url={`https://avatars.athom.com/large/${ownerId}.png`}
            width={88}
            height={88}
            round={true}
          />

          <h3>Accept Invite?</h3>
          <p>{ownerName} invited you to their Homey. Would you like to get access?</p>

          <GradientButton
            styleWidth="full"
            isDisabled={isAccepting === true}
            onPress={() => {
              setIsAccepting(true);
              const connectApi = new AthomConnectAPI();
              const min = wait(500);

              connectApi
                .acceptSharingRequest({
                  athomId: user._id,
                  homeyId: homeyId,
                  inviteToken: inviteToken,
                })
                .then(async (response) => {
                  await min;
                  // setIsAccepting(false);
                  window.location.assign(`/homeys/${homeyId}`);
                })
                .catch(async (error) => {
                  await min;
                  setIsAccepting(false);
                  ToastManager.handleError(error);
                });
            }}
          >
            <SpinnerFade
              isActive={isAccepting === true}
              color={theme.color.white}
              size={theme.icon.size_small}
            />
            <GradientButton.Text>Accept</GradientButton.Text>
          </GradientButton>
        </InvitePage.Centered>
      </InvitePage.Content>
    </InvitePage.Root>
  );
}

InvitePage.Root = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
`;

InvitePage.Header = styled.header`
  display: flex;
  flex: 0 0 80px;
  align-items: center;
  justify-content: flex-end;
  min-height: 0;
  padding: ${su(2)};
`;

InvitePage.Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
`;

InvitePage.Centered = styled.div`
  width: 320px;
  text-align: center;

  h3 {
    padding-top: 20px;
  }

  p {
    color: ${theme.color.text_light};
    margin-bottom: 40px;
  }
`;
