import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import { RouteManager } from '../../../RouteManager';

import { useApi } from '../../../store/HomeyStore';
import { useAdvancedFlowsById } from '../../../store/advanced-flow/useAdvancedFlows';

import { DialogShiftLock } from '../../../components/overlay/DialogShiftLock';
import { UpdateView } from './UpdateView';
import { PurchaseView } from './PurchaseView';
import { FlowTypeSelectView } from './FlowTypeSelectView';

export function NewFlowDialog() {
  const location = useLocation();

  const initialView =
    location.state?.viewId != null
      ? NewFlowDialog.views[location.state.viewId]
      : NewFlowDialog.views.default;

  const [view, setView] = useState(initialView);

  const { api } = useApi();
  const advancedFlows = useAdvancedFlowsById();

  function handleClose() {
    RouteManager.replaceState({});
  }

  function checkLicence() {
    const hasAdvancedFlowLicense = api?.features.advancedFlow.hasLicense;

    if (hasAdvancedFlowLicense === false) {
      if (api?.isCloud === true) {
        RouteManager.toFlows(RouteManager.getStateForPremiumRequiredDialog());
        return false;
      }

      setView(NewFlowDialog.views.purchase);
      return false;
    }

    return true;
  }

  function checkVersion() {
    const hasAdvancedFlowVersion = api?.features.advancedFlow.hasVersion;

    if (hasAdvancedFlowVersion === false) {
      setView(NewFlowDialog.views.update);
      return false;
    }

    // If no Advanced Flows are present show the template.
    if (Object.values(advancedFlows.byId ?? {}).length === 0) {
      RouteManager.toAdvancedFlow(`create?id=template`);
      return true;
    }

    RouteManager.toAdvancedFlow(`create?id=${uuid()}`);
    return true;
  }

  function getView() {
    switch (view.id) {
      case 'purchase':
        return (
          <PurchaseView
            onCloseRequest={handleClose}
            onContinueRequest={() => {
              checkVersion();
            }}
          />
        );
      case 'update':
        return <UpdateView onCloseRequest={handleClose} />;
      case 'default':
      default:
        return (
          <FlowTypeSelectView
            onCloseRequest={handleClose}
            onNavigateToAdvancedFlowRequest={() => {
              if (checkLicence() === false) return;
              if (checkVersion() === false) return;
            }}
            onNavigateToFlowRequest={() => {
              RouteManager.toFlow(`create?id=${uuid()}`);
            }}
          />
        );
    }
  }

  return (
    <DialogShiftLock layoutId={view.id} isOpen={true} onClose={handleClose}>
      <NewFlowDialog.View>{getView()}</NewFlowDialog.View>
    </DialogShiftLock>
  );
}

NewFlowDialog.views = {
  default: { id: 'default' },
  update: { id: 'update' },
  purchase: { id: 'purchase' },
};

NewFlowDialog.View = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 100%;
`;
