import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { ResourceUtils } from '../../../../store/ResourceUtils';

import { platform } from '../../../../lib/platform';

import { useI18n } from '../../../../hooks/useI18nFormatters';
import { useContextMenuContext } from '../../../../components/common/context-menu/ContextMenuContextProvider';
import { useTreeUsedTokenKeys } from '../useTreeTokenKeys';

import { ContextMenuContent } from '../../../../components/common/context-menu/ContextMenuContent';
import { DraggableNode } from './DraggableNode';
import { AdvancedFlowTestForm } from '../AdvancedFlowTestForm';
import { FlowCardMissingContextMenuContent } from '../../view-flow/FlowCardMissingContextMenuContent';
import { DeveloperMenu } from '../../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../../theme/icons/interface/trash-fill/trash-fill';
import { iconPlayCircle } from '../../../../theme/icons/interface/play-circle/play-circle';
import { iconCopyDuplicate } from '../../../../theme/icons/interface/copy-duplicate';
import { iconReplace } from '../../../../theme/icons/interface/replace';
import { iconLeftArrowRight } from '../../../../theme/icons/interface/arrow-left-arrow-right';

export function AdvancedFlowCardContextMenuContent(props) {
  const { i18n } = useI18n();
  const ctx = useContextMenuContext();
  // const { selected } = AdvancedFlowViewStore.useSelected();

  const { advancedFlow, nodeChildren, nodeAncestors } = AdvancedFlowViewStore.useAdvancedFlowView();
  const { treeTokenKeys, canTestWithoutTokens } = useTreeUsedTokenKeys({
    fromNodeId: props.nodeId,
    advancedFlow,
    nodeChildren,
    nodeAncestors,
  });

  function onAction(key, args) {
    // const position = ctx.getPosition();

    switch (key) {
      case 'copy':
        props.onCopyRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'start':
        props.onStartRequest({ nodeId: props.nodeId });
        break;
      case 'test':
        // todo
        // handle conditions and actions cards like in normal Flow's
        window.alert('unimplemented');
        break;
      case 'replace':
        props.onReplaceRequest({ nodeId: props.nodeId });
        break;
      case 'duplicate':
        props.onDuplicateRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'invert':
        props.onInvertRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'delay':
        function handleDelayRequest() {
          const key = 'delay';
          let value = null;

          if (props.data[key] == null) {
            value = {
              number: '1',
              multiplier: 1,
            };
          }

          AdvancedFlowViewStore.updateCardProperty({
            nodeId: props.nodeId,
            propertyKey: key,
            value: value,
          });
        }

        handleDelayRequest();
        //props.onDelayRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'duration':
        function handleDurationRequest() {
          const key = 'duration';
          let value = null;

          if (props.data[key] == null) {
            value = {
              number: '1',
              multiplier: 1,
            };
          }

          AdvancedFlowViewStore.updateCardProperty({
            nodeId: props.nodeId,
            propertyKey: key,
            value: value,
          });
        }

        handleDurationRequest();
        // props.onDurationRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'delete':
        props.onDeleteRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      default:
        break;
    }
  }

  if (props.card == null) {
    return (
      <FlowCardMissingContextMenuContent
        {...props}
        onReplaceRequest={() => {
          onAction('replace');
        }}
        onDeleteRequest={() => {
          onAction('delete');
        }}
      />
    );
  }

  return (
    <ContextMenuContent.List>
      {canTestWithoutTokens ? (
        <ContextMenuContent.ListItem
          icon={iconPlayCircle}
          onPress={() => {
            onAction('start');
          }}
        >
          {i18n.messageFormatter('flow.testFromHere')}
        </ContextMenuContent.ListItem>
      ) : (
        <ContextMenuContent.SubMenu title={i18n.messageFormatter('flow.testFromHere')}>
          <AdvancedFlowTestForm
            currentNodeId={props.nodeId}
            treeTokenKeys={treeTokenKeys}
            advancedFlow={advancedFlow}
            onStartRequest={({ tokens }) => {
              ctx.onCloseRequest();
              props.onStartRequest({ nodeId: props.nodeId, tokens });
            }}
          />
        </ContextMenuContent.SubMenu>
      )}

      {/*{canTestWithoutTokens ? (*/}
      {/*  <ContextMenuContent.ListItem*/}
      {/*    icon={iconPlay}*/}
      {/*    onPress={() => {*/}
      {/*      onAction('test');*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {messageFormatter('flow.testThisCard')}*/}
      {/*  </ContextMenuContent.ListItem>*/}
      {/*) : (*/}
      {/*  <ContextMenuContent.SubMenu title={messageFormatter('flow.testThisCard')} as="div">*/}
      {/*    <AdvancedFlowTestForm*/}
      {/*      treeTokens={treeTokens}*/}
      {/*      advancedFlow={advancedFlow}*/}
      {/*      onStartRequest={({ tokens }) => {*/}
      {/*        onAction('test');*/}
      {/*        // ctx.onCloseRequest();*/}
      {/*        // props.onStartRequest({ nodeId: props.nodeId, tokens });*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </ContextMenuContent.SubMenu>*/}
      {/*)}*/}

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.copy')}
        hint={`${platform.metaOrCtrlSymbol}C`}
        onPress={() => {
          onAction('copy');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.replace')}
        icon={iconReplace}
        onPress={() => {
          onAction('replace');
        }}
      />

      {props.data?.type === 'action' && props.card?.duration === true && (
        <>
          <ContextMenuContent.Divider />
          <ContextMenuContent.ListItem
            label={
              props.data.duration
                ? i18n.messageFormatter('flow.removeDuration')
                : `${i18n.messageFormatter('flow.addDuration')}...`
            }
            onPress={() => {
              onAction('duration');
            }}
          />
          {/*Disabled because of delay card exists*/}
          {/*<ContextMenuContent.ListItem*/}
          {/*  onPress={() => {*/}
          {/*    onAction('delay');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {props.data.delay*/}
          {/*    ? messageFormatter('flow.removeDelay')*/}
          {/*    : `${messageFormatter('flow.addDelay')}...`}*/}
          {/*</ContextMenuContent.ListItem>*/}
        </>
      )}

      {props.data?.type === 'condition' && (
        <>
          <ContextMenuContent.Divider />

          <ContextMenuContent.ListItem
            label={i18n.messageFormatter('flow.invert')}
            icon={iconLeftArrowRight}
            onPress={() => {
              onAction('invert');
            }}
          />
        </>
      )}

      <DeveloperMenu
        onIdRequest={() => props.card?.id}
        onUriRequest={() => ResourceUtils.getOwnerUri(props.card ?? {})}
        onResourceRequest={() => {
          return {
            card: props.card,
            data: props.data,
            nodeId: props.nodeId,
          };
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
