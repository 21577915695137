import { useI18n } from '../../hooks/useI18nFormatters';
import { useScript } from './useScripts';

import { ContextMenuContent } from '../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';
import { iconPlay } from '../../theme/icons/interface/play';

export function ScriptNavigationItemContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { script, appApiVersion } = useScript({ scriptId: props.scriptId });

  function onAction(key) {
    switch (key) {
      case 'start':
        props.onStartRequest();
        break;
      case 'rename':
        props.onRenameRequest();
        break;
      case 'delete':
        props.onDeleteRequest();
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      {appApiVersion >= 2 && (
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter('common.rename')}
          onPress={() => {
            onAction('rename');
          }}
        />
      )}

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.start')}
        icon={iconPlay}
        onPress={() => {
          onAction('start');
        }}
      />

      <DeveloperMenu onIdRequest={() => script?.id} onResourceRequest={() => script} />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
