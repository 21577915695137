import { proxy as color } from './color.palette.dark'

export const func = {
  /* Global */
  highlight_background:     color.blue_o_10_mono_050_b,
  highlight_component_on:   color.blue_o_10_mono_200_b,

  /* Text */
  text_light:               color.mono_500,
  text_light_hover:         color.mono_600,
  text_black:               color.mono_100,
  text_disabled:            color.mono_400,

  /* Main */
  body:                     color.mono_025,
  component:                color.mono_050,
  component_on:             color.mono_200,
  component_off:            color.mono_050,
  component_line:           color.mono_300,
  overlay:                  color.black_o_80,

  /* Backgrounds */
  background_hover:         color.mono_100,

  /* Lines */
  line:                     color.mono_200,

  /* Grids */
  grid:                     color.mono_050,

  /* Icons */
  icon_light:               color.mono_500,
  icon_light_hover:         color.mono_700,
  icon_dark:                color.mono_800,
  icon_dark_hover:          color.mono_900,
  icon_light_inactive:      color.mono_200,
  icon_disabled:            color.mono_200,

  /* Scroll */
  scroll_shadow:            color.black_o_70,

  /* States */
  state_droparea_background: color.mono_020,

  // Selected
  selected_background: color.blue_o_10_mono_100_b, // used for advanced flow cards
}
