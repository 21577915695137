import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';

import HomeyLib from 'homey-lib/webpack/index.js';

import { FocusScope, useLocale } from 'react-aria';

import { RouteManager } from '../../../RouteManager';
import { ResourceUtils } from '../../../store/ResourceUtils';
import { getTitleChunks } from '../../flows/flow-card/useTitleChunks';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useDevicesData } from '../../../store/devices/useDevices';
import { useFlowsData } from '../../../store/flow/useFlows';
import { useFlowCardsData } from '../../../store/flow-cards/useFlowCards';
import { useFlowFoldersData } from '../../../store/flow-folders/useFlowFolders';
import { useLogsData } from '../../../store/insights/useInsights';

import { useDebouncedMemo } from './useDebouncedMemo';

import { theme } from '../../../theme/theme';
import { scrollbarDark } from '../../../theme/elements/scrollbars';

import { AnimationRemain } from '../../../components/animation/AnimationRemain';
import { ResultEntry } from './ResultEntry';
import { ViewButton } from './ViewButton';

const deviceClasses = HomeyLib.Device.getClasses();

export function GlobalSearchResults(props) {
  const { searchFieldState } = props;
  const { i18n } = useI18n();

  const groupsInnerRef = useRef();
  const groupInnerRef = useRef();

  const [{ currentView, prevView }, setViewState] = useState({
    prevView: null,
    currentView: 'groups',
  });

  const setView = useCallback(
    (view) => {
      setViewState((prevViewState) => {
        if (prevViewState.currentView === view) return prevViewState;

        return {
          prevView: prevViewState.currentView,
          currentView: view,
        };
      });
    },
    [setViewState]
  );

  const [height, setHeight] = useState(500);

  useLayoutEffect(() => {
    if (currentView === 'groups') {
      setHeight(groupsInnerRef.current.offsetHeight);
    } else {
      setHeight(groupInnerRef.current.offsetHeight);
    }
  }, [currentView]);

  const { results, flowFolders } = useResults({
    searchFieldState,
  });

  useEffect(() => {
    props.setHasResults(results.devices?.length > 0 || results.flows?.length > 0);

    // setHasResults is setState func so stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  function getProps(view, result) {
    switch (view) {
      case 'devices':
        return {
          key: result.id,
          type: 'device',
          title: result.name,
          subtitle: result.zoneName,
          iconId: result.iconObj?.id,
          color: result.color,
          onPress() {
            props.overlayTriggerState.close();
            RouteManager.toDevice(result.id);
          },
        };

      case 'flows':
        return {
          key: result.id,
          type: 'flow',
          title: result.name,
          subtitle: flowFolders.data?.[result.folder]?.name,
          onPress() {
            props.overlayTriggerState.close();
            RouteManager.toFlow(result.id);
          },
        };

      case 'insights':
        return {
          key: ResourceUtils.temp__getId(result),
          type: 'log',
          title: result.title,
          subtitle: result.uriObj?.name ?? '',
          iconId: result.uriObj?.iconObj?.id,
          color: result.uriObj?.color,
          onPress() {
            props.overlayTriggerState.close();
            RouteManager.toInsights({
              defaultSelectedKeys: [ResourceUtils.temp__getId(result)],
            });
          },
        };

      default:
        return null;
    }
  }

  function renderGroup(view) {
    const groupResults = results[view] ?? [];

    const maxSize = 3;
    const hasResults = groupResults.length > 0;
    const hasMoreResults = groupResults.length > maxSize;

    function handleForwardPress() {
      setView(view);
    }

    function resultsMapper(result, index) {
      if (index >= maxSize) return null;
      const props = getProps(view, result);

      return <ResultEntry {...props} />;
    }

    return (
      hasResults && (
        <GlobalSearchResults.Group>
          <GlobalSearchResults.Header>
            <GlobalSearchResults.CategoryTitle>
              {i18n.messageFormatter(`navigation.${view}`)}
            </GlobalSearchResults.CategoryTitle>

            {hasMoreResults && (
              <ViewButton.Forward onPress={handleForwardPress}>
                View {groupResults.length} {i18n.messageFormatter(`navigation.${view}`)}
              </ViewButton.Forward>
            )}
          </GlobalSearchResults.Header>
          {groupResults.map(resultsMapper)}
        </GlobalSearchResults.Group>
      )
    );
  }

  const isGroupsView = currentView === 'groups';
  const isNotGroupsView = isGroupsView === false;
  const wasGroupViewPrev = prevView !== 'groups' && prevView !== null;
  const wasGroupsViewPrev = prevView === 'groups' && prevView !== null;

  const isGroupsEntering = isGroupsView && wasGroupViewPrev;
  const isGroupEntering = isNotGroupsView && wasGroupsViewPrev;

  const isGroupsLeaving = isNotGroupsView && wasGroupsViewPrev;
  const isGroupLeaving = isGroupsView && wasGroupViewPrev;

  return (
    <GlobalSearchResults.Root height={height}>
      <FocusScope>
        <GlobalSearchResults.GroupContainer
          viewType="groups"
          isEntering={isGroupsEntering}
          isLeaving={isGroupsLeaving}
        >
          <div ref={groupsInnerRef}>
            <AnimationRemain condition={isGroupsView} delay={500}>
              {() => {
                return (
                  <React.Fragment>
                    {renderGroup('devices')}
                    {renderGroup('flows')}
                    {renderGroup('insights')}
                  </React.Fragment>
                );
              }}
            </AnimationRemain>
          </div>
        </GlobalSearchResults.GroupContainer>
        <GlobalSearchResults.GroupContainer
          viewType="group"
          isEntering={isGroupEntering}
          isLeaving={isGroupLeaving}
        >
          <div ref={groupInnerRef}>
            {searchFieldState.value.length !== 0 && (
              <AnimationRemain
                condition={isNotGroupsView && results[currentView] != null}
                delay={500}
              >
                {() => {
                  return (
                    <GlobalSearchResults.Group>
                      <GlobalSearchResults.BackWrapper>
                        <ViewButton.Backward
                          onPress={() => {
                            setView('groups');
                          }}
                        >
                          {i18n.messageFormatter(`common.back`)}
                        </ViewButton.Backward>
                      </GlobalSearchResults.BackWrapper>
                      <GlobalSearchResults.Header>
                        <GlobalSearchResults.CategoryTitle>
                          {i18n.messageFormatter(`navigation.${currentView}`)}
                        </GlobalSearchResults.CategoryTitle>
                      </GlobalSearchResults.Header>

                      {results[currentView]?.map((result) => {
                        return <ResultEntry {...getProps(currentView, result)} />;
                      })}
                    </GlobalSearchResults.Group>
                  );
                }}
              </AnimationRemain>
            )}
          </div>
        </GlobalSearchResults.GroupContainer>
      </FocusScope>
    </GlobalSearchResults.Root>
  );
}

GlobalSearchResults.Root = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
  max-height: min(${(props) => (props.height ? `${props.height}px` : `100%`)}, 500px);
`;

const animationResults = {
  translateX: keyframes`
    from {
      --scrollbar-thumb-background: transparent;
      transform: translateX(var(--from));
    }

    to {
      --scrollbar-thumb-background: transparent;
      transform: translateX(var(--to));
    }
  `,
};

GlobalSearchResults.GroupContainer = styled.div`
  ${scrollbarDark};

  flex: 1 0 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  max-height: 100%;
  transition: opacity 300ms ease-in-out;

  ${(props) => {
    const vars = {
      groups: {
        '--from': '-100%',
        '--to': '0%',
      },
      group: {
        '--from': '0%',
        '--to': '-100%',
      },
    };

    if (props.isEntering) {
      return css`
        ${vars[props.viewType]};
        --scrollbar-thumb-background: unset;

        z-index: 2;
        transform: translateX(var(--to));
        animation-name: ${animationResults.translateX};
        animation-duration: ${theme.duration.slow};
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
        animation-fill-mode: none;
      `;
    }

    if (props.isLeaving) {
      return css`
        ${vars[props.viewType]};

        z-index: 1;
        transform: translateX(var(--to));
        opacity: 0;
      `;
    }
  }};
`;

GlobalSearchResults.Group = styled.div`
  position: relative;
  padding-bottom: 10px;

  ${ViewButton.Root} {
    flex: 0 1 auto;
  }

  :not(:first-of-type)::before {
    content: '';
    margin-left: 20px;
    margin-right: 20px;
    display: block;
    height: 1px;
    background-color: ${theme.color.line};
  }
`;

GlobalSearchResults.Header = styled.div`
  display: flex;
  z-index: 10;

  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0;
  height: 40px;
`;

GlobalSearchResults.BackWrapper = styled.div`
  position: sticky;
  padding: 0 20px;
  top: 0;
  z-index: 10;
  height: 40px;
  background-color: ${theme.color.component};
  margin-bottom: -10px;
`;

GlobalSearchResults.CategoryTitle = styled.div`
  flex: 1 1 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
`;

function useResults({ searchFieldState }) {
  const { locale } = useLocale();
  const { i18n } = useI18n();

  const devices = useDevicesData();
  const flows = useFlowsData();
  const flowCards = useFlowCardsData();
  const flowFolders = useFlowFoldersData();
  const logs = useLogsData();

  const extendedFlowData = useExtendedFlowData({
    flows,
    flowFolders,
    flowCards,
    messageFormatter: i18n.messageFormatter,
  });

  return {
    flowFolders,
    results: useDebouncedMemo(
      () => {
        if (searchFieldState.value.length === 0)
          return {
            devices: null,
            flows: null,
            insights: null,
          };

        const value = searchFieldState.value.toLowerCase();

        function getIsMatch(subject) {
          return subject?.toLowerCase?.().includes(value) ?? false;
        }

        function getIsSomeCardMatch(extendedFlow) {
          return (
            extendedFlow != null &&
            // might aswell merge these in memo
            [...extendedFlow.trigger, ...extendedFlow.conditions, ...extendedFlow.actions].some(
              (cardInfo) => {
                return getIsMatch(cardInfo.title);
              }
            )
          );
        }

        return {
          devices: Object.values(devices.data ?? {}).filter((device) => {
            return (
              getIsMatch(device.name) ||
              getIsMatch(
                deviceClasses?.[device.class]?.title?.[locale] ??
                  [device.class]?.title?.en ??
                  device.class
              ) ||
              getIsMatch(device.zoneName) ||
              getIsMatch(device.id) ||
              getIsMatch(device.driverUri) ||
              getIsMatch(device.driverId)
            );
          }),
          flows: Object.values(flows.data ?? {}).filter((flow) => {
            const extendedFlow = extendedFlowData?.[flow.id];
            if (extendedFlow == null) return true;

            return (
              getIsMatch(flow.name) ||
              getIsSomeCardMatch(extendedFlow) ||
              getIsMatch(flow.id) ||
              getIsMatch(extendedFlow.folder?.name)
            );
          }),
          insights: Object.values(logs.data ?? {}).filter((log) => {
            if (log.type !== 'number') return false;

            return (
              getIsMatch(log.title) ||
              getIsMatch(log.uriObj?.id) ||
              getIsMatch(log.uriObj?.name) ||
              getIsMatch(log.uriObj?.meta?.driverUri) ||
              getIsMatch(log.uriObj?.meta?.zoneName)
            );
          }),
        };
      },
      [searchFieldState.value, flows.data, devices.data, logs.data, extendedFlowData, locale],
      300
    ),
  };
}

function useExtendedFlowData({ flows, flowFolders, flowCards, messageFormatter }) {
  return useMemo(() => {
    if (
      flows.data == null ||
      flowFolders.data == null ||
      flowCards.triggerData == null ||
      flowCards.conditionData == null ||
      flowCards.actionData == null
    ) {
      return null;
    }

    // todo
    // fill in args
    function getTitle({ data, card }) {
      const { titleChunks } = getTitleChunks({
        data: data,
        card: card,
        messageFormatter: messageFormatter,
      });

      return titleChunks
        .map((chunk) => {
          if (chunk.type === 'text') {
            return chunk.value;
          }

          return '';
        })
        .join('');
    }

    return Object.values(flows.data).reduce((accumulator, flow) => {
      accumulator[flow.id] = {};
      accumulator[flow.id].flow = flow;
      accumulator[flow.id].folder = flowFolders.data[flow.folder];

      accumulator[flow.id].trigger = [];
      accumulator[flow.id].conditions = [];
      accumulator[flow.id].actions = [];

      const triggerKey = ResourceUtils.getKey(flow.trigger);
      const triggerCard = flowCards.triggerData[triggerKey];

      if (triggerCard != null) {
        const title = getTitle({ data: flow.trigger, card: triggerCard });
        accumulator[flow.id].trigger.push({
          dataSource: flow.trigger,
          cardSource: triggerCard,
          title: title,
        });
      }

      flow.conditions?.forEach((condition) => {
        const conditionKey = ResourceUtils.getKey(condition);
        const conditionCard = flowCards.conditionData[conditionKey];
        if (conditionCard == null) return;

        const title = getTitle({ data: condition, card: conditionCard });
        accumulator[flow.id].conditions.push({
          dataSource: condition,
          cardSource: conditionCard,
          title: title,
        });
      });

      flow.actions?.forEach((action) => {
        const actionKey = ResourceUtils.getKey(action);
        const actionCard = flowCards.actionData[actionKey];
        if (actionCard == null) return;

        const title = getTitle({ data: action, card: actionCard });
        accumulator[flow.id].actions.push({
          dataSource: action,
          cardSource: actionCard,
          title: title,
        });
      });

      return accumulator;
    }, {});
  }, [
    flows.data,
    flowFolders.data,
    flowCards.triggerData,
    flowCards.conditionData,
    flowCards.actionData,
    messageFormatter,
  ]);
}
