import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import throttle from 'lodash.throttle';
import { useGlobalListeners } from '@react-aria/utils';
import getScrollParent from 'dom-helpers/scrollParent';
import getPosition from 'dom-helpers/position';

import { clamp } from '../lib/math';
import { isWebKit } from '../lib/platform';
import { disableTextSelection, restoreTextSelection } from '../lib/dom/textSelection';

import { theme } from '../theme/theme';
import { disableChildPointerEventsClass } from '../theme/classes/pointer-evens';

/**
 * Expects selectable nodes to have the following attributes:
 *
 * data-node="true"
 * data-id="some-unique-id"
 * data-type="some-type" e.g. line, widget
 */
export function useSelectBox({ setSelected, isDisabled }, containerRef) {
  const selectBoxRef = useRef();
  const stateRef = useRef({});
  const [isSelecting, setIsSelecting] = useState(false);

  const { addGlobalListener, removeGlobalListener } = useGlobalListeners();

  function onPointerDown(onPointerDownEvent) {
    // the component is not rendered so there is no box element
    if (selectBoxRef.current == null) {
      return;
    }

    if (onPointerDownEvent.target !== containerRef.current) return;

    const scrollParent = getScrollParent(containerRef.current, true);
    const containerRect = containerRef.current.getBoundingClientRect();

    function setSelectBoxFromState() {
      const x1 = stateRef.current.x1;
      const x2 = stateRef.current.x2;
      const y1 = stateRef.current.y1;
      const y2 = stateRef.current.y2;

      const _x1 = Math.min(x1, x2);
      const _x2 = Math.max(x1, x2);
      const _y1 = Math.min(y1, y2);
      const _y2 = Math.max(y1, y2);

      const width = _x2 - _x1;
      const height = _y2 - _y1;

      selectBoxRef.current.style.left = _x1 + 'px';
      selectBoxRef.current.style.top = _y1 + 'px';
      selectBoxRef.current.style.width = width + 'px';
      selectBoxRef.current.style.height = height + 'px';

      const isVisible = width || height;
      selectBoxRef.current.style.opacity = isVisible ? 1 : 0;
    }

    function getRect(container, element) {
      const pos = getPosition(element, container);

      const left = pos.left;
      const top = pos.top;
      const right = pos.left + pos.width;
      const bottom = pos.top + pos.height;

      return { left, top, right, bottom };
    }

    function overlaps(rect1, rect2) {
      return !(
        rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom
      );
    }

    function areMapKeysEqual(mapA, mapB) {
      return mapA.size === mapB.size && [...mapA?.keys()].every((key) => mapB.has(key));
    }

    function createSelection() {
      const selectBoxRect = getRect(containerRef.current, selectBoxRef.current);
      const selected = new Map();
      const selectableNodes = stateRef.current.selectableNodes;

      // if (import.meta.env.MODE === 'development') {
      //   selectBoxRef.current.textContent = Object.entries(selectBoxRect).toString();
      // }

      // For all selectable nodes check if they overlap with the current select box.
      for (const node of selectableNodes) {
        const nodeRect = getRect(containerRef.current, node.element);
        if (overlaps(selectBoxRect, nodeRect)) {
          selected.set(node.id, { id: node.id, type: node.type });
        }
      }

      setSelected((prevSelected) => {
        if (areMapKeysEqual(prevSelected, selected)) {
          return prevSelected;
        }

        // Extend selection on merge mode.
        if (stateRef.current.isMergeMode === true) {
          return new Map([...prevSelected, ...selected]);
        }

        return selected;
      });
    }

    const throttledCreateSelection = throttle(createSelection, 100);

    let scrollInterval = null;

    function handlePointerMove(event) {
      clearInterval(scrollInterval);
      const containerRect = containerRef.current.getBoundingClientRect();
      const scrollParentRect = scrollParent.getBoundingClientRect();

      // So it's not possible to drag the box outside of current visible area
      const x = clamp(event.clientX, scrollParentRect.left, scrollParentRect.right);
      const y = clamp(event.clientY, scrollParentRect.top, scrollParentRect.bottom);

      stateRef.current.x2 = x - containerRect.left;
      stateRef.current.y2 = y - containerRect.top;

      // Collect which axis have changes.
      const changes = {};
      // Pointer detection area. If the pointer comes within this value from an edge the scroll
      // behavior is started.
      const edgeSize = 60;
      // Scroll step increment or decrement.
      const stepSize = 20;

      if (x > scrollParentRect.right - edgeSize) {
        changes.xDiff = stepSize;
      } else if (x < scrollParentRect.left + edgeSize) {
        changes.xDiff = -stepSize;
      }

      if (y > scrollParentRect.bottom - edgeSize) {
        changes.yDiff = stepSize;
      } else if (y < scrollParentRect.top + edgeSize) {
        changes.yDiff = -stepSize;
      }

      if (Object.keys(changes).length > 0) {
        scrollInterval = setInterval(() => {
          if (changes.xDiff != null) {
            scrollParent.scrollLeft = scrollParent.scrollLeft + changes.xDiff;
          }

          if (changes.yDiff != null) {
            scrollParent.scrollTop = scrollParent.scrollTop + changes.yDiff;
          }
        }, 50);
      }

      setSelectBoxFromState();

      throttledCreateSelection();
    }

    function onPointerMove(event) {
      if (event.pointerId === stateRef.current.pointerId) {
        event.preventDefault();
        event.stopPropagation();
        handlePointerMove(event);
      }
    }

    function onPointerUp(event) {
      if (event.pointerId === stateRef.current.pointerId) {
        restoreTextSelection(document.documentElement);
        clearInterval(scrollInterval);
        event.preventDefault();
        event.stopPropagation();
        throttledCreateSelection.cancel();
        createSelection();
        stateRef.current.pointerId = null;
        stateRef.current.selectableNodes = null;
        stateRef.current.isMergeMode = null;
        selectBoxRef.current.hidden = 1;
        setIsSelecting(false);

        removeGlobalListener(window, 'pointermove', onPointerMove, false);
        removeGlobalListener(window, 'pointerup', onPointerUp, false);
        removeGlobalListener(window, 'pointercancel', onPointerUp, false);
        removeGlobalListener(window, 'mousemove', onPointerMove, false);
        removeGlobalListener(window, 'mouseup', onPointerUp, false);
      }
    }

    if (onPointerDownEvent.button === 0 && stateRef.current.pointerId == null) {
      // else context menus cant close
      // onPointerDownEvent.preventDefault();
      // onPointerDownEvent.stopPropagation();

      disableTextSelection(document.documentElement);

      if (
        onPointerDownEvent.ctrlKey === true ||
        (isWebKit && onPointerDownEvent.metaKey === true)
      ) {
        stateRef.current.isMergeMode = true;
      } else {
        // setSelected(new Map());
      }

      stateRef.current.pointerId = onPointerDownEvent.pointerId;
      stateRef.current.selectableNodes = [];

      // The clientX is relative to the viewport so we need to subtract containerRect.left. This
      // value can become negative when scrolled since it falls outside of the screen.
      const x = onPointerDownEvent.clientX - containerRect.left;
      const y = onPointerDownEvent.clientY - containerRect.top;

      // Initialize to the same points.
      stateRef.current.x1 = x;
      stateRef.current.y1 = y;
      stateRef.current.x2 = x;
      stateRef.current.y2 = y;

      setSelectBoxFromState();

      selectBoxRef.current.hidden = 0;
      setIsSelecting(true);

      const selectableNodes = [];

      // TODO:
      // This checks the whole document but we just care about the child nodes of container
      for (const element of document.querySelectorAll('[data-node="true"]')) {
        const dataIsNode = element.getAttribute('data-node');

        if (dataIsNode === 'true') {
          const dataId = element.getAttribute('data-id');
          const dataType = element.getAttribute('data-type');

          selectableNodes.push({
            id: dataId,
            type: dataType,
            element: element,
          });
        }
      }

      // Cache the selectable nodes.
      stateRef.current.selectableNodes = selectableNodes;

      if (isWebKit) {
        addGlobalListener(window, 'mousemove', onPointerMove, false);
        addGlobalListener(window, 'mouseup', onPointerUp, false);
        return;
      }

      addGlobalListener(window, 'pointermove', onPointerMove, false);
      addGlobalListener(window, 'pointerup', onPointerUp, false);
      addGlobalListener(window, 'pointercancel', onPointerUp, false);
    }
  }

  return {
    containerProps: {
      onPointerDown(...args) {
        if (isWebKit === true || isDisabled === true) {
          return;
        }

        onPointerDown(...args);
      },
      onMouseDown(...args) {
        if (isDisabled === true) {
          return;
        }

        if (isWebKit === true) {
          onPointerDown(...args);
        }
      },
      className: isSelecting === true && disableChildPointerEventsClass,
    },
    isSelecting,
    component: <useSelectBox.SelectBox ref={selectBoxRef} hidden />,
  };
}

useSelectBox.SelectBox = styled.div`
  position: absolute;
  border: 2px solid ${theme.color.selection_border};
  background-color: ${theme.color.selection_background};
  z-index: 1000000;
  user-select: none;
`;
