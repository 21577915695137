import React from 'react';

import { HomeyColors } from '../../theme/HomeyColors';

import { useDevice } from '../../store/devices/useDevices';

import { getOverrideURL } from '../device/DeviceIcon';

import { ColumnItemDefault } from './ColumnItemDefault';

export function ColumnItemDevice(props) {
  const { device } = useDevice({ deviceId: props.deviceId });

  const iconOverride = device?.iconOverride;
  const backgroundColor = device?.color ?? HomeyColors.getColorForUri('homey:manager:devices');
  const url = iconOverride != null ? getOverrideURL({ iconOverride: iconOverride }) : null;

  return (
    <ColumnItemDefault url={url} iconObj={device?.iconObj} backgroundColor={backgroundColor} />
  );
}
