import { useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { useI18n } from '../../hooks/useI18nFormatters';

import { RouteManager } from '../../RouteManager';
import { ToastManager } from '../../ToastManager';
import { ScriptStore } from './ScriptStore';
import { wait } from '../../lib/wait';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Dialog } from '../../components/overlay/Dialog';
import { IconButton } from '../../components/buttons/IconButton';
import { SolidButton } from '../../components/buttons/SolidButton';
import { TextField } from '../../components/forms/TextField';
import { SpinnerFade } from '../../components/common/SpinnerFade';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';
import { iconCheckmark } from '../../theme/icons/interface/checkmark';

export function ScriptDialog() {
  const { i18n } = useI18n();
  const [isSaving, setIsSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  function onSubmit(data, options = {}) {
    setIsSaving(true);
    const values = { ...data };

    const waitPromise = wait(500);
    const setPromise = ScriptStore.createScript({
      script: { id: values.name, name: values.name, code: '' },
    });

    Promise.all([waitPromise, setPromise])
      .then(([, result]) => {
        setIsSaving(false);
        if (!options.preventClose) handleClose();

        if (result != null) {
          RouteManager.toScript(encodeURIComponent(result.id));
        }
      })
      .catch((error) => {
        ToastManager.handleError(error);
        setIsSaving(false);
      });
  }

  function handleClose() {
    RouteManager.pushState({});
  }

  return (
    <Dialog onClose={handleClose} isOpen>
      <ScriptDialog.Root>
        <ScriptDialog.Header>
          <h3>{i18n.messageFormatter(`script.newScript`)}</h3>

          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClose}
          />
        </ScriptDialog.Header>

        <ScriptDialog.Form
          onSubmit={(event) => {
            // single element form causes submit on enter key in a field
            event.preventDefault();
          }}
        >
          <TextField
            autoFocus
            autoComplete="off"
            orientation="vertical"
            type="text"
            name="name"
            label={i18n.messageFormatter('common.name')}
            placeholder={i18n.messageFormatter('script.newScriptName')}
            defaultValue=""
            register={register}
            required
            minLength={1}
            error={errors.name}
          />
        </ScriptDialog.Form>

        <ScriptDialog.Footer>
          <SolidButton
            styleFlat={true}
            styleColor="blue"
            styleWidth="full"
            isDisabled={isSaving === true}
            onPress={() => {
              handleSubmit(onSubmit)({ preventClose: false });
            }}
          >
            <SpinnerFade
              isActive={isSaving === true}
              size={theme.icon.size_small}
              color={theme.color.white}
            >
              <SolidButton.Icon
                url={iconCheckmark}
                color={theme.color.white}
                size={theme.icon.size_small}
              />
            </SpinnerFade>
            <SolidButton.Text>
              {/* {isSaving === true
                ? `${i18n.messageFormatter('common.saving')}...`
                : i18n.messageFormatter('common.save')} */}
              {i18n.messageFormatter('common.save')}
            </SolidButton.Text>
          </SolidButton>
        </ScriptDialog.Footer>
      </ScriptDialog.Root>
    </Dialog>
  );
}

ScriptDialog.Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;
  max-width: 320px;
`;

ScriptDialog.Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${su(2, 2, 0, 2)};
`;

ScriptDialog.Form = styled.form`
  padding: ${su(0, 2, 1, 2)};

  ${TextField.Root} {
    margin: ${su(2)} 0;
  }
`;

ScriptDialog.Footer = styled.footer`
  padding: ${su(0, 1, 1, 1)};
`;
