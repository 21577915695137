import React from 'react';
import styled from '@emotion/styled';

import { mergeProps, useFocusRing } from 'react-aria';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { useOptionContext } from '../../../components/list-box/ListBox';

export function DeviceSettingsGeneralStatusIndicatorSelectOption() {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  return (
    <S.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <S.Inner>
        <S.Name>{value.textValue}</S.Name>
        {value.indicator}
      </S.Inner>
    </S.Root>
  );
}

function S() {}
DeviceSettingsGeneralStatusIndicatorSelectOption.S = S;

S.Inner = styled.div`
  flex: 1 1 auto;
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: ${su(1, 2)};
`;

S.Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
`;

S.Root = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  max-width: 100%;
  outline: 0;
  cursor: pointer;

  &[data-is-selected='true'] {
    ${S.Name} {
      color: ${theme.color.highlight};
      font-weight: ${theme.fontWeight.medium};
    }
  }

  &[data-is-focus-visible='true'] {
    background-color: ${theme.color.background_hover};
  }

  &:hover {
    background-color: ${theme.color.background_hover};

    &:not([data-is-selected='true']) {
      ${S.Name} {
        font-weight: ${theme.fontWeight.medium};
      }
    }
  }
`;
