import React, { useMemo } from 'react';

import { HomeyColors } from '../../../theme/HomeyColors';

import { useApp } from '../../../store/apps/useApps';

export function AppFlowCard(props) {
  const { component: Component, ...rest } = props;
  const { app, api } = useApp({ appId: props.ownerId });

  const { iconUrl, iconObj, iconBackgroundColor, resourceUrl, name, isLocked } = useMemo(() => {
    const result = {
      iconUrl: null,
      iconObj: null,
      iconBackgroundColor: HomeyColors.getColorForUri('homey:manager:apps'),
      resourceUrl: null,
      name: props.card.ownerName ?? props.card.uriObj?.name,
      isLocked: props.card.requiresPremium === true && api.tier !== 'premium',
    };

    if (app != null) {
      result.iconObj = app.iconObj;
      result.iconBackgroundColor = app.brandColor ?? app.color;
    }

    return result;
  }, [app, props.card, api]);

  return (
    <Component
      {...rest}
      iconUrl={iconUrl}
      iconObj={iconObj}
      iconBackgroundColor={iconBackgroundColor}
      resourceUrl={resourceUrl}
      name={name}
      isLocked={isLocked}
    />
  );
}
