export function getDate(d) {
  return new Date(d.t);
}

export function getValue(d, key) {
  return d[key];
}

export function getPathYFromX(x, path, name, error) {
  if (path == null) return 0;
  let yValue = 0;

  error = error || 2;

  const maxIterations = 10;

  let lengthStart = 0;
  let lengthEnd = path.getTotalLength();
  if (!lengthEnd) return 0;
  let point = path.getPointAtLength((lengthEnd + lengthStart) / 2);
  let iterations = 0;

  while (x < point.x - error || x > point.x + error) {
    const midpoint = (lengthStart + lengthEnd) / 2;

    point = path.getPointAtLength(midpoint);

    if (x < point.x) {
      lengthEnd = midpoint;
    } else {
      lengthStart = midpoint;
    }

    iterations += 1;
    if (maxIterations < iterations) {
      break;
    }
  }

  yValue = point.y;

  return yValue;
}
