import { useRef } from 'react';
import styled from '@emotion/styled';

import { useSelectState, Item } from 'react-stately';
import { useSelect } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { Hint } from '../Hint';
import { ListBoxOverlay } from './ListBoxOverlay';
import { SelectPreview } from './SelectPreview';
import { SelectPreviewFlat } from './SelectPreviewFlat';

Select.Option = Item;

function children(option) {
  return <Select.Option key={option.key} textValue={option.textValue} children={option} />;
}

export function Select(props) {
  const buttonRef = useRef();

  const PreviewComponent = props.styleFlat === true ? SelectPreviewFlat : SelectPreview;

  const selectState = useSelectState({
    items: props.options,
    children: props.children ?? children,
    defaultSelectedKey: props.defaultValue,
    onSelectionChange(value) {
      props.onChange?.(value);
    },
  });

  const select = useSelect(
    {
      label: props.label,
      'aria-label': props['aria-label'],
      onBlur: props.onBlur,
    },
    selectState,
    buttonRef
  );

  const Root = props.orientation === 'vertical' ? S.RootVertical : S.RootHorizontal;
  const previewRef = mergeRefs([buttonRef, props.previewRef]);

  return (
    <S.Root as={Root} className={props.className}>
      <S.Header>
        {props.label && (
          <S.Label {...select.labelProps} title={props.label}>
            {props.renderLabel?.(props.label) ?? props.label}
          </S.Label>
        )}

        <input type="hidden" name={props.name} value={selectState.selectedKey} />

        {props.hint && <Hint hint={props.hint} />}
      </S.Header>

      <S.PreviewWrapper>
        <PreviewComponent previewRef={previewRef} select={select} state={selectState} />
      </S.PreviewWrapper>

      {selectState.isOpen && (
        <ListBoxOverlay
          menuProps={select.menuProps}
          selectState={selectState}
          targetRef={buttonRef}
          overlayStyle={props.overlayStyle}
          styleOption={props.styleOption}
        />
      )}
    </S.Root>
  );
}

function S() {}
Select.S = S;

S.Root = styled.div`
  display: flex;
`;

S.Header = styled.div`
  display: block;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;

S.Label = styled.label`
  display: inline;
  min-width: 0;
  margin-bottom: 0;
`;

S.PreviewWrapper = styled.div``;

S.RootHorizontal = styled(S.Root)`
  align-items: center;
  justify-content: space-between;

  ${S.Label} {
    flex: 0 1 50%;
    font-weight: ${theme.fontWeight.regular};
  }

  ${S.PreviewWrapper} {
    flex: 0 0 50%;
  }
`;

S.RootVertical = styled(S.Root)`
  flex-direction: column;
  align-items: flex-start;

  ${S.Header} {
    margin-bottom: 5px;
    color: ${theme.color.text_light};
    font-size: ${theme.fontSize.small};
    line-height: 24px;
  }

  ${S.PreviewWrapper} {
    width: 100%;
  }
`;
