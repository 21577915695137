import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { formatBytes } from '../../../lib/formatBytes';
import { ToastManager } from '../../../ToastManager';
import { updatesSettingsStore } from './UpdatesSettingsStore';
import { SCOPES } from '../../../lib/scopes';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { HomeyStore, useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';

import { FlatButton } from '../../../components/buttons/FlatButton';
import { Switch } from '../../../components/forms/Switch';
import { Spinner } from '../../../components/common/Spinner';
import { Throbber } from '../../../components/common/Throbber';
import { SpinnerFade } from '../../../components/common/SpinnerFade';
import { CenteredMessage } from '../../../components/common/CenteredMessage';
import { GeneralSettingsSection } from './GeneralSettingsSection';

import { iconUpdates } from '../../../theme/icons-v2/system/updates/updates';
import { iconDownload } from '../../../theme/icons/interface/download';
import { iconArrowDoubleCircularRotated } from '../../../theme/icons/interface/arrow-double-circular-rotated/arrow-double-circular-rotated';

export function UpdatesSettings() {
  const { i18n } = useI18n();
  const { api, scopes } = useApi();

  const { updatesSettings } = updatesSettingsStore.use();

  const [isAutoupdateEnabled, setIsAutoupdateEnabled] = useState(
    updatesSettings.optionAutoupdateData
  );
  const [isAutoupdateEnabledLocked, setIsAutoupdateEnabledLocked] = useState(false);
  const [isInstallLocked, setIsInstallLocked] = useState(false);

  useEffect(() => {
    setIsAutoupdateEnabled(updatesSettings.optionAutoupdateData);
  }, [updatesSettings.optionAutoupdateData]);

  if (updatesSettings.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  if (scopes[SCOPES.UPDATES_READONLY] !== true) {
    return (
      <S.Root>
        <CenteredMessage
          title={i18n.messageFormatter('common.insufficientPermissions')}
          subtitleColor={theme.color.text_light}
          subtitle={i18n.messageFormatter('common.insufficientPermissionsViewSubtitle', {
            name: i18n.messageFormatter('settings.system.updates'),
          })}
        />
      </S.Root>
    );
  }

  return (
    <S.Root>
      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>
          {i18n.messageFormatter('common.settings')}
        </GeneralSettingsSection.S.SectionHeading>

        <GeneralSettingsSection.S.SectionView>
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('settings.system.updatesAutomaticSwitchLabel')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              <Switch
                aria-label={i18n.messageFormatter('common.enabled')}
                name="enabled"
                value={isAutoupdateEnabled}
                isDisabled={scopes[SCOPES.UPDATES] !== true}
                onChange={(value) => {
                  if (isAutoupdateEnabledLocked) {
                    ToastManager.add({ message: 'Pending Request' });
                    return;
                  }

                  setIsAutoupdateEnabled(value);
                  setIsAutoupdateEnabledLocked(true);

                  api.updates
                    .setOptionAutoupdate({ value })
                    .then(() => {})
                    .catch((error) => {
                      setIsAutoupdateEnabled(updatesSettings.optionAutoupdateData);
                      ToastManager.handleError(error);
                    })
                    .finally(() => {
                      setIsAutoupdateEnabledLocked(false);
                    });
                }}
              />
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
          <S.UpdateHint>
            {i18n.messageFormatter('settings.system.updatesAutomaticSwitchHint')}
          </S.UpdateHint>
        </GeneralSettingsSection.S.SectionView>
      </GeneralSettingsSection.S.Section>

      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>
          {i18n.messageFormatter('settings.system.updates')}
        </GeneralSettingsSection.S.SectionHeading>

        <GeneralSettingsSection.S.SectionView>
          {updatesSettings.updateData == null && (
            <S.Actions>
              <FlatButton
                styleWidth="full"
                styleColor="blue"
                isDisabled={
                  scopes[SCOPES.UPDATES_READONLY] !== true ||
                  updatesSettings.isLoading ||
                  updatesSettings.isRefetchingUpdates
                }
                onPress={() => {
                  updatesSettingsStore
                    .refetchUpdates()
                    .then((updateData) => {
                      const length = updateData?.length;

                      if (length > 0 === false) {
                        ToastManager.add({
                          message: i18n.messageFormatter(
                            'settings.system.updatesNoUpdatesAvailable'
                          ),
                        });
                      }
                    })
                    .catch(ToastManager.handleError);
                }}
              >
                <Spinner
                  isActive={updatesSettings.isLoading || updatesSettings.isRefetchingUpdates}
                >
                  <FlatButton.Icon
                    url={iconArrowDoubleCircularRotated}
                    size={theme.icon.size_small}
                  />
                </Spinner>
                <FlatButton.Text>
                  {(() => {
                    switch (true) {
                      case updatesSettings.isLoading || updatesSettings.isRefetchingUpdates:
                        return `${i18n.messageFormatter(
                          'settings.system.updatesCheckingForUpdatesButtonLabel'
                        )}...`;
                      default:
                        return i18n.messageFormatter(
                          'settings.system.updatesCheckForUpdatesButtonLabel'
                        );
                    }
                  })()}
                </FlatButton.Text>
              </FlatButton>
            </S.Actions>
          )}

          {updatesSettings.updateData != null && (
            <>
              <GeneralSettingsSection.S.Row>
                <GeneralSettingsSection.S.Label>
                  {i18n.messageFormatter('common.version')}
                </GeneralSettingsSection.S.Label>
                <GeneralSettingsSection.S.Value>
                  {updatesSettings.updateData.version}
                </GeneralSettingsSection.S.Value>
              </GeneralSettingsSection.S.Row>

              <GeneralSettingsSection.S.Row>
                <GeneralSettingsSection.S.Label>
                  {i18n.messageFormatter('common.size')}
                </GeneralSettingsSection.S.Label>
                <GeneralSettingsSection.S.Value>
                  {formatBytes(updatesSettings.updateData.size)}
                </GeneralSettingsSection.S.Value>
              </GeneralSettingsSection.S.Row>

              <GeneralSettingsSection.S.Row>
                <GeneralSettingsSection.S.Label>
                  {i18n.messageFormatter('settings.system.updatesReleaseNotesLabel')}
                </GeneralSettingsSection.S.Label>
                <GeneralSettingsSection.S.Value />
              </GeneralSettingsSection.S.Row>

              <CustomParser html={updatesSettings.updateData.changelog} />

              <S.Actions>
                <FlatButton
                  isDisabled={scopes[SCOPES.UPDATES] !== true || isInstallLocked}
                  styleWidth="full"
                  styleColor="blue"
                  onPress={() => {
                    setIsInstallLocked(true);
                    updatesSettingsStore
                      .installUpdate()
                      .then(() => {
                        HomeyStore.setIsUpdating(true);
                      })
                      .catch(ToastManager.handleError)
                      .finally(() => {
                        setIsInstallLocked(false);
                      });
                  }}
                >
                  <SpinnerFade isActive={isInstallLocked} size={theme.icon.size_small}>
                    <FlatButton.Icon url={iconDownload} size={theme.icon.size_small} />
                  </SpinnerFade>
                  <FlatButton.Text>
                    {i18n.messageFormatter('settings.system.updatesDownloadAndInstallButtonLabel')}
                  </FlatButton.Text>
                </FlatButton>
              </S.Actions>
            </>
          )}
        </GeneralSettingsSection.S.SectionView>
      </GeneralSettingsSection.S.Section>
    </S.Root>
  );
}

function CustomParser({ html }) {
  const [structure, setStructure] = useState([]);

  useEffect(() => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    setStructure(parsedHtml.body.childNodes);
  }, [html]);

  function renderNodes(nodes) {
    return Array.from(nodes).map((node, index) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }

      const children = node.childNodes.length > 0 ? renderNodes(node.childNodes) : null;

      switch (node.tagName) {
        case 'P':
          return <p key={index}>{children}</p>;
        case 'STRONG':
          return <strong key={index}>{children}</strong>;
        case 'UL':
          return <S.Ul key={index}>{children}</S.Ul>;
        case 'LI':
          return <li key={index}>- {children}</li>;
        case 'A':
          return (
            <a key={index} href={node.href}>
              {children}
            </a>
          );
        default:
          console.log(node);
          return null;
      }
    });
  }

  return <S.Changelog>{renderNodes(structure)}</S.Changelog>;
}

UpdatesSettings.pathKey = 'system/updates';
UpdatesSettings.icon = iconUpdates;
UpdatesSettings.backgroundColor = theme.color.system_updates;

function S() {}
UpdatesSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

S.UpdateHint = styled.p`
  margin: 5px 0 15px;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;

S.Changelog = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;

S.Ul = styled.ul`
  li {
    margin-bottom: 5px;
  }
`;

S.Actions = styled.div`
  padding: 10px 0;
`;
