import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { OverlayContainer } from 'react-aria';
import { useGlobalListeners } from '@react-aria/utils';

import { theme } from '../../../theme/theme';
import { ignoredTags } from '../../../hooks/useEditorKeyboardShortcuts';

const cursorTypes = {
  default: 'default',
  grab: 'grab',
  grabbing: 'grabbing',
};

/**
 * Use Spacebar Drag
 *
 * Drag the scrollContainerRef while holding Spacebar and left button.
 *
 * @param scrollContainerRef
 * @param isDisabled
 * @returns {{
 *  component: JSX.Element,
 *  scrollContainerProps: {
 *    onMouseOver(): void,
 *    onMouseLeave(): void
 *    }
 *  }}
 */
export function useSpacebarDrag({ scrollContainerRef, isDisabled }) {
  const { addGlobalListener, removeGlobalListener } = useGlobalListeners();

  const cursorRef = useRef();
  const stateRef = useRef({
    isMouseEnterScrollContainer: false,
    isEnabled: false,
    pageX: null,
    pageY: null,
  });

  const [isSpacebarPress, setIsSpacebarPress] = useState();
  const [currentCursorType, setCurrentCursorType] = useState();

  // Always track mouse position so that the custom cursor is shown at the mouse position while
  // pressing the spacebar without moving the mouse.
  useEffect(() => {
    const state = stateRef.current;

    function handleMouseMovePosition(event) {
      state.pageX = event.pageX;
      state.pageY = event.pageY;
    }

    addGlobalListener(window, 'mousemove', handleMouseMovePosition);

    return function () {
      removeGlobalListener(window, 'mousemove', handleMouseMovePosition);
    };
  }, [addGlobalListener, removeGlobalListener]);

  // Check if spacebar is held down while the mouse is in the scroll container. If so set
  // isSpaceBarPress to true. When isSpaceBarPress is true it only becomes false after a keyup event.
  useEffect(() => {
    const state = stateRef.current;

    function handleKeyDownSpacebar(event) {
      const target = event.target;
      const role = event.target.getAttribute('role');

      if (
        ignoredTags.has(target.tagName) ||
        target.tagName === 'BUTTON' ||
        role === 'button' ||
        target.isContentEditable
      ) {
        return;
      }

      if (event.code === 'Space') {
        // Because the overlay will be rendered on isSpacebarPress isMouseEnterScrollContainer
        // will become false. So keep track if the behavior is enabled and prevent space down
        // events so scroll containers dont autoscroll (default space behavior).
        if (state.isEnabled === true && isDisabled !== true) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (state.isMouseEnterScrollContainer === true && isDisabled !== true) {
          event.preventDefault();
          event.stopPropagation();
          state.isEnabled = true;
          setIsSpacebarPress(true);
        }
      }
    }

    // There is a small bug where this event does not fire if a context menu is opened after
    // holding space down. This causes the press state to remain. I don't think this is worth
    // solving because users can just press space again to clear the state.
    function handleKeyUpSpacebar(event) {
      if (event.code === 'Space' && state.isEnabled === true) {
        event.preventDefault();
        state.isEnabled = false;
        setIsSpacebarPress(false);
      }
    }

    addGlobalListener(window, 'keydown', handleKeyDownSpacebar);
    addGlobalListener(window, 'keyup', handleKeyUpSpacebar, true);

    return function () {
      state.isEnabled = false;
      setIsSpacebarPress(false);

      removeGlobalListener(window, 'keydown', handleKeyDownSpacebar);
      removeGlobalListener(window, 'keyup', handleKeyUpSpacebar, true);
    };
  }, [isDisabled, addGlobalListener, removeGlobalListener]);

  useEffect(() => {
    const state = stateRef.current;
    const scrollContainerElement = scrollContainerRef.current;

    let startMousePosition = null;
    let startScrollPosition = null;

    function handleCursorPosition() {
      if (cursorRef.current != null) {
        cursorRef.current.style.setProperty('--cursor-x', `${state.pageX}px`);
        cursorRef.current.style.setProperty('--cursor-y', `${state.pageY}px`);
      }
    }

    function handleMouseMoveDrag(event) {
      // When mouseup outside viewport window, then cleanup and stop moving.
      if (event.buttons === 0) {
        return cleanup();
      }

      const top = startScrollPosition.top + (startMousePosition.y - state.pageY);
      const left = startScrollPosition.left + (startMousePosition.x - state.pageX);

      scrollContainerElement.scrollTop = top;
      scrollContainerElement.scrollLeft = left;
    }

    function handleMouseDown(event) {
      // Only handle left button.
      if (event.button !== 0) return;

      event.preventDefault();
      event.stopPropagation();

      setCurrentCursorType(cursorTypes.grabbing);

      startMousePosition = { x: event.pageX, y: event.pageY };
      startScrollPosition = {
        left: scrollContainerElement.scrollLeft,
        top: scrollContainerElement.scrollTop,
      };

      addGlobalListener(window, 'mousemove', handleMouseMoveDrag);
    }

    function handleMouseUp(event) {
      // Only handle left button.
      if (event.button !== 0) return;

      event.preventDefault();
      event.stopPropagation();

      setCurrentCursorType(cursorTypes.grab);

      startMousePosition = null;
      startScrollPosition = null;

      removeGlobalListener(window, 'mousemove', handleMouseMoveDrag);
    }

    // Reset everything we can
    function cleanup() {
      setCurrentCursorType(cursorTypes.default);

      removeGlobalListener(window, 'mousedown', handleMouseDown);
      removeGlobalListener(window, 'mouseup', handleMouseUp);
      removeGlobalListener(window, 'mousemove', handleMouseMoveDrag);
      removeGlobalListener(window, 'mousemove', handleCursorPosition);
    }

    if (isSpacebarPress) {
      handleCursorPosition(); // set first time position of cursor
      setCurrentCursorType(cursorTypes.grab);

      addGlobalListener(window, 'mousedown', handleMouseDown);
      addGlobalListener(window, 'mouseup', handleMouseUp);
      addGlobalListener(window, 'mousemove', handleCursorPosition);
    }

    return cleanup;
  }, [scrollContainerRef, isSpacebarPress, addGlobalListener, removeGlobalListener]);

  const fixedLayer = isSpacebarPress ? (
    <OverlayContainer>
      <SpacebarDrag.Root>
        <SpacebarDrag.Cursor ref={cursorRef} data-style-cursor={currentCursorType} />
      </SpacebarDrag.Root>
    </OverlayContainer>
  ) : null;

  return {
    scrollContainerProps: {
      // We are not using onMouseEnter because that one does not refire when you open an overlay
      // for example.
      onMouseOver() {
        stateRef.current.isMouseEnterScrollContainer = true;
      },
      onMouseLeave() {
        stateRef.current.isMouseEnterScrollContainer = false;
      },
    },
    component: fixedLayer,
    isSpacebarPress,
  };
}

const SpacebarDrag = {};

SpacebarDrag.Root = styled.div`
  pointer-events: auto;
  cursor: none;
  position: fixed;
  z-index: ${theme.zIndex.disabledLayer};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

SpacebarDrag.Cursor = styled.div`
  pointer-events: none;
  position: fixed;
  width: 36px;
  height: 36px;
  left: var(--cursor-x);
  top: var(--cursor-y);
  background-size: 100%;
  transform: translate(-50%, -50%);
  z-index: ${theme.zIndex.cursor};

  &[data-style-cursor='${cursorTypes.grab}'] {
    background-image: url('/img/cursor/grab.svg');
  }

  &[data-style-cursor='${cursorTypes.grabbing}'] {
    background-image: url('/img/cursor/grabbing.svg');
  }
`;
