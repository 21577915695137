import React from 'react';
import styled from '@emotion/styled';
import { useSeparator } from 'react-aria';

import { theme } from '../../theme/theme';

export function Separator(props) {
  const { separatorProps } = useSeparator(props);

  return <SeparatorContainer {...separatorProps} {...props} />;
}

const SeparatorContainer = styled.div`
  width: ${getWidth};
  height: ${getHeight};
  margin: ${getMy} ${getMx};
  background-color: ${theme.color.line};
`;

function getWidth({ orientation, mx }) {
  return orientation === 'vertical' ? '1px' : `calc(100% - ${mx ? mx * 2 : 0}px)`;
}

function getHeight({ orientation, my }) {
  return orientation === 'vertical' ? `calc(100% - ${my ? my * 2 : 0}px)` : '1px';
}

function getMx({ mx }) {
  return mx ? `${mx}px` : `0`;
}

function getMy({ my }) {
  return my ? `${my}px` : `0`;
}
