import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import {
  getColorForHueSaturation,
  getColorForTemperature,
  getDefaultColor,
} from '../../lib/capabilities/light';
import { round } from '../../lib/math';

import { useLightState } from '../../hooks/capabilities/useLightState';

const CAPABILITIES = [
  'light_mode',
  'light_temperature',
  'light_hue',
  'light_saturation',
  'dim',
  'onoff',
];

export function IndicatorLight({ device, capabilities }) {
  const { state } = useLightState({
    ids: CAPABILITIES,
    capabilities,
  });

  const { color, borderColor } = useMemo(() => {
    return getColor({ state });
  }, [state]);

  return <IndicatorLightContainer color={color} borderColor={borderColor} />;
}

function getColor({ state }) {
  const dim = state.dim ?? 1;
  const scaledDim = logScale(dim);
  const borderDim = dim * 0.5;

  if (
    state.light_temperature == null &&
    state.light_hue == null &&
    state.light_saturation == null
  ) {
    // light that only has dim or no initial values
    return {
      color: getDefaultColor().setAlpha(scaledDim).toHslString(),
      borderColor: getDefaultColor().setAlpha(borderDim).toHslString(),
    };
  }

  if (state.light_mode === 'color') {
    return {
      color: getColorForHueSaturation(state).setAlpha(scaledDim).toHslString(),
      borderColor: getColorForHueSaturation(state).setAlpha(borderDim).toHslString(),
    };
  }

  if (state.light_mode === 'temperature') {
    return {
      color: getColorForTemperature(state).setAlpha(scaledDim).toHslString(),
      borderColor: getColorForTemperature(state).setAlpha(borderDim).toHslString(),
    };
  }

  return {
    color: null,
    borderColor: null,
  };
}

const IndicatorLightContainer = styled.div`
  width: 16px;
  height: 16px;
  margin: 4px;
  border-radius: 50%;
  background-color: ${getBackgroundColor};
  box-shadow: 0 0 0 4px ${getBorderColor};
`;

function getBorderColor(props) {
  return props.borderColor ? props.borderColor : 'hsla(44,100%,72.7%,1)';
}

function getBackgroundColor(props) {
  return props.color ? props.color : 'hsla(44,100%,72.7%,1)';
}

function logScale(dim) {
  const minp = 0;
  const maxp = 1;

  // The result should be between 0.5 and 1
  const minv = Math.log(0.5);
  const maxv = Math.log(1);

  // calculate adjustment factor
  const scale = (maxv - minv) / (maxp - minp);

  return round(Math.exp(minv + scale * (dim - minp)), 2);
}
