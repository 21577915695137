import { ToastManager } from '../../../ToastManager';

import { useWebAppSettings } from '../../../store/web-app-settings/useWebAppSettings';

import { ContextMenuContent } from './ContextMenuContent';

import { iconSquareOnSquareRegular } from '../../../theme/icons/interface/square-on-square-regular/square-on-square-regular';

export function DeveloperMenu(props) {
  const { settings } = useWebAppSettings();

  async function onAction(key) {
    switch (key) {
      case 'inspect':
        const resource = props.onResourceRequest?.();
        console.log(resource);
        ToastManager.add({ message: 'Logged to console.' });
        break;
      case 'copy_id':
        try {
          const id = props.onIdRequest?.();
          await navigator.clipboard.writeText(id);
          ToastManager.add({ icon: iconSquareOnSquareRegular, message: 'Copied to clipboard.' });
        } catch (error) {
          ToastManager.handleError(error);
        }
        break;
      case 'copy_uri':
        try {
          const uri = props.onUriRequest?.();
          await navigator.clipboard.writeText(uri);
          ToastManager.add({ icon: iconSquareOnSquareRegular, message: 'Copied to clipboard.' });
        } catch (error) {
          ToastManager.handleError(error);
        }
        break;
      case 'copy_json':
        try {
          const resource = props.onResourceRequest?.();
          const json = JSON.stringify(resource, null, 2);
          await navigator.clipboard.writeText(json);
          ToastManager.add({ icon: iconSquareOnSquareRegular, message: 'Copied to clipboard.' });
        } catch (error) {
          ToastManager.handleError(error);
        }
        break;
      default:
        break;
    }
  }

  return (
    (settings.developer.inspect === true || import.meta.env.MODE !== 'production') && (
      <>
        <ContextMenuContent.Divider />

        <ContextMenuContent.ListItem
          label="Inspect"
          onPress={() => {
            onAction('inspect');
          }}
        />
        {props.onIdRequest != null && (
          <ContextMenuContent.ListItem
            label="Copy ID"
            onPress={() => {
              onAction('copy_id');
            }}
          />
        )}

        {props.onUriRequest != null && (
          <ContextMenuContent.ListItem
            label="Copy URI"
            onPress={() => {
              onAction('copy_uri');
            }}
          />
        )}

        {props.onResourceRequest != null && (
          <ContextMenuContent.ListItem
            label="Copy JSON"
            onPress={() => {
              onAction('copy_json');
            }}
          />
        )}
      </>
    )
  );
}
