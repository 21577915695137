import styled from '@emotion/styled';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { scrollbars } from '../../../../theme/elements/scrollbars';
import { executionStateType } from './executionStateType';

import { FlowToken } from '../../flow-arguments/FlowToken';
import { Icon } from '../../../../components/common/Icon';

import { iconCheckCircle } from '../../../../theme/icons/interface/check-circle';
import { iconCloseCircle } from '../../../../theme/icons/interface/close-circle';
import { iconWarning } from '../../../../theme/icons/interface/warning/warning';
import { useI18n } from '../../../../hooks/useI18nFormatters';
import { round } from '../../../../lib/math';

export function CardResult(props) {
  const { i18n } = useI18n();

  let tokens = null;
  let title = null;
  let subtitle = null;
  let icon = null;

  function getExecutionTime() {
    if (props.executionState.elapsedTime < 100) {
      return `${round(props.executionState.elapsedTime ?? 0, 0)}ms`;
    }

    return `${round((props.executionState.elapsedTime ?? 0) / 1000, 1)}s`;
  }

  switch (props.executionState.type) {
    case executionStateType.error:
      icon = <Icon url={iconWarning} color={theme.color.red} size={theme.icon.size_small} />;
      tokens = [
        {
          key: '__error__',
          type: 'error',
          title: 'Error',
          value: props.executionState.tokens?.[`card::${props.nodeId}::error`],
        },
      ];

      title = i18n.messageFormatter('flow.cardErrorAfter', { time: getExecutionTime() });
      subtitle = i18n.messageFormatter('flow.errorTagGenerated');
      break;
    case executionStateType.true:
      icon = <Icon url={iconCheckCircle} color={theme.color.blue} size={theme.icon.size_small} />;
      //value = props.executionState.tokens?.[`card::${props.nodeId}::error`];
      title = i18n.messageFormatter('flow.cardFinishedIn', { time: getExecutionTime() });
      subtitle = i18n.messageFormatter('flow.cardFinishedInTrueHint');

      break;
    case executionStateType.false:
      icon = <Icon url={iconCloseCircle} color={theme.color.yellow} size={theme.icon.size_small} />;
      //value = props.executionState.tokens?.[`card::${props.nodeId}::error`];
      title = i18n.messageFormatter('flow.cardFinishedIn', { time: getExecutionTime() });
      subtitle = i18n.messageFormatter('flow.cardFinishedInFalseHint');
      break;
    case executionStateType.end:
      icon = <Icon url={iconCheckCircle} color={theme.color.blue} size={theme.icon.size_small} />;
      //value = props.executionState.tokens?.[`card::${props.nodeId}::error`];
      title = i18n.messageFormatter('flow.cardFinishedIn', { time: getExecutionTime() });
      subtitle = i18n.messageFormatter('flow.cardFinishedInHint');

      if (props.card?.tokens) {
        tokens =
          props.card?.tokens.map((token) => {
            let value = props.executionState.tokens?.[`action::${props.nodeId}::${token.id}`];

            if (token.type === 'boolean') {
              value =
                value === true
                  ? i18n.messageFormatter('common.true')
                  : i18n.messageFormatter('common.false');
            }

            return {
              key: token.id,
              type: token.type,
              title: token.title,
              // The image tokens are serialized so there is no url to show. This should be fixed at
              // some point.
              value: token.type === 'image' ? 'Preview not available.' : value,
            };
          }) ?? null;

        if (tokens?.length === 1) {
          subtitle = i18n.messageFormatter('flow.cardFinishedInOneTagHint');
        } else if (tokens?.length > 1) {
          subtitle = i18n.messageFormatter('flow.cardFinishedInMultipleTagsHint');
        }
      }

      break;
    default:
      console.log(JSON.stringify(props.executionState.result, null, 2));
  }

  return (
    <CardResult.Root>
      <CardResult.Header>
        <CardResult.HeaderIcon>{icon}</CardResult.HeaderIcon>
        <CardResult.HeaderContent>
          <CardResult.Title>{title}</CardResult.Title>
          <CardResult.Subtitle>{subtitle}</CardResult.Subtitle>
        </CardResult.HeaderContent>
      </CardResult.Header>

      {tokens != null && (
        <>
          <CardResult.DividerWrapper>
            <CardResult.Divider />
          </CardResult.DividerWrapper>

          <CardResult.Content>
            {tokens.map((token, index) => {
              return (
                <CardResult.TokenResult key={token.key}>
                  <CardResult.TokenWrapper>
                    <FlowToken isHoverable={false} token={token} label={token.title} />
                  </CardResult.TokenWrapper>
                  <CardResult.Value>{token.value}</CardResult.Value>
                </CardResult.TokenResult>
              );
            })}
          </CardResult.Content>
        </>
      )}
    </CardResult.Root>
  );
}

CardResult.Root = styled.div`
  ${scrollbars.dark};
  // Used in src/containers/flows/flow-arguments/FlowToken.js
  --flow-token-max-width: 250px;

  overflow-y: auto;
  border-radius: ${theme.borderRadius.default};
  width: 300px;
  max-height: 400px;
  padding: ${su(2)};
`;

CardResult.Header = styled.div`
  display: flex;
`;

CardResult.HeaderContent = styled.div``;

CardResult.HeaderIcon = styled.div`
  padding-right: ${su(1)};
`;

CardResult.Title = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
`;

CardResult.Subtitle = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;

CardResult.Content = styled.div`
  display: flex;
  flex-direction: column;
`;

CardResult.DividerWrapper = styled.div`
  padding-top: ${su(1)};
`;

CardResult.Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.color.line};
`;

CardResult.TokenResult = styled.div`
  padding-top: ${su(1)};
`;

CardResult.TokenWrapper = styled.div`
  padding-bottom: ${su(1)};
`;

CardResult.Value = styled.div`
  ${scrollbars.dark};
  max-height: 120px;
  padding: ${su(1)};
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.color.body};
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-all;
`;
