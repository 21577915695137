import React, { forwardRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { getImageFromCacheSync, getImageFromCacheAsync } from '../../lib/imageCache';
import { platform } from '../../lib/platform';

import { useMount } from '../../hooks/useMount';

import { theme } from '../../theme/theme';

interface Props {
  className?: string;
  color?: string;
  url?: string;
  size?: string;
  style?: RootCSSProperties;
  display?: string;
}

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLButtonElement>> {
  S: typeof S;
  RoundWrapper: typeof RoundIconWrapper;
}

export const Icon = forwardRef((props, forwardedRef) => {
  const { url, size, color, display, style = {} as RootCSSProperties, className, ...rest } = props;

  // Safari has a weird glitch where svg icons are constantly flickering using the default system
  // cache.
  if (platform.isWebKit === true) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState<string | null>(() => {
      // Attempt to read sync. If it fails the useEffect behavior always runs.
      const image = getImageFromCacheSync({ id: url });

      if (image != null) {
        return image.src;
      }

      return null;
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mount = useMount();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // I think this is pretty useless since React has this behavior by default.
      function setStateIfNotEqual(nextState: string) {
        setState((prevState) => {
          if (nextState === prevState) {
            return prevState;
          }

          return nextState;
        });
      }

      // Not sure why they can be empty strings but it happens.
      if (url != null && url.length > 0) {
        (async () => {
          // Await should wrap any non-promise value.
          const image = await getImageFromCacheAsync({
            id: url,
            baseSrc: url,
            nextSrc: null,
          });

          if (mount.isMounted) {
            setStateIfNotEqual(image.src);
          }
        })().catch(console.error);
      }
    }, [url, mount]);

    if (state != null) {
      style.WebkitMaskImage = `url(${state})`;
    } else {
      style.opacity = 0;
    }
  } else {
    style.WebkitMaskImage = `url(${url})`;
  }

  return (
    <Icon.S.Root
      {...rest}
      ref={forwardedRef}
      className={className}
      style={{
        ...style,
        '--url': `url(${url})`,
        '--size': size ?? theme.icon.size_default,
        '--color': color ?? theme.color.icon_light,
        '--display': display ?? 'inline-block',
      }}
    />
  );
}) as CompoundedComponent;

function S() {}
Icon.S = S;

interface RootCSSProperties extends React.CSSProperties {
  '--url': string;
  '--size': string;
  '--color': string;
  '--display': string;
}

S.Root = styled.span<{ style: RootCSSProperties }>`
  display: var(--display);
  width: var(--size);
  height: var(--size);
  border: 0;
  outline: 0;
  background: var(--color);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
`;

interface RoundIconWrapperRootCSSProperties extends React.CSSProperties {
  '--size'?: string;
  '--color'?: string;
  '--border-radius'?: string;
}

interface RoundIconWrapperProps {
  color?: string;
  size?: string;
  borderRadius?: string;
  style?: RoundIconWrapperRootCSSProperties;
  children?: React.ReactNode;
}

export function RoundIconWrapper(
  props: RoundIconWrapperProps & React.RefAttributes<HTMLButtonElement>
) {
  return (
    <RoundIconWrapper.S.Root
      {...props}
      style={{
        ...props.style,
        '--border-radius': props.borderRadius,
        '--size': props.size,
        '--color': props.color,
      }}
    >
      {props.children}
    </RoundIconWrapper.S.Root>
  );
}

Icon.RoundWrapper = RoundIconWrapper;

function SRoundIconWrapper() {}
RoundIconWrapper.S = SRoundIconWrapper;

SRoundIconWrapper.Root = styled.span<{ style: RoundIconWrapperRootCSSProperties }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size, ${theme.icon.size_default});
  height: var(--size, ${theme.icon.size_default});
  border: 0;
  border-radius: var(--border-radius, 50%);
  flex: 0 0 auto;
  outline: 0;
  background: var(--color, ${theme.color.icon_light});
`;
