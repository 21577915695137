import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';
import { isDarkMode } from '../../theme/classes/darkMode';

import { Button } from './Button';
import { ButtonIcon } from './ButtonIcon';

export const OutlineButton = forwardRef((props, forwardedRef) => {
  const ref = useRef();
  const button = useButton(props, ref);

  return (
    <OutlineButton.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      title={props.title}
      className={props.className}
      form={props.form}
      style={props.style}
      data-style-width={props.styleWidth}
      data-style-compact={props.styleCompact}
      data-style-icon-color-disabled={props.styleIconColorDisabled}
      data-is-pressed={button.isPressed}
      data-is-disabled={props.isDisabled}
    >
      {props.children}
    </OutlineButton.Root>
  );
});

OutlineButton.defaultProps = {};

OutlineButton.propTypes = {
  ...Button.propTypes,
  styleCompact: PropTypes.bool,
  styleWidth: PropTypes.oneOf(['full']),
};

OutlineButton.Text = styled.span`
  white-space: nowrap;
`;

OutlineButton.Icon = ButtonIcon;

OutlineButton.Root = styled.button`
  --outline-button-border-color: ${theme.color.line};
  --outline-button-border-color-hover: ${theme.color.line_hover};
  --outline-button-font-color: ${theme.color.text};
  --outline-button-background-color: transparent;
  --outline-button-background-color-hover: ${theme.color.background_hover};

  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding: 15px ${su(2)};
  background: var(--outline-button-background-color);
  color: var(--outline-button-font-color);
  // border: 1px dashed var(--outline-button-border-color);
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, color, background-color;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23DADAE2' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='0' stroke-linejoin='miter' stroke-linecap='butt'/%3e%3c/svg%3e");

  ${isDarkMode} {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23292A2F' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='0' stroke-linejoin='miter' stroke-linecap='butt'/%3e%3c/svg%3e");
  }

  border-radius: ${theme.borderRadius.default};

  &:not([data-style-icon-color-disabled='true']) {
    ${OutlineButton.Icon.S.Root} {
      background: var(--outline-button-font-color);
    }
  }

  // States
  &:not([data-is-disabled='true']) {
    &:hover {
      border-color: var(--outline-button-border-color-hover);
      background-color: var(--outline-button-background-color-hover);
    }

    &[data-is-pressed='true'] {
      transform: scale(0.96875);
    }
  }

  &[data-is-disabled='true'] {
    --outline-button-background-color: ${theme.color.text_disabled};
    cursor: not-allowed;
  }

  // Size variants
  &[data-style-compact='true'] {
    grid-gap: 6px;
    padding-left: ${su(1.5)};
    padding-right: ${su(1.5)};
  }

  // Width variants
  &[data-style-width='full'] {
    width: 100%;
  }
`;
