import dim_upper from '../images/slider/dim_upper.svg';
import dim_lower from '../images/slider/dim_lower.svg';
import volume_set_upper from '../images/slider/volume_set_upper.svg';
import volume_set_lower from '../images/slider/volume_set_lower.svg';
import windowcoverings_set_upper from '../images/slider/windowcoverings_set_upper.png';
import windowcoverings_set_lower from '../images/slider/windowcoverings_set_lower.png';
import windowcoverings_tilt_set_upper from '../images/slider/windowcoverings_tilt_set_upper.png';
import windowcoverings_tilt_set_lower from '../images/slider/windowcoverings_tilt_set_lower.png';
import middle from '../images/button/middle.svg';
import middle_full from '../images/button/middle_full.svg';

export function getSliderImages(capabilityId, device) {
  const [actualId] = capabilityId.split('.');

  const deviceClass = device.class;
  switch (actualId) {
    case 'dim':
      switch (deviceClass) {
        case 'light':
          return { upper: dim_upper, lower: dim_lower };
        default:
          return { upper: middle_full, lower: middle };
      }
    case 'volume_set':
      return { upper: volume_set_upper, lower: volume_set_lower };
    case 'windowcoverings_set':
      return {
        upper: windowcoverings_set_upper,
        lower: windowcoverings_set_lower,
      };
    case 'windowcoverings_tilt_set':
      return {
        upper: windowcoverings_tilt_set_upper,
        lower: windowcoverings_tilt_set_lower,
      };
    default:
      return { upper: middle_full, lower: middle };
  }
}
