import { v4 as uuid } from 'uuid';

import { useApi } from '../../store/HomeyStore';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useFlowFolder } from '../../store/flow-folders/useFlowFolders';

import { ToastManager } from '../../ToastManager';
import { RouteManager } from '../../RouteManager';
import { FlowFolderStore } from '../../store/flow-folders/FlowFolderStore';

import { ContextMenuContent } from '../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';
import { iconFlow } from '../../theme/icons/system/flow/flow';
import { iconFolder } from '../../theme/icons/interface/folder';

export function FlowNavigationItemFlowFolderContextMenuContent(props) {
  const { i18n } = useI18n();

  const { api } = useApi();

  // const ctx = useContextMenuContext();

  const hasFolderChildren = props.folder.initialFolderChildren?.length > 0;
  const hasFlowChildren = props.folder.initialFlowChildren?.length > 0;

  // the prop.folder is not the store folder but an extended object with for example initialFolderChildren
  const { folder } = useFlowFolder({ folderId: props.folder?.id });

  const hasAdvancedFlowLicense = api?.features.advancedFlow.hasLicense;
  const hasAdvancedFlowVersion = api?.features.advancedFlow.hasVersion;

  async function onAction(key) {
    switch (key) {
      case 'new_flow':
        if (props.folder.id != null) {
          RouteManager.toFlow(`create?id=${uuid()}&folderId=${props.folder.id}`);
          return;
        }

        // root folder
        RouteManager.toFlow(`create?id=${uuid()}`);
        break;
      case 'new_advanced_flow':
        if (api.isCloudAndPremium === true) {
          RouteManager.toAdvancedFlow(`create?id=${uuid()}`);
          return;
        }

        if (api.isCloud === true) {
          RouteManager.toFlows(RouteManager.getStateForPremiumRequiredDialog());
          return;
        }

        if (hasAdvancedFlowLicense === false) {
          RouteManager.toNewFlow({ viewId: 'purchase' });
          return;
        }

        if (hasAdvancedFlowVersion === false) {
          RouteManager.toNewFlow({ viewId: 'update' });
          return;
        }

        if (props.folder.id != null) {
          RouteManager.toAdvancedFlow(`create?id=${uuid()}&folderId=${props.folder.id}`);
          return;
        }

        // root folder
        RouteManager.toAdvancedFlow(`create?id=${uuid()}`);
        break;
      case 'new_folder':
        props.expandNode({ nodeId: props.folder.id });
        FlowFolderStore.createTempFolder({ parentId: props.folder.id });
        break;
      case 'rename':
        props.onRenameRequest();
        break;
      case 'delete':
        FlowFolderStore.deleteFolder({ folderId: props.folder.id }).catch(ToastManager.handleError);
        //props.onDeleteRequest();
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.newFolder')}
        icon={iconFolder}
        onPress={() => {
          onAction('new_folder');
        }}
      />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.newFlow')}
        //icon={iconFlow}
        onPress={() => {
          onAction('new_flow');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.newAdvancedFlow')}
        icon={iconFlow}
        onPress={() => {
          onAction('new_advanced_flow');
        }}
      />

      {props.folder.id !== FlowFolderStore.rootFolderId && (
        <>
          <ContextMenuContent.Divider />

          <ContextMenuContent.ListItem
            label={i18n.messageFormatter('common.rename')}
            onPress={() => {
              onAction('rename');
            }}
          />

          <DeveloperMenu onIdRequest={() => props.folder?.id} onResourceRequest={() => folder} />

          <ContextMenuContent.Divider />
          <ContextMenuContent.ListItem
            label={i18n.messageFormatter('common.delete')}
            variant="danger"
            icon={iconTrashFill}
            isDisabled={hasFolderChildren || hasFlowChildren}
            tooltip={
              (hasFolderChildren || hasFlowChildren) && i18n.messageFormatter('flow.folderNotEmpty')
            }
            onPress={() => {
              onAction('delete');
            }}
          />
        </>
      )}
    </ContextMenuContent.List>
  );
}
