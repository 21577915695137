import { UserMeStore } from '../../store/user-me/UserMeStore';

import { useEffectEvent } from '../../hooks/useEffectEvent';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useMoodsById } from '../../store/moods/useMoods';
import { useUserMe } from '../../store/user-me/useUserMe';

import { typography } from '../../components/primitives/typography';

import { ContentLoader } from '../../components/content-loader/ContentLoader';
import { MoodGrid } from '../../components/mood/MoodGrid';
import { Mood } from '../../components/mood/Mood';

export function FavoriteMoods({ isSiblingLoading }) {
  const { i18n } = useI18n();
  const moods = useMoodsById();
  const userMe = useUserMe();

  const handleRequestSwap = useEffectEvent(({ firstId, secondId }) => {
    UserMeStore.swapFavoriteMoods({
      firstId,
      secondId,
    });
  });

  const handleDrop = useEffectEvent(() => {
    UserMeStore.saveFavoriteMoods();
  });

  if (moods.isLoading || userMe.isLoading || isSiblingLoading) {
    return (
      <MoodGrid.Root>
        {Array.from({ length: 3 }).map((_, index) => {
          return (
            <MoodGrid.Item key={index} data-animated={true}>
              <ContentLoader.Mood />
            </MoodGrid.Item>
          );
        })}
      </MoodGrid.Root>
    );
  }

  if (moods.error || userMe.error) {
    const message = moods.error?.message ?? userMe.error?.message ?? '';
    return <typography.body1>{message}</typography.body1>;
  }

  let result = null;

  if (moods.byId && userMe.favoriteMoods) {
    result = userMe.favoriteMoods.reduce((accumulator, favoriteMoodId, index) => {
      const mood = moods.byId[favoriteMoodId];

      mood != null &&
        accumulator.push(
          <MoodGrid.Item
            key={favoriteMoodId}
            style={{
              '--animation-delay': `${10 * index}ms`,
            }}
          >
            <Mood moodId={favoriteMoodId} onRequestSwap={handleRequestSwap} onDrop={handleDrop} />
          </MoodGrid.Item>
        );

      return accumulator;
    }, []);
  }

  if (result?.length > 0) {
    return <MoodGrid.Root>{result}</MoodGrid.Root>;
  }

  return <p>{i18n.messageFormatter(`home.noFavoriteMoods`)}</p>;
}
