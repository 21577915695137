import { useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { useFocusRing, useRadio, VisuallyHidden, mergeProps, useId } from 'react-aria';

import { RadioContext } from './RadioGroup';
import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

export function DescriptionRadio(props) {
  const radioRef = useRef();
  const context = useContext(RadioContext);
  const radio = useRadio({ ...props, children: props.description }, context.state, radioRef);
  const focusRing = useFocusRing();

  const isSelected = context.state.selectedValue === props.value;

  const { ref: registerRef, ...registerProps } =
    context.register?.(context.name, {
      required: props.required,
    }) ?? {};

  const titleId = useId();
  const descriptionId = useId();

  return (
    <S.Label data-is-selected={isSelected}>
      <VisuallyHidden>
        <input
          {...mergeProps(radio.inputProps, focusRing.focusProps, registerProps)}
          ref={mergeRefs([registerRef, radioRef])}
          aria-labelledby={titleId}
          aria-describedby={descriptionId}
        />
      </VisuallyHidden>

      <S.CirleContainer>
        <S.Cirle data-is-selected={isSelected} />
      </S.CirleContainer>

      <div>
        <S.Title id={titleId}>{props.title}</S.Title>

        <S.Description id={descriptionId}>{props.description}</S.Description>
      </div>
    </S.Label>
  );
}

function S() {}
DescriptionRadio.S = S;

S.Label = styled.label`
  position: relative;
  display: flex;
  padding: 10px 20px;
  cursor: pointer;

  &:not(:last-of-type):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 0;
    height: 1px;
    background: ${theme.color.line};
  }
`;

S.Title = styled.div`
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: 10px;
`;

S.Description = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  margin-bottom: 10px;
`;

S.CirleContainer = styled.div`
  padding-right: 10px;
`;

S.Cirle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid ${theme.color.mono_100};
  border-radius: 50%;

  &[data-is-selected='true'] {
    border-color: ${theme.color.blue};

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      background-color: ${theme.color.blue};
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
