import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';

import { SCOPES } from '../../../lib/scopes';
import { RouteManager } from '../../../RouteManager';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useUsersById } from '../../../store/users/useUsers';
import { useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';

import { OutlineButton } from '../../../components/buttons/OutlineButton';
import { Throbber } from '../../../components/common/Throbber';
import { RoundIconWrapper } from '../../../components/common/Icon';

import { FamilySettingsTableUsers } from './FamilySettingsTableUsers';
import { FamilySettingsTableInvites } from './FamilySettingsTableInvites';
import { FamilySettingsDialogCreateInvite } from './FamilySettingsDialogCreateInvite';
import { FamilySettingsDialogDeleteUser } from './FamilySettingsDialogDeleteUser';
import { FamilySettingsDialogSwapOwner } from './FamilySettingsDialogSwapOwner';

import { iconFamily } from '../../../theme/icons-v2/system/family/family';
import { iconPlusMedium } from '../../../theme/icons/interface/plus-medium';

export function FamilySettings() {
  const { i18n } = useI18n();
  const { scopes } = useApi();
  const users = useUsersById();

  // Matches the parent route.
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  if (users.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  return (
    <S.Root>
      <FamilySettingsTableUsers />

      <FamilySettingsTableInvites />

      {scopes[SCOPES.USER] === true && (
        <OutlineButton
          styleIconColorDisabled={true}
          onPress={() => {
            history.push(`${location.pathname}/invite`);
          }}
        >
          <RoundIconWrapper size={theme.icon.size_default} color={theme.color.blue}>
            <OutlineButton.Icon
              url={iconPlusMedium}
              size={theme.icon.size_tiny}
              color={theme.color.white}
            />
          </RoundIconWrapper>
          <OutlineButton.Text>
            {i18n.messageFormatter('settings.system.usersInviteButtonLabel')}
          </OutlineButton.Text>
        </OutlineButton>
      )}

      <AnimatePresence mode="wait">
        <Switch location={location} key={location.pathname}>
          <Route
            path={`${routeMatch.path}/invite`}
            render={() => {
              function handleCloseRequest() {
                RouteManager.toPath(`${routeMatch.url}`);
              }

              return <FamilySettingsDialogCreateInvite onCloseRequest={handleCloseRequest} />;
            }}
          />

          <Route
            path={`${routeMatch.path}/:userId/delete`}
            render={() => {
              function handleCloseRequest() {
                RouteManager.toPath(`${routeMatch.url}`);
              }

              return <FamilySettingsDialogDeleteUser onCloseRequest={handleCloseRequest} />;
            }}
          />

          <Route
            path={`${routeMatch.path}/:userId/swap-owner`}
            render={() => {
              function handleCloseRequest() {
                RouteManager.toPath(`${routeMatch.url}`);
              }

              return <FamilySettingsDialogSwapOwner onCloseRequest={handleCloseRequest} />;
            }}
          />
        </Switch>
      </AnimatePresence>
    </S.Root>
  );
}

FamilySettings.pathKey = 'system/family';
FamilySettings.icon = iconFamily;
FamilySettings.backgroundColor = theme.color.system_family;

function S() {}
FamilySettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${OutlineButton.Root} {
    margin: 20px 0;
    width: min(calc(100% - 40px), 720px);
  }
`;
