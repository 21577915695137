import React from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing } from 'react-aria';

import { useTabContext } from '../tabs/Tab';

import { getControlComponentImage } from './getControlComponentImage';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

export function ControlsComponentTab(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, tab, item, value, state } = useTabContext();
  const focusRing = useFocusRing();

  const isSelected = state.selectedKey === item.key;
  const isDisabled = state.disabledKeys.has(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  return (
    <ControlsComponentTab.Root
      {...mergeProps(tab.tabProps, focusRing.focusProps)}
      ref={ref}
      data-is-selected={isSelected}
      data-is-disabled={isDisabled}
      data-is-focused={isFocused}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <Icon
        url={getControlComponentImage(item.key)}
        size={theme.icon.size_large}
        color={theme.color.white}
      />
    </ControlsComponentTab.Root>
  );
}

ControlsComponentTab.Root = styled.div`
  padding: 10px;
  opacity: 0.5;
  outline: 0;
  border-radius: ${theme.borderRadius.small};
  transition: ${theme.duration.micro} ${theme.curve.easeInOut};
  transition-property: transform, opacity;
  cursor: pointer;

  &[data-is-selected='true'] {
    opacity: 1;

    ${Icon.S.Root} {
      // background: green;
    }
  }

  &[data-is-disabled='true'] {
    opacity: 0.25;
  }

  &[data-is-focus-visible='true'] {
    outline: 2px solid ${theme.color.highlight};
    outline-offset: 2px;
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 1;
    transform: scale(0.97);

    ${Icon.S.Root} {
      // background: red;
    }
  }
`;
