import { useEffect, useLayoutEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';

import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { DraggableNode } from './card/DraggableNode';

export function useDropGrid({ advancedFlow, isEditMode, onUpdate, expandWidth, expandHeight }) {
  const dropTargetRef = useRef();
  const managerRef = useRef({
    shiftKey: null,
    nodes: {},
    register(node) {
      managerRef.current.nodes[node.id] = node;
      return function () {
        managerRef.current.unregister(node);
      };
    },
    unregister(node) {
      delete managerRef.current.nodes[node.id];
    },
    onUpdate,
  });

  useLayoutEffect(() => {
    managerRef.current.onUpdate = onUpdate;
  });

  // const dragDropManager = useDragDropManager();
  // const monitor = dragDropManager.getMonitor();
  // // const [collected, updateCollected] = useCollector(monitor, collect)
  // console.log(monitor);
  // useEffect(() =>
  //   monitor.subscribeToStateChange(() => {
  //     console.log(monitor.getItemType());
  //     console.log(monitor.isDragging());
  //   })
  // );

  // Dont forget to add dependencies eslint wont warn here.
  const drop = useDrop(() => {
    return {
      accept: [DraggableNode.type],
      drop(item, monitor) {
        const manager = managerRef.current;
        const itemType = monitor.getItemType();
        const nodes = {};

        function processNode({ nodeType, nodeId }) {
          const nodeState = manager.nodes[nodeId];

          switch (nodeType) {
            case DraggableNode.type:
              nodes[nodeId] = {
                ...advancedFlow.cards[nodeId],
                x: nodeState.x,
                y: nodeState.y,
                layer: nodeState.layer,
              };

              break;
            default:
              console.error('Unsupported node type: ', nodeType);
              break;
          }

          return nodeState;
        }

        processNode({
          nodeId: item.id,
          nodeType: itemType,
        });

        const selected = AdvancedFlowViewStore.getSelected();

        if (selected.has(item.id)) {
          const otherSelected = [...selected.values()].filter((node) => node.id !== item.id);
          for (const node of otherSelected) {
            processNode({
              nodeId: node.id,
              nodeType: node.type,
            });
          }
        }

        // TODO
        // extract to AdvancedFlowView
        AdvancedFlowViewStore.updateAdvancedFlow({
          advancedFlow: {
            ...advancedFlow,
            cards: {
              ...advancedFlow.cards,
              ...nodes,
            },
          },
        });
      },
      hover(item, monitor) {
        const manager = managerRef.current;
        const itemType = monitor.getItemType();
        const clientOffset = monitor.getClientOffset();

        if (clientOffset == null) return;

        const dropTargetRect = dropTargetRef.current.getBoundingClientRect();

        const relativeX = clientOffset.x - dropTargetRect.x;
        const relativeY = clientOffset.y - dropTargetRect.y;

        // Grow on edges of grid.
        const growRate = 400;

        if (relativeX + growRate > dropTargetRect.width) {
          expandWidth(growRate);
        }

        if (relativeY + growRate > dropTargetRect.height) {
          expandHeight(growRate);
        }

        function processHover() {
          // Initial element position.
          const initialSourceClientOffset = monitor.getInitialSourceClientOffset();
          // Current element position.
          const currentSourceClientOffset = monitor.getSourceClientOffset();

          if (initialSourceClientOffset == null || currentSourceClientOffset == null) {
            return;
          }

          const nextPosition = {
            x: null,
            y: null,
          };

          // Calculate position relative to container.
          let relativeX = currentSourceClientOffset.x - dropTargetRect.x;
          let relativeY = currentSourceClientOffset.y - dropTargetRect.y;

          // Snap when shift is not held down.
          if (managerRef.current.shiftKey !== true) {
            relativeX = relativeX - (relativeX % 20);
            relativeY = relativeY - (relativeY % 20);
          }

          nextPosition.x = Math.round(relativeX);
          nextPosition.y = Math.round(relativeY);

          const nodeState = manager.nodes[item.id];

          // Store the diff compared to previous position.
          const diff = {
            x: Math.round(nextPosition.x - nodeState.x),
            y: Math.round(nextPosition.y - nodeState.y),
          };

          if (diff.x === 0 && diff.y === 0) return;

          const updates = [];
          const selected = AdvancedFlowViewStore.getSelected();

          // Create temporary update cache of other selected nodes with the current diff. Only if
          // the current node is part of the selection.
          if (selected.has(item.id)) {
            const otherSelected = [...selected.values()].filter((node) => node.id !== item.id);

            for (const node of otherSelected) {
              const nodeState = manager.nodes[node.id];

              updates.push({
                nodeId: node.id,
                nodeType: node.type,
                nodeState: nodeState,
                // Add the diff from the dragged node to the current node.
                x: Math.round(nodeState.x + diff.x),
                y: Math.round(nodeState.y + diff.y),
              });
            }
          }

          // Add the dragged node to the updates.
          updates.push({
            nodeId: item.id,
            nodeType: itemType,
            nodeState: nodeState,
            ...nextPosition,
          });

          let minX = 0;
          let minY = 0;

          // Not really needed for AdvancedFlow but the behavior is possible.
          if (nextPosition.bounds != null) {
            minX = nextPosition.bounds.minX;
            minY = nextPosition.bounds.minY;
          }

          // If out of bounds force min values.
          if (nextPosition.x < minX) nextPosition.x = minX;
          if (nextPosition.y < minY) nextPosition.y = minY;

          let shouldDiscardX = false;
          let shouldDiscardY = false;

          // We could opt out earlier when both are discarded
          updates.forEach((update) => {
            let updateMinX = 0;
            let updateMinY = 0;

            // Higher precedence.
            if (update.bounds) {
              updateMinX = update.bounds.minX;
              updateMinY = update.bounds.minY;
            }

            // Any item smaller than discard update on the current axis.
            if (update.x < updateMinX) {
              shouldDiscardX = true;
            }

            if (update.y < updateMinY) {
              shouldDiscardY = true;
            }
          });

          // Prevent updating when nothing is changeable.
          if (shouldDiscardX === true && shouldDiscardY === true) return;

          // Use previous value if discarded.
          updates.forEach((update) => {
            if (shouldDiscardX) update.x = update.nodeState.x;
            if (shouldDiscardY) update.y = update.nodeState.y;

            update.nodeState.update({
              x: update.x,
              y: update.y,
            });
          });

          // Call onUpdate with the nodes that changed so connections can be updated.
          managerRef.current.onUpdate({ nodes: updates });
        }

        processHover();
      },
      canDrop(item, monitor) {
        return isEditMode === true;
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          isDragging: monitor.getItemType() === DraggableNode.type,
        };
      },
    };
  }, [advancedFlow, expandWidth, expandHeight, isEditMode]);

  const [collectedProps, internalRef] = drop;
  internalRef(dropTargetRef);

  useEffect(() => {
    return function () {
      internalRef(null);
    };
  }, [internalRef]);

  useEffect(() => {
    const manager = managerRef.current;

    function handler(event) {
      manager.shiftKey = event.shiftKey;
    }

    // Because react-dnd does not expose the event for some reason. Sadly there is still a bug on
    // Chrome that causes the shift pressed before behavior not to work. It does seem to work if
    // there is a :before element overlayed but we cant use that for AdvancedFlow because it would
    // cause nothing on the card to be pressable.
    window.addEventListener('dragstart', handler);
    window.addEventListener('dragenter', handler);
    window.addEventListener('dragleave', handler);
    window.addEventListener('dragover', handler);
    window.addEventListener('dragend', handler);
    window.addEventListener('drop', handler);

    return function () {
      window.removeEventListener('dragstart', handler);
      window.removeEventListener('dragenter', handler);
      window.removeEventListener('dragleave', handler);
      window.removeEventListener('dragover', handler);
      window.removeEventListener('dragend', handler);
      window.removeEventListener('drop', handler);

      manager.shiftKey = null;
    };
  }, []);

  return {
    ref: dropTargetRef,
    collectedProps: collectedProps,
    manager: managerRef.current,
  };
}
