// import { proxy as color } from '../color.functional';

/**
 *
 */
export const device = {
  size_small: `60px`,
  size_medium: `120px`,
  size_large: `180px`,
};
