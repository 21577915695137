import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { LogicStore } from './LogicStore';
import { useAttach } from '../useAttach';

import { selectBase, selectData } from '../selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    data: state.data,
    byId: state.byId,
    byType: state.byType,
  };
}

function selectById(state) {
  return {
    ...selectBase(state),
    byId: state.byId,
  };
}

function selectByType(state) {
  return {
    ...selectBase(state),
    byType: state.byType,
  };
}

export function useVariables() {
  useAttach(LogicStore, 'variables');

  return LogicStore.store(selectAll, shallow);
}

export function useVariablesData() {
  useAttach(LogicStore, 'variablesData');

  return LogicStore.store(selectData, shallow);
}

export function useVariablesById() {
  useAttach(LogicStore, 'variablesById');

  return LogicStore.store(selectById, shallow);
}

export function useVariablesByType() {
  useAttach(LogicStore, 'variablesByType');

  return LogicStore.store(selectByType, shallow);
}

export function useVariable({ variableId }) {
  useAttach(LogicStore, 'variable');

  const select = useCallback(
    (state) => {
      return {
        variable: state.byId?.[variableId] ?? null,
        manager: state.manager,
      };
    },
    [variableId]
  );

  return LogicStore.store(select, shallow);
}

export function useVariablesAttach() {
  useAttach(LogicStore, 'useVariablesAttach');
}
