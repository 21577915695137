import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { round } from '../../lib/math';

import { theme } from '../../theme/theme';

export function IndicatorNumber({ device, capabilities, capabilityId }) {
  const capability = capabilities[capabilityId];
  const [value, setValue] = useState(capability?.value);

  const isMeasurePower = capabilityId.startsWith('measure_power');

  useEffect(() => {
    if (capability != null) {
      const unregister = capability.onChange(({ value }) => {
        setValue(value);
      });

      setValue(capability.value);

      return function () {
        unregister();
      };
    }
  }, [capability]);

  const renderValue = (() => {
    if (value == null) return '-';

    if (isMeasurePower === true) {
      if (value === 0) {
        return 0;
      }

      if (value < 0 && value > -1) {
        return Math.floor(value);
      }

      if (value > 0 && value < 1) {
        return Math.ceil(value);
      }

      return round(value, 0);
    }

    return round(value, capability?.decimals);
  })();

  const unitsPostFix = capability?.units ? ` ${capability.units}` : '';

  return (
    <IndicatorNumber.Root data-style-color={isMeasurePower === true ? 'green' : undefined}>
      {`${renderValue}${unitsPostFix}`}
    </IndicatorNumber.Root>
  );
}

IndicatorNumber.Root = styled.div`
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.bold};
  max-width: 54px;
  text-align: right;
  word-break: break-word;

  &[data-style-color='green'] {
    color: #0ba95d;
  }
`;
