import { useRef, useEffect, useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        ...action.payload,
        hasAlarm: hasAlarm({ ...state, ...action.payload }),
      };
    case 'init':
      return { ...action.payload };
    default:
      throw new Error();
  }
}

export function useSensorState({ capabilities, initialState = {} }) {
  const initialStateConstant = useRef(initialState).current;
  const [state, dispatch] = useReducer(reducer, {}, undefined);

  function setValues(values) {
    dispatch({
      type: 'set',
      payload: values,
    });
  }

  useEffect(() => {
    if (capabilities != null) {
      const listeners = [];

      function handleChange({ capabilityId, value }) {
        switch (capabilityId) {
          default:
            dispatch({
              type: 'set',
              payload: {
                [capabilityId]: value,
              },
            });
            break;
        }
      }

      const initialValues = Object.entries(capabilities).reduce(
        (accumulator, [capabilityId, capability]) => {
          if (capabilityId.startsWith('alarm_') === false) return accumulator;
          listeners.push(capability.onChange(handleChange));
          accumulator[capabilityId] = capability.value;
          return accumulator;
        },
        {}
      );

      dispatch({
        type: 'init',
        payload: {
          ...initialValues,
          ...initialStateConstant,
          hasAlarm: hasAlarm(initialValues),
        },
      });

      return function () {
        listeners.forEach((unregister) => unregister());
      };
    }
  }, [capabilities, initialStateConstant]);

  return { state, setValues };
}

function hasAlarm(values) {
  return Object.entries(values).some(([valueKey, value]) => {
    if (valueKey.startsWith('alarm_')) {
      return value === true;
    }

    return false;
  });
}
