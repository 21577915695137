import { ScriptStore } from './ScriptStore';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useScriptRouteId } from './useScriptRouteId';

import { theme } from '../../theme/theme';
import { showForLarge } from '../../theme/classes/responsive';

import { HeaderActionsContainer } from '../../components/header/HeaderActionsContainer';
import { GradientButton } from '../../components/buttons/GradientButton';
import { MonoButton } from '../../components/buttons/MonoButton';

import { iconCheckmark } from '../../theme/icons/interface/checkmark';
import { iconPlay } from '../../theme/icons/interface/play';

export function ScriptActions(props) {
  const { scriptRouteId } = useScriptRouteId();
  const { i18n } = useI18n();

  function handleRequestSaveScript() {
    ScriptStore.updateScript({
      id: scriptRouteId,
      script: {
        code: props.editorInstanceRef.current?.getValue(),
      },
    }).catch(console.error);
  }

  function handleRequestTestScript() {
    ScriptStore.testScript({
      id: scriptRouteId,
      script: {
        code: props.editorInstanceRef.current?.getValue(),
      },
    }).catch(console.error);
  }

  return (
    <HeaderActionsContainer>
      <GradientButton onPress={handleRequestSaveScript}>
        <GradientButton.Icon
          url={iconCheckmark}
          size={theme.icon.size_small}
          color={theme.color.white}
        />
        <GradientButton.Text className={showForLarge}>
          {i18n.messageFormatter('common.save')}
        </GradientButton.Text>
      </GradientButton>
      <MonoButton styleIconColor="green" onPress={handleRequestTestScript}>
        <MonoButton.Icon url={iconPlay} />
        <MonoButton.Text className={showForLarge}>
          {i18n.messageFormatter('common.test')}
        </MonoButton.Text>
      </MonoButton>
    </HeaderActionsContainer>
  );
}
