import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallow } from 'zustand/shallow';

import { useFlowCards } from '../../../../store/flow-cards/useFlowCards';

import { ResourceUtils } from '../../../../store/ResourceUtils';
import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';

import { TokenContext } from '../../TokenContext';

import { connectorTypesMap } from '../connectors/connectorTypes';

export const errorToken = {
  id: 'error',
  type: 'error',
  title: 'Error',
};

function selectNodeAncestors(state) {
  return {
    nodeAncestors: state.nodeAncestors,
  };
}

function useCardTokenContextProviderDependencies() {
  return AdvancedFlowViewStore.store(selectNodeAncestors, shallow);
}

TokenContextProvider.propTypes = {
  // Can be null for cursor cards.
  // TODO: Would like this to be required.
  nodeId: PropTypes.string,
  cardInstance: PropTypes.object,
  cardData: PropTypes.object.isRequired,
};

/**
 * Supplies the selectable tokens to a card. These tokens come from this node's ancestors.
 */
export function TokenContextProvider(props) {
  const flowCards = useFlowCards();

  const { nodeAncestors } = useCardTokenContextProviderDependencies();

  const ancestors = nodeAncestors[props.nodeId];

  const value = useMemo(() => {
    const tokens = {};

    if (ancestors != null) {
      for (const ancestor of ancestors) {
        const key = ResourceUtils.getKey({ ownerUri: ancestor.cardOwnerUri, id: ancestor.cardId });

        /**
         * We could mark mixed trigger tokens as a non valid state. Or more so that tokens can't be
         * used because it will always be undefined for one of the triggers.
         */

        switch (true) {
          // If an ancestor is a trigger and it has tokens add the tokens to the available tokens.
          case ancestor.cardType === 'trigger' && flowCards.triggers.byKey != null: {
            const triggerCard = flowCards.triggers.byKey?.[key];
            const triggerCardTokens = triggerCard?.tokens ?? [];

            for (const triggerCardToken of triggerCardTokens) {
              tokens[`trigger::${ancestor.id}::${triggerCardToken.id}`] = triggerCardToken;
            }

            break;
          }
          // If an ancestor is an action and it has tokens add the tokens to the available tokens.
          case ancestor.cardType === 'action' && flowCards.actions.byKey != null:
            {
              const actionCard = flowCards.actions.byKey?.[key];
              const actionCardTokens = actionCard?.tokens ?? [];

              for (const actionCardToken of actionCardTokens) {
                if (ancestor.fromConnectorType === connectorTypesMap.Out) {
                  tokens[`action::${ancestor.id}::${actionCardToken.id}`] = actionCardToken;
                }
              }
            }
            break;
          default:
            break;
        }

        // Add an error token if the ancestor has an error token.
        if (ancestor.fromConnectorType === connectorTypesMap.Error) {
          tokens[`card::${ancestor.id}::error`] = {
            id: 'error',
            type: 'error',
            title: 'Error',
          };
        }
      }
    }

    return tokens;
  }, [ancestors, flowCards]);

  const context = useMemo(() => {
    const ownTokensByKey = {};
    const tokens = props.cardInstance?.tokens ?? [];

    if (props.cardData.type === 'trigger') {
      for (const token of tokens) {
        ownTokensByKey[`trigger::${props.nodeId}::${token.id}`] = token;
      }
    } else if (props.cardData.type === 'action') {
      for (const token of tokens) {
        ownTokensByKey[`action::${props.nodeId}::${token.id}`] = token;
      }
    }

    if (props.cardData[connectorTypesMap.Error] != null) {
      ownTokensByKey[`card::${props.nodeId}::error`] = {
        id: 'error',
        type: 'error',
        title: 'Error',
      };
    }

    return {
      tokensByKey: value,
      ownTokensByKey: ownTokensByKey,
    };
  }, [value, props.nodeId, props.cardInstance, props.cardData]);

  return <TokenContext.Provider value={context}>{props.children}</TokenContext.Provider>;
}
