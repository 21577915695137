import type { FieldValues, Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { Checkbox, Props as CheckboxProps } from './Checkbox';

interface Props<T extends FieldValues = {}> extends CheckboxProps<T> {
  name: string;
  control: Control<T>;
  required?: boolean;
  defaultChecked?: boolean;
}

CheckboxControllable.S = Checkbox.S;

export function CheckboxControllable<T extends FieldValues = {}>(props: Props<T>) {
  const { field } = useController<any, any>({
    name: props.name,
    control: props.control,
    rules: { required: props.required, validate: props.validate },
    defaultValue: props.defaultChecked,
  });

  return (
    <Checkbox
      {...props}
      inputRef={field.ref}
      onBlur={field.onBlur}
      onChange={(value: unknown) => {
        field.onChange(value);
        // TODO
        // call props.onChange?
      }}
    />
  );
}
