import { useEffect, useRef } from 'react';
import { useKeyboard, useLabel } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { argInput } from './argInput';

import { Argument, ArgumentBase } from './Argument';

export function ArgumentDate(props) {
  const { i18n } = useI18n();

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.date.title');
  const placeholder = props.argument.placeholder ?? '';
  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  let renderValue = null;

  switch (true) {
    case localValue != null:
      renderValue = localValue;
      break;
    default:
      renderValue = props.argumentTypeText;
      break;
  }

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? localValue : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    const nextValue = value == null ? undefined : value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-invalid={isInvalid}
            data-is-empty={localValue == null}
          >
            {renderValue}
          </ArgumentBase>
        );
      }}
      renderOverlay={() => {
        return (
          <DateInput
            value={localValue}
            label={label}
            placeholder={placeholder}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
          />
        );
      }}
    />
  );
}

function DateInput(props) {
  const inputRef = useRef();
  const label = useLabel({
    label: props.label,
  });

  const keyboard = useKeyboard({
    onKeyDown(event) {
      // Consume enter key and propagate rest.
      if (event.key === 'Enter') {
        event.preventDefault();
        props.onSaveRequest();
      } else if (event.key === 'Delete') {
        event.preventDefault();
        props.onChange(undefined);
      } else {
        event.continuePropagation();
      }
    },
    onKeyUp(event) {
      // Consume enter key and propagate rest.
      if (event.key === 'Enter') {
        event.preventDefault();
      } else if (event.key === 'Delete') {
        event.preventDefault();
      } else {
        event.continuePropagation();
      }
    },
  });

  useEffect(() => {
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }, []);

  return (
    <argInput.InputContainer style={{ '--width': '200px' }}>
      <argInput.InputLabel {...label.labelProps}>{props.label}</argInput.InputLabel>

      <argInput.InputRow>
        <argInput.Input
          {...label.fieldProps}
          {...keyboard.keyboardProps}
          ref={inputRef}
          type="date"
          // todo: doesnt seem like the proper solution
          // todo: bugged in safari
          value={props.value?.split('-').reverse().join('-') ?? ''}
          placeholder={props.placeholder}
          onChange={(event) => {
            event.stopPropagation();

            const date = event.target.valueAsDate;

            let nextValue = undefined;

            if (date == null) {
              nextValue = undefined;
            } else {
              const month = `00${date.getUTCMonth() + 1}`.slice(-2);
              const day = `00${date.getUTCDate()}`.slice(-2);
              const year = `0000${date.getUTCFullYear()}`.slice(-4);
              nextValue = `${day}-${month}-${year}`;
            }

            props.onChange(nextValue);
          }}
        />
      </argInput.InputRow>
    </argInput.InputContainer>
  );
}
