import { useState, useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';

export function Time({ date, render }) {
  const [, setState] = useState({});

  useEffect(() => {
    if (date) {
      const ref = {
        time: 1000,
        interval: null,
      };

      function createInterval() {
        if (date == null) return;

        ref.interval = setInterval(() => {
          const result = differenceInMilliseconds(new Date(), new Date(date));
          const seconds = Math.round(result / 1000);
          setState({});

          switch (true) {
            case seconds >= 86401:
              clearInterval(ref.interval);
              break;
            case seconds >= 3601:
              clearInterval(ref.interval);
              ref.time = 3600000;
              createInterval();
              break;
            case seconds >= 61:
              clearInterval(ref.interval);
              ref.time = 60000;
              createInterval();
              break;
            default:
              break;
          }
        }, ref.time);
      }

      createInterval();

      return function () {
        clearInterval(ref.interval);
      };
    }
  }, [date]);

  return render(date != null ? new Date(date) : null);
}
