import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useButton, useHover } from 'react-aria';

import { theme } from '../../../theme/theme';

import { Icon } from '../Icon';

import { iconMore } from '../../../theme/icons/system/more';

export function ContextMenuButton(props) {
  const other = {
    'aria-haspopup': 'menu',
    'aria-expanded': props.isOpen,
  };

  const ref = useRef();
  const contextMenuButtonHover = useHover({});
  const button = useButton(
    {
      ...props,
      ...other,
      onPress(event) {
        props.onPress(event);
      },
    },
    ref
  );

  return (
    <ContextMenuButton.Root
      {...mergeProps(contextMenuButtonHover.hoverProps, button.buttonProps)}
      ref={ref}
      //isParentHovered={props.isParentHovered}
      data-is-open={props.isOpen}
      data-is-hovered={contextMenuButtonHover.isHovered}
      data-is-disabled={props.isDisabled}
    >
      <Icon url={iconMore} color={theme.color.icon_dark} />
    </ContextMenuButton.Root>
  );
}

ContextMenuButton.Root = styled.button`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity ${theme.transition.micro_partial};
`;
