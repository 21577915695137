import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import { history } from '../history';
import { RouteManager } from '../RouteManager';

export class StateRoute {
  constructor({ key }) {
    this.key = key;
  }

  open({ context }) {
    // TODO validate properties
    RouteManager.pushState(this.createState({ context }));
  }

  close() {
    RouteManager.replaceState({
      ...history.location.state,
      [this.key]: {
        ...history.location.state[this.key],
        open: false,
      },
    });
  }

  getContext(location) {
    return location.state?.[this.key]?.context ?? null;
  }

  useContext = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    const context = this.getContext(location);
    return { context };
  };

  createState({ context }) {
    return {
      [this.key]: {
        open: true,
        context,
      },
    };
  }

  IsMatch = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();

    let children = null;

    if (location.state?.[this.key]?.open === true) {
      children = props.children();
    }

    return <AnimatePresence>{children}</AnimatePresence>;
  };
}
