import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { userStore } from './UserStore';
import { selectData, selectById, selectIsLoading } from '../selectors';

import { useAttach } from '../useAttach';

export function useUserData() {
  useAttach(userStore, 'usersData');

  return userStore.store(selectData, shallow);
}

export function useUsersById() {
  useAttach(userStore, 'usersById');

  return userStore.store(selectById, shallow);
}

export function useUser({ userId }) {
  useAttach(userStore, 'user');

  const select = useCallback(
    (state) => {
      return {
        user: state.byId?.[userId] ?? null,
        api: state.api,
      };
    },
    [userId]
  );

  return userStore.store(select, shallow);
}

export function useUsersLoading() {
  useAttach(userStore, 'useUsersLoading');

  return userStore.store(selectIsLoading);
}

export function useUsersAttach() {
  useAttach(userStore, 'useUsersAttach');
}
