import { useRouteMatch } from 'react-router-dom';

/**
 * For migration purposes. Before we had a Route component but those will no longer support the
 * match param in React Router v6.
 */
export function MatchPath({ path, children }) {
  const match = useRouteMatch(path);
  return children({ match });
}
