import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';

import { ToastManager } from '../../../ToastManager';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { CapUtils } from '../../../lib/capabilities/capabilities';

import { theme } from '../../../theme/theme';

import { Icon } from '../../common/Icon';
import { HomeyIcon } from '../../common/HomeyIcon';
import { CapabilitySelect } from '../CapabilitySelect';
import * as Containers from '../Containers';

import { getToggleImages } from './getToggleImages';
import { scrollbar } from '../../../theme/elements/scrollbars';

import { iconToggleSelected } from '../../../theme/icons/capabilities/toggle/selected';
import { iconToggleDefault } from '../../../theme/icons/capabilities/toggle/default';

import { variants, childVariants } from '../button/Button';

export function Toggle({ device, component, capabilities }) {
  const { i18n } = useI18n();

  const instance = useRef(`Toggle:${uuid()}`);
  const [value, setValue] = useState(
    device.capabilitiesObj?.[component.capabilities[0]]?.value ?? false
  );
  const [capability, setCapability] = useState(() => {
    return capabilities?.[component.capabilities[0]] ?? null;
  });

  useEffect(() => {
    setCapability(capabilities?.[component.capabilities[0]] ?? null);
  }, [capabilities, component.capabilities]);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        setValue(CapUtils.nullAsTrue(value));
      });

      setValue(CapUtils.nullAsTrue(capability.value));

      return unregister;
    }
  }, [capability]);

  if (capability == null) return null;

  const { urlTrue, urlFalse, useIconObj } = getToggleImages(capability.id, capability);

  function renderContent() {
    if (capability.id === 'onoff' && capability.getable === false) {
      function makeHandleClick(value) {
        return async function handleClick() {
          try {
            await capability.setValue(value, instance.current);
          } catch (error) {
            ToastManager.handleError(error);
          }
        };
      }

      return (
        <Toggle.GroupContainer>
          <Toggle.Wrapper>
            <Toggle.Base
              initial="initial"
              whileTap="whileTap"
              transition={{
                duration: 0.2,
              }}
              variants={variants}
              onClick={makeHandleClick(true)}
            >
              <Icon
                as={motion.span}
                size="48px"
                transition={{
                  duration: 0.2,
                }}
                variants={childVariants}
                url={iconToggleSelected}
              />
            </Toggle.Base>

            <Toggle.Title>{i18n.messageFormatter('common.on')}</Toggle.Title>
          </Toggle.Wrapper>

          <Toggle.Wrapper>
            <Toggle.Base
              initial="initial"
              whileTap="whileTap"
              transition={{
                duration: 0.2,
              }}
              variants={variants}
              onClick={makeHandleClick(false)}
            >
              <Icon
                as={motion.span}
                size="48px"
                transition={{
                  duration: 0.2,
                }}
                variants={childVariants}
                url={iconToggleDefault}
              />
            </Toggle.Base>

            <Toggle.Title>{i18n.messageFormatter('common.off')}</Toggle.Title>
          </Toggle.Wrapper>
        </Toggle.GroupContainer>
      );
    }

    return (
      <Toggle.Base
        initial={false}
        animate={{
          backgroundColor: value ? theme.color.white_o_100 : theme.color.black_o_50,
        }}
        onClick={() => {
          handleClick({ prevValue: value, setValue, capability, instance });
        }}
      >
        {useIconObj ? (
          <HomeyIcon
            iconObj={capability.iconObj}
            as={motion.span}
            size="48px"
            initial={false}
            animate={{
              backgroundColor: value ? theme.color.green : theme.color.white,
            }}
          />
        ) : (
          <Icon
            as={motion.span}
            size="48px"
            initial={false}
            animate={{
              backgroundColor: value ? theme.color.green : theme.color.white,
            }}
            url={value ? urlTrue : urlFalse}
          />
        )}
      </Toggle.Base>
    );
  }

  return (
    <Containers.Control>
      <Containers.Select>
        {component.capabilities.length > 1 && (
          <CapabilitySelect
            selectedCapability={capability}
            capabilities={capabilities}
            componentCapabilities={component.capabilities}
            onSelectionChange={setCapability}
          />
        )}
      </Containers.Select>
      <Containers.Action>{renderContent()}</Containers.Action>
    </Containers.Control>
  );
}

function handleClick({ prevValue, setValue, capability, instance }) {
  if (capability.getable === true) {
    const nextValue = !prevValue;
    setValue(nextValue);

    capability
      .setValue(nextValue, instance.current)
      .then(({ transactionId, transactionTime, value }) => {
        // setValue(nullAsTrue(value));
      })
      .catch((error) => {
        setValue(prevValue);
        ToastManager.handleError(error);
      });
  } else {
    capability
      .setValue(true, instance.current)
      .then(({ transactionId, transactionTime, value }) => {})
      .catch((error) => {
        ToastManager.handleError(error);
      });
  }
}

Toggle.Base = styled(motion.button)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
`;

Toggle.Wrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;
Toggle.Title = styled.div`
  color: ${theme.color.text_invert_o_50};
  line-height: 16px;
  text-align: center;
  padding: 0 10px;
`;

Toggle.GroupContainer = styled.div`
  ${scrollbar};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 68px;
  overflow-y: auto;

  ${Toggle.Base} {
    margin: 10px;
    height: 100px;
    width: 100px;
  }
`;
