import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useTooltip, useTooltipTrigger as useTooltipTriggerDefault } from 'react-aria';
import { useTooltipTriggerState as useTooltipTriggerStateDefault } from 'react-stately';

import { AnimationRemain } from '../../animation/AnimationRemain';
import { Overlay } from '../../overlay/Overlay';

Tooltip.propTypes = {
  /** Placement of the tooltip relative to the trigger. */
  placement: PropTypes.string,
  /** Function to render the tooltip trigger. */
  renderTrigger: PropTypes.func.isRequired,
  /** Should the tooltip display instantly on hover.  */
  instant: PropTypes.bool,
  /** Offset of the overlay  */
  offset: PropTypes.number,
  /** Crossoffset of the overlay  */
  crossOffset: PropTypes.number,
  /** Default open state (uncontrolled) */
  defaultOpen: PropTypes.bool,
  /** Open state (controlled) */
  isOpen: PropTypes.bool,
  /** Disable tooltip */
  isDisabled: PropTypes.bool,
  /** Render a anchorPointer relative to the trigger */
  anchorPointer: PropTypes.bool,
};

Tooltip.defaultProps = {
  anchorPointer: false,
};

/**
 * The Tooltip component renders a tooltip on hovering over a trigger. The
 * delay will be disabled when multiple tooltips are hovered after eachother
 */
export function Tooltip(props) {
  const triggerRef = useRef();
  // const overlayRef = useRef();

  const sharedProps = {
    delay: props.instant ? 0 : props.delay,
    defaultOpen: props.defaultOpen,
    isOpen: props.isOpen,
    isDisabled: props.isDisabled,
  };

  const useTooltipTriggerState = props.triggerStateHook ?? useTooltipTriggerStateDefault;

  const triggerState = useTooltipTriggerState({
    ...sharedProps,
  });

  const useTooltipTrigger = props.triggerHook ?? useTooltipTriggerDefault;

  // This should never be allowed to be a dynamic prop but a different implementation can be provided on
  // initial render.
  const tooltipTrigger = useTooltipTrigger(
    {
      ...sharedProps,
    },
    triggerState,
    triggerRef
  );

  // Passing triggerState makes it possible to hover and prevent closing the overlay.
  const tooltip = useTooltip(
    {
      ...tooltipTrigger.tooltipProps,
    },
    triggerState
  );

  return (
    <>
      <AnimationRemain condition={triggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={triggerRef}
              overlayProps={tooltip.tooltipProps}
              overlayTriggerState={triggerState}
              placement={props.placement ?? 'bottom'}
              offset={props.offset ?? 10}
              crossOffset={props.crossOffset ?? 0}
              animationRemainProps={animationRemainProps}
              autoFocus={props.autoFocus ?? false}
              restoreFocus={props.restoreFocus ?? true}
              containFocus={props.containFocus}
              anchorPointer={props.anchorPointer}
            >
              {props.children}
            </Overlay>
          );
        }}
      </AnimationRemain>

      {props.renderTrigger(triggerRef, tooltipTrigger.triggerProps)}
    </>
  );
}
