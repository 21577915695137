import { validate } from 'uuid';

export function capitalize(str = '') {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function isUUID(string) {
  return validate(string);
}

function getUUIDMatches(string) {
  const regex = /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi;
  return string.matchAll(regex);
}

export function getHSL(hue, saturation = 75, light = 50) {
  return 'hsl(' + hue + ', ' + saturation + '%, ' + light + '%)';
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export const string = {
  capitalize,
  isUUID,
  getHSL,
  replaceAll,
  getUUIDMatches,
};
