export const stopPressPropagation = {
  onMouseDown(event) {
    if (event.button !== 0 || !event.currentTarget.contains(event.target)) {
      return;
    }

    event.stopPropagation();
  },
  onPointerDown(event) {
    if (event.button !== 0 || !event.currentTarget.contains(event.target)) {
      return;
    }

    event.stopPropagation();
  },
  onClick(event) {
    if (event.button !== 0 || !event.currentTarget.contains(event.target)) {
      return;
    }

    event.stopPropagation();
  },
  onTouchStart(event) {
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    event.stopPropagation();
  },
};
