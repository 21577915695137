import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { theme } from '../../theme/theme';
import { animationFade } from '../../theme/animations/animationFade';

export function AnchorPointer(props) {
  // return (
  //   <AnchorPointer.SVG {...props}>
  //     <AnchorPointer.Path d="M0,8 L2.06201067,8 C3.52378351,8 4.86914582,7.20261957 5.57091204,5.92031538 L7.71638184,2 C8.71132545,0.181986649 10.9916776,-0.485243209 12.809691,0.509700402 C13.4362762,0.852611511 13.9519773,1.36723151 14.2962026,1.99309572 L16.4602212,5.927675 C17.1632274,7.20586803 18.50632,8 19.9650849,8 L22,8 L22,8 L0,8 Z" />
  //   </AnchorPointer.SVG>
  // );

  return (
    <AnchorPointer.Root {...props}>
      <svg
        width="0px"
        height="0px"
        viewBox="0 0 0 0"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="anchor-pointer">
            <path d="M0,8 L2.06201067,8 C3.52378351,8 4.86914582,7.20261957 5.57091204,5.92031538 L7.71638184,2 C8.71132545,0.181986649 10.9916776,-0.485243209 12.809691,0.509700402 C13.4362762,0.852611511 13.9519773,1.36723151 14.2962026,1.99309572 L16.4602212,5.927675 C17.1632274,7.20586803 18.50632,8 19.9650849,8 L22,8 L22,8 L0,8 Z" />
          </clipPath>
        </defs>
      </svg>
    </AnchorPointer.Root>
  );
}

// AnchorPointer.SVG = styled.svg`
//   --anchor-pointer-width: 22px;
//   --anchor-pointer-height: 8px;
//
//   position: absolute;
//   width: var(--anchor-pointer-width);
//   height: var(--anchor-pointer-height);
//
//   top: ${getTop};
//   left: ${getLeft};
//   transform: ${getTransform};
//   overflow: visible;
// `;
//
// AnchorPointer.Path = styled.path`
//   filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
//   fill: white;
//   stroke: transparent;
//   stroke-width: 2;
// `;

AnchorPointer.Root = styled.div`
  --anchor-pointer-width: 22px;
  --anchor-pointer-height: 8px;

  display: var(--anchor-pointer-display, initial);

  position: absolute;
  width: var(--anchor-pointer-width);
  height: var(--anchor-pointer-height);
  overflow: hidden;
  clip-path: url(#anchor-pointer);
  background-color: var(--anchor-pointer-color, ${theme.color.component});

  top: ${getTop};
  left: ${getLeft};
  z-index: 1000000;
  transform: ${getTransform};

  ${(props) => {
    if (props.animationRemainProps == null) return;
    const { isExiting, duration } = props.animationRemainProps;
    const animation = isExiting ? animationFade.out : animationFade.in;
    return css`
      animation: ${animation} ${duration} ease-out 1 forwards;
    `;
  }};
`;

function getTransform(props) {
  switch (props.placement) {
    case 'top':
      return `rotate(180deg) translate(50%, 0)`;
    case 'right':
      return `rotate(270deg) translate(calc(var(--anchor-pointer-height) / 2 * 1), calc(-200% + 1px))`;
    case 'bottom':
      return `rotate(0deg) translate(-50%, -100%)`;
    case 'left':
      return `rotate(90deg) translate(calc(var(--anchor-pointer-height) / 2 * -1), 100%)`;
    default:
      return `rotate(deg) translate(0, 0)`;
  }
}

function getTop(props) {
  switch (props.placement) {
    case 'top':
      return '100%';
    case 'bottom':
      return '0';
    default:
      return undefined;
  }
}

function getLeft(props) {
  switch (props.placement) {
    case 'right':
      return '0';
    case 'left':
      return '100%';
    default:
      return undefined;
  }
}
