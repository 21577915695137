import { ResourceUtils } from '../../../store/ResourceUtils';
import { InsightStore } from '../../../store/insights/InsightStore';
import { ToastManager } from '../../../ToastManager';
import { promptMessage } from '../../../components/overlay/DialogPrompt';

import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useLog } from '../../../store/insights/useInsights';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';

import { iconDownload } from '../../../theme/icons/interface/download';
import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';

export function InsightContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { log, manager } = useLog({ logKey: ResourceUtils.temp__getId(props.log) });
  const { api } = useApi();

  function onAction(key) {
    switch (key) {
      case 'download':
        manager
          .getLogEntries({
            uri: ResourceUtils.getOwnerUri(log),
            id: ResourceUtils.getId(log),
            resolution: props.selectedResolutionKey,
          })
          .then((data) => {
            download({
              data,
              log: log,
              selectedResolutionKey: props.selectedResolutionKey,
              i18n,
            });
          })
          .catch((error) => {
            ToastManager.handleError(error);
          });
        break;
      case 'clear':
        promptMessage({
          type: 'clear',
          message: i18n.messageFormatter('prompt.clear', { name: log.title }),
        })
          .then(() => {
            manager
              .deleteLogEntries({
                uri: ResourceUtils.getOwnerUri(log),
                id: ResourceUtils.getId(log),
              })
              .then(() => {
                // invalidate log so fetchEntries is called
                // ugly
                InsightStore.fetchData({ silent: true });
              })
              .catch((error) => {
                ToastManager.handleError(error);
              });
          })
          .catch(() => {});
        break;
      case 'delete':
        promptMessage({
          type: 'delete',
          message: i18n.messageFormatter('prompt.delete', { name: log.title }),
        })
          .then(() => {
            manager
              .deleteLog({
                uri: ResourceUtils.getOwnerUri(log),
                id: ResourceUtils.getId(log),
              })
              .then(() => {
                // invalidate log so fetchEntries is called
                // ugly
                InsightStore.fetchData({ silent: true });
              })
              .catch((error) => {
                ToastManager.handleError(error);
              });
          })
          .catch(() => {});
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('insights.download')}
        icon={iconDownload}
        onPress={() => {
          onAction('download');
        }}
      />

      {api?.isCloud !== true && (
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter('insights.clear')}
          onPress={() => {
            onAction('clear');
          }}
        />
      )}

      <DeveloperMenu onIdRequest={() => props.log?.id} onResourceRequest={() => props.log} />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}

function download({ data, log, selectedResolutionKey, i18n }) {
  const resolutionTitle = i18n.messageFormatter(`insights.resolutions.${selectedResolutionKey}`);

  const delimiter = ',';
  const filename = `${ResourceUtils.getOwnerName(log)} - ${log.title} (${resolutionTitle}).csv`;

  const csv = data.values.map((entry) => {
    // dateFormatter.format(new Date(entry.t), 'yyyy-MM-dd HH:mm:ss');
    const date = new Date(entry.t).toISOString();
    return `${date}${delimiter}${entry.v}`;
  });

  csv.unshift(`date${delimiter}value`);

  const csvString = csv.join('\n');

  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
