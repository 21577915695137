import { useMemo } from 'react';
import isEqual from 'lodash.isequal';

export function useHasUnsavedChanges({ flow, initialFlow }) {
  return useMemo(() => {
    if (initialFlow == null || flow == null) return false;

    const initial = {
      name: initialFlow.name,
      trigger: initialFlow.trigger,
      conditions: initialFlow.conditions,
      actions: initialFlow.actions,
    };

    const next = {
      name: flow.name,
      trigger: flow.trigger,
      conditions: flow.conditions,
      actions: flow.actions,
    };

    return isEqual(initial, next) === false;
  }, [flow, initialFlow]);
}
