import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { Button } from './Button';
import { ButtonIcon } from './ButtonIcon';

export const TextButton = forwardRef((props, forwardedRef) => {
  const ref = useRef();
  const button = useButton(props, ref);

  // @Harwin Borger no disabled state implemented

  return (
    <TextButton.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      title={props.title}
      className={props.className}
      style={props.style}
      data-style-color={props.styleColor}
      data-style-width={props.styleWidth}
      data-style-font-weight={props.styleFontWeight}
      data-is-pressed={button.isPressed}
      date-is-disabled={props.isDisabled}
    >
      {props.children}
    </TextButton.Root>
  );
});

TextButton.defaultProps = {
  styleColor: 'default',
};

TextButton.propTypes = {
  ...Button.propTypes,
  styleColor: PropTypes.oneOf(['default', 'blue', 'red', 'light']),
  styleWidth: PropTypes.oneOf(['full']),
  styleFontWeight: PropTypes.oneOf(['regular']),
};

TextButton.Text = styled.span`
  white-space: nowrap;
`;

TextButton.Icon = ButtonIcon;

TextButton.Root = styled.button`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding: ${su(1)} ${su(2)};
  color: var(--text-button-color);
  font-weight: ${theme.fontWeight.medium};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, color;

  ${TextButton.Icon.S.Root} {
    background: var(--text-button-color);
  }

  // States
  &:hover {
    color: var(--text-button-color-hover);

    ${TextButton.Icon.S.Root} {
      background: var(--text-button-color-hover);
    }
  }

  &[data-is-pressed='true'] {
    color: var(--text-button-color-active);
    transform: scale(0.9375);

    ${TextButton.Icon.S.Root} {
      background: var(--text-button-color-active);
    }
  }

  // TODO
  &[data-is-disabled='true'] {
  }

  // Color variants
  &[data-style-color='default'] {
    --text-button-color: ${theme.color.text};
    --text-button-color-hover: ${theme.color.text};
    --text-button-color-active: ${theme.color.text};
  }

  &[data-style-color='light'] {
    --text-button-color: ${theme.color.text_light};
    --text-button-color-hover: ${theme.color.text_light};
    --text-button-color-active: ${theme.color.text_light};
  }

  &[data-style-color='blue'] {
    --text-button-color: ${theme.color.blue};
    --text-button-color-hover: ${theme.color.blue_hover};
    --text-button-color-active: ${theme.color.blue_active};
  }

  &[data-style-color='red'] {
    --text-button-color: ${theme.color.red};
    --text-button-color-hover: ${theme.color.red_hover};
    --text-button-color-active: ${theme.color.red_active};
  }

  &[data-style-color='green'] {
    --text-button-color: ${theme.color.green};
    --text-button-color-hover: ${theme.color.green_hover};
    --text-button-color-active: ${theme.color.green_active};
  }

  // Width variants
  &[data-style-width='full'] {
    width: 100%;
  }

  &[data-style-font-weight='regular'] {
    font-weight: ${theme.fontWeight.regular};
  }
`;
