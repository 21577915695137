import { useI18n } from '../../../hooks/useI18nFormatters';

import { ToastManager } from '../../../ToastManager';
import { notificationSettingsStore } from './NotificationSettingsStore';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
// import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';

export function NotificationSettingsTableOwnersContextMenuContent(props) {
  // eslint-disable-next-line no-unused-vars
  const { i18n } = useI18n();

  function onAction(key) {
    switch (key) {
      case 'delete':
        notificationSettingsStore
          .deleteNotifications({ uri: props.uri })
          .then(() => {
            ToastManager.add({
              message: i18n.messageFormatter('settings.system.notificationsDeletedMessage', {
                name: props.name,
              }),
            });
          })
          .catch(ToastManager.handleError);
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      {/* <DeveloperMenu onIdRequest={() => user?.id} onResourceRequest={() => user} /> */}

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('settings.system.notificationsDeleteButtonLabel')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => onAction('delete')}
      />
    </ContextMenuContent.List>
  );
}
