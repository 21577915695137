import { shallow } from 'zustand/shallow';

import { WebAppSettingsStore, WebAppSettingsStoreTemp } from './WebAppSettingsStore';

function selectWebAppSettings(state) {
  return { settings: state };
}

export function useWebAppSettings() {
  return WebAppSettingsStore.store(selectWebAppSettings, shallow);
}

function selectFullscreen(state) {
  return {
    isFullscreen: state.isFullscreen,
  };
}

export function useIsFullscreen() {
  return WebAppSettingsStoreTemp.store(selectFullscreen, shallow);
}
