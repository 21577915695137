import React, { useMemo } from 'react';
import { useOverlayTriggerState } from 'react-stately';

import { enqueueTask } from '../../../lib/enqueueTask';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { ArgumentBase, Argument } from './Argument';
import { DropdownInput } from './ArgumentDropdown';
import { FlowToken } from './FlowToken';

import { getTokenFlowKey, useToken } from './tokens';

const TOKEN_TYPES = ['boolean'];

export function ArgumentCheckbox(props) {
  const { i18n } = useI18n();

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });
  const values = useValues({ messageFormatter: i18n.messageFormatter });

  // const triggerRef = useRef();
  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  const { token, tokenKey, isToken } = useToken({
    value: localValue,
  });

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.checkbox.title');
  const placeholder =
    props.argument.placeholder ?? i18n.messageFormatter('flow.argument.checkbox.placeholder');

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? localValue : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: typeof nextValue === 'string' ? nextValue === 'true' : undefined,
      });
    }
  }

  function handleTokenSelect(key, { kind, token }) {
    const tokenFlowKey = getTokenFlowKey(key, { kind, token });
    const nextValue = `[[${tokenFlowKey}]]`;

    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: true });

    props.onUpdate?.({
      argumentKey: props.argumentKey,
      value: nextValue,
    });

    // Give the TokenPicker a chance to gracefully start closing.
    enqueueTask(() => {
      triggerState.close();
    });
  }

  function handleClearTokenRequest() {
    const nextValue = undefined;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleChange(value, { preventClose = false } = {}) {
    if (preventClose === false) {
      triggerState.close();
    }

    setLocalValue(value);
    checkInvalid(value, { checkNode: false });

    props.onUpdate?.({
      argumentKey: props.argumentKey,
      value: typeof value === 'string' ? value === 'true' : undefined,
    });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      // triggerRef={triggerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        switch (true) {
          case token != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken tokenKey={tokenKey} token={token} label={token.title} />
              </ArgumentBase>
            );
          case token == null && isToken:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                <FlowToken title={localValue} />
              </ArgumentBase>
            );
          case localValue != null:
            return (
              <ArgumentBase {...triggerProps} ref={triggerRef} data-is-invalid={isInvalid}>
                {i18n.messageFormatter(`flow.argument.checkbox.${localValue.toString()}`)}
              </ArgumentBase>
            );
          default:
            return (
              <ArgumentBase
                {...triggerProps}
                ref={triggerRef}
                data-is-invalid={isInvalid}
                data-is-empty={true}
              >
                {props.argumentTypeText}
              </ArgumentBase>
            );
        }
      }}
      renderOverlay={() => {
        return (
          <DropdownInput
            label={label}
            placeholder={placeholder}
            value={localValue?.toString() ?? ''}
            values={values}
            tokenTypes={TOKEN_TYPES}
            token={token}
            tokenKey={tokenKey}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
            onTokenSelect={handleTokenSelect}
            onClearTokenRequest={handleClearTokenRequest}
          />
        );
      }}
    />
  );
}

function useValues({ messageFormatter }) {
  return useMemo(() => {
    return [
      {
        id: 'true',
        title: messageFormatter(`flow.argument.checkbox.true`),
      },
      {
        id: 'false',
        title: messageFormatter(`flow.argument.checkbox.false`),
      },
    ];
  }, [messageFormatter]);
}
