import { useController, Control } from 'react-hook-form';

import { Select } from './Select';

SelectControllable.Option = Select.Option;
SelectControllable.S = Select.S;

interface Props {
  name: string;
  control: Control;
  required?: boolean;
  defaultValue?: string;
}

export function SelectControllable(props: Props) {
  const { field } = useController({
    name: props.name,
    control: props.control,
    rules: { required: props.required },
    defaultValue: props.defaultValue,
  });

  return (
    <Select
      {...props}
      previewRef={field.ref}
      onBlur={field.onBlur}
      onChange={(value: unknown) => {
        field.onChange(value);
        // TODO
        // call props.onChange?
      }}
    />
  );
}
