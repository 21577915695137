import React, { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { useButton } from 'react-aria';

import { RouteManager } from '../../RouteManager';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

import { iconLockDetail } from '../../theme/icons/interface/locked-detail';

export const PremiumLocked = forwardRef(function PremiumLockedForwardRef(props, forwardedRef) {
  return (
    <S.Root
      ref={forwardedRef}
      data-style-size={props.size ?? PremiumLocked.size.default}
      style={{
        '--z-index': props.zIndex,
      }}
    >
      <S.Background size={props.size}>
        <S.Icon url={iconLockDetail} color={theme.color.icon_white} />
      </S.Background>
    </S.Root>
  );
});

PremiumLocked.Pressable = function PremiumLockedPressable(props) {
  const buttonRef = useRef();
  const button = useButton(
    {
      elementType: 'div',
      onPress() {
        RouteManager.toPremiumRequiredDialog();
      },
    },
    buttonRef
  );

  return <PremiumLocked {...props} {...button.buttonProps} ref={buttonRef} />;
};

PremiumLocked.size = {
  small: 'small',
  default: 'default',
};

function S() {}
PremiumLocked.S = S;

S.Root = styled.div`
  --default-transition: width ease-in-out ${theme.duration.slow},
    height ease-in-out ${theme.duration.slow};

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 0;
  z-index: var(--z-index, 1);
  border-radius: ${theme.borderRadius.default};
  overflow: hidden;

  &[data-style-size='${PremiumLocked.size.small}'] {
    --icon-size: ${theme.icon.size_tiny};
    --icon-top: 5px;
    --icon-right: 5px;
    --background-width: 40px;
    --background-height: 32px;
  }

  &[data-style-size='${PremiumLocked.size.default}'] {
    --icon-size: ${theme.icon.size_small};
    --icon-top: 7px;
    --icon-right: 7px;
    --background-width: 50px;
    --background-height: 50px;
  }
`;

S.Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: var(--background-width);
  height: var(--background-height);
  background: ${theme.color.system_premium};
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  transition: var(--default-transition);
`;

S.Icon = styled(Icon)`
  position: absolute;
  top: var(--icon-top);
  right: var(--icon-right);
  width: var(--icon-size);
  height: var(--icon-size);
  transition: var(--default-transition);
`;
