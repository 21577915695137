import React, { useMemo } from 'react';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useFlowCardExplorerContext } from './FlowCardExplorerContext';

export function ColumnTypeDevice(props) {
  const { i18n } = useI18n();

  const { sections } = useDeviceSections({
    i18n,
    entry: props.entry,
    columnProps: props.columnProps,
  });

  return (
    <props.listBoxComponent {...props.listBoxProps} aria-label="Device column" items={sections} />
  );
}

function useDeviceSections({ i18n, entry, columnProps }) {
  const { cardType, cardFilter, onCardSelect } = columnProps;
  const cardTypePlural = `${cardType}s`;
  const flowCardExplorerContext = useFlowCardExplorerContext();
  const cards = flowCardExplorerContext[cardTypePlural];

  const device = useMemo(() => {
    return cards.byDeviceId[entry.key] ?? {};
  }, [cards.byDeviceId, entry.key]);

  const sections = useMemo(() => {
    const sections = [];

    const cardItems = [];
    const cardsByKeyEntries = Object.entries(device.cardsByKey ?? {});

    for (const [cardKey, card] of cardsByKeyEntries) {
      if (cardFilter(card) === false) continue;

      const cardItem = {
        key: cardKey,
        type: 'card',
        textValue: card.title ?? card.titleFormatted,
        action() {
          onCardSelect(cardKey, card);
        },
        context: {
          card,
        },
      };

      // If the card is popular, add it to the start of the array.
      if (card.highlight === true) {
        cardItems.unshift(cardItem);
        continue;
      }

      cardItems.push(cardItem);
    }

    if (cardItems.length > 0) {
      console.log(cardItems);
      sections.push({
        key: 'cards',
        title: i18n.messageFormatter('flow.cardDialog.cards'),
        children: cardItems,
      });
    }

    return sections;
  }, [i18n, cardFilter, onCardSelect, device]);

  return { sections };
}
