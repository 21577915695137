import styled from '@emotion/styled';

import { ZoneListItem } from './ZoneListItem';

const Item = styled.div`
  height: 40px;
  padding: 10px 0 10px ${(props) => props.level * 20}px;
`;

const zoneList = (
  <div style={{ padding: '0 10px' }}>
    <Item level={0}>
      <ZoneListItem />
    </Item>
    <Item level={1}>
      <ZoneListItem />
    </Item>
    <Item level={2}>
      <ZoneListItem />
    </Item>
    <Item level={2}>
      <ZoneListItem />
    </Item>
    <Item level={1}>
      <ZoneListItem />
    </Item>
    <Item level={2}>
      <ZoneListItem />
    </Item>
  </div>
);

export function ZoneList() {
  return zoneList;
}
