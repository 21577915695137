import React from 'react';
import styled from '@emotion/styled';

import { scrollbar } from '../../../theme/elements/scrollbars';

import { Button } from './Button';
import * as Containers from '../Containers';

export function ButtonGroup({ device, capabilities, component }) {
  if (capabilities == null) return null;

  return (
    <Containers.Control>
      <Containers.Select />
      <Containers.Action>
        <ButtonGroupContainer>
          {component?.capabilities?.map?.((capabilityId) => {
            const capability = capabilities[capabilityId];

            if (capability == null) return null;

            const isSingle = component.capabilities.length === 1;

            return <Button key={capabilityId} capability={capability} isSingle={isSingle} />;
          })}
        </ButtonGroupContainer>
      </Containers.Action>
    </Containers.Control>
  );
}

const ButtonGroupContainer = styled.div`
  ${scrollbar};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 68px;
  overflow-y: auto;
`;
