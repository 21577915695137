import { proxy as palette } from '../color.palette';
import { proxy as functional } from '../color.functional';
import { proxy as fontSize } from '../fontSize';

const color = { ...palette, ...functional };

export const flowcard = {
  background: color.component,

  /* Connectors */
  // connector: out
  connector_out: color.blue,
  connector_out_hover: color.blue_hover,
  connector_out_active: color.blue_active,
  // connector: true
  connector_true: color.blue,
  connector_true_hover: color.blue_hover,
  connector_true_active: color.blue,
  // connector: false
  connector_false: color.yellow,
  connector_false_hover: color.yellow_hover,
  connector_false_active: color.yellow_active,
  // connector: error
  connector_error: color.red,
  connector_error_hover: color.red_hover,
  connector_error_active: color.red_active,

  dropzone_target_background: color.blue,
  dropzone_target_outline: color.blue_o_20,

  /* States */
  // Dragover state
  dragover_border: color.blue,
  dragover_background: color.blue_050,
  dragging_and_can_connect_shadow: `
    0 10px 44px 0 ${color.blue_o_14},
    0 2px 12px 0 ${color.blue_o_20},
    0 2px 4px 0 ${color.blue_o_20}
  `,
  // Invalid state
  invalid_shadow: `
    0 10px 44px 0 ${color.red_o_14},
    0 2px 12px 0 ${color.red_o_20},
    0 2px 4px 0 ${color.red_o_20}
  `,

  // Disabled state
  disabled_color_text: color.mono_200,
  disabled_background_color: color.mono_100,
  disabled_icon_color: color.white,

  /* Elements */
  // Name
  name_color: color.text_light,
  name_font_size: fontSize.tiny,
  name_line_height: 1.667,
  // Content
  content_font_size: fontSize.default,
  content_line_height: 1.3125, // If you set this to 1.25 empty arguments on different lines will overlap.
  // Any / All
  title_color: color.mono_200
};
