import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { moodsStore } from './MoodsStore';
import { selectData, selectById, selectIsLoading } from '../selectors';

import { useAttach } from '../useAttach';

export function useMoodsData() {
  useAttach(moodsStore, 'moodsData');

  return moodsStore.store(selectData, shallow);
}

export function useMoodsById() {
  useAttach(moodsStore, 'moodsById');

  return moodsStore.store(selectById, shallow);
}

export function useMoodsByZoneId() {
  useAttach(moodsStore, 'useMoodsByZoneId');

  const moods = moodsStore.store(selectById, shallow);

  const byZoneId = useMemo(() => {
    const byZoneId = {};

    for (const mood of Object.values(moods.byId ?? {})) {
      byZoneId[mood.zone] = byZoneId[mood.zone] ?? {};
      byZoneId[mood.zone][mood.id] = mood;
    }

    return byZoneId;
  }, [moods.byId]);

  moods.byZoneId = byZoneId;

  return moods;
}

export function useMood({ moodId }) {
  useAttach(moodsStore, 'mood');

  const select = useCallback(
    (state) => {
      return {
        mood: state.byId?.[moodId] ?? null,
      };
    },
    [moodId]
  );

  return moodsStore.store(select, shallow);
}

export function useMoodsLoading() {
  useAttach(moodsStore, 'useMoodsLoading');

  return moodsStore.store(selectIsLoading);
}

export function useMoodsAttach() {
  useAttach(moodsStore, 'useMoodsAttach');
}
