import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Table } from './Table';
import { Icon } from '../../../components/common/Icon';

import { iconTriangleSmallUp } from '../../../theme/icons/interface/triangle-small-up/triangle-small-up';
import { iconTriangleSmallDown } from '../../../theme/icons/interface/triangle-small-down/triangle-small-down';
import { iconTriangleLargeUpDown } from '../../../theme/icons/interface/triangle-large-up-down/triangle-large-up-down';

export const order = {
  DESC: 'desc',
  ASC: 'asc',
};

export function DeviceColumnHead(props) {
  return (
    <sc.Head
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      sortable={props.sortable}
      isOrdered={props.sort === props.orderBy}
    >
      <sc.Spacer>
        <sc.Text>{props.children}</sc.Text>
        {props.sortable && (
          <sc.Sortable>
            <sc.SortIcon
              size={theme.icon.size_tiny}
              color={props.sort === props.orderBy ? theme.color.highlight : theme.color.icon_light}
              url={
                props.sort !== props.orderBy
                  ? iconTriangleLargeUpDown
                  : props.orderDirection === order.DESC
                  ? iconTriangleSmallUp
                  : iconTriangleSmallDown
              }
            />
          </sc.Sortable>
        )}
      </sc.Spacer>
    </sc.Head>
  );
}

const SortIcon = styled(Icon)`
  transition: ${theme.transition.micro};
`;

const Head = styled(Table.th)`
  ${(props) =>
    props.isOrdered !== true &&
    css`
      &:hover {
        ${SortIcon} {
          background-color: ${theme.color.icon_light_hover};
        }
      }
    `};
`;

const Spacer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div``;

const Sortable = styled.div`
  margin-left: ${su(1)};
`;

const sc = { Head, Spacer, SortIcon, Text, Sortable };
