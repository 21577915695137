import { color as base } from './color.palette';
import { accessorHandler, createNamedProxy } from "../utils";
import { blendHexColorWithBackground } from './components/helpers';

export const color = {
  ...base
};

export const proxy = createNamedProxy('color', color, accessorHandler)

/**
 * Dark overwrites for color.js
 *
 * Only overwrite colors from color.js. Never add new colors here.
 */

/* Monochrome Palette */
color.mono_000   = color.black = `#000000`;
color.mono_010   = '#08090C';
color.mono_020   = `#16171D`;
color.mono_025   = '#111218';
color.mono_050   = `#1E1F24`;
color.mono_070   = `#222329`; // DarkMode exclusive tint
color.mono_100   = `#292A2F`;
color.mono_150   = `#35363B`;
color.mono_200   = `#414247`;
color.mono_300   = `#58595D`;
color.mono_400   = `#707175`;
color.mono_500   = `#88898C`;
color.mono_600   = `#A0A0A3`;
color.mono_700   = `#B8B8BA`;
color.mono_800   = `#CFCFD1`;
color.mono_900   = `#E7E7E8`;
color.mono_950   = `#F2F2F3`;
color.mono_1000  = color.white = `#ffffff`;

color.mono_o_10  = `rgba(255, 255, 255, .1)`;
color.mono_o_20  = `rgba(255, 255, 255, .2)`;
color.mono_o_40  = `rgba(255, 255, 255, .4)`;
color.mono_o_50  = `rgba(255, 255, 255, .5)`;

color.mono_ex030 = '#0D0E13';

/* Blue palette */
color.blue_050   = '#1E2C3D';
color.blue_200   = '#1B3652';
color.blue_500   = '#105291';

color.blue_o_10_mono_050_b = blendHexColorWithBackground({ hexColor: '#105291', hexBackgroundColor: '#1E1F24', alpha: 0.1 })
color.blue_o_10_mono_100_b = blendHexColorWithBackground({ hexColor: '#105291', hexBackgroundColor: '#292A2F', alpha: 0.1 })
color.blue_o_10_mono_200_b = blendHexColorWithBackground({ hexColor: '#105291', hexBackgroundColor: '#414247', alpha: 0.1 })

color.red_050    = '#3E1E1E'
