import React, { useRef, forwardRef } from 'react';
import styled from '@emotion/styled';

import { useSelectState, Item } from 'react-stately';
import {
  useOverlay,
  useButton,
  useSelect,
  useListBox,
  useOption,
  useFocus,
  HiddenSelect,
  FocusScope,
  DismissButton,
  mergeProps,
} from 'react-aria';

import { theme } from '../../theme/theme';
import { Icon } from '../common/Icon';

import { iconChevronDown } from '../../theme/icons/interface/chevron-down';

export const Option = Item;

/**
 * @undeprecated and moved from common to controls.
 */
export const Select = forwardRef((props, forwardedRef) => {
  const triggerRef = useRef();

  const selectState = useSelectState(props);

  const select = useSelect(props, selectState, triggerRef);
  const button = useButton(select.triggerProps, triggerRef);

  return (
    <Container>
      <div {...select.labelProps}>{props.label}</div>

      <HiddenSelect
        state={selectState}
        triggerRef={triggerRef}
        label={props.label}
        name={props.name}
      />

      <Button {...button.buttonProps} name={props.name} ref={triggerRef}>
        <Value {...select.valueProps}>
          {selectState.selectedItem ? selectState.selectedItem.rendered : 'Select an option'}
        </Value>

        <Icon size={theme.icon.size_small} aria-hidden="true" url={iconChevronDown} />
      </Button>
      {selectState.isOpen && <ListBoxPopup {...select.menuProps} state={selectState} />}
    </Container>
  );
});

const Container = styled.div`
  display: inline-block;
  position: relative;
  pointer-events: all;
`;

const Value = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 280px;
  max-width: 280px;
  height: 48px;
  margin-right: -48px;
  padding: 0 30px;
  overflow: hidden;
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${theme.color.white};
  font-weight: 500;
  cursor: pointer;

  ${Icon.S.Root} {
    flex: 0 0 48px;
  }
`;

function ListBoxPopup({ state, ...otherProps }) {
  let listBoxRef = React.useRef();

  // Get props for the listbox
  let { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy,
      disallowEmptySelection: true,
      label: 'Options',
      'aria-label': 'Options',
    },
    state,
    listBoxRef
  );

  let overlayRef = React.useRef();
  let { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: false,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    overlayRef
  );

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} style={{ position: 'relative' }} ref={overlayRef}>
        <DismissButton onDismiss={() => state.close()} />
        <Arrow />
        <List {...mergeProps(listBoxProps, otherProps)} ref={listBoxRef}>
          {[...state.collection].map((item) => (
            <OptionItem key={item.key} item={item} state={state} />
          ))}
        </List>
      </div>
    </FocusScope>
  );
}

const Arrow = styled.div`
  position: absolute;
  top: 1px;
  left: 50%;
  width: 12px;
  height: 12px;
  clip-path: polygon(100% 0, 0 100%, 0 0);
  background-color: ${theme.color.component};
  border-radius: 4px;
  transform: rotate(45deg) translateX(-50%);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04), 0 0 44px 0 rgba(0, 0, 0, 0.07),
    0 0 12px 0 rgba(0, 0, 0, 0.1);
`;

const List = styled.div`
  position: absolute;
  z-index: 2000;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  overflow: hidden;
  border: 0;
  border-radius: 10px;
  background-color: ${theme.color.component};
  list-style: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 10px 44px 0 rgba(0, 0, 0, 0.07),
    0 2px 12px 0 rgba(0, 0, 0, 0.1);
`;

function OptionItem({ item, state }) {
  // Get props for the option element
  let ref = React.useRef();
  let isDisabled = state.disabledKeys.has(item.key);
  let isSelected = state.selectionManager.isSelected(item.key);
  let { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
    },
    state,
    ref
  );

  // Handle focus events so we can apply highlighted
  // style to the focused option
  let [isFocused, setFocused] = React.useState(false);
  let { focusProps } = useFocus({ onFocusChange: setFocused });

  return (
    <ListItem
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
      isSelected={isSelected}
      isFocused={isFocused}
    >
      {item.rendered}
    </ListItem>
  );
}

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  padding: 0 10px;
  outline: 0;
  background-color: ${({ isSelected, isFocused }) =>
    isFocused ? theme.color.background_hover : 'transparent'};
  color: ${theme.color.text};
  font-weight: ${({ isSelected, isFocused }) => (isSelected ? 700 : 400)};
  text-align: center;
  cursor: pointer;
`;
