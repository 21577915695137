import React from 'react';
import styled from '@emotion/styled';

import { mergeProps, useFocusRing } from 'react-aria';
import { useOptionContext } from '../list-box/ListBox';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Icon } from '../common/Icon';
import { HomeyIcon } from '../common/HomeyIcon';

import { iconChevronRight } from '../../theme/icons/interface/chevron-right/chevron-right';

export function ColumnItemDefault(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();
  const focusRing = useFocusRing();

  const url = props.url ?? props.iconUrl;
  const backgroundColor = props.backgroundColor ?? props.iconBackgroundColor;

  function getIcon() {
    switch (true) {
      case url != null:
        return (
          <Icon url={url} color={theme.color.white} size={props.iconSize ?? theme.icon.size_18} />
        );
      case props.iconObj != null:
        return (
          <HomeyIcon
            iconObj={props.iconObj}
            color={theme.color.white}
            size={props.iconSize ?? theme.icon.size_small}
          />
        );
      default:
        return null;
    }
  }

  return (
    <ColumnItemDefault.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={props.style}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-focus-visible={focusRing.isFocusVisible}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
    >
      <ColumnItemDefault.IconWrapper backgroundColor={backgroundColor}>
        {getIcon()}
      </ColumnItemDefault.IconWrapper>

      <ColumnItemDefault.Name>{item.textValue}</ColumnItemDefault.Name>

      {item.value.action == null && (
        <ColumnItemDefault.ChevronIcon
          url={iconChevronRight}
          color={theme.color.icon_light}
          size={theme.icon.size_small}
        />
      )}
    </ColumnItemDefault.Root>
  );
}

ColumnItemDefault.Name = styled.div`
  flex: 1 1 0;
  margin-left: ${su(1)};
  transition: color ${theme.transition.micro_partial};
`;

ColumnItemDefault.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  transition: background-color ${theme.transition.micro_partial};
`;

ColumnItemDefault.ChevronIcon = styled(Icon)`
  transition: background-color ${theme.transition.micro_partial};
`;

ColumnItemDefault.Root = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px ${su(1)};
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  cursor: pointer;
  transition: background-color ${theme.transition.micro_partial};

  &[data-is-focus-visible='true'],
  &:hover {
    background-color: ${theme.color.background_hover};

    ${ColumnItemDefault.Name} {
      font-weight: ${theme.fontWeight.medium};
    }
  }

  &[data-is-focus-visible='true']:not([aria-selected='true']),
  &:hover:not([data-is-selected='true']) {
    ${ColumnItemDefault.ChevronIcon} {
      background-color: ${theme.icon.color_dark};
    }
  }

  &[data-is-selected='true'] {
    ${ColumnItemDefault.Name} {
      color: ${theme.color.highlight};
      font-weight: ${theme.fontWeight.medium};
    }

    ${ColumnItemDefault.IconWrapper} {
      background-color: ${theme.color.highlight};
    }

    ${ColumnItemDefault.ChevronIcon} {
      background-color: ${theme.color.highlight};
    }
  }
`;
