import * as Sentry from '@sentry/react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import { __DEV__ } from '../lib/__dev__';
import { BaseStore } from './BaseStore';

import { AuthStore } from './AuthStore';
import { HomeyUtils } from './HomeyUtils';
import { ToastManager } from '../ToastManager';
import { SCOPES } from '../lib/scopes';

export class HomeyStore extends BaseStore {
  static createInitialState() {
    return {
      current: null,
      baseUrl: null,
      api: null,
      online: false,
      loading: true,
      error: null,
      isConnected: null,
      isRebooting: null,
      isUpdating: null,
      bootId: null,
      scopes: {},
      language: null,
    };
  }

  static store = create(
    devtools(
      subscribeWithSelector((set, get, api) => ({
        ...this.createInitialState(),
        retry: () => {
          if (AuthStore.prevController != null) {
            AuthStore.prevController.abort();
            HomeyStore.destroyApi();
          }

          const controller = new AbortController();
          const signal = controller.signal;
          AuthStore.prevController = controller;

          HomeyStore.authenticateHomey(get().current, { signal }).catch(console.error);
        },
      })),
      { name: 'homey' }
    )
  );

  static reset() {
    this.destroyApi();
    this.set(this.createInitialState());
  }

  static async authenticateHomey(current, { signal }) {
    if (current == null) return;
    __DEV__ && console.info('authenticate:homey');

    try {
      this.set({
        online: false,
        loading: true,
        error: null,
        isConnected: false,
        isRebooting: null,
        isUpdating: null,
        bootId: null,
        scopes: {},
        language: current.language,
      });

      // current.localUrlSecure = "https://192-168-178-220.192.168.178.220:443"
      // 'localSecure'|'local'|'cloud'|'mdns'
      // const api = await current.authenticate({ strategy: ['cloud'] });
      const api = await current.authenticate();

      // If the language wasnt synced properly to cloud. Most of the time these would be
      // the same but only when changing the Homey language the sync might not have been
      // done yet.
      api.i18n
        .getOptionLanguage()
        .then((result) => {
          if (this.get().language !== result.value) {
            this.set({
              language: result.value,
            });
          }
        })
        .catch(ToastManager.handleError);

      // Lazy update the bootId.
      api.system
        .getInfo()
        .then((info) => {
          if (signal.aborted) {
            return;
          }

          this.get().bootId = info.bootId;
        })
        .catch((error) => {
          console.error(error);
        });

      if (signal.aborted) {
        return;
      }

      const scopes = {};

      for (const [, SCOPE] of Object.entries(SCOPES)) {
        scopes[SCOPE] = api.hasScope(SCOPE);
      }

      const user = AuthStore.get().user;

      api.homey = current;
      api.homeyVersion = current.softwareVersion?.split?.('-')[0];
      api.apiVersion = current.apiVersion;

      api.isCloud = api.homey.platform === 'cloud';
      api.isCloudPremium = api.tier === 'premium';
      api.isCloudAndPremium = api.isCloud && api.isCloudPremium;

      let hasAdvancedFlowLicence = HomeyUtils.getHasAdvancedFlowLicense(api.homey);
      let hasAdvancedFlowVersion = HomeyUtils.getHasAdvancedFlowVersion(api.homey);
      const useSeamlessDevicePairingScreens = HomeyUtils.getIsSeamlessDevicePairingScreens(
        api.homey
      );

      if (api.isCloud === true) {
        hasAdvancedFlowVersion = true;
      }

      if (api.isCloudAndPremium === true) {
        hasAdvancedFlowLicence = true;
      }

      api.features = {
        advancedFlow: {
          get hasLicense() {
            if (api.features.advancedFlow.hasLicenseOverride === true) {
              return true;
            }

            return hasAdvancedFlowLicence;
          },
          hasVersion: hasAdvancedFlowVersion,
        },
        moods: {
          hasMoods: HomeyUtils.getHasMoods(api.homey, user),
        },
        useSeamlessDevicePairingScreens,
      };

      Sentry.setContext('homey', {
        id: api.homey.id,
        softwareVersion: api.homey.softwareVersion,
        apiVersion: api.homey.apiVersion,
        platform: api.homey.platform,
        platformVersion: api.homey.platformVersion,
        model: api.homey.model,
        name: api.homey.name,
        region: api.homey.region,
      });

      Sentry.setTag('softwareVersion', api.homey.softwareVersion);
      Sentry.setTag('apiVersion', api.homey.apiVersion);
      Sentry.setTag('platform', api.homey.platform);
      Sentry.setTag('platformVersion', api.homey.platformVersion);

      // api.enableRouteDetection();
      const baseUrl = await api.baseUrl;

      if (signal.aborted) {
        return;
      }

      api.addListener('online', (online) => {
        __DEV__ && console.log('online:', online);
        // Disabled this for now since it might be very short and removes the users content. Maybe
        // we can add an indicator to the homey icon in the top left.
        // this.set({ online });
      });

      api.addListener('logout', () => {
        window.location.reload();
      });

      api.addListener('routeChanged', (baseUrl) => {
        __DEV__ && console.log('routeChanged:', baseUrl);
        this.set({ baseUrl });
      });

      api.addListener('connect', () => {
        __DEV__ && console.log('connect', { connected: api._connected });
        // const state = this.get();

        // if (state.isRebooting === true) {
        //   state.api.system
        //     .getInfo()
        //     .then((info) => {
        //       if (info.bootId !== state.bootId) {
        //         __DEV__ && console.log('bootId has changed');
        //         this.get().bootId = info.bootId;
        //         window.location.reload();
        //         return;
        //       }

        //       ToastManager.handleError(
        //         new Error('Expected a new boot id but boot id was the same.')
        //       );
        //     })
        //     .catch((error) => {
        //       ToastManager.handleError(
        //         new Error('API has reconnected but failed to get new boot id.')
        //       );
        //       ToastManager.handleError(error);
        //     });

        //   return;
        // }

        this.set({
          connected: api._connected,
        });
      });

      api.addListener('disconnect', () => {
        __DEV__ && console.log('disconnect', { connected: api._connected });
        this.set({
          connected: api._connected,
        });

        // const ping = () => {
        //   console.log('attempting ping');
        //   const minWait = wait(5000);

        //   const currentApi = this.get().api;

        //   if (currentApi !== api) {
        //     // Api has changed so we stop pinging.
        //     return;
        //   }

        //   api.system
        //     .ping({ $timeout: 5000 })
        //     .then(async () => {
        //       const currentApi = this.get().api;

        //       if (currentApi !== api) {
        //         // Api has changed so we dont reconnect.
        //         return;
        //       }

        //       // If we are somehow not connected. Attempt to subscribe. This should fire
        //       // the connect listener above when successful
        //       if (api._connected !== true) {
        //         api._subscribe().catch(async (error) => {
        //           console.error(error);
        //           await minWait;
        //           ping();
        //         });
        //       } else {
        //         console.log('ping successful already connected');
        //       }
        //     })
        //     .catch(async () => {
        //       await minWait;
        //       ping();
        //     });
        // };

        // ping();
      });

      api.addListener('error', (error) => {
        console.error(error);
      });

      document.title = current?.name ?? 'My Homey';

      increaseListenerLimit(api);

      this.set({
        current,
        api,
        scopes,
        baseUrl,
        online: api.online,
        loading: false,
        error: null,
        isConnected: api._connected,
        isRebooting: null,
        isUpdating: null,
      });
    } catch (error) {
      // If the Homey was offline and another one was selected during loading we would never hit the
      // signal aborted because authenticate would throw. So we check the signal again here and
      // don't set the state after an error if the authenticateHomey was aborted.
      if (signal.aborted) {
        return;
      }

      this.set({
        // Leave current because we might have to retry after an error.
        current,
        baseUrl: null,
        api: null,
        scopes: {},
        online: false,
        loading: false,
        error,
        isConnected: null,
        isRebooting: null,
        isUpdating: null,
      });
    }
  }

  static destroyApi() {
    const state = HomeyStore.get();

    if (state.api != null) {
      state.api.destroy();
    }
  }

  static assignAdvancedFlowLicense() {
    const { api } = this.get();

    // This whole approach is a bit odd since we assume something will rerender to reflect the new
    // state. For now it's ok and better than refreshing the whole api (since that will refetch everything).
    if (api != null) {
      api.features.advancedFlow.hasLicenseOverride = true;
    }
  }

  static async reboot() {
    const { api } = this.get();

    if (api != null) {
      api._reconnectIfUsed = () => {};
      const { bootId } = await api.system.reboot();
      this.set({
        isRebooting: true,
        bootId,
      });
    }
  }

  static awaitReboot({ bootId }) {
    const { api } = this.get();

    if (api != null) {
      api._reconnectIfUsed = () => {};
      this.set({
        isRebooting: true,
        bootId,
      });
    }
  }

  static setIsUpdating(value) {
    this.set({
      isUpdating: value,
    });
  }
}

function selector(state) {
  return {
    current: state.current,
    baseUrl: state.baseUrl,
    api: state.api,
    online: state.online,
    loading: state.loading,
    error: state.error,
    retry: state.retry,
    isConnected: state.isConnected,
    isRebooting: state.isRebooting,
    isUpdating: state.isUpdating,
    bootId: state.bootId,
  };
}

function selectApiAndScopes(state) {
  return { api: state.api, scopes: state.scopes };
}

function selectBaseUrl(state) {
  return state.baseUrl;
}

function selectCurrent(state) {
  return state.current;
}

function selectLanguage(state) {
  return state.language;
}

export function useHomey() {
  return HomeyStore.store(selector, shallow);
}

export function useApi() {
  return HomeyStore.store(selectApiAndScopes);
}

export function useBaseUrl() {
  return { baseUrl: HomeyStore.store(selectBaseUrl) };
}

export function useCurrent() {
  return HomeyStore.store(selectCurrent);
}

export function useHomeyLanguage() {
  return HomeyStore.store(selectLanguage, shallow);
}

function increaseListenerLimit(api) {
  api.devices.setMaxListeners(50);
}
