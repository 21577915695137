import { useRouteMatch } from 'react-router-dom';

import { RouteManager } from '../../RouteManager';

export function useAdvancedFlowdRouteId() {
  const advancedFlowRoute = RouteManager.matchPath.advancedFlow;
  const advancedFlowRouteMatch = useRouteMatch(advancedFlowRoute);
  const advancedFlowRouteId = advancedFlowRouteMatch?.params?.advancedFlowId ?? null;

  return { advancedFlowRouteId };
}
