import React, { Suspense, useMemo, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useLabel } from 'react-aria';

import { getStoreKey } from '../tokens';

import { useNodeArgumentContext } from '../../view-advanced-flow/card/NodeArgumentsContext';
import { useTokensByKey } from '../../../../store/flow-tokens/useFlowTokens';
import { useTokenContext } from '../../TokenContext';
import { useArgumentValues } from '../argumentHooks';
import { useTokenHint } from '../TokenHintContext';

import { argInput } from '../argInput';

import { Argument, ArgumentBase } from '../Argument';
import { FlowToken } from '../FlowToken';
import { EditableInput } from './EditableInput';
import { TokenHintContextDecider } from '../TokenHintContext';

export function useArgumentText(props, state) {
  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const chunks = useChunks({
    value: localValue,
  });

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    isDisabled: props.isNodeArgumentContextDisabled,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if ((value == null || value === '') && props.argument.required !== false) {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? localValue : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    setLocalValue(value);
    checkInvalid(value, { checkNode: false });
  }

  function handleSaveRequest() {
    state.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    state.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return {
    onChange: handleChange,
    onCloseRequest: handleCloseRequest,
    onSaveRequest: handleSaveRequest,
    onCancelRequest: handleCancelRequest,
    chunks,
    storeValue,
    localValue,
    isInvalid,
  };
}

export function ArgumentText(props) {
  const triggerState = useOverlayTriggerState({
    // TODO
    // restore
    // defaultOpen: props.isFirstArgument && argumentText.storeValue === undefined,
  });

  const argumentText = useArgumentText(props, triggerState);

  function chunkMapper(chunk, index) {
    if (chunk.type === 'token') {
      return (
        <FlowToken
          key={index}
          token={chunk.token}
          tokenKey={chunk.value}
          data-is-disabled={props.isDisabled}
          data-is-disabled-style={props.isDisabledStyle}
          label={chunk.token?.title}
        />
      );
    }

    return chunk.value;
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={argumentText.onCloseRequest}
      onCancelRequest={argumentText.onCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-empty={argumentText.chunks.length === 0}
            data-is-invalid={argumentText.isInvalid}
          >
            {argumentText.chunks.length > 0
              ? argumentText.chunks.map(chunkMapper)
              : props.argumentTypeText}
          </ArgumentBase>
        );
      }}
      renderOverlay={() => {
        return (
          <TokenHintContextDecider
            cardType={props.cardType}
            card={props.card}
            data={props.data}
            argument={props.argument}
          >
            <TextInput
              label={props.argument.title}
              placeholder={props.argument.placeholder}
              chunks={argumentText.chunks}
              onChange={argumentText.onChange}
              onSaveRequest={argumentText.onSaveRequest}
              onCancelRequest={argumentText.onCancelRequest}
            />
          </TokenHintContextDecider>
        );
      }}
    />
  );
}

function TextInput(props) {
  const label = useLabel({
    label: props.label,
    'aria-label': 'Text',
  });

  const containerRef = useRef();

  const tokenHint = useTokenHint();

  return (
    <argInput.InputContainer ref={containerRef}>
      {props.label && (
        <argInput.InputLabel {...label.labelProps}>{props.label}</argInput.InputLabel>
      )}
      <argInput.InputRow>
        <Suspense fallback={null}>
          <EditableInput
            layout="contained"
            fieldProps={label.fieldProps}
            placeholder={props.placeholder}
            chunks={props.chunks}
            onChange={props.onChange}
            onSaveRequest={props.onSaveRequest}
            onCancelRequest={props.onCancelRequest}
          />
        </Suspense>
      </argInput.InputRow>

      {tokenHint != null && <argInput.InputHint>{tokenHint}</argInput.InputHint>}
    </argInput.InputContainer>
  );
}

// eslint-disable-next-line no-useless-escape
const REGEX = /\[\[([^\[\]]*?)\]\]/g;

export function useChunks({ value }) {
  const { tokensByKey } = useTokenContext();
  const tokens = useTokensByKey();

  return useMemo(() => {
    if (value == null) return [];

    // String(value) because somewhere an app developer might have decided that a previous number
    // argument became a text argument. So we ignore null and undefined but the rest is treated as
    // a string.
    let subject = String(value);

    const result = [];
    const matches = subject.matchAll(REGEX);

    let index = 0;

    for (const match of matches) {
      if (match.index !== index) {
        result.push({
          type: 'text',
          value: subject.substring(index, match.index),
        });
      }

      const key = subject.substring(match.index + 2, match.index + match[0].length - 2);
      const token = key.includes('|') ? tokens.byKey?.[getStoreKey(key)] : tokensByKey[key];

      result.push({
        type: 'token',
        value: key,
        token: token,
      });

      index = match.index + match[0].length;
    }

    if (index <= subject.length - 1) {
      result.push({
        type: 'text',
        value: subject.substring(index, subject.length),
      });
    }

    return result;
  }, [value, tokens.byKey, tokensByKey]);
}
