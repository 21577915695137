/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useOption } from 'react-aria';

import { theme } from '../../../theme/theme';
import { typography } from '../../../components/primitives/typography';

import { Icon } from '../../../components/common/Icon';
import { Circle } from '../../../components/common/Circle';

import { iconCheckmark } from '../../../theme/icons/interface/checkmark';

export function InsightAddListBoxOption(props) {
  const optionRef = useRef();
  const color = props.colorMap[props.item.key];

  const isDisabled = props.listState.disabledKeys.has(props.item.key);
  const isSelected = props.listState.selectionManager.isSelected(props.item.key);

  const option = useOption(
    {
      key: props.item.key,
      isDisabled,
      isSelected,
    },
    props.listState,
    optionRef
  );

  return (
    <sc.Item {...option.optionProps} ref={optionRef}>
      <sc.Checkbox isSelected={isSelected}>
        <Icon
          size={theme.icon.size_tiny}
          url={iconCheckmark}
          color={isSelected ? theme.color.white : 'transparent'}
        />
      </sc.Checkbox>

      <div>
        <div css={sc_css.uri_details}>
          <small>
            <strong>{props.ownerName}</strong>&nbsp;
            <sc.ZoneName>{props.ownerZoneName}</sc.ZoneName>
          </small>
        </div>

        <div css={sc_css.log_details}>
          <Circle hue={color?.hue} />
          <typography.body2>{props.item.value.title}</typography.body2>
        </div>
      </div>
    </sc.Item>
  );
}

const sc = {
  Item: styled.li`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    outline: 0;
    cursor: pointer;

    &:hover {
      background-color: ${theme.color.background_hover};
    }
  `,
  Checkbox: styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    border-radius: 4px;
    background-color: ${(props) => (props.isSelected ? theme.color.blue : 'transparent')};
    border: 1px solid ${(props) => (props.isSelected ? theme.color.blue : theme.color.line)};

    transition: ${theme.transition.micro};
  `,
  ZoneName: styled.span`
    font-size: ${theme.fontSize.small};
    color: ${theme.color.text_light};
  `,
};

const sc_css = {
  uri_details: css`
    display: flex;
    align-items: center;

    > :not(:last-of-type) {
      margin-right: 4px;
    }
  `,
  log_details: css`
    display: flex;
    align-items: center;

    > :not(:last-of-type) {
      margin-right: 10px;
    }
  `,
};
