import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { FlowFolderStore } from './FlowFolderStore';
import { useAttach } from '../useAttach';

import { selectData, selectById } from '../selectors';

export function useFlowFoldersData() {
  useAttach(FlowFolderStore, 'flowFoldersData');

  return FlowFolderStore.store(selectData, shallow);
}

export function useFlowFoldersById() {
  useAttach(FlowFolderStore, 'flowFoldersById');

  return FlowFolderStore.store(selectById, shallow);
}

export function useFlowFolder({ folderId }) {
  useAttach(FlowFolderStore, 'flowFolder');

  const select = useCallback(
    (state) => {
      return {
        folder: state.byId?.[folderId] ?? null,
        manager: state.manager,
        loading: state.loading,
        error: state.error,
      };
    },
    [folderId]
  );

  return FlowFolderStore.store(select, shallow);
}

export function useFlowFoldersAttach() {
  useAttach(FlowFolderStore, 'useFlowFoldersAttach');
}
