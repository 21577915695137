import styled from '@emotion/styled';

import { InsightListItem } from './InsightListItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Item = styled.div`
  padding: 10px 0;
`;

const insightList = (
  <Container>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
    <Item>
      <InsightListItem />
    </Item>
  </Container>
);

export function InsightList() {
  return insightList;
}
