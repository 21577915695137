import { useMemo } from 'react';
import styled from '@emotion/styled';

import { useI18n } from '../../../../hooks/useI18nFormatters';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { publicUrl } from '../../../../theme/functions/publicUrl';
import { animationFade } from '../../../../theme/animations/animationFade';

import { GradientButton } from '../../../buttons/GradientButton';

import { iconSquareArrowUpRight } from '../../../../theme/icons/interface/square-arrow-up-right/square-arrow-up-right';

export function BridgeRequiredView(props) {
  const { i18n } = useI18n();

  const isBasicDriver =
    props.driverKey != null &&
    props.uri === 'homey:manager:vdevice' &&
    (props.driverKey.includes('zigbee') ||
      props.driverKey.includes('zwave') ||
      props.driverKey.includes('infrared'));

  const connectivityMessage = useMemo(() => {
    const localConnectivities = {
      ble: 'Bluetooth',
      zwave: 'Z-Wave',
      zigbee: 'Zigbee',
      infrared: 'Infrared',
      rf433: '433 MHz',
    };

    // Filter out connectivities that are not in the localConnectivities object (e.g. Matter)
    const filteredConnectivities = props.connectivities.filter((connectivity) =>
      Object.keys(localConnectivities).includes(connectivity)
    );

    // Format the message as a list comma separated connectivities with the last one being 'or'
    return filteredConnectivities
      .map((connectivity) => localConnectivities[connectivity])
      .join(', ')
      .replace(/, ([^,]*)$/, ' or $1');
  }, [props.connectivities]);

  return (
    <BridgeRequiredView.Root>
      <BridgeRequiredView.Content>
        <BridgeRequiredView.Title>
          {i18n.messageFormatter('pair.bridgeRequiredTitle')}
        </BridgeRequiredView.Title>
        <BridgeRequiredView.Message>
          {isBasicDriver
            ? i18n.messageFormatter('pair.bridgeRequiredTechnologyMessage', {
                technology: props.driverInfo?.driverName,
              })
            : i18n.messageFormatter('pair.bridgeRequiredDeviceMessage', {
                device: props.driverInfo?.driverName,
                connectivity: connectivityMessage,
              })}
        </BridgeRequiredView.Message>
        <BridgeRequiredView.Image
          height="196"
          srcSet={`${publicUrl('/img/pairing/homey-bridge.png')} 1x, ${publicUrl(
            '/img/pairing/homey-bridge@2x.png'
          )} 2x`}
          src={publicUrl('/img/pairing/homey-bridge.png')}
        />
      </BridgeRequiredView.Content>
      <BridgeRequiredView.Footer>
        <GradientButton
          styleWidth="full"
          styleColor="purple"
          elementType="a"
          target="_blank"
          href="https://homey.app/homey-bridge"
        >
          <GradientButton.Text>
            {i18n.messageFormatter('pair.bridgeRequiredButton')}
          </GradientButton.Text>
          <GradientButton.Icon
            url={iconSquareArrowUpRight}
            color={theme.color.white}
            size={theme.icon.size_small}
          />
        </GradientButton>
      </BridgeRequiredView.Footer>
    </BridgeRequiredView.Root>
  );
}

BridgeRequiredView.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${su(2)};

  width: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;
`;

BridgeRequiredView.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

BridgeRequiredView.Title = styled.h1`
  margin-bottom: ${su(1)};
  text-align: center;
`;

BridgeRequiredView.Message = styled.div`
  text-align: center;
`;

BridgeRequiredView.Image = styled.img`
  display: block;
  max-height: 40vh;
  margin-top: ${su(6)};
`;

BridgeRequiredView.Footer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`;
