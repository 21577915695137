import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../../ToastManager';

import { useApi } from '../../../../store/HomeyStore';
import { useDriver } from '../../../../store/drivers/useDrivers';
import { useI18n } from '../../../../hooks/useI18nFormatters';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { animationFade } from '../../../../theme/animations/animationFade';

import { SolidButton } from '../../../buttons/SolidButton';
import { Icon } from '../../../common/Icon';
import { SpinnerFade } from '../../../common/SpinnerFade';
import { DeviceTile } from '../../../device/DeviceTile';
import { Image } from '../../../common/Image';

import { iconWarningCircle } from '../../../../theme/icons/interface/warning-circle/warning-circle';

export function ConnectView(props) {
  const { i18n } = useI18n();

  const instanceRef = useRef({});
  instanceRef.current.onConnectPress = props.onConnectPress;

  const { api } = useApi();
  const { driver } = useDriver({ driverKey: props.driverKey });
  const [isInstalling, setIsInstalling] = useState(false);

  useEffect(() => {
    // when it was not installed before but is installed now
    if (driver != null && driver.ready === true && props.isInstalled === false) {
      instanceRef.current.onConnectPress(driver.connectivity);
    }
  }, [driver, props.isInstalled]);

  function getButtonText() {
    if (isInstalling) return i18n.messageFormatter('pair.installingApp');
    if (props.isInstalled) return i18n.messageFormatter('common.connect');
    return i18n.messageFormatter('pair.installAndConnect');
  }

  async function handleConnectPress() {
    if (isInstalling) return;

    if (props.isInstalled === false) {
      setIsInstalling(true);

      try {
        // handled further in useEffect
        await api.apps.installFromAppStore({
          id: props.appInfo.appId,
        });
      } catch (error) {
        ToastManager.handleError(error);
        setIsInstalling(false);
      }

      return;
    }

    props.onConnectPress(driver.connectivity);
  }

  return (
    <ConnectView.Root>
      <ConnectView.Info>
        <ConnectView.IconWrapper backgroundColor={props.appInfo.appColor}>
          {props.appInfo.appId === 'vdevice' ? (
            <Image url={'/img/logo192.png'} size="100px" />
          ) : (
            <Icon url={props.appInfo.appIcon} color={theme.color.white} size="50px" />
          )}
        </ConnectView.IconWrapper>

        <ConnectView.AppName>{props.appInfo.appName}</ConnectView.AppName>

        <ConnectView.Message>
          {i18n.messageFormatter('pair.connect', {
            name: props.driverInfo.driverName,
          })}
        </ConnectView.Message>

        <DeviceTile
          isNoInteraction
          iconUrl={props.driverInfo.driverIcon}
          name={props.driverInfo.driverName}
        />
      </ConnectView.Info>

      {props.appInfo.appPermissions.includes('homey:manager:api') && (
        <ConnectView.WarningWrapper>
          <ConnectView.Warning>
            <Icon url={iconWarningCircle} color={theme.color.white} size={theme.icon.size_large} />
            {i18n.messageFormatter('pair.permissionAPI')}
          </ConnectView.Warning>
        </ConnectView.WarningWrapper>
      )}

      <ConnectView.Footer>
        <SolidButton
          styleColor="green"
          styleFlat={true}
          styleWidth="full"
          isDisabled={isInstalling === true}
          onPress={handleConnectPress}
        >
          <SpinnerFade
            isActive={isInstalling === true}
            color={theme.color.white}
            size={theme.icon.size_small}
          />
          <SolidButton.Text>{getButtonText()}</SolidButton.Text>
        </SolidButton>
      </ConnectView.Footer>
    </ConnectView.Root>
  );
}

ConnectView.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: ${su(2)};

  width: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;
`;

ConnectView.Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  margin: ${su(2, 0)};
`;

ConnectView.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100px;
  width: 100px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
`;

ConnectView.AppName = styled.h2`
  padding: ${su(2, 0, 1)};
  text-align: center;
`;

ConnectView.Message = styled.div`
  padding-bottom: ${su(4)};
  color: ${theme.color.text_light};
  text-align: center;
`;

ConnectView.WarningWrapper = styled.div`
  padding: ${su(1, 0)};
`;

ConnectView.Warning = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.color.warning};
  color: ${theme.color.white};
  border-radius: ${theme.borderRadius.default};
  padding: ${su(2, 1)};

  ${Icon.S.Root} {
    margin-right: ${su(1)};
  }
`;

ConnectView.Footer = styled.div`
  display: flex;
  width: 100%;
`;
