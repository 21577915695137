import React, { useMemo } from 'react';
import { HomeyColors } from '../../../theme/HomeyColors';
import { HomeyImages } from '../../../theme/HomeyImages';

import { useApi } from '../../../store/HomeyStore';

export function ManagerFlowCard(props) {
  const { component: Component, ...rest } = props;
  // const managerId = props.ownerId;
  const { api } = useApi();

  const { iconUrl, iconObj, iconBackgroundColor, resourceUrl, name, isLocked } = useMemo(() => {
    // todo maybe use managerId with locale translations
    const result = {
      iconUrl: HomeyImages.getIconForUri(props.ownerUri),
      iconObj: null,
      iconBackgroundColor: HomeyColors.getColorForUri(props.ownerUri),
      resourceUrl: null,
      name: props.card.ownerName ?? props.card.uriObj?.name,
      isLocked: props.card.requiresPremium === true && api.tier !== 'premium',
    };

    return result;
  }, [props.ownerUri, props.card, api]);

  return (
    <Component
      {...rest}
      iconUrl={iconUrl}
      iconObj={iconObj}
      iconBackgroundColor={iconBackgroundColor}
      resourceUrl={resourceUrl}
      name={name}
      isLocked={isLocked}
    />
  );
}
