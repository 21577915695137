import { createContext, useContext } from 'react';
import styled from '@emotion/styled';
import { useRef } from 'react';
import { useTableState } from 'react-stately';
import { useTable } from 'react-aria';

import { theme } from '../../../theme/theme';

import { SettingsTableRowGroup } from './SettingsTableRowGroup';
import { FamilySettingsTableHeaderRow } from './SettingsTableHeaderRow';
import { SettingsTableColumnHeader } from './SettingsTableColumnHeader';
import { SettingsTableRow } from './SettingsTableRow';

const RowContext = createContext(null);
const CellContext = createContext(null);
const HeaderColumnContext = createContext(null);

export function useRowContext() {
  return useContext(RowContext);
}

export function useCellContext() {
  return useContext(CellContext);
}

export function useHeaderColumnContext() {
  return useContext(HeaderColumnContext);
}

export function SettingsTable(props) {
  const state = useTableState({
    ...props,
    showSelectionCheckboxes: false,
  });

  const ref = useRef();
  const table = useTable(props, state, ref);

  function renderColumnHeader() {
    return <SettingsTableColumnHeader />;
  }

  return (
    <S.Table {...table.gridProps} ref={ref} data-is-header-hidden={props.isHeaderHidden}>
      <SettingsTableRowGroup type="thead">
        {state.collection.headerRows.map((headerRow) => (
          <FamilySettingsTableHeaderRow
            key={headerRow.key}
            item={headerRow}
            state={state}
            isHeaderHidden={props.isHeaderHidden}
          >
            {[...headerRow.childNodes].map((column) => {
              return (
                <HeaderColumnContext.Provider key={column.key} value={{ column, state }}>
                  {props.renderColumnHeader
                    ? props.renderColumnHeader({ column, state })
                    : renderColumnHeader({ column, state })}
                </HeaderColumnContext.Provider>
              );
            })}
          </FamilySettingsTableHeaderRow>
        ))}
      </SettingsTableRowGroup>

      <SettingsTableRowGroup type="tbody">
        {[...state.collection.body.childNodes].map((row) => {
          return (
            <RowContext.Provider key={row.key} value={{ row, state }}>
              <SettingsTableRow item={row} state={state}>
                {[...row.childNodes].map((cell) => {
                  return (
                    <CellContext.Provider key={cell.key} value={{ row, cell, state }}>
                      {props.renderCell({ cell, row })}
                    </CellContext.Provider>
                  );
                })}
              </SettingsTableRow>
            </RowContext.Provider>
          );
        })}
      </SettingsTableRowGroup>
    </S.Table>
  );
}

function S() {}
SettingsTable.S = S;

S.Table = styled.table`
  --header-height: 32px;

  &[data-is-header-hidden='true'] {
    --header-height: 20px;
  }

  width: min(calc(100% - 40px), 720px);
  border-collapse: collapse;
  position: relative;
  table-layout: fixed;

  &::before {
    content: '';
    position: absolute;
    box-shadow: ${theme.boxShadow.default};
    border-radius: ${theme.borderRadius.default};
    top: var(--header-height);
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`;
