import styled from '@emotion/styled';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { routingStore } from '../../RouteManager';

import { useAppsData } from '../../store/apps/useApps';
import { useI18n } from '../../hooks/useI18nFormatters';

import { theme } from '../../theme/theme';
import { scrollbars } from '../../theme/elements/scrollbars';

import { Layout } from '../../Layout';
import { CenteredMessage } from '../../components/common/CenteredMessage';
import { Icon } from '../../components/common/Icon';
import { ExternalLink } from '../../components/common/link/ExernalLink';

import { SettingsNavigation } from './SettingsNavigation';

import { GeneralSettings } from './system/GeneralSettings';
import { UpdatesSettings } from './system/UpdatesSettings';
import { LedRingSettings } from './system/LedRingSettings';
import { LanguageSettings } from './system/LanguageSettings';
import { LocationSettings } from './system/LocationSettings';
import { NotificationSettings } from './system/NotificationSettings';
import { FamilySettings } from './system/FamilySettings';
import { ApiKeysSettings } from './system/ApiKeysSettings';
import { ExperimentsSettings } from './system/ExperimentsSettings';
import { WebAppSettings } from './system/WebAppSettings';

import { AlexaSettings } from './integrations/AlexaSettings';
import { GoogleAssistantSettings } from './integrations/GoogleAssistantSettings';
import { SiriShortcutsSettings } from './integrations/SiriShortcutsSettings';

import { AppSettings } from './apps/AppSettings';
import { AppSettingsDialogConfigureApp } from './apps/AppSettingsDialogConfigureApp';
import { AppSettingsDialogUninstall } from './apps/AppSettingsDialogUninstall';

import { iconMagnifyingGlass } from '../../theme/icons/interface/magnifying-glass/magnifying-glass';

export function SettingsPage() {
  const apps = useAppsData();

  const { i18n } = useI18n();
  const { path } = useRouteMatch();

  return (
    <Layout
      subNavigationProps={{
        key: 'settings',
        width: 280,
        title: i18n.messageFormatter('settings.title'),
      }}
      subNavigationChildren={<SettingsNavigation />}
      content={
        <S.Content>
          <Switch>
            <Route
              path={`${path}/${GeneralSettings.pathKey}`}
              render={() => {
                return <GeneralSettings />;
              }}
            />

            <Route
              path={`${path}/${UpdatesSettings.pathKey}`}
              render={() => {
                return <UpdatesSettings />;
              }}
            />

            <Route
              path={`${path}/${LedRingSettings.pathKey}`}
              render={() => {
                return <LedRingSettings />;
              }}
            />

            <Route
              path={`${path}/${LanguageSettings.pathKey}`}
              render={() => {
                return <LanguageSettings />;
              }}
            />

            <Route
              path={`${path}/${LocationSettings.pathKey}`}
              render={() => {
                return <LocationSettings />;
              }}
            />

            <Route
              path={`${path}/${NotificationSettings.pathKey}`}
              render={() => {
                return <NotificationSettings />;
              }}
            />

            <Route
              path={`${path}/${FamilySettings.pathKey}`}
              render={() => {
                return <FamilySettings />;
              }}
            />

            <Route
              path={`${path}/${ApiKeysSettings.pathKey}`}
              render={() => {
                return <ApiKeysSettings />;
              }}
            />

            <Route
              path={`${path}/${ExperimentsSettings.pathKey}`}
              render={() => {
                return <ExperimentsSettings />;
              }}
            />

            <Route
              path={`${path}/${WebAppSettings.pathKey}`}
              render={() => {
                return <WebAppSettings />;
              }}
            />

            <Route
              path={`${path}/${AlexaSettings.pathKey}`}
              render={() => {
                return <AlexaSettings />;
              }}
            />

            <Route
              path={`${path}/${GoogleAssistantSettings.pathKey}`}
              render={() => {
                return <GoogleAssistantSettings />;
              }}
            />

            <Route
              path={`${path}/${SiriShortcutsSettings.pathKey}`}
              render={() => {
                return <SiriShortcutsSettings />;
              }}
            />

            <Route
              path={`${path}/apps`}
              render={() => {
                return (
                  <Switch>
                    <Route
                      exact={true}
                      path={`${path}/apps`}
                      render={() => {
                        // Maybe render redirect to /settings since we dont really have an /apps path.
                        return <div>Select an app.</div>;
                      }}
                    />

                    {Object.entries(apps.data ?? {}).map(([appId, app]) => {
                      return (
                        <Route
                          key={appId}
                          path={`${path}/apps/${appId}`}
                          render={() => {
                            return <AppSettings appId={appId} />;
                          }}
                        />
                      );
                    })}

                    <Route
                      path={`${path}/apps/*`}
                      render={() => {
                        // TODO add better state
                        if (apps.loading) {
                          return (
                            <CenteredMessage
                              subtitle="Loading..."
                              subtitleColor={theme.color.text_light}
                            />
                          );
                        }

                        // TODO add better state
                        if (apps.error) {
                          return (
                            <CenteredMessage
                              title="Error"
                              subtitle={apps.error.message ?? String(apps.error)}
                              subtitleColor={theme.color.text_light}
                            />
                          );
                        }

                        return (
                          <CenteredMessage
                            icon={
                              <Icon.RoundWrapper size="80px" color={theme.color.mono_050}>
                                <Icon
                                  url={iconMagnifyingGlass}
                                  color={theme.color.icon_light}
                                  size="40px"
                                />
                              </Icon.RoundWrapper>
                            }
                            title="App Not Found"
                            subtitle={
                              <>
                                Oops, it looks like this app no longer exists. You might have
                                recently deleted it. Go to{' '}
                                <ExternalLink url="https://homey.app/apps">
                                  homey.app/apps
                                </ExternalLink>{' '}
                                to reinstall the app.
                              </>
                            }
                            subtitleColor={theme.color.text_light}
                          />
                        );
                      }}
                    />
                  </Switch>
                );
              }}
            />

            <Route
              path="*"
              render={() => {
                const { homeyPath } = routingStore.getState();

                return (
                  <Redirect
                    to={{
                      pathname: `${homeyPath}/settings/${GeneralSettings.pathKey}`,
                    }}
                  />
                );
              }}
            />
          </Switch>

          <AppSettingsDialogUninstall.route.IsMatch
            children={() => {
              return <AppSettingsDialogUninstall />;
            }}
          />

          <AppSettingsDialogConfigureApp.route.IsMatch
            children={() => {
              return <AppSettingsDialogConfigureApp />;
            }}
          />
        </S.Content>
      }
    />
  );
}

function S() {}
SettingsPage.S = S;

S.Content = styled.div`
  ${scrollbars.dark};
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default SettingsPage;
