import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';

import { ToastManager } from '../../../ToastManager';

import { CapUtils } from '../../../lib/capabilities/capabilities';
import { getButtonImage } from './getButtonImage';

import { theme } from '../../../theme/theme';

import { Icon } from '../../common/Icon';
import { HomeyIcon } from '../../common/HomeyIcon';

export const variants = {
  initial: { backgroundColor: theme.color.black_o_50 },
  true: { backgroundColor: theme.color.white_o_100 },
  false: { backgroundColor: theme.color.black_o_50 },
  whileTap: { backgroundColor: theme.color.white_o_100 },
};

export const childVariants = {
  initial: { backgroundColor: theme.color.white_o_100 },
  true: { backgroundColor: theme.color.green },
  false: { backgroundColor: theme.color.white_o_100 },
  whileTap: { backgroundColor: theme.color.green },
};

export function Button({ capability, isSingle }) {
  const instance = useRef(`Button:${uuid()}`);
  const [value, setValue] = useState(capability?.value ?? false);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        setValue(CapUtils.nullAsFalse(value));
      });

      setValue(CapUtils.nullAsFalse(capability.value));

      return unregister;
    }
  }, [capability]);

  if (capability == null) return null;

  const { url, useIconObj } = getButtonImage(capability.id, capability);

  function handleClick() {
    if (capability.getable) {
      const prevValue = value;
      const nextValue = !prevValue;
      setValue(nextValue);

      capability
        .setValue(nextValue, instance.current)
        .then(({ transactionId, transactionTime, value }) => {})
        .catch((error) => {
          setValue(prevValue);
          ToastManager.handleError(error);
        });

      return;
    }

    capability
      .setValue(true, instance.current)
      .then(() => {})
      .catch((error) => {
        ToastManager.handleError(error);
      });
  }

  return (
    <sc.Container>
      <sc.ButtonBase
        initial="initial"
        animate={value ? 'true' : 'false'}
        whileTap="whileTap"
        transition={{
          duration: 0.2,
        }}
        variants={variants}
        isSingle={isSingle}
        onClick={handleClick}
      >
        {useIconObj ? (
          <HomeyIcon
            iconObj={capability.iconObj}
            as={motion.span}
            size={isSingle ? '48px' : '32px'}
            transition={{
              duration: 0.2,
            }}
            variants={childVariants}
          />
        ) : (
          <Icon
            as={motion.span}
            size={isSingle ? '48px' : '32px'}
            transition={{
              duration: 0.2,
            }}
            variants={childVariants}
            url={url}
          />
        )}
      </sc.ButtonBase>

      <sc.Title>{capability.title}</sc.Title>
    </sc.Container>
  );
}

const sc = {
  Container: styled.div`
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  `,
  Title: styled.div`
    color: ${theme.color.text_invert_o_50};
    line-height: 18px;
    text-align: center;
    padding: 0 10px;
  `,
  ButtonBase: styled(motion.button)`
    display: flex;
    position: relative;
    height: 100px;
    width: 100px;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border: 0;
    border-radius: 50%;
    outline: none;
    background-color: ${theme.color.black_o_50};
    cursor: pointer;
  `,
};
