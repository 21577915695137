import { useMemo } from 'react';
import styled from '@emotion/styled';

import { useCapabilities } from '../../../store/devices/useDevices';

import { su } from '../../../theme/functions/su';

import { Heading3Bold } from '../../../components/common/text/Heading3Bold';
import { DeviceSettingsMaintenanceAction } from './DeviceSettingsMaintenanceAction';

export function DeviceSettingsMaintenance(props) {
  const { capabilities } = useCapabilities({ deviceId: props.deviceId });

  const maintenanceCapabilitiesList = useMemo(() => {
    return Object.values(capabilities ?? {}).filter((capability) => {
      if (capability.id.startsWith('button') === false) return false;

      return (
        capability.maintenanceAction === true || capability.options?.maintenanceAction === true
      );
    });
  }, [capabilities]);

  if (maintenanceCapabilitiesList.length === 0) {
    return null;
  }

  return (
    <S.Root>
      <Heading3Bold>Maintenance</Heading3Bold>
      {maintenanceCapabilitiesList.map((capability) => {
        return <DeviceSettingsMaintenanceAction key={capability.id} capability={capability} />;
      })}
    </S.Root>
  );
}

function S() {}
DeviceSettingsMaintenance.S = S;

S.Root = styled.div`
  ${Heading3Bold.S.Root} {
    margin: ${su(4, 0, 2)};
  }
`;
