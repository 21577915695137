import { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { ParentSize } from '@visx/responsive';

import { RouteManager } from '../../RouteManager';
import { InsightUtils } from '../../store/insights/InsightUtils';

import { useQuery } from '../../hooks/useQuery';
import { useLogsByKey } from '../../store/insights/useInsights';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { useApi } from '../../store/HomeyStore';
import { useZonesAttach } from '../../store/zones/useZones';
import { useDevicesAttach } from '../../store/devices/useDevices';
import { useLogsAttach } from '../../store/insights/useInsights';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { dragTypes } from '../../components/dnd/dragTypes';

import { Layout } from '../../Layout';
import { Scroll } from '../../components/common/Scroll';
import { CenteredMessage } from '../../components/common/CenteredMessage';
import { MonoButton } from '../../components/buttons/MonoButton';
import { PremiumRequiredDialog } from '../../components/premium/PremiumRequiredDialog';

import { InsightListBox } from './insight-listbox/InsightListBox';
import { ResolutionSelect } from './resolution-select/ResolutionSelect';
import { LogGroup } from './LogGroup';
import { LogDropZone } from './LogDropZone';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';
import { iconInsights } from '../../theme/icons/system/insights';

export function InsightsPage() {
  const { i18n } = useI18n();

  const location = useLocation();
  const query = useQuery();

  const { api } = useApi();

  useDevicesAttach();
  useZonesAttach();
  useLogsAttach();

  const isPremiumLocked =
    api?.tier !== 'premium' && api?.homey.platform !== undefined && api?.homey.platform !== 'local';
  const logs = useLogsByKey();

  const [selectedResolutionKey, setSelectedResolutionKey] = useLocalStorageState(
    'last7Days',
    'insightsResolutionSelect'
  );

  const [chipDeviceId, setChipDeviceId] = useState(null);

  useEffect(() => {
    if (location.state?.defaultSelectedKeys != null && logs.byKey != null) {
      if (isPremiumLocked === true) {
        RouteManager.toPremiumRequiredDialog();
        return;
      }
      const nextLogsGroups = {};
      location.state.defaultSelectedKeys.forEach((key) => {
        const log = logs.byKey[key];

        if (log != null) {
          nextLogsGroups[`${getNextGroupKey(nextLogsGroups)}`] = [key];
        }
      });

      const nextRouteState = {
        ...location.state,
      };
      delete nextRouteState.defaultSelectedKeys;
      delete nextRouteState.deviceId;

      if (location.state.deviceId) {
        setChipDeviceId(location.state.deviceId);
      }

      RouteManager.toInsights(nextRouteState, getSelectionUrl(nextLogsGroups), { replace: true });
    }
  }, [location.state, logs.byKey, isPremiumLocked]);

  const { logGroups, logGroupEntries, selectedLogKeys } = useMemo(() => {
    const logGroups = {};
    const selectedLogKeys = new Set();

    for (const [key, value] of query.entries()) {
      if (key.startsWith('logs')) {
        const groupKey = key.replace('logs', '');
        logGroups[groupKey] = value.split(',');
        for (const logKey of logGroups[groupKey]) {
          selectedLogKeys.add(logKey);
        }
      }
    }

    return { logGroups, logGroupEntries: Object.entries(logGroups), selectedLogKeys };
  }, [query]);

  // non merging dropzone
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: [dragTypes.LOG],
    collect: (dropMonitor) => {
      return {
        isOver: dropMonitor.isOver({ shallow: true }),
        canDrop: dropMonitor.canDrop(),
      };
    },
    canDrop(dragItem, dropMonitor) {
      return true;
    },
    hover(item, monitor) {},
    drop(dragItem, monitor) {
      if (monitor.didDrop()) return;

      onDrop({
        targetGroupKey: null,
        logKey: dragItem.id,
        units: dragItem.units,
      });
    },
  });

  function getNextGroupKey(currentGroups) {
    let index = null;
    let cursor = 1;

    while (index === null) {
      if (currentGroups[cursor] == null) {
        index = cursor;
      } else {
        cursor++;
      }
    }

    return index;
  }

  function onDrop({ targetGroupKey, logKey, units }) {
    if (isPremiumLocked === true) {
      RouteManager.toPremiumRequiredDialog();
      return;
    }

    const group = Object.entries(logGroups).find(([groupKey]) => {
      return groupKey === targetGroupKey;
    });

    let nextLogGroups;

    if (group) {
      const [groupKey, groupValues] = group;

      // already part of this group
      if (groupValues.includes(logKey)) return logGroups;

      nextLogGroups = {
        ...logGroups,
        [groupKey]: [...groupValues, logKey],
      };
    } else {
      nextLogGroups = {
        ...logGroups,
        [`${getNextGroupKey(logGroups)}`]: [logKey],
      };
    }

    RouteManager.toInsights({}, getSelectionUrl(nextLogGroups));
  }

  function handleSelectionChange(selectedKeys) {
    if (isPremiumLocked === true) {
      RouteManager.toPremiumRequiredDialog();
      return;
    }

    const removed = new Set([...selectedLogKeys].filter((x) => !selectedKeys.has(x)));
    const added = new Set([...selectedKeys].filter((x) => !selectedLogKeys.has(x)));

    let nextLogGroups = { ...logGroups };

    [...removed].forEach((removedValue) => {
      nextLogGroups = Object.entries(nextLogGroups).reduce(
        (accumulator, [groupKey, groupValues]) => {
          let nextGroupValues = groupValues;

          if (groupValues.includes(removedValue)) {
            nextGroupValues = groupValues.filter((value) => value !== removedValue);
          }

          if (nextGroupValues.length === 0) {
            return accumulator;
          }

          accumulator[groupKey] = nextGroupValues;
          return accumulator;
        },
        {}
      );
    });

    [...added].forEach((logKey) => {
      nextLogGroups[`${getNextGroupKey(nextLogGroups)}`] = [logKey];
    });

    RouteManager.toInsights({}, getSelectionUrl(nextLogGroups));
  }

  function getSelectionUrl(groups) {
    let url = '';

    const groupEntries = Object.entries(groups);

    for (const [index, [logGroupKey, logKeys]] of groupEntries.entries()) {
      let postfix = '';

      if (index !== groupEntries.length - 1) {
        postfix = '&';
      }

      url += `logs${logGroupKey}=${logKeys.join(',')}${postfix}`;
    }

    return url;
  }

  function removeKeyFromGroup(groupKey, logKey) {
    const nextInsightGroups = { ...logGroups };

    nextInsightGroups[groupKey] = nextInsightGroups[groupKey].filter((key) => {
      return logKey !== key;
    });

    if (nextInsightGroups[groupKey].length === 0) {
      delete nextInsightGroups[groupKey];
    }

    const occursInOtherGroup = Object.values(nextInsightGroups).some((group) => {
      return group.includes(logKey);
    });

    if (occursInOtherGroup) {
    } else {
      const nextSelectionInsightKeys = new Set(selectedLogKeys);
      nextSelectionInsightKeys.delete(logKey);
    }

    RouteManager.toInsights({}, getSelectionUrl(nextInsightGroups));
  }

  function removeGroup(groupKey) {
    const nextInsightGroups = { ...logGroups };

    if (nextInsightGroups[groupKey]) {
      const currentGroup = nextInsightGroups[groupKey];
      delete nextInsightGroups[groupKey];

      const nextSelectionInsightKeys = new Set(selectedLogKeys);
      currentGroup.forEach((logKey) => {
        let occursInOtherGroup = Object.values(nextInsightGroups).some((group) => {
          return group.includes(logKey);
        });

        if (!occursInOtherGroup) {
          nextSelectionInsightKeys.delete(logKey);
        }
      });

      RouteManager.toInsights({}, getSelectionUrl(nextInsightGroups));
    }
  }

  return (
    <Layout
      actions={
        <InsightsPage.Actions>
          <ResolutionSelect
            selectedKey={selectedResolutionKey}
            onSelectionChange={setSelectedResolutionKey}
          />

          {selectedLogKeys.size > 0 && (
            <MonoButton
              onPress={() => {
                handleSelectionChange(new Set());
              }}
            >
              <MonoButton.Icon
                url={iconCloseThin}
                color={theme.color.text}
                size={theme.icon.size_small}
              />
              <MonoButton.Text>{i18n.messageFormatter('insights.closeAll')}</MonoButton.Text>
            </MonoButton>
          )}
        </InsightsPage.Actions>
      }
      subNavigationProps={{
        key: 'insights',
        title: i18n.messageFormatter('navigation.insights'),
        width: 280,
      }}
      subNavigationChildren={
        !logs.loading && (
          <InsightListBox
            selectedKeys={selectedLogKeys}
            selectedResolutionKey={selectedResolutionKey}
            chipDeviceId={chipDeviceId}
            setChipDeviceId={setChipDeviceId}
            onSelectionChange={handleSelectionChange}
          />
        )
      }
      content={
        logGroupEntries.length === 0 &&
        canDrop === false &&
        location.state?.defaultSelectedKeys == null ? (
          <Scroll flex>
            <InsightsPage.MessageWrapper>
              <CenteredMessage
                iconUrl={iconInsights}
                iconColor={theme.color.text_light}
                title={i18n.messageFormatter('insights.emptySelectionTitle')}
                titleColor={theme.color.text_light}
                subtitle={i18n.messageFormatter('insights.emptySelectionSubtitle')}
                subtitleColor={theme.color.text_light}
              />
            </InsightsPage.MessageWrapper>
          </Scroll>
        ) : (
          <Scroll>
            <InsightsPage.Grid.Root>
              <ParentSize
                parentSizeStyles={{}}
                ignoreDimensions={['height']}
                debounceTime={0}
                enableDebounceLeadingCall={true}
              >
                {({ width }) => {
                  const groups = logGroupEntries.map(([logGroupKey, logKeys]) => {
                    const log = logs.byKey?.[logKeys[0]];
                    let units = InsightUtils.getUnits(log);

                    // Cast null back to its proper value.
                    if (units === 'null') {
                      units = null;
                    }

                    return (
                      <InsightsPage.Grid.Item key={logGroupKey}>
                        <LogGroup
                          key={logGroupKey}
                          groupKey={logGroupKey}
                          groupUnits={units}
                          logKeys={logKeys}
                          width={width}
                          resolutionId={selectedResolutionKey}
                          onDrop={onDrop}
                          onRequestSelectKeys={(keys) => {
                            const nextLogGroups = { ...logGroups };
                            nextLogGroups[logGroupKey] = [...nextLogGroups[logGroupKey], ...keys];
                            RouteManager.toInsights({}, getSelectionUrl(nextLogGroups));
                          }}
                          onRemoveKeyRequest={({ logKey }) =>
                            removeKeyFromGroup(logGroupKey, logKey)
                          }
                          onRequestRemoveGroup={() => removeGroup(logGroupKey)}
                        />
                      </InsightsPage.Grid.Item>
                    );
                  });

                  return (
                    <>
                      {groups}
                      {canDrop && (
                        <InsightsPage.Grid.Item>
                          <LogDropZone
                            dropRef={dropRef}
                            isOver={isOver}
                            canDrop={canDrop}
                            height={280}
                            width={width}
                          />
                        </InsightsPage.Grid.Item>
                      )}
                    </>
                  );
                }}
              </ParentSize>
            </InsightsPage.Grid.Root>
          </Scroll>
        )
      }
    >
      <AnimatePresence>
        {location.state?.premiumRequiredDialog === true && <PremiumRequiredDialog />}
      </AnimatePresence>
    </Layout>
  );
}

InsightsPage.Actions = styled.div`
  display: flex;
  padding-left: 30px;

  > :not(:last-child) {
    margin-right: 20px;
  }
`;

InsightsPage.MessageWrapper = styled.section`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`;

InsightsPage.Grid = {};
InsightsPage.Grid.Root = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${su(2)} ${su(3)};
`;
InsightsPage.Grid.Item = styled.section`
  margin: ${su(4)} 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default InsightsPage;
