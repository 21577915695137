import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

export class AppStore extends BaseApiStore {
  static key = 'apps';
  static store = this.createStore(this.key);
  static appCacheId = 'HomeyAPI.ManagerApps.App';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:apps');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerApps = state.api.apps;

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 0));
      const appsPromise = managerApps.getApps();

      const [, appsData] = await Promise.all([waitPromise, appsPromise]);

      const result = Object.values(appsData).reduce(
        (accumulator, app) => {
          const appReference = { ...app };

          accumulator.data[app.id] = app;
          accumulator.byId[app.id] = appReference;

          return accumulator;
        },
        {
          data: {},
          byId: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        data: result.data,
        byId: result.byId,
        loading: false,
        manager: managerApps,
      });

      managerApps.addListener('app.create', this.handleCreate);
      managerApps.addListener('app.update', this.handleUpdate);
      managerApps.addListener('app.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:apps');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerApps = state.api.apps;

      managerApps.removeListener('app.create', this.handleCreate);
      managerApps.removeListener('app.update', this.handleUpdate);
      managerApps.removeListener('app.delete', this.handleDelete);
    }
  }

  static handleCreate = (createdApp) => {
    __DEV__ && console.info(`create:app:${createdApp.id}`);
    const state = this.get();

    const appInstance = state.api.apps._caches[this.appCacheId].getOne({
      id: createdApp.id,
    });

    if (appInstance == null) return;

    this.set({
      data: {
        ...state.data,
        [createdApp.id]: appInstance,
      },
      byId: {
        ...state.byId,
        [createdApp.id]: { ...appInstance },
      },
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedApp) => {
    __DEV__ && console.info(`update:app:${updatedApp.id}`);
    this.updateQueue[updatedApp.id] = {
      ...updatedApp,
    };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedApps = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextById = {
          ...state.byId,
          ...queuedApps,
        };

        this.set({
          byId: nextById,
        });
      }, 200);
    }
  };

  static handleDelete = (deletedApp) => {
    __DEV__ && console.info(`delete:app:${deletedApp.id}`);
    const state = this.get();
    const appInstance = state.data[deletedApp.id];

    if (appInstance == null) return;

    if (this.updateQueue[deletedApp.id]) {
      delete this.updateQueue[deletedApp.id];
    }

    const nextData = { ...state.data };
    const nextById = { ...state.byId };

    delete nextData[appInstance.id];
    delete nextById[appInstance.id];

    this.set({
      data: nextData,
      byId: nextById,
    });
  };
}
