import React, { useEffect, useState } from 'react';

import { publicUrl } from '../../theme/functions/publicUrl';

export function IndicatorSpeaker(props) {
  const capability = props.capabilities?.speaker_playing;

  const [isPlaying, setIsPlaying] = useState(capability?.value ?? false);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value }) => {
        setIsPlaying(value);
      });

      return function () {
        unregister();
      };
    }
  }, [capability]);

  if (capability == null) {
    return null;
  }

  return (
    <img
      alt="speaker playing gif"
      src={publicUrl('/img/indicators/equalizer.gif')}
      style={{
        width: 24,
        height: 24,
        opacity: isPlaying ? 1 : 0,
        transition: 'opacity 500ms ease-in-out',
      }}
    />
  );
}
