import { useMemo } from 'react';
import styled from '@emotion/styled';

import { scrollbarDark } from '../../../theme/elements/scrollbars';
import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Overlay } from '../../overlay/Overlay';
import { ListBox } from '../../list-box/ListBox';
import { Option } from './Option';
import { OptionDescriptive } from './OptionDescriptive';
import { OptionZone } from './OptionZone';

export function ListBoxOverlay(props) {
  const OptionComponent = useMemo(() => {
    switch (props.styleOption) {
      case 'descriptive':
        return OptionDescriptive;
      case 'optionZone':
        return OptionZone;
      default:
        return Option;
    }
  }, [props.styleOption]);

  const width = props.targetRef.current?.getBoundingClientRect().width ?? 'auto';

  function renderItem() {
    return <OptionComponent />;
  }

  // Note that if you are adding an exit animation here with animationRemainProps all restore focus
  // behavior will become broken for Select's that are rendered inside another overlay.
  return (
    <Overlay
      targetRef={props.targetRef}
      overlayProps={{}}
      overlayTriggerState={props.selectState}
      offset={5}
      autoFocus={true}
      restoreFocus={true}
      anchorPointer={false}
    >
      <ListBoxOverlay.ListBox
        {...props.menuProps}
        style={{ width, ...props.overlayStyle }}
        autoFocus={props.selectState.focusStrategy || true}
        disallowEmptySelection={true}
        state={props.selectState}
        renderItem={renderItem}
      />
    </Overlay>
  );
}

ListBoxOverlay.ListBox = styled(ListBox)`
  ${scrollbarDark};
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 420px;
  padding: ${su(1)} 0;
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  overflow-y: auto;
`;
