import { forwardRef, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useButton } from 'react-aria';

import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';
import { mergeRefs } from '../../../lib/mergeRefs';

import { useCapabilities, useDevice } from '../../../store/devices/useDevices';
import { useQuickAction } from '../../../hooks/capabilities/useQuickAction';
import { useApi } from '../../../store/HomeyStore';

import { theme } from '../../../theme/theme';
import { themeClass } from '../../../theme/classes/colors';

import { Icon } from '../../../components/common/Icon';
import { SwitchButton } from '../../../components/common/SwitchButton';
import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../../components/common/context-menu/ContextMenuButton';
import { Battery } from '../../../components/common/Battery';

import { DeviceNameField } from '../../../components/device/DeviceNameField';
import { DeviceIcon } from '../../../components/device/DeviceIcon';
import { DeviceContextMenuContent } from '../../../components/device/DeviceContextMenuContent';
import { PremiumLockedDeviceRow } from '../../../components/premium/PremiumLockedDeviceRow';
import { Table } from './Table';

import { iconWarning } from '../../../theme/icons/interface/warning/warning';

const empty = <span className={themeClass.color.light}>-</span>;

export function DeviceRow(props) {
  const { api } = useApi();
  const { device } = useDevice({ deviceId: props.deviceId });

  const deviceName = device?.name.replace(/\s/g, ' ');
  const [name, setName] = useState(deviceName);
  const [isRenaming, setIsRenaming] = useState(false);

  const { capabilities } = useCapabilities({ deviceId: props.deviceId });
  const { value, onQuickAction, quickActionTitle } = useQuickAction({
    device: device,
    capabilities,
  });

  function renameDevice(event) {
    if (event.currentTarget.value.length === 0) return;

    const prevName = name;
    const nextName = event.currentTarget.value.replace(/\s/g, ' ');

    setName(nextName);
    setIsRenaming(false);

    if (prevName !== nextName) {
      api.devices
        .updateDevice({
          id: device.id,
          device: {
            name: nextName,
          },
        })
        .then(() => {})
        .catch((error) => {
          setName(prevName);
          ToastManager.handleError(error);
        });
    }
  }

  function getRename() {
    return (
      <DeviceNameField
        type="input"
        defaultValue={device.name.replace(/\s/g, ' ')}
        onBlur={(event) => {
          renameDevice(event);
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Enter':
              event.preventDefault();
              break;
            default:
              break;
          }
        }}
        onKeyUp={(event) => {
          switch (event.key) {
            case 'Enter':
              renameDevice(event);

              break;
            case 'Escape':
              setIsRenaming(false);
              break;
            default:
              break;
          }
        }}
      />
    );
  }

  if (device == null) {
    return null;
  }

  const isLocked = device.requiresPremium && api?.tier !== 'premium';

  return (
    <ContextMenu
      content={
        <DeviceContextMenuContent
          deviceId={props.deviceId}
          context={null}
          quickActionTitle={quickActionTitle}
          isLocked={isLocked}
          onQuickActionRequest={onQuickAction}
          onRenameRequest={() => {
            setTimeout(() => {
              setIsRenaming(true);
            }, 0);
          }}
        />
      }
    >
      {({ isOpen, onContextMenu }) => {
        return (
          <RowButton
            isSelected={props.isSelected}
            data-is-locked={isLocked}
            onPress={(event) => {
              if (isLocked) {
                RouteManager.toPremiumRequiredDialog();
                return;
              }

              if (event.altKey) {
                onQuickAction();
                return;
              }

              props.onControlsClick?.(event, device);
            }}
            onContextMenu={onContextMenu}
          >
            <Table.td style={{ position: 'relative' }}>
              {isLocked && <PremiumLockedDeviceRow zIndex={2} />}

              <DeviceIcon
                iconObj={device.iconObj}
                iconOverride={device.iconOverride}
                size={theme.icon.size_default}
                color={theme.color.mono_700}
              />
            </Table.td>

            <Table.td>{isRenaming ? getRename() : name}</Table.td>

            <Table.td>{props.deviceProps.zoneName ?? empty}</Table.td>

            <Table.td>{props.deviceProps.class}</Table.td>

            <Table.td>{props.deviceProps.app !== null ? props.deviceProps.app : empty}</Table.td>

            <Table.td>
              {props.deviceProps.available === false || props.deviceProps.ready === false ? (
                <Icon url={iconWarning} color={theme.color.error} size={theme.icon.size_small} />
              ) : props.deviceProps.status !== null ? (
                <SwitchButton
                  onPress={() => {
                    onQuickAction();
                  }}
                  isSelected={value ?? props.deviceProps.status}
                />
              ) : (
                empty
              )}
            </Table.td>

            <Table.td>
              {props.deviceProps.batteryStatus ? (
                <>
                  <Battery percentage={props.deviceProps.batteryStatus.percentage} />
                  {props.deviceProps.batteryStatus.text}
                </>
              ) : (
                empty
              )}
            </Table.td>

            <Table.td>
              {props.deviceProps.batteries !== null ? props.deviceProps.batteries : empty}
            </Table.td>

            <Table.td>
              {props.deviceProps.energy !== null ? `${props.deviceProps.energy} W` : empty}
            </Table.td>

            <Table.td />

            <Table.td>
              <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
            </Table.td>
          </RowButton>
        );
      }}
    </ContextMenu>
  );
}

const RowButton = forwardRef(function RowWrapper(props, forwardedRef) {
  const buttonRef = useRef();
  const button = useButton({ ...props, elementType: 'tr' }, buttonRef);

  return (
    <DeviceRow.Root
      {...button.buttonProps}
      ref={mergeRefs([buttonRef, forwardedRef])}
      aria-selected={props.isSelected}
      onContextMenu={props.onContextMenu}
      isLocked={props.isLocked}
    >
      {props.children}
    </DeviceRow.Root>
  );
});

DeviceRow.Root = styled(Table.tr)`
  position: relative;
  outline: none;

  &[data-is-locked='true'] {
    ${Table.td}:not(:first-of-type):not(:last-of-type) {
      opacity: 0.3;
      filter: grayscale(1);
    }

    ${Table.td}:first-of-type {
      & :not(:first-of-type) {
        opacity: 0.3;
        filter: grayscale(1);
      }
    }
  }

  &:hover {
    ${ContextMenuButton.Root} {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    background-color: ${theme.color.background_hover};
    color: ${theme.color.text};
  }

  &[aria-selected='true'] {
    background-color: ${theme.color.highlight_background};
    --color: ${theme.color.text};

    td:nth-of-type(2) {
      color: ${theme.color.highlight};
      font-weight: ${theme.fontWeight.medium};
    }
  }
`;
