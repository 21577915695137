import React, { useEffect, useState } from 'react';

import { Battery } from '../common/Battery';

export function IndicatorBattery(props) {
  const capability = props.capabilities[props.capabilityId];
  const [value, setValue] = useState(capability?.value);

  useEffect(() => {
    if (capability != null) {
      const unregister = capability.onChange(({ value }) => {
        setValue(value);
      });

      setValue(capability.value);

      return function () {
        unregister();
      };
    }
  }, [capability]);

  if (capability?.type === 'boolean') {
    return <Battery percentage={value === true ? 10 : 100} />;
  }

  return <Battery percentage={value} />;
}
