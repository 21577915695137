import styled from '@emotion/styled';

import { Icon } from '../components/common/Icon';

import { theme } from '../theme/theme';
import { publicUrl } from '../theme/functions/publicUrl';

import googlePlayIcon from './google-play.svg';
import appStoreIcon from './app-store.svg';
import { iconArrowLeft } from '../theme/icons/interface/arrow-left';

export function MobilePage() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: '#F6F7FB',
        paddingTop: 40,
      }}
    >
      <img
        style={{
          maxWidth: '100%',
          marginRight: 0,
          maxHeight: '50%',
          marginBottom: 30,
        }}
        src={publicUrl('/img/tablet-phone.png')}
        alt="tablet-phone"
      />

      <HomeyLogoWrapper>
        <HomeyLogo src={publicUrl('/img/logo.png')} alt="homey" />
      </HomeyLogoWrapper>
      <h3
        style={{
          textAlign: 'center',
          color: '#292929',
          fontSize: 28,
          fontWeight: '700',
          lineHeight: '36px',
          marginBottom: 30,
        }}
      >
        Download the Homey app for iOS or Android
      </h3>
      <div
        style={{
          display: 'flex',
          marginBottom: 30,
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=app.homey">
          <img
            style={{ height: 40, marginRight: 20 }}
            alt="Get it on Google Play"
            src={googlePlayIcon}
          />
        </a>
        <a href="https://itunes.apple.com/nl/app/homey/id1435800024?mt=8">
          <img style={{ height: 40 }} alt="Get it on the App Store" src={appStoreIcon} />
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ContinueButton
          onClick={() => {
            localStorage.setItem('continueDesktopVersion', true);
            window.location.reload();
          }}
        >
          Continue to the web version instead <ArrowIcon url={iconArrowLeft} color="#aaaaaa" />
        </ContinueButton>
      </div>
    </div>
  );
}

const HomeyLogoWrapper = styled.div`
  border-radius: 50%;
  padding: 10px;
  margin-top: 0;
  background-color: ${theme.color.body};
  max-width: 25%;
`;

const HomeyLogo = styled.img`
  max-width: 100%;
  width: 60px;
  height: 60px;
`;

const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  color: #aaaaaa;
  font-family: inherit;
  font-size: ${theme.fontSize.small};
  font-weight: 400;
  line-height: 20px;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

const ArrowIcon = styled(Icon)`
  transform: rotate(180deg) translateY(0px);
`;
