import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import getScrollParent from 'dom-helpers/scrollParent';

import { enqueueTask } from '../../../lib/enqueueTask';
import { clamp } from '../../../lib/math';

import { useEffectEvent } from '../../../hooks/useEffectEvent';
import { useCurrentProps } from '../../../hooks/useCurrentProps';

import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { NodeArgumentsContext } from './card/NodeArgumentsContext';

import { AdvancedFlowCard } from './card/AdvancedFlowCard';
import { DelayCard } from './card/DelayCard';
import { StartCard } from './card/StartCard';
import { AllCard } from './card/AllCard';
import { AnyCard } from './card/AnyCard';
import { NoteCard } from './card/NoteCard';

export function useAdvancedFlowCursorCard(props, ref) {
  const rootRef = useRef();

  const setCardPosition = useEffectEvent((clientX, clientY) => {
    const scrollParent = getScrollParent(ref.current, true);
    const scrollParentRect = scrollParent.getBoundingClientRect();
    const rect = ref.current.getBoundingClientRect();

    if (clientX > rect.right - 400) {
      props.expandWidth(400);
    }

    if (clientY > rect.bottom - 400) {
      props.expandHeight(400);
    }

    const style = getComputedStyle(ref.current);

    const paddingLeft = parseFloat(style.paddingLeft);
    const paddingRight = parseFloat(style.paddingRight);
    const paddingTop = parseFloat(style.paddingTop);
    const paddingBottom = parseFloat(style.paddingBottom);

    const top = rect.top;
    const left = rect.left;

    const { width } = rootRef.current.getBoundingClientRect();

    // Prevent to drag the card outside of current visible area.
    const clampedClientX = clamp(
      clientX,
      scrollParentRect.left + paddingLeft + width / 2,
      scrollParentRect.right - paddingRight
    );
    const clampedClientY = clamp(
      clientY,
      scrollParentRect.top + paddingTop + 20,
      scrollParentRect.bottom - paddingBottom
    );

    const x = clampedClientX - left - width / 2;
    const y = clampedClientY - top - 20;

    const element = rootRef.current;

    if (element == null) return;

    element.style.transform = `translate(${x}px, ${y}px)`;
  });

  const currentProps = useCurrentProps({
    isPlacementDisabled: props.isPlacementDisabled,
    getPointerPosition: props.getPointerPosition,
  });

  useEffect(() => {
    if (props.cardCursorData != null) {
      const pointerPosition = currentProps.getPointerPosition();
      const { clientX, clientY } = pointerPosition ?? { clientX: 0, clientY: 0 };
      setCardPosition(clientX, clientY);

      // blur 'add' button
      enqueueTask(() => {
        document.activeElement.blur();
      });

      function onKeyDown(event) {
        if (event.key === 'Escape') {
          event.preventDefault();
          event.stopPropagation();

          AdvancedFlowViewStore.set({
            cardCursorData: null,
          });
        }
      }

      /**
       * When pressed outside cancel the cursor card. We need to include the scroll container
       * because otherwise a scroll bar press would also cause cancellation.
       */
      function pointerDownCapture(event) {
        if (currentProps.isPlacementDisabled === true) {
          return;
        }

        const target = ref.current;
        const scrollParent = getScrollParent(target, true);

        if (scrollParent.contains(event.target) === false) {
          AdvancedFlowViewStore.set({
            cardCursorData: null,
          });
        }
      }

      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('pointerdown', pointerDownCapture, { capture: true });

      return function () {
        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('pointerdown', pointerDownCapture, { capture: true });
      };
    }
  }, [props.cardCursorData, ref, currentProps, setCardPosition]);

  function handlePointerMove(event) {
    event.preventDefault();
    event.stopPropagation();

    setCardPosition(event.clientX, event.clientY);
  }

  function handlePointerLeave(event) {
    event.preventDefault();
    event.stopPropagation();

    setCardPosition(event.clientX, event.clientY);
  }

  function handlePointerDown(event) {
    // If not left button.
    if (event.button !== 0) return;

    event.preventDefault();
    event.stopPropagation();

    const style = getComputedStyle(ref.current);

    const paddingLeft = parseFloat(style.paddingLeft);
    // const paddingRight = parseFloat(style.paddingRight);
    const paddingTop = parseFloat(style.paddingTop);
    // const paddingBottom = parseFloat(style.paddingBottom);

    const rect = ref.current.getBoundingClientRect();
    const top = rect.top + paddingTop;
    const left = rect.left + paddingLeft;

    const { width } = rootRef.current.getBoundingClientRect();

    const x = event.clientX - left - width / 2;
    const y = event.clientY - top - 20;

    const snappedX = x - (x % 20);
    const snappedY = y - (y % 20);

    let removeCardCursorData = true;

    if (event.ctrlKey || event.metaKey) {
      removeCardCursorData = false;
    }

    AdvancedFlowViewStore.addCard({
      cardData: {
        ...props.cardCursorData.cardData,
        x: snappedX < 0 ? 0 : snappedX,
        y: snappedY < 0 ? 0 : snappedY,
      },
      removeCardCursorData: removeCardCursorData,
      shouldSelect: false,
    });
  }

  function handlePointerUp(event) {
    // If right button.
    if (event.button === 2) {
      event.preventDefault();
      event.stopPropagation();

      // Next tick because else the context menu appears.
      enqueueTask(() => {
        AdvancedFlowViewStore.set({
          cardCursorData: null,
        });
      });
    }
  }

  // TODO
  // Should work towards a more static version of the cards.
  function getContent() {
    switch (props.cardCursorData.cardData.type) {
      case 'delay':
        return (
          <DelayCard
            data={props.cardCursorData.cardData}
            activeConnectors={new Set()}
            nodeParents={{}}
            nodeChildren={{}}
            isInteractionDisabled={true}
          />
        );
      case 'start':
        return (
          <StartCard
            data={props.cardCursorData.cardData}
            activeConnectors={new Set()}
            nodeParents={{}}
            nodeChildren={{}}
            isInteractionDisabled={true}
          />
        );
      case 'all':
        return (
          <AllCard
            data={props.cardCursorData.cardData}
            activeConnectors={new Set()}
            nodeParents={{}}
            nodeChildren={{}}
            isInteractionDisabled={true}
          />
        );
      case 'any':
        return (
          <AnyCard
            data={props.cardCursorData.cardData}
            activeConnectors={new Set()}
            nodeParents={{}}
            nodeChildren={{}}
            isInteractionDisabled={true}
          />
        );
      case 'note':
        return (
          <NoteCard
            data={props.cardCursorData.cardData}
            activeConnectors={new Set()}
            nodeParents={{}}
            nodeChildren={{}}
            isInteractionDisabled={true}
          />
        );
      default:
        return (
          <NodeArgumentsContext>
            <AdvancedFlowCard
              data={props.cardCursorData.cardData}
              activeConnectors={new Set()}
              nodeParents={{}}
              nodeChildren={{}}
              isInteractionDisabled={true}
            />
          </NodeArgumentsContext>
        );
    }
  }

  return {
    containerProps:
      props.cardCursorData != null
        ? {
            onPointerMove(event) {
              handlePointerMove(event);
            },
            onPointerLeave(event) {
              handlePointerLeave(event);
            },
            onPointerDown(event) {
              handlePointerDown(event);
            },
            onPointerUp(event) {
              handlePointerUp(event);
            },
          }
        : {},
    component: props.cardCursorData != null && (
      <useAdvancedFlowCursorCard.Root ref={rootRef}>{getContent()}</useAdvancedFlowCursorCard.Root>
    ),
  };
}

useAdvancedFlowCursorCard.Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
`;
