import React from 'react';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';
import { SpinnerLoading } from '../common/SpinnerLoading';
import { Indicator } from './Indicator';

import { iconWarning } from '../../theme/icons/interface/warning/warning';

export function getIndicator({ device, capabilities, tileSize }) {
  if (device == null) return null;

  switch (true) {
    case device.ready === null:
      return <SpinnerLoading size={24} />;
    case device.available === false || device.ready === false:
      return (
        <Icon
          display="block"
          url={iconWarning}
          color={theme.color.error}
          size={theme.icon.size_default}
        />
      );
    default:
      return <Indicator device={device} capabilities={capabilities} tileSize={tileSize} />;
  }
}
