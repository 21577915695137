import { iconTimeline } from '../../theme/icons/capabilities/timeline';
import { iconMusic } from '../../theme/icons/capabilities/music';
import { iconThermostat } from '../../theme/icons/capabilities/thermostat';
import { iconBattery } from '../../theme/icons/capabilities/battery';
import { iconColor } from '../../theme/icons/capabilities/color';
import { iconPicker } from '../../theme/icons/capabilities/picker';
import { iconTernary } from '../../theme/icons/capabilities/ternary';
import { iconSensor } from '../../theme/icons/capabilities/sensor';
import { iconToggle } from '../../theme/icons/capabilities/toggle';
import { iconSlider } from '../../theme/icons/capabilities/slider';
import { iconButton } from '../../theme/icons/capabilities/button';
import { iconCamera } from '../../theme/icons/capabilities/camera';
import { iconFlows } from '../../theme/icons/capabilities/flows';

export function getControlComponentImage(componentId) {
  switch (componentId) {
    case 'button':
      return iconButton;
    case 'slider':
      return iconSlider;
    case 'toggle':
      return iconToggle;
    case 'sensor':
      return iconSensor;
    case 'ternary':
      return iconTernary;
    case 'picker':
      return iconPicker;
    case 'color':
      return iconColor;
    case 'battery':
      return iconBattery;
    case 'thermostat':
      return iconThermostat;
    case 'media':
      return iconMusic;
    case 'camera':
      return iconCamera;
    case 'timeline':
      return iconTimeline;
    case 'flows':
      return iconFlows;
    default:
      return null;
  }
}
