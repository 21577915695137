import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useButton, useInteractOutside } from 'react-aria';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useFlowCards } from '../../../store/flow-cards/useFlowCards';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { scrollbarDark } from '../../../theme/elements/scrollbars';

import { SearchField } from '../../../components/common/search-field/SearchField';
import { StaticFlowCard } from '../flow-card/StaticFlowCard';

export function FlowCardExplorerDialogSearch(props) {
  const searchFieldRef = useRef();
  const searchFieldWrapperRef = useRef();

  const { i18n } = useI18n();

  const [filterValue, setFilterValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useInteractOutside({
    ref: searchFieldWrapperRef,
    isDisabled: isOpen === false,
    onInteractOutside(event) {
      setIsOpen(false);
      searchFieldRef.current.blur();
    },
  });

  const flowCards = useFlowCards();
  const storeCards = flowCards[`${props.cardType}s`];

  const cards = useMemo(() => {
    return Object.entries(storeCards.byKey ?? {}).reduce((accumulator, [cardKey, card]) => {
      if (props.cardFilter.call(null, card) === false) {
        return accumulator;
      }

      if (filterValue == null || filterValue.length === 0) {
        accumulator[cardKey] = card;
        return accumulator;
      }

      const formattedFilterValue = filterValue.toLowerCase();
      const formattedCardTitle = card.title?.toLowerCase() ?? '';
      const formattedCardTitleFormatted = card.titleFormatted?.toLowerCase() ?? '';

      const formattedCardUriName =
        card.ownerName?.toLowerCase() ?? card.uriObj?.name?.toLowerCase() ?? '';

      if (
        formattedCardTitle?.includes(formattedFilterValue) ||
        formattedCardTitleFormatted?.includes(formattedFilterValue) ||
        formattedCardUriName?.includes(formattedFilterValue)
      ) {
        accumulator[cardKey] = card;
        return accumulator;
      }

      return accumulator;
    }, {});
  }, [storeCards, filterValue, props.cardFilter]);

  function mapCards() {
    return Object.entries(cards ?? {}).map(([cardKey, card]) => {
      return (
        <CardButton key={cardKey} onPress={(event) => props.onCardSelect(cardKey, card)}>
          <StaticFlowCard card={card} />
        </CardButton>
      );
    });
  }

  return (
    <FlowCardExplorerDialogSearch.SearchFieldWrapper ref={searchFieldWrapperRef}>
      <FlowCardExplorerDialogSearch.SearchField
        ref={searchFieldRef}
        excludeFromTabOrder // This should really be a combobox and not some shitty auto open overlay on focus.
        aria-label="Search cards"
        placeholder={`${i18n.messageFormatter('common.search')}...`}
        backgroundColor={theme.color.component}
        borderRadius={isOpen ? '10px 10px 0 0' : theme.input.border_radius}
        isOpen={isOpen}
        onChange={(value) => {
          isOpen === false && setIsOpen(true);
          setFilterValue(value);
        }}
        onClear={() => {
          setFilterValue('');
        }}
        onFocus={() => {
          setIsOpen(true);
        }}
        onKeyDown={(event) => {
          // If it was empty and escape it pressed propagate event so that
          // overlays can be closed with keyboard.
          if (event.key === 'Escape' && filterValue === '') {
            event.continuePropagation();
          }
        }}
      >
        {isOpen && (
          <FlowCardExplorerDialogSearch.CardContainer>
            {mapCards()}
          </FlowCardExplorerDialogSearch.CardContainer>
        )}
      </FlowCardExplorerDialogSearch.SearchField>
    </FlowCardExplorerDialogSearch.SearchFieldWrapper>
  );
}

FlowCardExplorerDialogSearch.SearchFieldWrapper = styled.div`
  position: relative;
  flex: 0 1 400px;
  align-self: stretch;
`;

FlowCardExplorerDialogSearch.SearchField = styled(SearchField)`
  z-index: 10000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border: 1px solid ${theme.input.border};
  border-radius: ${theme.input.border_radius};
  background-color: ${theme.color.component};
  box-shadow: ${(props) => (props.isOpen ? theme.boxShadow.default : 0)};
  transition: all ${theme.duration.fast} ease-in-out;

  &:hover {
    border: 1px solid ${theme.input.border};
    box-shadow: ${theme.input.boxShadow_hover};
  }

  ${SearchField.Input} {
    border-color: transparent;

    &:hover,
    &:focus {
      box-shadow: none !important;
      border-color: transparent !important;
    }
  }
`;

FlowCardExplorerDialogSearch.CardContainer = styled.div`
  ${scrollbarDark};

  display: flex;
  flex-direction: column;
  align-items: center;

  max-height: 60vh;
  padding: ${su(3)} 0;

  border-top: 1px solid ${theme.color.line};

  overflow-y: auto;

  ${StaticFlowCard.Root}:not(:first-of-type) {
    margin-top: 10px;
  }
`;

function CardButton(props) {
  const buttonRef = useRef();
  const button = useButton(props, buttonRef);

  return (
    <CardButton.Root {...button.buttonProps} ref={buttonRef} data-is-pressed={button.isPressed}>
      {props.children}
    </CardButton.Root>
  );
}

CardButton.Root = styled.div`
  outline: 0;
  transition: transform ${theme.duration.fast} ${theme.curve.fastIn};

  &:not(:last-of-type) {
    padding-bottom: ${su(1)};
  }

  &[data-is-pressed='true'] {
    transform: scale(0.97);
  }
`;
