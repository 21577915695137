import { useRef } from 'react';
import styled from '@emotion/styled';

import { useOption, useFocusRing, mergeProps } from 'react-aria';

import { theme } from '../../../theme/theme';

export function ResolutionSelectOption({ item, selectState }) {
  const optionRef = useRef();

  const isDisabled = selectState.disabledKeys.has(item.key);
  const isSelected = selectState.selectionManager.isSelected(item.key);

  const option = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
    },
    selectState,
    optionRef
  );

  const focus = useFocusRing();

  return (
    <ResolutionSelectOption.Root
      {...mergeProps(option.optionProps, focus.focusProps)}
      ref={optionRef}
      title={item.rendered}
      isSelected={isSelected}
      data-is-selected={isSelected}
      data-is-focus-visible={focus.isFocusVisible}
    >
      {item.rendered}
    </ResolutionSelectOption.Root>
  );
}

ResolutionSelectOption.Root = styled.li`
  outline: 0;
  font-size: ${theme.fontSize.small};
  line-height: 2;
  cursor: pointer;

  &[data-is-selected='true'],
  &[data-is-focus-visible='true'] {
    font-weight: ${theme.fontWeight.bold};
  }

  &:hover {
    font-weight: ${theme.fontWeight.bold};
  }

  &:before {
    display: block;
    content: attr(title);
    font-weight: ${theme.fontWeight.bold};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;
