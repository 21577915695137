import React from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing } from 'react-aria';

import { theme } from '../../../theme/theme';

import { useOptionContext } from '../../../components/list-box/ListBox';
import { HomeyIcon } from '../../../components/common/HomeyIcon';
import { Icon } from '../../../components/common/Icon';

export function DeviceSettingsGeneralIconSelectOption() {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  return (
    <S.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      {value.textValue === 'default' ? (
        <HomeyIcon
          iconObj={value.icon}
          size="40px"
          color={option.isSelected ? theme.color.icon_blue : theme.color.icon_dark}
        />
      ) : (
        <S.Icon
          url={value.icon}
          size="40px"
          color={option.isSelected ? theme.color.icon_blue : theme.color.icon_dark}
        />
      )}
    </S.Root>
  );
}

function S() {}
DeviceSettingsGeneralIconSelectOption.S = S;

S.Root = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 48px;
  height: 48px;
  border-radius: ${theme.borderRadius.default};
  border: 2px solid transparent;
  outline: 0;
  cursor: pointer;
  transition: ${theme.duration.micro} ${theme.curve.easeInOut};
  transition-property: background-color;

  &[data-is-focus-visible='true'],
  &:hover {
    background-color: ${theme.color.background_hover};
  }

  &[data-is-selected='true'] {
    ${S.Icon} {
      background-color: ${theme.color.highlight};
    }

    background-color: ${theme.color.highlight_background};
    border-color: ${theme.color.highlight};
  }
`;
S.Icon = styled(Icon)`
  flex: 1 1 0;
`;
