export class LogicUtils {
  static getDefaultTypeValue(type) {
    switch (type) {
      case 'string':
        return '';
      case 'number':
        return 0;
      case 'boolean':
        return false;
      default:
        throw new Error(`invalid_variable_type: ${type}`);
    }
  }

  static parseValue(type, value) {
    switch (type) {
      case 'string':
        return value;
      case 'number':
        return Number(value);
      case 'boolean':
        return typeof value === 'string' ? value === 'true' : value;
      default:
        throw new Error(`invalid_variable_type: ${type}`);
    }
  }
}
