import { useRef } from 'react';
import styled from '@emotion/styled';

import { useListState, Item } from 'react-stately';
import { useListBox, useOption } from 'react-aria';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

export function MultiplierPicker(props) {
  // Create state based on the incoming props
  const { i18n } = useI18n();

  const secText = i18n.messageFormatter('flow.argument.timeMultiplier.seconds');
  const minText = i18n.messageFormatter('flow.argument.timeMultiplier.minutes');

  const children = [
    <Item key={1} textValue={secText}>
      {secText}
    </Item>,
    <Item key={60} textValue={minText}>
      {minText}
    </Item>,
  ];

  const sharedProps = {
    children,
    selectionMode: 'single',
    selectedKeys: [String(props.value)],
    'aria-label': props['aria-label'],
    disallowEmptySelection: true,
    shouldFocusWrap: true,
    onSelectionChange(selectedKeys) {
      props.onChange(parseInt([...selectedKeys][0]), 10);
    },
  };

  // TODO
  // Keyboard navigation should be left to right not up to down.
  const listBoxRef = useRef();
  const listState = useListState({ ...sharedProps });
  const listBox = useListBox({ ...sharedProps }, listState, listBoxRef);

  return (
    <MultiplierPicker.List {...listBox.listBoxProps} ref={listBoxRef} className={props.className}>
      {[...listState.collection].map((item) => (
        <Option key={item.key} item={item} state={listState} />
      ))}
    </MultiplierPicker.List>
  );
}

function Option({ item, state }) {
  const optionRef = useRef();
  // const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = state.selectionManager.isSelected(item.key);

  const option = useOption(
    {
      key: item.key,
    },
    state,
    optionRef
  );

  return (
    <MultiplierPicker.ListItem
      {...option.optionProps}
      ref={optionRef}
      data-is-selected={isSelected}
    >
      {item.rendered}
    </MultiplierPicker.ListItem>
  );
}

MultiplierPicker.List = styled.ul`
  display: flex;
  outline: 0;
`;

MultiplierPicker.ListItem = styled.li`
  display: flex;
  align-items: center;

  outline: 0;
  cursor: pointer;
  padding: 5px;
  border-radius: ${theme.borderRadius.small};

  &[data-is-selected='true'] {
    font-weight: ${theme.fontWeight.bold};
  }

  &:focus-visible,
  &:hover {
    background-color: ${theme.color.background_hover};
  }
`;
