import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';
import { isMACOS } from '../../lib/platform';

import { theme } from '../../theme/theme';
import { when } from '../../theme/functions/when';
import { animationFade } from '../../theme/animations/animationFade';

import { Icon } from '../common/Icon';
import { DeviceIcon } from './DeviceIcon';
import { DeviceCapabilityList } from './DeviceCapabilityList';
import { PremiumLocked } from '../premium/PremiumLocked';

import { iconWarning } from '../../theme/icons/interface/warning/warning';

export const DeviceTile = forwardRef(function (props, forwardedRef) {
  const ref = useRef();
  const instanceRef = useRef({
    time: null,
  });
  const button = useButton(
    {
      onPressStart(event) {
        // console.log('onPressStart');
        instanceRef.current.time = Date.now();
      },
      onPress(event) {
        if (event.ctrlKey && isMACOS === true) return;

        // console.log('onPress');
        // const ms = Date.now() - (instanceRef.current.time ?? 0);

        // if (ms > 300 && props.onLongPress != null) {
        //   props.onLongPress(event);
        // } else {
        props.onPress?.(event);
        // }
      },
      onPressEnd(event) {
        // console.log('onPressEnd');
      },
      onPressUp(event) {
        // console.log('onPressUp');
      },
    },
    ref
  );

  return (
    <DeviceTile.Root
      {...(props.isRenaming !== true && button.buttonProps)}
      ref={mergeRefs([forwardedRef, ref])}
      value={props.value}
      title={props.isUnknown ? props.deviceId : props.title}
      isActive={props.value}
      isDragging={props.isDragging}
      isDragPreview={props.isDragPreview}
      isSelected={props.isSelected}
      isNoInteraction={props.isNoInteraction || props.isUnknown}
      isUnknown={props.isUnknown}
      isLocked={props.isLocked}
      tileSize={props.tileSize}
      onContextMenu={props.onContextMenu}
    >
      {props.isLocked && <PremiumLocked zIndex={2} />}

      <DeviceTile.Border isUnknown={props.isUnknown}>
        {props.isUnknown !== true ? (
          <DeviceTile.ShiftLock>
            <DeviceTile.Header>
              <DeviceTile.TopContainer>
                <DeviceIcon
                  iconObj={props.iconObj}
                  iconUrl={props.iconUrl}
                  iconOverride={props.iconOverride}
                  size={props.tileSize === DeviceTile.size.large ? '32px' : '40px'}
                />
              </DeviceTile.TopContainer>

              <DeviceTile.BottomContainer>
                {props.children ? (
                  props.children
                ) : (
                  <DeviceTile.Name title={props.name}>{props.name}</DeviceTile.Name>
                )}
              </DeviceTile.BottomContainer>
            </DeviceTile.Header>

            <DeviceTile.Ruler>
              {props.indicator && <DeviceTile.Indicator>{props.indicator}</DeviceTile.Indicator>}
            </DeviceTile.Ruler>

            {props.tileSize === DeviceTile.size.large && props.capabilityList}
          </DeviceTile.ShiftLock>
        ) : (
          <Icon size={theme.icon.size_large} color={theme.icon.color_medium} url={iconWarning} />
        )}
      </DeviceTile.Border>
    </DeviceTile.Root>
  );
});

DeviceTile.size = {
  medium: 1,
  large: 2,
};

/**
 * Shiftlock's main purpose is to improve animation when you switch between normal and large device tiles.
 */
DeviceTile.ShiftLock = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  display: flex;
  flex: 1 1 auto;
  width: var(--tile-size);
  height: var(--tile-size);
  padding: 10px;
  flex-direction: column;
  border-radius: ${theme.borderRadius.default};
  transition: filter ${theme.duration.fast} ease-in-out;
`;

/**
 * Border
 * @description important div to make certain the active blue works together with different tile sizes
 * and the effects when switching between them. Also gives enough space to scrollable area's in large
 * device tiles so they scrollarea is until the edge of the tile
 */
DeviceTile.Border = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid transparent;
  transition: border ${theme.duration.fast} ease-in-out;
  border-radius: ${theme.borderRadius.default};

  ${when((props) => props.isUnknown === true)} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

DeviceTile.Header = styled.header`
  display: flex;
  flex-direction: column;
`;

DeviceTile.Ruler = styled.div``;

DeviceTile.Indicator = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

DeviceTile.TopContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
`;

DeviceTile.BottomContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: flex-end;
  justify-content: flex-start;
  min-height: 48px;
  padding: 10px;
  border-radius: 0 0 12px 12px;
`;

DeviceTile.Name = styled.div`
  max-height: 36px;
  overflow: hidden;
  font-size: ${theme.fontSize.small};
  line-height: 1.286;
  text-align: left;
  text-overflow: ellipsis;
  word-break: normal;
  white-space: pre-line;
  hyphens: auto;
`;

const fadeIn = css`
  opacity: 0;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;
`;

DeviceTile.Root = styled.div`
  --tile-size: ${theme.device.size_medium};
  --transform-transition: transform ease-in-out ${theme.duration.fast};
  --default-transition: width ease-in-out ${theme.duration.slow},
    height ease-in-out ${theme.duration.slow}, border ease-in-out ${theme.duration.fast},
    var(--transform-transition);

  display: flex;
  position: relative;
  appearance: none;
  flex-direction: column;
  align-items: normal;
  width: var(--tile-size);
  height: var(--tile-size);
  padding: 10px;
  will-change: transform;
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  background-color: ${theme.color.component_on};
  box-shadow: ${theme.boxShadow.default};
  cursor: pointer;

  &:hover {
    transform: scale(1.05);

    &:active:not(:focus-within) {
      transform: scale(1);
    }
  }

  transition: var(--default-transition);
  overflow: hidden;

  ${when((props) => props.isSelected === true)} {
    --indicator-background-color: ${theme.color.highlight_component_on};
    background-color: ${theme.color.highlight_component_on};

    ${DeviceTile.Border} {
      border-color: ${theme.color.highlight};
    }
  }

  ${when((props) => props.isActive === false && props.isLocked !== true)} {
    --indicator-background-color: ${theme.color.component_off};
    background-color: ${theme.color.component_off};

    ${DeviceTile.ShiftLock} {
      opacity: 0.7;
      filter: grayscale(1);
    }
  }

  ${when((props) => props.isDragPreview === true)} {
    transform: scale(0.95);
    animation: ${keyframes`
      from {
        transform: scale(1);
      }

      to {
        transform: scale(0.95);
      }
    `} ${theme.duration.slow} ease-out forwards 1;
  }

  ${when((props) => props.isDragging === true)} {
    opacity: 0;
  }

  ${when((props) => props.isNoInteraction === true)} {
    cursor: default;

    &:hover {
      transform: initial;

      &:active:not(:focus-within) {
        transform: initial;
      }
    }
  }

  ${when((props) => props.isUnknown === true)} {
    background-color: rgba(221, 222, 226, 0.5);
    background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgb(151, 151, 151)' stroke-width='4' stroke-dasharray='10, 10' stroke-dashoffset='10' stroke-linecap='square'/%3E%3C/svg%3E");
  }

  ${when((props) => props.isLocked === true)} {
    --indicator-background-color: ${theme.color.component_off};
    background-color: ${theme.color.component_off};

    ${DeviceTile.ShiftLock} {
      opacity: 0.7;
      filter: grayscale(1);
    }

    ${DeviceTile.Indicator} {
      opacity: 0;
    }
  }

  ${when((props) => props.tileSize === DeviceTile.size.large)} {
    --tile-size: ${theme.device.size_large};

    ${DeviceTile.ShiftLock} {
      align-items: stretch;
    }

    ${DeviceTile.Header} {
      flex-direction: row;
      align-items: center;
      height: 40px;
      margin-bottom: 10px;
    }

    ${DeviceTile.Ruler} {
      position: relative;
      z-index: 1;

      ${when((props) => props.isDragPreview !== true)} {
        ${fadeIn};
      }

      &::before {
        position: absolute;
        content: '';
        z-index: 0;
        top: 50%;
        right: 0;
        left: 0;
        height: 1px;
        background: ${theme.color.component_line};
      }
    }

    ${DeviceTile.Indicator} {
      right: 0;
      top: 50%;
      // todo make scale nice in combination with indicator size
      transform: translateY(-50%) scale(0.666667);
      transform-origin: right center;
      background: var(--indicator-background-color, ${theme.color.component_on});
      padding-left: 10px;
    }

    ${DeviceTile.TopContainer} {
      flex: 0;
    }

    ${DeviceTile.BottomContainer} {
      flex-direction: row;
      position: relative;
      flex: 1 1 auto;
      padding: 0;
      margin-left: 10px;
      min-height: 52px;
      display: flex;
      align-items: center;
    }

    ${DeviceTile.Name} {
      font-weight: ${theme.fontWeight.medium};
      font-size: ${theme.fontSize.default};
      line-height: 22px;
      max-height: 62px;
      padding: 4px 0;
      margin: -4px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      ${when((props) => props.isDragPreview !== true)} {
        ${fadeIn};
      }
    }

    &:hover {
      ${DeviceCapabilityList.Root} {
        margin-bottom: -11px;
      }
    }
  }
`;
