import { proxy as palette } from "../color.palette";
import { proxy as functional } from "../color.functional";
import { proxy as borderRadius } from "../border-radius";

const color = {...palette, ...functional};

/**
 * Input
 */
export const input = {
  //sizes
  height_default:         `30px`,
  height_medium:          `40px`,
  height_large:           `50px`,
  //border
  border:                  color.line,
  border_hover:            color.mono_150,
  border_selected:         color.highlight,
  border_focus:            color.blue_o_50,
  border_error:            color.red_o_50,
  border_disabled:         color.mono_300,
  //background
  background:              color.component,
  background_selected:     color.highlight,
  //boxShadow
  boxShadow_hover:         `0 0 3px 0px rgba(0, 0, 0, .1)`,
  boxShadow_focus:         `0 0 3px 0px rgba(0, 130, 250, .5)`,
  boxShadow_error:         `0 0 3px 0px rgba(216, 28, 29, .5)`,
  //borderRadius
  border_radius:            borderRadius.small,
  //search
  search_border:           `transparent`,
  search_background:       color.body,
};
