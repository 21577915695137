import { useMemo } from 'react';
import styled from '@emotion/styled';

import { useI18n } from '../../hooks/useI18nFormatters';

import { su } from '../../theme/functions/su';
import { scrollbars } from '../../theme/elements/scrollbars';

import { NotificationsListItem } from './NotificationsListItem';

export function Notifications(props) {
  const { i18n } = useI18n();

  const notificationsList = useMemo(() => {
    if (props.notificationsById == null) return [];

    return Object.values(props.notificationsById).sort((a, b) => {
      return new Date(b.dateCreated) - new Date(a.dateCreated);
    });
  }, [props.notificationsById]);

  if (props.me != null && notificationsList.length > 0) {
    return (
      <S.Root>
        {notificationsList.map((notification) => {
          return (
            <NotificationsListItem
              key={notification.id}
              notification={notification}
              onRequestDelete={props.onRequestDelete}
            />
          );
        })}
      </S.Root>
    );
  }

  return <S.Empty>{i18n.messageFormatter('notifications.noNotifications')}</S.Empty>;
}

function S() {}
Notifications.S = S;

S.Root = styled.div`
  ${scrollbars.dark}
  height: 460px;
  padding: ${su(1)} 0;
  overflow-y: auto;
`;

S.Empty = styled.div`
  padding: ${su(1, 2)};
`;
