import React from 'react';
import styled from '@emotion/styled';

import { scrollbar } from '../../../theme/elements/scrollbars';

import { Sensor } from './Sensor';
import * as Containers from '../Containers';

export function SensorGroup({ device, component, capabilities }) {
  if (capabilities == null) return null;

  return (
    <Containers.Control>
      <Containers.Select />
      <Containers.Action>
        <SensorGroupContainer>
          {component?.capabilities?.map((capabilityId) => {
            return (
              <Sensor key={capabilityId} device={device} capability={capabilities[capabilityId]} />
            );
          })}
        </SensorGroupContainer>
      </Containers.Action>
    </Containers.Control>
  );
}

const SensorGroupContainer = styled.div`
  ${scrollbar};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
`;
