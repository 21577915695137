import React from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../ToastManager';

import { useI18n } from '../../hooks/useI18nFormatters';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { IconButton } from '../buttons/IconButton';

import { iconSquareOnSquareRegular } from '../../theme/icons/interface/square-on-square-regular/square-on-square-regular';

interface Props {
  value?: string;
  message?: string;
  autoFocus?: boolean;
  keyedAutoFocus?: string;
  styleColor?: 'blue' | 'light';
  styleTextAlign?: 'center' | 'left' | 'right';
}

export function Clipboard(props: Props) {
  const { i18n } = useI18n();

  const iconButtonColor = (() => {
    if (props.styleColor === 'light') {
      return theme.color.icon_light;
    }

    return theme.color.blue_500;
  })();

  return (
    <S.Root data-style-color={props.styleColor} data-style-text-align={props.styleTextAlign}>
      <S.Input
        style={{ '--clipboard-text-align': props.styleTextAlign }}
        data-style-color={props.styleColor}
        value={props.value}
        readOnly={true}
      />

      <IconButton
        autoFocus={props.autoFocus}
        keyedAutoFocus={props.keyedAutoFocus}
        title={i18n.messageFormatter('common.copy')}
        url={iconSquareOnSquareRegular}
        color={iconButtonColor}
        onPress={() => {
          if (props.value == null) return;

          navigator.clipboard
            .writeText(props.value)
            .then(() => {
              // @ts-expect-error
              ToastManager.add({
                icon: iconSquareOnSquareRegular,
                message: props.message ?? i18n.messageFormatter('common.copied'),
              });
            })
            .catch(ToastManager.handleError);
        }}
      />
    </S.Root>
  );
}

function S() {}
Clipboard.S = S;
Clipboard.IconButton = IconButton;

S.Root = styled.div`
  position: relative;

  ${IconButton.S.Root} {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-style-text-align='right'] ${IconButton.S.Root} {
    right: 0;
  }
`;

interface CSSProperties extends React.CSSProperties {
  '--clipboard-text-align'?: string;
}

S.Input = styled.input<{ style: CSSProperties }>`
  width: 100%;
  height: 40px;
  padding: ${su(1)} ${su(3)};
  background-color: ${theme.color.blue_050};
  color: ${theme.color.blue_500};
  border: 1px solid ${theme.color.blue_500};
  border-radius: ${theme.borderRadius.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: var(--clipboard-text-align, center);

  &:focus {
    text-overflow: clip;
  }

  &[data-style-color='light'] {
    background-color: unset;
    color: ${theme.color.text_light};
    border: 0;
    border-radius: 0;
  }
`;
