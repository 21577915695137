import { useEffect } from 'react';
import { Prompt, useLocation } from 'react-router-dom';

import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { useI18n } from '../../../hooks/useI18nFormatters';

export function useAdvancedFlowUnsavedChangesPrompt() {
  const { i18n } = useI18n();
  const location = useLocation();

  function handleNavigateAway(nextLocation, action) {
    if (
      `${nextLocation.pathname}${nextLocation.search}` === `${location.pathname}${location.search}`
    ) {
      return true;
    }

    if (nextLocation.state?.skipUnsavedChangesCheck === true) {
      return true;
    }

    const { advancedFlow, initialAdvancedFlow } = AdvancedFlowViewStore.get();
    const hasUnsavedChanges = advancedFlow !== initialAdvancedFlow;

    if (hasUnsavedChanges === true) {
      return i18n.messageFormatter('flow.unsavedChanges');
    }

    return true;
  }

  useEffect(() => {
    function listener(event) {
      const { advancedFlow, initialAdvancedFlow } = AdvancedFlowViewStore.get();
      const hasUnsavedChanges = advancedFlow !== initialAdvancedFlow;

      if (hasUnsavedChanges === true) {
        // Cancel the event
        event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        event.returnValue = '';
      }
    }

    window.addEventListener('beforeunload', listener);

    return function () {
      window.removeEventListener('beforeunload', listener);
    };
  }, []);

  return { prompt: <Prompt message={handleNavigateAway} /> };
}
