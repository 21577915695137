import semver from 'semver';
import { __DEV__ } from '../../lib/__dev__';

import { BaseApiStore } from '../../store/BaseApiStore';
import { ToastManager } from '../../ToastManager';
import { RouteManager } from '../../RouteManager';

import { VersionError } from '../../lib/InternalError';

import { iconPage } from '../../theme/icons/interface/page';

export class ScriptStore extends BaseApiStore {
  static key = 'scripts';
  static store = this.createStore(this.key);

  static createInitialState() {
    return {
      appApiVersion: null,
      appInstance: null,
      app: null,
      byId: null,
      logs: null,
      result: null,
      loading: true,
      installing: false,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:scripts');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    await this.runFetch(state);
  }

  static async runFetch(state) {
    try {
      const managerApps = state.api.apps;
      const satisfiesHomeyVersion = semver.satisfies(state.api.homeyVersion, '>=5.0.0');

      if (satisfiesHomeyVersion === false) {
        throw new VersionError.Homey('invalid_homey_version');
      }

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 0));
      const appPromise = await managerApps.getApp({
        id: 'com.athom.homeyscript',
        $skipCache: true,
      });

      const [, appInstance] = await Promise.all([waitPromise, appPromise]);

      const satisfiesAppVersion = semver.satisfies(appInstance.version, '>=3.1.0');

      if (satisfiesAppVersion === false) {
        throw new VersionError.App('invalid_app_version');
      }

      let appApiVersion = 1;

      const isAppApiVersion2 = semver.satisfies(appInstance.version, '>=3.3.0');

      if (isAppApiVersion2 === true) {
        appApiVersion = 2;
      }

      const scripts =
        appInstance.state === 'running'
          ? await appInstance.apiGet('script', {}).catch((error) => {
              ToastManager.handleError(error);
            })
          : appApiVersion >= 2
          ? []
          : {};

      const byId = {};

      if (typeof scripts === 'object' && scripts !== null && Array.isArray(scripts) === false) {
        const scriptValues = Object.values(scripts);

        for (const script of scriptValues) {
          byId[script.id] = script;
        }
      } else {
        for (const scriptId of scripts) {
          byId[scriptId] = {
            id: scriptId,
            name: scriptId,
          };
        }
      }

      this.set({
        ...this.createInitialState(),
        appApiVersion: appApiVersion,
        appInstance: appInstance,
        app: { ...appInstance },
        byId: byId,
        logs: [],
        result: null,
        loading: false,
        manager: managerApps,
      });

      this.attachListeners(appInstance);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static attachListeners(appInstance) {
    appInstance.addListener('$update', this.handleUpdate);
    appInstance.addListener('$delete', this.handleDelete);
    appInstance.addListener('log', this.handleLog);
  }

  static destroy() {
    __DEV__ && console.info('destroy:scripts');
    const state = this.get();
    const appInstance = state.appInstance;

    if (appInstance != null) {
      appInstance.removeListener('$update', this.handleUpdate);
      appInstance.removeListener('$delete', this.handleDelete);
      appInstance.removeListener('log', this.handleLog);
    }
  }

  static handleUpdate = (updatedApp) => {
    __DEV__ && console.info(`update:app:${updatedApp.id}`);
    const state = this.get();

    this.set({
      appInstance: state.appInstance,
      app: { ...state.appInstance },
    });
  };

  static handleDelete = () => {
    __DEV__ && console.info(`delete:app:com.athom.homeyscript`);
    this.fetchData().catch(console.error);
  };

  static handleLog = ({ script, text }) => {
    const state = this.get();
    const match = RouteManager.getPathMatch(RouteManager.matchPath.script);
    const encodedScriptId = match?.params?.scriptId;
    const scriptId = encodedScriptId != null ? decodeURIComponent(encodedScriptId) : null;

    if (scriptId != null && scriptId === script) {
      try {
        const json = JSON.parse(text);
        console.log(json);
      } catch (error) {
        console.log(text);
      }

      this.set({
        logs: [...(state.logs ?? []), text],
      });
    }
  };

  /**
   * todo: on:node-homey-api
   * Add a grace period after script internal timeout
   */
  static async _call(state, method, endpoint, queryParameters, body) {
    const basePath = `/api/app/com.athom.homeyscript/`;
    return await state.appInstance._parent._call(method, endpoint, {
      opts: { $basePath: basePath, $timeout: 40000 },
      body,
      queryParameters,
    });
  }

  static async getScript({ id }) {
    const state = this.get();

    try {
      // Includes code and lastExecuted.
      const script = await state.appInstance.apiGet(`script/${encodeURIComponent(id)}`, {});

      const nextById = { ...state.byId };
      nextById[id] = {
        ...nextById[id],
        code: '',
        ...script,
      };

      this.set({
        byId: nextById,
        logs: [],
        result: null,
      });

      return script;
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static async testScript({ id, script }) {
    const state = this.get();

    try {
      this.set({
        logs: [],
        result: null,
      });

      const result = await this._call(
        state,
        'POST',
        `script/${encodeURIComponent(id)}/run`,
        {},
        { code: script.code }
      );

      this.set({
        result: result,
      });
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static async startScript({ id }) {
    const state = this.get();

    try {
      // eslint-disable-next-line no-unused-vars
      const result = await this._call(state, 'POST', `script/${encodeURIComponent(id)}/run`);

      // ToastManager.add({
      //   message: `Success: ${result?.success.toString()} returns: ${
      //     result?.returns
      //   }`,
      // });
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static async createScript({ script }) {
    const state = this.get();

    if (
      Object.values(state.byId ?? {}).some((existingScript) => {
        return existingScript.id === script.id;
      })
    ) {
      ToastManager.addError({
        i18n: {
          key: 'script.alreadyExists',
          props: { name: script.id },
        },
      });
      return;
    }

    try {
      let result = null;

      if (state.appApiVersion >= 2) {
        result = await state.appInstance.apiPost(
          `script`,
          {
            name: script.name,
            code: script.code,
          },
          {}
        );
      } else {
        result = await state.appInstance.apiPut(
          `script/${encodeURIComponent(script.id)}`,
          {
            name: script.name,
            code: script.code,
          },
          {}
        );
      }

      const newScript = result ?? script;

      ToastManager.add({
        icon: iconPage,
        i18n: {
          key: 'script.createdMessage',
          props: { name: newScript.name },
        },
      });

      this.set((prevState) => {
        return {
          byId: {
            ...prevState.byId,
            [newScript.id]: newScript,
          },
        };
      });

      // Fallback as script for backwards compatibility.
      return result ?? script;
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static async updateScript({ id, script }) {
    const state = this.get();
    const currentScript = state.byId?.[id];

    try {
      await state.appInstance.apiPut(
        `script/${encodeURIComponent(id)}`,
        {
          name: script.name,
          code: script.code,
        },
        {}
      );

      this.set((prevState) => {
        return {
          byId: {
            ...prevState.byId,
            [id]: {
              ...prevState.byId[id],
              ...script,
            },
          },
        };
      });

      ToastManager.add({
        icon: iconPage,
        i18n: {
          key: 'script.savedMessage',
          props: { name: currentScript?.name },
        },
      });
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static async deleteScript({ id }) {
    const state = this.get();

    try {
      await state.appInstance.apiDelete(`script/${encodeURIComponent(id)}`, {});

      const nextById = { ...state.byId };
      const script = nextById[id];
      delete nextById[id];

      this.set({
        byId: nextById,
      });

      ToastManager.add({
        icon: iconPage,
        i18n: {
          key: 'script.deletedMessage',
          props: { name: script.name },
        },
      });
    } catch (error) {
      ToastManager.handleError(error);
    }
  }

  static install() {
    const state = this.get();

    function listener(createdApp) {
      if (createdApp.id === 'com.athom.homeyscript') {
        state.api.apps.removeListener('app.create', listener);

        setTimeout(async () => {
          const state = ScriptStore.get();
          await ScriptStore.runFetch(state).catch(ToastManager.handleError);
        }, 10000);
      }
    }

    this.set({
      installing: true,
    });
    state.api.apps.addListener('app.create', listener);

    state.api.apps
      .installFromAppStore({
        id: 'com.athom.homeyscript',
      })
      .then((result) => {})
      .catch((error) => {
        this.set({
          installing: false,
        });
        ToastManager.handleError(error);
      });
  }
}
