import React, { Fragment, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useButton } from 'react-aria';

import { useApp } from '../../../store/apps/useApps';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { RouterLink } from '../../common/link/RouterLink';
import { Time } from '../../common/Time';
import { Icon } from '../../common/Icon';
import { HomeyIcon } from '../../common/HomeyIcon';

import { iconFlow } from '../../../theme/icons/system/flow/flow';
import { iconWeb } from '../../../theme/icons/interface/platform/web';
import { iconMobile } from '../../../theme/icons/interface/platform/mobile';
import { iconDevtools } from '../../../theme/icons/interface/platform/dev-tools';
import { iconGoogleAssistant } from '../../../theme/icons/interface/platform/google-assistant';
import { iconIFTTT } from '../../../theme/icons/interface/platform/ifttt';
import { iconAmazonAlexa } from '../../../theme/icons/interface/platform/amazon-alexa';
import { iconCloudFill } from '../../../theme/icons/interface/cloud-fill';
import { iconMoods } from '../../../theme/icons-v2/system/moods/moods';

export function TimelineItem(props) {
  const { dateFormatter, messageFormatter } = props;
  const [isDetailView, setIsDetailView] = useState(false);

  const appId = props.entry.originUri?.substring('homey:app:'.length);
  const { app } = useApp({ appId: appId });

  const ref = useRef();
  const button = useButton({
    elementType: 'li',
    onPress(event) {
      setIsDetailView((prevState) => !prevState);

      if (event.ctrlKey && event.shiftKey) {
        console.log(props.entry);
      }
    },
  });

  const [component, icon, iconObj] = useMemo(() => {
    let via = null;

    switch (props.entry.originClientId) {
      // Web App
      case '5f03044d9492e57edb845770':
        via = 'Web';
        break;
      // Mobile App
      case '56d8494cf8ea8fcd7952e711':
        via = 'Mobile';
        break;
      // Devtools
      case '598d85a330e1bb0c0d75b8eb':
        via = props.entry.originClientName;
        break;
      // Google
      case '5bd988c9d6006d2798d1be2d':
        via = 'Google';
        break;
      // IFTTT
      case '577a8e246fec375410c93d0d':
        via = 'IFTTT';
        break;
      // Alexa
      case '5a465ea60632742a96253154':
        via = 'Alexa';
        break;
      default:
        break;
    }

    if (props.entry.originUri?.startsWith('homey:mood:')) {
      // const id = props.entry.originUri.replace('homey:mood:', '');

      return [
        <Fragment>
          {messageFormatter('common.by')}{' '}
          <TimelineItem.Origin>
            <Icon
              url={iconMoods}
              color={theme.color.text_white_o_50}
              size={theme.icon.size_small}
            />
            <span>{props.entry.originName}</span>
          </TimelineItem.Origin>
          {via != null && ` ${messageFormatter('common.via')} ${via}`}
        </Fragment>,
        iconMoods,
      ];
    }

    if (props.entry.originUri?.startsWith('homey:flow:')) {
      const id = props.entry.originUri.replace('homey:flow:', '');

      return [
        <Fragment>
          {messageFormatter('common.by')}{' '}
          <TimelineItem.OriginLink to={`/flows/${id}`}>
            <Icon url={iconFlow} color={theme.color.text_white_o_50} size={theme.icon.size_small} />
            <span>{props.entry.originName}</span>
          </TimelineItem.OriginLink>
          {via != null && ` ${messageFormatter('common.via')} ${via}`}
        </Fragment>,
        iconFlow,
      ];
    }

    if (props.entry.originUri?.startsWith('homey:advancedflow:')) {
      const id = props.entry.originUri.replace('homey:advancedflow:', '');

      return [
        <Fragment>
          {messageFormatter('common.by')}{' '}
          <TimelineItem.OriginLink to={`/flows/advanced/${id}`}>
            <Icon url={iconFlow} color={theme.color.text_white_o_50} size={theme.icon.size_small} />
            <span>{props.entry.originName}</span>
          </TimelineItem.OriginLink>
          {via != null && ` ${messageFormatter('common.via')} ${via}`}
        </Fragment>,
        iconFlow,
      ];
    }

    if (props.entry.originUri?.startsWith('homey:app')) {
      // const id = props.entry.originUri.replace('homey:app:', '');

      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originName}</strong>
        </Fragment>,
        null,
        app?.iconObj,
      ];
    }

    if (props.entry.originClientId === '5f03044d9492e57edb845770') {
      // props.entry.originUserId
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} Web
        </Fragment>,
        iconWeb,
      ];
    }

    if (props.entry.originClientId === '56d8494cf8ea8fcd7952e711') {
      // props.entry.originUserId
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} Mobile
        </Fragment>,
        iconMobile,
      ];
    }

    if (props.entry.originClientId === '598d85a330e1bb0c0d75b8eb') {
      // props.entry.originUserId
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} {props.entry.originClientName}
        </Fragment>,
        iconDevtools,
      ];
    }

    if (props.entry.originClientId === '5bd988c9d6006d2798d1be2d') {
      // props.entry.originUserId
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} Google
        </Fragment>,
        iconGoogleAssistant,
      ];
    }

    if (props.entry.originClientId === '577a8e246fec375410c93d0d') {
      // props.entry.originUserId
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} IFTTT
        </Fragment>,
        iconIFTTT,
      ];
    }

    if (props.entry.originClientId === '5a465ea60632742a96253154') {
      // props.entry.originUserId
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} Alexa
        </Fragment>,
        iconAmazonAlexa,
      ];
    }

    if (props.entry.originClientName != null && props.entry.originUserName != null) {
      return [
        <Fragment>
          {messageFormatter('common.by')} <strong>{props.entry.originUserName}</strong>{' '}
          {messageFormatter('common.via')} {props.entry.originClientName}
        </Fragment>,
        iconCloudFill,
      ];
    }

    if (props.entry.originClientName != null) {
      return [`${messageFormatter('common.via')} ${props.entry.originClientName}`, iconCloudFill];
    }

    if (props.entry.originName != null) {
      return [`${messageFormatter('common.by')} ${props.entry.originName}`];
    }

    return [];
  }, [props.entry, app, messageFormatter]);

  function formatDate(date) {
    if (isDetailView) {
      return dateFormatter.format(date, 'MMM dd yyyy HH:mm:ss');
    }

    return dateFormatter.formatDistanceToNowStrict(date, {
      addSuffix: true,
    });
  }

  function renderTime(date) {
    return <span>{formatDate(date)}</span>;
  }

  function getIcon() {
    switch (true) {
      case iconObj != null:
        return (
          <TimelineItem.IconWrapper>
            <HomeyIcon iconObj={iconObj} size={theme.icon.size_default} color={theme.color.white} />
          </TimelineItem.IconWrapper>
        );
      case icon != null:
        return (
          <TimelineItem.IconWrapper>
            <Icon url={icon} size={theme.icon.size_default} color={theme.color.white} />
          </TimelineItem.IconWrapper>
        );
      default:
        return <TimelineItem.Point />;
    }
  }

  return (
    <TimelineItem.Root
      {...button.buttonProps}
      ref={ref}
      // title={JSON.stringify(props.entry)}
    >
      {getIcon()}
      <TimelineItem.Details>
        <TimelineItem.Title>{props.entry.title}</TimelineItem.Title>
        <TimelineItem.Subtitle>
          <Time render={renderTime} date={props.entry.date} /> {component}
        </TimelineItem.Subtitle>
      </TimelineItem.Details>
    </TimelineItem.Root>
  );
}

TimelineItem.Point = styled.div`
  min-width: 41px;
  align-self: stretch;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${theme.color.white};
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    position: absolute;
    height: 200%;
    width: 1px;
    background: ${theme.color.white};
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

TimelineItem.IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 41px;
  height: 41px;
  border: 1px solid ${theme.color.white};
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: calc(50%);
    width: 1px;
    background: ${theme.color.white};
    left: 19px;
  }

  &::before {
    bottom: calc(50% + 20px);
  }

  &::after {
    top: calc(50% + 20px);
  }
`;

TimelineItem.Details = styled.div`
  flex: 1 1 auto;
  padding-left: 10px;
`;

TimelineItem.Title = styled.div`
  color: ${theme.color.text_white};
`;

TimelineItem.Subtitle = styled.div`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.text_white_o_50};
`;

TimelineItem.OriginLink = styled(RouterLink)`
  color: ${theme.color.text_white_o_50};
  margin-left: 2px;

  ${Icon.S.Root} {
    margin-right: 5px;
    vertical-align: middle;
    transform: translateY(-2px);
  }

  &:hover {
    color: ${theme.color.text_white};

    ${Icon.S.Root} {
      background-color: ${theme.color.text_white};
    }
  }
`;

TimelineItem.Origin = styled.span`
  color: ${theme.color.text_white_o_50};
  margin-left: 2px;

  ${Icon.S.Root} {
    margin-right: 5px;
    vertical-align: middle;
    transform: translateY(-2px);
  }
`;

TimelineItem.Root = styled.li`
  display: flex;
  align-items: center;
  padding: ${su(1, 2)};
  cursor: pointer;
  outline: 0;

  // don't show timeline above first item
  &:first-of-type {
    ${TimelineItem.Point} {
      &::after {
        height: 100%;
        top: 100%;
      }
    }

    ${TimelineItem.IconWrapper} {
      &::before {
        display: none;
      }
    }
  }

  // don't show timeline below last item
  &:last-of-type {
    ${TimelineItem.Point} {
      &::after {
        height: 100%;
        top: 0;
      }
    }

    ${TimelineItem.IconWrapper} {
      &::after {
        display: none;
      }
    }
  }
`;
