import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSliderState } from 'react-stately';
import styled from '@emotion/styled';

import {
  mergeProps,
  useFocusRing,
  useNumberFormatter,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';

export const LedringSettingsBrightnessSlider = forwardRef((props, forwardedRef) => {
  const { i18n } = useI18n();

  const trackRef = useRef(null);
  const numberFormatter = useNumberFormatter(props.formatOptions);
  const state = useSliderState({ ...props, numberFormatter });
  const slider = useSlider(props, state, trackRef);

  if (PointerEvent === undefined) {
    delete slider.trackProps.onPointerDown;
  }

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        set value(value) {
          state.setThumbValue(0, value);
        },
      };
    },
    [state]
  );

  return (
    <S.Root
      {...slider.groupProps}
      style={{
        '--slider-left': `${(1 - state.getThumbPercent(0)) * 100}%`,
      }}
    >
      <S.Track {...slider.trackProps} ref={trackRef}>
        <Thumb index={0} state={state} trackRef={trackRef} />
      </S.Track>

      <S.Labels>
        <div>{i18n.messageFormatter('settings.system.ledRingBrightnessSliderOffLabel')}</div>
        <div>{i18n.messageFormatter('settings.system.ledRingBrightnessSliderMediumLabel')}</div>
        <div>{i18n.messageFormatter('settings.system.ledRingBrightnessSliderHighLabel')}</div>
      </S.Labels>
    </S.Root>
  );
});

function Thumb(props) {
  const inputRef = useRef(null);
  const sliderThumb = useSliderThumb(
    {
      index: props.index,
      trackRef: props.trackRef,
      inputRef,
    },
    props.state
  );

  const focusRing = useFocusRing();
  return (
    <S.Thumb
      {...sliderThumb.thumbProps}
      style={{
        ...sliderThumb.thumbProps.style,
      }}
      data-is-dragging={sliderThumb.isDragging}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <VisuallyHidden>
        <input ref={inputRef} {...mergeProps(sliderThumb.inputProps, focusRing.focusProps)} />
      </VisuallyHidden>
    </S.Thumb>
  );
}

function S() {}
LedringSettingsBrightnessSlider.S = S;

S.Root = styled.div`
  position: relative;
`;

S.Track = styled.div`
  --track-height: 20px;
  height: var(--track-height);

  &::before {
    content: attr(x);
    display: block;
    position: absolute;
    background: ${theme.color.mono_100};
    height: 6px;
    width: 100%;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    z-index: 0;
  }

  &::after {
    content: attr(x);
    display: block;
    position: absolute;
    background: linear-gradient(45deg, #367cff 0%, #52deff 100%);
    height: 6px;
    left: 0;
    top: 50%;
    right: var(--slider-left);
    border-radius: 3px;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

S.Thumb = styled.div`
  width: 20px;
  height: var(--track-height);
  border-radius: 50%;
  background: ${theme.color.mono_000};
  top: 50%;
  box-shadow: ${theme.boxShadow.default};
  z-index: 2;

  &[data-is-dragging='true'] {
    background: ${theme.color.mono_020};
    transform: scale(2);
  }

  &[data-is-focus-visible='true'] {
    outline: 2px solid ${theme.color.highlight};
  }
`;

S.Labels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 13px;

  div {
    font-size: ${theme.fontSize.small};
    color: ${theme.color.text_light};
  }
`;
