// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
function toSolidBytes(match, p1) {
  return String.fromCharCode('0x' + p1);
}

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return window.btoa(window.encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, toSolidBytes));
}
