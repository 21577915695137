import { useEffect, useMemo, useState } from 'react';
import orderby from 'lodash.orderby';
import HomeyLib from 'homey-lib/webpack/index.js';
import { useLocale } from 'react-aria';

import { round } from '../../../lib/math';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useDevicesById } from '../../../store/devices/useDevices';
import { useZonesTree } from '../../../store/zones/useZones';
import { useAppsById } from '../../../store/apps/useApps';

import { space } from '../../../components/common/space';

import { Table } from './Table';
import { DeviceRow } from './DeviceRow';
import { DeviceColumnHead } from './DeviceColumnHead';

import { order } from './DeviceColumnHead';

const deviceClasses = HomeyLib.Device.getClasses();

const orderType = {
  NAME: 'name',
  ZONENAME: 'zoneName',
  TYPE: 'type',
  ENERGY: 'energy',
  APP: 'app',
  STATUS: 'status',
  BATTERYSTATUS: 'batteryStatus',
  BATTERIES: 'batteries',
};

const orderTypes = {
  [orderType.NAME]: (deviceRow) => deviceRow.name,
  [orderType.ZONENAME]: (deviceRow) => deviceRow.zoneName,
  [orderType.TYPE]: (deviceRow) => deviceRow.class,
  [orderType.ENERGY]: (deviceRow) => deviceRow.energy,
  [orderType.APP]: (deviceRow) => deviceRow.app,
  [orderType.STATUS]: (deviceRow) => deviceRow.status,
  [orderType.BATTERYSTATUS]: (deviceRow) => deviceRow.batteryStatus?.order,
  [orderType.BATTERIES]: (deviceRow) => deviceRow.batteries,
};

export function DevicesTable(props) {
  const { i18n } = useI18n();

  const [orderBy, setOrderBy] = useState(orderType.NAME);
  const [hoverIndex, setHoverIndex] = useState(false);
  const [orderDirection, setOrderDirection] = useState(order.ASC);

  const devices = useDevicesById();
  const zones = useZonesTree();
  const apps = useAppsById();

  const { locale } = useLocale();

  useRefresh({ store: devices, intervalMs: 10000 });

  function sort(type) {
    if (type !== orderBy) {
      setOrderBy(type);
    } else {
      if (orderDirection === order.DESC) {
        setOrderDirection(order.ASC);
      } else {
        setOrderDirection(order.DESC);
      }
    }
  }

  function head(name, sort) {
    return { name: name, sort: sort };
  }

  const devicesTableHeader = [
    false,
    head(i18n.messageFormatter('devicesTable.thead.name'), orderType.NAME),
    head(i18n.messageFormatter('devicesTable.thead.zone'), orderType.ZONENAME),
    head(i18n.messageFormatter('devicesTable.thead.type'), orderType.TYPE),
    head(i18n.messageFormatter('devicesTable.thead.app'), orderType.APP),
    head(i18n.messageFormatter('devicesTable.thead.state'), orderType.STATUS),
    head(i18n.messageFormatter('devicesTable.thead.batteryStatus'), orderType.BATTERYSTATUS),
    head(i18n.messageFormatter('devicesTable.thead.batteries'), orderType.BATTERIES),
    head(i18n.messageFormatter('devicesTable.thead.energy'), orderType.ENERGY),
    false, // Empty spacer column to fill up remaining space.
    false,
  ];

  // Sort and filter data for Devices.
  const devicesRowData = useMemo(() => {
    if (devices.byId == null || zones.byId == null || apps.byId == null) {
      return null;
    }

    // Prefill device rows with correct data.
    const devicesRowData = [];

    for (const [, device] of Object.entries(devices.byId)) {
      const deviceClass = device.virtualClass ?? device.class;
      const deviceRow = {
        id: device.id,
        iconId: device.iconObj?.id,
        name: device.name,
        zone: device.zone,
        zoneName: zones.byId?.[device.zone]?.name,
        class: deviceClasses[deviceClass] ? deviceClasses[deviceClass].title[locale] : deviceClass,
        app: getAppNameByKey(device.driverUri, apps.byId),
        status: getDeviceStatus(device),
        batteryStatus: getBatteryStatus(device),
        batteries: getBatteries(device),
        energy: getEnergyUsage(device),
      };
      devicesRowData.push(deviceRow);
    }

    return devicesRowData;
  }, [devices.byId, apps.byId, zones.byId, locale]);

  const orderedDevices = useMemo(() => {
    if (devicesRowData == null) {
      return null;
    }

    const selectedZoneChildren = zones.tree?.children?.[props.selectedZoneId] ?? new Set();

    return orderby(
      devicesRowData,
      [
        (device) => {
          // Show empty items from given column always at the bottom.
          return device[orderBy] === null;
        },

        orderTypes[orderBy],
      ],
      [order.ASC, orderDirection]
    ).filter((device) => {
      // When Home show all
      if (props.selectedZoneId === null) {
        return true;
      }

      // When a device in the current zone or in zones below current zone.
      return device.zone === props.selectedZoneId || selectedZoneChildren.has(device.zone);
    });
  }, [devicesRowData, orderBy, orderDirection, zones.tree, props.selectedZoneId]);

  return (
    <Table.table>
      <colgroup>
        {devicesTableHeader.map((head, index) => {
          return <Table.col key={index} isHover={index === hoverIndex} />;
        })}
      </colgroup>

      <Table.thead>
        <Table.tr>
          {devicesTableHeader.map((head, index) => {
            // Empty header.
            if (head === false) {
              return <DeviceColumnHead key={index}>{space}</DeviceColumnHead>;
            }

            // Header which is sortable.
            if (head.sort !== false) {
              return (
                <DeviceColumnHead
                  key={index}
                  sortable
                  sort={head.sort}
                  orderDirection={orderDirection}
                  orderBy={orderBy}
                  onClick={() => sort(head.sort)}
                  onMouseOver={() => setHoverIndex(index)}
                  onMouseOut={() => setHoverIndex(false)}
                >
                  {' '}
                  {head.name}
                </DeviceColumnHead>
              );
            }

            // Header with only name.
            return <DeviceColumnHead key={index}>{head.name}</DeviceColumnHead>;
          })}
        </Table.tr>
      </Table.thead>

      <Table.tbody>
        {orderedDevices ? (
          orderedDevices.map((device) => {
            return (
              <DeviceRow
                key={device.id}
                deviceId={device.id}
                deviceProps={device}
                isSelected={props.selectedDeviceId === device.id}
                onControlsClick={props.onControlsClick}
              />
            );
          })
        ) : (
          <Table.tr>
            <Table.td colSpan={devicesTableHeader.length}>
              {i18n.messageFormatter('devicesTable.status.loading')}
            </Table.td>
          </Table.tr>
        )}

        {orderedDevices?.length === 0 && (
          <Table.tr>
            <Table.td colSpan={devicesTableHeader.length}>
              {i18n.messageFormatter('devicesTable.status.noDevices')}
            </Table.td>
          </Table.tr>
        )}
      </Table.tbody>
    </Table.table>
  );
}

function useRefresh({ store, intervalMs }) {
  const refresh = store.refresh;
  useEffect(() => {
    let intervalId = null;

    if (store.loading === false) {
      intervalId = setInterval(() => {
        refresh();
      }, intervalMs);
    }

    return function () {
      clearInterval(intervalId);
    };
  }, [store.loading, refresh, intervalMs]);
}

/**
 * Get Battery Status
 * @param device
 * @returns {Object|null}
 *
 * todo - implement Device.makeCapabilityInstance for live updates
 */
export function getBatteryStatus(device) {
  if (
    !device.energyObj ||
    !device.capabilitiesObj ||
    (!device.energyObj.batteries &&
      !device.capabilitiesObj.alarm_battery &&
      !device.capabilitiesObj.measure_battery)
  ) {
    return null;
  }

  // gray battery settings are default
  let percentage = null;
  let text = '';
  let order = 0;

  // if we know a percentage show that
  if (device.capabilitiesObj.measure_battery) {
    if (device.capabilitiesObj.measure_battery.value) {
      percentage = device.capabilitiesObj.measure_battery.value;
      text = device.capabilitiesObj.measure_battery.value + '%';
      order = device.capabilitiesObj.measure_battery.value;
    }
  }
  // if we know only if there is an alarm battery, show that
  else if (device.capabilitiesObj.alarm_battery) {
    percentage = 10;
    text = 'LOW';
    order = 10;

    if (device.capabilitiesObj.alarm_battery.value === false) {
      percentage = 100;
      text = 'OK';
      order = 100;
    }
  }

  return { percentage: percentage, text: text, order: order };
}

/**
 * Get Batteries
 * @param device
 * @returns {string|null}
 */
export function getBatteries(device) {
  if (!device.energyObj || !device.energyObj.batteries) {
    return null;
  }

  return `${device.energyObj.batteries.length}x ${device.energyObj.batteries[0]}`;
}

/**
 *
 * @param device
 * @returns {JSX.Element|string}
 * todo what happens if we have multiple device statuses
 */
export function getDeviceStatus(device) {
  // Check if device capabilities has one of the required capabilities for turning on/off
  if (device.ui?.quickAction == null) {
    return null;
  }

  const quickAction = device.ui.quickAction;
  const capability = device.capabilitiesObj?.[quickAction];

  if (capability == null) {
    return null;
  }

  if (capability.getable !== true || capability.setable !== true) {
    return null;
  }

  return capability.value ?? true;
}

/**
 * Get App Name By Key
 * @description function to split fullKey and give back the app name
 * @param fullKey
 * @param apps
 * @returns {string|null}
 */
export function getAppNameByKey(fullKey, apps) {
  let key = fullKey.split(':');
  key = key[key.length - 1];

  if (apps == null || apps[key] == null) {
    return null;
  }

  return apps[key].name;
}

/**
 * Get Energy Usage
 * @param device
 * @returns {string|null}
 */
export function getEnergyUsage(device) {
  if (device.energyObj?.W == null) {
    return null;
  }

  return round(device.energyObj.W, 0);
}
