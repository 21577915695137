import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useButton } from '@react-aria/button';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../common/Icon';

import { iconArrowLeft } from '../../../theme/icons/interface/arrow-left';

export function BackButton(props) {
  const ref = useRef();
  const button = useButton(props, ref);

  return (
    <BackButton.Root {...button.buttonProps} ref={ref}>
      <Icon color={theme.icon.color_medium} size={theme.icon.size_small} url={iconArrowLeft} />
      {props.children}
    </BackButton.Root>
  );
}

BackButton.Root = styled.button`
  padding: ${su(1, 0)};
  color: ${theme.color.text_light};

  ${Icon.S.Root} {
    margin-right: ${su(1)};
  }

  &:hover {
    color: ${theme.color.text_light_hover};

    ${Icon.S.Root} {
      background-color: ${theme.icon.color_medium_hover};
    }
  }
`;
