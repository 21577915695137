import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { SCOPES } from '../../../lib/scopes';
import { ToastManager } from '../../../ToastManager';
import { ledringSettingsStore } from './LedringSettingsStore';

import { theme } from '../../../theme/theme';

import { GeneralSettingsSection } from './GeneralSettingsSection';

import { RadioGroup } from '../../../components/forms/radio/RadioGroup';
import { Radio } from '../../../components/forms/radio/Radio';
import { Throbber } from '../../../components/common/Throbber';

import { LedringSettingsBrightnessSlider } from './LedringSettingsBrightnessSlider';

import { iconLedRing } from '../../../theme/icons-v2/system/led-ring/led-ring';

export function LedRingSettings() {
  const { ledringSettings } = ledringSettingsStore.use();

  const { i18n } = useI18n();
  const { api, scopes } = useApi();

  const brightnessSliderRef = useRef(null);
  const [optionScreensaver, setOptionScreensaver] = useState(null);
  const [isOptionScreensaverLocked, setIsOptionScreensaverLocked] = useState(false);
  const [optionBrightness, setOptionBrightness] = useState(null);
  const [isOptionBrightnessLocked, setIsOptionBrightnessLocked] = useState(false);

  // If the data changes in the store, update the state. The data in the store also changes
  // after local sets.
  useEffect(() => {
    setOptionScreensaver(ledringSettings.optionScreensaverData);
  }, [ledringSettings.optionScreensaverData]);
  useEffect(() => {
    setOptionBrightness(ledringSettings.optionBrightnessData);
  }, [ledringSettings.optionBrightnessData]);

  const screensaverValueKey =
    optionScreensaver?.value != null
      ? `${optionScreensaver.value.uri}::${optionScreensaver.value.id}`
      : null;
  const brightnessValue = optionBrightness?.value != null ? optionBrightness.value : null;

  useEffect(() => {
    if (brightnessSliderRef.current != null) {
      brightnessSliderRef.current.value = brightnessValue;
    }
  }, [brightnessValue]);

  if (ledringSettings.isLoading === true) {
    return (
      <S.Root>
        <Throbber size={theme.icon.size_default} />
      </S.Root>
    );
  }

  // TODO
  // the slider doesnt update propertly if it is disabled.

  return (
    <S.Root>
      {api?.homey.platformVersion === 1 && (
        <GeneralSettingsSection.S.Section>
          <GeneralSettingsSection.S.SectionHeading>
            {i18n.messageFormatter('settings.system.ledRingBrightnessTitle')}
          </GeneralSettingsSection.S.SectionHeading>
          <GeneralSettingsSection.S.SectionView>
            <S.Spacer>
              <LedringSettingsBrightnessSlider
                ref={brightnessSliderRef}
                aria-label="Brightness"
                minValue={0}
                maxValue={1}
                step={0.01}
                defaultValue={brightnessValue}
                isDisabled={scopes[SCOPES.SYSTEM] !== true}
                onChangeEnd={(value) => {
                  if (isOptionBrightnessLocked === true) {
                    ToastManager.add({
                      message: 'The previous request is still pending. Please wait...',
                    });
                    return;
                  }

                  const prevValue = brightnessValue;
                  const nextValue = value;
                  setOptionBrightness({ value: nextValue });
                  setIsOptionBrightnessLocked(true);

                  ledringSettingsStore
                    .setOptionBrightness({
                      value,
                    })
                    .then((result) => {
                      // Is updated via the useEffect
                    })
                    .catch((error) => {
                      setOptionBrightness({ value: prevValue });
                      ToastManager.handleError(error);
                    })
                    .finally(() => {
                      setIsOptionBrightnessLocked(false);
                    });
                }}
              />
            </S.Spacer>
          </GeneralSettingsSection.S.SectionView>
        </GeneralSettingsSection.S.Section>
      )}

      <GeneralSettingsSection.S.Section>
        <GeneralSettingsSection.S.SectionHeading>
          {i18n.messageFormatter('settings.system.ledRingScreensaverTitle')}
        </GeneralSettingsSection.S.SectionHeading>
        <GeneralSettingsSection.S.SectionView>
          <RadioGroup
            name="role"
            aria-label="Role"
            value={screensaverValueKey}
            isDisabled={scopes[SCOPES.SYSTEM] !== true}
            onChange={(nextValueKey) => {
              if (isOptionScreensaverLocked === true) {
                ToastManager.add({
                  message: 'The previous request is still pending. Please wait...',
                });
                return;
              }

              const [uri, id] = nextValueKey.split('::');
              const prevValue = screensaverValueKey;
              const nextValue = {
                uri,
                id,
              };
              setOptionScreensaver({ value: nextValue });
              setIsOptionScreensaverLocked(true);

              ledringSettingsStore
                .setOptionScreensaver({
                  value: nextValue,
                })
                .then((result) => {
                  // Is updated via the useEffect
                })
                .catch((error) => {
                  setOptionScreensaver({ value: prevValue });
                  ToastManager.handleError(error);
                })
                .finally(() => {
                  setIsOptionScreensaverLocked(false);
                });
            }}
          >
            {ledringSettings.screensaversData?.map((screensaver) => {
              const id = `${screensaver.uri}::${screensaver.id}`;

              return (
                <Radio
                  key={id}
                  title={screensaver.title}
                  aria-label={screensaver.title}
                  value={id}
                />
              );
            })}
          </RadioGroup>
        </GeneralSettingsSection.S.SectionView>
      </GeneralSettingsSection.S.Section>
    </S.Root>
  );
}

LedRingSettings.pathKey = 'system/led-ring';
LedRingSettings.icon = iconLedRing;
LedRingSettings.backgroundColor = theme.color.system_ledring;

function S() {}
LedRingSettings.S = S;

S.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  flex: 1 0 auto;

  ${Throbber.S.Root} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

S.Spacer = styled.div`
  padding: 20px 0 10px;
`;
