import React from 'react';
import { mergeProps, useFocusRing } from 'react-aria';

import { useZone } from '../../../store/zones/useZones';

import { theme } from '../../../theme/theme';

import { useOptionContext } from '../../list-box/ListBox';
import { ListItem } from '../../list-box/ListItem';

export function OptionZone(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const focusRing = useFocusRing();

  const { zone = {} } = useZone({ zoneId: item.key });

  return (
    <ListItem.Root
      {...mergeProps(option.optionProps, focusRing.focusProps)}
      ref={ref}
      className={props.className}
      style={{ '--indent-level': value.level }}
      as={props.as}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
      data-is-focus-visible={focusRing.isFocusVisible}
    >
      <ListItem.Inner>
        <ListItem.Icon
          url={value.icon}
          size={theme.icon.size_default}
          color={theme.color.icon_dark}
        />

        <ListItem.Name>{zone.name}</ListItem.Name>
      </ListItem.Inner>
    </ListItem.Root>
  );
}
