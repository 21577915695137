import React from 'react';
import styled from '@emotion/styled';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Tooltip } from '../../../components/common/tooltip/Tooltip';
import { Icon } from '../../../components/common/Icon';

import { iconInfo } from '../../../theme/icons/interface/info';

export function FlowCardHint(props) {
  return (
    <Tooltip
      offset={16}
      delay={200}
      anchorPointer={true}
      placement="top"
      isDisabled={props.isDisabled}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <FlowCardHint.Trigger
            {...triggerProps}
            ref={triggerRef}
            className={props.className}
            data-is-disabled={props.isDisabled}
          >
            {props.icon ?? (
              <FlowCardHint.IconWrapper data-is-highlight={props.isHighlightIcon}>
                <Icon
                  url={props.iconUrl ?? iconInfo}
                  size="14px"
                  color={theme.icon.color_light}
                  display="block"
                />
              </FlowCardHint.IconWrapper>
            )}
          </FlowCardHint.Trigger>
        );
      }}
    >
      <FlowCardHint.Text>{props.hint}</FlowCardHint.Text>
    </Tooltip>
  );
}

FlowCardHint.Trigger = styled.div`
  display: block;
  flex: 0 0 auto;

  &[data-is-disabled='true'] {
    cursor: unset;
    pointer-events: none;
  }
`;

FlowCardHint.IconWrapper = styled.div`
  ${Icon.S.Root} {
    transition: background-color ${theme.duration.fast} ${theme.curve.easeInOut};
  }

  &[data-is-highlight='true'] {
    ${Icon.S.Root} {
      background-color: ${theme.color.highlight};
    }
  }

  &:hover {
    ${Icon.S.Root} {
      background-color: ${theme.icon.color_light_hover};
    }
  }
`;

FlowCardHint.Text = styled.div`
  width: 300px;
  padding: ${su(2)};
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
  word-break: break-word;
  // Supports /n in developer provided hints.
  white-space: pre-wrap;
`;
