import { generalSettingsStore } from './GeneralSettingsStore';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { Clipboard } from '../../../components/common/Clipboard';

import { GeneralSettingsSection } from './GeneralSettingsSection';

const STATES = {
  connected: 'common.connected',
  connecting: 'common.connecting',
  disconnected: 'common.disconnected',
  handshaking: 'common.handshaking',
  unknown: 'common.unknown',
};

export function GeneralSettingsInfoCloud(props) {
  const { i18n } = useI18n();
  const { api } = useApi();
  const { generalSettings } = generalSettingsStore.use();

  return (
    <GeneralSettingsSection.S.Section>
      <GeneralSettingsSection.S.SectionHeading>
        {i18n.messageFormatter('settings.system.generalInfoCloudSectionHeading')}
      </GeneralSettingsSection.S.SectionHeading>
      <GeneralSettingsSection.S.SectionView>
        <GeneralSettingsSection.S.Row>
          <GeneralSettingsSection.S.Label>Homey ID</GeneralSettingsSection.S.Label>
          <GeneralSettingsSection.S.Value>
            <Clipboard
              styleTextAlign="right"
              styleColor="light"
              message={i18n.messageFormatter('settings.system.generalInfoCloudHomeyIdCopied', {
                value: generalSettings.systemInfoData?.cloudId,
              })}
              value={generalSettings.systemInfoData?.cloudId}
            />
          </GeneralSettingsSection.S.Value>
        </GeneralSettingsSection.S.Row>

        {api?.isCloud === false && (
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('settings.system.generalInfoCloudConnectionStatus')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {i18n.messageFormatter(
                STATES[generalSettings.cloudStateData?.connection ?? 'unknown']
              )}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
        )}

        {api?.isCloud === true && (
          <GeneralSettingsSection.S.Row>
            <GeneralSettingsSection.S.Label>
              {i18n.messageFormatter('common.region')}
            </GeneralSettingsSection.S.Label>
            <GeneralSettingsSection.S.Value>
              {generalSettings.systemInfoData?.region}
            </GeneralSettingsSection.S.Value>
          </GeneralSettingsSection.S.Row>
        )}
      </GeneralSettingsSection.S.SectionView>
    </GeneralSettingsSection.S.Section>
  );
}
