// @refresh reset
import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import cloneDeep from 'lodash.clonedeep';
import { createEditor, Transforms, Editor } from 'slate';
import { Slate, Editable as SlateEditable, withReact, ReactEditor } from 'slate-react';

import { enqueueTask } from '../../../../lib/enqueueTask';
import { getTokenFlowKey } from '../tokens';

import { useEditorAutoFocus } from './editorHooks';

import { theme } from '../../../../theme/theme';
import { scrollbars } from '../../../../theme/elements/scrollbars';
import { su } from '../../../../theme/functions/su';

import { FlowToken } from '../FlowToken';
import { TokenPicker } from '../../token-picker/TokenPicker';

const TOKEN_TYPES = ['number', 'string', 'boolean', 'error'];

export const EditableInput = forwardRef((props, forwardedRef) => {
  const instanceRef = useRef({
    selection: null,
  });

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const editor = useMemo(() => {
    return buildEditor();
  }, []);

  // for some reason it needs text blocks around a single token
  // else its not possible to edit
  const [value, setValue] = useState([
    {
      type: 'root',
      children: getInitialChildren(props),
    },
  ]);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return buildStoreValue(value);
    },
  }));

  useEditorAutoFocus({ editor, autoFocus: props.autoFocus ?? true });

  function onKeyDown(event) {
    // If we stopPropagation here tokens without spaces between cant be backspace deleted.
    if (event.key !== 'Backspace') {
      event.stopPropagation();
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      props.onSaveRequest();
      event.preventDefault();
    } else if (event.key === 'Escape' && !event.shiftKey) {
      props.onCancelRequest();
    }
  }

  function onKeyUp(event) {
    // If we stopPropagation here tokens without spaces between cant be backspace deleted.
    if (event.key !== 'Backspace') {
      event.stopPropagation();
    }
  }

  function handleTokenSelect(key, { kind, token }) {
    const tokenFlowKey = getTokenFlowKey(key, { kind, token });

    const tokenNode = [
      { text: '' },
      {
        type: 'token',
        chunk: {
          value: tokenFlowKey,
          token: token,
        },
        children: [{ text: '' }],
      },
      { text: '' },
    ];

    Transforms.insertNodes(editor, tokenNode, { at: instanceRef.current.selection });

    // Because focus containment is stopped on a setState call. So first one stops the containment
    // and in the next tick we can focus because the behavior no longer stops focusing outside of
    // the FocusScope.
    enqueueTask(() => {
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, []));
    });
  }

  function handleTokenPickerOpenChange(isOpen) {
    // clone the current selection because the input gets blurred
    if (isOpen) {
      instanceRef.current.selection = cloneDeep(editor.selection);
    }
  }

  return (
    <EditableInput.Root data-layout={props.layout}>
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => {
          // For some reason this fires on initial render / open of popover

          setValue(newValue);
          props.onChange(buildStoreValue(newValue));
        }}
      >
        <EditableInput.Editable
          {...props.fieldProps}
          style={{
            // Ensures lines with no whitespace break at some point.
            overflowWrap: 'anywhere',
          }}
          renderElement={renderElement}
          renderPlaceholder={({ attributes, children }) => {
            // Remove style since we supply our own.
            const { style, ...rest } = attributes;

            return <EditableInput.Placeholder {...rest}>{children}</EditableInput.Placeholder>;
          }}
          placeholder={props.placeholder}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      </Slate>

      <TokenPicker
        triggerIconSize={theme.icon.size_small}
        types={TOKEN_TYPES}
        restoreFocus={false}
        onOpenChange={handleTokenPickerOpenChange}
        onSelect={handleTokenSelect}
      />
    </EditableInput.Root>
  );
});

function Element(props) {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'token':
      return (
        <span {...attributes} contentEditable={false}>
          <FlowToken
            style={{
              // pointerEvents: 'none',
              WebkitUserSelect: 'none',
              lineHeight: theme.flowcard.content_line_height,
            }}
            token={element.chunk.token}
            tokenKey={element.chunk.value}
            isValueTooltipDisabled={true}
            label={element.chunk.token?.title}
          >
            {children}
          </FlowToken>
        </span>
      );
    case 'root':
      return <div {...attributes}>{children}</div>;
    default:
      return <div {...attributes}>{children}</div>;
  }
}

function buildStoreValue(newValue) {
  return newValue.reduce((accumulator, part, index) => {
    if (index > 0) {
      accumulator += '\n';
    }

    part.children.forEach((child) => {
      switch (child.type) {
        case 'token':
          accumulator += `[[${child.chunk.value}]]`;
          break;
        default:
          accumulator += child.text;
          break;
      }
    });
    return accumulator;
  }, '');
}

function getInitialChildren(props) {
  if (props.chunks.length > 0) {
    const result = props.chunks.map((chunk) => {
      if (chunk.type === 'token') {
        return {
          type: 'token',
          chunk: chunk,
          children: [{ text: '' }],
        };
      }

      return {
        text: chunk.value,
        chunk: chunk,
      };
    });

    return [{ text: '' }, ...result, { text: '' }];
  }

  return [{ text: '' }];
}

function buildEditor() {
  const editor = withReact(createEditor());

  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'token' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'token' ? true : isVoid(element);
  };

  return editor;
}

// padding + border + lineheight accounts for the min-height
EditableInput.Editable = styled(SlateEditable)`
  ${scrollbars.dark}
  min-width: 0;
  padding: 7px ${su(1)};
  border: 1px solid ${theme.input.border};
  // Its not possible to target this via emotion because we lazy load this because of slate. So we
  // use a css variable to temporary override the border radius until we have applied the new border
  // standard to all components.
  border-radius: var(--editable-border-radius, ${theme.borderRadius.default});
  outline: 0;
  line-height: 24px;
  transition: ${theme.duration.micro} ${theme.curve.easeInOut};
  transition-property: border, box-shadow;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 320px;
  min-height: 40px;

  // overflow-wrap: anywhere !important;

  &:hover {
    border-color: ${theme.input.border_hover};
    box-shadow: ${theme.input.boxShadow_hover};
  }

  &:focus {
    border-color: ${theme.input.border_focus};
    box-shadow: ${theme.input.boxShadow_focus};
  }
`;

EditableInput.Root = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;

  ${EditableInput.Editable} {
    flex: 1 1 auto;
  }

  &[data-layout='contained'] {
    ${TokenPicker.Root} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      padding: 0;
    }

    ${EditableInput.Editable} {
      padding-right: 40px;
    }
  }
`;

EditableInput.Placeholder = styled.span`
  display: block;
  position: absolute;
  left: ${su(1)};
  top: 7px;
  max-width: 100%;
  width: 100%;
  line-height: 24px;
  color: ${theme.color.text_light};
  pointer-events: none;
  text-decoration: none;
  user-select: none;
`;
