import React from 'react';

import { AppUtils } from '../../store/apps/AppUtils';

import { useApp } from '../../store/apps/useApps';

import { ColumnItemDefault } from './ColumnItemDefault';

export function ColumnItemApp(props) {
  const { app } = useApp({ appId: props.appId });
  const backgroundColor = AppUtils.getBackgroundColor(app);

  return <ColumnItemDefault iconObj={app?.iconObj} backgroundColor={backgroundColor} />;
}
