import { useRouteMatch } from 'react-router-dom';

import { RouteManager } from '../../RouteManager';

export function useFlowdRouteId() {
  const flowRoute = RouteManager.matchPath.flow;
  const flowRouteMatch = useRouteMatch(flowRoute);
  const flowRouteId = flowRouteMatch?.params?.flowId ?? null;

  return { flowRouteId };
}
