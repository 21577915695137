import { useRef } from 'react';
import styled from '@emotion/styled';

import { useTextField, mergeProps } from 'react-aria';

import { stopPressPropagation } from '../../lib/dom/stopPressPropagation';
import TextareaAutosize from 'react-textarea-autosize';

import { theme } from '../../theme/theme';
import { input } from '../../theme/Global';

export function DeviceNameField(props) {
  const textFieldRef = useRef();
  const textField = useTextField(
    {
      'aria-label': 'Device Rename',
      name: 'name',
      type: 'text',
      defaultValue: props.defaultValue,
      autoFocus: true,
      onBlur: props.onBlur,
      onKeyUp: props.onKeyUp,
      onKeyDown: props.onKeyDown,
      onFocus: (event) => {
        event.currentTarget.setSelectionRange(
          event.currentTarget.value.length,
          event.currentTarget.value.length
        );
      },
    },
    textFieldRef
  );

  if (props.type === 'input') {
    return (
      <Input
        {...mergeProps(stopPressPropagation, textField.inputProps)}
        ref={textFieldRef}
        size={props.defaultValue.length}
      />
    );
  }

  return (
    <Textarea {...mergeProps(stopPressPropagation, textField.inputProps)} ref={textFieldRef} />
  );
}

const Textarea = styled(TextareaAutosize)`
  ${input};
  appearance: none;
  width: calc(100% + 12px);
  font-size: ${theme.fontSize.small};
  line-height: 1.286;
  resize: none;
  margin: -6px;
  padding: 5px;
`;

const Input = styled.input`
  ${input};
  appearance: none;
  width: auto;
  outline: 0;
  font-size: ${theme.fontSize.small};
  margin: -5px -11px;
  padding: 0 10px;
`;
