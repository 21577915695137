import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { useApi } from '../../store/HomeyStore';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useMount } from '../../hooks/useMount';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { round } from '../../lib/math';

export function Weather() {
  const { api } = useApi();
  const { i18n } = useI18n();
  const [state, setState] = useState('');
  const mount = useMount();

  useEffect(() => {
    function setter(result) {
      const message =
        i18n?.messageFormatter('home.weather', {
          temperature: round(result.temperature ?? 0, 0),
          temperatureUnits: result.temperatureUnits ?? '°C',
          state: result.state ?? '',
        }) ?? '';

      if (mount.isMounted) {
        setState(message);
      }
    }

    if (api) {
      api.weather
        .getWeather()
        .then(setter)
        .catch((error) => {});
      api.weather.addListener('weather', setter);
    }

    return function () {
      if (api) {
        api.weather.removeListener('weather', setter);
      }
    };
  }, [api, i18n, mount]);

  return <sc.Weather>{state}</sc.Weather>;
}

const sc = {};

sc.Weather = styled.div`
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.medium};
  margin-top: ${su(1)};
  min-height: 24px;
  line-height: 24px;
`;
