import styled from '@emotion/styled';

import { AuthStore } from '../../store/AuthStore';

import { useI18n } from '../../hooks/useI18nFormatters';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { scrollbars } from '../../theme/elements/scrollbars';

import { Separator } from '../../components/common/Separator';
import { Menu, MenuItem } from '../../components/common/Menu';
import { ImageBase } from '../../components/common/ImageBase';
import { Icon } from '../../components/common/Icon';
import { ExternalLink } from '../../components/common/link/ExernalLink';

import { iconHomeyCloud } from '../../theme/icons/homey/homey-cloud/homey-cloud';
import { iconHomeyBridgeHome } from '../../theme/icons/homey/homey-bridge-home/homey-bridge-home';
import { iconHomeyPro } from '../../theme/icons/homey/homey-pro/homey-pro';
import { iconHomeyPro2023Fill } from '../../theme/icons/homey/homey-pro-2023-fill/homey-pro-2023-fill';

import { iconLogOut } from '../../theme/icons/interface/log-out';
import { iconExternalLink } from '../../theme/icons/interface/external-link';

export function UserMenuOverlay({ user, homeys, current, onHomeySelect }) {
  const { i18n } = useI18n();
  const size = 26;

  function getIcon(homey) {
    const isCloud = homey.platform === 'cloud';
    const isCloudWithBridge = isCloud && homey.bridges?.length > 0;

    switch (true) {
      case isCloud && isCloudWithBridge:
        return iconHomeyBridgeHome;
      case isCloud:
        return iconHomeyCloud;
      case homey.model === 'homey5q':
        return iconHomeyPro2023Fill;
      default:
        return iconHomeyPro;
    }
  }

  return (
    <S.Root>
      <S.Title>{user?.fullname}</S.Title>

      <S.Link url="https://homey.app/account/">
        {i18n.messageFormatter('header.user.account')}
        <Icon url={iconExternalLink} color={theme.color.highlight} size={theme.icon.size_tiny} />
      </S.Link>

      <Separator mx={20} my={10} />

      <Menu
        onAction={(key) => {
          onHomeySelect({ homeyId: key });
        }}
        aria-label="Homey Select"
      >
        {homeys?.map((homey) => {
          const isCurrent = current?.id === homey.id;

          return (
            <MenuItem
              key={homey.id}
              textValue={homey.name}
              // isCurrent={current?.id === homey.id}
              // onClick={() => onHomeySelect({ homey })}
            >
              <S.ActionContainer>
                <ImageBase url={getIcon(homey)} size={size} />
                <S.ActionName title={homey.name} data-style-bold={isCurrent}>
                  {homey.name}
                </S.ActionName>
              </S.ActionContainer>
            </MenuItem>
          );
        })}
      </Menu>

      <Separator mx={20} my={10} />

      <Menu
        aria-label="Additional Actions"
        onAction={() => {
          AuthStore.logout().catch(console.error);
        }}
      >
        <MenuItem key="LOG_OUT" textValue={i18n.messageFormatter('header.user.logout')}>
          <S.ActionContainer>
            <S.ExitActionName>{i18n.messageFormatter('header.user.logout')}</S.ExitActionName>
            <S.ExitIcon url={iconLogOut} color={theme.color.icon_light} />
          </S.ActionContainer>
        </MenuItem>
      </Menu>
    </S.Root>
  );
}

function S() {}
UserMenuOverlay.S = S;

S.Root = styled.div`
  ${scrollbars.dark}
  max-height: 460px;
  padding: ${su(1)} 0;
  overflow-y: auto;
`;

S.Title = styled.div`
  padding: ${su(1)} ${su(2)} 0;
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
`;

S.Link = styled(ExternalLink)`
  display: flex;
  align-items: center;
  margin: ${su(1)} ${su(2)};
  text-decoration: none;
  outline: 0;

  ${Icon.S.Root} {
    margin-left: 5px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

S.ActionName = styled.span`
  display: block;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &[data-style-bold='true'] {
    font-weight: ${theme.fontWeight.bold};
  }
`;

S.ExitIcon = styled(Icon)`
  transition: ${theme.transition.micro};
`;

S.ExitActionName = styled(S.ActionName)`
  margin-left: 0;
  flex: 1 1 auto;
`;

S.ActionContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 10px 20px;

  &:hover {
    ${S.ExitIcon} {
      background-color: ${theme.color.icon_light_hover};
    }
  }
`;
