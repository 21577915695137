import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useController } from 'react-hook-form';

import { useSelectState, Item } from 'react-stately';
import { useSelect } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { theme } from '../../../theme/theme';

import { Hint } from '../../../components/forms/Hint';
import { DeviceSettingsGeneralStatusIndicatorSelectPreview } from './DeviceSettingsGeneralStatusIndicatorSelectPreview';
import { DeviceSettingsGeneralStatusIndicatorListBoxOverlay } from './DeviceSettingsGeneralStatusIndicatorListBoxOverlay';

function children(option) {
  return <Item key={option.id} textValue={option.textValue} children={option} />;
}

export function DeviceSettingsGeneralStatusIndicatorSelect(props) {
  const buttonRef = useRef();

  const { field } = useController({
    name: props.name,
    control: props.control,
    rules: { required: props.required },
    defaultValue: props.defaultValue,
  });

  const selectState = useSelectState({
    items: props.options,
    children: props.children ?? children,
    defaultSelectedKey: props.defaultValue,
    onSelectionChange(value) {
      field.onChange?.(value);
      props.onChange?.(value);
    },
  });

  const select = useSelect(
    {
      label: props.label,
      'aria-label': props['aria-label'],
    },
    selectState,
    buttonRef
  );

  const ref = mergeRefs([buttonRef, field.ref]);

  return (
    <S.Root className={props.className}>
      <S.Header>
        {props.label && (
          <S.Label {...select.labelProps} title={props.label}>
            {props.renderLabel?.(props.label) ?? props.label}
          </S.Label>
        )}

        <input type="hidden" name={props.name} value={selectState.selectedKey} />

        {props.hint && <Hint hint={props.hint} />}
      </S.Header>

      <S.PreviewWrapper>
        <DeviceSettingsGeneralStatusIndicatorSelectPreview
          previewRef={ref}
          select={select}
          state={selectState}
          onBlur={() => field.onBlur?.()}
        />
      </S.PreviewWrapper>

      {selectState.isOpen && (
        <DeviceSettingsGeneralStatusIndicatorListBoxOverlay
          menuProps={select.menuProps}
          selectState={selectState}
          targetRef={buttonRef}
          overlayStyle={props.overlayStyle}
        />
      )}
    </S.Root>
  );
}

function S() {}
DeviceSettingsGeneralStatusIndicatorSelect.S = S;

S.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

S.Header = styled.div`
  display: block;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 24px;
  margin-bottom: 5px;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;

S.Label = styled.label`
  display: inline;
  min-width: 0;
  margin-bottom: 0;
`;

S.PreviewWrapper = styled.div`
  width: 100%;
`;
