import { memo, useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ToastManager } from '../../ToastManager';
import { mergeRefs } from '../../lib/mergeRefs';
import { dragTypes } from '../dnd/dragTypes';

import { getIndicator } from './getIndicator';

import { useEffectEvent } from '../../hooks/useEffectEvent';
import { useDevice, useCapabilities } from '../../store/devices/useDevices';
import { useQuickAction } from '../../hooks/capabilities/useQuickAction';

import { ContextMenu } from '../common/context-menu/ContextMenu';

import { DeviceTile } from './DeviceTile';
import { DeviceNameField } from './DeviceNameField';
import { DeviceCapabilityList } from './DeviceCapabilityList';
import { DeviceContextMenuContent } from './DeviceContextMenuContent';
import { RouteManager } from '../../RouteManager';

export const Device = memo(function Device({
  deviceId,
  isSelected,
  tileSize,
  onControlsClick,
  onRequestSwap,
  onDrop,
}) {
  const { device, api, loading, error } = useDevice({ deviceId });
  const { capabilities } = useCapabilities({ deviceId });

  const deviceName = device?.name.replace(/\s/g, ' ');
  const isLocked = device?.requiresPremium && api?.tier !== 'premium';

  const [name, setName] = useState(deviceName);
  const [isRenaming, setIsRenaming] = useState(false);

  const { value, onQuickAction, quickActionId, quickActionTitle } = useQuickAction({
    device,
    capabilities,
  });

  function handleControlsClick(event, device) {
    onControlsClick?.(event, device);
  }

  const capabilityListProps = {
    device: device,
    capabilities: capabilities,
    value: value,
    quickActionId: quickActionId,
    quickActionValue: value,
    onQuickAction: onQuickAction,
  };

  const hasCapabilityList = tileSize === DeviceTile.size.large;
  const capabilityList =
    hasCapabilityList === true ? <DeviceCapabilityList {...capabilityListProps} /> : null;

  const indicator = getIndicator({
    device,
    capabilities,
    tileSize,
  });

  const sharedProps = {
    name: name,
    deviceId: deviceId,
    device: device,
    iconObj: device?.iconObj,
    iconOverride: device?.iconOverride,
    capabilities: capabilities,
    capabilityList: capabilityList,
    indicator: indicator,
    tileSize: tileSize,
  };

  // todo check for isDragPreview/isRenaming for all fade animations
  function dragItemRender() {
    return (
      <DeviceTile
        {...sharedProps}
        capabilityList={
          tileSize === DeviceTile.size.large ? (
            <DeviceCapabilityList {...capabilityListProps} isDragPreview={true} />
          ) : null
        }
        isDragPreview={true}
      />
    );
  }

  const { dragRef, dropRef, isDragging } = useDeviceDragDrop({
    deviceId,
    isDisabled: false,
    onDrop,
    onRequestSwap,
    dragItemRender,
  });

  useEffect(() => {
    setName(deviceName);
  }, [deviceName]);

  if (device == null && loading === false && error == null) {
    return <DeviceTile {...sharedProps} isUnknown={true} />;
  }

  if (loading === true) {
    return <DeviceTile {...sharedProps} />;
  }

  if (device == null) return null;

  if (isRenaming) {
    async function renameDevice(event) {
      if (event.currentTarget.value.length === 0) return;
      const prevName = name;
      const nextName = event.currentTarget.value.replace(/\s/g, ' ');

      setName(nextName);
      setIsRenaming(false);

      try {
        await api.devices.updateDevice({
          id: device.id,
          device: {
            name: nextName,
          },
        });
      } catch (error) {
        setName(prevName);
        ToastManager.handleError(error);
      }
    }

    return (
      <DeviceTile {...sharedProps} isRenaming={true}>
        <DeviceNameField
          defaultValue={deviceName}
          onBlur={(event) => {
            renameDevice(event);
          }}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                event.preventDefault();
                break;
              default:
                break;
            }
          }}
          onKeyUp={(event) => {
            switch (event.key) {
              case 'Enter':
                renameDevice(event);

                break;
              case 'Escape':
                setIsRenaming(false);
                break;
              default:
                break;
            }
          }}
        />
      </DeviceTile>
    );
  }

  function handlePress(event) {
    if (event.ctrlKey && event.shiftKey) {
      console.log(device);
      return;
    }

    if (isLocked) {
      RouteManager.toPremiumRequiredDialog();
      return;
    }

    if (event.altKey) {
      onQuickAction();
      return;
    }

    if (onControlsClick) {
      handleControlsClick(event, device);
    }
  }

  function handleLongPress(event) {
    // onQuickAction();

    if (isLocked) {
      return;
    }
  }

  return (
    <ContextMenu
      content={
        <DeviceContextMenuContent
          deviceId={device.id}
          quickActionTitle={quickActionTitle}
          isLocked={isLocked}
          onQuickActionRequest={onQuickAction}
          onRenameRequest={() => {
            setIsRenaming(true);
          }}
        />
      }
    >
      {({ isOpen, onContextMenu }) => {
        return (
          <DeviceTile
            {...sharedProps}
            key={device.id}
            ref={mergeRefs([dragRef, dropRef])}
            value={value}
            isDragging={isDragging}
            isSelected={isSelected}
            isLocked={isLocked}
            onPress={handlePress}
            onLongPress={handleLongPress}
            onContextMenu={onContextMenu}
          />
        );
      }}
    </ContextMenu>
  );
});

function useDeviceDragDrop({ deviceId, isDisabled, onRequestSwap, onDrop, dragItemRender }) {
  const renderFunction = useEffectEvent((...args) => {
    return dragItemRender(...args);
  });

  const [{ isDragging }, dragRef, preview] = useDrag(() => {
    return {
      type: dragTypes.DEVICE,
      item: () => {
        return {
          type: dragTypes.DEVICE,
          id: deviceId,
          render: renderFunction,
        };
      },
      canDrag(monitor) {
        return isDisabled !== true;
      },
      end(dragItem, dragMonitor) {},
      collect(dragMonitor) {
        return {
          isDragging: dragMonitor.isDragging(),
        };
      },
    };
  }, [deviceId, isDisabled]);

  const [, dropRef] = useDrop(() => {
    return {
      accept: [dragTypes.DEVICE],
      hover(item) {
        if (item.id !== deviceId) {
          onRequestSwap?.({ firstId: deviceId, secondId: item.id });
        }
      },
      canDrop(item, monitor) {
        return isDisabled !== true;
      },
      drop(item, monitor) {
        onDrop?.(item);
      },
    };
  }, [deviceId, isDisabled, onRequestSwap, onDrop]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return {
    dragRef,
    dropRef,
    isDragging,
  };
}
