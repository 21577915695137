import { useMemo, useState } from 'react';
import { useCollator } from 'react-aria';

import { FlowFolderStore } from '../../store/flow-folders/FlowFolderStore';

export function useFolderTree({
  flowFoldersById,
  flowsByFolderId,
  advancedFlowsByFolderId,
  cardOwnersFilterSet,
}) {
  const collator = useCollator();
  const [filterValue, setFilterValue] = useState('');

  const rootFolder = useMemo(() => {
    if (flowFoldersById == null || flowsByFolderId == null || advancedFlowsByFolderId == null) {
      return null;
    }

    const folders = Object.values(flowFoldersById).map((folder) => {
      // Ensure we use the same root id.
      const parent =
        folder.id !== FlowFolderStore.rootFolderId
          ? folder.parent ?? FlowFolderStore.rootFolderId
          : FlowFolderStore.rootFolderId;

      return {
        ...folder,
        // For dragging.
        type: 'folder',
        parent: parent,
      };
    });

    // Create an index to map each folder id to an index.
    const folderIdMapping = folders.reduce((accumulator, folder, index) => {
      accumulator[folder.id] = index;
      return accumulator;
    }, {});

    let rootFolder = null;
    const folderReferences = {};

    const flowsByFolderIdReferences = {
      ...flowsByFolderId,
    };

    const advancedFlowsByFolderIdReferences = {
      ...advancedFlowsByFolderId,
    };

    folders.forEach((folder) => {
      if (folder.flowChildren == null) {
        folder.flowChildren = Object.values(flowsByFolderIdReferences[folder.id] ?? {});
        delete flowsByFolderIdReferences[folder.id];

        const advancedFlowChildren = Object.values(
          advancedFlowsByFolderIdReferences[folder.id] ?? {}
        );
        delete advancedFlowsByFolderIdReferences[folder.id];

        folder.flowChildren = folder.flowChildren.concat(advancedFlowChildren);

        folder.flowChildren.sort((firstEl, secondEl) => {
          return collator.compare(firstEl.name, secondEl.name);
        });
      }

      if (folder.id === FlowFolderStore.rootFolderId) {
        rootFolder = folder;
        return;
      }

      let parentFolder = folders[folderIdMapping[folder.parent]];

      // Ghost folder possible with the api.
      if (!parentFolder) parentFolder = folders[folderIdMapping[FlowFolderStore.rootFolderId]];

      folderReferences[parentFolder.id] = [...(folderReferences[parentFolder.id] || []), folder];

      parentFolder.folderChildren = folderReferences[parentFolder.id];
    });

    // Whatever remains, this happens when a folder was deleted but it still had Flows. This is
    // currently possible with the api but might be fixed in the future.
    const remainingFoldersWithFlows = Object.entries(flowsByFolderIdReferences);
    for (const [, flows] of remainingFoldersWithFlows) {
      const children = Object.values(flows ?? {});
      rootFolder.flowChildren = rootFolder.flowChildren.concat(children);
    }

    const remainingFoldersWithAdvancedFlows = Object.entries(advancedFlowsByFolderIdReferences);
    for (const [, advancedFlows] of remainingFoldersWithAdvancedFlows) {
      const children = Object.values(advancedFlows ?? {});
      rootFolder.flowChildren = rootFolder.flowChildren.concat(children);
    }

    Object.entries(folderReferences).forEach(([folderReferenceKey, folderReference]) => {
      folderReferences[folderReferenceKey] = folderReference.sort((firstEl, secondEl) => {
        return collator.compare(firstEl.name, secondEl.name);
      });
    });

    return rootFolder;
  }, [flowFoldersById, flowsByFolderId, advancedFlowsByFolderId, collator]);

  const filteredRootFolder = useMemo(() => {
    const cardOwnersFilter = [...(cardOwnersFilterSet ?? [])][0];

    function filter(filterValue, folder) {
      let match = false;

      const folderCopy = { ...folder };

      if (folderCopy.folderChildren != null) {
        folderCopy.initialFolderChildren = folderCopy.folderChildren;
        folderCopy.folderChildren = folderCopy.folderChildren.reduce((accumulator, childFolder) => {
          const childMatch = filter(filterValue, childFolder);

          if (childMatch) {
            match = true;
            accumulator.push(childMatch);
          }

          return accumulator;
        }, []);
      }

      if (folderCopy.flowChildren != null) {
        folderCopy.initialFlowChildren = folderCopy.flowChildren;
        folderCopy.flowChildren = folderCopy.flowChildren.filter((flow) => {
          if (cardOwnersFilter != null) {
            if (flow.cardOwnerUris?.has(cardOwnersFilter) === false) {
              return false;
            }
          }

          if (flow.name.toLowerCase().includes(filterValue.toLowerCase())) {
            match = true;
            return true;
          }

          return false;
        });
      }

      // Match on folder name but only when no chip was selected.
      if (
        cardOwnersFilter == null &&
        folderCopy.name.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        match = true;
      }

      // Root folder always matches.
      if (folderCopy.id === null) {
        match = true;
      }

      if (match) {
        return folderCopy;
      }
    }

    if (rootFolder == null) return rootFolder;

    return filter(filterValue, rootFolder);
  }, [filterValue, rootFolder, cardOwnersFilterSet]);

  return { rootFolder: filteredRootFolder, filterValue, setFilterValue };
}
