import { useEffect } from 'react';
import { Prompt, useLocation } from 'react-router-dom';

import { ScriptStore } from './ScriptStore';
import { RouteManager } from '../../RouteManager';

import { useI18n } from '../../hooks/useI18nFormatters';

export function useScriptUnsavedChangesPrompt({ editorInstanceRef }) {
  const { i18n } = useI18n();
  const location = useLocation();

  function handleNavigateAway(nextLocation, action) {
    if (
      `${nextLocation.pathname}${nextLocation.search}` === `${location.pathname}${location.search}`
    ) {
      return true;
    }

    if (nextLocation.state?.skipUnsavedChangesCheck === true) {
      return true;
    }

    const match = RouteManager.getPathMatch(RouteManager.matchPath.script);
    const encodedScriptId = match?.params?.scriptId;
    const scriptId = encodedScriptId != null ? decodeURIComponent(encodedScriptId) : null;

    const currentValue = editorInstanceRef.current?.getValue();
    const code = ScriptStore.get().byId?.[scriptId]?.code ?? null;

    if (code == null || currentValue == null) return true;

    const hasUnsavedChanges = code !== currentValue;

    if (hasUnsavedChanges === true) {
      return i18n.messageFormatter('script.unsavedChanges');
    }

    return true;
  }

  useEffect(() => {
    function listener(event) {
      const match = RouteManager.getPathMatch(RouteManager.matchPath.script);
      const encodedScriptId = match?.params?.scriptId;
      const scriptId = encodedScriptId != null ? decodeURIComponent(encodedScriptId) : null;

      const currentValue = editorInstanceRef.current?.getValue();
      const code = ScriptStore.get().byId?.[scriptId]?.code ?? null;

      if (code == null || currentValue == null) return;

      const hasUnsavedChanges = code !== currentValue;

      if (hasUnsavedChanges === true) {
        // Cancel the event
        event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        event.returnValue = '';
      }
    }

    window.addEventListener('beforeunload', listener);

    return function () {
      window.removeEventListener('beforeunload', listener);
    };
  }, [editorInstanceRef]);

  return { prompt: <Prompt message={handleNavigateAway} /> };
}
