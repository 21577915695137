import { useRef, useEffect, useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';

import { ToastManager } from '../../../ToastManager';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';
import { useEffectEvent } from '../../../hooks/useEffectEvent';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';

import { Dialog } from '../../../components/overlay/Dialog';
import { Icon } from '../../../components/common/Icon';
import { RoundIconWrapper } from '../../../components/common/Icon';
import { IconButton } from '../../../components/buttons/IconButton';
import { Clipboard } from '../../../components/common/Clipboard';
import { Throbber } from '../../../components/common/Throbber';
import { GradientButton } from '../../../components/buttons/GradientButton';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconPage } from '../../../theme/icons/interface/page';

export function GeneralSettingsDialogDiagnosticsReport(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  const instanceRef = useRef({});
  const inertRef = useRef(null);

  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const errorMessageRef = useRef(error?.message || '');

  // So the previous error message remains after setError is called with null.
  if (error != null) {
    errorMessageRef.current = error.message;
  }

  const errorMessage = errorMessageRef.current;

  const [view, setView] = useState('loading');

  function handleClosePress() {
    props.onCloseRequest();
  }

  function handleDonePress() {
    props.onCloseRequest();
  }

  const sendLog = useEffectEvent(() => {
    if (api == null) return;

    // Replace on node-homey-api. There is a bug in node-athom-api that does not send the bearer
    // token for this request.
    return api.system
      ._call('POST', 'journalctl', {
        opts: {
          $timeout: 90000,
        },
        body: {},
        queryParameters: {},
      })
      .then((result) => {
        setResult(result);
      })
      .catch((error) => {
        ToastManager.handleError(error);
        setError(error);
      });
  });

  useEffect(() => {
    if (instanceRef.current.sendLog) return;

    instanceRef.current.sendLog = sendLog();
    instanceRef.current.sendLog.finally(() => {
      delete instanceRef.current.sendLog;
    });
  }, [sendLog]);

  useLayoutEffect(() => {
    if (result == null) {
      setInert(inertRef.current, true);
      return;
    }

    setInert(inertRef.current, false);
  }, [result]);

  return (
    <Dialog onClose={props.onCloseRequest}>
      <S.Root>
        <S.CloseIconButtonWrapper>
          <IconButton
            url={iconCloseThin}
            color={theme.color.icon_light}
            size={theme.icon.size_small}
            onPress={handleClosePress}
          />
        </S.CloseIconButtonWrapper>

        {view === 'loading' && (
          <S.StatusWrapper
            data-is-fade-in={result == null && error == null}
            data-is-fade-out={result != null || error != null}
            onAnimationEnd={(event) => {
              if (event.animationName === animationFade.out.name) {
                if (result != null) {
                  setView('result');
                  return;
                }

                if (error != null) {
                  setView('error');
                  return;
                }
              }
            }}
          >
            <Throbber size={theme.icon.size_default} />

            <S.LoadingMessage>{`${i18n.messageFormatter(
              'settings.system.diagnosticsReportLoading'
            )}`}</S.LoadingMessage>
          </S.StatusWrapper>
        )}

        {view === 'error' && (
          <S.StatusWrapper
            data-is-fade-in={error != null}
            data-is-fade-out={error == null}
            onAnimationEnd={(event) => {
              if (event.animationName === animationFade.out.name) {
                setView('loading');
                sendLog();
              }
            }}
          >
            <S.Error>Error: {errorMessage}</S.Error>
            <GradientButton
              styleWidth="full"
              styleColor="blue"
              styleFlat={true}
              autoFocus={true}
              onPress={() => {
                setError(null);
              }}
            >
              {i18n.messageFormatter('common.retry')}
            </GradientButton>
          </S.StatusWrapper>
        )}

        <S.Inert ref={inertRef} data-is-fade-in={view === 'result'}>
          <RoundIconWrapper size="80px" color={theme.color.blue_050}>
            <Icon size="32px" url={iconPage} color={theme.color.blue} />
          </RoundIconWrapper>

          <S.Title>{i18n.messageFormatter('settings.system.diagnosticsReportTitle')}</S.Title>

          <S.CodeView>
            <S.Info>{i18n.messageFormatter('settings.system.diagnosticsReportHint')}</S.Info>

            <Clipboard
              keyedAutoFocus={view === 'result' ? 'result' : null}
              message={i18n.messageFormatter('settings.system.diagnosticsReportCopied', {
                value: result,
              })}
              value={result ?? ''}
            />
          </S.CodeView>

          <S.Actions>
            <GradientButton styleFlat={true} styleColor="blue" onPress={handleDonePress}>
              <GradientButton.Text>{i18n.messageFormatter('common.done')}</GradientButton.Text>
            </GradientButton>
          </S.Actions>
        </S.Inert>
      </S.Root>
    </Dialog>
  );
}

function S() {}
GeneralSettingsDialogDiagnosticsReport.S = S;

S.Root = styled.div`
  position: relative;
  width: 360px;
  padding: ${su(4)} ${su(2)} ${su(2)};
`;

S.CloseIconButtonWrapper = styled.div`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
`;

S.Inert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;

  &[data-is-fade-in='true'] {
    animation-name: ${animationFade.in};
    animation-duration: ${theme.duration.normal};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-fill-mode: forwards;
  }
`;

S.StatusWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${su(2)};
  color: ${theme.color.text_light};
  z-index: 1;

  &[data-is-fade-in] {
    opacity: 0;
  }

  &[data-is-fade-out='true'] {
    animation-name: ${animationFade.out};
    animation-duration: ${theme.duration.normal};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-fill-mode: forwards;
  }

  &[data-is-fade-in='true'] {
    animation-name: ${animationFade.in};
    animation-duration: ${theme.duration.normal};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-fill-mode: forwards;
  }
`;

S.LoadingMessage = styled.div`
  color: ${theme.color.text_light};
  padding-top: ${su(1)};
`;

S.Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;

S.CodeView = styled.div``;

S.Title = styled.h3`
  padding-top: ${su(2)};
`;

S.Info = styled.p`
  text-align: center;
  color: ${theme.color.text_light};
`;

S.Actions = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-gap: ${su(1)};
  padding-top: ${su(2)};
`;

// TODO
// move to lib
function setInert(element, isInert) {
  if (isInert) {
    element.setAttribute('aria-hidden', 'true');

    element.style.setProperty('user-select', 'none');
    element.style.setProperty('pointer-events', 'none');

    element
      .querySelectorAll(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"]), audio[controls], video[controls], label, optgroup, option, area, [contenteditable="true"], [contenteditable=""]'
      )
      .forEach((child) => {
        child.setAttribute('data-saved-tabindex', child.tabIndex);
        child.tabIndex = -1;
      });
  } else {
    element.removeAttribute('aria-hidden');

    element.style.removeProperty('user-select');
    element.style.removeProperty('pointer-events');

    element.querySelectorAll('[data-saved-tabindex]').forEach((child) => {
      const savedTabIndex = child.getAttribute('data-saved-tabindex');
      child.tabIndex = savedTabIndex;
      child.removeAttribute('data-saved-tabindex');
    });
  }
}
