import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

interface TextMediumProps {
  lineHeight?: React.CSSProperties['lineHeight'];
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  children: React.ReactNode;
}

export function TextMedium(props: TextMediumProps) {
  return (
    <S.Root
      style={{
        '--text-medium-line-height': props.lineHeight,
        '--text-medium-text-align': props.textAlign,
        '--text-medium-color': props.color,
      }}
    >
      {props.children}
    </S.Root>
  );
}

interface CSSProperties {
  '--text-medium-line-height'?: TextMediumProps['lineHeight'];
  '--text-medium-text-align'?: TextMediumProps['textAlign'];
  '--text-medium-color'?: TextMediumProps['color'];
}

const S = (TextMedium.S = class S {
  static Root = styled.div<{ style: CSSProperties }>`
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeight.regular};
    line-height: var(--text-medium-line-height, 22px);
    text-align: var(--text-medium-text-align, left);
    color: var(--text-medium-color, ${theme.color.text});
  `;
});
