import { shallow } from 'zustand/shallow';

import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';

import { platform } from '../../../lib/platform';

import { useContextMenuContext } from '../../../components/common/context-menu/ContextMenuContextProvider';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';

function selectContextMenuDependencies(state) {
  return {
    selected: state.selected,
    hasStartCard: state.hasStartCard,
  };
}

function useContextMenuDependencies() {
  return AdvancedFlowViewStore.store(selectContextMenuDependencies, shallow);
}

export function AdvancedFlowViewContextMenuContent(props) {
  const ctx = useContextMenuContext();
  const { i18n } = useI18n();

  const { selected, hasStartCard } = useContextMenuDependencies();

  function onAction(key, args) {
    const position = ctx.getPosition();
    const target = props.offsetParentRef.current;
    const targetRect = target.getBoundingClientRect();

    // Target is not scrollable but in case someone makes it that way.
    const x = position.clientX != null ? position.clientX - targetRect.left + target.scrollLeft : 0;
    const y = position.clientY != null ? position.clientY - targetRect.top + target.scrollTop : 0;

    switch (key) {
      case 'card':
        switch (args.type) {
          case 'delay':
            AdvancedFlowViewStore.setCardCursorData({
              cardData: {
                type: 'delay',
                args: {
                  delay: {
                    number: '1',
                    multiplier: 1,
                  },
                },
                x: 0,
                y: 0,
              },
            });
            break;
          case 'start':
          case 'all':
          case 'any':
            AdvancedFlowViewStore.setCardCursorData({
              cardData: {
                type: args.type,
                x: 0,
                y: 0,
              },
            });
            break;
          case 'note':
            AdvancedFlowViewStore.setCardCursorData({
              cardData: {
                type: 'note',
                value: '',
                color: 'yellow',
                x: 0,
                y: 0,
                width: null,
                height: null,
              },
            });
            break;
          default:
            props.onCardExplorerRequest({
              type: args.type,
            });
            break;
        }
        break;
      case 'copy':
        props.onCopyRequest(selected);
        break;
      case 'paste':
        props.onPasteRequest({ pointer: { x: x, y: y } });
        break;
      case 'delete':
        props.onDeleteRequest(selected);
        break;
      default:
        break;
    }
  }

  if (props.advancedFlow == null) return <ContextMenuContent.List />;

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.SubMenu title={i18n.messageFormatter('common.add')}>
        <ContextMenuContent.List>
          <ContextMenuContent.ListItem
            label={`${i18n.messageFormatter('flow.triggerCardTitle')}...`}
            onPress={() => {
              onAction('card', { type: 'trigger' });
            }}
          />
          <ContextMenuContent.ListItem
            label={`${i18n.messageFormatter('flow.conditionCardTitle')}...`}
            onPress={() => {
              onAction('card', { type: 'condition' });
            }}
          />
          <ContextMenuContent.ListItem
            label={`${i18n.messageFormatter('flow.actionCardTitle')}...`}
            onPress={() => {
              onAction('card', { type: 'action' });
            }}
          />

          <ContextMenuContent.Divider />

          <ContextMenuContent.ListItem
            label={i18n.messageFormatter('flow.startCardTitle')}
            hint={hasStartCard === true ? '1/1' : '0/1'}
            isDisabled={hasStartCard === true}
            onPress={() => {
              onAction('card', { type: 'start' });
            }}
          />

          <ContextMenuContent.ListItem
            label={i18n.messageFormatter('flow.delayCardTitle')}
            onPress={() => {
              onAction('card', { type: 'delay' });
            }}
          />

          <ContextMenuContent.ListItem
            label="All"
            onPress={() => {
              onAction('card', { type: 'all' });
            }}
          />

          <ContextMenuContent.ListItem
            label="Any"
            onPress={() => {
              onAction('card', { type: 'any' });
            }}
          />

          <ContextMenuContent.Divider />

          <ContextMenuContent.ListItem
            label={i18n.messageFormatter('flow.noteCardTitle')}
            onPress={() => {
              onAction('card', { type: 'note' });
            }}
          />
        </ContextMenuContent.List>
      </ContextMenuContent.SubMenu>

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.copy')}
        hint={`${platform.metaOrCtrlSymbol}C`}
        isDisabled={selected.size === 0}
        onPress={() => {
          onAction('copy');
        }}
      />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.paste')}
        hint={`${platform.metaOrCtrlSymbol}V`}
        onPress={() => {
          onAction('paste');
        }}
      />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        isDisabled={selected.size === 0}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
