import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { RouteManager } from '../../RouteManager';

import { useI18n } from '../../hooks/useI18nFormatters';

import { theme } from '../../theme/theme';
import { definitionBase } from '../../theme/theme';
import { overrideCSSVariables } from '../../theme/Global';

import { Dialog } from '../overlay/Dialog';
import { ListBox } from '../list-box/ListBox';
import { PremiumRequiredDialogItem } from './PremiumRequiredDialogItem';
import { IconButton } from '../buttons/IconButton';

import { iconAdvancedFlow } from '../../theme/icons-v2/system/advanced-flow/advanced-flow';
import { iconInsights } from '../../theme/icons-v2/system/insights/insights';
import { iconLogic } from '../../theme/icons-v2/system/logic/logic';
import { iconPremium } from '../../theme/icons-v2/system/premium/premium';
import { iconCloseThin } from '../../theme/icons/interface/close-thin';

export function PremiumRequiredDialog() {
  const videoRef = useRef(null);
  const { i18n } = useI18n();
  const [progress, setProgress] = useState(0);
  const [iteration, setIteration] = useState(1);

  const items = [
    {
      key: 'unlimited-devices',
      title: 'premium.premiumRequiredDialog.unlimitedDevicesTitle',
      subtitle: 'premium.premiumRequiredDialog.unlimitedDevicesSubtitle',
      url: iconPremium,
      video: 'https://etc.athom.com/homey-premium/unlimited-devices-480x480.mp4',
      context: {},
    },
    {
      key: 'advanced-flow',
      title: 'premium.premiumRequiredDialog.advancedFlowTitle',
      subtitle: 'premium.premiumRequiredDialog.advancedFlowSubtitle',
      url: iconAdvancedFlow,
      video: 'https://etc.athom.com/homey-premium/advanced-flow-480x480.mp4',
      context: {},
    },
    {
      key: 'homey-insights',
      title: 'premium.premiumRequiredDialog.homeyInsightsTitle',
      subtitle: 'premium.premiumRequiredDialog.homeyInsightsSubtitle',
      url: iconInsights,
      video: 'https://etc.athom.com/homey-premium/insights-480x480.mp4',
      context: {},
    },
    {
      key: 'logic-and-variables',
      title: 'premium.premiumRequiredDialog.logicVariablesTitle',
      subtitle: 'premium.premiumRequiredDialog.logicVariablesSubtitle',
      url: iconLogic,
      video: 'https://etc.athom.com/homey-premium/logic-variables-480x480.mp4',
      context: {},
    },
  ];

  const defaultItem = items[0];

  useEffect(() => {
    const videoElement = videoRef.current;

    function handleTimeUpdate() {
      setProgress(videoElement.currentTime / videoElement.duration);
    }

    function handleEnded() {
      setIteration((iteration) => iteration + 1);
      setProgress(0);
      videoRef.current.play();
    }

    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  function handleClose() {
    RouteManager.pushState({});
  }

  function handleSelectionChange(keys, listState) {
    const [key] = keys;
    const item = listState.collection.getItem(key);

    if (item != null) {
      setProgress(0);
      setIteration(1);
      videoRef.current.src = item.value.video;
      videoRef.current.load();
      videoRef.current.play();
    }
  }

  return (
    <Dialog onClose={handleClose} isOpen>
      <S.Root>
        <S.Close
          url={iconCloseThin}
          color={theme.color.icon_light}
          size={theme.icon.size_small}
          onPress={() => {
            handleClose();
          }}
        />
        <S.Header>
          <S.Title>{i18n.messageFormatter('premium.premiumRequiredDialog.title')}</S.Title>
          <ListBox
            aria-label="Premium Feature Select"
            selectionMode="single"
            items={items}
            renderItem={() => {
              return <PremiumRequiredDialogItem progress={progress} iteration={iteration} />;
            }}
            disallowEmptySelection={true}
            defaultSelectedKeys={[defaultItem.key]}
            onSelectionChange={handleSelectionChange}
          />
        </S.Header>
        <S.Content>
          <S.Video ref={videoRef} autoPlay muted>
            <source src={defaultItem.video} type="video/mp4" />
          </S.Video>
        </S.Content>
      </S.Root>
    </Dialog>
  );
}

function S() {}
PremiumRequiredDialog.S = S;

S.Root = styled.div`
  ${overrideCSSVariables(definitionBase)};

  position: relative;
  display: flex;
  flex-direction: row;
  box-shadow: ${theme.boxShadow.default};
  border-radius: ${theme.borderRadius.default};
  height: auto;
  width: 100%;
  max-width: 896px;
  text-align: left;
  line-height: 1.5;
`;

S.Close = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

S.Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 3rem;
  border-radius: ${theme.borderRadius.default} 0 0 ${theme.borderRadius.default};
  background: ${theme.color.body};
  color: ${theme.color.text};
`;

S.Title = styled.h1`
  margin-bottom: 1.5rem;
  font-size: ${theme.fontSize.heading1};
  background: linear-gradient(
    30deg,
    ${theme.color.gradient_pink_start},
    ${theme.color.gradient_pink_end}
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

S.Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0;
  background: ${theme.color.white};
  border-radius: 0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0;
`;

S.Video = styled.video`
  width: 480px;
  height: 480px;
`;
