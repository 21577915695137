import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { I18nProvider, useCollator, useMessageFormatter } from 'react-aria';
import { formatDistanceToNowStrict, formatDistanceToNow, format } from 'date-fns';
import { v4 as uuid } from 'uuid';

import { BaseStore } from '../store/BaseStore';

import { useAuth } from '../store/AuthStore';
import { useHomeyLanguage } from '../store/HomeyStore';

import * as defaultLocale from '../locales/en';

export const I18nFormattersContext = createContext<FormattersContext | null>(null);

export function LocaleProvider({ children }: { children: React.ReactNode }) {
  const instanceRef = useRef<{ pendingId?: string }>({});
  const { user, current } = useAuth();
  const homeyLanguage = useHomeyLanguage();

  const [state, setState] = useState(() => {
    return createLocaleState(defaultLocale);
  });

  let language: null | string = null;

  switch (true) {
    case homeyLanguage != null:
      language = homeyLanguage;
      break;
    case current?.language != null:
      language = current.language;
      break;
    case user?.language != null:
      language = user.language;
      break;
    default:
      language = 'en';
      break;
  }

  useEffect(() => {
    const id = (instanceRef.current.pendingId = uuid());
    import(`../locales/${language}.ts`)
      .then((locale) => {
        if (id !== instanceRef.current.pendingId) return;

        setState((prevState) => {
          if (prevState.code === locale.code) return prevState;
          return createLocaleState(locale);
        });
      })
      .catch((err) => {
        if (id !== instanceRef.current.pendingId) return;

        console.error(err);
        setState((prevState) => {
          if (prevState.code === 'en') return prevState;
          return createLocaleState(defaultLocale);
        });
      });
  }, [language]);

  return (
    <I18nProvider locale={state.code}>
      <FormatterProvider state={state}>{children}</FormatterProvider>
    </I18nProvider>
  );
}

function createLocaleState(locale: {
  code: string;
  stringLocale: Record<string, string>;
  dateLocale: any;
}) {
  let baseStringLocale = defaultLocale.stringLocale;
  let stringLocale = locale.stringLocale;

  // make it clear that values are missing if not in production
  if (import.meta.env.MODE !== 'production') {
    baseStringLocale = Object.keys(defaultLocale.stringLocale).reduce((acc, key) => {
      acc[key] = `_${key}`;
      return acc;
    }, {} as Record<string, string>);
  }

  // remove empty values so they use the default value
  stringLocale = Object.entries(stringLocale).reduce((acc, [key, value]) => {
    if (value != null && value !== '') {
      acc[key] = value;
    }

    return acc;
  }, {} as Record<string, string>);

  return {
    code: locale.code,
    stringLocale: {
      [locale.code]: {
        ...baseStringLocale,
        ...stringLocale,
      },
    },
    dateLocale: locale.dateLocale,
  };
}

interface FormattersContext {
  messageFormatter: (key: string, variables?: Record<string, unknown>, fallback?: string) => string;
  dateFormatter: {
    formatDistanceToNowStrict: typeof formatDistanceToNowStrict;
    formatDistanceToNow: typeof formatDistanceToNow;
    format: typeof format;
  };
}

function FormatterProvider({
  state,
  children,
}: {
  state: ReturnType<typeof createLocaleState>;
  children: React.ReactNode;
}) {
  const messageFormatter = useMessageFormatter(state.stringLocale);
  const collator = useCollator();

  useEffect(() => {
    I18NStore.setCollator(collator);

    return function () {
      I18NStore.setCollator(null);
    };
  }, [collator]);

  const formatters: FormattersContext = useMemo(() => {
    return {
      messageFormatter(...args) {
        try {
          return messageFormatter(args[0], args[1]);
        } catch (error) {
          console.error(error);
          // Fallback passed as 3rd argument.
          if (args[2]) {
            return args[2];
          }
          return args[0];
        }
      },
      dateFormatter: {
        formatDistanceToNowStrict(date, options) {
          return formatDistanceToNowStrict(date, {
            ...options,
            locale: state.dateLocale,
          });
        },
        formatDistanceToNow(date, options) {
          return formatDistanceToNow(date, {
            ...options,
            locale: state.dateLocale,
          });
        },
        format(date, tokens, options) {
          return format(date, tokens, {
            ...options,
            locale: state.dateLocale,
          });
        },
      },
      dateLocale: state.dateLocale,
      stringLocale: state.stringLocale,
      collator,
    };
  }, [messageFormatter, collator, state]);

  return (
    <I18nFormattersContext.Provider value={formatters}>{children}</I18nFormattersContext.Provider>
  );
}

export class I18NStore extends BaseStore {
  static store = create(
    devtools(
      (set, get, api) => {
        let readyResolve = null;
        let readyReject = null;
        const readyPromise = new Promise((resolve, reject) => {
          readyResolve = resolve;
          readyReject = reject;
        });

        return {
          readyPromise: readyPromise,
          readyResolve: readyResolve,
          readyReject: readyReject,
          collator: null,
        };
      },
      { name: 'I18N' }
    )
  );

  static async getCollator() {
    const state = this.get();
    await state.readyPromise;

    return this.get().collator;
  }

  static setCollator(collator: null | ReturnType<typeof useCollator>) {
    this.set({ collator });
    this.get().readyResolve();
  }
}
