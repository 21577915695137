import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { dragTypes } from '../dnd/dragTypes';
import { getEmptyImage } from 'react-dnd-html5-backend';

export function useFlowDragDrop({ flowId, isDisabled, onDrop, onRequestSwap, render }) {
  const ref = useRef({});
  ref.current.render = render;

  const [{ isDragging }, dragRef, preview] = useDrag(() => {
    return {
      type: dragTypes.FLOW,
      item: () => {
        return {
          type: dragTypes.FLOW,
          id: flowId,
          render: (...args) => ref.current.render(...args),
        };
      },
      canDrag(monitor) {
        return isDisabled !== true;
      },
      end(dragItem, dragMonitor) {},
      collect(dragMonitor) {
        return {
          isDragging: dragMonitor.isDragging(),
        };
      },
    };
  }, [flowId, isDisabled]);

  const [, dropRef] = useDrop(() => {
    return {
      accept: [dragTypes.FLOW],
      canDrop(item, monitor) {
        return isDisabled !== true;
      },
      hover(item) {
        if (item.id !== flowId) {
          onRequestSwap?.({ firstId: flowId, secondId: item.id });
        }
      },
      drop(item, monitor) {
        onDrop?.(item);
      },
    };
  }, [flowId, isDisabled, onRequestSwap, onDrop]);

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return {
    dragRef,
    dropRef,
    isDragging,
  };
}
