import { round } from '../math';

export function nullAsTrue(value) {
  return value != null ? value : true;
}

export function nullAsFalse(value) {
  return value != null ? value : false;
}

export function getFormattedValue({ messageFormatter, capability, value }) {
  const emptyChar = '-';

  if (value == null) return emptyChar;

  switch (capability.type) {
    case 'boolean':
      if (capability.titleTrue != null && capability.titleFalse != null) {
        return value === true ? capability.titleTrue : capability.titleFalse;
      }

      return messageFormatter(`common.${value}`);
    case 'number':
      if (capability.units) {
        if (capability.units === '%') {
          // todo handle case where someone decides to use
          // min -1 and max 1 for whatever reason

          if (capability.max === 1 && capability.min === 0) {
            value = capability.value * 100;
          }

          return `${round(value, capability.decimals)}${capability.units}`;
        }

        return `${round(value, capability.decimals)} ${capability.units}`;
      }

      return value;
    case 'string':
      return value.toString();
    case 'enum':
      const id = value.toString();
      const enumValue = capability.values?.find((value) => {
        return value.id === id;
      });
      return enumValue?.title ?? emptyChar;
    default:
      return null;
  }
}

export const CapUtils = {
  nullAsFalse,
  nullAsTrue,
  getFormattedValue,
};
