import { useFocusRing, mergeProps } from 'react-aria';

import { RouteManager } from '../../RouteManager';
import { AppUtils } from '../../store/apps/AppUtils';

import { useApp } from '../../store/apps/useApps';
import { useOptionContext } from '../../components/list-box/ListBox';

import { theme } from '../../theme/theme';

import { ContextMenu } from '../../components/common/context-menu/ContextMenu';
import { ContextMenuButton } from '../../components/common/context-menu/ContextMenuButton';
import { SubNavItem } from '../../components/sub-navigation/SubNavItem';
import { SettingsNavigationItemAppContextMenuContent } from './SettingsNavigationItemAppContextMenuContent';

export function SettingsNavigationItemApp(props) {
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();
  const focusRing = useFocusRing();

  const { app } = useApp({ appId: value.context.appId });
  const appBackgroundColor = AppUtils.getBackgroundColor(app);

  const contextMenuContent = (
    <SettingsNavigationItemAppContextMenuContent appId={value.context.appId} />
  );

  return (
    <ContextMenu content={contextMenuContent}>
      {({ isOpen, onContextMenu }) => {
        return (
          <SubNavItem.Root
            {...mergeProps(option.optionProps, focusRing.focusProps)}
            ref={ref}
            data-is-selected={option.isSelected}
            data-is-focus-visible={focusRing.isFocusVisible}
            data-is-context-menu-open={isOpen}
            data-show-disabled-style={app?.enabled === false}
            onContextMenu={onContextMenu}
          >
            <SubNavItem.Link href={RouteManager.getPathForSetting(item.key)}>
              <SubNavItem.IconWrapper backgroundColor={appBackgroundColor}>
                <SubNavItem.HomeyIcon
                  iconObj={app?.iconObj}
                  color={theme.color.white}
                  size="14px"
                />
              </SubNavItem.IconWrapper>

              <SubNavItem.Name>{app?.name ?? item.textValue}</SubNavItem.Name>
            </SubNavItem.Link>

            <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
          </SubNavItem.Root>
        );
      }}
    </ContextMenu>
  );
}
