import { useState } from 'react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useLogGroupData } from './useLogGroupData';
import { useFetchEntries } from './useFetchEntries';

import { dragTypes } from '../../components/dnd/dragTypes';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { when } from '../../theme/functions/when';
import { animationFade } from '../../theme/animations/animationFade';
import { containers } from '../../theme/elements/containers';

import { SpinnerLoading } from '../../components/common/SpinnerLoading';
import { Chart } from '../../components/chart/Chart';
import { LegendItem } from './LogLegendItem';
import { InsightAddButton } from './insight-add-listbox/InsightAddButton';
import { IconButton } from '../../components/buttons/IconButton';
import { LogOwnerItem } from './LogOwnerItem';

import { iconCloseThin } from '../../theme/icons/interface/close-thin';

export function LogGroup(props) {
  const { i18n } = useI18n();
  const [highlightLogKey, setHightlightLogKey] = useState(null);

  const { manager, byKey, defaultLog, colorMap } = useLogGroupData({ logs: props.logKeys });
  const { data, currentLoadedResolutionId } = useFetchEntries({
    byKey,
    manager,
    resolutionId: props.resolutionId,
  });

  const [dropProps, dropRef] = useDrop({
    accept: [dragTypes.LOG],
    collect: (dropMonitor, ...rest) => {
      const dragItem = dropMonitor.getItem();

      return {
        isOver: dropMonitor.isOver({ shallow: true }),
        canDrop: dropMonitor.canDrop(),
        isValidDropTarget: dragItem?.units === props.groupUnits,
        isDragging: dragItem != null,
      };
    },
    canDrop(dragItem, dropMonitor) {
      return dragItem.units === props.groupUnits;
    },
    hover(item, monitor) {},
    drop(dragItem, monitor) {
      props.onDrop({
        targetGroupKey: props.groupKey,
        logKey: dragItem.id,
      });
    },
  });

  const height = props.height ?? 280;

  return (
    <LogGroup.RelativeContainer
      className={props.className}
      isOver={dropProps.isOver}
      canDrop={dropProps.canDrop}
      isValidDropTarget={dropProps.isValidDropTarget}
      isDragging={dropProps.isDragging}
    >
      {dropProps.isDragging && !dropProps.isValidDropTarget && dropProps.isOver && (
        <LogGroup.MessageWrapper>
          <LogGroup.Message>{i18n.messageFormatter('insights.sameUnits')}</LogGroup.Message>
        </LogGroup.MessageWrapper>
      )}

      {currentLoadedResolutionId !== props.resolutionId && (
        <LogGroup.SpinnerWrapper>
          <SpinnerLoading />
        </LogGroup.SpinnerWrapper>
      )}

      <LogGroup.ChartWrapper
        ref={dropRef}
        isOver={dropProps.isOver}
        canDrop={dropProps.canDrop}
        isDragging={dropProps.isDragging}
        isValidDropTarget={dropProps.isValidDropTarget}
        style={{
          width: '100%',
          minHeight: height + 80,
        }}
      >
        <IconButton
          url={iconCloseThin}
          size={theme.icon.size_small}
          hoverColor={theme.icon.color_light_hover}
          onPress={() => props.onRequestRemoveGroup()}
        />

        <Chart
          width={props.width}
          height={height}
          data={data}
          defaultLog={defaultLog}
          logsByKey={byKey}
          colorMap={colorMap}
          resolutionId={props.resolutionId}
          highlightLogKey={highlightLogKey}
        />

        <LogGroup.Footer>
          <LogGroup.Legend>
            {Object.entries(byKey ?? {}).map(([logKey, log]) => {
              return (
                <LogOwnerItem
                  component={LegendItem}
                  key={logKey}
                  logKey={logKey}
                  log={log}
                  color={colorMap[logKey]}
                  onHoverStart={() => setHightlightLogKey(logKey)}
                  onHoverEnd={() => setHightlightLogKey(null)}
                  onRemoveKeyRequest={({ logKey }) => {
                    if (highlightLogKey === logKey) {
                      setHightlightLogKey(null);
                    }

                    props.onRemoveKeyRequest({ logKey });
                  }}
                />
              );
            })}
          </LogGroup.Legend>

          <InsightAddButton
            selectedKeys={byKey}
            units={props.groupUnits}
            onRequestSelectKeys={props.onRequestSelectKeys}
          />
        </LogGroup.Footer>
      </LogGroup.ChartWrapper>
    </LogGroup.RelativeContainer>
  );
}

LogGroup.RelativeContainer = styled.div`
  ${containers.card};
  position: relative;
  transition: transform ${theme.transition.micro_partial};
  animation: ${animationFade.in} ${theme.duration.fast} ease-out 1 forwards;

  ${when((props) => props.isOver && props.canDrop)} {
    box-shadow: ${theme.boxShadow.drop};
    transform: scale(0.98);
  }
`;

LogGroup.ChartWrapper = styled.div`
  width: 100%;
  border-radius: ${theme.borderRadius.default};
  border: 1px solid transparent;
  transition: ${theme.transition.micro};

  ${when((props) => props.isOver === true && props.canDrop === true)} {
    border: 1px solid ${theme.color.highlight};
    background-color: ${theme.color.blue_o_05};
  }

  ${when((props) => props.isDragging === true && props.isValidDropTarget === false)} {
    opacity: 0.5;
    filter: grayscale(0.8);
  }

  ${IconButton.S.Root} {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: ${su(1)};
    cursor: pointer;
    transition: ${theme.transition.micro};
    z-index: 1;
  }

  &:hover {
    ${IconButton.S.Root} {
      display: block;
    }
  }
`;

LogGroup.Message = styled.div``;

LogGroup.MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: ${theme.color.mono_o_02};
  border-radius: ${theme.borderRadius.default};
  z-index: 10;

  ${LogGroup.Message} {
    color: ${theme.color.mono_000};
    padding: ${su(1)} ${su(2)};
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.color.mono_500};
  }
`;

LogGroup.SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

LogGroup.Legend = styled.div``;
LogGroup.Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${su(2)} ${su(2)};
  min-height: 80px;

  ${LogGroup.Legend} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    > :not(:last-of-type) {
      margin-right: 10px;
    }
  }

  ${InsightAddButton.S.Root} {
    flex: 0 0 auto;
    align-self: flex-end;
  }
`;
