import { useReducer, useRef, useMemo, useEffect, useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { FlowEditor } from './useFlowEditor';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { NodeArgumentsContext } from '../view-advanced-flow/card/NodeArgumentsContext';
import { TokenContext } from '../TokenContext';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { dragTypes } from '../../../components/dnd/dragTypes';

import { AddCardButton, flowCardTypes } from './flowCardTypes';

import { FlowCard } from './FlowCard';
import { DragArea, GroupDivider } from './flowCardDragging';
import { FlowCardConditionContextMenuContent } from './FlowCardConditionContextMenuContent';

export function FlowCardConditions(props) {
  const { i18n } = useI18n();
  const [state, dispatch] = useReducer(reducer, props.conditions, initReducer);
  const [isDragging, setIsDragging] = useState(false);
  const stateRef = useRef({});
  stateRef.current = state;

  const contentList = useMemo(() => {
    return [
      {
        type: 'dragarea',
        position: 'top',
        group: 'group1',
        values: state.groups.group1,
      },
      ...state.groups.group1,
      {
        type: 'dragarea',
        position: 'bottom',
        group: 'group1',
        values: state.groups.group1,
      },
      {
        type: 'divider',
        title: i18n.messageFormatter('flow.or'),
        group: 'group2',
        values: state.groups.group2,
      },
      {
        type: 'dragarea',
        position: 'top',
        group: 'group2',
        values: state.groups.group2,
      },
      ...state.groups.group2,
      {
        type: 'dragarea',
        position: 'bottom',
        group: 'group2',
        values: state.groups.group2,
      },
      {
        type: 'divider',
        title: i18n.messageFormatter('flow.or'),
        group: 'group3',
        values: state.groups.group3,
      },
      {
        type: 'dragarea',
        position: 'top',
        group: 'group3',
        hidden: false,
        values: state.groups.group3,
      },
      ...state.groups.group3,
      {
        type: 'dragarea',
        position: 'bottom',
        group: 'group3',
        values: state.groups.group3,
      },
    ];
  }, [state, i18n]);

  const conditions = props.conditions;
  useEffect(() => {
    dispatch({ type: 'reset', payload: conditions });
  }, [conditions]);

  function handleMoveCard({ prevIndex, prevGroup, nextIndex, nextGroup }) {
    dispatch({
      type: 'move_card',
      payload: {
        prevIndex,
        prevGroup,
        nextIndex,
        nextGroup,
      },
    });
  }

  function handleMoveCardToGroup({ prevIndex, prevGroup, nextGroup, position }) {
    dispatch({
      type: 'move_card_to_group',
      payload: {
        prevIndex,
        prevGroup,
        nextGroup,
        position,
      },
    });
  }

  function handleMoveCardBegin() {
    setIsDragging(true);
  }

  function handleMoveCardEnd() {
    setIsDragging(false);
    FlowEditor.setConditions({
      conditions: state.cards.map((card) => card.cardData),
    });
  }

  return (
    <flowCardTypes.Section>
      <flowCardTypes.Title isNonActive={props.conditions?.length === 0}>
        {`${i18n.messageFormatter('flow.and')}...`}
      </flowCardTypes.Title>
      <flowCardTypes.Content>
        {contentList.map((item) => {
          if (item.type === 'divider') {
            return (
              <GroupDivider key={`${item.group}-divider`} item={item} isDragging={isDragging} />
            );
          }

          if (item.type === 'dragarea') {
            return (
              <DragArea
                key={`${item.group}-${item.position}`}
                item={item}
                isDragging={isDragging}
                stateRef={stateRef}
                onMoveCardToGroup={handleMoveCardToGroup}
                dragType={dragTypes.FLOW_CARD_CONDITION}
              />
            );
          }

          const key = ResourceUtils.getKey(item.cardData);
          const card = props.flowCards.conditions.byKey?.[key];

          return (
            <FlowCardCondition
              key={`${key}-${item.cardIndex}`}
              cardKey={key}
              cardIndex={item.cardIndex}
              card={card}
              data={item.cardData}
              setFlowCardPickerState={props.setFlowCardPickerState}
              stateRef={stateRef}
              onMoveCard={handleMoveCard}
              onMoveCardBegin={handleMoveCardBegin}
              onMoveCardEnd={handleMoveCardEnd}
            />
          );
        })}
      </flowCardTypes.Content>

      <AddCardButton
        onClick={() => {
          props.setFlowCardPickerState({
            type: 'condition',
          });
        }}
      >
        {i18n.messageFormatter('flow.addCard')}
      </AddCardButton>
    </flowCardTypes.Section>
  );
}

// This is a temp solution. Because dragItemRender renders outside of the current tree it does not
// have access to the correct context. This solution makes local tokens unavailable while dragging
// but at least prevents the app from crashing until we have a permanent solution.
const stubContext = { tokensByKey: {} };

function FlowCardCondition(props) {
  const ref = useRef();

  function dragItemRender() {
    return (
      <TokenContext.Provider value={stubContext}>
        <NodeArgumentsContext>
          <FlowCard
            cardType="action"
            cardIndex={props.cardIndex}
            card={props.card}
            data={props.data}
            isDraggingClone={true}
          />
        </NodeArgumentsContext>
      </TokenContext.Provider>
    );
  }

  const dragItem = {
    type: dragTypes.FLOW_CARD_CONDITION,
    id: props.cardIndex,
    cardIndex: props.cardIndex,
    render: dragItemRender,
  };

  const [{ isDragging }, dragRef, preview] = useDrag({
    // {  } , preview
    type: dragTypes.FLOW_CARD_CONDITION,
    item: () => {
      props.onMoveCardBegin();
      return dragItem;
    },
    collect(dragMonitor) {
      return {
        isDragging: !!dragMonitor.isDragging(),
      };
    },
    end: (dropResult, monitor) => {
      props.onMoveCardEnd();
    },
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const [, dropRef] = useDrop({
    accept: dragTypes.FLOW_CARD_CONDITION,
    canDrop(dragItem, dropMonitor) {
      return false;
    },
    hover(item, dropMonitor) {
      const hoveredCard = props.stateRef.current.cards.find(
        (card) => card.cardIndex === item.cardIndex
      );

      const draggingCard = props.stateRef.current.cards.find(
        (card) => card.cardIndex === dragItem.cardIndex
      );

      if (hoveredCard.cardIndex !== draggingCard.cardIndex) {
        props.onMoveCard({
          prevIndex: hoveredCard.cardIndex,
          prevGroup: hoveredCard.cardData.group,
          nextIndex: draggingCard.cardIndex,
          nextGroup: draggingCard.cardData.group,
        });
      }
    },
  });

  useLayoutEffect(() => {
    dragRef(dropRef(ref));
    return () => {
      dragRef(null);
      dropRef(null);
    };
  }, [dragRef, dropRef, ref]);

  return (
    <NodeArgumentsContext>
      <DraggableFlowCard
        ref={ref}
        cardType="condition"
        cardIndex={props.cardIndex}
        card={props.card}
        data={props.data}
        isDragging={isDragging}
        contextMenuContent={
          <FlowCardConditionContextMenuContent
            card={props.card}
            data={props.data}
            onReplaceRequest={() => {
              props.setFlowCardPickerState({
                type: 'condition',
                cardIndex: props.cardIndex,
                currentCard: props.card,
              });
            }}
            onDuplicateRequest={() => {
              FlowEditor.duplicateConditionCard({
                cardIndex: props.cardIndex,
              });
            }}
            onInvertRequest={() => {
              FlowEditor.invertConditionCard({
                cardIndex: props.cardIndex,
              });
            }}
            onDeleteRequest={() => {
              FlowEditor.deleteConditionCard({
                cardIndex: props.cardIndex,
              });
            }}
          />
        }
      />
    </NodeArgumentsContext>
  );
}

const DraggableFlowCard = styled(FlowCard)`
  transition: opacity ${theme.duration.micro} ease-in-out;
  opacity: ${(props) => (props.isDragging ? 0.2 : 1)};
`;

function initReducer(conditions) {
  const groups = {
    group1: [],
    group2: [],
    group3: [],
  };

  conditions.forEach((condition, index) => {
    const group = condition.group;
    groups[group].push({
      cardIndex: index,
      cardData: condition,
    });
  });

  return {
    groups,
    cards: [...groups.group1, ...groups.group2, ...groups.group3],
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'move_card': {
      const { prevIndex, prevGroup, nextIndex, nextGroup } = action.payload;

      if (prevGroup === nextGroup) {
        const prevGroupItems = state.groups[prevGroup];

        const prevGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === prevIndex);
        const nextGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === nextIndex);

        const nextGroupItems = [...prevGroupItems];

        const tmp = {
          ...nextGroupItems[prevGroupIndex],
        };

        nextGroupItems[prevGroupIndex] = {
          ...nextGroupItems[nextGroupIndex],
        };

        nextGroupItems[nextGroupIndex] = tmp;

        const nextGroups = {
          ...state.groups,
          [prevGroup]: nextGroupItems,
        };

        return {
          ...state,
          groups: nextGroups,
          cards: [...nextGroups.group1, ...nextGroups.group2, ...nextGroups.group3],
        };
      }

      if (prevGroup !== nextGroup) {
        const prevGroupItems = [...state.groups[prevGroup]];
        const nextGroupItems = [...state.groups[nextGroup]];

        const prevGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === prevIndex);

        const [item] = prevGroupItems.splice(prevGroupIndex, 1);

        nextGroupItems.push({
          ...item,
          cardData: {
            ...item.cardData,
            group: nextGroup,
          },
        });

        const nextGroups = {
          ...state.groups,
          [prevGroup]: prevGroupItems,
          [nextGroup]: nextGroupItems,
        };

        return {
          ...state,
          groups: nextGroups,
          cards: [...nextGroups.group1, ...nextGroups.group2, ...nextGroups.group3],
        };
      }

      return state;
    }

    case 'move_card_to_group': {
      const { prevIndex, prevGroup, nextGroup, position } = action.payload;
      const prevGroupItems = [...state.groups[prevGroup]];
      const nextGroupItems = [...state.groups[nextGroup]];

      const prevGroupIndex = prevGroupItems.findIndex((item) => item.cardIndex === prevIndex);

      const [item] = prevGroupItems.splice(prevGroupIndex, 1);

      if (position === 'top') {
        nextGroupItems.unshift({
          ...item,
          cardData: {
            ...item.cardData,
            group: nextGroup,
          },
        });
      } else if (position === 'bottom') {
        nextGroupItems.push({
          ...item,
          cardData: {
            ...item.cardData,
            group: nextGroup,
          },
        });
      }

      const nextGroups = {
        ...state.groups,
        [prevGroup]: prevGroupItems,
        [nextGroup]: nextGroupItems,
      };

      return {
        ...state,
        groups: nextGroups,
        cards: [...nextGroups.group1, ...nextGroups.group2, ...nextGroups.group3],
      };
    }

    case 'reset':
      return initReducer(action.payload);

    default:
      throw new Error('unknown_action');
  }
}
