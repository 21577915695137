import React from 'react';

import { theme } from '../../theme/theme';

import { useSensorState } from '../../hooks/capabilities/useSensorState';

import { Icon } from '../common/Icon';

import { iconWarningCircle } from '../../theme/icons/interface/warning-circle/warning-circle';

export function IndicatorAlarm({ device, capabilities }) {
  const { state } = useSensorState({ capabilities });

  if (!state.hasAlarm) return null;

  return <Icon display="block" url={iconWarningCircle} color={theme.color.yellow} size="24px" />;
}
