import React from 'react';
import styled from '@emotion/styled';

import { theme } from '../../theme/theme';
import { Icon } from '../common/Icon';
import { HomeyIcon } from '../common/HomeyIcon';

export function DeviceIcon({
  iconObj,
  iconUrl,
  iconOverride,
  size = '48px',
  color = theme.color.icon_dark,
  ...rest
}) {
  const url = getDeviceIconURL({
    iconUrl,
    iconOverride,
  });

  return (
    <Container {...rest}>
      {url != null ? (
        <Icon url={url} size={size} color={color} />
      ) : (
        <HomeyIcon iconObj={iconObj} size={size} color={color} />
      )}
    </Container>
  );
}

export function getDeviceIconURL({ iconUrl, iconOverride }) {
  if (iconOverride != null) {
    return getOverrideURL({ iconOverride });
  }

  return iconUrl;
}

export function getOverrideURL({ iconOverride }) {
  return `/img/devices/${iconOverride}.svg`;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;
