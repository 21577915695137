import * as Sentry from '@sentry/react';

export class DeviceUtils {
  // static hasAdvancedSettings(device) {
  //   if (device == null) return false;

  //   return device.settingsObj && Object.keys(device.settings ?? {}).length > 0;
  // }

  // static hasMaintenanceActions(device) {
  //   if (device == null) return false;

  //   return Object.values(device.capabilitiesObj ?? {}).some((capability) => {
  //     return (
  //       capability.maintenanceAction === true || capability.options?.maintenanceAction === true
  //     );
  //   });
  // }

  static isFavoriteDevice(device, userMe) {
    // Temp log to sentry to see what on earth is set into userMe.favoriteDevices.
    if (userMe.favoriteDevices) {
      if (!userMe.favoriteDevices.includes) {
        const scope = new Sentry.Scope();
        scope.setContext('userMe', {
          ...userMe,
        });
        scope.setContext('device', {
          ...device,
        });
        Sentry.captureMessage('userMe.favoriteDevices.includes', scope);
      }
    }

    return userMe.favoriteDevices?.includes?.(device?.id) ?? false;
  }
}
