import { useMemo } from 'react';

import { useMood } from '../../../store/moods/useMoods';
import { useZone } from '../../../store/zones/useZones';
import { useApi } from '../../../store/HomeyStore';
import { useMixedColors } from '../../../components/mood/CanvasLiquidLinearCircle';

import { CanvasLiquidLinearCircle } from '../../../components/mood/CanvasLiquidLinearCircle';

export function MoodFlowCard(props) {
  const { component: Component, ...rest } = props;
  const { api } = useApi();
  const { mood } = useMood({ moodId: props.ownerId });
  const { zone } = useZone({ zoneId: mood?.zone });

  const circleSize = 40;
  const mixedColors = useMixedColors({ devices: mood?.devices });

  const { iconUrl, iconObj, iconBackgroundColor, resourceUrl, name, isLocked } = useMemo(() => {
    const result = {
      iconUrl: null,
      iconObj: null,
      iconBackgroundColor: null,
      resourceUrl: null,
      name: props.card.ownerName ?? props.card.uriObj?.name,
      isLocked: props.card.requiresPremium === true && api.tier !== 'premium',
    };

    if (zone != null) {
      result.name = `${result.name} - ${zone.name}`;
    }

    return result;
  }, [zone, props.card, api]);

  return (
    <Component
      {...rest}
      iconComponent={
        <CanvasLiquidLinearCircle id={mood.id} size={circleSize} mixedColors={mixedColors} />
      }
      iconUrl={iconUrl}
      iconObj={iconObj}
      iconBackgroundColor={iconBackgroundColor}
      resourceUrl={resourceUrl}
      name={name}
      isLocked={isLocked}
    />
  );
}
