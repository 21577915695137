import { useRef } from 'react';
import styled from '@emotion/styled';
import { useTableHeaderRow } from 'react-aria';

import { theme } from '../../../theme/theme';

export function FamilySettingsTableHeaderRow(props) {
  const ref = useRef();
  const tableHeaderRow = useTableHeaderRow({ node: props.item }, props.state, ref);

  return (
    <S.TableHeaderRow
      {...tableHeaderRow.rowProps}
      ref={ref}
      data-is-header-hidden={props.isHeaderHidden}
    >
      {props.children}
    </S.TableHeaderRow>
  );
}

function S() {}
FamilySettingsTableHeaderRow.S = S;

S.TableHeaderRow = styled.tr`
  // This was needed because its not possible to apply a box shadow to a tbody on Safari. So we use
  // a pseudo element and add a top offset of HEADER_HEIGHT px.
  height: var(--header-height);

  th {
    color: ${theme.color.text_light};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.medium};
    padding-bottom: 15px;
  }

  th:not(:first-child) {
    text-align: right;
  }

  th:first-child {
    padding-left: 20px;
  }

  th:last-child {
    padding-right: 20px;
    opacity: 0;
  }

  &[data-is-header-hidden='true'] {
    opacity: 0;
    padding: 0;

    th {
      padding: 0;
    }
  }
`;
