import { useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { v4 as uuid } from 'uuid';

import { BaseApiStore } from '../../../store/BaseApiStore';

import { useAttach } from '../../../store/useAttach';

import { useFlowEditor } from './useFlowEditor';
import { ToastManager } from '../../../ToastManager';

export class FlowTest extends BaseApiStore {
  static key = 'flowTest';
  static store = this.createStore(this.key);
  static createInitialState() {
    return {
      sessionId: `mha-${uuid()}`,
      data: null,
    };
  }

  static onSetApi() {
    const state = this.get();
    const api = state.api;

    this.addListeners(api);
  }

  static destroy() {
    const state = this.get();

    const api = state.api;

    if (api != null) {
      this.removeListeners(api);
    }
  }

  static addListeners(api) {
    api.flow.addListener('testFlowCardStart', this.handleTestFlowCardStart);
    api.flow.addListener('testFlowCardError', this.handleTestFlowCardError);
    api.flow.addListener('testFlowCardEnd', this.handleTestFlowCardEnd);
  }

  static removeListeners(api) {
    api.flow.removeListener('testFlowCardStart', this.handleTestFlowCardStart);
    api.flow.removeListener('testFlowCardError', this.handleTestFlowCardError);
    api.flow.removeListener('testFlowCardEnd', this.handleTestFlowCardEnd);
  }

  static handleTestFlowCardStart = (card) => {
    const state = this.get();
    if (card.sessionId === state.sessionId) {
      const type = card.type.replace('flowcard', '');

      this.set({
        data: {
          ...state.data,
          [`${type}::${card.index}`]: {
            ...card,
            state: 'start',
          },
        },
      });
    }
  };

  static handleTestFlowCardError = (card) => {
    const state = this.get();
    if (card.sessionId === state.sessionId) {
      const type = card.type.replace('flowcard', '');

      this.set({
        data: {
          ...state.data,
          [`${type}::${card.index}`]: {
            ...card,
            state: 'error',
          },
        },
      });

      console.error(card.err);
      ToastManager.addError({
        message: card.err,
      });
    }
  };
  static handleTestFlowCardEnd = (card) => {
    const state = this.get();
    if (card.sessionId === state.sessionId) {
      // card.sessionId === state.sessionId
      const type = card.type.replace('flowcard', '');

      this.set({
        data: {
          ...state.data,
          [`${type}::${card.index}`]: {
            ...card,
            state: 'end',
          },
        },
      });
    }
  };

  static setFlow({ flow }) {
    this.set({ flow: flow, data: null });
  }

  static testFlow({ tokens = {} } = {}) {
    const state = this.get();
    const api = state.api;
    this.set({ data: null });

    const flow = {
      trigger: state.flow.trigger,
      conditions: state.flow.conditions,
      actions: state.flow.actions,
    };

    const hasName = state.flow.name?.length > 0 ?? false;
    flow.name = hasName ? state.flow.name : 'Test';

    return api.flow
      .testFlow({
        sessionId: state.sessionId,
        tokens: tokens,
        flow: flow,
      })
      .then(() => {})
      .catch(ToastManager.handleError);
  }
}

export function useFlowTestListener() {
  useAttach(FlowTest, 'flowTest');

  const { flow } = useFlowEditor();

  useEffect(() => {
    FlowTest.setFlow({ flow });
  }, [flow]);
}

export function useFlowTest({ cardType, cardIndex }) {
  useAttach(FlowTest, 'flowTest');

  const selector = useCallback(
    (state) => state.data?.[`${cardType}::${cardIndex}`] ?? null,
    [cardType, cardIndex]
  );

  return FlowTest.store(selector, shallow);
}
