import { createContext, useContext, useLayoutEffect, useMemo } from 'react';
import { useInteract } from './useInteract';

import { overlayCloseReasons } from './overlayCloseReasons';
import { GlobalOverlayActiveContext, GlobalOverlayContext } from './GlobalOverlayContextProvider';

export const OverlayStackContext = createContext(null);

export function useOverlayStackContext() {
  // The root defines the context which child overlays reuse.
  const currentContext = useContext(OverlayStackContext);
  const globalOverlayContext = useContext(GlobalOverlayContext);
  const globalOverlayActiveContext = useContext(GlobalOverlayActiveContext);

  const { context, isRoot } = useMemo(() => {
    if (currentContext != null) return { context: currentContext, isRoot: false };

    return {
      context: {
        stack: [],
      },
      isRoot: true,
    };
  }, [currentContext]);

  useLayoutEffect(() => {
    if (isRoot === true) {
      globalOverlayContext.setActiveContext(context);
    }
  }, [isRoot, context, globalOverlayContext]);

  // The root handles all interactions and decides what closes.
  if (isRoot === true) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInteract({
      stack: context.stack,
      onInteractOutsideStart(event) {
        if (globalOverlayActiveContext !== context) {
          return;
        }

        const stackLength = context.stack.length;
        const lastIndex = stackLength - 1;

        const overlaysToClose = [];

        for (let index = lastIndex; index >= 0; index--) {
          const item = context.stack[index];

          // We need to check here because we close with requestAnimationFrame the state variable
          // might have changed already.
          if (item.getIsPreventClose() !== true) {
            overlaysToClose.push(context.stack[index]);
          }
        }

        // Since we handled this event we prevent bubbling. This will prevent 2,3 and 1 is the
        // useInteract itself.
        // 1 Capture (down)
        // 2 Target
        // 3 Bubble (up).
        if (overlaysToClose.length > 0) {
          event.preventDefault();
          event.stopPropagation();
        }

        // onInteractOutside handles the actual closing when the pointer up fires.
      },
      onInteractOutside(event) {
        if (globalOverlayActiveContext !== context) {
          return;
        }

        const stackLength = context.stack.length;
        const lastIndex = stackLength - 1;

        const overlaysToClose = [];

        for (let index = lastIndex; index >= 0; index--) {
          const item = context.stack[index];

          // We need to check here because we close with requestAnimationFrame the state variable
          // might have changed already.
          if (item.getIsPreventClose() !== true) {
            overlaysToClose.push(context.stack[index]);
          }
        }

        // Since we handled this event we prevent bubbling. This will prevent 2,3 and 1 is the
        // useInteract itself.
        // 1 Capture (down)
        // 2 Target
        // 3 Bubble (up).
        if (overlaysToClose.length > 0) {
          event.preventDefault();
          event.stopPropagation();
        }

        // We need this because FocusScope uses requestAnimationFrame to restore the focus. So we
        // close one and let the focus restore.
        Promise.resolve().then(async () => {
          for (const { onCloseRequest } of overlaysToClose) {
            await new Promise((resolve) => {
              requestAnimationFrame(() => {
                resolve(onCloseRequest(overlayCloseReasons.INTERACT_OUTSIDE));
              });
            });
          }
        });
      },
      onInteractInsideStart(event, interactedIndex) {
        if (globalOverlayActiveContext !== context) {
          return;
        }

        // Before anything first we get the current stack context and clone it.
        const clonedStack = [...context.stack];

        // This is needed because the reason for onInteractInsideStart might be the opening of a new
        // overlay on the same stacking context level.

        const lastIndex = clonedStack.length - 1;

        // If it's not the top most overlay.
        if (interactedIndex !== lastIndex) {
          const stackLength = clonedStack.length;
          const overlaysToClose = [];

          for (let index = stackLength - 1; index > interactedIndex; index--) {
            overlaysToClose.push(clonedStack[index]);

            // Remove from the original array.
            context.stack.splice(index, 1);
          }

          if (overlaysToClose.length > 0) {
            event.preventDefault();
            event.stopPropagation();
          }

          // We need this because FocusScope uses requestAnimationFrame to restore the focus. So
          // we close one and let the focus restore.
          Promise.resolve().then(async () => {
            for (const { onCloseRequest } of overlaysToClose) {
              await new Promise((resolve) => {
                requestAnimationFrame(() => {
                  resolve(onCloseRequest(overlayCloseReasons.INTERACT_PARENT));
                });
              });
            }
          });
        }
      },
      onInteractInside(event, index) {
        if (globalOverlayActiveContext !== context) {
          return;
        }
      },
    });
  }

  return { context, isRoot };
}
