import { useEffect, useLayoutEffect, createContext, useRef, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { keyframes as cnKeyframes } from '@emotion/css';
import { motion, useMotionValue } from 'framer-motion';

import { useSetNavContextState } from './containers/NavContext';
import { useLayoutContextState } from './LayoutContext';
import { useIsFullscreen } from './store/web-app-settings/useWebAppSettings';

import { theme } from './theme/theme';
import { su } from './theme/functions/su';

import { Header } from './containers/header/Header';
import { Nav } from './containers/Nav';
import { ErrorBoundary } from './components/ErrorBoundary';

const LayoutPortalContext = createContext(null);

export function Layout({
  subNavigationProps = {},
  subNavigationChildren,
  hideSubNavigation,
  minimalHeader,
  right,
  content,
  actions,
  children,
  isRightOpen,
  isRightOverlay,
}) {
  const [isPortalActive, setIsPortalActive] = useState(false);
  const portalRef = useRef(null);

  const { setNavContextState } = useSetNavContextState();
  const { layoutContextState } = useLayoutContextState();

  const { isFullscreen } = useIsFullscreen();

  useLayoutEffect(() => {
    setNavContextState(subNavigationProps.key, subNavigationProps.width);
  }, [subNavigationProps.key, subNavigationProps.width, setNavContextState]);

  const drawerAnimationMV = useMotionValue(null);
  const widthMV = useMotionValue(null);
  const width = '420px';

  useLayoutEffect(() => {
    if (isRightOpen) {
      layoutContextState.scrollbarThumbBackgroundMV.set('transparent');
      widthMV.set(width);
      drawerAnimationMV.set(`${open} 750ms ease-in-out`);

      return function () {
        layoutContextState.scrollbarThumbBackgroundMV.set(null);
        widthMV.set(null);
        drawerAnimationMV.set(`${close} 500ms ease-in-out`);
      };
    }
  }, [isRightOpen, width, widthMV, drawerAnimationMV, layoutContextState]);

  function handleAnimationEnd() {
    layoutContextState.scrollbarThumbBackgroundMV.set(null);
  }

  return (
    <LayoutPortalContext.Provider value={{ portalRef, setIsPortalActive }}>
      <Layout.Root>
        <Layout.Grid id={Layout.Grid.id} />
        <Layout.Portal ref={portalRef} />

        {/* Left navigation */}
        {isFullscreen === false && hideSubNavigation !== true && (
          <Nav
            title={subNavigationProps.title}
            beta={subNavigationProps.beta}
            width={subNavigationProps.width}
          >
            {subNavigationChildren}
          </Nav>
        )}

        {/* Center */}
        <Layout.Main data-is-portal-active={isPortalActive}>
          <ErrorBoundary>
            {isFullscreen === false && (
              <Header
                isRightOpen={isRightOpen}
                isRightOverlay={isRightOverlay}
                isMinimal={minimalHeader}
              >
                {actions}
              </Header>
            )}
            {content}
          </ErrorBoundary>
        </Layout.Main>

        {/* Right drawer */}
        {right && (
          <Layout.Sidebar
            style={{
              '--width': isRightOverlay !== true ? widthMV : 0,
            }}
          >
            <Layout.RightDrawerWrapper
              style={{
                '--width': width,
                '--drawer-animation': drawerAnimationMV,
              }}
              onAnimationEnd={handleAnimationEnd}
            >
              <Layout.RightDrawer>{right}</Layout.RightDrawer>
            </Layout.RightDrawerWrapper>
          </Layout.Sidebar>
        )}
        {children}
      </Layout.Root>
    </LayoutPortalContext.Provider>
  );
}

export function useDocumentGrid({ isDisabled } = {}) {
  useEffect(() => {
    document.getElementById(Layout.Grid.id)?.setAttribute('data-is-visible', 'true');

    return function () {
      document.getElementById(Layout.Grid.id)?.setAttribute('data-is-visible', 'false');
    };
  }, []);

  useEffect(() => {
    if (isDisabled === true) {
      document.getElementById(Layout.Grid.id)?.setAttribute('data-is-disabled', 'true');

      return function () {
        document.getElementById(Layout.Grid.id)?.setAttribute('data-is-disabled', 'false');
      };
    }
  }, [isDisabled]);
}

export function useDocumentScrollOffset({ ref }) {
  useEffect(() => {
    const element = ref.current;
    const layoutGrid = document.getElementById(Layout.Grid.id);

    function listener() {
      layoutGrid.style.setProperty('--scroll-top', `-${element.scrollTop}px`);
      layoutGrid.style.setProperty('--scroll-left', `-${element.scrollLeft}px`);
    }

    listener();
    element.addEventListener('scroll', listener);

    return function () {
      element.removeEventListener('scroll', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const open = cnKeyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  60% {
    transform: translateX(-5%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const close = cnKeyframes`
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

Layout.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 auto;
  max-width: 100%;
`;

Layout.Grid = styled.div`
  &[data-is-visible='true'] {
    --grid-position-x: calc(var(--width-left, 0px) + var(--scroll-left, 0px) + ${su(1)});
    --grid-position-y: calc(var(--scroll-top) + ${su(8)});

    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: ${su(2, 2)};
    background-image: linear-gradient(to right, ${theme.color.grid} 1px, transparent 1px),
      linear-gradient(to bottom, ${theme.color.grid} 1px, transparent 1px);
    background-repeat: repeat;
    background-position: var(--grid-position-x) var(--grid-position-y);
    transform: translate3d(0, 0, 0);
    transition: ${theme.duration.normal} ${theme.curve.fastIn};
    transition-property: opacity;
  }

  &[data-is-disabled='true'] {
    opacity: 0;
  }
`;
Layout.Grid.id = 'layout-grid';

Layout.Portal = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export function LayoutPortal(props) {
  const { portalRef, setIsPortalActive } = useContext(LayoutPortalContext);

  useLayoutEffect(() => {
    setIsPortalActive(true);

    return function () {
      setIsPortalActive(false);
    };
  }, [setIsPortalActive]);

  return portalRef.current != null && createPortal(props.children, portalRef.current);
}

Layout.Main = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  z-index: ${theme.zIndex.content};

  &[data-is-portal-active='true'] {
    pointer-events: none;
  }
`;

Layout.RightDrawerWrapper = styled(motion.div)`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  width: var(--width);
  opacity: 1;
  transform: translateX(100%);
  animation: var(--drawer-animation, none);
  animation-fill-mode: forwards;
`;

Layout.RightDrawer = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-height: 0;
  max-height: 100%;
  margin: ${su(2, 2, 2, 0)};
  background: linear-gradient(
    225deg,
    #16171d,
    #292a2f
  ); // equals color mono-02 and mono-10 from DarkMode palette
  color: ${theme.color.text_invert};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  overflow: hidden;
`;

Layout.Sidebar = styled(motion.div)`
  position: relative;
  display: flex;
  flex: 0 0 var(--width, 0);
  z-index: calc(${theme.zIndex.header} + 1);
`;
