import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { useButton, AriaButtonProps } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { ButtonIcon } from './ButtonIcon';

interface Props extends AriaButtonProps<'button'> {
  className?: string;
  style?: Object;
  title?: string;
  form?: string;
  /** Whether the element box shadow should be removed. */
  styleFlat?: boolean;
  /** Whether the element padding should be decreased. */
  styleCompact?: boolean;
  /** Whether the element should receive width 100% style. */
  styleWidth?: 'full';
  styleColor?: 'default' | 'blue' | 'red' | 'green';
}

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLButtonElement>> {
  S: typeof S;
  Icon: typeof ButtonIcon;
  Text: typeof Text;
}

export const SolidButton = forwardRef((props, forwardedRef) => {
  const { styleColor = 'default' } = props;

  const ref = useRef<HTMLButtonElement>(null);
  const button = useButton(props, ref);

  return (
    <S.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      className={props.className}
      style={props.style}
      as={props.elementType}
      form={props.form}
      title={props.title}
      data-style-flat={props.styleFlat}
      data-style-color={styleColor}
      data-style-width={props.styleWidth}
      data-style-compact={props.styleCompact}
      data-is-pressed={button.isPressed}
      data-is-disabled={props.isDisabled}
    >
      {props.children}
    </S.Root>
  );
}) as CompoundedComponent;

const Text = styled.span`
  white-space: nowrap;
`;

SolidButton.Text = Text;
SolidButton.Icon = ButtonIcon;

function S() {}
SolidButton.S = S;

S.Root = styled.button`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding: ${su(1)} ${su(2)};
  background: var(--solid-button-color);
  color: var(--solid-button-font-color, ${theme.color.white});
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  font-weight: var(--solid-button-font-weight, ${theme.fontWeight.medium});
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, color, box-shadow, background-color;

  ${SolidButton.Icon.S.Root} {
    background: ${theme.color.white};
  }

  // States
  &:not([data-is-disabled='true']) {
    &:hover {
      &:not[data-style-flat='true'] {
        box-shadow: ${theme.boxShadow.default_hover};
      }
      background-color: var(--solid-button-color-hover);
    }

    &[data-is-pressed='true'] {
      &:not[data-style-flat='true'] {
        box-shadow: ${theme.boxShadow.default_active};
      }
      background-color: var(--solid-button-color-active);
      transform: scale(0.96875);
    }

    // Color variants
    &[data-style-color='default'] {
      --solid-button-color: ${theme.color.mono_050};
      --solid-button-color-hover: ${theme.color.mono_020};
      --solid-button-color-active: ${theme.color.mono_100};
      --solid-button-font-color: ${theme.color.text_black};
      --solid-button-font-weight: ${theme.fontWeight.regular};
    }

    &[data-style-color='blue'] {
      --solid-button-color: ${theme.color.blue};
      --solid-button-color-hover: ${theme.color.blue_hover};
      --solid-button-color-active: ${theme.color.blue_active};
    }

    &[data-style-color='red'] {
      --solid-button-color: ${theme.color.red};
      --solid-button-color-hover: ${theme.color.red_hover};
      --solid-button-color-active: ${theme.color.red_active};
    }

    &[data-style-color='green'] {
      --solid-button-color: ${theme.color.green};
      --solid-button-color-hover: ${theme.color.green_hover};
      --solid-button-color-active: ${theme.color.green_active};
    }
  }

  &[data-is-disabled='true'] {
    --solid-button-color: ${theme.color.text_disabled};
    cursor: not-allowed;
  }

  // Shadow variants
  &[data-style-flat='true'] {
    box-shadow: none;
  }

  // Size variants
  &[data-style-compact='true'] {
    grid-gap: 6px;
    padding-left: ${su(1.5)};
    padding-right: ${su(1.5)};
  }

  // Width variants
  &[data-style-width='full'] {
    width: 100%;
  }
`;
