import { useDevice } from '../../store/devices/useDevices';
import { useZone } from '../../store/zones/useZones';
import { useApp } from '../../store/apps/useApps';
import { useI18n } from '../../hooks/useI18nFormatters';

export class GroupOwner {
  static getDeviceProps({ device, zone }) {
    return {
      ownerName: device?.name ?? '-',
      ownerZoneName: zone?.name ?? '-',
    };
  }

  static getAppProps({ appId, app }) {
    return {
      ownerName: app?.name ?? appId ?? '-',
      ownerZoneName: null,
    };
  }

  static getManagerProps({ managerId, i18n }) {
    const ownerName = (() => {
      switch (managerId) {
        case 'logic':
          return i18n.messageFormatter('manager.logic.name');
        case 'system':
          return i18n.messageFormatter('manager.system.name');
        case 'weather':
          return i18n.messageFormatter('manager.weather.name');
        case 'apps':
          return i18n.messageFormatter('manager.apps.name');
        default:
          return '-';
      }
    })();

    return {
      ownerName: ownerName,
      ownerZoneName: null,
    };
  }

  static getDefaultProps() {
    return {
      ownerName: 'Missing Owner',
      ownerZoneName: null,
    };
  }
}

export function GroupOwnerItem(props) {
  const ownerType = props.ownerType;

  switch (ownerType) {
    case 'device': {
      const deviceId = props.ownerId;
      return <DeviceItem rootProps={props} deviceId={deviceId} />;
    }
    case 'app': {
      const appId = props.ownerId;
      return <AppItem rootProps={props} appId={appId} />;
    }
    case 'manager': {
      const managerId = props.ownerId;
      return <ManagerItem rootProps={props} managerId={managerId} />;
    }
    default: {
      return <RootItem rootProps={props} />;
    }
  }
}

export function RootItem(props) {
  const { rootProps } = props;
  const { component: Component, ...rest } = rootProps;
  return <Component {...rest} {...GroupOwner.getDefaultProps({})} />;
}

export function ManagerItem(props) {
  const { i18n } = useI18n();

  const { rootProps } = props;
  const { component: Component, ...rest } = rootProps;
  return (
    <Component
      {...rest}
      {...GroupOwner.getManagerProps?.({
        managerId: props.managerId,
        i18n,
      })}
    />
  );
}

export function DeviceItem(props) {
  const { device } = useDevice({ deviceId: props.deviceId });
  const { zone } = useZone({ zoneId: device?.zone });

  const { rootProps } = props;
  const { component: Component, ...rest } = rootProps;
  return <Component {...rest} {...GroupOwner.getDeviceProps?.({ device, zone })} />;
}

export function AppItem(props) {
  const { app } = useApp({ appId: props.appId });

  const { rootProps } = props;
  const { component: Component, ...rest } = rootProps;
  return <Component {...rest} {...GroupOwner.getAppProps?.({ appId: props.appId, app })} />;
}
