import { useRef, useEffect, useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mergeProps, useButton, useTextField, useHover, usePress, useFocusRing } from 'react-aria';

import { promptMessage } from '../../../components/overlay/DialogPrompt';
import { LogicStore } from '../../../store/logic/LogicStore';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useVariable } from '../../../store/logic/useLogic';

import { mergeRefs } from '../../../lib/mergeRefs';
import { theme } from '../../../theme/theme';
import { input } from '../../../theme/Global';

import { Icon, RoundIconWrapper } from '../../../components/common/Icon';
import { BooleanSelect } from './BooleanSelect';

import { iconCloseBold } from '../../../theme/icons/interface/close-bold';
import { iconBoolean } from '../../../theme/icons/logic/boolean';
import { iconNumber } from '../../../theme/icons/logic/number';
import { iconText } from '../../../theme/icons/logic/text';

const iconMap = {
  boolean: {
    url: iconBoolean,
    color: theme.color.logic_boolean,
  },
  number: {
    url: iconNumber,
    color: theme.color.logic_number,
  },
  string: {
    url: iconText,
    color: theme.color.logic_string,
  },
};

export function VariablesListItem({ variableId }) {
  const { i18n } = useI18n();
  const { variable } = useVariable({ variableId });

  const nameRef = useRef();
  const valueRef = useRef();
  const buttonRef = useRef();
  const [isEditName, setIsEditName] = useState(false);
  const [isEditValue, setIsEditValue] = useState(false);

  const hover = useHover({});
  const button = useButton(
    {
      onPress: () => {
        promptMessage({
          type: 'danger',
          message: i18n.messageFormatter('logic.removeVariablePrompt', {
            name: variable.name,
          }),
        })
          .then(() => {
            LogicStore.deleteVariable({
              id: variable?.id,
            });
          })
          .catch(() => {});
      },
    },
    buttonRef
  );

  const focusRing = useFocusRing({});

  if (variable == null) return null;

  function onRequestSaveName({ name }) {
    LogicStore.saveVariableName({
      id: variable.id,
      type: variable.type,
      name: name,
    });
  }

  function onRequestSaveValue({ value }) {
    LogicStore.saveVariableValue({
      id: variable.id,
      type: variable.type,
      value: value,
    });
  }

  const inputType = getInputType(variable.type);

  const isDeleteIconVisible = hover.isHovered || focusRing.isFocusVisible;

  return (
    <sc.ListItem>
      <sc.VariableButton
        {...mergeProps(hover.hoverProps, focusRing.focusProps, button.buttonProps)}
        ref={buttonRef}
      >
        <RoundIconWrapper
          size={theme.icon.size_default}
          color={isDeleteIconVisible ? theme.color.danger : iconMap[variable.type].color}
        >
          <Icon
            size={isDeleteIconVisible ? '8px' : theme.icon.size_default}
            url={isDeleteIconVisible ? iconCloseBold : iconMap[variable.type].url}
            color={theme.color.icon_white}
          />
        </RoundIconWrapper>
      </sc.VariableButton>

      <TextField
        ref={nameRef}
        type="text"
        align="left"
        aria-label="Name"
        placeholder={i18n.messageFormatter(`logic.namePlaceholder`)}
        defaultValue={variable.name}
        isReadOnly={isEditName === false}
        onPress={() => {
          setIsEditName(true);
        }}
        onBlur={(event) => {
          const value = event.target.value;
          setIsEditName(false);

          onRequestSaveName({
            name: value,
          });
        }}
        onKeyDown={(event) => {
          const key = event.key;
          switch (key) {
            case 'Tab':
              setIsEditValue(true);
              break;
            default:
              break;
          }
        }}
        onKeyUp={(event) => {
          const key = event.key;
          switch (key) {
            case 'Enter':
              const value = event.target.value;
              setIsEditName(false);

              onRequestSaveName({
                name: value,
              });
              break;
            case 'Escape':
              setIsEditName(false);
              break;
            default:
              break;
          }
        }}
      />

      {inputType === 'select' ? (
        <BooleanSelect
          align="right"
          aria-label="Value"
          defaultValue={variable.value}
          onChange={(value) => {
            onRequestSaveValue({
              value: value,
            });
          }}
        />
      ) : (
        <TextField
          ref={valueRef}
          type={inputType}
          align="right"
          aria-label="Value"
          placeholder={i18n.messageFormatter(`logic.${variable.type}Placeholder`)}
          isReadOnly={isEditValue === false}
          defaultValue={variable.value}
          onPress={() => {
            setIsEditValue(true);
          }}
          onBlur={(event) => {
            const value = event.target.value;
            setIsEditValue(false);

            onRequestSaveValue({
              value: value,
            });
          }}
          onKeyDown={(event) => {
            const key = event.key;
            switch (key) {
              case 'Tab':
                setIsEditName(true);
                break;
              default:
                break;
            }
          }}
          onKeyUp={(event) => {
            const key = event.key;

            switch (key) {
              case 'Enter':
                const value = event.target.value;
                setIsEditValue(false);

                onRequestSaveValue({
                  value: value,
                });
                break;
              case 'Escape':
                setIsEditValue(false);
                break;
              default:
                break;
            }
          }}
        />
      )}
    </sc.ListItem>
  );
}

const TextField = forwardRef(function (props, forwardedRef) {
  const ref = useRef();
  const textField = useTextField(props, ref);
  const press = usePress({
    onPress: props.onPress,
  });

  useEffect(() => {
    if (props.isReadOnly === false) {
      ref.current.focus();
    }
  }, [props.isReadOnly]);

  useEffect(() => {
    if (props.isReadOnly === true) {
      ref.current.value = props.defaultValue;
    }
  }, [props.defaultValue, props.isReadOnly]);

  return (
    <sc.Input
      {...mergeProps(textField.inputProps, props.isReadOnly ? press.pressProps : {})}
      aria-label={props['aria-label']}
      title={props.defaultValue}
      align={props.align}
      isReadOnly={props.isReadOnly}
      justifySelf={props.justifySelf}
      ref={mergeRefs([ref, forwardedRef])}
      step={props.type === 'number' ? 'any' : undefined}
    />
  );
});

const VariableButton = styled.button`
  //cursor: pointer;
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-auto-rows: 40px;
  grid-gap: 10px;

  align-items: center;
  outline: 0;
  padding: 0 20px;
  line-height: 20px;

  &:hover {
    background-color: ${theme.color.background_hover};
  }
`;

const Input = styled.input`
  ${input};
  min-width: 0;
  background-color: ${theme.input.background};
  border: 1px solid ${theme.input.border};
  outline: 0;
  border-radius: ${theme.borderRadius.default};
  line-height: 30px;

  text-align: ${(props) => props.align};
  padding: 0 5px;
  margin-${(props) => props.align}: -6px;

  &:hover {
    border-color: ${theme.input.border};
    background: ${theme.color.component};
  }

  ${(props) =>
    props.isReadOnly &&
    css`
      background-color: transparent;
      border-color: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: ${theme.color.background_hover};
      }
    `};
`;

const sc = {
  ListItem,
  VariableButton,
  Input,
};

function getInputType(type) {
  switch (type) {
    case 'string':
      return 'text';
    case 'number':
      return 'number';
    case 'boolean':
      return 'select';
    default:
      return 'text';
  }
}
