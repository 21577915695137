import React from 'react';
import styled from '@emotion/styled';

import { theme } from '../../../../theme/theme';
import { animationFade } from '../../../../theme/animations/animationFade';

export function PairingView(props) {
  return <PairingView.Root>{props.children}</PairingView.Root>;
}

PairingView.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex: 1 1 auto;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;
`;
