import React, { useEffect, useState } from 'react';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

import { iconWarningCircle } from '../../theme/icons/interface/warning-circle/warning-circle';

export function IndicatorAlarmSpecific({ device, capabilities, capabilityId }) {
  const capability = capabilities[capabilityId];
  const [value, setValue] = useState(capability?.value);

  useEffect(() => {
    if (capability != null) {
      const unregister = capability.onChange(({ value }) => {
        setValue(value);
      });

      setValue(capability.value);

      return function () {
        unregister();
      };
    }
  }, [capability]);

  if (value !== true) return null;

  return <Icon display="block" url={iconWarningCircle} color={theme.color.yellow} size="24px" />;
}
