import { useEffect, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useKeyboard, useLabel } from 'react-aria';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { argInput } from './argInput';

import { ArgumentBase, Argument } from './Argument';

export function ArgumentTime(props) {
  const { i18n } = useI18n();

  const label = props.argument.title ?? i18n.messageFormatter('flow.argument.time.title');
  const placeholder = props.argument.placeholder ?? '';

  const { localValue, storeValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  let renderValue = null;

  switch (true) {
    case localValue != null:
      renderValue = localValue;
      break;
    default:
      renderValue = props.argumentTypeText;
      break;
  }

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard() {
    if (storeValue !== localValue) {
      const nextValue = localValue != null ? formatTimeValue(localValue) : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    const nextValue = value == null ? undefined : value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard();
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleRequestCancel() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleRequestCancel}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-invalid={isInvalid}
            data-is-empty={localValue == null}
          >
            {renderValue}
          </ArgumentBase>
        );
      }}
      renderOverlay={() => {
        return (
          <TimeInput
            label={label}
            placeholder={placeholder}
            value={localValue}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
          />
        );
      }}
    />
  );
}

function TimeInput(props) {
  const inputRef = useRef();
  const label = useLabel({
    label: props.label,
  });

  const keyboard = useKeyboard(
    {
      onKeyDown(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
          props.onSaveRequest();
        } else if (event.key === 'Delete') {
          event.preventDefault();
          props.onChange(undefined);
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
      onKeyUp(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
        } else if (event.key === 'Delete') {
          event.preventDefault();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
    },
    inputRef
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }, []);

  return (
    <argInput.InputContainer style={{ '--width': '160px' }}>
      <argInput.InputLabel {...label.labelProps}>{props.label}</argInput.InputLabel>
      <argInput.InputRow>
        <argInput.Input
          {...label.fieldProps}
          {...keyboard.keyboardProps}
          ref={inputRef}
          type="time"
          value={props.value ?? ''}
          placeholder={props.placeholder}
          onChange={(event) => {
            event.stopPropagation();
            const date = event.target.valueAsDate;
            let nextValue = undefined;

            if (date == null) {
              nextValue = undefined;
            } else {
              const hours = `00${date.getUTCHours()}`.slice(-2);
              const minutes = `00${date.getUTCMinutes()}`.slice(-2);
              nextValue = `${hours}:${minutes}`;
            }

            props.onChange(nextValue);
          }}
        />
      </argInput.InputRow>
    </argInput.InputContainer>
  );
}

function formatTimeValue(value) {
  // remove any garbage
  let returnValue = value.replace(/\D/g, '');

  // pick the last 4 (0000)
  // handle 930 case for example
  returnValue = `0${returnValue}`.slice(-4);

  // if length isnt 4 yet just add leading zeros
  if (returnValue.length !== 4) {
    returnValue = `0000${returnValue}`.slice(-4);
  }

  // readd or add :
  if (returnValue.includes(':') === false) {
    returnValue = `${returnValue.slice(-4, -2)}:${returnValue.slice(-2)}`;
  }

  return returnValue;
}
