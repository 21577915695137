import { css } from '@emotion/react';

import { theme } from '../theme';

const card = css`
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
`;

export const containers = {
  card,
};
