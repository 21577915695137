import React from 'react';
import styled from '@emotion/styled';

import { useSectionContext } from '../../../list-box/ListBox';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { viewport } from '../../../../theme/classes/responsive';

export function GridSection(props) {
  // eslint-disable-next-line no-unused-vars
  const { section, state, item, value, children } = useSectionContext();

  return (
    <GridSection.Root as={props.as} {...section.itemProps}>
      {item.rendered && (
        <GridSection.Heading {...section.headingProps}>{item.rendered}</GridSection.Heading>
      )}
      <GridSection.Children {...section.groupProps}>{children}</GridSection.Children>
    </GridSection.Root>
  );
}

GridSection.Heading = styled.div`
  margin-left: ${su(1)};
  margin-bottom: ${su(1)};
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
`;

GridSection.Root = styled.li`
  margin-bottom: ${su(2)};
`;

GridSection.Children = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${su(1)};
  margin: ${su(-1)};
  list-style: none;

  @media only screen and (min-width: ${viewport.medium}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
