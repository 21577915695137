import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { Icon } from './Icon';

export const SpinningIcon = styled(Icon)`
  animation: ${keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`} 2s linear infinite;
`;
