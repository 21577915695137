import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTreeState, Item as CollectionItem } from 'react-stately';
import { useMenu, useMenuItem, useFocusRing, mergeProps } from 'react-aria';

import { theme } from '../../theme/theme';

export const MenuItem = CollectionItem;

export function Menu(props) {
  const menuRef = useRef();
  const treeState = useTreeState({ ...props, selectionMode: 'none' });
  const menu = useMenu(props, treeState, menuRef);

  return (
    <MenuContainer {...menu.menuProps} ref={menuRef}>
      {[...treeState.collection].map((item) => {
        return <Item key={item.key} item={item} treeState={treeState} onAction={props.onAction} />;
      })}
    </MenuContainer>
  );
}

function Item({ item, treeState, onAction }) {
  const itemRef = useRef();

  const menuItem = useMenuItem(
    {
      key: item.key,
      isDisabled: item.props.isDisabled,
      onAction: item.props.onAction ?? onAction,
    },
    treeState,
    itemRef
  );

  const focusRing = useFocusRing();

  return (
    <MenuItemContainer
      {...mergeProps(menuItem.menuItemProps, focusRing.focusProps)}
      ref={itemRef}
      isFocusVisible={focusRing.isFocusVisible}
      isDisabled={item.props.isDisabled}
      warning={item.props.warning}
    >
      {item.rendered}
    </MenuItemContainer>
  );
}

const MenuContainer = styled.ul`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
`;

const MenuItemContainer = styled.li`
  ${getBackgroundColor};
  padding: 0;
  outline: 0;
  transition: ${theme.transition.micro};
`;

function getBackgroundColor({ isFocusVisible, isDisabled, warning }) {
  return css`
    opacity: ${isDisabled ? 0.2 : 1};
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};

    &:hover {
      background-color: ${!isDisabled && warning
        ? theme.color.danger_background
        : theme.color.background_hover};
    }
  `;
}
