import React from 'react';

import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';

import { iconWarningCircle } from '../../theme/icons/interface/warning-circle/warning-circle';

export function IndicatorAlarmStatic() {
  return <Icon display="block" url={iconWarningCircle} color={theme.color.yellow} size="24px" />;
}
