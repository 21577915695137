import { shallow } from 'zustand/shallow';

import { FlowCardStore } from '../../../../store/flow-cards/FlowCardStore';
import { ResourceUtils } from '../../../../store/ResourceUtils';

import { getConnectionKey } from './deriveConnections';

import { connectorTypesMap, connectorOutputTypesList } from '../connectors/connectorTypes';

export async function testAdvancedFlow({
  fromNodeId,
  tokens = {},
  onBeforeStart,
  onStart,
  onCardStart,
  onCardUpdate,
  onCardEnd,
  onCardError,
  onConnectionStart,
  onConnectionError,
}) {
  const state = this.get();

  // Already testing so dont do anything.
  if (state.conditionTest === this.conditionTest.testing) {
    return;
  }

  // This might not be loaded but since we only call this on the Flow page they should be loaded.
  // Should probably throw if they are not loaded. The only issue it causes is that action card tokens might
  // become undefined.
  const cards = FlowCardStore.get();

  const cardData = state.advancedFlow.cards[fromNodeId];

  // Find all the reachable cards and connections so we can grey out cards that can never run.
  const reachableNodes = new Set([]);
  const reachableConnections = new Set([]);

  function traverseChildren({ id, cardData }) {
    if (cardData == null) return;

    reachableNodes.add(id);

    for (const outputType of connectorOutputTypesList) {
      if (cardData[outputType] != null) {
        for (const childId of cardData[outputType]) {
          const connectionKey = getConnectionKey({
            fromNodeId: id,
            toNodeId: childId,
            fromConnectorType: connectorTypesMap.Out,
            toConnectorType: outputType,
          });

          reachableConnections.add(connectionKey);

          traverseChildren({
            id: childId,
            cardData: state.advancedFlow.cards[childId],
          });
        }
      }
    }
  }

  traverseChildren({ id: fromNodeId, cardData });

  await onBeforeStart({ reachableNodes, reachableConnections });

  this.set({ conditionTest: this.conditionTest.testing, testNodeId: fromNodeId });

  const memoryByFirstNodeIdMap = {};

  await onStart({ reachableNodes, reachableConnections });
  await new Promise((resolve) => setTimeout(resolve, 0));

  // Recursive run behavior
  const runChildren = async function ({
    firstNodeId,
    parentNodeId,
    children = [],
    childrenType,
    tokens = {},
  }) {
    const state = this.get();
    if (state.conditionTest !== this.conditionTest.testing) return;

    const promises = [];
    memoryByFirstNodeIdMap[firstNodeId] = memoryByFirstNodeIdMap[firstNodeId] || {};

    for (const nodeId of children) {
      const connectionKey = `${parentNodeId}::${nodeId}::${childrenType}::${connectorTypesMap.In}`;
      const cardData = state.advancedFlow.cards[nodeId];

      // Init memory for this node.
      memoryByFirstNodeIdMap[firstNodeId][nodeId] =
        memoryByFirstNodeIdMap[firstNodeId][nodeId] || {};

      const nodeMemory = memoryByFirstNodeIdMap[firstNodeId][nodeId];

      if (
        (nodeMemory.didRun === true && cardData.type !== 'all') ||
        nodeMemory.isFinished === true
      ) {
        // Already ran this card in a branch of the current trigger.
        promises.push(onConnectionError({ connectionKey }).catch(console.error));
        continue;
      }

      // Set didRun to true for this cardDataId for the current firstNodeId branch.
      nodeMemory.didRun = true;
      // All cards can have multiple hits.
      if (cardData.type !== 'all') {
        nodeMemory.isFinished = true;
      }

      // Reuse parent tokens reference.
      let localTokens = tokens;

      // Allow parallel execution of children.
      const promise = Promise.resolve().then(async () => {
        // Can fail silently on start points if parentNodeId === null.
        await onConnectionStart({ connectionKey });

        let result;

        try {
          switch (cardData.type) {
            case 'action':
              {
                await onCardStart({ nodeId });

                const response = await new Promise((resolve, reject) => {
                  let unsub = null;

                  let duration;

                  // runFlowCardAction accepts duration in seconds or args.duration in milliseconds.
                  // We cant send the whole object because the endpoint doesnt support that yet?

                  // Would prefer it to just be consistent in ms or be able to just send the whole object
                  // and let the backend do the conversion it normally does during card.run with the
                  // saved card data. Now it's just confusing.
                  if (cardData.duration != null) {
                    const multiplier = cardData.duration.multiplier;
                    const number = parseDuration(cardData.duration.number);
                    duration = multiplier * number;
                  }

                  state.api.flow
                    .runFlowCardAction({
                      $timeout: 60000,
                      uri: ResourceUtils.getOwnerUriV3(cardData),
                      id: cardData.id,
                      args: cardData.args,
                      droptoken: cardData.droptoken,
                      duration: duration,
                      tokens: localTokens,
                    })
                    .then((response) => {
                      unsub?.();
                      if (response.error != null) {
                        response.error.usedTokens = response.usedTokens;
                        response.error.elapsedTime = response.elapsedTime;
                        reject(response.error);
                        return;
                      }
                      resolve(response);
                    })
                    .catch((error) => {
                      unsub?.();
                      reject(error);
                    });

                  // Listen for stop changes.
                  unsub = this.store.subscribe(
                    (state) => ({ conditionTest: state.conditionTest }),
                    ({ conditionTest }) => {
                      if (conditionTest === this.conditionTest.stopped) {
                        unsub();
                        resolve('stopped');
                      }
                    },
                    { equalityFn: shallow }
                  );
                });

                if (
                  this.get().conditionTest !== this.conditionTest.testing ||
                  response === 'stopped'
                ) {
                  return;
                }

                result = response?.result;
                let returnTokens = response?.returnTokens;

                localTokens = { ...localTokens };
                const cardKey = ResourceUtils.getKey(cardData);
                const card = cards.actions.byKey?.[cardKey];

                // If the card contains a tokens field extend the tokens with the result value.
                for (const token of card?.tokens ?? []) {
                  const tokenValue = returnTokens != null ? returnTokens[token.id] : null;
                  localTokens[`action::${nodeId}::${token.id}`] = tokenValue;
                }

                await onCardEnd({
                  nodeId,
                  result,
                  tokens: localTokens,
                  usedTokens: response.usedTokens,
                  elapsedTime: response.elapsedTime,
                });
              }
              break;
            case 'condition':
              {
                await onCardStart({ nodeId });

                const response = await new Promise((resolve, reject) => {
                  let unsub = null;

                  state.api.flow
                    .runFlowCardCondition({
                      $timeout: 60000,
                      uri: ResourceUtils.getOwnerUriV3(cardData),
                      id: cardData.id,
                      args: cardData.args,
                      droptoken: cardData.droptoken,
                      tokens: localTokens,
                    })
                    .then((response) => {
                      unsub?.();
                      if (response.error != null) {
                        response.error.usedTokens = response.usedTokens;
                        reject(response.error);
                        return;
                      }
                      resolve(response);
                    })
                    .catch((error) => {
                      unsub?.();
                      reject(error);
                    });

                  // Listen for stop changes.
                  unsub = this.store.subscribe(
                    (state) => ({ conditionTest: state.conditionTest }),
                    ({ conditionTest }) => {
                      if (conditionTest === this.conditionTest.stopped) {
                        unsub();
                        resolve('stopped');
                      }
                    },
                    { equalityFn: shallow }
                  );
                });

                if (
                  this.get().conditionTest !== this.conditionTest.testing ||
                  response === 'stopped'
                ) {
                  return;
                }

                // TODO
                // Conditions can return null which we consider as false or should we error?
                if (response.result == null) {
                  response.result = false;
                }

                result = cardData.inverted ? !response.result : response.result;
                await onCardEnd({
                  nodeId,
                  result,
                  tokens: localTokens,
                  usedTokens: response.usedTokens,
                  elapsedTime: response.elapsedTime,
                });
              }
              break;
            case 'delay':
              {
                const multiplier = cardData.args.delay.multiplier;
                const number = parseDuration(cardData.args.delay.number);
                const delay = multiplier * number * 1000;

                await onCardStart({ nodeId, delay });

                const response = await new Promise((resolve, reject) => {
                  let unsub = null;

                  const timeout = setTimeout(() => {
                    unsub?.();
                    resolve();
                  }, delay);

                  // Listen for stop changes.
                  unsub = this.store.subscribe(
                    (state) => ({ conditionTest: state.conditionTest }),
                    ({ conditionTest }) => {
                      if (conditionTest === this.conditionTest.stopped) {
                        clearTimeout(timeout);
                        unsub();
                        resolve('stopped');
                      }
                    },
                    { equalityFn: shallow }
                  );
                });

                if (
                  this.get().conditionTest !== this.conditionTest.testing ||
                  response === 'stopped'
                ) {
                  return;
                }

                await onCardEnd({ nodeId, result: null, tokens: localTokens });
              }
              break;
            case 'all':
              {
                const isStartingPoint = nodeId === firstNodeId && parentNodeId == null;
                const thisNodeParents = this.get().nodeParents[nodeId];
                const parentConnectorKey = `${parentNodeId}::${childrenType}`;

                if (
                  isStartingPoint === false &&
                  thisNodeParents.some((parentNode) => {
                    return (
                      parentNode.id === parentNodeId &&
                      parentNode.fromConnectorType === childrenType
                    );
                  }) === false
                ) {
                  // Should not be possible.
                  throw new Error(`${parentNodeId} attempted to start a non linked all card.`);
                }

                // Only call start once on the first hit.
                if (nodeMemory.inputRunMap == null) {
                  await onCardStart({ nodeId });
                }

                nodeMemory.inputRunMap = nodeMemory.inputRunMap || {};

                if (nodeMemory.inputRunMap[parentConnectorKey] != null) {
                  // Should not be possible.
                  throw new Error(`${parentConnectorKey} attempted to start a all card twice.`);
                }

                nodeMemory.inputRunMap[parentConnectorKey] = {
                  tokens: localTokens,
                };

                const runCount = Object.keys(nodeMemory.inputRunMap).length;
                const continueCount = thisNodeParents.length;

                if (runCount !== continueCount && isStartingPoint === false) {
                  await onCardUpdate({
                    nodeId,
                    data: {
                      runCount: runCount,
                      continueCount: continueCount,
                      doneConnectorKeysSet: new Set(Object.keys(nodeMemory.inputRunMap)),
                    },
                  });

                  // We can't continue because not all inputs are hit so we bail here.
                  return;
                }

                if (this.get().conditionTest !== this.conditionTest.testing) {
                  return;
                }

                localTokens = { ...localTokens };

                // Assign all ancestor tokens.
                Object.values(nodeMemory.inputRunMap).forEach((node) => {
                  Object.assign(localTokens, node.tokens);
                });

                nodeMemory.isFinished = true;

                await onCardEnd({
                  nodeId,
                  result: null,
                  tokens: localTokens,
                  data: {
                    runCount: isStartingPoint === false ? runCount : 0,
                    continueCount: continueCount,
                    doneConnectorKeysSet: new Set(Object.keys(nodeMemory.inputRunMap)),
                  },
                });
              }
              break;
            case 'any':
              {
                const isStartingPoint = nodeId === firstNodeId && parentNodeId == null;
                const thisNodeParents = this.get().nodeParents[nodeId];

                if (
                  isStartingPoint === false &&
                  thisNodeParents.some((parentNode) => {
                    return parentNode.id === parentNodeId;
                  }) === false
                ) {
                  // Should not be possible.
                  throw new Error(
                    'A card attempted to start a non linked card or a one way linked card.'
                  );
                }

                if (this.get().conditionTest !== this.conditionTest.testing) {
                  return;
                }

                await onCardEnd({
                  nodeId,
                  result: null,
                  tokens: localTokens,
                  data: {
                    runCount: isStartingPoint === false ? 1 : 0,
                    continueCount: 1,
                  },
                });
              }
              break;
            case 'start':
              await onCardEnd({ nodeId });

              break;
            default:
              // This basically means there somehow is a connection with a non connection card type.
              // If this happens we just return. Only seems likely if someone creates their own
              // AdvancedFlow via the API.
              return;
          }
        } catch (error) {
          console.warn(error);

          if (this.get().conditionTest !== this.conditionTest.testing) {
            return;
          }

          // If the card errors we need to check if we need to run outputError children. If there
          // are no children we stop executing here and throw the error meaning this branch is done.

          let errorToken = getErrorToken(error);
          localTokens = { ...localTokens };
          localTokens[`card::${nodeId}::error`] = errorToken;

          await onCardError({
            nodeId,
            error,
            tokens: localTokens,
            usedTokens: error?.usedTokens ?? null,
            elapsedTime: error.elapsedTime,
          });

          if (cardData[connectorTypesMap.Error] != null) {
            await runChildren({
              firstNodeId: firstNodeId,
              parentNodeId: nodeId,
              children: cardData[connectorTypesMap.Error],
              childrenType: connectorTypesMap.Error,
              tokens: localTokens,
            });

            return;
          }

          // Return here even though this error was not handled so we dont visit any children.
          return;
          // throw error;
        }

        let childrenToRun = [];
        let childrenToRunType = null;

        switch (cardData.type) {
          case 'condition':
            if (result === true) {
              childrenToRun = cardData[connectorTypesMap.True];
              childrenToRunType = connectorTypesMap.True;
            } else {
              childrenToRun = cardData[connectorTypesMap.False];
              childrenToRunType = connectorTypesMap.False;
            }
            break;
          case 'action':
            childrenToRun = cardData[connectorTypesMap.Out];
            childrenToRunType = connectorTypesMap.Out;
            break;
          case 'delay':
          case 'start':
          case 'all':
          case 'any':
            childrenToRun = cardData[connectorTypesMap.Out];
            childrenToRunType = connectorTypesMap.Out;
            break;
          default:
            break;
        }

        await runChildren({
          firstNodeId: firstNodeId,
          parentNodeId: nodeId,
          children: childrenToRun,
          childrenType: childrenToRunType,
          tokens: localTokens,
        });
      });

      promises.push(promise);
    }

    // Wait for all children.
    await Promise.all(promises);
  }.bind(this);

  const branchPromises = [];

  if (cardData.type === 'trigger') {
    await onCardEnd({ nodeId: fromNodeId }).catch(() => {});
    branchPromises.push(
      runChildren({
        firstNodeId: fromNodeId,
        parentNodeId: fromNodeId,
        children: cardData[connectorTypesMap.Out],
        childrenType: connectorTypesMap.Out,
        tokens: tokens,
      })
    );
  } else {
    // We dont run the connection here because we reuse the run logic.
    branchPromises.push(
      runChildren({
        firstNodeId: fromNodeId,
        parentNodeId: null,
        children: [fromNodeId],
        childrenType: connectorTypesMap.Out,
        tokens: tokens,
      })
    );
  }

  // Multiple branches might reject but we only return one error at a time.
  try {
    await Promise.all(branchPromises);
  } catch (error) {
    throw error;
  } finally {
    if (this.get().testNodeId === fromNodeId) {
      this.set({ conditionTest: this.conditionTest.tested });
    }
  }

  return true;
}

function parseDuration(duration) {
  const durationString = String(duration);

  if (durationString.indexOf('-') > 0) {
    const [min, max] = durationString.split('-').map((num) => parseFloat(num));
    return min + Math.random() * (max - min);
  }

  return parseFloat(duration);
}

function getErrorToken(error) {
  let errorToken = null;

  switch (true) {
    case typeof error === 'object' && error !== null:
      // Our own internal errors sometimes have a translated description. Note since we now changed
      // the backend this should no longer be needed since it will always be error.message.
      if (error.description != null) {
        errorToken = error.description;
      } else {
        errorToken = error.message;
      }
      break;
    case typeof error === 'string':
      errorToken = error;
      break;
    default:
      errorToken = 'Error';
      break;
  }

  return errorToken;
}
