import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import type { FieldValues, Control } from 'react-hook-form';
import { useToggleState } from 'react-stately';
import { usePress, useLabel, useCheckbox, VisuallyHidden, mergeProps } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';
import { theme } from '../../theme/theme';

import { Icon } from '../common/Icon';
import { Hint } from './Hint';

import { iconCheckmark } from '../../theme/icons/interface/checkmark';

export interface Props<T extends FieldValues> {
  className?: string;
  name: string;
  label?: string;
  'aria-label'?: string;
  validate: any;
  control?: Control<T>;

  required?: boolean;
  autoFocus?: boolean;
  isDisabled?: boolean;
  isReversed?: boolean;

  inputRef?: React.Ref<HTMLInputElement>;

  checked?: boolean;
  defaultChecked?: boolean;
  value?: string;

  hint: string;

  orientation: 'vertical' | 'horizontal';
  styleSize?: 'small';
  styleColor?: 'blue';
  forceSelectedStyle?: boolean;
  children?: React.ReactNode;

  onBlur?: () => void;
  onChange?: (checked: boolean) => void;
}

export function Checkbox<T extends FieldValues = {}>(props: Props<T>) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const label = useLabel({
    label: props.label,
    'aria-label': props['aria-label'],
  });

  const { autoFocus, ...rest } = props;
  const toggleState = useToggleState({
    ...rest,
    defaultSelected: props.defaultChecked,
    isSelected: props.checked,
    onChange(isSelected) {
      props.onChange?.(isSelected);
    },
  });

  const [isFocused, setIsFocused] = useState(false);

  const checkbox = useCheckbox(
    {
      ...rest,
      ...label.fieldProps,
      defaultSelected: props.defaultChecked,
      isSelected: props.checked,
      onFocusChange(isFocused) {
        setIsFocused(isFocused);
      },
      onBlur() {
        props.onBlur?.();
      },
    },
    toggleState,
    checkboxRef
  );

  useEffect(() => {
    if (autoFocus === true) {
      setTimeout(() => {
        checkboxRef.current?.focus();
      }, 0);
    }
  }, [autoFocus]);

  const press = usePress({
    onPress(event) {
      if (props.isDisabled) return;

      // controlled
      if (props.onChange != null && props.checked != null) {
        props.onChange(!props.checked);
      } else {
        checkboxRef.current?.click();
      }
    },
  });

  const Root =
    props.orientation === 'vertical' ? Checkbox.S.RootVertical : Checkbox.S.RootHorizontal;

  return (
    <Checkbox.S.Root as={Root} className={props.className}>
      <VisuallyHidden>
        {/* @ts-ignore */}
        <input
          {...mergeProps(checkbox.inputProps)}
          ref={mergeRefs([checkboxRef, props.inputRef])}
        />
      </VisuallyHidden>

      {(() => {
        const array = [
          <Checkbox.S.Header>
            {props.label && (
              <Checkbox.S.Label {...label.labelProps} key="label" title={props.label}>
                {props.label}
              </Checkbox.S.Label>
            )}

            {props.hint && <Hint key="hint" hint={props.hint} />}
          </Checkbox.S.Header>,

          <Checkbox.S.Input key="input">
            <Checkbox.S.InputVisual
              {...press.pressProps}
              aria-hidden="true"
              data-is-selected={props.forceSelectedStyle ?? toggleState.isSelected}
              data-is-focused={isFocused}
              data-is-disabled={props.isDisabled}
              data-style-size={props.styleSize}
              data-style-color={props.styleColor}
            >
              <Icon
                url={iconCheckmark}
                color={
                  props.forceSelectedStyle ?? toggleState.isSelected
                    ? theme.color.white
                    : 'transparent'
                }
                size={props.styleSize === 'small' ? theme.icon.size_tiny : theme.icon.size_small}
              />
            </Checkbox.S.InputVisual>
          </Checkbox.S.Input>,
        ];
        if (props.isReversed === true) array.reverse();
        return array;
      })()}

      {props.children}
    </Checkbox.S.Root>
  );
}

function S() {}
Checkbox.S = S;

S.Root = styled.div`
  display: flex;
`;

S.Header = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-gap: 5px;
`;

S.Label = styled.label`
  display: inline-block;
  min-width: 0;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

S.InputVisual = styled.div`
  --checkbox-selected-background-color: ${theme.color.green};
  --checkbox-selected-border-color: ${theme.color.green};
  --checkbox-border-width: 1px;
  --checkbox-size: 24px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  background-color: ${theme.color.component};
  border: var(--checkbox-border-width) solid ${theme.input.border};
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  transition: ${theme.duration.fast} ${theme.curve.easeInOut};
  transition-property: background-color, border;

  &[data-style-size='small'] {
    --checkbox-size: 18px;
    --checkbox-border-width: 2px;

    ${Icon.S.Root} {
      width: ${theme.icon.size_tiny};
      height: ${theme.icon.size_tiny};
    }
  }

  &[data-style-color='blue'] {
    --checkbox-selected-background-color: ${theme.color.blue};
    --checkbox-selected-border-color: ${theme.color.blue};
  }

  &[data-is-selected='true'] {
    background-color: var(--checkbox-selected-background-color);
    border: var(--checkbox-border-width) solid var(--checkbox-selected-border-color);
  }

  &[data-is-focused='true'] {
    border: var(--checkbox-border-width) solid ${theme.input.border_focus};
  }

  &[data-is-disabled='true'] {
    background-color: ${theme.color.text_disabled};
    cursor: not-allowed;
    border: var(--checkbox-border-width) solid ${theme.input.border_disabled};
  }
`;

S.Input = styled.div``;

S.RootHorizontal = styled(S.Root)`
  align-items: center;

  ${S.Label} {
    font-weight: ${theme.fontWeight.regular};
  }

  ${S.Input} {
  }
`;

S.RootVertical = styled(S.Root)`
  flex-direction: column;
  align-items: flex-start;

  ${S.Header} {
    color: ${theme.color.text_light};
    margin-bottom: 5px;
    font-size: ${theme.fontSize.small};
    line-height: 24px;
  }

  ${S.Input} {
  }
`;
