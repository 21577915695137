import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { RouteManager } from '../../RouteManager';
import { ToastManager } from '../../ToastManager';

import { AdvancedFlowStore } from '../../store/advanced-flow/AdvancedFlowStore';
import { FlowStore } from '../../store/flow/FlowStore';
import { FlowFolderStore } from '../../store/flow-folders/FlowFolderStore';
import { FlowUtils } from '../../store/flow/FlowUtils';
import { AdvancedFlowUtils } from '../../store/advanced-flow/AdvancedFlowUtils';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useFlowsByFolderId } from '../../store/flow/useFlows';
import { useFlowFoldersById } from '../../store/flow-folders/useFlowFolders';
import { useAdvancedFlowsByFolderId } from '../../store/advanced-flow/useAdvancedFlows';
import { useFolderTree } from './useFolderTree';
import { useAdvancedFlowdRouteId } from './useAdvancedFlowdRouteId';
import { useFlowdRouteId } from './useFlowdRouteId';
import { useDevice } from '../../store/devices/useDevices';

import { theme } from '../../theme/theme';
import { scrollbars } from '../../theme/elements/scrollbars';
import { dragTypes } from '../../components/dnd/dragTypes';

import { ScrollContext } from '../../components/common/Scroll';

import { TreeView } from '../../components/tree-view/TreeView';
import { TreeItem } from '../../components/tree-view/TreeItem';
import { Icon } from '../../components/common/Icon';
import { SubNav } from '../../components/sub-navigation/SubNavRoot';
import { SubNavItem } from '../../components/sub-navigation/SubNavItem';
import { ChippedSearchField } from '../../components/common/search-field/ChippedSearchField';

import { FlowNavigationItemFlowContextMenuContent } from './FlowNavigationItemFlowContextMenuContent';
import { FlowNavigationItemFlowFolderContextMenuContent } from './FlowNavigationItemFlowFolderContextMenuContent';
import { FlowNavigationItemAdvancedFlowContextMenuContent } from './FlowNavigationItemAdvancedFlowContextMenuContent';

import { iconFlow } from '../../theme/icons/system/flow/flow';
import { iconAdvancedFlow } from '../../theme/icons/interface/advanced-flow/advanced-flow';
import { iconFlowDisabled } from '../../theme/icons/interface/flow-disabled';
import { iconAdvancedFlowDisabled } from '../../theme/icons/interface/advanced-flow-disabled/advanced-flow-disabled';
import { iconWarningSmall } from '../../theme/icons/interface/warning-small/warning-small';

export function FlowNavigation() {
  const { i18n } = useI18n();
  const location = useLocation();

  const scrollRef = useRef();

  const { advancedFlowRouteId } = useAdvancedFlowdRouteId();
  const { flowRouteId } = useFlowdRouteId();

  const [expanded, setExpanded] = useState(() => new Set([FlowFolderStore.rootFolderId]));
  const [chipDeviceId, setChipDeviceId] = useState(null);
  const [cardOwnersFilterSet, setCardOwnersFilterSet] = useState(null);

  const { device } = useDevice({ deviceId: chipDeviceId });

  const flowFolders = useFlowFoldersById();
  const flows = useFlowsByFolderId();
  const advancedFlows = useAdvancedFlowsByFolderId();

  const flowFoldersById = flowFolders.byId;
  const flowsByFolderId = flows.byFolderId;
  const advancedFlowsByFolderId = advancedFlows.byFolderId;

  const { rootFolder, setFilterValue } = useFolderTree({
    flowFoldersById,
    flowsByFolderId,
    advancedFlowsByFolderId,
    cardOwnersFilterSet: cardOwnersFilterSet,
  });

  useEffect(() => {
    if (location.state?.deviceId != null) {
      setChipDeviceId(location.state.deviceId);

      const nextRouteState = {
        ...location.state,
      };
      delete nextRouteState.deviceId;

      RouteManager.replaceState(nextRouteState);
    }
  }, [location.state]);

  useEffect(() => {
    if (chipDeviceId != null) {
      setCardOwnersFilterSet(new Set([`homey:device:${chipDeviceId}`]));
    } else {
      setCardOwnersFilterSet(null);
    }
  }, [chipDeviceId]);

  const handleNodeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  function handleNodeDrop({ dropTarget, dropItem }) {
    if (dropItem.type === dragTypes.FOLDER && dropTarget.type === dragTypes.FOLDER) {
      FlowFolderStore.setFolderParent({
        parentId: dropTarget.id,
        folderId: dropItem.id,
      }).catch(ToastManager.handleError);
    }

    if (dropItem.type === dragTypes.ITEM && dropTarget.type === dragTypes.FOLDER) {
      if (dropItem.nodeType === AdvancedFlowUtils.typeName) {
        AdvancedFlowStore.updateAdvancedFlowFolder({
          folderId: dropTarget.id,
          id: dropItem.id,
        }).catch(ToastManager.handleError);
        return;
      }

      FlowStore.setFlowFolder({
        folderId: dropTarget.id,
        flowId: dropItem.id,
      }).catch(ToastManager.handleError);
    }
  }

  function handleNodeSelect(event, nodeId, nodeData) {
    if (nodeData.type !== 'folder') {
      if (flowRouteId === nodeId || advancedFlowRouteId === nodeId) {
        RouteManager.toFlows();
        return;
      }

      if (AdvancedFlowUtils.isAdvancedFlow(nodeData)) {
        RouteManager.toAdvancedFlow(nodeId);
        return;
      }

      RouteManager.toFlow(nodeId);
    }
  }

  function expandNode({ nodeId }) {
    if (!expanded.has(nodeId)) {
      setExpanded(new Set([...expanded, nodeId]));
    }
  }

  function collapseNode({ nodeId }) {
    if (expanded.has(nodeId)) {
      setExpanded(new Set([...expanded].filter((id) => id !== nodeId)));
    }
  }

  return (
    <SubNav.Root>
      <SubNav.SearchFieldWrapper>
        <ChippedSearchField
          chips={chipDeviceId != null ? [{ key: chipDeviceId, title: device?.name ?? '?' }] : null}
          aria-label="Filter Flows"
          placeholder="Filter..."
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && event.target.value === '') {
              setChipDeviceId(null);
            }
          }}
          onChange={(value) => {
            setFilterValue(value);
          }}
          onRemoveChips={(keys) => {
            setChipDeviceId(null);
          }}
        />
      </SubNav.SearchFieldWrapper>

      <ScrollContext.Provider value={scrollRef}>
        <FlowNavigation.TreeView
          ref={scrollRef}
          expanded={expanded}
          selected={advancedFlowRouteId ?? flowRouteId}
          onNodeToggle={handleNodeToggle}
          onNodeSelect={handleNodeSelect}
          onNodeDrop={handleNodeDrop}
        >
          {rootFolder &&
            renderFolder({
              folder: rootFolder,
              level: 0,
              expandNode,
              collapseNode,
            })}
        </FlowNavigation.TreeView>
      </ScrollContext.Provider>

      <SubNav.Footer>
        <SubNav.NewButton
          onPress={() => {
            RouteManager.toNewFlow();
          }}
        >
          {i18n.messageFormatter('flow.newFlow')}
        </SubNav.NewButton>
      </SubNav.Footer>
    </SubNav.Root>
  );
}

function renderFolder({ folder, level, expandNode, collapseNode }) {
  function handleSaveNameRequest(value) {
    if (folder.id === 'tmp-folder') {
      FlowFolderStore.saveTempFolder({ name: value }).catch(ToastManager.handleError);
      return;
    }

    FlowFolderStore.saveFolderName({
      folderId: folder.id,
      name: value,
    }).catch(ToastManager.handleError);
  }

  function handleCancelNameRequest() {
    if (folder.id === 'tmp-folder') {
      FlowFolderStore.deleteTempFolder();
    }
  }

  return (
    <TreeItem
      key={folder.id}
      dragType={dragTypes.FOLDER}
      level={level}
      nodeId={folder.id}
      nodeData={folder}
      label={folder.name}
      title={folder.name}
      defaultIsRenaming={folder.id === 'tmp-folder'}
      contextMenuContentProps={{
        folder,
        expandNode,
      }}
      contextMenuContent={FlowNavigationItemFlowFolderContextMenuContent}
      onSaveNameRequest={handleSaveNameRequest}
      onCancelNameRequest={handleCancelNameRequest}
    >
      {folder.folderChildren?.map((folder) =>
        renderFolder({
          folder,
          level: level + 1,
          expandNode,
          collapseNode,
        })
      )}
      {folder.flowChildren?.map((flow) =>
        renderFlow({
          flow,
          level: level + 1,
          expandNode,
          collapseNode,
        })
      )}
    </TreeItem>
  );
}

function renderFlow({ flow, level, expandNode, collapseNode }) {
  const isAdvancedFlow = AdvancedFlowUtils.isAdvancedFlow(flow);
  const defaultIcon = isAdvancedFlow === true ? iconAdvancedFlow : iconFlow;

  const icon =
    flow.enabled !== false
      ? defaultIcon
      : isAdvancedFlow
      ? iconAdvancedFlowDisabled
      : iconFlowDisabled;

  function handleSaveNameRequest(value) {
    if (AdvancedFlowUtils.isAdvancedFlow(flow)) {
      AdvancedFlowStore.updateAdvancedFlowName({
        id: flow.id,
        name: value,
      });
    } else {
      FlowStore.saveFlowName({
        flowId: flow.id,
        name: value,
      }).catch(ToastManager.handleError);
    }
  }

  const iconOverride =
    flow.broken === true ? (
      <FlowNavigation.BrokenFlowIcon>
        <SubNavItem.Icon url={icon} size={theme.icon.size_small} />
        <Icon
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
          url={iconWarningSmall}
          color={theme.color.error}
          size={theme.icon.size_small}
        />
      </FlowNavigation.BrokenFlowIcon>
    ) : null;

  const opts = {};

  if (AdvancedFlowUtils.isAdvancedFlow(flow)) {
    opts.nodeType = AdvancedFlowUtils.typeName;
    opts.href = RouteManager.getPathForAdvancedFlow(flow.id);
    opts.contextMenuContent = FlowNavigationItemAdvancedFlowContextMenuContent;
  } else {
    opts.nodeType = FlowUtils.typeName;
    opts.href = RouteManager.getPathForFlow(flow.id);
    opts.contextMenuContent = FlowNavigationItemFlowContextMenuContent;
  }

  return (
    <TreeItem
      key={flow.id}
      dragType={dragTypes.ITEM}
      level={level}
      nodeId={flow.id}
      nodeType={opts.nodeType}
      nodeData={flow}
      href={opts.href}
      label={flow.name}
      title={`${flow.name} ${flow.triggerCount ?? ''}`}
      iconUrl={icon}
      iconOverride={iconOverride}
      isDisabled={flow.enabled === false}
      contextMenuContent={opts.contextMenuContent}
      onSaveNameRequest={handleSaveNameRequest}
    />
  );
}

// todo restore Scroll component
FlowNavigation.TreeView = styled(TreeView)`
  ${scrollbars.dark};
  flex: 1 1 0;
  min-width: 0;
  overflow-y: auto;
`;

FlowNavigation.BrokenFlowIcon = styled.div`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
