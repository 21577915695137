import { useEffect } from 'react';

import { AuthStore } from '../store/AuthStore';

export function LoginPage() {
  useEffect(() => {
    // Directly navigate to the login URL
    const loginUrl = AuthStore.getLoginUrl({ pathname: '/' });
    console.log('loginUrl', loginUrl);
    window.location.href = loginUrl; // Use window.location.href for a full page reload to the login URL
  }, []);

  // Since we're navigating away immediately, no need to return any JSX
  return null;
}
