import styled from '@emotion/styled';
import { useMemo } from 'react';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';

import { notificationSettingsStore } from './NotificationSettingsStore';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../../components/common/Icon';
import { Tooltip } from '../../../components/common/tooltip/Tooltip';
import { SettingsTable } from './SettingsTable';
import { NotificationSettingsTableOwnersCell } from './NotificationSettingsTableOwnersCell';
import { NotificationSettingsTableOwnersColumnHeader } from './NotificationSettingsTableOwnersColumnHeader';

import { iconInfo } from '../../../theme/icons/interface/info';

export function NotificationSettingsTableOwners(props) {
  const { i18n } = useI18n();
  const { notificationSettings } = notificationSettingsStore.use();

  const columns = [
    {
      key: 'category',
      name: i18n.messageFormatter('settings.system.notificationsColumnLabelCategory'),
    },
    {
      key: 'timeline',
      name: i18n.messageFormatter('settings.system.notificationsColumnLabelTimeline'),
      info: i18n.messageFormatter('settings.system.notificationsColumnLabelTimelineHint'),
    },
    {
      key: 'push',
      name: i18n.messageFormatter('settings.system.notificationsColumnLabelPush'),
      info: i18n.messageFormatter('settings.system.notificationsColumnLabelPushHint'),
    },
    { key: 'actions', name: 'Actions' },
  ];

  const rows = useMemo(() => {
    const rows = [];
    // const rolesOrder = ['owner', 'manager', 'user', 'guest'];

    for (const [uri, owner] of Object.entries(notificationSettings.ownersData ?? {})) {
      rows.push({
        key: uri,
        category: owner.uriObj.name,
        size: owner.size,
        timeline: owner.enabled,
        push: owner.push,
      });
    }

    rows.sort((a, b) => {
      return i18n.collator.compare(a.category, b.category);
    });

    return rows;
  }, [notificationSettings.ownersData, i18n]);

  function renderCell() {
    return <NotificationSettingsTableOwnersCell />;
  }

  function renderColumnHeader() {
    return <NotificationSettingsTableOwnersColumnHeader />;
  }

  return (
    <SettingsTable
      type="user"
      aria-label="Table"
      selectionMode="none"
      renderCell={renderCell}
      renderColumnHeader={renderColumnHeader}
    >
      <TableHeader columns={columns}>
        {(column) => {
          if (column.info != null) {
            return (
              <Column>
                {column.name} <Info content={column.info} />
              </Column>
            );
          }

          return <Column>{column.name}</Column>;
        }}
      </TableHeader>

      <TableBody items={rows}>
        {(item) => {
          return (
            <Row>
              {(columnKey) => {
                switch (columnKey) {
                  default:
                    return (
                      <Cell
                        value={item[columnKey]}
                        item={item}
                        textValue={String(item[columnKey])}
                      />
                    );
                }
              }}
            </Row>
          );
        }}
      </TableBody>
    </SettingsTable>
  );
}

function Info(props) {
  return (
    <Tooltip
      offset={10}
      instant={true}
      anchorPointer={true}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <InfoS.IconWrapper>
            <Icon
              {...triggerProps}
              ref={triggerRef}
              size={theme.icon.size_small}
              url={iconInfo}
              color={theme.color.icon_light}
            />
          </InfoS.IconWrapper>
        );
      }}
    >
      <InfoS.Root>{props.content}</InfoS.Root>
    </Tooltip>
  );
}

function InfoS() {}
Info.S = InfoS;

InfoS.Root = styled.div`
  max-width: 320px;
  padding: ${su(1)} ${su(2)};
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
`;

InfoS.IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${su(1)};

  ${Icon.S.Root} {
    cursor: pointer;
  }
`;
