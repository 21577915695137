import React from 'react';
import styled from '@emotion/styled';

import { Icon } from '../common/Icon';

import { iconWarning } from '../../theme/icons/interface/warning/warning';
import { iconCheckCircle } from '../../theme/icons/interface/check-circle';

import { theme } from '../../theme/theme';

export function ControlsMessage(props) {
  const url = props.type === 'warning' ? iconWarning : iconCheckCircle;

  return (
    <ControlsErrorContainer>
      <Icon url={url} size="52px" />
      <div className="title">{props.title}</div>
      <div className="message">{props.message}</div>
    </ControlsErrorContainer>
  );
}

const ControlsErrorContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  .title {
    margin-top: 14px;
    text-align: center;
    color: ${theme.color.text_white};
    font-size: ${theme.fontSize.heading2};
    font-weight: ${theme.fontWeight.medium};
    line-height: 26px;
  }

  .message {
    margin-top: 4px;
    text-align: center;
    color: ${theme.color.text_light};
    font-size: ${theme.fontSize.default};
    line-height: 18px;
  }
`;
