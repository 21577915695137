import { useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { theme } from '../../theme/theme';

import { Icon } from './Icon';

import { iconSpinner } from '../../theme/icons/interface/spinner/spinner';

const animationRotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

export function SpinnerFade(props) {
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  useLayoutEffect(() => {
    if (props.isActive === true) {
      setIsAnimationActive(true);
    }
  }, [props.isActive]);

  if (props.children == null && props.isActive === false) return null;

  return (
    <S.Root style={{ '--size': props.size ?? theme.icon.size_default }}>
      <S.SpinnerWrapper
        data-is-animation-active={props.isActive}
        onTransitionEnd={() => {
          if (props.isActive !== true) {
            setIsAnimationActive(false);
          }
        }}
      >
        {isAnimationActive && <S.Spinner size={props.size} url={iconSpinner} color={props.color} />}
      </S.SpinnerWrapper>

      <S.IconWrapper data-is-animation-active={props.isActive}>{props.children}</S.IconWrapper>
    </S.Root>
  );
}

function S() {}
SpinnerFade.S = S;

S.Root = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

S.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity ${theme.duration.fast} ease-in-out;
  width: var(--size);
  height: var(--size);

  &[data-is-animation-active='true'] {
    opacity: 0;
  }
`;

S.SpinnerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity ${theme.duration.fast} ease-in-out;

  &[data-is-animation-active='true'] {
    opacity: 1;
  }
`;

S.Spinner = styled(Icon)`
  animation-name: ${animationRotate};
  animation-timing-function: ${theme.curve.easeInOut};
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
`;
