import { FlowFolderStore } from '../flow-folders/FlowFolderStore';
import { ResourceUtils } from '../ResourceUtils';

export class FlowUtils {
  static typeName = 'flow';

  static getFolderId(flow) {
    return flow.folder ?? FlowFolderStore.rootFolderId;
  }

  static getOwnerUrisSetFromCards(flow) {
    const cardOwnerUris = new Set();

    if (flow.trigger != null) {
      const ownerUri = ResourceUtils.getOwnerUriV3(flow.trigger);
      cardOwnerUris.add(ownerUri);
    }

    if (flow.conditions?.length) {
      for (const condition of flow.conditions) {
        const ownerUri = ResourceUtils.getOwnerUriV3(condition);
        cardOwnerUris.add(ownerUri);
      }
    }

    if (flow.actions?.length) {
      for (const action of flow.actions) {
        const ownerUri = ResourceUtils.getOwnerUriV3(action);
        cardOwnerUris.add(ownerUri);
      }
    }

    return cardOwnerUris;
  }
}
