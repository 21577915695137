import generic_device from '../images/sensor/generic-device.svg';
import alarm_generic from '../images/sensor/alarm_generic.svg';
import alarm_generic_active from '../images/sensor/alarm_generic_active.svg';
import alarm_motion from '../images/sensor/alarm_motion.svg';
import alarm_motion_active from '../images/sensor/alarm_motion_active.svg';
import alarm_contact from '../images/sensor/alarm_contact.svg';
import alarm_contact_active from '../images/sensor/alarm_contact_active.svg';
import alarm_co from '../images/sensor/alarm_co.svg';
import alarm_co_active from '../images/sensor/alarm_co_active.svg';
import alarm_co2 from '../images/sensor/alarm_co2.svg';
import alarm_co2_active from '../images/sensor/alarm_co2_active.svg';
import alarm_pm25 from '../images/sensor/alarm_pm25.svg';
import alarm_pm25_active from '../images/sensor/alarm_pm25_active.svg';
import alarm_tamper from '../images/sensor/alarm_tamper.svg';
import alarm_tamper_active from '../images/sensor/alarm_tamper_active.svg';
import alarm_smoke from '../images/sensor/alarm_smoke.svg';
import alarm_smoke_active from '../images/sensor/alarm_smoke_active.svg';
import alarm_fire from '../images/sensor/alarm_fire.svg';
import alarm_fire_active from '../images/sensor/alarm_fire_active.svg';
import alarm_heat from '../images/sensor/alarm_temperature.svg';
import alarm_heat_active from '../images/sensor/alarm_temperature_active.svg';
import alarm_water from '../images/sensor/alarm_water.svg';
import alarm_water_active from '../images/sensor/alarm_water_active.svg';
import alarm_battery from '../images/sensor/alarm_battery.svg';
import alarm_battery_active from '../images/sensor/alarm_battery_active.svg';
import alarm_night from '../images/sensor/alarm_night.svg';
import alarm_night_active from '../images/sensor/alarm_night_active.svg';
import alarm_impact from '../images/sensor/alarm_impact.svg';
import alarm_impact_active from '../images/sensor/alarm_impact_active.svg';
import alarm_level from '../images/sensor/alarm_level.svg';
import alarm_level_active from '../images/sensor/alarm_level_active.svg';
import alarm_vibration from '../images/sensor/alarm_vibration.svg';
import alarm_vibration_active from '../images/sensor/alarm_vibration_active.svg';
import meter_power from '../images/sensor/meter_power.svg';
import meter_water from '../images/sensor/meter_water.svg';
import meter_gas from '../images/sensor/meter_gas.svg';
import meter_rain from '../images/sensor/meter_rain.svg';
import measure_power from '../images/sensor/measure_power.svg';
import measure_temperature from '../images/sensor/measure_temperature.svg';
import measure_co from '../images/sensor/measure_co.svg';
import measure_co2 from '../images/sensor/measure_co2.svg';
import measure_pm25 from '../images/sensor/measure_pm25.svg';
import measure_humidity from '../images/sensor/measure_humidity.svg';
import measure_pressure from '../images/sensor/measure_pressure.svg';
import measure_noise from '../images/sensor/measure_noise.svg';
import measure_rain from '../images/sensor/measure_rain.svg';
import measure_wind_strength from '../images/sensor/measure_wind_strength.svg';
import measure_wind_angle from '../images/sensor/measure_wind_angle.svg';
import measure_gust_strength from '../images/sensor/measure_wind_strength.svg';
import measure_gust_angle from '../images/sensor/measure_wind_angle.svg';
import measure_battery from '../images/sensor/alarm_battery.svg';
import measure_voltage from '../images/sensor/measure_power.svg';
import measure_current from '../images/sensor/measure_power.svg';
import measure_luminance from '../images/sensor/measure_luminance.svg';
import measure_ultraviolet from '../images/sensor/measure_ultraviolet.svg';
import measure_water from '../images/sensor/alarm_water.svg';

export function getSensorImages(capabilityId, capability) {
  const [actualId] = capabilityId.split('.');

  switch (actualId) {
    case 'alarm_generic':
      return {
        default: alarm_generic,
        active: alarm_generic_active,
      };
    case 'alarm_motion':
      return {
        default: alarm_motion,
        active: alarm_motion_active,
      };
    case 'alarm_contact':
      return {
        default: alarm_contact,
        active: alarm_contact_active,
      };
    case 'alarm_co':
      return {
        default: alarm_co,
        active: alarm_co_active,
      };
    case 'alarm_co2':
      return {
        default: alarm_co2,
        active: alarm_co2_active,
      };
    case 'alarm_pm25':
      return {
        default: alarm_pm25,
        active: alarm_pm25_active,
      };
    case 'alarm_tamper':
      return {
        default: alarm_tamper,
        active: alarm_tamper_active,
      };
    case 'alarm_smoke':
      return {
        default: alarm_smoke,
        active: alarm_smoke_active,
      };
    case 'alarm_fire':
      return {
        default: alarm_fire,
        active: alarm_fire_active,
      };
    case 'alarm_heat':
      return {
        default: alarm_heat,
        active: alarm_heat_active,
      };
    case 'alarm_water':
      return {
        default: alarm_water,
        active: alarm_water_active,
      };
    case 'alarm_battery':
      return {
        default: alarm_battery,
        active: alarm_battery_active,
      };
    case 'alarm_night':
      return {
        default: alarm_night,
        active: alarm_night_active,
      };
    case 'alarm_impact':
      return {
        default: alarm_impact,
        active: alarm_impact_active,
      };
    case 'alarm_level':
      return {
        default: alarm_level,
        active: alarm_level_active,
      };
    case 'alarm_vibration':
      return {
        default: alarm_vibration,
        active: alarm_vibration_active,
      };
    case 'meter_power':
      return {
        default: meter_power,
        active: null,
      };
    case 'meter_water':
      return {
        default: meter_water,
        active: null,
      };
    case 'meter_gas':
      return {
        default: meter_gas,
        active: null,
      };
    case 'meter_rain':
      return {
        default: meter_rain,
        active: null,
      };
    case 'measure_temperature':
      return {
        default: measure_temperature,
        active: null,
      };
    case 'measure_co':
      return {
        default: measure_co,
        active: null,
      };
    case 'measure_co2':
      return {
        default: measure_co2,
        active: null,
      };
    case 'measure_pm25':
      return {
        default: measure_pm25,
        active: null,
      };
    case 'measure_humidity':
      return {
        default: measure_humidity,
        active: null,
      };
    case 'measure_pressure':
      return {
        default: measure_pressure,
        active: null,
      };
    case 'measure_noise':
      return {
        default: measure_noise,
        active: null,
      };
    case 'measure_rain':
      return {
        default: measure_rain,
        active: null,
      };
    case 'measure_wind_strength':
      return {
        default: measure_wind_strength,
        active: null,
      };
    case 'measure_wind_angle':
      return {
        default: measure_wind_angle,
        active: null,
      };
    case 'measure_gust_strength':
      return {
        default: measure_gust_strength,
        active: null,
      };
    case 'measure_gust_angle':
      return {
        default: measure_gust_angle,
        active: null,
      };
    case 'measure_battery':
      return {
        default: measure_battery,
        active: null,
      };
    case 'measure_power':
      return {
        default: measure_power,
        active: null,
      };
    case 'measure_voltage':
      return {
        default: measure_voltage,
        active: null,
      };
    case 'measure_current':
      return {
        default: measure_current,
        active: null,
      };
    case 'measure_luminance':
      return {
        default: measure_luminance,
        active: null,
      };
    case 'measure_ultraviolet':
      return {
        default: measure_ultraviolet,
        active: null,
      };
    case 'measure_water':
      return {
        default: measure_water,
        active: null,
      };
    default:
      if (capability.iconObj) {
        return {
          default: `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`,
          active: `https://icons-cdn.athom.com/${capability.iconObj.id}.svg`,
          useIconObj: true,
        };
      }
      return {
        default: generic_device,
        active: generic_device,
      };
  }
}
