import { useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { useFocusRing, useRadio, VisuallyHidden, mergeProps } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';
import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon, RoundIconWrapper } from '../../common/Icon';

import { RadioContext } from './RadioGroup';

import { iconBoolean } from '../../../theme/icons/logic/boolean';
import { iconNumber } from '../../../theme/icons/logic/number';
import { iconText } from '../../../theme/icons/logic/text';

const iconMap = {
  boolean: {
    url: iconBoolean,
    color: theme.color.logic_boolean,
  },
  number: {
    url: iconNumber,
    color: theme.color.logic_number,
  },
  string: {
    url: iconText,
    color: theme.color.logic_string,
  },
};

export function TypeRadio(props) {
  const context = useContext(RadioContext);

  const { ref: registerRef, ...registerProps } =
    context.register?.(context.name, {
      required: props.required,
    }) ?? {};

  const radioRef = useRef();
  const radio = useRadio(props, context.state, radioRef);
  const focusRing = useFocusRing();

  const isSelected = context.state.selectedValue === props.value;

  return (
    <S.Label data-is-selected={isSelected}>
      <VisuallyHidden>
        <input
          {...mergeProps(radio.inputProps, focusRing.focusProps, registerProps)}
          ref={mergeRefs([registerRef, radioRef])}
        />
      </VisuallyHidden>

      <RoundIconWrapper size={theme.icon.size_large} color={iconMap[props.type].color}>
        <Icon
          size={theme.icon.size_large}
          url={iconMap[props.type].url}
          color={theme.color.icon_white}
        />
      </RoundIconWrapper>
      <S.Name>{props.children}</S.Name>
    </S.Label>
  );
}

function S() {}
TypeRadio.S = S;

S.Label = styled.label`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  align-items: center;
  padding: ${su(1)};
  border-radius: ${theme.borderRadius.default};
  cursor: pointer;
  margin: 0;
  border: 1px solid transparent;

  &[data-is-selected='true'] {
    background-color: ${theme.color.highlight_background};
    border: 1px solid ${theme.color.highlight_border};
  }
`;

S.Name = styled.span`
  display: inline-block;
  margin-top: ${su(1)};
`;
