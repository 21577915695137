import { v4 as uuid } from 'uuid';

// import * as Sentry from '@sentry/react';

import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

export class DeviceStore extends BaseApiStore {
  static key = 'devices';
  static store = this.createStore(this.key);
  static cacheId = 'HomeyAPI.ManagerDevices.Device';
  static createInitialState() {
    return {
      data: null,
      byId: null,
      byZoneId: null,
      capabilities: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData({ silent = false, skipCache = false } = {}) {
    __DEV__ && console.info('fetch:devices');
    this.destroy();
    const state = this.get();

    silent === false &&
      this.set({
        ...this.createInitialState(),
      });

    try {
      const managerDevices = state.api.devices;

      const time = silent === true ? 0 : 600;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, time));
      const devicesPromise = managerDevices.getDevices({
        $skipCache: skipCache,
      });

      const [, data] = await Promise.all([waitPromise, devicesPromise]);
      const devices = Object.values(data);

      const result = devices.reduce(
        (accumulator, deviceInstance) => {
          accumulator.data[deviceInstance.id] = deviceInstance;
          accumulator.byId[deviceInstance.id] = { ...deviceInstance };

          if (deviceInstance.driverId !== 'homey') {
            accumulator.byZoneId[deviceInstance.zone] = this.assignToPrevZoneId(
              accumulator.byZoneId[deviceInstance.zone],
              deviceInstance
            );
          }

          accumulator.capabilities[deviceInstance.id] = this.getCapabilities(deviceInstance);

          deviceInstance.addListener(
            'capability',
            accumulator.capabilities[deviceInstance.id].listener
          );

          return accumulator;
        },
        {
          data: {},
          byId: {},
          byZoneId: {},
          capabilities: {},
        }
      );

      this.set({
        ...this.createInitialState(),
        loading: false,
        data: result.data,
        byId: result.byId,
        byZoneId: result.byZoneId,
        capabilities: result.capabilities,
        manager: managerDevices,
      });

      managerDevices.addListener('device.create', this.handleCreate);
      managerDevices.addListener('device.update', this.handleUpdate);
      managerDevices.addListener('device.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:devices');
    const state = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (state.api) {
      const managerDevices = state.api.devices;

      Object.values(state.data ?? {}).forEach((device) => {
        const capabilities = state.capabilities[device.id];
        capabilities != null && device.removeListener('capability', capabilities.listener);
      });

      managerDevices.removeListener('device.create', this.handleCreate);
      managerDevices.removeListener('device.update', this.handleUpdate);
      managerDevices.removeListener('device.delete', this.handleDelete);
    }
  }

  static handleCreate = (createdDevice) => {
    __DEV__ && console.info(`create:devices:${createdDevice.id}`);
    const state = this.get();
    // read sync from the cache
    const deviceInstance = state.api?.devices._caches[this.cacheId].getOne({
      id: createdDevice.id,
    });

    if (deviceInstance == null) return;

    const capabilities = this.getCapabilities(deviceInstance);
    deviceInstance.addListener('capability', capabilities.listener);

    let nextByZoneId = state.byZoneId;

    if (deviceInstance.driverId !== 'homey') {
      nextByZoneId = {
        ...state.byZoneId,
        [deviceInstance.zone]: {
          ...state.byZoneId[deviceInstance.zone],
          [deviceInstance.id]: deviceInstance.name,
        },
      };
    }

    this.set({
      data: {
        ...state.data,
        [createdDevice.id]: deviceInstance,
      },
      byId: {
        ...state.byId,
        [createdDevice.id]: { ...deviceInstance },
      },
      capabilities: {
        ...state.capabilities,
        [createdDevice.id]: capabilities,
      },
      byZoneId: nextByZoneId,
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedDevice) => {
    __DEV__ && console.info(`update:devices:${updatedDevice.id}`);
    this.updateQueue[updatedDevice.id] = { ...updatedDevice };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedDevices = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const updates = Object.values(queuedDevices).reduce(
          (accumulator, queuedDevice) => {
            const deviceInstance = state.data?.[queuedDevice.id];

            if (deviceInstance == null || deviceInstance.flags?.includes('homey'))
              return accumulator;

            if (
              state.byId[queuedDevice.id].zone !== queuedDevice.zone ||
              state.byId[queuedDevice.id].name !== queuedDevice.name
            ) {
              accumulator.zoneUpdates[queuedDevice.id] = queuedDevice;
            }

            const prevCapabilities = state.capabilities[queuedDevice.id];

            deviceInstance.removeListener('capability', prevCapabilities.listener);

            accumulator.nextCapabilities[queuedDevice.id] = this.getCapabilities(deviceInstance);

            deviceInstance.addListener(
              'capability',
              accumulator.nextCapabilities[queuedDevice.id].listener
            );

            return accumulator;
          },
          {
            nextCapabilities: {},
            zoneUpdates: {},
          }
        );

        const nextById = {
          ...state.byId,
          ...queuedDevices,
        };

        const nextCapabilities = {
          ...state.capabilities,
          ...updates.nextCapabilities,
        };

        let nextByZoneId = state.byZoneId;

        if (Object.keys(updates.zoneUpdates).length > 0) {
          nextByZoneId = Object.values(updates.zoneUpdates).reduce(
            (accumulator, device) => {
              if (device.driverId === 'homey') {
                return accumulator;
              }

              const prevDevice = state.byId[device.id];
              const prevZone = prevDevice.zone;

              const { [prevDevice.id]: value, ...rest } = accumulator[prevZone];

              accumulator[prevZone] = {
                ...rest,
              };

              if (Object.keys(accumulator[prevZone]).length === 0) {
                delete accumulator[prevZone];
              }

              accumulator[device.zone] = this.assignToPrevZoneId(accumulator[device.zone], device);

              return accumulator;
            },
            {
              ...state.byZoneId,
            }
          );
        }

        this.set({
          byId: nextById,
          capabilities: nextCapabilities,
          byZoneId: nextByZoneId,
        });
      }, 200);
    }
  };

  static handleDelete = (deletedDevice) => {
    __DEV__ && console.info(`delete:devices:${deletedDevice.id}`);
    const state = this.get();
    const deviceInstance = state.data?.[deletedDevice.id];

    if (deviceInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };
    const nextCapabilities = { ...state.capabilities };
    const nextByZoneId = { ...state.byZoneId };

    nextData[deletedDevice.id].removeListener(
      'capability',
      nextCapabilities[deletedDevice.id].listener
    );

    delete nextData[deletedDevice.id];
    delete nextById[deletedDevice.id];
    delete nextCapabilities[deletedDevice.id];

    if (deviceInstance.driverId !== 'homey') {
      const { [deletedDevice.id]: value, ...rest } = nextByZoneId[deviceInstance.zone];

      nextByZoneId[deviceInstance.zone] = {
        ...rest,
      };

      if (Object.keys(nextByZoneId[deviceInstance.zone]).length === 0) {
        delete nextByZoneId[deviceInstance.zone];
      }
    }

    this.set({
      data: nextData,
      byId: nextById,
      capabilities: nextCapabilities,
      byZoneId: nextByZoneId,
    });
  };

  static processCapabilitiesObj(device) {
    return Object.values(device.capabilitiesObj ?? {}).reduce((accumulator, capability) => {
      accumulator[capability.id] = { ...capability };
      accumulator[capability.id].setValue = (value, callerId) => {
        const state = this.get();
        return state.api.devices.setCapabilityValue({
          deviceId: device.id,
          capabilityId: capability.id,
          transactionId: `mha[:]${uuid()}[:]caller[:]${callerId ?? 'internal'}`,
          value,
        });
      };

      accumulator[capability.id].listeners = new Set();
      accumulator[capability.id].onChange = (listener) => {
        accumulator[capability.id].listeners.add(listener);

        return function unregister() {
          accumulator[capability.id].listeners.delete(listener);
        };
      };
      return accumulator;
    }, {});
  }

  static getCapabilities(deviceInstance) {
    const capabilities = this.processCapabilitiesObj(deviceInstance);

    Object.defineProperty(capabilities, 'listeners', {
      value: new Set(),
      writable: false,
      configurable: false,
      enumerable: false,
    });

    Object.defineProperty(capabilities, 'onChange', {
      value: function onChange(listener) {
        capabilities.listeners.add(listener);

        return function unregister() {
          capabilities.listeners.delete(listener);
        };
      },
      writable: false,
      configurable: false,
      enumerable: false,
    });

    Object.defineProperty(capabilities, 'listener', {
      value: ({ capabilityId, transactionId, transactionTime, value }) => {
        try {
          const prevTransactionTime = capabilities[capabilityId]._transactionTime ?? 0;

          if (transactionTime > prevTransactionTime) {
            capabilities[capabilityId]._transactionTime = transactionTime;
            capabilities[capabilityId].value = value;
            capabilities[capabilityId].lastUpdated = new Date(transactionTime).toISOString();

            const [, , , callerId = null] = transactionId?.split('[:]') ?? [];

            capabilities.listeners.forEach((listener) => {
              listener({
                capabilityId,
                transactionId,
                transactionTime,
                value,
                callerId,
              });
            });

            capabilities[capabilityId].listeners.forEach((listener) => {
              listener({
                capabilityId,
                transactionId,
                transactionTime,
                value,
                callerId,
              });
            });
          }
        } catch (error) {
          //Sentry.captureException(error);
        }
      },
      writable: false,
      configurable: false,
      enumerable: false,
    });

    return capabilities;
  }

  static assignToPrevZoneId(prev, device) {
    return {
      ...prev,
      [device.id]: device.name,
    };
  }
}
