import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

interface Heading1BoldProps {
  lineHeight?: React.CSSProperties['lineHeight'];
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  children: React.ReactNode;
}

export function Heading1Bold(props: Heading1BoldProps) {
  return (
    <S.Root
      style={{
        '--heading-1-bold-line-height': props.lineHeight,
        '--heading-1-bold-text-align': props.textAlign,
        '--heading-1-bold-color': props.color,
      }}
    >
      {props.children}
    </S.Root>
  );
}

interface CSSProperties {
  '--heading-1-bold-line-height'?: Heading1BoldProps['lineHeight'];
  '--heading-1-bold-text-align'?: Heading1BoldProps['textAlign'];
  '--heading-1-bold-color'?: Heading1BoldProps['color'];
}

const S = (Heading1Bold.S = class S {
  static Root = styled.h1<{ style: CSSProperties }>`
    font-size: ${theme.fontSize.heading1};
    font-weight: ${theme.fontWeight.bold};
    line-height: var(--heading-1-bold-line-height, 42px);
    text-align: var(--heading-1-bold-text-align, left);
    color: var(--heading-1-bold-color, ${theme.color.text});
  `;
});
