import styled from '@emotion/styled';

export const Circle = styled.div`
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  background-color: hsl(${(props) => props.hue}, 75%, 50%);
  border: 1px solid hsl(${(props) => props.hue}, 75%, 45%);
  border-radius: 50%;
`;
