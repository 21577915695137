import { useRef } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useFocusRing, useTableColumnHeader } from 'react-aria';
import { useHeaderColumnContext } from './SettingsTable';

export function NotificationSettingsTableOwnersColumnHeader(props) {
  const { column, state } = useHeaderColumnContext();
  const ref = useRef();
  const tableColumnHeader = useTableColumnHeader({ node: column }, state, ref);
  const focusRing = useFocusRing();

  return (
    <S.TableColumnHeader
      {...mergeProps(tableColumnHeader.columnHeaderProps, focusRing.focusProps)}
      colSpan={column.colspan}
      ref={ref}
      data-type={column.key}
    >
      <S.TableColumnHeaderInner>{column.rendered}</S.TableColumnHeaderInner>
    </S.TableColumnHeader>
  );
}

function S() {}
NotificationSettingsTableOwnersColumnHeader.S = S;

S.TableColumnHeader = styled.th`
  outline: none;
  text-align: left;
  cursor: default;

  &[data-type='category'] {
    width: 38%;
  }

  &[data-type='push'] {
    width: 24%;
  }

  &[data-type='timeline'] {
    width: 24%;
  }

  &[data-type='actions'] {
    width: 14%;
  }
`;

S.TableColumnHeaderInner = styled.div`
  display: inline-flex;
`;
