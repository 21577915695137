import React, { useState } from 'react';
import styled from '@emotion/styled';
import { mergeProps, useButton, useFocus } from 'react-aria';

import { theme } from '../../../theme/theme';

import { iconChevronDown } from '../../../theme/icons/interface/chevron-down';
import { Icon } from '../../common/Icon';

export function SelectPreview(props) {
  const button = useButton(props.select.triggerProps, props.previewRef);
  const [isFocused, setIsFocused] = useState(false);
  const focus = useFocus({
    onFocusChange: setIsFocused,
  });

  return (
    <S.Input
      {...mergeProps(button.buttonProps, focus.focusProps)}
      ref={props.previewRef}
      title={props.state.selectedItem?.textValue}
      data-is-open={props.state.isOpen}
      data-is-focused={isFocused}
    >
      <S.Span {...props.select.valueProps}>
        {props.state.selectedItem?.value.icon && (
          <S.SelectedIcon
            url={props.state.selectedItem?.value.icon}
            size={theme.icon.size_default}
            color={theme.color.icon_dark}
          />
        )}
        <S.Text>{props.state.selectedItem?.textValue}</S.Text>
      </S.Span>
      <S.ChevronDownIcon
        url={iconChevronDown}
        color={theme.color.icon_light}
        size={theme.icon.size_tiny}
      />
    </S.Input>
  );
}

function S() {}
SelectPreview.S = S;

S.Input = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  height: 37px;
  padding: 6px 10px;
  background-color: ${theme.color.component};
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;
  border: 1px solid ${theme.input.border};
  border-radius: ${theme.input.border_radius};

  &[data-is-open='true'],
  &[data-is-focused='true'] {
    border: 1px solid ${theme.input.border_focus};
  }
`;

S.Span = styled.span`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

S.SelectedIcon = styled(Icon)`
  flex: 0 0 auto;
`;

S.Text = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

S.ChevronDownIcon = styled(Icon)`
  flex: 0 0 ${theme.icon.size_tiny};
`;
