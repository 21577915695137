import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { useButton } from 'react-aria';
import { useUser } from '../../store/users/useUsers';

import { theme } from '../../theme/theme';

export function User(props) {
  const { user } = useUser({ userId: props.userId });

  const ref = useRef();
  const button = useButton(
    {
      elementType: 'div',
      onPress(event) {
        props.onPress?.(event, user);
      },
    },
    ref
  );

  if (props.placeholder) {
    return (
      <User.Root>
        <User.Avatar src={`/img/ph-64.png`} alt="user-avatar" />
      </User.Root>
    );
  }

  if (user == null) return null;

  return (
    <User.Root {...button.buttonProps} id={user.id}>
      <User.Avatar
        src={user.avatar || `/img/ph-64.png`}
        alt="user-avatar"
        present={user.present}
        asleep={user.asleep}
      />
      {user.asleep && <User.SleepIndicator />}
      <User.Name>{user.name?.split(' ')[0]}</User.Name>
    </User.Root>
  );
}

User.Root = styled.div`
  --transform-transition: transform ease-in-out ${theme.duration.fast};

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 60px;
  cursor: pointer;
  transition: var(--transform-transition);
  outline: 0;

  &:hover:not([disabled]) {
    transform: scale(1.05);

    &:active:not([disabled]) {
      transform: scale(1);
    }
  }
`;

User.Avatar = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 50%;
  opacity: ${({ present }) => (present ? 1 : 0.5)};
  box-shadow: ${theme.boxShadow.default};
`;

User.SleepIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  border-radius: 100%;
  background: ${theme.color.white};
  box-shadow: ${theme.boxShadow.default};
  font-size: 9px;

  :before {
    content: 'Zzz';
    color: ${theme.color.text_black};
  }
`;

User.Name = styled.div`
  font-size: ${theme.fontSize.small};
  text-align: center;
`;
