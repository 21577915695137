import { css as cn } from '@emotion/css';

export const viewport = {
  small: '0',
  medium: '640px',
  large: '1280px',
};

export const showForLarge = cn`
  @media only screen and (max-width: ${viewport.large}) {
    display: none;
  }
`;

export const showForMedium = cn`
  @media only screen and (max-width: ${viewport.medium}) {
    display: none;
  }
`;
