import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';

import { RouteManager } from '../../RouteManager';

import { useAuth } from '../../store/AuthStore';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useAppsData } from '../../store/apps/useApps';
import { useApi } from '../../store/HomeyStore';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { SubNav } from '../../components/sub-navigation/SubNavRoot';
import { ListBoxSection } from '../../components/list-box/ListBoxSection';

import { SettingsNavigationItem } from './SettingsNavigationItem';
import { SettingsNavigationItemApp } from './SettingsNavigationItemApp';

import { GeneralSettings } from './system/GeneralSettings';
import { UpdatesSettings } from './system/UpdatesSettings';
import { LedRingSettings } from './system/LedRingSettings';
import { LanguageSettings } from './system/LanguageSettings';
import { LocationSettings } from './system/LocationSettings';
import { NotificationSettings } from './system/NotificationSettings';
import { FamilySettings } from './system/FamilySettings';
import { ApiKeysSettings } from './system/ApiKeysSettings';
import { ExperimentsSettings } from './system/ExperimentsSettings';
import { WebAppSettings } from './system/WebAppSettings';

import { AlexaSettings } from './integrations/AlexaSettings';
import { GoogleAssistantSettings } from './integrations/GoogleAssistantSettings';
import { SiriShortcutsSettings } from './integrations/SiriShortcutsSettings';

export function SettingsNavigation(props) {
  const settingRoute = RouteManager.matchPath.setting;
  const settingRouteMatch = useRouteMatch(settingRoute);
  const settingRouteId = (() => {
    if (settingRouteMatch?.params?.settingSectionId && settingRouteMatch?.params?.settingId) {
      return `${settingRouteMatch.params.settingSectionId}/${settingRouteMatch?.params?.settingId}`;
    }

    return null;
  })();

  const items = useItems();

  function onSelectionChange(keys, listState) {
    const item = listState.collection.getItem(keys[0]);

    if (item != null) {
      RouteManager.toSetting(item.key);
    } else {
      // Don't allow deselect.
    }
  }

  function renderSection() {
    return <ListBoxSection headingComponent={S.ListBoxSectionHeading} />;
  }

  function renderItem({ item, value, state }) {
    switch (item.parentKey) {
      case 'apps':
        return <SettingsNavigationItemApp />;
      default:
        return <SettingsNavigationItem />;
    }
  }

  return (
    <SubNav.Root>
      <SubNav.ListBox
        aria-label="Settings"
        items={items}
        selectedKeys={settingRouteId != null ? [settingRouteId] : []}
        renderItem={renderItem}
        renderSection={renderSection}
        onSelectionChange={onSelectionChange}
      />
    </SubNav.Root>
  );
}

function useItems() {
  const { i18n } = useI18n();
  const apps = useAppsData();
  const { api } = useApi();
  const { experimentalFlag } = useAuth();

  const sections = useMemo(() => {
    // apps.data only changes on app create events and app delete events. This is so we dont have our
    // navigation rerender on any app property change. If we need to change that behavior and show
    // app disabled states in the navigation for example we should use the appsById hook.

    const sections = [];
    const systemItems = [];
    const integrationItems = [];
    const appItems = [];

    systemItems.push({
      key: GeneralSettings.pathKey,
      textValue: i18n.messageFormatter('settings.system.general'),
      context: {
        icon: GeneralSettings.icon,
        backgroundColor: GeneralSettings.backgroundColor,
      },
    });

    api?.isCloud !== true &&
      systemItems.push({
        key: UpdatesSettings.pathKey,
        textValue: i18n.messageFormatter('settings.system.updates'),
        context: {
          icon: UpdatesSettings.icon,
          backgroundColor: UpdatesSettings.backgroundColor,
        },
      });

    api?.isCloud !== true &&
      systemItems.push({
        key: LedRingSettings.pathKey,
        textValue: i18n.messageFormatter('settings.system.ledRing'),
        context: {
          icon: LedRingSettings.icon,
          backgroundColor: LedRingSettings.backgroundColor,
        },
      });

    systemItems.push({
      key: LanguageSettings.pathKey,
      textValue: i18n.messageFormatter('settings.system.language'),
      context: {
        icon: LanguageSettings.icon,
        backgroundColor: LanguageSettings.backgroundColor,
      },
    });

    systemItems.push({
      key: LocationSettings.pathKey,
      textValue: i18n.messageFormatter('settings.system.location'),
      context: {
        icon: LocationSettings.icon,
        backgroundColor: LocationSettings.backgroundColor,
      },
    });

    systemItems.push({
      key: NotificationSettings.pathKey,
      textValue: i18n.messageFormatter('settings.system.notifications'),
      context: {
        icon: NotificationSettings.icon,
        backgroundColor: NotificationSettings.backgroundColor,
      },
    });

    systemItems.push({
      key: FamilySettings.pathKey,
      textValue: i18n.messageFormatter('settings.system.users'),
      context: {
        icon: FamilySettings.icon,
        backgroundColor: FamilySettings.backgroundColor,
      },
    });

    api?.isCloud !== true &&
      api?.homey.platformVersion >= 2 &&
      systemItems.push({
        key: ApiKeysSettings.pathKey,
        textValue: i18n.messageFormatter('settings.system.apiKeys'),
        context: {
          icon: ApiKeysSettings.icon,
          backgroundColor: ApiKeysSettings.backgroundColor,
        },
      });

    api?.isCloud !== true &&
      systemItems.push({
        key: ExperimentsSettings.pathKey,
        textValue: i18n.messageFormatter('settings.system.experiments'),
        context: {
          icon: ExperimentsSettings.icon,
          backgroundColor: ExperimentsSettings.backgroundColor,
        },
      });

    experimentalFlag === true &&
      systemItems.push({
        key: WebAppSettings.pathKey,
        textValue: 'Web App',
        context: {
          icon: WebAppSettings.icon,
          backgroundColor: WebAppSettings.backgroundColor,
          experimentalFlag,
        },
      });

    // generalItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    sections.push({
      key: 'system',
      title: i18n.messageFormatter('settings.system.label'),
      children: systemItems,
    });

    integrationItems.push({
      key: AlexaSettings.pathKey,
      textValue: 'Alexa',
      context: {
        icon: AlexaSettings.icon,
        backgroundColor: AlexaSettings.backgroundColor,
      },
    });

    integrationItems.push({
      key: GoogleAssistantSettings.pathKey,
      textValue: 'Google Assistant',
      context: {
        icon: GoogleAssistantSettings.icon,
        backgroundColor: GoogleAssistantSettings.backgroundColor,
      },
    });

    integrationItems.push({
      key: SiriShortcutsSettings.pathKey,
      textValue: 'Siri Shortcuts',
      context: {
        icon: SiriShortcutsSettings.icon,
        backgroundColor: SiriShortcutsSettings.backgroundColor,
      },
    });

    sections.push({
      key: 'integrations',
      title: i18n.messageFormatter('settings.integrations.label'),
      children: integrationItems,
    });

    for (const [appId, app] of Object.entries(apps.data ?? {})) {
      appItems.push({
        key: `apps/${appId}`,
        textValue: app.name,
        context: {
          appId,
        },
      });
    }

    appItems.sort((a, b) => i18n.collator.compare(a.textValue, b.textValue));

    // Only show apps sections if there are installed apps.
    if (appItems.length > 0) {
      sections.push({
        key: 'apps',
        title: 'Apps',
        children: appItems,
      });
    }

    return sections;
  }, [api, apps.data, i18n, experimentalFlag]);

  return sections;
}

function S() {}
SettingsNavigation.S = S;

S.ListBoxSectionHeading = styled.div`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.text};
  padding-left: ${su(2)};
  padding-bottom: ${su(1)};
`;
