import styled from '@emotion/styled';

import { scrollbarDark } from '../../theme/elements/scrollbars';
import { su } from '../../theme/functions/su';

import { ListBox } from '../list-box/ListBox';
import { SubNavNewButton } from './SubNavNewButton';

export function SubNav() {}

SubNav.Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex: 1 1 auto;
  min-width: 0;
`;

SubNav.NewButton = SubNavNewButton;

SubNav.SearchFieldWrapper = styled.div`
  flex: 0 0 auto;
  padding: 0 ${su(2)} ${su(2)};
`;

SubNav.ListBox = styled(ListBox)`
  ${scrollbarDark};
  flex: 1 1 auto;
  min-width: 0;
  overflow-y: auto;
`;

SubNav.Footer = styled.footer`
  padding: ${su(2)} ${su(2)} 15px;
  flex: 0 0 auto;
`;
