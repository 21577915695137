import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { keyframes } from '@emotion/react';

import { useButton, useSearchField } from 'react-aria';
import { useOverlayTriggerState, useSearchFieldState } from 'react-stately';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';

import { Icon } from '../../../components/common/Icon';

import { GlobalSearchOverlay } from './GlobalSearchOverlay';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';
import { iconMagnifyingGlass } from '../../../theme/icons/interface/magnifying-glass/magnifying-glass';

// this component is meant to be used once for now
// this search value should probably be cleared at some point
// for example when switching homeys

let defaultValue = '';
export function GlobalSearch() {
  const { i18n } = useI18n();

  const instanceRef = useRef({
    hasBeenOpened: false,
  });

  const rootRef = useRef();
  const inputRef = useRef();

  const [hasResults, setHasResults] = useState(false);
  const overlayTriggerState = useOverlayTriggerState({
    onOpenChange() {
      instanceRef.current.hasBeenOpened = true;
    },
  });
  const searchFieldState = useSearchFieldState({
    defaultValue: defaultValue,
    onChange(value) {
      defaultValue = value;
    },
  });

  const searchField = useSearchField(
    {
      'aria-label': i18n.messageFormatter('common.search'),
      placeholder: `${i18n.messageFormatter('common.search')}...`,
      onFocus() {
        overlayTriggerState.open();
      },
      onClear() {
        if (searchFieldState.value === '') {
          overlayTriggerState.close();
        }
      },
      onKeyUp(event) {
        if (searchFieldState.value !== '') {
          overlayTriggerState.open();
        }

        if (event.key === 'ArrowDown') {
        }
      },
    },
    searchFieldState,
    inputRef
  );

  // onPressStart overrides clearButtonProps to prevent
  // opening when closed and cleared
  const clearButton = useButton({
    ...searchField.clearButtonProps,
    onPressStart() {
      if (overlayTriggerState.isOpen) {
        inputRef.current.focus();
      }
    },
  });

  useEffect(() => {
    function keyDownListener(event) {
      if (document.body === event.target && event.key === '/') {
        event.stopPropagation();
        event.preventDefault();

        inputRef.current?.focus();
      }
    }

    document.documentElement.addEventListener('keydown', keyDownListener);

    return function () {
      document.documentElement.removeEventListener('keydown', keyDownListener);
    };
  }, []);

  return (
    <GlobalSearch.Root
      ref={rootRef}
      isOpen={overlayTriggerState.isOpen}
      isClosed={overlayTriggerState.isOpen === false && instanceRef.current.hasBeenOpened}
    >
      <GlobalSearch.InputWrapper isOpen={overlayTriggerState.isOpen}>
        <GlobalSearch.SearchIcon size={theme.icon.size_default} url={iconMagnifyingGlass} />
        <GlobalSearch.Input {...searchField.inputProps} ref={inputRef} />
        <GlobalSearch.ClearButton {...clearButton.buttonProps}>
          <Icon url={iconCloseThin} size={theme.icon.size_tiny} />
        </GlobalSearch.ClearButton>
      </GlobalSearch.InputWrapper>
      <GlobalSearch.Popup isOpen={overlayTriggerState.isOpen} hasResults={hasResults}>
        <GlobalSearchOverlay
          rootRef={rootRef}
          inputRef={inputRef}
          overlayTriggerState={overlayTriggerState}
          searchFieldState={searchFieldState}
          setHasResults={setHasResults}
        />
      </GlobalSearch.Popup>
    </GlobalSearch.Root>
  );
}

const animationSearch = {
  in: keyframes`
    0% {
      max-width: 200px;
      animation-timing-function: ease-in-out;
    }

    20% {
      max-width: 190px;
      animation-timing-function: ease-in-out;
    }

    70% {
      max-width: 510px;
      animation-timing-function: ease-in-out;
    }

    100% {
      max-width: 500px;
    }
  `,
  out: keyframes`
    0% {
      max-width: 500px;
      animation-timing-function: ease-in-out;
    }

    20% {
      max-width: 510px;
      animation-timing-function: ease-in-out;
    }

    80% {
      max-width: 190px;
      animation-timing-function: ease-in-out;
    }

    100% {
      max-width: 200px
    }
  `,
};

GlobalSearch.Root = styled.div`
  position: relative;
  align-self: flex-start;
  min-height: 40px;
  width: 100%;
  max-width: 200px;

  ${(props) =>
    props.isOpen &&
    css`
      max-width: 500px;
      animation: ${animationSearch.in} 700ms 1 forwards ease-in-out;
    `};

  ${(props) =>
    props.isClosed &&
    css`
      max-width: 200px;
      animation: ${animationSearch.out} 700ms 1 forwards ease-in-out;
    `};
`;

GlobalSearch.Popup = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  min-height: 40px;
  background-color: ${theme.color.component};
  box-shadow: ${theme.boxShadow.default};
  border-radius: ${theme.borderRadius.large};
  padding-top: 40px;
  transition: border-radius 300ms ease-in-out;
  overflow: hidden; // force content to stay within border-radius;

  border-radius: ${(props) => {
    return (
      props.isOpen && (props.hasResults ? theme.borderRadius.default : theme.borderRadius.large)
    );
  }};
`;

GlobalSearch.Input = styled.input`
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 40px;
  display: block;
  width: 100%;
  border-radius: ${theme.borderRadius.large};
  padding-left: ${su(4)};
  border: none;
  background: none;
  color: ${theme.color.text};
  caret-color: ${theme.color.mono_1000};
  transition: padding-left 400ms ease-in-out;

  ::placeholder {
    color: ${theme.color.text_light};
  }
`;

GlobalSearch.SearchIcon = styled(Icon)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: left 400ms ease-in-out;
  pointer-events: none;
`;

GlobalSearch.ClearButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  transition: opacity ${theme.duration.fast} ${theme.curve.fastIn};

  ${Icon.S.Root} {
    transition: background-color ${theme.duration.fast} ${theme.curve.fastIn};
  }

  &:hover {
    ${Icon.S.Root} {
      background-color: ${theme.color.icon_light_hover};
    }
  }

  ${GlobalSearch.Input}:placeholder-shown + & {
    opacity: 0;
    pointer-events: none;
  }
`;

GlobalSearch.InputWrapper = styled.div`
  position: relative;
  z-index: 10;

  ${(props) =>
    props.isOpen &&
    css`
      ${GlobalSearch.SearchIcon} {
        left: 20px;
      }

      ${GlobalSearch.Input} {
        padding-left: ${su(5)};
      }
    `};
`;
