import { useRef } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { useAuth } from '../../store/AuthStore';
import { useCurrent } from '../../store/HomeyStore';

import { ImageBase } from '../../components/common/ImageBase';
import { Overlay } from '../../components/overlay/Overlay';
import { AnimationRemain } from '../../components/animation/AnimationRemain';

import { HeaderButton } from './HeaderButton';
import { UserMenuOverlay } from './UserMenuOverlay';

export function UserMenu(props) {
  const userMenuTriggerRef = useRef();
  const userMenuTriggerState = useOverlayTriggerState({ defaultOpen: false });
  const userMenuOverlayTrigger = useOverlayTrigger(
    { type: 'menu' },
    userMenuTriggerState,
    userMenuTriggerRef
  );

  function handleHomeySelect({ homeyId }) {
    userMenuTriggerState.close();
    props.onHomeySelect({ homeyId });
  }

  return (
    <>
      <HeaderButton
        {...userMenuOverlayTrigger.triggerProps}
        ref={userMenuTriggerRef}
        title={props.current?.name}
        onPress={userMenuTriggerState.toggle}
      >
        <ImageBase url={props.user?.avatar.medium} width={40} height={40} round={true} />
      </HeaderButton>

      <AnimationRemain condition={userMenuTriggerState.isOpen} delay={200}>
        {(animationRemainProps) => {
          return (
            <Overlay
              targetRef={userMenuTriggerRef}
              overlayProps={userMenuOverlayTrigger.overlayProps}
              overlayTriggerState={userMenuTriggerState}
              offset={10}
              animationRemainProps={animationRemainProps}
              autoFocus={true}
              restoreFocus={true}
            >
              <UserMenuOverlay
                user={props.user}
                homeys={props.homeys}
                current={props.current}
                onHomeySelect={handleHomeySelect}
              />
            </Overlay>
          );
        }}
      </AnimationRemain>
    </>
  );
}

function Injected(props) {
  const { user, homeys } = useAuth();
  const current = useCurrent();

  return <UserMenu user={user} homeys={homeys} current={current} {...props} />;
}

UserMenu.Injected = Injected;
