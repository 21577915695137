import { ToastManager } from '../../ToastManager';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useApp } from '../../store/apps/useApps';
import { useApi } from '../../store/HomeyStore';

import { ContextMenuContent } from '../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../components/common/context-menu/DeveloperMenu';
import { AppSettingsDialogUninstall } from './apps/AppSettingsDialogUninstall';

import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';
import { iconArrowDoubleCircularRotated } from '../../theme/icons/interface/arrow-double-circular-rotated/arrow-double-circular-rotated';

export function SettingsNavigationItemAppContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { api } = useApi();
  const { app } = useApp({ appId: props.appId });

  const isRestartDisabled = (() => {
    switch (true) {
      case app == null:
      case app.enabled !== true:
      case app.state === 'stopping':
      case app.state === 'starting':
        return true;
      case app.state === 'running':
      case app.state === 'crashed':
      case app.state === 'stopped':
        return false;
      default:
        return true;
    }
  })();

  function onAction(key) {
    switch (key) {
      case 'restart':
        api.apps
          .restartApp({ id: props.appId })
          .then(() => {})
          .catch(ToastManager.handleError)
          .finally(() => {});
        break;
      case 'uninstall':
        AppSettingsDialogUninstall.route.open({
          context: { appId: props.appId },
        });
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      {api?.isCloud !== true && (
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter('common.restart')}
          icon={iconArrowDoubleCircularRotated}
          isDisabled={isRestartDisabled}
          onPress={() => {
            onAction('restart');
          }}
        />
      )}

      <DeveloperMenu onIdRequest={() => app?.id} onResourceRequest={() => app} />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.uninstall')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('uninstall');
        }}
      />
    </ContextMenuContent.List>
  );
}
