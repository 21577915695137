import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

interface Heading3BoldProps {
  lineHeight?: React.CSSProperties['lineHeight'];
  textAlign?: React.CSSProperties['textAlign'];
  color?: React.CSSProperties['color'];
  children: React.ReactNode;
}

export function Heading3Bold(props: Heading3BoldProps) {
  return (
    <S.Root
      style={{
        '--heading-3-bold-line-height': props.lineHeight,
        '--heading-3-bold-text-align': props.textAlign,
        '--heading-3-bold-color': props.color,
      }}
    >
      {props.children}
    </S.Root>
  );
}

interface CSSProperties {
  '--heading-3-bold-line-height'?: Heading3BoldProps['lineHeight'];
  '--heading-3-bold-text-align'?: Heading3BoldProps['textAlign'];
  '--heading-3-bold-color'?: Heading3BoldProps['color'];
}

const S = (Heading3Bold.S = class S {
  static Root = styled.h3<{ style: CSSProperties }>`
    font-size: ${theme.fontSize.heading3};
    font-weight: ${theme.fontWeight.bold};
    line-height: var(--heading-3-bold-line-height, 24px);
    text-align: var(--heading-3-bold-text-align, left);
    color: var(--heading-3-bold-color, ${theme.color.text});
  `;
});
