import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';

import { ToastManager } from '../../../ToastManager';
import { getTernaryImage } from './getTernaryImage';
import { theme } from '../../../theme/theme';

import { Icon } from '../../common/Icon';
import { CapabilitySelect } from '../CapabilitySelect';
import * as Containers from '../Containers';

export function Ternary({ device, component, capabilities }) {
  const instance = useRef(`Ternary:${uuid()}`);
  const [value, setValue] = useState(null);
  const [capability, setCapability] = useState(() => {
    return capabilities?.[component.capabilities[0]] ?? null;
  });

  useEffect(() => {
    setCapability(capabilities?.[component.capabilities[0]] ?? null);
  }, [capabilities, component.capabilities]);

  useEffect(() => {
    if (capability) {
      const unregister = capability.onChange(({ value, callerId }) => {
        if (callerId === instance.current) return;

        setValue(value);
      });

      setValue(capability.value);

      return unregister;
    }
  }, [capability]);

  if (capability == null) return null;

  return (
    <Containers.Control>
      <Containers.Select>
        {component.capabilities.length > 1 && (
          <CapabilitySelect
            selectedCapability={capability}
            capabilities={capabilities}
            componentCapabilities={component.capabilities}
            onSelectionChange={setCapability}
          />
        )}
      </Containers.Select>
      <Containers.Action>
        {capability.values?.map((ternaryValue) => {
          const { id } = ternaryValue;

          return (
            <ButtonBase
              key={id}
              initial={{
                backgroundColor: value === id ? theme.color.white_o_100 : theme.color.black_o_50,
              }}
              animate={{
                backgroundColor: value === id ? theme.color.white_o_100 : theme.color.black_o_50,
              }}
              onClick={() => {
                handleClick({
                  nextValue: id,
                  prevValue: value,
                  setValue,
                  capability,
                  instance,
                });
              }}
            >
              <Icon
                as={motion.span}
                size="42px"
                initial={{
                  backgroundColor: value === id ? theme.color.white_o_100 : theme.color.black_o_50,
                }}
                animate={{
                  backgroundColor: value === id ? theme.color.green : theme.color.white_o_100,
                }}
                url={getTernaryImage(id)}
              />
            </ButtonBase>
          );
        })}
      </Containers.Action>
    </Containers.Control>
  );
}

function handleClick({ nextValue, prevValue, setValue, capability, instance }) {
  setValue(nextValue);

  capability
    .setValue(nextValue, instance.current)
    .then(({ transactionId, transactionTime, value }) => {
      // setValue(value);
    })
    .catch((error) => {
      setValue(prevValue);
      ToastManager.handleError(error);
    });
}

const ButtonBase = styled(motion.button)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 50px;
  }
`;
