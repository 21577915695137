import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { useI18n } from '../../../../hooks/useI18nFormatters';
import { useDriverClassList } from '../useDriverClassList';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { animationFade } from '../../../../theme/animations/animationFade';

import { Icon } from '../../../common/Icon';
import { Image } from '../../../common/Image';
import { ExternalLink } from '../../../common/link/ExernalLink';
import { SpinnerLoading } from '../../../common/SpinnerLoading';
import { SearchField } from '../../../common/search-field/SearchField';
import { DeviceTile } from '../../../device/DeviceTile';

import { iconSearch } from '../../../../theme/icons/interface/magnifying-glass/magnifying-glass';
import { iconSquareArrowUpRight } from '../../../../theme/icons/interface/square-arrow-up-right/square-arrow-up-right';

export function DriversView(props) {
  const { i18n } = useI18n();
  const searchFieldRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const driverClassList = useDriverClassList({ uriDrivers: props.uriDrivers, searchValue });

  function handleChange(value) {
    setSearchValue(value.toLowerCase());
  }

  useEffect(() => {
    if (props.isLoading) return;

    requestAnimationFrame(() => {
      searchFieldRef.current.focus();
    });
  }, [props.isLoading]);

  if (props.isLoading) return null;

  return (
    <DriversView.Root>
      <DriversView.AppContainer>
        <DriversView.IconWrapper backgroundColor={props.appInfo?.appColor}>
          {props.appInfo.appId === 'vdevice' ? (
            <Image url={'/img/logo192.png'} size="50px" />
          ) : (
            <Icon
              url={props.appInfo?.appIcon}
              color={theme.color.white}
              size={theme.icon.size_large}
            />
          )}
        </DriversView.IconWrapper>

        <h3>{props.appInfo?.appName}</h3>
      </DriversView.AppContainer>

      <DriversView.SearchField
        ref={searchFieldRef}
        aria-label="Search"
        borderRadius={theme.borderRadius.default}
        placeholder={i18n.messageFormatter('pair.searchDriverPlaceholder')}
        onChange={handleChange}
        onKeyDown={(event) => {
          // If it was empty and escape it pressed propagate event so that
          // overlays can be closed with keyboard.
          if (event.key === 'Escape' && event.currentTarget.value === '') {
            event.continuePropagation();
          }
        }}
      />

      <DriversView.SearchResults>
        {driverClassList.length > 0 &&
          driverClassList.map(({ classId, classTitle, drivers }) => {
            const title =
              classId !== '__none__' ? (
                <DriversView.ClassTitle>{classTitle}</DriversView.ClassTitle>
              ) : (
                <DriversView.Spacer />
              );

            return (
              <Fragment key={classId}>
                {title}
                <DriversView.Grid>
                  {drivers.map((driver) => {
                    const isNotReady = driver.ready !== undefined && driver.ready !== true;

                    function handleDriverPress() {
                      if (isNotReady === false) {
                        props.onDriverSelect({ driver });
                      }
                    }

                    return (
                      <DriversView.GridItem key={driver.id}>
                        <DeviceTile
                          iconObj={driver.iconObj}
                          iconUrl={driver.icon}
                          name={driver.name}
                          indicator={isNotReady === true ? <SpinnerLoading size={24} /> : null}
                          onPress={handleDriverPress}
                        />
                      </DriversView.GridItem>
                    );
                  })}
                </DriversView.Grid>
              </Fragment>
            );
          })}

        {driverClassList.length === 0 && (
          <DriversView.NoResults>
            <Icon url={iconSearch} size={'48px'} color={theme.icon.color_light} />
            <DriversView.NoResultsTitle>
              {i18n.messageFormatter('pair.noSearchResults')}
            </DriversView.NoResultsTitle>
            <DriversView.NoResultsText>
              {i18n.messageFormatter('pair.noDriversFound', {
                requestADevice: (
                  <DriversView.Link
                    key={'request-a-device'}
                    url={'https://request-a-brand.homey.app/boards/request-a-brand'}
                  >
                    {i18n.messageFormatter('pair.requestADevice')}
                    <Icon
                      url={iconSquareArrowUpRight}
                      size={theme.icon.size_small}
                      color={theme.icon.color_blue}
                    />
                  </DriversView.Link>
                ),
              })}
            </DriversView.NoResultsText>
          </DriversView.NoResults>
        )}
      </DriversView.SearchResults>
    </DriversView.Root>
  );
}

DriversView.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;
`;

DriversView.AppContainer = styled.div`
  display: flex;
  align-items: center;
`;

DriversView.IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  margin-right: ${su(1)};
`;

DriversView.SearchField = styled(SearchField)`
  flex: 0 0 auto;
  margin-top: ${su(2)};

  ${SearchField.Input} {
    height: ${theme.input.height_large};
    line-height: ${theme.input.height_large};
  }
`;

DriversView.SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: ${su(2)};
`;

DriversView.Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-auto-rows: 120px;
  grid-gap: ${su(2)};
`;

DriversView.GridItem = styled.div`
  transition: transform ${theme.duration.fast} ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:hover:active {
    transform: scale(0.95);
  }
`;

DriversView.Spacer = styled.div`
  margin-top: ${su(3)};
  margin-bottom: ${su(2)};
  height: 1px;
  width: 100%;
  background-color: ${theme.color.line};
`;

DriversView.ClassTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.text_light};
  margin-top: ${su(3)};
  margin-bottom: ${su(2)};

  &::after {
    margin-left: ${su(2)};
    content: '';
    height: 1px;
    background-color: ${theme.color.line};
    flex-grow: 1;
  }
`;

DriversView.Link = styled(ExternalLink)`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  color: ${theme.color.text_link};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

DriversView.NoResults = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  padding-bottom: ${su(5)};
`;

DriversView.NoResultsTitle = styled.h2`
  margin: 24px 0 8px;
  color: ${theme.color.text};
`;

DriversView.NoResultsText = styled.p`
  margin: 0;
  color: ${theme.color.text_light};
  line-height: 1.5;
`;
