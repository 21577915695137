import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { OverlayProvider } from 'react-aria';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { MotionConfig } from 'framer-motion';
import isValidProp from '@emotion/is-prop-valid';

import { GlobalStyles } from './theme/Global';

import { GlobalOverlayContextProvider } from './components/overlay/GlobalOverlayContextProvider';
import { LocaleProvider } from './providers/LocaleProvider';
import { LayoutContextProvider } from './LayoutContext';
import { NavContextProvider } from './containers/NavContext';
import { ContextMenuContextProvider } from './components/common/context-menu/ContextMenuContextProvider';

import { App } from './App';

// Imports AuthStore which resolves the initial logged in state.
import './store/AuthStateManager';

import { currentOS, OS } from './lib/platform';
import { classes } from './theme/classes';

import { MobilePage } from './mobile/MobilePage';

import { history } from './history';
import { AuthStore } from './store/AuthStore';

import * as Sentry from '@sentry/react';

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://57677201cd54491aa453cabe89f30c0b@o4504916257931264.ingest.sentry.io/4504949401387008',
    release: 'athom-cloud-my-homey-app@' + import.meta.env.VITE_VERSION,
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors: [
      'websocket error',
      'Network request failed',
      'Non-Error promise rejection captured with keys: __athom_api_type',
      'Non-Error promise rejection captured with keys: [object has no keys]',
      'Non-Error promise rejection captured with keys: code',
      'Non-Error promise rejection captured with keys: timeout',
      'Non-Error promise rejection captured with keys: message',
      'UnhandledRejection: Non-Error promise rejection captured with value: timeout',
      'ResizeObserver loop limit exceeded',
      "Unexpected token '<'",
      "expected expression, got '<'",
      'ChunkLoadError',
    ],
    beforeSend(event, hint) {
      // return null;
      return event;
    },
    // debug: true,
  });
}

const continueDesktopVersion = localStorage.getItem('continueDesktopVersion') === 'true';

function renderApp(children) {
  return (
    <React.StrictMode>
      {/** https://github.com/framer/motion/issues/1406 */}
      <MotionConfig isValidProp={isValidProp}>
        <OverlayProvider className={classes.overlayProvider}>
          <GlobalOverlayContextProvider>
            <Router history={history}>
              <LocaleProvider>
                <DndProvider backend={HTML5Backend}>
                  <ContextMenuContextProvider>
                    <LayoutContextProvider>
                      <NavContextProvider>
                        <GlobalStyles />
                        {children}
                      </NavContextProvider>
                    </LayoutContextProvider>
                  </ContextMenuContextProvider>
                </DndProvider>
              </LocaleProvider>
            </Router>
          </GlobalOverlayContextProvider>
        </OverlayProvider>
      </MotionConfig>
    </React.StrictMode>
  );
}

const url = new URL(window.location.href);

const code = url.searchParams.get('code');
const state = url.searchParams.get('state');
if (code && state) {
  async function handleAuthentication() {
    if (code && state) {
      try {
        await AuthStore.authenticateWithAuthorizationCode(code);
        console.log('Redirecting to', state);
        window.location.href = state;
      } catch (err) {
        window.alert(err);
      }
    }
  }

  handleAuthentication();
} else if ((currentOS === OS.IOS || currentOS === OS.ANDROID) && continueDesktopVersion === false) {
  render(
    <React.StrictMode>
      <OverlayProvider className={classes.overlayProvider}>
        {/* <GlobalStyles /> */}
        <MobilePage />
      </OverlayProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  render(renderApp(<App />), document.getElementById('root'));
}
