import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ImageBase = forwardRef(function ({ url, ...rest }, forwardedRef) {
  return <Base {...rest} ref={forwardedRef} src={url} />;
});

export const Base = styled(motion.img)`
  display: inline-block;
  width: ${({ size, width }) => size ?? width ?? 32}px;
  height: ${({ size, height }) => size ?? height ?? 32}px;
  border: 0;
  border-radius: ${({ round }) => round && '50%'};
  outline: 0;
`;
