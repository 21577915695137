import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useButton } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { Button } from './Button';
import { ButtonIcon } from './ButtonIcon';

export const MonoButton = forwardRef((props, forwardedRef) => {
  const ref = useRef();
  const button = useButton(props, ref);

  return (
    <MonoButton.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      title={props.title}
      className={props.className}
      style={props.style}
      data-style-color={props.styleColor}
      data-style-icon-color={props.styleIconColor}
      data-style-width={props.styleWidth}
      data-style-flat={props.styleFlat}
      data-style-compact={props.styleCompact}
      data-is-pressed={button.isPressed}
      data-is-disabled={props.isDisabled}
    >
      {props.children}
    </MonoButton.Root>
  );
});

MonoButton.defaultProps = {
  styleColor: 'default',
};

MonoButton.propTypes = {
  ...Button.propTypes,
  styleIconColor: PropTypes.oneOf(['default', 'disabled', 'blue', 'red', 'green']),
  styleColor: PropTypes.oneOf(['default', 'disabled', 'blue', 'red', 'green']),
  styleCompact: PropTypes.bool,
  styleFlat: PropTypes.bool,
  styleWidth: PropTypes.oneOf(['full']),
};

MonoButton.Text = styled.span`
  white-space: nowrap;
`;

MonoButton.Icon = ButtonIcon;

MonoButton.Root = styled.button`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding: ${su(1)} ${su(2)};
  background: ${theme.monoButton.background};
  color: var(--mono-button-color);
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: transform, color, box-shadow, background-color;

  ${MonoButton.Icon.S.Root} {
    background: var(--mono-button-icon-color, var(--mono-button-color));
    transition: ${theme.duration.fast} ${theme.curve.fastIn};
    transition-property: background-color;
  }

  // States
  &:not([data-is-disabled='true']) {
    &:hover {
      &:not[data-style-flat='true'] {
        box-shadow: ${theme.boxShadow.default_hover};
      }
      background-color: ${theme.monoButton.background_hover};

      ${MonoButton.Icon.S.Root} {
        background: var(--mono-button-icon-color-hover, var(--mono-button-color-hover));
      }
    }

    &[data-is-pressed='true'] {
      &:not[data-style-flat='true'] {
        box-shadow: ${theme.boxShadow.default_active};
      }
      transform: scale(0.96875);

      ${MonoButton.Icon.S.Root} {
        background: var(--mono-button-icon-color-active, var(--mono-button-color-active));
      }
    }

    // Color variants
    &[data-style-color='default'] {
      --mono-button-color: ${theme.color.text};
      --mono-button-color-hover: ${theme.color.text};
      --mono-button-color-active: ${theme.color.text};
    }

    &[data-style-color='disabled'] {
      --mono-button-color: ${theme.color.text_disabled};
      --mono-button-color-hover: ${theme.color.text_disabled};
      --mono-button-color-active: ${theme.color.text_disabled};
    }

    &[data-style-color='blue'] {
      --mono-button-color: ${theme.color.blue};
      --mono-button-color-hover: ${theme.color.blue_hover};
      --mono-button-color-active: ${theme.color.blue_active};
    }

    &[data-style-color='red'] {
      --mono-button-color: ${theme.color.red};
      --mono-button-color-hover: ${theme.color.red_hover};
      --mono-button-color-active: ${theme.color.red_active};
    }

    &[data-style-color='green'] {
      --mono-button-color: ${theme.color.green};
      --mono-button-color-hover: ${theme.color.green_hover};
      --mono-button-color-active: ${theme.color.green_active};
    }

    &[data-style-icon-color='default'] {
      --mono-button-icon-color: ${theme.color.text};
      --mono-button-icon-color-hover: ${theme.color.text};
      --mono-button-icon-color-active: ${theme.color.text};
    }

    &[data-style-icon-color='disabled'] {
      --mono-button-icon-color: ${theme.color.text_disabled};
      --mono-button-icon-color-hover: ${theme.color.text_disabled};
      --mono-button-icon-color-active: ${theme.color.text_disabled};
    }

    &[data-style-icon-color='blue'] {
      --mono-button-icon-color: ${theme.color.blue};
      --mono-button-icon-color-hover: ${theme.color.blue_hover};
      --mono-button-icon-color-active: ${theme.color.blue_active};
    }

    &[data-style-icon-color='red'] {
      --mono-button-icon-color: ${theme.color.red};
      --mono-button-icon-color-hover: ${theme.color.red_hover};
      --mono-button-icon-color-active: ${theme.color.red_active};
    }

    &[data-style-icon-color='green'] {
      --mono-button-icon-color: ${theme.color.green};
      --mono-button-icon-color-hover: ${theme.color.green_hover};
      --mono-button-icon-color-active: ${theme.color.green_active};
    }
  }

  &[data-is-disabled='true'] {
    --mono-button-color: ${theme.color.text_disabled};
    cursor: not-allowed;
  }

  // Size variants
  &[data-style-compact='true'] {
    grid-gap: 6px;
    padding-left: ${su(1.5)};
    padding-right: ${su(1.5)};
  }

  // Shadow variants
  &[data-style-flat='true'] {
    box-shadow: none;
  }

  // Width variants
  &[data-style-width='full'] {
    width: 100%;
  }
`;
