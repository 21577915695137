import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  useOverlay,
  useOverlayPosition,
  useListBox,
  FocusScope,
  OverlayContainer,
  mergeProps,
} from 'react-aria';

import { theme } from '../../../theme/theme';
import { scrollbarDark } from '../../../theme/elements/scrollbars';
import { animationScalePerspFade } from '../../../theme/animations/animationScalePerspFade';

import { ResolutionSelectSection } from './ResolutionSelectSection';
import { AnchorPointer } from '../../../components/common/AnchorPointer';

export function ResolutionSelectListBox(props) {
  const listBoxRef = useRef();
  const overlayRef = useRef();

  const listBox = useListBox(
    {
      'aria-label': 'ListBox',
      autoFocus: props.selectState.focusStrategy || true,
      disallowEmptySelection: true,
      shouldFocusWrap: true,
    },
    props.selectState,
    listBoxRef
  );

  const overlay = useOverlay(
    {
      shouldCloseOnBlur: true,
      isOpen: props.selectState.isOpen,
      isDismissable: true,
      isKeyboardDismissDisabled: false,
      onClose: props.selectState.close,
    },
    overlayRef
  );

  const overlayPosition = useOverlayPosition({
    targetRef: props.targetRef,
    overlayRef: overlayRef,
    placement: 'bottom left',
    offset: 10,
    isOpen: props.selectState.isOpen,
    shouldUpdatePosition: true,
    shouldFlip: true,
    onClose: props.selectState.close,
  });

  useEffect(() => {
    setTimeout(() => {
      listBoxRef.current?.focus();
    }, 0);
  }, []);

  return (
    <OverlayContainer>
      <FocusScope restoreFocus contain>
        <div {...overlay.overlayProps} {...overlayPosition.overlayProps} ref={overlayRef}>
          <ResolutionSelectListBox.Overlay
            animationRemainProps={props.animationRemainProps}
            transformOriginX={overlayPosition.arrowProps.style.left}
            transformOriginY={overlayPosition.arrowProps.style.top}
            placement={overlayPosition.placement}
            tabIndex={-1}
          >
            <AnchorPointer {...overlayPosition.arrowProps} placement={overlayPosition.placement} />

            <ResolutionSelectListBox.Root
              {...mergeProps(listBox.listBoxProps, props.menuProps)}
              ref={listBoxRef}
            >
              {[...props.selectState.collection].map((section) => (
                <ResolutionSelectSection
                  key={section.key}
                  section={section}
                  selectState={props.selectState}
                />
              ))}
            </ResolutionSelectListBox.Root>
          </ResolutionSelectListBox.Overlay>
        </div>
      </FocusScope>
    </OverlayContainer>
  );
}

ResolutionSelectListBox.Overlay = styled.div`
  position: relative;
  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  outline: 0;
  opacity: 0;

  ${(props) => {
    if (props.animationRemainProps == null) {
      return {
        opacity: 1,
      };
    }

    // Issue on Safari if placement is undefined and it's defined on the next render Safari does
    // not seem to allow an update for transform origin.
    if (props.placement == null) {
      return;
    }

    const { isExiting, duration } = props.animationRemainProps;

    const animation = isExiting ? animationScalePerspFade.out : animationScalePerspFade.in;

    const vars = animationScalePerspFade.setupVars(
      props.placement,
      props.transformOriginX,
      props.transformOriginY
    );

    return css`
      ${vars};
      animation: ${animation} ${duration} ease-out 1 forwards;
    `;
  }};
`;

ResolutionSelectListBox.Root = styled.ul`
  ${scrollbarDark};
  display: flex;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
`;
