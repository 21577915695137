import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { usePresence } from 'framer-motion';

// import { DismissButton, OverlayContainer, FocusScope } from 'react-aria';
import { OverlayContainer, FocusScope, usePreventScroll, useModal, useDialog } from 'react-aria';
import { getFocusableTreeWalker } from '@react-aria/focus';

import { mergeRefs } from '../../lib/mergeRefs';
import { OverlayStackContext } from './OverlayStackContext';
import { OverlayContext } from './OverlayContext';
import { OverlaySource } from './OverlaySource';

import { overlayCloseReasons } from './overlayCloseReasons';

import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useCurrentProps } from '../../hooks/useCurrentProps';
import { useOverlayStackContext } from './OverlayStackContext';
import { useOverlay } from './useOverlay';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';
import { isReducedMotion } from '../../theme/classes/reducedMotion';
import { animationFade } from '../../theme/animations/animationFade';

export function DialogShiftLock(props) {
  const [isPreventCloseOverride, setPreventCloseOverride] = useState(null);
  const [stackIndex, setStackIndex] = useState(-1);

  const overlayContext = useCurrentProps({ setPreventCloseOverride, stackIndex });

  const overlayRef = useRef();
  const rootRef = useRef();
  const instanceRef = useRef({
    didFocus: false,
    isPreventClose: isPreventCloseOverride,
    onCloseRequest: onCloseRequestMiddleWare,
  });

  function onCloseRequestMiddleWare(reason) {
    if (isPreventCloseOverride) {
      return;
    }

    if (props.onClose != null) {
      // If something wants to respond different to close behavior.
      // E.g. click outside = save or escape = cancel.
      // They are responsible for calling overlayTriggerState.close()
      props.onClose?.(reason);
      return;
    }

    // Stack removal happens in useLayoutEffect cleanup.
    props.overlayTriggerState?.close();
  }

  useLayoutEffect(() => {
    instanceRef.current.onCloseRequest = onCloseRequestMiddleWare;
    instanceRef.current.isPreventClose = isPreventCloseOverride;
  });

  const { context, isRoot } = useOverlayStackContext();

  useLayoutEffect(() => {
    const source = new OverlaySource({
      ref: overlayRef,
      onCloseRequest(reason) {
        instanceRef.current.onCloseRequest(reason);
      },
      getIsPreventClose() {
        return instanceRef.current.isPreventClose;
      },
    });

    const length = context.stack.push(source);
    setStackIndex(length - 1);

    return function () {
      // It might have been removed already onCloseRequest
      // console.log('current:stack', [...context.stack])
      const index = context.stack.findIndex((element) => element === source);

      if (index > -1) {
        context.stack.splice(index, 1);
        // console.log('next:stack', [...context.stack])
      }
    };
  }, [context]);

  const overlay = useOverlay(
    {
      context,
      isOpen: props.overlayTriggerState?.isOpen ?? true,
      isKeyboardDismissDisabled: false,
      onCloseRequest: (reason) => {
        // This one fires for keyboard.
        onCloseRequestMiddleWare(reason);
      },
    },
    overlayRef
  );

  usePreventScroll();

  const modal = useModal();
  const dialog = useDialog(
    {
      role: props.role ?? 'dialog',
      'aria-label': props['aria-label'] ?? 'Dialog',
    },
    overlayRef
  );

  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    // ${theme.duration.normal}
    !isPresent && setTimeout(safeToRemove, 350);
  }, [isPresent, safeToRemove]);

  const shiftLockChildrenRef = useRef();

  useResizeObserver({
    ref: shiftLockChildrenRef,
    onResize() {
      const rootElement = rootRef.current;
      const shiftLockChildrenElement = shiftLockChildrenRef.current;

      const offsetWidth = shiftLockChildrenElement.offsetWidth;
      const offsetHeight = shiftLockChildrenElement.offsetHeight;

      rootElement.style.setProperty('--content-width', `${offsetWidth}px`);
      rootElement.style.setProperty('--content-height', `${offsetHeight}px`);
    },
  });

  const shiftLockRef = useRef();
  const backgroundRef = useRef();

  useLayoutEffect(() => {
    if (props.layoutId != null) {
      const rootElement = rootRef.current;
      const shiftLockChildrenElement = shiftLockChildrenRef.current;

      const offsetWidth = shiftLockChildrenElement.offsetWidth;
      const offsetHeight = shiftLockChildrenElement.offsetHeight;

      const currentWidth = rootElement.style.getPropertyValue('--content-width');
      const currentHeight = rootElement.style.getPropertyValue('--content-height');

      const nextWidth = `${offsetWidth}px`;
      const nextHeight = `${offsetHeight}px`;

      rootElement.style.setProperty('--content-width', nextWidth);
      rootElement.style.setProperty('--content-height', nextHeight);

      // Ignore initial and after that only enable if dimensions have changed.
      if (
        (currentWidth !== '' && currentWidth !== nextWidth) ||
        (currentHeight !== '' && currentHeight !== nextHeight)
      ) {
        shiftLockRef.current.setAttribute('data-layout-animation', true);
        backgroundRef.current.setAttribute('data-layout-animation', true);
      }
    }
  }, [props.layoutId]);

  function handleTransitionEnd(event) {
    if (event.target === event.currentTarget) {
      shiftLockRef.current.setAttribute('data-layout-animation', false);
      backgroundRef.current.setAttribute('data-layout-animation', false);
    }
  }

  let [tabIndex, setTabIndex] = useState(undefined);

  const style = {
    '--stack-index': stackIndex,
  };

  // Check if the overlay has a focusable child. If not we make the overlay itself focusable so it
  // can be closed with the keyboard.
  useLayoutEffect(() => {
    if (overlayRef.current) {
      const update = () => {
        // Detect if there are any tabbable elements and update the tabIndex accordingly.
        let walker = getFocusableTreeWalker(overlayRef.current, { tabbable: true });
        setTabIndex(walker.nextNode() ? undefined : 0);
      };

      update();

      // Update when new elements are inserted, or the tabIndex/disabled attribute updates.
      const observer = new MutationObserver(update);
      observer.observe(overlayRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
        attributeFilter: ['tabIndex', 'disabled'],
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  // If autoFocus was enabled and we set the tabIndex to the overlay make ensure we focus it. But
  // only once during the lifetime of this component.
  useLayoutEffect(() => {
    if (props.autoFocus === true && tabIndex != null && instanceRef.current.didFocus === false) {
      instanceRef.current.didFocus = true;
      overlayRef.current.focus();
    }
  }, [props.autoFocus, tabIndex]);

  function wrap(children) {
    if (isRoot === true) {
      return (
        <OverlayStackContext.Provider value={context}>{children}</OverlayStackContext.Provider>
      );
    }

    return children;
  }

  return wrap(
    <OverlayContext.Provider value={overlayContext}>
      <OverlayContainer>
        <DialogShiftLock.Root
          ref={rootRef}
          data-is-open={isPresent}
          data-is-exiting={isPresent === false}
        >
          <FocusScope
            restoreFocus={props.restoreFocus ?? true}
            contain={props.containFocus ?? true}
            autoFocus={props.autoFocus ?? true}
          >
            <DialogShiftLock.Content
              {...overlay.overlayProps}
              {...dialog.dialogProps}
              {...modal.modalProps}
              ref={mergeRefs([overlayRef, props.overlayRef])}
              style={style}
              data-stack-index={stackIndex}
              tabIndex={tabIndex}
            >
              <DialogShiftLock.Background ref={backgroundRef} data-is-shift-locked={true} />

              <DialogShiftLock.ShiftLock ref={shiftLockRef} onTransitionEnd={handleTransitionEnd}>
                <DialogShiftLock.Children ref={shiftLockChildrenRef}>
                  {props.children}
                </DialogShiftLock.Children>
              </DialogShiftLock.ShiftLock>
            </DialogShiftLock.Content>
          </FocusScope>
        </DialogShiftLock.Root>
      </OverlayContainer>
    </OverlayContext.Provider>
  );
}

DialogShiftLock.Background = styled.div`
  position: absolute;

  top: 50%;
  left: 50%;

  --translate-x: -50%;
  --translate-y: -50%;

  z-index: -1;

  transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(1);

  width: var(--content-width);
  height: var(--content-height);

  max-width: calc(100vw - ${su(4)});
  max-height: calc(100vh - ${su(4)});

  background-color: ${theme.color.component};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  outline: 0;

  &[data-layout-animation='true'] {
    transition: ${theme.duration.normal} ease-in-out;
    transition-property: width, height;
  }
`;

DialogShiftLock.Content = styled.div`
  position: relative;
  outline: 0;
`;

DialogShiftLock.Children = styled.div`
  display: flex;
  flex-direction: column;

  max-width: calc(100vw - ${su(4)});
  max-height: calc(100vh - ${su(4)});
`;

/**
 * ShiftLock
 *
 * This component is used to avoid layout shifts while animating between sizes.
 *
 */
DialogShiftLock.ShiftLock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  --horizontal-clip: calc((100vw - var(--content-width)) / 2);
  --vertical-clip: calc((100vh - var(--content-height)) / 2);

  clip-path: inset(var(--vertical-clip) var(--horizontal-clip) round ${theme.borderRadius.default});

  &[data-layout-animation='true'] {
    transition: ${theme.duration.normal} ease-in-out;
    transition-property: clip-path;
  }
`;

DialogShiftLock.Root = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${theme.color.overlay};
  z-index: calc(${theme.zIndex.overlay} + var(--stack-index, 0));

  &[data-is-open='true'] {
    animation: ${animationFade.in} ${theme.duration.normal} ease-out forwards;

    &:not(${isReducedMotion}) {
      ${DialogShiftLock.Background}, ${DialogShiftLock.Children} {
        animation: ${keyframes`
          0% {
            transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(0.8);
            animation-timing-function: ease-out;
          }
          60% {
            transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(1.05);
            animation-timing-function: ease-in-out;
          }
          100% {
            transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(1);
          }
        `} ${theme.duration.normal} ease-out none;
      }
    }
  }

  &[data-is-exiting='true'] {
    animation: ${animationFade.out} ${theme.duration.normal} ease-out forwards;

    &:not(${isReducedMotion}) {
      ${DialogShiftLock.Background}, ${DialogShiftLock.Children} {
        animation: ${keyframes`
          0% {
            transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(1);
            animation-timing-function: ease-out;
          }
          40% {
            transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(1.05);
            animation-timing-function: ease-in;
          }
          100% {
            transform: translate(var(--translate-x, 0), var(--translate-y, 0)) scale(0.8);
          }
        `} ${theme.duration.normal} ease-out none;
      }
    }
  }
`;

DialogShiftLock.REASON = overlayCloseReasons;
