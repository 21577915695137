import { unstable_batchedUpdates } from 'react-dom';
import { matchPath } from 'react-router-dom';
// import debugModule from 'debug';

import { HomeyStore } from './HomeyStore';
import { AuthStore } from './AuthStore';

import { history } from '../history';

// const debug = debugModule('Homey');

function handleUser(user) {
  // debug('handleUser');

  if (history.location != null && user != null) {
    const match = matchPath(history.location.pathname, '/homeys/:homeyId');

    let getCurrent = null;

    if (match != null) {
      const homeyId = match.params.homeyId;
      getCurrent = () => {
        return AuthStore.getCurrentHomeyById(user, homeyId);
      };
    } else {
      getCurrent = () => {
        return AuthStore.getCurrentHomey(user);
      };
    }

    try {
      const current = getCurrent();

      if (current.id === AuthStore.currentHomeyId) {
        // debug('Homey ID was the same aborting.');
        return;
      }

      AuthStore.currentHomeyId = current.id;

      if (AuthStore.prevController != null) {
        AuthStore.prevController.abort();
        HomeyStore.destroyApi();
      }

      const controller = new AbortController();
      const signal = controller.signal;
      AuthStore.prevController = controller;

      unstable_batchedUpdates(() => {
        AuthStore.set({ current, error: null });
      });
      // debug('authenticateHomey');
      HomeyStore.authenticateHomey(current, { signal }).then().catch(console.error);
    } catch (error) {
      AuthStore.set({ error });
      return;
    }
  }

  if (user == null) {
    HomeyStore.reset();
  }
}

AuthStore.emitter.on('user', ({ user }) => {
  handleUser(user);
});

history.listen((location, action) => {
  const match = matchPath(location.pathname, '/homeys/:homeyId');

  if (match != null) {
    const homeyId = match.params.homeyId;

    if (AuthStore.currentHomeyId !== homeyId) {
      handleUser(AuthStore.get().user);
    }
  }
});
