import React from 'react';
import styled from '@emotion/styled';

import { ImageButton } from '../../common/ImageButton';

import colorWheel from '../images/color/color-wheel.png';
import temperatureWheel from '../images/color/temperature-wheel.png';

export function ModeButton({ mode, ...rest }) {
  return <ModeButtonBase {...rest} url={getImage(mode)} />;
}

function getImage(mode) {
  switch (mode) {
    case 'color':
      return colorWheel;
    case 'temperature':
      return temperatureWheel;
    default:
      return colorWheel;
  }
}

const ModeButtonBase = styled(ImageButton)`
  position: relative;

  &:first-of-type:not(:only-child) {
    margin-right: 20px;
  }
`;
