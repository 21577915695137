import { RouteManager } from '../../RouteManager';
import { ToastManager } from '../../ToastManager';
import { UserMeStore } from '../../store/user-me/UserMeStore';
import { AdvancedFlowStore } from '../../store/advanced-flow/AdvancedFlowStore';

import { promptMessage } from '../../components/overlay/DialogPrompt';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useAdvancedFlow } from '../../store/advanced-flow/useAdvancedFlows';
import { useApi } from '../../store/HomeyStore';
import { useUserMe } from '../../store/user-me/useUserMe';

import { theme } from '../../theme/theme';

import { ContextMenuContent } from '../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../theme/icons/interface/trash-fill/trash-fill';
import { iconCopyDuplicate } from '../../theme/icons/interface/copy-duplicate';
import { iconHeartFill } from '../../theme/icons/interface/heart-fill';
import { iconHeartOutline } from '../../theme/icons/interface/heart-outline';
import { iconPlay } from '../../theme/icons/interface/play';
import { iconAdvancedFlow } from '../../theme/icons/interface/advanced-flow/advanced-flow';

export function FlowNavigationItemAdvancedFlowContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { api } = useApi();
  const { advancedFlow } = useAdvancedFlow({ advancedFlowId: props.nodeId });

  const userMe = useUserMe();
  const isFavoriteFlow = userMe.favoriteFlows?.includes(advancedFlow?.id) ?? false;

  const toggleText = advancedFlow.enabled
    ? i18n.messageFormatter(`flow.disable`)
    : i18n.messageFormatter(`flow.enable`);

  function onAction(key) {
    (async () => {
      switch (key) {
        case 'trigger': {
          try {
            const result = await api.flow.triggerAdvancedFlow({ id: props.nodeId, state: {} });
            ToastManager.add({
              icon: iconAdvancedFlow,
              message: i18n.messageFormatter(`flow.startedAdvancedFlow`, {
                name: advancedFlow.name,
              }),
            });
            console.log(result);
          } catch (error) {
            console.error(error);
            ToastManager.handleError(error);
          }

          break;
        }

        case 'toggle':
          AdvancedFlowStore.updateAdvancedFlow(
            {
              id: advancedFlow.id,
              advancedFlow: { ...advancedFlow, enabled: !advancedFlow.enabled },
            },
            {
              handleError: true,
            }
          ).catch(ToastManager.handleError);
          break;

        case 'favorite':
          const favoriteName = `**${advancedFlow.name}**`;

          if (isFavoriteFlow) {
            try {
              await UserMeStore.removeFavoriteFlow({ id: advancedFlow.id });
              ToastManager.add({
                icon: iconHeartOutline,
                iconColor: theme.icon.color_light,
                message: i18n.messageFormatter(`flow.removeFavorite`, {
                  name: favoriteName,
                }),
              });
            } catch (error) {
              ToastManager.handleError(error);
            }
            return;
          }

          try {
            await UserMeStore.addFavoriteFlow({ id: advancedFlow.id });
            ToastManager.add({
              icon: iconHeartFill,
              iconColor: theme.color.special_favorite,
              message: i18n.messageFormatter(`flow.addFavorite`, {
                name: favoriteName,
              }),
            });
          } catch (error) {
            ToastManager.handleError(error);
          }

          break;

        case 'duplicate':
          try {
            const result = await api.flow.createAdvancedFlow({
              advancedflow: {
                name: `${advancedFlow.name} (${i18n.messageFormatter('common.copyNoun')})`,
                folder: advancedFlow.folder,
                enabled: advancedFlow.enabled,
                cards: advancedFlow.cards,
              },
            });

            RouteManager.toAdvancedFlow(result.id);
          } catch (error) {
            ToastManager.handleError(error);
          }

          break;

        case 'rename':
          props.onRenameRequest();
          break;
        case 'delete':
          try {
            await promptMessage({
              message: i18n.messageFormatter('prompt.delete', { name: advancedFlow.name }),
            });

            await AdvancedFlowStore.deleteAdvancedFlow({
              id: advancedFlow.id,
            });

            // Reroute if this Flow was selected.
            if (RouteManager.isActiveFlow(advancedFlow.id)) {
              RouteManager.toFlows({ skipUnsavedChangesCheck: true });
            }
          } catch {}
          break;
        default:
          break;
      }
    })().catch(console.error);
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.start')}
        icon={iconPlay}
        isDisabled={advancedFlow.triggerable !== true}
        onPress={() => {
          onAction('trigger');
        }}
      />

      <ContextMenuContent.ListItem
        label={toggleText}
        onPress={() => {
          onAction('toggle');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.rename')}
        onPress={() => {
          onAction('rename');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.favorite')}
        icon={isFavoriteFlow ? iconHeartFill : iconHeartOutline}
        onPress={() => {
          onAction('favorite');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <DeveloperMenu onIdRequest={() => advancedFlow?.id} onResourceRequest={() => advancedFlow} />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
