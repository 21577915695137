import { useI18n } from '../../../hooks/useI18nFormatters';

import { ArgumentDuration } from './ArgumentDuration';

export function ArgumentDelay(props) {
  const { i18n } = useI18n();

  const label = i18n.messageFormatter('flow.argument.delay.title');
  const placeholder = i18n.messageFormatter('flow.argument.delay.placeholder');
  const prefix = i18n.messageFormatter('flow.argument.delay.prefix');
  const storeValue = props.data?.delay;

  return (
    <ArgumentDuration
      {...props}
      label={label}
      placeholder={placeholder}
      prefix={prefix}
      storeValue={storeValue}
    />
  );
}
