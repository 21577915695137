import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { publicUrl } from '../../theme/functions/publicUrl';

export const hourglass = keyframes`
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(720deg);
  }
`;

export function Logo(props) {
  return (
    <S.Root
      className={props.className}
      style={{ ...props.style, '--size': props.size }}
      src={publicUrl('/img/logo.png')}
      alt={props.alt}
      data-is-paused={props.isLoading !== true}
    />
  );
}

function S() {}
Logo.S = S;

S.Root = styled.img`
  width: var(--size, 32px);
  height: var(--size, 32px);
  animation: ${hourglass} 1.2s infinite;

  &[data-is-paused='true'] {
    animation-play-state: paused;
  }

  animation-play-state: ${(props) => !props.isLoading && 'paused'};
`;
