import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { AppStore } from './AppStore';
import { useAttach } from '../useAttach';

import { selectData, selectById } from '../selectors';

export function useAppsData() {
  useAttach(AppStore, 'appsData');

  return AppStore.store(selectData, shallow);
}

export function useAppsById() {
  useAttach(AppStore, 'appsById');

  return AppStore.store(selectById, shallow);
}

export function useApp({ appId }) {
  useAttach(AppStore, 'app');

  const select = useCallback(
    (state) => {
      return {
        app: state.byId?.[appId] ?? null,
        appInstance: state.data?.[appId] ?? null,
        api: state.api,
        loading: state.loading,
      };
    },
    [appId]
  );

  return AppStore.store(select, shallow);
}

export function useAppsAttach() {
  useAttach(AppStore, 'useAppsAttach');
}
