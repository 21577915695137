import { useLayoutEffect, useRef, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import styled from '@emotion/styled';
import { mergeProps } from 'react-aria';

import { isMACOS } from '../../../lib/platform';
import { mergeRefs } from '../../../lib/mergeRefs';
import { ValidationError } from '../../../lib/InternalError';
import { ToastManager } from '../../../ToastManager';
import { RouteManager } from '../../../RouteManager';
import { AdvancedFlowViewStore } from './store/AdvancedFlowViewStore';
import { AdvancedFlowViewUtils } from './store/AdvancedFlowViewUtils';
import { TokenHoverContextProvider } from '../TokenHoverContext';
import { NodeArgumentsContext } from './card/NodeArgumentsContext';
// import { AdvancedFlowTokensContextProvider } from './AdvancedFlowTokensContext';

import { useQuery } from '../../../hooks/useQuery';
import { useDropGrid } from './useDropGrid';
import { useAdvancedFlowdRouteId } from '../useAdvancedFlowdRouteId';
import { useSelectBox } from '../../../hooks/useSelectBox';
import { useSpacebarDrag } from './useSpacebarDrag';
import { usePointerPosition } from '../../../hooks/usePointerPosition';
import { useEditorKeyboardShortcuts } from '../../../hooks/useEditorKeyboardShortcuts';
import { useAdvancedFlowViewContext } from './AdvancedFlowViewContext';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useAdvancedFlowValidation } from './useAdvancedFlowValidation';
import { useAdvancedFlowUnsavedChangesPrompt } from './useAdvancedFlowUnsavedChangesPrompt';
import { useDocumentScrollOffset, useDocumentGrid } from '../../../Layout';
import { useAdvancedFlowCursorCard } from './useAdvancedFlowCursorCard';
import { useScrollContainerFade } from './useScrollContainerFade';
import { useResizeObserver } from '../../../hooks/useResizeObserver';

import { theme } from '../../../theme/theme';
import { scrollbars } from '../../../theme/elements/scrollbars';
import { su } from '../../../theme/functions/su';
import { scrollContainerMask } from './useScrollContainerFade';

import { ContextMenu } from '../../../components/common/context-menu/ContextMenu';
import { DraggableNode } from './card/DraggableNode';
import { AdvancedFlowCard } from './card/AdvancedFlowCard';
import { DelayCard } from './card/DelayCard';
import { StartCard } from './card/StartCard';
import { AllCard } from './card/AllCard';
import { AnyCard } from './card/AnyCard';
import { NoteCard } from './card/NoteCard';

import { Connections } from './connections/Connections';
import { AdvancedFlowViewContextMenuContent } from './AdvancedFlowViewContextMenuContent';
import { AdvancedFlowTestBar } from './AdvancedFlowTestBar';
import { AdvancedFlowViewCardDialog } from './AdvancedFlowViewCardDialog';

export function AdvancedFlowView() {
  const scrollContainerRef = useRef();
  const rootRef = useRef();
  const cardsLayerRef = useRef();
  const cardsLayerSizerRef = useRef();
  const connectionsLayerRef = useRef();
  const SVGPortalLayerRef = useRef();
  const { i18n } = useI18n();

  const instanceRef = useRef({
    clipboard: {},
    // TODO
    // This position is slightly off because of Root padding. It needs to be relative to the cards
    // layer element. But we can't attach it to that component because that one has no pointer
    // events. We should probably adjust this in usePointerPosition with a passed offset parent ref.
    pointer: null,
    getPointerPosition() {
      return instanceRef.current.pointer;
    },
  });

  const advancedFlowViewContext = useAdvancedFlowViewContext();
  const { advancedFlowRouteId } = useAdvancedFlowdRouteId();
  const {
    advancedFlow,
    connections,
    activeConnectors,
    nodeChildren,
    nodeParents,
    conditionTest,
    conditionSave,
    testNodeId,
    cardCursorData,
  } = AdvancedFlowViewStore.useAdvancedFlowView();

  const params = useQuery();
  const resetId = params.get('id');
  const folderId = params.get('folderId');

  AdvancedFlowViewStore.useSetAdvancedFlowView({
    advancedFlowId: advancedFlowRouteId,
    folderId,
    resetId,
  });

  const isTesting = conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  const isSaving = conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isInteractionDisabled = cardCursorData != null;

  const isDocumentGridDisabled = isTesting;
  const isDragDropDisabled = isTesting || isSaving || isInteractionDisabled;

  useDocumentScrollOffset({ ref: scrollContainerRef });
  useDocumentGrid({ isDisabled: isDocumentGridDisabled });

  const { prompt } = useAdvancedFlowUnsavedChangesPrompt();

  useAdvancedFlowValidation({
    advancedFlowViewContext,
    messageFormatter: i18n.messageFormatter,
  });

  const selectBox = useSelectBox(
    {
      setSelected: AdvancedFlowViewStore.setSelected,
      isDisabled: isInteractionDisabled,
    },
    rootRef
  );

  usePointerPosition(
    {
      onChange(pointer) {
        instanceRef.current.pointer = pointer;
      },
    },
    rootRef
  );

  const spacebarDrag = useSpacebarDrag({
    scrollContainerRef,
    isDisabled: selectBox.isSelecting,
  });

  useScrollContainerFade({
    scrollContainerRef: scrollContainerRef,
    scrollContainerChildRef: rootRef,
  });

  const setDimensions = useCallback(() => {
    const rootElement = rootRef.current;
    const style = getComputedStyle(rootElement);

    const paddingX = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const paddingY = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

    const elementWidth = rootElement.clientWidth - paddingX;
    const elementHeight = rootElement.clientHeight - paddingY;

    // Includes the overflowing content.
    const scrollWidth = cardsLayerSizerRef.current.scrollWidth;
    const scrollHeight = cardsLayerSizerRef.current.scrollHeight;

    // We could also include the connections because they might be larger on the right side when
    // curved but svg doesnt seem to have a proper getBoundingClientRect or scrollWidth that
    // matches the right most line.

    cardsLayerSizerRef.current.style.width = `${scrollWidth}px`;
    cardsLayerSizerRef.current.style.height = `${scrollHeight}px`;

    // When cards layer width is larger than root.
    if (scrollWidth > elementWidth) {
      rootElement.style.width = `${scrollWidth + paddingX + 100}px`;
    }

    // When cards layer height is larger than root.
    if (scrollHeight > elementHeight) {
      rootElement.style.height = `${scrollHeight + paddingY + 100}px`;
    }
  }, []);

  const expandWidth = useCallback((value) => {
    const rect = rootRef.current.getBoundingClientRect();
    const minWidth = rect.width;
    rootRef.current.style.width = `${minWidth + (value ?? 400)}px`;
  }, []);

  const expandHeight = useCallback((value) => {
    const rect = rootRef.current.getBoundingClientRect();
    const minHeight = rect.height;
    rootRef.current.style.height = `${minHeight + (value ?? 400)}px`;
  }, []);

  useLayoutEffect(() => {
    const rootElement = rootRef.current;
    const cardsSizerElement = cardsLayerSizerRef.current;

    return function () {
      rootElement.style.height = '0';
      rootElement.style.width = '0';
      cardsSizerElement.style.height = '0';
      cardsSizerElement.style.width = '0';
    };
  }, [advancedFlowRouteId]);

  // Cards can grow after load this would cause incorrect size still but only is an issue if a very
  // large card is at the bottom.
  useLayoutEffect(() => {
    if (advancedFlow != null) {
      setDimensions();
    }
  }, [advancedFlow, setDimensions]);

  useResizeObserver({
    ref: scrollContainerRef,
    onResize() {
      setDimensions();
    },
  });

  const advancedFlowCursorCard = useAdvancedFlowCursorCard(
    {
      cardCursorData,
      isPlacementDisabled: spacebarDrag.isSpacebarPress,
      expandWidth,
      expandHeight,
      getPointerPosition: instanceRef.current.getPointerPosition,
    },
    rootRef
  );

  const dropGrid = useDropGrid({
    advancedFlow,
    expandWidth,
    expandHeight,
    isEditMode: isDragDropDisabled !== true,
    onUpdate({ nodes }) {
      unstable_batchedUpdates(() => {
        for (const node of nodes) {
          const connections =
            advancedFlowViewContext.connectionsByNodeId.get(node.nodeId) ?? new Map();

          for (const [, connection] of connections) {
            connection.recalculate();
          }
        }
      });
    },
  });

  useEditorKeyboardShortcuts({
    ref: rootRef,
    onCopy(event) {
      const selected = AdvancedFlowViewStore.getSelected();
      if (selected?.size > 0) {
        event.preventDefault();
        event.stopPropagation();
        handleCopyRequest(selected);
      }
    },
    onPaste(event) {
      event.preventDefault();
      event.stopPropagation();
      handlePasteRequest({});
    },
    onCut(event) {
      const selected = AdvancedFlowViewStore.getSelected();
      if (selected?.size > 0) {
        event.preventDefault();
        event.stopPropagation();
        handleCutRequest(selected);
      }
    },
    onDuplicate(event) {
      const selected = AdvancedFlowViewStore.getSelected();
      if (selected?.size > 0) {
        event.preventDefault();
        event.stopPropagation();
        handleDuplicateRequest(selected);
      }
    },
    onSelectAll(event) {
      event.preventDefault();
      event.stopPropagation();
      handleSelectAllRequest();
    },
    onDelete(event) {
      const selected = AdvancedFlowViewStore.getSelected();
      if (selected?.size > 0) {
        event.preventDefault();
        event.stopPropagation();
        handleDeleteRequest(selected);
        AdvancedFlowViewStore.setSelected(new Map());
      }
    },
    onSave(event) {
      event.preventDefault();
      event.stopPropagation();
      AdvancedFlowViewStore.saveAdvancedFlow({
        route: true,
      }).catch(console.error);
    },
    onUndo(event) {
      event.preventDefault();
      event.stopPropagation();
      AdvancedFlowViewStore.undo();
    },
    onRedo(event) {
      event.preventDefault();
      event.stopPropagation();
      AdvancedFlowViewStore.redo();
    },
    onCancel(event) {
      // If it was tested or stopped clear the test state so the test bar disappears.
      const state = AdvancedFlowViewStore.get();

      const isTesting = state.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
      const isTested = state.conditionTest === AdvancedFlowViewStore.conditionTest.tested;
      const isStopped = state.conditionTest === AdvancedFlowViewStore.conditionTest.stopped;

      if (isTested || isStopped) {
        event.preventDefault();
        event.stopPropagation();
        advancedFlowViewContext.resetAll().catch(console.error);
        AdvancedFlowViewStore.set({
          conditionTest: AdvancedFlowViewStore.conditionTest.initial,
        });
        return;
      }

      // Stop current execution.
      if (isTesting) {
        event.preventDefault();
        event.stopPropagation();
        AdvancedFlowViewStore.set({
          conditionTest: AdvancedFlowViewStore.conditionTest.stopped,
        });
        return;
      }

      const selected = AdvancedFlowViewStore.getSelected();

      // If something was selected clear the selection.
      if (selected?.size > 0) {
        event.preventDefault();
        event.stopPropagation();

        AdvancedFlowViewStore.setSelected(new Map([]));
      }
    },
    isDisabled: false,
  });

  function mergeSelectedIfSelected(node) {
    const key = node.id;
    const entry = [key, node];
    const selected = AdvancedFlowViewStore.getSelected();

    if (selected.has(key)) {
      return new Map([...selected, entry]);
    }

    return new Map([entry]);
  }

  function handleCopyRequest(map, node) {
    if (map == null && node != null) {
      map = mergeSelectedIfSelected(node);
    }

    if (map != null) {
      instanceRef.current.clipboard = AdvancedFlowViewUtils.copyFromAdvancedFlow({
        advancedFlow,
        map,
      });
    }
  }

  function handleCutRequest(map, node) {
    if (map == null && node != null) {
      map = mergeSelectedIfSelected(node);
    }

    if (map != null) {
      const { clipboard, nextAdvancedFlow, nextNodes } = AdvancedFlowViewUtils.cutFromAdvancedFlow({
        advancedFlow,
        map,
      });

      if (nextAdvancedFlow === advancedFlow) return;

      instanceRef.current.clipboard = clipboard;

      AdvancedFlowViewStore.updateAdvancedFlow({ advancedFlow: nextAdvancedFlow });
      AdvancedFlowViewStore.setSelected(nextNodes);
    }
  }

  function handlePasteRequest({ pointer }) {
    const { nextAdvancedFlow, nextNodes } = AdvancedFlowViewUtils.pasteIntoAdvancedFlow({
      advancedFlow,
      clipboard: instanceRef.current.clipboard,
      pointer: pointer ?? instanceRef.current.pointer,
    });

    if (nextAdvancedFlow === advancedFlow) return;

    AdvancedFlowViewStore.updateAdvancedFlow({ advancedFlow: nextAdvancedFlow });
    AdvancedFlowViewStore.setSelected(nextNodes);
  }

  function handleDeleteRequest(map, node) {
    if (map == null && node != null) {
      map = mergeSelectedIfSelected(node);
    }

    if (map != null && map.size > 0) {
      const nextAdvancedFlow = AdvancedFlowViewUtils.deleteFromAdvancedFlow({
        advancedFlow,
        map,
      });
      AdvancedFlowViewStore.updateAdvancedFlow({ advancedFlow: nextAdvancedFlow });
    }
  }

  function handleInvertRequest(map, node) {
    if (map == null && node != null) {
      map = mergeSelectedIfSelected(node);
    }

    if (map != null && map.size > 0) {
      const nextAdvancedFlow = AdvancedFlowViewUtils.invertInAdvancedFlow({
        advancedFlow,
        map,
      });
      AdvancedFlowViewStore.updateAdvancedFlow({ advancedFlow: nextAdvancedFlow });
    }
  }

  function handleDuplicateRequest(map, node) {
    if (map == null && node != null) {
      map = mergeSelectedIfSelected(node);
    }

    if (map != null && map.size > 0) {
      const { nextAdvancedFlow, nextNodes } = AdvancedFlowViewUtils.duplicateInAdvancedFlow({
        advancedFlow,
        map,
      });
      AdvancedFlowViewStore.updateAdvancedFlow({ advancedFlow: nextAdvancedFlow });
      AdvancedFlowViewStore.setSelected(nextNodes);
    }
  }

  function handleReplaceRequest({ nodeId }) {
    const card = advancedFlow.cards[nodeId];
    RouteManager.pushState({
      cardDialog: {
        type: card.type,
        nodeId,
        currentCard: card,
      },
    });
  }

  // TODO
  // Make this a method of selectBox.
  function handleSelectAllRequest() {
    const { nextNodes } = AdvancedFlowViewUtils.selectAllInAdvancedFlow({
      advancedFlow,
    });
    AdvancedFlowViewStore.setSelected(nextNodes);
  }

  function handleNodePress(event, { type, nodeId }) {
    window.getSelection().removeAllRanges();

    if (event.ctrlKey === true || (isMACOS && event.metaKey === true)) {
      AdvancedFlowViewStore.setSelected((prevSelected) => {
        if (prevSelected.has(nodeId)) {
          return new Map(
            [...prevSelected].filter(([key, value]) => {
              return value.id !== nodeId;
            })
          );
        }

        return new Map([...prevSelected, [nodeId, { type: type, id: nodeId }]]);
      });
      return;
    }

    if (event.altKey === true && event.shiftKey + event.metaKey + event.ctrlKey === 0) {
      handleStartRequest({ nodeId });
      return;
    }

    AdvancedFlowViewStore.setSelected(
      new Map([
        [
          nodeId,
          {
            type: type,
            id: nodeId,
          },
        ],
      ])
    );
  }

  function handleStartRequest({ nodeId, tokens }) {
    AdvancedFlowViewStore.testAdvancedFlow({
      fromNodeId: nodeId,
      tokens,
      async onCardStart({ nodeId, delay, data }) {
        const node = advancedFlowViewContext.nodes.get(nodeId);
        await node.onCardStart({ delay, data });
      },
      async onCardUpdate({ nodeId, data }) {
        const node = advancedFlowViewContext.nodes.get(nodeId);
        await node.onCardUpdate({ data });
      },
      async onCardEnd({ nodeId, result, tokens, usedTokens, elapsedTime, data }) {
        const node = advancedFlowViewContext.nodes.get(nodeId);
        await node.onCardEnd({ result, tokens, usedTokens, elapsedTime, data });
      },
      async onCardError({ nodeId, error, tokens, usedTokens, elapsedTime, data }) {
        const node = advancedFlowViewContext.nodes.get(nodeId);
        await node.onCardError({ error, tokens, usedTokens, elapsedTime, data });
      },
      async onBeforeStart({ reachableNodes, reachableConnections }) {
        let isAdvancedFlowInvalid = false;

        for (const [
          nodeId,
          nodeArgumentsHandler,
        ] of advancedFlowViewContext.nodeArgumentsHandlerByNodeId) {
          if (reachableNodes.has(nodeId) === false) continue;

          // eslint-disable-next-line no-unused-vars
          const { isNodeInvalid, result } = nodeArgumentsHandler.onInvalidRequest();
          if (isNodeInvalid === true) {
            isAdvancedFlowInvalid = true;
          }
        }

        if (isAdvancedFlowInvalid === true) {
          throw new ValidationError.InvalidArguments(
            i18n.messageFormatter('flow.validation.invalid_arguments')
          );
        }
      },
      async onStart({ reachableNodes, reachableConnections }) {
        for (const [, node] of advancedFlowViewContext.nodes) {
          await node.onCardPreStart({ isReachable: reachableNodes.has(node.id) });
        }

        for (const [, connection] of advancedFlowViewContext.connections) {
          await connection.onConnectionPreStart({
            isReachable: reachableConnections.has(connection.key),
          });
        }
      },
      async onConnectionStart({ connectionKey }) {
        const connection = advancedFlowViewContext.connections.get(connectionKey);
        await connection?.onConnectionStart();
      },
      async onConnectionError({ connectionKey }) {
        const connection = advancedFlowViewContext.connections.get(connectionKey);
        await connection?.onConnectionError();
      },
    }).catch((error) => {
      if (error instanceof ValidationError) {
        ToastManager.handleError(error);
      } else {
        console.error(error);
      }
    });
  }

  function handleCardExplorerRequest({ type, pointer }) {
    RouteManager.pushState({
      cardDialog: {
        type: type,
        pointer: pointer,
      },
    });
  }

  return (
    // <AdvancedFlowTokensContextProvider>
    <ContextMenu
      content={
        <AdvancedFlowViewContextMenuContent
          offsetParentRef={cardsLayerRef}
          advancedFlow={advancedFlow}
          onCardExplorerRequest={handleCardExplorerRequest}
          onCopyRequest={handleCopyRequest}
          onPasteRequest={handlePasteRequest}
          onDeleteRequest={handleDeleteRequest}
        />
      }
      isDisabled={isInteractionDisabled}
      onContextMenu={(event) => {}}
    >
      {({ onContextMenu }) => {
        function handleContextMenu(event) {
          const selected = AdvancedFlowViewStore.getSelected();

          // If something was selected remove the selection.
          if (selected?.size > 0) {
            AdvancedFlowViewStore.setSelected(new Map([]));
          }

          onContextMenu(event);
        }

        return (
          <TokenHoverContextProvider>
            {prompt}
            {spacebarDrag.component}

            <AdvancedFlowTestBar conditionTest={conditionTest} />

            <AdvancedFlowView.ScrollContainer
              {...spacebarDrag.scrollContainerProps}
              ref={scrollContainerRef}
            >
              <AdvancedFlowView.Root
                {...mergeProps(selectBox.containerProps, advancedFlowCursorCard.containerProps)}
                ref={rootRef}
                tabIndex={-1}
                onContextMenu={handleContextMenu}
              >
                {advancedFlowCursorCard.component}
                {selectBox.component}

                <AdvancedFlowView.ConnectionsLayer ref={connectionsLayerRef}>
                  <Connections
                    containerRef={rootRef}
                    SVGPortalLayerRef={SVGPortalLayerRef}
                    advancedFlow={advancedFlow}
                    connections={connections}
                    conditionTest={conditionTest}
                    conditionSave={conditionSave}
                    isInteractionDisabled={isInteractionDisabled}
                  />
                </AdvancedFlowView.ConnectionsLayer>

                <AdvancedFlowView.SVGPortalLayer ref={SVGPortalLayerRef} />

                <AdvancedFlowView.CardsLayer
                  ref={mergeRefs([cardsLayerRef, dropGrid.ref])}
                  data-is-dragging={dropGrid.collectedProps.isDragging}
                >
                  <AdvancedFlowView.CardsLayerSizer ref={cardsLayerSizerRef}>
                    {Object.entries(advancedFlow?.cards ?? {}).map(([nodeId, data]) => {
                      let Component = null;

                      switch (data.type) {
                        case 'delay':
                          Component = DelayCard;
                          break;
                        case 'start':
                          Component = StartCard;
                          break;
                        case 'all':
                          Component = AllCard;
                          break;
                        case 'any':
                          Component = AnyCard;
                          break;
                        case 'note':
                          Component = NoteCard;
                          break;
                        case 'trigger':
                        case 'condition':
                        case 'action':
                          Component = AdvancedFlowCard;
                          break;
                        default:
                          return null;
                      }

                      function handleDragStart() {
                        const isSelected =
                          AdvancedFlowViewStore.getSelected()?.has(nodeId) ?? false;

                        if (isSelected === false) {
                          AdvancedFlowViewStore.setSelected(
                            new Map([
                              [
                                nodeId,
                                {
                                  type: DraggableNode.type,
                                  id: nodeId,
                                },
                              ],
                            ])
                          );
                        }
                      }

                      function handlePress(event) {
                        handleNodePress(event, {
                          type: DraggableNode.type,
                          nodeId: nodeId,
                        });
                      }

                      return (
                        <DraggableNode
                          key={nodeId}
                          nodeId={nodeId}
                          data={data}
                          manager={dropGrid.manager}
                          isDisabled={isDragDropDisabled}
                          onDragStart={handleDragStart}
                        >
                          <NodeArgumentsContext nodeId={nodeId}>
                            <Component
                              nodeId={nodeId}
                              testNodeId={testNodeId}
                              conditionTest={conditionTest}
                              conditionSave={conditionSave}
                              data={data}
                              nodeChildren={nodeChildren}
                              nodeParents={nodeParents}
                              activeConnectors={activeConnectors}
                              isInteractionDisabled={isInteractionDisabled}
                              onStartRequest={handleStartRequest}
                              onReplaceRequest={handleReplaceRequest}
                              onCopyRequest={handleCopyRequest}
                              onDuplicateRequest={handleDuplicateRequest}
                              onDeleteRequest={handleDeleteRequest}
                              onInvertRequest={handleInvertRequest}
                              onPress={handlePress}
                            />
                          </NodeArgumentsContext>
                        </DraggableNode>
                      );
                    })}
                  </AdvancedFlowView.CardsLayerSizer>
                </AdvancedFlowView.CardsLayer>
              </AdvancedFlowView.Root>
            </AdvancedFlowView.ScrollContainer>
            <AdvancedFlowViewCardDialog />
          </TokenHoverContextProvider>
        );
      }}
    </ContextMenu>
    // </AdvancedFlowTokensContextProvider>
  );
}

/**
 * layer priority
 *
 * CursorCard 1
 * SVGPortal 2
 * Cards 3
 * SVG 4
 *
 */

AdvancedFlowView.debug = false;

AdvancedFlowView.ScrollContainer = styled.div`
  ${scrollbars.dark}
  ${scrollContainerMask}

  position: relative;
  z-index: ${theme.zIndex.advanced_flow_view};
  flex: 1 1 auto;
  min-width: 0;
  width: calc(100% + ${su(2)});
  overflow: auto;

  margin-left: ${su(-2)};
  margin-top: ${su(-2)};

  ${import.meta.env.MODE === 'development' &&
  AdvancedFlowView.debug &&
  {
    // backgroundColor: 'rgba(255, 0, 0, 0.1)',
    // border: '2px solid rgba(255, 0, 0, 0.1)',
  }};
`;

AdvancedFlowView.Root = styled.div`
  position: relative;
  outline: 0;
  padding: ${su(4, 2, 2, 4)};

  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;

  ${import.meta.env.MODE === 'development' &&
  AdvancedFlowView.debug &&
  {
    // backgroundColor: 'rgba(0, 255, 0, 0.1)',
    // border: '2px solid rgba(0, 255, 0, 0.1)',
  }};

  ${useAdvancedFlowCursorCard.Root} {
    z-index: 40;
  }
`;

AdvancedFlowView.ConnectionsLayer = styled.svg`
  position: absolute;
  left: 0;
  top: 0;

  width: 0;
  height: 0;
  min-width: 100%;
  min-height: 100%;

  z-index: 10;
  pointer-events: none;

  ${import.meta.env.MODE === 'development' &&
  AdvancedFlowView.debug &&
  {
    // backgroundColor: 'rgba(255, 255, 0, 0.1)',
    // border: '2px solid rgba(0, 255, 0, 0.1)',
  }};
`;

AdvancedFlowView.CardsLayer = styled.div`
  position: relative;
  width: 0;
  height: 0;
  min-width: 100%;
  min-height: 100%;

  z-index: 20;

  ${import.meta.env.MODE === 'development' &&
  AdvancedFlowView.debug && {
    backgroundColor: 'rgba(0, 0, 255, 0.1)',
    // border: '2px solid rgba(0, 0, 255, 0.1)',
  }};

  // We need to disable pointer-events by default because connections can be hovered to remove them.
  pointer-events: none;

  // Ensures the drop grid receives pointer events during drag. Else the drop operation is canceled
  // as soon as the pointer leaves the drag card.
  &[data-is-dragging='true'] {
    pointer-events: auto;
  }
`;

AdvancedFlowView.CardsLayerSizer = styled.div`
  position: relative;
  width: 0;
  height: 0;

  ${import.meta.env.MODE === 'development' &&
  AdvancedFlowView.debug && {
    backgroundColor: 'rgba(255, 128, 0, 0.1)',
    // border: '2px solid rgba(255, 128, 0, 0.1)',
  }};
`;

AdvancedFlowView.SVGPortalLayer = styled.svg`
  position: absolute;
  top: 0;
  left: 0;

  width: 0;
  height: 0;
  min-width: 100%;
  min-height: 100%;

  pointer-events: none;
  z-index: 30;
`;
