import { shallow } from 'zustand/shallow';

import { BaseApiStore, BaseApiStoreState, FetchArgs } from '../../../store/BaseApiStore2';

import { __DEV__ } from '../../../lib/__dev__';
import { wait } from '../../../lib/wait';

import { useAttach } from '../../../store/useAttach';
import { selectBase } from '../../../store/selectors2';

export interface State {
  optionLanguageData: object | null;
  isLoading: boolean;
  error: unknown;
}

export type LanguageSettingsStoreState = State & BaseApiStoreState;

class LanguageSettingsStore extends BaseApiStore<State> {
  key = 'language-settings';

  override createInitialState() {
    return {
      optionLanguageData: null,
      isLoading: true,
      error: null,
    };
  }

  override async fetch({ api, silent, skipCache }: FetchArgs) {
    try {
      __DEV__ && console.info('fetch:language');
      this.destroy();

      silent === false &&
        this.set({
          ...this.createInitialState(),
        });

      const managerI18n = api.i18n;

      const time = silent === true ? 0 : 0;
      const waitPromise = wait(time);

      const optionLanguagePromise = managerI18n.getOptionLanguage();

      const [, optionLanguageData] = await Promise.all([waitPromise, optionLanguagePromise]);

      this.set({
        ...this.createInitialState(),
        optionLanguageData: optionLanguageData as unknown as object,
        isLoading: false,
      });
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        isLoading: false,
        error,
      });
    }
  }

  override destroy() {}

  use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAttach(this, this.key);

    return { languageSettings: this.store(selectAll, shallow) };
  }

  async setOptionLanguage({ value }: { value: string }) {
    const api = this.getApi();
    const result = (await api.i18n.setOptionLanguage({ value })) as unknown as Promise<{
      value: string;
    }>;
    this.set({ optionLanguageData: result });
  }
}

function selectAll(state: LanguageSettingsStoreState) {
  return {
    ...selectBase(state),
    optionLanguageData: state.optionLanguageData,
  };
}

export const languageSettingsStore = new LanguageSettingsStore();
