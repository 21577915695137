import { useEffect, useState, useRef, useMemo } from 'react';
import throttle from 'lodash.throttle';

import { generateErrorLinkPath, generateLinkPath } from './generateLinkPath';

import { useAdvancedFlowViewContext } from '../AdvancedFlowViewContext';

import { connectorTypesMap } from '../connectors/connectorTypes';

import { enqueueTask } from '../../../../lib/enqueueTask';

export function useConnectionLink({ containerRef, connection }) {
  const instanceRef = useRef({
    pathElement: document.createElementNS('http://www.w3.org/2000/svg', 'path'),
  });

  const [link, setLink] = useState({});
  const advancedFlowViewContext = useAdvancedFlowViewContext();

  const recalculate = useMemo(() => {
    return throttle((node) => {
      /**
       * Because the card renders at the same time we cant get the action position in
       * the same tick.
       */
      enqueueTask(() => {
        const fromConnector = advancedFlowViewContext.getConnector({
          type: connection.fromConnectorType,
          nodeId: connection.fromNodeId,
        });

        const toConnector = advancedFlowViewContext.getConnector({
          type: connection.toConnectorType,
          nodeId: connection.toNodeId,
        });

        const link = generateLink({
          fromConnector,
          toConnector,
          offsetParentElement: containerRef.current,
          pathElement: instanceRef.current.pathElement,
        });

        if (link != null) {
          setLink({
            path: link.path,
            fromX: link.fromX,
            fromY: link.fromY,
            toX: link.toX,
            toY: link.toY,
            centerX: link.centerPoint.x,
            centerY: link.centerPoint.y,
          });
        }
      });
    }, 50);
  }, [advancedFlowViewContext, connection, containerRef, instanceRef]);

  useEffect(() => {
    advancedFlowViewContext.subscribe(
      connection.fromConnectorType,
      connection.fromNodeId,
      recalculate
    );
    advancedFlowViewContext.subscribe(connection.toConnectorType, connection.toNodeId, recalculate);

    recalculate();

    return function () {
      advancedFlowViewContext.unsubscribe(
        connection.fromConnectorType,
        connection.fromNodeId,
        recalculate
      );
      advancedFlowViewContext.unsubscribe(
        connection.toConnectorType,
        connection.toNodeId,
        recalculate
      );
    };
  }, [advancedFlowViewContext, connection, recalculate]);

  return [link, recalculate];
}

function generateLink({ fromConnector, toConnector, offsetParentElement, pathElement }) {
  // If anything is not present yet dont attempt to create the link.
  if (
    fromConnector != null &&
    toConnector != null &&
    offsetParentElement != null &&
    pathElement != null
  ) {
    function getConnectorCoords({ connector, toConnectorKey, fromConnectorKey }) {
      const position = connector.getPosition({
        offsetParentElement,
        toConnectorKey,
        fromConnectorKey,
      });

      // Return the center of the connector
      return {
        x: position.x + position.width / 2,
        y: position.y + position.height / 2,
      };
    }

    const fromConnectorCoords = getConnectorCoords({
      connector: fromConnector,
      toConnectorKey: toConnector.key,
    });
    const toConnectorCoords = getConnectorCoords({
      connector: toConnector,
      fromConnectorKey: fromConnector.key,
    });

    const fromX = fromConnectorCoords.x;
    const fromY = fromConnectorCoords.y;
    const toX = toConnectorCoords.x;
    const toY = toConnectorCoords.y;

    let path = `M ${fromX} ${fromY} L ${toX} ${toY}`;

    if (fromConnector.type !== connectorTypesMap.Error) {
      const cardHeight = fromConnector.getCardHeight();
      const cardWidth = fromConnector.getCardWidth();

      path = generateLinkPath(fromX, fromY, toX, toY, 1, { width: cardWidth, height: cardHeight });
    } else {
      path = generateErrorLinkPath(fromX, fromY, toX, toY);
    }

    // Get the center point of the current path. Same pathElement can be reused on subsequent calls.
    pathElement.setAttribute('d', path);
    const total = pathElement.getTotalLength();
    const point = total > 0 ? pathElement.getPointAtLength(total / 2) : 0;

    return {
      path,
      fromX,
      fromY,
      toX,
      toY,
      centerPoint: point,
    };
  }
}
