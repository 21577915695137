import { HomeyStore } from './HomeyStore';

export class ResourceUtils {
  static TYPES = {
    device: 'device',
    app: 'app',
    manager: 'manager',
  };

  static getKey(resource) {
    const apiVersion = HomeyStore.get().api?.apiVersion;

    if (apiVersion >= 3) {
      return resource?.id ?? null;
    }

    const ownerUri = resource?.ownerUri ?? resource?.uri ?? null;

    if (ownerUri != null) {
      return `${ownerUri}::${resource.id}`;
    }

    return resource?.id ?? null;
  }

  static getOwnerUriV3(resource) {
    const apiVersion = HomeyStore.get().api?.apiVersion;

    if (apiVersion >= 3) {
      let parts = resource.id.split(':');
      parts.pop();
      return parts.join(':');
    }

    return resource?.ownerUri ?? resource?.uri ?? null;
  }

  static temp__getId(resource) {
    const apiVersion = HomeyStore.get().api?.apiVersion;

    if (resource == null) {
      throw new Error('ResourceUtils.getId: resource is null');
    }

    if (apiVersion >= 3) {
      return resource.id;
    }

    return `${resource.uri}:${resource.id}`;
  }

  // For api version v3 tokens are the only exception. The id is not unique across tokens. To generate
  // a unique key we use the uri combined with the id.
  static getTokenKey(resource) {
    const ownerUri = resource?.ownerUri ?? resource?.uri ?? null;

    if (ownerUri != null) {
      return `${ownerUri}::${resource.id}`;
    }

    return resource?.id ?? null;
  }

  static splitKey(key) {
    const split = key.split('::');

    if (split.length === 1) {
      return [null, split[0]];
    }

    return split;
  }

  static getId(resource) {
    return resource?.id ?? null;
  }

  static getOwnerUri(resource) {
    return resource?.ownerUri ?? resource?.uri ?? null;
  }

  static getOwnerId(resource) {
    const ownerUri = ResourceUtils.getOwnerUri(resource);
    return ResourceUtils.getOwnerTypeAndIdFromOwnerUri(ownerUri).id;
  }

  static getOwnerType(resource) {
    const ownerUri = ResourceUtils.getOwnerUri(resource);
    return ResourceUtils.getOwnerTypeAndIdFromOwnerUri(ownerUri).type;
  }

  static getOwnerName(resource) {
    return resource?.ownerName ?? resource?.uriObj?.name ?? null;
  }

  static getOwnerColor(resource) {
    return resource?.color ?? resource?.uriObj?.color ?? null;
  }

  static getOwnerTypeAndIdFromOwnerUri(ownerUri) {
    if (ownerUri == null) {
      return {
        type: null,
        id: null,
      };
    }

    // homey:app:com.athom.homey:whatever
    const regex = /^homey:(?<type>app|manager|device|zone|logic|mood):(?<id>[^:]+)$/;
    const match = ownerUri.match(regex);

    // type = app
    // id = com.athom.homey

    if (match == null) {
      return {
        type: null,
        id: null,
      };
    }

    return { id: match.groups.id, type: match.groups.type };
  }

  static getOwnerProps(resource) {
    if (resource == null) {
      return {
        ownerUri: null,
        ownerId: null,
        ownerType: null,
      };
    }

    const ownerUri = ResourceUtils.getOwnerUri(resource);
    const { id, type } = ResourceUtils.getOwnerTypeAndIdFromOwnerUri(ownerUri);

    return {
      ownerUri: ownerUri,
      ownerId: id,
      ownerType: type,
    };
  }
}
