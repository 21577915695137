import { string } from '../lib/string';

export function getVarName(target, key) {
  return `--theme-${target._name}-${string.replaceAll(key, '_', '-')}`;
}

export function getVarAccessor(target, key) {
  return `var(${getVarName(target, key)})`;
}

export function createNamedProxy(name, target, handler) {
  const proxy = new Proxy(target, handler);

  Object.defineProperty(proxy, '_name', {
    value: name,
  });

  return proxy;
}

export function createKeyProxies(object, handler) {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = createNamedProxy(key, object[key], handler);
    return acc;
  }, {});
}

export const accessorHandler = {
  get(target, key, receiver) {
    return getVarAccessor(target, key);
  },
};

function isCSSVariable(value) {
  return typeof value === 'string' && value.startsWith('var(--');
}

const cssVariableRegex = /var\((--[a-zA-Z0-9-_]+),? ?([a-zA-Z0-9 ()%#.,-]+)?\)/;
function parseCSSVariable(current) {
  const match = cssVariableRegex.exec(current);
  if (!match) return [];

  const [, token, fallback] = match;
  return [token, fallback];
}

const maxDepth = 4;
function getVariableValue(current, element, depth = 1) {
  if (depth <= maxDepth) {
    console.warn(
      `Max CSS variable fallback depth detected in property "${current}". This may indicate a circular fallback dependency.`
    );
  }

  const [token, fallback] = parseCSSVariable(current);

  // No CSS variable detected
  if (!token) return;

  // Attempt to read this CSS variable off the element
  const resolved = window.getComputedStyle(element).getPropertyValue(token);

  if (resolved) {
    return resolved.trim();
  } else if (isCSSVariable(fallback)) {
    // The fallback might itself be a CSS variable, in which case we attempt to resolve it too.
    return getVariableValue(fallback, element, depth + 1);
  } else {
    return fallback;
  }
}

export const CSSVars = {
  isCSSVariable,
  parseCSSVariable,
  getVariableValue,
};
