import { SCOPES } from '../../../lib/scopes';
import { userStore } from '../../../store/users/UserStore';
import { ToastManager } from '../../../ToastManager';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useUser } from '../../../store/users/useUsers';
import { useApi } from '../../../store/HomeyStore';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
// import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';

export function FamilySettingsTableInvitesContextMenuContent(props) {
  const { i18n } = useI18n();
  const { user } = useUser({ userId: props.userId });
  const { scopes } = useApi();

  async function onAction(key) {
    switch (key) {
      case 'reshare':
        navigator.clipboard
          .writeText(user?.inviteUrl)
          .then(() => {
            ToastManager.add({
              message: i18n.messageFormatter('common.copied'),
            });
          })
          .catch(ToastManager.handleError);
        break;
      case 'delete':
        userStore.deleteUser({ id: props.userId }).catch(ToastManager.handleError);
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.reshare')}
        isDisabled={scopes[SCOPES.USER] !== true}
        onPress={() => {
          onAction('reshare');
        }}
      />

      {/* <DeveloperMenu onIdRequest={() => user?.id} onResourceRequest={() => user} /> */}

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        isDisabled={scopes[SCOPES.USER] !== true}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
