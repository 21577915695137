import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ZoneStore } from './ZoneStore';
import { useAttach } from '../useAttach';

import { selectBase, selectData } from '../selectors';

function selectAll(state) {
  return {
    ...selectBase(state),
    data: state.data,
    byId: state.byId,
    rootZoneId: state.rootZoneId,
  };
}

function selectById(state) {
  return {
    ...selectBase(state),
    byId: state.byId,
    rootZoneId: state.rootZoneId,
  };
}

function selectChildren(state) {
  return {
    ...selectBase(state),
    data: state.data,
    byIdChildren: state.byIdChildren,
    rootZoneId: state.rootZoneId,
  };
}

function selectRootZoneId(state) {
  return {
    loading: state.loading,
    rootZoneId: state.rootZoneId,
  };
}

function selectTree(state) {
  return {
    ...selectBase(state),
    byId: state.byId,
    tree: state.tree,
  };
}

export function useZones() {
  useAttach(ZoneStore, 'zones');

  return ZoneStore.store(selectAll, shallow);
}

export function useZonesData() {
  useAttach(ZoneStore, 'zonesData');

  return ZoneStore.store(selectData, shallow);
}

export function useZonesById() {
  useAttach(ZoneStore, 'zonesById');

  return ZoneStore.store(selectById, shallow);
}

export function useZonesByIdChildren() {
  useAttach(ZoneStore, 'zonesByIdChildren');

  return ZoneStore.store(selectChildren, shallow);
}

export function useZonesTree() {
  useAttach(ZoneStore, 'zonesTree');

  return ZoneStore.store(selectTree, shallow);
}

export function useRootZoneId() {
  useAttach(ZoneStore, 'rootZoneId');

  return ZoneStore.store(selectRootZoneId, shallow);
}

export function useZone({ zoneId }) {
  useAttach(ZoneStore, 'zone');

  const select = useCallback(
    (state) => {
      return {
        zone: state.byId?.[zoneId] ?? null,
        api: state.api,
      };
    },
    [zoneId]
  );

  return ZoneStore.store(select, shallow);
}

export function useZonesAttach() {
  useAttach(ZoneStore, 'useZonesAttach');
}
