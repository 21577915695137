import styled from '@emotion/styled';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useToken } from '../../../store/flow-tokens/useFlowTokens';
import { useOptionContext } from '../../../components/list-box/ListBox';

import { theme } from '../../../theme/theme';

import { defaultTokenExamples } from '../flow-arguments/tokens';

import { FlowToken } from '../flow-arguments/FlowToken';

export function TokenItem(props) {
  const { i18n } = useI18n();
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const { token } = useToken({ tokenKey: value.key });

  let formattedTokenValue;
  const tokenValue = value.context.tokenValue != null ? value.context.tokenValue : token?.value;

  switch (token?.type) {
    case 'image':
      formattedTokenValue = '';
      break;
    case 'boolean':
      formattedTokenValue =
        tokenValue != null
          ? i18n.messageFormatter(`common.${tokenValue}`)
          : i18n.messageFormatter('logic.boolean');
      break;
    default:
      formattedTokenValue = String(
        tokenValue ?? token?.example ?? defaultTokenExamples[token?.type]
      );
      break;
  }

  return (
    <TokenItem.Root
      {...option.optionProps}
      ref={ref}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
    >
      <TokenItem.NameWrapper>
        <FlowToken
          style={{ '--flow-token-max-width': '200px' }}
          label={item.textValue}
          token={token}
          isValueTooltipDisabled={true}
        />
      </TokenItem.NameWrapper>
      <TokenItem.Value>{formattedTokenValue}</TokenItem.Value>
    </TokenItem.Root>
  );
}

export function LocalTokenItem(props) {
  const { i18n } = useI18n();
  // eslint-disable-next-line no-unused-vars
  const { ref, option, state, item, value } = useOptionContext();

  const token = value.context.token;
  let tokenValue;

  switch (token?.type) {
    case 'image':
      tokenValue = '';
      break;
    case 'boolean':
      tokenValue = i18n.messageFormatter('logic.boolean');
      break;
    default:
      tokenValue = String(token.example ?? defaultTokenExamples[token.type]);
      break;
  }

  return (
    <TokenItem.Root
      {...option.optionProps}
      ref={ref}
      data-is-selected={option.isSelected}
      data-is-focused={option.isFocused}
      data-is-pressed={option.isPressed}
      data-is-disabled={option.isDisabled}
    >
      <TokenItem.NameWrapper>
        <FlowToken
          style={{ '--flow-token-max-width': '200px' }}
          label={item.textValue}
          tokenKey={item.key}
          token={token}
          isValueTooltipDisabled={true}
        />
      </TokenItem.NameWrapper>
      <TokenItem.Value>{tokenValue}</TokenItem.Value>
    </TokenItem.Root>
  );
}

TokenItem.NameWrapper = styled.div`
  flex: 1 1 60%;
`;

TokenItem.Value = styled.span`
  display: inline-block;
  padding-left: 10px;
  flex: 1 1 40%;
  color: ${theme.color.text_light};
  font-size: ${theme.fontSize.small};
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

TokenItem.Root = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  min-height: var(--height);
  border-radius: ${theme.borderRadius.default};
  outline: 0;
  padding: 5px 10px;
  cursor: pointer;

  &[data-is-focused='true'],
  &:hover {
    background-color: ${theme.color.background_hover};
  }
`;
