import { useRef, useState } from 'react';
import styled from '@emotion/styled';

import { mergeProps, useHover } from 'react-aria';

import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { TokenContextProvider } from './TokenContextProvider';

import { mergeRefs } from '../../../../lib/mergeRefs';

import { useExecutionState } from './useExecutionState';
import { useRecalculateConnection } from './useRecalculateConnection';
import { useDraggableButton } from './useDraggableButton';

import { theme } from '../../../../theme/theme';

import { ContextMenu } from '../../../../components/common/context-menu/ContextMenu';

import { AdvancedFlowCard } from './AdvancedFlowCard';
import { DelayCardContextMenuContent } from './DelayCardContextMenuContent';
import { ConnectorIn } from '../connectors/ConnectorIn';
import { ConnectorOut } from '../connectors/ConnectorOut';

import { executionStateType } from './executionStateType';

export function AnyCard(props) {
  const rootRef = useRef();
  const buttonRef = useRef();

  const { executionState } = useExecutionState({
    rootRef,
    nodeId: props.nodeId,
    cardData: props.data,
  });
  useRecalculateConnection({ rootRef, nodeId: props.nodeId });

  // copy this section from AdvancedFlowCard for now
  const isUnreachableNode = executionState.type === executionStateType.unreachable;
  const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  // eslint-disable-next-line no-unused-vars
  const isTestingRoot = isTesting && props.testNodeId === props.nodeId;
  const isSaving = props.conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isDisabled = isTesting || isUnreachableNode || isSaving || props.isInteractionDisabled;
  const isDisabledStyle = isUnreachableNode ? 'unreachable' : 'readonly';
  // copy this section end

  const isSelected = AdvancedFlowViewStore.useIsSelected({ id: props.nodeId });

  const hover = useHover({});
  const button = useDraggableButton(
    {
      elementType: 'div',
      isDisabled: isDisabled,
      onPress: props.onPress,
    },
    buttonRef
  );

  const [isDraggingAndCanConnect, setIsDraggingAndCanConnect] = useState(null);
  const [isDragOverAndCanConnect, setIsDragOverAndCanConnect] = useState(null);

  function handleIsDraggingAndCanConnectChange(isDraggingAndCanConnect) {
    // Dont update the state if it's null and it's called with false. This ensures the initial render
    // does not cause an animation effect.
    setIsDraggingAndCanConnect((prevState) => {
      if (prevState == null && isDraggingAndCanConnect === false) {
        return prevState;
      }

      return isDraggingAndCanConnect;
    });
  }

  function handleIsDragOverAndCanConnectChange(isDragOverAndCanConnect) {
    // Dont update the state if it's null and it's called with false. This ensures the initial render
    // does not cause an animation effect.
    setIsDragOverAndCanConnect((prevState) => {
      if (prevState == null && isDragOverAndCanConnect === false) {
        return prevState;
      }

      return isDragOverAndCanConnect;
    });
  }

  return (
    <TokenContextProvider nodeId={props.nodeId} cardData={props.data}>
      <ContextMenu
        content={
          <DelayCardContextMenuContent
            nodeId={props.nodeId}
            card={props.card}
            data={props.data}
            onStartRequest={props.onStartRequest}
            onCopyRequest={props.onCopyRequest}
            onDuplicateRequest={props.onDuplicateRequest}
            onDeleteRequest={props.onDeleteRequest}
          />
        }
      >
        {({ isOpen, onContextMenu }) => {
          return (
            <AnyCard.Root
              {...mergeProps(button.buttonProps, hover.hoverProps)}
              ref={mergeRefs([props.forwardedRef, rootRef, buttonRef])}
              className={props.className}
              nodeId={props.nodeId}
              data-is-selected={isSelected}
              data-is-dragging-and-can-connect={isDraggingAndCanConnect}
              data-is-dragover-and-can-connect={isDragOverAndCanConnect}
              data-is-unreachable-node={isUnreachableNode}
              data-is-testing={isTesting}
              data-style-type={props.data.type}
              data-execution-state-type={executionState.type}
              onContextMenu={onContextMenu}
            >
              <AnyCard.Inner>
                <ConnectorIn
                  cardRootRef={rootRef}
                  style={{
                    position: 'absolute',
                    right: 'calc(100% - 1px)',
                    top: '50%',
                    transform: 'translate(0, -50%) rotate(90deg)',
                  }}
                  nodeId={props.nodeId}
                  nodeChildren={props.nodeChildren}
                  nodeParents={props.nodeParents}
                  activeConnectors={props.activeConnectors}
                  executionState={executionState}
                  isSelected={isSelected}
                  isHovered={hover.isHovered}
                  isVisibleOverride={true}
                  isUnreachableNode={isUnreachableNode}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                  onIsDraggingAndCanConnectChange={handleIsDraggingAndCanConnectChange}
                  onIsDragOverAndCanConnectChange={handleIsDragOverAndCanConnectChange}
                />

                <ConnectorOut
                  cardRootRef={rootRef}
                  style={{
                    position: 'absolute',
                    left: 'calc(100% - 1px)',
                    top: '50%',
                    transform: 'translate(0, -50%) rotate(-90deg)',
                  }}
                  nodeId={props.nodeId}
                  activeConnectors={props.activeConnectors}
                  executionState={executionState}
                  isSelected={isSelected}
                  isHovered={hover.isHovered}
                  isVisibleOverride={true}
                  isDragOverAndCanConnect={isDragOverAndCanConnect}
                  isUnreachableNode={isUnreachableNode}
                  isDisabled={isDisabled}
                  isDisabledStyle={isDisabledStyle}
                />

                <AnyCard.Content>
                  <AnyCard.AnimationWrapper>
                    <AnyCard.AnimationContainer>
                      <AnyCard.Title>ANY</AnyCard.Title>
                      <AnyCard.Counter data-is-continue={(executionState.data?.runCount ?? 0) >= 1}>
                        {executionState.data?.runCount ?? 0} / {1}
                      </AnyCard.Counter>
                    </AnyCard.AnimationContainer>
                  </AnyCard.AnimationWrapper>
                </AnyCard.Content>
              </AnyCard.Inner>
            </AnyCard.Root>
          );
        }}
      </ContextMenu>
    </TokenContextProvider>
  );
}

AnyCard.Root = AdvancedFlowCard.Root;

AnyCard.Inner = AdvancedFlowCard.Inner;

AnyCard.Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 20px;
`;

AnyCard.AnimationWrapper = styled.div`
  overflow: hidden;
  padding: 10px 0;
  height: 40px;
  mask-image: linear-gradient(0deg, transparent 0%, black 10px, black 30px, transparent 100%);
`;

AnyCard.AnimationContainer = styled.div`
  display: grid;
  grid-template-rows: 20px;
  grid-gap: 10px;
  transform: translateY(-30px);
  transition: ${theme.duration.normal} ease-in-out;
  transition-property: transform;

  [data-execution-state-type='idle'] & {
    transform: translateY(0);
  }
`;

AnyCard.Title = styled.span`
  line-height: 20px;
  font-weight: ${theme.fontWeight.black};
  color: ${theme.flowcard.title_color};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: color;

  [data-is-dragover-and-can-connect='true'] & {
    color: ${theme.color.blue};
  }
`;

AnyCard.Counter = styled.div`
  border-radius: ${theme.borderRadius.small};
  background: ${theme.color.orange};
  color: ${theme.color.text_white};
  line-height: 20px;
  height: 20px;
  padding: 0 5px;
  font-size: ${theme.fontSize.tiny};
  font-weight: ${theme.fontWeight.bold};
  transition: ${theme.duration.fast} ease-in-out;
  transition-property: background;

  &[data-is-continue='true'] {
    background: ${theme.color.blue};
  }
`;
