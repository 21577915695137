import { ToastManager } from '../../../ToastManager';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useApi } from '../../../store/HomeyStore';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';
import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';
import { FlowCardMissingContextMenuContent } from './FlowCardMissingContextMenuContent';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';
import { iconReplace } from '../../../theme/icons/interface/replace';
import { iconCopyDuplicate } from '../../../theme/icons/interface/copy-duplicate';
import { iconPlay } from '../../../theme/icons/interface/play';
import { iconCheckmark } from '../../../theme/icons/interface/checkmark';

export function FlowCardActionContextMenuContent(props) {
  const { i18n } = useI18n();
  // const ctx = useContextMenuContext();

  const { api } = useApi();

  async function onAction(key) {
    switch (key) {
      case 'replace':
        props.onReplaceRequest();
        break;
      case 'duplicate':
        props.onDuplicateRequest();
        break;
      case 'run':
        async function run() {
          try {
            let duration;

            const number = props.data.duration?.number;
            const multiplier = props.data.duration?.multiplier ?? 1;

            if (String(number).indexOf('-') > 0) {
              duration = number.split('-').map((num) => parseInt(num, 10));
              const [min = 0, max = 0] = duration;
              duration = min + Math.random() * (max - min);
            } else if (number != null) {
              duration = parseFloat(number);
            }

            await api.flow.runFlowCardAction({
              uri: ResourceUtils.getOwnerUriV3(props.data),
              id: props.data.id,
              args: props.data.args,
              droptoken: props.data.droptoken,
              duration: duration != null ? duration * multiplier : undefined,
            });

            ToastManager.add({
              icon: iconCheckmark,
              message: i18n.messageFormatter('flow.startCardSuccess'),
            });
          } catch (error) {
            ToastManager.handleError(error);
          }
        }

        run();
        break;

      case 'duration':
        props.onDurationRequest();
        break;
      case 'delay':
        props.onDelayRequest();
        break;
      case 'delete':
        props.onDeleteRequest();
        break;
      default:
        break;
    }
  }

  if (props.card == null) {
    return <FlowCardMissingContextMenuContent {...props} />;
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.replace')}
        icon={iconReplace}
        onPress={() => {
          onAction('replace');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.test')}
        icon={iconPlay}
        onPress={() => {
          onAction('run');
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={
          props.data.duration
            ? i18n.messageFormatter('flow.removeDuration')
            : `${i18n.messageFormatter('flow.addDuration')}...`
        }
        isHidden={!props.card?.duration}
        onPress={() => {
          onAction('duration');
        }}
      />
      <ContextMenuContent.ListItem
        label={
          props.data.delay
            ? i18n.messageFormatter('flow.removeDelay')
            : `${i18n.messageFormatter('flow.addDelay')}...`
        }
        onPress={() => {
          onAction('delay');
        }}
      />

      <DeveloperMenu
        onIdRequest={() => props.card?.id}
        onUriRequest={() => ResourceUtils.getOwnerUri(props.card ?? {})}
        onResourceRequest={() => {
          return {
            card: props.card,
            data: props.data,
          };
        }}
      />

      <ContextMenuContent.Divider />
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
