import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { ResourceUtils } from '../../../../store/ResourceUtils';

import { platform } from '../../../../lib/platform';

import { useI18n } from '../../../../hooks/useI18nFormatters';
import { useContextMenuContext } from '../../../../components/common/context-menu/ContextMenuContextProvider';
import { useTreeUsedTokenKeys } from '../useTreeTokenKeys';

import { ContextMenuContent } from '../../../../components/common/context-menu/ContextMenuContent';
import { DraggableNode } from './DraggableNode';
import { AdvancedFlowTestForm } from '../AdvancedFlowTestForm';
import { DeveloperMenu } from '../../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../../theme/icons/interface/trash-fill/trash-fill';
import { iconPlayCircle } from '../../../../theme/icons/interface/play-circle/play-circle';
import { iconCopyDuplicate } from '../../../../theme/icons/interface/copy-duplicate';

export function DelayCardContextMenuContent(props) {
  const { i18n } = useI18n();
  const ctx = useContextMenuContext();
  // const { selected } = AdvancedFlowViewStore.useSelected();

  const { advancedFlow, nodeChildren, nodeAncestors } = AdvancedFlowViewStore.useAdvancedFlowView();
  const { treeTokenKeys, canTestWithoutTokens } = useTreeUsedTokenKeys({
    fromNodeId: props.nodeId,
    advancedFlow,
    nodeChildren,
    nodeAncestors,
  });

  function onAction(key, args) {
    // const position = ctx.getPosition();

    switch (key) {
      case 'copy':
        props.onCopyRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'start':
        props.onStartRequest({ nodeId: props.nodeId });
        break;
      case 'duplicate':
        props.onDuplicateRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      case 'delete':
        props.onDeleteRequest(null, { id: props.nodeId, type: DraggableNode.type });
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      {canTestWithoutTokens ? (
        <ContextMenuContent.ListItem
          label={i18n.messageFormatter('flow.testFromHere')}
          icon={iconPlayCircle}
          onPress={() => {
            onAction('start');
          }}
        />
      ) : (
        <ContextMenuContent.SubMenu title={i18n.messageFormatter('flow.testFromHere')}>
          <AdvancedFlowTestForm
            currentNodeId={props.nodeId}
            treeTokenKeys={treeTokenKeys}
            advancedFlow={advancedFlow}
            onStartRequest={({ tokens }) => {
              ctx.onCloseRequest();
              props.onStartRequest({ nodeId: props.nodeId, tokens });
            }}
          />
        </ContextMenuContent.SubMenu>
      )}

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.copy')}
        hint={`${platform.metaOrCtrlSymbol} + C`}
        onPress={() => {
          onAction('copy');
        }}
      />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.duplicate')}
        icon={iconCopyDuplicate}
        onPress={() => {
          onAction('duplicate');
        }}
      />

      <DeveloperMenu
        onIdRequest={() => props.card?.id}
        onUriRequest={() => ResourceUtils.getOwnerUri(props.card ?? {})}
        onResourceRequest={() => {
          return {
            card: props.card,
            data: props.data,
            nodeId: props.nodeId,
          };
        }}
      />

      <ContextMenuContent.Divider />

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('flow.delete')}
        variant="danger"
        icon={iconTrashFill}
        onPress={() => {
          onAction('delete');
        }}
      />
    </ContextMenuContent.List>
  );
}
